/* eslint-disable react-hooks/exhaustive-deps */
import { H5 } from 'components/FontStyle'
import { ButtonAdd, InputSearch } from 'components/output'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as IconTrash } from 'images/icons/trash.svg'
import ModalSelectList from 'components/ModalSelectList'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import brandProvider from 'provider/brandProvider'
import PaginationComponent from 'components/pagination'
import ModalConfirm from 'components/modalConfirm/ModalConfirm'
import ModalAlert from 'components/modalAlert/ModalAlert'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert'
// import { ReactComponent as IconSearch } from 'images/icons/search.svg'
import { ReactComponent as IconAlert } from 'images/icons/alert.svg'
import { ReactComponent as IconEdit } from 'images/icons/edit.svg'
import { ReactComponent as IconNoItem } from 'images/icons/status-error.svg'
import IconDefaultBrand from 'images/icons/icon-default-brand.svg'
import classNames from 'classnames'
import { UncontrolledTooltip } from 'reactstrap';

const HeaderContainer = styled.div`
    padding: 1em;
`

const GridHeader = styled.div`
     display: grid;
    grid-row: auto;
    grid-column: auto;
    grid-auto-rows: auto;
    grid-template-columns: 10% auto 13% 10% 10%;
    border-radius: 10px 10px 0px 0px;
    color: #FFFFFF;
    padding: 1em;
    margin-top: 1em;
    align-items: baseline;
    text-align: center;
`

const GridBody = styled.div`
     display: grid;
    grid-row: auto;
    grid-column: auto;
    grid-auto-rows: auto;
    grid-template-columns: 10% auto 13% 10% 10%;
    background: #FFFFFF;
    color: #383633;
    padding: 1em;
    align-items: center;
    text-align: center;
    row-gap: 1em;
    margin-top: 1em;
`

const BrandLogo = styled.img`
    width: auto;
    height: 50px; 
    border: 1px solid #E1E1E1;
`

const Alert = styled(IconAlert)`
    width: auto;
    height: auto;
    max-width: 15px;
    margin-right: 5px;
    & path {
        fill: #ED1072;
    }
`

const Brand = (props) => {
    const { history } = props
    const dispatch = useDispatch()
    const currentShop = useSelector(state => state.shop.currentShop)

    const [listBrands, setListBrands] = useState([])
    const [listTotal, setListTotal] = useState(0)
    const [modal, setModal] = useState({})
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState(null)
    const [modalCategories, setModalCategories] = useState({ isOpen: false })


    useEffect(() => {
        getListBrand()
    }, [page, limit, modalCategories])

    const getListBrand = async () => {
        const getBrand = await brandProvider.getBrandByShopId(currentShop?.id, { page, limit, kw: search })
        setListBrands(getBrand.data.data)
        setListTotal(getBrand.data.count)
    }

    const handleDeleteBrand = async (id) => {
        try {
            const resDelete = await brandProvider.deleteBrand(id)
            if (resDelete) {
                getListBrand()
                setModal({})
            }
        } catch (error) {
            if (error?.response?.data?.message === "THERE_ARE_PRODUCTS_USING_THIS_BRAND_CANNOT_DELETE" || error?.data?.message === "THERE_ARE_PRODUCTS_USING_THIS_BRAND_CANNOT_DELETE" ) {
                dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'ไม่สามารถลบแบรนด์สินค้านี้ได้ เนื่องจากมีสินค้าใช้แบรนด์นี้อยู่' } }))
                setModal({})
            }
        }
    }

    const handleSearch = () => {
        getListBrand()
    }

    const handleClearSearch = async () => {
        setSearch('')
        setPage(1)
        const getBrand = await brandProvider.getBrandByShopId(currentShop?.id, { page, limit })
        setListBrands(getBrand.data.data)
        setListTotal(getBrand.data.count)
    }


    return (
        <div>
            <div className="bg-white">
                <HeaderContainer>
                    <div className="d-flex flex-row justify-content-between">
                        <H5 secondary>แบรนด์สินค้า</H5>
                        <Link
                            //  to="/manage/shop/shopdetail/addbrand"
                            to={{
                                pathname: "/manage/shop/shopdetail/addbrand",
                                query: { actionType: 'add' }
                            }}
                        >
                            <ButtonAdd
                                label="เพิ่มแบรนด์สินค้า"
                            // handleAdd={() => alert('add')}
                            />
                        </Link>

                    </div>
                    {/* <form onSubmit={(e) => handleSearch(e)} className="w-50 position-relative">
                        <IconSearch className="cursor-pointer position-absolute" onClick={(e) => handleSearch(e)} style={{ right: '10px', top: '20%' }} />
                        <input type="text" className="form-control mt-3" placeholder="ค้นหาแบรนด์สินค้า" onChange={(e) => setSearch(e.target.value)} />
                    </form> */}
                    <div className="w-50">
                        <InputSearch
                            handleSearch={(e) => handleSearch(e)}
                            placeholder='ค้นหาแบรนด์สินค้า'
                            onChange={(e) => setSearch(e.target.value)}
                            onClearSearch={() => handleClearSearch()}
                            statusSearch={search && search.trim().length > 0}
                        />
                    </div>
                </HeaderContainer>
            </div>

            <div className="mt-2">
                <GridHeader className='base-table-header'>
                    <div>ลำดับ</div>
                    <div className="text-left">ชื่อแบรนด์สินค้า</div>
                    <div>หมวดหมู่สินค้า</div>
                    <div>สถานะ</div>
                    <div></div>
                </GridHeader>
                {listBrands && listBrands?.length === 0 && <div className="d-flex flex-column align-items-center justify-content-center mt-4 h-50vh">
                    <IconNoItem />
                    <span className="mt-2 font-secondary">ไม่มีรายการ</span>
                </div>}
                {listBrands?.map((brand, brandIndex) => (
                    <GridBody className="base-table-content" key={`listBrands${brandIndex}`}>
                        <React.Fragment>
                            <div><div><span>{((page - 1) * limit) + brandIndex + 1}</span></div></div>
                            <div className="text-left">
                               { brand.logoPath && brand.logoPath?.host ?
                               <BrandLogo
                                    src={`${brand.logoPath?.host}${brand.logoPath?.path}`} alt={brand.name}
                                    style={{ maxWidth: '40px', maxHeight: '40px', objectFit: 'cover' }} />
                                :
                                <BrandLogo
                                    src={IconDefaultBrand} alt={brand.name}
                                    style={{ maxWidth: '40px', maxHeight: '40px', objectFit: 'cover' }} />
                                }
                                <span className="ml-3">{brand.name}</span> </div>
                            <div className="cursor-pointer color-info-main" onClick={() => setModalCategories({ isOpen: true, data: brand })}> 
                            {brand?.countCategories === 0 && 
                                         <React.Fragment>
                                         <Alert id="test1">
                                         </Alert>
                                         <UncontrolledTooltip placement="top" target="test1">
                                             {`ยังไม่ได้ตั้งค่า `}
                                         </UncontrolledTooltip>
                                     </React.Fragment>
                                    }
                                    <span>ตั้งค่า</span>
                            </div>
                            <div className={classNames('badge', { 'badge-green': brand.status === 'show', 'badge-gray': brand.status === 'hide' })}>{brand.status === 'show' ? 'เปิด' : 'ปิด'}</div>
                            <div>
                                <IconEdit className="cursor-pointer icon-manage"
                                    onClick={() => {
                                        history.push({
                                            pathname: '/manage/shop/shopdetail/addbrand',
                                            // search: '?query=abc',
                                            state: { brand }
                                        });
                                    }} />
                                <IconTrash className="cursor-pointer icon-manage"
                                    onClick={() =>
                                        setModal({
                                            isOpen: true,
                                            message: 'คุณแน่ใจหรือไม่ที่จะลบแบรนด์นี้',
                                            confirm: () => handleDeleteBrand(brand.id),
                                            isClose: () => setModal({})
                                        })} /></div>
                        </React.Fragment>
                    </GridBody>
                ))}

                <ModalConfirm
                    btnConfirm='ยืนยัน'
                    btnCancel='ยกเลิก'
                    isOpen={modal.isOpen}
                    message={modal.message}
                    confirm={() => modal.confirm()}
                    isClose={() => modal.isClose()}
                />

                <ModalAlert />

                {listBrands && listBrands?.length !== 0 &&
                    <PaginationComponent
                        defaultCurrent={1}
                        total={listTotal}
                        onChange={(page) => setPage(page)}
                        pageSize={limit}
                        pageSizeOptions={[10, 20, 40]}
                        onShowSizeChange={(page, limit) => { setPage(page); setLimit(limit) }}
                        current={page}
                    />
                }



                <ModalSelectList
                    modal={modalCategories}
                    isClose={() => setModalCategories({ isOpen: false, data: null })}
                    type='brand'
                />
            </div>

            {/* <ModalSelectList /> */}
        </div>
    )
}

export default Brand
