import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
// import arrowLeft from '../images/icon/arrow-left.svg';
import arrowRight from 'images/icons/arrow-right.svg';
import arrowLeft from 'images/icons/arrow-left.svg';
import { ReactComponent as IconArrowRight } from 'images/icons/arrow-right.svg';
import { ReactComponent as IconArrowLeft } from 'images/icons/arrow-left.svg';

import "./style.scss";
import { getInitialEditVod } from "redux/sagas/product";

const MONTHS = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม"
];
const MONTHS_SHORT = ["ม.ค", "ก.พ.", "มี.ค", "เม.ย.", "พ.ค", "มิ.ย.", "ก.ค", "ส.ค.", "ก.ย", "ต.ค.", "พ.ย", "ธ.ค."]

const DAYS_LONG = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const DAYS_SHORT = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];

const now = new Date();
const DATE_INFO = { date: now.getDate(),
                    month: now.getMonth(),
                    today: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
                    year: now.getFullYear()} 

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */
const CalendarInput = (props) => {

    const { mode, periodOption } = props;
    const { dateInfo,
            selectedDate,
            getWeeks,
            previousMonth,
            nextMonth,
            nextYear,
            previousYear,
            longMonthName,
            renderDayHeader,
            renderWeek,
            renderMonth,
            renderYear
          } = useCalendarInput(props)
    const { month, year } = dateInfo;
    const renderFullClendar = props => {
        return (
          <div className={`react-daypicker-root ${mode !== 'PERIOD' ? 'margin-top-calendar' : ''}`}>
            <div className="header">
              <div className={`month-year ${mode === 'PERIOD' ? 'period-date' : ''}`}>
                <IconArrowLeft className="arrow" onClick={previousMonth} />
                {/* <img src={arrowLeft} className="arrow" onClick={previousMonth} /> */}
                <span className="header-month">{longMonthName(month)} {(year + 543)}</span>   
                <IconArrowRight className="arrow" onClick={nextMonth} />
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  {renderDayHeader(0)}
                  {renderDayHeader(1)}
                  {renderDayHeader(2)}
                  {renderDayHeader(3)}
                  {renderDayHeader(4)}
                  {renderDayHeader(5)}
                  {renderDayHeader(6)}
                </tr>
              </thead>
              <tbody>{getWeeks().map(renderWeek)}</tbody>
            </table>
          </div>
        );
    }

    const renderMonthCalendar = props => {
        return (
          <div className={`react-daypicker-root ${mode !== 'PERIOD' ? 'margin-top-calendar' : ''}`}>
            <div className="header">
              <div className="month-year">
                <IconArrowLeft className="arrow" onClick={previousYear} />
                <span className="header-month">{(year + 543)}</span>   
                <IconArrowRight className="arrow" onClick={nextYear} />
              </div>
            </div>
              {renderMonth()}
          </div>
        );
    }

    const renderYearCalendar = props => {
        return (
          <div className={`react-daypicker-root ${mode !== 'PERIOD' ? 'margin-top-calendar' : ''}`}>
            <div className="header">
              <div className="month-year">
                <IconArrowLeft className="arrow" onClick={previousYear} />
                <span className="header-month">{(Number(moment(selectedDate).format("YYYY")) + 543)}</span>   
                <IconArrowRight className="arrow" onClick={nextYear} />
              </div>
            </div>
              {renderYear()}
          </div>
        );
    }

    if(mode === "DAY" || mode === "WEEK") return renderFullClendar();
    if(mode === "MONTH") return renderMonthCalendar();
    if(mode === "YEAR") return renderYearCalendar();

    return renderFullClendar();

}

const useCalendarInput = (props) => {
    const periodOptions = { START: {key: 'start', value: 'start'} ,
                            END: {key: 'end', value: 'end'}               
                          }
    const {selectedDate : propSelectedDate, 
           mode = "DAY", 
           periodOption = periodOptions.START.value,
           periodDate={startDate: moment(),
                       endDate: moment()} } = props;
    const [dateInfo, setDateInfo] = useState(DATE_INFO);
    const [selectedDate, setSelectedDate] = useState('');
    const [yearPage, setYearPage] = useState('');

    useEffect(() => {
      if(!yearPage) setYearPage(getInitialYearPage());
    }, []);

    useEffect(() => {

        if(propSelectedDate) {
            const dateTime = new Date(propSelectedDate);
            setDateInfo({
                date: dateTime.getDate(),
                month: dateTime.getMonth(),
                today: new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()),
                year: dateTime.getFullYear(),
                selectedDate: props.selectedDate
            })
            setSelectedDate(propSelectedDate);
        }
        
    }, [propSelectedDate]);
  
    const getInitialYearPage = () => {
      const targetYear = Number(moment().format('YYYY'))+543;
      const tableYear = getYears(targetYear);
      return tableYear[0]
    }

    const getDays = () => {
        const { month, year } = dateInfo;
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const daysInMonthPrevious = new Date(year, month, 0).getDate();
        const days = [];
        const offset = new Date(year, month, 1).getDay();
    
        //date in previous month
        if (offset < 7) {
          for (let i = (offset - 1); i >= 0; i--) {
            //days.push(null);
            days.push(new Date(year, (month - 1), (daysInMonthPrevious - i)));
          }
        }
        // date in this month
        for (let i = 1; i <= daysInMonth; i++) {
          days.push(new Date(year, month, i));
        }
    
        // date in next month
        if (days.length % 7 != 0) {
          const mergin = 7 - (days.length % 7);
          for (let i = 1; i <= mergin; i++)
            days.push(new Date(year, (month + 1), i));
        }
    
        return days;
      }
    
    const getWeeks = () => {
        const days = getDays();
        const weeks = [];
        const weekCount = Math.ceil(days.length / 7);
        for (let i = 0; i < weekCount; i++) {
          weeks.push(days.slice(i * 7, (i + 1) * 7));
        }
        return weeks;
    }

    const getYears = (yearSelect) => {
      const yearSelected = yearSelect;
      let start = yearSelected;
      let count = 1;
      while(start % 10 !== 0) {
        start = yearSelected - count
        count++;
      }
      
      let collectionYear = [start];
      for(let i = 1; i <= 11; i++) 
          collectionYear = [...collectionYear, (start+i)]

      return collectionYear;
    }

    const longMonthName = (month) => {
      if (props.monthNames) {
        return props.monthNames[month];
      }

      return MONTHS[month];
    }

    const longDayName = (dayOfWeek) => {
      if (props.longDayNames) {
        return props.longDayNames[dayOfWeek];
      }

      return DAYS_LONG[dayOfWeek];
    }

    const shortDayName = (dayOfWeek) => {
      if (props.shortDayNames) {
        return props.shortDayNames[dayOfWeek];
      }

      return DAYS_SHORT[dayOfWeek];
    }

    const previousMonth = () => {
      const { month, year } = dateInfo;
    
      setDateInfo({ ...dateInfo,
        month: month !== 0 ? month - 1 : 11,
        year: month !== 0 ? year : year - 1
      });
    };
    
    const nextMonth = () => {
      const { month, year } = dateInfo;

      setDateInfo({  ...dateInfo,
        month: month !== 11 ? month + 1 : 0,
        year: month !== 11 ? year : year + 1
      });
    
    };

    const previousYear = () => {
      const { year } = dateInfo;
      let yearInfo = (year-1);

      if(props.mode === "YEAR") {
        yearInfo = (yearPage-543)-10;
        setYearPage(yearPage-10);
      }

      clearSelectedElem();
      setDateInfo({  ...dateInfo,
        year: yearInfo
      });
    };
    
    const nextYear = () => {
      const { year } = dateInfo;
      let yearInfo = (year+1);

      if(props.mode === "YEAR") {
        yearInfo = (yearPage-543)+10;
        setYearPage(yearPage+10);
      }

      clearSelectedElem();
      setDateInfo({  ...dateInfo,
        year: yearInfo
      });
    
    };
    
    const onDayClick = (day,rowIndex) => (event) => {
        // day mode
        if(mode === "DAY" || mode === "PERIOD") {
            if(!event.target.classList.contains('dissable-date')) {
              if (day && date_diff_indays(new Date(), new Date(day)) >= 0 || 
                  props.allowpreviousDate) { /// this allowpreviousDate control previousDate on/off
                    
                    
                    const elementSelected = document.getElementsByClassName('selected');
                    if (elementSelected) 
                    Array.from(elementSelected).forEach(item => item.classList.remove('selected'))
                    
                    event.target.classList.add("selected");
                    setSelectedDate(moment(day));
                    props.onDateClick(moment(day));
              }
              
              if (props.checkEndDate && date_diff_startEnd(props.checkEndDate, new Date(day)) <= 0) {
                    props.onDayClick(moment(props.checkEndDate).format("DD/MM/YYYY"));
                    if (selectedDate) 
                        event.target.classList.remove("selected")
              }
           
              if (props.checkStartDate && date_diff_startEnd(props.checkStartDate, new Date(day)) >= 0) {
                props.onDayClick(moment(props.checkStartDate).format("DD/MM/YYYY"));
                    if (selectedDate) {
                    event.target.classList.remove("selected")
                }
              }

            }
        } 
        if(mode === "WEEK") { 
            // week mode
            if(!event.target.classList.contains('dissable-date')) {
               
                const elementSelected = document.getElementsByClassName('week-selected');
                if (elementSelected) 
                    Array.from(elementSelected).forEach(item => item.classList.remove('week-selected'))

               let rowSelected = document.getElementById(`row-${rowIndex}`);
               rowSelected.classList.add('week-selected')
               
               setSelectedDate(moment(day));
               props.onDateClick(moment(day));
              //  setSelectedDate(getCurrentWeekDays(day));
              //  props.onDateClick(getCurrentWeekDays(day));
            }
        }
    }

    const onYearClick = (yearItem) => (event) => {
        if(mode === "YEAR") {
            if(!event.target.classList.contains('dissable-date')) {

              const elemYears = document.getElementsByClassName('date-label');
              if(elemYears && elemYears?.length > 0) {
                const tableYears = Array.from(elemYears).map(year => Number(year.innerText));
                setYearPage(tableYears[0]);
              }


                    clearSelectedElem();
                    event.target.classList.add("date-selected");

                    setSelectedDate(moment(new Date(`1/1/${yearItem}`)));
                    props.onDateClick(moment(new Date(`1/1/${yearItem}`)));
            }
        }
    }
    
    const onMonthClick = (index) => (event) => {
        if(mode === "MONTH") {
            if(!event.target.classList.contains('dissable-date')) {
                  clearSelectedElem();
                  event.target.classList.add("date-selected");

                  const { year } = dateInfo;
                  setSelectedDate(moment(new Date(`${index+1}/1/${year}`)));
                  props.onDateClick(moment(new Date(`${index+1}/1/${year}`)));
            }
        }
    }

    const clearSelectedElem = () => {
      const elementSelected = document.getElementsByClassName('date-selected');
      if (elementSelected) 
          Array.from(elementSelected)
               .forEach(item => item.classList.remove('date-selected'))
    }

    const getCurrentWeekDays = (day) => {
        const weekStart = moment(day).startOf('week');
      
        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, 'days'));
        }
      
        return days;
    }
    
    const date_diff_indays = (date1, date2) => {
        const dt1 = new Date(date1);
        const dt2 = new Date(date2);
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    }
    
    const date_diff_startEnd = (date1, date2) => {
      const dt1 = new Date(date1);
      const dt2 = new Date(date2);
      return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    }

    const isSameDay = (a, b) => {
        return (
          a &&
          b &&
          a.getFullYear() === b.getFullYear() &&
          a.getMonth() === b.getMonth() &&
          a.getDate() === b.getDate()
        );
    }

    const renderDay = (rowIndex) => {
        return function (day, index) {
            let { date, month, today, year } = dateInfo;
            let { selectedDate, allowpreviousDate, dissableDateFuture, dissableDatePast, allowTodayDate } = props; /// this allowpreviousDate control previousDate on/off
            let isToday = day && day.valueOf() === today.valueOf();
            const previousDate = date_diff_indays(new Date(), new Date(day)) < 0 ? ' otherMonth ' : '';
    
            const isFutureDateBlock = dissableDateFuture ? 
                                    date_diff_indays(new Date(dissableDateFuture), new Date(day)) > 0 : false;
            const isPastDateBlock = dissableDatePast ? 
                                    date_diff_indays(new Date(dissableDatePast), new Date(day)) < 0 : false;
                                    
            let dissableDate = isFutureDateBlock || isPastDateBlock ? ' otherMonth dissable-date ' : '';

    
            let selected="";
            let startDate="";
            let endDate="";
            let betweenDate="";
            
            const isDaySelectedIsToday = moment().isSame(moment(selectedDate), "day");
            const isTomorrowToday = moment(day).add(1, 'days').isSame(moment(), "day");
            const isRenderDayIsSelectedDay = moment(day).isSame(moment(selectedDate), "day");
            const isRenderDayIsToday = moment(day).isSame(moment(), "day");

                  // set focus date selected
                    if( selectedDate && 
                      mode === "DAY" 
                    ){ //  case subtract -1 day
                      if(allowTodayDate === false) {
                        if (isDaySelectedIsToday && isTomorrowToday) {
                            selected = "selected";
                        } else {
                          if(!isDaySelectedIsToday && isRenderDayIsSelectedDay)
                          selected = "selected";
                        }
                      } else { // normal case
                        if (isRenderDayIsSelectedDay) {
                          selected = "selected";
                        } 
                      }
                    }

                    // Do not allow to select Today 
                    if( selectedDate && 
                        mode === "DAY" &&
                        allowTodayDate === false &&
                        isRenderDayIsToday) {
                       dissableDate = ' otherMonth dissable-date ';
                    }

                    if( periodDate && 
                        mode === "PERIOD") {
                          selected="";
                          isToday=false;
                          const isStartDate = moment(day).isSame(periodDate.startDate, "day");
                          const isEndDate = moment(day).isSame(periodDate.endDate, "day");
                          const betweenDateRange = date_diff_indays(periodDate.startDate.toDate(), periodDate.endDate.toDate());
                          const diffDate = date_diff_indays(periodDate.startDate.toDate(), new Date(day));
                          const isBetweenDate =  diffDate >= 0 && diffDate <= betweenDateRange;
                          const isDatePrevious = diffDate < 0;

                          if(isStartDate) {
                            startDate = "start-date";
                          }
                          if(isEndDate) {
                            endDate = "end-date";
                          }
                          if(isBetweenDate){
                            betweenDate = "between-date";
                          }
                          if(periodOption === periodOptions.END.value && isDatePrevious) {
                            dissableDate = ' otherMonth dissable-date ';
                          }

                      }
            return (
            <td key={`${day}-${index}`}
                className={[startDate, endDate, betweenDate].filter(v => v).join(" ")}
            >
                <div className="day-container">
                <div className={`day`}> &nbsp; </div>
                <div className={["container",
                    !day ? "empty" : null,
                    isToday === true ? " today" : "",
                    allowpreviousDate ? "" : previousDate, /// this allowpreviousDate control previousDate on/off
                    dissableDate, 
                    selected
                ].filter(v => v).join(" ")}
                    key={`${year}.${month}.day.${index}`}
                    onClick={onDayClick(day,rowIndex)}
                    id={`${moment(day).format("MM_DD_YYYY")}`}>
                    {day ? moment(day).format("DD") : ""}
                </div>
                </div>
            </td>
            );
        }
    };

    const renderWeek = (days, index) => {
        const { month, year } = dateInfo;
        const rowIndex = index;

        let selected;
        days.forEach(day => {
               

            if(props.mode === "WEEK" &&
               moment(selectedDate).format("MM/DD/YYYY") === moment(day).format("MM/DD/YYYY")) {
                   selected = "week-selected";
               } 
            
        })

        return (
        <tr key={`${year}.${month}.week.${index}`}
            className={selected}
            id={`row-${index}`}>{
            days.map(renderDay(rowIndex))}</tr>
        );
    };

    const renderMonth = () => {
        
        const monthSelected = (index) => {
              const {year} = dateInfo;
              let selectClass = ""
              if(props.mode === "MONTH" &&
                moment(propSelectedDate).format("MM/YYYY") === 
                moment(new Date(`${index+1}/1/${year}`)).format("MM/YYYY")) {
                    selectClass = "date-selected";
                }
              return selectClass
        }

        const checkFutureMonth = (index)  => {
          const {year} = dateInfo;
          const currentMonth = moment();
          const tagetMonth = moment(new Date(`${index+1}/1/${year}`));
          return currentMonth < tagetMonth ? " otherMonth dissable-date " : "";
        }

        return <div className="date-container">
                  { MONTHS_SHORT.map((month, index) => {
                      const isMonthSelect = monthSelected(index);
                      const isFutureMonth = props.dissableDateFuture ? checkFutureMonth(index) : "";
                      return <div key={`month.${index}`} className="date-column" >
                                  <div className="date-item-container">
                                      <div className={`date-item ${isMonthSelect} `} 
                                          id={`month-${index}`} 
                                          onClick={onMonthClick(index)}>
                                          <div className={`date-label ${isFutureMonth}`}>{month}</div> 
                                      </div>
                                  </div> 
                            </div>
                      })
                  }
              </div> 
    }

    const renderYear= () => {

      const {year} = dateInfo;
      let yearCalculate = year+543;

      // fix issue render new page when select element 11, 12 of table
      if(yearPage) {
        const isYearExistTable = getYears(yearPage).find(yearItem => yearItem === (year+543));
        if(isYearExistTable)
        yearCalculate = yearPage;
      }

      let years = getYears(yearCalculate);
      years = years.map(year => year-543)

      const yearSelected = (yearItem) => {
          let selectClass = ""
          if(props.mode === "YEAR" &&
            moment(selectedDate).format("YYYY") === 
            moment(new Date(`1/1/${yearItem}`)).format("YYYY")) {
              selectClass = "date-selected";
            }
          return selectClass
      }

      const checkFutureMonth = (yearItem) => {
          const currentYear = moment();
          const targetYear = moment(new Date(`1/1/${yearItem}`));
          return currentYear < targetYear ? " otherMonth dissable-date " : "";
      } 
    

        return <div className="date-container">
                  { years && years.map((yearItem, index) => {
                      const isYearSelected = yearSelected(yearItem);
                      const isYearFuture = props.dissableDateFuture ? checkFutureMonth(yearItem) : "";
                      return <div key={`yearItem.${index}`} className="date-column" >
                                  <div className="date-item-container">
                                      <div className={`date-item ${isYearSelected}`} 
                                          id={`year-${yearItem}`} 
                                          onClick={onYearClick(yearItem)}>
                                          <div className={`date-label ${isYearFuture} `}>{(yearItem+543)}</div> 
                                      </div>
                                  </div> 
                            </div>
                      })
                  }
              </div> 
    }

  const renderDayHeader = (dayOfWeek, index) => {
    return (
      <th scope="col day-header" key={`${dayOfWeek}_${index}`}>
        <span title={longDayName(dayOfWeek)} className='header-day'>
          {shortDayName(dayOfWeek)}
        </span>
      </th>
    );
  }

  return {  dateInfo,
            selectedDate,
            getWeeks,
            previousMonth,
            nextMonth,
            nextYear,
            previousYear,
            longMonthName,
            renderDayHeader,
            renderWeek,
            renderMonth,
            renderYear
         }

}

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default CalendarInput;