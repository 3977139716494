/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // Button, 
  Modal, ModalHeader, ModalBody,
  //  ModalFooter 
} from "reactstrap";
import IconClose from "../../images/icons/close.svg";
// import IconAvatar from '../../images/business-woman-profile-1.png';
import IconTrue from '../../images/icons/true.svg'
import IconShopAvatar from '../../images/icons/default-shop-logo.svg';
import { ReactComponent as IconPrint } from "../../images/icons/printer-orange.svg";
import { ReactComponent as IconDigitalProductLogo } from "../../images/icons/digitalProductLogo.svg";
import "./index.scss";
import host from '../../config/host';
import numeral from 'numeral';
import { displayDateTh } from '../../helper/timeUtil';
import {
  dispatchOrder, OPEN_MODAL_INVOICE_DETAIL,
  UPDATE_INVOICE_LOGISTIC, UPDATE_INVOICE_SHOP_NOTE, SET_COMPLETE_LOGISTIC,
  // SET_UPDATE_SHOP_NOTE_RES, SET_UPDATE_LOGISTIC_RES, 
  SET_MODAL_REFUND_REASON,
  ON_CONFIRM_REFUND, SET_CLEARING_ORDER, OPEN_MODAL_CONFIRM_REFUND, CLOSE_MODAL_CONFIRM_REFUND, CLOSE_MODAL_CONFIRM_CLEARING,
  OPEN_MODAL_CONFIRM_CLEARING,
  CLOSE_MODAL_CONFIRM_LARGE,
  OPEN_MODAL_CONFIRM_LARGE,
  HANDLE_SET_REDELIVERY,
  OPEN_MODAL_CHILDREN,
  CLOSE_MODAL_CHILDREN,
  OPEN_MODAL_COVER_SHEET_PRINT,
  CONFIRM_ORDER,
  SET_STATUS_VOID_REFUND
} from '../../redux/actions/order';

import TooltipNotification from '../TooltipNotification';
import classNames from 'classnames'
import ModalRefundReason from '../modalRefundReason'
import ModalMessage from "../modalMessage";
// import modalMessage from "../../redux/reducers/modalMessage";
import { CLOSE_MODAL_MESSAGE } from "../../redux/actions/modalMessage";
import ModalConfirm from '../modalConfirm/ModalConfirm'
import {
  dispatchModalConfirm,
  // OPEN_MODAL_CONFIRM 
} from "../../redux/actions/modalConfirm";
import Loading from "../Loading";


import { getUserRole } from '../../helper/utils';
import { ACCOUNTANT, ADMIN, OWNER, SUPER_ADMIN } from '../../layout/Sidebar/Role';
import DatePickerInput from 'components/DatePickerInput'
import TimePicker from 'components/Timepicker'
import moment from 'moment'
import { differenceDate } from '../../helper/timeUtil'
// import RadioComponent from "components/DropDownList";
import DropdownIcon from "components/DropdownIcon";
// import productProvider from "provider/productProvider";
import DropdownButton from "components/DropdownButton";
// import { getUser } from "redux/sagas/selectors";
import ModalConfirmLarge from "components/ModalConfirmLarge";
import ModalLargeChildren from "components/ModalLargeChildren";
import { ReactComponent as IconSelect } from 'images/icons/select-circle-1.svg'
import { Body1, Body2, Caption, H6, SubCaption, SubTitle2 } from "components/FontStyle";
import { dispatchModalAlert, OPEN_MODAL_ALERT } from "redux/actions/modalAlert";
import { dispatchApp, SET_LOADING } from "redux/actions/app";
import True from '../../images/icons/true.svg'

import { MediaCard } from 'components/output'
import { InputGroup, InputGroupAddon, Input } from 'reactstrap'
import { TextInput, Textarea, FloatInput } from 'components/input'
import { Radio } from 'antd'
import RadioButton from "components/RadioButton";

const PaymentMethod = {
  CREDIT_CARD: { key: "creditcard", value: "บัตรเครดิต" },
  I_BANKING: { key: "ibanking", value: "อินเตอร์เน็ตแบงก์กิ้ง" },
  COD: { key: "cod", value: "เก็บเงินปลายทาง" },
  TRANSFER: { key: "transfer", value: "โอนผ่านธนาคาร" },
  QR_CODE: { key: "qrcode", value: "QR Code" },
}

const PaymentStatus = {
  WAIT: { key: "wait", value: "รอชำระเงิน" },
  TRANSFER: { key: "transfer", value: "รอตรวจสอบ" },
  APPROVED: { key: "approved", value: "ตรวจสอบแล้ว" },
  PAID: { key: "paid", value: "ชำระเงินแล้ว" },
  FAILED: { key: "failed", value: "ยกเลิก" }
}

const LogisticStatus = {
  WAIT: { key: "wait", value: "รอรับออเดอร์" },
  PREPARE: { key: 'prepare', value: "รอจัดส่ง" },
  POSTING: { key: "posting", value: "อยู่ระหว่างขนส่ง" },
  INTRANSIT: { key: "intransit", value: "อยู่ระหว่างขนส่ง" },
  REDELIVERY: { key: "redelivery", value: "จัดส่งใหม่" },
  DELIVERED: { key: "delivered", value: "สำเร็จ" },
  OUTDELIVERY: { key: "outdelivery", value: "อยู่ระหว่างขนส่ง" },
  ISSUES: { key: "issues", value: "เกิดปัญหา" },
  RETURN: { key: "return", value: "คืนของ" }
}

const OrderStatus = {
  WAIT_CONFIRM: 'waitConfirm',
  WAIT_SHIPPING: 'waitShipping',
  ON_THE_WAY: 'onTheWay',
  SUCCESS: 'success',
  WAIT_REFUND: 'waitRefund',
  REFUNDED: 'refunded',
  DELIVERY_DELAYED: 'deliveryDelayed',
  REFUND_APPROVED: 'refundApproved'

}

let orderStatusChange = [
  {
    value: 'wait',
    name: 'เปลี่ยนสถานะเป็นรอจัดส่ง',
  },
  {
    value: 'success',
    name: 'เปลี่ยนสถานะเป็นจัดส่งสำเร็จ',
  },
]
let refundStatus = {
  returnrefund: { value: "returnrefund", label: "คืนเงิน คืนสินค้า" },
  refund: { value: "refund", label: "คืนเงิน ไม่คืนสินค้า" },
  reject: { value: "reject", label: "ปฏิเสธคำขอ" },
}

const renderOption = (params) => {
  const { key, value, col = 6, cols, options, disabled, fontSize = 14, disabledItems, onInputValueChange } = params;
  /** Params
   * key(string) : 
   * value(string,number)
   * col(number)
   * cols(array(number))
   * optios(object) 
   * fontSize(number)
   * disabledItems(array(number))
   */
  return (
    <div className="option-container">
      {Object.keys(options).map((keyItem, index) => {
        const isDisabled = disabledItems ? disabledItems.find(item => item === index) : false;
        const disabledItem = isDisabled != undefined ? true : disabled;
        let column = col;
        if (cols) column = cols[index];

        return <div key={keyItem} className={`option-item`}>
          <RadioButton
            value={options[keyItem]?.value}
            className="mr-4"
            valueOption={value}
            name={key}
            disabled={disabledItem}
            onChange={() =>
              onInputValueChange(options[keyItem].value)
            }
          />
          <label className="form-check-label mr-4" style={{ fontSize: fontSize }}>
            {options[keyItem]?.label}
          </label>
          <br />
          {options[keyItem]?.description &&
            <label className="label-description">
              {options[keyItem]?.description}
            </label>
          }

        </div>
      }
      )}
    </div>
  );
};

class ModalInvoiceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      shopNote: '',
      trackingNo: '',
      confirmRefund: false,
      modalConfirmClearing: false,
      currentModal: '',
      releaseDate: {
        date: null,
        time: null
      },
      updateSecondTracking: false,
      deliveries: [],
      logisticRedelivery: null,
      refundStatus: 'refund',
      note: '',
      refundType: 'void'
    };
  }

  componentDidMount() {
    var time = moment().utcOffset(0)
    time.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    this.setState({
      releaseDate: {
        date: moment().format('MM/DD/YYYY'),
        time: time
      }
    })
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.order?.status !== this.props.order?.status) {
      if (nextProps.order.status === "onTheWay" || nextProps.order.status === "onTheWayDelay") {
        orderStatusChange = [
          {
            value: 'wait',
            name: 'เปลี่ยนสถานะเป็นรอจัดส่ง',
          },
          {
            value: 'success',
            name: 'เปลี่ยนสถานะเป็นจัดส่งสำเร็จ',
          },
        ]
      }
      if (nextProps.order.status === "waitShipping") {
        orderStatusChange = [{
          value: 'success',
          name: 'เปลี่ยนสถานะเป็นจัดส่งสำเร็จ',
        }]
      }
      if (nextProps.order.status === "success") {
        orderStatusChange = [{
          value: 'wait',
          name: 'เปลี่ยนสถานะเป็นรอจัดส่ง',
        }]
      }
    }


    if (nextProps.modalInvoiceDetail !== this.props.modalInvoiceDetail) {
      let logisticDefault = nextProps.modalInvoiceDetail?.deliveries && nextProps.modalInvoiceDetail?.logistics?.filter(
        (logistic) =>
          logistic?.name === nextProps.modalInvoiceDetail?.deliveries[nextProps.modalInvoiceDetail?.deliveries?.length - 1].logistic)
      if (logisticDefault == undefined || logisticDefault.length <= 0) {
        logisticDefault = nextProps.modalInvoiceDetail?.logistics && [{ ...nextProps.modalInvoiceDetail?.logistics[0] }]
      }
      this.setState({
        trackingNo: nextProps.modalInvoiceDetail?.currentInvoice?.delivery?.trackingNo || '',
        shopNote: nextProps.modalInvoiceDetail?.currentInvoice?.shopNote,
        deliveries: nextProps.modalInvoiceDetail?.deliveries,
        logisticRedelivery: logisticDefault && logisticDefault[0]?.value
      })
      // if (nextProps.order.status === "onTheWay") {
      //   if (nextProps.modalInvoiceDetail?.deliveries && nextProps.modalInvoiceDetail?.deliveries[nextProps.modalInvoiceDetail?.deliveries.length - 1].logistic === "EMS") {
      //     delete orderStatusChange[1]
      //   } 
      //   else {
      //     orderStatusChange = [
      //       {
      //         value: 'wait',
      //         name: 'เปลี่ยนสถานะเป็นรอจัดส่ง',
      //       },
      //       {
      //         value: 'success',
      //         name: 'เปลี่ยนสถานะเป็นจัดส่งสำเร็จ',
      //       },
      //     ]
      //   }
      // }

    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalInvoiceDetail.isOpen !== this.props.modalInvoiceDetail.isOpen) {
      if (prevProps.modalInvoiceDetail.isOpen) {
        this.clearData()
      }
    }
  }

  clearData = () => {
    this.setState({
      modal: false,
      shopNote: '',
      trackingNo: '',
      confirmRefund: false,
      modalConfirmClearing: false,
      currentModal: '',
      // releaseDate: {
      //   date: null,
      //   time: null
      // },
      updateSecondTracking: false,
      deliveries: [],
      logisticRedelivery: null,
      refundStatus: 'refund',
      note: '',
      refundType: 'void'
    })
  }

  onchangeRefundStatus = (v) => {
    this.setState({ refundStatus: v })
  }

  onChangeNote = (v) => {
    this.setState({ note: v });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeLogisticTracking = (e) => {
    let deliveries = this.state.deliveries
    deliveries[deliveries.length - 1] = {
      ...deliveries[deliveries.length - 1],
      trackingNo: e.target.value
    }
    this.setState({ deliveries })
  }

  toggleModal = () => {
    this.props.dispatch(dispatchOrder({ type: OPEN_MODAL_INVOICE_DETAIL, payload: { currentInvoice: null, isOpen: false } }));
    this.setState({ updateSecondTracking: false })
  }

  checkPaymentStatus = (status) => {
    // eslint-disable-next-line default-case
    switch (status) {
      case PaymentStatus.WAIT.key: return <SubCaption className="badge badge-gray">{PaymentStatus.WAIT.value}</SubCaption>
      case PaymentStatus.TRANSFER.key: return <SubCaption className="badge wait-approve">{PaymentStatus.TRANSFER.value}</SubCaption>
      case PaymentStatus.APPROVED.key: return <SubCaption className="badge badge-green"> <img src={IconTrue} className="mr-2" />{PaymentStatus.APPROVED.value}</SubCaption>
      case PaymentStatus.PAID.key: return <SubCaption className="badge badge-green"> <img src={IconTrue} className="mr-2" />{PaymentStatus.PAID.value}</SubCaption>
      case PaymentStatus.FAILED.key: return <SubCaption className="badge badge-red">{PaymentStatus.FAILED.value}</SubCaption>
    }
  }

  checkLogisticStatus = (status, shipmentStatus) => {
    if (shipmentStatus === "wait" || shipmentStatus === "prepare") {
      // eslint-disable-next-line no-sequences
      switch (status, shipmentStatus) {
        case (LogisticStatus.WAIT.key, 'waitconfirm'): return <SubCaption className="badge badge-gray">{LogisticStatus.WAIT.value}</SubCaption>
        case (LogisticStatus.WAIT.key, 'prepare'): return <SubCaption className="badge wait-approve">{LogisticStatus.PREPARE.value}</SubCaption>
        default: return null
      }
    } else {
      // eslint-disable-next-line default-case
      switch (status) {
        case (LogisticStatus.WAIT.key): return <SubCaption className="badge badge-gray">{LogisticStatus.WAIT.value}</SubCaption>
        case LogisticStatus.POSTING.key: return <SubCaption className="badge wait-approve"> {LogisticStatus.POSTING.value}</SubCaption>
        case LogisticStatus.INTRANSIT.key: return <SubCaption className="badge badge-green"> <img src={IconTrue} className="mr-2" />{LogisticStatus.INTRANSIT.value}</SubCaption>
        case LogisticStatus.REDELIVERY.key: return <SubCaption className="badge wait-approve">{LogisticStatus.REDELIVERY.value}</SubCaption>
        case LogisticStatus.DELIVERED.key: return <SubCaption className="badge badge-green"> <img src={IconTrue} className="mr-2" />{LogisticStatus.DELIVERED.value}</SubCaption>
        case LogisticStatus.OUTDELIVERY.key: return <SubCaption className="badge wait-approve">{LogisticStatus.OUTDELIVERY.value}</SubCaption>
        case LogisticStatus.ISSUES.key: return <SubCaption className="badge badge-red">{LogisticStatus.ISSUES.value}</SubCaption>
        case LogisticStatus.RETURN.key: return <SubCaption className="badge badge-red">{LogisticStatus.RETURN.value}</SubCaption>
      }
    }
  }

  getDateByPaymentStatus = () => {
    const { deliveryDate } = this.props.modalInvoiceDetail?.currentInvoice || { deliveryDate: '' };
    const { deliveredAt } = this.props.modalInvoiceDetail?.currentInvoice || { deliveredAt: '' };
    const { refundedAt } = this.props.modalInvoiceDetail?.currentInvoice?.payment || { refundedAt: '' };
    const status = this.props?.orderStatus;
    const { currentInvoice } = this.props.order.modalInvoiceDetail

    // eslint-disable-next-line default-case
    switch (status) {
      case OrderStatus.WAIT_CONFIRM: return <H6 className="payment-date pr-5">กรุณาจัดส่งภายในวันที่ {displayDateTh(deliveryDate, 'DD MMM yyyy')} </H6>
      case OrderStatus.WAIT_SHIPPING: return <H6 className="payment-date pr-5">กรุณาจัดส่งภายในวันที่ {displayDateTh(deliveryDate, 'DD MMM yyyy')}</H6>
      case OrderStatus.ON_THE_WAY: return <H6 className="payment-date pr-5">กรุณาจัดส่งภายในวันที่ {displayDateTh(deliveryDate, 'DD MMM yyyy')}</H6>
      case OrderStatus.SUCCESS: return <H6 className="payment-date pr-5"> จัดส่งสำเร็จวันที่ {displayDateTh(deliveredAt, 'DD MMM yyyy')}  </H6>
      case OrderStatus.WAIT_REFUND: return <H6 className="payment-date pr-5"> <span style={{ color: '#ED1072' }}> สถานะ รอการคืนเงิน</span> </H6>
      case OrderStatus.REFUND_APPROVED: return <H6 className="payment-date pr-5"> <span style={{ color: '#ED1072' }}> สถานะ {currentInvoice?.refundStatus === 'return' ? 'รอคืนสินค้า' : 'กำลังดำเนินการคืนเงิน'} </span> </H6>
      case OrderStatus.REFUNDED: return <H6 className="payment-date pr-5">คืนเงินวันที่ {displayDateTh(refundedAt, 'DD MMM yyyy')}</H6>
      case OrderStatus.DELIVERY_DELAYED: return <H6 className="payment-date pr-5">กรุณาจัดส่งภายในวันที่ {displayDateTh(deliveryDate, 'DD MMM yyyy')} <span style={{ color: '#D10808' }}> เกินเวลาจัดส่งมา {differenceDate(new Date(), deliveryDate)} วัน</span> </H6>
    }
  }

  checkPaymentMethod = (method) => {
    // eslint-disable-next-line default-case
    switch (method) {
      case PaymentMethod.CREDIT_CARD.key: return PaymentMethod.CREDIT_CARD.value;
      case PaymentMethod.I_BANKING.key: return PaymentMethod.I_BANKING.value;
      case PaymentMethod.COD.key: return PaymentMethod.COD.value;
      case PaymentMethod.TRANSFER.key: return PaymentMethod.TRANSFER.value;
      case PaymentMethod.QR_CODE.key: return PaymentMethod.QR_CODE.value;
    }
  }

  updateLogistic = () => {
    // const deliveryId = this.props.modalInvoiceDetail?.currentInvoice?.delivery?.id || 0;
    const { logisticRedelivery, deliveries } = this.state
    const { shipmentStatus } = this.props.modalInvoiceDetail.currentInvoice
    const deliveryId = deliveries[deliveries.length - 1].id || 0;
    const trackingNo = deliveries[deliveries.length - 1].trackingNo
    const logisticId = (logisticRedelivery !== null) && (logisticRedelivery.id || deliveries[deliveries.length - 1].logisticId)
    const logistic = (logisticRedelivery !== null) && ((logisticRedelivery.logistic || logisticRedelivery.name) || (deliveries[deliveries.length - 1].logistic || deliveries[deliveries.length - 1].name))
    let trackingRemoveSpace = trackingNo.replace(/\s/g, '');
    if (trackingRemoveSpace.trim().length < 10) {
      this.props.dispatch(dispatchModalAlert({
        type: OPEN_MODAL_ALERT, payload: {
          isOpen: true,
          type: 'alert',
          message: 'เลข tracking ไม่ถูกต้อง',
          confirm: () => {
            this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CHILDREN }));
            this.props.dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }));
            this.props.dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: false, message: '', type: null } }))
          }
        }
      }))
      this.props.dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
      return
    }
    if (shipmentStatus === "waitconfirm") {
      this.handleConfirmOrder()
    }
    this.props.dispatch(dispatchOrder({ type: UPDATE_INVOICE_LOGISTIC, payload: { deliveryId, trackingNo: trackingRemoveSpace, logisticId, logistic } }));
    this.setState({ updateSecondTracking: false })
  }

  onClickCompleteLogistic = () => {

    const isDirty = () => {
      return this.state.trackingNo !== this.state.deliveries[this.state.deliveries.length - 1].trackingNo
    }
    const dateString = moment(this.state.releaseDate.date).format('YYYY-MM-DD')
    const timeString = moment(this.state.releaseDate.time).format('HH:mm')
    const releaseDate = `${dateString}T${timeString}:00.000+07:00`
    // this.props.dispatch(dispatchOrder({ type: SET_COMPLETE_LOGISTIC, payload: { detail: this.props.modalInvoiceDetail.currentInvoice, dateString, timeString, releaseDate } })); ###old delivery

    if (isDirty()) {
      this.props.dispatch(dispatchModalAlert({
        type: OPEN_MODAL_ALERT, payload: {
          isOpen: true,
          type: 'alert',
          message: 'กรุณาบันทึกเลข tracking ก่อนเปลี่ยนสถานะ',
          confirm: () => {
            this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CHILDREN }));
            this.props.dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: false, message: '', type: null } }))
          }
        }
      }))
    } else {
      this.props.dispatch(dispatchOrder({ type: SET_COMPLETE_LOGISTIC, payload: { detail: this.props.modalInvoiceDetail.deliveries, dateString, timeString, releaseDate } }));
      this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CHILDREN }))
    }

  }

  updateShopNote = () => {
    const invoiceId = this.props.modalInvoiceDetail?.currentInvoice?.id || 0;
    const shopNote = this.state.shopNote;
    this.props.dispatch(dispatchOrder({ type: UPDATE_INVOICE_SHOP_NOTE, payload: { invoiceId, shopNote } }));
  }

  onRefund = () => {
    this.props.dispatch(dispatchOrder({ type: SET_MODAL_REFUND_REASON, payload: true }))
  }

  onConfirmRefund = (id, currentInvoice) => {
    const data = {
      id,
      currentInvoice,
      priceRefund: currentInvoice.priceTotal,
      note: this.state.note,
      issueNo: currentInvoice?.issue[0]?.issueNo,
      solveType: this.state.refundStatus
    }
    if (this.state.refundStatus == "reject") {
      delete data.priceRefund
    }
    this.setState({ confirmRefund: true },
      () => this.props.dispatch(dispatchOrder({ type: ON_CONFIRM_REFUND, payload: data }))
    )
  }

  onCancelRefund = (id, currentInvoice) => {
    //   const data = {
    //     note:payload.note,
    //     issueNo: payload.issueNo,
    //     solveType:"reject"
    // }
    this.setState({ confirmRefund: false },
      () => this.props.dispatch(dispatchOrder({ type: ON_CONFIRM_REFUND, payload: { status: this.state.confirmRefund, id, currentInvoice: currentInvoice } }))
    )
  }

  openModalConfirm = (status) => {
    const tabStatus = this.props.order?.modalInvoiceDetail?.currentInvoice?.refundStatus

    this.setState({ confirmRefund: status }, () => this.props.dispatch(dispatchModalConfirm({
      type: OPEN_MODAL_CONFIRM_REFUND, payload: {
        isOpen: true,
        // tab request
        message: status && tabStatus == 'request' ? 'อนุมัติคำขอยกเลิก/คืนเงิน' : !status && tabStatus == 'request' ? 'ปฏิเสธคำขอยกเลิก/คืนเงิน' :

          // tab approve
          status && tabStatus == 'approved' ? 'แจ้งโอนเงินคืนให้ลูกค้า' : '',

        btnConfirm: 'ยืนยัน',
        btnCancel: 'ยกเลิก'
      }
    })))
  }

  handleClearingOrder = (id) => {
    this.props.dispatch(dispatchOrder({ type: SET_CLEARING_ORDER, payload: { id, search: this.props.search, filter: this.props.filter } }))
    this.setState({ modalConfirmClearing: false })
  }

  openModalClearing = () => {
    this.props.dispatch(dispatchOrder({
      type: OPEN_MODAL_CONFIRM_CLEARING, payload: {
        isOpen: true,
        message: 'กรุณายืนยันว่าโอนเงินให้ร้านค้าเรียบร้อยแล้ว',
        btnConfirm: 'ยืนยัน',
        btnCancel: 'ยกเลิก'
      }
    }))
  }

  closeModalClearing = () => {
    this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CONFIRM_CLEARING }))
  }

  onDateDatePickerInputChange = (date) => {
    this.setState({ releaseDate: { ...this.state.releaseDate, date } })
  }

  onTimePickerChange = (time) => {
    const _time = moment(time)
    if (!_time.isValid()) {
      let timeTmp = moment().utcOffset(0)
      timeTmp.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      this.setState({
        releaseDate: { ...this.state.releaseDate, time: timeTmp },
      })
    } else {
      this.setState({ releaseDate: { ...this.state.releaseDate, time } })
    }
  }

  onOpenModalPrintCoverSheet = () => {
    this.toggleModal();
    this.props.dispatch(dispatchOrder({
      type: OPEN_MODAL_COVER_SHEET_PRINT,
      payload: {
        currentInvoice: this.props.modalInvoiceDetail.currentInvoice,
        isOpen: true
      }
    }));
  }

  handleSelectStatusLogistic = (value) => {
    if (value === 'wait') {
      this.props.dispatch(dispatchOrder({
        type: OPEN_MODAL_CONFIRM_LARGE, payload: {
          isOpen: true,
          message: <div>คุณแน่ใจหรือไม่ที่จะเปลี่ยนสถานะเป็น <div>“รอจัดส่ง”</div></div>,
          headerMsg: this.props.modalInvoiceDetail.currentInvoice.orderNo,
          btnConfirm: 'ยืนยัน',
          btnCancel: 'ยกเลิก',
          data: { status: value }
        }
      }))
    } else if (value === 'success') {
      this.props.dispatch(dispatchOrder({
        type: OPEN_MODAL_CHILDREN, payload: {
          isOpen: true,
          headerMsg: 'เปลี่ยนสถานะเป็นจัดส่งสำเร็จ',
          btnConfirm: 'ตกลง,',
          btnCancel: 'ยกเลิก',
          data: { status: value }
        }
      }))
    }
  }

  handleRedelivery = () => {
    this.props.dispatch(dispatchOrder({
      type: HANDLE_SET_REDELIVERY, payload: {
        orderId: this.props.modalInvoiceDetail.currentInvoice.id,
        logisticId: this.props.modalInvoiceDetail.deliveries[0].logisticId
      }
    }))
  }

  handleSelectLogisticRedelivery = (value) => {
    this.setState({
      logisticRedelivery: value
    })
  }

  handleConfirmOrder = () => {
    const orderId = this.props.modalInvoiceDetail.currentInvoice.id
    this.props.dispatch(dispatchOrder({ type: CONFIRM_ORDER, payload: { orderId } }));
  }


  handleSetStatusVoidRefund = ({orderId, issueNo,refundType}) => {
      this.props.dispatch(dispatchOrder({ type: SET_STATUS_VOID_REFUND ,payload: {
        orderId,
        issueNo,
        refundType
      }}))
  }

  render() {
    const {
      isOpen,
      currentInvoice
    } = this.props.modalInvoiceDetail;
    const {
      orderNo,
      priceNet,
      priceTotal,
      paymentStatus,
      logisticStatus,
      // expirePaymentDate,
      paymentMethod,
      createdAt,
      updatedAt,
      shop,
      customerInformation,
      // delivery,
      paymentedAt,
      id,
      issueNote,
      // note,
      payment,
      priceShippingNet = 0,
      // promotionCode = "",
      priceVoucherDiscount = 0,
      priceShippingDiscount = 0,
      // priceItemDiscount = 0,
      shipmentStatus,
      customerTaxInformation,
    } = currentInvoice || {};
    const {
      logoPath,
      name
    } = shop || {};
    const {
      mobile,
      address,
      province,
      subdistrict,
      district,
      zipcode,
      email
    } = customerInformation || {};
    const {
      shopNote,
      trackingNo,
      releaseDate,
      // trackingNo2,
      updateSecondTracking,
      deliveries,
      logisticRedelivery,
      refundType
    } = this.state;
    const {
      order,
      modalMessage,
      modalConfirmRefund,
      modalConfirmClearing,
      modalInvoiceDetail,
      modalConfirmLarge,
      modalLargeChildren
    } = this.props
    const issue = currentInvoice ? currentInvoice?.issue : [];
    const { time } = releaseDate;
    const totalShippingPrice = priceShippingNet - priceShippingDiscount;
    const priceItemDiscountShop = currentInvoice?.discount?.priceItemDiscountShop || 0
    const priceItemDiscount = currentInvoice?.discount?.priceItemDiscount || 0
    const totalPromotionDiscountPrice = priceNet - (priceItemDiscountShop + priceItemDiscount);
    const totalBeforeDiscountVoucher = currentInvoice?.items[0].product?.productType === "digital" ? totalPromotionDiscountPrice : totalPromotionDiscountPrice + totalShippingPrice;
    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          backdrop='static'
          style={{ width: '80%', maxWidth: '80%' }}
        >
          <ModalHeader toggle={this.toggle}>
            <div className="d-flex justify-content-between w-100">
              <div>
                <H6 className='header-lable'> หมายเลขออเดอร์ </H6>
                <H6 className='header-invoice-no'>{orderNo}</H6>
              </div>
              {this.getDateByPaymentStatus(logisticStatus)}
            </div>
            <div className='close-btn-wrap'>
              <img src={IconClose} onClick={this.toggleModal} alt='close' />
            </div>
          </ModalHeader>
          <ModalBody>
            <div id='modal-invoice-detail' className='container'>
              <div className='row'>
                {/* SECTION - รายการสั่งซื้อ */}
                <div className='col-8'>
                  <div className='card-container mb-0 pb-20'>
                    <ul className='d-flex justify-content-between'>
                      <li>
                        <H6>รายการสั่งซื้อ</H6>
                        <div className='shop-profile'>
                          <Caption className='mr-2'>ร้านค้า : </Caption>
                          {logoPath ? (
                            <img
                              src={`${host.avatar}${logoPath?.path}`}
                              className='shop-profile-image'
                              alt='logo'
                            />
                          ) : (
                            <img
                              src={IconShopAvatar}
                              className='shop-profile-image'
                              alt='shop-logo'
                            />
                          )}
                          {/* <img src={IconAvatar} className="shop-profile-image"></img> */}
                          <Caption> {name}</Caption>
                        </div>
                      </li>
                      <li className='text-right'>
                        <div>
                          <Caption>
                            ยืนยันคำสั่งซื้อเมื่อ :{' '}
                            {displayDateTh(createdAt, 'DD MMM yyyy')}
                          </Caption>
                        </div>
                        <div>
                          <Caption>
                            อัปเดทล่าสุด :{' '}
                            {displayDateTh(updatedAt, 'DD MMM yyyy')}
                          </Caption>
                        </div>
                      </li>
                    </ul>

                    <div className='product-table'>
                      <div className='row header'>
                        <Body1 className='col-1 text-center'>ลำดับ</Body1>
                        <Body1 className='col-6 text-left'>สินค้า</Body1>
                        <Body1 className='col-2 text-center'>จำนวน</Body1>
                        <Body1 className='col-3 text-right'>ราคา</Body1>
                      </div>

                      {currentInvoice?.items &&
                        currentInvoice?.items.map((item, itemIndex) => {
                          const {
                            quantity,
                            product,
                            sku,
                            productSKU,
                            priceNet,
                          } = item || {}

                          return (
                            <React.Fragment key={`item-${itemIndex}`}>
                              <div className='row product'>
                                <div className='col-1 align--center'>
                                  {itemIndex + 1}
                                </div>
                                <div className='col-6'>
                                  <div className='product-detail'>
                                    <div className='product-detail-image'>
                                      <img
                                        src={`${host.avatar}${productSKU?.imagePath.path}`}
                                        alt='sku-logo'
                                      ></img>
                                    </div>
                                    <div className='product-detail-name'>
                                      <ul>
                                        <li>
                                          <SubTitle2>
                                            {product.name}
                                          </SubTitle2>
                                        </li>
                                        <li>
                                          <span className='sku'>{sku}</span>
                                        </li>
                                        <li>
                                          <span className='sku'>เลขอ้างอิง SKU: {productSKU?.sellerSKU || '-'}</span>
                                        </li>
                                        <li>
                                          <span className='sku'>รหัสบาร์โค้ด: {productSKU?.barcode || '-'}</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-2 align--center'>
                                  {quantity}
                                </div>
                                <div className='col-3 align--center align--text-right'>
                                  ฿ {numeral(priceNet).format('0,0.00')}{' '}
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        })}

                      <div className='row total body-2'>
                        <div className='offset-6 col-6'>
                          <div className='total-detail'>

                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>ราคาต่อหน่วยรวม</li>
                              <li className='text-right'>
                                ฿ {numeral(priceNet).format('0,0.00')}
                              </li>
                            </ul>

                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>- คูปองส่วนลด V AVENUE</li>
                              <li className='text-right'>
                                ฿ {numeral(0 - currentInvoice?.discount?.priceItemDiscount).format('0,0.00')}
                              </li>
                            </ul>

                            {/* {currentInvoice?.discount?.priceItemDiscountShop && currentInvoice?.discount?.priceItemDiscountShop > 0 && */}
                            <React.Fragment>
                              <ul className='d-flex justify-content-between'>
                                <li className='text-left'>- คูปองส่วนลดร้านค้า</li>
                                <li className='text-right'>
                                  ฿ {numeral(0 - currentInvoice?.discount?.priceItemDiscountShop).format('0,0.00')}
                                </li>
                              </ul>
                            </React.Fragment>
                            {/* } */}


                            {/* <ul className='d-flex justify-content-between'>
                              <li className='text-left'>คูปองส่วนลดของ V AVENUE</li>
                              <li className='text-right'>
                              </li>
                            </ul>

                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>- ครบ 2,000 ลด 200</li>
                              <li className='text-right'>
                                ฿ {numeral(0 - priceNet).format('0,0.00')}
                              </li>
                            </ul> */}


                            {/* ANCHOR -  promotion discount  */}
                            {/* {priceItemDiscount && priceItemDiscount > 0 ?
                              <React.Fragment>
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'>ส่วนลดโปรโมชั่น</li>
                                  <li className='text-right'> </li>
                                </ul>
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'> - {promotionCode}</li>
                                  <li className='text-right'>
                                    ฿{' '} {numeral((0 - priceItemDiscount)).format('0,0.00',)}
                                  </li>
                                </ul>
                              </React.Fragment>
                              :
                              <React.Fragment>
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'>ส่วนลดโปรโมชั่น</li>
                                  <li className='text-right'>฿{' '} {numeral(0).format('0,0.00',)} </li>
                                </ul>
                              </React.Fragment>
                            } */}
                            <hr />
                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>ทั้งหมด</li>
                              <li className='text-right'>฿{' '} {numeral(totalPromotionDiscountPrice).format('0,0.00',)}</li>
                            </ul>
                            <hr />

                            {/* ANCHOR -  shipping price  */}
                            {currentInvoice?.items[0].product?.productType === "general" &&
                              <>
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'>ค่าจัดส่ง</li>
                                  <li className='text-right'>฿{' '} {numeral(priceShippingNet).format('0,0.00',)}</li>
                                </ul>
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'>- ส่วนลดค่าจัดส่ง </li>
                                  <li className='text-right'>฿{' '} {numeral(0 - priceShippingDiscount).format('0,0.00',)}</li>
                                </ul>
                                <hr />
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'>รวมค่าจัดส่ง</li>
                                  <li className='text-right'>฿{' '} {numeral(totalShippingPrice).format('0,0.00',)}</li>
                                </ul>
                                <hr />
                              </>
                            }
                            {currentInvoice?.items[0].product?.productType === "digital" &&
                              <>
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'>ค่าจัดส่ง</li>
                                  <li className='text-right'>฿{' '} {numeral(0).format('0,0.00',)}</li>
                                </ul>
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'>- ส่วนลดค่าจัดส่ง </li>
                                  <li className='text-right'>฿{' '} {numeral(0).format('0,0.00',)}</li>
                                </ul>
                                <hr />
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'>รวมค่าจัดส่ง</li>
                                  <li className='text-right'>฿{' '} {numeral(0).format('0,0.00',)}</li>
                                </ul>
                                <hr />
                              </>
                            }

                            {/* ANCHOR -  total price  */}
                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>รวมทั้งสิ้น</li>
                              <li className='text-right'>฿{' '} {numeral(totalBeforeDiscountVoucher).format('0,0.00',)}</li>
                            </ul>

                            {/* { (priceShippingDiscount+priceItemDiscount) > 0 &&
                              <ul className='d-flex justify-content-between'>
                              <li className='text-left'>ส่วนลด {promotionCode}</li>
                              <li className='text-right'>
                                ฿{' '}
                                {numeral(0-(priceShippingDiscount+priceItemDiscount)).format(
                                  '0,0.00',
                                )}
                              </li>
                              </ul>
                            } */}

                            {/* { priceVoucherDiscount && priceVoucherDiscount > 0 && */}
                            <ul className='d-flex justify-content-between'>
                              {/* <li className='text-left'>คูปองแทนเงินสด {(priceVoucherDiscount) && (priceVoucherDiscount > 0) && `${promotionCode}`}</li> */}
                              <li>คูปองแทนเงินสด</li>
                              <li className='text-right'>
                                ฿{' '} {numeral(0 - (priceVoucherDiscount)).format('0,0.00',)}
                              </li>
                            </ul>
                            <hr />
                            {/* } */}

                          </div>

                          {/* ANCHOR -  total summary  */}
                          <div className='total-summary'>
                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>
                                <Body1>รวมจำนวนทั้งสิ้น</Body1>
                              </li>
                              <li className='text-right total-price'>
                                <Body1> ฿ {numeral(priceTotal).format('0,0.00')} </Body1>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mt-4 flex-wrap gap-1">
                      <div className="d-flex flex-row items-center flex-wrap gap-1">

                        {/* ====== allow all role ====== */}
                        {order &&
                          // order.status != 'waitRefund' &&
                          // order.status != 'refunded' &&
                          order.status == 'waitShipping' &&
                          currentInvoice?.items[0].product?.productType !== "digital"
                          // && getUserRole() !== OWNER
                          ?
                          (
                            <button className='btn-red' onClick={this.onRefund}>
                              <Caption>ขอยกเลิก/คืนเงิน</Caption>
                            </button>
                          ) : null
                        }

                        {order && order.status == 'waitShipping' &&
                          shipmentStatus === 'waitconfirm' &&
                          <div className="row">
                            <div className="col-12 text-right p-0">
                              <button className='btn-submit'
                                onClick={() => this.handleConfirmOrder()} >
                                <Caption>ยืนยันรับออเดอร์</Caption>
                              </button>
                            </div>
                          </div>
                        }


                        {/* {order && order.status == "success" ?
                          !currentInvoice?.clearingAt ? <button className="btn-submit ml-2" onClick={() => this.openModalClearing()}>โอนเงินให้ร้านค้าแล้ว</button> : <span className="text-green ml-2"> <img src={IconTrue} alt="" /> โอนเงินให้ร้านค้าแล้ว</span>
                          : null} */}

                        {/* {order && order.status == 'waitRefund' ? (
                          <div className='ml-4 d-flex flex-column'>
                            <Body2 className='text-error'>
                              หมายเหตุ : {issueNote}{' '}
                            </Body2>
                            {getUserRole() !== OWNER && <label className='mt-2'>
                              <button
                                className='btn-cancel'
                                onClick={() => this.openModalConfirm(false)}
                              >
                                <Caption>ยกเลิกการขอคืนเงิน</Caption>
                              </button>
                              <button
                                className='btn-submit ml-3'
                                onClick={() => this.openModalConfirm(true)}
                              >
                                <Caption>คืนเงินให้ลูกค้าแล้ว</Caption>
                              </button>
                            </label>}
                          </div>
                        ) : null} */}
                        {/* {order && order.status == 'refunded' ? (
                          <Body2 className='text-error ml-4'>
                            หมายเหตุ : {issueNote}{' '}
                          </Body2>
                        ) : null} */}
                      </div>
                      {
                        currentInvoice?.items[0].product?.productType === "general" &&
                        order && order.status == 'waitShipping' &&
                        <div>
                          <button className='btn-cancel'
                            style={{ width: 'auto' }}
                            onClick={this.onOpenModalPrintCoverSheet} >
                            <IconPrint className='mr-2 icon-print' />
                            <Caption>พิมพ์ใบปะหน้าพัสดุ</Caption>
                          </button>
                        </div>
                      }

                    </div>


                  </div>

                  {/* ================ Redesign button cancel order =============== */}
                  {order && order.status == 'waitRefund' || order && order.status == 'refunded' || order && order.status == 'refundApproved' ? (
                    <div className='d-flex flex-column bg-white'>
                      <div className="px-3 py-1 text-error bg-light-red">
                        <Body2>มีคำขอยกเลิก/ขอคืนเงิน</Body2>
                      </div>

                      <div className="px-3 pt-2 d-flex flex-column">
                        <SubTitle2 className='text-primary pb-1' style={{ wordBreak: 'break-all' }} >
                          หมายเหตุการขอยกเลิก/คืนเงิน:
                        </SubTitle2>
                        <Body2 className='text-error pb-1' style={{ wordBreak: 'break-all' }}>
                          {issue[issue?.length - 1]?.reason}{' '}
                        </Body2>

                        {
                          issue[issue?.length - 1]?.extraData?.productImages?.length > 0 ?
                            <div className="d-flex flex-column">
                              <SubTitle2 className='text-primary pb-1'>
                                หลักฐานการรูปภาพ:
                              </SubTitle2>
                              <div className="d-flex align-items-center">
                                {issue[issue?.length - 1]?.extraData?.productImages?.map((image, index) => (
                                  <MediaCard
                                    data={{ path: image.host + image.path, preview: image.host + image.path }}
                                    index={index}
                                    preview
                                    key={image.preview + index}
                                  />
                                ))}
                              </div>
                            </div> : null
                        }

                        {issue[issue?.length - 1]?.extraData?.issueNote !== issue[issue?.length - 1]?.reason && issue[issue?.length - 1]?.extraData?.issueNote !== null ?
                          <div className="d-flex flex-column">
                            <SubTitle2 className='text-primary pb-1' style={{ wordBreak: 'break-all' }}>
                              รายละเอียดเพิ่มเติม:
                            </SubTitle2>

                            <Body2 className='text-secondary pb-1' style={{ wordBreak: 'break-all' }}>
                              {issue[issue?.length - 1]?.extraData?.issueNote}
                            </Body2>
                          </div> : null
                        }

                        {
                          issue[issue?.length - 1]?.solveType ?
                            <div className="d-flex flex-column border-top mt-3 pt-3">
                              <SubTitle2 className='text-primary pb-1'>
                                เงื่อนไขการยกเลิก/คืนเงิน:
                              </SubTitle2>

                              <Body2 className='text-secondary pb-1'>
                                {refundStatus[issue[issue?.length - 1]?.solveType].label}
                              </Body2>
                            </div> : null
                        }

                        {currentInvoice?.refundStatus === "request" ?
                          <div className="d-flex flex-column border-top mt-3 pt-3"><SubTitle2 className='text-primary pb-1'>
                            เลือกเงื่อนไขขอยกเลิก/คืนเงิน
                          </SubTitle2>
                            <div className="d-flex flex-column">
                              {renderOption({
                                key: "refundStatus",
                                value: this.state.refundStatus,
                                col: 6,
                                disabledItems: currentInvoice?.items[0]?.product?.productType == "digital" || currentInvoice?.delivery.trackingNo === null ? [0] : [],
                                options: refundStatus,
                                disabled: false,
                                onInputValueChange: this.onchangeRefundStatus,
                                fontSize: '22px'

                              })}
                            </div>
                            <div>
                              <TextInput
                                label=''
                                defaultValue={''}
                                handleChangeText={(text) => this.onChangeNote(text)}
                                error={false}
                                title='ข้อความถึงลูกค้า'
                              />
                            </div>
                          </div> : null
                        }

                        {
                          (currentInvoice?.refundStatus === "approved" || currentInvoice?.refundStatus === "refunded") && issue[issue?.length - 1]?.solveType === refundStatus.returnrefund.value ?
                            <div className="d-flex flex-column border-top mt-3 pt-3">
                              <SubTitle2 className='text-primary pb-1'>
                                รูปภาพสินค้า/กล่องพัสดุ::
                              </SubTitle2>
                              <div className="d-flex align-items-center">
                                {issue[issue?.length - 1]?.extraData?.returnParcelImage?.map((image, index) => (
                                  <MediaCard
                                    data={{ path: image.host + image.path, preview: image.host + image.path }}
                                    index={index}
                                    preview
                                    key={image.preview + index}
                                  />
                                ))}
                              </div>
                              <SubTitle2 className='text-primary pb-1'>
                                ขนส่ง:
                              </SubTitle2>
                              <Body2 className='text-secondary pb-1'>
                                <img src={host.image + issue[issue?.length - 1]?.logistic?.logo} width={85}></img> {issue[issue?.length - 1]?.logisticCode}
                              </Body2>
                              <SubTitle2 className='text-primary pb-1'>
                                หมายเลขพัสด:
                              </SubTitle2>
                              <Body2 className='text-secondary pb-1'>
                                {issue[issue?.length - 1]?.trackingNo}
                              </Body2>
                            </div>
                            : null
                        }


                        {/* ====== allow all role ====== */}
                        {((order && order.status == 'waitRefund') || (order && order.status == 'refundApproved')) ?
                          <div className="pb-4 mr-2 d-flex flex-wrap gap-1 justify-content-end border-top mt-3 pt-3">
                            {/* {order && order.status != 'refundApproved' &&
                              <button
                                className='btn-cancel'
                                onClick={() => this.openModalConfirm(false)}
                              >
                                <Caption>ปฏิเสธคำขอยกเลิก/คืนเงิน</Caption>
                              </button>
                            } */}
                            {currentInvoice?.refundStatus !== "return" ? <button
                              disabled={currentInvoice?.refundStatus === "return"}
                              className='btn-submit'
                              onClick={() => this.openModalConfirm(true)}
                            >
                              <Caption>{currentInvoice?.refundStatus === "approved" ? 'ดำเนินการคืนเงินให้ลูกค้าแล้ว' : 'อนุมัติคำขอยกเลิก/คืนเงิน'}</Caption>
                            </button> : null
                            }
                          </div>
                          : null
                        }
                      </div>



                      {order && order.status == 'refunded' &&
                      <React.Fragment>
                        {(getUserRole() === ACCOUNTANT) ? issue.length && issue[issue.length - 1].refundType ? 
                        <div className="px-3">
                          <SubTitle2 className='text-primary pb-1'>
                            รายละเอียดการคืน:
                          </SubTitle2> <br />
                              <Body2 className='text-secondary pb-1'>
                              {issue[issue.length - 1].refundType === 'refunded' ? 'Refund' : issue[issue.length - 1].refundType === 'void' ? 'Void' : ''}
                              </Body2>
                        </div> : 
                        <div className="refunded-footer-container">
                          <SubTitle2 className='text-primary pb-1'>
                            รายละเอียดการคืน:
                          </SubTitle2> <br />
                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
                          <div className="d-flex gap-4 flex-row flex-wrap status-refund-container">
                            <Radio.Group
                                  onChange={(e) => this.setState({ refundType: e.target.value })}
                                  value={refundType}
                                  className='font-primary d-flex justify-content-between'
                                  style={{ width: '30%' }}
                                  name={'statusRefund'}
                              >
                                  <Radio value={'void'}>
                                      <Body2>Void</Body2>
                                  </Radio>
                                  <Radio value={'refunded'}>
                                      <Body2>Refund</Body2>
                                  </Radio>
                              </Radio.Group>
                          </div>
                          <button className="btn btn-submit" onClick={() => this.handleSetStatusVoidRefund({orderId: currentInvoice?.id,issueNo: issue[issue.length - 1].issueNo, refundType })}><Body2>ยืนยันการคืนเงิน</Body2></button>
                        </div>
                      </div> : null
                        }
                         <div className="px-3 mb-4 d-flex flex-wrap gap-1  justify-content-end border-top mt-3 pt-3" style={{margin: '0 1rem'}}>
                         <SubCaption className="badge badge-green px-3" style={{ borderRadius: '10px' }}><img src={True} alt="check" className="check-success-pay" /> แจ้งคืนเงินให้ลูกค้าแล้ว </SubCaption>
                       </div>
                       </React.Fragment>
                      }
                    </div>
                  ) : null}

                  {/* {shipmentStatus === 'waitconfirm' &&
                    <div className="row">
                      <div className="col-12 text-right">
                        <button className='btn-submit ml-3 mt-5'
                          onClick={() => this.handleConfirmOrder()} >
                          <Caption>ยืนยันรับออเดอร์</Caption>
                        </button>
                      </div>
                    </div>
                  } */}

                </div>
                {/* !SECTION  */}
                {/* SECTION - การชำระเงิน */}
                <div className='col-4'>

                  {currentInvoice?.refCode1 && currentInvoice?.memberConfig?.label &&
                    <div className='card-container'>
                      <div className='card-item'>
                        <ul className='d-flex justify-content-between'>
                          <li className='text-left'>
                            <Body1>หมายเลขสมาชิก {currentInvoice?.memberConfig?.label}</Body1>
                          </li>
                        </ul>
                      </div>
                      <div className='card-item'>
                        <div className='message-box'>
                          <Body2>{currentInvoice?.refCode1}</Body2>
                        </div>
                      </div>
                    </div>
                  }

                  {customerTaxInformation?.taxType &&
                    <div className='card-container'>
                      <div className='card-item'>
                        <ul className='d-flex justify-content-between align-items-center'>
                          <li className='text-left'>
                            <Body1 className="mb-0">   <IconSelect />  ต้องการรับใบกำกับภาษี</Body1>
                          </li>
                          <li className='text-left'>
                            <SubCaption>{customerTaxInformation?.taxType === 'company' ? 'นิติบุคคล' : 'บุคคลธรรมดา'}</SubCaption>
                          </li>
                        </ul>
                        <div className="d-flex flex-column">
                          <Body2>{customerTaxInformation?.name}</Body2>
                          {customerTaxInformation?.taxType === 'company' ?
                            <React.Fragment>
                              <Body2>{customerTaxInformation?.branch === '00000' ? 'สำนักงานใหญ่' : `สาขา ${customerTaxInformation?.branch}`}</Body2>
                              <Body2>เลขที่ผู้เสียภาษี {customerTaxInformation?.taxNo} </Body2>
                            </React.Fragment>
                            : <Body2>หมายเลขบัตรประชาชน {customerTaxInformation?.taxNo} </Body2>}
                        </div>

                        <div className="d-flex flex-column mt-4">
                          <SubCaption className="mb-1">ที่อยู่ใบกำกับภาษี</SubCaption>
                          <Body2>
                            {`${customerTaxInformation?.address ? customerTaxInformation?.address : ''} 
                        ${customerTaxInformation?.subdistrict ? customerTaxInformation?.subdistrict : ''} 
                        ${customerTaxInformation?.district ? customerTaxInformation?.district : ''} 
                        ${customerTaxInformation?.province ? customerTaxInformation?.province : ''} 
                        ${customerTaxInformation?.zipcode ? customerTaxInformation?.zipcode : ''}`}
                          </Body2>
                        </div>

                      </div>
                    </div>
                  }
                  {/* <div className='card-container'>
                    <div className='card-item'>
                      <ul className='d-flex justify-content-between'>
                        <li className='text-left'>
                          <Body1>หมายเหตุจากลูกค้า</Body1>
                        </li>
                      </ul>
                    </div>
                    <div className='card-item'>
                      <div className='message-box'>
                        <span className="color-red">{note ? note : '-'}</span>
                      </div>
                    </div>
                  </div> */}
                  <div className='card-container'>
                    <div className='card-item'>
                      <ul className='d-flex justify-content-between'>
                        <li className='text-left'>
                          <Body1>การชำระเงิน</Body1>
                        </li>
                        <li className='text-right'>
                          {this.checkPaymentStatus(paymentStatus)}
                        </li>
                      </ul>
                    </div>
                    <div className='card-item'>
                      <div className='payment-method'>
                        <SubCaption>ช่องทางการชำระเงิน</SubCaption>
                        <div className='payment-method-detail'>
                          <Body2>
                            {this.checkPaymentMethod(paymentMethod)}
                          </Body2>

                          <div className="d-flex flex-column card-item mt-2">
                            <SubCaption>เลขอ้างอิง Transaction</SubCaption>
                            <Body2>{payment?.transactionId}</Body2>
                          </div>

                          {paymentedAt && (
                            <ul>
                              <li>
                                <Body2>  {displayDateTh(paymentedAt, 'DD MMM yyyy') +
                                  displayDateTh(paymentedAt, ' HH:mm')}</Body2>

                              </li>
                              <li className='mt-2'>
                                <Body1 className='color-orange'>
                                  ฿ {numeral(priceTotal).format('0,0.00')}
                                </Body1>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-container'>
                    <div className='card-item'>
                      <ul className='d-flex justify-content-between'>
                        <li className='text-left'>
                          <Body1>การจัดส่ง </Body1>
                        </li>
                        <li className='text-right'>
                          {this.checkLogisticStatus(logisticStatus, shipmentStatus)}
                        </li>
                      </ul>
                    </div>
                    <div className='card-item'>

                      {currentInvoice?.items[0].product?.productType === "general" &&
                        <>
                          <SubCaption>ข้อมูลการจัดส่ง</SubCaption>
                          <ul>
                            <li><Body2>{customerInformation?.name}</Body2></li>
                            <li>
                              <Body2>{mobile}
                                {(getUserRole() == ADMIN || getUserRole() == SUPER_ADMIN) && ` / ${email && email}`}</Body2>
                              {/* {`${email ? '/' + email : ''}`} */}
                            </li>
                            <li><Body2>{address}</Body2> </li>
                            <li>
                              <Body2>{district} {subdistrict} {province} {zipcode}</Body2>
                            </li>
                          </ul>
                        </>
                      }

                      {currentInvoice?.items[0].product?.productType === "digital" &&
                        <>
                          <SubCaption>ชื่อลูกค้า</SubCaption> <br />
                          <Caption>{currentInvoice?.customerInformation?.email}</Caption> <br />
                          <SubCaption>รูปแบบการจัดส่ง</SubCaption>
                          <div className="d-flex flex-row align-items-">
                            <IconDigitalProductLogo />
                            <Caption className={"ml-2"}>Digital Product</Caption>
                          </div>
                        </>
                      }


                      {currentInvoice?.items.length > 0 &&
                        currentInvoice?.items[0].product?.productType === "digital" &&
                        <div className="d-flex flex-column mt-3" style={{ lineHeight: '27px' }}>
                          <SubCaption>ข้อมูลการใช้งานสินค้า Digital Product</SubCaption>
                          <Caption>{currentInvoice?.items[0].product?.digitalInfo?.value ?
                            currentInvoice?.items[0].product?.digitalInfo?.value :
                            '-'}</Caption>
                        </div>
                      }

                    </div>


                    {currentInvoice?.items[0].product?.productType === "general" &&
                      (deliveries && deliveries.length > 0) &&
                      deliveries?.map((delivery, idx) => (
                        <React.Fragment>
                          {/* ----------------------------redelivery status---------------------------------- */}
                          {(logisticStatus === "redelivery") && deliveries?.length > 1 && idx != deliveries?.length - 1 &&
                            <div className="second-logistic">
                              <div className='card-item mb-0' key={'delivery' + idx}>
                                <SubCaption className='title'>รูปแบบการจัดส่ง {idx !== 0 ? <SubCaption>ครั้งที่ {idx + 1}</SubCaption> : null}</SubCaption>
                                <div className='logistic-logo'>
                                  <img
                                    src={`${host.image}${delivery?.logistics?.logo}`}
                                    alt='logistic-logo'
                                  ></img>
                                </div>
                              </div>

                              <div className='card-item'>
                                <span>หมายเลขพัสดุ {idx !== 0 ? <span>ครั้งที่ {idx + 1}</span> : null}</span>
                                {logisticStatus !== LogisticStatus.WAIT.key ? (
                                  <div> {delivery.trackingNo || '-'} </div>
                                ) : (
                                  <div className='input-tracking pt-1'>
                                    <div className='pl-0 body-2'>
                                      <input
                                        type='text'
                                        className='input-form'
                                        placeholder='กรุณากรอกหมายเลขพัสดุ'
                                        name={`trackingNo${idx}`}
                                        onChange={this.onChangeLogisticTracking}
                                        value={trackingNo + idx}
                                      />
                                    </div>
                                    <div className='pl-0 pr-0'>
                                      <div className='tooltip-wrap'>
                                        <TooltipNotification toolTipId='tracking-no'>
                                          <button
                                            className={classNames('w-100', {
                                              'btn-disable':
                                                (deliveries && deliveries[idx].trackingNo === null) || (deliveries && deliveries[idx].trackingNo === ''),
                                              'btn-submit': deliveries && deliveries[idx].trackingNo,
                                            })}
                                            id='submit-tracking-no'
                                            onClick={this.updateLogistic}
                                            disabled={
                                              (deliveries && deliveries[idx].trackingNo === null) || (deliveries && deliveries[idx].trackingNo === '')
                                            }
                                          >
                                            <Caption>บันทึก</Caption>
                                          </button>
                                        </TooltipNotification>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                            </div>
                          }
                          {/* ----------------------------other status------------------------------------- */}
                          {logisticStatus !== "redelivery" && deliveries.length > 1 &&
                            <div className="second-logistic">
                              <div className='card-item mb-0' key={'delivery' + idx}>
                                <SubCaption>รูปแบบการจัดส่ง  {idx !== 0 ? <SubCaption>ครั้งที่ {idx + 1}</SubCaption> : null}</SubCaption>
                                <div className='logistic-logo'>
                                  {idx === deliveries.length - 1 && logisticStatus === LogisticStatus.POSTING.key && order.status !== 'waitRefund' && order.status !== 'refunded' ?
                                    <DropdownIcon
                                      options={modalInvoiceDetail.logistics}
                                      handleSelected={this.handleSelectLogisticRedelivery}
                                      defaultValue={logisticRedelivery ? logisticRedelivery : modalInvoiceDetail?.logistics ? modalInvoiceDetail?.logistics && modalInvoiceDetail?.logistics[0]?.value : null}
                                    /> :
                                    <img
                                      src={`${host.image}${deliveries[idx]?.logistics?.logo}`}
                                      alt='logistic-logo'
                                    ></img>
                                  }

                                </div>
                              </div>

                              <div className='card-item'>
                                <span className='title'> หมายเลขพัสดุ {idx !== 0 ? <span>ครั้งที่ {idx + 1}</span> : null} </span>
                                {idx === deliveries.length - 1 && logisticStatus === LogisticStatus.POSTING.key && order.status !== 'waitRefund' && order.status !== 'refunded' ? (
                                  <div className='input-tracking pt-1'>
                                    <div className='pl-0 body-2'>
                                      <input
                                        type='text'
                                        className='input-form'
                                        placeholder='กรุณากรอกหมายเลขพัสดุ'
                                        name={`trackingNo${idx}`}
                                        onChange={this.onChangeLogisticTracking}
                                        value={deliveries[idx].trackingNo}
                                        style={{ fontSize: '20px' }}
                                      />
                                    </div>
                                    <div className='pl-0 pr-0'>
                                      <div className='tooltip-wrap'>
                                        <TooltipNotification toolTipId='tracking-no'>
                                          <button
                                            className={classNames('w-100', {
                                              'btn-disable':
                                                (deliveries && deliveries[idx].trackingNo === null) || (deliveries && deliveries[idx].trackingNo === ''),
                                              'btn-submit': deliveries && deliveries[idx].trackingNo,
                                            })}
                                            id='submit-tracking-no'
                                            onClick={this.updateLogistic}
                                            disabled={
                                              (deliveries && deliveries[idx].trackingNo === null) || (deliveries && deliveries[idx].trackingNo === '')
                                            }
                                          >
                                            <Caption>บันทึก</Caption>
                                          </button>
                                        </TooltipNotification>
                                      </div>
                                    </div>
                                  </div>

                                ) : (
                                  <div> {delivery.trackingNo || '-'} </div>
                                )}
                              </div>
                            </div>
                          }

                          {/* ------------------------------1 delivery status != wait-------------------------------- */}
                          {deliveries?.length == 1 &&
                            <>
                              <div className='card-item mb-0' key={'delivery' + idx}>
                                <SubCaption>รูปแบบการจัดส่ง</SubCaption>
                                <div className='logistic-logo'>
                                  {(logisticStatus === LogisticStatus.WAIT.key || logisticStatus === LogisticStatus.POSTING.key) && order.status !== 'waitRefund' && order.status !== 'refunded' ?
                                    <DropdownIcon
                                      options={modalInvoiceDetail.logistics}
                                      handleSelected={this.handleSelectLogisticRedelivery}
                                      defaultValue={logisticRedelivery ? logisticRedelivery : modalInvoiceDetail?.logistics ? modalInvoiceDetail?.logistics && modalInvoiceDetail?.logistics[0].value : null}
                                    /> :
                                    <img
                                      src={`${host.image}${delivery?.logistics?.logo}`}
                                      alt='logistic-logo'
                                    ></img>
                                  }

                                </div>
                              </div>

                              {this.props?.orderStatus !== OrderStatus.WAIT_CONFIRM &&
                                <div className='card-item'>
                                  <SubCaption>หมายเลขพัสดุ</SubCaption>
                                  {(logisticStatus !== LogisticStatus.WAIT.key) && (logisticStatus !== LogisticStatus.POSTING.key || order.status === 'waitRefund' || order.status === 'refunded') ? (
                                    <div> {delivery.trackingNo || '-'} </div>
                                  ) : (
                                    <div className='input-tracking pt-1'>
                                      <div className='pl-0 body-2'>
                                        <input
                                          type='text'
                                          className='input-form'
                                          placeholder='กรุณากรอกหมายเลขพัสดุ'
                                          name={`trackingNo${idx}`}
                                          onChange={this.onChangeLogisticTracking}
                                          value={deliveries[idx].trackingNo}
                                        />
                                      </div>
                                      <div className='pl-0 pr-0'>
                                        <div className='tooltip-wrap'>
                                          <TooltipNotification toolTipId='tracking-no'>
                                            <button
                                              className={classNames('w-100', {
                                                'btn-disable':
                                                  (deliveries && deliveries[idx].trackingNo === null) || (deliveries && deliveries[idx].trackingNo === ''),
                                                'btn-submit': deliveries && deliveries[idx].trackingNo,
                                              })}
                                              id='submit-tracking-no'
                                              onClick={this.updateLogistic}
                                              disabled={
                                                (deliveries && deliveries[idx].trackingNo === null) || (deliveries && deliveries[idx].trackingNo === '')
                                              }
                                            >
                                              <Caption>บันทึก</Caption>
                                            </button>
                                          </TooltipNotification>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              }
                            </>
                          }
                          {/* -------------------------------------------------------------- */}
                          {/* ------------------------------add new tracking------------------------------------ */}


                          {logisticStatus === LogisticStatus.REDELIVERY.key && idx === deliveries?.length - 1 ?
                            <div className={classNames({ 'second-logistic': updateSecondTracking })}>
                              {!updateSecondTracking && <button className="btn btn-cancel w-100 m-0 title" onClick={() => this.setState({ updateSecondTracking: true })}><Caption>อัปเดตหมายเลขพัสดุครั้งที่</Caption> {modalInvoiceDetail.deliveries?.length}</button>}
                              {updateSecondTracking && logisticStatus == LogisticStatus.REDELIVERY.key &&
                                <React.Fragment>
                                  <div className="d-flex flex-column">
                                    <span className='title'>รูปแบบการจัดส่ง ครั้งที่ {modalInvoiceDetail.deliveries?.length}</span>
                                    <DropdownIcon
                                      options={modalInvoiceDetail.logistics}
                                      handleSelected={this.handleSelectLogisticRedelivery}
                                    />
                                  </div>
                                  <div className="d-flex flex-column mt-1">
                                    <span className='title'>หมายเลขพัสดุ ครั้งที่ {modalInvoiceDetail.deliveries?.length}</span>
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="col-8 pl-0 body-2">
                                        <input
                                          type='text'
                                          className='input-form'
                                          placeholder='กรุณากรอกหมายเลขพัสดุ'
                                          name={`trackingNo${modalInvoiceDetail.deliveries?.length}`}
                                          onChange={this.onChangeLogisticTracking}
                                          value={deliveries[idx].trackingNo} />
                                      </div>
                                      {/* <button className="btn-disable">บันทึก</button> */}
                                      <div className='tooltip-wrap'>
                                        <TooltipNotification toolTipId='tracking-no'>
                                          <button
                                            className={classNames('w-100', {
                                              'button-disable':
                                                (deliveries && deliveries[idx].trackingNo === null) || (deliveries && deliveries[idx].trackingNo === ''),
                                              'button-submit': deliveries && deliveries[idx].trackingNo,
                                            })}
                                            id='submit-tracking-no'
                                            onClick={this.updateLogistic}
                                            disabled={
                                              (deliveries && deliveries[idx].trackingNo === null) || (deliveries && deliveries[idx].trackingNo === '')
                                            }
                                          >
                                            <Caption>บันทึก</Caption>
                                          </button>
                                        </TooltipNotification>
                                      </div>


                                    </div>
                                  </div> </React.Fragment>}

                            </div> : null
                          }


                        </React.Fragment>
                      ))}
                    {logisticStatus === LogisticStatus.DELIVERED.key && currentInvoice?.deliveredAt &&
                      <div className="card-item">
                        <span className="title">วันเวลาส่งสำเร็จ</span>
                        <div>{currentInvoice?.deliveredAt && displayDateTh(currentInvoice?.deliveredAt, 'DD MMM yyyy')} {currentInvoice?.deliveredAt && displayDateTh(currentInvoice?.deliveredAt, 'HH:mm')}</div>
                      </div>
                    }
                    

                    {
                      // getUserRole() == ADMIN || getUserRole() == SUPER_ADMIN &&
                      logisticStatus !== LogisticStatus.WAIT.key &&
                      currentInvoice?.items[0].product?.productType === "general" &&
                      // logisticStatus !== LogisticStatus.DELIVERED.key && 
                      logisticStatus !== LogisticStatus.REDELIVERY.key && order?.status !== 'refunded' && order?.status !== 'waitRefund' && order?.status !== 'refundApproved'&&
                      !(moment().startOf('day').isAfter(moment(currentInvoice?.deliveredAt).add(7, 'day').startOf('day'))) &&
                      <div className="w-75 mt-4">
                        <DropdownButton
                          handleSelected={this.handleSelectStatusLogistic}
                          options={orderStatusChange}
                          defaultValue={orderStatusChange[0].value || ''}
                        />
                      </div>
                    }


                  </div>
                  {/* !SECTION  */}
                  {/* SECTION - หมายเหตุ */}
                  {getUserRole() !== OWNER && (
                    <div className='card-container'>
                      <div className='card-item'>
                        <Body1>หมายเหตุ</Body1>
                      </div>
                      <div className='card-item body-2'>
                        <textarea
                          name='shopNote'
                          id='shopNote'
                          cols='5'
                          className='input-form w-100'
                          placeholder='หมายเหตุ'
                          value={shopNote}
                          onChange={this.onChange}
                        ></textarea>
                        <div className='row mt-2'>
                          <div className='col-12 text-center'>
                            <div className='tooltip-wrap'>
                              <TooltipNotification toolTipId='shop-note'>
                                <button
                                  className='btn-submit'
                                  id='submit-shop-note'
                                  onClick={this.updateShopNote}
                                >
                                  <Caption>บันทึกหมายเหตุ</Caption>
                                </button>
                              </TooltipNotification>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* !SECTION  */}
                </div>
              </div>

            </div>
          </ModalBody>
          <ModalRefundReason id={id} issueNo={currentInvoice?.issue[0]?.issueNo} />
          <ModalMessage
            title={modalMessage.title}
            message={modalMessage.message}
            isShowModalMessage={modalMessage.isShowModalMessage}
            btnLabel={modalMessage.btnLabel}
            confirmCallback={() =>
              modalMessage.title == 'iconSuccess'
                ? window.location.replace('/manage/order/all/waitRefund')
                : this.props.dispatch(
                  dispatchOrder({ type: CLOSE_MODAL_MESSAGE }),
                )
            }
          />

          {modalConfirmRefund.isOpen ? (
            <ModalConfirm
              isOpen={modalConfirmRefund.isOpen}
              message={modalConfirmRefund.message}
              btnConfirm={modalConfirmRefund.btnConfirm}
              btnCancel={modalConfirmRefund.btnCancel}
              confirm={() =>
                this.onConfirmRefund(id, currentInvoice)
              }
              // confirm={() =>
              //   this.state.confirmRefund
              //     ? this.onConfirmRefund(id, currentInvoice)
              //     : this.onCancelRefund(id, currentInvoice)
              // }
              isClose={() =>
                this.props.dispatch(
                  dispatchOrder({ type: CLOSE_MODAL_CONFIRM_REFUND }),
                )
              }
            />
          ) : null}

          {modalConfirmClearing.isOpen ? (
            <ModalConfirm
              isOpen={modalConfirmClearing.isOpen}
              message={modalConfirmClearing.message}
              btnConfirm={modalConfirmClearing.btnConfirm}
              btnCancel={modalConfirmClearing.btnCancel}
              confirm={() => this.handleClearingOrder(id)}
              isClose={() => this.closeModalClearing()}
            />
          ) : null}

          <ModalConfirmLarge
            isOpen={modalConfirmLarge.isOpen}
            message={modalConfirmLarge.message}
            btnConfirm={modalConfirmLarge.btnConfirm}
            btnCancel={modalConfirmLarge.btnCancel}
            headerMsg={<span>หมายเลขออเดอร์ <span className="color-primary"> {modalConfirmLarge.headerMsg} </span></span>}
            confirm={modalConfirmLarge?.data?.status === "wait" ? () => this.handleRedelivery() : () => { }}
            isClose={() => this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CONFIRM_LARGE }))}
          />

          <ModalLargeChildren
            isOpen={modalLargeChildren.isOpen}
            headerMsg={modalLargeChildren.headerMsg}
            isClose={() => this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CHILDREN }))}
            confirm={() => this.onClickCompleteLogistic()}
          >
            <div>
              {
                // getUserRole() === 'SUPER_ADMIN' && 
                // deliveries && deliveries[deliveries?.length - 1]?.logistic !== 'EMS'
                // && currentInvoice?.logisticStatus !== "delivered" && currentInvoice?.logisticStatus !== "wait"
                // ? 
                <div>
                  <h4 className="d-flex align-items-start" style={{ margin: '1rem' }}>ระบุวันที่ส่งสำเร็จไปแล้ว</h4>
                  <div className='d-flex align-items-start flex-column' style={{ margin: '1rem' }}>
                    <span className="require font-primary">วันที่</span>
                    <DatePickerInput /// here
                      onSubmitDateSelected={this.onDateDatePickerInputChange}
                      allowpreviousDate={true} /// this allowpreviousDate control previousDate on/off
                      date={new Date(this.state.releaseDate.date)}
                    />
                  </div>

                  <div className='d-flex align-items-start flex-column' style={{ margin: '1rem' }}>
                    <span className="require font-primary">เวลา</span>
                    <TimePicker
                      onTimePickerChange={this.onTimePickerChange}
                      value={time}
                    />
                  </div>

                  {/* <button
                              className={classNames({
                                'btn-disable':
                                  this.state.trackingNo.trim().length == 0,
                                'btn-submit': this.state.trackingNo,
                              })}
                              id='submit-tracking-no'
                              onClick={this.onClickCompleteLogistic}
                              disabled={this.state.trackingNo.trim().length == 0}
                            >
                              จัดส่งสำเร็จ
                            </button> */}
                </div>
                // :
                // null
              }
            </div>
          </ModalLargeChildren>

          <Loading />
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  modalInvoiceDetail: state.order.modalInvoiceDetail,
  modalMessage: state.modalMessage,
  modalConfirm: state.modalConfirm,
  modalConfirmRefund: state.order.modalConfirm,
  modalConfirmClearing: state.order.modalConfirmClearing,
  modalConfirmLarge: state.order.modalConfirmLarge,
  modalLargeChildren: state.order.modalLargeChildren,
  orderStatus: state.order.status
})

export default connect(mapStateToProps)(ModalInvoiceDetail);
