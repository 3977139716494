import { displayDateThShortYear } from 'helper/timeUtil'
import numeral from 'numeral'
import React from 'react'

const AdminWithdraw = (props) => {
    const { withdrawList, page, limit, showStatusWithdraw } = props
    return (
        <div className='overflow-auto'>
            <div className='table-header-withdraw'>
                <div>ลำดับ</div>
                <div>หมายเลขออเดอร์</div>
                <div>สถานะการโอนเงินให้ร้านค้า</div>
                <div>วันจัดส่ง</div>
                <div>วันที่ชำระเงิน</div>
                <div>ชื่อร้านค้า</div>
                <div>Vendor Code</div>
                <div>ชื่อนิติบุคคล/ชื่อบุคคลธรรมดา</div>
                <div>ยอดรวมค่าสินค้า</div>
                <div>ค่าจัดส่ง</div>
                <div>ส่วนลดค่าจัดส่ง</div>
                <div>คงเหลือค่าจัดส่ง</div>
                <div>ยอดรวมค่าสินค้าและค่าจัดส่ง</div>
                <div>ส่วนลด V-avenue</div>
                <div>ส่วนลดร้านค้า</div>
                <div>ส่วนลดทั้งหมด</div>
                <div>ยอดชำระทั้งสิ้น</div>
                <div>ช่องทางการชำระเงิน</div>
                <div>เลขอ้างอิง mPAY</div>
                <div>ค่าบริการชำระเงินคิดที่อัตรา 2%</div>
                <div> ภาษีค่าบริการชำระเงิน</div>
                <div>ค่าบริการชำระเงิน (รวมภาษี)</div>
                <div>ภาษีหัก ณ ที่จ่าย (W/T)</div>
                <div>รายได้สุทธิของร้านค้า</div>
            </div>
            {withdrawList?.data?.map((list, idx) => (
                <div className="table-content-withdraw">
                    <div>{(page - 1) * limit + idx + 1}</div>
                    <div>{list?.order?.orderNo}</div>
                    <div>{showStatusWithdraw(list?.status,list?.transferredDate,list?.sellerWithdraw?.attachment)}</div>
                    <div>{list?.order?.deliveredAt && displayDateThShortYear(list?.order?.deliveredAt,'DD MMM YY HH:mm')}</div>
                    <div>{list?.order?.paymentedAt && displayDateThShortYear(list?.order?.paymentedAt,'DD MMM YY HH:mm')}</div>
                    <div>{list?.shop?.name}</div>
                    <div>{list?.shop?.partner?.value}</div>
                    <div>{list?.shop?.shopTax?.name}</div>
                    <div>{numeral(list?.totalItemPrice).format('#,0.00')}</div>
                    <div>{numeral(list?.totalShippingFee).format('#,0.00')}</div>
                    <div>{numeral(list?.order?.priceShippingDiscount + list?.order?.priceShippingDiscountShop).format('#,0.00')}</div>
                    <div>{numeral(list?.totalShippingFee - (list?.order?.discount?.priceShippingDiscount ? list?.order?.discount?.priceShippingDiscount : 0 + list?.order?.discount?.priceShippingDiscountShop ? list?.order?.discount?.priceShippingDiscountShop : 0) ? list?.totalShippingFee - (list?.order?.discount?.priceShippingDiscount ? list?.order?.discount?.priceShippingDiscount : 0 + list?.order?.discount?.priceShippingDiscountShop ? list?.order?.discount?.priceShippingDiscountShop : 0) : 0).format('#,0.00')}</div>
                    <div>{numeral((list?.totalItemPrice + list.order?.priceShippingNet)).format('#,0.00')}</div>
                    <div>{numeral(list?.totalDiscountPlatform).format('#,0.00')}</div>
                    <div>{numeral(list?.totalDiscountShop).format('#,0.00')}</div>
                    <div>{numeral(list?.totalDiscountPlatform + list?.totalDiscountShop).format('#,0.00')}</div>
                    <div>{numeral(list?.totalAmount).format('#,0.00')}</div>
                    <div>{list?.order?.paymentMethod}</div>
                    <div>{list?.order?.payment?.transactionId}</div>
                    <div>{numeral(list?.totalPaymentFee).format('#,0.00')}</div>
                    <div>{numeral(list?.totalPaymentFeeVat).format('#,0.00')}</div>
                    <div>{numeral(list?.totalPaymentFee + list?.totalPaymentFeeVat).format('#,0.00')}</div>
                    <div>{numeral(list?.totalWithHoldingTax).format('#,0.00')}</div>
                    <div className='color-success-main'>{numeral(list?.totalRevenue).format('#,0.00')}</div>
                </div>
            ))
            }

            {withdrawList?.data?.length > 0 &&
                <div className="table-last-content-withdraw">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>{numeral(withdrawList?.totalItemPrice).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalShippingFee).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalDiscountShippingShop).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalShippingFee - (withdrawList?.totalDiscountShippingShop + withdrawList?.totalDiscountShippingPlatform)).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalItemPrice + withdrawList?.totalShippingFee).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalDiscountPlatform).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalDiscountShop).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalDiscountShop + withdrawList?.totalDiscountPlatform).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalAmount).format('#,0.00')}</div>
                    <div></div>
                    <div></div>
                    <div>{numeral(withdrawList?.totalPaymentFee).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalPaymentFeeVat).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalPaymentFee + withdrawList?.totalPaymentFeeVat).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalWithHoldingTax).format('#,0.00')}</div>
                    <div>{numeral(withdrawList?.totalRevenue).format('#,0.00')}</div>
                </div>
            }
        </div>
    )
}

export default AdminWithdraw