import { Body1, Caption, H4, H5, SubTitle1 } from 'components/FontStyle'
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as IconPhoto } from 'images/icons/ic_photo.svg';
import { ReactComponent as IconDropper } from 'images/icons/ic_dropper.svg'
import { hexToRgb } from 'helper/utils';
import { SketchPicker } from 'react-color';

const ColorContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 100%;
    /* overflow: hidden; */
`

const ColorPreview = styled.div`
    display: flex;
    /* flex-direction: column; */
    gap: 20px;
    align-items: center;
    margin-bottom: 2em;
`

const ColorItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 25px;
`

const ColorItem = styled.div`
    border: 1px solid #AEAFAF;
    background-color: ${props => props.color || 'none'};
    height: 60px;
    width: 60px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    /* z-index: 0; */
`

const ColorList = styled.div`
  /* background-color: white; */
    /* padding: 1em; */
    width: 50%;
    /* padding-left: 6em; */
    padding: 1em 1em;
`

const Navbar = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    padding: 0.5em;
    background-color: ${props => props.color || 'none'};
    color: ${props => props.textColor || 'black'};
`

const ContentPreview = styled.div`
    display: flex;
    flex-direction: row;
    padding: 2em 1em;
    gap: 4vw;
    background-color: white;
    /* height: 100%; */
    padding-bottom: 20%;
`
const Square = styled.div`
    width: 260px;
    height: 260px;
    /* border: 1px solid black; */
    background-color: ${props => props.color || 'none'};
    color: ${props => props.text || 'none'};
    align-items: center;
    display: flex;
    justify-content: center;
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em 0px;
    line-height: 24px;
`

const ButtonWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    width: 20vw;
    gap: 0.2em;
`

const Button1 = styled.button`
    border-radius: 50px;
    background-color: ${props => props.color || 'none'};
    color: ${props => props.text || 'none'};
    border: none;
`

const ContentWrap = styled.div`
    padding: 0 2em;
    background-color: #F9F9F9;
    width: 100%;
    /* height: 100%; */
    /* background-color: white; */
`

const TextContent = styled(H4)`
    color: ${props => props.color || 'none'};
`

const IconImage = styled(IconPhoto)`
        width: ${props => props.width};
        height: ${props => props.height};
        path{
            fill: ${props => props.color};
        }
        rect {
            stroke: ${props => props.color};
        }
`

const Dropper = styled(IconDropper)`
    path{
        fill: ${props => (props.color?.r * 0.299 + props.color?.g * 0.587 + props.color?.b * 0.114) > 186 ? props.theme.defaultTextPrimary : 'white'}
    }
`

const ColorPick = styled(SketchPicker)`
    box-shadow: none;
`

const Button = styled.button`
    width: auto;
    background-color: red;
    height: auto;
    border: none;
    border-radius: 20px;
    padding: 0.2em 1em;
    background-color: ${props => props.type === 'confirm' ? props.theme.AISPrimaryMain : props.theme.White};
    color: ${props => props.type === 'confirm' ? props.theme.defaultTextPrimary : props.theme.AISPrimaryMain};
`
const ButtonColorPicker = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
    padding: 0.5em;
    border-radius: 0 0 4px 4px;
    margin-top: -3px;
`


const ShopThemeColor = (props) => {
    const [colorIndex, setColorIndex] = useState(null)
    const [listColor, setListColor] = useState(null)
    const { theme } = props

    useEffect(() => {
        setListColor(theme)
        return () => {
            setColorIndex(null)
            setListColor(null)
        }
    }, [])

    const onChangeColor = (value, index) => {
        let oldTheme = [...theme]
        oldTheme[index].value = value.hex
        setListColor(oldTheme)
        if(props.onChange){
            props.onChange(oldTheme)
        }
    }

    const onAcceptColor = (color, index) => {
        setColorIndex(null)

    }

    const onCancelColor = () => {
        onChangeColor({ hex: colorIndex.color, }, colorIndex.index)
        setColorIndex(null)
    }

    return (
        <div style={{ paddingLeft: '0', paddingTop: '1em' }} className='bg-white mt-3'>
            <H5>ธีมสีของร้านค้า</H5>

            <hr />

            <div>
                <ColorContainer className='mt-4'>
                    <ColorList>
                        <Body1>เลือกสี</Body1>
                        {theme?.map((color, index) => (
                            <ColorPreview>
                                <ColorItem color={color?.value} onClick={() => setColorIndex({ color: color.value, index })}>
                                    <Dropper color={color?.value && hexToRgb(color?.value)} className='cursor-pointer' />
                                </ColorItem>
                                <ColorItemWrap>
                                    <span>{color?.label}</span>
                                    <div className='position-relative'>
                                        <div className='position-absolute' style={{ top: '1.5em', left: '-4em' }}>
                                            {index === colorIndex?.index &&
                                                <React.Fragment>
                                                    <ColorPick
                                                        color={color.value}
                                                        disableAlpha
                                                        onChange={(val) => onChangeColor(val, index)}
                                                        presetColors={[]}
                                                        style={{ boxShadow: 'none' }}
                                                    />
                                                    <ButtonColorPicker>
                                                        <Button type='cancel' onClick={() => onCancelColor()}>ยกเลิก</Button>
                                                        <Button type='confirm' onClick={() => onAcceptColor(listColor[index], index)}>เลือก</Button>
                                                    </ButtonColorPicker>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                    <span className='color-primary'>{color?.value}</span>
                                </ColorItemWrap>
                            </ColorPreview>
                        ))}
                    </ColorList>

                    <ContentWrap>
                        <Body1>ตัวอย่างการแสดงผล</Body1>
                        <Navbar color={theme && theme[0].value} textColor={theme && theme[3].value}>
                            <span>Categories 1</span>
                            <span>Categories 2</span>
                            <span>Categories 3</span>
                            <span>Categories 4</span>
                        </Navbar>
                        <ContentPreview>
                            <Square color={theme && theme[1].value} text={theme && theme[0].value}>
                                <IconImage color={theme && theme[0].value} style={{ width: 50, height: 50 }} />
                            </Square>
                            <div>
                                <Header>
                                    <TextContent color={theme && theme[3].value}><SubTitle1 className='font-weight-bold'>TITLE</SubTitle1></TextContent>
                                    <TextContent color={theme && theme[3].value}><Caption>SUBTITLE</Caption></TextContent>
                                </Header>

                                <TextContent color={theme && theme[0].value}>TEXT</TextContent>

                                <ButtonWrap>
                                    <Button1 color={theme && theme[0].value} text={theme && theme[3].value}><SubTitle1>TEXT</SubTitle1></Button1>
                                    <Button1 color={theme && theme[2].value} text={theme && theme[4].value}><SubTitle1>TEXT</SubTitle1></Button1>
                                </ButtonWrap>

                            </div>
                        </ContentPreview>
                    </ContentWrap>
                </ColorContainer>
            </div>
        </div>
    )
}

export default ShopThemeColor
