import {
  SET_KEY_LIST_SELECTED_SHOP,
  ADD_LIST_SELECTED_SHOP,
  REMOVE_LIST_SELECTED_SHOP,
} from "../actions/listSelectedShop";

const initialState = {
  listSelectedShop: {
    shops: {}
  }
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_KEY_LIST_SELECTED_SHOP: {
      return {
        ...state,
        [payload.key]: payload.value,
      };
    }

    case ADD_LIST_SELECTED_SHOP: {
      let temp = { ...state.listSelectedShop };
      for (const shop of payload) {
        // check shop not already
        if (state.listSelectedShop[shop?.shopId] === undefined) {
          temp[shop?.shopId] = {};
        }

        for (const product of shop?.product) {
          temp[shop?.shopId][product?.id] = product;
        }
      }

      return {
        ...state,
        listSelectedShop: { ...temp, ...state.listSelectedShop },
      };
    }

    case REMOVE_LIST_SELECTED_SHOP: {
      let list = { ...state.listSelectedShop };
      Object.keys(list).map((item) => {
        delete list[payload?.shopId];
      });

      return {
        ...state,
        listSelectedShop: list,
      };
    }

    default:
      return state;
  }
}
