// import Axios from 'axios';
// import { isUndefined } from 'lodash';
import axios from '../config/axios';
import host from '../config/host'

class LogisticProvider {

    getLogisticList = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${host.api}/logistics`;
                const res = await axios.get(url);
                resolve(res.data ? res.data : []);
            } catch (error) {
                throw error
            }
        })
    }

    createShopLogistic = async (params) => {
        try {
            const url = `${host.api}/shop-logistic-rates`
            const res = await axios.post(url, params)
            return res
        } catch (error) {
            throw error
        }
    }

    getListShopLogistic = async (params) => {
        try {
            const paging = {
                page: params.page ? `?page=${params.page}` : '',
                limit: params.limit ? `&limit=${params.limit}` : ''
            }
            const url = `${host.api}/shop-logistics/shop/${params.id}${paging.page}${paging.limit}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            throw error
        }
    }

    updateShopLogistic = async (params) => {
        try {
            const url = `${host.api}/shop-logistic-rates/${params.id}`
            const res = await axios.patch(url,params.data)
            return res.data
        } catch (error) {
            throw error
        }
    }

    getShopRateLogistic = async (params) => {
        try {
            const url = `${host.api}/shop-logistics/${params.id}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    applyAllShopLogisticId = async (id) => {
        try {
            const url = `${host.api}/shop-logistics/${id}/apply-all`
            const res = await axios.patch(url)
            return res
        } catch (error) {
            throw error
        }
    }

    getProviderShopLogisticByShopId = async (params) => {
        const paging = {
            page: params.page ? `&page=${params.page}` : '',
            limit: params.limit ? `&limit=${params.limit}` : ''
        }
        try {
            if(params.logisticId === undefined) return
            const url = `${host.api}/shop-logistics/shop/${params.shopId}?logisticId=${params.logisticId}${paging.page}${paging.limit}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            throw error
        }
    }

    deleteShopLogisticByLogisticId = async (id) => {
        try {
            const url = `${host.api}/shop-logistics/${id}`
            const res = await axios.delete(url)
            return res
        } catch (error) {
            throw error
        }
    }

    getListShopLogisticAllByShopId = async (id) => {
        try {
            const url = `${host.api}/shop-logistics/shop/${id}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            throw error
        }
    }

}

export default new LogisticProvider()