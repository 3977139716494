import { SET_PLAYLISTS, SET_PLAYLISTS_ITEMS, SET_MODAL_ADD_PLAYLIST_MALL, ADD_KEY_VALUE_PLAYLISTS } from '../actions/playlists'

const initialState = {
  playlists: null,
  playlistsItems: null,
  modalAddPlayListItem: {isOpen: false, data: null},
  playlistShops: [],
  playlistShopCoupons: [],
  playlistCoupons: [],
  playlistCategories: [],
  playlistType: []
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {

    case ADD_KEY_VALUE_PLAYLISTS:
        return {
          ...state,
          [payload.key]: payload.value,
    }

    case SET_PLAYLISTS: {
      return {
        ...state,
        playlists: payload,
      }
    }

    case SET_PLAYLISTS_ITEMS: {
      return {
        ...state,
        playlistsItems: payload,
      }
    }

    case SET_MODAL_ADD_PLAYLIST_MALL: {
      const {isOpen,data} = payload;
      
      let newData = {};
      if(!payload.data) newData = {...state.modalAddPlayListItem, isOpen}
      else newData = {isOpen, data}

      return {
        ...state,
        modalAddPlayListItem: {...newData}
      }
    }

    default:
      return state
  }
}
