/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
// import validator from "validator";
import numeral from 'numeral';
import { isEmpty } from 'helper/utils';
import { useSelector, useDispatch } from "react-redux";
import { Caption, Require } from "components/FontStyle";
import "../styles/_addPromotion.scss"
import IconShop  from '../images/icons/menu/menu-shop.svg';
import host from 'config/host';


import { SelectShop } from "components/PlaylistItems/";
import ToggleInput from 'components/ToggleInput';
import Radio from "components/RadioButton";
import InputText from "components/InputText";
import FieldNumber from "components/FieldNumber";
import DateTimePicker from "components/DateTimePicker";
import { Textarea } from 'components/input';

import ModalConfirm from "components/modalConfirm/ModalConfirm";
import ModalMessageCallback from "components/ModalMessageCallback";

import {
    GET_USER_SHOPS,
    ADD_KEY_VALUE_SHOP,
    GET_SHOPS_PROMOTION_SEARCH
  } from 'redux/actions/shop';

import {
  GET_CLIENT_LEVEL
} from "redux/actions/promocode";
import {
  OPEN_MODAL_MESSAGE_PROMOTION ,
  OPEN_MODAL_CONFIRM_PROMOTION,
  CREATE_PROMOTION,
  UPDATE_PROMOTION,
  GET_PROMOTION_DETAIL
} from "redux/actions/promotion";

/* -------------------------------------------------------------------------- */
/*                                  constant                                  */
/* -------------------------------------------------------------------------- */
const options = {
    benefitUnit: {
        PERCENT: { value: "percent", label: "ลดราคาเป็น %" },
        VALUE: { value: "value", label: "ลดราคาเป็นจำนวนเงิน" }
    },
    usability: {
      UNLIMIT: { value: "unlimit", label: "ไม่จำกัด" },
      LIMIT: { value: "limit", label: "จำกัด" },
    }

};
const disabledInputRule = {
    add: {},
    edit: {
      codeFormatValue: true,
      productCode: true,
      productType: true,
    }
  }

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */
const RowItem = (props) => {
  const { title, title2, width=7, titleWidth=3, header, children, require } = props;
  return (
    <>
      <div className="row mb-4">
        <div className={`col-${titleWidth}`}>
          <Caption>
            {header && header}
            {title && title}
            {title2 && (<> <br />{title2}</>)}
            {require && <Require />}
          </Caption>
        </div>
        <div className={`col-${width}`}>{children}</div>
      </div>
    </>
  );
};

const renderOption = (params) => {
  const { key, value, col=6, cols,options, disabled, fontSize, disabledItems, onInputValueChange } = params;
  /** Params
   * key(string) : 
   * value(string,number)
   * col(number)
   * cols(array(number))
   * optios(object) 
   * fontSize(number)
   * disabledItems(array(number))
   */
  return (
    <div className="row">
      { Object.keys(options).map((keyItem,index) => {
        const isDisabled = disabledItems ? disabledItems.find(itemIndex => itemIndex === index) : false;
        const disabledItem = isDisabled ? true : disabled;
        let column = col;

        if(cols) column = cols[index];

        return <div key={keyItem} className={`col-${column} mb-4`}>
                <Radio
                  value={options[keyItem].value}
                  className="mr-4"
                  valueOption={value}
                  name={key}
                  disabled={disabledItem}
                  onChange={() =>
                    onInputValueChange(key, options[keyItem].value)
                  }
                />
                <label className="form-check-label mr-4" style={{fontSize: fontSize}}>
                  {options[keyItem].label}
                </label>
                <br />
                { options[keyItem].description &&
                  <label className="label-description">
                    {options[keyItem].description}
                  </label>
                }
               
              </div>
        } 
      )}
    </div>
  );
};

const ContainerSection = (props) => {
    const {children, title} = props;
    return <div className="section">
      { title && <>
        <h3 style={{fontWeight: 600}}>{title}</h3>
        <hr />
        </>
      }
      <div className="content">
            {children}
      </div>
    </div>
}

/* -------------------------------------------------------------------------- */
/*                                  component                                 */
/* -------------------------------------------------------------------------- */
const AddPromotion = (props) => {
    const { shopSelected,
            userShops,
            shopUser,
            isAddForm,
          } = useAddPromotion(props);

    return <> 
      { shopUser && 
        <>
         { (!shopSelected &&
            shopUser.length > 1 &&  // check user's shop  one or many
            isAddForm) ? (
          <SelectShop showPlayList={false} shopPromotion={true} /> 
          ) : ( 
            <FormAddPromotion shopUser={shopUser}/>
          )
          }
        </> 
      }
    </>

}

const FormAddPromotion = (props) => {

    const dispatch = useDispatch();
    const {propsData, shopUser} = props;
    const { handleDataChange,
            handleStartDateTimeChannge,
            handleStopDateTimeChannge,
            handleSuccessComfirmModal,
            handleCancelSubmit,
            handleManagePromotion,
            modalAlert,
            modalConfirm,
            modalMessage,
            data,
            errors,
            history,
            shopSelected,
           } = useFormAddPromotion(propsData);
    const { benefitUnit,
            benefitValue,
            clientLevel,
            clientLevelOption,
            conditionValue,
            description,
            modalDateOpen,
            name,
            startDate,
            startTime,
            status,
            stopDate,
            stopTime,
            usability } = data;

    const isAddForm = window.location.pathname.includes('add');
    const disabledInput = isAddForm ? disabledInputRule.add : disabledInputRule.edit;
  
    return <>
    <h3>{ isAddForm ? "เพิ่มคูปองส่วนลด" : "แก้ไขคูปองส่วนลด"}</h3>
    <div className="add-promotion">
      { isAddForm === true && 
         <div className="section pb-4">
            <div className="d-flex flex-row">
              { shopSelected &&
                <div className='d-flex justify-content-start align-items-end'>
                  <div className='pr-4'>
                    {
                      shopSelected?.logoPath ? 
                      <img style={{ height: 40 }} alt="logo-shop" src={`${host.image}${shopSelected?.logoPath?.path}`} />
                      :
                      <img src={IconShop} className="mr-2" alt='logo-shop'/>
                    }
                  </div>
                  <div className='pl-4' style={{ borderLeft: 'solid black 2px' }}>
                    <span>{shopSelected?.name}</span>
                  </div>
                </div>
              }
              { isAddForm && 
                (shopUser && shopUser.length > 1) &&
                // propsData?.shopUser?.length > 1 &&
                <div className='d-flex justify-content-start align-items-end ml-4'>
                  <span className="link-url" 
                        onClick={() => dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopSelected', value: null } })}> 
                        เปลี่ยนร้าน 
                  </span>
                </div>
              }
            </div>
        </div>
      }

        <ContainerSection title={"รายละเอียดส่วนลด"}> 
            <RowItem title="ชื่อโปรโมชั่น" require={true}>
                <InputText
                  className={"input-text"}
                  onChange={(event) => handleDataChange("name", event.target.value) }
                  value={name}
                  label={"ชื่อ"}
                  title={"(จำกัด 100 ตัวอักษร)"}
                  placeholder={"Ex.ลดราคา 50 บาท เมื่อซื้อครบ 500 บาท"}
                  disabled={disabledInput["name"]}
                  isRequire={true}
                  isError={errors["name"] ? true : false}
                  errorMsg={ errors["name"] ? errors["name"].message : "" }
                  classNameTitle={"input-title"}
                  classNameSubTitle={"input-subtitle"}
                />
            </RowItem>
            <RowItem title="รายละเอียด" require={true}>
              <Textarea
                label='รายละเอียด'
                title={'(จำกัด 5,000 ตัวอักษร)'}
                defaultValue={description}
                placeholder='รายละเอียด'
                handleChangeText={(text) => handleDataChange('description', text)}
                error={errors["description"] ? errors["description"].message : "" }
                require={true}
              />
            </RowItem>
        </ContainerSection>
        <ContainerSection title={"รูปแบบส่วนลด"}> 
            <RowItem title="รูปแบบส่วนลด">
                ลดราคาค่าจัดส่ง
            </RowItem>
            <RowItem title="ประเภทส่วนลด" width={8}>
                {renderOption({
                  key: "benefitUnit",
                  value: benefitUnit,
                  col: 4,
                  options: options.benefitUnit,
                  disabled: disabledInput["benefitUnit"],
                  onInputValueChange: handleDataChange,
                })}
                <br />
                    <div className="d-flex flex-wrap mt-2">
                      <div style={{flex: '50%', width: '100%', paddingRight: '15px'}}>
                        <FieldNumber
                          type={"CURRENCY"}
                          title={"เมื่อยอดซื้อถึง"}
                          detail={""}
                          placeholder={"0"}
                          name={"conditionValue"}
                          value={conditionValue}
                          onChange={(value) => handleDataChange("conditionValue", value.floatValue) }
                          disabled={disabledInput["conditionValue"]}
                          isRequire={true}
                          isError={errors["conditionValue"] ? true : false}
                          errorMessage={ errors["conditionValue"] ? errors["conditionValue"].message : "" }
                        />
                      </div>
                      <div style={{flex: '50%', width: '100%', paddingLeft: '15px'}}>
                          <FieldNumber
                            type={`${benefitUnit === options.benefitUnit.VALUE.value ?"CURRENCY":"PERCENT"}`}
                            title={`รับส่วนลด ${benefitUnit === options.benefitUnit.PERCENT.value ? "(%)" : ""}`}
                            detail={""}
                            placeholder={"0"}
                            name={"benefitValue"}
                            value={benefitValue}
                            onChange={(value) => handleDataChange("benefitValue", value.floatValue) }
                            disabled={disabledInput["benefitValue"]}
                            isRequire={true}
                            isError={errors["benefitValue"] ? true : false}
                            errorMessage={ errors["benefitValue"] ? errors["benefitValue"].message : "" }
                          />
                      </div>
                    </div>
            </RowItem>
        </ContainerSection>
         <ContainerSection title={"ระยะเวลาการใช้งาน"}>
              <RowItem title="ระยะเวลาการใช้งาน" width={8}>
                  {renderOption({
                      key: "usability",
                      value: usability,
                      col: 4,
                      options: options.usability,
                      disabled: disabledInput["usability"],
                      onInputValueChange: handleDataChange,
                    })}
              </RowItem>
              { usability === options.usability.LIMIT.value &&
                <>
                  <RowItem title="กำหนดวันที่">
                    <DateTimePicker
                      isModalDateOpen={modalDateOpen==="startDate"}
                      toggleModalDate={() => handleDataChange('modalDateOpen', modalDateOpen==="startDate" ? "" : 'startDate')}
                      disabled={disabledInput["startDate"]}
                      allowpreviousDate={false}
                      isDateError={errors["startDate"] ? true : false}
                      isTimeError={errors["startDate"] ? true : false}
                      date={startDate}
                      time={startTime}
                      labelDate={"วันที่เริ่มใช้งาน"}
                      labelTime={"เวลา"}
                      onChange={handleStartDateTimeChannge}
                      dateErrorMessage={''}
                      timeErrorMessage={''}
                    />
                    {errors["collectStartDate"] &&
                      <div className="row mt-3 pl-4">
                        <span style={{ color: "red" }}>
                          {errors["collectStartDate"].message}
                        </span>
                      </div>
                    }
                  </RowItem>
                  <br />
                  <RowItem title="">
                    <DateTimePicker
                      isModalDateOpen={modalDateOpen==="stopDate"}
                      toggleModalDate={() => handleDataChange('modalDateOpen', modalDateOpen==="stopDate" ? "" : 'stopDate')}
                      disabled={disabledInput["stopDate"]}
                      allowpreviousDate={false}
                      isDateError={errors["stopDate"] ? true : false}
                      isTimeError={errors["stopDate"] ? true : false}
                      date={stopDate}
                      time={stopTime}
                      labelDate={"วันที่สิ้นสุด"}
                      labelTime={"เวลา"}
                      onChange={handleStopDateTimeChannge}
                      dateErrorMessage={''}
                      timeErrorMessage={''}
                    />

                    {/* <div className="row mt-3 pl-4">
                      <span style={{ color: "red" }}>
                        * หมายเหตุ วันที่สิ้นสุดห้ามเกินวันหมดอายุ
                      </span>
                    </div> */}
                  </RowItem>
                </>
              }
        </ContainerSection>
        <ContainerSection>
              <RowItem title="ประเภทลูกค้าที่ใช้สิทธิได้" width={8}>
              {clientLevelOption && Object.keys(clientLevelOption).length > 0 &&
                  renderOption({
                    key: "clientLevel",
                    value: clientLevel,
                    col: 4,
                    options: clientLevelOption,
                    disabled: disabledInput["clientLevel"],
                    onInputValueChange: handleDataChange,
                  })}
                  {errors["clientLevel"] &&
                  <div className="row mt-3 pl-4">
                    <span style={{ color: "red" }}>
                      {errors["clientLevel"].message}
                    </span>
                  </div>
                }
              </RowItem>
        </ContainerSection>
        <ContainerSection>
              <RowItem title="สถานะการใช้งาน">
                <ToggleInput value={status !== 'hide'}
                  onChange={() => handleDataChange("status", status === 'show' ? 'hide' : 'show')}
                  name={"status"}
                  labelOn={"เปิดการใช้งาน"}
                  labelOff={"ปิดการใช้งาน"} />
              </RowItem>
        </ContainerSection>
        <ContainerSection>
            <div className="d-flex justify-content-center">
              <button className="btn-cancel mr-4 pt-3 pb-3" onClick={handleCancelSubmit}>
                ยกเลิก
              </button>
              <button className="btn-submit" onClick={handleManagePromotion}>
                บันทึก
              </button>
            </div>
            <br />
          </ContainerSection>  

          <ModalConfirm
            isOpen={modalConfirm?.isOpen}
            message={modalConfirm?.message}
            confirm={modalConfirm?.confirm}
            isClose={modalConfirm?.isClose}
          />
          <ModalMessageCallback
            isOpen={modalMessage?.isOpen}
            message={modalMessage?.message}
            confirmCallback={() => handleSuccessComfirmModal()
            } // สร้าง coupon สำเร็ จ
            title={modalMessage?.title}
          />
          {/* <ModalAlert isOpen={modalAlert.isOpen} /> */}
    </div>
    </>
}

/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const useAddPromotion = (props) => {

    const dispatch = useDispatch();
    const { shopSelected, userShops, shopsSearch } = useSelector((state) => state.shop);
    const { userInfo } = useSelector((state) => state.user);
    const [shopUser, setShopUser] = useState([]);
    const isAddForm = window.location.pathname.includes('add');

    const { location } = props;
    const { promotion: promotionDetail } = location?.state || {promotion: null} ;
    const history = useHistory();

    useEffect(() => {
      initialComponent()
      return () => unmounthComponent()
    }, [])

    useEffect(() => {

      if(userShops && shopUser.length === 0) {
        setShopUser(userShops);

        // for user that have one shop get shopSelected
        if(userShops && userShops.length === 1) {

          dispatch({ type: ADD_KEY_VALUE_SHOP, payload: 
                   { key: 'shopSelected', value: userShops[0] } });

          // each shop can have one promotion 
          // check promotion that this shop have
          // only create promotion
          if(isAddForm)
          dispatch({ type: GET_SHOPS_PROMOTION_SEARCH, payload: { kw: userShops[0]?.name } })
        }

      }
    }, [userShops])

    useEffect(() => {
      
      // redirect to promotion list if this shop has a promotion already
      // check for only owner user 
      if( !Array.isArray(shopsSearch) &&
         isAddForm &&
         userInfo.role === 'brand' &&
         shopsSearch?.shop[0] &&
         shopsSearch?.shop[0]?.promotionCount &&
         shopsSearch?.shop[0]?.promotionCount > 0) {
           history.push({ pathname: '/manage/promotion/' })
      }

    }, [shopsSearch])

    const initialComponent = () => {

      // get user'shop for display component selectShop if they have more than one shop
      dispatch({ type: GET_USER_SHOPS, payload: {} }) 
      
      // case update 
      if(isAddForm === false) {
        if(promotionDetail && promotionDetail?.id)
        dispatch({ type: GET_PROMOTION_DETAIL, payload: {id: promotionDetail?.id} }) 
        else
        history.push({ pathname: '/manage/promotion/' }) 
      }
    }

    const unmounthComponent = () => {
      dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopSelected', value: null } });
      dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'userShops', value: null } });
    }

    return  { shopSelected,
              userShops,
              shopUser,
              isAddForm,
            }
}

const useFormAddPromotion = (props) => {

    const initialData = {   benefitUnit: options.benefitUnit.PERCENT.value,
                            benefitValue: '',
                            clientLevel: 3,
                            clientLevelOption: {},
                            conditionValue: '',
                            description: '',
                            modalDateOpen: '',
                            name: '',
                            startDate: moment(),
                            startTime: moment('00:00','HH:mm'),
                            status: 'show',
                            stopDate: moment(),
                            stopTime: moment('23:59','HH:mm'),
                            usability: options.usability.UNLIMIT.value,
                            
                        };
    const dispatch = useDispatch();
    const history = useHistory();
    const isAddForm = window.location.pathname.includes('add');

    const {shopSelected} = useSelector(state => state.shop);
    const modalAlert = useSelector((state) => state.modalAlert.modalAlert);
    const { clientLevel:propClientLevel } = useSelector((state) => state.promocode);
    const { promotionDetail } = useSelector((state) => state.promotion);
    const { modalMessage,
            modalConfirm } = useSelector((state) => state.promotion);

    const [data, setData] = useState(initialData);
    const [errors, setErrors] = useState({}); 


    // inital data for update
    useEffect(() => {
      if(promotionDetail) {
        if(isAddForm === false) 
        setData(initialUpdateData(promotionDetail));
      } 
    }, [promotionDetail]);

    const initialUpdateData = promotionDetail => {
        let status = promotionDetail?.status;
        if(promotionDetail.stopDate && moment(promotionDetail?.stopDate) < moment()) 
           status = 'hide';

        return {   ...data,
                   benefitUnit: promotionDetail?.promotionRates[0]?.benefitUnit === options.benefitUnit.VALUE.value ? 
                                options.benefitUnit.VALUE.value : options.benefitUnit.PERCENT.value,
                   benefitValue: promotionDetail?.promotionRates[0]?.benefitValue,
                   clientLevel: promotionDetail?.levelIds.length === 1 ? promotionDetail?.levelIds[0] : 3 ,
                   conditionValue: promotionDetail?.promotionRates[0]?.condition?.value,
                   description: promotionDetail?.description,
                   name: promotionDetail?.name,
                   startDate: promotionDetail?.startDate ? moment(promotionDetail?.startDate) : moment(),
                   startTime: promotionDetail?.startDate ? moment(promotionDetail?.startDate) : moment('00:00','HH:mm'),
                   status: status,
                   stopDate: promotionDetail?.stopDate ? moment(promotionDetail?.stopDate) : moment(),
                   stopTime: promotionDetail?.stopDate ? moment(promotionDetail?.stopDate) : moment('23:59','HH:mm'),
                   promotionRateId: promotionDetail?.promotionRates[0]?.id,
                   usability: !promotionDetail?.startDate && !promotionDetail?.stopDate ?
                              options.usability.UNLIMIT.value : options.usability.LIMIT.value
              };
    }

    useEffect(() => {
      initialComponent()
      return () => unmounthComponent()
    }, []);

    const initialComponent = () => {
        dispatch({ type: GET_CLIENT_LEVEL, payload: {} })
    }

    const unmounthComponent = () => {
        dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopSelected', value: null } });
        dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'userShops', value: null } });
    }

    useEffect(() => {
        if (propClientLevel && propClientLevel.length > 0)
          provideClientLevel(propClientLevel);
    }, [propClientLevel]);

    const provideClientLevel = clientLevels => {
        let optionsAPI = clientLevels.reduce((total, levelItem) => {
          let collection = total;
          collection[levelItem.name] = { ...levelItem, value: levelItem.id };
          let label = '';
          // eslint-disable-next-line default-case
          switch (levelItem.name) {
            case 'normal': label = 'เฉพาะลูกค้าที่ไม่ใช่ AIS'; break;
            case 'ais': label = 'เฉพาะลูกค้า AIS'; break;
          }
          collection[levelItem.name].label = label;
          return collection;
        }, {});
    
        let options = {all: {
          level : 3,
          name : "all",
          id : 3, 
          value : 3,
          label : "ลูกค้าทั้งหมด",
          }}
    
        options = {...options, ais: optionsAPI.ais,  normal: optionsAPI.normal}
    
        setData({ ...data, clientLevelOption: options, });
    }

    const handleDataChange = (key, value) => {
          setData({ ...data, [key]: value });
    }

    const handleStartDateTimeChannge = (key, value) => {
        // eslint-disable-next-line default-case
        switch (key) {
          case "date":
            setData({ ...data, startDate: value, modalDateOpen: "" });
            break;
          case "time":
            setData({ ...data, startTime: value });
            break;
        }
    }

    const handleStopDateTimeChannge = (key, value) => {
        // eslint-disable-next-line default-case
        switch (key) {
          case "date":
            setData({ ...data, stopDate: value, modalDateOpen: "" });
            break;
          case "time":
            setData({ ...data, stopTime: value });
            break;
        }
    }

    const handleCancelSubmit = () => {
        const titleMessage = isAddForm ? 'ยกเลิกการสร้างโปรโมชั่น' :'ยกเลิกการแก้ไขโปรโมชั่น';
        dispatch(
          {
            type: OPEN_MODAL_CONFIRM_PROMOTION,
            payload: {
              isOpen: true, message: titleMessage,
              confirm: () => cancelSubmit(),
              isClose: () => closeConfirmModal()
            }
          })
    }

    const cancelSubmit = () => {
      setData({ ...initialData });
      closeConfirmModal();
      let redirectPath = '/manage/promotion/';
      history.push({ pathname: redirectPath, });
    }

    const closeConfirmModal = () => {
      dispatch({
        type: OPEN_MODAL_CONFIRM_PROMOTION,
        payload: { isOpen: false, message: '', confirm: () => { } }
      })
    }

    const provideParam = (option) => {

    const { benefitUnit,
            benefitValue,
            clientLevel,
            conditionValue,
            description,
            name,
            startDate,
            startTime,
            status,
            stopDate,
            stopTime,
            promotionRateId,
            usability } = data;

      const provideDataTime = (date, time) => new Date(`${date} ${time}`).toISOString();

      const startDateTime = provideDataTime(moment(startDate).format('MM/DD/YYYY'), moment(startTime).format('HH:mm'));
      const endDateTime = provideDataTime(moment(stopDate).format('MM/DD/YYYY'), moment(stopTime).format('HH:mm'));
     
      let clientLevels = [clientLevel];
      if(clientLevel === 3) clientLevels = [1, 2];

      let updateParam = {
        benefit: 'shipping',
        description: description,
        levelIds: clientLevels,
        mediaCover: 'image/5c4b11cb-573d-46ae-b166-ab8e37a89f9a.png',
        name: name,
        owner: 'shop',
        rates: [ { condition: {
                    value: conditionValue,
                    unit: "amount" 
                    },
                  promotionRateId: promotionRateId,
                  benefitValue: benefitValue, 
                  benefitUnit: benefitUnit,
                  deleted: false } 
               ],
        // stockMax: 10,
        // stockMaxDay: 10,
        // stockRemains: 0,
        // stockUses: 0,
        status: status,
        startDate: null,
        stopDate: null
      }

      let createParam = {
        benefit: 'shipping',
        benefitType: 'discount',
        campaignId: 1,
        description: description,
        levelIds: clientLevels,
        limitGlobal: { value: 10, unit: 'day' },
        limitUser: { value: 10, unit: 'day' },
        mediaCover: 'image/5c4b11cb-573d-46ae-b166-ab8e37a89f9a.png',
        name: name,
        owner: 'shop',
        promotionItem: 'shop',
        rates: [ { condition: {
                      value: conditionValue,
                      unit: "amount" 
                  },
                   benefitValue: benefitValue, 
                   benefitUnit: benefitUnit } ],
        shopId: shopSelected?.id,
        status: status,
        stockMax: 10,
        startDate: null,
        stopDate: null
      }

      if(usability === options.usability.LIMIT.value) {
        createParam = {...createParam, stopDate: endDateTime, startDate: startDateTime}
        updateParam = {...updateParam, stopDate: endDateTime, startDate: startDateTime}
      }

      if(option === 'CREATE') return createParam;
      if(option === 'UPDATE') return updateParam;
      else return {}
    }

    const validateForm = () => {
      const requireMessage = "กรุณากรอกข้อมูล";
   
      let _errors = {};
      let requireFields =  ["name",
                            "conditionValue",
                            "benefitValue",
                            "startDate",
                            "stopDate"
                           ];
  
      const convertDateTimeMoment = (_date, _time) => {
        const date = moment(_date).format('MM/DD/YYYY');
        const time = moment(_time).format('HH:mm');
        return moment(`${date} ${time}`);
      }
      const startDate = convertDateTimeMoment(data.startDate, data.startTime);
      const stopDate = convertDateTimeMoment(data.stopDate, data.stopTime);
  
      if(startDate > stopDate) _errors['date'] = { message: "วันที่เริ่มต้องน้อยกว่าวันที่สิ้นสุด" };
   
      requireFields.forEach(field => {
         if (isEmpty(data[field]))
        _errors[field] = { message: requireMessage };
      })
  
      setErrors(_errors);
      return Object.keys(_errors).length === 0
    }

    const handleManagePromotion = async () => {

        const payload = isAddForm ? await provideParam('CREATE') : await provideParam('UPDATE');

        const isValid = validateForm();
        
        if (isValid) {
          if (isAddForm) {
            dispatch({ type: CREATE_PROMOTION, payload: payload })
          } else {
            dispatch({ type: UPDATE_PROMOTION, payload: { data: payload, id:  promotionDetail?.id } })
          }
        }
        
    };

    const handleSuccessComfirmModal = () => {
     
        let redirectPath = '/manage/promotion/';
      
        dispatch({
          type: OPEN_MODAL_MESSAGE_PROMOTION,
          payload: { isOpen: false, message: '', title: 'iconSuccess' }
        })

        history.push({ pathname: redirectPath, 
                       state: { shopItemSelected: shopSelected?.id } })
    }

    return { handleDataChange,
             handleStartDateTimeChannge,
             handleStopDateTimeChannge,
             handleCancelSubmit,
             handleManagePromotion,
             handleSuccessComfirmModal,
             modalAlert,
             modalMessage,
             modalConfirm,
             data,
             errors,
             history,
             shopSelected,
             }
}

export default AddPromotion;

