import React from "react";
import "../../styles/_tableAnnounce.scss";
import { ReactComponent as IconTrash } from "images/icons/trash.svg";
import { ReactComponent as IconEdit } from "images/icons/edit.svg";
import host from "config/host";
import { displayDateThShortYear } from "helper/timeUtil";
import classNames from "classnames";
import moment from "moment";
import AnnounceImageDefault from "images/announce-default.png";

const TableAnnounce = ({ data, currentPage, limitPage, handleEditAnnounce, handleDeleteAnnounce }) => {
  const header = [
    "ลำดับ",
    "หัวข้อประกาศ",
    "",
    "ระยะเวลาการใช้งาน",
    "สถานะการใช้งาน",
    "",
  ];

  return (
    <div className="wrap-table-announce">
      <div className="table-announce-header">
        {header.map((item, index) => (
          <div className="header-announce-item" key={index}>
            {item}
          </div>
        ))}
      </div>

      <div>
        {data &&
          data?.map((item, index) => {
            const isExpiredDate = moment(item?.announceEnd) < moment();

            return (
              <div className="table-announce-body" key={index}>
                <div className="body-announce-item">
                  {((currentPage - 1) * limitPage ) + index+1}
                </div>
                <div className="body-announce-item">
                  <img
                    className={classNames('image-preview-announce', isExpiredDate && 'image-expired')}
                    src={
                      item?.image
                        ? host?.imgAnnounce + item?.image?.path
                        : AnnounceImageDefault
                    }
                  />
                </div>
                <div className="body-announce-item ">
                  <span className="table-announce-name">{item?.title}</span>
                </div>
                <div className="body-announce-item">
                  <div>
                    <span>
                      {displayDateThShortYear(
                        item?.announceBegin,
                        "DD/MM/YYYY HH:mm"
                      )}
                    </span>{" "}
                    <br />
                    <span>
                      {displayDateThShortYear(
                        item?.announceEnd,
                        "DD/MM/YYYY HH:mm"
                      )}
                    </span>
                  </div>
                </div>
                <div className="body-announce-item">
                  <span
                    className={classNames(
                      "tag-status-announce",
                      isExpiredDate && "isExpiredDate"
                    )}
                  >
                    {isExpiredDate ? "ปิดใช้งาน" : "เปิดใช้งาน"}
                  </span>
                </div>
                <div className="body-announce-item">
                  <IconEdit onClick={() => handleEditAnnounce(item?.id)}/>
                  <IconTrash onClick={()=> handleDeleteAnnounce(item?.id)}/>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TableAnnounce;
