/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { ListChatSummary } from 'components/outputChat'

import 'styles/chat/chatRoom.scss'

const ChatSummary = (props) => {
  const [dataUser, setDataUser] = useState({})
  const [idSelected, setIdSelected] = useState('')
  const [isShowChat, setIsShowChat] = useState(false)

  useEffect(() => {
    document.getElementById('content').classList.toggle('hide-sidebar')
  }, [])

  const setUser = async (data) => {
    let userArrange = {}

    await Promise.all(
      // eslint-disable-next-line array-callback-return
      await data.map((user, index) => {
        userArrange[user._id] = user
      }),
    )

    setDataUser(userArrange)
  }

  const setIdChat = (id) => {
    setIdSelected(id)
    setIsShowChat(true)
  }

  return (
    <div>
      <div className='div-all chat-summary'>
        {/* <div className='div-list-user'>
          <ListUser
            setDataUser={setUser}
            setIdChat={setIdChat}
            idSelected={idSelected}
          />
        </div> */}

        <div className='div-list-chat summary'>
          <ListChatSummary
          // dataUser={dataUser}
          // idSelected={idSelected}
          // isShowChat={isShowChat}
          />
        </div>
      </div>
    </div>
  )
}

export default ChatSummary
