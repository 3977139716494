/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import { ReactComponent as IReply } from 'images/icons/icon-reply.svg'
import { ReactComponent as IPhoto } from 'images/icons/icon-photo.svg'
import closeImage from 'images/icons/close-image.svg'
import 'styles/chat/modal.scss'

const ModalReply = (props) => {
  const { showModal, dataUser, closeModal, replyMsg, replyImage } = props

  const [msgSend, setMsgSend] = useState('')

  // const [error, setError] = useState({
  //   name: true,
  //   mobile: true,
  // })

  const [createUserMobile, setCreateUserMobile] = useState(false)
  const [imagePreview, setImagePreview] = useState('')
  const [imageFile, setImageFile] = useState(null)

  const clearImage = () => {
    setImagePreview('')
    setImageFile(null)
  }

  const onBrowseImage = (e) => {
    if (msgSend) setMsgSend('')

    const checkType = ['image/jpeg', 'image/png', 'image/gif']

    if (e.target.files[0] && checkType.includes(e.target.files[0].type)) {
      const reader = new FileReader()
      const file = e.target.files[0]

      reader.onloadend = () => {
        // setIsOpenCropImage(true)
        setImagePreview(reader.result)
      }

      reader.readAsDataURL(file)
      setImageFile(file)
    } else {
      alert('โปรดเลือกไฟล์ที่จะอัพโหลดให้ถูกต้อง')
    }
  }

  const onClickReply = () => {
    if (msgSend) {
      replyMsg(msgSend)
    } else if (imageFile) {
      replyImage(imageFile)
    }

    setMsgSend('')
    clearImage()
    closeModal()
  }

  return (
    <Modal isOpen={showModal} className='modal-medium'>
      <div className='modal-header reply'>
        <button className='btn-cloes btn' onClick={closeModal}>
          <i className='icon-close' />
        </button>
      </div>
      <ModalBody className='body-reply'>
        <div className='box-profile'>
          <img alt='' src={dataUser?.urlImg}></img>
          <h3>{dataUser?.name}</h3>
        </div>
        {imagePreview ? (
          <div className='box-preview'>
            <div className='box-image'>
              <img className='img-preview' alt='' src={imagePreview} />
              <div className='img-close' onClick={clearImage}>
                <img alt='' src={closeImage} className='clear-image' />
              </div>
            </div>
          </div>
        ) : (
          <textarea
            type='textarea'
            rows='4'
            className='form-control mr-1'
            onChange={(e) => setMsgSend(e.target.value)}
            value={msgSend}
          />
        )}

        <div className='box-reply-footer'>
          <button
            className={`${!!imagePreview && 'disabled'} btn-photo`}
            disabled={!!imagePreview}
          >
            <IPhoto className='icon' />
            <input
              type='file'
              onChange={onBrowseImage}
              accept='image/x-png, image/gif, image/jpeg'
              disabled={!!imagePreview}
            />
          </button>

          <button className='btn-reply' onClick={onClickReply}>
            <IReply className='icon' />
            ส่ง
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalReply
