/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect,useRef } from 'react'
import classnames from 'classnames'
import './styles.scss'

function useOutsideAlerter(ref,inputRef) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (!inputRef.current.contains(event.target) && !ref.current.contains(event.target)) {
                ref.current.classList.add('hide')
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const AutoCompleteInput = (props) => {
    const wrapperRef = useRef(null);
    const inputRef = useRef(null)
    useOutsideAlerter(wrapperRef,inputRef);
    // const optionsMock = [
    //     '1', '2', '3', '4', '5'
    // ]
    // eslint-disable-next-line no-unused-vars
    const [showOptions, setShowOptions] = useState(false)
    const { className, name, onChange, value, 
        // onFocus, 
        err, 
        // onSelect, 
        options } = props


    // const onFocusInput = (e) => {
    //     // wrapperRef?.current?.classList?.remove('hide')
    //     // console.log(`wrapper`, wrapperRef.current.classList.remove('hide'))
    //     // setShowOptions(true)
    // }

    // const onBlur = async (e) => {
    //     setShowOptions(false)
    // }

    // const onClickOutsideList = (e) => {
    //     console.log(`e.target`, e.target)
    // }

    const  onClickInput = () => {
        wrapperRef.current.classList.remove('hide')
    }

    const onSelectItem = (e,item) => {
        props.onSelect(item)
        // setShowOptions(false)
        wrapperRef.current.classList.add('hide')
    }

    return (
        <div id="auto-complete-input" >
            <input type="text"
                className={classnames(`${className} form-control`, { 'error-field': err })}
                name={name}
                onChange={(e) => onChange(e)}
                value={value}
                // onFocus={onFocusInput}
                // onBlur={(e) => onBlur(e)}
                autoComplete={'off'}
                ref={inputRef}
                onClick={() => onClickInput()}
            />

            {
                <div className="option-container hide" ref={wrapperRef}>
                    {options?.filter((elem) => elem.includes(value)).map((item) => (
                        <label onClick={(e) => onSelectItem(e,item)}> {item} </label>
                    ))}
                </div>
            }
        </div>
    )
}

AutoCompleteInput.defaultProps = {
    className: '',
    name: '',
    onChange: () => { },
    value: null,
    onFocus: () => { },
    err: false,
    onSelect: () => { },
    options: ['1', '2', '3', '4', '5']
}

export default AutoCompleteInput
