import React from 'react';
import Switch from 'antd/lib/switch';  // for js
import './index.scss';

const SwitchComponent = (props) => {

    const {checkedChildren, unCheckedChildren, checked,onChange} = props;

    return (
        <Switch checkedChildren={checkedChildren}
                unCheckedChildren={unCheckedChildren}
                checked={checked}
                onChange={onChange}
        />
    )


};


export default SwitchComponent;