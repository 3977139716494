/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import OptionInput from 'components/PeriodTimeInput/OptionInput';
import CalendarInput from 'components/PeriodTimeInput/CalendarInput';
import { displayDateTh } from 'helper/timeUtil'
import moment from "moment";
import { generatePeriodDate, options } from 'components/PeriodTimeInput/dataUtil';



import './style.scss';
import { set } from "js-cookie";
import PeriodDate from "../PeriodDate";

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */

const ModalOption = (props, ref) => {
    const {isOpen, periodDate, optionDate, optionTypeDisabled, allowTodayDate} = props;
    const { optionSelected,
            periodDateValue, 
            onOptionClick,
            onPeriodDateClick,
          } = useModalOption(props);

        //   useEffect(() => {
        //       if(optionDate.key === 'REAL_TIME'){
        //           return 
        //       } else {
        //         onOptionClick(optionDate)
        //       }
        //   }, [optionDate])
         
    const isCalendarTypeSelected = () => {
        if( optionSelected === options.REAL_TIME ||
            optionSelected === options.YESTERDAY || 
            optionSelected === options.PAST_SEVEN ||
            optionSelected === options.PAST_THIRTY )
            return false

            return true
    }
    


    return <div className={`modal-option fade3 ${isOpen ? 'show' : ''}`} ref={ref} >
                    <div className="content-box">
                        <div className="option">
                            <OptionInput optionSelected={optionSelected}
                                         onOptionClick={onOptionClick}
                                         optionTypeDisabled={optionTypeDisabled}
                                         options={options}
                            />
                        </div>
                        <div className="display">
                            <div className="separate-line"></div>
                            <div className="display-container">
                                { !isCalendarTypeSelected() &&
                                    <ul>
                                        <li className={`display-topic ${optionSelected === options.REAL_TIME ? 'show': ''}`}> {periodDate?.label} </li>
                                        <li className={`display-topic ${optionSelected === options.YESTERDAY ? 'show': ''}`}> {periodDate?.label} </li>
                                        <li className={`display-topic ${optionSelected === options.PAST_SEVEN ? 'show': ''}`}> {periodDate?.label} </li>
                                        <li className={`display-topic ${optionSelected === options.PAST_THIRTY ? 'show': ''}`}> {periodDate?.label} </li>
                                    </ul>
                                }
                                { optionSelected === options.WITHIN_DAY &&
                                  <CalendarInput   selectedDate={periodDateValue.period.from}
                                                   mode={"DAY"}
                                                   onDateClick={(date) => onPeriodDateClick(options.WITHIN_DAY,{startDate:date, endDate: date})} 
                                                   allowpreviousDate={true} 
                                                   dissableDateFuture={moment()}
                                                   allowTodayDate={allowTodayDate}
                                                   //    dissableDatePast={moment(new Date('1/1/2022'))}
                                                   /> 
                                }
                                { optionSelected === options.WITHIN_WEEK &&
                                  <CalendarInput   selectedDate={periodDateValue.period.from}
                                                   mode={"WEEK"}
                                                   onDateClick={(date) => onPeriodDateClick(options.WITHIN_WEEK,{startDate:date, endDate: date})} 
                                                   allowpreviousDate={true} 
                                                   dissableDateFuture={moment()}
                                                   //    dissableDatePast={moment(new Date('1/1/2022'))}
                                                   /> 
                                }
                                { optionSelected === options.WITHIN_MONTH &&
                                  <CalendarInput   selectedDate={periodDateValue.period.from}
                                                   mode={"MONTH"}
                                                   onDateClick={(date) => onPeriodDateClick(options.WITHIN_MONTH,{startDate:date, endDate: date})} 
                                                   allowpreviousDate={true} 
                                                   dissableDateFuture={moment()}
                                                   //    dissableDatePast={moment(new Date('1/1/2022'))}
                                                   /> 
                                }
                                { optionSelected === options.WITHIN_YEAR &&
                                  <CalendarInput   selectedDate={periodDateValue.period.from}
                                                   mode={"YEAR"}
                                                   onDateClick={(date) => onPeriodDateClick(options.WITHIN_YEAR,{startDate:date, endDate: date})} 
                                                   allowpreviousDate={true} 
                                                   dissableDateFuture={moment()}
                                                   //    dissableDatePast={moment(new Date('1/1/2022'))}
                                                   /> 
                                }
                                 { optionSelected === options.MANUAL &&
                                   <PeriodDate  selectedDate={{startDate: periodDateValue.period.from, endDate: periodDateValue.period.to}}
                                                onDateClick={(date, isFinish) => 
                                                             onPeriodDateClick(options.MANUAL,date, isFinish)}/>
                                }
                            </div>
                        </div>
                    </div>
            </div>
}

/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const useModalOption = (props) => {
   const [optionSelected, setOptionSelected] = useState(options.REAL_TIME);
   const {onDateSelected, setIsToggle, isSubtract, allowTodayDate, periodDate,optionDate} = props;
   const defaultDate = {startDate:moment(), endDate: moment()};
   const [periodDateValue, setPeriodDateValue] = useState(generatePeriodDate(options.REAL_TIME,defaultDate));

   useEffect(() => {
     if(periodDate) {
        setPeriodDateValue(periodDate);
     }
   }, [periodDate])

   useEffect(() => {
       if (!!optionDate?.key && !!optionDate?.label) {
           setOptionSelected(optionDate)
       }
    }, [optionDate])
    

   const onOptionClick = (option) => {
    setOptionSelected(option);
    if(option === options.REAL_TIME){
       setIsToggle(false);
       onDateSelected(generatePeriodDate(options.REAL_TIME, defaultDate));
    }
    if(option === options.YESTERDAY) {
        setIsToggle(false);
        onDateSelected(generatePeriodDate(options.YESTERDAY, defaultDate));
    }
    if(option === options.PAST_SEVEN) {
        setIsToggle(false);
        onDateSelected(generatePeriodDate(options.PAST_SEVEN, defaultDate, isSubtract));
    }
    if(option === options.PAST_THIRTY) {
        setIsToggle(false);
        onDateSelected(generatePeriodDate(options.PAST_THIRTY, defaultDate));
    }

    if(option === options.WITHIN_DAY) {
         setPeriodDateValue(generatePeriodDate(options.WITHIN_DAY, defaultDate));
    }
    if(option === options.WITHIN_WEEK) {
        setPeriodDateValue(generatePeriodDate(options.WITHIN_WEEK, defaultDate));
    }
    if(option === options.WITHIN_MONTH) {
        setPeriodDateValue(generatePeriodDate(options.WITHIN_MONTH, defaultDate));
    }
    if(option === options.WITHIN_YEAR) {
        setPeriodDateValue(generatePeriodDate(options.WITHIN_YEAR, defaultDate));
    }
    if(option === options.MANUAL) {
        setPeriodDateValue(generatePeriodDate(options.MANUAL, defaultDate));
    }
    
    
   }

   const onPeriodDateClick = (periodType, periodDate, isFinish=true) => {
       if(isFinish === true) {
           onDateSelected(generatePeriodDate(periodType, periodDate, allowTodayDate));
           setIsToggle(false);
       }
   }
   
   return {optionSelected, 
           periodDateValue,
           setOptionSelected,
           onPeriodDateClick,
           onOptionClick}
}

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default React.forwardRef(ModalOption)
