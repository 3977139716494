/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect, useSelector } from 'react-redux'
import {
  dispatchGallery,
  CREATE_MEDIA_GALLERY,
  GET_ALBUM_DEFAULT,
  SET_GALLERY_FIELD,
} from '../../../redux/actions/gallery'
import { ModalLarge, ModalLoading, Modals } from 'components/output'
import { Album, BrowseImage, BrowseVideo, Media } from 'components/Gallery'
import host from '../../../config/host'

const ModalUploadMedia = (props) => {
  // fix
  const {
    isOpenModal,
    closeModal,
    dataTabs,
    isShowTabs,
    cardType,
    limitImageUpload,
    dispatch,
    albumDetail,
    shopDetail,
    albumDefault,
    title,
    labelCancel,
    labelButton,
    labelResetMedia,
    fieldUpload,
    // setLoading,
    isUploading,
    mediaData,
    targetAlbum, /// if u want to upload file to other album (not default album)
    // setLoading,
    validateWidthHeight
  } = props

  let content = null

  const mediaUploadEvent = useSelector((state) => state.gallery[fieldUpload])

  const [tabActive, setTabActive] = useState('browse')
  const [statusContent, setStatusContent] = useState('browse')

  const [listFile, setListFile] = useState([])
  const [tmpAlbumDetail, setTmpAlbumDetail] = useState(null)
  const [tmpMediaSelect, setTmpMediaSelect] = useState([])
  const [VDO, setVDO] = useState(null)
  const [modalError, setModalError] = useState({
    isOpen: false,
    messageError: '',
  })

  const [isLoading, setIsLoading] = useState(false)
  const [progressPercent, setProgressPercent] = useState(0)

  useEffect(() => {
    if (mediaData && !isUploading) setIsLoading(false)
  }, [mediaData])

  useEffect(() => {
    getDefaultAlbum()
  }, [shopDetail.shopId])

  useEffect(() => {
    if (dataTabs.length) {
      setTabActive(dataTabs[0].key)
      setStatusContent(dataTabs[0].key)
    }

    getDefaultAlbum()
  }, [dataTabs])

  const getDefaultAlbum = () => {
    if (!albumDetail && shopDetail.shopId) {
      dispatch(
        dispatchGallery({
          type: GET_ALBUM_DEFAULT,
          payload: { shopId: shopDetail.shopId },
        }),
      )
    }
  }

  const handleSubmit = async () => {
    if (listFile.length > 0) {
      if (cardType === 'video') {
        if (listFile[0].size > 2000000000) {
          return setModalError({
            isOpen: true,
            messageError: 'ขนาดไฟล์สูงสุด 2 GB',
          })
        }

        const vdo = document.getElementById('video-preview')
        const height = vdo.videoHeight
        const width = vdo.videoWidth

        if (
          (height !== 1280 || width !== 720) &&
          (height !== 720 || width !== 1280) &&
          (height !== 1920 || width !== 1080) &&
          (height !== 1080 || width !== 1920)
        ) {
          return setModalError({
            isOpen: true,
            messageError:
              'ขนาดวิดีโอมาตราฐานที่แนะนำคือ 1280×720 และ 1920x1080 พิกเซล ขนาดไฟล์สูงสุด 2 GB',
          })
        }
      }

      // dispatch(
      //   dispatchGallery({
      //     type: SET_GALLERY_FIELD,
      //     payload: { key: 'isUploading', value: true },
      //   }),
      // )
      setIsLoading(true)

      ////// มาจาก flow upload ธรรมดา เลือกจากคลัง
      const path = `${host.apiMedia}/upload`

      listFile.map(async (file) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('serviceKey', host.serviceKey)
        formData.append('secretKey', host.secretKey)

        const config = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            )
            setProgressPercent(percentCompleted)
          },
        }

        try {
          const upload = await axios.post(path, formData, config)
          let data

          if (albumDetail) {
            data = {
              name: upload.data.originalFileName,
              albumId: albumDetail.id,
              shopId: albumDetail.shopId,
              type: cardType,
              size: upload.data.size * 0.000001,
              path: `${cardType == 'image' ? cardType : 'vdo'}/${
                upload.data.fileName
              }`,
              fileName: upload.data.fileName,
              systemPath: upload.data.path,
            }
          } else {
            data = {
              name: upload.data.originalFileName,
              albumId: albumDefault.id,
              shopId: albumDefault.shopId,
              type: cardType,
              size: upload.data.size * 0.000001,
              path: `${cardType == 'image' ? cardType : 'vdo'}/${
                upload.data.fileName
              }`,
              fileName: upload.data.fileName,
              systemPath: upload.data.path,
            }
          }

          if(targetAlbum) data.albumId = targetAlbum

          // setIsLoading(false)
          dispatch(
            dispatchGallery({
              type: CREATE_MEDIA_GALLERY,
              payload: data,
              etc: {
                type: cardType,
                fieldUpload: fieldUpload,
                dispatch: dispatch,
              },
            }),
          )
        } catch (error) {
          setModalError({
            isOpen: true,
            messageError:
              'เกิดข้อผิดพลาดกรุณาลองอีกครั้ง',
          })
        }
      })

      closeModal()
      setListFile([])
      setVDO(null)
    } else {
      ////// มาจาก flow เลือกจากคลังงงงงงง
      if (fieldUpload) {
        dispatch(
          dispatchGallery({
            type: SET_GALLERY_FIELD,
            payload: { key: fieldUpload, value: tmpMediaSelect },
          }),
        )
      }
      closeModal()
    }
  }

  const onloadLargeFile = (file) => {
    setIsLoading(true)

    let reader = new FileReader()
    reader.readAsArrayBuffer(file)

    reader.onload = function (e) {
      let buffer = e.target.result

      let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' })

      let url = URL.createObjectURL(videoBlob)

      setVDO({ name: file.name, preview: url })
      setIsLoading(false)
    }
  }

  const onClickBrowseVideo = async (e) => {
    const checkType = ['video/mp4', 'video/x-m4v', 'video/*']

    if (e.target.files[0] && checkType.includes(e.target.files[0].type)) {
      const file = e.target.files[0]

      onloadLargeFile(file)

      setListFile([file])
    } else {
      setModalError({
        isOpen: true,
        messageError: 'โปรดเลือกไฟล์ที่จะอัพโหลดให้ถูกต้อง',
      })
    }
  }

  const onClickTab = (key) => {
    setTabActive(key)
    setStatusContent(key)
  }

  const onClickAlbum = (data) => {
    setTmpAlbumDetail(data.albumDetail)
    setStatusContent('media')
  }

  const onCloseModalError = () => {
    setModalError({
      isOpen: false,
      messageError: '',
    })
  }

  switch (statusContent) {
    case 'browse':
      content =
        cardType === 'image' ? (
          <BrowseImage
            limitImageUpload={limitImageUpload}
            mediaUploadEvent={mediaUploadEvent}
            setListFile={setListFile}
            listFile={listFile}
            validateWidthHeight={validateWidthHeight}
          />
        ) : (
          <BrowseVideo
            // setListFile={setListFile}
            VDO={VDO}
            setVDO={setVDO}
            mediaUploadEvent={mediaUploadEvent}
            onClickBrowseVideo={onClickBrowseVideo}
          />
        )
      break

    case 'albums':
      content = <Album clickAlbum={onClickAlbum} shopDetail={shopDetail} />
      break

    case 'media':
      content = (
        <Media
          cardType={cardType}
          albumDetail={tmpAlbumDetail}
          setTmpMediaSelect={setTmpMediaSelect}
          mediaUploadEvent={mediaUploadEvent}
          fieldUpload={fieldUpload}
          limitImageUpload={limitImageUpload}
          validateWidthHeight={validateWidthHeight}
        />
      )
      break

    default:
      break
  }

  return (
    <>
      <ModalLarge
        title={title}
        closeModal={closeModal}
        clickSubmit={handleSubmit}
        labelCancel={labelCancel}
        labelButton={labelButton}
        labelResetMedia={labelResetMedia}
        clickInput={onClickBrowseVideo}
        isOpenModal={isOpenModal}
        dataTabs={dataTabs}
        tabActive={tabActive}
        isShowTabs={isShowTabs}
        clickTab={onClickTab}
        disabled={
          listFile.length > 0 ? false : tmpMediaSelect.length > 0 ? false : true
        }
        isShowFooter
        setVDO={setVDO}
        setListFile={setListFile}
      >
        {content}
      </ModalLarge>
      {isLoading && (
        <ModalLoading isOpenModal={isLoading} progress={progressPercent} />
      )}
      {/* {isUploading && <ModalLoading isUploadingModal={isUploading} />} */}
      <Modals
        title='อัพโหลดไฟล์'
        closeModal={onCloseModalError}
        clickButton={onCloseModalError}
        labelButton='ตกลง'
        labelCancel='ยกเลิก'
        isOpenModal={modalError.isOpen}
      >
        <h5 className='text-center p-3'>{modalError.messageError}</h5>
      </Modals>
    </>
  )
}

ModalUploadMedia.defaultProps = {
  title: 'เพิ่มรูปภาพ',
  labelCancel: '',
  labelButton: 'ยืนยัน',
  labelResetMedia: '',
  cardType: 'image',
  dataTabs: [],
  isOpenModal: false,
  isShowTabs: false,
  closeModal: () => {},
  limitImageUpload: 4,
  fieldUpload: null,
}

const mapStateToProps = (state) => ({
  albumDefault: state.gallery.albumDefault,
  isUploading: state.gallery.isUploading,
  mediaData: state.gallery.mediaData,
})

export default connect(mapStateToProps, null)(ModalUploadMedia)
