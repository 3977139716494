/* eslint-disable no-useless-constructor */
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
// import '../styles/modal.scss'
// import '../styles/modalMessage.scss'
import './index.scss'
import Success from '../../images/icons/success.svg'
import Alert from '../../images/icons/alert.svg'


class ModalMessage extends React.Component {


  static defaultProps = {
    isShowModalMessage: false,
    title: "",
    message: "",
    footerMessage: "",
    btnLabel : "ตกลง",
    infoMsg : ""

  }


  constructor(props) {
    super(props);
    this.state = {
      //   isShowModalMessage : false,
      //   title : "หมุดห่างจาก ที่อยู่ที่คุณค้นหา มากเกินไป",
      //   message : "กรุณาเลื่อนหมุดให้ใกล้เคียงกับที่อยู่ที่คุณค้นหา"

    };
  }

  toggleModalMessage = () => {
    this.props.closeModalMessage()
  }

  confirm = () => {
    try {
      this.props.confirmCallback()
    } catch (ex) {
      
    }
  }

  cancel = () => {
    try {
      this.props.cancelCallback()
    } catch (ex) {
      
    }
  }

    showIconMsg = (title) => {
      if(title === "iconSuccess"){
        return <img src={Success} alt=""/>
      } else if(title === "iconFail"){
        return <img src={Alert} alt=""/>
      } else if(title === "iconRemind"){
        return <i className="icon-remind-circle-outline icon-cancel-message" />
      } else if(title === "receiveOrder"){
        return <i className="icon-confirm-product icon-confirm-receive"></i>
      }  
      else {
        return title
      }
    }




  render() {
    const { title, message, isShowModalMessage, footerMessage ,btnLabel ,infoMsg,btnCancel } = this.props
    return (
      <Modal
        isOpen={isShowModalMessage}
        className="modal-message" size={"sm"}>
        <ModalBody>
          <h3 className="title"> {this.showIconMsg(title)}</h3>

          {
            infoMsg ?
              <label className="info-message">{infoMsg}</label>
              : null
          }
        <div className="d-flex flex-column align-items-center text-center">
        {
            message ?
              <label className="message">{message}</label>
              : null
          }
          {
            footerMessage ?
              <label className="footer-message">{footerMessage}</label>
              : null
          }
          <div className='d-flex flex-row-reverse mt-4 gap-2'>
            <button onClick={this.confirm} className="btn-submit" >{btnLabel }</button>
            {btnCancel ?  <button onClick={this.cancel} className="btn-cancel" > {btnCancel} </button> : null}
          </div>
        </div>
          
        </ModalBody>
      </Modal>
    );
  }
}



export default ModalMessage

