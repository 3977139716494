import { Body1, Body2, H1, H5, H6 } from 'components/FontStyle'
import ModalPreviewMassUpload from 'components/ModalPreviewMassUpload';
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux";
import '../styles/_massUploadExcel.scss';
import TemplateImportProduct from 'file/template-import-product.xlsx'
import { ReactComponent as IconDownload } from 'images/icons/IconDownload.svg';

import UploadXlsx from 'components/UploadXlsx';
import ModalAlert from 'components/modalAlert/ModalAlert';
import {
    dispatchModalAlert,
    OPEN_MODAL_ALERT,
} from 'redux/actions/modalAlert';
import { dispatchApp, SET_LOADING } from 'redux/actions/app';
import useSelection from 'antd/lib/table/hooks/useSelection';
import massUploadProvider from 'provider/MassUploadProvider'
import {
    dispatchShop,
    GET_ALL_SHOPS,
} from 'redux/actions/shop';
import { displayDateThShortYear } from 'helper/timeUtil';
import { UncontrolledTooltip } from 'reactstrap';
import { ReactComponent as IconHint } from 'images/icons/hint.svg'
import host from 'config/host';
import axiosNew from 'config/axios';
import Cookies from 'js-cookie';
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const MassUploadExcel = (props) => {

    const { handlePreviewProductXlsx,
        isOpenModalPreviewMassUpload,
        setIsOpenModalPreviewMassUpload,
        productXlsx,
        downloadTemplateExcel,
        shopList,
        isLoading,
        page,
        skip,
        hasMore,
        listDownload,
        setPage,
        setSkip,
        fileXlsx,
        shopId,
        setShopId,
        setStatusUploaded,
        statusUploaded,
        clearFileExcel,
        getListDownload,
        lastDownloadElement,
        showErrorUpload,
        handleDownloadExcel,
        setListDownload,
        errorUpload,
        handleSubmitAlert,
        submitAction
    } = useMassExcelUpload(props);



    return (
        <div id='mass-upload-excel'>

            <H5>เพิ่มสินค้าแบบชุด</H5>
            <div className='d-flex flex-row justify-content-between align-items-center bg-white'>
                <label className='d-flex flex-column'>
                    <H6>ดาวน์โหลดแบบฟอร์ม</H6>
                    <Body1>กรุณาดาวน์โหลดแบบฟอร์ม เพื่อกรอกข้อมูลที่จำเป็นสำหรับอัปโหลดสินค้าของคุณ</Body1>
                </label>
                <a id="my_download" href={TemplateImportProduct} download="Template_V-Avenue_v1" className='d-none' />
                <button className='btn-cancel' onClick={downloadTemplateExcel} style={{ height: '3em' }}> <IconDownload className='icon-download' /> ดาวน์โหลดแบบฟอร์ม</button>
                {/* <a href={resume} download="YourName resume.pdf"> Download CV </a> */}
            </div>

            <div className='bg-white d-flex flex-column'>
                <H6>อัปโหลด</H6>
                <Body1>อัปโหลดแบบฟอร์มที่แก้ไขเสร็จแล้ว คุณสามารถตรวจสอบสินค้าที่อัปโหลดสำเร็จแล้วที่แถบ “ยังไม่ลงขาย” ในหน้าสินค้า</Body1>
                <UploadXlsx onFileChange={(file, data) => handlePreviewProductXlsx(file, data)} />
                {/* /////////////////////////////////// component upload ////////////////////////////*/}
            </div>

            <div className='bg-white d-flex flex-column'>
                <H6>ประวัติการอัปโหลด</H6>
                <div className='d-flex flex-row justify-content-between'>
                    <Body1>แสดงรายการอัปโหลด 30 วันล่าสุด สูงสุดไม่เกิน 100 ครั้ง</Body1>
                    <Body2 className='color-primary cursor-pointer' onClick={() => props.history.push({
                        pathname: '/manage/product',
                        state: {
                            activeTab: 'hide'
                        }
                    })}>{`ตรวจสอบรายละเอียดสินค้า >`}</Body2>
                </div>

                <div className='table-mass-upload-header width-table-mass-upload'>
                    <div>ลำดับ</div>
                    <div>วันที่อัปโหลด</div>
                    <div>ชื่อไฟล์</div>
                    <div>ชื่อร้านค้า</div>
                    <div>จำนวนสินค้า</div>
                    <div>สถานะ</div>
                    <div></div>
                </div>

                <div id='infinite-list'>
                    {listDownload.map((item, index) => {
                        return (
                            <div className="table-mass-upload-content width-table-mass-upload" key={index} ref={lastDownloadElement}>
                                <div>{index + 1}</div>
                                <div>{displayDateThShortYear(item?.createdAt)} {displayDateThShortYear(item?.createdAt, 'HH:mm')}</div>
                                <div>{item?.originalName}</div>
                                <div>{item?.shop?.name}</div>
                                <div>{item?.totalItem}</div>
                                <div className=''>{showErrorUpload(errorUpload[item?.issueNote?.message] || item?.issueNote?.message, item?.issueNote?.row, item.id)}</div>
                                <div>{item.status === 'success' && <span className='cursor-pointer color-upload-status-success' style={{ textDecoration: 'underline' }} onClick={() => { handleDownloadExcel(item.shop.id, item.fileName, item.originalName) }}>Download</span>}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {/* <H1>...isLoading</H1> */}
            {isLoading && <H1>...isLoading</H1>}

            <ModalPreviewMassUpload isOpen={isOpenModalPreviewMassUpload}
                data={productXlsx}
                shopList={shopList}
                toggle={() => setIsOpenModalPreviewMassUpload(!isOpenModalPreviewMassUpload)}
                fileXlsx={fileXlsx}
                shopId={shopId}
                setShopId={setShopId}
                setStatusUploaded={(status) => setStatusUploaded(status)}
                statusUploaded={statusUploaded}
                clearFileExcel={clearFileExcel}
                isFromShop={props?.location?.state?.isFromShop}
                getListDownload={getListDownload}
                setListDownload={setListDownload}
                errorUpload={errorUpload}
                setPage={setPage}
                mode={"create"}
            />
            <ModalAlert onSubmit={() => handleSubmitAlert(submitAction)} />
        </div>
    )
}

export const useMassExcelUpload = props => {

    const errorUpload = {
        DUPLICATE_DATA: 'มีข้อมูลซ้ำในระบบ',
        REQUIRED_FIELD: 'กรอก required field ไม่ครบ',
        DATA_OVER_LIMIT: 'ข้อมูลเกิน 100 รายการ',
        DATA_NOT_FOUND: 'ไม่พบ data',
        OPTIONS_2_OR_NAME_OPTIONS_2: 'ไม่ได้ใส่ ชื่อตัวเลือก2 หรือ ตัวเลือก 2',
        SELLER_SKU_DUPLICATE: 'มี sellerSKU ซ้ำ',
        SKU_DUPLICATE: 'มีตัวเลือก sku ซ้ำใน file',
        ATTRIBUTE_DUPLICATE: 'มี Product ที่มีชื่อตัวเลือก SKU ที่ 1 และ ชื่อตัวเลือก SKU ที่ 2 ซ้ำกันใน file',
        ATTRIBUTE_MUST_BE_SAME: 'ชื่อตัวเลือกสินค้า ต้องใช้ชื่อเดียวกัน ในกลุ่มตัวเลือก SKU ที่เหมือนกัน'
    }
    const currentShop = useSelector(state => state.shop.currentShop)

    const [isOpenModalPreviewMassUpload, setIsOpenModalPreviewMassUpload] = useState(false);
    const [productXlsx, setProductXlsx] = useState([]);
    const [shopList, setShopList] = useState([]);
    const [page, setPage] = useState(1)
    const [skip, setSkip] = useState(10)
    const [isLoading, setIsLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [shopId, setShopId] = useState(null)
    const [fileXlsx, setFileXlsx] = useState(null)
    const [statusUploaded, setStatusUploaded] = useState(null) /* cancel, fail, uploaded, validateFail */
    const [listDownload, setListDownload] = useState([])
    const [submitAction, setSubmitAction] = useState({ action: '', payload: {} }); /* 'Error': show modal xlsx with error */

    const rowLimit = 100;
    const dispatch = useDispatch();
    const stateShop = useSelector(state => state.shop);
    const { allShops } = stateShop;

    const observer = useRef();
    const lastDownloadElement = useCallback(node => {
        if (isLoading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting
                && hasMore
            ) {
                setPage(page => page + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [isLoading, hasMore])

    useEffect(() => {
        getAllShops();
        // getListDownload();
        return () => {
            // setHasMore(false)
        }
    }, [])

    useEffect(() => {

        if (allShops && Array.isArray(allShops)) {
            const _shopList = allShops.map(shop => ({ label: shop.name, value: shop }));
            setShopList(_shopList);
            if (props.location?.state?.isFromShop) return
            setShopId(_shopList[0]?.value?.id)
        }
    }, [allShops]);

    // useEffect(() => {
    //     // setSkip(prevSkip => prevSkip + 10)
    //     // setPage(prevPage => prevPage + 1)
    //     setPage(page + 1)
    //     console.log('page', page)
    //     // getListDownload()
    //     setHasMore(true)
    // }, [listDownload])

    useEffect(() => {
        getListDownload()
        return () => {
        }
    }, [page])

    useEffect(() => {
        (async () => {
            switch (statusUploaded) {
                case 'uploaded':
                case 'fail':
                    await setPage(1)
                    await setListDownload([])
                    if (page === 1) {
                        await getListDownload()
                    }
                    break;
                case 'cancel':
                    // await setPage(1)
                    // await setListDownload([])
                    // await getListDownload()
                    break;
                default:
                    break;
            }
        })()
        return () => {
        }
    }, [statusUploaded])

    const clearFileExcel = () => {
        document.getElementById('file').value = ''
    }

    /* Convert data to spread sheet format */
    const handlePreviewProductXlsx = async (file, data) => {
        try {
            setLoading(true);
            setStatusUploaded(null);

            if (data.error) throw data.error;

            const dataXlxs = provideDataRow(data);

            /* validate file xlsx */
            const validateXlsxFile = new ValidateXlsxFile(dataXlxs);

            const validateAll = await validateXlsxFile.validateAll();
            setLoading(false);

            if (typeof validateAll === 'object')
                throw validateAll;

            openModalPreview(dataXlxs.data, file);
        } catch (error) {
            setStatusUploaded('validateFail');
            setLoading(false);
            if (error.name === errorStatusXlsx.InvalidNumberColumn.name) {
                openAlertModal(errorStatusXlsx.InvalidNumberColumn.message);
            }
            if (error.name === errorStatusXlsx.InvalidFileType.name) {
                openAlertModal(errorStatusXlsx.InvalidFileType.message);
            }
            if (error.name === errorStatusXlsx.EmptyData.name) {
                openAlertModal(errorStatusXlsx.EmptyData.message);
            }
            if (error.name === errorStatusXlsx.RowOverLimit.name) {
                openAlertModal(errorStatusXlsx.RowOverLimit.message);
            }
            if (error.name === errorStatusXlsx.RowError.name) {
                openAlertModal(errorStatusXlsx.RowError.message);
                setSubmitAction({ action: 'Error', payload: { rows: error.rows } })
            }

        }
    }

    /* handle action after submit button on alert modal */
    const handleSubmitAlert = ({ action, payload }) => {
        if (action === 'Error') {
            openModalPreview(payload.rows);
            setSubmitAction({ action: '', payload: {} });
        }
    }

    const openModalPreview = (rows, file) => {
        setFileXlsx(file)
        setProductXlsx(rows);
        setIsOpenModalPreviewMassUpload(true);
    }

    /* Provide data form xlxs original file */
    const provideDataRow = (data) => {

        /* validate header column */
        if(data[3].length !== 29)
            throw ( errorStatusXlsx.InvalidNumberColumn )

        /* remove empty rows and remove topic row */
        let removeEmptyRow = data.reduce((rows, row, index) => {
            if (row.length > 0 &&
                index != 4 &&
                index > 2) {

                if (row.length < 29) {
                    for (let i = row.length - 1; i < 28; i++) {
                        row.push('');
                    }
                }
                rows = [...rows, row];
            }

            return rows;
        }, []);

        /* validate row data is between 1 - 100 rows */
        if ((removeEmptyRow.length - 1) === 0) {
            throw ({
                name: errorStatusXlsx.EmptyData.name,
                rowCount: removeEmptyRow.length - 1
            })
        }
        /* validate row data is over 100 rows */
        if ((removeEmptyRow.length - 1) > rowLimit) {
            throw ({
                name: errorStatusXlsx.RowOverLimit.name,
                rowCount: removeEmptyRow.length - 1
            })
        }

        /* convert data to react spreadsheet format */
        const newDataFormated = removeEmptyRow.map((row, rowIndex) => {
            return convertRowToSpreadSheet(row, rowIndex);
        });

        return { data: newDataFormated, rowCount: removeEmptyRow.length - 1 };
    }

    /* Convert data to spread sheet format */
    const convertRowToSpreadSheet = (row, rowIndex) => {
        let newRow = [];
        try {
            for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {

                let content = row[cellIndex];
                let classNames = '';

                if (rowIndex === 0) classNames = 'table-content text-center bg-header'; /* render header style */
                else classNames = 'table-content content text-center'; /* render body style */



                const columnImage = [8, 14, 15, 16, 17, 18, 19, 20, 21, 22]; /* image column */

                /* Insert picture if data is image url */
                if (typeof content === 'string' && rowIndex > 0) {
                    if (columnImage.indexOf(cellIndex) > -1) {
                        content = <img src={content} />;
                    }
                }
                if (content === '' || content === undefined) {
                    /* empty element */
                    newRow = [...newRow, { value: '', className: classNames }];
                } else {
                    newRow = [...newRow, { value: content, className: classNames }];
                }
            }

            return newRow;
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (props.location?.state?.isFromShop) {
            // const findShop = shopList.find((shop) => shop.value.id === currentShop.id)
            setShopId(currentShop?.id)
        }
        return () => {
            // setShopId('')
        }
    }, [props.location?.state?.isFromShop])


    let isFirstTime = true
    useEffect(() => {
        if (!isFirstTime) {
            isFirstTime = false
            setIsOpenModalPreviewMassUpload(false)
            setFileXlsx(null)
            setProductXlsx([])
            setStatusUploaded(null)
            clearFileExcel()
        }
        return () => {
            // setStatusUploaded(null)
        }
    }, [statusUploaded === 'uploaded' || !isOpenModalPreviewMassUpload])

    const openAlertModal = (message) => {
        dispatch(
            dispatchModalAlert({
                type: OPEN_MODAL_ALERT,
                payload: {
                    isOpen: true,
                    message: message,
                    type: 'alert',
                },
            }),
        )
    }

    const setLoading = (status) => {
        dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: status } }))
    }

    const getAllShops = () => {
        dispatch(
            dispatchShop({
                type: GET_ALL_SHOPS,
                payload: {},
            }),
        )
    }

    const downloadTemplateExcel = () => {
        document.getElementById("my_download").click()
    }

    const getListDownload = async () => {
        const res = await massUploadProvider.getListUploadExcel({ page, skip })
        if (res) {
            if (res.length === 0) {
                setHasMore(false)
            } else {
                setHasMore(true)
                setListDownload(prevDownload => [...prevDownload, ...res])
            }
        }
    }

    const showErrorUpload = (msg, row, id) => {

        if (msg || row) {
            return (
                // <div className='p-0 icon-hint'>
                //     <div className='p-0'>
                //         <p><span className='color-red icon-hint'>อัพโหลดไม่สำเร็จ</span> <span className='cursor-pointer' style={{ color: 'red' }} href="#" id={`showError${id}`}><IconHint /></span></p>
                //         <UncontrolledTooltip placement="top" target={`showError${id}`}>
                //             <span>อัพโหลดไม่สำเร็จเนื่องจาก</span> <br />
                //             {row && <span>สินค้าในแถว : {row}</span>}<br />
                //             <span>{msg}</span>
                //         </UncontrolledTooltip>
                //     </div>
                // </div>
                // <div className='tootip'>
                //     <OverlayTrigger placement="top" overlay={renderTooltip}>
                //         <span>sad</span>
                //     </OverlayTrigger>
                // </div>
                <div className='d-flex flex-row align-items-center justify-content-center p-0'>
                    <span className='color-red icon-hint mr-1'>อัพโหลดไม่สำเร็จ</span>
                    <div className='position-relative tooltip-mass-upload p-0'>
                        {/* <p><p className='color-red icon-hint'>อัพโหลดไม่สำเร็จ</p> <p className='cursor-pointer' style={{ color: 'red' }} href="#" id={`showError${id}`}><IconHint /></p></p> */}
                        <span class="tooltiptext">
                            <span>อัพโหลดไม่สำเร็จเนื่องจาก</span> <br />
                            {row && <span>สินค้าในแถว : {row}<br /></span>}
                            <span>{msg}</span>
                        </span>
                        <label htmlFor="" className='icon-hint'>
                            <div className='p-0'><IconHint className='cursor-pointer icon-hint' /></div>
                        </label>
                    </div>
                </div>


            )
        } else {
            return (
                <div className='color-upload-status-success p-0'>อัพโหลดสำเร็จ</div>
            )
        }
    }

    const handleDownloadExcel = async (shopId, fileName, originalName) => {
        // await massUploadProvider.getDownloadExcel(shopId,fileName)

        axiosNew.get(`${host.api}/product-attachments/download?shopId=${shopId}&fileName=${fileName}`, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${Cookies.get('token')}`,

            },
            responseType: 'arraybuffer',
        }).then((response) => {
            const temp = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = temp;
            link.setAttribute('download', `${originalName}`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    return {
        handlePreviewProductXlsx,
        isOpenModalPreviewMassUpload,
        setIsOpenModalPreviewMassUpload,
        productXlsx,
        downloadTemplateExcel,
        shopList,
        page,
        skip,
        isLoading,
        hasMore,
        listDownload,
        setPage,
        setSkip,
        fileXlsx,
        shopId,
        setShopId,
        setStatusUploaded,
        statusUploaded,
        clearFileExcel,
        getListDownload,
        lastDownloadElement,
        showErrorUpload,
        handleDownloadExcel,
        errorUpload,
        handleSubmitAlert,
        submitAction
    }
}

export default MassUploadExcel

const errorStatusXlsx =       { InvalidNumberColumn: {name:'InvalidNumberOfColumn',message:'จำนวนคอลลั่มไม่ถูกต้อง'},
                                EmptyData: {name:'EmptyData',message:'ไม่มีข้อมูล'},
                                ImageDoesNotExist: {name:'ImageDoesNotExist',message:'URL รูปภาพไม่ถูกต้อง'},
                                InvalidNumber: {name:'InValidNumber',message:'รูปแบบตัวเลขไม่ถูกต้อง'},
                                InvalidNumberString: {name:'InvalidNumberString',message:'รูปแบบของบาร์ดโค๊ดไม่ถูกต้อง'},
                                InvalidNumberAndLetter: {name:'InvaLidNumberAndLetter',message:'รูปแบบของ SKU ไม่ถูกต้อง'},
                                InvalidSpecialAndLetter: {name:'InvaLidSpecialAndLetter',message:'รูปแบบของแบรนด์ไม่ถูกต้อง'},
                                RowError: {name:'RowError',message:'ข้อมูลไม่ถูกต้อง'},
                                InvalidFileType: {name: 'InvalidFileType', message: 'ชนิดของไฟล์ไม่ถูกต้อง'}, 
                                EmptyData: {name: 'EmptyData', message: 'ไม่มีข้อมูล'},
                                RowOverLimit: {name: 'RowOverLimit', message: 'ไม่สามารถอัปโหลดไฟล์ได้เนื่องจากไฟล์มีสินค้าเกิน 100 รายการ'},
                                LengthTextOverLimit: {name: 'LengthTextOverLimit', message: 'ความยาวตัวอักษรเกินกว่าที่กำหนด'},
                                DuplicateSellerSKU: {name: 'DuplicateSellerSKU', message: 'เลขอ้างอิงตัวเลือกสินค้าซ้ำกัน'},
                                DuplicateOptionKey: {name: 'DuplicateOptionKey', message: 'ชื่อตัวเลือกสินค้าซ้ำกัน'},
                                DuplicateOptionWithinKey1: {name: 'DuplicateOptionWithinKey1', message: 'ชื่อตัวเลือก 1 ต้องมีค่าเดียวกัน'},
                                DuplicateOptionWithinKey2: {name: 'DuplicateOptionWithinKey2', message: 'ชื่อตัวเลือก 2 ต้องมีค่าเดียวกัน'},
                                DuplicateOptionValue: {name: 'DuplicateOptionValue', message: 'ตัวเลือกสินค้าซ้ำกัน'},
                              }
export class ValidateXlsxFile {

    constructor(fileXlsx) {
        this.fileXlsx = fileXlsx;
        this.columnName = ['หมวดหมู่สินค้า', 'แบรนด์', 'ชื่อสินค้า', 'รายละเอียดสินค้า', 'เลขอ้างอิงตัวเลือกสินค้า (Seller SKU)', 'รหัสบาร์โค้ด', 'ชื่อตัวเลือก 1', 'ตัวเลือก 1', 'ภาพตัวเลือก', 'ชื่อตัวเลือก 2', 'ตัวเลือก 2', 'ราคาปกติ', 'ราคาที่ประกาศขาย', 'สต็อก', 'ภาพสินค้า', 'รูปภาพ 1', 'รูปภาพ 2', 'รูปภาพ 3', 'รูปภาพ 4', 'รูปภาพ 5', 'รูปภาพ 6', 'รูปภาพ 7', 'รูปภาพ 8', 'น้ำหนัก (กก)', 'ความยาว (ซม)', 'ความกว้าง (ซม.)', 'ความสูง (ซม.)', 'ระยะเวลาเตรียมการจัดส่ง (ชม.)', 'Standard Delivery ']
    }

    /* get all sellerSKU from each row */
    getSellerSKUList = () => {
        const sellerSKUList = this.fileXlsx.data.reduce((total, row, rowIndex) => {
            const element = row.filter((col, colIndex) => colIndex === 4);

            /* find the element that is same value */
            if (rowIndex !== 0) {
                const key = element[0].value;
                total[key] = total[key] + 1 || 0;
            }

            return total;
        }, []);

        /* filter only sellerSKU that duplicate */
        const duplicateValue = Object.keys(sellerSKUList)
            .filter((key) => sellerSKUList[key] > 0);

        return duplicateValue; /* ['sellerSKU3', 'sellerSKU5', 'sellerSKU9'] */

    }

    /* get duplicate option key */
    getDuplicateOptionKey = () => {
        try {
            const option1Index = 6;
            const option2Index = 9;
            const productNameIndex = 2;

            const groupByProduct = this.fileXlsx?.data?.slice(1).reduce((total, row) => {

                const productName = row[productNameIndex].value;
                const option1 = row[option1Index].value;
                const option2 = row[option2Index].value;
                const options = { option1, option2 }

                if (total[productName])
                    total[productName] = [...total[productName], options];
                else total[productName] = [options];

                return total;
            }, {})
    
        const duplicateOptionKeys = Object.keys(groupByProduct).reduce((total, key) => {
    
                let option1 = {};
                let option2 = {};
        
                groupByProduct[key].forEach(element => {
                    if(element.option1)
                    option1[element.option1] = true;
                    if(element.option2)
                    option2[element.option2] = true;
                });
        
                const Option1Key = Object.keys(option1);
                const Option2Key = Object.keys(option2);
        
                let duplicates = [];
        
                if(Option1Key.length > 0) {
                        duplicates = Option2Key.filter((val) => {
                        return Option1Key.indexOf(val) != -1;
                    });
                    console.log(duplicates);
                }
        
                total[key] = duplicates
        
                return total;
                }, {});
        

         
        const duplicateWithinKeys = Object.keys(groupByProduct).reduce((total,key) => {

            let duplicateKEY1 = {};
            let duplicateKEY2 = {};
            const product = groupByProduct[key];
          
            product.forEach(value => {
               if(value.option1)
               duplicateKEY1[value.option1] = true;
               if(value.option2)
               duplicateKEY2[value.option2] = true;
            });
          
            total[key] = {};
            if(Object.keys(duplicateKEY1).length > 1) {
               total[key]['option1'] = true;
            } else {
               total[key]['option1'] = false;
            }
            if(Object.keys(duplicateKEY2).length > 1) {
               total[key]['option2'] = true;
            } else {
               total[key]['option2'] = false;
            }
          
            return total;
          
         }, {});
    
            return {duplicateOptionKeys, duplicateWithinKeys};
        } catch (error) {

        }
    }

    /* get duplicate option value*/
    getDuplicateOptionValue = () => {

        /* get option value in each product
           slice(1) for skip header
         */
        const optionValues = this.fileXlsx?.data?.slice(1).reduce((total, row) => {
            
            const productNameIndex = 2; 
            const optionValue1Index = 7;
            const optionValue2Index = 10;

            const productName = row[productNameIndex].value;
            const optionValue1 = row[optionValue1Index].value;
            const optionValue2 = row[optionValue2Index].value;
            const optionValue = optionValue1+optionValue2;
         
            if(total[productName]){
               if(total[productName][optionValue]){
                   total[productName][optionValue] = total[productName][optionValue]+1;
               }else{
                  total[productName][optionValue] = 1;
               }
            }
            else {
               /* initial value */
               total[productName] = {};
               total[productName][optionValue] = 1;
            }
         
            return total;
         }, {});

         /* filter only option value that duplicate */
         const duplicateOptionValues = Object.keys(optionValues).reduce((total, key) => {
            const totalVal = Object.keys(optionValues[key])
            .filter((val) => optionValues[key][val] > 1) 
         
            console.log(totalVal)
            if(totalVal.length > 0) 
               total[key] = totalVal;
         
            return total;
          }
         , {});

         return duplicateOptionValues;
    }

    validateAll = async () => {
        let rowErrors = {};
        let rows = [];
        const duplicateSellerSKU = this.getSellerSKUList();
        const {duplicateOptionKeys, duplicateWithinKeys} = this.getDuplicateOptionKey();
        const duplicateOptionValues = this.getDuplicateOptionValue();

        console.log({duplicateOptionKeys, duplicateWithinKeys});

        /* validate number of column */
        if (!this.validateNumberOfColumn()) {
            return errorStatusXlsx.InvalidNumberColumn;
        }

        /* validate data in each row. */
        for await (const [rowIndex, row] of this.fileXlsx.data.entries()) {

            let rowError = {};
            let newRow = [];

            for await (const [columnIndex, column] of row.entries()) {

                let newColumn = { ...column }

                /* 0:หมวดหมู่สินค้า, 
                   1:แบรนด์, 
                   2:ชื่อสินค้า, 
                   3:รายละเอียดสินค้า, 
                   4:เลขอ้างอิงตัวเลือกสินค้า (Seller SKU), 
                   6:ชื่อตัวเลือก 1, 
                   7:ตัวเลือก 1, 
                   11:ราคาปกติ, 
                   12:ราคาที่ประกาศขาย, 
                   13:สต็อก, 14:ภาพสินค้า, 
                   23:น้ำหนัก (กก), 
                   27:ระยะเวลาเตรียมการจัดส่ง (ชม.), 
                   28:Standard Delivery */
                const columnRequire = [0, 1, 2, 3, 4, 6, 7, 11, 12, 13, 14, 23, 27, 28];

                const columnImage = [8, 14, 15, 16, 17, 18, 19, 20, 21, 22] // image

                /* 11:ราคาปกติ, 
                   12:ราคาที่ประกาศขาย, 
                   13:สต็อก, 
                   23:น้ำหนัก (กก), 
                   24:ความยาว (ซม), 
                   25:ความกว้าง (ซม.), 
                   26:ความสูง (ซม.), 
                   27:ระยะเวลาเตรียมการจัดส่ง (ชม.) */
                const columnNumber = [11, 12, 13, 23, 24, 25, 26, 27]

                /* 1:แบรนด์, 
                   2:ชื่อสินค้า, 
                   3:รายละเอียดสินค้า,
                   4:เลขอ้างอิงตัวเลือกสินค้า (Seller SKU),   */
                const columnTextLength = [1,2,3,4];
                
                const columnStringNumber = [5] // รหัสบาร์โค๊ด
                const columnSpecialLetter = [4] // เลขอ้างอิงตัวเลือกสินค้า
                const columnSpecialAndCharacter = [1] // แบรนด์
                const columnOptionKey = [6,9] // ชื่อตัวเลือก1, ชื่อตัวเลือก2
                const columnOptionValue = [7,10] // ชื่อตัวเลือก1, ชื่อตัวเลือก2

                let value = null;
                if (column) {
                    if (typeof column.value !== 'object') {
                        value = column.value; /* column value */
                    } else {
                        if (column.value && column?.value?.props)
                            value = column.value?.props?.src; /* tag image */
                    }
                }

                if (rowIndex > 0) {

                    /* validate empty field */
                    if(columnRequire.indexOf(columnIndex) > -1){
                        if(columnIndex === 27) {
                            console.log({columnIndex,value})
                        }
                        if(!value || (String(value).trim().length === 0)) {
                            rowError[`${columnIndex}`] =  errorStatusXlsx.EmptyData;
                            newColumn.className = `${column.className} error-element`;
                            newColumn.value = <span className='error-element-message'>{'ข้อมูลจำเป็น'}</span>;
                        }
                    }

                    /* validate text length */
                    if(columnTextLength.indexOf(columnIndex) > -1){
                        if(value) {
                            switch(columnIndex) {
                                case 1: // 1:แบรนด์, 
                                if(String(value).length > 30) {
                                    rowError[`${columnIndex}`] =  errorStatusXlsx.LengthTextOverLimit;
                                    newColumn.className = `${column.className} error-element`;
                                    newColumn.value = <span className='error-element-message'>{errorStatusXlsx.LengthTextOverLimit.message}{' 30 ตัวอักษร'}</span>;
                                }
                                break;
                                case 2: // 2:ชื่อสินค้า
                                if(String(value).length > 200) {
                                    rowError[`${columnIndex}`] =  errorStatusXlsx.LengthTextOverLimit;
                                    newColumn.className = `${column.className} error-element`;
                                    newColumn.value = <span className='error-element-message'>{errorStatusXlsx.LengthTextOverLimit.message}{' 200 ตัวอักษร'}</span>;
                                }
                                break;
                                case 3: // 3:รายละเอียดสินค้า
                                if(String(value).length > 5000) {
                                    rowError[`${columnIndex}`] =  errorStatusXlsx.LengthTextOverLimit;
                                    newColumn.className = `${column.className} error-element`;
                                    newColumn.value = <span className='error-element-message'>{errorStatusXlsx.LengthTextOverLimit.message}{' 5000 ตัวอักษร'}</span>;
                                }
                                break;
                                case 4: // 4:เลขอ้างอิงตัวเลือกสินค้า (Seller SKU)
                                if(String(value).length > 100) {
                                    rowError[`${columnIndex}`] =  errorStatusXlsx.LengthTextOverLimit;
                                    newColumn.className = `${column.className} error-element`;
                                    newColumn.value = <span className='error-element-message'>{errorStatusXlsx.LengthTextOverLimit.message}{' 100 ตัวอักษร'}</span>;
                                }
                                break;
                            }
                        }
                    }

                    /* validate image is exist */
                    // if (columnImage.indexOf(columnIndex) > -1) {
                    //     if (value) {
                    //         const isImageIsExist = await this.validateImageIsExist(value);
                    //         if (!isImageIsExist) {
                    //             rowError[`${columnIndex}`] = errorStatusXlsx.ImageDoesNotExist;
                    //             newColumn.className = `${column.className} error-element`;
                    //             newColumn.value = <span className='error-element-message'>{'รูปภาพไม่ถูกต้อง'}</span>;
                    //         }
                    //     }
                    // }

                    /* validate element is not number */
                    if (columnNumber.indexOf(columnIndex) > -1) {
                        if (value && !this.validateFieldIsNumber(value)) {
                            rowError[`${columnIndex}`] = errorStatusXlsx.InvalidNumber;
                            newColumn.value = <div>
                                <p>{column.value}</p>
                                <span className='error-element-message'>
                                    {errorStatusXlsx.InvalidNumber.message}
                                </span>
                            </div>;
                            newColumn.className = `${column.className} error-element`;
                        }
                    }

                    /* validate element is not number and string */
                    if(columnStringNumber.indexOf(columnIndex) > -1){
                        if(value && !this.validateFieldIsNumberStr(String(value))){
                            rowError[`${columnIndex}`] =  errorStatusXlsx.InvalidNumberString;
                            newColumn.value = <div>
                                <p>{column.value}</p>
                                <span className='error-element-message'>
                                    {errorStatusXlsx.InvalidNumberString.message}
                                </span>
                            </div>;
                            newColumn.className = `${column.className} error-element`;
                        }
                    }
                    
                     /* validate element is not number and letter */
                    if(columnSpecialLetter.indexOf(columnIndex) > -1){
                        if(value && !this.validateFieldIsNumberAndLetter(String(value))){
                            rowError[`${columnIndex}`] =  errorStatusXlsx.InvalidNumberAndLetter;
                             newColumn.value = <div>
                                                    <p>{column.value}</p>
                                                    <span className='error-element-message'>
                                                        {errorStatusXlsx.InvalidNumberAndLetter.message}
                                                    </span>
                                              </div>;
                            newColumn.className = `${column.className} error-element`;
                        }
                    }

                     /* validate element is not number and letter */
                    if(columnSpecialAndCharacter.indexOf(columnIndex) > -1){
                        if(value && !this.validateFieldIsNumberAndLetterAndSpecial(String(value))){
                            rowError[`${columnIndex}`] =  errorStatusXlsx.InvalidSpecialAndLetter;
                             newColumn.value = <div>
                                                    <p>{column.value}</p>
                                                    <span className='error-element-message'>
                                                        {errorStatusXlsx.InvalidSpecialAndLetter.message}
                                                    </span>
                                              </div>;
                            newColumn.className = `${column.className} error-element`;
                        }
                    }

                     /* validate duplicate sellerSKU */
                    if(columnSpecialLetter.indexOf(columnIndex) > -1 && duplicateSellerSKU.length > 0){
                        if(value && duplicateSellerSKU.indexOf(String(value)) > -1) {
                            rowError[`${columnIndex}`] =  errorStatusXlsx.DuplicateSellerSKU;
                            newColumn.value = <div>
                                                    <p>{column.value}</p>
                                                    <span className='error-element-message'>
                                                        {errorStatusXlsx.DuplicateSellerSKU.message}
                                                    </span>
                                              </div>;
                            newColumn.className = `${column.className} error-element`;
                        }
                    }

                    /* validate duplicate option key between optionKey1 and optionKey2 */
                    if(columnOptionKey.indexOf(columnIndex) > -1){
                      if(Object.keys(duplicateOptionKeys).length > 0) {

                        const productNameIndex = 2; 
                        const productName = row[productNameIndex].value;

                        if(Object.keys(duplicateOptionKeys).indexOf(productName) > -1){
                            if(duplicateOptionKeys[productName].indexOf(String(value)) > -1){
                                rowError[`${columnIndex}`] =  errorStatusXlsx.DuplicateOptionKey;
                                newColumn.value = <div>
                                                        <p>{column.value}</p>
                                                        <span className='error-element-message'>
                                                            {errorStatusXlsx.DuplicateOptionKey.message}
                                                        </span>
                                                </div>;
                                newColumn.className = `${column.className} error-element`;
                            }
                        }
                      }
                    }

                    /*  validate duplicate option value */
                    if(columnOptionValue.indexOf(columnIndex) > -1){
                        if(Object.keys(duplicateOptionValues).length > 0 &&
                          value) {
  
                          const productNameIndex = 2; 
                          const optionValue1Index = 7;
                          const optionValue2Index = 10;

                          const optionValue = row[optionValue1Index].value+row[optionValue2Index].value;
                          const productName = row[productNameIndex].value;
  
                          if(Object.keys(duplicateOptionValues).indexOf(productName) > -1){
                              if(duplicateOptionValues[productName].indexOf(String(optionValue)) > -1){
                                  rowError[`${columnIndex}`] =  errorStatusXlsx.DuplicateOptionKey;
                                  newColumn.value = <div>
                                                          <p>{column.value}</p>
                                                          <span className='error-element-message'>
                                                              {errorStatusXlsx.DuplicateOptionKey.message}
                                                          </span>
                                                  </div>;
                                  newColumn.className = `${column.className} error-element`;
                              }
                          }
                        }
                    }

                     /*  validate duplicate option key */
                     if(columnOptionKey.indexOf(columnIndex) > -1){
                        if(value) {
  
                          const productNameIndex = 2; 
                          const productName = row[productNameIndex].value;

                          if((columnIndex === 6 && 
                              duplicateWithinKeys[productName].option1 === true) ||
                             (columnIndex === 9 && 
                              duplicateWithinKeys[productName].option2 === true) 
                            ) {

                                const error = columnIndex === 6 ? 
                                              errorStatusXlsx.DuplicateOptionWithinKey1 : 
                                              errorStatusXlsx.DuplicateOptionWithinKey2;

                                rowError[`${columnIndex}`] =  error;
                                  newColumn.value = <div>
                                                          <p>{column.value}</p>
                                                          <span className='error-element-message'>
                                                              {error.message}
                                                          </span>
                                                  </div>;
                                  newColumn.className = `${column.className} error-element`;
                            }
                          
                        }
                    }
                }
                newRow = [...newRow, newColumn];
            };

            if (Object.keys(rowError).length > 0)
                rowErrors[`${rowIndex}`] = rowError;

            rows = [...rows, newRow];

        };

        /* filter column index error that unique */
        const columnError = Object.keys(rowErrors).reduce((arr,row) => {
   
            Object.keys(rowErrors[row]).forEach(element => {
                arr[element] = true;
            });

            return arr;
         }, {});

        /* apply column error style */
         rows[0] = rows[0].map((col, columnIndex) => {
            if(Object.keys(columnError).indexOf(`${columnIndex}`) > -1) {
                col.className = col.className+' error-column'
            }
            return col;
         })

        return Object.keys(rowErrors).length > 0 ?
            {
                name: errorStatusXlsx.RowError.name,
                message: errorStatusXlsx.RowError.value,
                rowErrors,
                rows
            } : true;
    }

    validateImageIsExist = (URL) => {
        return new Promise((resolve, reject) => {
            var image = new Image();
            image.onload = () => resolve(true);
            image.onerror = () => resolve(false);
            image.src = URL;
        })
    }

    /* check image size */
    validateImageSize = (imageURL) => {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', imageURL, true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
                if (xhr.status == 200) {
                    alert('Size in bytes: ' + xhr.getResponseHeader('Content-Length'));
                } else {
                    alert('ERROR');
                }
            }
        };
        xhr.send(null);
    }

    /* check filed empty require field */
    /* return index of empty in array */
    validateFieldEmpty = (row) => {
        try {
            let emptyElements = []
            for (let i = 0; i < row.length; i++) {
                if (row[i] === '' || row[i] === undefined) {
                    emptyElements = [...emptyElements, i]
                }
            }
            return emptyElements
        } catch (error) {
            console.log(error)
        }
    }

    /* check field is number */
    validateFieldIsNumber = (value) =>
        (typeof value === 'number' && value > -1)

    /* check field is number 0-9 string */
    validateFieldIsNumberStr = (value) =>
        (typeof value === 'string' && /^[0-9]+$/.test(value))

    /* check field is number and letter 0-9 and a-zA-z */
    validateFieldIsNumberAndLetter = (value) =>
        (typeof value === 'string' && /^[a-zA-Z0-9]+$/.test(value))

    /* check field is number and letter 0-9 and a-zA-z and special character*/
    validateFieldIsNumberAndLetterAndSpecial = (value) =>
        (typeof value === 'string' && /^[a-zA-Z0-9-_$&+,:;=?@#|'<>.^*()%!-\s]+$/.test(value))

    /* check number of column */
    validateNumberOfColumn = () => {
        if (Array.isArray(this.fileXlsx.data[0]) &&
            this.fileXlsx.data.length > 1) {
            return this.fileXlsx.data[0].length === this.columnName.length
        }
        return false;
    }

}
