/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import { Link } from "react-router-dom";
import host from 'config/host';

import { ButtonAdd, InputSearch } from "components/output";
import Loading from 'components/Loading';
import DropDownPlaylist from "components/DropdownPlaylist";
import PaginationComponent from 'components/pagination';
import ModalAlert from 'components/modalAlert/ModalAlert';

import {
  SET_MODAL_ADD_PLAYLIST_MALL,
  UPDATE_PLAYLIST_SHOP_ORDER,
  ADD_SHOP_TO_PLAYLIST,
  DELETE_SHOP_FROM_PLAYLIST,
  GET_PLAYLIST_SHOP
} from 'redux/actions/playlists';
import {
  GET_SHOPS,
  GET_SHOPS_SEARCH,
  ADD_KEY_VALUE_SHOP,
  // ON_SEARCH_SHOP
} from 'redux/actions/shop';
import {
  OPEN_MODAL_ALERT,
} from 'redux/actions/modalAlert';

import "./style.scss";
import IconClose from "images/icons/close.svg";
import IconTrash from "images/icons/trash.svg";
import IconEmpty from 'images/icons/status-error.svg';
import Checkbox from "components/Checkbox";

import ModalConfirm from "components/modalConfirm/ModalConfirm";

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */

const EmptyItem = (props) => {
  return <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
    <img src={IconEmpty} alt='icon-empty'/>
    <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
  </div>
}

const TableShopList = (props) => {
  const { shopList, dropDownPlaylist, handleSwitchIndex, handleDeleteShop } = props;
  return (
    <div className="container-table">
      <div className="table-header">
        <div className="text-center">ลำดับ</div>
        <div className="text-left">ชื่อร้านค้า</div>
        <div className="d-flex align-items-center"></div>
      </div>
      {shopList &&
        shopList.length > 0 ?
        shopList.map((shop, index) => (
          <RowShopList
            key={`RowShopList${index}`}
            shop={shop}
            dropDownPlaylist={dropDownPlaylist}
            handleSwitchIndex={handleSwitchIndex}
            handleDeleteShop={handleDeleteShop}
          />
        ))
        :
        <EmptyItem />
      }
    </div>
  );
};

const RowShopList = (props) => {
  const { dropDownPlaylist, handleDeleteShop, handleSwitchIndex, shop } = props;
  const { order } = props?.shop || {};
  const { name, logoPath } = props?.shop?.shop || {};
  return (
    <div className="table-row">
      <div className="d-flex align-items-center justify-content-center">
        <DropDownPlaylist
          options={dropDownPlaylist}
          defaultValue={order}
          handleSelected={(orderNumber) => handleSwitchIndex(orderNumber, shop)}
        />
      </div>
      <div className="d-flex align-items-center text-left flex-row">
        <img src={`${host.avatar}${logoPath.path}`} className="mr-2" alt='logo-shop'/>
        <span>{name}</span>
      </div>
      <div className="d-flex align-items-right justify-content-end">
        <ButtonDeleteShop
          handleDeleteShop={() => handleDeleteShop(props?.shop)}
        />
      </div>
    </div>
  );
};

const ButtonDeleteShop = (props) => {
  const { handleDeleteShop } = props;
  return (
    <a className="clickable" onClick={handleDeleteShop}>
      <img src={IconTrash} alt='icon-trash'/>
    </a>
  );
};

const TableAddShop = (props) => {
  const { shopList, handleSelectShop, pageOffset } = props;

  return (
    <div className="container-table">
      <div className="table-header">
        <div></div>
        <div className="text-center">ลำดับ</div>
        <div className="text-left">ชื่อร้านค้า</div>
        <div className="d-flex align-items-center"></div>
      </div>
      {shopList &&
        shopList.length > 0 &&
        shopList.map((shop, index) => (
          <RowAddShop
            key={`RowAddShop${index}`}
            shop={shop}
            index={pageOffset + (index + 1)}
            handleSelectShop={handleSelectShop}
          />
        ))}
    </div>
  );
};

const RowAddShop = (props) => {
  const { handleSelectShop, shop, index } = props;
  const { name, logoPath, id, isSelected } = shop || {};
  return (
    <div className="table-row">
      <div className="d-flex align-items-center justify-content-center">
        <Checkbox
          title={""}
          checked={isSelected}
          value={id}
          onChange={() => handleSelectShop(shop)}
          name={name}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        {index}
      </div>
      <div className="d-flex align-items-center text-left flex-row">
        <img src={`${logoPath?.host}${logoPath?.path}`} alt='logo-shop'/>
        <span className="ml-2">{name}</span>
      </div>
      <div className="d-flex align-items-center justify-content-center"></div>
    </div>
  );
};



/* -------------------------------------------------------------------------- */
/*                             main component                                 */
/* -------------------------------------------------------------------------- */

const ModalAddShop = (props) => {

  const {
    isOpen,
    shopList,
    keyword,
    statusSearch,
    pageSizeOptions,
    total,
    page,
    limit,
    toogle,
    setKeyword,
    handleSearchShop,
    handleSearchKeyPress,
    handleClearSearch,
    handleSelectShop,
    handleAddShopToPlaylist,
    handleShowSizeChange,
    handlePageChange
  } = useModalAddShop(props);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toogle}
      backdrop="static"
      style={{ width: "80%", maxWidth: "80%" }}
    >
      <ModalHeader toggle={toogle}>
        <div className="d-flex justify-content-between w-100">
          <div>
            <span className="header-lable"> เพิ่มร้านค้า </span>
          </div>
          <div className="close-btn-wrap">
            <img src={IconClose} onClick={toogle} alt='icon-close'/>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Loading />
        <div className="container-modal-add-shop p-4">
          <div className="row mb-4">
            <div className="col-5">
              <InputSearch
                placeholder={"ค้นหาร้านค้า"}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyPress={handleSearchKeyPress}
                handleSearch={handleSearchShop}
                onClearSearch={handleClearSearch}
                statusSearch={statusSearch}
                defaultValue={keyword}
              />
            </div>
          </div>
          {shopList && shopList.length > 0 &&
            <div className="d-flex w-100 justify-content-end mb-4">
              <PaginationComponent
                defaultCurrent={1}
                total={total}
                onChange={(page) => handlePageChange(page)}
                pageSize={limit}
                pageSizeOptions={pageSizeOptions}
                onShowSizeChange={handleShowSizeChange}
                current={page}
                locale={{ items_per_page: '/ หน้า' }}
              />
            </div>
          }
          <TableAddShop
            shopList={shopList}
            pageOffset={limit * (page - 1)}
            handleSelectShop={handleSelectShop}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center w-100 flex-row">
          <button className="btn-cancel mr-3" onClick={toogle}>
            ยกเลิก
          </button>
          <button className="btn-submit" onClick={handleAddShopToPlaylist}>
            เพิ่มลงเพลย์ลิส
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const PlaylistShop = (props) => {

  const { shopList,
    dropDownPlaylist,
    playlistsId,
    playlistsName,
    playlistsType,
    handleDeleteShop,
    handleSwitchIndex,
    handleOpenModalAddShop,
    modalConfirm,
    // setModalConfirm
    } = usePlaylistShop(props);
  return (
    <div className="container-playlist-shop">
      <div className="d-flex justify-content-between">
        <h3>{playlistsName}</h3>
        <div className="mb-1">
          <ButtonAdd label="เพิ่มร้านค้าลงเพลย์ลิสต์" handleAdd={handleOpenModalAddShop} />
        </div>
      </div>
      <TableShopList
        shopList={shopList}
        dropDownPlaylist={dropDownPlaylist}
        handleDeleteShop={handleDeleteShop}
        handleSwitchIndex={handleSwitchIndex}
      />
      <ModalAddShop playlist={{ playlistsId, playlistsType }} />
      <ModalAlert />
      <ModalConfirm
        confirm={() => modalConfirm.confirm()}
        isClose={() => modalConfirm.isClose()}
        message={modalConfirm?.message}
        isOpen={modalConfirm?.isOpen}
        btnConfirm='ตกลง'
        btnCancel='ยกเลิก'
      />
    </div>
  );
};



/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const usePlaylistShop = (props) => {
  const dispatch = useDispatch();
  const { playlistsId, playlistsName, playlistsType } = props.playlist;

  const [shopList, setShopList] = useState([]);
  const [dropDownPlaylist, setDropDownPlaylist] = useState([]);
  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    message: '',
    confirm: () => { },
    // isClose: () => setModalConfirm({ isOpen: false, message: '', confirm: () => { } })
  })

  const playlistShops = useSelector((state) => state.playlists.playlistShops);

  // initial component
  useEffect(() => {
    initialComponent()
  }, [])

  useEffect(() => {
    // set up shop list
    if (playlistShops) setShopList(playlistShops);
  }, [playlistShops])

  useEffect(() => {
    // set up dropdown playlist
    if (shopList) setDropDownPlaylist(getDropDownPlayListOption(shopList));
  }, [shopList]);

  const initialComponent = () => {
    dispatch({ type: GET_PLAYLIST_SHOP, payload: { playlistId: playlistsId } })
  }

  const getDropDownPlayListOption = (shopList) => {
    if (shopList && shopList.length > 0)
      return shopList.map((shop) => ({ name: `${shop.order}`, value: shop.order }));
    else
      return [];
  }

  const deleteShopFromPlaylist = (shop) => {
    dispatch({
      type: DELETE_SHOP_FROM_PLAYLIST,
      payload: {
        playlistShopId: shop.id,
        playlistId: playlistsId,
        playlistsType: playlistsType
      }
    })
    setModalConfirm({isOpen: false,message: '',confirm: () => {}})
  };

  const handleDeleteShop = (shop) => {
    setModalConfirm({
      isOpen: true,
      message: 'ต้องการลบหมวดหมู่นี้ในเพลย์ลิสต์ใช่หรือไม่',
      confirm: () => {
        deleteShopFromPlaylist(shop)
      },
      isClose: () => setModalConfirm({isOpen: false,message: '',confirm: () => {}})
    })
  }

  const handleSwitchIndex = (orderNumber, shop) => {
    dispatch({
      type: UPDATE_PLAYLIST_SHOP_ORDER,
      payload: {
        playlistShopId: shop.id,
        orderNumber,
        playlistId: playlistsId,
        playlistsType: playlistsType
      }
    })
  };

  const handleOpenModalAddShop = () => {
    dispatch({ type: SET_MODAL_ADD_PLAYLIST_MALL, payload: { isOpen: true } })
  }

  return {
    shopList,
    playlistsId,
    playlistsName,
    playlistsType,
    dropDownPlaylist,
    handleOpenModalAddShop,
    handleDeleteShop,
    handleSwitchIndex,
    modalConfirm,
    setModalConfirm
  };
}

const useModalAddShop = props => {
  const { playlistsId, playlistsType } = props.playlist;
  const dispatch = useDispatch();
  const modalAddPlayListItem = useSelector(state => state.playlists.modalAddPlayListItem);
  const { isOpen } = modalAddPlayListItem;
  const pageSizeOptions = ["10", "20", "40"];

  const [statusSearch, setStatusSearch] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [shopList, setShopList] = useState([]);
  const [shopSelected, setShopSelected] = useState([]);
  // const itemPerPage = 10;

  const toogle = () => dispatch({ type: SET_MODAL_ADD_PLAYLIST_MALL, payload: { isOpen: false } })
  const { shops, page, limit, total, shopsSearch } = useSelector((state) => state.shop);

  useEffect(() => {
    if (isOpen && isOpen === true)
      initialComponent();
    else
      resetComponent();
  }, [isOpen]);

  useEffect(() => {
    if (shops)
      initialShopList(shops);
  }, [shops]);

  useEffect(() => {
    if (shopsSearch) {
      dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'total', value: shopsSearch.count } });
      initialShopList(shopsSearch.shop);
    }
  }, [shopsSearch])

  const initialComponent = () => getShops(1, 10);

  const initialShopList = (shopItem) => {
    // add field isSelected
    if (shopItem) {
      const shopSelectedId = shopSelected.map(shop => shop.id);
      const _shops = shopItem.map((shop) => {
        // match item with shop selected
        const isMatchForShopSelected = shopSelectedId.indexOf(shop.id) !== -1;
        shop = { ...shop, isSelected: isMatchForShopSelected }
        return shop;
      });
      setShopList(_shops);
    }
  };

  const resetComponent = () => {
    setStatusSearch(false);
    setKeyword("");
    setShopList([]);
    setShopSelected([]);
  }

  const getShops = (page, pageLimit) => {
    if (pageLimit)
      dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'limit', value: pageLimit } });

    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: page } });
    dispatch({ type: GET_SHOPS });
  }

  const handleShowSizeChange = (page, pageSize) => {
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'limit', value: pageSize } });
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: page } });
  }

  const handlePageChange = page => getShops(page)

  const handleSearchShop = () => {

    if (keyword.trim().length === 0) {
      setStatusSearch(false);
      dispatch({ type: GET_SHOPS_SEARCH, payload: { keyword } })
    } else {
      setStatusSearch(true);
      dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: 1 } });
      dispatch({ type: GET_SHOPS_SEARCH, payload: { keyword } })
    }

  };

  const handleSearchKeyPress = event => {
    if (event.key === "Enter")
      handleSearchShop();
  }

  const handleClearSearch = () => {
    setKeyword("");
    initialComponent();
  }

  const handleSelectShop = (selectedShop) => {

    // switch check box value
    const shopListUpdated = shopList.map((shop) => {
      if (shop.id === selectedShop.id)
        shop.isSelected = !shop.isSelected;
      return shop;
    });

    setShopList(shopListUpdated);

    // store shop selected id
    if (selectedShop.isSelected === true)
      setShopSelected([...shopSelected, selectedShop]);
    else
      setShopSelected([...shopSelected].filter(shop => shop.id !== selectedShop.id));

  };

  const handleAddShopToPlaylist = () => {
    if (validateShopSelected()) {
      const shopSelectedId = shopSelected.map(shop => shop.id);
      dispatch({
        type: ADD_SHOP_TO_PLAYLIST,
        payload: { shopIds: shopSelectedId, playlistId: playlistsId, playlistsType }
      })
    }
  };

  const validateShopSelected = () => {
    if (shopSelected && shopSelected.length < 1) {
      openModalAlert()
      return false;
    } else {
      return true;
    }
  }

  const openModalAlert = () => {
    dispatch({
      type: OPEN_MODAL_ALERT,
      payload: {
        isOpen: true, message: 'กรุณาเลือกมอล์ลก่อนเพิ่มลงเพลลิสต์',
        type: 'alert'
      }
    });
  }

  return {
    isOpen,
    shopList,
    keyword,
    statusSearch,
    pageSizeOptions,
    total,
    page,
    limit,
    toogle,
    setKeyword,
    handleSearchShop,
    handleSearchKeyPress,
    handleClearSearch,
    handleSelectShop,
    handleAddShopToPlaylist,
    handleShowSizeChange,
    handlePageChange
  }
}

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default PlaylistShop;



