import { getTopViewColor } from 'helper/utils';
import React from 'react'
import Chart from "react-google-charts";
import useScreenWidth from '../useScreenWidth';

const LineChartMulti = (props) => {
    const screenType = useScreenWidth()
  const showTextType = {day: 12, week: 1, month: 2,year: 1}
const {data, showTextEvery} = props

function generatePattern(numItems) {
    const pattern = {};
  
    for (let i = 0; i <= numItems; i++) {
        const color = getTopViewColor(i)
      pattern[i] = { color };
    }
  
    return pattern;
  }

  return (
    <div>
    <Chart
        width={'100%'}
        height={'300px'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
            fontName: 'DBHeavent',
            // fontSize: '20',
            fontSize: screenType?.isTablet ? '12' : screenType?.isMobile ? '8' : '16',
            // fontWeight: 'bold',
            animation: {
                startup: true,
                easing: 'linear',
                duration: 1000,
            },
            hAxis: {
                gridlines: {
                    color: '#BFBFBF',
                    fill: 'red'
                },
                showTextEvery: showTextType[showTextEvery],
            },
            vAxis: {
                gridlines: {
                    color: '#e9e9e9', fill: 'none'
                },
                baselineColor: '#BFBFBF',
                minorGridlines: {
                    color: 'none'
                },
                textPosition: 'none'
            },
            series: {
                // 0: { curveType: 'function' }, //set index for curve
                ...generatePattern(10)
            },
            chartArea: { left: 0, top: 50, width: '95%', height: '75%' },
            legend: 'none',
            tooltip: { isHtml: true, trigger: "both" },
            // scaleType: 'log',
            // logscale: true
        }}
        // rootProps={{ 'data-testid': '2' }}
    />
</div>
  )
}

export default LineChartMulti