import axios from 'axios';
import host from '../config/host'
import Cookies from 'js-cookie'
import axiosNew from 'config/axios';


const header = {
  headers: { 'secret-key': host.tokenSecretKey,'channel':'cms' }
  // headers: { Authorization: "Bearer " + host.tokenSecretKey }
}

class userProvider {
  handleLogin = async (params) => {
    try {
      const data = {
        email: params.email,
        password: params.password,
      }

      const result = await axios.post(`${host.api}/auth/login`, data, header)
      return result
    } catch (error) {
      Cookies.remove('user')
      Cookies.remove('token')
      Cookies.remove('refreshToken')
      throw error
    }
  }

  authenticateUser = async (params) => {
    if (params.accessToken === undefined) return false
    const data = {
      access_token: params.accessToken,
    }
    try {
      const result = await axios.post(`${host.api}/auth/authenticate`, data)
      return result
    } catch (error) {}
  }

  refreshToken = async (params) => {
    // let data = params.config
    try {
      //
      const result = await axios.post(
        `${host.api}/auth/refresh-token`,
        { refreshToken: params.refreshToken },
        header,
      )
      if (result) {
        Cookies.set('token', result.data.accessToken)
        Cookies.set('refreshToken', result.data.refreshToken)
      }
      return result.data
      // data.headers.Authorization = "Bearer " + result.data.accessToken
      // let newAxios = axios.create(data)
      //
      // //
      // const res = await newAxios(params.url,params.data)
      //
    } catch (error) {
      // window.location.replace('/login')
      throw error
    }
  }

  createUserShop = async (body) => {
    try {
      const url = `${host.api}/auth/register`
      const res = await axios.post(url, body, header)

      return res.data
    } catch (error) {
      return error.response
    }
  }

  getUser = async (params) => {
    try {
      const data = {
        email: params.email,
        password: params.password,
      }

      const res = await axios.post(`${host.api}/auth/login`, data, header)
      return res
    } catch (error) {
      throw error
    }
  }

  resetPassword = async (params) => {
    /// @param
    /// email
    /// resetToken
    /// newpassword
    try {
      const data = {
        email: params.email,
        resetToken: params.resetToken,
        password: params.password
      }
      const url = `${host.api}/auth/reset-password`
      const res = await axios.post(url,data)
      return res
    } catch (error) {
      throw error
    }
  }
  sendEmailPasswordReset = async (params) => {
    try {
      const data = { email: params.email, }
      const res = await axios.post(`${host.api}/auth/send-password-reset`, data, header)
      return res
    } catch (error) {
      throw error
    }
  }

  createUserStaff = async (params) => {
      try {
        const url = `${host.api}/users/staff`
        const res = await axiosNew.post(url,params)
        return res.data
      } catch (error) {
        throw error
      }
  }

  verifyUserStaff = async (email,key) => {
    try {
      const url = `${host.api}/shop-user/staff/activate`
      const res = await axiosNew.post(url, { email, key })
      if(res){
        return res
      }
    } catch (error) {
      throw error
    }
  }
}

export default new userProvider()
