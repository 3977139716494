import React, { useState, useEffect } from 'react'
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { useDispatch } from 'react-redux';
import { dispatchApp, SET_LOADING } from 'redux/actions/app';

const XlsxCreate = (props) => {
    const [data, setData] = useState(null)
    const [header, setHeader] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        setData(props.data)
        setHeader(props.header)
        return () => {
            setData(null);
            setHeader(null);
        }
    }, [props.data, props.header])

    const { fileName, handleFetchData, headerA1,numberFormat,nonNumberFormat } = props
    const getSheetData = (data, header) => {
        // if(data.length === 0) return
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    const saveAsExcel = async () => {
        const col = ['A', 'B', 'C', 'D', 'E', 'F']
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(data, header);
            const totalColumns = sheetData[0].length;
            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            col.map((alp) => sheet1.column(alp).width(25).hidden(false))
            sheet1.column("G").width(50).hidden(false);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, `${fileName}.xlsx`);
            });
        });
    }


    /////////// fetch Data first /////////////
    const getResData = async () => {
        dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
        const resData = await new Promise(async (resolve, reject) => {
            ///  resReturn req = {
            // header: ['exam','exam2'],
            // data: [{exam:'data',exam2:'data'}]
            // }
            const res = await handleFetchData()
            resolve(res)
        })
        if (resData) {
            saveAsExcelAfterFetch(resData.data, resData.header)
        }
        dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
    }

    function toColumnName(num) {
        for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
          ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
        }
        return ret;
      }
      
    const saveAsExcelAfterFetch = async (data, header) => {
        console.log('data', data)
        // if(data.length === 0) return
        const col = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC']
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);

            //// count column ////
            const sheetData = getSheetData(data, header);
            const totalColumns = sheetData && sheetData[0].length;
            //// count column ////

            col.slice(0, totalColumns)
            sheet1.cell("A2").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = toColumnName(totalColumns);


            ////merge cell////
            const headerText = sheet1.range(`A1:${endColumn}1`);
            headerText.value('test')
            headerText.merged(true)
            sheet1.cell("A1").value(`${headerA1}`);
            ////merge cell////

            sheet1.row(1).style("bold", true);
            sheet1.row(1).height(40);
            col.map((alp) => sheet1.column(alp).width(20).hidden(false))
            // sheet1.column("A").width(20).hidden(false);
            sheet1.range("A2:" + endColumn + "1").style({fill: "BFBFBF",bold:true});
            sheet1.range("A1:" + endColumn + "1").style({ fill: "FFFFFF", bold: true, verticalAlignment: 'center', fontSize: 16,numberFormat:'0' });
            range.style("border", true);

            const AStringLength = sheet1.range(`A2:A${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                // if (value === 5) return 5;
                if (value === undefined) return max;
                return Math.max(max, value.toString().length + 5);
            }, 0);
            // For the default font settings in Excel, 1 char -> 1 pt is a pretty good estimate.
            sheet1.column("A").width(AStringLength);

            const BStringLength = sheet1.range(`B1:B${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                // if (value.toString().length < 5) return 5;
                if (value === undefined) return max;
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("B").width(BStringLength);

            const CStringLength = sheet1.range(`C2:C${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("C").width(CStringLength);

            const DStringLength = sheet1.range(`D2:D${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if(value > 30) return 30
                return Math.max(max, value.toString().length + 10);
            }, 0);
            sheet1.column("D").width(DStringLength);


            const EStringLength = sheet1.range(`E2:E${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value?.toString().trim().length > 30) return 30
                if (value === undefined) return max;
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("E").width(EStringLength);

            const FStringLength = sheet1.range(`F2:F${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value?.toString().trim().length > 30) return 30
                if (value === undefined) return max;
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("F").width(FStringLength);

            const GStringLength = sheet1.range(`G2:G${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value?.toString().trim().length > 30) return 30
                if (value === undefined) return max;
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("G").width(GStringLength);

            const HStringLength = sheet1.range(`H2:H${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("H").width(HStringLength);

            const IStringLength = sheet1.range(`I2:I${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("I").width(IStringLength);

            const JStringLength = sheet1.range(`J2:J${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("J").width(JStringLength);

            const KStringLength = sheet1.range(`K2:K${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("K").width(KStringLength);

            const LStringLength = sheet1.range(`L2:L${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("L").width(LStringLength);

            const MStringLength = sheet1.range(`M2:M${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("M").width(MStringLength);

            const NStringLength = sheet1.range(`N2:N${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("N").width(NStringLength);

            const OStringLength = sheet1.range(`O2:O${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("O").width(OStringLength);

            const PStringLength = sheet1.range(`P2:P${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("P").width(PStringLength);

            const QStringLength = sheet1.range(`Q2:Q${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("Q").width(QStringLength);

            const RStringLength = sheet1.range(`R2:R${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("R").width(RStringLength);

            const SStringLength = sheet1.range(`S2:S${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("S").width(SStringLength);

            const TStringLength = sheet1.range(`T2:T${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("T").width(TStringLength);

            const UStringLength = sheet1.range(`U2:U${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("U").width(UStringLength);

            const VStringLength = sheet1.range(`V2:V${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("V").width(VStringLength);

            const WStringLength = sheet1.range(`W2:W${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("W").width(WStringLength);

            const XStringLength = sheet1.range(`X2:X${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("X").width(XStringLength);

            const YStringLength = sheet1.range(`Y2:Y${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("Y").width(YStringLength);

            const ZStringLength = sheet1.range(`Z2:Z${range._maxRowNumber}`).reduce((max, cell) => {
                const value = cell.value();
                if (value === undefined) return max;
                if (value?.toString().trim().length > 30) return 30
                return Math.max(max, value.toString().length + 5);
            }, 0);
            sheet1.column("Z").width(ZStringLength);

            const allCell = sheet1.range(`A1:${endColumn}${range._maxRowNumber}`)
            allCell.style({ verticalAlignment: 'top',horizontalAlignment: 'left',numberFormat: numberFormat,wrapText: true})
            sheet1.column('A').style({numberFormat:'0'})

            if(nonNumberFormat){
                for (let index = 0; index < nonNumberFormat.length; index++) {
                    const element = nonNumberFormat[index];
                    sheet1.column(element).style({numberFormat:'0'})
                }
            }

            if(props.textRight?.length){
             const arrTextRight = props.textRight  ///exam ['A','B','C']
             for (let index = 0; index < arrTextRight.length; index++) {
                 const element = arrTextRight[index];
                 sheet1.range(`${element}2:${element}${data.length + 2}`).style({ horizontalAlignment: 'right' });
             }
            }
           
            return workbook.outputAsync().then((res) => {
                saveAs(res, `${fileName}.xlsx`);
            });
        });
    }


    return (
        <React.Fragment>
            <button type="button" onClick={handleFetchData ? getResData : saveAsExcel} style={{ background: 'none', border: 'none', padding: 0 }}>
                {props.children}
            </button>
        </React.Fragment>
    )
}

XlsxCreate.defaultProps = {
    headerA1: '',
    numberFormat: '0,0',
    nonNumberFormat: []
}

export default XlsxCreate
