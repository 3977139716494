
import React from 'react';
import { ReactComponent as IconShop } from '../../images/icons/menu/menu-shop.svg';
import { ReactComponent as IconBag } from '../../images/icons/menu/menu-bag.svg';
// import { ReactComponent as IconDashBoard } from '../../images/icons/menu/menu-dashboard.svg';
import { ReactComponent as IconClipBoard } from '../../images/icons/menu/menu-clip-board.svg';
// import { ReactComponent as IconProfile } from '../../images/icons/menu/menu-profile.svg';
import { ReactComponent as IconPromotion } from '../../images/icons/menu/menu-promotion.svg';
import { ReactComponent as IconDashboard } from '../../images/icons/menu/dashboard.svg'
import { ReactComponent as IconWithdraw } from 'images/icons/menu/withdraw.svg'
import {ReactComponent as IconPromotionDelivery} from 'images/icons/menu/ic-promotion-delivery.svg'
import {ReactComponent as IconProfileDefault} from 'images/icons/no-profile.svg'
// import { ReactComponent as IconRibbon } from 'images/icons/ribbon.svg'
// import { ReactComponent as IconBroadcast } from 'images/icons/ic_broadcast.svg'

import {ReactComponent as IconAnnounce} from 'images/icons/announce.svg'


// import { PERMISSION_MANAGE_SHOP } from '../../config/permission';
import { ADMIN, OWNER, SUPER_ADMIN, ACCOUNTANT } from './Role';

import {
  USER,
  MANAGE,
  CHAT,
  // ORDER,
  // REPORT,
  // SETTING
} from '../../config/groupTabMenu';

export default {
  items: [
    // Group User
    // {
    //   name: 'Mall',
    //   url: '/manage/shop',
    //   // icon: <IconWithdraw />,
    //   // permission: PERMISSION_MANAGE_SHOP,
    //   group: [MANAGE],
    //   show: true,
    //   role: [ADMIN, SUPER_ADMIN, OWNER],
    //   icon: <IconRibbon />,
    //   subMenu: [
    //     {
    //       name: 'เพิ่ม Mall',
    //       url: '/manage/shop/addmall',
    //       icon: (
    //         <React.Fragment>
    //           <IconBag />
    //         </React.Fragment>
    //       ),
    //       group: [MANAGE, CHAT],
    //       show: true,
    //       role: [SUPER_ADMIN, ADMIN],
    //     },
    //   ],
    // },
 
    {
      name: 'ผู้ใช้งาน',
      url: '/user',
      icon: (
        <React.Fragment>
          <IconBag />
        </React.Fragment>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [USER],
      show: true,
      role: [SUPER_ADMIN, ADMIN, OWNER],
    },
    {
      name: 'เพิ่มผู้ใช้งาน',
      url: '/user/adduser',
      icon: (
        <React.Fragment>
          <IconBag />
        </React.Fragment>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [USER],
      show: true,
      role: [SUPER_ADMIN, ADMIN, OWNER],
    },

    // Group Manage
    // {
    //     name: 'แดชบอร์ด',
    //     url: '/manage/dashboard',
    //     icon: <React.Fragment><IconDashBoard/></React.Fragment>,
    //     permission: PERMISSION_MANAGE_SHOP,
    //     group: [MANAGE,CHAT],
    //     show: true
    // },
    {
      name: 'สรุปรายงาน',
      url: '/manage/dashboard',
      icon: (
        <div>
          <IconDashboard />
        </div>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE, CHAT],
      show: true,
      role: [SUPER_ADMIN,ADMIN,OWNER],
    },

    {
      name: 'ร้านค้า',
      nameEdit: 'ร้านค้า',
      url: '/manage/shop',
      subMenu: [
        {
          name: 'รายละเอียดร้านค้า',
          url: '/manage/shop/shopdetail',
          editHeadMenu: true,
          subMenu: [
            {
              name: 'สร้างไลฟ์',
              url: '/manage/shop/shopdetail/createlive',
              icon: (
                <React.Fragment>
                  <IconBag />
                </React.Fragment>
              ),
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE, CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
            {
              name: 'แก้ไขไลฟ์',
              url: '/manage/shop/shopdetail/editlive',
              icon: (
                <React.Fragment>
                  <IconBag />
                </React.Fragment>
              ),
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE, CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
            {
              name: 'แก้ไข VOD',
              url: '/manage/shop/shopdetail/editvod',
              icon: (
                <React.Fragment>
                  <IconBag />
                </React.Fragment>
              ),
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE, CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
            {
              name: 'อัพโหลด',
              url: '/manage/shop/shopdetail/upload',
              icon: (
                <React.Fragment>
                  <IconBag />
                </React.Fragment>
              ),
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE, CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
            {
              name: 'จัดการ LIVE',
              url: '/manage/shop/shopdetail/managelive',
              icon: (
                <React.Fragment>
                  <IconBag />
                </React.Fragment>
              ),
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE, CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
            {
              name: 'เพิ่มราคาค่าจัดส่ง',
              url: '/manage/shop/shopdetail/addlogisticprice',
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE, CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
          ],
          icon: (
            <React.Fragment>
              <IconBag />
            </React.Fragment>
          ),
          // permission: PERMISSION_MANAGE_SHOP,
          group: [MANAGE, CHAT],
          show: true,
          role: [SUPER_ADMIN, ADMIN],
        },
        {
          name: 'สร้างร้านค้า',
          url: '/manage/shop/addshop',
          icon: (
            <React.Fragment>
              <IconBag />
            </React.Fragment>
          ),
          // permission: PERMISSION_MANAGE_SHOP,
          group: [MANAGE, CHAT],
          show: false,
        },
      ],
      icon: (
        <React.Fragment>
          <IconShop />
        </React.Fragment>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE, CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN,OWNER],
    },
    {
      name: 'สินค้า',
      url: '/manage/product',
      subMenu: [
        {
          name: 'เพิ่มสินค้า',
          url: '/manage/product/addproduct',
          icon: (
            <React.Fragment>
              <IconBag />
            </React.Fragment>
          ),
          // permission: PERMISSION_MANAGE_SHOP,
          group: [MANAGE, CHAT],
          show: false,
        },
      ],
      icon: (
        <React.Fragment>
          <IconBag />
        </React.Fragment>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE, CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN, OWNER],
    },
    {
      name: 'รายการสั่งซื้อ',
      url: '/manage/order',
      icon: (
        <React.Fragment>
          <IconClipBoard />
        </React.Fragment>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE, CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN, OWNER, ACCOUNTANT],
      collapse: [{
        name: 'รายการสั่งซื้อทั้งหมด',
        url: '/manage/order/all',
        role: [SUPER_ADMIN, ADMIN,OWNER],
      }, {
        name: 'รายการจัดส่งที่ยังไม่ถึงผู้รับ',
        url: '/manage/order/order-ontheway',
        role: [],
      },
      {
        name: 'สรุปรายงานการสั่งซื้อ',
        url: '/manage/order/summary-order',
        role: [SUPER_ADMIN, ADMIN,OWNER, ACCOUNTANT],
      }
      ]
    },
    

    {
      name: 'รายได้ / การโอนเงิน',
      url: '/manage/revenue',
      icon: <IconWithdraw />,
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE, CHAT],
      show: true,
      role: [OWNER, SUPER_ADMIN, ACCOUNTANT],
      collapse: [{
        name: 'รายการรายได้',
        url: '/manage/revenue/withdraw',
        role: [SUPER_ADMIN, ADMIN,OWNER, ACCOUNTANT],
      }, {
        name: 'การโอนเงิน',
        url: '/manage/revenue/transfer',
        role: [SUPER_ADMIN, ADMIN,OWNER,ACCOUNTANT],
      }
      ]
    },

    {
      name: 'โปรโมชั่นค่าจัดส่ง',
      url: '/manage/promotion',
      icon: <IconPromotionDelivery />,
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE, CHAT],
      show: true,
      role: [OWNER, SUPER_ADMIN, ADMIN],
    },

    {
      name: 'คูปองส่วนลด',
      nameEdit: 'คูปองส่วนลด',
      url: '/manage/promocode/platform/',
      subMenu: [
        {
          name: 'เพิ่มคูปองส่วนลด',
          url: '/manage/promocode/addpromocode',
          icon: (
            <React.Fragment>
              <IconPromotion />
            </React.Fragment>
          ),
          // permission: PERMISSION_MANAGE_SHOP,
          group: [MANAGE, CHAT],
          show: false,
        },
        {
          name: 'เพิ่มคูปองส่วนลด',
          url: '/manage/promocode/addcoupon',
          icon: (
            <React.Fragment>
              <IconPromotion />
            </React.Fragment>
          ),
          // permission: PERMISSION_MANAGE_SHOP,
          group: [MANAGE, CHAT],
          show: false,
        },
        { url: '/manage/promocode/comingsoon' },
        { url: '/manage/promocode/processing' },
        { url: '/manage/promocode/expired' },
      ],
      icon: (
        <React.Fragment>
          <IconPromotion />
        </React.Fragment>
      ),
      group: [MANAGE, CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN],
      collapse: [
        {
          name: 'แพลตฟอร์ม',
          url: '/manage/promocode/platform/',
          role: [SUPER_ADMIN, ADMIN],
        }, 
        {
          name: 'ร้านค้า',
          url: '/manage/promocode/mall/',
          role: [SUPER_ADMIN, ADMIN],
        }
      ]
    },

    {
      name: 'คูปองส่วนลด',
      nameEdit: 'คูปองส่วนลด',
      url: '/manage/promocode/',
      icon: (
        <React.Fragment>
          <IconPromotion />
        </React.Fragment>
      ),
      group: [MANAGE, CHAT],
      show: true,
      role: [OWNER]
    },

    {
      name: 'เพลย์ลิสต์',
      nameEdit: 'เพลย์ลิสต์',
      url: '/manage/playlists',
      subMenu: [
        {
          name: 'จัดการเพลย์ลิสต์',
          url: '/manage/playlists/items',
          editHeadMenu: true,
          icon: (
            <React.Fragment>
              <IconBag />
            </React.Fragment>
          ),
          group: [MANAGE, CHAT],
          show: true,
          role: [SUPER_ADMIN, ADMIN],
        },
      ],
      icon: (
        <React.Fragment>
          <IconShop />
        </React.Fragment>
      ),
      group: [MANAGE, CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN],
      collapse: [{
        name: 'แพลตฟอร์ม',
        url: '/manage/playlists/platform',
        role: [SUPER_ADMIN, ADMIN],
      }, {
        name: 'ร้านค้า',
        url: '/manage/playlists/mall',
        role: [SUPER_ADMIN, ADMIN],
      }
      ]
    },

    {
      name: 'เพลย์ลิสต์',
      nameEdit: 'เพลย์ลิสต์',
      url: '/manage/playlists',
      icon: (
        <React.Fragment>
          <IconShop />
        </React.Fragment>
      ),
      group: [MANAGE, CHAT],
      show: true,
      role: [OWNER]
    },
    {
      name: 'ประกาศ',
      nameEdit: 'ประกาศ',
      url: '/manage/announce',
      icon: (
        <React.Fragment>
          <IconAnnounce />
        </React.Fragment>
      ),
      group: [MANAGE, CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN]
    },




    {
      name: 'สร้างบัญชีผู้ใช้',
      nameEdit: 'สร้างบัญชีผู้ใช้',
      url: '/manage/user/create-staff',
      icon: (
        <React.Fragment>
          <IconProfileDefault width={25} height={25} fill="white" className='no-profile-icon'/>
        </React.Fragment>
      ),
      group: [MANAGE, CHAT],
      show: true,
      role: [SUPER_ADMIN,ADMIN]
    },


    

    // {
    //   name: 'ตัวจัดการหน้าเว็บ',
    //   nameEdit: 'Playlist Section',
    //   url: '/manage/playlist-front-page/platform',
    //   subMenu: [
    //     {
    //       name: '',
    //       url: '/manage/playlist-front-page/page',
    //       editHeadMenu: true,
    //       icon: (
    //         <React.Fragment>
    //           <IconBag />
    //         </React.Fragment>
    //       ),
    //       group: [MANAGE, CHAT],
    //       show: true,
    //       role: [SUPER_ADMIN, ADMIN],
    //     },
    //   ],
    //   icon: (
    //     <React.Fragment>
    //       <IconShop />
    //     </React.Fragment>
    //   ),
    //   group: [MANAGE, CHAT],
    //   show: true,
    //   role: [SUPER_ADMIN, ADMIN],
    //   collapse: [{
    //     name: 'แพลตฟอร์ม',
    //     url: '/manage/playlist-front-page/platform',
    //     role: [SUPER_ADMIN, ADMIN],
    //   }, {
    //     name: 'ร้านค้า',
    //     url: '/manage/playlist-front-page/mall',
    //     role: [SUPER_ADMIN, ADMIN],
    //   }
    //   ]
    // },
    // {
    //   name: 'ตัวจัดการหน้าเว็บ',
    //   nameEdit: 'Playlist Section',
    //   url: '/manage/playlist-front-page/mall',
    //   icon: (
    //     <React.Fragment>
    //       <IconShop />
    //     </React.Fragment>
    //   ),
    //   group: [MANAGE, CHAT],
    //   show: true,
    //   role: [OWNER]
    // },
    // {
    //   name: 'ตกแต่งรูปสินค้า',
    //   url: '/manage/ribbon',
    //   // icon: <IconWithdraw />,
    //   // permission: PERMISSION_MANAGE_SHOP,
    //   group: [MANAGE],
    //   show: true,
    //   role: [ADMIN, SUPER_ADMIN, OWNER],
    //   icon: <IconRibbon />
    // },
    // {
    //   name: 'บรอดแคสต์',
    //   url: '/manage/broadcast',
    //   group: [MANAGE],
    //   show: true,
    //   role: [ADMIN, SUPER_ADMIN, OWNER],
    //   icon: <IconBroadcast />,
    //   subMenu: [
    //     {
    //       name: 'สร้างบรอดแคสต์',
    //       url: '/manage/broadcast/createbroadcast',
    //       // editHeadMenu: true,
    //       icon: (
    //         <React.Fragment>
    //           <IconBroadcast />
    //         </React.Fragment>
    //       ),
    //       group: [MANAGE, CHAT],
    //       show: true,
    //       role: [SUPER_ADMIN, ADMIN],
    //     }
    //   ]
    // },


    // {
    //     name: 'ถ่ายทอดสด',
    //     url: '/manage/managelive',
    //     icon: <React.Fragment><IconBag/></React.Fragment>,
    //     permission: PERMISSION_MANAGE_SHOP,
    //     group: [MANAGE,CHAT],
    //     show: true
    // },
    // {
    //     name: 'สร้างไลฟ์',
    //     url: '/manage/create-live',
    //     icon: <React.Fragment><IconBag/></React.Fragment>,
    //     permission: PERMISSION_MANAGE_SHOP,
    //     group: [MANAGE,CHAT],
    //     show: true
    // },
    // {
    //     name: 'เพิ่มสินค้า',
    //     url: '/manage/add-product',
    //     icon: <React.Fragment><IconBag/></React.Fragment>,
    //     permission: PERMISSION_MANAGE_SHOP,
    //     group: ORDER
    // },

    /// Group Order
  ],
}
