import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import './styles.scss'
import { Tooltip } from 'components/FontStyle'

const Textarea = (props) => {
  const {
    label,
    defaultValue,
    handleChangeText,
    placeholder,
    error,
    require,
    title,
    limit
  } = props

  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const onChangeText = (e) => {
    setValue(e.target.value)
    handleChangeText(e.target.value)
  }

  return (
    <div className='textarea-field'>
      <label>{label}</label>
      {require && <span className='txt-red'>*</span>}<Tooltip className="txt-grey">{title}</Tooltip>
      <div className={classnames('container-input', { error: !!error })}>
        <textarea
          className='input-box'
          placeholder={placeholder}
          onChange={onChangeText}
          value={value}
          maxLength={limit}
          rows='3'
        />
      </div>
      {error && <div className='txt-red mt-1'>{error}</div>}
    </div>
  )
}

Textarea.defaultProps = {
  label: 'ชื่ออัลบั้ม',
  defaultValue: '',
  placeholder: '',
  handleChangeText: () => { },
  error: '',
  require: false,
  title: '',
  limit:500
}

export default Textarea
