import React,{ useState, useEffect } from 'react';


const useScreenWidth = () => {

    const [windowWidth, setWindowWidth] = useState(null);
    const [windowHeight, setWindowHeight] = useState(null);

    const isWindow = typeof window !== 'undefined';

    const getWidth = () => isWindow ? window.innerWidth : windowWidth;
    const getHeight = () => isWindow ? window.innerHeight : windowHeight;
    const resize = () => setWindowWidth(getWidth());

    useEffect(() => {
        if (isWindow) {
            setWindowWidth(getWidth());
      
            window.addEventListener('resize', resize);
       
            return () => window.removeEventListener('resize', resize);
        }
    //eslint-disable-next-line
    }, [isWindow]);

    return {
        isDesktop: windowWidth <= 1920 && windowWidth > 768,
        isTablet: windowWidth <= 768 && windowWidth > 428,
        isMobile:  windowWidth <= 428,
    };
}

export default useScreenWidth