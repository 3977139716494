
import { OPEN_MODAL_ALERT,CLOSE_MODAL_ALERT } from '../actions/modalAlert'

const initialState = {
    modalAlert: {
        isOpen: false,
        message: "",
        type: "",
        btnLabel: "ตกลง",
        confirm: null
    }
}


export default function (state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case OPEN_MODAL_ALERT:
            const { isOpen, message, type, btnLabel, confirm = null } = payload
            return {
                ...state,
                modalAlert: { isOpen, message, type, btnLabel, confirm }
            }
        case CLOSE_MODAL_ALERT:
            return {
                ...state,
                modalAlert: {
                    isOpen: false,
                    message: "",
                    type: "",
                    btnLabel: "ตกลง",
                    confirm: null
                }
            }
        default:
            return state
    }
}