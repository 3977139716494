import {
    ADD_KEY_VALUE_PRODUCT, ADD_IMAGE_PATH, ADD_VALUE_IN_PRODUCT,
    ADD_SEO_IN_PRODUCT, ADD_KEYWORD_IN_SEO, REMOVE_KEYWORD_IN_SEO,
    // GET_LIST_PRODUCT, 
    GET_LIST_ALL_PRODUCT,
    // ON_CREATE_PRODUCT, HANDLE_CRAETE_PRODUCT, 
    SET_PRODUCT_CATEGORIES,
    SELECT_PRODUCT_CATEGORIES, ADD_IMAGE_SEO, REMOVE_IMAGE_PRODUCT, REMOVE_SEO_IMAGE, SET_SEO_LENGTH,
    SET_PRODUCTS_BY_SHOP, SET_SEARCH_PRODUCTS_BY_SHOP, OPEN_MODAL_ADD_PRODUCT, REMOVE_ALL_IMAGE,
    // HANDLE_SEARCH_SHOP_PRODUCT, 
    SET_KEY_PRODUCT,
    OPEN_MODAL_LIMIT_PURCHASE,
    UNCHECK_PRODUCT_EDIT_PRODUCT,
    CHECK_PRODUCT_EDIT_PRODUCT,
    SET_ALL_PRODUCT,
    RESET_EDIT_PRODUCT
} from '../actions/product'


const initialState = {
    value: {
        shopId: 1,
        userId: 11,
        status: 'show', //hide status
        name: "",  //productName
        nameEn: "",
        description: "",
        saleType: "buynow", //default
        unitType: "ชิ้น",
        categories: [],
        brand: null,
        coverPath: { path: "", contentType: "image" },
        imagePath: [],
        prepareDay: 24,
        seo: {
            title: "",
            description: "",
            keyword: [],
            seoImage: "",
            seoLength: { title: "", description: "", keyword: "" },
            permalink: "",
        },
        productType: 'general'
    },
    modalAddProduct: { isOpen: false, data: [] },
    modalLimitPurchase: { isOpen: false },
    coverImage: [],
    pathImage: [],
    seoImage: [],
    listAllProduct: [],
    listAllCategories: [],
    listAllBrands: { count: 0, data: [] },
    productsByShop: [],
    searchProductsByShop: [],
    loading: false,
    total: null,
    limit: 10,
    page: 1,
    sort: null,
    pageSizeOptions: ["10", "20", "40"],
    productAttribute: null,
    currentProduct: null,
    productLogisticList: null,
    product: {},
    valueSearch: '',
    statusSearch: false,
    activeTab: '',
    editProduct: {},
    editProductProductCount: 0,
    editProductSkuCount: 0,
    editProductIsAllProduct: 'all'
}

const countProductSku = (data) => {
     let totalProductSkus = 0;
    Object.keys(data).forEach(productId => {
      totalProductSkus += Object.keys(data[productId].productSkus).length;
    });
    return { editProductSkuCount: totalProductSkus || 0,editProductProductCount: Object.keys(data).length || 0 }
}

const unCheckProductEditProduct = (payload, state) => {
    const productId = Object.keys(payload)[0]
    delete state.editProduct[productId];
    const dataCount = countProductSku(state.editProduct)
    return {...state,...dataCount};
}

const checkProductEditProduct = (payload, state) => {
    state.editProduct = { ...state.editProduct, ...payload }
    const dataCount = countProductSku(state.editProduct)
    return {...state,...dataCount};
}

const setAllProductEditProduct = (payload,state) => {
    if(payload === 'some'){
        const dataCount = countProductSku(state.editProduct)
        state.editProductIsAllProduct = 'some'
        return {...state,...dataCount}
    } else {
        state.editProductIsAllProduct = 'all'
        return {...state}
    }
  
}


export default function (state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        // case OPEN_MODAL_CONFIRM:
        //     return {
        //         ...state,
        //         modalConfirm: payload
        //     }

        case ADD_KEY_VALUE_PRODUCT:
            return {
                ...state,
                [payload.key]: payload.value
            }
        case ADD_IMAGE_PATH:
            return {
                ...state,
                [payload.key]: [...state[payload.key], {
                    fileImage: payload.value
                }]
                // coverImage: payload.coverImage,
                // pathImage: payload.pathImage
            }
        case ADD_IMAGE_SEO:
            return {
                ...state,
                [payload.key]: [{ fileImage: payload.value }]
            }
        // case ADD_KEY_VALUE_PRODUCT:
        //     return {
        //         ...state,
        //         [payload.key]: payload.value
        //     }
        case ADD_VALUE_IN_PRODUCT:
            return {
                ...state,
                value: {
                    ...state.value,
                    [payload.key]: payload.value
                }
            }
        case ADD_SEO_IN_PRODUCT:
            return {
                ...state,
                value: {
                    ...state.value,
                    seo: {
                        ...state.value.seo,
                        [payload.key]: payload.value
                    }
                }
            }

        case ADD_KEYWORD_IN_SEO:
            return {
                ...state,
                value: {
                    ...state.value,
                    seo: {
                        ...state.value.seo,
                        keyword: [...state.value.seo.keyword, payload.seo.name]
                    }
                }
            }

        case REMOVE_KEYWORD_IN_SEO:
            let currentKeyword = [...state.value.seo.keyword]
            currentKeyword.splice(payload, 1)
            return {
                ...state,
                value: {
                    ...state.value,
                    seo: {
                        ...state.value.seo,
                        keyword: currentKeyword
                    }
                }
            }

        case GET_LIST_ALL_PRODUCT:
            return {
                ...state,
                listAllProduct: payload.getListProduct
            }
        case SET_PRODUCTS_BY_SHOP:
            const { productsByShop } = payload
            return { ...state, productsByShop }

        case SET_SEARCH_PRODUCTS_BY_SHOP:
            const { searchProductsByShop } = payload
            return { ...state, searchProductsByShop }

        case SET_PRODUCT_CATEGORIES:
            return {
                ...state,
                listAllCategories: payload.getCategories
            }

        case SELECT_PRODUCT_CATEGORIES:
            return {
                ...state,
                value: {
                    ...state.value,
                    // categories: [...state.value.categories, payload.name]
                    categories: [payload.name]
                }
            }
        case REMOVE_IMAGE_PRODUCT:
            let currentImage = [...state.pathImage]
            currentImage.splice(payload.idx, 1)
            return {
                ...state,
                pathImage: currentImage
            }

        case REMOVE_SEO_IMAGE:
            return {
                ...state,
                seoImage: []
            }

        case REMOVE_ALL_IMAGE:
            return {
                ...state,
                seoImage: [],
                pathImage: []
            }

        case OPEN_MODAL_ADD_PRODUCT:
            const { isOpen } = payload
            return {
                ...state,
                modalAddProduct: { isOpen }
            }
        case OPEN_MODAL_LIMIT_PURCHASE:
            return {
                ...state,
                modalLimitPurchase: { isOpen: payload.isOpen, data: payload.data, product: { ...payload.product } }
            }

        case SET_SEO_LENGTH:
            return {
                ...state,
                value: {
                    ...state.value,
                    seo: {
                        ...state.value.seo,
                        seoLength: {
                            ...state.value.seo.seoLength,
                            [payload.key]: payload.value
                        }
                    }
                }
            }

        case SET_KEY_PRODUCT:
            return {
                ...state,
                [payload.key]: payload.value
            }

        case UNCHECK_PRODUCT_EDIT_PRODUCT: /* payload  : (Object) {['shopID']: {[productId]:false}} */
            return { ...unCheckProductEditProduct(payload, state) }
        case CHECK_PRODUCT_EDIT_PRODUCT:
            return { ...checkProductEditProduct(payload, state) }
        case SET_ALL_PRODUCT: 
            return {  ...setAllProductEditProduct(payload,state) }
        case RESET_EDIT_PRODUCT: 
            return {  ...state,
            editProduct: {},
            editProductIsAllProduct: 'all',
            // editProductProductCount: 0,
            // editProductSkuCount: 0
        }
        default: {
            return state
        }

    }
}
