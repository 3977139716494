import React, { Component } from 'react'
import { ThemeProvider } from "styled-components";

const theme = {
    primaryColor:'#ED1072',
    secondaryColor: '#f7b100',
    // Background:#EEF1F4,
    Background: '#ffffff',
    White: '#ffffff',
    lightGray: '#f9f9f9',
    Gray: '#e0e0e0',
    Darkgray: '#828282',
    Darkgray2: '#646464',
    Black: '#131414',
    darkBlack: '#383633',
    Red: '#e94d4d',
    Orange: '#ED1072',
    Yellow: '#f7ef00',
    LightBlue: '#f1f3ff',
    line: '#57b535',
    facebook: '#1877f2',
    GreyComingSoon: '#e9ecef',
    Tertiary: '#31678c',
    lightYellow: '#feedc3',
    lightGreen: '#f1fbdd',
    oldLace: '#fdf3e8',
    disableGray: '#e1e1e1',
    lightRed: '#ffecec',
    grey: '#9d9d9d',
    DarkBlue: '#333bff',
    GreyIcon: '#808081',
    ActionDisabled: '#dbdbdb',
    infoMain: '#ED1072',

    BtnRed: '#ff1010',
    RedLogo: '#d10808',

    badgeGreen: '#f1fbdd',
    badgeOrange: '#feedc3',
    badgeRed: '#ffecec',
    badgeGray: '#e1e1e1',
    badgeYellow: '#fff4da',

    textRed: '#d10808',
    textGreen: '#88bb1b',
    textOrange: '#ED1072',
    textGray: '#808081',
    textBadgeYellow: '#ED1072',

    success: '#88bb1b',
    error: '#d10808',
    bg3: '#eeeeee',
    bgHot: '#FFF9F3',
    bg2: '#f3f3f3',

    defaultText: '#FFFFFF',
    defaultBg: '#F9F9F9',
    defaultTextPrimary: '#2A2C2D',
    defaultTextSecondary: '#444450',
    defaultTextTertiary: '#6B6B6B',
    defaultTextInput: '#AEAFAF',
    defaultTextDisable: 'rgba(42, 44, 45, 0.20)',

    defaultActionMain: '#AFAEAE',
    defaultActionDisable: '#DBDBDB',
    defaultActionborder: '#F2F2F2',
    defaultActionDivider: '#E9E9E9',

    defaultInfoMain: '#ED1072',
    defaultInfoBg: '#EC008C',

    // defaultInfoBg: '#FDEBF6',

    defaultSuccessMain: '#008F46',
    defaultSuccessBG: '#EEF5ED',

    defaultWarningMain: '#FE9138',
    defaultWarningBg: '#FFF5ED',

    defaultErrorMain: '#ED1E46',
    defaultErrorDark: '#C81F4C',
    defaultErrorBg: '#FEF5F4',

    AISPrimaryMain:'#B2D234',
    AISPrimaryLight: '#DCE04E',
    AISPrimaryDark: '#C9D44F',
    AISPrimaryBg: '#F9FBEF',
    AISPrimaryBorder:'#F4F8E2',

    defaultBMain: '#252527',
    defaultBDark: '#141414',
    defaultBLight: '#2E2E31',
    // PrimaryMain: #

    H4: '42px',
    H5: '32px',
    H6: '28px',
    subTitle1: '24px',
    subTitle2: '22px',
    caption: '20px',
    subCaption: '20px',
    tooltip: '16px',
    subTooltip: '16px',
    H1: '104px',
    H2: '68px',
    H3: '56px',
    body1: '24px',

    body2: '22px',
    content: '14px',
    note: '10px'
}

const Theme = ({ children }) => {
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </React.Fragment>
    )
};

export default Theme;