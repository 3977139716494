/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { takeLatest, select, call, put, delay, takeLeading } from "@redux-saga/core/effects";
import moment from "moment";
import ribbonProvider from "provider/ribbonProvider";
import { SET_GALLERY_FIELD } from "redux/actions/gallery";
import { CLOSE_TOAST, OPEN_TOAST } from "redux/actions/notification";
// import { GET_LIST_ALL_PRODUCT } from "redux/actions/product";
import { GET_LIST_DECORATE_GROUP, HANDLE_CREATE_DECORATE_GROUP, SET_KEY_RIBBON, HANDLE_CREATE_RIBBON, GET_LIST_RIBBON, 
    // SET_KEY_DATA_RIBBON, 
    SET_DATA_RIBBON_DEFAULT, SET_MODAL_CREATE_RIBBON, SET_MODAL_CREATE_CATEGORIES, GET_DATA_ADD_PRODUCT, ADD_PRODUCT_SELECT_RIBBON, HANDLE_CONFIRM_ADD_PRODUCT, SET_MODAL_ADD_PRODUCT_RIBBON, HANDLE_CHANGE_SHOP_SELECT_FILTER, HANDLE_GET_LIST_PRODUCT_DECORATE, SEARCH_PRODUCT_ADD_RIBBON, HANDLE_SELECT_ALL_PRODUCT_DECORATE, HANDLE_RANDOM_PRODUCT_DECORATE, HANDLE_SET_PERMISSION_DECORATE, SET_MODAL_CONFIRM_RIBBON, HANDLE_DELETE_DECORATE, HANDLE_UPDATE_STATUS_DECORATE_GROUP, HANDLE_GET_DECORATE_BY_ID, HANDLE_UPDATE_DECORATE_BY_ID, HANDLE_DELETE_DECORATE_GROUP, SET_PAGINATION_PRODUCT, SET_PAGINATION_RIBBON, HANDLE_CHANGE_PAGE_PRODUCT, HANDLE_CHANGE_PAGE_RIBBON, HANDLE_GET_SUPER_RIBBON, GET_ALBUM_RIBBON } from "redux/actions/ribbon";
import { 
    // getGallery, 
    getRibbon } from './selectors'
import productProvider from 'provider/productProvider'
import _, { isEqual } from "lodash";
import shopProvider from "provider/shopProvider";
import { SET_LOADING } from "redux/actions/app";
import galleryProvider from "provider/galleryProvider";
const api_product = new productProvider()
const api_shop = new shopProvider()


export function* handleCreateDecorateGroup(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        // const { payload } = action
        const getRibbonState = yield select(getRibbon)
        const data = {
            type: getRibbonState.activeTab,
            name: getRibbonState.decorateName
        }
        const res = yield call(ribbonProvider.createDecorateGroup, data)


        if (res.data.code === 422 && res.data.message === "NAME_ALREADY") {
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'errDecorateName', value: { err: true, message: 'มีชื่อนี้ในระบบแล้ว' } } })
        } else {
            yield put({ type: SET_MODAL_CREATE_CATEGORIES, payload: { isOpen: false, type: null } })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'decorateName', value: '' } })
            yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการสำเร็จ', type: 'success' } })
            yield getListDecorateGroup({})
            yield delay(2000)
            yield put({ type: CLOSE_TOAST, payload: { message: '', type: '' } })
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        console.log(`error`, error)
        yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการไม่สำเร็จ', type: 'success' } })
    }
}


export function* getListDecorateGroup(action) {
    try {
        // yield put({ type: SET_LOADING, payload: { loading: true } })
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'listRibbon', value: [] } })
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'listSuperRibbon', value: [] } })
        // const { payload } = action
        const getRibbonState = yield select(getRibbon)
        // const { pagination } = getRibbonState
        const type = getRibbonState?.activeTab
        const res = yield call(ribbonProvider.getListDecorateGroup, { type })
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'listDecorate', value: res.data.data } })
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'totalDecorate', value: res.data.count } })
        // yield put({ type: SET_PAGINATION_RIBBON,payload:{page:1,limit: pagination.ribbon.limit,total: res.data.count}})
        const defaultDecorateTab = [...res.data.data.filter((item) => item.id === { ...getRibbonState.decorateTab }.id)]
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'decorateTab', value: defaultDecorateTab[0]?.id ? defaultDecorateTab[0] : res.data.data[0] } })
        yield getListRibbon({})
        yield handleGetAlbumRibbon({})
        yield getListAllProductRibbon({})
        yield getListKeyRibbon({ payload: { id: res.data.data[0]?.id } })
        // yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        console.log(`error`, error)
    }
}

export function* handleCreateRibbon(action) {
    try {
        // const { payload } = action
        const getRibbonState = yield select(getRibbon)
        // const getGelleryState = yield select(getGallery)
        const decorateGroupId = getRibbonState.decorateTab.id
        let data = {
            ...getRibbonState.dataRibbon,
            startDate: moment(getRibbonState.dataRibbon.startDate + ' ' + getRibbonState.dataRibbon.startTime, "DD/MM/YYYY HH:mm"),
            endDate: moment(getRibbonState.dataRibbon.endDate + ' ' + getRibbonState.dataRibbon.endTime, "DD/MM/YYYY HH:mm"),
            decorationGroupId: decorateGroupId
        }
        if (!data.dateLimit) {
            delete data.startDate
            delete data.endDate
            delete data.startTime
            delete data.endTime
        }
        delete data.dateLimit
        delete data.startTime
        delete data.endTime


        const res = yield call(ribbonProvider.createRibbon, data)
        if (res.data.code === 422 && res.data.message === "NAME_ALREADY") {
            yield put({ type: OPEN_TOAST, payload: { message: 'มีชื่อนี้ในระบบแล้ว', type: 'danger' } })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'nameRibbonAlready', value: { err: true, message: 'มีชื่อนี้ในระบบแล้ว' } } })
        } else {
            yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการสำเร็จ', type: 'success' } })
            yield put({ type: SET_DATA_RIBBON_DEFAULT })
            yield put({ type: SET_GALLERY_FIELD, payload: { key: 'ribbon', value: [] } })
            yield put({ type: SET_MODAL_CREATE_RIBBON, payload: { isOpen: false, data: null, type: null } })
            yield getListRibbon({})
            yield delay(2000)
            yield put({ type: CLOSE_TOAST, payload: { message: '', type: '' } })
        }
    } catch (error) {
        console.log(`error`, error)
        yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการไม่สำเร็จ', type: 'danger' } })
    }


}

export function* getListRibbon(action) {
    try {
        // yield put({type: SET_LOADING,payload:{loading: true}})
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'listRibbon', value: [] } })
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'listSuperRibbon', value: [] } })
        const getRibbonState = yield select(getRibbon)
        const { pagination } = getRibbonState
        const decorateId = getRibbonState.decorateTab.id
        const res = yield call(ribbonProvider.getListRibbon, { id: decorateId, page: pagination.ribbon.page, limit: pagination.ribbon.limit })
        if (res.data) {
            yield getListAllRibbon({})
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listRibbon', value: res.data.data } })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'totalRibbon', value: res.data.count } })
            yield put({ type: SET_PAGINATION_RIBBON, payload: { page: pagination.ribbon.page, limit: pagination.ribbon.limit, total: res.data.count } })
            yield getSuperRibbon({})
            yield getListKeyRibbon({})
            // yield put({type: SET_LOADING,payload:{loading: false}})
        }
    } catch (error) {
        console.log(`error`, error)
    }
}

export function* handleChangeDecorateGroup(action) {
    try {
        yield put({type: SET_LOADING,payload:{loading: true}})
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'listRibbon', value: [] } })
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'listSuperRibbon', value: [] } })
        const getRibbonState = yield select(getRibbon)
        const { pagination } = getRibbonState
        const decorateId = getRibbonState.decorateTab.id
        const res = yield call(ribbonProvider.getListRibbon, { id: decorateId, page: pagination.ribbon.page, limit: pagination.ribbon.limit })
        if (res.data) {
            yield getListAllRibbon({})
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listRibbon', value: res.data.data } })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'totalRibbon', value: res.data.count } })
            yield put({ type: SET_PAGINATION_RIBBON, payload: { page: pagination.ribbon.page, limit: pagination.ribbon.limit, total: res.data.count } })
            yield getSuperRibbon({})
            yield getListKeyRibbon({})
            yield put({type: SET_LOADING,payload:{loading: false}})
        }
    } catch (error) {
        console.log(`error`, error)
    }
}

export function* getListAllRibbon(action) {
    try {
        const getRibbonState = yield select(getRibbon)
        const decorateId = getRibbonState.decorateTab.id
        const res = yield call(ribbonProvider.getListRibbon, { id: decorateId })
        if (res.data) {
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listAllRibbon', value: res.data.data } })
        }
    } catch (error) {
        console.log(`error`, error)
    }
}

export function* getSuperRibbon(action) {
    const getRibbonState = yield select(getRibbon)
    const decorateId = getRibbonState.decorateTab.id
    const res = yield call(ribbonProvider.getListRibbon, { id: decorateId })
    if (res.data) {
        const findSuper = res.data.data.filter((item) => item.priority === 1)
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'listSuperRibbon', value: findSuper } })
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'totalSuperRibbon', value: findSuper.length } })
    }
}

export function* getListProductDecorate(action) {
    try {
        const getRibbonState = yield select(getRibbon)
        const { pagination } = getRibbonState
        const data = {
            page: pagination.product.page,
            limit: pagination.product.limit
        }
        const getListProduct = yield call(api_product.getListAllProduct, data)
        yield put({
            type: SET_KEY_RIBBON, payload: { key: 'listProduct', value: getListProduct.product }
        })
        yield put({
            type: SET_KEY_RIBBON, payload: { key: 'listProductCount', value: getListProduct.count }
        })
        yield put({ type: SET_PAGINATION_PRODUCT, payload: { page: pagination.product.page, limit: pagination.product.limit, total: getListProduct.count } })
        const shops = yield call(api_shop.getAllShops, {})
        yield put({
            type: SET_KEY_RIBBON, payload: { key: 'listShops', value: shops.shop }
        })
        yield put({
            type: SET_KEY_RIBBON, payload: { key: 'listShopsCount', value: shops.count }
        })

    } catch (error) {
        console.log(`error`, error)
    }
}

export function* handleAddProductSelectRibbon(action) {
    try {
        const { payload } = action
        const { product } = payload
        const getRibbonState = yield select(getRibbon)
        let listProductSelected = getRibbonState.listProductSelected
        const isSame = listProductSelected.find((item) => isEqual(item.id, product.id))
        if (!isSame) {
            listProductSelected = [...listProductSelected, product]
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: listProductSelected } })
        } else {
            const index = listProductSelected.findIndex((item) => isEqual(item.id, product.id))
            listProductSelected.splice(index, 1)
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: listProductSelected } })
        }
    } catch (error) {
        console.log(`error`, error)
    }
}

export function* handleConfirmAddProduct(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        // const { payload } = action
        const getRibbonState = yield select(getRibbon)
        const res = yield call(ribbonProvider.getListDecorateByDecorateId, { id: getRibbonState.modalAddProduct.data?.id })
        if (res.data.length === 0) {
            const getRibbonState = yield select(getRibbon)
            const shopId = []
            // eslint-disable-next-line array-callback-return
            getRibbonState.listProductSelected.map((product) => {
                if (!shopId.includes(product.shopId)) {
                    shopId.push(product.shopId)
                }
            })
            const setDataProductShop = []
            shopId.map((shop) => {
                const data = {
                    shopId: shop,
                    productIds: []
                }
                const filterProductShop = getRibbonState.listProductSelected.filter((product) => product.shopId === shop)
                filterProductShop.map((product) => data.productIds.push(product.id))
                setDataProductShop.push(data)
            })
            let data = {
                items: setDataProductShop,
                decorationGroupId: getRibbonState.decorateTab.id,
                decorationId: getRibbonState.modalAddProduct.data.id
            }

            const res = yield call(ribbonProvider.createProductDecorate, data)
            if (res) {
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: [] } })
                yield put({ type: SET_MODAL_ADD_PRODUCT_RIBBON, payload: { isOpen: false, data: null } })
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'searchProduct', value: '' } })
                yield getListRibbon({})
            }
        } else {
            const productId = res.data.map((item) => item.product.id)
            const productSelected = getRibbonState.listProductSelected.map((item) => item.id)
            const setOutItem = productId.filter((item) => {
                if (!productSelected.includes(item)) {
                    return item
                }
            })
            const setNewItem = productSelected.filter((item) => {
                if (!productId.includes(item)) {
                    return item
                }
            })
            const shopNewItemId = []
            const shopNewItem = setNewItem.map((item) => getRibbonState.listAllProduct.find((shop) => shop.id == item))
            shopNewItem.map((item) => {
                if (!shopNewItemId.includes(item.shop.id)) {
                    shopNewItemId.push(item.shop.id)
                }
            })

            let data = {
                newItems: [],
                outItems: []
            }

            shopNewItemId.map((item) => {
                const shopData = {
                    shopId: item,
                    productIds: []
                }
                const filter = shopNewItem.filter((shop) => shop.shop.id === item)
                filter.map((product) => shopData.productIds.push(product.id))
                data.newItems.push(shopData)
            })

            const shopOutItemId = []
            const shopOutItem = setOutItem.map((item) => getRibbonState.listAllProduct.find((shop) => shop.id == item))
            shopOutItem.map((item) => {
                if (!shopOutItemId.includes(item.shop.id)) {
                    shopOutItemId.push(item.shop.id)
                }
            })
            shopOutItemId.map((shop) => {
                const shopData = {
                    shopId: shop,
                    productIds: []
                }
                const filter = shopOutItem.filter((item) => item.shop.id === shop)
                filter.map((product) => shopData.productIds.push(product.id))
                data.outItems.push(shopData)
            })

            const updateProduct = yield call(ribbonProvider.updateProductDecorate, { data, id: getRibbonState.modalAddProduct.data.id })
            if (updateProduct.data) {
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: [] } })
                yield put({ type: SET_MODAL_ADD_PRODUCT_RIBBON, payload: { isOpen: false, data: null } })
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'searchProduct', value: '' } })
                yield getListRibbon({})
            }

        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        console.log(`error`, error)
    }
}

export function* handleChangeProductByShop(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        // const { payload } = action
        const getRibbonState = yield select(getRibbon)
        const { pagination } = getRibbonState
        if (getRibbonState.shopSelectFilter.toString().trim().length === 0 || getRibbonState.shopSelectFilter === null || getRibbonState.shopSelectFilter == {} || Object.keys(getRibbonState.shopSelectFilter).length === 0) {
            const res = yield call(api_product.getListAllProduct, { page: getRibbonState.pagination.product.page, limit: getRibbonState.pagination.product.limit })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'searchProduct', value: '' } })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProduct', value: res.product } })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductCount', value: res.count } })
            yield put({ type: SET_PAGINATION_PRODUCT, payload: { page: pagination.product.page, limit: pagination.product.limit, total: res.count } })
        } else {
            const res = yield call(api_product.getProductsByShop, { shopId: getRibbonState.shopSelectFilter.id, page: getRibbonState.pagination.product.page, limit: getRibbonState.pagination.product.limit })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'searchProduct', value: '' } })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProduct', value: res.product } })
            yield put({ type: SET_PAGINATION_PRODUCT, payload: { page: pagination.product.page, limit: pagination.product.limit, total: res.count } })
            const resAllProduct = yield call(api_product.getProductsByShop, { shopId: getRibbonState.shopSelectFilter.id, page: 1, limit: 999 })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductCount', value: resAllProduct.count } })
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductShop', value: resAllProduct.product } })
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        console.log(`error`, error)
    }
}

export function* getListDecorateByDecorateId(action) {
    try {
        // const { payload } = action
        const getRibbonState = yield select(getRibbon)
        const res = yield call(ribbonProvider.getListDecorateByDecorateId, { id: getRibbonState.modalAddProduct.data?.id })
        let productData = []
        if (res.data) {
            res.data.map((item) => productData.push({ ...item.product, shop: item.shop }))
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: productData } })
        }
    } catch (error) {
        console.log(`error`, error)
    }
}

export function* handleSearchProductRibbon(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        // const { payload } = action
        const getRibbonState = yield select(getRibbon)
        const { searchProduct, pagination } = getRibbonState
        if(getRibbonState.searchProduct.trim().length !== 0){
            if(typeof getRibbonState.shopSelectFilter == "object" && !_.isEmpty(getRibbonState.shopSelectFilter)){
                const res = yield call(api_product.getSearchProductsByShop,{keyword: searchProduct,limit:pagination.product.limit ,page:pagination.product.page,shopId:getRibbonState.shopSelectFilter.id})
                 yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProduct', value: res.product } })
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductCount', value: res.count } })
                yield put({ type: SET_PAGINATION_PRODUCT, payload: { page: pagination.product.page, limit: pagination.product.limit, total: res.count } })

                const resAllProduct = yield call(api_product.getSearchProductsByShop,{keyword: searchProduct,limit:999 ,page:1,shopId:getRibbonState.shopSelectFilter.id})
                 yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductShop', value: resAllProduct.product } })
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductCount', value: resAllProduct.count } })
                // yield put({ type: SET_PAGINATION_PRODUCT, payload: { page: pagination.product.page, limit: pagination.product.limit, total: res.data.count } })
            } else {
                const res = yield call(api_product.handleSearchProduct,{search: searchProduct,limit:pagination.product.limit ,page:pagination.product.page})
                     yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProduct', value: res.data.product } })
                    yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductCount', value: res.data.count } })
                    yield put({ type: SET_PAGINATION_PRODUCT, payload: { page: pagination.product.page, limit: pagination.product.limit, total: res.data.count } })
    
                    const resAllPProduct = yield call(api_product.handleSearchProduct,{search: searchProduct,limit:999 ,page:1})
                     yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductShop', value: resAllPProduct.data.product } })
                    yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductCount', value: resAllPProduct.data.count } })
                    // yield put({ type: SET_PAGINATION_PRODUCT, payload: { page: pagination.product.page, limit: pagination.product.limit, total: res.data.count } })            }
            }
        } else {
            yield handleChangeProductByShop({})
        }
        // if (isEqual(getRibbonState.shopSelectFilter, {}) || getRibbonState.shopSelectFilter.toString().trim().length === 0) {
        //     const getListProduct = yield call(api_product.getListAllProduct, { page: 1, limit: 999 })
        //     console.log(`getListProduct`, getListProduct)
        //     const filter = getListProduct.product.filter((product) => product.name.toLowerCase().includes(searchProduct.toLowerCase()))
        //     console.log(`filter`, filter)
        //     yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProduct', value: filter } })
        //     yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductCount', value: filter.length } })
        //     yield put({ type: SET_PAGINATION_PRODUCT, payload: { page: 1, limit: 100, total: filter.length } })
        // } else {
        //     const getListProduct = yield call(api_product.getProductsByShop, { shopId: getRibbonState.shopSelectFilter.id, page: 1, limit: 999 })
        //     const filter = getListProduct.product.filter((product) => product.name.toLowerCase().includes(searchProduct.toLowerCase()))
        //     yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProduct', value: filter } })
        //     yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductCount', value: filter.length } })
        //     yield put({ type: SET_PAGINATION_PRODUCT, payload: { page: 1, limit: 100, total: filter.length } })
        // }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
        console.log(`error`, error)
    }
}

export function* handleSelectAllProductDecorate(action) {
    const getRibbonState = yield select(getRibbon)
    let { 
        // listProduct, 
        listProductSelected, listAllProduct, shopSelectFilter,listProductShop, 
        // searchProduct 
    } = getRibbonState

    const productSelected = [...listProductSelected.map((product) => product.id)]
    // const product = [...listProduct.map((product) => product.id)]
    const allProduct = [...listAllProduct.map((product) => product.id)]
    const productShop = [...listProductShop.map((product) => product.id)]
        if (typeof shopSelectFilter == 'object' && Object.keys(shopSelectFilter).length == 0) { ////all shop
            if (allProduct.filter((item) => productSelected.includes(item)).length === allProduct.length) {
                allProduct.map((item) => {
                    if (productSelected.includes(item)) {
                        const indexProduct = listProductSelected.findIndex((product, idx) => product.id === item)
                        listProductSelected.splice(indexProduct, 1)
                    }
                })
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: listProductSelected } })
            } else {
                allProduct.map((item) => {
                    if (productSelected.includes(item)) {
    
                    } else {
                        listProductSelected.push(listAllProduct.find((product) => isEqual(product.id, item)))
                    }
                })
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: listProductSelected } })
            }
        } else { /// filter shop
            if (productShop.filter((item) => productSelected.includes(item)).length === productShop.length) {
                productShop.map((item) => {
                    if (productSelected.includes(item)) {
                        const indexProduct = listProductSelected.findIndex((product, idx) => product.id === item)
                        listProductSelected.splice(indexProduct, 1)
                    }
                })
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: listProductSelected } })
            } else {
                productShop.map((item) => {
                    if (productSelected.includes(item)) {
    
                    } else {
                        listProductSelected.push(listProductShop.find((product) => isEqual(product.id, item)))
                    }
                })
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: listProductSelected } })
            }
        }
    }

export function* handleRandomProductDecorate(action) {
    const getRibbonState = yield select(getRibbon)
    let { 
        // listProduct,
         listProductSelected, shopSelectFilter, listAllProduct, listProductShop, 
        //  searchProduct 
        } = getRibbonState
    const idProductRandom = []
    const productRandom = []


    // if(searchProduct.trim().length !== 0){
    //     for (var i = 0; i < Math.floor(listProduct.length * (30 / 100)); i++) {
    //         const indexRandom = getRandomIndex(listProduct)
    //         if (idProductRandom.includes(indexRandom)) {
    //             // return
    //             i--;
    //         } else {
    //             idProductRandom.push(indexRandom)
    //             productRandom.push(listProduct[indexRandom])
    //         }
    //     }
    //     if (shopSelectFilter?.id) {
    //         const filterShop = listProductSelected.filter((product) => product.shopId !== shopSelectFilter.id)
    //         yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: [...filterShop, ...productRandom] } })
    //     } else {
    //         yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: productRandom } })
    //     }
    // } else {
        if (typeof shopSelectFilter == "object" && Object.keys(shopSelectFilter).length == 0) {
            for (var i = 0; i < Math.floor(listAllProduct.length * (30 / 100)); i++) {
                const indexRandom = getRandomIndex(listAllProduct)
                if (idProductRandom.includes(indexRandom)) {
                    // return
                    i--;
                } else {
                    idProductRandom.push(indexRandom)
                    productRandom.push(listAllProduct[indexRandom])
                }
            }
            if (shopSelectFilter?.id) {
                const filterShop = listProductSelected.filter((product) => product.shopId !== shopSelectFilter.id)
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: [...filterShop, ...productRandom] } })
            } else {
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: productRandom } })
            }
        } else {
            // eslint-disable-next-line no-redeclare
            for (var i = 0; i < Math.floor(listProductShop.length * (30 / 100)); i++) {
                const indexRandom = getRandomIndex(listProductShop)
                if (idProductRandom.includes(indexRandom)) {
                    // return
                    i--;
                } else {
                    idProductRandom.push(indexRandom)
                    productRandom.push(listProductShop[indexRandom])
                }
            }
            if (shopSelectFilter?.id) {
                const filterShop = listProductSelected.filter((product) => product.shopId !== shopSelectFilter.id)
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: [...filterShop, ...productRandom] } })
            } else {
                yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: productRandom } })
            }
        }
    // }
    // yield put({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: productRandom } })
}

function getRandomIndex(items) {
    return Math.floor(Math.random() * items.length)
}

export function* handleSetPermissionDecorate(action) {
    try {
        yield put({type: SET_LOADING,payload:{loading: true}})
        yield put({ type: SET_MODAL_CONFIRM_RIBBON, payload: { isOpen: false, data: null, message: '', btnCancel: '', btnConfirm: '' } })
        yield put({type: SET_KEY_RIBBON,payload:{key:'executingPriority',value: true}})
        const { payload } = action
        // const getRibbonState = yield select(getRibbon)
        const res = yield call(ribbonProvider.setPermissionDecorate, { id: payload.id, priority: payload.priority })
        if (res.data) {
            yield getListDecorateGroup({})
        }
        yield put({type: SET_KEY_RIBBON,payload:{key:'executingPriority',value: false}})
        yield put({type: SET_LOADING,payload:{loading: false}})
    } catch (error) {
        yield put({type: SET_LOADING,payload:{loading: false}})
        yield put({type: SET_KEY_RIBBON,payload:{key:'executingPriority',value: false}})
        console.log(`error`, error)
    }
}

export function* handleDeleteDecorate(action) {
    try {
        yield put({type: SET_LOADING,payload:{loading: true}})
        yield put({type: SET_KEY_RIBBON,payload:{key:'executingPriority',value: true}})
        const { payload } = action
        const res = yield call(ribbonProvider.deleteDecorate, { id: payload.id })
        if (res.data) {
            yield put({ type: SET_MODAL_CONFIRM_RIBBON, payload: { isOpen: false, data: null, message: '', btnCancel: '', btnConfirm: '' } })
            yield getListDecorateGroup({})
        }
        yield put({type: SET_KEY_RIBBON,payload:{key:'executingPriority',value: false}})
        yield put({type: SET_LOADING,payload:{loading: false}})
    } catch (error) {
        yield put({type: SET_LOADING,payload:{loading: false}})
        yield put({type: SET_KEY_RIBBON,payload:{key:'executingPriority',value: false}})
        console.log(`error`, error)
    }
}

export function* handleUpdateStatusDecorateGroup(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const { payload } = action
        const getRibbonState = yield select(getRibbon)
        const status = getRibbonState.decorateTab?.status
        const name = payload.name
        const id = getRibbonState.decorateTab?.id
        // const isDefault = getRibbonState.decorateTab?.default
        let data = {
            id,
            status,
            name,
            // isDefault
        }
        if (name === getRibbonState.decorateTab.name) delete data.name

        const res = yield call(ribbonProvider.updateDecorateGroup, data)
        let validate = false
        if (name.trim().length == 0 || name == null) {
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'errDecorateName', value: true } })
        } else {
            validate = true
        }
        if (validate && res.data.code === 422 && res.data.message === "NAME_ALREADY") {
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'errDecorateName', value: { err: true, message: 'มีชื่อนี้ในระบบแล้ว' } } })
        } else if (res.data && validate) {
            yield getListDecorateGroup({})
            yield put({ type: SET_MODAL_CREATE_CATEGORIES, payload: { isOpen: false, type: null, data: null } })
            yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการสำเร็จ', type: 'success' } })
            yield delay(2000)
            yield put({ type: CLOSE_TOAST, payload: { message: '', type: '' } })

        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        console.log(`error`, error)
        yield put({ type: SET_LOADING, payload: { loading: false } })
        yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการผิดพลาด', type: 'danger' } })
        yield delay(2000)
        yield put({ type: CLOSE_TOAST, payload: { message: '', type: '' } })
    }
}

export function* handleGetDecorateById(action) {
    //param ID
    const { payload } = action
    let getRibbonState = yield select(getRibbon)
    const ribbon = getRibbonState.listRibbon.filter((item) => item.id === payload.id)
    if (ribbon[0]) {
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'dataRibbon', value: { ...getRibbonState.dataRibbon, ...ribbon[0] } } })
        getRibbonState = yield select(getRibbon)
        if (ribbon[0].startDate == null) {
            yield put({
                type: SET_KEY_RIBBON, payload: {
                    key: 'dataRibbon', value: {
                        ...getRibbonState.dataRibbon, dateLimit: false,
                        startDate: moment().format('DD/MM/YYYY'),
                        endDate: moment().format('DD/MM/YYYY'),
                    }
                }
            })
        } else {
            yield put({
                type: SET_KEY_RIBBON, payload: {
                    key: 'dataRibbon', value: {
                        ...getRibbonState.dataRibbon,
                        dateLimit: true,
                        startDate: moment(ribbon[0].startDate).format('DD/MM/YYYY'),
                        endDate: moment(ribbon[0].endDate).format('DD/MM/YYYY'),
                        startTime: moment(ribbon[0].startDate).format('HH:mm'),
                        endTime: moment(ribbon[0].endDate).format('HH:mm'),
                        imagePath: ribbon[0].imagePath
                    }
                }
            })
        }
    }
}

export function* handleUpdateDecorateById(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        // const { payload } = action
        const getRibbonState = yield select(getRibbon)
        const ribbon = getRibbonState.dataRibbon
        const ribbonSelected = { ...getRibbonState.listRibbon.filter((item) => item.id === ribbon.id)[0] }
        let data = {
            name: ribbon.name,
            key: ribbon.key,
            imageId: ribbon.imageId,
            // dateLimit: ribbon.dateLimit,
            startDate: moment(ribbon.startDate + ' ' + ribbon.startTime, "DD/MM/YYYY HH:mm"),
            endDate: moment(ribbon.endDate + ' ' + ribbon.endTime, "DD/MM/YYYY HH:mm"),
            status: ribbon.status,
        }
        if (ribbon.name === ribbonSelected.name) delete data.name
        if (ribbon.key === ribbonSelected.key) delete data.key
        if (ribbon.imageId === ribbonSelected.imageId) delete data.imageId
        if (!ribbon.dateLimit) {
            delete data.startDate
            delete data.endDate
        }
        const res = yield call(ribbonProvider.updateDecorateById, { data, id: ribbon.id })

        if (res.data.code === 422 && res.data.message === "NAME_ALREADY") {
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'nameRibbonAlready', value: { err: true, message: 'มีชื่อนี้ในระบบแล้ว' } } })
        } else if(res.data.code === 422 && res.data.message === "CANNOT_USE_KEY"){
            yield put({ type: SET_KEY_RIBBON, payload: { key: 'keyRibbonAlready', value: { err: true, message: 'ไม่สามารถใช้งาน tag นี้ได้' } } })
        } else if (res.data) {
            yield put({ type: SET_MODAL_CREATE_RIBBON, payload: { isOpen: false, type: null, data: null } })
            yield put({ type: SET_DATA_RIBBON_DEFAULT })
            yield put({ type: SET_GALLERY_FIELD, payload: { key: 'ribbon', value: [] } })
            yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการสำเร็จ', type: 'success' } })
            yield delay(2000)
            yield put({ type: CLOSE_TOAST, payload: { message: '', type: '' } })
            yield getListDecorateGroup({})
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        console.log(`error`, error)
    }
}

export function* handleDeleteDecorateGroup(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const { payload } = action
        const res = yield call(ribbonProvider.deleteDecorateGroup, { id: payload.id })
        if (res.data.status === "success") {
            yield put({ type: SET_MODAL_CONFIRM_RIBBON, payload: { isOpen: false, data: null, type: null } })
            yield put({ type: SET_MODAL_CREATE_CATEGORIES, payload: { isOpen: false, type: null } })
            yield getListDecorateGroup({})
            yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการสำเร็จ', type: 'success' } })
            yield delay(2000)
            yield put({ type: CLOSE_TOAST, payload: { message: '', type: '' } })
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการไม่สำเร็จ', type: 'danger' } })
        console.log(`error`, error)
    }
}

export function* handleChangePageProduct(action) {
    const { payload } = action
    yield put({ type: SET_PAGINATION_PRODUCT, payload: { page: payload.page } })
    const ribbon = yield select(getRibbon)
    if(ribbon.searchProduct.trim().length !== 0){
        yield handleSearchProductRibbon({})
    } else {
        yield handleChangeProductByShop({})
    }
}

export function* handleChangePageRibbon(action) {
    const { payload } = action
    yield put({ type: SET_PAGINATION_RIBBON, payload: { page: payload.page } })
    yield getListRibbon({})

}

export function* handleGetAlbumRibbon(action) {
    // const { payload } = action
    const album = yield call(galleryProvider.getAlbumById, { shopId: 0 })
    const findIdAlbum = album.filter((item) => item.name.toLowerCase().includes('ribbon'))
    const findIdFrame = album.filter((item) => item.name.toLowerCase().includes('frame'))
    yield put({
        type: SET_KEY_RIBBON, payload: {
            key: 'albumRibbon', value: {
                ribbon: findIdAlbum.length > 0 ? { ...findIdAlbum[0] } : null,
                frame: findIdFrame.length > 0 ? { ...findIdFrame[0] } : null
            }
        }
    })
}

export function* getListAllProductRibbon(action) {
    try {
        // const getRibbonState = yield select(getRibbon)
        // const { pagination } = getRibbonState
        const data = {
            page: 1,
            limit: 999,
            id: 'al;sdkal;sdk'
        }
        const getListProduct = yield call(api_product.getListAllProduct, data)
        yield put({
            type: SET_KEY_RIBBON, payload: { key: 'listAllProduct', value: getListProduct.product }
        })
    } catch (error) {
        console.log(`error`, error)
    }
}

export function* getListKeyRibbon(action) {
    try {
        const getRibbonState = yield select(getRibbon)
        // const { payload } = action
        const res = yield call(ribbonProvider.getListKeyDecorate, { id: getRibbonState.decorateTab.id })
        yield put({ type: SET_KEY_RIBBON, payload: { key: 'listKey', value: res.data } })
    } catch (error) {
        console.log(`error`, error)
    }
}


export default function* useWatcher() {
    // yield takeLatest(GET_LIST_PROMOCODE, onGetListPromocode)
    yield takeLatest(HANDLE_CREATE_DECORATE_GROUP, handleCreateDecorateGroup)
    yield takeLatest(GET_LIST_DECORATE_GROUP, getListDecorateGroup)
    yield takeLatest(HANDLE_CREATE_RIBBON, handleCreateRibbon)
    yield takeLatest(GET_LIST_RIBBON, handleChangeDecorateGroup)
    yield takeLatest(GET_DATA_ADD_PRODUCT, getListProductDecorate)
    yield takeLatest(ADD_PRODUCT_SELECT_RIBBON, handleAddProductSelectRibbon)
    yield takeLatest(HANDLE_CONFIRM_ADD_PRODUCT, handleConfirmAddProduct)
    yield takeLatest(HANDLE_CHANGE_SHOP_SELECT_FILTER, handleChangeProductByShop)
    yield takeLatest(HANDLE_GET_LIST_PRODUCT_DECORATE, getListDecorateByDecorateId)
    yield takeLatest(SEARCH_PRODUCT_ADD_RIBBON, handleSearchProductRibbon)
    yield takeLatest(HANDLE_SELECT_ALL_PRODUCT_DECORATE, handleSelectAllProductDecorate)
    yield takeLatest(HANDLE_RANDOM_PRODUCT_DECORATE, handleRandomProductDecorate)
    yield takeLatest(HANDLE_SET_PERMISSION_DECORATE, handleSetPermissionDecorate)
    yield takeLatest(HANDLE_DELETE_DECORATE, handleDeleteDecorate)
    yield takeLatest(HANDLE_UPDATE_STATUS_DECORATE_GROUP, handleUpdateStatusDecorateGroup)
    yield takeLeading(HANDLE_GET_DECORATE_BY_ID, handleGetDecorateById)
    yield takeLatest(HANDLE_UPDATE_DECORATE_BY_ID, handleUpdateDecorateById)
    yield takeLatest(HANDLE_DELETE_DECORATE_GROUP, handleDeleteDecorateGroup)
    yield takeLatest(HANDLE_CHANGE_PAGE_PRODUCT, handleChangePageProduct)
    yield takeLatest(HANDLE_CHANGE_PAGE_RIBBON, handleChangePageRibbon)
    yield takeLatest(HANDLE_GET_SUPER_RIBBON, getSuperRibbon)
    yield takeLatest(GET_ALBUM_RIBBON, handleGetAlbumRibbon)
}