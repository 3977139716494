import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { dispatchOrder, SET_MODAL_REFUND_REASON, REQUEST_REFUND } from '../../redux/actions/order';
import './index.scss'
import { ReactComponent as IconClose } from '../../images/icons/close.svg'


class modalRefundReason extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            message: ""
        }
    }


    confirmModal = () => {
        // this.props.confirm();
        // this.props.dispatch(dispatchModalConfirm({type:OPEN_MODAL_CONFIRM,payload:false}))
    }

    cancleModal = () => {
        // this.props.dispatch(dispatchModalConfirm({type:OPEN_MODAL_CONFIRM,payload:false}))
    }

    onClickRefund = () => {
    }

    onCloseModal = () => {
        this.props.dispatch(dispatchOrder({ type: SET_MODAL_REFUND_REASON, payload: false }))
        this.setState({ message: "" })
    }

    onSubmitRefund = () => {
        this.props.dispatch(dispatchOrder({ type: REQUEST_REFUND, payload: { id: this.props.id, message: this.state.message, issueNo:this.props.issueNo } }))

    }

   

    render() {
        /// message show
        const { isOpen, data } = this.props
        return (
            <React.Fragment>
                <div className="modal-refund-container">
                    <Modal isOpen={isOpen} toggle={() => this.setState({ modal: false })} className="modal-refund">
                        <ModalHeader className="">ขอคืนเงิน
                            {/* <img src={Close} className="close cursor-pointer" onClick={this.onCloseModal} /> */}
                            <IconClose className='cursor-pointer close' onClick={this.onCloseModal} />
                        </ModalHeader>
                        <ModalBody>
                            <div className="body-container">
                                <span>กรุณาระบุหมายเหตุการขอคืนเงิน</span>
                                <textarea className="text-refund" rows="10" placeholder="ระบุหมายเหตุ" onChange={(e) => this.setState({ message: e.target.value })} value={this.state.message} />

                                <button className="btn-submit mt-4" onClick={this.onSubmitRefund}>ตกลง</button>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    // modalConfirm : state.modalConfirm.ModalConfirm
    modalRefundReason: state.order.modalRefundReason,
    isOpen: state.order.modalRefundReason.isOpen
})

export default connect(mapStateToProps, null)(modalRefundReason)