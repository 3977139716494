/* eslint-disable eqeqeq */
import React, { Component } from 'react';
// import { Nav, NavItem, NavLink } from 'reactstrap';
// import classnames from 'classnames';
// import { InputSearch } from 'components/output';
import { connect } from 'react-redux';
import '../styles/_withdraw.scss';
import TableHeader from 'components/TableHeader';
import {
    // SET_TAB_ACTIVE, 
    disapatchWithdraw, GET_LIST_WITHDRAW, ON_ADD_SELECT_WITHDRAW, ON_SELECT_ALL_WITHDRAW, ON_CONFITM_WITHDRAW, HANDLE_SET_TAB_ACTIVE, SET_KEY_WITHDRAW, ON_SEARCH_WITHDRAW,
    SET_DEFAULT_WITHDRAW, OPEN_MODAL_WITHDRAW_PREVIEW, CLOSE_MODAL_WITHDRAW_PREVIEW, FETCH_ORDER_SELLER_WITHDRAW, GET_LIST_SHOP_WITHDRAW
} from '../redux/actions/withdraw'
// import Checkbox from 'components/Checkbox'
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';
import PaginationComponent from 'components/pagination';
import numeral from 'numeral'
import {
    // displayDateTh, 
    displayDateThShortYear
} from 'helper/timeUtil';
import noItem from 'images/icons/status-error.svg'
import ModalWithdrawPreview from 'components/ModalWithdrawPreview';
import SelectInput from 'components/SelectInput';
import { getUserRole } from 'helper/utils';
import { OWNER, SUPER_ADMIN } from 'layout/Sidebar/Role';
// import { getUser } from 'redux/sagas/selectors';
// import moment from 'moment';
import { Body1, Body2, Caption, H5 } from 'components/FontStyle';
import PeriodTimeInput from 'components/PeriodTimeInput';
import SelectInputWithImg from 'components/SelectInputWithImg';
import { dispatchApp, SET_LOADING } from 'redux/actions/app';
import moment from 'moment';
import { ReactComponent as IconNoItem } from 'images/icons/no-item.svg'
import { options } from 'components/PeriodTimeInput/dataUtil';
import XlsxCreate from 'components/XlsxCreate';
import classNames from 'classnames';
import { getUser } from 'redux/sagas/selectors';
import withdrawProvider from 'provider/withdrawProvider';
import AccountantWithdraw from 'components/output/Withdraw/AccountantWithdraw';
import AdminWithdraw from 'components/output/Withdraw/AdminWithdraw';

// const headerTab = [
//     { value: 'never', label: 'รายการรอโอน' },
//     { value: 'processing', label: 'กำลังดำเนินการ' },
//     { value: 'transferred', label: 'ระบบโอนเงินแล้ว' }
// ]

const withdrawStatus = {
    NEVER: 'never',
    PROCESSING: 'inProgress',
    TRANSFERRED: 'transferred'
}

const sellerWithdrawStatus = {
    ALL: 'all',
    PROCESSING: 'inProgress',
    TRANSFERRED: 'transferred'
}

const statusWithdraw = [
    'requested', 'settled', 'approved', 'transferred'
]

class WithDraw extends Component {
    constructor(props) {
        super(props)
        this.state = {
            statusSearch: false,
            valueSearch: '',
            shopSelected: null,
            shopSelectedIndex: 0,
            dateOption: options.WITHIN_MONTH,
            dateSelected:{ startDate: moment().startOf('month'), endate: moment().subtract(1,'days').endOf('day') }
        }
    }

    componentDidMount() {
        this.props.dispatch(disapatchWithdraw({ type: GET_LIST_SHOP_WITHDRAW }))
        this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW }))
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.limit != nextProps.limit || this.props.page != nextProps.page) {
            // this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW }))
            // this.handleSearch()
        }
    }

    componentWillUnmount() {
        this.props.dispatch(disapatchWithdraw({ type: SET_DEFAULT_WITHDRAW }))
        this.props.dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
    }

    toggle = (status) => {
        this.props.dispatch(disapatchWithdraw({ type: HANDLE_SET_TAB_ACTIVE, payload: { status, shopId: this.state.shopSelectedIndex && this.props.listShop[this.state.shopSelectedIndex]?.value?.id } }))
    }

    onAddWithdrawId = (order) => {
        this.props.dispatch(disapatchWithdraw({ type: ON_ADD_SELECT_WITHDRAW, payload: { id: order.id, priceTotal: order.priceTotal } }))
    }

    checkDuplicateArr = (arr, value) => {
        return arr.some(item => item == value)
    }

    handleSelectAllWithdraw = () => {
        this.props.dispatch(disapatchWithdraw({ type: ON_SELECT_ALL_WITHDRAW }))
    }

    handleConfirm = () => {
        this.props.dispatch(dispatchModalConfirm({
            type: OPEN_MODAL_CONFIRM, payload: {
                isOpen: true,
                message: 'ยืนยันการถอนเงิน',
                message2: `จำนวนเงิน : ${numeral(this.props.priceTransfer).format('0,0.00')} บาท`,
                type: 'success',
                btnConfirm: 'ตกลง',
                btnCancel: 'ยกเลิก',
                confirm: () => this.confirmWithdraw()
            }
        }))
    }

    confirmWithdraw = () => {
        this.props.dispatch(disapatchWithdraw({ type: ON_CONFITM_WITHDRAW }))
    }

    onChangePage = (page) => {
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'page', value: page } }))
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'selectWithdraw', value: [] } }))
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'priceTransfer', value: 0 } }))
        this.handleSearch()
        // this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW }))
    }
    onShowSizeChange = (page, limit) => {
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'limit', value: limit } }))
    }

    onChangeSearch = (e) => {
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'valueSearch', value: e.target.value } }))
    }

    onKeyPressSearch = (e) => {
        if (e.key == "Enter") {
            this.handleSearch()
        }
    }

    handleSearch = () => {
        const { valueSearch } = this.props
        if (valueSearch.trim().length == 0) {
            this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'statusSearch', value: false } }))
        } else {
            this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'statusSearch', value: true } }))
        }
        this.props.dispatch(disapatchWithdraw({ type: ON_SEARCH_WITHDRAW, payload: { shopId: this.state.shopSelectedIndex && this.props.listShop[this.state.shopSelectedIndex].value?.id } }))
    }

    onClearSearch = () => {
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'statusSearch', value: false } }))
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'valueSearch', value: '' } }))
        this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW, payload: { shopId: this.state.shopSelectedIndex && this.props.listShop[this.state.shopSelectedIndex].value?.id } }))
    }

    openModalWithdrawSeller = (list) => {
        this.props.dispatch(disapatchWithdraw({ type: OPEN_MODAL_WITHDRAW_PREVIEW, payload: { data: [], headerData: list } }))
        this.props.dispatch(disapatchWithdraw({ type: FETCH_ORDER_SELLER_WITHDRAW, payload: { id: list.id } }))
    }

    checkTab = (status) => {
        if (this.props.activeTab === status) {
            return true
        }
        return false
    }

    onChangeShop = (value) => {
        this.setState({
            shopSelected: this.props.listShop[value]?.value,
            shopSelectedIndex: value
        }, () => {
            this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'page', value: 1 } }))
            this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW, payload: { shopId: this.props.listShop[value]?.value?.id } }))
        })
    }


    // ---------------------------------
    onChangeDate = (date) => {
        this.setState({
            ...this.state,
            dateOption: date?.type,
            dateSelected: {startDate: date?.period?.from, endDate: date?.period?.to}
        });
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'page', value: 1 } }))
        // this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'limit', value: 10 } }))
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'fromDate', value: moment(date.period?.from).utc(0).format() } }))
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'toDate', value: moment(date.period?.to).utc(0).format() } }))
        this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW, payload: { shopId: this.props.listShop && this.props.listShop[this.state.shopSelectedIndex]?.value?.id, fromDate: moment(date.period?.from).utc(0).format(), toDate: moment(date.period?.to).utc(0).format() } }))
    }

    showStatusWithdraw = (status, date, withdrawTransaction) => {
        switch (status) {
            // case 'requested':
            // case 'settled':
            //     return <span>กำลังดำเนินการ</span>
            //     break;
            case 'transferred':
                return <span className='d-inline-block flex-column justify-content-center align-items-center'>
                    <span className='color-default-success'> โอนเงินให้ร้านค้าแล้ว</span>
                    <span className='color-grey d-flex'>{displayDateThShortYear(date,'DD MMM YY HH:mm')}</span>
                    <span className='color-grey d-flex'>{withdrawTransaction?.length > 0 && `฿ ${numeral(withdrawTransaction?.length > 0 && withdrawTransaction[withdrawTransaction?.length - 1]?.totalAmount).format('0,0.00')}` }</span>
                </span>

            default:
                return <span className=''>กำลังดำเนินการ</span>
                break;
        }
    }

    fetchDataExcel = async () => {
        const { fromDate, toDate, activeTab } = this.props.withdrawState

        const perm = getUserRole()
        // const header = perm === 'ACCOUNTANT' ? ['ลำดับ', 'หมายเลขออเดอร์', 'สถานะการโอนเงินให้ร้านค้า', 'วันจัดส่ง', 'วันที่ชำระเงิน', 'ร้านค้า', 'นิติบุคคล/บุคคลธรรมดา', 'จำนวนรายการ', 'ยอดรวมค่าสินค้า', 'ค่าจัดส่ง', 'ส่วนลดค่าจัดส่ง', 'คงเหลือค่าจัดส่ง', 'ยอดรวมค่าสินค้าและค่าจัดส่ง', 'ส่วนลดร้านค้า', 'ยอดชำระทั้งสิ้น', 'ช่องทางการชำระเงิน', 'เลขอ้างอิง mPAY'] :
        //     ['ลำดับ', 'หมายเลขออเดอร์', 'สถานะการโอนเงินให้ร้านค้า', 'ชื่อร้านค้า', 'วันที่ลูกค้าได้รับสินค้า', 'ค่าสินค้า', 'ค่าขนส่ง', 'ส่วนลดร้านค้า', 'ส่วนลดแพลตฟอร์ม', 'ยอดที่ต้องชำระ', 'ค่าธรรมเนียมการชำระเงิน', 'ค่าธรรมเนียมการขายสินค้า', 'รายได้สุทธิ']
        const header = ['ลำดับ', 'หมายเลขออเดอร์', 'สถานะการโอนเงินให้ร้านค้า', 'วันจัดส่ง', 'วันที่ชำระเงิน', 'ชื่อร้านค้า', 'Vendor Code', 'ชื่อนิติบุคคล/ชื่อบุคคลธรรมดา', 'ยอดรวมค่าสินค้า',
            'ค่าจัดส่ง', 'ส่วนลดค่าจัดส่ง', 'คงเหลือค่าจัดส่ง', 'ยอดรวมค่าสินค้าและค่าจัดส่ง', 'ส่วนลด V-avenue', 'ส่วนลดร้านค้า', 'ส่วนลดทั้งหมด', 'ยอดชำระทั้งสิ้น', 'ช่องทางการชำระเงิน', 'เลขอ้างอิง mPAY',
            'ค่าบริการชำระเงินคิดที่อัตรา 2%', 'ภาษีค่าบริการชำระเงิน', 'ค่าบริการชำระเงิน (รวมภาษี)', 'ภาษีหัก ณ ที่จ่าย (W/T)', 'รายได้สุทธิของร้านค้า']
        const excelData = await new Promise(async (resolve, reject) => {
            const params = {
                shopId: this.props.listShop[this.state.shopSelectedIndex]?.value?.id, fromDate, toDate, status: activeTab
            }
            if (!params.shopId) delete params.shopId
            if (params.status === 'all') delete params.status
            if (this.props.listShop.length === 1) delete params.shopId
            const data = await withdrawProvider.getListSellerWithdrawOrder(params)
            const dataExcel = []
            if (data.data) {
                // if (perm !== 'ACCOUNTANT') {
                data.data.map((list, index) => {
                    dataExcel.push({
                        index: index + 1,
                        orderNo: list.order.orderNo,
                        statusTransfer: list?.status === 'transferred' ? `โอนเงินให้ร้านค้าแล้ว\n${displayDateThShortYear(list?.transferredDate,'DD MMM YY HH:mm')}\n฿ ${numeral(list?.sellerWithdraw?.attachment[list.sellerWithdraw?.attachment?.length - 1]?.totalAmount).format('0,0.00')}` : 'กำลังดำเนินการ',
                        deliveredDate: list?.order?.deliveredAt && displayDateThShortYear(list?.order?.deliveredAt, 'DD MMM YY HH:mm'),
                        paymentedDate: list?.order?.paymentedAt && displayDateThShortYear(list?.order?.paymentedAt, 'DD MMM YY HH:mm'),
                        shopName: list.shop.name,
                        vendorCode: list?.shop?.partner?.value,
                        customerName: list?.shop?.shopTax?.name,
                        totalItemPrice: list.totalItemPrice ? list.totalItemPrice : '0',
                        totalShippingFee: list.totalShippingFee ? list.totalShippingFee : '0',
                        totalDiscountShipping: list?.order?.discount?.priceShippingDiscount ? list?.order?.discount?.priceShippingDiscount : 0 + list?.order?.discount?.priceShippingDiscountShop ? list?.order?.discount?.priceShippingDiscountShop : 0 ? (list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0 + list?.order?.priceShippingDiscountShop ? list?.order?.priceShippingDiscountShop : 0) : '0',
                        totalShippingDiscount: list?.totalShippingFee - (list?.order?.discount?.priceShippingDiscount ? list?.order?.discount?.priceShippingDiscount : 0 + list?.order?.discount?.priceShippingDiscountShop ? list?.order?.discount?.priceShippingDiscountShop : 0) ? list?.totalShippingFee - (list?.order?.discount?.priceShippingDiscount ? list?.order?.discount?.priceShippingDiscount : 0 + list?.order?.disocunt?.priceShippingDiscountShop ? list?.order?.disocunt?.priceShippingDiscountShop : 0) : '0',
                        totalItemShippingPrice: list?.totalItemPrice + list.order?.priceShippingNet ? list?.totalItemPrice + list.order?.priceShippingNet : '0',
                        totalDiscountPlatform: list.totalDiscountPlatform ? list.totalDiscountPlatform : '0',
                        totalDiscountShop: list.totalDiscountShop ? list.totalDiscountShop : '0',
                        totalDiscount: list.totalDiscountShop + list.totalDiscountPlatform ? list.totalDiscountShop + list.totalDiscountPlatform : '0',
                        totalAmount: list.totalAmount ? list.totalAmount : '0',
                        typePayment: list?.order?.paymentMethod,
                        mPayId: list?.order?.payment?.transactionId,
                        totalPaymentFee: list.totalPaymentFee ? list.totalPaymentFee : '0',
                        totalPaymentFeeVat: list.totalPaymentFeeVat ? list.totalPaymentFeeVat : '0',
                        totalPaymentFeeAndVat: list?.totalPaymentFee + list?.totalPaymentFeeVat ? list?.totalPaymentFee + list?.totalPaymentFeeVat : '0',
                        totalHoldingTax: list?.totalWithHoldingTax ? list?.totalWithHoldingTax : '0',
                        totalRevenue: list?.totalRevenue ? list.totalRevenue : '0'
                    })
                })

                /// sum order
                dataExcel.push({
                    index: '',
                    orderNo: '',
                    transferStatus: '',
                    deliveredDate: '',
                    paymentedDate: '',
                    shopName: '',
                    vendorCode: '',
                    customerName: '',
                    totalItemPrice: data.totalItemPrice ? data.totalItemPrice : '0',
                    totalShippingFee: data.totalShippingFee ? data.totalShippingFee : '0',
                    totalDiscountShipping: data.totalDiscountShippingShop ? data.totalDiscountShippingShop : '0',
                    totalShippingDiscount: data?.totalShippingFee - data?.totalDiscountShippingShop ? data?.totalShippingFee - data?.totalDiscountShippingShop : '0',
                    totalItemShippingPrice: data?.totalItemPrice + (data?.totalShippingFee - data?.totalDiscountShippingShop) ? data?.totalItemPrice + (data?.totalShippingFee - data?.totalDiscountShippingShop) : '0',
                    totalDiscountPlatform: data.totalDiscountPlatform ? data.totalDiscountPlatform : '0',
                    totalDiscountShop: data.totalDiscountShop ? data.totalDiscountShop : '0',
                    totalDiscount: data.totalDiscountShop + data.totalDiscountPlatform,
                    totalAmount: data.totalAmount ? data.totalAmount : '0',
                    typePayment: '',
                    mPayId: '',
                    totalPaymentFee: data.totalPaymentFee ? data.totalPaymentFee : '0',
                    totalPaymentFeeVat: data.totalPaymentFeeVat ? data.totalPaymentFeeVat : '0',
                    totalPaymentFeeAndVat: data?.totalPaymentFee + data?.totalPaymentFeeVat ? data?.totalPaymentFee + data?.totalPaymentFeeVat : '0',
                    totalHoldingTax: data?.totalWithHoldingTax ? data?.totalWithHoldingTax : '0',
                    totalRevenue: data.totalRevenue ? data.totalRevenue : '0',


                    // index: '',
                    // orderNo: '',
                    // transferStatus: '',
                    // shopName: '',
                    // deliveredDate: '',
                    // totalItemPrice: data.totalItemPrice ? data.totalItemPrice : '0',
                    // totalShippingFee: data.totalShippingFee ? data.totalShippingFee : '0',
                    // totalDiscountShop: data.totalDiscountShop ? data.totalDiscountShop : '0',
                    // totalDiscountPlatform: data.totalDiscountPlatform ? data.totalDiscountPlatform : '0',
                    // totalAmount: data.totalAmount ? data.totalAmount : '0',
                    // totalPaymentFee: data.totalPaymentFee ? data.totalPaymentFee : '0',
                    // totalCommission: data.totalCommission ? data.totalCommission : '0',
                    // totalRevenue: data.totalRevenue ? data.totalRevenue : '0'
                })



                // <div></div>
                // <div></div>
                // <div></div>
                // <div></div>
                // <div></div>
                // <div></div>
                // <div></div>
                // <div></div>
                // <div className='price'>{numeral(withdrawList?.totalItemPrice).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalShippingFee).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalDiscountShippingShop).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalShippingFee - withdrawList?.totalDiscountShippingShop).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalItemPrice + withdrawList?.totalShippingFee).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalDiscountPlatform).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalDiscountShop).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalDiscountShop + withdrawList?.totalDiscountPlatform).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalAmount).format('0,0.00')}</div>
                // <div></div>
                // <div></div>
                // <div className='price'>{numeral(withdrawList?.totalPaymentFeeVat).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalPaymentFee).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalPaymentFee + withdrawList?.totalPaymentFeeVat).format('0,0.00')}</div>
                // <div className='price'>{numeral(withdrawList?.totalRevenue).format('0,0.00')}</div>



                /// sum order
                // } else {
                //     data.data.map((list, index) => {
                //         dataExcel.push({
                //             index: index + 1,
                //             orderNo: list?.order.orderNo,
                //             transferStatus: list?.status === 'transferred' ? 'โอนเงินให้ร้านค้าแล้ว' : 'กำลังดำเนินการ',
                //             deliveredDate: list?.order?.deliveredAt && displayDateThShortYear(list?.order?.deliveredAt),
                //             paymentedAt: list?.order?.payment?.paymentedAt && displayDateThShortYear(list?.order?.payment?.paymentedAt),
                //             shopName: list?.shop.name,
                //             name: list?.order?.customerInformation?.name,
                //             itemQuantity: list?.order?.items?.length,
                //             totalItemPrice: list?.order?.priceNet ? list?.order?.priceNet : '0',
                //             totalShippingFee: list?.order?.priceShippingNet ? list?.order?.priceShippingNet : '0',
                //             priceShippingDiscount: list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : '0',
                //             priceShippingAfterDiscount: list?.order?.priceShippingNet - list?.order?.priceShippingDiscount ? list?.order?.priceShippingNet - list?.order?.priceShippingDiscount : '0',
                //             totalPriceShippingAfterDiscount: list?.order?.priceNet + (list?.order?.priceShippingNet - list?.order?.priceShippingDiscount) ? list?.order?.priceNet + (list?.order?.priceShippingNet - list?.order?.priceShippingDiscount) : '0',
                //             priceItemDiscountShop: list?.totalDiscountShop ? list?.totalDiscountShop : '0',
                //             totalPay: list?.totalRevenue ? list?.totalRevenue : '0',
                //             typePayment: list?.order?.paymentMethod,
                //             mPayId: list?.order?.payment?.transactionId
                //         })
                //     })
                //      /// sum order
                //      dataExcel.push({
                //         index: '',
                //         orderNo: '',
                //         transferStatus: '',
                //         deliveredDate: '',
                //         paymentedAt: '',
                //         shopName: '',
                //         name: '',
                //         itemQuantity: data.totalItem,
                //         totalItemPrice: data.totalItemPrice ? data.totalItemPrice : '0',
                //         totalShippingFee:  data.totalShippingFee ? data.totalShippingFee : '0',
                //         priceShippingDiscount: data.totalDiscountShippingShop ? data.totalDiscountShippingShop : '0',
                //         priceShippingAfterDiscount: data?.totalShippingFee - data.totalDiscountShippingShop ? data?.totalShippingFee - data.totalDiscountShippingShop : '0',
                //         totalPriceShippingAfterDiscount: data?.totalItemPrice + data?.totalShippingFee ? data?.totalItemPrice + data?.totalShippingFee : '0',
                //         priceItemDiscountShop: data?.totalDiscountShop ? data?.totalDiscountShop : '0',
                //         totalPay: data?.totalRevenue ? data?.totalRevenue : '0',
                //         typePayment: '',
                //         mPayId: '',
                //     })
                //     /// sum order
                // }
            }
            resolve(dataExcel)
        })
        return {
            header, data: excelData
        }
    }

    render() {
        const { activeTab, withdrawList, selectBox,
            // selectWithdraw, statusSearch,
            page, limit, total, priceTransfer, modalWithdrawPreview, listShop } = this.props
        const { dateSelected, dateOption } = this.state;
        const perm = getUserRole()
        return (
            <React.Fragment>
                <div id="withdraw-container">

                    <H5>รายการรายได้</H5>
                    <Caption className='detail-header-withdraw'>(รายการแต่ละรายการจะแสดงหลังจากวันที่ลูกค้าได้รับสินค้า)</Caption>

                    {
                        // getUserRole() === SUPER_ADMIN &&
                        <div className="w-100 mb-4 d-flex flex-row" style={{ gap: '10%' }}>
                            {/* className,width,height,options,placeholder,onChange,onClearSearchShop,value,onBlur,iconClearSearch,name  */}
                            <div className='d-flex flex-row gap-1 align-items-center w-50'>
                                <Body2>ร้านค้า : </Body2>
                                <div className="w-75">
                                    <SelectInputWithImg
                                        options={listShop}
                                        onChange={this.onChangeShop}
                                        iconClearSearch={false}
                                        value={listShop?.length > 0 && this.state.shopSelectedIndex}
                                    />
                                </div>
                            </div>

                            <div className="d-flex flex-row gap-1 align-items-center w-100 justify-content-end">
                                <Body2>ระยะเวลาของข้อมูล: </Body2>
                                <PeriodTimeInput
                                    onPeriodDateSelected={(date) => this.onChangeDate(date)}
                                    disabled={false}
                                    optionDate={dateOption}
                                    optionsRangeDate={dateSelected}
                                    optionTypeDisabled={[options.REAL_TIME, options.YESTERDAY]
                                    }
                                />
                                <XlsxCreate
                                    handleFetchData={() => this.fetchDataExcel()}
                                    fileName={`${listShop && listShop[this.state.shopSelectedIndex]?.label}-รายการรายได้-${displayDateThShortYear(new Date(), 'DD-MM-YY')}`}
                                    headerA1={'รายการรายได้'}
                                    numberFormat={'#,0.00'}
                                    textRight={['I','J','K','L','M','N','O','P','Q','T','U','V','W','X']}
                                // nonNumberFormat={perm === 'ACCOUNTANT' ? ['H'] : null}
                                >
                                    <button className="btn-cancel"><Body1>ดาวน์โหลด Excel</Body1></button>
                                </XlsxCreate>
                            </div>
                        </div>
                    }

                    <div className='d-flex flex-row justify-content-between align-items-center'>
                        <Caption className="Body2-primary text-danger">*กด Shift ค้างแล้วเลื่อนลูกกลิ้งเมาส์ (Scroll mouse) เพื่อเลื่อนดูข้อมูลตามแนวนอน</Caption>

                        {/* <div>
                            <div className="text-detail-withdraw align-items-end">
                                <span className="head-content head-content-primary color-success">จำนวนเงิน :  {numeral(priceTransfer).format('0,0.00')} บาท</span>
                                <span className="content content-primary">รายการทั้งหมด {withdrawList.count || 0} รายการ</span>
                            </div>
                        </div> */}
                        <div className='d-flex flex-row align-items-center'>
                            <Caption>สถานะการโอนให้ร้านค้า</Caption>
                            <button className={classNames('btn', { 'btn-cancel': activeTab === sellerWithdrawStatus.ALL })} onClick={() => this.toggle(sellerWithdrawStatus.ALL)} ><Body1>แสดงทั้งหมด</Body1></button>
                            <button className={classNames('btn', { 'btn-cancel': activeTab === sellerWithdrawStatus.PROCESSING })} onClick={() => this.toggle(sellerWithdrawStatus.PROCESSING)}><Body1>กำลังดำเนินการ</Body1></button>
                            <button className={classNames('btn', { 'btn-cancel': activeTab === sellerWithdrawStatus.TRANSFERRED })} onClick={() => this.toggle(sellerWithdrawStatus.TRANSFERRED)}><Body1>โอนเงินให้ร้านค้าแล้ว</Body1></button>
                        </div>
                    </div>



                    {perm === 'ACCOUNTANT' ?
                        // <AccountantWithdraw
                        //     withdrawList={withdrawList}
                        //     page={page}
                        //     limit={limit}
                        //     showStatusWithdraw={() => this.showStatusWithdraw()}
                        // />
                        <AdminWithdraw
                            withdrawList={withdrawList}
                            page={page}
                            limit={limit}
                            showStatusWithdraw={(status,date,withdrawTransaction) => this.showStatusWithdraw(status,date,withdrawTransaction)}
                        />
                        :
                        <AdminWithdraw
                            withdrawList={withdrawList}
                            page={page}
                            limit={limit}
                            showStatusWithdraw={(status,date,withdrawTransaction) => this.showStatusWithdraw(status,date,withdrawTransaction)}
                        />
                    }


                    {withdrawList?.data?.length === 0 &&
                        <div className='d-flex flex-column justify-items-center align-items-center' style={{ marginTop: '10rem', marginBottom: '10rem' }}>
                            <IconNoItem />
                            ไม่มีข้อมูล
                        </div>
                    }
                </div>


                {withdrawList.data?.length == 0 ? null : <PaginationComponent
                    defaultCurrent={1}
                    total={total}
                    onChange={(page) => this.onChangePage(page)}
                    pageSize={limit}
                    pageSizeOptions={[10, 30, 50]}
                    onShowSizeChange={this.onShowSizeChange}
                    current={page}
                />}
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => ({
    withdrawList: state.withdraw.withdrawList,
    activeTab: state.withdraw.activeTab,
    selectBox: state.withdraw.selectBox,
    selectWithdraw: state.withdraw.selectWithdraw,
    priceTransfer: state.withdraw.priceTransfer,
    page: state.withdraw.page,
    limit: state.withdraw.limit,
    total: state.withdraw.total,
    valueSearch: state.withdraw.valueSearch,
    statusSearch: state.withdraw.statusSearch,
    modalWithdrawPreview: state.withdraw.modalWithdrawPreview,
    listShop: state.withdraw.listAllShop,
    withdrawState: state.withdraw
})


export default connect(mapStateToProps)(WithDraw);