import React,{useEffect,useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import classNames from "classnames";
import host from 'config/host';
import IconEmptyProduct from 'images/icons/cover-product.svg'
import { Checkbox } from "components/input";
import numeral from "numeral";
import { CHECK_PRODUCT_EDIT_PRODUCT, UNCHECK_PRODUCT_EDIT_PRODUCT } from "redux/actions/product";

const CardShopProduct = (props) => {
    const { product, isAllProduct:isAll} = props || {};
    const {coverPath, name, productSkus, productId} = product || {};

    const dispatch = useDispatch();
    const productState = useSelector(state => state.product);
    const editShopProductState = productState.editProduct
    const editProductSkuCountState = productState.editProductSkuCount
    const [isAllProduct, setIsAll] = useState(null)
    useEffect(() => {
      setIsAll(isAll)
      return () => {
      }
    }, [isAll])

    function getPriceNetScope(productSkus) {
        try {
            if(productSkus && typeof productSkus === "object") {
                if(Object.values(productSkus).length > 1) {
                    const prices = Object.values(productSkus).map(product => product.priceNet);
                    const maxPrice = Math.max(...prices);
                    const minPrice = Math.min(...prices);
                    if(maxPrice === minPrice) return  `฿ ${maxPrice}`
                    else return  `฿ ${numeral(minPrice).format('0,0')} - ${numeral(maxPrice).format('0,0')}`
                } else {
                    return `฿ ${numeral(Object.values(productSkus)[0]?.priceNet).format('0,0')}`;
                }
            }
            return "";
        } catch(error) {
            throw(error)
        }
    }
    
    const handleOnClickCheckbox = () => {
      if (editShopProductState.hasOwnProperty(props.product.productId)) {
        dispatch({
          type: UNCHECK_PRODUCT_EDIT_PRODUCT,
          payload: { [props.product.productId]: props.product },
        });
        } else {
          if((Object.keys(props.product.productSkus).length + editProductSkuCountState) > 100) {
            return alert('sku เกินกำหนด')
          }
          dispatch({
            type: CHECK_PRODUCT_EDIT_PRODUCT,
            payload: {
              [props.product.productId]: props.product
            }
          });
    };
  }

    


    return (
      <div className={classNames("card-product", { active: editShopProductState[productId] !== undefined && !isAllProduct, })} >
        <div className="select-product">
          <Checkbox
            defaultChecked={productId}
            checking={isAllProduct ? true :
              editShopProductState[productId] !== undefined
                ? true
                : editShopProductState[productId] !== undefined
            }
            onSelectMedia={handleOnClickCheckbox}
            disabled={isAllProduct === true}
          />
        </div>
        <div className="product-image">
          {coverPath?.path ? (
            <img src={`${host.image}${coverPath?.path}`} />
          ) : (
            <img src={IconEmptyProduct} />
          )}
        </div>
        <div className="product-detail">
          <div className="product-name"> {name}</div>
          <div className="product-price">
            <span>{getPriceNetScope(productSkus)}</span>
          </div>
        </div>
      </div>
    );
}

export default CardShopProduct;