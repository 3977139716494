import {
  ADD_KEY_VALUE_CHECKED_SHOP,
  REMOVE_PRODUCT_CHECKED_SHOP,
  UNCHECK_ALL_SHOP_PRODUCT_CHECKED_SHOP,
  UNCHECK_PRODUCT_CHECKED_SHOP,
  RESET_CHECKED_SHOP,
  COUNT_CHECKED_ALL_SHOP,
  UPDATE_SKU_CHECKED_SHOP,
  CHECK_PRODUCT_CHECKED_SHOP,
  SET_KEY_ALLPRODUCT_SHOP,
  ADD_CHECKED_SHOP_SELECT,
} from "redux/actions/checkedShop"

const initialState = {
  countAllShop: 0,
  countAllProduct: 0,
  countAllSKU: 0,
  shops: {
    // ' 12': {
    //   allProduct: false,
    //   count: 2,
    //   sku: 3,
    //   name: "",
    //   logoPath: {},
    //   product: {
    //     ' 23': {
    //       ' 45': true,
    //       //' 55': true,
    //     },
    //     ' 24': {
    //       ' 45': true,
    //       ' 55': true,
    //     },
    //   },
    // },
    // ' 13': {
    //   allProduct: false,
    //   count: 1,
    //   sku: 2,
    //   name: "",
    //   logoPath: {},
    //   product: {
    //     ' 23': {
    //       ' 45': true,
    //       ' 55': true,
    //     },
    //   },
    // },
  }
};

const countCheckAllShop = (payload, state) => {
  const { shopId: id, checkedShop } = payload
  const shopId = ` ${parseInt(id)}`
  const stateShops = !checkedShop ? { ...state } : { ...checkedShop }
  if (checkedShop) {
    Object.keys(checkedShop.shops).reduce(function (acc, sid) {
      if (stateShops.shops[shopId]?.allProduct) {
        return acc;
      }
      const result = Object.keys(checkedShop.shops[sid]?.product).reduce(function (accumulator, productId) {
        accumulator.count += 1
        accumulator.sku += Object.keys(checkedShop.shops[sid]?.product[productId]).length
        return accumulator;
      }, { count: 0, sku: 0 })
      stateShops.shops[sid].count = result.count
      stateShops.shops[sid].sku = result.sku
      return acc
    }, {})

  } else {
    if (Object.keys(stateShops?.shops || {}).length) {
      if (stateShops.shops[shopId]?.allProduct) {
        return stateShops;
      }
      const result = Object.keys(stateShops.shops[shopId]?.product).reduce(function (accumulator, productId) {
        accumulator.count += 1
        accumulator.sku += Object.keys(stateShops.shops[shopId]?.product[productId]).length
        return accumulator;
      }, { count: 0, sku: 0 })
      stateShops.shops[shopId].count = result.count
      stateShops.shops[shopId].sku = result.sku

    } else {
      stateShops.countAllProduct = 0;
      stateShops.countAllSKU = 0;
    }
  }
  return stateShops;

};




/* function : uncheck all product check shop by delete product and reduce count */
/* payload  : (Object) {['shopID']: null} */
/* return   : (Object) {state} */
const unCheckAllProductCheckShop = (payload, state) => {
  const id = Object.keys(payload)[0]
  const kShopId = ` ${parseInt(id)}`
  state.countAllProduct -= state.shops[kShopId].count
  state.countAllSKU -= state.shops[kShopId].sku
  state.countAllShop -= 1;

  state.shops[kShopId].product = {}
  state.shops[kShopId].count = 0
  state.shops[kShopId].sku = 0

  return state;
}

/* function : uncheck product check shop by delete product and reduce count */
/* payload  : (Object) {['shopID']: {[productId]:false}} */
/* return   : (Object) {state} */
const unCheckProductCheckShop = (payload, state) => {
  const { shops } = { ...state }
  const kShopId = Object.keys(payload)[0]
  const kProductId = Object.keys(Object.values(payload)[0])[0]
  const productLength = Object.keys(shops[kShopId]?.product || {})?.length

  if (productLength == 1) {
    state.shops[kShopId].product = {}
    state.shops[kShopId].count = 0
    state.shops[kShopId].sku = 0
    // delete state.shops[kShopId]
  } else {
    delete state.shops[kShopId]?.product[kProductId];
  }

  return state;
}

/* function : update sku checked && count allShop, allProduct, allSKU, count&sku by product  */
/* payload  : (Object)  
    shopID: {
      productId:
        {skuID:true|false}
    }
*/
/* return   : (Object) {state} */
const updateSkuCheckedShop = (payload, state) => {
  const kShopId = Object.keys(payload)[0]
  const kProductId = Object.keys(Object.values(payload)[0])[0]
  const kSkuId = Object.keys(Object.values(Object.values(payload)[0])[0])[0]
  const vSkuId = Object.values(Object.values(Object.values(payload)[0])[0])[0]
  const { shops } = { ...state }

  const skuLength = Object.keys(shops[kShopId]?.product[kProductId] || {})?.length

  if (vSkuId) {
    /* is check sku */
    state.shops[kShopId].sku += 1
    state.shops[kShopId].product[kProductId][kSkuId] = true
  } else if (!vSkuId && skuLength > 1) {
    /* un check sku */
    state.shops[kShopId].sku -= 1
    delete state.shops[kShopId].product[kProductId][kSkuId]
  }

  return state;
}

/* function : remove product && count allShop, allProduct, allSKU, count&sku by product  */
/* payload  : (Object) {['shopID']: {[productId]:null}} */
/* return   : (Object) {state} */
const removeProductCheckShop = (payload, state) => {
  const kShopId = Object.keys(payload)[0]
  const kProductId = Object.keys(Object.values(payload)[0])[0]
  const { shops } = { ...state }

  const productLength = Object.keys(shops[kShopId]?.product || {})?.length
  const skuLength = Object.keys(shops[kShopId]?.product[kProductId] || {})?.length
  if (productLength > 1) {
    state.shops[kShopId].count -= 1
    state.shops[kShopId].sku -= skuLength
    delete state.shops[kShopId].product[kProductId]
  }

  return state;
}

/* function : addCheckShopSelect  */
/* payload  : (Object) {
  ['shopID']: {
    name:'',
    logoPath:'',
    allProduct:true|false,
  }} 
*/
/* return   : (Object) {state} */
const addCheckShopSelect = (payload, state) => {
  const kShopId = Object.keys(payload || '')[0]
  const vPayload = Object.values(payload || {})[0]

  if (!state.shops[` ${kShopId}`]) {
    state.shops[` ${kShopId}`] = {
      count: 0,
      sku: 0,
      name: vPayload?.name,
      logoPath: vPayload?.logoPath || {},
      allProduct: vPayload?.allProduct,
      product: {},
    }
  } else if (state.shops[` ${kShopId}`]) {
    state.shops[` ${kShopId}`] = {
      ...state.shops[` ${kShopId}`],
      name: vPayload?.name,
      logoPath: vPayload?.logoPath || {},
      allProduct: state.shops[` ${kShopId}`]?.allProduct ? state.shops[` ${kShopId}`]?.allProduct : vPayload?.allProduct,
    }
  }

  return state;
}

const checkProduct = (shop, state) => {
  //   payload: { [` ${shopId}`]: 
  //   {
  //   name: product.name,
  //   logoPath: product?.coverPath?.path,
  //   allProduct: false, ///////////////////////////////////////////////////////////////////
  //   product: {
  //       [` ${productId}`]: sku
  //     }
  //   } 
  // }
  for (const shopId in shop) {
    if (!state.shops.hasOwnProperty(shopId)) {
      state.shops[shopId] = {
        count: 0,
        sku: 0,
        product: {},
        name: '',
        allProduct: false,
        logoPath: ''
      };
    }

    const shopData = shop[shopId];
    const shopInfo = state.shops[shopId];
    shopInfo.name = shopData.name || ''
    shopInfo.logoPath = shopData.logoPath || ''

    for (const productId in shopData.product) {
      if (!shopInfo.product.hasOwnProperty(productId)) {
        shopInfo.product[productId] = {};
        // shopInfo.count += 1
      }

      const productData = shopData.product[productId];
      const productInfo = shopInfo.product[productId]

      for (const skuId in productData) {
        if (!productInfo.hasOwnProperty(skuId)) {
          shopInfo.product[productId][skuId] = productData[skuId];
          // shopInfo.sku += 1; // เพิ่มค่า sku ของร้านค้า
        }
      }
    }
  }

  // state.countAllShop = Object.keys(state.shops).length;
  // state.countAllSKU = Object.values(state.shops).reduce((total, shop) => {
  //   return total + Object.values(shop.product).reduce((sum, product) => {
  //     return sum + Object.keys(product).length;
  //   }, 0);
  // }, 0);

  // state.countAllProduct = Object.values(state.shops).reduce((total, shop) => {
  //   return total + Object.keys(shop.product).length;
  // }, 0);

  return state;
};

/* function : check all product by shop /
/ payload  : (Object) {['shopID']: {allProduct:true|false} /
/ return   : (Object) {state} */
// {17:true}
const checkAllProductByShop = (payload, state) => {
  const kShopId = (Object.keys(payload || {})[0]).toString().trim()
  const vAllProduct = Object.values(payload || {})[0]?.allProduct
  state.shops[` ${kShopId}`].allProduct = vAllProduct || false
  return state;
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_KEY_VALUE_CHECKED_SHOP:
      return { ...state, [payload.key]: payload.value, }
    case UNCHECK_ALL_SHOP_PRODUCT_CHECKED_SHOP: /* payload  : (Object) {['shopID']: null} */
      return { ...unCheckAllProductCheckShop(payload, state) }
    case UNCHECK_PRODUCT_CHECKED_SHOP: /* payload  : (Object) {['shopID']: {[productId]:false}} */
      return { ...unCheckProductCheckShop(payload, state) }
    case CHECK_PRODUCT_CHECKED_SHOP:
      return { ...checkProduct(payload, state) }
    case ADD_CHECKED_SHOP_SELECT: /* payload  : (Object) {['shopID']: {name:'',logoPath:'',allProduct:true|false,}} */
      return { ...addCheckShopSelect(payload, state) }
    case COUNT_CHECKED_ALL_SHOP:  /* payload  : (Object) {checkedShop: Object,selectedShop: Object, shopId: Number}
     ถ้าต้องการให้ count all ให้ส่ง checkedShop มาด้วย*/
      return { ...countCheckAllShop(payload, state) }
    case UPDATE_SKU_CHECKED_SHOP: /* payload  : (Object) { isCheck: Boolean, skuId: Number, productId: Number, shopId: Number} */
      return { ...updateSkuCheckedShop(payload, state) }
    case REMOVE_PRODUCT_CHECKED_SHOP: /* payload  : (Object) {['shopID']: {[productId]:null}} */
      return { ...removeProductCheckShop(payload, state) }
    case SET_KEY_ALLPRODUCT_SHOP:
      return { ...checkAllProductByShop(payload, state) }
    case RESET_CHECKED_SHOP:
      return {
        countAllShop: 0,
        countAllProduct: 0,
        countAllSKU: 0,
        shops: {}
      }

    default: {
      return state
    }
  }
}
