import useScreenWidth from 'components/useScreenWidth';
import React, { Component,useState,useEffect } from 'react'
import Chart from "react-google-charts";
import LegendViewer from './legendViewer';

const LineChart = (props) => {
    const showTextType = {day: 12, week: 1, month: 2,year: 1}
    const {data, showTextEvery, fakeControls, setFakeControls, width, height} = props
    const screenType = useScreenWidth()

    return (
    <div>
                <LegendViewer />
                <Chart
                    // width={'-webkit-fill-available;'}
                    width={`100%`}
                    height={'300px'}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    data={data}
                    options={{
                        fontName: 'DBHeavent',
                        fontSize: screenType?.isTablet ? '12' : screenType?.isMobile ? '8' : '16',
                         // fontWeight: 'bold',
                        animation: {
                            startup: true,
                            easing: 'linear',
                            duration: 1000,
                        },
                        hAxis: {
                            gridlines: {
                                color: '#BFBFBF',
                                fill: 'red'
                            },
                            count: showTextType[showTextEvery],
                        },
                        vAxis: {
                            gridlines: {
                                color: '#e9e9e9', fill: 'none'
                            },
                            baselineColor: '#BFBFBF',
                            minorGridlines: {
                                color: 'none'
                            },
                            // textPosition: 'none'
                        },
                        series: {
                            0: {
                                color: '#B2D234'
                            },
                        },
                        chartArea: { left: 0, top: 50, width: '95%', height: '75%' },
                        legend: 'none',
                        tooltip: { isHtml: true, trigger: "visible", fontSize: '12' },
                        responsive: true,
                    }}
                    // rootProps={{ 'data-testid': '2' }}
                />
            </div>
  )
}

export default LineChart