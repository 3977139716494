import {
    ADD_KEY_VALUE_SELECTEDED_PRODUCT,
    ADD_SKU_PROMOCODE,
    UPDATE_SKU_PROMOCODE,
    REMOVE_SKU_PRODUCT_PROMOCODE,
    RESET_PRODUCT_SELECTED

} from "redux/actions/selectedProduct"

const initialState = {
    count: 0,
    sku: 0,
    product: {},
};

/* function : add sku when add product to list */
/* payload  : (Object) {'98': { '12': true, '14': true, '55': true }} */
/* return   : (Object) {sku, count, product} */
const addSKUpromocode = (product, state) => {

    Object.keys(product).map((key) => {
        const sku = product[key]
        let setSku = false
        if (sku == undefined) return 0;
        const sum = Object.values(sku).reduce((total, item) => {
            setSku = true
            return total + (item === true ? 1 : 0);
        }, 0)
        if (setSku) {
            state.count += 1
            state.sku += sum
        }

    }, 0);
    state.product = { ...product, ...state.product };
    
    return state;
}

/* function : update sku when check/uncheck sku */
/* payload  : (Object) {'98': { '12': false }} */
/* return   : (Object) {sku, product} */
const updateSKUpromocode = (product, state) => {

    const productId = Object.keys(product)[0];
    const productSKUid = Object.keys(product[`${productId}`])[0];
    const productSKUvalue = Object.values(product[`${productId}`])[0];

    /* check at least one item */
    const checkAtleastOneItem = () => {
        const productSKUs = state.product[`${productId}`];
        const totalActive = Object.values(productSKUs).reduce((total, value) => { return total + (value === true ? 1 : 0); }, 0);
        return totalActive > 1 ? true : false;
    };

    /* add sku item */
    if (productSKUvalue === true) {
        state.product[`${productId}`] = { ...state.product[`${productId}`], ...product[`${productId}`], };
        state.sku = state.sku + 1;
    }

    /* delete sku false item */
    if (checkAtleastOneItem() === true && productSKUvalue === false) {
        delete state.product[`${productId}`][`${productSKUid}`];
        state.sku = state.sku - 1;
    }

    return state;
}

/* function : clear product sku and count */
/* payload  : (Object) {productId: null} */
/* return   : (Object) {product} */
const removeSKUpromocode = (product, state) => {
    const productId = Object.keys(product)[0];

    state.count = state.count - 1;
    state.sku = state.sku - Object.keys(state.product[`${productId}`]).length;
    delete state.product[productId]

    return state
}


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_KEY_VALUE_SELECTEDED_PRODUCT:
            return { ...state, [payload.key]: payload.value, }

        case ADD_SKU_PROMOCODE:
            return { ...addSKUpromocode(payload, state) }

        case UPDATE_SKU_PROMOCODE:
            return { ...updateSKUpromocode(payload, state) }

        case REMOVE_SKU_PRODUCT_PROMOCODE:
            return { ...removeSKUpromocode(payload, state) }

        case RESET_PRODUCT_SELECTED: {
            return {
                ...state,
                count: 0,
                sku: 0,
                product: {}
            }
        }

        default: {
            return state
        }
    }
}