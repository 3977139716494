import React, { useEffect, useRef } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./index.scss";
import IconIncrease from "images/icons/increse.svg";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { ReactComponent as IconClose } from "images/icons/close.svg";
import {
  SET_KEY_VALUE_PROMOCODE,
  SELECTED_ITEMJOIN_PROMOCODECODE,
  OPEN_MODAL_SELECTED_PRODUCT
} from "redux/actions/promocode";
import classNames from "classnames";
import {
  REMOVE_SKU_PRODUCT_PROMOCODE,
  UPDATE_SKU_PROMOCODE,
} from "redux/actions/selectedProduct";
import SelectedProduct from "./products";
import { OPEN_TOAST } from 'redux/actions/notification'

const ModalSelectedProduct = (props) => {
  const { isOpen = false } = props;

  const {
    promocode,
    selectedProduct,
    handleCloseModal = null,
    handleDeleteProduct = null,
    handleUpdateSKU = null,
    handleSubmit = null,
    handleOpenModalCheckedProduct = null
  } = useModalSelectedProduct();

  const { listProduct = {} } = promocode;

  const HeaderTable = ["สินค้า", "ราคาขาย", "คลัง", "สถานะ", ""];
  const isDisableBtn = selectedProduct?.sku == 0;

  const BtnAdd = () => {
    return (
      <div className="wrap-btn-add-product" onClick={() => handleOpenModalCheckedProduct()}>
        <img src={IconIncrease} width={25} />
        <span>เลือกสินค้า</span>
      </div>
    );
  };

  const EmptyProduct = () => {
    return (
      <div className="cover-empty-table-product-sku">
        <span
          className="text-empty"
          dangerouslySetInnerHTML={{
            __html: `ยังไม่มีรายการ <br/> กรุณากดปุ่มเลือกสินค้า เพื่อทำรายการต่อ`,
          }}
        ></span>

        <BtnAdd />
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} className="modal-selected-product" centered={true}>
      <ModalHeader className="header-modal-selected-product">
        <div className="cover-header-modal-selected-product">
          <div>
            <span className="title-selected-product">
              สินค้าที่เข้าร่วมส่วนลด
            </span>
            <span className="subTitle-selected-product">
              (เลือกแล้ว{" "}
              <span className="text-number-highlight">
                {selectedProduct?.count || 0}
              </span>{" "}
              สินค้า |{" "}
              <span className="text-number-highlight">
                {selectedProduct?.sku || 0}
              </span>{" "}
              SKU)
            </span>
          </div>
          <div>
            <IconClose
              className="icon-close-modal-selected-product"
              onClick={() => handleCloseModal()}
            />
          </div>
        </div>
      </ModalHeader>

      <ModalBody className="cover-body-modal-selected-product">
        <div className="d-flex justify-content-end">
          <BtnAdd />
        </div>

        <div className="cover-header-table-product-sku">
          {HeaderTable.map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </div>

        {/* ======== body table ======= */}
        {!isEmpty(selectedProduct?.product) && Object.keys(selectedProduct?.product).length > 0 ? (
          <div className="cover-body-table-product-sku">
            {Object.keys(listProduct)?.map((e)=>{
              if(selectedProduct?.product[e] == undefined) {
                return null
              }

              return(
                <SelectedProduct
                  key={listProduct[e].productId}
                  product={listProduct[e]}
                  handleUpdateSKU={(data)=>handleUpdateSKU(data)}
                  handleDeleteProduct={(productId)=> handleDeleteProduct(productId)}
                />
              )
            })}
          </div>
        ) : (
          <EmptyProduct />
        )}
      </ModalBody>

      <ModalFooter className="cover-footer-modal-selected-product">
        <button
          className={classNames("btn-cancel", isDisableBtn && "disable-btn")}
          disabled={isDisableBtn}
          onClick={() => handleCloseModal()}
        >
          ยกเลิก
        </button>
        <button
          className={classNames("btn-submit", isDisableBtn && "disable-btn")}
          disabled={isDisableBtn}
          onClick={() => handleSubmit()}
        >
          ตกลง
        </button>
      </ModalFooter>
    </Modal>
  );
};

const useModalSelectedProduct = () => {
  const promocode = useSelector((state) => state.promocode);
  const selectedProduct = useSelector((state) => state.selectedProduct);

  const dispatch = useDispatch();
  useEffect(() => {
    if (promocode.isShowModalSelectedProduct && !isEmpty(promocode?.itemJoin?.skus)) {
      dispatch({
        type: OPEN_MODAL_SELECTED_PRODUCT
      });
    }
  }, [promocode.isShowModalSelectedProduct])


  const handleCloseModal = () => {
    dispatch({
      type: SET_KEY_VALUE_PROMOCODE,
      payload: {
        key: "isShowModalSelectedProduct",
        value: false,
      },
    });
  };

  const handleDeleteProduct = (productId = null) => {
    dispatch({
      type: REMOVE_SKU_PRODUCT_PROMOCODE,
      payload: {[productId]: null}
    });
  };

  const handleUpdateSKU = (data = {}) => {
    const { productID, skuID, checked} = data
    const payload = { 
      [productID]: {
        [skuID]: checked
      }
    }

    dispatch({
      type: UPDATE_SKU_PROMOCODE,
      payload: payload,
    });
  };

  const handleSubmit = () => {
    dispatch({
      type: SELECTED_ITEMJOIN_PROMOCODECODE,
    });

    // close modal selected
    handleCloseModal()

    dispatch({
      type: OPEN_TOAST,
      payload: {
        type: 'success',
        message: 'ทำรายการสำเร็จ'
      }
    })
  };

  const handleOpenModalCheckedProduct = () => {
    dispatch({
      type: SET_KEY_VALUE_PROMOCODE,
      payload: {
        key: 'isShowModalCheckedProduct',
        value: true
      }
    })
  }

  return {
    promocode,
    selectedProduct,
    handleCloseModal,
    handleDeleteProduct,
    handleUpdateSKU,
    handleSubmit,
    handleOpenModalCheckedProduct
  };
};

export default ModalSelectedProduct;
