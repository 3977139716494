export const SET_SHOP_DASHBOARD = "SET_SHOP_DASHBOARD"
export const SET_KEY_DASHBOARD = "SET_KEY_DASHBOARD"
export const SET_DEFAULT_DASHBOARD = "SET_DEFAULT_DASHBOARD"
export const GET_REPORT = "GET_REPORT"

export function dispatchDashboard(param){
    return {
      type: param.type,
      payload: param.payload,
    };
  }