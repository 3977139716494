import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import host from 'config/host';

import { ButtonAdd, 
  // InputSearch 
} from "components/output";
import Loading from 'components/Loading';
import DropDownPlaylist from "components/DropdownPlaylist";
// import PaginationComponent from 'components/pagination';
import ModalAlert from 'components/modalAlert/ModalAlert';

import {
  SET_MODAL_ADD_PLAYLIST_MALL,
  GET_PLAYLIST_CATEGORY,
  UPDATE_PLAYLIST_CATEGORY,
  // ADD_PLAYLIST_CATEGORIES,
  DELETE_PLAYLIST_CATEGORY,
  GET_PLAYLIST_CATEGORY_MALL,
  DELETE_PLAYLIST_PLAYLIST_CATEGORY
} from 'redux/actions/playlists';
// import {
//   ADD_KEY_VALUE_CATEGORIES,
//   GET_CATEGORIES,
//   SEARCH_CATEGORIES
// } from 'redux/actions/categories';
// import {
//   OPEN_MODAL_ALERT,
// } from 'redux/actions/modalAlert';

import "./style.scss";
import IconClose from "images/icons/close.svg";
import IconTrash from "images/icons/trash.svg";
// import Checkbox from "components/Checkbox";
import ModalSelectList from "components/ModalSelectList";
import ModalConfirm from "components/modalConfirm/ModalConfirm";

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */
const TableCategoriesList = (props) => {
  const { categoriesList, dropDownPlaylist, handleSwitchIndex, handleDeleteCategories } = props;
  return (
    <div className="container-table">
      <div className="table-header">
        <div className="text-center">ลำดับ</div>
        <div className="text-left">หมวดหมู่</div>
        {/* <div className="text-right">หมวดหมู่ย่อย 2</div>
        <div className="text-right">หมวดหมู่ย่อย 3</div> */}
        <div className="d-flex align-items-center"></div>
      </div>
      {categoriesList &&
        categoriesList.length > 0 &&
        categoriesList.map((categories, index) => (
          <RowCategoriesList
            key={`RowCategoriesList${index}`}
            categories={categories}
            dropDownPlaylist={dropDownPlaylist}
            handleSwitchIndex={handleSwitchIndex}
            handleDeleteCategories={handleDeleteCategories}
          />
        ))}
    </div>
  );
};

const RowCategoriesList = (props) => {
  const { dropDownPlaylist, handleDeleteCategories, handleSwitchIndex, categories } = props;
  const { order } = props?.categories || {};
  const { name, imagePath, } = props?.categories?.categories || {};
  return (
    <div className="table-row">
      <div className="d-flex align-items-center justify-content-center">
        <DropDownPlaylist
          options={dropDownPlaylist}
          defaultValue={order}
          handleSelected={(orderNumber) => handleSwitchIndex(orderNumber, categories)}
        />
      </div>
      <div className="d-flex align-items-center text-left flex-row">
        <img src={`${host.avatar}${imagePath}`} className="mr-2" style={{ width: '60px', height: '60px', maxWidth: '60px', maxHeight: '60px' }} alt='img-playlist'/>
        <span>{name}</span>
      </div>
      <div className="d-flex align-items-center justify-content-end flex-row">
        {/* {0} */}
      </div>
      <div className="d-flex align-items-center justify-content-end flex-row">
        {/* {0} */}
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <ButtonDeleteCategory
          handleDeleteCategories={() => handleDeleteCategories(props?.categories)}
        />
      </div>
    </div>
  );
};

const ButtonDeleteCategory = (props) => {
  const { handleDeleteCategories } = props;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="clickable" onClick={handleDeleteCategories}>
      <img src={IconTrash} alt='icon-trash'/>
    </a>
  );
};

/* -------------------------------------------------------------------------- */
/*                             main component                                 */
/* -------------------------------------------------------------------------- */
const ModalAddCategories = (props) => {

  const {
    isOpen,
    toogle,
  } = useModalAddCategories(props);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toogle}
      backdrop="static"
      style={{ width: "80%", maxWidth: "80%" }}
    >
      <ModalHeader toggle={toogle}>
        <div className="d-flex justify-content-between w-100">
          <div>
            <span className="header-lable"> เพิ่มหมวดหมู่ลงเพลย์ลิสต์ </span>
          </div>
          <div className="close-btn-wrap">
            <img src={IconClose} onClick={toogle} alt='icon-close'/>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Loading />
        <div className="container-modal-add-mall-playlist p-4">

        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center w-100 flex-row">
          <button className="btn-cancel mr-3" onClick={toogle}>
            ยกเลิก
          </button>
          <button className="btn-submit" onClick={() => { }}>
            เพิ่มลงเพลย์ลิส
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const PlaylistCategories = (props) => {
  const { categoriesList,
    dropDownPlaylist,
    playlistsId,
    playlistsName,
    playlistsType,
    handleDeleteCategories,
    handleSwitchIndex,
    // handleOpenModalAddCategories,
    modalCategories,
    setModalCategories,
    modalConfirm,
    // setModalConfirm
  } = usePlaylistCategories(props);

  return (
    <div className="container-playlist-categories">
      <div className="d-flex justify-content-between">
        <h3>{playlistsName}</h3>
        <div className="mb-1">
          <ButtonAdd label="เพิ่มหมวดหมู่สินค้าลงเพลย์ลิสต์" handleAdd={() => setModalCategories({ isOpen: true, data: props.playlist })} />
        </div>
      </div>
      <TableCategoriesList
        categoriesList={categoriesList}
        dropDownPlaylist={dropDownPlaylist}
        handleDeleteCategories={handleDeleteCategories}
        handleSwitchIndex={handleSwitchIndex}
      />
      <ModalAddCategories playlist={{ playlistsId, playlistsType }} />
      <ModalAlert />
      <ModalSelectList modal={modalCategories} type='playlist' isClose={() => setModalCategories({ isOpen: false })} />
      <ModalConfirm
        confirm={() => modalConfirm.confirm()}
        isClose={() => modalConfirm.isClose()}
        message={modalConfirm.message}
        isOpen={modalConfirm.isOpen}
        btnConfirm='ตกลง'
        btnCancel='ยกเลิก'
      />
      <Loading />
    </div>
  );
};



/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const usePlaylistCategories = (props) => {
  const dispatch = useDispatch();
  const { playlistsId, playlistsName, playlistsType, playlistsPlatform } = props.playlist;

  const [categoriesList, setCategoriesList] = useState([]);
  const [dropDownPlaylist, setDropDownPlaylist] = useState([]);
  const [modalCategories, setModalCategories] = useState({ isOpen: false, data: null })
  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    message: '',
    confirm: () => { },
    // isClose: () => setModalConfirm({ isOpen: false, message: '', confirm: () => { } })
  })


  const playlistCategories = useSelector((state) => state.playlists.playlistCategories);

  // initial component
  useEffect(() => {
    initialComponent()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // set up categories list
    if (playlistCategories) setCategoriesList(playlistCategories);
  }, [playlistCategories])

  useEffect(() => {
    // set up dropdown playlist
    if (categoriesList) setDropDownPlaylist(getDropDownPlayListOption(categoriesList));
  }, [categoriesList]);

  const initialComponent = () => {
    if (playlistsPlatform?.shopId) {
      getPlaylistCategoriesMall()
    } else {
      getPlaylistCategory()
    }

  }

  const getPlaylistCategoriesMall = () => dispatch({ type: GET_PLAYLIST_CATEGORY_MALL, payload: { playlistId: playlistsId } })
  const getPlaylistCategory = () => dispatch({ type: GET_PLAYLIST_CATEGORY, payload: { playlistId: playlistsId } })

  const getDropDownPlayListOption = (categoriesList) => {
    if (categoriesList && categoriesList.length > 0)
      return categoriesList.map((categories) => ({ name: `${categories.order}`, value: categories.order }));
    else
      return [];
  }

  const handleDeletePlaylistCategoryShop = (category) => {
    dispatch({
      type: DELETE_PLAYLIST_PLAYLIST_CATEGORY,
      payload: { category }
    }); 
    setModalConfirm({ isOpen: false, message: '', confirm: () => { } })
  }

  // const handleDeletePlaylist = (playlistsId,category) => {
  //   dispatch({
  //     type: DELETE_PLAYLIST_CATEGORY,
  //     payload: { playlistId: playlistsId, playlistCategoriesId: category }
  //   })
  // }

  const handleDeleteCategories = (category) => {
    if (playlistsPlatform?.shopId) {
      setModalConfirm({
        isOpen: true,
        message: 'ต้องการลบหมวดหมู่นี้ในเพลย์ลิสต์ใช่หรือไม่',
        confirm: () => {
          handleDeletePlaylistCategoryShop(category)
        },
      })
    } else {
      // setModalConfirm({
      //   isOpen: true,
      //   message: 'ต้องการลบหมวดหมู่นี้ในเพลย์ลิสต์ใช่หรือไม่',
      //   confirm: () => {
      //     handleDeletePlaylist({ playlistId: playlistsId, playlistCategoriesId: category.id })
      //   },
      // })
      dispatch({
        type: DELETE_PLAYLIST_CATEGORY,
        payload: { playlistId: playlistsId, playlistCategoriesId: category }
      })
    }
  };

  const handleSwitchIndex = (orderNumber, categories) => {
    dispatch({
      type: UPDATE_PLAYLIST_CATEGORY,
      payload: {
        playlistCategoriesId: categories.id,
        orderNumber,
        playlistId: playlistsId,
        playlistsType: playlistsType
      }
    })
  };

  const handleOpenModalAddCategories = () => {
    dispatch({ type: SET_MODAL_ADD_PLAYLIST_MALL, payload: { isOpen: true } })
  }

  return {
    categoriesList,
    playlistsId,
    playlistsName,
    playlistsType,
    dropDownPlaylist,
    handleOpenModalAddCategories,
    handleDeleteCategories,
    handleSwitchIndex,
    modalCategories, setModalCategories,
    modalConfirm, setModalConfirm
  };
};

const useModalAddCategories = props => {
  const { playlistsId, playlistsType } = props.playlist;
  const dispatch = useDispatch();
  const modalAddPlayListItem = useSelector(state => state.playlists.modalAddPlayListItem);
  const { isOpen } = modalAddPlayListItem;

  const toogle = () => dispatch({ type: SET_MODAL_ADD_PLAYLIST_MALL, payload: { isOpen: false } })
  return {
    isOpen,
    toogle,
    playlistsId,
    playlistsType
  }
}

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default PlaylistCategories;



