import ligisticProvider from '../../provider/ligisticProvider';
import { takeLatest, call, put,select } from 'redux-saga/effects';
import { GET_LOGISTIC_LIST, SET_LOGISTIC_LIST,GET_LOGISTIC_LIST_BY_TYPE } from '../actions/logistic';
import {getLogistic} from '../sagas/selectors'

export function* onGetLogisticList(action) {
    const param = {}
    const logisticList = yield call(ligisticProvider.getLogisticList,param);
    yield put({ type: SET_LOGISTIC_LIST, payload: logisticList });
}

export function* onGetLogisticListByType(action) {
    const param = {}
    const {payload} = action
    const logisticList = yield call(ligisticProvider.getLogisticList,param);
    yield put({ type: SET_LOGISTIC_LIST, payload: payload.type === 'digital' ? logisticList.filter((logistic) => logistic.code === 'DIGITAL') : logisticList });
    // const state = yield select(getLogistic)
}


export default function* useWatcher() {
    yield takeLatest(GET_LOGISTIC_LIST, onGetLogisticList)
    yield takeLatest(GET_LOGISTIC_LIST_BY_TYPE, onGetLogisticListByType)
    
}