import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from 'classnames';
import "./index.scss";

// import moment from 'moment';

import DatePickerInput from 'components/DatePickerInput';
import TimePicker from 'components/Timepicker';

const initialState = {};

class DateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  onChange = (key,value) => {
    this.props.onChange(key,value)
  }

  render() {

    const { date, 
            time, 
            disabled, 
            isDateError, 
            labelDate="วันที่", 
            labelTime="เวลา", 
            hideDate=false,
            hideTime=false,
            isModalDateOpen,
            isTimeError, 
            allowpreviousDate, 
            dissableDateFuture, 
            dissableDatePast,
            disabledTime,
            dateErrorMessage, 
            toggleModalDate,
            timeErrorMessage } = this.props;
             
    return <React.Fragment>
         {/* ANCHOR [date time] */}
         <div className='date-container'>
            <div className='row'>
              {!hideDate && 
                <div className={`col-${hideTime ? '12' : '6'}`}>
                  <div className="mb-1"> {labelDate} <span style={{ color: 'red' }}>*</span> </div>
                  <DatePickerInput
                    date={date}
                    oneModalPerSceenMode={true}
                    isModalOpen={isModalDateOpen}
                    toggleModal={toggleModalDate}
                    isError={isDateError}
                    allowpreviousDate={allowpreviousDate}
                    dissableDateFuture={dissableDateFuture}
                    dissableDatePast={dissableDatePast}
                    disabled={disabled}
                    onSubmitDateSelected={value => this.onChange('date',value)}
                  />
                  <div className="error-msg">{dateErrorMessage}</div>
                </div>
              }
              {!hideTime &&
                <div className={`col-${hideDate ? '12' : '6'}`}>
                  <div className="mb-1"> {labelTime} <span style={{ color: 'red' }}>*</span> </div>
                  <div className={classNames({'input-border-error':isTimeError})}>
                    <TimePicker
                      onTimePickerChange={value => this.onChange('time',value)}
                      value={time}
                      disabled={disabled}
                      disabledTime={disabledTime}
                    />
                  </div>
                  <div className="error-msg">{timeErrorMessage}</div>
                </div>
              }
            </div>
        </div>
    </React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(DateTimePicker);


  
