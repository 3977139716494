export const ADD_KEY_VALUE_CHECKED_SHOP = "ADD_KEY_VALUE_CHECKED_SHOP"
export const UNCHECK_ALL_SHOP_PRODUCT_CHECKED_SHOP = "UNCHECK_ALL_SHOP_PRODUCT_CHECKED_SHOP"
export const UNCHECK_PRODUCT_CHECKED_SHOP = "UNCHECK_PRODUCT_CHECKED_SHOP"
export const RESET_CHECKED_SHOP = "RESET_CHECKED_SHOP"
export const CHECK_PRODUCT_CHECKED_SHOP = "CHECK_PRODUCT_CHECKED_SHOP"
export const COUNT_CHECKED_ALL_SHOP = "COUNT_CHECKED_ALL_SHOP"
export const UPDATE_SKU_CHECKED_SHOP = "UPDATE_SKU_CHECKED_SHOP"
export const REMOVE_PRODUCT_CHECKED_SHOP = "REMOVE_PRODUCT_CHECKED_SHOP"
export const SET_KEY_ALLPRODUCT_SHOP = "SET_KEY_ALLPRODUCT_SHOP"
export const ADD_CHECKED_SHOP_SELECT = "ADD_CHECKED_SHOP_SELECT"

export function dispatchCheckedShop(param){
    return {
      type: param.type,
      payload: param.payload,
    };
  }