/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useRef } from "react-redux";
import './style.scss';
import PeriodInput from 'components/PeriodTimeInput/PeriodInput';
import ModalOption from 'components/PeriodTimeInput/ModalOption';
import moment from "moment";
import { options, generatePeriodDate } from './dataUtil';
;


/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */

const InitialData = {   type: options.REAL_TIME,
                        // period: moment(), //////// 
                        period: {from: moment().startOf('day'),to: moment()},
                        label: `วันนี้จนถึง ${moment().format('HH:mm')}`
                    }

const PeriodTimeInput = (props) => {

    const {isToggle, 
           setIsToggle, 
           periodDate,
           setPeriodDate, 
           onDateSelected,
        } = usePeriodTimeInput(props);
    
    // @PARAMS : OPTIONS PERIOD DATE TYPE AND SAMPLE ASSIGNMENT
    // optionDate: 'REAL_TIME'   (real time)        optionsRangeDate: moment()
    // optionDate: 'YESTERDAY'   (เมื่อวาน)           optionsRangeDate: moment()
    // optionDate: 'PAST_SEVEN'  (ย้อนหลัง 7 วัน )     optionsRangeDate: moment()
    // optionDate: 'PAST_THIRTY' (ย้อนหลัง 30 วัน)     optionsRangeDate: moment()
    // optionDate: 'WITHIN_DAY'  (ภายในวันที่)         optionsRangeDate: moment('month/day/year')
    // optionDate: 'WITHIN_WEEK' (ภายในสัปดาห์)       optionsRangeDate: moment('month/day/year')
    // optionDate: 'WITHIN_MONTH'(ภายในเดือน)        optionsRangeDate: moment('month/1/year')
    // optionDate: 'WITHIN_YEAR' (ภายในปี)           optionsRangeDate: moment('1/1/year')
    // isSubtract : if required subtract day by 1

    const {disabled, optionsRangeDate, optionDate, optionTypeDisabled, allowTodayDate=true} = props;

    useEffect(() => {

      //NOTE - inital component case null datePeriod props
      setPeriodDate(generatePeriodDate(options.REAL_TIME, moment()));

    }, [])

    useEffect(() => {
      if(optionsRangeDate && optionDate) {
        setPeriodDate(generatePeriodDate(optionDate, optionsRangeDate))
      }
    }, [optionsRangeDate,optionDate,optionTypeDisabled]);


    const ModalOptionRef = React.createRef();
    const onClose = () => setIsToggle(false);
    useClickOutside(ModalOptionRef, isToggle, onClose);

    return  <div className="period-time-input">
                <PeriodInput isToggle={isToggle}
                             disabled={disabled}
                             periodDate={periodDate}
                             setIsToggle={setIsToggle}/>

                <ModalOption isOpen={isToggle} 
                             setIsToggle={setIsToggle}
                             ref={ModalOptionRef}
                             onDateSelected={onDateSelected} 
                             periodDate={periodDate}
                             optionDate={optionDate ? optionDate : options.REAL_TIME}
                             optionTypeDisabled={optionTypeDisabled}
                             allowTodayDate={allowTodayDate}
                             />
            </div>
}

/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const usePeriodTimeInput = (props) => {
    const { onPeriodDateSelected } = props;
    const [isToggle, setIsToggle] = useState(false);
    const [periodDate, setPeriodDate] = useState(InitialData);

    const onDateSelected = (periodDate) => {
        setPeriodDate(periodDate);
        onPeriodDateSelected(periodDate);
        // console.log('[periodDate?.period]:',periodDate?.period);
        // console.log('[from]:', periodDate?.period?.from?.format("DD/MM/YYYY HH:mm"));
        // console.log('[to]:', periodDate?.period?.to?.format("DD/MM/YYYY HH:mm"));
    }
    return {isToggle, 
            setIsToggle, 
            periodDate, 
            setPeriodDate,
            onDateSelected}
}

const useClickOutside = (
    insideRefs,
    isVisible,
    onClose,
  ) => {
    useEffect(() => {
      const handleWindowClick = (event) => {
        let someRefContainTarget = false;
        
        if(insideRefs && insideRefs.current) {
            if(insideRefs.current.contains(event.target)) {
                someRefContainTarget = true;
            }
        }

        if(!insideRefs.current) someRefContainTarget = true;

        if (someRefContainTarget) {
            return;
          }
    
          if (!isVisible) {
            return;
          }
    
          if (onClose) {
            onClose();
          }
      };
  
      if (isVisible) {
        window.addEventListener('click', handleWindowClick);
      }
  
      return () => {
        if (isVisible) {
          window.removeEventListener('click', handleWindowClick);
        }
      };
    }, [isVisible, onClose]);
  };

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default PeriodTimeInput;