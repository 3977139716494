import AnnounceProvider from "provider/announceProvider";
import { takeLatest, call, put, select, delay } from "redux-saga/effects";
import {
  SET_KEY_ANNOUNCE,
  CREATE_ANNOUNCE,
  EDIT_ANNOUNCE,
  DELETE_ANNOUNCE,
  GET_ALL_ANNOUNCES,
  GET_ANNOUNCE,
  GET_DISPLAY_ANNOUNCE,
  OPEN_MODAL_DISPLAY_ANNOUNCE,
  SET_PAGINATION
} from "redux/actions/announce";
import { OPEN_TOAST } from '../actions/notification'
import { getAnnounce, getUser } from "./selectors";
import { SET_LOADING } from "redux/actions/app";

const api_announce = new AnnounceProvider();

export function* onGetAnnounceList(action) {
  const { payload } = action
  const { limit = 10, page = 1, currentPage = 1 } = payload

  try {
    yield put({ type: SET_LOADING, payload: { loading: true } });

    const data = { page: page, limit: limit };
    const announceList = yield call(api_announce.getAllAnnounces, data);

    yield put({
      type: SET_KEY_ANNOUNCE,
      payload: { key: "announceList", value: announceList },
    });


    yield put({
      type: SET_PAGINATION,
      payload: {
        currentPage: currentPage,
        limit: limit,
      },
    });

  } catch (error) {
    console.log("--getAnnounceList error--", error);
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
  } finally {
    yield put({ type: SET_LOADING, payload: { loading: false } });
  }
}

export function* onGetAnnounce(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } });

    const { payload } = action || {};
    const currentAnnounce = yield call(api_announce.getAnnounce, payload);

    yield put({
      type: SET_KEY_ANNOUNCE,
      payload: { key: "currentAnnounce", value: currentAnnounce?.data[0] },
    });

  } catch (error) {
    console.log("--getAnnounce error--", error);
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
  } finally {
    yield put({ type: SET_LOADING, payload: { loading: false } });
  }
}

export function* onGetDisplayAnnounce(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } });
    const { payload } = action || {};
    const displayAnnounce = yield call(api_announce.getDisplayAnnounce, payload);

    console.log('--displayAnnounce--', displayAnnounce);
    // yield put({
    //   type: SET_KEY_ANNOUNCE,
    //   payload: { key: "currentAnnounce", value: currentAnnounce?.data[0] },
    // });

  } catch (error) {
    console.log("--getDisplayAnnounce error--", error);
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
  } finally {
    yield put({ type: SET_LOADING, payload: { loading: false } });
  }
}

export function* onCreateAnnounce(action) {
  try {
    console.log('--CREATE ANNOUNCE--', );
    yield put({ type: SET_LOADING, payload: { loading: true } });
    yield call(api_announce.createAnnounce, action.payload);

    yield put({ type: OPEN_TOAST, payload: { message: `สร้างประกาศสำเร็จ`, type: 'success' } });
    yield delay(3000);
    action.history.push('/manage/announce'); 
    
  } catch (error) {
    console.log("--createAnnounce error--", error);
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
  } finally {
    yield put({ type: SET_LOADING, payload: { loading: false } });
  }
}

export function* onUpdateAnnounce(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } });
    yield call(api_announce.updateAnnounce, action.payload);
    yield put({ type: OPEN_TOAST, payload: { message: `แก้ไขประกาศสำเร็จ`, type: 'success' } });
  } catch (error) {
    console.log("--updateAnnounce error--", error);
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
  } finally {
    yield put({ type: SET_LOADING, payload: { loading: false } });
  }
}

export function* onDeleteAnnounce(action) {
  const { payload } = action
  const { id = null } = payload
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } });
    yield call(api_announce.deleteAnnounce, { announceId: id });
    yield put({ type: GET_ALL_ANNOUNCES, payload: {} });
  } catch (error) {
    console.log("--deleteAnnounce error--", error);
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
  } finally {
    yield put({ type: SET_LOADING, payload: { loading: false } });
  }
}

export function* onOpenModalDisplayAnnounce(action) {
  try {

    const announceState = yield select(getAnnounce);
    const userState = yield select(getUser);
    const {id:userId} = userState?.userInfo || {};

    const {isOpen} = action.payload || {};
    let payload = [];

    /* If there are items in this cookie send this to call AP */
    if(localStorage.getItem("announce")) {
      // const openedAnnounceId = JSON.parse(Cookies.get('openedAnnounceId'));

      /* get all user excepted announce */
      const announces = JSON.parse(localStorage.getItem("announce"));

      /* find the one that match with user id */
      const announceUser = announces?.find(announceItem => announceItem.userId === userId);

      if(announceUser && announceUser.exceptedAnnounceId && announceUser.exceptedAnnounceId.length > 0 ) {
        payload = {announceIdList: announceUser.exceptedAnnounceId};
      }
     
    }

    const displayAnnounceData = yield call(api_announce.getDisplayAnnounce, payload);

    let data = {}

    if(displayAnnounceData.data && displayAnnounceData.data.length > 0) {
      data = displayAnnounceData.data[0]
      yield put({
        type: SET_KEY_ANNOUNCE,
        payload: { key: "modalDisplayAnnounce", value: {isOpen: true, data} },
      });
    }


  } catch (error) {
    console.log("--openModalDisplayAnnounce error--", error);
    // yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
  } finally {
    // yield put({ type: SET_LOADING, payload: { loading: false } });
  }
}

export default function* useWatcher() {
  yield takeLatest(GET_ALL_ANNOUNCES, onGetAnnounceList);
  yield takeLatest(GET_ANNOUNCE, onGetAnnounce);
  yield takeLatest(GET_DISPLAY_ANNOUNCE, onGetDisplayAnnounce);
  yield takeLatest(CREATE_ANNOUNCE, onCreateAnnounce);
  yield takeLatest(EDIT_ANNOUNCE, onUpdateAnnounce);
  yield takeLatest(DELETE_ANNOUNCE, onDeleteAnnounce);
  yield takeLatest(OPEN_MODAL_DISPLAY_ANNOUNCE, onOpenModalDisplayAnnounce);
}
