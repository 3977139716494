// import apiClass from '../helper/api'
import host from '../config/host'
import axios from 'config/axios'


class brandProvider {

    createBrand = async (payload) => {
        try {
            const url = `${host.api}/brands`
            const res = axios.post(url, payload)
            return res
        } catch (e) {
            throw e
        }
    }

    getBrandByShopId = async (id, params) => {
        let param = ''
        if (params?.page) {
            param = `&page=${params.kw ? 1 : params.page}`
        }
        if (params?.limit) {
            param = param.concat(`&limit=${params.limit}`)
        }
        if (params?.kw) {
            param = param.concat(`&kw=${params.kw}`)
        }

        try {
            const url = `${host.api}/brands?shopId=${id}${param}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    deleteBrand = async (id) => {
        try {
            const url = `${host.api}/brands/${id}`
            const res = await axios.delete(url)
            return res
        } catch (e) {
            throw e
        }
    }

    createBrandCategory = async (param,shopDetail) => {
        try {
            const {
                categoryType,
                id,
            } = shopDetail || {}
            const url = categoryType != 'custom' || categoryType == null ? `${host.api}/brands/categories` : `${host.api}/brands/shop-categories`
            const res = await axios.post(url, param)
            return res
        } catch (error) {
            throw error
        }
    }

    getCateBrandfromBrandId = async (params,shopDetail=null) => {
        // let param = ''
        // if (params?.search) {
        //     param = param.concat(`&search=${params.search}`)
        // }
        // if (params?.parentId) {
        //     param = param.concat(`&parentId=${params.parentId}`)
        // }
        const {
            categoryType,
            id,
        } = shopDetail || {}
        const url = categoryType != 'custom' || categoryType == null ? `${host.api}/brands/categories` : `${host.api}/brands/shop-categories`
        let param = new URL(url)
        if (params) {
            for (const [key, value] of Object.entries(params)) {
                param.searchParams.set(key, value);
            }
        }
        try {
            // const url = `${host.api}/categories/all`
            const res = axios.get(url + param.search)
            return res
        } catch (e) {
            throw e
        }


        // try {
        //     const url = `${host.api}/brands/categories?brandId=${params.id}${param}`
        //     const res = await axios.get(url)
        //     return res
        // } catch (error) {
        //     throw error
        // }
    }

    updateBrandCategory = async (param,shopDetail=null) => {
        try {
            // const url = `${host.api}/brands/categories`
            const {
                categoryType,
                id,
            } = shopDetail || {}
            const url = categoryType != 'custom' || categoryType == null ? `${host.api}/brands/categories` : `${host.api}/brands/shop-categories`
            const res = await axios.patch(url, param)
            return res
        } catch (error) {
            throw error
        }
    }

    getBrandFromBrandId = async (brandId) => {
        try {
            const url = `${host.api}/brands/${brandId}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    updateBrand = async (brandId, payload) => {
        try {
            const url = `${host.api}/brands/${brandId}`
            const res = axios.patch(url, payload)
            return res
        } catch (e) {
            throw e
        }
    }


}

export default new brandProvider()
