import React, { useEffect, useState } from "react";
import "./index.scss";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import RadioComponent from "components/RadioButton";
import { TextInput } from "components/input";
import IconClose from "../../images/icons/close.svg";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchCategories,
  ADD_KEY_VALUE_CATEGORIES,
} from "redux/actions/categories";
import Loading from 'components/Loading'

const ModalCategory = (props) => {
  const {
    isShowModal = false,
    actionType = "add",
    title = "เพิ่มหมวดหมู่",
    handleSubmitCategory = null,
  } = props;

  const {
    categoryAmountInitial,
    inputMultiCategory,
    categoryAmount,
    isMultiCategory,
    handleSelectCategoryAmount,
    handleSubmit,
    handleChangeInput,
    handleToggleModal,
  } = useModalCategory({ handleSubmitCategory, actionType });

  return (
    <Modal
      isOpen={isShowModal}
      size="xl"
      centered={true}
      className="modal-category"
    >
      <ModalHeader className="modal-header-category">
        <div>
          <span className="text-header-category">
            {actionType == "add" ? title : "แก้ไขหมวดหมู่ย่อย"}
          </span>
        </div>
        <div>
          <img
            src={IconClose}
            className="icon-close-modal-category"
            onClick={() => handleToggleModal()}
          />
        </div>
      </ModalHeader>

      <ModalBody
        className={classNames(
          "modal-body-category",
          isMultiCategory && "isMultiCategory"
        )}
      >
        <div className="body-category">
          {actionType == "add" && (
            <>
              <span className="text-type-category">รูปแบบการสร้าง</span>

              <div className="cover-amount-category">
                {categoryAmountInitial.map((item, index) => (
                  <div key={index}>
                    <RadioComponent
                      name={item?.name}
                      value={item?.name}
                      valueOption={categoryAmount}
                      onChange={(e) => handleSelectCategoryAmount(e)}
                    />
                    <span>{item?.label}</span>
                  </div>
                ))}
              </div>
            </>
          )}

          {[...Array(5)].map((item, index) => {
            if (!isMultiCategory && index >= 1) {
              return null
            }
            return (
              <div key={"input-" + index}>
                <TextInput
                  label={isMultiCategory ? `หมวดหมู่ที่ ${index + 1}` : 'ชื่อหมวดหมู่'}
                  title="(จำกัด 100 ตัวอักษร)"
                  require={index == 0}
                  placeholder="กรอกข้อมูล"
                  maxLength={100}
                  error={inputMultiCategory[index]?.error}
                  handleChangeText={(e) => handleChangeInput(e, index)}
                  defaultValue={inputMultiCategory[index]?.value}
                />
              </div>
            )
          }
          )}
        </div>
      </ModalBody>

      <ModalFooter className="modal-footer-category">
        <button className="btn-create-category" onClick={() => handleSubmit(actionType)}>
          {actionType == "add" ? "สร้างหมวดหมู่" : "บันทึก"}
        </button>
      </ModalFooter>

      <Loading />

    </Modal>
  );
};

const useModalCategory = ({ handleSubmitCategory = null, actionType }) => {
  const categoryAmountInitial = [
    {
      name: "one",
      label: "ครั้งละ 1 หมวดหมู่",
    },
    {
      name: "multi",
      label: "ครั้งละหลายหมวดหมู่",
    },
  ];

  const initialInputMulti = [
    { value: "", error: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ];


  const {
    isShowModalCategory = false,
    modalCategory,
    selectCategoryLevel1,
    selectCategoryLevel2,
    selectCategoryLevel3,
  } = useSelector((state) => state.categories);

  const [inputMultiCategory, setInputMultiCategory] =
    useState(initialInputMulti);
  const [categoryAmount, setCategoryAmount] = useState("one");

  const dispatch = useDispatch();
  const isMultiCategory = categoryAmount == "multi";

  useEffect(() => {

    /* initial value */
    if (isShowModalCategory) {
      if (actionType === "add") {
        setInputMultiCategory(initialInputMulti);
      }
      if (actionType === "edit") {

        const { level } = modalCategory || {};
        const categorySelected = getCategorySelected(level);
        let  resList= [...initialInputMulti]
        resList[0]={
          value: categorySelected?.name,
          error: "",
        }

        setInputMultiCategory(resList);
      }

      setCategoryAmount("one")
    }
  }, [isShowModalCategory])

  const getCategorySelected = (level) => {
    switch (level) {
      case 1:
        return selectCategoryLevel1;
      case 2:
        return selectCategoryLevel2;
      case 3:
        return selectCategoryLevel3;
    }
  };

  const handleSelectCategoryAmount = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setCategoryAmount(name);
    }

    let multi = [...inputMultiCategory];
    multi[0].error = "";
    setInputMultiCategory(multi);
  };

  const handleSubmit = (actionType) => {
    let res = "";
    const { level } = modalCategory || {};
    const categorySelected = getCategorySelected(level);

    if (inputMultiCategory[0]?.value?.trim() == "") {
      let category = [...inputMultiCategory];
      category[0].error = "กรุณากรอกข้อมูลให้ครบ";
      setInputMultiCategory(category);
    } else {
      res = inputMultiCategory
        .filter((i) => i?.value !== "")
        .map((item) => item?.value);


        let resData = isMultiCategory ? res : actionType === "add" ? [ inputMultiCategory[0]?.value ] : { name: inputMultiCategory[0]?.value, id: categorySelected?.id }
    
        // callback to parent
        handleSubmitCategory(resData, actionType);
    }

  };

  const handleChangeInput = (value, index) => {
      const result = inputMultiCategory.some((i, idx) => index == idx);

      let res = [...inputMultiCategory];
      if (result) {
        res[index].value = value;
      } else {
        res.push({ value: value });
      }

      if(index == 0) {
        res[0].error = "";
      }

      setInputMultiCategory(res);
  };

  const handleToggleModal = () => {
    setInputMultiCategory(initialInputMulti);

    dispatch(
      dispatchCategories({
        type: ADD_KEY_VALUE_CATEGORIES,
        payload: {
          key: "isShowModalCategory",
          value: false,
        },
      })
    );
  };

  return {
    categoryAmountInitial,
    inputMultiCategory,
    categoryAmount,
    isMultiCategory,
    handleSelectCategoryAmount,
    handleSubmit,
    handleChangeInput,
    handleToggleModal,
  };
};

export default ModalCategory;
