import moment from "moment";
import { SET_KEY_ANNOUNCE, SET_PAGINATION } from "../actions/announce";

const initialState = {
  announceList: [],
  currentAnnounce: {},
  pagination: {
    limitPage: 10,
    currentPage: 1,
    totalPage: 1,
    totalCount: 1,
    pageSizeOptions: [12, 24, 36],
  },
  modalDisplayAnnounce: {
    isOpen: false,
    data: {
      image: "",
      title: "",
      description: "",
      announceBegin: moment(),
      announceEnd: moment(),
      id: ""
    }
  }
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_KEY_ANNOUNCE:
      const { key, value } = payload;
      return {
        ...state,
        [key]: value,
      };


      case SET_PAGINATION: {
        const { currentPage = 1, limit = 10 } = payload

        return {
          ...state,
          pagination: {...state.pagination, limitPage: limit, currentPage: currentPage},
        };
      }
    default:
      return state;
  }
}
