/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import host from 'config/host'
import { ButtonAdd, Modals, ModalLoading } from 'components/output'
import { TextInput, Checkbox } from 'components/input'
// import Checkbox from 'components/Checkbox'
import {
  dispatchPlaylists,
  GET_PLAYLISTS,
  // CREATE_PLAYLISTS,
  UPDATE_PLAYLISTS,
  DELETE_PLAYLISTS,
} from 'redux/actions/playlists'
import {
  dispatchPlaylistFrontPage,
  GET_PAGE,
  // GET_PLAYLIST_FRONT_PAGE,
  CREATE_PLAYLIST_FRONT_PAGE,
  // UPDATE_PLAYLIST_FRONT_PAGE,
  // DELETE_PLAYLISTS,
} from 'redux/actions/playlistFrontPage'
import {
  ADD_KEY_VALUE_SHOP,
  // GET_CURRENT_SHOP_DETAIL
} from 'redux/actions/shop'
// import { ReactComponent as IconCamera } from 'images/icons/iconcamera.svg'
// import IconTrash from 'images/icons/trash.svg'
// import closeImage from 'images/icons/close-image.svg'
import noItem from 'images/icons/status-error.svg'
// import edit from 'images/icons/edit.svg'
import 'styles/_playlists.scss'

const PlaylistFrontPage = (props) => {
  const { playlists, pages, dispatch, history } = props

  const [activeModal, setActiveModal] = useState('none')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [base64, setBase64] = useState(null)
  const [data, setData] = useState(null)

  const location = useLocation()
  const isPlatFormMode = location.pathname === '/manage/playlist-front-page/platform'
  // const userInfo = useSelector(state => state.user.userInfo)
  const shopSelected = useSelector(state => state.shop.shopSelected)

  useEffect(() => {
    setIsLoading(true)
    // User shop login
    // if (userInfo.shopId) {
    //   dispatch({ type: GET_CURRENT_SHOP_DETAIL })
    // } else {
    dispatch(dispatchPlaylists({ type: GET_PLAYLISTS }))
    dispatch(dispatchPlaylistFrontPage({ type: GET_PAGE }))
    // }
  }, [])

  useEffect(() => {
    if (shopSelected) {
      dispatch(dispatchPlaylists({ type: GET_PLAYLISTS }))
      dispatch(dispatchPlaylistFrontPage({ type: GET_PAGE }))
    }
  }, [shopSelected])

  useEffect(() => {
    if (pages) {
      setIsLoading(false)
    }
  }, [pages])

  const toggleModal = (modal) => {
    if (modal === 'none') {
      setData(null)
      setError(null)
      setBase64(null)
    }
    setActiveModal(modal)
  }

  const handleChangePage = (text) => {
    setData({
      ...data,
      page: text,
    })
  }

  const handleChangeSection = (text) => {
    setData({
      ...data,
      section: text,
    })
  }

  // const handleFileRead = async (event) => {
  //   const tmpFile = event.target.files[0]
  //   const tmpBase64 = await convertBase64(tmpFile)

  //   setData({
  //     ...data,
  //     icon: tmpFile,
  //   })
  //   setBase64(tmpBase64)
  // }

  // const convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader()
  //     fileReader.readAsDataURL(file)
  //     fileReader.onload = () => {
  //       resolve(fileReader.result)
  //     }
  //     fileReader.onerror = (error) => {
  //       reject(error)
  //     }
  //   })
  // }

  const uploadImage = async (data) => {
    const path = `${host.apiMedia}/upload`

    const formData = new FormData()
    formData.append('file', data)
    formData.append('serviceKey', host.serviceKey)
    formData.append('secretKey', host.secretKey)

    const response = await axios.post(path, formData)

    if (response.status === 200) {
      return response.data
    } else {
      // handle error

      return null
    }
  }

  const validator = () => {
    let tmpError = {}
    let hasError = true

    if (!data?.page) {
      tmpError.page = 'กรุณาใส่ชื่อ page'
      hasError = false
    }

    if (!data?.section) {
      tmpError.section = 'กรุณาใส่ชื่อ section'
      hasError = false
    }

    if (!data?.playlists.length) {
      tmpError.playlists = 'กรุณาเลือกเพลย์ลิส'
      hasError = false
    }

    setError(tmpError)
    return hasError
  }

  const clickSubmit = async () => {
    const isValid = validator()

    if (isValid) {
      setIsLoading(true)

      switch (activeModal) {
        case 'create':
          // const image = await uploadImage(data.icon)

          // if (image) {
          //   const path = `image/${image.fileName}`

          dispatch(
            dispatchPlaylistFrontPage({
              type: CREATE_PLAYLIST_FRONT_PAGE,
              payload: { data, type: GET_PAGE },
            }),
          )
          // }

          break

        case 'edit':
          let path = ''

          if (data.icon !== data.iconActivePath) {
            const image = await uploadImage(data.icon)

            if (image) {
              path = `image/${image.fileName}`
            }
          } else {
            path = data.iconActivePath
          }

          if (path) {
            dispatch(
              dispatchPlaylists({
                type: UPDATE_PLAYLISTS,
                payload: {
                  playlistsId: data.id,
                  body: {
                    name: data.name,
                    title: data.name,
                    iconActivePath: path,
                    iconInactivePath: path,
                  },
                },
              }),
            )
          }
          break

        default:
          break
      }

      toggleModal('none')
    } else {
    }
  }

  // const clearImage = () => {
  //   setBase64(null)
  //   setData({
  //     ...data,
  //     icon: null,
  //   })
  // }

  const onSelectedData = (item) => {
    setData({
      ...item,
      icon: item.iconActivePath,
    })
  }

  const onDeletePlaylist = (playlistsId) => {
    setIsLoading(true)
    dispatch(
      dispatchPlaylists({
        type: DELETE_PLAYLISTS,
        payload: playlistsId,
      }),
    )
  }

  const onSelectedPlaylist = (value, e) => {
    let tmpPrevPlaylist = data?.playlists ? data?.playlists : []

    if (e.target.checked) {
      tmpPrevPlaylist.push({ playlistId: value })
    } else {
      tmpPrevPlaylist = data?.playlists.filter(
        (item) => item.playlistId !== value,
      )
    }

    setData({
      ...data,
      playlists: tmpPrevPlaylist,
    })
  }

  const handleClearShopSelected = () =>
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopSelected', value: null } });

  return (
    <div className='playlists'>
      <div className='playlists-content'>
        <div className='d-flex justify-content-between'>
          <div>
            {/* {!userInfo.shopId && !isPlatFormMode && */}
            {!isPlatFormMode &&
              <div className='d-flex justify-content-start align-items-end'>
                <div className='pr-4'>
                  <img src={`${host.image}${shopSelected?.logoPath?.path}`} style={{ height: 40, marginRight: 5 }} />
                </div>
                <div className='pl-4' style={{ borderLeft: 'solid black 2px' }}>
                  {shopSelected?.name}
                </div>
              </div>
            }
          </div>
          <div className="d-flex flex-row justify-content-end">
            {/* {!userInfo.shopId && !isPlatFormMode && */}
            {!isPlatFormMode &&
              <div className='btn-info mr-2'
                style={{ cursor: 'pointer' }}
                onClick={handleClearShopSelected}> เลือกร้านค้า
              </div>
            }
            <ButtonAdd
              label='เพิ่มหน้าและเซคชั่น'
              handleAdd={() => toggleModal('create')}
            />
          </div>
        </div>
        <Table
          data={pages}
          toggleModal={toggleModal}
          history={history}
          setSelectedData={onSelectedData}
          onDelete={onDeletePlaylist}
        />
        <Modals
          title={
            activeModal === 'create'
              ? 'เพิ่มหน้าและเซคชั่น'
              : 'แก้ไขหน้าและเซคชั่น'
          }
          closeModal={() => toggleModal('none')}
          clickButton={clickSubmit}
          labelButton='ตกลง'
          isOpenModal={activeModal === 'create' || activeModal === 'edit'}
        >
          <div className='m-3'>
            <TextInput
              label='ชื่อหน้า'
              placeholder='ชื่อหน้า'
              handleChangeText={handleChangePage}
              defaultValue={data?.page}
              error={error && error.page}
              require
            />
          </div>
          <div className='m-3'>
            <TextInput
              label='ชื่อเซคชั่น'
              placeholder='ชื่อเซคชั่น'
              handleChangeText={handleChangeSection}
              defaultValue={data?.section}
              error={error && error.section}
              require
            />
          </div>
          <div className='m-3'>
            <TableAddPlaylist
              data={playlists}
              dataSelected={data?.playlists ? data?.playlists : []}
              toggleModal={toggleModal}
              history={history}
              setSelectedData={onSelectedPlaylist}
              clearDataSelected={() => { }}
              onDelete={onDeletePlaylist}
              itemSize='small'
              isScroll
            />
            {error && error.playlists ? (
              <div className='text-left'>
                <span style={{ color: 'red' }}>{error.playlists}</span>
              </div>
            ) : null}
          </div>
        </Modals>
        {isLoading && <ModalLoading isOpenModal={isLoading} />}
      </div>
    </div>
  )
}

const Table = (props) => {
  const { data, 
    // toggleModal, 
    history, 
    // setSelectedData, onDelete
   } = props

  const location = useLocation()
  const isPlatFormMode = location.pathname.includes('platform');

  return data && data.length === 0 ? (
    <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
      <img src={noItem} alt='noItem' />
      <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
    </div>
  ) : (
    <div className='playlists-list'>
      <div className='table-header'>
        <div className='row'>
          <div className='col-2 text-center'>ลำดับ</div>
          <div className='col'>หน้า</div>
        </div>
      </div>
      <div className='table-body'>
        {data &&
          data.map((item, index) => (
            <div
              onClick={() =>
                history.push(`/manage/playlist-front-page/${isPlatFormMode ? 'platform' : 'mall'}/page`, {
                  page: item.page,
                })
              }
              key={index}
            >
              <div className='row'>
                <div className='col-2 d-flex align-items-center justify-content-center'>
                  {index + 1}
                </div>
                {/* <div className='col-1 d-flex align-items-center'>
                  <span className='image-playlist-container'>
                    <img
                      src={host.image + item.iconActivePath}
                      alt=''
                      className='item-playlist'
                      style={{ width: '100%' }}
                    />
                  </span>
                </div> */}
                <div className='col-6 d-flex align-items-center'>
                  {item.page}
                </div>
                {/* <div
                  className='d-flex'
                  onClick={(e) => {
                    e.stopPropagation()
                    setSelectedData(item)
                    toggleModal('edit')
                  }}
                >
                  <img src={edit} alt='edit' className='icon-link' />
                </div>
                <div
                  className='d-flex'
                  onClick={(e) => {
                    e.stopPropagation()
                    onDelete(item.id)
                  }}
                >
                  <img src={IconTrash} alt='edit' className='icon-link' />
                </div> */}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

const TableAddPlaylist = (props) => {
  const {
    data,
    dataSelected,
    toggleModal,
    setSelectedData,
    // clearDataSelected,
    isScroll,
    itemSize = '',
  } = props

  return data && data.length === 0 ? (
    <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
      <img src={noItem} alt='noItem' />
      <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
    </div>
  ) : (
    <div className='playlists-list'>
      <div className={`table-header ${itemSize}`}>
        <div className='row'>
          {dataSelected ? null : <div className='col-2 text-center'>ลำดับ</div>}
          <div className='col-10'>ชื่อเพลย์ลิสต์</div>
        </div>
      </div>
      <div className={`table-body ${isScroll && 'scroll'}`}>
        {
          // dataSelected ? (
          //   <div>
          //     <div className={`row ${itemSize}`}>
          //       <div className='col-10 d-flex align-items-center'>
          //         {dataSelected.name}
          //       </div>
          //       <div className='btn-close' onClick={clearDataSelected}>
          //         <img alt='' src={closeImage} className='icon-close' />
          //       </div>
          //     </div>
          //   </div>
          // ) : (
          data &&
          data.map((item, index) => {
            return (
              <div key={index}>
                <div className={`row ${itemSize}`}>
                  <div className='col-2 d-flex align-items-center justify-content-center'>
                    {index + 1}
                  </div>

                  {/* <div className='col-1 d-flex align-items-center'>
                    <span className='image-playlist-container'>
                      <img
                        src={host.image + item.iconActivePath}
                        alt=''
                        className='item-playlist'
                        style={{ width: '100%' }}
                      />
                    </span>
                  </div> */}

                  <div className='col-8 d-flex align-items-center'>
                    {item.name}
                  </div>

                  {/* <ButtonAdd
                  label=''
                  handleAdd={(e) => {
                    e.stopPropagation()
                    setSelectedData(item)
                    toggleModal('edit')
                  }}
                /> */}
                  <Checkbox
                    label=''
                    // defaultChecked
                    checked={dataSelected.some(
                      (d) => d.playlistId === item.id,
                    )}
                    onSelectMedia={(d, e) => {
                      e.stopPropagation()
                      setSelectedData(d.id, e)
                      toggleModal('create')
                    }}
                    media={item}

                  // title=''
                  // checked={dataSelected.some((d) => d.id === item.id)}
                  // value={item.id}
                  // onChange={(e) => {
                  //   // console.log(d, 'd')
                  //   e.stopPropagation()
                  //   setSelectedData(item)
                  //   // toggleModal('edit')
                  // }}
                  // name={item.id}
                  />
                </div>
              </div>
            )
          })
          // )
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  playlists: state.playlists.playlists,
  pages: state.frontPage.pages,
  frontpage: state.frontPage.frontPage,
  shop: state.shop.shopSelected
})

export default connect(mapStateToProps, null)(PlaylistFrontPage)
