// import apiClass from '../helper/api'
import host from '../config/host'
import axios from 'config/axios'


class categoriesProvider {

  getCategories = async (params = null, shopDetail = null,level=1) => {
    const {
      categoryType,
      id,
    } = shopDetail || {}

    const url = (categoryType != 'custom' && level == 1) ? `${host.api}/categories/parent-all`
      : categoryType != 'custom' ? `${host.api}/categories/all`
        : (level > 1 || (params?.search?.length > 0)) ? `${host.api}/shop-categories/all`
          : `${host.api}/shop-categories/parent-all`
    let param = new URL(url)
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        param.searchParams.set(key, value);
      }
    }
    try {
      // const url = `${host.api}/categories/all`
      // const urlRes = `${url}${categoryType != 'custom' ? param.search : param.search ? `${param.search}&shopId=${id}`: `?shopId=${id}`}`
      // const urlRes = `${url}${categoryType != 'custom' ? param.search : param.search ? `${param.search}&shopId=${id}` : level > 1 ? '' : `?shopId=${id}`}`
      // const urlRes = `${url}${categoryType != 'custom' && level <= 1 && shopDetail?.id>=0 && param?.search ? `${param?.search}&shopId=${id}`
      //     : categoryType != 'custom' && level <= 1 ? `${param?.search}`
      //       : level <= 1 ? `?shopId=${id}` : `${param?.search}`
      //     }`
      let urlRes = param?.search?.length > 0 ? `${url}${param?.search}` : `${url}`
      urlRes += level > 1 ? '' : (param?.search?.length > 0 && shopDetail?.id >= 0) ? categoryType == 'custom' ? `&shopId=${id}` : `?shopId=${id}` : ''
      const res = await axios.get(urlRes)
      return res
    } catch (e) {
      throw e
    }
  }

  getBrandByShopId = async (id, params) => {
    let param = ''
    if (params?.page) {
      param = `&page=${params.page}`
    }
    if (params?.limit) {
      param = param.concat(`&limit=${params.limit}`)
    }
    if (params?.kw) {
      param = param.concat(`&kw=${params.kw}`)
    }
    try {
      const url = `${host.api}/brands?shopId=${id}${param}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }






  getParentCategoriesAll = async ({ shopId = null }) => {
    try {
      const url = `${host.api}/shop-categories/parent-all?shopId=${shopId}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }


  getChildCategories = async ({ parentId = null }) => {
    try {
      const url = `${host.api}/shop-categories/all?parentId=${parentId}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }



  createCategories = async ({ body = null }) => {
    try {
      const url = `${host.api}/shop-categories`
      const res = await axios.post(url, body)
      return res
    } catch (error) {
      throw error
    }
  }



  updateCategories = async ({ categoryId = null, body = null }) => {
    try {
      const url = `${host.api}/shop-categories/${categoryId}`
      const res = await axios.patch(url, body)
      return res
    } catch (error) {
      throw error
    }
  }


  deleteCategories = async ({ categoryId = null }) => {
    try {
      const url = `${host.api}/shop-categories/${categoryId}`
      const res = await axios.delete(url)
      return res
    } catch (error) {
      throw error
    }
  }
}

export default new categoriesProvider()


// import host from "../config/host";

// class categoriesProvider {
//   getCategories = async () => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         const url = `${host.api}/categories/parent-all`;
//         const res = await axios.get(url);
//         resolve(res.data ? res.data : []);
//       } catch (error) {
//         throw error;
//       }
//     });
//   };
// }

// export default new categoriesProvider();
