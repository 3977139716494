import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import classNames from "classnames";
import host from 'config/host';
import './cardProduct.scss';
import IconEmptyProduct from 'images/icons/cover-product.svg'
import { Checkbox } from "components/input";
import { CHECK_PRODUCT_PROMOCODE, UNCHECK_PRODUCT_PROMOCODE } from "redux/actions/checkedProduct";
import { ADD_CHECKED_PRODUCT_PROMOCODE, REMOVE_CHECKED_PRODUCT_PROMOCODE } from "redux/actions/promocode";
import { OPEN_TOAST } from 'redux/actions/notification'
import numeral from "numeral";

const CardProduct = (props) => {

    const {product, objSku} = props || {};
    const {coverPath, name, productSkus, productId} = product || {};

    const dispatch = useDispatch();
    const checkedProduct = useSelector(state => state.checkedProduct);
    const seletedProduct = useSelector(state => state.selectedProduct);

    function getPriceNetScope(productSkus) {
        try {
            if(productSkus && typeof productSkus === "object") {
                if(Object.values(productSkus).length > 1) {
                    const prices = Object.values(productSkus).map(product => product.priceNet);
                    const maxPrice = Math.max(...prices);
                    const minPrice = Math.min(...prices);
                    if(maxPrice === minPrice) return  `฿ ${maxPrice}`
                    else return  `฿ ${numeral(minPrice).format('0,0')} - ${numeral(maxPrice).format('0,0')}`
                  } else {
                    return `฿ ${numeral(Object.values(productSkus)[0]?.priceNet).format('0,0')}`;
                }
            }
            return "";
        } catch(error) {
            throw(error)
        }
    }
    
    const handleOnClickCheckbox = () => {
      const sku = objSku(productSkus)

        // dispatch({ type: ADD_KEY_VALUE_CHECKED_PRODUCT, payload: { key: 'count', value: checkedProduct.count + 1 } })
    
      if (seletedProduct.product[productId] !== undefined) return null;

      if (checkedProduct.product[productId] !== undefined) {
        dispatch({
          type: UNCHECK_PRODUCT_PROMOCODE,
          payload: { [productId]: sku },
        });
        dispatch({
            type: REMOVE_CHECKED_PRODUCT_PROMOCODE,
            payload: { [productId]: product },
          });
      } else {

        if(seletedProduct?.count + checkedProduct?.count >= 100) {
          dispatch({
            type: OPEN_TOAST,
            payload: {
              type: 'danger',
              message: `คุณเลือกสินค้าเกินที่กำหนด <br/>
              (สินค้าบางรายการ สามารถเข้าโปรโมชั่น ได้สูงสุด 100 สินค้า)`
            }
          })
        } else {
        dispatch({
          type: CHECK_PRODUCT_PROMOCODE,
          payload: { [productId]: sku },
        });
        dispatch({
            type: ADD_CHECKED_PRODUCT_PROMOCODE,
            payload: { [productId]: product },
          });
        }
      }
    
    };

    


    return (
      <div className={classNames("card-product", { active: checkedProduct.product[productId] !== undefined, })} >
        <div className="select-product">
          <Checkbox
            defaultChecked={productId}
            checking={
              seletedProduct.product[productId] !== undefined
                ? true
                : checkedProduct.product[productId] !== undefined
            }
            onSelectMedia={handleOnClickCheckbox}
            disabled={seletedProduct.product[productId] !== undefined}
          />
        </div>
        <div className="product-image">
          {coverPath?.path ? (
            <img src={`${host.image}${coverPath?.path}`} />
          ) : (
            <img src={IconEmptyProduct} />
          )}
        </div>
        <div className="product-detail">
          <div className="product-name"> {name} </div>
          <div className="product-price">
            <span>{getPriceNetScope(productSkus)}</span>
          </div>
        </div>
      </div>
    );
}

export default CardProduct;