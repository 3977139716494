import { OPEN_MODAL_CREATE_LIVE,
    // SET_LIVE_FILED, 
         SET_LIVE ,
        //  SET_SELECT_PRODUCT_DETAIL,
        //  UPDATE_QTY_SELECT_PRODUCT_DETAIL,
        //  SET_PRODUCT_LIST,
         SET_LIVE_STATUS,
         SET_LIVE_DURATION,
         SET_VIDEO_SOURCE
  } from '../actions/live'

const initialState = {
    modalCreateLive: false,
    postDetail: null,
    productLive : [],
    productShelf: [],
    liveStatus: null,
    liveDuration: 0,
    videoSources: ['live-19-06-2021.mp4', 'live-05-11-2021.mp4', 'live-11-12-2021.mp4', 'live-18-01-2021.mp4']
    
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {

      
        case SET_VIDEO_SOURCE: {
            return { ...state, videoSources: payload.videoSources }
        }

        case SET_LIVE_STATUS: {
            return { ...state, liveStatus: payload.liveStatus }
        }

        case SET_LIVE_DURATION: {
            return { ...state, liveDuration: payload.liveDuration }
        }

        case OPEN_MODAL_CREATE_LIVE: {
            return {
                ...state,
                modalCreateLive: payload
            }
        }
        case SET_LIVE: {
            const { postDetail } = payload
            const { arrLive, arrShelf } = selectItemLiveShelf(postDetail.items)
   
            return {
               ...state,
               postDetail: postDetail,
               productLive: arrLive,
               productShelf: arrShelf,
            }
         }
   
        default: {
            return state
        }
    }
}

const selectItemLiveShelf = (data) => { /// return [productLive] , [productShelf]
    const arrLive = []
    const arrShelf = []
 
    // eslint-disable-next-line no-unused-vars
    for(const [idx, item] of data.entries()){
       if(item.showOn.includes("live")){
          arrLive.push(item)
        }
        if(item.showOn.includes("shelf")){
          arrShelf.push(item)
        }
    }
 
    return { arrLive, arrShelf }
 }
 