import React from 'react'
import numeral from 'numeral'
import CheckboxComponent from 'components/Checkbox'

const SelectedProductSkus = ({imagePath, name, price, amount, status, handleSkuCheckbox, value}) => {


  return (
  <div className='productSkus'>
    <div className='productSku-checkbox'>
      <CheckboxComponent
        checked={value} 
        onChange={(e)=>handleSkuCheckbox(e?.target?.checked)}
        value={value}
        name={name}
        />
    </div>
    <div className='productSku-detail'>
      <img src={imagePath} alt={`รูป${name}`}/>
      <span>{name}</span>
    </div>
    <div className='productSku-price'>
      {`฿ ${numeral(price).format('0,0.00')}`}
    </div>
    <div className='productSku-amount'>
      {numeral(amount).format('0,0')}
    </div>
    <div className='productSku-status'>
      {status === 'show' ? <span className='show'>ขายอยู่</span> : <span className='not-show'>ไม่ขาย</span>}
    </div>
  </div>
  )
}

export default SelectedProductSkus