/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "antd";
import host from 'config/host';

import OrderIndex from "components/Broadcast/OrderIndex";
import InputText from "components/InputText";
import ButtonUploadCustom from "components/Broadcast/ButtonUploadCustom";

import IconTrash from "images/icons/trash-orange.svg";
// import IconCamera from "images/icons/camera-orange.svg";
import IconClose from "images/icons/close-image.svg";

import "./index.scss";

const getImageRatio = async (content) => {
  const { data } = content;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const ratio = getAspectRatio(this.width, this.height);
      resolve({ ...content, ratio });
    };
    img.src = `${host.image}${data && data.path}`;
  });
};

const getAspectRatio = (width, height) => {
  var ratio = width / height;
  return Math.abs(ratio - 4 / 3) < Math.abs(ratio - 16 / 9) ? "4-3" : "16-9";
};

const renderHeader = (props, useContentImage) => {
  const { indexSelected, options, onSwitchIndex, onDeleteItem, onSetCardIndex } = props;
  const { setIndexSelected, shop } = useContentImage;
  const { indexOld, indexNew } = useContentImage.indexSelected;
  return (
    <div className="d-flex justify-content-between">
      {/* left side */}
      <div className="d-flex flex-row align-items-center">
        <OrderIndex
          options={options}
          indexSelected={indexSelected}
          handleSelected={(value) =>
            setIndexSelected({ indexOld: indexSelected, indexNew: value })
          }
        />
        <button
          className="btn-info small-button"
          onClick={() => onSwitchIndex(indexOld, indexNew)}
        >
          ย้าย
        </button>
      </div>

      {/* right side */}
      <div className="d-flex flex-row align-items-center">
          <ButtonUploadCustom
            width='12em'
            height='12em'
            type='image'
            shopDetail={{ shopId: shop.currentShop?.id }}
            disabled={false}
            fieldUpload={`boardcastUploadEvent`}
            limitImageUpload={10}
            setCardIndex={() => onSetCardIndex(indexSelected)}
          />
        <button
          className="btn-cancel ml-4 d-flex justify-content-center align-items-center"
          onClick={() => onDeleteItem(indexSelected)}
        >
          <img src={IconTrash} className="mr-2" alt='icon-trash'/>
          ลบเนื้อหานี้
        </button>
      </div>
    </div>
  );
};

const renderDetail = (props, { imageContent }) => {
  return (
    <div className="d-flex justify-content-start flex-column mt-4">
      {imageContent.length > 0 ? (
        <>
          {/* images list */}
          <section className="section image-list">
            {rederImages(props, imageContent)}
          </section>
          {/* link list */}
          {renderLinks(props)}
        </>
      ) : (
        <>
          {/* no data */}
          <section className="section image-list">
            <div className="d-flex justify-content-center w-100">
              <label className="title"> ไม่มีรูปภาพ </label>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

const getNewTextLink = (content, newLink) => {
  let newContent = content;
  newContent.link = newLink;
  return newContent;
};

const renderLinks = (props) => {
  const { contents, indexSelected, onUpdateContentItem } = props;
  const { Panel } = Collapse;
  return (
    <div>
      <Collapse
        className="pt-3"
        expandIcon={({ isActive }) => (
          <FontAwesomeIcon
            className="icon-angle"
            icon={isActive ? faAngleUp : faAngleDown}
          />
        )}
        ghost
      >
        <Panel
          header={<label className="panel-header">เพิ่มลิงค์ URL</label>}
          key="1"
        >
          {contents &&
            contents.map((content, index) => (
              <InputText
                className={"input-text"}
                value={content.link}
                label={`รูปภาพที่ ${index + 1} : ลิ้งค์ URL`}
                isRequire={false}
                isError={false}
                key={index}
                onChange={(event) =>
                  onUpdateContentItem(
                    indexSelected,
                    index,
                    getNewTextLink(content, event.target.value)
                  )
                }
              />
            ))}
        </Panel>
      </Collapse>
    </div>
  );
};

const rederImages = (props, imageContent) => {
  return (
    <>
      {imageContent &&
        imageContent.map((content, index) => {
          return (
            <div key={index} className={`ratio${content.ratio}`}>
              {imageThumbnal(props, index, content)}
            </div>
          );
        })}
    </>
  );
};

const imageThumbnal = ( { indexSelected, onDeleteContenImageItem }, index, { data } ) => (
  <div className={`image-thumbnal`}>
    <label>รูปที่ {index + 1}</label>
    <span className="thumbnail">
      <img src={`${host.image}${data && data.path}`} alt='img-content'/>
      <div className="overlay">
        <img
          src={IconClose}
          className="remove-item"
          onClick={() => onDeleteContenImageItem(indexSelected, index)}
          alt='icon-close'
        />
      </div>
    </span>
  </div>
);

export default function ContentImage(props) {
  const payload = useContentImage(props);
  return (
    <div className="container-content">
      {renderHeader(props, payload)}
      {renderDetail(props, payload)}
    </div>
  );
}

const useContentImage = (props) => {
  const { contents, onCreateContentImageItem } = props;
  const [imageContent, setImageContent] = React.useState([]);
  const [indexSelected, setIndexSelected] = React.useState({
    indexOld: 0,
    indexNew: 0,
  });


  const stateShop = useSelector(state => state.shop);
  const stateGallery = useSelector(state => state.gallery);
  // eslint-disable-next-line no-unused-vars
  const [shop, setShop] = React.useState(stateShop);

  useEffect(() => {
    async function ImageRaito() {
      Promise.all(contents.map(async (content) => await getImageRatio(content)))
        .then((result) => setImageContent(result))
        .catch((error) => console.error(error));
    }
    if (contents.length > 0) ImageRaito();
    else setImageContent([]);
  }, [contents]);

  useEffect(() => {
    // add image from gallery
    onCreateContentImageItem(props.indexSelected);
  }, [stateGallery]);

  return { imageContent, indexSelected, setIndexSelected, shop};
};
