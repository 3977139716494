import React, { Component } from 'react'
import { connect } from 'react-redux'
import IconDefaultProduct from '../../images/icons/default-product-image.svg'
import './modalPreview.scss'
import close from '../../images/icons/close.svg'
import host from '../../config/host'




class ModalPreview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
    }

    componentDidMount = () => {
        this.props.setClick(this.toogleDisplay);
    }

    toogleDisplay = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    getProductSkuImage = (productSkuFileImages,attribute) => {
        if(productSkuFileImages && attribute) {
            const imageSku = productSkuFileImages.find(productSku => {
                const attributeValue = attribute[0]?.attribute ? 
                                       attribute[0]?.attribute?.value : attribute[0]?.value
                return productSku?.key === attributeValue
            })
            return imageSku;
        }

        return null;
    }

    render() {
        const { idx, sku, optionDetail, productSkuFileImages} = this.props;
        const { attribute } = sku 
        const productIndex = idx+1;
        const { isOpen } = this.state;
        const productSku = this.getProductSkuImage(productSkuFileImages,attribute);

        return (
            <div style={{ width: '35em' }} id="modalPreview">

                <div className={`${isOpen ? "" : "modal-preview-hide"}`}>

                    <div className="bg-white" style={{ padding: '1.5em' }} >

                        <div className="d-flex justify-content-between mb-3">
                            <h5 className="align-items-end mb-0">ตัวอย่างการแสดงผล</h5> 
                            <img src={close} alt="" onClick={this.toogleDisplay} className="cursor-pointer" />
                        </div>
                        <div className="modal-preview-container">

                            <div className="d-flex flex-row  item-preview-container justify-content-between">
                                <div className="select-all-item">
                                    <label className="container">
                                        {/* <input type="checkbox" disabled/> */}
                                        {/* <span className="checkmark"></span>  */}
                                        <div className="product-image">
                                            {
                                                productSku ? <img src={`${host.avatar}${productSku?.fileImage?.path}`} alt="lip"/>
                                                : <img src={IconDefaultProduct} alt='sku-img'/>
                                            }        
                                        </div>
                                    </label>

                                </div>

                                <div style={{ lineHeight: '2em' }}>
                                    <span className="font-weight-bold">ชิ้นที่ {productIndex}</span>
                                    <div>
                                        <span className="mr-2" style={{ color: 'red', fontSize: '1rem' }}>{`฿${sku.priceNet ? sku.priceNet : '0'}`}</span> 
                                        <span style={{ textDecoration: 'line-through', fontSize: '1rem' }}> {`฿${sku.priceList ? sku.priceList : '0'}`}</span>
                                    </div>
                                    <span> มีสินค้าทั้งหมด <span style={{ color: 'orange' }}>{sku.stockRemain ? sku.stockRemain : '0'}</span> ชิ้น</span> <br />
                                </div>
                            </div>

                            {
                                optionDetail && optionDetail.map((optDetail,optDetailindex) => 
                                    <div className="d-flex flex-column sku-select-preview" key={optDetailindex}>
                                        <span>{optDetail.name}</span>
                                        <div className="color-modal-preview mt-2 mb-2 d-flex flex-wrap">
                                            {
                                                optDetail.tag && optDetail.tag.map((tag,tagIndex) => 
                                                    <React.Fragment key={tagIndex}>
                                                        {
                                                           attribute && attribute[optDetailindex] &&
                                                            <div className = {attribute[optDetailindex]?.attribute?.value === tag.name ? "preview-select-active": "preview-select-notactive"} key={tagIndex}>{tag.name}</div>
                                                        }
                                                    </React.Fragment>
                                                )
                                            }
                                        </div>
                                        {/* <span style={{ border: '1px solid orange', padding: '0.5em', margin: '0.5em' }}>140ml</span> */}
                                    </div>
                                )
                            }
                           
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

})


export default connect(mapStateToProps, null)(ModalPreview)