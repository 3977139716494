import axios from "config/axios"
import host from "config/host"

class dashboardProvider {


    getDashboardReport = async (params) => {

        const url = `${host.api}/dashboards`
        let param = new URL(url)
        if (params) {
          for (const [key, value] of Object.entries(params)) {
            param.searchParams.set(key, value);
          }
        }
        try {
          // const url = `${host.api}/categories/all`
          const res = axios.get(url + param.search)
          return res
        } catch (e) {
          throw e
        }
        // const url = `${host.api}/dashboards?fromDate=2021-12-14T00:00:00&toDate=2021-12-21T23:59:59&shopId=${shopId}`
        const res = await axios.get(url)
        return res
    }

    getOrderDashboardReport = async (type,params) => {
            const url = `${host.api}/dashboards/${type}`
            let param = new URL(url)
            if(params) {
                for(const [key,value] of Object.entries(params)) {
                    param.searchParams.set(key,value)
                }
            }

            try {
                const res = axios.get(url + param.search)
                return res
            } catch(e){
                throw e
            }
    }

    getDashboardProductTopView = async (params) => {
            /* 
            const url = `${host.api}/dashboards/summary-product-view
            ?fromDate=${'2023-08-17T17:00:00Z'}
            &toDate=${'2023-08-21T16:59:59Z'}
            &shopId=${'425'}
            &limit=${'10'}
            &page=${'1'}`
             */
            const url = `${host.api}/dashboards/summary-product-view`
            let param = new URL(url)
            if(params) {
                for(const [key,value] of Object.entries(params)) {
                    param.searchParams.set(key,value)
                }
            }

            try {
                const res = axios.get(url + param.search)
                return res
            } catch(e){
                throw e
            }
    }

}

export default new dashboardProvider()