import CheckboxComponent from 'components/Checkbox';
import { H5 } from 'components/FontStyle'
import ModalConfirmChildren from 'components/ModalConfirmChidrenHeader';
import { ButtonAdd } from 'components/output'
import PaginationComponent from 'components/pagination';
import React, { useState, useEffect } from 'react'
import 'styles/_playlists.scss';

const items = [
    {
        id: 1,
        img: 'https://image.flaticon.com/icons/png/512/1350/1350095.png',
        name: 'เฮลตี้'
    },
    {
        id: 2,
        img: 'https://image.flaticon.com/icons/png/512/1350/1350095.png',
        name: 'perfume'
    },
    {
        id: 3,
        img: 'https://image.flaticon.com/icons/png/512/1350/1350095.png',
        name: 'elec'
    }
]
const PlaylistCategories = () => {
    const [modal, setModal] = useState({ isOpen: false })
    const [categoriesSelected, setCategoriesSelected] = useState([])

    useEffect(() => {

    }, [modal.isOpen])

    const onSelectCat = (value) => {
        const findIndex = categoriesSelected.findIndex(item => item === value)
        if (findIndex === -1) {
            setCategoriesSelected([...categoriesSelected, value])
        } else {
            const data = categoriesSelected.filter((cat) => cat !== value)
            setCategoriesSelected(data)
        }
    }

    const findObject = (arr, value) => {
        const element = arr.find((e) => {
            return e === value;
        });
        return typeof element === "object" ? true : false;
    }




    return (
        <div id="playlist-categories">
            <div className="d-flex justify-content-between">
                <div>
                    <H5 secondary>รายการหมวดหมู่หลัก</H5>
                    <span className="ml-2">0 รายการ</span>
                </div>
                <ButtonAdd
                    label='เพิ่มหมวดหมู่สินค้าลงเพลย์ลิสต์'
                    handleAdd={() => setModal({ isOpen: true })}
                />
            </div>

            <div className="content">
                <div className="table-header">
                    <div>ลำดับ</div>
                    <div className="text-left">หมวดหมู่หลัก</div>
                    <div>หมวดหมู่ย่อย 2</div>
                    <div>หมวดหมู่ย่อย 3</div>
                    <div>สถานะ</div>
                </div>
                <div className="table-content">
                    <div>1</div>
                    <div className="text-left">categories</div>
                    <div>categories</div>
                    <div>categories</div>
                    <div>categories</div>
                </div>
            </div>

            <ModalConfirmChildren
                isOpen={modal.isOpen}
                labelHeader='เพิ่มหมวดหมู่หลัก'
                handleClose={() => setModal({ isOpen: false })}
                btnConfirm='เพิ่มลงเพลย์ลิส'
                btnCancel='ยกเลิก'

            >
                <div className="content">
                    <div className="header">
                        <input type="text" className="form-control" placeholder="ค้นหาหมวดหมู่" />
                    </div>
                    <div className="tr-playlist-categories">
                        <div> <span style={{ fontWeight: 100 }}>ลำดับ</span></div>
                        <div className="text-left">หมวดหมู่หลัก</div>
                        <div className="text-right">หมวดหมู่ย่อย 2</div>
                        <div className="text-right">หมวดหมู่ย่อย 3</div>
                    </div>

                    {items.map((item, idx) => (
                        <React.Fragment>
                            <div>
                                <div className="tr-playlist-content-categories">
                                    <div> <CheckboxComponent
                                        onChange={() => onSelectCat(item)}
                                        checked={findObject(categoriesSelected, item)}
                                    />
                                        <span className="ml-3">1</span>
                                    </div>
                                    <div className="text-left d-flex flex-row align-items-center">
                                        <img src={item.img} alt="img" style={{ maxWidth: '50px', border: '1px solid black' }} />
                                        <span className="ml-2">categories</span> </div>
                                    <div className="text-right">0</div>
                                    <div className="text-right">0</div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}


                    <PaginationComponent

                    />

                </div>
            </ModalConfirmChildren>
        </div >
    )
}

export default PlaylistCategories
