import CheckboxComponent from 'components/Checkbox'
import { H5 } from 'components/FontStyle'
import SwitchComponent from 'components/SwitchGreen'
import { TextInput } from 'components/input'
import React from 'react'
import '../../styles/_webhook.scss';
import { ADMIN, SUPER_ADMIN } from 'layout/Sidebar/Role'
import IconReload from 'images/icons/icon_reload.svg'


const WebHookComponent = (props) => {
    const { role, data, webHookAllow, handleChangeField, handleChangeText, getWebHookKey, handleWebHookisAllow,handleCopyToClipboard } = props
    const { shopKey = null, shopSecret = null, status: statusWebhook = 'show', url = null } = Object(data)




    return (
        <div className='web-hook-container'>


            {
                (((role === ADMIN || role === SUPER_ADMIN) || ((role !== ADMIN || role !== SUPER_ADMIN) && webHookAllow)) &&
                    <div className='d-flex flex-row justify-content-between'>
                        <H5>Webhooks Configuration</H5>
                        <div className='d-flex flex-row flex-wrap gap-1 align-items-center'>
                            {(role === ADMIN || role === SUPER_ADMIN) && <CheckboxComponent title='อนุญาตให้ใช้ Webhook' checked={webHookAllow} onChange={(handleWebHookisAllow)} />}
                            <SwitchComponent checked={statusWebhook === 'show' ? true : false} onChange={() => webHookAllow ? handleChangeField('webHook', 'status', statusWebhook === 'show' ? 'hide' : 'show') : null} /> <span className="ml-2">{statusWebhook === 'show' ? 'เปิดใช้งานอยู่' : 'ปิดใช้งานอยู่'}</span>
                        </div>
                    </div>
                )
            }

            {
                webHookAllow &&
                <>
                    <div className='d-flex flex-row justify-content-center align-items-end'>
                        <TextInput label='Shop ID' defaultValue={shopKey} disabled />
                        <div className='btn-wrap'>
                            <img src={IconReload} alt="" style={{ visibility: 'hidden' }} />
                            <button className="btn btn-cancel" onClick={() => handleCopyToClipboard(shopKey)}> คัดลอก</button>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-center align-items-end'>
                        <TextInput label='Shop secret' defaultValue={shopSecret} disabled />
                        <div className='btn-wrap'>
                            <img src={IconReload} alt="" className='cursor-pointer' onClick={getWebHookKey} />
                            <button className="btn btn-cancel" onClick={() => handleCopyToClipboard(shopSecret)}> คัดลอก</button>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-center align-items-end'>
                        <TextInput label='Webhook URL' placeholder='https://example.com' defaultValue={url} handleChangeText={(e) => { handleChangeField('webHook', 'url', e) }} />
                        {/* <button className="btn btn-confirm" onClick={() => {}}>บันทึก</button> */}
                    </div>
                </>
            }

        </div>
    )
}

export default WebHookComponent