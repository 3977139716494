import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconClose } from "images/icons/close.svg";
import classNames from "classnames";
import ProductItem from "./productItem";
import { UPDATE_SKU_CHECKED_SHOP,REMOVE_PRODUCT_CHECKED_SHOP, RESET_CHECKED_SHOP } from "redux/actions/checkedShop";
import { SET_KEY_VALUE_PROMOCODE } from "redux/actions/promocode";

import { getCheckedShop, getListCheckedShop} from "redux/sagas/selectors";
import { SET_KEY_LIST_CHECKED_SHOP } from "redux/actions/listCheckedShop";
import iconNoItem from 'images/icons/no-item.svg'

const ModalListSkuSelectedShop = (props) => {
  const { isOpen = false} = props;
  const {
    checkedShop,
    listCheckedShop,
    shopId,
    handleCloseModal = null,
    handleDeleteProduct = null,
    handleUpdateSKU = null,
    handleSubmit = null,
  } = useModalSelectedProduct();
  const HeaderTable = ["สินค้า", "ราคาขาย", "คลัง", "สถานะ", ""];
  const isDisableBtn = Object.keys(checkedShop?.shops).length === 0;


  const EmptyProduct = () => {
    return (
      <div className="cover-empty-table-product-sku">
        <img src={iconNoItem}/>
        <span
          className="text-empty"
          dangerouslySetInnerHTML={{
            __html: `ไม่มีรายการ`,
          }}
        ></span>
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} className="modal-list-sku-selected-product" centered={true}>
      <ModalHeader className="header-modal-list-sku-selected-product">
        <div className="cover-header-modal-list-sku-selected-product">
          <div>
            <span className="title-selected-product">
              {`ร้านค้า ${checkedShop?.shops[shopId]?.name}`}
            </span>
            <span className="subTitle-selected-product">
              (เลือกแล้ว{" "}
              <span className="text-number-highlight">
                {checkedShop?.shops[shopId]?.count || 0}
              </span>{" "}
              สินค้า |{" "}
              <span className="text-number-highlight">
                {checkedShop?.shops[shopId]?.sku|| 0}
              </span>{" "}
              SKU)
            </span>
          </div>
          <div>
            <IconClose
              className="icon-close-modal-list-sku-selected-product"
              onClick={() => handleCloseModal()}
            />
          </div>
        </div>
      </ModalHeader>

      <ModalBody className="cover-body-modal-list-sku-selected-product">
        <div className="cover-header-table-product-sku">
          {HeaderTable.map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </div>

        {/* ======== body table ======= */}
        {(listCheckedShop?.listCheckedShopOld.shops !== undefined && shopId !== '') &&
          Object.keys(listCheckedShop?.listCheckedShopOld?.shops).length > 0 ? (  
            <div className="cover-body-table-product-sku">
        
                {
                checkedShop?.shops[shopId]?.allProduct === true ? (
                  Object.values(listCheckedShop?.listCheckedShopOld?.shops[shopId]).map((product) => (
                    <ProductItem
                      key={`platform-product-${product.productId}`}
                      product={product}
                      shopId={shopId}
                      count={checkedShop?.shops[shopId]?.count}
                      handleUpdateSKU={(data) => handleUpdateSKU(data)}
                      handleDeleteProduct={(data) => handleDeleteProduct(data)}
                    />
                  ))
                ) : (
                  Object.values(listCheckedShop?.listCheckedShopOld?.shops[shopId]).map((product) => {
                    if (checkedShop?.shops[shopId]?.product[product?.productId] === undefined) {
                      return null;
                    }
                    return (
                      <ProductItem
                        key={`platform-product-${product.productId}`}
                        product={product}
                        shopId={shopId}
                        count={checkedShop?.shops[shopId]?.count}
                        handleUpdateSKU={(data) => handleUpdateSKU(data)}
                        handleDeleteProduct={(data) => handleDeleteProduct(data)}
                      />
                    );
                  })
                )
              }

            </div>
          ):(
            <EmptyProduct />
          )}
      </ModalBody>

      <ModalFooter className="cover-footer-modal-list-sku-selected-product">
        <button
          className={classNames("btn-cancel", isDisableBtn && "disable-btn")}
          disabled={isDisableBtn}
          onClick={() => handleCloseModal()}
        >
          ยกเลิก
        </button>
        <button
          className={classNames("btn-submit", isDisableBtn && "disable-btn")}
          disabled={isDisableBtn}
          onClick={() => handleSubmit(shopId)}
        >
          ตกลง
        </button>
      </ModalFooter>
    </Modal>
  );
};

const useModalSelectedProduct = () => {
  const checkedShop = useSelector(getCheckedShop);
  const listCheckedShop = useSelector(getListCheckedShop);

  const shopId =
    Object.keys(checkedShop?.shops).length > 0
      ? Object.keys(checkedShop?.shops)[0]
      : ''; 
  
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch({
      type: SET_KEY_VALUE_PROMOCODE,
      payload: {
        key: "isShowModalListSkuSelectedShop",
        value: false,
      },
    });
    dispatch({
      type: SET_KEY_LIST_CHECKED_SHOP,
      payload: {
        key: "listCheckedShopOld",
        value: { shops: {}  }
      }
    });
    dispatch({
      type: RESET_CHECKED_SHOP
    })
  };

  const handleDeleteProduct = (data = null) => {
    const { shopId, productId } = data
    const payload = {
      [shopId]: {
        [productId]: null
      }
    }
    dispatch({
      type: REMOVE_PRODUCT_CHECKED_SHOP,
      payload: payload
    })
  };
  
  const handleUpdateSKU = (data = {}) => {
    const { shopId, productId, skuID, checked } = data
    const payload = {
      [shopId]: {
        [productId]: {
          [skuID]: checked
        }
      }
    }

    dispatch({
      type: UPDATE_SKU_CHECKED_SHOP,
      payload: payload
    });
  };

  const handleSubmit = (shopId) => {
    const payload = {shopId : shopId}
    dispatch({
      type:'SELECTED_PRODUCT_IN_SHOP',
      payload: payload
    })
  };

  return {
    checkedShop,
    listCheckedShop,
    shopId,
    handleCloseModal,
    handleDeleteProduct,
    handleUpdateSKU,
    handleSubmit,
  };
};

export default ModalListSkuSelectedShop;
