import axios from "config/axios";
import host from "config/host";

class promotionProvider {
  getListPromotion = async (params) => {
    const url = `${host.api}/promotions`;
    let param = new URL(url);
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        param.searchParams.set(key, value);
      }
    }
    try {
      const res = axios.get(url + param.search);
      return res;
    } catch (e) {
      throw e;
    }
  };

  deletePromotion = async (idPromotion) => {
    try {
      const url = `${host.api}/promotions/${idPromotion}`;
      const res = axios.delete(url);
      return res;
    } catch (error) {
      throw error.response.data;
    }
  };

  addPromotion = async (params) => {
    try {
      const url = `${host.api}/promotions`;
      const res = await axios.post(url, params);
      return res;
    } catch (error) {
      // if (error.response)
      //     throw error.response.data
      throw error;
    }
  };

  updatePromotion = async (params) => {
    try {
      const url = `${host.api}/promotions/${params.id}`;
      const res = await axios.patch(url, params.body);
      return res;
    } catch (error) {
      throw error;
    }
  };

  getPromotionDetail = async (params) => {
    try {
      const url = `${host.api}/promotions/${params.id}`;
      const res = await axios.get(url);
      return res;
    } catch (error) {
      throw error;
    }
  };
}

export default new promotionProvider();
