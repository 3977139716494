import classNames from 'classnames'
import DatePickerInputComponent from 'components/DatePickerInput'
import { Body1, Body2, Caption, H5, H6, SubCaption } from 'components/FontStyle'
import { ButtonUpload } from 'components/output'
import ButtonUploadWithoutAlbum from 'components/output/ButtonUploadWithoutAlbum'
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import './styles.scss'
import TimePicker from 'components/Timepicker'
import moment from 'moment'
import { decimal2point, decimalToInteger, formatNumber, getLocalStorage, getUserRole, isNumber } from 'helper/utils'
import fileProvider from 'provider/fileProvider'
import { useDispatch } from 'react-redux'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert'
import withdrawProvider from 'provider/withdrawProvider'
import { dispatchNotification, OPEN_TOAST } from 'redux/actions/notification'
import { displayDateThShortYear } from 'helper/timeUtil'
import host from 'config/host'
import { ReactComponent as IconClose } from '../../images/icons/close.svg'
import { ACCOUNTANT, ADMIN, OWNER, SUPER_ADMIN } from 'layout/Sidebar/Role'
import numeral from 'numeral'
import InputNumber from 'components/InputNumber'
import InputText from 'components/InputText'


const ModalTransferConfirm = (props) => {
    const { setmodalDetail, modalDetail, getListTransfer,setWithdrawSelect,setSumWithdrawSelect,getAllWithdraw } = props
    const width = '10em'
    const height = '10em'

    const [date, setDate] = useState(moment())
    const [time, setTime] = useState(moment())
    const [amount, setAmount] = useState(0)
    const [image, setImage] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)
    const [error, setError] = useState({})
    const [currentData, setCurrentData] = useState(null)

    const dispatch = useDispatch()


    useEffect(() => {
        // const dataConfirmTransfer = getLocalStorage({key:'confirmTransfer'})
        // console.log('dataConfirmTransfer', dataConfirmTransfer)
        // setCurrentData(dataConfirmTransfer)
        // console.log('modalDetail.data?.attachment[modalDetail.data?.attachment?.length - 1] ', modalDetail.data?.attachmentmodalDetail.data?.attachment[modalDetail.data?.attachment?.length - 1] )
        const currentAttachment = modalDetail.data?.attachment[modalDetail.data?.attachment?.length - 1]
        // modalDetail.data?.sellerWithdraw?.attachment[modalDetail.data?.sellerWithdraw?.attachment?.length - 1] || []
        const { data } = modalDetail
        if (data?.attachment.length > 0) {
            setDate(moment(currentAttachment?.transferredDate))
            setTime(moment(currentAttachment?.transferredDate))
            setAmount(currentAttachment?.totalAmount)
            setImagePreview(currentAttachment?.attachmentFile.length > 0 ? currentAttachment?.attachmentFile[0] : null)
            setCurrentData(currentAttachment)
        }

        return () => {
            cleanUp()
        }
    }, [modalDetail])


    // useEffect(() => {
    //     cleanUp()
    // }, [!modalDetail.isOpen])

    const cleanUp = () => {
        setDate(moment())
        setTime(moment())
        setAmount(null)
        setImage(null)
        setImagePreview(null)
        setError({})
        setCurrentData(null)
    }


    const handleChangeImage = (image) => {
        setImage(image)
    }

    const onDateDatePickerInputChange = (date) => {
        setDate(date)
    }

    const onTimePickerChange = (time) => {
        setTime(time)
    }

    const onSetTransferAmount = (name, value) => {
        setAmount(value.floatValue)
    }

    const handleSubmit = async () => {
        // console.log('currentData', currentData)
        let attachmentFile
        if (image) {
            try {
                let uploadImage = await fileProvider.uploadImageOnce(image?.file)
                attachmentFile = uploadImage
            } catch (error) {
                console.log('error', error)
                dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'อัพโหลดรูปภาพไม่สำเร็จ', type: 'alert' } }))
                return
            }
        }

        const dateFormat = moment(date).format('MM-DD-YYYY')
        const timeFormat = moment(time).format('HH:mm')
        //// แปลง format date + time
        const dateTimeFormat = moment(dateFormat + ' ' + timeFormat)
        const params = {
            transferredDate: dateTimeFormat,
            totalAmount: amount,
            attachmentFile: attachmentFile?.fileName ? `image/${attachmentFile?.fileName}` : null,
            id: modalDetail?.data?.id
        }

        const validate = validateForm({ totalAmount: params.totalAmount, date: dateFormat, time: timeFormat })
        if (validate) {
            try {
                if (Array.isArray(modalDetail.data.id)) {
                    handleCreateMultiTransfer(params)
                } else {
                    handleCreateTransfer(params)
                }

            } catch (error) {
                console.log('error', error)
                dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'ทำรายการผิดพลาด', type: 'alert' } }))
            }
        }
    }

    const handleCreateTransfer = async (params) => {
        try {
            const res = await withdrawProvider.createTransferSellerById(params)
            // const res = {status: 'success'}
            if (res.status === 'success') {
                // dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'ยืนยันการโอนเงินสำเร็จ', type: 'success' } }))
                dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } }))
                setmodalDetail({ isOpen: false, data: null })
                setWithdrawSelect([])
                setSumWithdrawSelect(0)
                await getListTransfer()
                await getAllWithdraw()
            }
        } catch (error) {
            throw error
        }
    }

    const handleCreateMultiTransfer = async (params) => {
        try {
            const res = await withdrawProvider.createMultiTransferSellerById(params)
            // const res = {status: 'success'}
            if (res.status === 'success') {
                // dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'ยืนยันการโอนเงินสำเร็จ', type: 'success' } }))
                dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } }))
                setmodalDetail({ isOpen: false, data: null })
                setWithdrawSelect([])
                setSumWithdrawSelect(0)
                await getListTransfer()
                await getAllWithdraw()
            }
        } catch (error) {
            throw error
        }
    }

    const validateForm = (data) => {
        let error = {}
        if (!data.date) {
            error = {
                ...error,
                date: 'กรุณากรอกวันที่'
            }
        }
        if (!data.time) {
            error = {
                ...error,
                time: 'กรุณากรอกเวลา'
            }
        }

        const isFloat = /[+-]?([0-9]*[.])?[0-9]+/
        if (!data.totalAmount || data.toString().trim().length === 0) {
            error = {
                ...error,
                totalAmount: 'กรุณากรอกจำนวนเงินที่โอน'
            }
        }
        if (data.totalAmount !== parseFloat(modalDetail?.data?.totalRevenue.toFixed(2))) {
            error = {
                ...error,
                totalAmount: 'ยอดเงินไม่ตรงกับยอดโอน'
            }
        }


        if (!isFloat.test(data.totalAmount) || isNaN(data.totalAmount)) {
            error = {
                ...error,
                totalAmount: 'กรุณารูปแบบตัวเลขให้ถูกต้อง'
            }
        }

        // }
        if (Object.entries(error).length > 0) {
            setError(error)
            console.log('error', error)
            return false
        }


        setError({})
        return true
    }

    const closeModal = () => {
        setmodalDetail({ isOpen: false, data: null })
    }

    const checkPermWithdraw = () => {
        if (getUserRole() === ACCOUNTANT) return true
        return false
    }

    return (
        <React.Fragment>
            {/* <Button color="danger" onClick={() => this.setState({ modal: true })}>{'modalconfirm'}</Button> */}
            <Modal isOpen={modalDetail.isOpen} toggle={closeModal} modalClassName="modal-transfer-confirm" size='xl'>
                <ModalHeader toggle={closeModal}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <H5>ยืนยันการโอนเงิน</H5>
                        <div className='d-flex gap-2 align-items-center'>
                            {modalDetail?.data?.totalRevenue && modalDetail.data.status !== 'transferred' && `ยอดเงินที่ต้องโอน ${numeral(modalDetail?.data?.totalRevenue).format('#,0.00')} บาท`}
                            <IconClose onClick={closeModal} className='cursor-pointer' />
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className='bg-white p-4'>
                        <H5>กรุณาระบุข้อมูล</H5>

                        <div className='d-flex flex-row justify-content-between' style={{ gap: '10px' }}>
                            <div className='w-50 d-flex flex-column'>
                                <SubCaption className='require'>วันที่โอน</SubCaption>
                                {currentData ?
                                    <Body2>{displayDateThShortYear(date, 'DD/MM/YYYY')}</Body2> :
                                    <DatePickerInputComponent
                                        onSubmitDateSelected={onDateDatePickerInputChange}
                                        allowpreviousDate={true} /// this allowpreviousDate control previousDate on/off
                                        dissableDateFuture={moment()}
                                        date={date}
                                    />
                                }
                                <span className='text-danger'>{error?.date}</span>
                            </div>

                            <div className='w-50 d-flex flex-column'>
                                <SubCaption className='require'>เวลาที่โอน</SubCaption>
                                {currentData ?
                                    <Body2>{displayDateThShortYear(date, 'HH:mm')}</Body2> :
                                    <TimePicker
                                        onTimePickerChange={onTimePickerChange}
                                        value={time}
                                    />
                                }

                                <span className='text-danger'>{error?.time}</span>
                            </div>
                        </div>

                        <div className='w-100 d-flex flex-column mt-2'>
                            <SubCaption className='require'>จำนวนเงินที่โอน </SubCaption>
                            {currentData ? <Body2>{numeral(amount).format('0,0.00')}</Body2> :
                                // <input className='form-control' type='text'
                                //     value={amount} onChange={(e) => onSetTransferAmount(e)} />
                                <InputNumber
                                    className='form-control'
                                    placeholder={'0.00'}
                                    type={'DECIMAL'}
                                    onChange={(value) => onSetTransferAmount("payment", value)}
                                    // onValueChange={(value) => onSetTransferAmount("payment", value)}
                                    value={amount}
                                    decimalScale={2}
                                />

                                // <InputText
                                //     type={'number'}
                                //     // className={'form-control'}
                                //     onChange={(value) => onSetTransferAmount("payment", value)}
                                //     placeholder={'0.00'}
                                // />

                            }
                            <span className='text-danger'>{error?.totalAmount}</span>
                        </div>

                        <div>

                            <div className='d-flex flex-column mt-4'>
                                {checkPermWithdraw() ? (
                                    <React.Fragment>
                                        <Body1>สลิปการโอนเงิน <span className='color-grey'> (ไม่จำเป็น)</span></Body1>
                                        {imagePreview ?
                                            <img src={`${host.image}/${imagePreview}`} alt='attachment' style={{ maxWidth: width, maxHeight: height }} /> :
                                            <ButtonUploadWithoutAlbum
                                                width={width}
                                                height={height}
                                                handleChangeImage={(image) => handleChangeImage(image)}
                                            // disabled={!(getUserRole() === ADMIN || getUserRole() === SUPER_ADMIN || getUserRole() === ACCOUNTANT)}
                                            />
                                        }
                                    </React.Fragment>
                                ) : imagePreview ?
                                    <React.Fragment>  <Body1>สลิปการโอนเงิน <span className='color-grey'> (ไม่จำเป็น)</span></Body1>
                                        <img src={`${host.image}/${imagePreview}`} alt='attachment' style={{ maxWidth: width, maxHeight: height }} />
                                    </React.Fragment> : null
                                }

                            </div>

                        </div>

                        {(checkPermWithdraw() && !imagePreview) &&
                            <ModalFooter className='mt-4 justify-content-center'>
                                <span><button className="btn-cancel" onClick={closeModal}><Caption>{'ยกเลิก'}</Caption></button></span>
                                <span><button className="btn-submit" onClick={() => handleSubmit()} disabled={false}><Caption>{'โอนเงินให้ร้านค้า'}</Caption></button></span>
                            </ModalFooter>
                        }



                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ModalTransferConfirm