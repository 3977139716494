
import React, {Component} from 'react';
import NumberFormat from 'react-number-format';
// import InputNumberCommon from '../InputNumberCommon'
import './index.scss';

class InputNumberComponent extends Component {

    // constructor(props) {
    //     super(props)
    // }

    componentDidMount = () => {
       
    }

    componentWillReceiveProps = nextProps => {
       
    }


    onChange = value => {
        this.props.onChange(value, this.props.name, this.props.params);
    }

    onFocus = () => {
        if(this.props.onFocus)
           this.props.onFocus(this.props.name)
    }

    onBlur = () => {
        if(this.props.onBlur)
           this.props.onBlur()
    }
    
    render() {
        
        const {type,placeholder,name,className,value, disabled=false,decimalScale} = this.props;
        // type: DECIMAL, CURRENCY, COMMON

        if (type === 'DECIMAL') {
            return ( <React.Fragment>
                <NumberFormat value={value}
                              thousandSeparator={true} 
                              name={name}
                              onFocus={this.onFocus}
                              className={`${className} ${disabled === true ? 'disabled' : ''}` }
                              placeholder={placeholder}
                              fixedDecimalScale={true}
                              onValueChange={value => this.onChange(value)}
                              disabled={disabled}
                              decimalScale={decimalScale}
                              />
            </React.Fragment>) 

        } else if (type === 'COMMON') {
            return ( <React.Fragment>
                <NumberFormat value={value}
                              thousandSeparator={false} 
                              name={name}
                              onFocus={this.onFocus}
                              className={`${className} ${disabled === true ? 'disabled' : ''}` }
                              placeholder={placeholder}
                              fixedDecimalScale={false}
                              onValueChange={value => this.onChange(value)}
                              disabled={disabled}
                              />
            </React.Fragment>) 

        } else {

        return ( <React.Fragment>
            <NumberFormat value={value}
                          thousandSeparator={true} 
                          name={name}
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          className={`${className} ${disabled === true ? 'disabled' : ''}` }
                          placeholder={placeholder}
                          fixedDecimalScale={type === 'CURRENCY'}
                          onValueChange={value => this.onChange(value)}
                          decimalScale={type === 'CURRENCY' ? 2 : 0}
                          disabled={disabled}
                          />
        </React.Fragment>) 
        }

    }


}

export default InputNumberComponent;

