import React, { useEffect, useState } from 'react'
import './styles.scss'
import { ReactComponent as IconCamera } from 'images/icons/iconcamera.svg'
import classnames from 'classnames'
import { detailButton } from 'components/Gallery/constant'
import { ReactComponent as IconAlbum } from 'images/icons/icon-camera.svg'
import ImageComponent from 'components/ImageComponent'

const ButtonUploadWithoutAlbum = (props) => {
  const { width, height, handleChangeImage, exImage, closeButton, validateWidthHeight
    , addTypeImage = []
    // ,disabled 
  } = props

  const imageInputRef = React.useRef()
  const [image, setImage] = useState({ file: null, preview: null })

  const typeImage = !!addTypeImage ? addTypeImage : []
  const checkType = ['image/x-png', 'image/jpeg', 'image/png', 'image/gif'].concat(typeImage)
  const acceptTypeImage = checkType?.join(", ") // accept='image/x-png, image/gif, image/jpeg, image/png'

  useEffect(() => {
    if (exImage) {
      setImage(exImage)
      handleChangeImage(exImage)
    }
  }, [exImage])

  const handleChange = (e) => {

    if (e.target.files.length && checkType.includes(e.target.files[0].type)) {
      if (validateWidthHeight) {
        validateDimesionWidthHeight(e.target.files[0])
      } else {
        onInsertImage(e.target.files[0])
      }
    }
  }

  const onInsertImage = (file) => {
    setImage({ file: file, preview: URL.createObjectURL(file) })
    handleChangeImage({ file: file, preview: URL.createObjectURL(file) })
  }

  const onRemoveImage = () => {
    setImage({ file: null, preview: null, type: 'delete' })
    handleChangeImage({ file: null, preview: null, type: 'delete' })

    if (imageInputRef.current) {
      imageInputRef.current.value = '' ///////// Clear value ที่ค้างอยู่ทำให้ Upload ค่าเดิมไม่ได้
    }
  }

  const validateDimesionWidthHeight = (file) => {
    let image = new Image()
    let validate = false

    image.src = URL.createObjectURL(file)

    image.onload = () => {
      let height = image.height
      let width = image.width

      if (validateWidthHeight.type == "lower") {
        if (validateWidthHeight.height < height || validateWidthHeight.width < width) {
          alert(`ขนาดรูปภาพต้องเป็น ${validateWidthHeight.width}x${validateWidthHeight.height} หรือต่ำกว่า`)
          onRemoveImage()
        } else {
          validate = true
        }
      } else if (validateWidthHeight.type == "heigher") {
        if (validateWidthHeight.height > height || validateWidthHeight.width > width) {
          alert(`ขนาดรูปภาพต้องเป็น ${validateWidthHeight.width}x${validateWidthHeight.height} หรือมากกว่า`)
          onRemoveImage()
        } else { validate = true }
      } else if (validateWidthHeight.type == "equal") {
        if (height != width) {
          alert(`รองรับไฟล์ 1:1 นามสกุล jpg หรือ png  ขนาดที่แนะนำ 512x512 px`)
          onRemoveImage()
        } else { validate = true }
      }

      if (validate) {
        onInsertImage(file)
      } else {
        onRemoveImage()
      }
    }
  }


  return (
    <div className='btn-upload-without-album'>
      {
        image.preview ?
          <div className='media-card-box mt-3' style={{ width: width, height: height }}>
            {
              closeButton ?
                <div className='close-box' onClick={onRemoveImage}> X </div> : null
            }

            <ImageComponent alt='' className='img-media-card' src={image.preview} />
          </div>
          :
          <div
            className={`button-upload-media-box cursor-pointer btn-upload-img`}
            style={{ width: width, height: height, paddingLeft: 0 }}
          >
            <div
              className={classnames('button-upload-media', {
                disabled: false,
              })}
            >
              <IconAlbum className='icon-album' />
              <div className='title'>{detailButton['image'].title}</div>
              <input
                type='file'
                ref={imageInputRef}
                onChange={handleChange}
                className='input-file'
                // accept='image/x-png, image/gif, image/jpeg, image/png'
                accept={acceptTypeImage}
              />
            </div>
          </div>
      }
    </div>
  )
}

ButtonUploadWithoutAlbum.defaultProps = {
  width: '10px',
  height: '10px',
  handleChangeImage: () => { },
  exImage: null,
  closeButton: true,
}

export default ButtonUploadWithoutAlbum