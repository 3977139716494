// import { difference } from 'lodash';
import moment from 'moment'
import momentTZ from 'moment-timezone'


 moment.defineLocale('th', {
    months : 'มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤศจิกายน_ธันวาคม'.split('_'),
    monthsShort : 'ม.ค._ก.พ._มี.ค._เม.ย._พ.ค._มิ.ย._ก.ค._ส.ค._ก.ย._ต.ค._พ.ย._ธ.ค.'.split('_'),
    monthsParseExact: true,
    weekdays : 'อาทิตย์_จันทร์_อังคาร_พุธ_พฤหัสบดี_ศุกร์_เสาร์'.split('_'),
    weekdaysShort : 'อาทิตย์_จันทร์_อังคาร_พุธ_พฤหัส_ศุกร์_เสาร์'.split('_'), // yes, three characters difference
    weekdaysMin : 'อา._จ._อ._พ._พฤ._ศ._ส.'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'H:mm',
        LTS : 'H:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY เวลา H:mm',
        LLLL : 'วันddddที่ D MMMM YYYY เวลา H:mm',
    },
    meridiemParse: /ก่อนเที่ยง|หลังเที่ยง/,
    isPM: function (input) {
        return input === 'หลังเที่ยง';
    },
    meridiem : function (hour, minute, isLower) {
        if (hour < 12) {
            return 'ก่อนเที่ยง';
        } else {
            return 'หลังเที่ยง';
        }
    },
    calendar : {
        sameDay : '[วันนี้ เวลา] LT',
        nextDay : '[พรุ่งนี้ เวลา] LT',
        nextWeek : 'dddd[หน้า เวลา] LT',
        lastDay : '[เมื่อวานนี้ เวลา] LT',
        lastWeek : '[วัน]dddd[ที่แล้ว เวลา] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'อีก %s',
        past : '%sที่แล้ว',
        s : 'ไม่กี่วินาที',
        m : '1 นาที',
        mm : '%d นาที',
        h : '1 ชั่วโมง',
        hh : '%d ชั่วโมง',
        d : '1 วัน',
        dd : '%d วัน',
        M : '1 เดือน',
        MM : '%d เดือน',
        y : '1 ปี',
        yy : '%d ปี'
    }
});

const momentTH = (date,timeList ,dateIndex) => {
    


   const  deliveryDate  = moment(date).add(543,"years").format('D MMM YY')
   let  returnResult = []
    // eslint-disable-next-line array-callback-return
    timeList.map(( time, idx  ) => {
        const  tz = momentTZ(time).tz("Asia/Bangkok").format()
        try {

        returnResult.push({
            date :  deliveryDate ,
            selectDate :  moment(date).format('YYYY-MM-DD'),
            time :  tz.split("T")[1].substring(0, 5),
            dateServer :  date ,
            timeServer : time.split("T")[1].substring(0, 5),
            isChecked :  dateIndex === 0 && idx === 0 ? true : false,
            orderDateDelivery : moment(date).format('YYYY-MM-DD')+"T"+time.split("T")[1]
            
        })
       }catch(ex){
           
       }

    })


   return returnResult

}

const durationFormat = seconds => {
    let date = new Date(0);
    date.setSeconds(seconds); 
    return date.toISOString().substr(11, 8);
}

const  displayDateTh = (date , format = "dd D MMM YYYY รอบ H:mm") => {
    const  dateTh  = moment(date).format(format)

    const yearEn = moment(date).year()
     const  yearTh = moment(date).year()+543
   
     const res  = dateTh.replace(yearEn ,yearTh)
    return res
    
}

const displayDateThShortYear = (date,format = "DD MMM YY") => {
    const dateTh = moment(date).add(543,'y').format(format)
    if(dateTh === 'Invalid date') return null
    return dateTh
}

//format 'ส 18 ต.ค. 62'
const changeFormatDate = (date) => {
  const daysOfWeek = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."]
  const monthsOfYear = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."]
  const monthDelivery = monthsOfYear[moment(date).month()]
  const year = parseInt(moment(date).format('YYYY')) + 543
  const weekDay = daysOfWeek[moment(date).day()]
  const dateDelivery = moment(date).format('DD')
  return weekDay + " " + dateDelivery + " " + monthDelivery + " " + year.toString().slice(-2)
}

const changeFormatTime = (date) => moment(date).format('HH:mm')

const convertTimeStampToFormant = (time) => {
  const day = changeFormatDateNotDay(time)
  return day + ', ' + moment(time).format('HH:mm')
}

const changeFormatDateNotDay = (date) => {
  const monthsOfYear = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
  ]

  const monthDelivery = monthsOfYear[moment(date).month()]
  const year = parseInt(moment(date).format('YYYY')) + 543
  const dateDelivery = moment(date).format('DD')
  return dateDelivery + ' ' + monthDelivery + ' ' + year.toString().slice(-2)
}

const differenceDate = (date1, date2) => {
    const now = moment(date1).startOf('day') //todays date
    const end = moment(date2).startOf('day') // another date
    return now.diff(end, 'days');
}



export {
  momentTH,
  displayDateTh,
  displayDateThShortYear,
  convertTimeStampToFormant,
  changeFormatDate,
  changeFormatTime,
  durationFormat,
  differenceDate
}