import moment from "moment"
import { SET_FORM_CREATE_BROADCAST, CLEAR_FORM_DATA_BROADCAST, OPEN_MODAL_PREVIEW_BROADCAST, CLOSE_MODAL_PREVIEW_BROADCAST } from "redux/actions/broadcast"

const initialState = {
    formData: {
        date: new Date(),
        time: moment()
    },
    modalPreview: {
        isOpen: false
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case SET_FORM_CREATE_BROADCAST: {
            return {
                ...state,
                formData: {
                    ...state.formData,
                    [payload.key]: payload.value
                }
            }
        }
        case CLEAR_FORM_DATA_BROADCAST: {
            return {
                ...state,
                formData: {}
            }
        }
        case OPEN_MODAL_PREVIEW_BROADCAST: {
            return {
                ...state,
                modalPreview: {
                    isOpen: true
                }
            }
        }
        case CLOSE_MODAL_PREVIEW_BROADCAST: {
            return {
                ...state,
                modalPreview: {
                    isOpen: false
                }
            }
        }
        default: {
            return state
        }
    }
}