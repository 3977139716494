/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import classnames from 'classnames'
import ReactTags from 'react-tag-autocomplete'
import { ButtonUpload } from 'components/output'
import { Body2, H5 } from 'components/FontStyle'
import IconShopAvatar from 'images/icons/default-shop-logo.svg'
import host from 'config/host'

const reactTagBaseClass = {
    rootFocused: 'is-focused',
    selected: 'react-tags__selected',
    selectedTag: 'react-tags__selected-tag',
    selectedTagName: 'react-tags__selected-tag-name',
    search: 'react-tags__search',
    searchWrapper: 'react-tags__search-wrapper',
    searchInput: 'react-tags__search-input',
    suggestions: 'react-tags__suggestions',
    suggestionActive: 'is-active',
    suggestionDisabled: 'is-disabled',
}
const reactTagClass = {
    root: 'react-tags',
    ...reactTagBaseClass,
}



const CreateSEO = (props) => {
    const { handleChangeSEO, seo, modal, setKeyValue, errors } = props
    const { data, isOpen } = modal

    useEffect(() => {
        // data.seo = from shop
        // seo = state
        if (data?.seo !== seo) {
            const dataSEO = []
            if (data?.seo?.keyword?.length > 0) {
                // eslint-disable-next-line array-callback-return
                data.seo && data.seo.keyword.map((item, index) => {
                    dataSEO.push({ id: index, name: item })
                })
            }
            setKeyValue('seo', { ...data.seo, keyword: dataSEO })
            
        }
    }, [])

    useEffect(() => {
        if (!isOpen) {
            setKeyValue('seo', null)
            setKeyValue('errors', {})
        }
    }, [isOpen])

    const onAddition = (tag) => {
        if (seo?.keyword.length === 0) {
            handleChangeSEO([{ id: 1, name: tag.name }], 'keyword', 'tag')
        } else {
            const tags = [].concat(seo?.keyword, { id: seo?.keyword[seo.keyword.length - 1].id + 1, name: tag.name })
            handleChangeSEO(tags, 'keyword', 'tag')
        }
    }

    const onDelete = (i) => {
        const tags = seo.keyword.slice(0)
        tags.splice(i, 1)
        handleChangeSEO(tags, 'keyword', 'tag')
    }

    const replaceWhiteSpace = (e) => {
        e.target.value = e.target.value.replace(' ', '-')
    }

    const checkSeoTitle = () => {
        let textShow = ''
        if (document.querySelector('.progressTitle')) {
            document.querySelector('.progressTitle').style.width = seo?.title?.length + 'px'
            document.querySelector('.progressTitle').style.maxWidth = '160px'
            document.querySelector('.progressTitle').style.height = '0px'
            document.querySelector('.seoTitle').style.fontSize = '16px'

            if (seo?.title?.length <= 24) {
                textShow = 'สั้นเกินไป'
                document.querySelector('.progressTitle').style.background = 'red'
                document.querySelector('.seoTitle').style.color = 'red'
            } else if (seo?.title?.length <= 34) {
                textShow = 'สั้นไป'
                document.querySelector('.progressTitle').style.background = 'yellow'
                document.querySelector('.seoTitle').style.color = 'yellow'
            } else if (seo?.title?.length <= 65) {
                textShow = 'ดี'
                document.querySelector('.progressTitle').style.background = 'green'
                document.querySelector('.seoTitle').style.color = 'green'
            } else if (seo?.title?.length <= 75) {
                textShow = 'ยาวไป'
                document.querySelector('.progressTitle').style.background = 'yellow'
                document.querySelector('.seoTitle').style.color = 'yellow'
            } else if (seo?.title?.length >= 76) {
                textShow = 'ยาวเกินไป'
                document.querySelector('.progressTitle').style.background = 'red'
                document.querySelector('.seoTitle').style.color = 'red'
            }
        }

        return (
            <div>
                <div className="seoTitle"><span style={{ color: '#000' }}>{seo?.title?.length} ตัวอักษร </span>({textShow})</div>
                <div className="containerTitle">
                    <div className="progressTitle"></div>
                </div>
            </div>
        )
    }

    const checkSeoDescription = () => {
        let textShow = ''

        if (document.querySelector('.progressDes')) {
            document.querySelector('.progressDes').style.width = (seo?.description?.length / 4) + 'px'
            document.querySelector('.progressDes').style.maxWidth = '160px'
            document.querySelector('.progressDes').style.height = '0px'
            document.querySelector('.seoDescription').style.fontSize = '16px'

            if (seo?.description?.length <= 44) {
                textShow = 'สั้นเกินไป'
                document.querySelector('.progressDes').style.background = 'red'
                document.querySelector('.seoDescription').style.color = 'red'
            } else if (seo?.description?.length <= 62) {
                textShow = 'สั้นไป'
                document.querySelector('.progressDes').style.background = 'yellow'
                document.querySelector('.seoDescription').style.color = 'yellow'
            } else if (seo?.description?.length <= 250) {
                textShow = 'ดี'
                document.querySelector('.progressDes').style.background = 'green'
                document.querySelector('.seoDescription').style.color = 'green'
            } else if (seo?.description?.length <= 320) {
                textShow = 'ยาวไป'
                document.querySelector('.progressDes').style.background = 'yellow'
                document.querySelector('.seoDescription').style.color = 'yellow'
            } else if (seo?.description?.length >= 321) {
                textShow = 'ยาวเกินไป'
                document.querySelector('.progressDes').style.background = 'red'
                document.querySelector('.seoDescription').style.color = 'red'
            }

        }
        return (
            <div>
                <div className="seoDescription" ><span style={{ color: '#000' }}>{seo?.description?.length} ตัวอักษร </span>({textShow}) </div>
                <div className="containerDes">
                    <div className="progressDes"></div>
                </div>
            </div>
        )
    }


    return (
        <div>
            <div className='mt-4 bg-white'>
                <div className='ml-10per pt-4 pb-4 col-10'>
                    <H5>จัดการ SEO</H5>
                    <div className='d-flex justify-content-between align-items-end'>
                        {/* <span>Meta Title <span className="text-danger">*</span> (20 ตัวอักษรขึ้นไป) </span>
                                    <input type="text" className="form-control" onChange={(e) => this.props.dispatch(dispatchShop({ type: ADD_SEO_IN_PRODUCT, payload: { key: 'title', value: e.target.value } }))} /> */}

                        <div>
                            <Body2 className="text-darkgrey2">
                                Meta Title
                            </Body2>
                            <span
                                className={classnames(`text-danger d-none`, {
                                    'd-inline': errors?.title,
                                })}
                            >
                                กรุณากรอก Title SEO
                            </span>
                        </div>
                        <span>{checkSeoTitle()}</span>
                    </div>
                    <input
                        type='text'
                        className={`input-form ${errors?.title ? 'error-field' : ''
                            }`}
                        value={seo?.title}
                        name='title_description'
                        onChange={(e) => handleChangeSEO(e, 'title')}
                    />

                    <div className="mb-2">
                        <div className='d-flex justify-content-between align-items-end'>
                            <div>
                                <Body2 className="text-darkgrey2"> Meta Description</Body2>
                                <span
                                    className={classnames(`text-danger d-none`, {
                                        'd-inline': errors?.description,
                                    })}
                                >
                                    กรุณากรอกรายละเอียด SEO
                                </span>
                            </div>
                            <span> {checkSeoDescription()}</span>
                        </div>
                        {/* <span>Meta Description <span className="text-danger">*</span> </span> */}
                        {/* <textarea type="text" className="form-control" style={{ height: "10em", resize: 'none' }} onChange={(e) => this.props.dispatch(dispatchShop({ type: ADD_SEO_IN_PRODUCT, payload: { key: 'description', value: e.target.value } }))} /> */}
                        <textarea
                            type='text'
                            name='meta-description'
                            className={`input-form ${errors?.description ? 'error-field' : ''
                                }`}
                            value={seo?.description}
                            style={{ height: '10em', resize: 'none' }}
                            onChange={(e) => handleChangeSEO(e, 'description')}
                        />
                    </div>

                    <div className="mb-2">
                        <Body2 className="text-darkgrey2">Keyword </Body2>
                        <span className='color-grey'>
                            พิมพ์และกดปุ่ม Enter เพื่อสร้างตัวเลือก
                        </span>
                        <ReactTags
                            classNames={reactTagClass}
                            autofocus={false}
                            autoresize={false}
                            allowNew={true}
                            minQueryLength={0}
                            maxSuggestionsLength={20}
                            tags={seo?.keyword}
                            placeholderText={''}
                            onDelete={(e) => onDelete(e)}
                            onAddition={(e) => onAddition(e)}
                        />
                    </div>

                    <div
                        className='image-live-container d-flex flex-column mb-2'
                        style={{ alignItems: 'start' }}
                    >
                        <Body2 className="text-darkgrey2">รูปภาพสำหรับแชร์ </Body2>
                        <span
                            className={classnames(`text-danger d-none`, {
                                'd-inline': null,
                            })}
                        >
                            กรุณาใส่รูปภาพสำหรับแชร์
                        </span>
                        <div className='d-flex flex-wrap'>
                            {/* {this.props.listImageSEO?.length ? (
                                this.props.listImageSEO.map(
                                    (item, listImageSEOIndex) => (
                                        <span
                                            className='item-product-container'
                                            key={`listImageSEOIndex-${listImageSEOIndex}`}
                                        >
                                            <img
                                                src={host.image + item?.path}
                                                alt=''
                                                className='image'
                                                style={{ width: '100%' }}
                                            />
                                            <div
                                                className='middle'
                                                onClick={() =>
                                                    this.onClickRemoveImage(
                                                        listImageSEOIndex,
                                                        'listImageSEO',
                                                        'seoUploadEvent',
                                                    )
                                                }
                                            >
                                            </div>
                                        </span>
                                    ),
                                )
                            ) : ( */}

                            {/* {seo?.seoImage ?
                                <img src={`${seo.seoImage.host}${seo.seoImage.path}`} alt="" style={{ maxWidth: '12em', maxHeight: '12em' }} />
                                : */}
                            <ButtonUpload
                                width='12em'
                                height='12em'
                                type='image'
                                shopDetail={{
                                    shopId: data?.id,
                                }}
                                disabled={props.gallery}
                                fieldUpload='seoUploadEvent'
                                isAvatar
                                avatar={
                                    seo?.seoImage?.path && seo?.seoImage?.host ? (
                                        <img src={`${seo?.seoImage?.host}${seo?.seoImage?.path}`} alt="" style={{ maxWidth: '12em', maxHeight: '12em' }} />
                                    ) : seo?.seoImage?.path ? <img src={host.image + seo?.seoImage?.path} alt="" style={{ maxWidth: '12em', maxHeight: '12em' }}/> : (
                                        <img src={IconShopAvatar} alt='' />
                                    )
                                }
                                limitImageUpload={1}
                            />
                            {/* } */}

                            {/* )} */}

                        </div>
                        <div className='d-flex justify-content-between mt-3 align-items-end'>
                            <span>
                                <Body2 className="text-darkgrey2">Permarlink </Body2>
                                <span
                                    className={classnames(`text-danger d-none`, {
                                        'd-inline': errors?.permalink,
                                    })}
                                >
                                    {errors?.permalink}
                                </span>
                                <span
                                    className={classnames(`text-danger d-none`, {
                                        'd-inline': null,
                                    })}
                                >
                                    รูปแบบของ Permarlink ไม่ถูกต้อง
                                </span>
                            </span>
                            <span>
                                <Body2 className="text-darkgrey2">แสดงเป็น :</Body2>
                                <span className='color-primary'>
                                https://v-avenue.outletlive.com/mall/{seo?.permalink}
                                </span>
                            </span>
                        </div>
                        <input
                            type='text'
                            className={`input-form ${errors?.permalink
                                ? 'error-field'
                                : ''
                                }`}
                            value={seo?.permalink}
                            onChange={(e) => handleChangeSEO(e, 'permalink', 'permalink')}
                            onInput={(e) => replaceWhiteSpace(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateSEO
