import React from "react";

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */
const checkTypeDisabled = (optionTypeDisabled, option) => {
    if(optionTypeDisabled && optionTypeDisabled.length > 0) {
        const isExist = optionTypeDisabled.find(optionItem => option === optionItem.key ) 
       return !isExist ? false : true 
    }
    return false;
}
const OptionInput = (props) => {
    const { options,
            optionSelected,
            optionTypeDisabled, 
            onOptionClick} = props;
    return <>
            <ul>
                { Object.keys(options).map((option, index) => {
                    if(index > 3) return
                    return <> 
                            { !checkTypeDisabled(optionTypeDisabled,option) &&
                                <li key={`period-option-${index}`}
                                    onClick={() => onOptionClick(options[option])} 
                                    className={`${optionSelected === options[option] ? 'active' : ''}`}>
                                    {options[option].label}
                                </li>
                            }
                    </>
                })}
            </ul>
            <div className="line"></div>
            <ul>
                { Object.keys(options).map((option, index) => {
                    if(index > 3) 
                    return <>{ !checkTypeDisabled(optionTypeDisabled,option) &&
                                <li key={`calendar-option-${index}`}
                                    onClick={() => onOptionClick(options[option])} 
                                    className={`${optionSelected === options[option] ? 'active' : ''}`}>
                                    {options[option].label}
                                </li>
                              }
                            </>
                    else return 
                })}
            </ul>
           </>
}

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default OptionInput;