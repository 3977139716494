import React, { Component } from "react";
import "./index.scss";
// import logoOutlet from '../../images/icons/outlet-live-logo.svg';
import logo from '../../images/icons/logo-v-avenue.svg';
import { connect } from "react-redux";


class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }


  render() {
    const { loading } = this.props;
    return (
      <React.Fragment>
        <div className={`loadingio-spinner-ripple-pod74wfyirk ${loading ? '' : 'hide-loading'}`}>
          <div className="loading-container">
            <img src={logo} className="logo-icon" alt='logo-icon'/>
            <div className="ldio-se1ucuxedm">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.app.loading
});

export default connect(mapStateToProps)(Loading);
