import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import successIcon from '../../../images/icons/success.svg'
import alertIcon from '../../../images/icons/alert.svg'
import './index.scss'
import { connect } from 'react-redux'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from '../../../redux/actions/modalAlert';
import { Caption, H5 } from 'components/FontStyle';

class ModalAlert extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false
        }
    }

    toggle = () => this.setState({ modal: !this.state.modal })

    onSubmit = async () => {
        if(this.props.modalAlert?.confirm){
           return await this.props.modalAlert.confirm && this.props.modalAlert.confirm()
        }
        await this.props.onSubmit && this.props.onSubmit()
        await this.props.dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: false }))
    }

    closeModal = () => {
        this.props.dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: false }))
    }

    render() {

        const { isOpen, message, type, btnLabel = "ตกลง" } = this.props.modalAlert;

        return (
            <div id="modal-alert">
                <Modal returnFocusAfterClose={false} isOpen={isOpen} toggle={() => this.props.dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: false }))} modalClassName="modal-dialog-body">
                    <ModalBody style={{ height: "20em", borderRadius:"5px" }}>
                        <div className="h-100 d-flex justify-content-around flex-column align-items-center">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="d-flex flex-column text-center" style={{ alignItems: 'center' }}>
                                    {type === 'success' && <img src={successIcon} alt="success" width={'100px'} />}
                                    {type === 'alert' && <img src={alertIcon} alt="alert" width={'100px'} />}
                                    <H5 className="mt-4 h5-message">{message}</H5>
                                </div>
                            </div>
                            <button className="btn-submit" onClick={() => this.onSubmit()}><Caption>{btnLabel}</Caption></button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

ModalAlert.defaultProps = {
    message: 'test',
    type: 'alert',
    btnLabel: 'ตกลง',
}

const mapStateToProps = (state) => ({
    modalAlert: state.modalAlert.modalAlert
})


export default connect(mapStateToProps)(ModalAlert)

