/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import calendar from 'images/icons/calendar.svg';
import classNames from 'classnames';
import { displayDateTh } from 'helper/timeUtil';
import moment from "moment";
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */
const DateInput = (props) => {

    const { disabled,
            onClick,
            value, 
            placeholder } = props; 
    const {  
            fieldData, 
            displayDate, 
          } = useDateInput(props);

    return <div className={classNames('date-picker')}>
            <div className="date-picker-input">
                <input
                    type="text" 
                    placeholder={placeholder}
                    size="10" 
                    autoComplete="off" 
                    value={displayDate(value)} 
                    onClick={onClick}
                    disabled={disabled} />
                <span className="date-picker-input-suffix" 
                      onClick={onClick}>
                    <img src={calendar} alt="calendar" />
                </span>
            </div>
        </div>
}

/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const useDateInput = (props) => {
    
    const {value} = props;
    const [fieldData, setFieldData] = useState(value);

    useEffect(() => {
        
    })

    const displayDate = date => {
        return displayDateTh(moment(date), 'D MMM YYYY');
    }
    const onClick = () => {
        props.onClick();
    }

    return { fieldData,
             displayDate,
             onClick }
}

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default DateInput;