import React, { Component } from "react";
import { connect } from "react-redux";

import host from "config/host";
import Checkbox from "components/Checkbox";
import DropdownButton from "components/DropdownButton"

const initialState = {};

class LiveProducItem extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  render() {

    const {item, onShowOnChange, itemIndex, optionOrder, onOrderChange} = this.props;
    const { productName, 
            sku, 
            imagePath, 
            priceNet, 
            solds, 
            order,
            quotaRemains, 
            showOn,
            // showOnValue, 
            skuShowOn} = item;
    return <React.Fragment>
         <div className="p-2 border-card-manage" key={item.id}>
                <div className=" d-flex flex-row m-2">

                  <img src={`${host.avatar}${imagePath?.path}`} alt="lip" className="img-shelf align-self-start" />
                  <div className="d-flex flex-column font-size-16px ml-3 w-100" style={{ lineHeight: "1.5em" }} >
                    <span className="font-weight-bold">{productName}</span>
                    <span>{sku}</span>
                    <span className="text-danger">฿ {priceNet}</span>
                  </div>

                </div>

                <div className="item-detail-card  d-flex flex-row justify-content-around w-100">
                    <div className="d-flex flex-row justify-content-between w-100 pl-2 pr-2">
                      <div className="d-flex flex-column align-items-center">
                        <span className="color-grey">ลำดับการแสดงผล</span>
                        <DropdownButton
                            handleSelected={(value) => onOrderChange(value, item, itemIndex)}
                            options={optionOrder}
                            defaultValue={order}
                        />
                      </div>
                      <div className="vl"></div>
                      <div className="d-flex flex-column align-items-center">
                        <span className="color-grey">ยอดสั่งซื้อ</span>
                        <h2 className="color-orange">{solds}</h2>
                      </div>
                      <div className="vl"></div>
                      <div className="d-flex flex-column align-items-center">
                        <span className="color-grey">จำนวนสินค้าใน VOD</span>
                        <h2>{quotaRemains}</h2>
                      </div>
                    </div>
                </div>

                <div className="d-flex flex-row justify-content-around w-100 pt-2">
                  <div className="select-all-item">
                    <Checkbox
                      title={`แสดงในเชล์ฟ`}
                      checked={showOn ? showOn.indexOf("shelf") > -1 : false}
                      disabled={
                        !(skuShowOn ? skuShowOn.indexOf("shelf") > -1 : false)
                      }
                      value="shelf"
                      onChange={(e) => onShowOnChange(e, itemIndex, item)}
                      name="shelf"
                    />
                  </div>

                  <div className="select-all-item">
                    <Checkbox
                      title={`แสดงในไลฟ์`}
                      checked={showOn ? showOn.indexOf("live") > -1 : false}
                      disabled={ !(skuShowOn ? skuShowOn.indexOf("live") > -1 : false) }
                      value="live"
                      onChange={(e) => onShowOnChange(e, itemIndex, item)}
                      name="live"
                    />
                  </div>
                </div>
                
              </div>
    </React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(LiveProducItem);





  
