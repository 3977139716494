/* eslint-disable array-callback-return */

/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Navbar,
  Nav,
  NavItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import MenuList from './MenuList';
import HeaderMenuList from '../Header/HeaderMenuList';
import { SETTING_MENU_SIDEBAR, dispatchApp } from '../../redux/actions/app';
import Cookies from 'js-cookie';
import { ADMIN, OWNER, SUPER_ADMIN, ACCOUNTANT } from './Role'

import './index.scss';
import classNames from 'classnames';
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { IsJsonString } from 'helper/utils';

class Sidebar extends Component {



  constructor(props) {
    super(props)
    this.state = { userRole: null, collapse: false }
  }

  componentDidMount = () => {
    // const user = Cookies.get('user')
    // const userInfo = IsJsonString(user) ? JSON.parse(user != undefined ? user : null) : {};
    // ** owner by shop
    // userInfo.shopId ? OWNER : null

    const role = Cookies.get('role');
    let userRole = OWNER;
    switch(role) {
      case 'ADMIN' : userRole = ADMIN; break;
      case 'SUPER_ADMIN' : userRole = SUPER_ADMIN; break;
      case 'OWNER' : userRole = OWNER; break;
      case 'ACCOUNTANT' : userRole = ACCOUNTANT; break;
    }

    this.setState({ userRole });
    const currentPath = this.props.location.pathname;
    const group = HeaderMenuList.items.find(menu => menu.url === '/' + currentPath.split('/')[1]);
    if (group) {
      group.title = group.name;
      this.setSideBarGroupMenu(group);
    }
  }

  setSideBarGroupMenu = (groupMenu) => {
    this.props.dispatch(dispatchApp({
      type: SETTING_MENU_SIDEBAR,
      payload: {
        group: groupMenu.group,
        title: groupMenu.name,
        permission: groupMenu.permission
      }, url: groupMenu.url
    }))
  }

  getActiveSubmenuClassName = (currentPath, menu) => {
    let classNameElm = '';
    if (currentPath === menu.url || currentPath.includes(menu.url)) classNameElm = 'active';
    if (menu.subMenu !== undefined) {
      menu.subMenu.map(menu => {
        if (currentPath === menu.url) classNameElm = 'active';
        if (menu.subMenu !== undefined && menu.subMenu.find(menu => menu.url === currentPath)) {
          classNameElm = 'active';
        }
      })
    }

    // collaps check / [2]
    if (menu.collapse !== undefined) {
      const path = currentPath.split('/')
      const menuPath = menu.url.split('/')
      if (path[2] === menuPath[2]) {
        classNameElm = 'active';
      }
    }

    return classNameElm;
  }

  checkSubActive = (currentPath, menu) => {
    let classNameElm = '';
    if (currentPath === menu.url || currentPath.includes(menu.url)) {
      classNameElm = 'sub-active';
    }
    return classNameElm
  }

  toggleCollapse = (id) => {
    const re = document.getElementById(id)
    re.classList.toggle('expand');
  }

  activeCollapse = (id) => {
    const re = document.getElementById(id)
    re.classList.add('expand');
  }

  checkIconCollapse = (currentPath, menu) => {
    const checkUrl = menu.collapse.find(url => url.url === currentPath)
    if (checkUrl !== undefined) {
      return true
    }
    return false
    // const re = document.getElementById(id)
    // if (re) {
    //   const classes = re.classList
    //   const isDown = classes.contains('expand')
    //   return isDown
    // }
  }
  checkSubmenuExpand = (currentPath, menu) => {
    let classNameElm
    if (menu.collapse !== undefined) {
      const path = currentPath.split('/')
      const menuPath = menu.url.split('/')
      if (path[2] === menuPath[2]) {
        classNameElm = 'expand';
      }
    }
    return classNameElm;
  }

  render() {
    const { tabMenuHeader } = this.props
    const currentPath = this.props.location.pathname
    const { userRole } = this.state

    return (
      <React.Fragment>
        <section id='sidebar'>
          <Navbar color='faded' expand='md'>
            <Nav vertical navbar>
              {MenuList.items
                .filter((menu) => menu.group.findIndex(name => name === tabMenuHeader.group) > -1)
                .map((menu, index) => (
                  <React.Fragment key={index}>
                    {menu.show === true && menu.role.includes(userRole) && (
                      <NavItem
                        className={this.getActiveSubmenuClassName(
                          currentPath,
                          menu,
                        )}
                      >
                        {!menu['collapse'] ?
                          <Link to={menu.url}>
                            {menu.icon} <span>{menu.name}</span>
                          </Link>
                          :
                          <React.Fragment>
                            <div style={{ position: 'relative' }} onClick={() => this.activeCollapse(menu.name)}>
                              <Link to={menu.collapse[0].url}>
                                <div>
                                  {menu.icon} <span>{menu.name}</span>
                                </div>
                                <span style={{ position: 'absolute', right: 5 }}>
                                  <FontAwesomeIcon
                                    icon={this.checkIconCollapse(currentPath, menu) ? faChevronUp : faChevronDown}
                                    onClick={() => this.toggleCollapse(menu.name)} />
                                </span>
                              </Link>

                            </div>
                            <div id={menu.name} className={classNames('sub-menu', this.checkSubmenuExpand(currentPath, menu))}>
                              {menu.collapse.map((submenu, subIdx) => (
                                <React.Fragment key={`subMenu${subIdx}`}>
                                  {submenu.role.includes(userRole) &&
                                    <Link to={submenu.url} className={classNames('sub-menu-item',
                                      this.checkSubActive(
                                        currentPath, submenu
                                      ))} key={'subMenu' + subIdx}>
                                      <div className="pl-4">
                                        {submenu.icon} <span>{submenu.name}</span>
                                      </div>
                                    </Link>
                                  }
                                </React.Fragment>
                              ))}
                            </div>
                          </React.Fragment>

                        }
                      </NavItem>
                    )}
                  </React.Fragment>
                ))}
            </Nav>
          </Navbar>
        </section>
      </React.Fragment >
    )
  }
}

const mapStateToProps = state => ({
  tabMenuHeader: state.app.tabMenuHeader,
})

export default connect(mapStateToProps)(Sidebar)

