import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { H5, SubTitle1 } from 'components/FontStyle'
import ModalAlert from 'components/modalAlert/ModalAlert'
import ModalConfirm from 'components/modalConfirm/ModalConfirm'
import { ButtonAdd } from 'components/output'
import PaginationComponent from 'components/pagination'
import SelectInput from 'components/SelectInput'
import '../styles/_promotionDelivery.scss'
import shopProvider from 'provider/shopProvider'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchApp, SET_LOADING } from 'redux/actions/app'
import { dispatchPromotion, SET_DEFAULT_PROMOTION, SET_KEY_PROMOTION, SET_LIST_SHOP_PROMOTION } from 'redux/actions/promotion'
import promotionProvider from 'provider/promotionProvider'
import { displayDateThShortYear } from 'helper/timeUtil'
import { ReactComponent as IconTrash } from 'images/icons/trash.svg';
import { ReactComponent as IconEdit } from 'images/icons/edit.svg';
import { ReactComponent as IconNoPromocode } from 'images/icons/no-item.svg'
import { Link } from 'react-router-dom'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert'
import moment from 'moment';

const api_shop = new shopProvider()

const PromotionDelivery = () => {
    const [modalConfirm, setModalConfirm] = useState(null)
    const dispatch = useDispatch()
    const history = useHistory();
    const promotionState = useSelector(state => state.promotion)
    const { page, limit, shopSelectedIndex, listShop, listPromotionCount, total } = promotionState

    useEffect(() => {
        (async function () {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            await getListShop()
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        })()

        return () => {
            cleanup()
        }
    }, [])

    useEffect(() => {
        (async function () {
            await getListPromotion()

        })()
    }, [shopSelectedIndex,page,limit])


    const cleanup = () => {
        dispatch(dispatchPromotion({ type: SET_DEFAULT_PROMOTION }))
    }



    const getListPromotion = async () => {
        const data = {
            benefitType: 'discount',
            benefit: 'shipping',
            page,
            limit,
            shopId: listShop[shopSelectedIndex]?.value?.id
        }
        if (!data.shopId) {
            delete data.shopId
        }
        const res = await promotionProvider.getListPromotion(data)
        dispatch(dispatchPromotion({ type: SET_KEY_PROMOTION, payload: { key: 'listPromotion', value: res.data.data } }))
        dispatch(dispatchPromotion({ type: SET_KEY_PROMOTION, payload: { key: 'listPromotionCount', value: res.data.count } }))

    }

    const getListShop = async () => {
        const res = await api_shop.getAllShops()
        const listShop = []
        if (res) {
            res.shop.map((shop) => {
                listShop.push({ label: shop.name, value: shop })
            })
            if(res.count > 1){
                listShop.unshift({ label: 'ร้านทั้งหมด', value: null })
            }
        }
        dispatch(dispatchPromotion({ type: SET_LIST_SHOP_PROMOTION, payload: { listShop } }))
        dispatch(dispatchPromotion({ type: SET_KEY_PROMOTION, payload: { key: 'total', value: res.count } }))
        // if (!promotionState.shopSelectedIndex) {
        //     dispatch(dispatchPromotion({ type: SET_KEY_PROMOTION, payload: { key: 'shopSelectedIndex', value: 0 } }))
        // }
    }

    const handleSelectShop = async (shopIndex) => {
        dispatch(dispatchPromotion({ type: SET_KEY_PROMOTION, payload: { key: 'page', value: 1 } }))
        dispatch(dispatchPromotion({ type: SET_KEY_PROMOTION, payload: { key: 'shopSelectedIndex', value: shopIndex } }))
        dispatch(dispatchPromotion({ type: SET_KEY_PROMOTION, payload: { key: 'shopSelected', value: promotionState.listShop[shopIndex].value } }))
    }

    const handleChangePage = async (page) => {
        dispatch(dispatchPromotion({ type: SET_KEY_PROMOTION, payload: { key: 'page', value: page } }))
    }

    const handleChangePageLimit = async (page, limit) => {
        dispatch(dispatchPromotion({ type: SET_KEY_PROMOTION, payload: { key: 'limit', value: limit } }))
    }

    const checkTypeCustomer = (type) => {
        if (type.length === 2) {
            return 'ลูกค้าทั้งหมด'
        }
        if (type.length === 1 && type.includes(1)) {
            return 'เฉพาะลูกค้าที่ไม่ใช่ AIS'
        }

        if (type.length === 1 && type.includes(2)) {
            return 'เฉพาะลูกค้า AIS'
        }
    }

    const handleDeletePromotion = (promotion) => {
        setModalConfirm({
            isOpen: true,
            message: `ต้องการลบ ${promotion.name} ใช่หรือไม่`,
            confirm: () => deletePromotion(promotion.id),
            isClose: () => closeModalConfirm()
        })
    }

    const closeModalConfirm = () => {
        setModalConfirm({ isOpen: false, message: '', confirm: null })
    }

    const deletePromotion = async (id) => {
        try {
            const res = await promotionProvider.deletePromotion(id)
            if (res.status === 201) {
                closeModalConfirm()
                getListPromotion()
            } else {
                dispatch(dispatchModalAlert({
                    type: OPEN_MODAL_ALERT,
                    payload: { isOpen: true, message: 'เกิดข้อผิดพลาด', type: 'alert', },
                }))
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <div id="promotion-delivery">
            {/* <H4>โปรโมชั่นโค้ด</H4> */}
            <H5>โปรโมชั่นค่าจัดส่ง</H5>

            <div className="header-promotion-container bg-white">
                <div className="d-flex justify-content-between align-items-center">

                    {/* <div className='d-flex flex-row'> */}
                    <div className='w-100 d-flex flex-row justify-content-between header-option-promotion' style={{ gap: '1em' }}>
                        <div className='w-50'>
                            <SelectInput
                                options={promotionState?.listShop}
                                placeholder='กรุณาเลือกร้านค้า'
                                onChange={(value) => { handleSelectShop(value) }}
                                // onClearSearchShop={() => this.setState({ shopSelected: undefined })}
                                value={listShop.length > 0 && promotionState.shopSelectedIndex}
                                // iconClearSearch={this.state.shopSelected?.toString().trim().length > 0 ? true : false}
                                iconClearSearch={false}
                            />
                        </div>

                        {listPromotionCount < total &&
                            <ButtonAdd
                                label='เพิ่มโปรโมชั่น'
                                handleAdd={() => history.push("/manage/promotion/addpromotion")}
                            />
                        }

                    </div>

                    {/* TODO - handle manage item for user owner and admin */}




                </div>
            </div>

            <div className='table-pormotion-delivery'>
                <React.Fragment>
                    <div className="table-promotion-header">
                        <div>ลำดับ</div>
                        <div>ชื่อร้านค้า</div>
                        <div>ชื่อโปรโมชั่น</div>
                        <div>รูปแบบส่วนลด</div>
                        <div>เมื่อยอดซื้อถึง</div>
                        <div>มูลค่าส่วนลด</div>
                        <div>ระยะเวลาการใช้งาน</div>
                        <div>ประเภทลูกค้าที่ใช้สิทธิ์ได้</div>
                        <div>สถานะการใช้งาน</div>
                        <div></div>
                    </div>

            {promotionState?.listPromotion.map((promotion, idx) => (
                <div className="table-promotion-content">
                    <div>{(page - 1) * limit + idx + 1}</div>
                    <div>{promotion.shop.name}</div>
                    <div>{promotion.name}</div>
                    <div>ลดค่าจัดส่ง</div>
                    <div>{promotion.promotionRates[0]?.condition?.value?.toLocaleString()} บาท</div>
                    <div>{promotion.promotionRates[0].benefitValue} {promotion.promotionRates[0].benefitUnit === 'percent' ? '%' : 'บาท'}</div>
                    {
                        (promotion.startDate && promotion.stopDate) ?
                        <div>{displayDateThShortYear(promotion.startDate, 'DD/MM/YYYY HH:mm')} <br/> {displayDateThShortYear(promotion.stopDate, 'DD/MM/YYYY HH:mm')}</div>
                        :
                        <div>ไม่จำกัด</div>
                    }
                    <div>{checkTypeCustomer(promotion.levelIds)}</div>
                    <div>
                         { 
                           (promotion?.stopDate && moment(promotion?.stopDate) < moment())
                           ? 
                           <span className='color-default-action-main'>ไม่ได้ใช้งาน</span>
                           : <> 
                            {
                                (promotion.status === 'show') 
                                ?
                                <span className='color-success'>ใช้งานอยู่</span> 
                                :
                                <span className='color-default-action-main'>ไม่ได้ใช้งาน</span>
                            }
                           </>
                         }
                    </div>
                    <div>
                        <Link to={{
                            pathname: '/manage/promotion/editpromotion',
                            state: {
                                promotion
                            }
                        }}> <IconEdit className="cursor-pointer" /> </Link>

                        <IconTrash className="cursor-pointer" onClick={() => handleDeletePromotion(promotion)} />

                        </div>
                        </div>
                    ))}

                    {promotionState?.listPromotion.length === 0 &&
                        <div className="no-item-promotion">
                            <IconNoPromocode />
                            <SubTitle1 className="title">ยังไม่มีโปรโมชั่น</SubTitle1>
                        </div>
                    }
                </React.Fragment>
            </div>


            {promotionState?.listPromotion.length !== 0 &&
                <PaginationComponent
                    pageSize={limit}
                    current={page}
                    total={listPromotionCount}
                    defaultCurrent={1}
                    onChange={(page) => handleChangePage(page)}
                    pageSizeOptions={[10, 20, 40]}
                    onShowSizeChange={(page, limit) => handleChangePageLimit(page, limit)}
                    showQuickJumper={true}
                />
            }


            <ModalConfirm
                isOpen={modalConfirm?.isOpen}
                message={modalConfirm?.message}
                confirm={modalConfirm?.confirm}
                isClose={modalConfirm?.isClose}
            />

            <ModalAlert />

        </div>
    )
}

export default PromotionDelivery