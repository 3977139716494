import React, { useEffect } from 'react'
import Playlists from 'components/PlaylistItems/Playlists'
import { useSelector, useDispatch } from "react-redux";
import {
  dispatchShop,
  ADD_KEY_VALUE_SHOP,
} from 'redux/actions/shop'

const PlaylistPlatForm = (props) => {
  const { dispatch, history } = props;
  const distPatch = useDispatch();
  const {shopSelected} = useSelector((state) => state.shop);

  useEffect(() => {
    initialComponent();
    return () => { }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialComponent = () => {
    distPatch(
      dispatchShop({
        type: ADD_KEY_VALUE_SHOP,
        payload: { key: 'shopSelected', value: null }
      }));
  }

  return (<>
          { !shopSelected && 
            <Playlists data={{ dispatch, history }} />
          }
          </>)
}

export default PlaylistPlatForm;