import { takeLatest, call, put, select, delay } from "redux-saga/effects";
import Cookies from 'js-cookie';
import productProvider from '../../provider/productProvider';
import {
    GET_LIST_PRODUCT,
    GET_LIST_ALL_PRODUCT,
    CREATE_PRODUCT_SKU,
    HANDLE_CRAETE_PRODUCT,
    SET_PRODUCT_CATEGORIES,
    GET_PRODUCT_CATEGORIES,
    ON_GET_BRANDS,
    ADD_KEY_VALUE_PRODUCT,
    GET_PRODUCT_ATTRIBUTE,
    GET_PRODUCT,
    UPDATE_PRODUCT_LOGISTICS,
    GET_PRODUCTS_BY_SHOP,
    SET_PRODUCTS_BY_SHOP,
    GET_SEARCH_PRODUCTS_BY_SHOP,
    UPDATE_PRODUCT_SKU,
    UPDATE_PRODUCT_BY_ID,
    GET_LIST_PRODUCT_LOGISTICS_BY_PRODUCT_ID,
    SET_SEARCH_PRODUCTS_BY_SHOP,
    CREATE_PRODUCT_LOGISTIC,
    HANDLE_SEARCH_PRODUCT,
    HANDLE_SEARCH_SHOP_PRODUCT,
    GET_ALL_PRODUCTS_BY_SHOP,
    GET_DATA_PRODUCT_PAGE,
    VALIDATE_STOCK_REMAIN,
    OPEN_MODAL_ADD_PRODUCT,
    HANDLE_DELETE_PRODUCT,
    HANDLE_SEARCH_PRODUCT_FILTER_PLAYLIST,
    GET_LIST_LOGISTICS_BY_SHOP,
    GET_LIST_PROVIDER_SHOP_LOGISTIC,
    GET_INITIAL_EDIT_VOD,
    GET_BRAND_BY_SHOP,
    ADD_VALUE_IN_PRODUCT,
    SET_KEY_PRODUCT,
    GET_CATEGORIES_BY_PRODUCT_ID,
    GET_LIST_PRODUCT_PROMOCODE
} from '../actions/product'
import { GET_POST_VOD_BY_ID } from '../actions/post'
import { SET_LOGISTICS_BY_SHOP } from 'redux/actions/order'
import { OPEN_TOAST } from '../actions/notification'
import { getProduct, getShop, getGallery, getOrder } from './selectors'
import { SET_LOADING } from '../actions/app';
import { SET_DATA_SHOP_DETAIL, SET_PAGINATION_SHOP_DETAIL } from "../actions/shop";
import { OPEN_MODAL_MESSAGE, OPEN_MODAL_MESSAGE_ERROR } from '../actions/modalMessage'
import { reject } from "lodash";
import { putWait, withCallback } from 'redux-saga-callback';
import logisticProvider from 'provider/ligisticProvider'
import { GET_LOGISTIC_LIST } from "redux/actions/logistic";
import brandProvider from "provider/brandProvider";
// import { IsJsonString } from "helper/utils";
const api_product = new productProvider();

export function* onGetListProduct(action) {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const getStateProduct = yield select(getProduct)
    const { activeTab, limit, page } = getStateProduct
    let data = {
        limit: limit,
        page: page,
        status: activeTab
    }
    if (!data.status.trim().length) {
        delete data.status
    }
    try {
        const getListProduct = yield call(api_product.getListAllProduct, data)
        yield put({
            type: GET_LIST_ALL_PRODUCT, payload: {
                getListProduct: getListProduct.product
            }
        })
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: getListProduct.count } })


        if (getListProduct.product.length === 0 && getListProduct.count !== 0) {
            yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: getStateProduct.page - 1 } })
            data.page = data.page - 1
            const getListProduct = yield call(api_product.getListAllProduct, data)
            yield put({
                type: GET_LIST_ALL_PRODUCT, payload: {
                    getListProduct: getListProduct.product
                }
            })
            yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: getListProduct.count } })
        }


    } catch (error) {

        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }
    yield put({ type: SET_LOADING, payload: { loading: false } })
}

export function* onCreateProduct(action) {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { payload } = action;
    let { productSkusParam, logisticParam, category } = payload;
    try {
        let stateProduct = yield select(getProduct)
        let stateGallery = yield select(getGallery)
        let stateShop = yield select(getShop)
        const createBy = JSON.parse(localStorage.getItem('createProductBy'))
        if (createBy.createAt === "shopDetail") {
            stateProduct.value.shopId = stateShop.currentShop.id
        }
        stateProduct.value.imagePathIds = [];
        stateProduct.value.imagePathIds = stateGallery.productUploadEvent.map(image => image.id);
        // let coverImgIndex
        // console.log('aaaaa')
        // for (const [idx, img] of stateGallery.productUploadEvent.entries()) {
        //     stateProduct.value.imagePathIds.push(img.id)
        //     console.log('stateProduct', stateProduct)
        //     switch (img.fileName.split('.').pop()) {
        //         // case 'mp4':
        //         // case 'avi':
        //         // case 'mpg':
        //         // case 'm4v':
        //         case 'jpg':
        //         case 'jpeg':
        //         case 'png':
        //         case 'gif':
        //         case 'bmp':
        //         // case 'jfif':
        //             if (coverImgIndex === undefined) coverImgIndex = idx
        //             break;
        //         default: 
        //             coverImgIndex = null
        //         break;
        //     }
        // }

        stateProduct.value.videoPathIds = []
        if (stateGallery.productUploadEventVideo) {
            // eslint-disable-next-line no-unused-vars
            for (const [idx, vdo] of stateGallery.productUploadEventVideo.entries()) {
                stateProduct.value.videoPathIds.push(vdo.id)
            }
        }

        // stateProduct.value.coverPathId = stateProduct.value.imagePathIds[coverImgIndex];
        stateProduct.value.coverPathId = stateGallery.productUploadEvent[0].id
        if (stateGallery.seoUploadEvent && stateGallery.seoUploadEvent.length > 0) {
            stateProduct.value.seo.seoImageId = stateGallery.seoUploadEvent[0].id;
        }

        const param = stateProduct.value
        if (stateProduct.value.brand) {
            stateProduct.value.brandId = stateProduct.value.brand.value.id
            // delete stateProduct.value.brand
            // delete param.brand
        }
        let { brand, ...paramsProduct } = param
        if (stateGallery.seoUploadEvent && !(stateGallery.seoUploadEvent.length > 0)) {
            if (paramsProduct?.seo?.seoImageId) delete paramsProduct.seo.seoImageId
        }
        if (!(paramsProduct?.seo?.permalink.length > 0)) delete paramsProduct.seo.permalink;
        if (!(paramsProduct?.seo?.title.length > 0)) delete paramsProduct.seo.title;
        if (!(paramsProduct?.seo?.description.length > 0)) delete paramsProduct.seo.description;
        if (!(paramsProduct?.seo?.seoImage)) delete paramsProduct.seo.seoImage;
        if (!(paramsProduct?.seo?.keyword.length > 0)) delete paramsProduct.seo.keyword;

        if (paramsProduct.productType === 'general') {
            delete paramsProduct.digitalInfo
        }

        /* create product */
        const createProductRes = yield call(api_product.onCreateProduct, paramsProduct);


        // eslint-disable-next-line no-unused-vars
        let createProductSkuRes = '';
        const productId = createProductRes.data.id;
        const shopId = stateProduct.value.shopId;

        category.productId = productId;

        /* create category product */
        yield call(api_product.createCategoryProduct, category);

        /* NOTE - Create product sku */
        if (payload.productSkuParam) {
            let item = payload.productSkuParam;
            item.sku.status = stateProduct.value.status;
            item.sku.imagePathId = stateGallery?.productUploadEvent[0]?.id;
            item.sku.createdBy = JSON.parse(Cookies.get('user')).id;
            // item.sku.sku = `${productId}-${item.sku.sku}`;
            item.sku.sku = `${item.sku.sku}`;
            item.sku.attribute[0].value = `${stateProduct.value.name}`;
            item.sku.attribute[0].layer = 1;
            item.sku.attribute[0].imagePathId = stateGallery?.productUploadEvent[0]?.id;

            if (item.sku.hasOwnProperty('imagePath')) delete item.sku.imagePath;
            if (item.sku.hasOwnProperty('fileImage')) delete item.sku.fileImage;
            if (item.sku.hasOwnProperty('updateStock')) delete item.sku.updateStock;

            const skus = [];
            skus.push(item.sku);
            const params = { productId: productId, skus }
            createProductSkuRes = yield call(api_product.createProductSku, params);
        } else {
            /* NOTE - Create product skus */
            const shopId = stateProduct.shopId;
            productSkusParam.productId = productId;
            productSkusParam.shopId = shopId;

            yield putWait({ type: CREATE_PRODUCT_SKU, payload: productSkusParam });
            // yield call(api_product.createProductSku, productSkusParam);
        }

        /* NOTE - Create product logistic */
        const logisticPayload = logisticParam.filter(logistic => logistic.enable === true)
            .map(logistic => {
                let param = {
                    logisticId: logistic.id,
                    productId,
                    shopId,
                    rateType: logistic.rateType,
                    shopLogisticId: logistic.rateType === "custom" ? logistic.shopLogisticSelected?.id : null
                };
                if (logistic.rateType === "standard") {
                    delete param.shopLogisticId
                }
                // if (logistic.rateType === "free") param.price = logistic.price;
                return param;
            });

        yield putWait({ type: CREATE_PRODUCT_LOGISTIC, payload: logisticPayload });
        yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } });
        const createProductBy = JSON.parse(localStorage.getItem("createProductBy"));
        yield delay(3000);

        if (createProductBy.createAt === 'product') {
            window.location.replace("/manage/product");
        }
        if (createProductBy.createAt === 'shopDetail') {
            localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: 0 }));
            window.location.replace("/manage/shop/shopdetail");
        }
        /////close modal here !!!!
        // yield put({ type: OPEN_MODAL_ADD_PRODUCT, payload: { isOpen: false} })
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        const errorMessage = error?.response?.data?.message || '';
        if (error.response.status === 400 && error.response.data.message === "THIS_PERMALINK_IS_EXIST") {
            yield put({ type: SET_KEY_PRODUCT, payload: { key: 'productDetailError', value: error.response.data.message } })
            yield put({ type: OPEN_MODAL_MESSAGE, payload: { message: error.response?.data?.message, title: "iconFail" } })
            yield put({ type: SET_LOADING, payload: { loading: false } })
        }
        else if (error.response.status === 400 && errorMessage.indexOf('SELLER_SKU') > -1 && errorMessage.indexOf('ALREADY_EXISTS') > -1) {
            const duplicateSellerSKU = errorMessage.replace('SELLER_SKU_', '').replace('_ALREADY_EXISTS', '');
            yield put({ type: OPEN_MODAL_MESSAGE, payload: { message: `${duplicateSellerSKU} ถูกใช้งานไปแล้ว!`, title: "iconFail" } })
            yield put({ type: SET_LOADING, payload: { loading: false } })
        }
        else {
            if (error.response) yield put({ type: OPEN_MODAL_MESSAGE, payload: { message: error.response?.data?.errors, title: "iconFail" } })
            yield put({ type: SET_LOADING, payload: { loading: false } })
            yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }

}

export function* onCreateProductSku(action) {

    try {

        const { payload } = action;
        const { skus, skusImageFile, productId } = payload;

        /* NOTE create skuImages param for create product sku */
        let skusNewimagePath = [];
        skusNewimagePath = skus.map((element, index) => {
            delete element.fileImage;
            delete element.updateStock;
            delete element.imagePath;

            // element.sku = `${productId}-${element.sku}`;
            element.sku = `${element.sku}`;
            const key = element.attribute[0].value;
            const imagePath = skusImageFile.filter(item => item.key === key);

            element.createdBy = JSON.parse(Cookies.get('user')).id;

            element.imagePathId = imagePath[0]?.fileImage?.id;
            element.attribute[0].imagePathId = imagePath[0]?.fileImage?.id;
            return element;
        })

        const params = {
            productId: productId,
            skus: skusNewimagePath
        }

        const response = yield call(api_product.createProductSku, params);
        return response;

    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
        yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
        throw (error);
    }
}

export function* createProductLogistic(action) {
    const { payload } = action;
    const response = yield call(api_product.createProductLogistics, payload)
    return response.status;
}

export function* getListAllCategories(action) {
    const getCategories = yield call(api_product.getListCategories)
    yield put({ type: SET_PRODUCT_CATEGORIES, payload: { getCategories } })
}

export function* onGetProductsByShop(action) {
    // NOTE get product by shop id
    try {
        // const param = {shopId: payload.shopId}

        const getShopState = yield select(getShop);
        const { currentShop } = getShopState;
        let param = {
            shopId: currentShop.id,
            page: getShopState.paginationShopDetail.page,
            limit: getShopState.paginationShopDetail.limit
        };

        const productsByShop = yield call(api_product.getProductsByShop, param);
        yield put({ type: SET_PRODUCTS_BY_SHOP, payload: { productsByShop } })
        yield put({ type: SET_PAGINATION_SHOP_DETAIL, payload: { key: 'total', value: productsByShop.count } })
        if (productsByShop.product.length === 0 && productsByShop.count !== 0) {
            param.page = (param.page > 1) ? param.page - 1 : 1;
            yield put({
                type: SET_PAGINATION_SHOP_DETAIL, payload: {
                    key: 'page',
                    value: param.page
                }
            })
            const productsByShop = yield call(api_product.getProductsByShop, param);
            yield put({ type: SET_PRODUCTS_BY_SHOP, payload: { productsByShop } })
            yield put({ type: SET_PAGINATION_SHOP_DETAIL, payload: { key: 'total', value: productsByShop.count } })
        }

    } catch (ex) {

    }
}

export function* onGetAllProductsByShop(action) {
    // NOTE get product by shop id
    try {
        const { payload } = action;
        // const param = {shopId: payload.shopId}

        const getShopState = yield select(getShop);
        const { currentShop } = getShopState;
        const param = {
            shopId: payload.id ? payload.id : currentShop.id,
            page: 1,
            limit: 1000
        };

        const productsByShop = yield call(api_product.getProductsByShop, param);
        yield put({ type: SET_PRODUCTS_BY_SHOP, payload: { productsByShop } })
        yield put({ type: SET_PAGINATION_SHOP_DETAIL, payload: { key: 'total', value: productsByShop.count } })
    } catch (ex) {

    }
}



export function* onGetListProductPromocode(action) {
    // NOTE get product by shop id
    try {
        const { payload } = action;
        yield put({ type: SET_LOADING, payload: { loading: true } })
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { loading: true } })
        // const param = {shopId: payload.shopId}
        const getStateProduct = yield select(getProduct)
        const { limit, page, valueSearch, sort } = getStateProduct

        const getShopState = yield select(getShop);
        const { shopSelected } = getShopState;

        const param = {
            shopId: payload?.id ? payload?.id : shopSelected?.id,
            keyword: valueSearch?.trim(),
            page: page,
            limit: limit,
            sortBy: sort
        }
        const res = yield call(api_product.getSearchProducts, param);
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'product', value: res.product } })
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: res.count } })
        if(payload?.type === 'editProduct'){
            yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'editProductProductCount', value: res.count } })
            yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'editProductSkuCount', value: res.countSku } })
        }

        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (ex) {
        console.log(ex)
        yield put({ type: SET_LOADING, payload: { loading: false } })
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }

}

export function* onGetSearchProductsByShop(action) {
    try {
        const { payload } = action
        const searchProductsByShop = yield call(api_product.getSearchProductsByShop, payload);
        yield put({ type: SET_SEARCH_PRODUCTS_BY_SHOP, payload: { searchProductsByShop } })
    } catch (ex) {

    }
}

export function* onGetBrands(action) {
    try {
        const result = yield call(api_product.getBrands)
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'listAllBrands', value: result.data } })
    } catch (error) {
        // yield put({type: SET_MODAL})
        console.log(`error`, error)
    }

}

export function* onSearchProduct(action) {
    const getStateProduct = yield select(getProduct)
    const { activeTab } = getStateProduct
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        if (!getStateProduct.statusSearch) {
            yield onGetListProduct({})
            // yield put({
            //     type: GET_LIST_PRODUCT, payload: {
            //         limit: getStateProduct.limit,
            //         page: getStateProduct.page,
            //     }
            // })
        } else {
            const res = yield call(api_product.handleSearchProduct, { search: getStateProduct.valueSearch.trim(), page: getStateProduct.page, limit: getStateProduct.limit, status: activeTab })
            yield put({
                type: GET_LIST_ALL_PRODUCT, payload: {
                    getListProduct: res.data.product
                }
            })
            yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: res.data.count } })


            if (res.data.product.length === 0 && res.data.count !== 0) {
                yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: getStateProduct.page - 1 } })
                const res = yield call(api_product.handleSearchProduct, { search: getStateProduct.valueSearch, page: getStateProduct.page - 1, limit: getStateProduct.limit, status: activeTab })
                yield put({
                    type: GET_LIST_ALL_PRODUCT, payload: {
                        getListProduct: res.data.product
                    }
                })
                yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: res.data.count } })
            }
        }
        // yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        // yield put({ type: SET_LOADING, payload: { loading: false } })
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })

    } finally {
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }

}

// function getShopId() {

// }

export function* onSearchProductFilterPlaylist(action) {
    const { payload } = action
    const getStateProduct = yield select(getProduct)

    // const user = Cookies.get('user')
    // const userInfo = IsJsonString(user) ? JSON.parse(user != undefined ? user : null) : {};
    const getStateShop = yield select(getShop)

    const shopId = getStateShop.shopSelected ? getStateShop.shopSelected.id : '';

    try {
        let res
        if (payload.search.trim().length <= 0) {
            res = yield call(api_product.searchProductFilterPlaylist, { playlistsId: payload.playlistsId, search: "", page: getStateProduct.page, limit: getStateProduct.limit, shopId })
        } else {
            res = yield call(api_product.searchProductFilterPlaylist, { playlistsId: payload.playlistsId, search: payload.search, page: getStateProduct.page, limit: getStateProduct.limit, shopId })

            if (res.data.product.length === 0 && res.data.count !== 0) {
                yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: getStateProduct.page - 1 } })
                res = yield call(api_product.searchProductFilterPlaylist, { playlistsId: payload.playlistsId, search: payload.search, page: getStateProduct.page - 1, limit: getStateProduct.limit, shopId })
                yield put({
                    type: GET_LIST_ALL_PRODUCT, payload: {
                        getListProduct: res.data.product
                    }
                })
                yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: res.data.count } })
            }

        }

        yield put({
            type: GET_LIST_ALL_PRODUCT, payload: {
                getListProduct: res.data.product
            }
        })

        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: res.data.count } })

    } catch (error) {
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })

    }

}

export function* onSearchShopProduct(action) {
    const { payload } = action
    const getStateShop = yield select(getShop)
    let param = {
        shopId: getStateShop.currentShop.id,
        keyword: payload.search,
        limit: getStateShop.paginationShopDetail.limit,
        // page: getStateShop.paginationShopDetail.page
        page: payload?.page ? payload?.page : getStateShop.paginationShopDetail.page
    }
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const searchProductsByShop = yield call(api_product.getSearchProductsByShop, param);
        yield put({ type: SET_PRODUCTS_BY_SHOP, payload: { productsByShop: searchProductsByShop } })
        yield put({ type: SET_PAGINATION_SHOP_DETAIL, payload: { key: 'total', value: searchProductsByShop.count } })
        if (searchProductsByShop.product.length > 0 && searchProductsByShop.count !== 0) {
            yield put({
                type: SET_PAGINATION_SHOP_DETAIL, payload: {
                    key: 'page',
                    value:  payload?.page ? payload?.page : getStateShop.paginationShopDetail.page
                }
            })
            // param.page = param.page - 1
            const searchProductsByShop = yield call(api_product.getSearchProductsByShop, param);
            yield put({ type: SET_PRODUCTS_BY_SHOP, payload: { productsByShop: searchProductsByShop } })
            yield put({ type: SET_PAGINATION_SHOP_DETAIL, payload: { key: 'total', value: searchProductsByShop.count } })
        }
    } catch (error) {
        console.log('error', error)
    }
    yield put({ type: SET_LOADING, payload: { loading: false } })


}

export function* onGetProductAttributes(action) {
    try {
        const { payload } = action; // productId
        const res = yield call(api_product.getProductAttributes, payload)
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'productAttribute', value: res.data || {} } })
    } catch (error) {

    }
}

export function* onGetProduct(action) {
    try {
        const { payload } = action; // productId
        const res = yield call(api_product.getProductByProductId, payload)
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'currentProduct', value: res.data || {} } });
        return res.data;
    } catch (error) {
        throw new Error(error)
    }
}

export function* onValidateStockRemain(action) {
    try {
        const { payload } = action;
        const { productId } = payload;
        yield put({ type: GET_PRODUCT, payload: { productId } });
    } catch (error) {
        throw new Error(error)
    }

}

export function* onUpdateProductById(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const { payload } = action;
        let { productId, productLogistic, skus, skusImageFile, deleteSkus, category } = payload;
        let stateProduct = yield select(getProduct);
        let stateGallery = yield select(getGallery)
        let stateShop = yield select(getShop)
        /* NOTE - set description format */
        let description = []
        description.push({ contentType: 'text', content: stateProduct.value.description });
        stateProduct.value.coverPathId = stateGallery.productUploadEvent[0].id;

        stateProduct.value.imagePathIds = stateGallery.productUploadEvent.map(image => image.id);

        if (stateGallery.productUploadEventVideo)
            stateProduct.value.videoPathIds = stateGallery.productUploadEventVideo.map(vdo => vdo.id)

        if (stateGallery.seoUploadEvent && stateGallery.seoUploadEvent.length > 0) {
            stateProduct.value.seo.seoImageId = stateGallery.seoUploadEvent[0].id;
        }

        let {
            // brand,
            categories, coverPathId,
            imagePathIds, videoPathIds, name, seo, status, prepareDay } = stateProduct.value;

        if (typeof seo.keyword === 'object')
            seo.keyword = seo.keyword.map(current => typeof current === 'object' ? current.name : current)
        if (!!categories[0] || !categories[0]) {
            categories.splice(0, 1)
        }
        let productDetail = {
            // brandId: brand.value.id,
            categories,
            coverPathId,
            description,
            imagePathIds,
            videoPathIds,
            name,
            seo,
            status,
            prepareDay,
            digitalInfo: payload.digitalInfo
        }

        if (!(stateGallery.seoUploadEvent && stateGallery.seoUploadEvent.length > 0)) {
            delete productDetail.seo.seoImageId;
            delete productDetail.seo.seoImage;
        }

        if (!productDetail.seo.permalink || !(productDetail?.seo?.permalink.length > 0)) productDetail.seo.permalink = productId.toString();
        if (!productDetail.seo.title || !(productDetail?.seo?.title.length > 0)) delete productDetail.seo.title;
        if (!productDetail.seo.description || !(productDetail?.seo?.description.length > 0)) delete productDetail.seo.description;
        if (!productDetail.seo.seoImage || !(productDetail?.seo?.seoImage.length > 0)) delete productDetail.seo.seoImage;
        if (!productDetail.seo.keyword || !(productDetail?.seo?.keyword.length > 0)) delete productDetail.seo.keyword;

        if (seo && seo.seoImage) delete seo.seoImage
        yield call(api_product.updateProductById, { productId, productDetail });

        if (category.action === 'CREATE') {
            if (category.action) delete category.action;
            category.productId = productId;
            yield call(api_product.createCategoryProduct, category);
        } else if (category.action === 'UPDATE') {
            if (category.action) delete category.action;
            yield call(api_product.updateCategoryProduct, category, productId);
        }

        yield putWait({ type: UPDATE_PRODUCT_SKU, payload: { skus, productId, skusImageFile, deleteSkus } });
        yield putWait({ type: UPDATE_PRODUCT_LOGISTICS, payload: { productLogistic } });

        yield put({ type: OPEN_TOAST, payload: { message: `แก้ไขสินค้าสำเร็จ`, type: 'success' } });

        const createProductBy = JSON.parse(localStorage.getItem("createProductBy"));
        if (createProductBy.createAt === 'product') {
            // yield put({ type: GET_DATA_PRODUCT_PAGE, payload: {} })
            yield put({ type: HANDLE_SEARCH_PRODUCT })
        }
        if (createProductBy.createAt === 'shopDetail') {
            if (stateShop.shopDetailSearchStatus === true) {
                yield put({
                    type: HANDLE_SEARCH_SHOP_PRODUCT,
                    payload: {
                        search: stateShop.shopDetailKeyword
                    }
                })
            }
            else {
                yield put({ type: GET_PRODUCTS_BY_SHOP, payload: { shopId: stateShop?.currentShop?.id } })
            }
        }

        yield delay(3000);
        yield put({ type: SET_LOADING, payload: { loading: false } });
        yield put({ type: OPEN_MODAL_ADD_PRODUCT, payload: { isOpen: false } });

    } catch (error) {
        const errorMessage = error?.response?.data?.message || '';
        if (error?.response?.status === 400 && errorMessage.indexOf('SELLERSKU') > -1 && errorMessage.indexOf('ALREADY_EXISTS') > -1) {
            const duplicateSellerSKU = errorMessage.replace('SELLERSKU_', '').replace('_ALREADY_EXISTS', '');
            yield put({ type: OPEN_MODAL_MESSAGE, payload: { message: `${duplicateSellerSKU} ถูกใช้งานไปแล้ว!`, title: "iconFail" } })
            yield put({ type: SET_LOADING, payload: { loading: false } })
        }

        yield put({ type: SET_LOADING, payload: { loading: false } });
        yield put({ type: SET_KEY_PRODUCT, payload: { key: 'productDetailError', value: error?.response?.data?.message } })
        yield put({ type: OPEN_TOAST, payload: { message: `แก้ไขสินค้าไม่สำเร็จ`, type: 'danger' } });
    }
}

export function* onUpdateProductSku(action) {
    try {
        const { payload } = action;
        const { skus, productId, skusImageFile, deleteSkus } = payload;

        if (deleteSkus.length > 0) {
            const skuDelete = deleteSkus.filter(current => current.id).map(current => current.id);
            yield call(api_product.deleteProductSku, { productSkus: { skuId: skuDelete }, productId });
        }

        /* NOTE - common update skus */
        const SKUs = JSON.parse(JSON.stringify(skus))
        const skuParam = SKUs.map(_sku => {

            /* NOTE - assign imagePath to sku */
            const key = _sku.attribute[0].attribute ? _sku.attribute[0].attribute.value : _sku.attribute[0].value;
            const imagePath = skusImageFile.filter(item => item.key === key);
            _sku.imagePathId = imagePath[0]?.fileImage?.id;

            if (_sku.attribute[0].value) {
                if (!_sku.attribute[0].imagePathId)
                    _sku.attribute[0].imagePathId = imagePath[0]?.fileImage?.id;
            }

            delete _sku.imagePath;
            delete _sku.fileImage;
            if (_sku.skuId) delete _sku.attribute;
            delete _sku.createdBy;
            delete _sku.updateStock;
            return _sku
        })

        yield call(api_product.updateProductSku, { skus: { skus: [...skuParam] }, productId })
        // yield put({ type: GET_LIST_PRODUCT, payload: {} });
        // yield onSearchProduct({})


    } catch (error) {
        reject(error);

    }
}

export function* onUpdateProductLogistics(action) {
    try {
        const { payload } = action
        const { productLogistic } = payload;
        yield call(api_product.updateProductLogistics, { productLogistic })
    } catch (error) {

    }
}

export function* onGetListProductByProductId(action) {
    // try {
    // const productState = yield select(getProduct)
    // yield put({ type: SET_LOADING, payload: { loading: true } })
    // const { payload } = action;
    // console.log('payload', payload)
    // const { productId } = payload;
    // const res = yield call(api_product.getListProductLogisticsByProductId, { productId });
    // yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'productLogisticList', value: res.data } })
    // const shopState = yield select(getShop)
    // const listProviderShopLogistic = yield call(logisticProvider.getListShopLogisticAllByShopId, shopState.currentShop.id)
    // let providerShopLogistic = []
    // console.log('listProviderShopLogistic', listProviderShopLogistic)
    // let logisticList = payload.logisticList
    // for (const [index, element] of listProviderShopLogistic.entries()) {
    //     console.log('element', element)
    //     const filterElement = payload.logisticList.filter((item, idx) => item.logisticId === element.id)
    //     logisticList[index].shopProviderLogistic = filterElement
    //     // logisticList[index].shopLogisticSelected = { ...filterElement[0] }
    //     // providerShopLogistic.push(filterElement)
    // }
    // yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'providerShopLogistic', value: providerShopLogistic.data } })
    // yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'logisticList', value: logisticList } })
    // yield put({ type: SET_LOADING, payload: { loading: false } })
    // // } catch (error) {

    // // }

    // try {

    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { payload } = action;
    const shopState = yield select(getShop)
    let logisticList = payload.logisticList
    const { productId } = payload;
    const res = yield call(api_product.getListProductLogisticsByProductId, { productId });
    yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'productLogisticList', value: res.data } })
    const listProviderShopLogistic = yield call(logisticProvider.getListShopLogisticAllByShopId, payload.shopId || shopState.currentShop?.id || JSON.parse(Cookies.get('user')).shopId) ///OWNER GET SHOPID BY COOKIE USER
    const providerShopLogistic = []
    for (const [index, element] of payload.logisticList.entries()) {
        // const res = yield call(logisticProvider.getProviderShopLogisticByShopId, { shopId: element.shopId, logisticId: element.logisticId })
        const filterElement = listProviderShopLogistic.data.filter((item, idx) => item.logisticId === element.id && item.status === "show")
        logisticList[index].shopProviderLogistic = filterElement
        providerShopLogistic.push(filterElement)
        const filter = res.data.filter((e) => e.logisticId === element.id && e.rateType === "custom")
        const getShopLogistic = listProviderShopLogistic.data.filter((item) => item.id === (filter.length > 0 && filter[0].shopLogisticId))
        if (getShopLogistic.length > 0) {
            logisticList[index].shopLogisticSelected = getShopLogistic[0]
        } else {
            logisticList[index].shopLogisticSelected = {}
        }
    }
    yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'providerShopLogistic', value: providerShopLogistic } })
    yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'logisticList', value: logisticList } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
    // } catch (error) {
    // }
}

export function* ongetDataProductPage(action) {
    // yield put({ type: SET_LOADING, payload: { loading: true } })
    const getStateProduct = yield select(getProduct)
    const data = {
        limit: getStateProduct.limit,
        page: getStateProduct.page,
        status: getStateProduct.activeTab
    }
    if (data.status.trim().length === 0) {
        delete data.status
    }
    try {
        const getListProduct = yield call(api_product.getListAllProduct, data)
        yield put({
            type: GET_LIST_ALL_PRODUCT, payload: {
                getListProduct: getListProduct.product
            }
        })
        yield put({ type: GET_LOGISTIC_LIST })
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: getListProduct.count } })


    } catch (error) {

    }
    yield put({ type: SET_DATA_SHOP_DETAIL })
    // yield put({ type: SET_LOADING, payload: { loading: false } })
}

export function* onDeleteProduct(action) {
    const { payload } = action

    try {
        yield put({ type: SET_LOADING, payload: { loading: true } });
        const res = yield call(api_product.deleteProduct, payload.product.id)
        if (res) {
            if (window.location.pathname === "/manage/product") {
                if (payload.statusSearch) {
                    yield onSearchProduct({
                        payload: {
                            search: payload.historySearch
                        }
                    })
                } else {
                    yield onGetListProduct({})
                }
            } else {
                if (payload.statusSearch) {
                    yield onSearchShopProduct({
                        payload: {
                            search: payload.historySearch
                        }
                    })
                } else {
                    yield onGetProductsByShop({})
                }
            }

        }
        yield put({ type: SET_LOADING, payload: { loading: false } });
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } });

    }
}

export function* getListLogisticsByShop(action) {
    const orderState = yield select(getOrder)
    const getLogistics = yield call(api_product.geListLogisticsNotPriceByShopId, { shopId: orderState.modalInvoiceDetail.currentInvoice.shopId })
    const logistics = []
    for (const logistic of getLogistics.data) {
        logistics.push({ name: logistic.name, value: logistic, icon: logistic.logo })
    }
    yield put({ type: SET_LOGISTICS_BY_SHOP, payload: { logistics: logistics } })

}


export function* getListProviderShopLogistic(action) {
    // yield put({type: SET_LOADING,payload:{loading:true}})
    const { payload } = action
    let logisticList = payload.logisticList
    // const logisticList = productState?.logisticList
    const providerShopLogistic = []
    const shopState = yield select(getShop)
    // yield put({ type: SET_LOADING, payload: { loading: true } })
    const listAllShopLogistic = yield call(logisticProvider.getListShopLogisticAllByShopId, payload?.shopId || shopState.currentShop?.id || JSON.parse(Cookies.get('user')).shopId)
    for (const [index, element] of logisticList.entries()) {
        const filterElement = listAllShopLogistic.data.filter((item, idx) => item.logisticId === element.id)
        logisticList[index].shopProviderLogistic = filterElement
        logisticList[index].shopLogisticSelected = { ...filterElement[0] }
        providerShopLogistic.push(filterElement)
        //     const res = yield call(logisticProvider.getProviderShopLogisticByShopId, { shopId: shopState.currentShop.id, logisticId: element?.id, page: logisticPriceState.page, limit: logisticPriceState.limit })
        //     providerShopLogistic.push(res.data)
        //     logisticList[index].shopProviderLogistic = res.data
        //     logisticList[index].shopLogisticSelected = { ...res.data[0] }
        //     // logisticList[index].shopProviderLogistic = res.data
    }
    yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'logisticList', value: logisticList } })


    yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'providerShopLogistic', value: providerShopLogistic } })
    // yield put({ type: SET_LOADING, payload: { loading: false } })
    // yield put({type: SET_LOADING,payload:{loading:false}})
}



export function* getInitialEditVod(action) {

    const { postId } = action.payload;

    yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'productsByShop', value: '' } });
    yield putWait({ type: GET_POST_VOD_BY_ID, payload: { postId } });
    yield putWait({ type: GET_ALL_PRODUCTS_BY_SHOP, payload: {} });

}

export function* handleGetBrandProduct(action) {
    const { payload } = action
    const { data } = yield call(brandProvider.getBrandByShopId, payload)
    const productState = yield select(getProduct)
    const brandSelected = data.data.filter((item) => item.id === productState.currentProduct?.brandId)
    yield put({ type: ADD_VALUE_IN_PRODUCT, payload: { key: 'brand', value: { label: brandSelected[0]?.name, value: brandSelected[0] } } })
}

export function* handleGetProductCategories(action) {
    try {
        const { payload } = action
        const { data } = yield call(api_product.getProductCategoriesByProductId, payload.productId);
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'productCategory', value: data } })
    } catch (error) {
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'productCategory', value: { categoryPath: '' } } })
        console.log(error)
    }
}



export default function* useWatcher() {
    yield takeLatest(GET_LIST_PRODUCT, onGetListProduct)
    yield takeLatest(CREATE_PRODUCT_SKU, withCallback(onCreateProductSku))
    yield takeLatest(HANDLE_CRAETE_PRODUCT, onCreateProduct)
    yield takeLatest(GET_PRODUCTS_BY_SHOP, withCallback(onGetProductsByShop))
    yield takeLatest(GET_ALL_PRODUCTS_BY_SHOP, onGetAllProductsByShop)
    yield takeLatest(GET_SEARCH_PRODUCTS_BY_SHOP, onGetSearchProductsByShop)
    yield takeLatest(GET_PRODUCT_CATEGORIES, getListAllCategories)
    yield takeLatest(ON_GET_BRANDS, onGetBrands)
    yield takeLatest(CREATE_PRODUCT_LOGISTIC, withCallback(createProductLogistic))
    yield takeLatest(HANDLE_SEARCH_PRODUCT, onSearchProduct)
    yield takeLatest(HANDLE_SEARCH_PRODUCT_FILTER_PLAYLIST, onSearchProductFilterPlaylist)
    yield takeLatest(HANDLE_SEARCH_SHOP_PRODUCT, onSearchShopProduct)
    yield takeLatest(GET_PRODUCT_ATTRIBUTE, onGetProductAttributes)
    yield takeLatest(GET_PRODUCT, onGetProduct)
    yield takeLatest(UPDATE_PRODUCT_SKU, withCallback(onUpdateProductSku))
    yield takeLatest(UPDATE_PRODUCT_BY_ID, onUpdateProductById)
    yield takeLatest(UPDATE_PRODUCT_LOGISTICS, withCallback(onUpdateProductLogistics))
    yield takeLatest(GET_LIST_PRODUCT_LOGISTICS_BY_PRODUCT_ID, onGetListProductByProductId)
    yield takeLatest(GET_DATA_PRODUCT_PAGE, ongetDataProductPage)
    yield takeLatest(VALIDATE_STOCK_REMAIN, onValidateStockRemain)
    yield takeLatest(HANDLE_DELETE_PRODUCT, onDeleteProduct)
    yield takeLatest(GET_LIST_LOGISTICS_BY_SHOP, getListLogisticsByShop)
    yield takeLatest(GET_LIST_PROVIDER_SHOP_LOGISTIC, getListProviderShopLogistic)
    yield takeLatest(GET_INITIAL_EDIT_VOD, getInitialEditVod)
    yield takeLatest(GET_BRAND_BY_SHOP, handleGetBrandProduct)
    yield takeLatest(GET_CATEGORIES_BY_PRODUCT_ID, handleGetProductCategories)
    yield takeLatest(GET_LIST_PRODUCT_PROMOCODE, onGetListProductPromocode)

}
