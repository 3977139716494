import {
  GET_LIST_PROMOCODE, OPEN_MODAL_ADD_PROMOCODE,
  // CLOSE_MODAL_ADD_PROMOCODE, 
  SET_TAB_PROMOCODE, SET_KEY_VALUE_PROMOCODE, OPEN_MODAL_CONFIRM_PROMOCODE, OPEN_MODAL_MESSAGE_PROMOCODE,
  SET_DEFAULT_DATA_PROMOCODE, ADD_PRODUCT_PROMOCODE, ADD_CHECKED_PRODUCT_PROMOCODE, REMOVE_PRODUCT_PROMOCODE, REMOVE_CHECKED_PRODUCT_PROMOCODE,
  ADD_ITEMJOIN_PROMOCODE
} from '../actions/promocode'

const initialState = {
  //   promocode: null,
  activeTab: '',
  kw: '',
  modalDetailPromocode: {
    isOpen: false,
    data: null
  },
  modalConfirm: {
    isOpen: false,
    message: null,
    confirm: null,
    isClose: null
  },
  page: 1,
  limit: 10,
  total: null,
  pageSizeOptions: [10, 20, 40],
  modalMessage: {
    isOpen: '',
    message: '',
    btnLabel: 'ตกลง',
    title: ''
  },
  promotionId:0,
  managePromocode: '',
  clientLevel: [],
  couponDetail: null,
  statusSearch: false,
  listProduct: {},
  listCheckedProduct: {},
  itemJoin:[],
  isShowModalCheckedProduct:false,
  isShowModalSelectedProduct: false,
  shopSelected: null,
  isShowModalListSkuSelectedShop: false,
  isShowModalListShopProductPromocode: false,
  isShowModalListShopSelectedShop: false,
  shopSelectedDetail: {}
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {

    case SET_KEY_VALUE_PROMOCODE: {
      return {
        ...state,
        [payload.key]: payload.value
      }
    }

    case GET_LIST_PROMOCODE: {
      return {
        ...state,
        promocode: payload,
      }
    }
    

    case ADD_ITEMJOIN_PROMOCODE: {
      return {
        ...state,
        itemJoin: payload,
      }
    }

    case ADD_PRODUCT_PROMOCODE: {
      Object.keys(payload).map((key) => {
        delete state.listProduct[key]
      })

      return {
        ...state,
        listProduct: { ...payload, ...state.listProduct },
      }
    }


    case REMOVE_PRODUCT_PROMOCODE: {
      const listProduct = state.listProduct;
      delete listProduct[payload]
      return {
        ...state,
        listProduct: listProduct,
      }
    }
    case ADD_CHECKED_PRODUCT_PROMOCODE: {
      return {
        ...state,
        listCheckedProduct: { ...payload, ...state.listCheckedProduct },
      }
    }
    case REMOVE_CHECKED_PRODUCT_PROMOCODE: {
      const listCheckedProduct = state.listCheckedProduct;
      delete listCheckedProduct[payload]
      return {
        ...state,
        listCheckedProduct: listCheckedProduct,
      }
    }


    case OPEN_MODAL_ADD_PROMOCODE: {
      return {
        ...state,
        modalDetailPromocode: {
          ...state.modalDetailPromocode,
          isOpen: payload.isOpen,
          data: payload.data
        }
      }
    }

    case SET_TAB_PROMOCODE: {
      return {
        ...state,
        activeTab: payload
      }
    }

    case OPEN_MODAL_CONFIRM_PROMOCODE: {
      return {
        ...state,
        modalConfirm: {
          isOpen: payload.isOpen,
          message: payload.message,
          confirm: payload.confirm,
          isClose: payload.isClose
        }
      }
    }

    case OPEN_MODAL_MESSAGE_PROMOCODE: {
      return {
        ...state,
        modalMessage: {
          isOpen: payload.isOpen,
          message: payload.message,
          title: payload.title
        }
      }
    }

    case SET_DEFAULT_DATA_PROMOCODE: {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}
