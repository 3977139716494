/* eslint-disable jsx-a11y/alt-text */

/* eslint-disable*/

/* eslint-disable eqeqeq */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  //  Switch, Route, 
  Redirect
} from 'react-router-dom';
import { withRouter } from 'react-router'
import { Container } from 'reactstrap';
import '../styles/base.scss';
import './full.scss';
import Loading from '../components/Loading';
import Breadcrumb from "./Breadcrumb/Breadcrumb";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
// import Footer from "./Footer/Footer";
import { isAuth } from '../index'
import ModalMessage from '../components/modalMessage'
import { CLOSE_MODAL_MESSAGE, dispatchModalMessage } from "redux/actions/modalMessage";
import ModalConfirm from "components/modalConfirm/ModalConfirm";
import {
  checkDuplicateArr,
  getUserRole,
  // getLocalStorage, 
  setLocalStorage
} from 'helper/utils'
import ToastNotification from "components/ToastNotification";
import user from "redux/reducers/user";
import { SUPER_ADMIN } from "./Sidebar/Role";
import PrivateRoute from "PrivateRoute";
import ModalAnnounce from "components/ModalAnnounce/modalAnnounce";
import { OPEN_MODAL_DISPLAY_ANNOUNCE, dispatchAnnounce } from "redux/actions/announce";

const setTempUrl = () => {
  setLocalStorage({ key: 'tempUrl', value: window.location.pathname })
  return <Redirect to='/login' />
}
class Full extends Component {

  componentDidMount() {
    this.FetchAnnounce()
  }

  /* Announce */
  FetchAnnounce = () => {
    try {
      this.props.dispatch(
        dispatchAnnounce({ type: OPEN_MODAL_DISPLAY_ANNOUNCE, payload: { isOpen: true } }),
      )
    } catch (error) {
      console.log(`error:`, error);
    }
  }
  /* Announce */

  render() {
    const { modalConfirm, permission, location, announce } = this.props
    const { modalDisplayAnnounce } = announce
    return isAuth ? (
      <React.Fragment>
        <div className='app'>
          <Header {...this.props} />
          <Sidebar {...this.props} />
          <section id='content'>
            <Breadcrumb {...this.props} />
            <Container fluid>
              <Loading />
              {this.props.children}
            </Container>
          </section>
        </div>
        <ModalMessage
          isShowModalMessage={this.props.modalMessage.isShowModalMessage}
          title={this.props.modalMessage.title}
          message={this.props.modalMessage.message}
          confirmCallback={() =>
            this.props.dispatch(
              dispatchModalMessage({ type: CLOSE_MODAL_MESSAGE }),
            )
          }
        />

        <ModalConfirm
          isOpen={modalConfirm.isOpen}
          message={modalConfirm.message}
          btnConfirm={modalConfirm.btnConfirm}
          btnCancel={modalConfirm.btnCancel}
          confirm={modalConfirm.confirm}
          isClose={modalConfirm.isClose}
          message2={modalConfirm.message2}
          type={modalConfirm.type}
        />
        <ToastNotification />

        <ModalAnnounce
          isShowModal={modalDisplayAnnounce?.isOpen}
          data={modalDisplayAnnounce?.data}
        />
      </React.Fragment>
    )
      :
      // getUserRole === 'brand' ? (
      <Redirect to={'/login'} />
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  modalMessage: state.modalMessage,
  modalConfirm: state.modalConfirm.modalConfirm,
  announce: state.announce,
});

export default connect(mapStateToProps)(withRouter(Full))
