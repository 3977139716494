import React, { useEffect } from "react";
import DateTimePicker from "components/DateTimePicker";
import Radio from "components/RadioButton";
import moment from "moment";

/* -------------------------------------------------------------------------- */
/*                      Component Date and Time selection                     */
/* -------------------------------------------------------------------------- */
/* 
                         <DateTimeSelection date={date} 
                                time={time} 
                                sending={sending}
                                onDateTimeChange={this.onDateTimePickerChange}
                                onSendingChange={this.onSendingChange}
                            />
*/
/* -------------------------------------------------------------------------- */
const sendingOption = {
    PRESET: { value: "PRESET", label: "ตั้งค่าล่วงหน้า" },
    IMMEDIATELY: { value: "IMMEDIATELY", label: "ส่งทันที" },
};

function DateTimeSelection(props) {
  const { time, date, sending, onDateTimeChange, onSendingChange } = useDateTimeSelection(props);
  
  return (
    <section className="section date-time-sending">

      <div className="row mb-4">
        <div className="col-1">
          <Radio
            value={sendingOption.PRESET.value}
            className="mr-4"
            valueOption={sending}
            name="sending"
            onChange={onSendingChange}
          />
        </div>
        <div className="col-8">
          <label className="form-check-label mr-4 mb-2"> {sendingOption.PRESET.label} </label>
          <DateTimePicker
            date={date}
            time={time}
            onChange={(key, value) => onDateTimeChange(key, value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-1">
          <Radio
            value={sendingOption.IMMEDIATELY.value}
            className="mr-4"
            valueOption={sending}
            name="sending"
            onChange={onSendingChange}
          />
        </div>
        <div className="col-8">
          <label className="form-check-label mr-4"> {sendingOption.IMMEDIATELY.label} </label>
        </div>
      </div>
    </section>
  );
}

export default DateTimeSelection;

/* -------------------------------------------------------------------------- */
/*                          Hook: DateTimeSelection                           */
/* -------------------------------------------------------------------------- */
export const useDateTimeSelection = (props) => {
  const [time, setTime] = React.useState(moment());
  const [date, setDate] = React.useState(moment());
  const [sending, setSending] = React.useState(sendingOption.IMMEDIATELY.value);

  useEffect(() => {
     if(props.time) setTime(props.time);
     if(props.date) setDate(props.date);
     if(props.sending) setSending(props.sending);
  }, [props.time, props.date, props.sending])

  const onDateTimeChange = (key, value) => {
    props.onDateTimeChange(key, value);
    if (key === "date") setDate(value);
    if (key === "time") setTime(value);
  };

  const onSendingChange = (event) => {
    const value = event.target.value;
    props.onSendingChange(value);
    if(value) setSending(value);
  }

  return {
    time,
    setTime,
    date,
    setDate,
    sending,
    setSending,
    onDateTimeChange,
    onSendingChange
  };
};
