import React from "react";
import "./index.scss";

const ToggleInput = (props) => {
  let { value, 
        onChange, 
        name,
        // params,
        labelOn = 'เปิดใช้งาน', 
        labelOff= 'ปิดใช้งาน' } = props;

  return (
    <div className="toggle-input">
      <div className="toggle-green d-flex justify-content-start align-items-center" style={{ gap: "10px" , width: '100%'}} >
        <label className="switch">
          <input
            type="checkbox"
            checked={value}
            // onChange={() => onChange(value, params)}
            onChange={onChange}
            name={name}
          />
          <span className="slider round"></span>
        </label>
        {
          value ?
          <span className="color-success"> {labelOn} </span>
          :
          <span> {labelOff} </span>
        }
      </div>
    </div>
  );
};

export default ToggleInput;
