/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import host from 'config/host';
import { displayDateThShortYear } from 'helper/timeUtil';

import { ButtonAdd, InputSearch } from "components/output";
import Loading from 'components/Loading';
import DropDownPlaylist from "components/DropdownPlaylist";
import PaginationComponent from 'components/pagination';
import ModalAlert from 'components/modalAlert/ModalAlert';

import {
  SET_MODAL_ADD_PLAYLIST_MALL,
  GET_PLAYLIST_COUPON,
  CREATE_PLAYLIST_COUPON_ITEM,
  DELETE_COUPON_ITEM,
  UPDATE_PLAYLIST_COUPON,
  ADD_KEY_VALUE_PLAYLISTS
} from 'redux/actions/playlists';

import {
  // dispatchPromocode,
  SET_KEY_VALUE_PROMOCODE,
  GET_LIST_PROMOCODE
} from 'redux/actions/promocode';
import {
  OPEN_MODAL_ALERT,
} from 'redux/actions/modalAlert';
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from "redux/actions/modalConfirm";

import "./style.scss";
import IconClose from "images/icons/close.svg";
import IconTrash from "images/icons/trash.svg";
import IconEmpty from 'images/icons/status-error.svg';
import { ReactComponent as IconPercent } from 'images/icons/logo-percent.svg';
import { ReactComponent as IconBath } from 'images/icons/logo-bath.svg';
import Checkbox from "components/Checkbox";
import globalProvider from "provider/globalProvider";
import classNames from "classnames";
import playlistsProvider from "provider/playlistsProvider";

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */

const EmptyItem = (props) => {
  return <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
    <img src={IconEmpty} alt='icon-empty'/>
    <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
  </div>
}

const TableCouponList = (props) => {
  const { couponList, dropDownPlaylist, handleSwitchIndex, handleDeleteCoupon } = props;
  return (
    <div className="container-table">
      <div className="table-header">
        <div className="text-center">ชื่อคูปองส่วนลด</div>
        <div className="text-right">มูลค่าส่วนลด</div>
        <div className="text-center">ระยะเวลาใช้</div>
      </div>
      {couponList &&
        couponList.length > 0 ?
        couponList.map((coupon, index) => (
          <RowCouponList
            key={`RowCouponList${index}`}
            coupon={coupon}
            dropDownPlaylist={dropDownPlaylist}
            handleSwitchIndex={handleSwitchIndex}
            handleDeleteCoupon={handleDeleteCoupon}
          />
        ))
        :
        <EmptyItem />
      }
    </div>
  );
};

const RowCouponList = (props) => {
  const { dropDownPlaylist, handleDeleteCoupon, handleSwitchIndex, coupon } = props;
  const { startDate, stopDate, order, detail, promotion } = coupon || {};
  return (
    <div className="table-row">
      <div className="d-flex align-items-center text-left flex-row mr-2 ml-2">
        <DropDownPlaylist
          options={dropDownPlaylist}
          defaultValue={order}
          handleSelected={(orderNumber) => handleSwitchIndex(orderNumber, coupon)}
        />
        <div className='p-0 ml-4'>
          {detail?.discountUnit === 'number' && <IconBath className='mr-2' />}
          {detail?.discountUnit === 'percent' && <IconPercent className='mr-2' />}
        </div>
        <div className='d-flex flex-column text-left p-0'>
          <span>{promotion?.name}</span>
          <span>{detail?.productCode}</span>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end flex-row">
        {detail?.discountValue} {detail?.discountUnit === 'number' ? 'บาท' : '%'}
      </div>
      <div className="d-flex align-items-center justify-content-center flex-row">
        {displayDateThShortYear(promotion.startDate, 'DD/MM/YYYY HH:mm')}<br />
        {displayDateThShortYear(promotion.stopDate, 'DD/MM/YYYY HH:mm')}
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <ButtonDeleteShop
          handleDeleteCoupon={() => handleDeleteCoupon(coupon)}
        />
      </div>
    </div>
  );
};

const ButtonDeleteShop = (props) => {
  const { handleDeleteCoupon } = props;
  return (
    <a className="clickable" onClick={handleDeleteCoupon}>
      <img src={IconTrash} alt='icon-trash' />
    </a>
  );
};

const TableAddCoupon = (props) => {
  const { couponList, handleSelectCoupon, pageOffset } = props;

  return (
    <div className="container-table">
      <div className="table-header">
        <div></div>
        <div className="text-left">ชื่อคูปองส่วนลด</div>
        <div className="text-center">มูลค่าส่วนลด</div>
        <div className="text-center">ระยะเวลาใช้</div>
      </div>
      {couponList &&
        couponList.length > 0 &&
        couponList.map((coupon, index) => (
          <RowAddCoupon
            key={`RowAddCoupon${index}`}
            coupon={coupon}
            index={pageOffset + (index + 1)}
            handleSelectCoupon={handleSelectCoupon}
          />
        ))}
    </div>
  );
};

const RowAddCoupon = (props) => {
  const { handleSelectCoupon, coupon, 
    // index 
  } = props;
  const { name, id, isSelected, detail, startDate, stopDate } = coupon || {};
  return (
    <div className="table-row">
      <div className="d-flex align-items-center justify-content-center">
        <Checkbox
          title={""}
          checked={isSelected}
          value={id}
          onChange={() => handleSelectCoupon(coupon)}
          name={name}
        />
      </div>
      <div className="d-flex align-items-center text-left flex-row">
        <div className='p-0'>
          {detail?.discountUnit === 'number' && <IconBath className='mr-2' />}
          {detail?.discountUnit === 'percent' && <IconPercent className='mr-2' />}
        </div>
        <div className='d-flex flex-column text-left p-0'>
          <span>{name}</span>
          <span>{detail?.productCode}</span>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center flex-row">
        {detail?.discountValue} {detail?.discountUnit === 'number' ? 'บาท' : '%'}
      </div>
      <div className="d-flex align-items-center justify-content-center flex-row">
        {displayDateThShortYear(startDate, 'DD/MM/YYYY HH:mm')}<br />
        {displayDateThShortYear(stopDate, 'DD/MM/YYYY HH:mm')}
      </div>
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                             main component                                 */
/* -------------------------------------------------------------------------- */
const ModalAddCoupon = (props) => {

  const {
    isOpen,
    couponList,
    keyword,
    statusSearch,
    pageSizeOptions,
    total,
    page,
    limit,
    toogle,
    // setKeyword,
    handleSearchCoupon,
    handleSearchKeyPress,
    handleClearSearch,
    handleSelectCoupon,
    handleAddCouponToPlaylist,
    handleShowSizeChange,
    handlePageChange,
    handleSearchCouponChange
  } = useModalAddCoupon(props);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toogle}
      backdrop="static"
      style={{ width: "80%", maxWidth: "80%" }}
    >
      <ModalHeader toggle={toogle}>
        <div className="d-flex justify-content-between w-100">
          <div>
            <span className="header-lable"> เลือกคูปองลงเพลย์ลิสต์ </span>
          </div>
          <div className="close-btn-wrap">
            <img src={IconClose} onClick={toogle} alt='icon-close'/>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Loading />
        <div className="container-modal-add-mall-coupon p-4">
          <div className="row mb-4">
            <div className="col-5">
              <InputSearch
                placeholder={"ค้นหาร้านค้า"}
                onChange={(e) => handleSearchCouponChange(e.target.value)}
                onKeyPress={handleSearchKeyPress}
                handleSearch={handleSearchCoupon}
                onClearSearch={handleClearSearch}
                statusSearch={statusSearch}
                defaultValue={keyword}
              />
            </div>
          </div>
          {couponList && couponList.length > 0 &&
            <div className="d-flex w-100 justify-content-end mb-4">
              <PaginationComponent
                defaultCurrent={1}
                total={total}
                onChange={(page) => handlePageChange(page)}
                pageSize={limit}
                pageSizeOptions={pageSizeOptions}
                onShowSizeChange={handleShowSizeChange}
                current={page}
                locale={{ items_per_page: '/ หน้า' }}
              />
            </div>
          }
          <TableAddCoupon
            couponList={couponList}
            pageOffset={limit * (page - 1)}
            handleSelectCoupon={handleSelectCoupon}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center w-100 flex-row">
          <button className="btn-cancel mr-3" onClick={toogle}>
            ยกเลิก
          </button>
          <button className="btn-submit" onClick={handleAddCouponToPlaylist}>
            เพิ่มลงเพลย์ลิส
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const PlaylistCoupon = (props) => {

  const { couponList,
    dropDownPlaylist,
    playlistsId,
    playlistsName,
    playlistsType,
    handleDeleteCoupon,
    handleSwitchIndex,
    handleOpenModalAddCoupon,
    // listSortType,
    // sortDisplay,
    // handleSetSortType 
  } = usePlaylistCoupon(props);

  return (
    <div className="container-playlist-coupon">
      <div className="d-flex justify-content-between">
        <h3>{playlistsName}</h3>
        <div className="mb-1">
          <ButtonAdd label="เพิ่มคูปองลงเพลย์ลิสต์" handleAdd={handleOpenModalAddCoupon} />
        </div>
      </div>

      {/* <div className='playlist-coupon-filter'>
        <span>รูปแบบการแสดงผลในหน้าเว็บ</span>
        {listSortType && listSortType.map((list, listIndex) => (
          <React.Fragment key={`sortFilter${listIndex}`}>
            <li
              className={classNames({ 'active': sortDisplay === list.value.name })}
              onClick={() => handleSetSortType(list.value.name)}>
              {list.label}
            </li>
          </React.Fragment>
        ))}
      </div> */}


      <TableCouponList
        couponList={couponList}
        dropDownPlaylist={dropDownPlaylist}
        handleDeleteCoupon={handleDeleteCoupon}
        handleSwitchIndex={handleSwitchIndex}
      />
      <ModalAddCoupon playlist={{ playlistsId, playlistsType }} />
      <ModalAlert />
    </div>
  );
};



/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const usePlaylistCoupon = (props) => {
  const dispatch = useDispatch();
  const { playlistsId, playlistsName, playlistsType } = props.playlist;

  const [couponList, setCouponList] = useState([]);
  const [dropDownPlaylist, setDropDownPlaylist] = useState([]);
  const [listSortType, setSortType] = useState(null)
  const [sortDisplay, setSortDisplay] = useState(null)

  const playlistCoupon = useSelector((state) => state.playlists.playlistCoupon);

  // initial component
  useEffect(() => {
    initialComponent()
    return () => dispatch({ type: ADD_KEY_VALUE_PLAYLISTS, payload: { key: 'playlistCoupon', value: null } })
  }, [])

  useEffect(() => {
    // set up shop list
    if (playlistCoupon) setCouponList(playlistCoupon);
  }, [playlistCoupon])

  useEffect(() => {
    // set up dropdown playlist
    if (couponList) {
      setDropDownPlaylist(getDropDownPlayListOption(couponList));
      setSortDisplay(couponList[0]?.sortType)
    }
  }, [couponList]);

  const initialComponent = async () => {
    getPlaylistCoupon()
    await getListSortType()
  }

  // TODO - saga get playlist coupon
  const getPlaylistCoupon = () =>
    dispatch({
      type: GET_PLAYLIST_COUPON,
      payload: { playlistId: playlistsId }
    })

  const getDropDownPlayListOption = (couponList) => {
    if (couponList && couponList.length > 0)
      return couponList.map((coupon) => ({ name: `${coupon.order}`, value: coupon.order }));
    else
      return [];
  }

  const getPlaylistShopCoupon = () => dispatch({ type: GET_PLAYLIST_COUPON, payload: { playlistId: playlistsId } })

  const getListSortType = async () => {
    const getListSortType = await globalProvider.getListSortType()
    const listSortType = await Promise.all(getListSortType.data.map((list, listIndex) => (
      {
        label: list.nameTh,
        value: list
      }
    )))
    setSortType(listSortType)
    // setSortDisplay(listSortType)
  }

  const handleSetSortType = async (value) => {
    const body = { sortType: value }
    const data = await playlistsProvider.updatePlaylists({ body, playlistsId })
    if (data) {
      setSortDisplay(data.sortType)
      getPlaylistShopCoupon()
    }
  }

  const handleDeleteCoupon = (coupon) => {

    dispatch(dispatchModalConfirm({
      type: OPEN_MODAL_CONFIRM,
      payload: {
        isOpen: true,
        message: 'คุณแน่ใจหรือไม่ที่จะลบข้อมูลออกจากเพลย์ลิสต์',
        btnConfirm: 'ยืนยัน',
        btnCancel: 'ยกเลิก',
        confirm: () => {
          dispatch({
            type: DELETE_COUPON_ITEM,
            payload: {
              playlistCouponId: coupon.id,
              playlistId: playlistsId,
              playlistsType: playlistsType
            }
          })
        },
        isClose: () => {
          dispatch(dispatchModalConfirm({
            type: OPEN_MODAL_CONFIRM, payload: {
              isOpen: false
            }
          }))
        }
      }
    }))

  };

  const handleSwitchIndex = (orderNumber, coupon) => {
    dispatch({
      type: UPDATE_PLAYLIST_COUPON,
      payload: {
        playlistCouponId: coupon.id,
        orderNumber,
        playlistId: playlistsId,
        playlistsType: playlistsType
      }
    })
  };

  const handleOpenModalAddCoupon = () => {
    dispatch({ type: SET_MODAL_ADD_PLAYLIST_MALL, payload: { isOpen: true } })
  }

  return {
    couponList,
    playlistsId,
    playlistsName,
    playlistsType,
    dropDownPlaylist,
    handleOpenModalAddCoupon,
    handleDeleteCoupon,
    handleSwitchIndex,
    listSortType,
    sortDisplay,
    setSortDisplay,
    handleSetSortType
  };
};

const useModalAddCoupon = props => {
  const { playlistsId, 
    // playlistsType 
  } = props.playlist;
  const dispatch = useDispatch();
  const modalAddPlayListItem = useSelector(state => state.playlists.modalAddPlayListItem);
  const { isOpen } = modalAddPlayListItem;
  const pageSizeOptions = ["10", "20", "40"];

  const [statusSearch, setStatusSearch] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [couponList, setCouponList] = useState([]);
  const [couponSelected, setCouponSelected] = useState([]);
  // const itemPerPage = 10;

  const toogle = () => dispatch({ type: SET_MODAL_ADD_PLAYLIST_MALL, payload: { isOpen: false } })
  const { promocode, page, limit, total } = useSelector((state) => state.promocode);
  const { shopSelected } = useSelector((state) => state.shop);

  useEffect(() => {
    if (isOpen && isOpen === true)
      initialComponent();
    else
      resetComponent();
  }, [isOpen]);

  useEffect(() => {
    if (promocode)
      initialCouponList(promocode);
  }, [promocode]);

  const initialComponent = () => getCoupons(1, 10);

  const initialCouponList = (couponItems) => {
    // add field isSelected
    if (couponItems && couponItems?.count > 0) {
      const couponsSelectedId = couponSelected.map(coupon => coupon.id);
      const newCoupon = couponItems.data.map((coupon) => {
        // match item with coupon selected
        const isMatchForCouponSelected = couponsSelectedId.indexOf(coupon.id) !== -1;
        coupon = { ...coupon, isSelected: isMatchForCouponSelected }
        return coupon;
      });
      setCouponList(newCoupon);
    }
  };

  const resetComponent = () => {
    setStatusSearch(false);
    setKeyword("");
    setCouponList([]);
    setCouponSelected([]);
  }

  const getCoupons = (page, pageLimit) => {
    // TODO - saga get coupon list
    if (pageLimit)
      dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'limit', value: pageLimit } });

    let payload = {}
    if (shopSelected) payload = { ...payload, shopId: shopSelected.id };

    dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'page', value: page } });
    dispatch({ type: GET_LIST_PROMOCODE, payload });
  }

  const handleShowSizeChange = (page, pageSize) => {
    // TODO - saga handle page and limit
    dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'limit', value: pageSize } });
    dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'page', value: page } });
  }

  const handlePageChange = page => getCoupons(page)

  const handleSearchCouponChange = (KeyWord) => {
    setKeyword(KeyWord);
    dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'kw', value: KeyWord } });
  }

  const handleSearchCoupon = () => {
    // TODO - handle search coupon
    if (keyword.trim().length === 0) {
      setStatusSearch(false);
      getCoupons(1);
    } else {
      setStatusSearch(true);
      dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'page', value: 1 } });
      getCoupons(1);
    }

  };

  const handleSearchKeyPress = event => {
    if (event.key === "Enter")
      handleSearchCoupon();
  }

  const handleClearSearch = () => {
    setKeyword("");
    dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'kw', value: '' } });
    initialComponent();
  }

  const handleSelectCoupon = (selectedCoupon) => {

    // switch check box value
    const couponListUpdated = couponList.map((coupon) => {
      if (coupon.id === selectedCoupon.id)
        coupon.isSelected = !coupon.isSelected;
      return coupon;
    });

    setCouponList(couponListUpdated);

    // store coupon selected id
    if (selectedCoupon.isSelected === true)
      setCouponSelected([...couponSelected, selectedCoupon]);
    else
      setCouponSelected([...couponSelected].filter(coupon => coupon.id !== selectedCoupon.id));

  };

  const handleAddCouponToPlaylist = () => {
    if (validateCouponSelected()) {
      const couponSelectedId = couponSelected.map(couponItem => couponItem.coupon.id);
      dispatch({
        type: CREATE_PLAYLIST_COUPON_ITEM,
        payload: { data: { couponIds: couponSelectedId, playlistId: playlistsId } }
      })
    }
  };

  const validateCouponSelected = () => {
    if (couponSelected && couponSelected.length < 1) {
      openModalAlert()
      return false;
    } else {
      return true;
    }
  }

  const openModalAlert = () => {
    dispatch({
      type: OPEN_MODAL_ALERT,
      payload: {
        isOpen: true, message: 'กรุณาเลือกมอล์ลก่อนเพิ่มลงเพลลิสต์',
        type: 'alert'
      }
    });
  }

  return {
    isOpen,
    couponList,
    keyword,
    statusSearch,
    pageSizeOptions,
    total,
    page,
    limit,
    toogle,
    setKeyword,
    handleSearchCoupon,
    handleSearchKeyPress,
    handleClearSearch,
    handleSelectCoupon,
    handleAddCouponToPlaylist,
    handleShowSizeChange,
    handlePageChange,
    handleSearchCouponChange
  }
}

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default PlaylistCoupon;



