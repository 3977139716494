import { Radio } from 'antd'
import classNames from 'classnames'
import { Body2, Body1 } from 'components/FontStyle'
import host from 'config/host'
import React from 'react'
import styled, { css } from 'styled-components'


const Container = styled.div`
    padding: 1em 7em;
    background-color: white;
    margin-bottom: 1em;
    filter: ${props => props.disabled ? 'grayscale(1)' : 'none'};

    @media only screen and (max-width: 991px){
        padding: 1em 1em;
    }
`
const Box = styled.div`
    display: flex;
    margin-top: 1em;
    flex-flow: row wrap;

    @media only screen and (max-width: 380px){
        padding: 1em 1em;
        flex-flow: column;
    }
`
const Frame = styled.div`
    /* display: flex; */
    flex: 0.2 1 auto;
`
const Img = styled.img`
    ${({ theme }) => css`
        width: 76px;
        height: 76px;
        border: 1px solid ${theme.disableGray};
        object-fit: cover;
    `}
`
const Detail = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`
const DetailContent = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 0 1em 1em;

    @media only screen and (max-width: 380px){
        flex-direction: column;
    }
`
const Label = styled.div`
    width: 100px;
`
const Value = styled.div`
    flex-grow: 1;
`

const onChangeTypeLimit = (key, value, index, sku, props) => {
    props.handleChangeSKUlimit(key, value, index, sku)
}

// const onChangeQuantity = (key, value, index, sku, props) => {
//     const value = e.target.value
//     console.log(`value`, value)
// }

const LimitProductOption = (props) => {
    const { image, index, sku, productSKU, statusLimit, errors } = props
    return (
        <Container disabled={!statusLimit}>
            <Body1>ตัวเลือกสินค้า {index + 1} รหัสสินค้า SKU : {sku}</Body1>
            <Box>
                <Frame className="">
                    <Img src={host.image + image} alt="img-sku" />
                </Frame>

                <Detail>
                    <DetailContent>
                        <Label>จำกัดจำนวน</Label>
                        <Radio.Group
                            onChange={(e) => onChangeTypeLimit('typeLimit', e.target.value, index, productSKU, props)}
                            value={productSKU.typeLimit}
                            className='font-primary'
                            disabled={!statusLimit}
                        >
                            <Radio value={'limit'}>
                                <Body2 style={{ fontWeight: '100' }}>จำกัด</Body2>
                            </Radio>
                            <Radio value={'unlimit'}>
                                <Body2 style={{ fontWeight: '100' }}>ไม่จำกัด</Body2>
                            </Radio>
                        </Radio.Group>
                    </DetailContent>
                    {productSKU.typeLimit === 'limit' &&
                        <DetailContent>
                            <Label>ระบุจำนวน</Label>
                            <Value>
                                <input type="number"
                                    className={classNames('form-control', { 'border-error': errors && errors['sku' + index] })}
                                    value={productSKU.limitQuantity}
                                    onChange={(e) => onChangeTypeLimit('limitQuantity', parseInt(e.target.value), index, productSKU, props)}
                                    disabled={!statusLimit}
                                />
                                {errors && errors['sku' + index] && <span className="text-danger">{errors['sku' + index]}</span>}
                            </Value>
                        </DetailContent>
                    }
                </Detail>
            </Box>
        </Container >
    )
}

export default LimitProductOption