/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import IconCalendar from 'images/icons/calendar_ic.svg';
import './style.scss';


import calendar from 'images/icons/calendar.svg'

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */

const PeriodInput = (props) => {

    const {isToggle,
           periodDate,
           disabled, 
           setIsToggle} = props;
    
    const { onClick } = usePeriodInput(props);

    return <div className={`input-select ${disabled===true ? 'disabled' : ''}`}
                onClick={onClick}>
            <div>
                <img src={IconCalendar} alt="" />
                <span style={{paddingTop:2, marginLeft: 4}} className="topic">{`${periodDate?.type?.label}:`} </span>
                <span style={{paddingTop:5}}>{periodDate.label}</span>
            </div>
            <div className="arrow">
                <FontAwesomeIcon className="icon-angle" icon={isToggle ? faAngleUp : faAngleDown} />
            </div>
    </div>
}

/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const usePeriodInput = (props) => {

    const { disabled, setIsToggle, isToggle } = props;
    const onClick = () => {
        if(disabled && disabled === true) setIsToggle(false);
        else setIsToggle(!isToggle)
    }

    return {onClick}

}

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default PeriodInput;