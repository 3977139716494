import { Body1, Body2, H6, SubTitle2 } from 'components/FontStyle'
import DashBoardHeaderSelect from 'components/output/DashBoardHeaderSelect'
import PaginationComponent from 'components/pagination'
import moment from 'moment'
import dashboardProvider from 'provider/dashboardProvider'
import shopProvider from 'provider/shopProvider'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchApp, SET_LOADING } from 'redux/actions/app'
import { dispatchDashboard, SET_DEFAULT_DASHBOARD, SET_KEY_DASHBOARD } from 'redux/actions/dashboard'
import { ReactComponent as IconNoItem } from 'images/icons/no-item.svg';
import { generatePeriodDate, options } from 'components/PeriodTimeInput/dataUtil'
import 'styles/_topViewer.scss';
import numeral from 'numeral'

const mockAPISummaryProductView = {
    "data": {
        "count": 2,
        "product": [
            {
                "id": 675,
                "shop": {
                    "id": 22,
                    "name": "test test test test test test test test test test test test test test test test test test test test test test test test"
                },
                "name": "we bear bear 2 we bear bear 2 we bear bear 2 we bear bear 2 we bear bear 2 we bear bear 2 we bear bear 2 we bear bear 2 we bear bear 2 we bear bear 2",
                "totalViewer": 100000000000000,
                "coverPath": {
                    "type": "image",
                    "path": "image/b499e0af-2e48-4e9f-a664-4f358f8ad3f7.jpg",
                    "id": 1295,
                    "host": "https://secure-media.saranros.com/"
                },
                "imagePath": [
                    {
                        "type": "image",
                        "path": "image/b499e0af-2e48-4e9f-a664-4f358f8ad3f7.jpg",
                        "id": 1295,
                        "host": "https://secure-media.saranros.com/"
                    }
                ]
            },
            {
                "id": 2327,
                "shop": {
                    "id": 22,
                    "name": "test"
                },
                "name": "playstation",
                "totalViewer": 9,
                "coverPath": {
                    "type": "image",
                    "path": "image/b499e0af-2e48-4e9f-a664-4f358f8ad3f7.jpg",
                    "id": 1295,
                    "host": "https://secure-media.saranros.com/"
                },
                "imagePath": [
                    {
                        "type": "image",
                        "path": "image/b499e0af-2e48-4e9f-a664-4f358f8ad3f7.jpg",
                        "id": 1295,
                        "host": "https://secure-media.saranros.com/"
                    }
                ]
            }
        ]
    }
}


const TopViewer = () => {
    const dispatch = useDispatch()
    const dashboardState = useSelector(state => state.dashboard)
    const { shopSelected, fromDate, toDate, listShop, rangeDate } = dashboardState
    const [listAllShop, setListAllShop] = useState([])
    const [listTopView, setListTopView] = useState({ product: [], count: 0 })
    const [listTopViewAll, setListTopViewAll] = useState(null)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(null)
    const [totalTopView, setTotalTopView] = useState(0)
    const [headerExcel, setHeaderExcel] = useState(null)
    const [type, setType] = useState('top-view')
    const [isFirst, setIsFirst] = useState(true)
    const api_shops = new shopProvider()
    const totalViewer = totalTopView

    useEffect(() => {
        (async () => {
            if (listAllShop?.length > 0) {
                // await getListTopView()
            } else {
                await getListShop()
            }
        })()
        return () => {
            (async () => {
                new Promise(async (resolve, reject) => {
                    const initialRealTime = generatePeriodDate(options.REAL_TIME, { startDate: moment(), endDate: moment() })
                    // if (initialRealTime) {
                    //     await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initialRealTime.period.from).utc(0).format() } }))
                    //     await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initialRealTime.period.to).utc(0).format() } }))
                    //     await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: initialRealTime } }))
                    //     await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'shopSelected', value: 0 } }))
                    //     // await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'summaryOrder', value: false } }))
                    //     // await dispatch(dispatchDashboard({ type: SET_DEFAULT_DASHBOARD }))  
                    // }
                })
            })()
        }
    }, [listAllShop])


    useEffect(() => {
        (async () => {
            if (!dashboardState.isFromDashboard) {
                await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'shopSelected', value: 0 } }))
            }

            await getListShop()
            window.scrollTo(0, 0);
            // await getListTopView()
            // await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'summaryOrder', value: true } }))


            if (rangeDate) {
                const initDate = generatePeriodDate(rangeDate?.type, { startDate: rangeDate?.period?.from, endDate: rangeDate?.period?.to })
                if (initDate) {
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initDate.period.from).utc(0).format() } }))
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initDate.period.to).utc(0).format() } }))
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: rangeDate } }))
                }
            } else {
                const initialRealTime = generatePeriodDate(options.REAL_TIME, { startDate: moment(), endDate: moment() })
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: initialRealTime } }))
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initialRealTime.period.from).utc(0).format() } }))
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initialRealTime.period.to).utc(0).format() } }))
            }
        })()

        return () => {
            (async () => {
                new Promise(async (resolve, reject) => {
                    const initialRealTime = generatePeriodDate(options.REAL_TIME, { startDate: moment(), endDate: moment() })
                    if (initialRealTime) {
                        await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initialRealTime.period.from).utc(0).format() } }))
                        await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initialRealTime.period.to).utc(0).format() } }))
                        await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: initialRealTime } }))
                        await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'shopSelected', value: 0 } }))
                        // await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'summaryOrder', value: false } }))
                        // await dispatch(dispatchDashboard({ type: SET_DEFAULT_DASHBOARD }))  
                    }
                })

            })()
        }

    }, [])

    useEffect(() => {
        (async () => {
            // if(isFirst){
            //     setIsFirst(false)
            //     return
            // }
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            if (fromDate && toDate && page && limit && listAllShop?.length > 0) {
                await getListTopView()
            }
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        })()

        return () => {
            // console.log('unmount')
            // dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'shopSelected', value: 0 } }))
        }
    }, [
        shopSelected, rangeDate, fromDate, toDate, page, limit, listAllShop
    ])

    const getListShop = async () => {
        // dispatch({ type: SET_LOADING, payload: { loading: true } })
        const res = await api_shops.getAllShops()
        if (res) {
            const listShop = res.shop.map((shop) => {
                return { label: shop.name, value: shop, img: shop.logoPath }
            })

            // for user which have one shop
            if (res?.shop?.length > 1) {
                // dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'shopSelected', value: 0 } }))
                listShop.unshift(
                    {
                        label: 'ร้านทั้งหมด',
                        value: '',
                        img: null
                    })
            }
            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'listShop', value: listShop } }))
            setListAllShop(listShop)
        }
        // dispatch({ type: SET_LOADING, payload: { loading: false } })
    }

    const fetchDataExcel = async () => {
        const { shopSelected, fromDate, toDate, listShop, rangeDate } = dashboardState
        const paramsListAll = { fromDate, toDate, page: 1 }
        const excelArr = []
        // if (shopSelected) {
        //     const shopId = listShop[shopSelected]?.value?.id
        //     if (shopId) {
        //         const shopId = listShop[shopSelected]?.value?.id
        //         // params.shopId = shopId
        //         paramsListAll.shopId = shopId
        //     }
        // }
        try {
            if(listTopView?.count){
                let params = {
                    fromDate: fromDate,
                    toDate: toDate,
                    page: 1,
                    limit: listTopView?.count,
                }
                // if (paramsListAll?.shopId > -1) {
                    params.shopId = listShop[shopSelected]?.value?.id
                // }
                if(params.shopId === undefined){
                    delete params.shopId
                }

    
                const listTopViewAll = await dashboardProvider.getDashboardProductTopView(params)
                if (listTopViewAll?.data?.product?.length > 0) {
                    const data = listTopViewAll?.data?.product?.map((data, idx) => {
                        return excelArr.push({
                            no: idx + 1,
                            productName: data?.name,
                            shopName: data?.shop?.name,
                            totalViewer: data?.totalViewer,
                        })
                    })
                    return {
                        header: [
                            "ลำดับ",
                            "ข้อมูลสินค้า",
                            "ชื่อร้านค้า",
                            "จำนวนยอดวิว",
                        ],
                        data: excelArr
                    }
                }
            }
        } catch (error) {
            console.log(`error:`, error);
        }    
    }

    const getListTopView = async () => {
        try {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            if (listAllShop.length > 0) {
                let params = {
                    fromDate: fromDate,
                    toDate: toDate,
                    // shopId: listShop[shopSelected]?.value?.id,
                    page: page,
                    limit: limit,
                }

                if (listShop[shopSelected]?.value?.id) {
                    params.shopId = listShop[shopSelected]?.value?.id
                }

                let listTopView = {}
                try {
                    listTopView = 1 == 1 ? await dashboardProvider.getDashboardProductTopView(params) : { ...mockAPISummaryProductView }
                    if (!!listTopView?.data) {
                        setListTopView(listTopView?.data)
                        setTotal(listTopView?.data?.count)
                        setTotalTopView(listTopView?.data?.totalViewer)
                    }
                } catch (error) {
                    console.log(`error:`, error);
                }
            }
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))

        } catch (error) {
            console.log(`error:`, error);
        }
    }

    const onChangePage = (page) => {
        setPage(page)
    }

    const onShowSizeChange = (page, limit) => {
        setPage(page)
        setLimit(limit)
    }

    return (
        <div className='top-viewer'>
            <DashBoardHeaderSelect
                // headerExcel={headerExcel}
                // dataReport={listTopViewAll}
                type={type}
                setPage={setPage}
                fetchDataExcel={() => fetchDataExcel()}
                rangeDate={dashboardState.rangeDate}
            />

            <section className='d-flex flex-row bg-white' style={{ padding: '1em' }}>
                <div className='pl-0' style={{ padding: '0 2em' }}>
                    {type === 'top-view' && <React.Fragment><Body1>จำนวนยอดวิวทั้งหมด</Body1> <H6 className='font-weight-bold'>{numeral(totalViewer).format("0,0")}</H6></React.Fragment>}
                </div>
            </section>


            <section>
                <div className='table-top-viewer'>
                    <div className='text-center justify-content-center'>ลำดับ</div>
                    <div>ข้อมูลสินค้า</div>
                    <div>ชื่อร้านค้า</div>
                    <div className='text-right justify-content-end'>จำนวนยอดวิว</div>
                </div>
                <div className='content-top-viewer'>
                    {
                        listTopView?.product?.length === 0 ?
                            <div className='d-flex justify-content-center align-items-center flex-column p-4 mt-4 mb-4'>
                                <IconNoItem />
                                <span>ไม่มีรายการ</span>
                            </div> :
                            listTopView?.product.map((order, index) => {
                                const col1 = (page - 1) * limit + index + 1
                                const col2 = order?.name
                                const colImg2 = order?.imagePath[0]?.host + order?.imagePath[0]?.path || ""
                                const col3 = order?.shop?.name
                                const col4 = order?.totalViewer || 0
                                return (
                                    <div className='table-top-viewer-content' key={`${order?.orderNo + index}`}>
                                        <div className='text-center justify-content-center'><Body2>{numeral(col1).format('0,0')}</Body2></div>
                                        <div className='text-left gap-2 flex'>
                                            <img src={colImg2} alt={`cover-product-${col1}`} />
                                            <Body2>{col2}</Body2>
                                        </div>
                                        <div><Body2>{col3}</Body2></div>
                                        <div className='text-right justify-content-end'><Body2>{numeral(col4).format('0,0')}</Body2></div>
                                    </div>
                                )
                            })
                    }
                </div>
            </section>



            {listTopView?.product?.length > 0 &&
                <PaginationComponent
                    defaultCurrent={1}
                    total={total}
                    onChange={(page) => onChangePage(page)}
                    pageSize={limit}
                    pageSizeOptions={[10, 20, 30]}
                    onShowSizeChange={onShowSizeChange}
                    current={page}
                    locale={{ items_per_page: '/ หน้า' }}
                />
            }

        </div>
    )
}

export default TopViewer