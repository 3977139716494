/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import FormShopDetail from 'components/Form/ShopDetail'
// import IconEllipse from '../images/icons/ellipse.svg';
import IconLive from '../images/icons/live.svg'
import { ReactComponent as IconTrash } from '../images/icons/trash.svg'
import IconClock from '../images/icons/clock.svg'
// import { ReactComponent as IconIncrease } from '../images/icons/increse.svg'
import IconShoppingBag from '../images/icons/shoppingBag.svg'
import { ReactComponent as IconEdit } from '../images/icons/edit.svg'
import IconShopAvatar from 'images/icons/default-shop-logo.svg'
import { UncontrolledTooltip } from 'reactstrap';
import { ReactComponent as IconAlert } from '../images/icons/alert.svg'
import { ReactComponent as IconDownload } from 'images/icons/IconDownload.svg';
import iconDefaultProduct from 'images/icons/icon-default-product.svg';
import { Button, Dropdown, Space } from 'antd';


import {
  getUserRole,
  // getUserRole, 
  setLocalStorage
} from '../helper/utils'

import { SETTING_MENU_SIDEBAR, dispatchApp } from '../redux/actions/app'
import {
  GET_PRODUCTS_BY_SHOP,
  OPEN_MODAL_ADD_PRODUCT,
  dispatchProduct,
  HANDLE_SEARCH_SHOP_PRODUCT,
  HANDLE_DELETE_PRODUCT,
  OPEN_MODAL_LIMIT_PURCHASE,
} from '../redux/actions/product'
import {
  SET_CURRENT_POST,
  dispatchShop,
  SET_PAGINATION_SHOP_DETAIL,
  SET_PAGINATION_POSTS,
  SET_DATA_SHOP_DETAIL,
  // SET_CURRENT_SHOP,
  ADD_KEY_VALUE_SHOP
} from '../redux/actions/shop'
import {
  GET_POST_BY_SHOP_ID,
  dispatchPost,
  HANDLE_DELETE_POST_LIVE,
  SET_MODAL_RERUN_LIVE
} from '../redux/actions/post'


import host from '../config/host'
import { displayDateTh, displayDateThShortYear } from '../helper/timeUtil'
import { sortByKey } from '../helper/utils'

import '../styles/_shopDetail.scss'
import '../styles/_react-tab.scss'
import ModalRerunLive from '../components/modalRerunLive';
import ModalAddProduct from '../components/modalAddProduct'
import Pagination from '../components/pagination'

import noItem from '../images/icons/status-error.svg'
import classnames from 'classnames'
// import { checkPermission } from '../helper/utils'
import {
  dispatchModalConfirm,
  OPEN_MODAL_CONFIRM,
} from 'redux/actions/modalConfirm'
import { ButtonAdd, InputSearch } from 'components/output'
import ShopTax from './ShopTax';

import numeral from 'numeral'
import { ADMIN, SUPER_ADMIN } from 'layout/Sidebar/Role'
import ShopHub from './ShopHub'
// import VodList from 'components/VodList';
// import LiveList from 'components/LiveList';
import SetPriceLogistic from './ListLogisticPrice'
// import RerunList from 'components/RerunList'
import {
  Body2,
  Caption,
  H5,
  // Content, 
  LinkLabel
} from 'components/FontStyle';

// import VideoPlayer from 'components/VideoPlayer2'
// import VideoPlayer3 from 'components/VideoPlayer3'
import ModalSelectList from 'components/ModalSelectList';
import Brand from './Brand'
import ModalLimitPurchase from 'components/ModalLimitPurchase';
import ShopMemberInfo from 'components/Form/ShopMemberInfo'
import XlsxCreate from 'components/XlsxCreate'
import productProvider from 'provider/productProvider'
import { OWNER } from 'layout/Sidebar/Role'
import ModalAlert from 'components/modalAlert/ModalAlert'
import ProductCategory from 'components/ProductCategory'
import ReactDropdown from 'react-dropdown'
import DropdownText from 'components/DropdownText'
const api_product = new productProvider()


const LiveFilterOption = {
  ALL: { label: 'ทั้งหมด', value: 'ALL' },
  NOW_LIVE: { label: 'Now LIVE', value: 'NOW_LIVE' },
  LIVE_PROGRAM: { label: 'LIVE Program', value: 'LIVE_PROGRAM' },
  FINISH_LIVE: { label: 'Finish LIVE', value: 'FINISH_LIVE' }
}

class ShopDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shopDetail: {},
      currentTab: 2,
      productsByShop: {
        product: [],
        count: 0,
      },
      postsByShopId: {
        count: 0,
        posts: [],
      },
      searchProduct: '',
      statusSearch: false,
      searchHistory: '',
      productMode: 'ADD',
      productSelected: null,
      liveFilterSelected: LiveFilterOption.ALL,
      liveFilterOption: [LiveFilterOption.ALL,
      LiveFilterOption.NOW_LIVE,
      LiveFilterOption.LIVE_PROGRAM,
      LiveFilterOption.FINISH_LIVE,
      ],
      modalCategories: { isOpen: false, data: null }
    }
  }


  componentDidMount = (props) => {
    // const shopId = Number(this.props.match.params.shopId);
    // 
    // const keyword = "กระเป๋าเป้";
    // this.props.dispatch(dispatchProduct({ type: GET_SEARCH_PRODUCTS_BY_SHOP, payload: { shopId, keyword } }))
    this.props.dispatch(dispatchShop({ type: SET_DATA_SHOP_DETAIL }))
    // this.props.dispatch(dispatchProduct({ type: GET_PRODUCTS_BY_SHOP, payload: { shopId } }))
    // this.props.dispatch(dispatchShop({ type: GET_SHOP_DETAIL, payload: { shopId } }))
    // this.props.dispatch(dispatchPost({ type: GET_POST_BY_SHOP_ID, payload: { shopId } }))

    const currentTab = JSON.parse(localStorage.getItem('shopDetailDefaultTab')) || { tab: 0 };
    this.setState({ currentTab: currentTab.tab });
    if (currentTab.tab) {
      localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: currentTab.tab }));
    } else {
      localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: 0 }));
    }

    localStorage.setItem(
      'createProductBy',
      JSON.stringify({ createAt: 'shopDetail' }),
    )
    // if (!this.props.shopDetail) {
    //   this.props.history.push('/manage/shop')
    // }
    dispatchShop({
      type: ADD_KEY_VALUE_SHOP,
      payload: {
        key: 'shopDetailSearchStatus',
        value: false
      }
    })
    this.props.dispatch(
      dispatchShop({
        type: ADD_KEY_VALUE_SHOP,
        payload: {
          key: 'shopDetailKeyword',
          value: this.state.search
        }
      })
    )
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.shopDetail !== this.props.shopDetail) {
      this.setState({ shopDetail: nextProps.shopDetail })
    }
    if (nextProps.productsByShop !== this.props.productsByShop) {
      this.setState({ productsByShop: nextProps.productsByShop })
    }
    if (nextProps.post.postsByShopId !== this.props.post.postsByShopId) {
      this.setState({
        postsByShopId: {
          posts: nextProps.post.postsByShopId,
        },
      })
    }
  }

  getListProduct = () => {
    this.props.dispatch(
      dispatchProduct({
        type: HANDLE_SEARCH_SHOP_PRODUCT,
        payload: { search: this.state.searchProduct },
      }),
    )
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevState.modalCategories?.isOpen && !this.state.modalCategories?.isOpen) {
    //   this.props.dispatch(
    //     dispatchProduct({
    //       type: HANDLE_SEARCH_SHOP_PRODUCT,
    //       payload: { search: this.state.searchProduct },
    //     }),
    //   )
    // }
    if (prevState?.statusSearch !== this.state?.statusSearch) {
      this.props.dispatch(
        dispatchShop({
          type: ADD_KEY_VALUE_SHOP,
          payload: {
            key: 'shopDetailSearchStatus',
            value: this.state?.statusSearch
          }
        }),
      )
    }
    if (prevState?.searchProduct !== this.state?.searchProduct) {
      this.props.dispatch(
        dispatchShop({
          type: ADD_KEY_VALUE_SHOP,
          payload: {
            key: 'shopDetailKeyword',
            value: this.state.searchProduct
          }
        }),
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'page', value: 1 },
      }),
    )
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'limit', value: 10 },
      }),
    )
    // this.props.dispatch(dispatchShop({ type: SET_CURRENT_SHOP, payload: { shop: null } }))
  }

  setSideBarGroupMenu = (groupMenu) => {
    this.props.dispatch(
      dispatchApp({
        type: SETTING_MENU_SIDEBAR,
        payload: { group: groupMenu },
      }),
    )
  }

  setCurrentPost = (post) => {
    localStorage.setItem('post', JSON.stringify(post))
    this.props.dispatch(
      dispatchShop({ type: SET_CURRENT_POST, payload: { post } }),
    )
  }

  // openModalAddProduct = () => {
  //     this.props.dispatch(dispatchProduct({ type: OPEN_MODAL_ADD_PRODUCT, payload: { isOpen: true } }))
  // }

  onChangePageProduct = (page) => {
    const shopId = Number(this.props.match.params.shopId)
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'page', value: page },
      }),
    )
    if (this.state.statusSearch) {
      this.props.dispatch(
        dispatchProduct({
          type: HANDLE_SEARCH_SHOP_PRODUCT,
          payload: { search: this.state.searchHistory },
        }),
      )
    } else {
      this.props.dispatch(
        dispatchProduct({ type: GET_PRODUCTS_BY_SHOP, payload: { shopId } }),
      )
    }
  }

  onShowSizeChangeProduct = (page, pageSize) => {
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'page', value: page },
      }),
    )
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'limit', value: pageSize },
      }),
    )
  }

  onChangePagePosts = (page) => {
    const shopId = Number(this.props.match.params.shopId)
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_POSTS,
        payload: { key: 'page', value: page },
      }),
    )
    this.props.dispatch(
      dispatchPost({ type: GET_POST_BY_SHOP_ID, payload: { shopId } }),
    )
  }

  onShowSizeChangePosts = (page, pageSize) => {
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_POSTS,
        payload: { key: 'page', value: page },
      }),
    )
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_POSTS,
        payload: { key: 'limit', value: pageSize },
      }),
    )
  }

  onSearchProduct = (e) => {
    this.setState({ searchProduct: e.target.value })
  }

  onKeypressSearchProduct = (e) => {
    const { searchProduct } = this.state
    if (searchProduct.trim().length === 0) return
    if (e.key === 'Enter') {
      this.onClickSearchProduct()
    }
  }

  onClickSearchProduct = () => {
    this.setState({
      statusSearch: true,
      searchHistory: this.state.searchProduct,
    })
    this.props.dispatch(
      dispatchProduct({
        type: HANDLE_SEARCH_SHOP_PRODUCT,
        payload: { search: this.state.searchProduct, page: 1 },
      }),
    )
  }

  handleDeleteProduct = (product) => {
    this.props.dispatch(
      dispatchProduct({
        type: HANDLE_DELETE_PRODUCT,
        payload: {
          product,
          statusSearch: this.state.statusSearch,
          historySearch: this.state.searchHistory,
        },
      }),
    )
  }

  openModalDeleteProduct = (product) => {
    this.props.dispatch(
      dispatchModalConfirm({
        type: OPEN_MODAL_CONFIRM,
        payload: {
          isOpen: true,
          message: `คุณต้องการลบสินค้า ${product.name}`,
          btnConfirm: 'ใช่',
          btnCancel: 'ไม่ใช่',
          isClose: () =>
            this.props.dispatch(
              dispatchModalConfirm({
                type: OPEN_MODAL_CONFIRM,
                payload: false,
              }),
            ),
          confirm: () => this.handleDeleteProduct(product),
        },
      }),
    )
  }

  confirmDeletePost = (post) => {
    this.props.dispatch(
      dispatchPost({ type: HANDLE_DELETE_POST_LIVE, payload: { post } }),
    )
  }

  handleDeletePostLive = (post) => {
    this.props.dispatch(
      dispatchModalConfirm({
        type: OPEN_MODAL_CONFIRM,
        payload: {
          isOpen: true,
          message: `ต้องการลบ Live ${post.title}`,
          btnConfirm: 'ใช่',
          btnCancel: 'ไม่ใช่',
          confirm: () => this.confirmDeletePost(post),
          isClose: () =>
            this.props.dispatch(
              dispatchModalConfirm({
                type: OPEN_MODAL_CONFIRM,
                payload: false,
              }),
            ),
        },
      }),
    )
    // this.props.dispatch(dispatchModalMessage({type: OPEN_MODAL_CONFIRM ,payload:{title: "iconFail",message: "ลบ live นี้"}}))
  }

  openModalAddProduct = (mode, productSelected = {}) => {
    this.setState({ productMode: mode, productSelected }, () =>
      this.props.dispatch(
        dispatchProduct({
          type: OPEN_MODAL_ADD_PRODUCT,
          payload: { isOpen: true },
        }),
      ),
    )
  }

  onClearSearch = () => {
    const shopId = Number(this.props.match.params.shopId)
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'page', value: 1 },
      }),
    )
    this.setState({ statusSearch: false, searchHistory: '' }, () =>
      this.props.dispatch(
        dispatchProduct({ type: GET_PRODUCTS_BY_SHOP, payload: { shopId } }),
      ),
    )
  }

  openModalRerunLive = (liveSelected) => {
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'isOpen', value: true } }))
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'currentLive', value: liveSelected } }))
  }

  onFilterSelected = item => this.setState({ liveFilterSelected: item })

  checkPublishStatus = () =>
    this.state.postsByShopId.posts.filter((post) => post.liveStatus === 'publish').length > 0

  filterLiveStatusByStatus = (posts, type) => {
    const filter = posts.filter((post) => post.liveStatus === type);
    return sortByKey(filter, 'releaseDate');
  }

  getEnableCreateRerun = (date) => {
    const current = new Date();
    const finishDate = new Date(date);
    return (current - finishDate) > 600 ? true : false;
  }

  setCurrentTab = index => {
    this.setState({ currentTab: index })
    setLocalStorage({ key: 'shopDetailDefaultTab', value: { tab: index } })
    //// product index 0
    if (index === 0) {
      this.props.dispatch(dispatchShop({ type: SET_DATA_SHOP_DETAIL }))
    }
  }

  openModalLimitPurchase = (data, product) => {
    const { productSkus, ...productNosku } = product.product
    this.props.dispatch(
      dispatchProduct({
        type: OPEN_MODAL_LIMIT_PURCHASE,
        payload: { isOpen: true, data, product: productNosku },
      })
    );
  }

  fetchDataExcel = async () => {
    const setFormatData = async (data) => {
      const newData = await new Promise(async (resolve, reject) => {
        const header = ['ลำดับ', 'ชื่อสินค้า', 'หมวดหมู่', 'ชื่อร้านค้า', 'จำกัดการซื้อ', 'ตัวเลือก SKU', 'เลขอ้างอิง SKU', 'รหัสบาร์โค้ด', 'ราคาขาย', 'คลัง', 'สถานะ', 'จำกัดการซื้อ(สินค้าย่อย)']
        const dataProduct = await data.map((product, index) => {
          return {
            index: index + 1,
            productName: product.name,
            category: product.productCategories?.categories?.name,
            shopName: product.shop.name,
            limitPurchase: product.limit?.toLocaleString(),
            // sku: product.productSkus.map((sku) => `${sku.id}\n`).join(''),
            optionProduct: product.productSkus.map((sku) => `${sku.sku}`).join('\n'),
            refSKU: product.productSkus.map((sku) => `${sku.sellerSKU ? sku.sellerSKU : ''}`).join('\n'),
            barcodeSKU: product.productSkus.map((sku) => `${sku.barcode ? sku.barcode : ''}`).join('\n'),
            price: product.productSkus.map((sku) => `${sku.priceNet.toLocaleString()}`).join('\n'),
            stock: product.productSkus.map((sku) => `${sku.stockRemain.toLocaleString()}`).join('\n'),
            status: product.productSkus.map((sku) => `${sku.status === 'show' ? 'ขายอยู่' : 'ไม่ขาย'}`).join('\n'),
            limitPurchaseSKU: product.productSkus.map((sku) => `${sku.limit ? sku.limit.toLocaleString() : 'ไม่จำกัด'}`).join('\n'),
          }
        })
        resolve({ header, dataProduct })
      })
      return newData
    }
    const { shop } = this.props
    const data = await new Promise(async (resolve, reject) => {
      const { statusSearch, searchProduct } = this.state
      const { getStateShop } = this.props

      if (statusSearch) {
        const res = await api_product.getSearchProductsByShop({
          keyword: searchProduct,
          limit: getStateShop.paginationShopDetail.total,
          page: 1,
          shopId: getStateShop.currentShop.id
        })
        const newData = await setFormatData(res.product)
        resolve(newData)
      } else {
        const res = await api_product.getAllProductByShop({ shopId: shop.id, page: 1, limit: getStateShop?.paginationShopDetail?.total })
        const newData = await setFormatData(res.data.product)
        resolve(newData)
      }
    })
    return { header: data.header, data: data.dataProduct }
  }

  render() {
    const { shopDetail,
      productsByShop,
      statusSearch,
      // postsByShopId,
      // liveFilterSelected,
      // liveFilterOption,
      currentTab } = { ...this.state, }
    const {
      createdAt,
      updatedAt,
      logoPath,
      zone,
    } = shopDetail
    const { paginationShopDetail, shop, page, limit } = this.props;
    return (
      <React.Fragment>
        <div className='shop-detail'>
          <h4>รายละเอียดร้านค้า</h4>
          <div className='shop-detail-header'>
            <div className='row'>
              <div className='col-1-atl'>
                {logoPath ? (
                  <img
                    src={`${host.avatar}${logoPath && logoPath.path}`}
                    className='shop-profile-image'
                  />
                ) : (
                  <img src={IconShopAvatar} alt='IconShopAvatar' />
                )}
              </div>
              <div className='col-7'>
                <div className='row'>
                  <div className='col-12'>
                    <h5>
                      {shopDetail.name}
                      {this.checkPublishStatus() && <img className="pl-2" src={IconLive} />}
                    </h5>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6 align-center'>
                    <img src={IconShoppingBag} className='mr-2' />
                    <span>สินค้าทั้งหมด {productsByShop.count} รายการ</span>
                  </div>
                  <div className='col-6 align-center'>
                    <img src={IconClock} className='mr-2' />
                    <span>
                      เข้าร่วมเมื่อ {displayDateTh(createdAt, 'D MMM YYYY')}
                    </span>
                  </div>
                </div>
                {/* <div className='row'>
                  <div className='col-12'>
                    <span>{zone}</span>
                  </div>
                </div> */}
              </div>
              <div className='col-4-atl'>
                <div className='right-side-wrap'>
                  <div className='row align-center-right'>
                    {' '}
                    <span>
                      อัปเดตข้อมูลล่าสุด{' '}
                      {displayDateTh(updatedAt, 'D MMM YYYY')}
                    </span>
                  </div>
                  {/* {checkPermission('shop', 'delete', 'rw') ? (
                    <div className='row align-center align-center-right'>
                      {' '}
                      <img src={IconTrash} /> <span>ลบร้านค้านี้</span>
                    </div>
                  ) : null} */}
                  {/* {user.permission?.some((item) => item.module == "shop" && item.action == "delete") ?
                                    <div className="row align-center align-center-right"> <img src={IconTrash} /> <span>ลบร้านค้านี้</span></div> : null} */}
                </div>
              </div>
            </div>
          </div>

          <div className='shop-detail-content'>
            <Tabs selectedIndex={currentTab} onSelect={index => this.setCurrentTab(index)}>
              <TabList>
                <Tab>สินค้าทั้งหมด</Tab>
                <Tab className={classnames('react-tabs__tab', { 'd-none': getUserRole() === SUPER_ADMIN || getUserRole() === ADMIN })}>หมวดหมู่</Tab>
                <Tab>แบรนด์สินค้า</Tab>
                {/* <Tab> <img src={IconEllipse} className="pr-1" /> Live </Tab> */}
                {/* <Tab> VOD </Tab> */}
                {/* <Tab> รีรันจบแล้ว </Tab> */}
                {/* <Tab className={classnames('react-tabs__tab', { 'd-none': getUserRole() !== SUPER_ADMIN })}>ข้อมูลร้านค้า</Tab> */}
                <Tab className={classnames('react-tabs__tab')}>ข้อมูลร้านค้า</Tab>
                <Tab className={classnames('react-tabs__tab')}>ที่อยู่สำหรับการจัดส่ง</Tab>
                <Tab className={classnames('react-tabs__tab')}>ข้อมูลภาษี</Tab>
                <Tab className={classnames('react-tabs__tab')}>ตั้งค่าราคาจัดส่ง</Tab>
                <Tab className={classnames('react-tabs__tab')}>เลขสมาชิกสำหรับร้านค้า</Tab>
              </TabList>

              <TabPanel>
                <section className='product-container'>
                  <div className='product-header'>
                    <div className='d-flex justify-content-between'>
                      <div className='text-left align-center w-50'>
                        <span className='font-subtitle'>
                          {productsByShop.count} รายการ
                        </span>
                        {/* <div className="input-text-field">
                                                <input type="text" placeholder="ค้นหาสินค้า" value={this.state.search} onChange={this.onSearchProduct} onKeyPress={this.onKeypressSearchProduct} />
                                                <img src={IconSearch} className="cursor-pointer" onClick={this.onClickSearchProduct} />
                                            </div> */}
                        <div className='pl-2' style={{ width: '90%' }}>
                          <InputSearch
                            statusSearch={statusSearch}
                            value={this.state.search}
                            placeholder='"ค้นหาจากชื่อสินค้า, ประเภทสินค้า, แบรนด์, ตัวเลือกสินค้า, เลขอ้างอิง SKU"'
                            onChange={this.onSearchProduct}
                            onKeyPress={this.onKeypressSearchProduct}
                            handleSearch={this.onClickSearchProduct}
                            onClearSearch={this.onClearSearch}
                          />
                        </div>
                      </div>

                      {/* <div
                        className={classnames(
                          'col-2 text-right align-middle link',
                          {
                            'd-none': !checkPermission(
                              'product',
                              'create',
                              'rw',
                            ),
                          },
                        )}
                        onClick={() => this.openModalAddProduct('ADD')}
                      > */}
                      {/* <div className="col-2"> */}
                      {' '}
                      <div className='d-flex flex-row align-items-center gap-3'>
                        <ButtonAdd
                          label='สร้างสินค้า'
                          handleAdd={() => this.openModalAddProduct('ADD')}
                        />

                        {/* {getUserRole() === OWNER && <Body2 className='color-primary cursor-pointer' onClick={() => this.props.history.push({
                        pathname: '/manage/product/mass-upload',
                        state: {
                          isFromShop: true
                        }
                      })}>เพิ่มสินค้าแบบชุด</Body2>} */}
                        <DropdownText label="จัดการสินค้าแบบชุด" data={[{
                          label: 'สร้างสินค้าแบบชุด',
                          key: 'create',
                        },
                        {
                          label: 'แก้ไขสินค้าแบบชุด',
                          key: 'edit',
                        },]}
                          handleClick={(key) => key === 'create' ? this.props.history.push({
                            pathname: '/manage/product/mass-upload',
                            state: {
                              isFromShop: true
                            }
                          }) : this.props.history.push({
                            pathname: '/manage/product/mass-upload-edit',
                            state: {
                              isFromShop: true
                            }
                          })}
                          label="จัดการสินค้าแบบชุด"
                        />
                      </div>

                    </div>
                  </div>
                  {/* </div> */}
                  {/* </div> */}

                  <div className='d-flex align-items-center justify-content-between btn-download-container p-3'>


                    {productsByShop &&
                      productsByShop.product.length === 0 ? null : (
                      // <div className='pagination-top'>
                      //   <div className='float-right position-relative mt-2'>
                      <React.Fragment>
                        <XlsxCreate
                          handleFetchData={() => this.fetchDataExcel()}
                          fileName={`${shop.name}-รายการสินค้า ${displayDateThShortYear(new Date(), "DD-MM-YY")}`}
                          headerA1={`รายการสินค้า`}
                        >
                          <button className='btn-cancel' style={{ padding: '0.5rem 0.3rem' }}> <IconDownload className='icon-download' /> ดาวน์โหลดข้อมูล </button>
                        </XlsxCreate>
                        <Pagination
                          defaultCurrent={1}
                          current={paginationShopDetail.page}
                          pageSize={paginationShopDetail.limit}
                          total={paginationShopDetail.total}
                          onChange={this.onChangePageProduct}
                          onShowSizeChange={this.onShowSizeChangeProduct}
                          pageSizeOptions={paginationShopDetail.pageSizeOptions}
                        />
                      </React.Fragment>

                      //   </div>
                      // </div>
                    )}
                  </div>



                  {productsByShop && productsByShop.product.length === 0 ? (
                    <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
                      <img src={noItem} alt='noItem' />
                      <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
                    </div>
                  ) : (
                    <div className='product-content'>
                      <div className='product-list'>
                        <div className='product-table-content'>
                          <div class="row product-row align-items-top header-style">
                            <div className='col-1 text-center break-word'>ลำดับ</div>
                            <div className='col-2 text-left break-word'> ชื่อสินค้า </div>
                            <div className='col-1 text-center break-word'>หมวดหมู่</div>
                            <div className='col-1 text-center break-word'>ชื่อร้านค้า</div>
                            <div className="col-1 text-center break-word">จำกัดการซื้อ </div>

                            <div class="col-6 text-center nopadding">
                              <div class="row align-items-baseline pb-4">
                                <div className='col-2-atl text-center break-word'>ตัวเลือกสินค้า</div>
                                <div className='col-2-atl text-center break-word'>เลขอ้างอิง SKU</div>
                                <div className='col-2-atl text-center break-word'>รหัสบาร์โค้ด</div>
                                <div className='col-2-atl text-center break-word'>ราคาขาย</div>
                                <div className='col-2-atl text-center break-word'>คลัง</div>
                                <div className='col-2-atl text-center break-word'>สถานะ</div>
                                <div className="col-2-atl text-center break-word">จำกัดการซื้อ (สินค้าย่อย) </div>
                              </div>
                            </div>

                            <div className='col-1 text-center'></div>
                          </div>
                        </div>
                        <div className='product-table-content'>
                          {productsByShop &&
                            productsByShop.product.map(
                              (product, productIndex) => {
                                const { coverPath, shop, productSkus, imagePath } = product
                                return (
                                  <React.Fragment key={`productIndex-${productIndex}`}>
                                    <div className='row product-row align-items-top'>
                                      <div className='col-1 text-center nopadding'>
                                        {(paginationShopDetail.page - 1) *
                                          paginationShopDetail.limit +
                                          productIndex +
                                          1}
                                      </div>
                                      <div className='col-2 text-left nopadding'>
                                        <div className='product-name'>
                                          <div className='row nopadding'>
                                            <div className='col-4 nopadding'>
                                              <div className='product-image'>
                                                {coverPath && coverPath?.path ?
                                                  <img src={`${host.avatar}${coverPath?.path}`} alt='' />
                                                  :
                                                  <img src={iconDefaultProduct} alt='' />
                                                }
                                              </div>
                                            </div>
                                            <div className='col-8 nopadding'>
                                              <div className='product-detail'>
                                                <ul>
                                                  <li>
                                                    {' '}
                                                    <span className='label-product-name'>
                                                      {product.name}
                                                    </span>{' '}
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-1 text-center cursor-pointer color-info-main" onClick={() => this.setState({ modalCategories: { isOpen: true, data: product } })}>
                                        <div className='d-flex justify-content-center gap-1 product-category'>
                                          {(product?.countCategories === 0 || product?.countShopCategories === 0) &&
                                            <React.Fragment>
                                              <span><IconAlert id="test1" fill="red" className="icon-hint" /></span>
                                              <UncontrolledTooltip placement="top" target="test1">
                                                {`ยังไม่ได้ตั้งค่า `}
                                              </UncontrolledTooltip>
                                            </React.Fragment>
                                          }
                                          <span>ตั้งค่า</span>
                                        </div>
                                      </div>
                                      <div className='col-1 text-center nopadding'>
                                        {' '}
                                        <span>{shop.name}</span>{' '}
                                      </div>
                                      <div className='col-1 text-center nopadding'>
                                        {' '}
                                        <LinkLabel>
                                          <Caption
                                            onClick={() => this.openModalLimitPurchase(productSkus, { product: product })}>
                                            {product?.limit ? product.limit : 'ไม่จำกัด'}
                                          </Caption>
                                        </LinkLabel>
                                      </div>
                                      <div className='col-6 text-center nopadding'>
                                        {productSkus &&
                                          productSkus.map(
                                            (productSku, skuIndex) => {
                                              const {
                                                id,
                                                sku,
                                                sellerSKU,
                                                stockRemain,
                                                status,
                                                priceNet,
                                                limit,
                                                barcode
                                              } = productSku
                                              return (
                                                <React.Fragment key={`skuIndex-${skuIndex}`}>
                                                  <div className='row align-items-baseline pb-4'>
                                                    <div className='col-2-atl text-left'>
                                                      {sku}
                                                    </div>
                                                    {sellerSKU ?
                                                      <div className='col-2-atl text-left text-break'> {sellerSKU} </div>
                                                      :
                                                      <div className='col-2-atl text-center'> - </div>
                                                    }
                                                    {barcode ?
                                                      <div className='col-2-atl text-left text-break'> {barcode} </div>
                                                      :
                                                      <div className='col-2-atl text-center'> - </div>
                                                    }
                                                    <div className='col-2-atl text-center' style={{ flex: '1 0 13.38%', maxWidth: '13.38%' }}>
                                                      ฿{' '}
                                                      {numeral(priceNet).format(
                                                        '0,0.00',
                                                      )}
                                                    </div>
                                                    <div className='col-2-atl text-center' style={{ flex: '1 0 9.38%', maxWidth: ' 9.38%' }}>
                                                      {numeral(
                                                        stockRemain,
                                                      ).format('0,0')}
                                                    </div>
                                                    <div className='col-2-atl text-center pb-2 position-relative'>
                                                      {status === 'show' ? (
                                                        <div>
                                                          <span className='badge badge-green position-absolute ml-auto mr-auto' style={{ top: -14, left: 0, right: 0 }}>ขายอยู่</span>
                                                        </div>
                                                      ) : (
                                                        <div>
                                                          <span className='badge badge-gray position-absolute ml-auto mr-auto' style={{ top: -14, left: 0, right: 0 }}>ไม่ขาย</span>
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div className='col-2-atl text-center'>
                                                      <LinkLabel>
                                                        <Caption onClick={() => this.openModalLimitPurchase(productSkus, { product: product })}>
                                                          {limit ? limit : 'ไม่จำกัด'}
                                                        </Caption>
                                                      </LinkLabel>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              )
                                            },
                                          )}
                                      </div>
                                      <div className='col-1 text-center nopadding'>
                                        <div className='product-action'>
                                          <IconEdit className='cursor-pointer icon-manage-shop' onClick={() => this.openModalAddProduct('UPDATE', product,)} />
                                          <IconTrash className='cursor-pointer icon-manage-shop' onClick={() => this.openModalDeleteProduct(product,)} />
                                          {/* <img src={IconEdit} className='cursor-pointer' onClick={() => this.openModalAddProduct('UPDATE', product,)} /> */}
                                          {/* <img src={IconTrash} className='cursor-pointer' onClick={() => this.openModalDeleteProduct(product,)} /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              },
                            )}

                          {productsByShop &&
                            productsByShop.product.length === 0 ? null : (
                            <div className='float-right position-relative mt-2'>
                              <Pagination
                                defaultCurrent={1}
                                current={paginationShopDetail.page}
                                pageSize={paginationShopDetail.limit}
                                total={paginationShopDetail.total}
                                onChange={this.onChangePageProduct}
                                onShowSizeChange={this.onShowSizeChangeProduct}
                                pageSizeOptions={
                                  paginationShopDetail.pageSizeOptions
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              </TabPanel>
              <TabPanel>
                <ProductCategory currentTab={currentTab} />
              </TabPanel>
              <TabPanel>
                <Brand history={this.props.history} />
              </TabPanel>
              {/* <TabPanel>
                <LiveList></LiveList>
              </TabPanel>
              <TabPanel>
                <VodList></VodList>
              </TabPanel>
              <TabPanel>
                <RerunList></RerunList>
              </TabPanel> */}
              <TabPanel>
                <FormShopDetail />
              </TabPanel>
              <TabPanel>
                <ShopHub />
              </TabPanel>
              <TabPanel>
                <ShopTax />
              </TabPanel>
              <TabPanel>
                <SetPriceLogistic match={this.props.match} history={this.props.history} location={this.props.location} />
              </TabPanel>
              <TabPanel>
                <ShopMemberInfo />
              </TabPanel>
            </Tabs >
          </div >
          <ModalAddProduct
            mode={this.state.productMode}
            productSelected={this.state.productSelected}
          />
          <ModalSelectList
            modal={this.state.modalCategories}
            isClose={() => this.setState({ modalCategories: { isOpen: false, data: null } })}
            type='product'
            onSubmit={() => this.getListProduct()}
          />
          <ModalRerunLive></ModalRerunLive>
          <ModalLimitPurchase productSelected={this.state.productSelected} getListProduct={() => this.props.dispatch(dispatchShop({ type: SET_DATA_SHOP_DETAIL }))} />




          <ModalAlert />



          {/* defaultCurrent={1}
                    total={total}
                    onChange={(page) => this.onChangePage(page)}
                    pageSize={limit}
                    pageSizeOptions={pageSizeOptions}
                    onShowSizeChange={this.onShowSizeChange}
                    current={page}
                    showQuickJumper={true}
                    locale={{ items_per_page: '/ หน้า' }} */}
        </div >
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state) => ({
  shopDetail: state.shop.shopDetail,
  productsByShop: state.product.productsByShop,
  postsByShopId: state.post.postsByShopId,
  post: state.post,
  paginationShopDetail: state.shop.paginationShopDetail,
  paginationPost: state.shop.paginationPost,
  user: state.user.userInfo,
  loading: state.app.loading,
  shop: state.shop.currentShop,
  getStateShop: state.shop
})

export default connect(mapStateToProps)(ShopDetail)
