import React from "react";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "antd";
import OrderIndex from "components/Broadcast/OrderIndex";
import Editor from "components/Editor";
import IconTrash from "images/icons/trash-orange.svg";
// import IconCamera from "images/icons/camera-orange.svg";

import InputText from "components/InputText";
import "./index.scss";

const renderHeader = (props, useContentText) => {
  const { indexSelected, options, onSwitchIndex, onDeleteItem } = props;
  const { setIndexSelected } = useContentText;
  const { indexOld, indexNew } = useContentText.indexSelected;
  return (
    <div className="d-flex justify-content-between">
      {/* item index */}
      <div className="d-flex flex-row align-items-center">
        <OrderIndex
          options={options}
          indexSelected={indexSelected}
          handleSelected={(value) =>
            setIndexSelected({ indexOld: indexSelected, indexNew: value })
          }
        />
        <button
          className="btn-info small-button"
          onClick={() => onSwitchIndex(indexOld, indexNew)}
        >
          ย้าย
        </button>
      </div>
      {/* manage item */}
      <div className="d-flex flex-row align-items-center">
        <button
          className="btn-cancel ml-4 d-flex justify-content-center align-items-center"
          onClick={() => onDeleteItem(indexSelected)}
        >
          <img src={IconTrash} className="mr-2" alt='icon-trash'/>
          ลบเนื้อหานี้
        </button>
      </div>
    </div>
  );
};

const getNewTextData = (content, newText) => {
  let newContent = content;
  newContent.data = newText;
  return newContent;
};

const getNewTextLink = (content, newLink) => {
  let newContent = content;
  newContent.link = newLink;
  return newContent;
};

const renderDetail = (props) => {
  return (
    <div className="d-flex justify-content-start flex-column mt-4">
      {/* content editor */}
      {renderTextEditor(props)}

      {/* content link */}
      {renderLinks(props)}
    </div>
  );
};

const renderTextEditor = (props) => {
  const { indexSelected, onUpdateContentItem } = props;
  const newContent = props.contents[0];
  const { data, 
    // link 
  } = newContent;
  return (
    <>
      <label>
        <span className="title">เนื้อหา </span>
        <span className="detail">(จำกัด 200 ตัวอักษร)</span>
      </label>
      <div>
        <Editor
          html={data}
          onChange={(htmlString) =>
            onUpdateContentItem(
              indexSelected,
              0,
              getNewTextData(newContent, htmlString)
            )
          }
          readOnly={false}
        />
      </div>
    </>
  );
};

const renderLinks = (props) => {
  const { indexSelected, onUpdateContentItem } = props;
  const newContent = props.contents[0];
  const { 
    // data
    link } = newContent;
  const { Panel } = Collapse;
  return (
    <div>
      <Collapse
        className="pt-3"
        expandIcon={({ isActive }) => (
          <FontAwesomeIcon
            className="icon-angle"
            icon={isActive ? faAngleUp : faAngleDown}
          />
        )}
        ghost
      >
        <Panel
          header={<label className="panel-header">เพิ่มลิงค์ URL</label>}
          key="1"
        >
          <InputText
            className={"input-text"}
            onChange={(event) =>
              onUpdateContentItem(
                indexSelected,
                0,
                getNewTextLink(newContent, event.target.value)
              )
            }
            value={link}
            label={"ลิ้งค์ URL"}
            isRequire={false}
            isError={false}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default function ContentText(props) {
  const hookContentText = useContentText(props);
  return (
    <div className="container-content">
      {renderHeader(props, hookContentText)}
      {renderDetail(props, hookContentText)}
    </div>
  );
}

const useContentText = (props) => {
  const [indexSelected, setIndexSelected] = React.useState({
    indexOld: 0,
    indexNew: 0,
  });
  return { indexSelected, setIndexSelected };
};
