import { displayDateThShortYear } from 'helper/timeUtil'
import numeral from 'numeral'
import React from 'react'
import './styles.scss'
import { ReactComponent as IconNoItem } from 'images/icons/no-item.svg'


const AdminTransferDetail = (props) => {
    const { withdrawList, page, limit, showStatusWithdraw,transactionData,orderWithdraw,showButtonTransferred } = props
    return (
        <div className='overflow-auto'>
            <div className='table-header-withdraw-detail'>
                <div>ลำดับ</div>
                <div>ยืนยันการโอนเงินให้ร้านค้า</div>
                <div>สถานะการโอนเงินให้ร้านค้า</div>
                <div>Transaction number</div>
                <div>Vendor Code</div>
                {/* <div>Customer Code</div> */}
                <div>หมายเลขออเดอร์</div>
                <div>รอบสรุปข้อมูล</div>
                <div>กำหนดโอนเงินโดยประมาณไม่เกิน</div>
                <div>วันที่ลูกค้าชำระเงิน</div>
                <div>วันที่จัดส่งถึงลูกค้า</div>
                <div>ร้านค้า</div>
                <div>ชื่อนิติบุคคล/ชื่อบุคคลธรรมดา</div>
                <div>จำนวนสินค้า</div>
                <div>ยอดรวมค่าสินค้า</div>
                <div>ค่าจัดส่ง</div>
                <div>ส่วนลดค่าจัดส่ง</div>
                <div>คงเหลือค่าจัดส่ง</div>
                <div>ยอดรวมค่าสินค้าและค่าจัดส่ง</div>
                <div>ส่วนลด V-avenue</div>
                <div>ส่วนลดร้านค้า</div>
                <div>ส่วนลดทั้งหมด</div>
                <div>ยอดชำระทั้งสิ้น</div>
                <div>ช่องทางการชำระเงิน</div>
                <div>เลขอ้างอิง mPAY</div>
                <div>ค่าบริการชำระเงินคิดที่อัตรา 2%</div>
                <div> ภาษีค่าบริการชำระเงิน</div>
                <div>ค่าบริการชำระเงิน (รวมภาษี)</div>
                {/* <div>ค่าบริการชำระเงินเรียกเก็บตามสัญญา</div> */}
                <div>ส่วนแบ่งรายได้ร้านค้า</div>
                <div>ภาษีหัก ณ ที่จ่าย (W/T)</div>
                <div>ส่วนแบ่งรายได้หลังหักภาษี ณ ที่จ่าย</div>
            </div>

            {withdrawList?.orderWithdraws?.length === 0 &&
                <div className='d-flex flex-column justify-items-center align-items-center' style={{ marginTop: '10rem', marginBottom: '10rem' }}>
                    <IconNoItem />
                    ไม่มีข้อมูล
                </div>
            }
            {withdrawList?.orderWithdraws?.map((list, idx) => (
                <div className="table-content-withdraw-detail">
                    <div>{idx + 1}</div>
                    <div>{showButtonTransferred(list)}</div>
                    <div>{showStatusWithdraw(list.status,list.transferredDate, list?.sellerWithdraw?.attachment)}</div>
                    <div>{list?.sellerWithdraw?.refNumber}</div>
                    <div>{transactionData?.shop?.partner?.value}</div>
                    {/* <div>Customer Code</div> */}
                    <div>{list?.order?.orderNo}</div>
                    <div>{displayDateThShortYear(transactionData?.settledDate)}</div>
                    <div>{displayDateThShortYear(list?.sellerWithdraw?.settlementPeriod, 'DD MMM YY')}</div>
                    <div>{list?.order?.paymentedAt && displayDateThShortYear(list?.order?.paymentedAt,'DD MMM YY HH:mm')}</div>
                    <div>{list?.order?.deliveredAt && displayDateThShortYear(list?.order?.deliveredAt,'DD MMM YY HH:mm')}</div>
                    <div>{transactionData?.shop?.name}</div>
                    <div>{transactionData?.shop?.shopTax?.name}</div>
                    <div>{list.order?.items.map(element => element.quantity).reduce((a, b) => a+ b , 0)}</div>
                    <div>{numeral(list?.totalItemPrice).format('0,0.00')}</div>
                    <div>{numeral(list?.totalShippingFee).format('0,0.00')}</div>
                    <div>{numeral(list?.totalShippingDiscount + list?.totalShippingDiscountShop).format('0,0.00')}</div>
                    <div>{numeral(list?.totalShippingFee - (list?.totalShippingDiscount + list?.totalShippingDiscountShop)).format('0,0.00')}</div>
                    <div>{numeral((list?.totalItemPrice + list.order?.priceShippingNet)).format('0,0.00')}</div>
                    <div>{numeral(list?.totalDiscountPlatform).format('0,0.00')}</div>
                    <div>{numeral(list?.totalDiscountShop).format('0,0.00')}</div>
                    <div>{numeral(list?.totalDiscountPlatform + list?.totalDiscountShop).format('0,0.00')}</div>
                    <div>{numeral(list?.totalAmount).format('0,0.00')}</div>
                    <div>{list?.order?.paymentMethod}</div>
                    <div>{list?.order?.payment?.transactionId}</div>
                    <div>{numeral(list?.totalPaymentFee).format('0,0.00')}</div>
                    <div>{numeral(list?.totalPaymentFeeVat).format('0,0.00')}</div>
                    <div>{numeral(list?.totalPaymentFee + list?.totalPaymentFeeVat).format('0,0.00')}</div>
                    {/* <div>ค่าบริการชำระเงินเรียกเก็บตามสัญญา</div> */}
                    <div>{numeral(list?.totalAmount - (list?.totalPaymentFee + list?.totalPaymentFeeVat)).format('0,0.00')}</div>
                    <div>{numeral(list?.totalWithHoldingTax).format('0,0.00')}</div>
                    <div>{numeral(list?.totalRevenue).format('0,0.00')}</div>
                </div>
            ))
            }

            {withdrawList?.orderWithdraws?.length > 0 &&
                // withdrawList.orderWithdraws.map()
                <div className="table-last-content-withdraw-detail">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>{numeral(withdrawList && withdrawList?.orderWithdraws?.map(element => element.order.items.map(item => item.quantity).reduce((a,b) => a+b,0)).reduce((a,b) => a+b,0)).format('0,0')}</div>
                    {/* <div>10</div> */}
                    <div>{numeral(transactionData?.totalItemPrice).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalShippingFee).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalShippingDiscountShop + transactionData?.totalShippingDiscount).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalShippingFee - (transactionData?.totalShippingDiscountShop + transactionData?.totalShippingDiscount)).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalItemPrice + transactionData?.totalShippingFee).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalDiscountPlatform).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalDiscountShop).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalDiscountShop + transactionData?.totalDiscountPlatform).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalAmount).format('0,0.00')}</div>
                    <div></div>
                    <div></div>
                    <div>{numeral(transactionData?.totalPaymentFee).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalPaymentFeeVat).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalPaymentFee + transactionData?.totalPaymentFeeVat).format('0,0.00')}</div>
                    {/* <div>ค่าบริการชำระเงินเรียกเก็บตามสัญญา</div> */}
                    <div>{numeral(transactionData?.totalAmount - (transactionData?.totalPaymentFee + transactionData?.totalPaymentFeeVat)).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalWithHoldingTax).format('0,0.00')}</div>
                    <div>{numeral(transactionData?.totalRevenue).format('0,0.00')}</div>
                </div>
            }
        </div>
    )
}

export default AdminTransferDetail