import galleryProvider from '../../provider/galleryProvider'
import { takeLatest, call, put, 
    // select, take 
} from 'redux-saga/effects';

import {
    SET_GALLERY_FIELD,
    GET_SHOP_GALLERY,
    SET_SHOP_GALLERY,
    GET_ALBUM_GALLERY,
    SET_ALBUM_GALLERY,
    CREATE_ALBUM_GALLERY,
    UPDATE_ALBUM_GALLERY,
    SEARCH_ALBUM_GALLERY,
    GET_MEDIA_GALLERY,
    SET_MEDIA_GALLERY,
    CREATE_MEDIA_GALLERY,
    UPDATE_MEDIA_GALLERY,
    DELETE_MEDIA_GALLERY,
    SEARCH_MEDIA_GALLERY,
    // SET_MEDIA_UPLOAD_EVENT,
    GET_ALBUM_DEFAULT,
} from '../actions/gallery'

export function* onFetchShopGallery(action) {
    // const { payload } = action
    try {
        const shopData = yield call(galleryProvider.getShop)
        yield put({ type: SET_SHOP_GALLERY, payload: shopData })
    } catch (err) {
        
        throw err
    }
}

export function* onFetchAlbum(action) {
    const { payload } = action
    try {
        const albumData = yield call(galleryProvider.getAlbumById, { shopId: payload.shopId, sort: payload.sort })
        yield put({ type: SET_ALBUM_GALLERY, payload: albumData })
    } catch (err) {
        
        throw err
    }
}

export function* onCreateAlbum(action) {
    const { payload } = action
    try {
        // eslint-disable-next-line no-unused-vars
        const albumData = yield call(galleryProvider.createAlbum, payload)
        yield put({ type: GET_ALBUM_GALLERY, payload: { shopId: payload.shopId } })
    } catch (err) {
        
        throw err
    }
}

export function* onUpdateAlbum(action) {
    const { payload, etc } = action
    try {
        // eslint-disable-next-line no-unused-vars
        const albumData = yield call(galleryProvider.updateAlbum, etc.albumId, payload)
        yield put({ type: GET_ALBUM_GALLERY, payload: { shopId: etc.shopId } })
    } catch (err) {
        
        throw err
    }
}

export function* onSearchAlbum(action) {
    const { payload } = action
    try {
        const albumData = yield call(galleryProvider.searchAlbumByName, payload)
        yield put({ type: SET_ALBUM_GALLERY, payload: albumData })
    } catch (err) {
        
        throw err
    }
}

export function* onFetchMedia(action) {
    const { payload } = action
    try {
        const mediaData = yield call(galleryProvider.getMediaByAlbumId, { albumId: payload.albumId, type: payload.type, sort: payload.sort })
        yield put({ type: SET_MEDIA_GALLERY, payload: mediaData })
    } catch (err) {
        
        throw err
    }
}

export function* onCreateMedia(action) {
    const { payload, etc } = action
    try {
        yield put ({ type: SET_GALLERY_FIELD, payload: { key: 'isUploading', value: true } })

        // eslint-disable-next-line no-unused-vars
        const mediaData = yield call(galleryProvider.createMedia, payload, etc)
        
        yield put({ type: GET_MEDIA_GALLERY, payload: { albumId: payload.albumId, type: etc.type } })
    } catch (err) {
        
        throw err
    }
}

export function* onUpdateMedia(action) {
    const { payload, etc } = action
    try {
        yield call(galleryProvider.updateMedia, etc.mediaId, payload)
        yield put({ type: GET_MEDIA_GALLERY, payload: { albumId: etc.albumId, type: etc.type } })
    } catch (err) {
        
        throw err
    }
}

export function* onDeleteMedia(action) {
    const { etc } = action
    try {
        yield call(galleryProvider.deleteMedia, etc.mediaId)
        yield put({ type: GET_MEDIA_GALLERY, payload: { albumId: etc.albumId, type: etc.type } })
    } catch (err) {
        
        throw err
    }
}

export function* onSearchMedia(action) {
    const { payload } = action
    try {
        const mediaData = yield call(galleryProvider.searchMediaByName, payload)
        yield put({ type: SET_MEDIA_GALLERY, payload: mediaData })
    } catch (err) {
        
        throw err
    }
}

export function* onFecthAlbumDefaultByShopId(action) {
    const { payload } = action
    try {
        const mediaData = yield call(galleryProvider.onFecthAlbumDefaultByShopId, payload.shopId)
        yield put({ type: SET_GALLERY_FIELD, payload: { key: 'albumDefault', value: mediaData }})
    } catch (err) {
        
        throw err
    }
}

export default function* useWatcher() {
    yield takeLatest(GET_SHOP_GALLERY, onFetchShopGallery)
    yield takeLatest(GET_ALBUM_GALLERY, onFetchAlbum)
    yield takeLatest(CREATE_ALBUM_GALLERY, onCreateAlbum)
    yield takeLatest(UPDATE_ALBUM_GALLERY, onUpdateAlbum)
    yield takeLatest(SEARCH_ALBUM_GALLERY, onSearchAlbum)
    yield takeLatest(GET_MEDIA_GALLERY, onFetchMedia)
    yield takeLatest(CREATE_MEDIA_GALLERY, onCreateMedia)
    yield takeLatest(UPDATE_MEDIA_GALLERY, onUpdateMedia)
    yield takeLatest(DELETE_MEDIA_GALLERY, onDeleteMedia)
    yield takeLatest(SEARCH_MEDIA_GALLERY, onSearchMedia)
    yield takeLatest(GET_ALBUM_DEFAULT, onFecthAlbumDefaultByShopId)
}