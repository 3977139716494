import axios from 'axios';
import { setLocalStorage } from 'helper/utils';
import Cookies from 'js-cookie';
import userProvider from '../provider/userProvider';
import host from './host';

const axiosNew = axios.create();
let from;
let data;
let getConfig
let isRefresh
let retryCount = 0;

axiosNew.interceptors.request.use(function (config) {
  getConfig = config;
  from = config.url?.split('/api/')[1];
  data = config.data;

  const access_token = Cookies.get('token');
  config.headers['channel'] = 'cms';
  config.headers['platform'] = navigator.platform;
  config.headers['userAgent'] = navigator.userAgent;
  if (access_token != null)
    config.headers.Authorization = 'Bearer ' + access_token;
  else
    setLocalStorage({ key: 'tempUrl', value: window.location.pathname });
    config.headers['secret-key'] = host.tokenSecretKey;
  return config;
}, function (err) {
  return Promise.reject(err);
});

axiosNew.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  const err = error.response;
  const url = err?.config?.url;
  const segments = url?.split('/');
  const lastSegment = segments?.length && segments[segments.length - 1];


  if ((err?.status === 401 && (lastSegment !== undefined && lastSegment !== 'refresh-token'))) {
    Cookies.remove('token')
    try {
      const refreshToken = Cookies.get('refreshToken');
        const token = await userProvider.refreshToken({ refreshToken });
        isRefresh = true;
        retryCount++
        err.config.headers['Authorization'] = 'Bearer ' + token;
        isRefresh = false;
        return axiosNew(err.config);
    } catch (error) {
      throw error
    }
  }

  if (err?.status == 422 || err?.status == 400) {
    return Promise.reject(err)
  }

  return Promise.reject(error)
});

export default axiosNew;
