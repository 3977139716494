import React from 'react'
import styled from 'styled-components'
import { Input, AutoComplete, Select } from 'antd';
import './styles.scss'
import ISearch from 'images/icons/search.svg'
import {ReactComponent as IconSearch} from 'images/icons/search.svg'
import IClose from 'images/icons/close-circle.svg'

const FilterSearchContainer = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
`

const { Option } = Select;

class FilterSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { width, height, options, onChange, onChangeValue, onClearSearchFilterType, value, valueTypeFilter, onSearchOrder, onKeyPress, statusSearch } = this.props
        return (
            <FilterSearchContainer id="filter-search" width={width} height={height}>
                <Input.Group compact>
                    <Select
                        defaultValue={options[0].label}
                        style={{ width: '30%' }}
                        onChange={onChange}
                        value={valueTypeFilter}
                    >
                        {options.map((item, index) => (
                            <Option value={index} key={index}>{item.label}</Option>
                        ))}
                    </Select>
                    <AutoComplete
                        style={{ width: '70%' }}
                        placeholder="ค้นหา"
                        // options={[{ value: 'ceb 1' }, { value: 'kuroky 2' }]}
                        onChange={onChangeValue}
                        value={value}
                        onKeyPress={value?.trim() ? onKeyPress : null}
                    />
                    <div className="icon">
                        {statusSearch ? <img src={IClose} alt="" className="cursor-pointer" onClick={() => onClearSearchFilterType()} /> : null}
                        {/* <img src={ISearch} alt="" className="cursor-pointer" onClick={onSearchOrder} /> */}
                        <IconSearch className="icon-search" onClick={value?.trim() ? onSearchOrder : null} />
                    </div>


                </Input.Group>

            </FilterSearchContainer>
        )
    }
}


FilterSearch.defaultProps = {
    width: 'auto',
    height: 'auto',
    options: [
        {
            label: 'ชื่อลูกค้า',
            value: 'name'
        },
        {
            label: 'หมายเลขออเดอร์',
            value: 'orderId'
        }
    ],
    statusSearch: false
}

export default FilterSearch