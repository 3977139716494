import moment from "moment"
import {
    ADD_KEY_VALUE_CATEGORIES, SET_LEVEL_CATEGORIES, SET_MODAL_CATEGORY, SET_CATEGORY_SYSTEM
} from "redux/actions/categories"
import { isEmpty } from "lodash";

const initialState = {
    categories: [],
    isShowModalCategory: false,
    modalCategory: {
        type: '', // 'add' || 'edit' || 'delete' || 'select'
        level: 1,
        index: 0,
    },
    modalConfirmCategory: {
        isOpen: false,
        message: "",
        action: "submit",
        param: {}
    },
    categoryType: 'default', // default, custom
    categoryLevel1: [],
    categoryLevel2: [],
    categoryLevel3: [],
    selectCategoryLevel1: null,
    selectCategoryLevel2: null,
    selectCategoryLevel3: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_KEY_VALUE_CATEGORIES:
            return {
                ...state,
                [payload?.key]: payload?.value,
            }
        case SET_LEVEL_CATEGORIES:
            return {
                ...state,
                categoryType: !!payload?.categoryType ? payload?.categoryType : state.categoryType,
                modalCategory: !!payload?.modalCategory ? payload?.modalCategory : state.modalCategory,
                selectCategoryLevel1: payload?.level1,
                selectCategoryLevel2: payload?.level2,
                selectCategoryLevel3: payload?.level3,
            }
        case SET_MODAL_CATEGORY:
            return {
                ...state,
                isShowModalCategory: !!payload?.isShowModalCategory ? payload?.isShowModalCategory : state.isShowModalCategory,
                modalCategory: !!payload?.modalCategory ? payload?.modalCategory : state.modalCategory,
                [payload?.selectCategoryLevel?.key]: !!payload?.selectCategoryLevel?.value ? payload?.selectCategoryLevel?.value : state[payload?.selectCategoryLevel?.key],
            }





        case SET_CATEGORY_SYSTEM: {
            const { categoryLevel1 = null, categoryLevel2 = null, categoryLevel3 = null , selectCategoryLevel1 = null, selectCategoryLevel2 = null, selectCategoryLevel3 = null } = payload
            return {
                ...state,
                categoryLevel1: !!categoryLevel1 ? categoryLevel1 : state. categoryLevel1,
                categoryLevel2: !!categoryLevel2 ? categoryLevel2 : state.categoryLevel2,
                categoryLevel3: !!categoryLevel3 ? categoryLevel3 : state.categoryLevel3,
                selectCategoryLevel1: !!selectCategoryLevel1 ? selectCategoryLevel1 : state.selectCategoryLevel1,
                selectCategoryLevel2: !!selectCategoryLevel2 ? selectCategoryLevel2 : state.selectCategoryLevel2,
                selectCategoryLevel3: !!selectCategoryLevel3 ? selectCategoryLevel3 : state.selectCategoryLevel3
            }
        }
        default: {
            return state
        }
    }
}