import ContentText from "components/Broadcast/ContentText";
import ContentImage from "components/Broadcast/ContentImage";
import { Body1 } from 'components/FontStyle'

import IconText from "images/icons/text.svg";
import IconPhoto from "images/icons/photo.svg";

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SET_GALLERY_FIELD } from "../../../redux/actions/gallery";

const renderContetns = (content, useBoardcast) => {
  const { contentType } = content;
  const {
    options,
    setCardIndex,
    handleSwitchItemIndex,
    handleDeleteItem,
    handleUpdateContentItem,
    handleDelteContentItem,
    handleCreateContentImageItem,
    handleDeleteContenImageItem,
  } = useBoardcast;
  switch (contentType) {
    case "text":
      return (
        <ContentText
          indexSelected={content.index}
          contents={content.contents}
          options={options}
          onSwitchIndex={handleSwitchItemIndex}
          onDeleteItem={handleDeleteItem}
          onUpdateContentItem={handleUpdateContentItem}
          onDelteContentItem={handleDelteContentItem}
        />
      );
    case "image":
      return (
        <ContentImage
          indexSelected={content.index}
          contents={content.contents}
          options={options}
          onSwitchIndex={handleSwitchItemIndex}
          onDeleteItem={handleDeleteItem} // delete card item
          onUpdateContentItem={handleUpdateContentItem} // update link
          onCreateContentImageItem={handleCreateContentImageItem} // add image
          onDeleteContenImageItem={handleDeleteContenImageItem} // delete image
          onSetCardIndex={setCardIndex} //
        />
      );
    default:
      return <></>;
  }
};

const ContentList = (props) => {
  const hookBroadcast = useBoardcast(props);
  const { contents, handleCreateNewItem } = hookBroadcast;
  return (
    <section className="section-container">
      <section className="section contents">
        {contents && contents.length > 0 ? (
          contents.map((content, index) => (
            <div key={index}>{renderContetns(content, hookBroadcast)}</div>
          ))
        ) : (
          <div className="content-container">
            <div className="show-content-box">
              <div className="d-flex align-items-center justify-content-center h-100">
                <Body1>
                  <span style={{ color: "#BFBFBF" }}>ไม่มีรายการ</span>
                </Body1>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center mt-4">
          <button
            className="btn-camera ml-4 d-flex justify-content-center align-items-center"
            onClick={() => handleCreateNewItem("text")}
          >
            <img src={IconText} className="mr-2" alt='icon-text'/>
            เพิ่มกล่องข้อความ
          </button>
          <button
            className="btn-camera ml-4 d-flex justify-content-center align-items-center"
            onClick={() => handleCreateNewItem("image")}
          >
            <img src={IconPhoto} className="mr-2" alt='icon-camera' />
            เพิ่มกล่องรูปภาพ
          </button>
        </div>
      </section>
    </section>
  );
};

export default ContentList;

export const useBoardcast = (props) => {
  const { 
    // contentList, 
    onContentListChange } = props;
  // const mockDataTest = mockData();
  const stateGallery = useSelector((state) => state.gallery);

  const dispatch = useDispatch();
  const clearBroadCastGallery = () =>
    dispatch({
      type: SET_GALLERY_FIELD,
      payload: { key: "boardcastUploadEvent", value: [] },
    });

  const [contents, setContents] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [cardIndex, setCardIndex] = React.useState(0);

  useEffect(() => {
    // setContents(mockDataTest)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    // generate order index option
    setOptions(generateOptions(contents));

    // parse data back to parent node
    onContentListChange(contents);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents]);

  const generateOptions = (contentsParam) =>
    contentsParam.map((content, index) => ({
      value: content.index,
      name: content.index,
    }));

  const handleSwitchItemIndex = (indexOld, indexNew) => {
    const newContents = moveIndex(contents, indexOld, indexNew);
    setContents(newContents);
  };

  const handleCreateNewItem = (contentType) => {
    const newContents = createNewItem(contents, contentType);
    setContents(newContents);
  };

  const handleDeleteItem = (index) => {
    const newContents = deleteItem(contents, index);
    setContents(newContents);
  };

  const handleUpdateContentItem = (indexSelected, contentIndex, newItem) => {
    const newContents = [...contents].map((item, _index) => {
      if (item.index === indexSelected)
        item.contents = item.contents.map((content, _contentIndex) => {
          if (contentIndex === _contentIndex) return newItem;

          return content;
        });
      return item;
    });
    setContents(newContents);
  };

  const handleDelteContentItem = (indexSelected, contentIndex) => {
    const newContents = [...contents].map((item, _index) => {
      if (item.index === indexSelected)
        item.contents = item.contents.filter(
          (content, _contentIndex) => _contentIndex !== contentIndex
        );
      return item;
    });
    setContents(newContents);
  };

  const handleCreateContentImageItem = (indexSelected) => {
    const broadCastImage = stateGallery[`boardcastUploadEvent`];
    const newImageContents =
      broadCastImage && broadCastImage.length > 0
        ? broadCastImage.map((image) => ({ data: image, link: "" }))
        : [];

    if (broadCastImage && broadCastImage.length > 0) {
      // insert only cardIndex that set
      if (cardIndex === indexSelected) {
        const newContents = contents.map((content) => {
          if (content.index === indexSelected) {
            content.contents = [...content.contents, ...newImageContents];
          }
          return content;
        });
        setContents(newContents);
        clearBroadCastGallery();
      }
    }
  };

  const handleDeleteContenImageItem = (indexSelected, contentIndex) => {
    const newContents = contents.map((content) => {
      if (content.index === indexSelected) {
        content.contents = content.contents.filter(
          (contentImage, contentImageIndex) =>
            contentImageIndex !== contentIndex
        );
      }
      return content;
    });
    setContents(newContents);
  };

  return {
    contents,
    options,
    cardIndex,
    setCardIndex,
    handleSwitchItemIndex,
    handleCreateNewItem,
    handleDeleteItem,
    handleUpdateContentItem,
    handleDelteContentItem,
    handleCreateContentImageItem,
    handleDeleteContenImageItem,
  };
};

const moveIndex = (arr, indexOld, indexNew) => {
  let tempIndex = { ...[...arr][indexOld - 1] };
  let orderIndex = 1;
  const removeIndexOld = [...arr].filter((item) => item.index !== indexOld);

  return removeIndexOld.reduce((prev, item, index) => {
    let newIndexItem = { ...item };

    if (index === indexNew - 1) {
      tempIndex.index = orderIndex;
      prev = [...prev, tempIndex];
      orderIndex++;

      newIndexItem.index = orderIndex;
      prev = [...prev, newIndexItem];
      orderIndex++;
    } else {
      newIndexItem.index = orderIndex;
      prev = [...prev, newIndexItem];
      orderIndex++;
    }

    if (index === removeIndexOld.length - 1 && indexNew === [...arr].length)
      prev = [...prev, tempIndex];

    return prev;
  }, []);
};

const deleteItem = (arr, index) => {
  const contents = arr.filter((item) => item.index !== index);
  return contents.map((content, index) => {
    content.index = index + 1;
    return content;
  });
};

const createNewItem = (arr, contentType) => {
  switch (contentType) {
    case "text":
      return [...arr, newContent(arr, "text")];
    case "image":
      return [...arr, newContent(arr, "image")];
    default:
      return;
  }
};

const newContent = (arr, contentType) => ({
  contentType,
  contents: contentType === "text" ? [{ data: "", link: "" }] : [],
  index: arr && arr.length === 0 ? 1 : arr[arr.length - 1].index + 1,
});

/* -------------------------------------------------------------------------- */
/*                              Create mock data                              */
/* -------------------------------------------------------------------------- */
// const mockData = () => {
//   const sampleText1 = `สุดยอดข้อเสนอสุดพิเศษ เป็นเจ้าของ iPhone 12 ทุกรุ่น ราคาลดมากกว่าทุกครั้ง พร้อมผ่อน 0% ได้สบายๆ นานสูงถึง 40 เดือน รีบเป็นเจ้าของได้แล้ววันนี้ ที่เอไอเอส ช็อป, เทเลวิซ และตัวแทนจำหน่ายที่ร่วมรายการ โปรโมชั่นตั้งแต่วันที่ 3 ก.ค. 64 – 16 ส.ค. 64 เท่านั้น`;
//   const sampleText2 = `ยุคสมัยในปัจจุบันกำลังเปลี่ยนไปอย่างรวดเร็ว ระบบสัญญาณอินเทอร์เน็ตก็ต้องไวเช่นกัน เฮียร์จึงมั่นใจในระบบของ AIS เพราะเป็นแหล่งผู้นำเครือข่ายคุณภาพที่ดีที่สุด และพบกับโปรโมชั่นพิเศษ AIS จัดให้! เป็นเจ้าของ iPhone 12 ราคาพิเศษ ผ่อน 0% นานสูงถึง 40 เดือน
//   นอกจากโปรโมชั่นนี้แล้ว เฮียร์ขอแนะนำโปรโมชั่นอื่นๆ อีกมากมายของ AIS สามารถติดตามโปรโมชั่นอื่นๆ ของแบรนด์นี้ได้ที่ลิ้งก์ >> https://salehere.co.th/ais หรือจะเลือกดูโปรโมชั่นทั้งหมดที่นี่ >> https://salehere.co.th/promotions รับรองว่าคุณจะไม่พลาดโปรโมชั่นเด็ดๆ อย่างแน่นอน
//   `;
//   const sampleImage1 = {
//     id: 2232,
//     path: "image/5c5e95ea-2598-4cc1-b5fb-fa6c73d04c04.jpeg",
//   };
//   const sampleImage2 = {
//     id: 2233,
//     path: "image/8b72c455-0714-4ea7-b4d1-81e8df5b70e9.jpeg",
//   };
//   const sampleImage3 = {
//     id: 2234,
//     path: "image/5a37edf5-2ab7-4b9a-8f63-d5a01d8080a8.jpeg",
//   };
//   const sampleImage4 = {
//     id: 2235,
//     path: "image/950e19c3-a0ca-4204-990b-e298402403bd.jpeg",
//   };
//   // const sampleImage5 = {
//   //   id: 2236,
//   //   path: "image/1d7fe662-9597-4bb3-9d94-0b35741f3ffd.jpeg",
//   // };
//   const sampleImage6 = {
//     id: 2237,
//     path: "image/1db71d22-4927-4917-9cdd-68b0d297508e.jpeg",
//   };
//   const sampleLink = `http://seller.outletlive.com/`;
//   const contentTextData1 = [
//     {
//       data: sampleText1,
//       link: sampleLink,
//     },
//   ];
//   const contentTextData2 = [
//     {
//       data: sampleText2,
//       link: sampleLink,
//     },
//   ];
//   const contentImageData1 = [
//     {
//       data: sampleImage1,
//       link: sampleLink,
//     },
//     {
//       data: sampleImage2,
//       link: sampleLink,
//     },
//     {
//       data: sampleImage3,
//       link: sampleLink,
//     },
//     {
//       data: sampleImage6,
//       link: sampleLink,
//     },
//     {
//       data: sampleImage4,
//       link: sampleLink,
//     },
//   ];
//   const contentImageData2 = [
//     {
//       data: sampleImage2,
//       link: sampleLink,
//     },
//     {
//       data: sampleImage3,
//       link: sampleLink,
//     },
//     {
//       data: sampleImage6,
//       link: sampleLink,
//     },
//   ];
  
//   const contentImage1 = { contentType: "image", contents: contentImageData1 };
//   const contentImage2 = { contentType: "image", contents: contentImageData2 };
//   const contentText1 = { contentType: "text", contents: contentTextData1 };
//   const contentText2 = { contentType: "text", contents: contentTextData2 };

//   const mockData1 = [
//     { ...contentText1, index: 1 },
//     { ...contentImage1, index: 2 },
//     { ...contentText2, index: 3 },
//     { ...contentImage2, index: 4 },
//     { ...contentText2, index: 5 },
//     { ...contentText1, index: 6 },
//   ];
//   const mockData2 = [
//     { ...contentText1, index: 1 },
//     { ...contentImage1, index: 2 },
//     { ...contentText2, index: 3 },
//     { ...contentImage2, index: 4 },
//   ];
//   const mockData3 = [
//     { ...contentText1, index: 1 },
//     { ...contentText2, index: 2 },
//   ];
//   return [];
// };


