export const ADD_LIST_SELECTED_SHOP = "ADD_LIST_SELECTED_SHOP";
export const REMOVE_LIST_SELECTED_SHOP = "REMOVE_LIST_SELECTED_SHOP";
export const SET_KEY_LIST_SELECTED_SHOP = "SET_KEY_LIST_SELECTED_SHOP";

export function dispatchListSelectedShop(param) {
  return {
    type: param.type,
    payload: param.payload,
  };
}
