/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment'
import { isSpecialCharacters } from 'helper/utils'
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm'
import { dispatchNotification, OPEN_TOAST } from 'redux/actions/notification'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert'
import { CLOSE_MODAL_PREVIEW_BROADCAST, dispatchBroadcast, OPEN_MODAL_PREVIEW_BROADCAST } from 'redux/actions/broadcast'


export const useFormCreateBroadcastNoti = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const sendingOption = {
        PRESET: { value: "PRESET", label: "ตั้งค่าล่วงหน้า" },
        IMMEDIATELY: { value: "IMMEDIATELY", label: "ส่งทันที" },
    };

    const broadcastType = [
        { label: 'แจ้งเตือนแบบส่งลิงค์ URL', value: 'notification' },
        { label: 'แจ้งเตือนแบบสร้างเนื้อหาบทความ', value: 'content' }
    ]

    const activeTabSelected = [
        { label: 'ข้อมูลสำหรับการแจ้งเตือน', value: 'notificationTab', type: ['content', 'notification'] },
        { label: 'ข้อมูลส่วนของเนื้อหา', value: 'contentTab', type: ['content'] }
    ]

    const [typeBroadcast, setTypeBroadcast] = useState(broadcastType[0].value)
    const [activeTab, setActiveTab] = useState(activeTabSelected[0].value)
    const [notification, setNotification] = useState('')
    const [desc, setDesc] = useState('')
    const [url, setUrl] = useState('')
    const [date, setDate] = useState(new Date())
    const [time, setTime] = useState(moment())
    const [typeSend, setTypeSend] = useState(sendingOption.PRESET.value)
    const [notiEmail, setNotiEmail] = useState(false)
    const [notiAppWeb, setNotiAppWeb] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [errors, setErrors] = useState({})
    const [content, setContent] = useState([])
    const [htmlFormat, setHtmlFormat] = useState(null)
    useEffect(() => {
        return () => {
            cleanup()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeBroadcast])

    useEffect(() => {
        const { notificationContent, ...withoutNoti } = errors
        setErrors(withoutNoti)
    }, [notification])
    useEffect(() => {
        const { description, ...withoutDesc } = errors
        setErrors(withoutDesc)
    }, [desc])
    useEffect(() => {
        const { keyword, ...withoutKeyword } = errors
        setErrors(withoutKeyword)
    }, [keyword])
    useEffect(() => {
        const { notiError, ...withoutNoti } = errors
        setErrors(withoutNoti)
    }, [notiEmail, notiAppWeb])

    const setDateTime = (key, value) => {
        if (key === 'date') { setDate(value); }
        if (key === 'time') { setTime(value); }
    }

    const closeModalPreview = () => {
        dispatch(dispatchBroadcast({ type: CLOSE_MODAL_PREVIEW_BROADCAST }))
    }

    const cleanup = () => {
        setNotification('')
        setDesc('')
        setUrl('')
        setDate(new Date())
        setTime(moment())
        setTypeSend(sendingOption.PRESET.value)
        setNotiEmail(false)
        setNotiAppWeb(false)
        setKeyword('')
        setErrors({})
        setContent([])
        setHtmlFormat(null)
        // dispatch(dispatchBroadcast({type: CLEAR_FORM_DATA_BROADCAST}))
    }

    const validate = () => {
        const data = {
            notificationContent: notification,
            description: desc,
            url: url,
            typeSend: typeSend,
            date: date,
            time: time,
            keyword: keyword,
            notiAppWeb: notiAppWeb,
            notiEmail: notiEmail,
            content: content
        }

        let errors = {};
        let formIsValid = true;

        if (!data["notificationContent"]) {
            formIsValid = false;
            errors["notificationContent"] = "กรุณากรอกข้อมูล";
        }
        if (!data["description"]) {
            formIsValid = false;
            errors["description"] = "กรุณากรอกข้อมูล";
        }

        if (typeBroadcast === broadcastType[1].value) {
            if (!data["keyword"]) {
                formIsValid = false;
                errors["keyword"] = "กรุณากรอกข้อมูล";
            }
            if (data["keyword"]) {
                let text = data['keyword']
                const checkChars = isSpecialCharacters(text)
                if (!checkChars) {
                    formIsValid = false;
                    errors["keyword"] = "หัามใช้อักขระพิเศษ";
                }
            }
            if (!data["notiAppWeb"] && !data["notiEmail"]) {
                formIsValid = false;
                errors["notiError"] = "กรุณาเลือกช่องทางการแจ้งเตือน";
            }
        }

        setErrors(errors)
        return formIsValid
    }

    const createForm = async (event) => {
        event.preventDefault()
        const isValidate = await validate()
        if (isValidate) {
            if (typeBroadcast === broadcastType[0].value) {
                await dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ!`, type: 'success' } }))
                history.push('/manage/broadcast')
            }
            if (typeBroadcast === broadcastType[1].value) {
                if (content.length === 0) {
                    dispatch(dispatchModalAlert({
                        type: OPEN_MODAL_ALERT, payload: {
                            isOpen: true,
                            message: <div className="text-center">กรุณากรอกข้อมูลส่วนของเนื้อหา <br /> ก่อนทำรายการแสดงตัวอย่างก่อนบันทึก</div>,
                            type: 'none',
                            confirm: () => setActiveTab(activeTabSelected[1].value)
                        }
                    }))
                }
                if (content.length !== 0) {
                    handlePreview()
                }
            }
        }
        if (!isValidate) {
            if (activeTab === activeTabSelected[1].value) {
                dispatch(dispatchModalAlert({
                    type: OPEN_MODAL_ALERT, payload: {
                        isOpen: true,
                        message: <div className="text-center">กรุณากรอกข้อมูลส่วนของการแจ้งเตือน <br /> ก่อนทำรายการแสดงตัวอย่างก่อนบันทึก</div>,
                        type: 'none',
                        confirm: () => setActiveTab(activeTabSelected[0].value)
                    }
                }))
            }
        }

    }

    const cancelForm = async (event) => {
        dispatch(dispatchModalConfirm({
            type: OPEN_MODAL_CONFIRM, payload: {
                isOpen: true,
                message: <div className="text-center">คุณแน่ใจหรือไม่ที่จะทิ้งบรอดแคสต์นี้ <br /> ข้อมูลที่คุณสร้างไว้จะไม่ถูกบันทึก</div>,
                btnCancel: 'ยกเลิก',
                btnConfirm: 'ยืนยัน',
                confirm: () => history.push('/manage/broadcast')
            }
        }))
    }

    const handlePreview = () => {
        console.log('Preview')
        dispatch(dispatchBroadcast({ type: OPEN_MODAL_PREVIEW_BROADCAST }))
    }

    const createContentBroadcast = async () => {
        //call api create content
        closeModalPreview()
        await dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ!`, type: 'success' } }))
        history.push('/manage/broadcast')
    }



    return {
        notification,
        setNotification,
        desc,
        setDesc,
        url,
        setUrl,
        date, setDate,
        time, setTime,
        typeSend, setTypeSend,
        setDateTime,
        notiEmail, setNotiEmail,
        notiAppWeb, setNotiAppWeb,
        cleanup,
        keyword, setKeyword,
        validate, createForm,
        errors, setErrors,
        broadcastType, activeTabSelected,
        typeBroadcast, setTypeBroadcast,
        activeTab, setActiveTab,
        content, setContent,
        cancelForm,
        htmlFormat,
        setHtmlFormat,
        closeModalPreview,
        createContentBroadcast
    }
}