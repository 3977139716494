import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap';
import './modalconfirm.scss'
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from '../../redux/actions/modalConfirm';
import classnames from 'classnames'
import { Caption, H5 } from 'components/FontStyle';
import Loading from 'components/Loading';
import IconSuccess from 'images/icons/success.svg'
import IconAlert from 'images/icons/alert.svg'


class ModalConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false
        }
    }

    static defaultProps = {
        isOpen: false,
        message: '',
        message2: '',
        btnConfirm: 'ใช่',
        btnCancel: 'ไม่ใช่',
        disabled: false,
        confirm: () => { },
    }

    confirmModal = () => {
        if(this.props.modalConfirm?.confirm) {
            this.props.modalConfirm.confirm && this.props.modalConfirm.confirm()
        } else {
            this.props.confirm();
        }
        this.props.dispatch(dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: false }))
    }

    cancelModal = () => {
        if (this.props.isClose !== undefined) return this.props.isClose()
        this.props.dispatch(dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: false }))
    }

    showIconStatus = (status) => {
        switch(status) {
            case "success" :  return <img src={IconSuccess} alt=""/>
            case "fail" : return <img src={IconAlert} alt=""/>
            default : return null
        }
    }


    render() {
        /// message show
        const { message, isOpen, btnConfirm, btnCancel, message2, type, disabled } = this.props
        const {status} = this.props.modalConfirm || {}
        return (
            <React.Fragment>
                {/* <Button color="danger" onClick={() => this.setState({ modal: true })}>{'modalconfirm'}</Button> */}
                <Modal isOpen={isOpen} toggle={() => this.setState({ modal: false })} modalClassName="modal-dialog-confirm" centered>
                    {/* <ModalHeader toggle={() => this.setState({ modal: true })}></ModalHeader> */}
                    <ModalBody>
                        <div className="header-wrap">
                            {status && <>{this.showIconStatus(status)}</>}
                            <H5 className="text-center">{message}</H5>
                            <H5 className={classnames({ 'text-success': type === "success" })}>{message2}</H5>
                        </div>
                        <div className="w-100 d-flex" style={{ justifyContent: 'space-evenly' }}>

                            <span><button className="btn-cancel" onClick={this.cancelModal}><Caption>{btnCancel}</Caption></button></span>
                            <span><button className="btn-submit" onClick={this.confirmModal} disabled={disabled}><Caption>{btnConfirm}</Caption></button></span>


                        </div>
                    </ModalBody>
                </Modal>
                <Loading/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    modalConfirm: state.modalConfirm.modalConfirm
})

export default connect(mapStateToProps, null)(ModalConfirm)