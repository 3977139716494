/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import host from 'config/host';

import { ButtonAdd, InputSearch } from "components/output";
import Loading from 'components/Loading';
import DropDownPlaylist from "components/DropdownPlaylist";
import PaginationComponent from 'components/pagination';
import ModalAlert from 'components/modalAlert/ModalAlert';

import {
  SET_MODAL_ADD_PLAYLIST_MALL,
  UPDATE_PLAYLIST_SHOP_ORDER,
  ADD_SHOP_TO_PLAYLIST,
  DELETE_SHOP_FROM_PLAYLIST,
  GET_PLAYLIST_SHOP_COUPON,
} from 'redux/actions/playlists';
import {
  GET_SHOPS_COUPON,
  GET_SHOPS_SEARCH,
  ADD_KEY_VALUE_SHOP,
} from 'redux/actions/shop';
import {
  OPEN_MODAL_ALERT,
} from 'redux/actions/modalAlert';

import "./style.scss";
import IconClose from "images/icons/close.svg";
import IconTrash from "images/icons/trash.svg";
import IconEmpty from 'images/icons/status-error.svg';
import Checkbox from "components/Checkbox";
import classNames from "classnames";
import playlistsProvider from "provider/playlistsProvider";
import globalProvider from 'provider/globalProvider'

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */

const EmptyItem = (props) => {
  return <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
    <img src={IconEmpty} alt='icon-empty'/>
    <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
  </div>
}

const TableShopList = (props) => {
  const { shopList, dropDownPlaylist, handleSwitchIndex, handleDeleteShop, sortDisplay } = props;
  return (
    <div className="container-table">
      <div className="table-header">
        <div className="text-center">ลำดับ</div>
        <div className="text-left">ชื่อร้านค้า</div>
        <div className="text-right">จำนวนโปรโมชั่น</div>
        <div className="text-right">ลดสูงสุด</div>
        <div className="d-flex align-items-center"></div>
      </div>
      {shopList &&
        shopList.length > 0 ?
        shopList.map((shop, index) => (
          <RowShopList
            key={`RowShopList${index}`}
            shop={shop}
            sortDisplay={sortDisplay}
            dropDownPlaylist={dropDownPlaylist}
            handleSwitchIndex={handleSwitchIndex}
            handleDeleteShop={handleDeleteShop}
          />
        ))
        :
        <EmptyItem />
      }
    </div>
  );
};

const RowShopList = (props) => {
  const { dropDownPlaylist, handleDeleteShop, handleSwitchIndex, shop, sortDisplay } = props;
  const { order, couponCount, couponDiscountMax } = props?.shop || {};
  const { name, logoPath, } = props?.shop?.shop || {};
  return (
    <div className="table-row">
      <div className="d-flex align-items-center justify-content-center">
        <DropDownPlaylist
          options={dropDownPlaylist}
          disabled={sortDisplay !== 'playlist'}
          defaultValue={order}
          handleSelected={(orderNumber) => handleSwitchIndex(orderNumber, shop)}
        />
      </div>
      <div className="d-flex align-items-center text-left flex-row">
        <img src={`${host.avatar}${logoPath?.path}`} className="mr-2" alt='icon-logo'/>
        <span>{name}</span>
      </div>
      <div className="d-flex align-items-center justify-content-end flex-row">
        {couponCount}
      </div>
      <div className="d-flex align-items-center justify-content-end flex-row">
        {couponDiscountMax}
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <ButtonDeleteShop
          handleDeleteShop={() => handleDeleteShop(props?.shop)}
        />
      </div>
    </div>
  );
};

const ButtonDeleteShop = (props) => {
  const { handleDeleteShop } = props;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="clickable" onClick={handleDeleteShop}>
      <img src={IconTrash} alt='icon-trash'/>
    </a>
  );
};

const TableAddShop = (props) => {
  const { shopList, handleSelectShop, pageOffset } = props;

  return (
    <div className="container-table">
      <div className="table-header">
        <div></div>
        <div className="text-center">ลำดับ</div>
        <div className="text-left">ชื่อร้านค้า</div>
        <div className="text-center">จำนวนโปรโมชั่น</div>
        <div className="text-center">ลดสูงสุด</div>
        <div className="d-flex align-items-center"></div>
      </div>
      {shopList &&
        shopList.length > 0 &&
        shopList.map((shop, index) => (
          <RowAddShop
            key={`RowAddShop${index}`}
            shop={shop}
            index={pageOffset + (index + 1)}
            handleSelectShop={handleSelectShop}
          />
        ))}
    </div>
  );
};

const RowAddShop = (props) => {
  const { handleSelectShop, shop, index } = props;
  const { name, logoPath, id, isSelected, couponCount, couponDiscountMax } = shop || {};
  return (
    <div className="table-row">
      <div className="d-flex align-items-center justify-content-center">
        <Checkbox
          title={""}
          checked={isSelected}
          value={id}
          onChange={() => handleSelectShop(shop)}
          name={name}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        {index}
      </div>
      <div className="d-flex align-items-center text-left flex-row">
        <img src={`${logoPath?.host}${logoPath?.path}`} alt='icon-logo'/>
        <span className="ml-2">{name}</span>
      </div>
      <div className="d-flex align-items-center justify-content-center flex-row">
        {couponCount}
      </div>
      <div className="d-flex align-items-center justify-content-center flex-row">
        {couponDiscountMax}
      </div>
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                             main component                                 */
/* -------------------------------------------------------------------------- */
const ModalAddShop = (props) => {

  const {
    isOpen,
    shopList,
    keyword,
    statusSearch,
    pageSizeOptions,
    total,
    page,
    limit,
    toogle,
    setKeyword,
    handleSearchShop,
    handleSearchKeyPress,
    handleClearSearch,
    handleSelectShop,
    handleAddShopToPlaylist,
    handleShowSizeChange,
    handlePageChange
  } = useModalAddShopCoupon(props);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toogle}
      backdrop="static"
      style={{ width: "80%", maxWidth: "80%" }}
    >
      <ModalHeader toggle={toogle}>
        <div className="d-flex justify-content-between w-100">
          <div>
            <span className="header-lable"> เพิ่มร้านค้า </span>
          </div>
          <div className="close-btn-wrap">
            <img src={IconClose} onClick={toogle} alt='icon-close'/>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Loading />
        <div className="container-modal-add-mall-coupon p-4">
          <div className="row mb-4">
            <div className="col-5">
              <InputSearch
                placeholder={"ค้นหาร้านค้า"}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyPress={handleSearchKeyPress}
                handleSearch={handleSearchShop}
                onClearSearch={handleClearSearch}
                statusSearch={statusSearch}
                defaultValue={keyword}
              />
            </div>
          </div>
          {shopList && shopList.length > 0 &&
            <div className="d-flex w-100 justify-content-end mb-4">
              <PaginationComponent
                defaultCurrent={1}
                total={total}
                onChange={(page) => handlePageChange(page)}
                pageSize={limit}
                pageSizeOptions={pageSizeOptions}
                onShowSizeChange={handleShowSizeChange}
                current={page}
                locale={{ items_per_page: '/ หน้า' }}
              />
            </div>
          }
          <TableAddShop
            shopList={shopList}
            pageOffset={limit * (page - 1)}
            handleSelectShop={handleSelectShop}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center w-100 flex-row">
          <button className="btn-cancel mr-3" onClick={toogle}>
            ยกเลิก
          </button>
          <button className="btn-submit" onClick={handleAddShopToPlaylist}>
            เพิ่มลงเพลย์ลิส
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const PlaylistShopCoupon = (props) => {
  // let sortDisplayPlaylist = [
  //   { label: 'ตามเพลย์ลิสต์', value: 'playlist' },
  //   { label: 'สุ่มคูปอง', value: 'random' },
  //   { label: 'วันที่สร้าง', value: 'createdAt' }
  // ]

  const { shopList,
    dropDownPlaylist,
    playlistsId,
    playlistsName,
    playlistsType,
    handleDeleteShop,
    handleSwitchIndex,
    handleOpenModalAddShop,
    sortDisplay,
    handleSetSortType,
    listSortType,
     } = usePlaylistShopCoupon(props);

  return (
    <div className="container-playlist-shop-coupon">
      <div className="d-flex justify-content-between">
        <h3>{playlistsName}</h3>
        <div className="mb-1">
          <ButtonAdd label="เพิ่มร้านค้าลงเพลย์ลิสต์" handleAdd={handleOpenModalAddShop} />
        </div>
      </div>

      <div className='playlist-format-filter'>
        <span>รูปแบบการแสดงผลในหน้าเว็บ</span>
        {listSortType && listSortType.map((list, listIndex) => (
          <React.Fragment key={`sortFilter${listIndex}`}>
            <li
              className={classNames({ 'active': sortDisplay === list.value.name })}
              onClick={() => handleSetSortType(list.value.name)}>
              {list.label}
            </li>
          </React.Fragment>
        ))}
      </div>

      <TableShopList
        shopList={shopList}
        dropDownPlaylist={dropDownPlaylist}
        handleDeleteShop={handleDeleteShop}
        handleSwitchIndex={handleSwitchIndex}
        sortDisplay={sortDisplay}
      />
      <ModalAddShop playlist={{ playlistsId, playlistsType }} />
      <ModalAlert />
    </div>
  );
};



/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const usePlaylistShopCoupon = (props) => {
  const dispatch = useDispatch();
  const { playlistsId, playlistsName, playlistsType, sortType, isPlatFormMode } = props.playlist;
  // const [playlistItem,setPlaylistItem] = useState(null)
  const [shopList, setShopList] = useState([]);
  const [dropDownPlaylist, setDropDownPlaylist] = useState([]);
  const [sortDisplay, setSortDisplay] = useState(null)
  const [listSortType, setListSortType] = useState(null)

  const playlistShopCoupons = useSelector((state) => state.playlists.playlistShopCoupons);

  // initial component
  useEffect(() => {
   
    async function fetchData() {
      // You can await here
      await initialComponent()
      // ...
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // set up shop list
    if (playlistShopCoupons){
      setShopList(playlistShopCoupons);
      if(playlistShopCoupons.length > 0){
        setSortDisplay(playlistShopCoupons[0].sortType)
      }
    } 
  }, [playlistShopCoupons])

  useEffect(() => {
    setSortDisplay(sortType)
  }, [sortType])

  useEffect(() => {
    // set up dropdown playlist
    if (shopList) setDropDownPlaylist(getDropDownPlayListOption(shopList));
  }, [shopList]);

  const initialComponent = async () => {
    // await dispatch(dispatchPlaylists({ type: GET_PLAYLISTS, payload: { isPlatFormMode } }))
    // await dispatch(dispatchPlaylists({ type: GET_LISTKEY }))
    await getPlaylistShopCoupon()
    await getListSortType()
    await getPlaylistItem()
  }

  const getListSortType = async () => {
    const getListSortType =  await globalProvider.getListSortType()
    const listSortType = await Promise.all(getListSortType.data.map((list,listIndex) => (
      {
        label: list.nameTh,
        value: list
      }
    )))
    setListSortType(listSortType)
    // setSortDisplay(listSortType)
  }

  const getPlaylistShopCoupon = () => dispatch({ type: GET_PLAYLIST_SHOP_COUPON, payload: { playlistId: playlistsId } })

  const getPlaylistItem = async () => {
    const res = await playlistsProvider.getPlaylistById(playlistsId) 
    setSortDisplay(res.data.sortType)
  }

  const getDropDownPlayListOption = (shopList) => {
    if (shopList && shopList.length > 0)
      return shopList.map((shop) => ({ name: `${shop.order}`, value: shop.order }));
    else
      return [];
  }

  const handleSetSortType = async (value) => {
    const body = { sortType: value }
    const data = await playlistsProvider.updatePlaylists({ body, playlistsId })
    if(data){
      setSortDisplay(data.sortType)
      // await dispatch(dispatchPlaylists({ type: GET_PLAYLISTS, payload: { isPlatFormMode } }))
      // await dispatch(dispatchPlaylists({ type: GET_LISTKEY }))
      getPlaylistShopCoupon()
      // const dataa = getPlaylistItem()
      // console.log(`dataa`, dataa)
    }
    
  }

  const handleDeleteShop = (shop) => {
    dispatch({
      type: DELETE_SHOP_FROM_PLAYLIST,
      payload: {
        playlistShopId: shop.id,
        playlistId: playlistsId,
        playlistsType: playlistsType
      }
    })
  };

  const handleSwitchIndex = (orderNumber, shop) => {
    dispatch({
      type: UPDATE_PLAYLIST_SHOP_ORDER,
      payload: {
        playlistShopId: shop.id,
        orderNumber,
        playlistId: playlistsId,
        playlistsType: playlistsType
      }
    })
  };

  const handleOpenModalAddShop = () => {
    dispatch({ type: SET_MODAL_ADD_PLAYLIST_MALL, payload: { isOpen: true } })
  }

  return {
    shopList,
    playlistsId,
    playlistsName,
    playlistsType,
    dropDownPlaylist,
    handleOpenModalAddShop,
    handleDeleteShop,
    handleSwitchIndex,
    sortDisplay,
    setSortDisplay,
    handleSetSortType,
    listSortType,
    isPlatFormMode
  };
};

const useModalAddShopCoupon = props => {
  const { playlistsId, playlistsType, isPlatFormMode} = props.playlist;
  const dispatch = useDispatch();
  const modalAddPlayListItem = useSelector(state => state.playlists.modalAddPlayListItem);
  const { isOpen } = modalAddPlayListItem;
  const pageSizeOptions = ["10", "20", "40"];

  const [statusSearch, setStatusSearch] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [shopList, setShopList] = useState([]);
  const [shopSelected, setShopSelected] = useState([]);
  // const itemPerPage = 10;

  const toogle = () => dispatch({ type: SET_MODAL_ADD_PLAYLIST_MALL, payload: { isOpen: false } })
  const { shopCoupons, page, limit, total, shopsSearch } = useSelector((state) => state.shop);

  useEffect(() => {
    if (isOpen && isOpen === true)
      initialComponent();
    else
      resetComponent();
  }, [isOpen]);

  useEffect(() => {
    if (shopCoupons)
      initialShopList(shopCoupons);
  }, [shopCoupons]);

  useEffect(() => {
    if (shopsSearch) {
      dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'total', value: shopsSearch.count } });
      initialShopList(shopsSearch.shop);
    }
  }, [shopsSearch])

  const initialComponent = () => getShops(1, 10);

  const initialShopList = (shopItem) => {
    // add field isSelected
    if (shopItem) {
      const shopSelectedId = shopSelected.map(shop => shop.id);
      const _shops = shopItem.map((shop) => {
        // match item with shop selected
        const isMatchForShopSelected = shopSelectedId.indexOf(shop.id) !== -1;
        shop = { ...shop, isSelected: isMatchForShopSelected }
        return shop;
      });
      setShopList(_shops);
    }
  };

  const resetComponent = () => {
    setStatusSearch(false);
    setKeyword("");
    setShopList([]);
    setShopSelected([]);
  }

  const getShops = (page, pageLimit) => {
    if (pageLimit)
      dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'limit', value: pageLimit } });

    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: page } });
    dispatch({ type: GET_SHOPS_COUPON });
  }

  const handleShowSizeChange = (page, pageSize) => {
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'limit', value: pageSize } });
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: page } });
  }

  const handlePageChange = page => getShops(page)

  const handleSearchShop = () => {

    if (keyword.trim().length === 0) {
      setStatusSearch(false);
      dispatch({ type: GET_SHOPS_SEARCH, payload: { keyword } })
    } else {
      setStatusSearch(true);
      dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: 1 } });
      dispatch({ type: GET_SHOPS_SEARCH, payload: { keyword } })
    }

  };

  const handleSearchKeyPress = event => {
    if (event.key === "Enter")
      handleSearchShop();
  }

  const handleClearSearch = () => {
    setKeyword("");
    initialComponent();
  }

  const handleSelectShop = (selectedShop) => {

    // switch check box value
    const shopListUpdated = shopList.map((shop) => {
      if (shop.id === selectedShop.id)
        shop.isSelected = !shop.isSelected;
      return shop;
    });

    setShopList(shopListUpdated);

    // store shop selected id
    if (selectedShop.isSelected === true)
      setShopSelected([...shopSelected, selectedShop]);
    else
      setShopSelected([...shopSelected].filter(shop => shop.id !== selectedShop.id));

  };

  const handleAddShopToPlaylist = () => {
    if (validateShopSelected()) {
      const shopSelectedId = shopSelected.map(shop => shop.id);
      dispatch({
        type: ADD_SHOP_TO_PLAYLIST,
        payload: { shopIds: shopSelectedId, playlistId: playlistsId, playlistsType }
      })
    }
  };

  const validateShopSelected = () => {
    if (shopSelected && shopSelected.length < 1) {
      openModalAlert()
      return false;
    } else {
      return true;
    }
  }

  const openModalAlert = () => {
    dispatch({
      type: OPEN_MODAL_ALERT,
      payload: {
        isOpen: true, message: 'กรุณาเลือกมอล์ลก่อนเพิ่มลงเพลลิสต์',
        type: 'alert'
      }
    });
  }

  return {
    isOpen,
    shopList,
    keyword,
    statusSearch,
    pageSizeOptions,
    total,
    page,
    limit,
    toogle,
    setKeyword,
    handleSearchShop,
    handleSearchKeyPress,
    handleClearSearch,
    handleSelectShop,
    handleAddShopToPlaylist,
    handleShowSizeChange,
    handlePageChange,
    isPlatFormMode
  }
}

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default PlaylistShopCoupon;



