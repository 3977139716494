/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import io from 'socket.io-client'
import { convertTimeStampToFormant } from 'helper/timeUtil'
import { ReactComponent as IReply } from 'images/icons/icon-reply.svg'
import {
  getMessage,
  socket,
  sendMessage,
  uploadMedia,
} from 'provider/liveChatProvider'
import ModalPreview from './ModalPreview'
import ModalReply from './ModalReply'
import host from 'config/host'
import Cookies from 'js-cookie'

import 'styles/chat/chat.scss'

const pathImage = 'https://thumb.izcene.com/saranros/'
let messageConnection = null

const ListChatSummary = (props) => {
  const [dataSelectedReply, setDataSelectedReply] = useState(null)

  const [prevScrollHeight, setPrevScrollHeight] = useState(0)
  const [listChat, setListChat] = useState([])
  const [imagePreview, setImagePreview] = useState('')
  const [isShowModalPreview, setIsShowModalPreview] = useState(false)
  const [isShowModalReply, setIsShowModalReply] = useState(false)
  const [isLoadmore, setIsLoadmore] = useState(false)
  const [isSendMsg, setIsSendMsg] = useState(false)
  const [lastId, setLastId] = useState(null)
  const [newMsg, setNewMsg] = useState('')

  useEffect(() => {
    list({ params: { limit: 1000 }, isFirstFatch: true })
    connectSocket()
  }, [])

  useEffect(() => {
    /// handle listchat change

    if (listChat) {
      const lastTagMsg = document.getElementsByClassName('wd-he-toscroll')
      const scrollHeight = lastTagMsg[0]?.scrollHeight
      const isScrollToBottom = scrollHeight - lastTagMsg[0]?.scrollTop

      setPrevScrollHeight(scrollHeight)

      if (isScrollToBottom - lastTagMsg[0]?.clientHeight <= 400 || isSendMsg) {
        setIsSendMsg(false)
        lastTagMsg[0].scrollTop = scrollHeight
      } else if (
        isLoadmore &&
        prevScrollHeight &&
        scrollHeight > prevScrollHeight
      ) {
        setIsLoadmore(false)
        const prevPosScroll = scrollHeight - prevScrollHeight
        lastTagMsg[0].scrollTop = prevPosScroll
      }
    }
  }, [listChat])

  useEffect(() => {
    /// handle when message incoming
    if(newMsg){
      const newArray = [...listChat, newMsg].filter((msg) => msg.mockUp !== true)
      setListChat(newArray)
    }
  }, [newMsg])

  const connectSocket = () => {
    if (messageConnection) messageConnection.disconnect()

    const socket = io(host.socket)
    messageConnection = socket.on(`message`, (message) => {
      setNewMsg(message)
    })
  }

  const handleScrollChat = async (e) => {
    if (e.target.scrollTop === 0 && listChat) {
      setIsLoadmore(true)

      if (lastId === listChat[0]._id) setIsLoadmore(false)

      setLastId(listChat[0]._id)
      list({ params: { lastId: listChat[0]._id, limit: 30 } })
    }
  }

  const list = async ({ params, isFirstFatch }) => {
    const historyMsg = await getMessage(params)

    const tmpMsg =
      listChat && !isFirstFatch ? [...historyMsg, ...listChat] : historyMsg

    setListChat(tmpMsg)

    if (tmpMsg.length > 0) {
      let lastTagMsg = document.getElementsByClassName('wd-he-toscroll')

      if (isFirstFatch) lastTagMsg[0].scrollTop = lastTagMsg[0].scrollHeight
    }
  }

  const showModalPreview = (img) => {
    setIsShowModalPreview(true)
    setImagePreview(img)
  }

  const closeModalPreview = () => {
    setIsShowModalPreview(false)
    setImagePreview('')
  }

  const onReplyMsg = (msg) => {
    const { data, refId } = dataSelectedReply

    setIsSendMsg(true)

    const message = {
      msg,
      type: 'text',
      shopId: data.shopId,
      userId: data.userId,
      shop: { name: data.shopId },
      user: { name: data.userId },
      sender: JSON.parse(Cookies.get('user')).username,
      refId,
      replyMsg: true,
    }

    sendMessage({ message })
  }

  const onReplyImage = async (iamge) => {
    const { data, refId } = dataSelectedReply

    setIsSendMsg(true)

    const media = await uploadMedia(iamge)
    const urlImg = media.fileName

    const dataImage = {
      msg: urlImg,
      type: 'image',
      shopId: data.shopId,
      userId: data.userId,
      shop: { name: data.shopId },
      user: { name: data.userId },
      sender: JSON.parse(Cookies.get('user')).username,
      refId,
      replyMsg: true,
    }

    sendMessage({
      message: dataImage,
    })
  }

  const onClickMsgReply = (data) => {
    if (data) {
      window.open('/chat', `${data.roomId._id}`, `${data.roomId._id}`)
    }
  }

  const checkMsgType = (msgData, index, refId) => {
    if (msgData.replyMsg) {
      switch (msgData.type) {
        case 'text':
          const {roomId={}} = msgData;
          const { urlImg='', name='' } = roomId || {};

          return (
            <div
              key={index}
              className={`${
                msgData.refId && 'pointer'
              } div-msg-call-center-summary`}
              id={refId}
              onClick={() => onClickMsgReply(msgData)}
            >
              <div className='wrap-msg-call-center'>
                <div className='wrap-time-summary'>
                  <span className='txt-time'>
                    {msgData.createdAt
                      ? convertTimeStampToFormant(msgData.createdAt)
                      : null}
                  </span>
                  <span className='txt-name'>{msgData.sender || '...'}</span>
                </div>
                <div
                  className='box-msg-call-center-summary'
                  dangerouslySetInnerHTML={{
                    __html: msgData.msg.replace(/[\r\n]/g, '<br>'),
                  }}
                ></div>
              </div>
              <div className='img-call-center'>
                <img alt='' src={urlImg} />
              </div>
            </div>
          )

        case 'image':
          return (
            <div key={index} className='div-msg-call-center' id={refId}>
              <div className='wrap-time'>
                <span>
                  {msgData.createdAt
                    ? convertTimeStampToFormant(msgData.createdAt)
                    : null}
                </span>
                <span>{msgData.sender || '...'}</span>
              </div>

              <div
                className='img-size'
                onClick={
                  () => onClickMsgReply(msgData)
                  // showModalPreview(pathImage + '0x900/image/' + msgData.msg)
                }
              >
                <img alt='' src={pathImage + '250x0/image/' + msgData.msg} />
              </div>
            </div>
          )

        case 'video':
          return (
            <div width='320' height='240' controls id={refId}>
              video
            </div>
          )

        case 'audio':
          return <div>audio</div>

        case 'location':
          return (
            <div className='div-msg-call-center' key={index} id={refId}>
              location
            </div>
          )

        default:
          break
      }
    } else {
      const {roomId={}} = msgData;
      const { urlImg='', name='' } = roomId || {};
     
      switch (msgData.type) {
        case 'text':
          // const {
          //   roomId: { urlImg, name },
          // } = msgData

          return (
            <div className='div-msg-customer-summary' key={index} id={refId}>
              <div className='img-customer'>
                <img alt='' src={urlImg} />
              </div>
              <div className='msg-customer'>
                <div className='box-name-customer'>
                  <span className='txt-name'>{name}</span>
                  <span className='txt-time'>
                    {convertTimeStampToFormant(msgData.createdAt)}
                  </span>
                </div>
                <div className='box-msg-customer-summary'>
                  <div
                    className='content'
                    dangerouslySetInnerHTML={{
                      // eslint-disable-next-line no-useless-escape
                      __html: msgData.msg.replace(/[\r\n\,]/g, '<br>'),
                    }}
                  ></div>
                </div>
                <button
                  className='btn-reply'
                  onClick={() => {
                    setIsShowModalReply(true)
                    setDataSelectedReply({ data: msgData.roomId, refId })
                  }}
                >
                  <IReply className='icon' />
                  ตอบกลับ
                </button>
              </div>
            </div>
          )

        case 'image':
          return (
            <div className='div-msg-customer-summary' key={index} id={refId}>
              <div className='img-customer'>
                <img alt='' src={urlImg} />
              </div>
              <div className='msg-customer'>
                <div className='box-name-customer'>
                  <span className='txt-name'>{name}</span>
                  <span className='txt-time'>
                    {convertTimeStampToFormant(msgData.createdAt)}
                  </span>
                </div>
                {/* <div className='box-msg-customer-summary'> */}
                <div className='img-size'>
                  <img alt='' src={`${host.avatar}image/${msgData.msg}`} />
                </div>
                {/* </div> */}
                <button
                  className='btn-reply'
                  onClick={() => setIsShowModalReply(true)}
                >
                  <IReply className='icon' />
                  ตอบกลับ
                </button>
              </div>
            </div>
          )

        case 'sticker':
          return (
            <div className='div-msg-customer' key={index} id={refId}>
              sticker
            </div>
          )

        case 'video':
          return (
            <div className='div-msg-customer' key={index} id={refId}>
              video
            </div>
          )

        case 'audio':
          return (
            <div className='div-msg-customer' key={index} id={refId}>
              audio
            </div>
          )

        case 'location':
          return (
            <div className='div-msg-customer' key={index} id={refId}>
              location
            </div>
          )

        default:
          break
      }
    }
  }

  return (
    <div className='wrap-div-chat-summary'>
      {/* {props.isShowChat ? ( */}
      <div className='div-chat summary'>
        {/* {dataUserSelected ? ( */}
        <div onScroll={handleScrollChat}>
          <div className='line-haeder' />

          <div className='div-list-data chat-sum'>
            <div className='wd-he-toscroll'>
              {listChat?.map((data, index) => {
                return data.mockUp
                  ? checkMsgType(data, index)
                  : checkMsgType(data, index, data._id)
              })}
            </div>
          </div>
        </div>
        {/* ) : null} */}
      </div>
      {/* ) : (
        <div className='box-frist'>
          <img alt='' src={ArrowLrft} />
          <div className='box-text'>
            <label>เลือกลูกค้า </label>
            <label>เพื่อเริ่มการแชท</label>
          </div>
        </div>
      )} */}

      {/* ------------ */}
      {/* <ModalPreview
        showModalPreview={isShowModalPreview}
        closeModalPreview={closeModalPreview}
        imagePreview={imagePreview}
      /> */}

      <ModalReply
        dataUser={dataSelectedReply?.data}
        showModal={isShowModalReply}
        closeModal={() => setIsShowModalReply(false)}
        replyMsg={onReplyMsg}
        replyImage={onReplyImage}
      />
    </div>
  )
}

export default ListChatSummary
