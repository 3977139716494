/* eslint-disable eqeqeq */
import React from 'react'
import numeral from 'numeral'
import host from 'config/host'
import bgProduct from 'images/bg-product-card.png'
import 'styles/chat/productCard.scss'

const ProductCard = ({ product, hasCloseCard, invoice }) => {

  const checkLogisticStatus = (logistic, cancel) => {
    if (cancel) {
      return <span className='text-color-red'>ยกเลิก</span>
    }
    if (logistic && !cancel) {
      switch (logistic) {
        case 'wait':
          return <span className='text-color-black'>รอจัดส่ง</span>
        case 'posting':
        case 'intransit':
        case 'outdelivery':
          return <span className='text-color-black'>อยู่ระหว่างขนส่ง</span>
        case 'delivered':
          return <span className='text-color-primary'>สำเร็จ</span>
        case 'issues':
          return 'พบปัญหา'
        case 'redelivery':
          return 'จัดส่งใหม่'
        default: return ''
      }
    }
  }


  return (
    <div className='product-card-chat-box'>
      <div className='bg-image-product-chat'>
        <img alt='' className='bg-product' src={bgProduct} />
      </div>
      <div className='content-product-card-chat flex-row'>
        <div className='img-box-product-card-chat'>
          <img
            alt=''
            className='img-product-card'
            src={host.img + (product?.imagePath[0].path || (invoice?.items && invoice?.items[0]?.productSKU?.imagePath?.path))}
          />

        </div>

        <div className='product-price-item-chat'>
          <label className='product-name-chat'></label>
          <label className='product-name-chat'>{product ? product?.name : `หมายเลขออเดอร์ ${invoice?.orderNo}`}</label>
          {product?.cheapPriceList &&
            product?.cheapPriceList != product?.cheapPrice ? (
            <div className='flex-row'>
              <label className='product-price-old-chat'>฿&nbsp;</label>
              {product?.cheapPriceList != null &&
                product?.cheapPriceList != product?.expensivePriceList ? (
                <label className='product-price-old-chat '>
                  {numeral(product?.cheapPriceList).format('0,0')} -&nbsp;
                </label>
              ) : null}
              <label className='product-price-old-chat'>
                {numeral(product?.expensivePriceList).format('0,0')}
              </label>
            </div>
          ) : null}
          <div className='footer-card-chat'>
           {product ? 
           <div className='flex-row'>
           <label className='product-price-chat'>
             ฿ {numeral(product?.cheapPrice).format('0,0')}
           </label>
           {product?.expensivePrice ? (
             <label className='product-price-chat'>
               - {numeral(product?.expensivePrice).format('0,0')}
             </label>
           ) : null}
         </div> :  <label className='product-price-chat'> ฿ {numeral(invoice?.priceTotal).format('0,0')}</label>
          }
          </div>
          {/* <label className='product-name-chat'> สถานะ : {invoice ? checkLogisticStatus(invoice?.logisticStatus,invoice?.canceledAt) : null}</label> */}
          {invoice ? <label className='product-name-chat'>
            สถานะ :
            {invoice ? checkLogisticStatus(invoice?.logisticStatus, invoice?.canceledAt) : null}
          </label> :
            null}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
