/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { InputGroup, InputGroupAddon, Input } from 'reactstrap'
import io from 'socket.io-client'
import host from 'config/host'
import { getChatRooms, getListShop, searchChatRoom,  searchChatRoomAdmin,
  // socket, 
  updateRoom } from 'provider/liveChatProvider'
import { changeFormatDate, changeFormatTime } from 'helper/timeUtil'
import { getUserRole } from 'helper/utils'
import iconNoProfile from 'images/no-profile.png'
import {ReactComponent as IconNoImgProfile} from 'images/icons/no-profile.svg'
import 'styles/chat/listUserChat.scss'
import { SUPER_ADMIN } from 'layout/Sidebar/Role'
import { H6, SubTooltip, Tooltip } from 'components/FontStyle'

let limit = 10
let isSocketConnecting = null

const ListUser = (props) => {
  const [rooms, setRooms] = useState([])
  const [listSearch, setListSearch] = useState([])
  const [listID, setListID] = useState([])
  const [msgSearch, setMsgSearch] = useState('')
  const [newMsg, setNewMsg] = useState('')
  const role = getUserRole()

  useEffect(() => { /// initial Room
    fetchIDShop()

    if(role === SUPER_ADMIN) {
      getRoom({ limit })
    }
  }, [])

  useEffect(() => { /// initial Room
      for (let item of listID) {
        if (item.id) {
          role === 'OWNER' && !item.id && window.close()
          getRoom({ limit, shopId: item.id })
        }
      }
      connectSocket()
  }, [listID])

  useEffect(() => { /// handle when message incoming

    // const inGroup = rooms.some((room) => room._id === newMsg._id)
    // if(!inGroup) return
    if (newMsg) {
      const arr = rooms.filter((item) => item._id !== newMsg._id)
      const newArr = sortingArray([newMsg, ...arr], "updateAt")
      setRooms(newArr) ///  ต้อง set คู่กัน
      props.setDataUser(newArr) /// ต้อง set คู่กัน
    }

  }, [newMsg])

  useEffect(() => {
    if(rooms) {
      props.setDataUser(rooms)
    }
  },[rooms])

  useEffect(() => {
    if(listSearch) {
      setRooms(listSearch)
    }
  },[listSearch])

  const connectSocket = () => {
    const socket = io(host.socket)
    if (isSocketConnecting) isSocketConnecting.disconnect()

    if(role === 'OWNER'){
      for(let item of listID) {
        isSocketConnecting = socket.on(`room-shop${item.id}`, (roomData) => {
          setNewMsg(roomData)
        })
      }
    }else{
      isSocketConnecting = socket.on('room', (roomData) => {
        setNewMsg(roomData)
      })

    }

  }

  const sortingArray = (arr, field) => {
    const sortArr = arr.sort((a, b) => new Date(b[field]) - new Date(a[field]) )
    return sortArr
  }

  const getRoom = async (params) => {
    const res = await getChatRooms(params)
      setRooms((prevValue) =>
        [...prevValue, ...res].sort(
          (a, b) => new Date(b['updateAt']) - new Date(a['updateAt'])
        )
      )
  }

  const fetchIDShop = async () => {
    const { data } = await getListShop()
    if(data) {
      setListID(data)
    }
  }

  const onClickToChat = async (doc) => {
    props.setIdChat(doc._id)
    await updateRoom(doc._id, { read: true })
    // this.props.setPin(doc.pin)
    // this.props.setAnnounce(doc.announce)
    // this.props.setAnnounceMsg(doc.announceMsg)

  }

  const handleScrollRoom = (e) => {
    if (!msgSearch) {
      const isScrollToBottom =
        e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight
      if (isScrollToBottom) {
        let skip = rooms.length
        if (role === SUPER_ADMIN) {
          getRoom({ limit, skip })
        } else {
          for (let item of listID) {
            getRoom({ limit, shopId: item.id, skip })
          }
        }
      }
    }
  }

  const handleSearchChatRoom = async (e) => {
    let searchResult = []
    if (e.key === 'Enter') {
      let res

      if(!msgSearch){
        res = await getChatRooms({ limit })
        searchResult = [...searchResult, ...res]
        setListSearch(searchResult)
      } else {
        if(role === SUPER_ADMIN){
          res = await searchChatRoomAdmin(msgSearch)
          searchResult = [...searchResult, ...res]
          setListSearch(searchResult)
        } else {
          for(let item of listID) {
            res = await searchChatRoom(msgSearch, item.id)
            searchResult = [...searchResult, ...res]
            setListSearch(searchResult)
          }
        }
      }
    }
  }

  const handleClearSearch = () => {
    setMsgSearch('')
    setListSearch([])
    if(role === SUPER_ADMIN) {
      getRoom({ limit })
    } else {
      for(let item of listID) {
        getRoom({ limit, shopId: item.id })
      }
    }
  }

  return (
    <div className='wrap-customer'>
      <div className='haeder-user-chat'>
        <div className='haeder-cus'>
          <H6 className=''>Customer</H6>
        </div>
        <div className='haeder-search'>
          <InputGroup>
            <InputGroupAddon addonType='prepend'>
              <button onClick={handleSearchChatRoom} className='btn'>
                <i className='icon-search' />
              </button>
            </InputGroupAddon>
            <Input
              value={msgSearch}
              onChange={(e) => setMsgSearch(e.target.value)}
              placeholder='ค้นหาชื่อ'
              onKeyPress={handleSearchChatRoom}
            />
            <InputGroupAddon addonType='prepend'>
              {msgSearch.length > 0 ? (
                <button className='btn btn-close'>
                  <i className='icon-close' onClick={handleClearSearch} />
                </button>
              ) : null}
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>

      <div className='wrap-box-user' onScrollCapture={handleScrollRoom}>
        {rooms?.map((cust, index) => {
          return (
              cust.platform !== 'facebook' && 
              <div
              key={index}
              className={
                cust.read
                  ? props?.idSelected === cust._id
                    ? ' box-customer active-box'
                    : 'box-customer'
                  : props?.idSelected === cust._id
                  ? 'box-customer-noti active-box'
                  : 'box-customer-noti'
              }
              onClick={() => onClickToChat(cust)}
            >
              <div className='right-item'>
                <div className='box-img'>
                  {cust?.user?.profileImage ? 
                    <img alt='' src={cust?.user?.profileImage || iconNoProfile} /> : <IconNoImgProfile />
                }
                  {/* <img alt='' src={cust?.user?.profileImage || iconNoProfile} /> */}
                  <div
                    className={
                      cust.platform === 'line'
                        ? 'social line'
                        : cust.platform === 'facebook'
                        ? 'social facebook'
                        : 'social phone'
                    }
                  >
                    <i
                      className={
                        cust.platform === 'line'
                          ? 'icon-line'
                          : cust.platform === 'facebook'
                          ? 'icon-facebook'
                          : 'icon-phone'
                      }
                    />
                  </div>
                </div>
                <div className='detail'>
                  <label className='name'>{cust?.user?.displayName}</label>
                  {role !== 'OWNER' && <label className='name'>{cust?.shop?.name}</label>}
                  {/* <label>{cust.mobile || 'ยังไม่บันทึกเบอร์'}</label> */}
                  <label className='msg'>
                    {cust.lastText ? cust.lastText : ''}
                  </label>
                </div>
              </div>
              <div className='left-item'>
                <div className='left-item-bottom'>
                  <Tooltip className='text-time'>
                    {changeFormatDate(cust.updateAt)}
                  </Tooltip>
                  <Tooltip className='text-time'>
                    {changeFormatTime(cust.updateAt)}
                  </Tooltip>
                </div>
                <div className='left-item-top'>
                  {cust.pin ? (
                    <div className='follow-up'>
                      <i className='icon-pin' />
                      ปักหมุด
                    </div>
                  ) : null}
                  <div className={cust.read ? '' : 'noti-alert'} />
                </div>
              </div>
            </div>
          )
        })}
        <div className='cover-bar' />
      </div>
      <div></div>
    </div>
  )
}

export default ListUser
