import React, { useEffect, useState }  from "react";
import './style.scss';
import Spreadsheet from "react-spreadsheet";

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */
const SpreadSheet = props => {
    const {data} = useSpreadSheet(props);
    return <Spreadsheet data={data} />;
}

export default SpreadSheet;

/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const useSpreadSheet = (props) => {
    
    const [data, setData] = useState([]);

    useEffect(() => {

        if(props.data) {
            setData(props.data);
        }
    }, [props.data]);

    return {data}
}


