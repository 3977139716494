import { SET_KEY_ADD_LOGISTIC_PRICE, SET_PRICE_LOGISTIC_PRICE, SET_DEFAULT_LOGISTIC_PRICE, SET_MODAL_CONFIRM, SET_EDIT_SHOP_LOGISTIC, HANDLE_ADD_LOGISTIC_DELETED } from "redux/actions/logisticPrice";

const initialState = {
    listLogistics: null,
    name: '',
    price: [
        {
            start: 1,
            end: null,
            price: null,
            rateType: 'flat',
            status: 'active',
        }
    ],
    logisticSelected: '',
    modalConfirm: {
        isOpen: false,
        btnCancel: 'สร้างรายการใหม่เพิ่ม',
        btnConfirm: 'เสร็จสิ้น'
    },
    shopLogistic: null,
    removeRateLogisiticPrice: [],
    filterSelected: null,
    total: null,
    limit: 10,
    page: 1,
    pageSizeOptions: [10,20,40],
    calculateBy: "quantity"
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case SET_KEY_ADD_LOGISTIC_PRICE: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }

        case SET_PRICE_LOGISTIC_PRICE: {
            let price = state.price
            price[payload.idx] = {
                ...price[payload.idx],
                [payload.name]: payload.name === "end" || payload.name === "price" ? parseFloat(payload.value) : payload.value
            }
            return {
                ...state,
                price: price
            }
        }

        case SET_DEFAULT_LOGISTIC_PRICE: {
            return {
                // ...initialState,
                ...state,
                name: '',
                price: [ {
                    start: 1,
                    end: null,
                    price: null,
                    rateType: 'flat',
                    status: 'active',
                }],
                logisticSelected: state.listLogistics?.find((item) => item.code === "DHL"),
                modalConfirm: {
                    isOpen: false,
                    btnCancel: 'สร้างรายการใหม่เพิ่ม',
                    btnConfirm: 'เสร็จสิ้น'
                },
            }
        }

        case SET_MODAL_CONFIRM: {
            return {
                ...state,
                modalConfirm: {
                    isOpen: payload.isOpen,
                    btnCancel: payload.btnCancel,
                    btnConfirm: payload.btnConfirm
                }
            }
        }

        case SET_EDIT_SHOP_LOGISTIC: {
            return {
                ...state,
                name: payload.name,
                price: payload.shopLogisticRates,
                calculateBy: payload?.calculateBy,

                /* snap data for update data */
                snapData:{ calculateBy:payload?.calculateBy, price: payload.shopLogisticRates },
                
                logisticSelected: payload.logistic && payload.logistic[0],
                modalConfirm: {
                    isOpen: false,
                    btnCancel: 'สร้างรายการใหม่เพิ่ม',
                    btnConfirm: 'เสร็จสิ้น'
                },
            }
        }

        case HANDLE_ADD_LOGISTIC_DELETED : {
            return {
                ...state,
                removeRateLogisiticPrice: [
                    ...state.removeRateLogisiticPrice,
                    payload
                ]
            }
        }

        default:
            return state
            // eslint-disable-next-line no-unreachable
            break;
    }
}