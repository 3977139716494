import React from 'react';
import './styles.scss';
import { Dropdown, Space, Menu } from 'antd';


const DropdownText = ({ handleClick, label, data }) => {
    const onClick = ({key}) => {
        handleClick(key)
    };

    const menu = (
        <Menu onClick={onClick}>
            {data.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
        </Menu>
    );


    return (
        <Dropdown overlay={menu}>
            <a onClick={e => e.preventDefault()}>
                <Space>
                    {label}
                </Space>
            </a>
        </Dropdown>
    )
}

export default DropdownText;