import promocodeProvider from "provider/promocodeProvider";
import shopProvider from '../../provider/shopProvider';
import { call, put, select, takeLatest } from "redux-saga/effects";
import { SET_LOADING } from "redux/actions/app";
import {
    OPEN_MODAL_MESSAGE_ERROR,
} from "redux/actions/modalMessage";
import {
    CREATE_PROMOCODE,
    GET_LIST_PROMOCODE,
    OPEN_MODAL_ADD_PROMOCODE,
    OPEN_MODAL_LIST_SKU,
    OPEN_MODAL_SELECTED_SHOP,
    OPEN_MODAL_MESSAGE_PROMOCODE,
    SET_KEY_VALUE_PROMOCODE,
    VERIFY_REF_CODE,
    HANDLE_UPDATE_PROMOCODE,
    HANDLE_DELETE_PROMOCODE,
    OPEN_MODAL_CONFIRM_PROMOCODE,
    GET_DETAIL_UPDATE_PROMOCODE,
    CREATE_COUPON, UPDATE_COUPON,
    GET_COUPON_DETAIL,
    GET_COUPON_ALL_DETAIL,
    GET_CLIENT_LEVEL,
    SET_SHOP_METAS,
    ADD_PRODUCT_PROMOCODE,
    REMOVE_PRODUCT_PROMOCODE,
    GET_PRODUCT_PROMOCODE,
    ADD_ITEMJOIN_PROMOCODE,
    SELECTED_PRODUCT_PROMOCODE,
    SELECTED_ITEMJOIN_PROMOCODECODE,
    OPEN_MODAL_SELECTED_PRODUCT,
    SELECT_SHOP_PRODUCT,
    SUBMIT_PRODUCT_PROMOCODE,
    SELECTED_SHOPJOIN_PRODUCT,
    UPDATE_TYPE_PRODUCT_SELECTION_PROMOCODE,
    UPDATE_COUNT_CHECKED_ALL_SHOP,
    SELECTED_PRODUCT_IN_SHOP,
} from "redux/actions/promocode";

import { ADD_KEY_VALUE_SELECTEDED_PRODUCT, ADD_SKU_PROMOCODE } from "redux/actions/selectedProduct"
import { ADD_KEY_VALUE_CHECKED_PRODUCT } from "redux/actions/checkedProduct"


import {
    getPromocode,
    getSelectedProduct,
    getCheckedProduct,
    getSelectedShop,
    getCheckedShop,
    getListCheckedShop,
    getListSelectedShop,
    getJoinSelectedShop,
} from "./selectors";
import { ADD_KEY_VALUE_SHOP, } from '../actions/shop'
import { putWait, withCallback } from 'redux-saga-callback';
// import Cookies from 'js-cookie';
import moment from 'moment'
import { OPEN_MODAL_ALERT } from "redux/actions/modalAlert";
import productProviders from "provider/productProvider";
import { OPEN_TOAST } from "redux/actions/notification";
import { ADD_KEY_VALUE_PRODUCT } from 'redux/actions/product'

import { ADD_PRODUCT_SELECTED_SHOP, UPDATE_COUNT_SELECTED_SHOP } from 'redux/actions/selectedShop'
import { ADD_KEY_VALUE_CHECKED_SHOP, RESET_CHECKED_SHOP, COUNT_CHECKED_ALL_SHOP, SET_KEY_ALLPRODUCT_SHOP } from 'redux/actions/checkedShop'
import { ADD_LIST_SELECTED_SHOP, SET_KEY_LIST_SELECTED_SHOP } from 'redux/actions/listSelectedShop'
import { SET_KEY_LIST_CHECKED_SHOP } from 'redux/actions/listCheckedShop'


import { convert2obj, convert2array, convert2objPromocode, convert2arrPromocode } from "helper/utils"
import { ADD_KEY_VALUE_SELECTEDED_SHOP } from "redux/actions/selectedShop";
import { ADD_KEY_VALUE_SELECTEDED_JOIN_SHOP, ADD_PRODUCT_SELECTED_JOIN_SHOP } from "redux/actions/joinSelectedShop";

import { isEmpty, merge } from "lodash";

const api_shop = new shopProvider()

const productProvider = new productProviders()

export function* updateCheckAllShop(action) {
    const { shopId } = action.payload
    // const shopId = ` ${id}`
    const selectedShop = yield select(getSelectedShop)
    const checkedShop = yield select(getCheckedShop)
    try {
        yield put({ type: COUNT_CHECKED_ALL_SHOP, payload: { shopId } })

        console.log(checkedShop.shops)
        const result = Object.keys(checkedShop.shops).reduce(function (accumulator, shopId) {
            if (selectedShop.shops[shopId] == undefined) {
                if (checkedShop.shops[shopId]?.allProduct == true) {
                    accumulator.countAllShop += 1;
                } else {
                    if (Object.keys(checkedShop.shops[shopId].product).length > 0) {
                        accumulator.countAllShop += 1;
                    }
                }
            }
            accumulator.countAllSKU += checkedShop.shops[shopId]?.sku
            accumulator.countAllProduct += checkedShop.shops[shopId]?.count;
            // if (selectedShop.shops[shopId] && Object.keys(checkedShop.shops[shopId]?.product || {}).length > 0) {
            //     // const productCount = Object.keys(checkedShop.shops[shopId].product).length;
            //     const skuCount = Object.keys(checkedShop.shops[shopId].product).reduce(
            //         (total, productId) => {
            //             return total + Object.keys(checkedShop.shops[shopId].product[productId]).length;
            //         },
            //         0
            //     );
            //     accumulator.countAllSKU += skuCount;
            //     accumulator.countAllProduct += productCount;
            // }
            // else {
            //     if (Object.keys(checkedShop.shops[shopId]?.product || {}).length > 0) {
            //         accumulator.countAllShop += 1;
            //     }
            //     const productCount = Object.keys(checkedShop.shops[shopId].product).length;
            //     const skuCount = Object.keys(checkedShop.shops[shopId].product).reduce(
            //         (total, productId) => {
            //             return total + Object.keys(checkedShop.shops[shopId].product[productId]).length;
            //         },
            //         0
            //     );
            //     accumulator.countAllSKU += skuCount;
            //     accumulator.countAllProduct += productCount;
            // }
            return accumulator;
        }, checkedShop, checkedShop.countAllShop = 0, checkedShop.countAllSKU = 0, checkedShop.countAllProduct = 0);
        yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'countAllProduct', value: result.countAllProduct } })
        yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'countAllSKU', value: result.countAllSKU } })
        yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'countAllShop', value: result.countAllShop } })
    } catch (error) {
        console.log('error', error)
    }



}


export function* onGetListPromocode(action) {
    const { payload } = action
    try {
        // const shopId = JSON.parse(Cookies.get('user')).shopId;

        yield put({ type: SET_LOADING, payload: { loading: true } })
        const getPromocodeState = yield select(getPromocode)
        let param = {
            filter: { kw: getPromocodeState.kw },
            status: getPromocodeState.activeTab,
            page: getPromocodeState.page,
            limit: getPromocodeState.limit,
        }
        if (payload?.shopId !== undefined && payload.shopId?.toString().trim().length !== 0)
            param.shopId = payload.shopId

        if (payload?.type)
            param.type = payload.type


        const res = yield call(promocodeProvider.getListPromocode, param)
        if (res) {
            yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'promocode', value: res.data } })
            yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'total', value: res.data.count } })

        }



        // yield put({ type: GET_PRODUCT_PROMOCODE, payload: 754 })

        // const SearchProducts = yield call(productProvider.getSearchProducts, { limit: 10, page: 1 })
        // console.log(SearchProducts)
        // yield put({ type: REMOVE_PRODUCT_PROMOCODE, payload: 675 })

        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }

}

export function* handleVerifyRefCode(action) {
    const { payload } = action
    // const shopId = JSON.parse(Cookies.get('user')).shopId || payload.shopId;
    const shopId = payload.shopId;
    let param = { code: payload.promotionCode }
    if (shopId) param.shopId = shopId;

    try {
        const res = yield call(promocodeProvider.verifyRefCode, param)
        // eslint-disable-next-line no-unused-vars
        let getPromocodeState = yield select(getPromocode)
        yield put({ type: GET_CLIENT_LEVEL })
        if (res.data.id) {
            // if (getPromocodeState.managePromocode === "create") {
            yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'verifyCode', value: res.data } })
            yield put({ type: OPEN_MODAL_ADD_PROMOCODE, payload: { isOpen: true, data: res.data } })
            // }
            getPromocodeState = yield select(getPromocode)

        } else if (res.status === 422 || res.status === 500) {
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'ตรวจสอบรหัสอ้างอิงเรียบร้อยแล้ว  ไม่สามารถใช้งานรหัสอ้างอิงนี้ได้' } })
        }
    } catch (error) {
        console.log('error', error)
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }
}
export function* handleCreateCoupon(action) {
    let { payload } = action;
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const res = yield call(promocodeProvider.addPromocode, payload);
        if (res.status === 422 || res.status === 500) {
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'ตรวจสอบรหัสอ้างอิงเรียบร้อยแล้ว ไม่สามารถใช้งานรหัสอ้างอิงนี้ได้', title: 'iconFail', status: res.status } })
        } else {
            yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการสำเร็จ', type: 'success' } })
            payload.handleSuccessComfirmModal();
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
        if (error.response) {
            const { message } = error.response.data;
            if (message === 'PRODUCT_CODE_ALREADY_EXISTS') {
                yield put({
                    type: OPEN_MODAL_MESSAGE_ERROR,
                    payload: {
                        message: 'เลขหมายกำกับรหัสโค้ดนี้ถูกใช้งานไปแล้ว',
                        title: 'iconFail',
                        code: 'PRODUCT_CODE_ALREADY_EXISTS'
                    }
                })
            }else if(message === 'PRODUCT_ALREADY_EXISTS') {
                yield put({
                    type: OPEN_MODAL_MESSAGE_ERROR,
                    payload: {
                        message: 'รหัสโค้ดสำหรับนำไปใช้งานนี้ถูกใช้งานแล้ว',
                        title: 'iconFail',
                        code: 'PRODUCT_ALREADY_EXISTS'
                    }
                })
            } else {
                yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
            }
        } else {
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
        }
    }
}
export function* handleUpdateCoupon(action) {
    const { payload } = action;
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const res = yield call(promocodeProvider.updatePromocode, { body: payload.data, id: payload.id })
        if (res.status === 422 || res.status === 500) {
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'ตรวจสอบรหัสอ้างอิงเรียบร้อยแล้ว ไม่สามารถใช้งานรหัสอ้างอิงนี้ได้', title: 'iconFail', } })
        } else {
            payload.handleSuccessComfirmModal();
            yield put({ type: OPEN_TOAST, payload: { message: 'ทำรายการสำเร็จ', type: 'success' } })
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }

}

export function* handleCreatePromocode(action) {
    const { payload } = action
    try {
        const getPromocodeState = yield select(getPromocode)
        // const getShopState = yield select(getShop)
        const { verifyCode } = getPromocodeState
        let data = {
            campaignId: verifyCode.id,
            // shopId: getShopState.currentShop.id,
            name: verifyCode.name,
            mediaCover: `image/${verifyCode?.mediaCover?.path}`,
            description: verifyCode.description,
            stopDate: moment(payload.coupon.dateEnd + ' ' + moment(payload.coupon.timeEnd, "HH:mm").format("HH:mm"), "DD/MM/YYYY HH:mm"),
            startDate: moment(payload.coupon.dateStart + ' ' + moment(payload.coupon.timeStart, "HH:mm").format("HH:mm"), "DD/MM/YYYY HH:mm"),
            couponType: payload.coupon.pattern,
            couponRole: payload.coupon.License,
            couponAction: payload.coupon.type,
            refCode1: verifyCode.productCode,
            costSharingPlatform: payload.coupon.companySharing,
            costSharingPartner: payload.coupon.shopSharing,
            status: payload.coupon.usageStatus ? 'show' : 'hide',
            limitUser: {
                value: parseInt(payload.coupon.promotionCodePerUserOption.promotionCodeValue),
                unit: payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อวัน" ? 'day' :
                    payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อสัปดาห์" ? 'week' :
                        payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อเดือน" ? 'month' :
                            payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อโปรโมชั่น" ? 'promotion' : null
            }
        }

        payload.coupon?.promotionCodePerUser === "unlimit" && delete data.limitUser


        const res = yield call(promocodeProvider.addPromocode, data)
        if (res.status === 422 || res.status === 500) {
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'ตรวจสอบรหัสอ้างอิงเรียบร้อยแล้ว ไม่สามารถใช้งานรหัสอ้างอิงนี้ได้', title: 'iconFail', } })
        } else {
            yield put({ type: OPEN_MODAL_MESSAGE_PROMOCODE, payload: { isOpen: true, message: 'ทำรายการสำเร็จ', title: 'iconSuccess' } })
        }

    } catch (error) {
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }
}

export function* getDetailUpdatePromocode(action) {
    const { payload } = action
    yield put({ type: SET_LOADING, payload: { loading: true } })
    // const { shopId } = payload;

    try {
        yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'managePromocode', value: 'edit' } })
        const getDetail = yield call(promocodeProvider.getDetailCoupon, payload)
        yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'couponDetail', value: getDetail.data } })

        if (getDetail.data?.coupon?.skuJoin) {
            yield put({ type: ADD_ITEMJOIN_PROMOCODE, payload: getDetail.data.coupon.skuJoin })
        }

        // if (getDetail.data?.coupon?.promotionId) {
        //     yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'promotionId', value: getDetail.data.coupon.promotionId } })
        // }
        if (getDetail.data?.coupon?.promotionId) {
            yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'promotionId', value: getDetail.data.coupon.promotionId } })
            yield put({ type: GET_PRODUCT_PROMOCODE, payload: getDetail.data.coupon.promotionId })

        }
        if (getDetail?.data?.coupon?.shopId) {
            const shopDetail = yield call(api_shop.getShopDetail, { shopId: getDetail?.data?.coupon?.shopId });
            yield put({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopSelected', value: shopDetail } });
        }



        // const refCode = { code: getDetail.data.productCode };
        // const param = shopId !== 0 ? { ...refCode, shopId } : refCode;

        // const verifyCode = yield call(promocodeProvider.verifyRefCode, param)
        // yield put({ type: GET_CLIENT_LEVEL })
        // if (verifyCode.status === 422 || verifyCode.status === 500) {
        //     yield put({ type: OPEN_MODAL_MESSAGE_PROMOCODE, payload: { isOpen: true, message: 'ตรวจสอบรหัสอ้างอิงเรียบร้อยแล้ว ไม่สามารถใช้งานรหัสอ้างอิงนี้ได้', title: 'iconFail' } })
        //     // yield put({type: OPEN_MODAL_MESSAGE_ERROR})
        // } else {
        //     yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'verifyCode', value: getDetail.data } })
        //     yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'couponDetail', value: getDetail.data } })
        //     yield put({ type: OPEN_MODAL_ADD_PROMOCODE, payload: { isOpen: true, data: verifyCode.data } })
        // }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
        console.log('error', error)
    }
}

export function* handleUpdatePromocode(action) {
    try {
        const { payload } = action
        const getPromocodeState = yield select(getPromocode)
        // const getShopState = yield select(getShop)
        const { verifyCode } = getPromocodeState

        let data = {
            // shopId: getShopState.currentShop.id,
            name: verifyCode.name,
            mediaCover: `image/${verifyCode?.mediaCover?.path}`,
            description: verifyCode.description,
            stopDate: moment(payload.coupon.dateEnd + ' ' + moment(payload.coupon.timeEnd, "HH:mm").format("HH:mm"), "DD/MM/YYYY HH:mm"),
            startDate: moment(payload.coupon.dateStart + ' ' + moment(payload.coupon.timeStart, "HH:mm").format("HH:mm"), "DD/MM/YYYY HH:mm"),
            couponType: payload.coupon.pattern,
            couponRole: payload.coupon.License,
            couponAction: payload.coupon.type,
            costSharingPlatform: payload.coupon.companySharing,
            costSharingPartner: payload.coupon.shopSharing,
            status: payload.coupon.usageStatus ? 'show' : 'hide',
            limitUser: {
                value: parseInt(payload.coupon.promotionCodePerUserOption.promotionCodeValue),
                unit: payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อวัน" ? 'day' :
                    payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อสัปดาห์" ? 'week' :
                        payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อเดือน" ? 'month' :
                            payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อโปรโมชั่น" ? 'promotion' : null
            },
        }
        if (payload.coupon?.promotionCodePerUser === "unlimit") {
            data.limitUser = null
        }
        const res = yield call(promocodeProvider.updatePromocode, { body: data, id: payload.id })
        if (res.data) {
            yield put({ type: OPEN_MODAL_MESSAGE_PROMOCODE, payload: { isOpen: true, message: 'ทำรายการสำเร็จ', title: 'iconSuccess' } })
        }
    } catch (error) {
        console.log('error', error)
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }

}



export function* handleDeletePromocode(action) {
    const { payload } = action
    const res = yield call(promocodeProvider.deletePromocode, payload)
    if (res.data.status === "success") {
        yield put({ type: OPEN_MODAL_CONFIRM_PROMOCODE, payload: { isOpen: false } })
        yield onGetListPromocode({})
    }
}

export function* handleGetCouponDetail(action) {
    const { payload } = action
    try {
        const getDetail = yield call(promocodeProvider.getDetailCoupon, payload)
        yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'couponDetail', value: getDetail.data } })
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}

export function* handleGetCouponAllDetail(action) {
    try {
        const { payload } = action;
        yield put({ type: SET_LOADING, payload: { loading: true } })
        yield putWait({ type: VERIFY_REF_CODE, payload: { promotionCode: payload.promotionCode } })
        yield put({ type: GET_COUPON_DETAIL, payload: { id: payload.couponId } })
        yield put({ type: GET_CLIENT_LEVEL })
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}

export function* handleGetClientLevel(action) {
    try {
        const { payload } = action
        const clientLevel = yield call(promocodeProvider.getClientLevel, payload)
        yield put({
            type: SET_KEY_VALUE_PROMOCODE,
            payload: {
                key: 'clientLevel',
                value: clientLevel.data
            }
        })
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}

export function* handleSetShopMetas(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const { payload } = action;
        const data = yield call(promocodeProvider.setShopMetas, payload)
        if (data.data.code === 422) {
            yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'setSortListPromocode', value: 'failed' } })
            yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'เกิดข้อผิดพลาด', type: 'alert' } })
        } else {
            yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'setSortListPromocode', value: 'success' } })
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'setSortListPromocode', value: 'failed' } })
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'เกิดข้อผิดพลาด', type: 'alert' } })
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}

export function* onGetProductPromocode(action) {
    try {
        const getPromocodeState = yield select(getPromocode)
        const { payload } = action;
        const isPlatform = window.location.pathname.includes('platform');
        yield put({ type: SET_LOADING, payload: { loading: true } })
        if (!isPlatform) {
            const products = yield call(productProvider.getProductsPromocode, { promotionId: payload })

            yield put({ type: ADD_PRODUCT_PROMOCODE, payload: products.product })
            const data = convert2obj(getPromocodeState.itemJoin)
            yield put({ type: ADD_SKU_PROMOCODE, payload: data })
            const skus = convert2array(data)
            yield put({ type: ADD_ITEMJOIN_PROMOCODE, payload: { skus: skus } })
            yield put({ type: UPDATE_COUNT_SELECTED_SHOP })

        } else {
            const res = yield call(productProvider.getShopsProductsPromocode, { promotionId: payload })
            const data = convert2objPromocode(res)
            const { shops } = data
            yield put({ type: ADD_PRODUCT_SELECTED_SHOP, payload: shops })
            const dataArr = convert2arrPromocode(data)
            yield put({ type: ADD_ITEMJOIN_PROMOCODE, payload: dataArr })

            const getSelectedShopState = yield select(getSelectedShop)
            // const tmp = new Object(getSelectedShopState)
            yield put({ type: ADD_PRODUCT_SELECTED_JOIN_SHOP, payload: shops })
            // localStorage.setItem("itemjoin", JSON.stringify(getSelectedShopState));

        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (err) {
        console.log(err);
    }
}


export function* handleOpenModalSelectedProduct() {
    yield put({ type: ADD_KEY_VALUE_SELECTEDED_PRODUCT, payload: { key: 'product', value: {} } })
    yield put({ type: ADD_KEY_VALUE_SELECTEDED_PRODUCT, payload: { key: 'sku', value: 0 } })
    yield put({ type: ADD_KEY_VALUE_SELECTEDED_PRODUCT, payload: { key: 'count', value: 0 } })
    const getPromocodeState = yield select(getPromocode)
    const data = convert2obj(getPromocodeState.itemJoin.skus)

    yield put({ type: ADD_SKU_PROMOCODE, payload: data })
}

export function* handleOpenModalListSku(action) { //shopId
    try {

        const getPromocodeState = yield select(getPromocode)
        const { payload } = action;
        yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: "isShowModalListSkuSelectedShop", value: true } })
        yield put({ type: SET_LOADING, payload: { loading: true } })
        /*** set data SelectedShop ****/
        // const products = yield call(productProvider.getProductsShopsPromocode, { promotionId: getPromocodeState.promotionId, shopId:  parseInt(payload) })
        // const data = convert2objPromocode(products)
        // yield put({ type: ADD_PRODUCT_SELECTED_SHOP, payload: data.shops })
        /*** set data SelectedShop ****/


        const getSelectedShopState = yield select(getSelectedShop)
        console.log(getSelectedShopState)
        const shop = getSelectedShopState.shops[payload]
        if (shop?.allProduct) {
            const param = {
                shopId: payload,
                sortBy: 'name'
            }
            const allProducts = yield call(productProvider.getSearchProducts, param);
            yield put({ type: SET_KEY_LIST_CHECKED_SHOP, payload: { key: 'listCheckedShopOld', value: { shops: { [payload]: allProducts.product } } } })
            yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'countAllProduct', value: allProducts.count } })
            yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'countAllSKU', value: allProducts.countSku } })

            const getSelectedShopState = yield select(getSelectedShop)
            const shop = getSelectedShopState.shops[payload]
            const temp = { [payload]: shop }
            yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'shops', value: temp } })

        } else {
            yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'shops', value: {} } })
            const getListSelectedShopState = yield select(getListSelectedShop)
            const shop = getSelectedShopState.shops[payload]
            const temp = { [payload]: JSON.parse(JSON.stringify(shop)) }
            yield put({ type: SET_KEY_LIST_CHECKED_SHOP, payload: { key: 'listCheckedShop', value: getListSelectedShopState.listSelectedShop } })
            const products = yield call(productProvider.getProductsShopsPromocode, { promotionId: getPromocodeState.promotionId, shopId: parseInt(payload) })
            const tmp_shops = { ...getListSelectedShopState?.listSelectedShop?.shops[payload], ...products?.product }
            yield put({ type: SET_KEY_LIST_CHECKED_SHOP, payload: { key: 'listCheckedShopOld', value: { shops: { [payload]: tmp_shops } } } })

            yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'shops', value: temp } })
        }
    } catch (error) {
        console.log(error)
    } finally {
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}


export function* handleOpenModalSelectedShop(action) { //shopId
    try {

        yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: "isShowModalListShopSelectedShop", value: true } })
        yield put({ type: SET_LOADING, payload: { loading: true } })


        const joinSelectedShop = yield select(getJoinSelectedShop)
        // console.log(joinSelectedShop)
        // yield put({ type: ADD_KEY_VALUE_SELECTEDED_SHOP, payload: { key: null, value: joinSelectedShop } })

        // const tmp = localStorage.getItem("itemjoin");
        yield put({ type: ADD_KEY_VALUE_SELECTEDED_SHOP, payload: { key: null, value: JSON.parse(JSON.stringify(joinSelectedShop)) } })

    } catch (error) {
        console.log(error)
    } finally {
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}

export function* handleselectItemJoinPromocode() {
    const getSelectedProductState = yield select(getSelectedProduct)
    const skus = convert2array(getSelectedProductState.product)
    yield put({ type: ADD_ITEMJOIN_PROMOCODE, payload: { skus: skus } })

}

export function* handleSubmitItemJionShop() {
    const getSelectedShopState = yield select(getSelectedShop)
    console.log(getSelectedShopState)
    const data = convert2arrPromocode(getSelectedShopState)
    console.log(data)
    yield put({ type: ADD_ITEMJOIN_PROMOCODE, payload: data })
    yield put({ type: ADD_KEY_VALUE_SELECTEDED_JOIN_SHOP, payload: { key: null, value: { ...getSelectedShopState } } })
    // localStorage.setItem("itemjoin", JSON.stringify(getSelectedShopState));

}


export function* handleselectProductPromocode() {
    const getCheckedProductState = yield select(getCheckedProduct)

    const getPromocodeState = yield select(getPromocode)
    yield put({ type: ADD_SKU_PROMOCODE, payload: getCheckedProductState.product })
    yield put({ type: ADD_KEY_VALUE_CHECKED_PRODUCT, payload: { key: 'product', value: {} } })
    yield put({ type: ADD_KEY_VALUE_CHECKED_PRODUCT, payload: { key: 'sku', value: 0 } })
    yield put({ type: ADD_KEY_VALUE_CHECKED_PRODUCT, payload: { key: 'count', value: 0 } })

    yield put({ type: ADD_PRODUCT_PROMOCODE, payload: getPromocodeState.listCheckedProduct })
    yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'listCheckedProduct', value: {} } })
}


export function* handleSelectShopProduct(action) {
    const { shopId = null } = action?.payload

    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })

        yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'shopSelected', value: shopId } })

        const param = {
            shopId: shopId,
            page: 1,
            limit: 30,
            sortBy: 'name'
        }

        const res = yield call(productProvider.getSearchProducts, param);

        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'product', value: res?.product } })
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: res?.count } })
        yield put({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } })

        yield put({ type: SET_LOADING, payload: { loading: false } })

    } catch (err) {
        console.log('err', err);
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }

}

export function* handleSubmitProduct() {
    const checkedShopState = yield select(getCheckedShop)
    const selectedShopState = yield select(getSelectedShop)
    const listCheckedShopState = yield select(getListCheckedShop)
    const listSelectedShopState = yield select(getListSelectedShop)

    const { listCheckedShop = {} } = listCheckedShopState
    const { listSelectedShop = {} } = listSelectedShopState

    let newSelectedShop = selectedShopState
    let newShop = checkedShopState
    for (const [shopKey, shopValue] of Object.entries(checkedShopState?.shops)) {
        if (shopValue?.allProduct) {
            if (newSelectedShop.shops[shopKey]) {
                newSelectedShop.shops[shopKey].product = {}

                if(newSelectedShop.shops[shopKey]?.allProduct) {
                    if(newShop.shops[shopKey].count == 0 && newSelectedShop.shops[shopKey].count != 0) {
                        newShop.shops[shopKey].count = newSelectedShop.shops[shopKey].count
                        newShop.shops[shopKey].sku = newSelectedShop?.shops[shopKey]?.sku || 0
                    }
                }
            }

        } else if (!shopValue?.allProduct && isEmpty(shopValue?.product)) {
            delete newShop.shops[shopKey]
        }else if (shopValue?.allProduct ==false) {   
            if (newSelectedShop.shops[shopKey] != undefined) {
                if (newSelectedShop.shops[shopKey].allProduct == true) {
                    newSelectedShop.shops[shopKey].count = 0
                    newSelectedShop.shops[shopKey].sku = 0
                }
            }
        }
    }

    const mergeData = merge(listCheckedShop, listSelectedShop)

    // update selected shop
    yield put({ type: ADD_PRODUCT_SELECTED_SHOP, payload: newShop?.shops })

    // update list selected shop
    yield put({
        type: SET_KEY_LIST_SELECTED_SHOP, payload: {
            key: 'listSelectedShop',
            value: mergeData
        }
    })

    // clear data
    yield put({
        type: SET_KEY_LIST_CHECKED_SHOP, payload: {
            key: 'listCheckedShop',
            value: {
                shops: {}
            }
        }
    })
    yield put({ type: RESET_CHECKED_SHOP })

    // update count selected shop
    yield put({ type: UPDATE_COUNT_SELECTED_SHOP })


    yield put({
        type: SET_KEY_VALUE_PROMOCODE, payload: {
            key: 'isShowModalListShopProductPromocode',
            value: false
        }
    })

}

/* payload: {shopId(string): ' 123' } */
export function* handleSelectedProductInShop(action) {
    try {
        const { shopId } = action?.payload || {};

        const getCheckedShopState = yield select(getCheckedShop);
        const { shops: checkedShopState } = getCheckedShopState;

        const getSelectedShopState = yield select(getSelectedShop);
        const { shops: selectedShopState } = getSelectedShopState;

        selectedShopState[shopId].product = checkedShopState[shopId]?.product;
        selectedShopState[shopId].sku = checkedShopState[shopId]?.sku;
        selectedShopState[shopId].count = checkedShopState[shopId]?.count;


        yield put({ type: ADD_KEY_VALUE_SELECTEDED_SHOP, payload: { key: "shops", value: selectedShopState } });
        yield put({ type: UPDATE_COUNT_SELECTED_SHOP });
        yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: "isShowModalListSkuSelectedShop", value: false } });

        yield put({ type: SET_KEY_LIST_CHECKED_SHOP, payload: { key: "listCheckedShopOld", value: { shop: {} } } });
        yield put({ type: SET_KEY_LIST_CHECKED_SHOP, payload: { key: "listCheckedShop", value: { shop: {} } } });
        yield put({ type: RESET_CHECKED_SHOP })

    } catch (error) {
        console.log('error on submit modal product :>> ', error);
    }
}

export function* handleUpdateTypeItemJoinShop(action) {
    try {
        const { shopId: id, isAllProduct = false } = action?.payload
        const shopId = ` ${parseInt(id)}`
        const checkedShopState = yield select(getCheckedShop)
        if (isAllProduct) {
            const param = {
                shopId: parseInt(shopId),
                sortBy: 'name',
                scope: 'count'
            }
            const allProducts = yield call(productProvider.getSearchProducts, param);
            const getSelectedShopState = yield select(getSelectedShop)
            if (checkedShopState.shops[shopId] == undefined && getSelectedShopState.shops[shopId] == undefined) {
                yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'countAllShop', value: checkedShopState.countAllShop + 1 } })

            }
            yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'countAllProduct', value: (checkedShopState?.countAllProduct - checkedShopState?.shops[shopId]?.count || 0) + allProducts?.count || 0 } })
            yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'countAllSKU', value: (checkedShopState?.countAllSKU - checkedShopState?.shops[shopId]?.sku || 0) + allProducts?.countSku || 0 } })
            console.log(checkedShopState.shops[shopId])
            checkedShopState.shops[shopId].count = allProducts?.count || 0
            checkedShopState.shops[shopId].sku = allProducts?.countSku || 0


            yield put({ type: SET_KEY_ALLPRODUCT_SHOP, payload: { [shopId]: { allProduct: isAllProduct } } })
            yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'shops', value: checkedShopState.shops } })
            yield put({ type: UPDATE_COUNT_CHECKED_ALL_SHOP, payload: { shopId: parseInt(shopId) } })


        } else {
            const obj = { ...checkedShopState.shops[shopId], ...{ allProduct: false } }
            checkedShopState.shops[shopId] = obj
            yield put({ type: ADD_KEY_VALUE_CHECKED_SHOP, payload: { key: 'shops', value: checkedShopState.shops } })
            yield put({ type: UPDATE_COUNT_CHECKED_ALL_SHOP, payload: { shopId: parseInt(shopId) } })
        }
        const getSelectedShopState = yield select(getSelectedShop)
        const data = convert2arrPromocode(getSelectedShopState)
        yield put({ type: ADD_ITEMJOIN_PROMOCODE, payload: data })
    } catch (error) {
        console.error(error)
    }

}


export default function* useWatcher() {
    yield takeLatest(GET_LIST_PROMOCODE, onGetListPromocode)
    yield takeLatest(VERIFY_REF_CODE, withCallback(handleVerifyRefCode))
    yield takeLatest(CREATE_PROMOCODE, handleCreatePromocode)
    yield takeLatest(OPEN_MODAL_LIST_SKU, handleOpenModalListSku)
    yield takeLatest(OPEN_MODAL_SELECTED_SHOP, handleOpenModalSelectedShop)

    yield takeLatest(GET_DETAIL_UPDATE_PROMOCODE, getDetailUpdatePromocode)
    yield takeLatest(HANDLE_DELETE_PROMOCODE, handleDeletePromocode)
    yield takeLatest(HANDLE_UPDATE_PROMOCODE, handleUpdatePromocode)
    yield takeLatest(CREATE_COUPON, handleCreateCoupon)
    yield takeLatest(UPDATE_COUPON, handleUpdateCoupon)
    yield takeLatest(GET_COUPON_DETAIL, handleGetCouponDetail)
    yield takeLatest(GET_COUPON_ALL_DETAIL, handleGetCouponAllDetail)
    yield takeLatest(GET_CLIENT_LEVEL, handleGetClientLevel)
    yield takeLatest(SET_SHOP_METAS, handleSetShopMetas)
    yield takeLatest(GET_PRODUCT_PROMOCODE, onGetProductPromocode)
    yield takeLatest(SELECTED_ITEMJOIN_PROMOCODECODE, handleselectItemJoinPromocode)
    yield takeLatest(SELECTED_PRODUCT_PROMOCODE, handleselectProductPromocode)
    yield takeLatest(OPEN_MODAL_SELECTED_PRODUCT, handleOpenModalSelectedProduct)
    yield takeLatest(UPDATE_COUNT_CHECKED_ALL_SHOP, updateCheckAllShop)

    yield takeLatest(SELECT_SHOP_PRODUCT, handleSelectShopProduct)
    yield takeLatest(SUBMIT_PRODUCT_PROMOCODE, handleSubmitProduct)
    yield takeLatest(SELECTED_PRODUCT_IN_SHOP, handleSelectedProductInShop)
    yield takeLatest(SELECTED_SHOPJOIN_PRODUCT, handleSubmitItemJionShop)
    yield takeLatest(UPDATE_TYPE_PRODUCT_SELECTION_PROMOCODE, handleUpdateTypeItemJoinShop)


}
