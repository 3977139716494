import axiosNew from "config/axios"
import host from "config/host"

class MassUploadProvider {
    getListUploadExcel = async (params) => {
        try {
            const path = `${host.api}/product-attachments?page=${params.page}&limit=${params.skip}`
            const res = await axiosNew.get(path)
            return res.data
        } catch (error) {
            throw error
        }
    }

    getListEditUploadExcel = async (params) => {
        try {
            const path = `${host.api}/product-attachments?page=${params.page}&limit=${100}&type=edit`
            const res = await axiosNew.get(path)
            return res.data
        } catch (error) {
            throw error
        }
    }

    getDownloadExcel = async (shopId,filename) => {
        try {
            const path = `${host.api}/product-attachments/download?shopId=${shopId}&fileName=${filename}`
            const res = await axiosNew.get(path)
            return res.data
        } catch (error) {
                throw error
        }
    }

    getListUploadExcelTracking = async (params) => {
        try {
            const path = `${host.api}/delivery-attachments?page=${params.page}&limit=${params.skip}`
            const res = await axiosNew.get(path)
            return res.data
        } catch (error) {
            throw error
        }
    }

    getDownloadExcelTracking = async (shopId,filename) => {
        try {
            const path = `${host.api}/delivery-attachments/download?shopId=${shopId}&fileName=${filename}`
            const res = await axiosNew.get(path)
            return res.data
        } catch (error) {
                throw error
        }
    }


}

export default new MassUploadProvider()