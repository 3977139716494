export const GET_PLAYLISTS = 'GET_PLAYLISTS'
export const SET_PLAYLISTS = 'SET_PLAYLISTS'
export const CREATE_PLAYLISTS = 'CREATE_PLAYLISTS'
export const UPDATE_PLAYLISTS = 'UPDATE_PLAYLISTS'
export const GET_PLAYLISTS_ITEMS = 'GET_PLAYLISTS_ITEMS'
export const SET_PLAYLISTS_ITEMS = 'SET_PLAYLISTS_ITEMS'
export const CREATE_PLAYLISTS_ITEM = 'CREATE_PLAYLISTS_ITEM'
export const SHUFFLE_PLAYLISTS_ITEMS = "SHUFFLE_PLAYLISTS_ITEMS"
export const PIN_PLAYLISTS_ITEMS = "PIN_PLAYLISTS_ITEMS"
export const DELETE_PLAYLISTS = 'DELETE_PLAYLISTS'
export const DELETE_PLAYLISTS_ITEM = 'DELETE_PLAYLISTS_ITEM'
export const UPDATE_ORDER_PLAYLISTS = 'UPDATE_ORDER_PLAYLISTS'
export const UPDATE_ORDER_PLAYLISTS_ITEMS = 'UPDATE_ORDER_PLAYLISTS_ITEMS'
export const SET_MODAL_ADD_PLAYLIST_MALL = 'SET_MODAL_ADD_PLAYLIST_MALL'
export const GET_PLAYLIST_SHOP = 'GET_PLAYLIST_SHOP'
export const UPDATE_PLAYLIST_SHOP_ORDER = 'UPDATE_PLAYLIST_SHOP_ORDER'
export const ADD_SHOP_TO_PLAYLIST = 'ADD_SHOP_TO_PLAYLIST'
export const ADD_KEY_VALUE_PLAYLISTS = 'ADD_KEY_VALUE_PLAYLISTS'
export const DELETE_SHOP_FROM_PLAYLIST = 'DELETE_SHOP_FROM_PLAYLIST'
export const GET_PLAYLIST_SHOP_COUPON = 'GET_PLAYLIST_SHOP_COUPON'
export const GET_LISTKEY = 'GET_LISTKEY'
export const DELETE_PLAYLIST_CATEGORY = 'DELETE_PLAYLIST_CATEGORY'
export const ADD_PLAYLIST_CATEGORIES = 'ADD_PLAYLIST_CATEGORIES'
export const UPDATE_PLAYLIST_CATEGORY = 'UPDATE_PLAYLIST_CATEGORY'
export const GET_PLAYLIST_CATEGORY = 'GET_PLAYLIST_CATEGORY'
export const GET_PLAYLIST_CATEGORY_MALL = 'GET_PLAYLIST_CATEGORY_MALL'
export const DELETE_PLAYLIST_PLAYLIST_CATEGORY = 'DELETE_PLAYLIST_PLAYLIST_CATEGORY'
export const GET_PLAYLIST_COUPON = 'GET_PLAYLIST_COUPON'
export const CREATE_PLAYLIST_COUPON_ITEM = 'CREATE_PLAYLIST_COUPON_ITEM'
export const UPDATE_PLAYLIST_COUPON = 'UPDATE_PLAYLIST_COUPON'
export const DELETE_COUPON_ITEM = 'DELETE_COUPON_ITEM'



export function dispatchPlaylists(param) {
  return {
    type: param.type,
    payload: param.payload,
  }
}
