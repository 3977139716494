import React, { Component } from "react";
import { connect } from "react-redux";

import './index.scss';
// import host from 'config/host';

import ProductSkuItem from '../ProductSkuItem'

const initialState = {};

class ProductItem extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  filterProductSkuSelected = product => {
    if (product.productSkus.length > 0) {
      const skus =  product.productSkus.reduce((prev, current) => {
          
              if(current.select === true) prev = [...prev, current]
              return prev;
          
      }, [])
      return skus;
    } else return [];
  }

  render() {
    // const productSkus = this.filterProductSkuSelected(this.props.product);
    const productSkus = this.props.product.productSkus;
    const {productIndex} = this.props;

    return <React.Fragment>
      { this.filterProductSkuSelected(this.props.product).length > 0 && 
        <section className="section-item">
            <div className="item-header">
                <div className="row p-2" style={{textAlign:'left', justifyContent: 'center'}}>
                    <div style={{width:"25%"}}> 
                        <span className="title-sm">{this.props.product?.name}</span> 
                    </div>
                    <div style={{width:"15%"}}>
                        <span className="title-sm">ราคาปกติ</span>
                        <div className="title-detail-sm">ราคาตั้งต้นก่อนลดราคา</div>
                    </div>
                    <div style={{width:"10%"}}>
                        <span className="title-sm">ราคาสุทธิ</span>
                        <div className="title-detail-sm">ราคาที่ลูกค้าจะเห็น</div>
                    </div>
                    <div style={{width:"5%"}}></div>
                    <div style={{width:"10%", padding:'0 5px'}}>
                        <span className="title-sm">ส่วนลด (%)</span>
                        <div className="title-detail-sm">คิดเป็นเปอร์เซนต์</div>
                    </div>
                    <div style={{width:"20%", padding:'0 5px', maxWidth: '150px'}}>
                        <span className="title-sm">จำนวนสิทธิ์พิเศษ</span>
                        <div className="title-detail-sm">จำนวนของสินค้าราคาพิเศษที่ต้อง การขายในไลฟ์ / สต็อกทั้งหมด</div>
                    </div>
                    <div style={{width:"10%", padding:'0 0 0 5px'}}>
                        <span className="title-sm">จำกัดสิทธิ์การซื้อ</span>
                        <div className="title-detail-sm">จำกัดสิทธิ์ต่อการซื้อ 1 ครั้ง</div>
                    </div>
                    <div style={{width:"5%", padding:'0 0 0 5px'}}></div>
                </div>
            </div>

            { productSkus.map((productSku,productSkuIndex) => <ProductSkuItem 
                                                       key={productSkuIndex} 
                                                       productSku={productSku}
                                                       onChange={this.props.onChange}
                                                       onDelete={this.props.onDelete}
                                                       onElementFocus={this.props.onElementFocus}
                                                       params={{productIndex ,productSkuIndex}}
                                                       /> )}
            
        </section>
      }
    </React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(ProductItem);


  
