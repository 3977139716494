import React, { useEffect, useState } from 'react';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from "file-saver";
import { displayDateThShortYear } from 'helper/timeUtil';
import { ReactComponent as IconDownload } from 'images/icons/IconDownload.svg'
import { Body1 } from 'components/FontStyle';
import './styles.scss';
import { SET_LOADING, dispatchApp } from 'redux/actions/app';
import { useDispatch } from 'react-redux';

const XlsxCoupon = (props) => {
    const { fileName, handleFetchData, isPlatform } = props
    const [data, setData] = useState([]);
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchData = async () => {
            setData([]);
        };

        fetchData();
    }, []);

    const getSheetData = (data, header) => {
        // const dataLength = data[0]
        // const columnNames = data[0].map((item, index) => convertToExcelColumnName(index));
        // return columnNames;
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        // sheetData.unshift(header);
        sheetData.slice(1, sheetData.length)
        return sheetData;
    }

    function convertToExcelColumnName(index) {
        let columnName = '';

        while (index >= 0) {
            columnName = String.fromCharCode(65 + (index % 26)) + columnName;
            index = Math.floor(index / 26) - 1;
        }
        return columnName;
    }


    const saveAsExcel = async (data) => {
        let col
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            col = data[0].map((item, index) => convertToExcelColumnName(index));
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(data, col);
            const totalColumns = sheetData.length;

            sheet1.cell("A6").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = sheetData[sheetData.length - 1];
            const arrTextRight = ['I','J','K','L','P','W','Y','AA','AB','AC','AE','AF','AG','AH','M','O']
            for (let index = 0; index < arrTextRight.length; index++) {
                const element = arrTextRight[index];
                sheet1.range(`${element}6:${element}${totalColumns + 6}`).style({ horizontalAlignment: 'right' });
            }

            const arrTextCenter = ['N','X','Z','AD','AI']
            for (let index = 0; index < arrTextCenter.length; index++) {
                const element = arrTextCenter[index];
                sheet1.range(`${element}6:${element}${totalColumns + 6}`).style({ horizontalAlignment: 'center' });
            }
            for (let index = 1; index < 6; index++) {
                sheet1.row(index).style("bold", true);
            }
            col.map((alp) => sheet1.column(alp).width(25).hidden(false))

            sheet1.row(1).height(30)
            sheet1.row(2).height(30)
            sheet1.row(3).height(30)
            sheet1.row(4).height(30)
            sheet1.row(5).height(30)

            // sheet1.column("G").width(50).hidden(true);
            // sheet1.range("A1:" + "F" + "1").style("fill", "BFBFBF");

            const header = workbook.sheet(0).range(`A1:${col[col.length - 1]}1`);
            header.value("รายการคูปองส่วนลด");
            header.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            header.merged(true);


            const indexCol = workbook.sheet(0).range(`A2:A5`);
            indexCol.value("ลำดับ");
            indexCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            indexCol.merged(true);



            const shopCol = workbook.sheet(0).range(`B2:B5`);
            shopCol.value(isPlatform ? "คูปองแพลตฟอร์ม" : "ชื่อร้าน");
            shopCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            shopCol.merged(true);


            ///รายละเอียดส่วนลด
            const detailCol = workbook.sheet(0).range(`C2:G2`);
            detailCol.value("รายละเอียดส่วนลด");
            detailCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            detailCol.merged(true);


            const nameCol = workbook.sheet(0).range(`C3:C5`);
            nameCol.value("ชื่อคูปองส่วนลด");
            nameCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            nameCol.merged(true);

            const detailCouponCol = workbook.sheet(0).range(`D3:D5`);
            detailCouponCol.value("รายละเอียด");
            detailCouponCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            detailCouponCol.merged(true);


            ////รูปแบบโค้ดส่วนลด
            const typeDiscountCol = workbook.sheet(0).range(`E3:G3`);
            typeDiscountCol.value("รูปแบบโค้ดส่วนลด");
            typeDiscountCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            typeDiscountCol.merged(true);

            const keepCouponCol = workbook.sheet(0).cell(`E4`);
            keepCouponCol.value("สำหรับให้ลูกค้าเก็บส่วนลดก่อนใช้");
            sheet1.column("E").width(50);
            keepCouponCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const promoCodecol = workbook.sheet(0).range(`F4:G4`);
            promoCodecol.value("สำหรับให้ลูกค้ากรอกช่องโค้ดส่วนลด (Promo Code)");
            promoCodecol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            promoCodecol.merged(true);

            const codeKeepCol = workbook.sheet(0).cell(`E5`);
            codeKeepCol.value("เลขหมายกำกับรหัสโค้ด");
            codeKeepCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const codeCol = workbook.sheet(0).cell(`F5`);
            codeCol.value("เลขหมายกำกับรหัสโค้ด");
            codeCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const passCodeCol = workbook.sheet(0).cell(`G5`);
            passCodeCol.value("รหัสโค้ดสำหรับนำไปใช้งาน");
            passCodeCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const typeDiscountHeader = workbook.sheet(0).range(`H2:N2`);
            typeDiscountHeader.value("รูปแบบส่วนลด");
            typeDiscountHeader.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            typeDiscountHeader.merged(true);

            const typeCol = workbook.sheet(0).range(`H3:H5`);
            typeCol.value("รูปแบบส่วนลด");
            typeCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            typeCol.merged(true);

            const formatDiscountCol = workbook.sheet(0).range(`I3:L3`);
            formatDiscountCol.value("ประเภทส่วนลด");
            formatDiscountCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            formatDiscountCol.merged(true);

            const percentDiscountCol = workbook.sheet(0).range(`I4:J4`);
            percentDiscountCol.value("ลดราคาเป็น %");
            percentDiscountCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            percentDiscountCol.merged(true);

            const cashDiscountCol = workbook.sheet(0).range(`K4:L4`);
            cashDiscountCol.value("ลดราคาเป็นเงินสด");
            cashDiscountCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            cashDiscountCol.merged(true);

            const purchaseMinCol = workbook.sheet(0).cell(`I5`);
            purchaseMinCol.value("เมื่อยอดซื้อถึง (฿)");
            purchaseMinCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const purchaseMinPercentCol = workbook.sheet(0).cell(`J5`);
            purchaseMinPercentCol.value("รับส่วนลด (%)");
            purchaseMinPercentCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const percentPurchaseMinCol = workbook.sheet(0).cell(`K5`);
            percentPurchaseMinCol.value("เมื่อยอดซื้อถึง (฿)");
            percentPurchaseMinCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const CashPurchaseMinCol = workbook.sheet(0).cell(`L5`);
            CashPurchaseMinCol.value("รับส่วนลด (บาท)");
            CashPurchaseMinCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const maximumDiscountCol = workbook.sheet(0).range(`M3:N4`);
            maximumDiscountCol.value("ลดสูงสุด");
            maximumDiscountCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            maximumDiscountCol.merged(true);

            const limitCol = workbook.sheet(0).cell(`M5`);
            limitCol.value("จำกัด(ระบุจำนวน)");
            limitCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const unlimitCol = workbook.sheet(0).cell(`N5`);
            unlimitCol.value("ไม่จำกัด");
            unlimitCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })


            const skuDiscountCol = workbook.sheet(0).range(`O2:P3`);
            skuDiscountCol.value("สินค้าที่เข้าร่วมส่วนลด");
            skuDiscountCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            skuDiscountCol.merged(true);

            // const listSKUDiscountCol = workbook.sheet(0).range(`O3:P3`);
            // listSKUDiscountCol.value("สินค้าที่เข้าร่วม");
            // listSKUDiscountCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            // listSKUDiscountCol.merged(true);

            const allProductCol = workbook.sheet(0).range(`O4:O5`);
            allProductCol.value("สินค้าทั้งหมด");
            allProductCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            allProductCol.merged(true);

            const skuDiscountCount = workbook.sheet(0).range(`P4:P5`);
            skuDiscountCount.value("สินค้าบางรายการ\n(ระบุจำนวนSKU)");
            skuDiscountCount.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            skuDiscountCount.merged(true);

        
            const rangeKeepCoupon = workbook.sheet(0).range(`Q2:T3`);
            rangeKeepCoupon.value("ระยะเวลาการเก็บคูปอง");
            rangeKeepCoupon.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            rangeKeepCoupon.merged(true);

            const rangeDateCol = workbook.sheet(0).range(`Q4:T4`);
            rangeDateCol.value("กำหนดวันที่");
            rangeDateCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            rangeDateCol.merged(true);

            const keepDateStartCol = workbook.sheet(0).cell(`Q5`);
            keepDateStartCol.value("วันที่เริ่มเก็บ");
            keepDateStartCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const keepTimeStartCol = workbook.sheet(0).cell(`R5`);
            keepTimeStartCol.value("เวลาที่เริ่มเก็บ");
            keepTimeStartCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const keepDateEndCol = workbook.sheet(0).cell(`S5`);
            keepDateEndCol.value("วันที่สิ้นสุด");
            keepDateEndCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const keepTimeEnd = workbook.sheet(0).cell(`T5`);
            keepTimeEnd.value("เวลาที่สิ้นสุด");
            keepTimeEnd.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const rangeUseCol = workbook.sheet(0).range(`U2:V3`);
            rangeUseCol.value("ระยะเวลาการใช้งาน");
            rangeUseCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            rangeUseCol.merged(true);

            const rangeUseDateTimeCol = workbook.sheet(0).range(`U4:V4`);
            rangeUseDateTimeCol.value("กำหนดวันที่");
            rangeUseDateTimeCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            rangeUseDateTimeCol.merged(true);

            const rangeUseDateStartCol = workbook.sheet(0).cell(`U5`);
            rangeUseDateStartCol.value("วันที่เริ่มต้น");
            rangeUseDateStartCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const rangeUseDateEndCol = workbook.sheet(0).cell(`V5`);
            rangeUseDateEndCol.value("วันที่สิ้นสุด");
            rangeUseDateEndCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const keepTypeCol = workbook.sheet(0).range(`W2:AI2`);
            keepTypeCol.value("การจำกัดจำนวน");
            keepTypeCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            keepTypeCol.merged(true);
          
            const allCouponCol = workbook.sheet(0).range(`W3:X4`);
            allCouponCol.value("จำนวนคูปองส่วนลดทั้งหมด");
            allCouponCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            allCouponCol.merged(true);
           
            const typeGiveCol = workbook.sheet(0).range(`Y3:Z4`);
            typeGiveCol.value("รูปแบบจำนวนการแจก");
            typeGiveCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            typeGiveCol.merged(true);
            
            const usedCouponCol = workbook.sheet(0).range(`AA3:AD3`);
            usedCouponCol.value("จำนวนการใช้คูปองส่วนลดทั้งหมด");
            usedCouponCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            usedCouponCol.merged(true);
            
            const limitUseCouponCol = workbook.sheet(0).range(`AA4:AC4`);
            limitUseCouponCol.value("จำกัด (ระบุจำนวน)");
            limitUseCouponCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            limitUseCouponCol.merged(true);
           
            const unlimitUseCol = workbook.sheet(0).range(`AD4:AD5`);
            unlimitUseCol.value("ไม่จำกัด");
            unlimitUseCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            unlimitUseCol.merged(true);

            const limitUsedCount = workbook.sheet(0).range(`AE4:AH4`);
            limitUsedCount.value("จำกัด (ระบุจำนวน)");
            limitUsedCount.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            limitUsedCount.merged(true);

            const unlimitUsedCount = workbook.sheet(0).range(`AI4:AI5`);
            unlimitUsedCount.value("ไม่จำกัด");
            unlimitUsedCount.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            unlimitUsedCount.merged(true);

            const maximumUseCouponCol = workbook.sheet(0).range(`AE3:AI3`);
            maximumUseCouponCol.value("จำนวนการใช้คูปองส่วนลดของผู้ซื้อ");
            maximumUseCouponCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            maximumUseCouponCol.merged(true);

            const typeCustomerUseCol = workbook.sheet(0).range(`AJ2:AJ5`);
            typeCustomerUseCol.value("ประเภทลูกค้าที่ใช้สิทธิ์ได้");
            typeCustomerUseCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            typeCustomerUseCol.merged(true);

            const statusCouponCol = workbook.sheet(0).range(`AK2:AK5`);
            statusCouponCol.value("สถานะการใช้งาน");
            statusCouponCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            statusCouponCol.merged(true);

            const totalCouponLimitCol = workbook.sheet(0).cell(`W5`);
            totalCouponLimitCol.value("จำกัด (ระบุจำนวน)");
            totalCouponLimitCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const limitTypeGiveAwayCouponCol = workbook.sheet(0).cell(`Y5`);
            limitTypeGiveAwayCouponCol.value("จำกัด (ระบุจำนวน)");
            limitTypeGiveAwayCouponCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const TotalCouponUnlimitCol = workbook.sheet(0).cell(`Z5`);
            TotalCouponUnlimitCol.value("ไม่จำกัด");
            TotalCouponUnlimitCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const unLimitTypeGiveAwayCouponCol = workbook.sheet(0).cell(`X5`);
            unLimitTypeGiveAwayCouponCol.value("ไม่จำกัด");
            unLimitTypeGiveAwayCouponCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })
            
            const limitTypeCouponUsedPerDayCol = workbook.sheet(0).cell(`AA5`);
            limitTypeCouponUsedPerDayCol.value("ต่อวัน");
            limitTypeCouponUsedPerDayCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const usesDiscountCouponBuyerPerDayCol = workbook.sheet(0).cell(`AE5`);
            usesDiscountCouponBuyerPerDayCol.value("ต่อวัน");
            usesDiscountCouponBuyerPerDayCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const limitTypeCouponUsedPerWeekCol = workbook.sheet(0).cell(`AB5`);
            limitTypeCouponUsedPerWeekCol.value("ต่อสัปดาห์");
            limitTypeCouponUsedPerWeekCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const usesDiscountCouponBuyerPerWeekCol = workbook.sheet(0).cell(`AF5`);
            usesDiscountCouponBuyerPerWeekCol.value("ต่อสัปดาห์");
            usesDiscountCouponBuyerPerWeekCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const limitTypeCouponUsedPerMonthCol = workbook.sheet(0).cell(`AC5`);
            limitTypeCouponUsedPerMonthCol.value("ต่อเดือน");
            limitTypeCouponUsedPerMonthCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const usesDiscountCouponBuyerPerMonthCol = workbook.sheet(0).cell(`AG5`);
            usesDiscountCouponBuyerPerMonthCol.value("ต่อเดือน");
            usesDiscountCouponBuyerPerMonthCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const usesDiscountCouponBuyerPerCampaignCol = workbook.sheet(0).cell(`AH5`);
            usesDiscountCouponBuyerPerCampaignCol.value("ต่อทั้งแคมเปญ");
            usesDiscountCouponBuyerPerCampaignCol.style({ horizontalAlignment: "center", verticalAlignment: "center", border: true })

            const numberCol = ['A','P','W','Y','AA','AB','AC','AE','AF','AG','AH','O','P']
            for (let index = 0; index < numberCol.length; index++) {
                const element = numberCol[index];
                sheet1.column(element).style({ numberFormat: '#,#' })
            }

            const priceCol = ['I', 'J', 'K', 'L','M']
            for (let index = 0; index < priceCol.length; index++) {
                const element = priceCol[index];
                sheet1.column(element).style({ numberFormat: '#,#.00' })
            }

            sheet1.column("AB").width(50).hidden(false)
            sheet1.column("A").width(10).hidden(false)
            sheet1.column("D").width(40).style({wrapText: true});


            range.style("border", true);

            // sheet1.freezePanes('A1');

            return workbook.outputAsync().then((res) => {
                saveAs(res, `${fileName}.xlsx`);
            });
        });
    }

    const getResData = async () => {

        try {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))

            const resData = await new Promise(async (resolve, reject) => {
                const res = await handleFetchData()
                resolve(res)
            })


            if (resData.data.length) {
                const arrCouponExcel = []
                resData.data.map((coupon, index) => {
                    arrCouponExcel.push([
                        index + 1,
                        isPlatform ? "คูปองแพลตฟอร์ม" : (coupon?.shop?.mall?.name || '-'),
                        coupon?.name,
                        coupon?.detail?.description,
                        coupon?.detail?.productType === 'batch' ? coupon?.coupon?.refCode1 : '-',
                        coupon?.detail?.productType === 'online' ? coupon?.coupon?.refCode1 : '-',
                        coupon?.detail?.productType === 'online' ? coupon?.detail?.codeFormat?.fixed : '-',
                        coupon?.coupon.couponType === 'coupon' ? 'คูปอง' : coupon.coupon.couponType === 'discount' ? 'ลดราคา' : coupon.coupon.couponType,
                        coupon?.detail?.discountUnit === 'percent' ? coupon.detail?.logicData?.purchaseMin : '-',
                        coupon?.detail?.discountUnit === 'percent' ? coupon.detail?.discountValue : '-',
                        coupon?.detail?.discountUnit === 'number' ? coupon.detail?.logicData?.purchaseMin : '-',
                        coupon.detail?.discountUnit === 'number' ? coupon.detail?.discountValue : '-',
                        coupon?.detail?.discountUnit === 'percent' ? coupon.detail?.logicData?.discountLimit || '-' : '-' || '-',
                        ((coupon?.detail?.discountUnit === 'percent' && coupon.detail?.logicData?.discountLimit)) ? '-' : `\u2714`,
                        coupon?.countProductJoin ? coupon?.countProductJoin : 0, // all product
                        coupon?.countSkuJoin ? coupon?.countSkuJoin : 0,  ///sku amount
                        displayDateThShortYear(coupon?.collectStartDate), //date
                        displayDateThShortYear(coupon?.collectStartDate, 'HH:mm'), // time collect
                        displayDateThShortYear(coupon?.collectStopDate), // date stop
                        displayDateThShortYear(coupon?.collectStopDate, 'HH:mm'), // time collect stop
                        displayDateThShortYear(coupon.detail?.sellingStartAt),
                        displayDateThShortYear(coupon.detail?.sellingStopAt),
                        coupon.detail?.stockMax !== 999999 ? coupon.detail?.stockMax : '-',
                        coupon.detail?.stockMax === 999999 ? `\u2714` : '-',
                        coupon.detail?.generateLimit !== 999999 ? coupon.detail?.generateLimit : '-',
                        coupon.detail?.generateLimit === 999999 ? `\u2714` : '-',
                        coupon.detail?.limitGlobal?.unit === 'day' ? coupon.detail?.limitGlobal?.value : '-',
                        coupon.detail?.limitGlobal?.unit === 'week' ? coupon.detail?.limitGlobal?.value : '-',
                        coupon.detail?.limitGlobal?.unit === 'month' && coupon.detail?.limitGlobal?.value !== 999999 ? coupon.detail?.limitGlobal?.value : '-',
                        coupon.detail?.limitGlobal?.value === 999999 ? `\u2714` : '-',
                        coupon.limitUser?.unit === 'day' ? coupon.limitUser?.value : '-',
                        coupon.limitUser?.unit === 'week' ? coupon.limitUser?.value : '-',
                        coupon.limitUser?.unit === 'month' ? coupon.limitUser?.value : '-',
                        coupon.limitUser?.unit === 'promotion' ? coupon.limitUser?.value : '-',
                        coupon.limitUser === null ? `\u2714` : '-',
                        checkTypeCustomer(coupon.levelIds),
                        coupon.coupon.status === 'show' ? 
                        coupon.statusCustom === 'comingsoon' ? 'เร็วๆนี้' : 
                        coupon.statusCustom === 'processing' ? 'ใช้งานอยู่' : 
                        coupon.statusCustom === 'expired' ? 'หมดอายุ' : '' : 'ยังไม่เปิดใช้งาน'
                    ]
                    )
                })
                await setData(arrCouponExcel)
                saveAsExcel(arrCouponExcel)
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        }

    }

    function checkTypeCustomer(arr) {
        // if(!!arr) return '-'
        if (arr?.length === 1 && arr[0] === 1) {
            return 'เฉพาะลูกค้าที่ไม่ใช่ AIS';
        } else if (arr?.length === 1 && arr[0] === 2) {
            return 'เฉพาะลูกค้า AIS';
        } else if (arr?.length === 2 && arr.includes(1) && arr.includes(2)) {
            return 'ลูกค้าทั้งหมด';
        } else {
            return `-`;
        }
    }

    return (
        <div className='xlsx-coupon-container'>
            {/* <h2>download</h2> */}
            <button className='btn btn-cancel d-flex flex-row align-items-center' onClick={getResData}> <IconDownload /> <Body1>ดาวน์โหลด Excel</Body1></button>

        </div>
    );
};

export default XlsxCoupon;
