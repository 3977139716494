/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  dispatchGallery,
  GET_MEDIA_GALLERY,
  CREATE_MEDIA_GALLERY,
  UPDATE_MEDIA_GALLERY,
  DELETE_MEDIA_GALLERY,
  SEARCH_MEDIA_GALLERY,
  SET_GALLERY_FIELD,
  GET_ALBUM_GALLERY,
} from '../../../redux/actions/gallery'
import {
  MediaCard,
  DropdownSort,
  InputSearch,
  ButtonAdd,
  ShopStorage,
  Modals,
  ButtonIcon,
  ModalLoading,
} from 'components/output'
import ModalUploadMedia from '../ModalUploadMedia'
import { TextInput, Textarea, Checkbox, Dropdown } from 'components/input'
import { LayoutGallery, LayoutTab } from 'components/layout'
import { tabsBrowse, optionsDropdownSort, detailButton } from '../constant'
import movefile from '../../../images/icons/movefile.svg'
import deleteicon from '../../../images/icons/deleteicon.svg'
import 'styles/gallery.scss'
import { getUserRole } from 'helper/utils'
import host from 'config/host'

const arrAllmedia = new Set()

const Media = (props) => {
  const {
    isMainPage,
    isEditName,
    cardType,
    shopDetail,
    albumDetail,
    dispatch,
    mediaData,
    albumData,
    setTmpMediaSelect,
    mediaUploadEvent,
    fieldUpload,
    limitImageUpload,
    validateWidthHeight
  } = props

  const [isLoading, setIsLoading] = useState(true)
  const [activeModal, setActiveModal] = useState('none')
  const [mediaType, setMediaType] = useState(cardType)

  const [mediaName, setMediaName] = useState('')
  const [mediaDescription, setMediaDescription] = useState('')
  const [mediaId, setMediaId] = useState(null)
  const [albumArrange, setAlbumArrange] = useState([])
  const [albumPicked, setAlbumPicked] = useState(null)
  const [mediaSelected, setMediaSelected] = useState([])

  useEffect(() => {
    getMedia(cardType)
    if (!albumData) {
      getAlbum(albumDetail.shopId)
    }
  }, [])

  useMemo(() => {
    setMediaSelected(mediaUploadEvent || [])
  }, [mediaUploadEvent])

  const getMedia = (type) => {
    dispatch(
      dispatchGallery({
        type: GET_MEDIA_GALLERY,
        payload: { albumId: albumDetail.id, type: type },
      }),
    )
  }

  const getAlbum = (shopId) => {
    dispatch(
      dispatchGallery({
        type: GET_ALBUM_GALLERY,
        payload: { shopId: shopId },
      }),
    )
  }

  useEffect(() => {
    if (mediaData) {
      setIsLoading(false)
      arrAllmedia.clear()
      const tmpMediaByType = mediaData.map((item) => arrAllmedia.add(item))
    }
  }, [mediaData])

  const toggleModal = (modal) => {
    setActiveModal(modal)
  }

  const onClickTab = (value) => {
    setMediaType(value)
    setIsLoading(true)
    getMedia(value)
    arrangeAlbumDataForDropDown()
  }

  const clickButtonModals = () => {
    if (activeModal === 'createImage' && mediaName) {
      /////// ไปอยู่หน้า Modal Upload
      // dispatch(
      //   dispatchGallery({
      //     type: CREATE_MEDIA_GALLERY,
      //     payload: { albumId: albumDetail.id },
      //     etc: { type: mediaType },
      //   }),
      // )
    } else if (activeModal === 'editImage' && mediaName) {
      dispatch(
        dispatchGallery({
          type: UPDATE_MEDIA_GALLERY,
          payload: { name: mediaName, description: mediaDescription },
          etc: { mediaId: mediaId, type: mediaType, albumId: albumDetail.id },
        }),
      )
    } else if (activeModal === 'moveImage') {
      for (const media of mediaSelected) {
        dispatch(
          dispatchGallery({
            type: UPDATE_MEDIA_GALLERY,
            payload: { albumId: albumPicked },
            etc: {
              mediaId: media.id,
              type: mediaType,
              albumId: albumDetail.id,
            },
          }),
        )
      }
      setAlbumPicked(null)
    } else if (activeModal === 'deleteImage') {
      for (const media of mediaSelected) {
        dispatch(
          dispatchGallery({
            type: DELETE_MEDIA_GALLERY,
            etc: {
              mediaId: media.id,
              type: mediaType,
              albumId: albumDetail.id,
            },
          }),
        )
      }
    }

    toggleModal('none')
  }

  const arrangeAlbumDataForDropDown = () => {
    const arrArrange = []
    if (albumData) {
      for (const alb of albumData) {
        arrArrange.push({
          value: alb.id,
          name: alb.name === 'default' ? 'อัลบั้มหลัก' : alb.name,
        })
      }
      setAlbumArrange(arrArrange)
    }
  }

  const getMeta = async (url, media) => {
    let img = new Image();
    img.onload = function () {
      const arrSelected = [...mediaSelected]
      let height = this.height
      let width = this.width
      let validate = false
      if (validateWidthHeight.type == "lower") {
        if (validateWidthHeight.height < height || validateWidthHeight.width < width) {
          alert(`ขนาดรูปภาพต้องเป็น ${validateWidthHeight.width}x${validateWidthHeight.height} หรือต่ำกว่า`)
        } else {
          validate = true
        }
      } else if (validateWidthHeight.type == "heigher") {
        if (validateWidthHeight.height > height || validateWidthHeight.width > width) {
          alert(`ขนาดรูปภาพต้องเป็น ${validateWidthHeight.width}x${validateWidthHeight.height} หรือมากกว่า`)
        } else { validate = true }
      } else if (validateWidthHeight.type == "equal") {
        if (height != validateWidthHeight.height || width != validateWidthHeight.width) {
          alert(`ขนาดรูปภาพต้องเป็น ${validateWidthHeight.width}x${validateWidthHeight.height} เท่านั้น`)
        } else { validate = true }
      } else {

      }
      if ((validate &&
        limitImageUpload > arrSelected.length) ||
        limitImageUpload === undefined
      ) {
        arrSelected.push(media)
      }
      setMediaSelected(arrSelected)
      setTmpMediaSelect(arrSelected)
    }
    img.src = url;
  }


  const onSelectMedia = async (media) => {
    const arrSelected = [...mediaSelected]
    const arrayFind = arrSelected.findIndex(
      (mediaSelect) => mediaSelect.id == media.id,
    )
    if (arrayFind !== -1) {
      arrSelected.splice(arrayFind, 1)
    } else {
      if (validateWidthHeight) {
        await getMeta(`${host.image}/${media?.path}`, media)
      } else {
        if (
          limitImageUpload > arrSelected.length ||
          limitImageUpload === undefined
        ) {
          arrSelected.push(media)
        }
      }

    }

    setMediaSelected(arrSelected)
    setTmpMediaSelect(arrSelected) /// set event modal upload

    // if (fieldUpload) {
    //   dispatch(
    //     dispatchGallery({
    //       type: SET_GALLERY_FIELD,
    //       payload: { key: fieldUpload, value: arrSelected },
    //     }),
    //   )
    // }
  }

  const onSelectMediaAll = () => {
    if (mediaSelected.length === [...arrAllmedia].length) {
      setMediaSelected([])
      setTmpMediaSelect([]) /// set event modal upload
    } else {
      setMediaSelected([...arrAllmedia])
      setTmpMediaSelect([...arrAllmedia]) /// set event modal upload
    }
  }

  const checkPermissionSystemAlbum = () => {

    if(albumDetail.name === "ribbon (System)" || albumDetail.name === "frame (System)"){
      if(getUserRole() === 'SUPER_ADMIN') return true
      return false
    }else{
      return true
    }

  }

  return (
    <>
      <div className='container-select-image'>
        {isMainPage && (
          <ShopStorage
            detail={{
              id: shopDetail.shopId,
              name: shopDetail.shop.name,
              storage: {
                used: shopDetail.used,
                max: shopDetail.storageSizes,
              },
              count: 30,
              logoPath: shopDetail.shop?.logoPath?.path,
            }}
          />
        )}
        <LayoutTab
          valueActive='image'
          handleClickTab={onClickTab}
          isMainPage={isMainPage}
        >
          <div className='bg-white'>
            <div className='header'>
              <div className='content-header'>
                <h5>
                  {albumDetail.name === 'default'
                    ? 'อัลบั้มหลัก'
                    : albumDetail.name}
                </h5>
                {isMainPage && checkPermissionSystemAlbum() &&(
                  <ButtonAdd
                    label={
                      mediaType === 'image' ? 'เพิ่มรูปภาพ' : 'เพิ่มวิดีโอ'
                    }
                    handleAdd={() => toggleModal('createImage')}
                  />
                )}
              </div>
              <div className='content-header'>
                <div style={{ width: '50%' }}>
                  <InputSearch
                    handleSearch={(value) =>
                      dispatch(
                        dispatchGallery({
                          type: SEARCH_MEDIA_GALLERY,
                          payload: { name: value, albumId: albumDetail.id },
                        }),
                      )
                    }
                    placeholder='ค้นหาจากชื่อ'
                  />
                </div>
                <div style={{ width: '35%' }}>
                  <DropdownSort
                    label='อัปโหลดล่าสุด'
                    defaultValue='lastUpload'
                    options={optionsDropdownSort}
                    handleSort={(value) =>
                      dispatch(
                        dispatchGallery({
                          type: GET_MEDIA_GALLERY,
                          payload: {
                            albumId: albumDetail.id,
                            type: mediaType,
                            sort: value,
                          },
                        }),
                      )
                    }
                  />
                </div>
              </div>
            </div>
            {mediaData?.length ? (
              <div className='container-select-all '>
                <Checkbox
                  label='เลือกทั้งหมด'
                  onSelectMedia={onSelectMediaAll}
                  checking={
                    mediaSelected &&
                      mediaSelected.length === [...arrAllmedia].length &&
                      [...arrAllmedia].length !== 0
                      ? true
                      : false
                  }
                />
              </div>
            ) : null}
            <LayoutGallery>
              {!isLoading
                ? mediaData?.map((media, index) => {
                  return (
                    <MediaCard
                      key={media.id}
                      statusContent='edit-album'
                      statusAction='select-image'
                      data={media}
                      index={index}
                      onClickIconEdit={() => {
                        toggleModal('editImage')
                        setMediaName(media.name)
                        setMediaDescription(media.description)
                        setMediaId(media.id)
                      }}
                      isEditName={isEditName}
                      cardType={mediaType}
                      mediaSelected={mediaSelected}
                      onSelectMedia={onSelectMedia}
                    />
                  )
                })
                : null}
            </LayoutGallery>
          </div>
        </LayoutTab>
        {isMainPage && (
          <div className='footer-action'>
            <div className='btn-box'>
              <ButtonIcon
                label='ย้ายอัลบั้ม'
                color='bg-orange'
                onClickButton={() => {
                  toggleModal('moveImage')
                  arrangeAlbumDataForDropDown()
                }}
                disabled={mediaSelected.length > 0 ? false : true}
              >
                <img alt='' src={movefile} style={{ width: '20px' }} />
              </ButtonIcon>
              <ButtonIcon
                label='ลบ'
                color='bg-red'
                onClickButton={() => toggleModal('deleteImage')}
                disabled={mediaSelected.length > 0 ? false : true}
              >
                <img alt='' src={deleteicon} style={{ width: '15px' }} />
              </ButtonIcon>
            </div>
          </div>
        )}
      </div>
      {isMainPage && (
        <>
          <Modals
            title='รายละเอียดไฟล์'
            closeModal={() => toggleModal('none')}
            clickButton={clickButtonModals}
            labelButton='ตกลง'
            isOpenModal={activeModal === 'editImage'}
          >
            <TextInput
              label='ชื่อไฟล์'
              handleChangeText={(value) => setMediaName(value)}
              placeholder={mediaName}
            />
            <Textarea
              label='รายละเอียด'
              handleChangeText={(value) => setMediaDescription(value)}
              placeholder={mediaDescription}
            />
          </Modals>

          <Modals
            title='ต้องการลบรูป'
            closeModal={() => toggleModal('none')}
            clickButton={clickButtonModals}
            labelButton='ตกลง'
            labelCancel='ยกเลิก'
            isOpenModal={activeModal === 'deleteImage'}
          />

          <Modals
            title='ย้ายไปที่อัลบั้ม'
            closeModal={() => {
              toggleModal('none')
              setAlbumPicked(null)
            }}
            clickButton={clickButtonModals}
            labelButton='ตกลง'
            isOpenModal={activeModal === 'moveImage'}
          >
            <Dropdown
              options={albumArrange}
              handleSelected={(value) => setAlbumPicked(value)}
            />
          </Modals>

          <ModalUploadMedia
            title={detailButton[mediaType].title}
            labelResetMedia={detailButton[mediaType].reset}
            closeModal={() => toggleModal('none')}
            isOpenModal={activeModal === 'createImage'}
            dataTabs={tabsBrowse}
            cardType={mediaType}
            dispatch={dispatch}
            shopDetail={shopDetail}
            albumDetail={albumDetail}
            setLoading={(data) => setIsLoading(data)}
          />
        </>
      )}
    </>
  )
}

Media.defaultProps = {
  cardType: 'image',
  isEditName: false,
  isMainPage: false,
  setTmpMediaSelect: () => { },
  mediaUploadEvent: [],
  fieldUpload: null,
}

const mapStateToProps = (state) => ({
  mediaData: state.gallery.mediaData,
  albumData: state.gallery.albumData,
})

export default connect(mapStateToProps, null)(Media)
