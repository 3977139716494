// import TableHeader from 'components/TableHeader';
import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './index.scss'
import iconClose from 'images/icons/close.svg'
import { displayDateTh } from 'helper/timeUtil';
import numeral from 'numeral'

const statusWithdraw = [
    'requested', 'settled', 'approved', 'transferred'
]

class ModalWithdrawPreview extends Component {
    componentDidMount() { 
        // this.props.fetchData()
    }
    render() {
        const { isOpen, 
            // data, 
            handleClose, headerData } = this.props
        return (
            <div>
                <Modal isOpen={isOpen} className={'modal-withdraw-preview'} size="xl">
                    <ModalHeader>
                        <div className="w-100 d-flex justify-content-between">
                            <span>หมายเลขอ้างอิง {headerData?.refNumber}</span>
                            <img src={iconClose} alt="" className="cursor-pointer" onClick={handleClose} />
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="content-box">

                            <div className="header-content-box">
                                <div className="status-box">
                                    <span>รอบการตั้งเบิก : {displayDateTh(headerData?.settlementPeriod,'DD MMM yyyy')} </span>
                                    <span>วันที่กดถอน : {displayDateTh(headerData?.requestedDate,'DD MMM yyyy')} </span>
                                    {headerData?.transferredDate ? <span>วันที่โอนเงิน : {displayDateTh(headerData?.transferredDate,'DD MMM yyyy')}</span> : null}
                                    <span>สถานะ :  {headerData?.status === statusWithdraw[0] || headerData?.status === statusWithdraw[1] ? <span className="color-primary">รออนุมัติ</span> : <span className="color-success">อนุมัติแล้ว</span>} </span>

                                </div>
                                <div className="amount-box">
                                    <span>จำนวนเงินที่โอน</span>
                                    <span className="topic topic-secondary color-primary">฿ {numeral(headerData?.totalRevenue).format('0,0.00')}</span>
                                </div>
                            </div>
                            {this.props.children}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default ModalWithdrawPreview