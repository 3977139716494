import React from 'react';
import { ReactComponent as IconNoItem } from 'images/icons/no-item.svg';
import './emptyComponent.scss'

const EmptyComponent = (
    props = {}
) => {

    const {
        title = 'ไม่มีรายการ',
        iconCustom = null,
        bgColor = '#ffffff',
    } = props || {};

    /* Set Default */

    // title={'ไม่มีรายการ}'
    // iconCustom={null}
    // bgColor={''}

    /* Set Default */


    const IconRender = () => {
        if (!!iconCustom) {
            return (
                <div style={{ minHeight: '70px', minWidth: '70px' }}>
                    {iconCustom}
                </div>
            )
        } else {
            return (
                <IconNoItem style={{ minHeight: '70px', minWidth: '70px' }} />
            )
        }
    }

    return (
        <div
            className='d-flex justify-content-center align-items-center flex-column'
            style={{
                backgroundColor: bgColor ? bgColor : 'transparent',
                padding: "1rem",
                minHeight: "250px",
            }}
        >
            <IconRender />
            <span>{title}</span>
        </div>
    );
};


export default EmptyComponent;