import React from "react";
import Checkbox from "antd/lib/checkbox"; // for js
import './index.scss';
import { Caption } from "components/FontStyle";

const CheckboxComponent = (props) => {

  /**─────────────────────────────────────────────────────────────────────────────────────────────────
   *	title : String
   *	check : Boolean
   *	onChange : Callbacks Function
   *	name : String
   *	disabled : true
   *─────────────────────────────────────────────────────────────────────────────────────────────────*/

  const { title, checked, onChange, disabled, name, value } = props;

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      name={name}
      value={value}
    >
      <Caption>{title}</Caption>
    </Checkbox>
  );
};

export default CheckboxComponent;
