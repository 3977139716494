import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import './styles.scss';

const { Option } = Select;
// const children = [];

// for (let i = 10; i < 36; i++) {
//     children.push(<Option key={i.toString(36) + i}>{i}</Option>);
//     children.push(<Option value="jack">Jack</Option>);
// }



const MultipleSelect = (props) => {
    const {placeholder} = props
    const [listOption, setListOption] = useState([])
    const [arrListOption, setArrListOption] = useState([])
    const [value, setValue] = useState([])

    useEffect(() => {
        (async () => {
            const data = []
            const arr = []
            props.option.map(async (shop) => {
                await data.push(<Option key={shop.key}>{shop.key}</Option>);
                await arr.push({ key: shop.key, value: shop })
                setListOption(data)
                setArrListOption(arr)
            })
        })()

        return () => {

        }
    }, [props.option])

    const handleChange = (value) => {
        setValue(value)
        if(props.handleValueOption) {
            props.handleValueOption(value)
        }
    };


    return (
       <div id='multiple-select'>
           <Select
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder={placeholder}
                defaultValue={[]}
                onChange={(value) => handleChange(value)}
                value={props.value}
            >
                {listOption}
            </Select>
       </div>
    )
};

MultipleSelect.defaultProps = {
    placeholder: 'Please select'
}

export default MultipleSelect;