import { takeLatest, put, call, 
  // select, delay 
} from 'redux-saga/effects'
import userProvider from '../../provider/userProvider'
// import { FETCH_REQUEST_OTP
//     } from "../actions/user";
import {
  FETCH_USER_LOGIN,
  AUTH_USER,
  SET_AUTH_USER,
  SET_KEY_USER,
  CREATE_USER_SHOP,
  SET_USER_SHOP,
  CREATE_USER_SHOP_FIELD,
  SKIP_CREATE_USER,
} from '../actions/user'
import { 
  // getLocalStorage, 
  getUserRole } from 'helper/utils'
import Cookies from 'js-cookie'
import { OPEN_MODAL_MESSAGE_ERROR } from 'redux/actions/modalMessage'
import { SET_LOADING } from 'redux/actions/app'

// import { history } from '../../history'

const api_user = userProvider

//const liff = window.liff

export function* onFetchReqeustOtp(mobile, userId) { }

export function* handleLogin(action) {

  function loginPage(url) {
    const role = getUserRole()
    if (url) {
      let splitUrl = url.slice(1,(url.length - 1))
      localStorage.removeItem("tempUrl");
      if (role === 'OWNER') {
        window.location = splitUrl ? splitUrl : 'manage/dashboard/'
      } else if (role === 'ADMIN') {
        window.location = splitUrl ? splitUrl : 'manage/dashboard/'
      } else if (role === 'SUPER_ADMIN') {
        window.location = splitUrl ? splitUrl : 'manage/dashboard/'
      } else if (role === 'ACCOUNTANT') {
        window.location = splitUrl ? splitUrl : 'manage/revenue/withdraw'
      }
    } else {
      if (role === 'OWNER') {
        window.location = 'manage/dashboard/'
      } else if (role === 'ADMIN') {
        window.location = 'manage/dashboard/'
      } else if (role === 'SUPER_ADMIN') {
        window.location = 'manage/dashboard/'
      }  else if (role === 'ACCOUNTANT') {
        window.location = 'manage/order/summary-order'
      }
    }
  }

  const { payload } = action
  // const { history } = payload
  ///login api
  try {
    const resultLogin = yield call(api_user.handleLogin, payload.user)
    if (resultLogin) {
      // yield put({ type: ADD_USER_INFO, payload: resultLogin.data })
      Cookies.set('user', resultLogin.data.user)
      // Cookies.set('role', resultLogin.data.user?.role?.name || 'owner') //// role == undefined == owner
      Cookies.set('token', resultLogin.data.token.accessToken)
      Cookies.set('refreshToken', resultLogin.data.token.refreshToken)
      const role = getUserRole()
      Cookies.set('role', role)
      const getTempUrl = localStorage.getItem('tempUrl')
      loginPage(getTempUrl)
    }
    // sessionStorage.setItem('token', JSON.stringify(resultLogin.data.token));
  } catch (error) {
    console.log('erorr',error.response)
    yield put({ type: SET_KEY_USER, payload: { key: 'errLogin', value: error.response.data.message } })
  }
}

export function* authenticateUser(action) {
  const userInfo = Cookies.get('token')
  try {
    const getAuthen = yield call(
      api_user.authenticateUser,
      JSON.parse(userInfo),
    )
    if (getAuthen) {
      yield put({ type: SET_AUTH_USER, payload: true })
    }
  } catch (error) {
    // throw error

    yield put({ type: SET_AUTH_USER, payload: false })
  }
}

// yield call(forwardTo, ('/manage/add-product'))
// function forwardTo(location) {
//
//   // history.push({ pathname: location, state: null });
// }

export function* onCreateUserShop(action) {
  const { payload } = action
  yield put({ type: SET_LOADING, payload: { loading: true } })
  try {
    const userShop = yield call(api_user.createUserShop, { email: payload.email.trim(), mobile: payload.mobile, password: payload.password })
    if (userShop.status === 409) {
      switch (userShop.data.message) {
        case 'CODE_ALREADY_EXISTS':
          yield put({
            type: CREATE_USER_SHOP_FIELD,
            payload: {
              shopCode: 'รหัสร้านค้าถูกใช้แล้ว',
            },
          })
          break;

        default: return null
      }
    }
    if (userShop.status === 422) {
      switch (userShop.data.message) {
        case 'EMAIL_EXIST':
          yield put({
            type: CREATE_USER_SHOP_FIELD,
            payload: {
              email: '',
              skipBtn: true,
            },
          })
          break

        case 'MOBILE_EXIST':
          yield put({
            type: CREATE_USER_SHOP_FIELD,
            payload: {
              mobile: 'เบอร์โทรศัพท์นี้ได้ถูกใช้ไปแล้ว กรุณากรอกใหม่อีกครั้ง',
            },
          })
          break

          case 'UPDATE_SHOPCODE_ERROR':
            yield put({
              type: CREATE_USER_SHOP_FIELD,
              payload: {
                shopCode: 'รหัสร้านค้าถูกใช้แล้ว',
              },
            })
            break;

        default:
          break
      }
    } else if (
      userShop.status === 400 &&
      userShop.data.message === 'VALIDATION_ERROR'
    ) {
      yield put({
        type: CREATE_USER_SHOP_FIELD,
        payload: {
          email: 'รูปแบบอีเมลไม่ถูกต้อง',
          skipBtn: false,
        },
      })
    } else {
      yield put({ type: SET_USER_SHOP, payload: userShop })
      yield put({
        type: CREATE_USER_SHOP_FIELD,
        payload: null,
      })
    }
  } catch (err) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw err
  }
  yield put({ type: SET_LOADING, payload: { loading: false } })
}

export function* onSkipCreateUser(action) {
  const { payload } = action
  try {
    const user = yield call(api_user.getUser, payload)
    yield put({ type: SET_USER_SHOP, payload: user.data })
    yield put({ type: CREATE_USER_SHOP_FIELD, payload: null })

  } catch (err) {
    yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    yield put({
      type: CREATE_USER_SHOP_FIELD,
      payload: {
        skipBtn: true,
        password: 'รหัสผ่านผิดพลาด',
      },
    })
  }
}

export default function* useWatcher() {
  // yield takeLatest(FETCH_REQUEST_OTP  , onFetchReqeustOtp)
  yield takeLatest(FETCH_USER_LOGIN, handleLogin)
  yield takeLatest(AUTH_USER, authenticateUser)
  yield takeLatest(CREATE_USER_SHOP, onCreateUserShop)
  yield takeLatest(SKIP_CREATE_USER, onSkipCreateUser)
}
