import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'
import { Body1 } from 'components/FontStyle'

const ModalsLarge = (props) => {
  const {
    title,
    labelButton,
    labelCancel,
    labelResetMedia,
    children,
    closeModal,
    clickCancel,
    clickInput,
    clickTab,
    clickSubmit,
    isOpenModal,
    isShowTabs,
    isShowFooter,
    disabled,
    dataTabs,
    tabActive,
    setVDO,
    setListFile,
  } = props

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(isOpenModal)
  }, [isOpenModal])

  useEffect(() => {
    if (labelCancel) {
    }
  }, [labelCancel])

  const onCloseModal = () => {
    setIsOpen(false)
    closeModal(false)
    setVDO(null)
    setListFile([])
  }

  const onClickChangeVideo = (e) => {
    setListFile([])
    setVDO(null)
    clickInput(e)
  }

  return (
    <Modal className='container-modal-large' isOpen={isOpen}>
      <div className='header'>
        <Body1>{title}</Body1>
        <div className='icon-close-box'>
          <FontAwesomeIcon
            className='icon-close'
            icon={faTimes}
            onClick={onCloseModal}
          />
        </div>
        {isShowTabs && (
          <div className='header-tabs'>
            {dataTabs.map((tab) => {
              return (
                <div
                  key={tab.key}
                  className={classnames('tabs', {
                    active: tab.key === tabActive,
                  })}
                  onClick={() => clickTab(tab.key)}
                >
                  {tab.title}
                </div>
              )
            })}
          </div>
        )}
      </div>

      <ModalBody>{children}</ModalBody>
      {isShowFooter ? (
        <ModalFooter>
          {tabActive === 'browse' && labelResetMedia && ( ///// ตรงนี้ ต้องทำ css ใหม่ เพื่อเลือก vdo
            <div className='btn-reset-media'>
              <button
                className={classnames('btn-box cancel', {
                  disabled: disabled,
                })}
                disabled={disabled}
              >
                {labelResetMedia}
              </button>
              <input
                type='file'
                onChange={onClickChangeVideo}
                className='reset-file'
                accept='video/mp4,video/x-m4v,video/*'
              />
            </div>
          )}
          {labelCancel && (
            <button
              className={classnames('btn-box cancel', { disabled: disabled })}
              disabled={disabled}
              onClick={clickCancel}
            >
              {labelCancel}
            </button>
          )}
          <button
            className={classnames('btn-box', { disabled: disabled })}
            onClick={clickSubmit}
            disabled={disabled}
          >
            {labelButton}
          </button>
        </ModalFooter>
      ) : null}
    </Modal>
  )
}

ModalsLarge.defaultProps = {
  title: '',
  closeModal: () => { },
  clickCancel: () => { },
  clickInput: () => { },
  clickSubmit: () => { },
  clickTab: () => { },
  labelButton: '',
  labelCancel: '',
  labelInput: '',
  isOpenModal: false,
  isShowTabs: false,
  disabled: false,
  isShowFooter: false,
  setVDO: () => { },
  setListFile: () => { },
}

export default ModalsLarge
