import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { hasSubMenu, hasQueryParam, menuItem } from './BreadcrumbMenu'
import './index.scss';
import { Tooltip } from "components/FontStyle";

const BreadCrumb = () => {
  const location = useLocation();

  const checkPathnameIsExist = (pathName) => {
    return menuItem.find((menu) => menu.path === pathName)
  }

  const getPathnameWithOutSlug = (pathName) => {
    const pathWithoutSlug = pathName.reduce((total, current, index) => {
      if (index < pathName.length - 1 && current)
        total = total.concat(`/${current}`);
      return total;
    }, "");
    return menuItem.find((menu) => menu.path === pathWithoutSlug)
  };

  const breadCrumbView = () => {
    const { pathname } = location;
    let pathnames = pathname.split("/").filter((item) => item);

    /* ถ้าเช็คแล้วไม่เจอ เช็คเงื่อนไขของ slug ต่อ*/
    if(!checkPathnameIsExist(pathname)) {
      const pathNameWithOutSlug = getPathnameWithOutSlug(pathnames);
      pathnames = pathNameWithOutSlug ? pathNameWithOutSlug.path.split("/").filter((item) => item) : pathnames;
    }
    
    return (
      <div className='breadcrumb-wrap'>
        <Breadcrumb>

          {/*pathnames.length > 0 ? (
            <Breadcrumb.Item>
              <Link to="/">หน้าหลัก</Link>
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item>หน้าหลัก</Breadcrumb.Item>
          )*/}

          {pathnames.filter(item => item !== 'manage')
                    .map((name, index) => {

            let routeTo = `/${pathnames.slice(0, index + 2).join("/")}`;

            const isLast = index === pathnames.length - 2;
            const menuName = menuItem.find(menu => menu?.path?.toLowerCase() === routeTo?.toLowerCase())


            const subMenuCondition = () => {
              if (!hasSubMenu.includes(name)) {
                if(!menuName) return false;
                return true;
              } else {
                if(!menuName) return false;
                if (pathnames.length === 2) return true;
                else return false
              }
            }
            
            if (index === 1 && hasQueryParam.includes(pathnames[1]) && menuName)
              routeTo = routeTo.concat('/');

            return (
              <React.Fragment key={`breadcrum${index}`}>
                {subMenuCondition() && (
                  <>
                    {isLast ? (
                      <Breadcrumb.Item>
                        <Tooltip className="breadcrumb-url-active">
                          {menuName?.name}
                        </Tooltip>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>
                        <Link to={`${routeTo}`}>
                          <Tooltip className="color-ais-primary-main">
                            {menuName?.name}
                          </Tooltip>
                        </Link>
                      </Breadcrumb.Item>
                    )}
                  </>
                )}
              </React.Fragment>
            );

          })}
        </Breadcrumb>
      </div>
    );
  };

  return <>{breadCrumbView()}</>;
};

export default BreadCrumb;
