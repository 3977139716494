/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import host from 'config/host'
import { ButtonAdd, Modals, ModalLoading } from 'components/output'
// import Chat from 'components/output/Chat'
// import DropdownButton from "components/DropdownButton"
import { TextInput } from 'components/input'
import {
  dispatchPlaylists,
  GET_PLAYLISTS,
  CREATE_PLAYLISTS,
  UPDATE_PLAYLISTS,
  DELETE_PLAYLISTS,
  UPDATE_ORDER_PLAYLISTS,
  GET_LISTKEY
} from 'redux/actions/playlists'

import { ADD_KEY_VALUE_SHOP, 
  // GET_CURRENT_SHOP_DETAIL 
} from 'redux/actions/shop'

import { ReactComponent as IconCamera } from 'images/icons/iconcamera.svg'
import IconTrash from 'images/icons/trash.svg'
import closeImage from 'images/icons/close-image.svg'
import noItem from 'images/icons/status-error.svg'
import edit from 'images/icons/edit.svg'
import 'styles/_playlists.scss'
import DropDownPlaylist from 'components/DropdownPlaylist'
import DropDown from 'components/DropDownList'
// import classNames from 'classnames';
import { H5 } from 'components/FontStyle';
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';

const Playlists = (props) => {
  const { history } = props?.data || {}
  const dispatch = useDispatch();

  const [activeModal, setActiveModal] = useState('none')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [base64, setBase64] = useState(null)
  const [data, setData] = useState(null)
  const [playlistTypes, setPlaylistTypes] = useState([]);
  // const [sortDisplay, setSortDisplay] = useState('playlist')

  const playlistType = useSelector(state => state.playlists.playlistType)
  const playlists = useSelector(state => state.playlists.playlists)
  const shopSelected = useSelector(state => state.shop.shopSelected)
  // const userInfo = useSelector(state => state.user.userInfo)

  const location = useLocation()
  const isPlatFormMode = location.pathname === '/manage/playlists/platform'

  useEffect(() => {

    // User shop login
    // if (userInfo.shopId) {
    //   dispatch({ type: GET_CURRENT_SHOP_DETAIL })
    // } else {
    getPlaylists();
    // }

  }, [])

  useEffect(() => {
    if (shopSelected) {
      getPlaylists();
    }
  }, [shopSelected])

  useEffect(() => {
    if (playlists) {
      setIsLoading(false)
    }
  }, [playlists])

  // convert playlist type from API to dropdown label
  useEffect(() => {
    if (playlistType) {
      const newPlaylistTypes = playlistType
        .map(type =>
          ({ label: type.name.replace('_', ' '), value: type.name }))
      setPlaylistTypes(newPlaylistTypes)
    }
  }, [playlistType])

  const getPlaylists = () => {

    setIsLoading(true)
    dispatch(dispatchPlaylists({ type: GET_PLAYLISTS, payload: { isPlatFormMode } }))
    dispatch(dispatchPlaylists({ type: GET_LISTKEY,payload: {type: isPlatFormMode ? 'platform' : 'shop'} }))
  }

  const handleClearShopSelected = () => {
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopSelected', value: null } });
  }

  const toggleModal = (modal) => {
    if (modal === 'none') {
      setData(null)
      setError(null)
      setBase64(null)
    }
    setActiveModal(modal)
  }

  const handleChangeText = (text) => {
    setData({
      ...data,
      name: text
    })
  }

  const handleChangePlaylistType = (playlistType) => {
    setData({
      ...data,
      playlistType: playlistType
    })
  }

  const handleFileRead = async (event) => {
    const tmpFile = event.target.files[0]
    const tmpBase64 = await convertBase64(tmpFile)

    setData({
      ...data,
      icon: tmpFile,
    })
    setBase64(tmpBase64)
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const uploadImage = async (data) => {
    const path = `${host.apiMedia}/upload`

    const formData = new FormData()
    formData.append('file', data)
    formData.append('serviceKey', host.serviceKey)
    formData.append('secretKey', host.secretKey)

    const response = await axios.post(path, formData)

    if (response.status === 200) {
      return response.data
    } else {
      // handle error

      return null
    }
  }

  const validator = () => {
    let tmpError = {}
    let hasError = true

    if (!data?.name) {
      tmpError.name = 'กรุณาใส่ชื่อเพลย์ลิส'
      hasError = false
    }

    if (!data?.icon) {
      tmpError.icon = 'กรุณาใส่ไอคอนเพลย์ลิส'
      hasError = false
    }

    setError(tmpError)
    return hasError
  }

  const clickSubmit = async () => {

    const isValid = validator()

    if (isValid) {
      setIsLoading(true)

      switch (activeModal) {
        case 'create':
          const image = await uploadImage(data.icon)

          if (image) {
            const path = `image/${image.fileName}`
            let payload = {
              name: data.name,
              title: data.name,
              iconActivePath: path,
              iconInactivePath: path,
              key: data?.playlistType
            }

            if (shopSelected?.id)
              payload.shopId = shopSelected?.id;

            dispatch(
              dispatchPlaylists({
                type: CREATE_PLAYLISTS,
                payload: payload
              }),
            )
          }

          break

        case 'edit':

          let path = ''

          if (data.icon !== data.iconActivePath) {
            const image = await uploadImage(data.icon)

            if (image) {
              path = `image/${image.fileName}`
            }

          } else {
            path = data.iconActivePath
          }

          if (path) {
            dispatch(
              dispatchPlaylists({
                type: UPDATE_PLAYLISTS,
                payload: {
                  playlistsId: data.id,
                  body: {
                    name: data.name,
                    title: data.name,
                    iconActivePath: path,
                    iconInactivePath: path,
                  },
                },
              }),
            )
          }
          break

        default:
          break
      }

      toggleModal('none')
    } else {
    }
  }

  const clearImage = () => {
    setBase64(null)
    setData({
      ...data,
      icon: null
    })
  }

  const onSelectedData = (item) => {
    setData({
      ...item,
      playlistType: item.key,
      icon: item.iconActivePath,
    })
  }

  const onDeletePlaylist = (playlistsId) => {
    dispatch(dispatchModalConfirm({
      type: OPEN_MODAL_CONFIRM,
      payload: {
        isOpen: true,
        message: 'คุณแน่ใจหรือไม่ที่จะลบข้อมูลออกจากเพลย์ลิสต์',
        btnConfirm: 'ยืนยัน',
        btnCancel: 'ยกเลิก',
        confirm: () => {
          dispatch(
            dispatchPlaylists({
              type: DELETE_PLAYLISTS,
              payload: playlistsId,
            }),
          )
        },
        isClose: () => {
          dispatch(dispatchModalConfirm({
            type: OPEN_MODAL_CONFIRM,
            payload: {
              isOpen: false
            }
          }))
        }
      }
    }))
    // setIsLoading(true)
    // dispatch(
    //   dispatchPlaylists({
    //     type: DELETE_PLAYLISTS,
    //     payload: playlistsId,
    //   }),
    // )
  }

  const handleChangePosition = (playlist, order) => {
    dispatch(
      dispatchPlaylists({
        type: UPDATE_ORDER_PLAYLISTS,
        payload: {
          order: order,
          playlistsId: playlist.id
        },
      }),
    )

    setIsLoading(true)
  }

  // const onFetchShop = () => {
  //   // fetchshop
  // }

  // const onChangeShop = (value) => {
  //   // console.log(value)
  // }

  return (
    <div className='playlists'>
      <div className='playlists-content'>

        <div className='d-flex justify-content-between'>
          <div> {
            shopSelected && !isPlatFormMode &&
            <div className='d-flex justify-content-start align-items-end'>
              <div className='pr-4'>
                <img style={{ height: 40 }}
                  src={`${shopSelected.logoPath?.host}${shopSelected.logoPath?.path}`} alt='logo-shop'/>
              </div>
              <div className='pl-4' style={{ borderLeft: 'solid black 2px' }}>
                <span>{shopSelected.name}</span>
              </div>
            </div>
          }
          </div>
          <div className='d-flex justify-content-end align-items-center'>
            {/* {!userInfo.shopId && !isPlatFormMode && */}
            {!isPlatFormMode &&
              <div className='btn-info mr-2'
                style={{ cursor: 'pointer' }}
                onClick={handleClearShopSelected}> เลือกร้านค้า
              </div>
            }
            {!isPlatFormMode && <ButtonAdd
              label='เพิ่มคูปองลงเพลย์ลิสต์'
              handleAdd={() => toggleModal('create')}
            />}
          </div>
        </div>

        {isPlatFormMode &&
          <div className='d-flex justify-content-between container-header-page'>
            <H5>
              คูปองส่วนลด
            </H5>
            <ButtonAdd
              label='เพิ่มคูปองลงเพลย์ลิสต์'
              handleAdd={() => toggleModal('create')}
            />
          </div>
        }

        {/* {isPlatFormMode &&
          <div className='playlist-format-filter'>
            <span>รูปแบบการแสดงผลในหน้าเว็บ</span>
            {sortDisplayPlaylist.map((list, listIndex) => (
              <React.Fragment key={`sortFilter${listIndex}`}>
                <li
                  className={classNames({ 'active': sortDisplay === list.value && shopSelected !== 0, 'pe-none': shopSelected === 0 })}
                  onClick={() => this.handleSelectSortDisplay(list.value)}>
                  {list.label}
                </li>
              </React.Fragment>
            ))}
          </div>} */}

        <Table
          data={playlists}
          toggleModal={toggleModal}
          history={history}
          isPlatFormMode={isPlatFormMode}
          setSelectedData={onSelectedData}
          onDelete={onDeletePlaylist}
          handleChangePosition={handleChangePosition}
        />
        <Modals
          title={`${activeModal === 'create' ? 'สร้าง' : 'แก้ไข'}เพลย์ลิสต์`}
          closeModal={() => toggleModal('none')}
          clickButton={clickSubmit}
          labelButton='ตกลง'
          isOpenModal={activeModal === 'create' || activeModal === 'edit'}
        >

          <div className='upload-playlist-box mb-3'>
            <div className='upload-playlist'>
              {base64 || data?.icon ? (
                // <img alt='' src={host.avatar + iconPlaylists} />
                <div className='d-flex'>
                  <span className='image-playlist-container'>
                    <img
                      src={base64 ? base64 : host.image + data.icon}
                      alt=''
                      className='image-playlist'
                      style={{ width: '100%' }}
                    />
                    <div className='middle' onClick={clearImage}>
                      <img alt='' src={closeImage} className='clear-image' />
                    </div>
                  </span>
                </div>
              ) : (
                <div
                  className='button-upload-playlist-box'
                  style={{ width: '12em', height: '12em' }}
                >
                  <input
                    type='file'
                    className='file-upload'
                    onChange={handleFileRead}
                  />
                  <div className='icon-camera'>
                    <IconCamera />
                  </div>
                  <div className='title'>เพิ่มไอคอนเพลย์ลิส</div>
                </div>
              )}
              {error && error.icon ? (
                <div className='text-left'>
                  <span style={{ color: 'red' }}>{error.icon}</span>
                </div>
              ) : null}
            </div>
          </div>
          <TextInput
            label='ชื่อเพลย์ลิสต์'
            placeholder='ชื่อเพลย์ลิสต์'
            handleChangeText={handleChangeText}
            defaultValue={data?.name}
            error={error && error.name}
            require
          />
          <div className="mb-2"></div>
          <DropDown
            onChange={(data) => handleChangePlaylistType(data.value)}
            options={playlistTypes}
            placeholder={'ประเภทเพลย์ลิสต์'}
            value={data?.playlistType}
            className={`dropdownlist`}
            disabled={activeModal === 'edit'}
          ></DropDown>
        </Modals>
        {isLoading && <ModalLoading isOpenModal={isLoading} />}
      </div>
    </div>
  )
}

const Table = (props) => {
  const { data, toggleModal, history, setSelectedData, onDelete, handleChangePosition, isPlatFormMode } = props
  const optionPos = [...Array(data?.length).keys()].map(pos => data ? ({ value: data[pos]?.order, name: `${pos + 1}` }) : null)

  const getPlaylistsItemName = (type) => {
    if (type) {
      if (type.includes('_')) {
        return type.replace('_', '-')
      } else {
        return type;
      }
    } else {
      return '';
    }
  }

  return data && data.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-items-center mt-4 h-50vh">
      <img src={noItem} alt="noItem" style={{ width: 40 }} />
      <span className="mt-2 font-secondary">ไม่มีรายการ</span>
    </div>
  ) : (
    <div className="playlists-list">
      <div className="table-header">
        <div className="row align-items-baseline">
          <div className="col-2 text-center">ลำดับ</div>
          <div className="col-2 text-center">ประเภท</div>
          <div className="col">ชื่อเพลย์ลิสต์</div>
        </div>
      </div>
      <div className="table-body">
        {data &&
          data.map((item, index) => (
            <div key={index}>
              <div className="row align-items-center">
                <div className="col-2 d-flex justify-content-center">
                  {/* {index + 1} */}
                  <div className="w-75">
                    <DropDownPlaylist
                      options={optionPos}
                      defaultValue={optionPos[index].value || ""}
                      handleSelected={(orderNumber) => handleChangePosition(item, orderNumber)}
                    />
                  </div>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  {item.key}
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                  className="col-1 d-flex"
                  onClick={() => {
                    history.push(`/manage/playlists/${isPlatFormMode ? 'platform' : 'mall'}/${getPlaylistsItemName(item.key)}-items`, {
                      playlistsId: item.id,
                      playlistsName: item.name,
                      playlistsType: item.key,
                      isPlatFormMode:isPlatFormMode
                    })
                  }
                  }
                >
                  <span className="image-playlist-container">
                    <img
                      src={host.image + item.iconActivePath}
                      alt=""
                      className="item-playlist"
                      style={{ width: "100%" }}
                    />
                  </span>
                </div>
                <div
                  className="col-5 d-flex align-items-baseline cursor-pointer"
                  onClick={() =>
                    history.push(`/manage/playlists/${isPlatFormMode ? 'platform' : 'mall'}/${getPlaylistsItemName(item.key)}-items`, {
                      playlistsId: item.id,
                      playlistsName: item.name,
                      playlistsType: item.key,
                      playlistsPlatform: item,
                      sortType: item.sortType,
                      isPlatFormMode:isPlatFormMode
                    })
                  }
                >
                  {item.name}
                </div>
                <div
                  className="d-flex align-items-baseline"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedData(item);
                    toggleModal("edit");
                  }}
                >
                  <img src={edit} alt="edit" className="icon-link" />
                </div>
                <div
                  className="d-flex"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(item.id);
                  }}
                >
                  <img src={IconTrash} alt="delete" className="icon-link" />
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* <Chat /> */}
    </div>
  )
}

const mapStateToProps = (state) => ({
  playlists: state.playlists.playlists,
  playlistType: state.playlists.playlistType
})

export default connect(mapStateToProps, null)(Playlists)
