/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from 'reactstrap';
import classnames from 'classnames'
import closeIcon from '../../images/icons/iconClose.svg'
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'components/pagination'
import { InputSearch } from 'components/output';
import SelectInput from 'components/SelectInput';
import iconNoItem from 'images/icons/no-item.svg'
import { ReactComponent as IconDownload } from 'images/icons/IconDownload.svg'
import XlsxPopulate from 'xlsx-populate';

import {
    SELECTED_PRODUCT_PROMOCODE,
    SET_KEY_VALUE_PROMOCODE,
} from 'redux/actions/promocode';
import {
    ADD_KEY_VALUE_PRODUCT,
    HANDLE_SEARCH_PRODUCT,
    GET_LIST_PRODUCT_PROMOCODE,
    SET_ALL_PRODUCT,
    RESET_EDIT_PRODUCT,
    SET_KEY_PRODUCT
} from 'redux/actions/product';
import {
    UNCHECK_ALL_PRODUCT_PROMOCODE,
} from 'redux/actions/checkedProduct'
import { SET_LOADING } from 'redux/actions/app';
import CardShopProduct from './cardShopProduct';
import EditProductRadio from 'components/ModalListShopProductPromocode/editProductRadio';
import productProvider from 'provider/productProvider';
import { displayDateThShortYear } from 'helper/timeUtil';
import { OPEN_MODAL_ALERT } from 'redux/actions/modalAlert';


const listSort = [
    { value: "name", label: "เรียงจากชื่อ" },
    { value: "createdAt", label: "อัปโหลดสินค้าล่าสุด" }
]

const ModalEditProductExcel = (props) => {
    const { isOpen } = props
    const [search, setSearch] = useState('')
    const [statusSearch, setStatusSearch] = useState(false)
    const [historySearch, setHistorySearch] = useState([])
    const [sortBy, setSortBy] = useState(0)
    // const [isDisableBtn, setIsDisableBtn] = useState(true)
    const promocodeState = useSelector(state => state.promocode)
    const stateProduct = useSelector(state => state.product);
    const [totalProduct, setTotalProduct] = useState(null)
    const { product, limit, page, total, pageSizeOptions, valueSearch, editProduct, editProductProductCount, editProductSkuCount, editProductIsAllProduct } = stateProduct;
    const dispatch = useDispatch()

    useEffect(() => {
        if (totalProduct === null && editProductSkuCount > 0) {
            setTotalProduct({ editProductProductCount, editProductSkuCount })
        }
        return () => {
        }
    }, [editProductProductCount, editProductSkuCount])

    useEffect(() => {
        // setAllProduct('all')
        if (isOpen) {
            setAllProduct('all')
        }
        return () => {
        }
    }, [isOpen])

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: e.target.value } })
    }

    const onSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            if (search.trim().length === 0) {
                onClearSearch()
            }
        }
    }
    const onClearSearch = useCallback(() => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
        setSearch('')
        setStatusSearch(false)
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } })
        // if (search.trim().length > 0) {
        dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { id: promocodeState.shopSelected } })
        // }
    })

    const onClickSeachProduct = () => {
        if (search.trim().length === 0) {
            setStatusSearch(false)
            setHistorySearch(search)
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } })
            // dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { id: promocodeState.shopSelected } })

        } else {
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: true } })
            setStatusSearch(true)
            setHistorySearch(search)
            dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { id: promocodeState.shopSelected } })

        }
    }

    const clearModal = useCallback(() => {
        dispatch({ type: SET_LOADING, payload: { loading: false } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: 0 } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'product', value: {} } })
        dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'listCheckedProduct', value: {} } })
        dispatch({ type: UNCHECK_ALL_PRODUCT_PROMOCODE })
    })

    const onChangePage = (page) => {
        if (statusSearch) {
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: page } })
            dispatch({ type: HANDLE_SEARCH_PRODUCT, payload: { search: historySearch } })
        } else {
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: page } })
            dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { id: promocodeState.shopSelected } })
        }
    }
    const onShowSizeChange = (current, pageSize) => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'limit', value: pageSize } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: current } })
    }
    const handleSelectSort = (value) => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'sort', value: listSort[value].value } })
        setSortBy(value)
        dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { id: promocodeState.shopSelected } })
    }

    const closeModal = () => {
        dispatch({ type: RESET_EDIT_PRODUCT })
        resetCountProductSkus()
        onClearSearch()
        props.toggle(false)
    }

    const confirmModal = () => {
        dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'isShowModalEditProductExcel', value: false } })
        dispatch({ type: SELECTED_PRODUCT_PROMOCODE })
    }

    const resetCountProductSkus = () => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'editProductProductCount', value: 0 } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'editProductSkuCount', value: 0 } })
    }

    const handleUnCheckAllProductCheckShop = (shopId) => {
        dispatch({ type: SET_KEY_PRODUCT, payload: { key: 'editProduct', value: {} } })
        if (stateProduct?.editProductIsAllProduct === 'some') {
            resetCountProductSkus()
        }
        // dispatch({type: RESET_EDIT_PRODUCT})
        // if(isAllProduct)
        // dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'editProductProductCount', value: totalProduct.editProductProductCount } })
        // dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'editProductSkuCount', value: totalProduct.editProductSkuCount } })

    }

    // const handleUpdateTypeProductSelectionPromocode = (type) => {
    // console.log('type', type)
    // dispatch({
    //     type: UPDATE_TYPE_PRODUCT_SELECTION_PROMOCODE,
    //     payload:
    //     {
    //         isAllProduct: type === 'all' ? true : false,
    //         shopId: `${promocodeState?.shopSelected}`,
    //     }
    // })
    // }

    const objSku = (productSkus) => {
        const sku = {}
        if (typeof productSkus === 'object') {
            for (const [key, value] of Object.entries(productSkus)) {
                Object.assign(sku, { [key]: true })
            }
            return sku
        } else {
            return {}
        }
    }

    useEffect(() => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'sort', value: listSort[0].value } })
        dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { type: 'editProduct' } })
    }, [])

    const setAllProduct = (e) => {
        if (e === 'all') {
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'editProductProductCount', value: totalProduct.editProductProductCount } })
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'editProductSkuCount', value: totalProduct.editProductSkuCount } })
        } else {
            dispatch({ type: RESET_EDIT_PRODUCT })
            resetCountProductSkus()
        }
        dispatch({ type: SET_ALL_PRODUCT, payload: e })
    }

    const formatUpdateProduct = async (data) => {
        const skus = [];
        for (const productId in data) {
            if (Object.hasOwnProperty.call(data, productId)) {
                const product = data[productId];
                const productSkus = product.productSkus;
                for (const skuId in productSkus) {
                    if (Object.hasOwnProperty.call(productSkus, skuId)) {
                        const sku = productSkus[skuId];
                        skus.push(sku);
                    }
                }
            }
        }
        return skus
    }

    const handleDownloadExcel = async () => {
        try {
            if (editProductIsAllProduct === 'some') {
                if (Object.keys(editProduct).length === 0) {
                    alert('กรุณาเลือกสินค้า')
                    return
                }
                const skus = await formatUpdateProduct(editProduct)
                await generateExcelFile(skus)
            } else {
                const api_shop = new productProvider()
                // const limitPerPage = 100;
                const res = await api_shop.getSearchProducts({ shopId: null, page: 1, limit: 9999 });
                const aa = await formatUpdateProduct(res.product)
                while (aa.length > 0) {
                    const currentChars = aa.splice(0, 100);
                    await generateExcelFile(currentChars)
                }
            }
            dispatch({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'ทำรายการสำเร็จ', type: 'success' } })
        } catch (error) {
            dispatch({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'ทำรายการไม่สำเร็จ', type: 'alert' } })
        } finally {
            closeModal()
        }

    }

    function removeTags(htmlString) {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        return doc.body.textContent || "";
      }

    //   function hasTags(text) {
    //     return /<[a-z][\s\S]*>/i.test(text);
    //   }

    const generateExcelFile = async (skus) => {
        try {
            const { RichText } = XlsxPopulate
        const wb = await XlsxPopulate.fromBlankAsync();
        const sheet = wb.sheet(0);

        const header1 = sheet.range('A1:H2');
        header1.merged(true);
        header1.value("หากมีแบรนด์ใหม่ กรุณาสร้างแบรนด์ใน CMS ก่อนอัปโหลดสินค้าผ่านไฟล์ excel");
        header1.style({
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            wrapText: true,
            fill: { type: 'solid', color: 'ff0000' },
            fontColor: 'FFFFFF'
        });
        sheet.row(1).height(20);
        sheet.row(3).height(40);
        sheet.row(4).height(67);
        sheet.row(5).height(99);
        const header2 = sheet.range('I1:M2');
        header2.merged(true)
        header2.value("หากมีแบรนด์ใหม่ กรุณาสร้างแบรนด์ใน CMS ก่อนอัปโหลดสินค้าผ่านไฟล์ excel");
        header2.style({
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            wrapText: true,
            fill: { type: 'solid', color: 'ff0000' },
            fontColor: 'FFFFFF'
        });

        const header3 = sheet.range('A3:H3');
        header3.merged(true)
        header3.value("ห้ามเพิ่มหรือแก้ไขตารางเทมเพลตนี้ กรุณากรอกข้อมูลตั้งแต่บรรทัดที่ 6 ลงไป");
        header3.style({
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            wrapText: true,
            fill: { type: 'solid', color: 'ff9900' },
            fontColor: 'FFFFFF'
        });
        const header4 = sheet.range('I3:M3');
        header4.merged(true)
        header4.value("ห้ามเพิ่มหรือแก้ไขตารางเทมเพลตนี้ กรุณากรอกข้อมูลตั้งแต่บรรทัดที่ 6 ลงไป");
        header4.style({
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            wrapText: true,
            fill: { type: 'solid', color: 'ff9900' },
            fontColor: 'FFFFFF'
        });

        const data = ['Seller SKU', 'ชื่อสินค้า', 'รายละเอียดสินค้า', 'เลขอ้างอิง SKU', 'รหัสบาร์โค้ด', 'ราคาปกติ', 'ราคาที่ประกาศขาย', 'สต็อก', 'น้ำหนัก (กก)', 'ความยาว (ซม)', 'ความกว้าง (ซม.)', 'ความสูง (ซม.)', 'ระยะเวลาเตรียมการจัดส่ง (ชม.)']
        const maxStringLength = data.reduce((max, value) => {
            if (value === undefined) return max;
            return Math.max(max, value.toString().length);
        }, 0);
        data.forEach((item, index) => {
            sheet.cell(4, index + 1).value(item);
            sheet.column(index + 1).width(maxStringLength);
            sheet.column(index + 1).style({
                horizontalAlignment: 'center',
                verticalAlignment: 'center',
                wrapText: true,
            })
            sheet.cell(4, index + 1).style({
                fill: { type: 'solid', color: '000000' },
                fontColor: 'FFFFFF'
            })
        });
        const data2 = ['Seller SKU\n*ห้ามแก้ไข*', 'ชื่อสินค้า (จำกัด 200 ตัวอักษร)\n*จำเป็นต้องกรอก*',
            'รายละเอียดของสินค้า (ไม่เกิน 5000 ตัวอักษร)\n*จำเป็นต้องกรอก*',
            'ตัวอักษร a-z หรือ 0-9 ไม่เกิน 100 ตัวอักษร แต่ละ SKU ห้ามกรอกข้อมูลซ้ำกัน\n*จำเป็นต้องกรอก*',
            'กรอกรหัสบาร์โค้ดของสินค้า กรอกเฉพาะตัวเลขเท่านั้น\n*ไม่จำเป็นต้องกรอก*',
            'ราคาตั้งต้นก่อนลดราคา\n*จำเป็นต้องกรอก*',
            'ราคาที่ลูกค้าจะซื้อจริง\n*จำเป็นต้องกรอก*',
            'จำนวนสินค้าพร้อมขาย\n*จำเป็นต้องกรอก*',
            'ระบุน้ำหนักของสินค้า\n*จำเป็นต้องกรอก*',
            'ระบุความยาวของกล่องพัสดุ\n*ไม่จำเป็นต้องกรอก*',
            'ระบุความกว้างของกล่องพัสดุ\n*ไม่จำเป็นต้องกรอก*',
            'ระบุความสูงของกล่องพัสดุ\n*ไม่จำเป็นต้องกรอก*',
            'ระบุระยะเวลาเตรียมการจัดส่งโดยประมาณโดยไม่รวมกับเวลาที่ขนส่ง\n*จำเป็นต้องกรอก*']
        data2.forEach((item, index) => {
            const richtext = sheet.cell(5, index + 1).value(new RichText());
            const parts = item.split('\n'); // Splitting text before and after '\n'
            const textBeforeNewline = parts[0];
            const textAfterNewline = parts[1];
            const notReq = '*ไม่จำเป็นต้องกรอก*'
            // const req = '*จำเป็นต้องกรอก*' || "*ห้ามแก้ไข*"
            richtext.value()
                .add(textBeforeNewline, { italic: true, bold: true })
                .add('\n')
                .add(textAfterNewline, { fontColor: textAfterNewline === notReq ? '666666' : 'FF0000' });
            sheet.column(index + 1).width(maxStringLength);
            sheet.column(index + 1).style({
                horizontalAlignment: 'center',
                verticalAlignment: 'center',
                wrapText: true,
                border: true
            })

            sheet.cell(5, index + 1).style({
                fill: { type: 'solid', color: 'bdbdbd' },
                fontColor: '000000'
            })

        });
        sheet.cell('A5').style({
            fill: { type: 'solid', color: 'fff2cc' },
            fontColor: '000000'
        })
        skus.forEach((item, index) => {
            const data = [item.skuCode, item.productName, removeTags(item.description[0].content), item.sellerSKU, item.barcode, item.priceList, item.priceNet, item.stockRemain, item.weight, item.size.length, item.size.width, item.size.height, item.prepareDay]
            data.forEach((sku, idx) => {
                sheet.cell(6 + index, idx + 1).value(sku);
                sheet.column(idx + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    wrapText: true,
                })
            })

        });
        sheet.range('A1:C3').style({ border: true });
        sheet.freezePanes(0, 5);
        sheet.column("E").style("numberFormat", "0");
        sheet.column("F").style("numberFormat", "#,0.00");
        sheet.column("G").style("numberFormat", "#,0.00");
        sheet.column("H").style("numberFormat", "#,0");
        sheet.column("I").style("numberFormat", "#,0.00");
        sheet.column("J").style("numberFormat", "#,0.00");
        sheet.column("K").style("numberFormat", "#,0.00");
        sheet.column("L").style("numberFormat", "#,0.00");
        sheet.column("M").style("numberFormat", "0");

        const buffer = await wb.outputAsync();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `V-avenue_mass_Edit_${displayDateThShortYear()}_template.xlsx`);

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        } catch (error) {
            throw error
        }
    };


    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                // isOpen={true}
                toggle={closeModal}
                modalClassName="modal-edit-product-excel"
                centered={true}
            >
                <ModalHeader style={{ padding: '0.5rem 24px', border: 'none' }}>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <div>
                            <span className="title">{'เลือกร้านค้า และสินค้า'}</span>
                            <span className="sub">(เลือกแล้ว <span className="sub-txt">{stateProduct?.editProductProductCount}</span> สินค้า <span className="sub-txt">{stateProduct?.editProductSkuCount}</span> SKU)</span>
                        </div>
                    </div>

                </ModalHeader>
                <ModalBody>
                    <div className="body-content m-auto">
                    <div className="header-detail">
                                    <span>ข้อมูลที่สามารถแก้ไขได้ :  ชื่อสินค้า, รายละเอียดสินค้า, เลขอ้างอิงSKU , รหัสบาร์โค้ด, ราคา, สต๊อก, ขนาดของสินค้า, เวลาเตรียมการจัดส่ง</span>
                                </div>
                        <div className='cover-title-modal-select-product'>
                     
                            <div className='container-right'>
                                <span className='text-title'>เลือกสินค้าภายในร้าน</span>
                            </div>
                        </div>
                        <div className="container-shop-product">
                            <div className='list-product-container'>
                                <EditProductRadio
                                    callbackData={(e) => setAllProduct(e)}
                                    type={stateProduct?.editProductIsAllProduct}
                                />
                                <div className="d-flex justify-content-between align-items-center w-100" style={{ gap: '10px' }}>
                                    <InputSearch
                                        placeholder={"ค้นหาจากชื่อสินค้า, ประเภทสินค้า, แบรนด์, ตัวเลือกสินค้า, เลขอ้างอิง SKU"}
                                        onChange={onChangeSearch}
                                        onKeyPress={onSearchKeyPress}
                                        handleSearch={onClickSeachProduct}
                                        onClearSearch={onClearSearch}
                                        statusSearch={statusSearch}
                                        // defaultValue={valueSearch}
                                        defaultValue={valueSearch}
                                    />


                                    <div className='dropdown'>
                                        <SelectInput
                                            options={listSort}
                                            placeholder=''
                                            onChange={(value) => { handleSelectSort(value) }}
                                            value={sortBy}
                                            iconClearSearch={false}
                                            width={'12.5rem'}
                                        />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center w-100 py-2 mt-2">
                                    <span className="text-teriary">ทั้งหมด {total}  สินค้า</span>
                                    <button
                                        className={classnames("btn-submit", { "disable-btn": Object.keys(editProduct).length === 0 })}
                                        disabled={Object.keys(editProduct).length === 0}
                                        style={{ padding: '0px', minWidth: '158px', borderRadius: '5px' }}
                                        onClick={() => handleUnCheckAllProductCheckShop(promocodeState.shopSelected)}>
                                        <img src={closeIcon} alt="icon-close" className='icon-close' style={{ paddingRight: '6px' }} />{'ยกเลิกที่เลือกทั้งหมด'}
                                    </button>
                                </div>


                                {(total === 0 || Object.values(product)?.length === 0) ?
                                    (
                                        <div className="no-list-card">
                                            <div><img src={iconNoItem} alt="noitem" /></div>
                                            <div className="font-weight-bold" >ไม่มีรายการ</div>
                                        </div>

                                    ) : (
                                        <div className="list-card">
                                            {
                                                Object.keys(product)?.map((key) => {
                                                    const item = product[key]
                                                    return <CardShopProduct
                                                        key={`card-product-${key}`}
                                                        objSku={objSku}
                                                        product={item}
                                                        shopId={` ${promocodeState.shopSelected}`}
                                                        isAllProduct={stateProduct?.editProductIsAllProduct === 'all' ? true : false}
                                                    />
                                                })}
                                        </div>
                                    )}

                                <div className="cover-pagination-select-product">
                                    <Pagination
                                        total={total}
                                        defaultCurrent={1}
                                        onChange={onChangePage}
                                        pageSize={limit}
                                        pageSizeOptions={pageSizeOptions}
                                        onShowSizeChange={onShowSizeChange}
                                        current={page}
                                        showQuickJumper={true}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="footer">
                        {/* <button onClick={generateExcelFile}>Generate Excel</button> */}
                        <span><button className="btn-cancel" onClick={closeModal}>{'ยกเลิก'}</button></span>
                        <span><button className="btn-submit" onClick={() => handleDownloadExcel()}><IconDownload /> ดาวน์โหลดแบบฟอร์ม</button></span>
                    </div>

                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default ModalEditProductExcel