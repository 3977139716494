export const ADD_KEY_VALUE_CHECKED_PRODUCT = "ADD_KEY_VALUE_CHECKED_PRODUCT"
export const CHECK_PRODUCT_PROMOCODE = "CHECK_PRODUCT_PROMOCODE"
export const UNCHECK_PRODUCT_PROMOCODE = "UNCHECK_PRODUCT_PROMOCODE"
export const UNCHECK_ALL_PRODUCT_PROMOCODE = "UNCHECK_ALL_PRODUCT_PROMOCODE"

export function dispatchCheckedProduct(param){
    return {
      type: param.type,
      payload: param.payload,
    };
  }