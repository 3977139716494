import React from 'react'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'
import host from '../../../config/host'
import logoimage from '../../../images/icons/logo-v-avenue-media.png'

const AlbumCard = (props) => {
  const { data, onClickIconEdit, isEditName, clickAlbum } = props

  return (
    <div className='container-album-card'>
      <div className={data.name === "ribbon (System)" || data.name === "frame (System)" ? 'cover-box-system' : 'cover-box'}>
        <div className={data.name === "ribbon (System)" || data.name === "frame (System)" ? 'cover-system' : 'cover'}>
          <div className='img-box' onClick={clickAlbum}>
            {
              data.thumbnail?.split('/')[0] === 'vdo' ? 
                <div className='container-browse-video'>
                  <video controls>
                    <source src={data.thumbnail} type='video/mp4' />
                  </video>
                </div>
                :
                <img
                  alt=''
                  className='img-album'
                  src={
                    data.thumbnail
                    ? `${host.avatar}${data.thumbnail}`
                    : data.path
                    ? `${host.avatar}${data.path}`
                    : logoimage
                  }
                />
            }
          </div>
          <DetailAlbum
            detail={data}
            onClickIconEdit={onClickIconEdit}
            isEditName={isEditName}
          />
        </div>
      </div>
    </div>
  )
}

const DetailAlbum = (props) => {
  const {
    detail: { files, name },
    onClickIconEdit,
    isEditName,
  } = props

  return (
    <div className='detail-box'>
      <div className='group-header'>
        <div className='album-name ellipsis'>
          {name === 'default' ? 'อัลบั้มหลัก' : name}
        </div>
        <div className='count-file'>{`${files} ไฟล์`}</div>
      </div>
      {name !== 'default' && name !== "ribbon (System)" && name !== "frame (System)"
        ? isEditName && (
            <FontAwesomeIcon
              icon={faPen}
              className='icon-edit'
              onClick={onClickIconEdit}
            />
          )
        : null}
    </div>
  )
}

AlbumCard.defaultProps = {
  isEditName: false,
  clickAlbum: () => {}
}

export default AlbumCard
