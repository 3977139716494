import axios from '../config/axios'
import host from '../config/host'

class PlaylistsProvider {
  getPlaylists = async (body) => {
    try {

      const url = Object.keys(body).length > 0 ?
        `${host.api}/playlists/cms?shopId=${body.shopId}` :
        `${host.api}/playlists/cms`

      const res = await axios.get(url)
      return res.data
    } catch (error) { }
  }

  getPlaylistById = async (playlistId) => {
    try {
      const url = `${host.api}/playlists/${playlistId}`
      const res = await axios.get(url)
      console.log(`res`, res)
      return res
    } catch (error) {
      throw error
    }
  }

  createPlaylists = async (body) => {
    try {
      const url = `${host.api}/playlists`
      const res = await axios.post(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  updatePlaylists = async ({ body, playlistsId }) => {
    try {
      const url = `${host.api}/playlists/${playlistsId}`
      const res = await axios.patch(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  deletePlaylists = async (playlistsId) => {
    try {
      const url = `${host.api}/playlists/${playlistsId}`
      const res = await axios.delete(url)
      return res.data
    } catch (error) { }
  }

  createPlaylistItem = async (body) => {
    try {
      const url = `${host.api}/playlist-items`
      const res = await axios.post(url, body)
      return res.data
    } catch (error) { }
  }

  getPlaylistItemsByPlaylistId = async (query) => {
    try {
      const url = `${host.api}/playlist-items/playlist/${query.playlistsId}?limit=${query.limit}&page=${query.page}`
      const res = await axios.get(url)
      return res.data
    } catch (error) { }
  }

  deletePlaylistsItem = async (playlistsItemId) => {
    try {
      const url = `${host.api}/playlist-items/${playlistsItemId}`
      const res = await axios.delete(url)
      return res.data
    } catch (error) { }
  }

  shufflePlaylistsItem = async (playlistsId) => {
    try {
      const url = `${host.api}/playlist-items/shuffle/${playlistsId}`
      const res = await axios.get(url)
      return res.data
    } catch (error) {
      return error
    }
  }

  pinPlaylistsItem = async (params) => {
    try {
      const url = `${host.api}/playlist-items/${params.playlistsItemId}`
      const res = await axios.patch(url, { pinDate: params.pinDate })
      return res.data
    } catch (error) {
      return error
    }
  }

  // searchMediaByName = async(payload) => {
  //     try {
  //         const url = `${host.api}/medias/search?name=${payload.name}&albumId=${payload.albumId}`
  //         const res = await axios.get(url)
  //         return res.data
  //     } catch (error) {

  //     }
  // }

  // onFecthAlbumDefaultByShopId = async(shopId) => {
  //     try {
  //         const url = `${host.api}/albums/getDefaultAlbum/${shopId}`
  //         const res = await axios.get(url)
  //         return res.data
  //     } catch (error) {

  //     }
  // }

  updateOrderPlaylists = async (playlistsId, body) => {
    try {
      const url = `${host.api}/playlists/order/${playlistsId}`
      const res = await axios.patch(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  updateOrderPlaylistsItems = async (playlistItemId, body) => {
    try {
      const url = `${host.api}/playlist-items/order/${playlistItemId}`
      const res = await axios.patch(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  getPlaylistShop = async (playlistId) => {
    try {
      const url = `${host.api}/playlist-shops/playlist/${playlistId}/shop`
      const res = await axios.get(url)
      return res.data
    } catch (error) {
      return error
    }
  }

  getPlaylistShopCoupon = async (playlistId) => {
    try {
      const url = `${host.api}/playlist-shops/playlist/${playlistId}/coupon`
      const res = await axios.get(url)
      return res.data
    } catch (error) {
      return error
    }
  }

  updatePlaylistShopOrder = async (playlistShopId, body) => {
    try {
      const url = `${host.api}/playlist-shops/${playlistShopId}/order`
      const res = await axios.patch(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  addShopToPlayList = async (body) => {
    try {
      const url = `${host.api}/playlist-shops`
      const res = await axios.post(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  deleteShopFromPlayList = async (playlistShopId) => {
    try {
      const url = `${host.api}/playlist-shops/${playlistShopId}`
      const res = await axios.delete(url)
      return res.data
    } catch (error) {
      return error
    }
  }

  getListKey = async (params) => {
    try {
      const url = `${host.api}/playlists/listKey`
      let param = new URL(url)
      if (params) {
        for (const [key, value] of Object.entries(params)) {
          param.searchParams.set(key, value);
        }
      }
      const res = await axios.get(url + param.search)
      return res.data
    } catch (error) {
      return error
    }
  }

  deletePlaylistCategory = async (playlistCategoriesId) => {
    try {
      const url = `${host.api}/playlist-categories/${playlistCategoriesId}`
      const res = await axios.delete(url)
      return res.data
    } catch (error) {
      return error
    }
  }

  addPlaylistCategories = async (body) => {
    try {
      const url = `${host.api}/playlist-categories`
      const res = await axios.post(url, body)
      return res
    } catch (error) {
      return error
    }
  }

  updateCategoryPlaylist = async (playlistCategoriesId, body) => {
    // body = { order: 1 }
    try {
      const url = `${host.api}/playlist-categories/${playlistCategoriesId}/order`
      const res = await axios.patch(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  getPlaylistCategories = async (playlistId) => {
    try {
      const url = `${host.api}/playlist-categories/playlist/${playlistId}`
      const res = await axios.get(url)
      return res.data
    } catch (error) {
      return error
    }
  }

  getPlaylistCategoriesMall = async (params) => {
    const url = `${host.api}/categories/shop/${params.shopId}`
    delete params.shopId
    let param = new URL(url)
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        param.searchParams.set(key, value);
      }
    }
    try {
      // const url = `${host.api}/categories/all`
      const res = axios.get(url + param.search)
      return res
    } catch (e) {
      throw e
    }
    // try {
    //   const url = `${host.api}/categories/shop/${param.shopId}`
    //   const res = await axios.get(url)
    //   return res.data
    // } catch (e) {
    //   throw e
    // }
  }

  getPlaylistCategoriesByPlaylistId = async (playlistId) => {
    try {
      // const url = `${host.api}/playlist-categories/playlist/${playlistId}/categories`
      const url = `${host.api}/playlist-categories/playlist/${playlistId}`
      const res = await axios.get(url)
      return res.data
    } catch (e) {
      throw e
    }
  }

  deleteCategoriesPlaylist = async (playlistId, param) => {
    try {
      const url = `${host.api}/playlist-categories/playlist/${playlistId}`
      const res = await axios.delete(url, { data: param })
      return res.data
    } catch (error) {
      throw error
    }
  }

  createCouponPlaylistItem = async (param) => {
    try {
      const url = `${host.api}/playlist-coupons`
      const res = await axios.post(url,  param.data )
      return res.data
    } catch (error) {
      throw error
    }
  }

  updateOrderCouponPlaylist = async (playlistId,param) => {
    try {
      const url = `${host.api}/playlist-coupons/${playlistId}/order`
      const res = await axios.patch(url, param )
      return res.data
    } catch (error) {
      throw error
    }
  }

  getListCouponPlaylist = async (playlistId) => {
    try {
      const url = `${host.api}/playlist-coupons/playlist/${playlistId}`
      const res = await axios.get(url)
      return res.data
    } catch (error) {
      throw error
    }
  }

  deleteCouponFromPlaylist = async (playlistCouponId) => {
    try {
      const url = `${host.api}/playlist-coupons/${playlistCouponId}`
      const res = await axios.delete(url)
      return res.data
    } catch (error) {
      throw error
    }
  }


}

export default new PlaylistsProvider()


