import React from 'react'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import host from 'config/host';
import { Body1 } from 'components/FontStyle';
import close from '../../images/icons/close.svg'
import { connect } from 'react-redux'



const Container = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100%;
    flex-flow: row wrap;
    margin-bottom: 1em;
`

const Box = styled.div`
    text-align: center;
`

const Image = styled.img`
    max-width: 100%;
`
const Text = styled.a`
    text-align: justify;
`

const ModalPreviewBroadCast = (props) => {
    const {
        className,
        content,
        setHtmlFormat,
        modalBroadcast,
        closeModalPreview,
        createContentBroadcast
    } = props;

    const setCodePreview = async () => {
        let doc = document.implementation.createHTMLDocument("Broadcast");
        const code = document.getElementById('htmlCode').innerHTML
        let container = document.createElement("div");
        container.innerHTML = code
        let style = doc.createElement('style')
        style.type = 'text/css';
        let css = '.TgAJF { text-align: justify; } .fvmXNs { max-width:100%;} .esUBPJ { text-align: center; } .iohNdu { display: flex;justify-content: center;max-width: 100%;flex-flow: row wrap;margin-bottom: 1em; }';
        var styleNode = document.createTextNode(css);
        style.appendChild(styleNode)
        doc.head.appendChild(style)
        doc.body.appendChild(container)
        const pageString = doc.documentElement.innerHTML
        await setHtmlFormat(pageString)
        await createContentBroadcast()
    }

    const showContentType = (type, content) => {
        switch (type) {
            case 'image':
                return (
                    <a target="_blank" href={content.link} rel="noopener noreferrer">
                        <Image src={host.image + content.data.path} />
                    </a>
                )
            case 'text': {
                if (content['link']) {
                    return <Text href={content.link} target="_blank"> <Body1>{content.data}</Body1>  </Text>
                }
                return <Body1>{content.data}</Body1>
            }

            default: return 'null'
        }
    }

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal isOpen={modalBroadcast.isOpen} toggle={closeModalPreview} className={className} size="xl">
                <ModalHeader toggle={closeModalPreview}>
                    <div className="d-flex justify-content-between">
                        <h5 className="align-items-end mb-0">แสดงตัวอย่างก่อนบันทึก</h5>
                        <img src={close} alt="" onClick={closeModalPreview} className="cursor-pointer" />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div id="htmlCode">
                        {content.map((item, idx) => (
                            <Container>
                                {item.contents.map((contentElement, conidx) => (
                                    <Box>
                                        {showContentType(item.contentType, contentElement)}
                                    </Box>
                                ))}
                            </Container>
                        ))}
                    </div>
                </ModalBody>
                <div className="d-flex justify-content-center p-4">
                    <button className="btn btn-cancel" onClick={closeModalPreview}> กลับไปแก้ไข </button>
                    <button className="btn btn-submit" onClick={() => setCodePreview()}> บันทึก </button>
                </div>

            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    modalBroadcast: state.broadcast.modalPreview
})

export default connect(mapStateToProps)(ModalPreviewBroadCast)