import React from 'react'
import { Modal, Spinner } from 'reactstrap'

import './styles.scss'

const ModalLoading = ({ isOpenModal, progress }) => {
  return (
    <Modal className='modal-loading' isOpen={isOpenModal} centered>
      <Spinner style={{ width: '3rem', height: '3rem' }} color='warning' />
      {progress ? <span className='txt-progress'>{progress-2} %</span> : null}
    </Modal>
  )
}

ModalLoading.defaultProps = {
  isOpenModal: false,
}

export default ModalLoading
