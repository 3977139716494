/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import classNames from 'classnames';
import moment from "moment";
// import validator from "validator";
import numeral from 'numeral';
import { Checkbox } from 'antd'
import { isEmpty } from 'helper/utils';
import { useSelector, useDispatch } from "react-redux";
import { Caption, Require } from "components/FontStyle";
import "../styles/_addCoupon.scss"

import { SelectShop } from "components/PlaylistItems/";
import ToggleInput from 'components/ToggleInput';
import Radio from "components/RadioButton";
import InputText from "components/InputText";
import FieldNumber from "components/FieldNumber";
import DateTimePicker from "components/DateTimePicker";
import ModalAlert from "components/modalAlert/ModalAlert";
import { Textarea } from 'components/input'

import {
  OPEN_MODAL_CONFIRM_PROMOCODE,
  OPEN_MODAL_MESSAGE_PROMOCODE,
  OPEN_MODAL_SELECTED_SHOP,
  SET_KEY_VALUE_PROMOCODE,
  CREATE_COUPON,
  UPDATE_COUPON,
  GET_CLIENT_LEVEL,
  UPDATE_COUNT_CHECKED_ALL_SHOP
} from "redux/actions/promocode";
import {
  GET_USER_SHOPS,
  ADD_KEY_VALUE_SHOP
} from 'redux/actions/shop';
import { ADD_KEY_VALUE_SELECTEDED_JOIN_SHOP, ADD_PRODUCT_SELECTED_JOIN_SHOP } from "redux/actions/joinSelectedShop";
import ModalConfirm from "components/modalConfirm/ModalConfirm";
import ModalMessageCallback from "components/ModalMessageCallback";
import ModalCheckedProduct from "components/ModalCheckedProduct"
import productProvider from "provider/productProvider";
import IconIncrease from '../images/icons/increse.svg';
import ModalMessage from "components/modalMessage";
import { dispatchModalMessage, OPEN_MODAL_MESSAGE, CLOSE_MODAL_MESSAGE } from "redux/actions/modalMessage"
import { UncontrolledTooltip } from 'reactstrap';
import { ReactComponent as IconHint } from 'images/icons/hint.svg'
import ModalSelectedProduct from "components/ModalSelectedProduct/ModalSelectedProduct";
import { OPEN_MODAL_LIST_SKU } from "redux/actions/promocode";
import { OPEN_MODAL_ALERT, dispatchModalAlert } from 'redux/actions/modalAlert'
import _ from 'lodash'

import { RESET_PRODUCT_SELECTED } from "redux/actions/selectedProduct";
import ToastNotification from 'components/ToastNotification'
import { ADD_PRODUCT_SELECTED_SHOP, UPDATE_PRODUCT_SELECTED_SHOP, dispatchSelectedShop } from "redux/actions/selectedShop";
import { async } from "q";
import { convert2obj, convert2array, convert2objPromocode, convert2arrPromocode } from "helper/utils"
import ModalListShopSelectedShop from "components/ModalListShopSelectedShop";

import { GET_ALL_SHOPS } from 'redux/actions/shop';
import ModalListShopProductPromocode from "components/ModalListShopProductPromocode";
import { GET_SHOPS } from 'redux/actions/shop';
import { ADD_KEY_VALUE_SELECTEDED_SHOP, RESET_SELECTED_SHOP } from "redux/actions/selectedShop";
import { RESET_PRODUCT_SELECTED_JOIN_SHOP } from 'redux/actions/joinSelectedShop'

/* -------------------------------------------------------------------------- */
/*                                  constants                                 */
/* -------------------------------------------------------------------------- */

const options = {
  productType: {
    BATCH: { value: "batch", label: "สำหรัับให้ลูกค้าเก็บส่วนลดก่อนใช้", description: "ลูกค้าต้องกดเก็บส่วนลดก่อน จึงจะสามารถนำไปใช้งานได้" },
    ONLINE: { value: "online", label: "สำหรัับให้ลูกค้ากรอกช่องโค้ดส่วนลด (Promo Code)", description: "ลูกค้าสามารถกรอกหรือค้นหาโค้ดได้เอง เพื่อนำไปใช้ (เหมาะกับกรณีประชาสัมพันธ์เป็นโค้ด เช่น NEW2021)" },
  },
  productChannel: {
    DEAL: { value: "deal", label: "ดีล" },
    COUPON: { value: "coupon", label: "คูปอง (โปรโมชั่นโค้ด)" }
  },
  discountUnit: {
    PERCENT: { value: "percent", label: "ลดราคาเป็น %" },
    NUMBER: { value: "number", label: "ลดราคาเป็นเงินสด" },
  },
  limit: {
    LIMIT: { value: "limit", label: "จำกัด" },
    UNLIMIT: { value: "unlimit", label: "ไม่จำกัด" },
  },
  productCategory: {
    COUPON: { value: "voucher", label: "คูปองเงินสด" },
    DISCOUNT: { value: "discount", label: "ลดราคา" },
  },
  discountOption: {
    PRODUCT: { value: "product", label: "ลดราคาสินค้า" },
    SHIPPING: { value: "shipping", label: "ลดราคาค่าจัดส่ง" },
  },
  fequencyUnitOption: {
    DAY: { value: "day", label: "จำกัดต่อวัน" },
    WEEK: { value: "week", label: "จำกัดต่อสัปดาห์" },
    MONTH: { value: "month", label: "จำกัดต่อเดือน" },
    UNLIMIT: { value: "unlimit", label: "ไม่จำกัด" }
  },
  limitUserUnitOption: {
    DAY: { value: "day", label: "จำกัดต่อวัน" },
    WEEK: { value: "week", label: "จำกัดต่อสัปดาห์" },
    MONTH: { value: "month", label: "จำกัดต่อเดือน" },
    PROMOTION: { value: "promotion", label: "จำกัดต่อทั้งแคมเปญ" },
    UNLIMIT: { value: "unlimit", label: "ไม่จำกัด" }
  },
  fequencyUnit: [
    { label: "ต่อวัน", value: "day" },
    { label: "ต่อสัปดาห์", value: "week" },
    { label: "ต่อเดือน", value: "month" },
  ],
  generateLimit: {
    LIMIT: { value: "limit", label: "ระบุจำนวน" },
    UNLIMIT: { value: "unlimit", label: "จำกัดตามโปรโมชั่นท้ังหมด" },
  },
  validType: {
    PERIOD: { value: "period", label: "ช่วงเวลา" },
    DAY: { value: "day", label: "นับจากวันที่ซื้อ Deals (ระบุจำนวนวัน)" },
  },
  codeFormatType: {
    BASIC: { value: "basic", label: "ตั้งค่าพื้นฐาน (สุ่ม)" },
    FIXED: { value: "fixed", label: "กำหนดเอง" },
  },
  codeFormatOption: {
    NUMBER: { value: "number", label: "ตัวเลขเท่านั้น" },
    TEXT: { value: "text", label: "ตัวอักษรเท่านั้น" },
    ALL: { value: "all", label: "ตัวเลขและตัวอักษร" },
    DEFINE: { value: "define", label: "กำหนดเอง" },
  },
  codeFormatLength: [
    { name: "6", value: 6 },
    { name: "7", value: 7 },
    { name: "8", value: 8 },
    { name: "9", value: 9 },
    { name: "10", value: 10 },
    { name: "11", value: 11 },
  ],

  productJointType: {
    ALL: { value: "all", label: "สินค้าทั้งหมด" },
    SOME: { value: "some", label: "สินค้าบางรายการ" },
  },

  shopJointType: {
    ALL: { value: "allShop", label: "ร้านค้า และสินค้าทั้งหมด" },
    SOME: { value: "someShop", label: "ร้านค้า และสินค้าบางรายการ" },
  }
};


const disabledInputRule = {
  add: {},
  edit: {
    codeFormatValue: true,
    productCode: true,
    productType: true,
  }
}

/* -------------------------------------------------------------------------- */
/*                               component function                           */
/* -------------------------------------------------------------------------- */
const RowItem = (props) => {
  const { title, title2, width = 7, titleWidth = 3, header, children, require, id = '' } = props;
  return (
    <>
      <div className="row mb-4" id={id}>
        <div className={`col-${titleWidth}`}>
          <Caption>
            {header && header}
            {title && title}
            {title2 && (<> <br />{title2}</>)}
            {require && <Require />}
          </Caption>
        </div>
        <div className={`col-${width}`}>{children}</div>
      </div>
    </>
  );
};

const renderOption = (params) => {
  const { key, value, col = 6, cols, options, disabled, fontSize, disabledItems, onInputValueChange, isHasProductJoint = false, promoCode = {}, error = '', handleOpenModalSelectedProducts = null, isPlatform = false } = params;
  /** Params
   * key(string) : 
   * value(string,number)
   * col(number)
   * cols(array(number))
   * optios(object) 
   * fontSize(number)
   * disabledItems(array(number))
   */
  return (
    <div className="row">
      {Object.keys(options).map((keyItem, index) => {
        const isDisabled = disabledItems ? disabledItems.find(itemIndex => itemIndex === index) : false;
        const disabledItem = isDisabled ? true : disabled;
        let column = col;

        if (cols) {
          column = cols[index];
        }


        return <div key={keyItem} className={`col-${column} mb-4 cover-radio-coupon d-flex flex-column`}>
          <div className=" d-flex flex-row align-items-center flex-wrap">
            <Radio
              value={options[keyItem].value}
              className="mr-4"
              valueOption={value}
              name={key}
              disabled={disabledItem}
              onChange={() =>
                onInputValueChange(key, options[keyItem].value)
              }
            />
            <label className="form-check-label mr-4" style={{ fontSize: fontSize }}>
              {options[keyItem].label}
            </label>

            {
              isHasProductJoint && (options[keyItem].value == 'some' || options[keyItem].value == 'someShop') &&
              <>
                <div className="cover-product-join" onClick={() => handleOpenModalSelectedProducts()} id="productJoin">
                  <img src={IconIncrease} width={25} />

                  {isPlatform ?
                    <>
                      <span className="text-select-product">เลือกร้านค้า และสินค้า</span>
                      <span className="text-product-join-sku">(เลือกแล้ว  {promoCode?.countAllShop} ร้านค้า | {promoCode?.countAllSKU} SKU)</span>
                    </>
                    :
                    <>
                      <span className="text-select-product">เลือกสินค้า</span>
                      {promoCode?.itemJoin?.skus?.length > 0 &&
                        <span className="text-product-join-sku">(เลือกแล้ว {promoCode?.itemJoin?.skus?.length} SKU)</span>
                      }
                    </>
                  }

                </div> <br />
              </>
            }

            <br />
            {options[keyItem].description &&
              <label className="label-description">
                {options[keyItem].description}
              </label>
            }
          </div>

          {error && (options[keyItem].value == 'some' || options[keyItem].value == 'someShop' && isHasProductJoint) &&
            <div className="text-error-radio">
              {error}
            </div>
          }
        </div>
      }
      )}
    </div>
  );
};

const renderCheckBox = (params) => {
  /** Params
  * key: string
  * value: string,number
  * col: number
  * cols: array(number)
  * optios: object 
  * fontSize: number
  * disabledItems: array(number)
  */
  const { key, value, col = 6, options, onInputValueChange } = params;
  return <Checkbox.Group style={{ width: '100%' }}
    value={value}
    onChange={(checkedValues) => onInputValueChange(key, checkedValues)}>
    <div className="row">
      {Object.keys(options).map((keyItem, index) => {
        return <div key={keyItem} className={`col-${col} mb-4`}>
          <Checkbox value={options[keyItem].value}>
            <label className="form-check-label" style={{ fontSize: "1.4rem" }}>
              {options[keyItem].label}
            </label>
          </Checkbox>
        </div>
      }
      )}
    </div>

  </Checkbox.Group>
}

const ContainerSection = (props) => {
  const { children, title, tooltipTitle = '', tooltipContent = '', tooltipId = '', tooltipWidth = '400px', id = "" } = props;
  return <div className="section">
    {title && <>

      <div className="d-flex" id={id}>
        <h3 style={{ fontWeight: 600 }}>{title}</h3>

        {tooltipTitle != '' &&
          <span id={tooltipId} className="icon-hint-tooltip" ><IconHint width={20} height={20} /></span>
        }
      </div>

      {tooltipTitle != '' &&
        <UncontrolledTooltip placement="top" target={tooltipId} style={{ maxWidth: tooltipWidth, textAlign: 'left' }}>
          <span style={{ fontWeight: '700', lineHeight: '20px' }}> {tooltipTitle} </span><br />
          <span
            style={{ lineHeight: '20px' }}
            dangerouslySetInnerHTML={{ __html: tooltipContent }}></span>
        </UncontrolledTooltip>
      }
      <hr />
    </>
    }
    <div className="content">
      {children}
    </div>
  </div>
}

/* -------------------------------------------------------------------------- */
/*                                    component                               */
/* -------------------------------------------------------------------------- */
const AddCoupon = (props) => {
  const dispatch = useDispatch();
  const { shopSelected, userShops } = useSelector((state) => state.shop);
  const { managePromocode, promocodeId } = useSelector((state) => state.promocode);
  const selectedShop = useSelector((state) => state.selectedShop);
  const joinSelectedShop = useSelector((state) => state.joinSelectedShop);

  const [shopUser, setShopUser] = useState([]);
  const isPlatform = window.location.pathname.includes('platform');

  useEffect(() => {
    initialComponent()
    return () => unmounthComponent()
  }, [])

  useEffect(() => {
    if (userShops && shopUser.length === 0) {
      setShopUser(userShops);


      // for user that have one shop get shopSelected
      if (userShops && userShops.length === 1)
        dispatch({
          type: ADD_KEY_VALUE_SHOP, payload:
            { key: 'shopSelected', value: userShops[0] }
        });


    }
    funcApi();
  }, [userShops])



  const funcApi = async () => {
    try {
      const _productProvider = new productProvider();
      if (promocodeId) {
        const products = await _productProvider.getShopsProductsPromocode({ promocodeId: promocodeId })
        const data = convert2objPromocode(products)
        console.log(data)
        dispatch({ type: ADD_PRODUCT_SELECTED_SHOP, payload: data.shops })
      }

    } catch (error) {
      console.log(error)
    }
  }

  const initialComponent = () => {
    // get user'shop for display component selectShop if they have more than one shop
    if (!isPlatform)
      dispatch({ type: GET_USER_SHOPS, payload: {} })

    // platfrom mode never use shopDetail but fig to shopId: 0 
    if (isPlatform)
      dispatch({
        type: ADD_KEY_VALUE_SHOP, payload:
          { key: 'shopSelected', value: { shopId: 0, shopCode: 'VAV' } }
      });

    // case refresh page managePromocode from redux will set to null
    if (!managePromocode && window.location.pathname.includes('edit'))
      window.location.replace('/manage/promocode/mall/')
  }

  const unmounthComponent = () => {
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopSelected', value: null } });
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'userShops', value: null } });

    dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'listProduct', value: {} } });
    dispatch({ type: RESET_PRODUCT_SELECTED });
  }

  const propData = { ...props, shopUser };
  return <>
    {shopUser &&
      <>
        {(!shopSelected &&
          !isPlatform &&
          shopUser.length > 1 &&  // check user's shop  one or many
          window.location.pathname.includes('add')) ? (
          <SelectShop showPlayList={false} />
        ) : (
          <> {isPlatform ?
            <>{<FormCoupon propsData={propData} />} </> : // platfrom no need to use shopSelected
            <>{shopSelected && <FormCoupon propsData={propData} />} </>
          } </>
        )}
      </>
    }
  </>
}

const FormCoupon = (props) => {
  const dispatch = useDispatch();
  const { propsData } = props;
  const {
    history,
    data,
    errors,
    modalAlert,
    modalConfirm,
    promoCode,
    joinSelectedShop,
    selectedShop,
    shopSelected,
    productJoin,
    modalMessage,
    shopJoin,
    setElementName,
    handleDataChange,
    handleDataLetterChange,
    handleManageCoupon,
    handleCollectStartDateTimeChannge,
    handleCollectEndDateTimeChannge,
    handleUsedStartDateTimeChannge,
    handleUsedEndDateTimeChannge,
    handleCostSharingChange,
    handleCancelSubmit,
    handleSuccessComfirmModal,
    handleSelectProductJoint,
    handleRedirectToProduct,
    handleOpenModalSelectedProducts,
    handleOpenModalSelectedShop,
    handleSelectShopJoint
  } = useFormCoupon(propsData.props);
  const {
    couponName,
    limitUserOption,
    discountLimitOption,
    generateLimitOption,
    limitGlobalOption,
    stockMaxOption,
    stockMax,
    limitGlobal,
    limitUser,
    costSharingPartner,
    costSharingPlatform,
    collectStartDate,
    collectStartTime,
    collectEndDate,
    collectEndTime,
    usedStartDate,
    usedStartTime,
    usedEndDate,
    usedEndTime,
    productCode,
    productType,
    discountUnit,
    discountValue,
    purchaseMin,
    clientLevel,
    clientLevelOption,
    status,
    description,
    discountLimit,
    generateLimit,
    codeFormatLength,
    codeFormatValue,
    modalDateOpen
  } = data;
  const isPlatform = window.location.pathname.includes('platform');
  const isAddForm = window.location.pathname.includes('add');
  const disabledInput = isAddForm ? disabledInputRule.add : disabledInputRule.edit;


  // let codeFormatTypeDisabled = [];
  // if(productType === options.productType.BATCH.value) 
  // codeFormatTypeDisabled = [...codeFormatTypeDisabled, 1]

  return (
    <>
      <div className="add-coupon">

        {!isPlatform &&
          <div className="section pb-4">
            <div className="d-flex flex-row">
              {shopSelected &&
                <div className='d-flex justify-content-start align-items-end'>
                  <div className='pr-4'>
                    <img style={{ height: 40 }}
                      alt="cover"
                      src={`${shopSelected?.logoPath?.host}${shopSelected?.logoPath?.path}`} />
                  </div>
                  <div className='pl-4' style={{ borderLeft: 'solid black 2px' }}>
                    <span>{shopSelected?.name}</span>
                  </div>
                </div>
              }
              {window.location.pathname.includes('add') && propsData?.shopUser?.length > 1 &&
                <div className='d-flex justify-content-start align-items-end ml-4'>
                  <span className="link-url"
                    onClick={() => dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopSelected', value: null } })}>
                    เปลี่ยนร้าน
                  </span>
                </div>
              }
            </div>
          </div>
        }
        <ContainerSection title={"รายละเอียดส่วนลด"}>
          <RowItem title="ชื่อคูปองส่วนลด" require={true} id="couponName">
            <InputText
              className={"input-text"}
              onChange={(event) =>
                handleDataChange("couponName", event.target.value)
              }
              value={couponName}
              label={"ชื่อ"}
              title={"(จำกัด 100 ตัวอักษร)"}
              placeholder={"Ex. คูปองแทนเงินสด 500 บาท"}
              disabled={disabledInput["couponName"]}
              isRequire={true}
              isError={errors["couponName"] ? true : false}
              errorMsg={errors["couponName"] ? errors["couponName"].message : ""}
              classNameTitle={"input-title"}
              classNameSubTitle={"input-subtitle"}
            />
          </RowItem>
          <RowItem title="รายละเอียด" require={true} id="description">
            <Textarea
              label='รายละเอียด'
              title={'(จำกัด 5,000 ตัวอักษร)'}
              defaultValue={description}
              placeholder='รายละเอียด'
              handleChangeText={(text) => handleDataChange('description', text)}
              error={errors["description"] ? errors["description"].message : ""}
              require={true}
            />
          </RowItem>
          <RowItem title="รูปแบบโค้ดส่วนลด" require={false}>
            {renderOption({
              key: "productType",
              value: productType,
              col: 12,
              options: options.productType,
              disabled: disabledInput["productType"],
              onInputValueChange: handleDataChange,
            })}
          </RowItem>
          <RowItem title="เลขหมายกำกับรหัสโค้ด" require={true}>
            <div className="d-flex flex-row align-items-center w-100" id="productCode">
              {window.location.pathname.includes('add') ?
                <>
                  <div className="mr-2" style={{ width: '12%' }}>
                    <span>{shopSelected?.shopCode}-{productType === options.productType.ONLINE.value ? "1" : "2"}-</span>
                  </div>
                  <div style={{ width: '100%' }}>
                    <input className={classNames('input-form mt-1 mb-1', { 'border-error': !!errors["productCode"] }, { 'disabled': !!disabledInput["productCode"] })}
                      type='text'
                      placeholder={"กรอก A-Z, 0-9 สูงสุด 5 ตัวอักษร"}
                      value={productCode}
                      onChange={(event) => handleDataLetterChange("productCode", event.target.value)}
                      maxLength={5}
                      disabled={!!disabledInput["productCode"]}
                      name={"productCode"}
                    />
                    {!!errors["productCode"] &&
                      <span className="text-danger">
                        {errors["productCode"] ? errors["productCode"].message : ""}
                      </span>}
                  </div>
                </> : <> <label className="form-check-label mr-4">
                  {productCode} </label></>
              }
            </div>
          </RowItem>
          {productType === options.productType.ONLINE.value &&
            <RowItem title="รหัสโค้ดสำหรับนำไปใช้งาน" require={true} id="codeFormatValue">
              {/* { renderOption({
                      key: "codeFormatType",
                      value: codeFormatType,
                      options: options.codeFormatType,
                      disabled: disabledInput["codeFormatType"],
                      disabledItems: codeFormatTypeDisabled,
                      onInputValueChange: handleDataChange,
                    })}
                    <br/> */}
              <InputText
                className={"input-text"}
                onChange={(event) =>
                  handleDataLetterChange("codeFormatValue", event.target.value)
                }
                label={"โค้ดรหัส"}
                value={codeFormatValue}
                placeholder={"A-Z a-z 0-9"}
                limit={codeFormatLength}
                disabled={disabledInput["codeFormatValue"]}
                isRequire={true}
                isError={errors["codeFormatValue"] ? true : false}
                errorMsg={
                  errors["codeFormatValue"] ? errors["codeFormatValue"].message : ""
                }
                classNameTitle={"input-title"}
                classNameSubTitle={"input-subtitle"}
              />
            </RowItem>
          }
        </ContainerSection>
        <ContainerSection title={"รูปแบบส่วนลด"}>
          <RowItem title="รูปแบบส่วนลด" require={true}>
            ลดราคา
          </RowItem>
          <RowItem title="ประเภทส่วนลด" require={true}>
            {renderOption({
              key: "discountUnit",
              value: discountUnit,
              options: options.discountUnit,
              disabled: disabledInput["discountUnit"],
              onInputValueChange: handleDataChange,
            })}
            <br />
            <div className="d-flex flex-wrap mt-2">
              <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }} id="purchaseMin">
                <FieldNumber
                  type={"CURRENCY"}
                  title={"เมื่อยอดซื้อถึง"}
                  detail={""}
                  placeholder={"0"}
                  name={"purchaseMin"}
                  value={purchaseMin}
                  onChange={(value) =>
                    handleDataChange("purchaseMin", value.floatValue)
                  }
                  disabled={disabledInput["purchaseMin"]}
                  isRequire={true}
                  isError={errors["purchaseMin"] ? true : false}
                  errorMessage={
                    errors["purchaseMin"] ? errors["purchaseMin"].message : ""
                  }
                />
              </div>
              <div style={{ flex: '50%', width: '100%', paddingLeft: '15px' }} id="discountValue">
                <FieldNumber
                  type={`${discountUnit === options.discountUnit.NUMBER.value ? "CURRENCY" : "PERCENT"}`}
                  title={`รับส่วนลด ${discountUnit === options.discountUnit.PERCENT.value ? "(%)" : ""}`}
                  detail={""}
                  placeholder={"0"}
                  name={"discountValue"}
                  value={discountValue}
                  onFocus={elementName => setElementName(elementName)}
                  onChange={(value) =>
                    handleDataChange("discountValue",
                      (value.floatValue > 100) &&
                        (discountUnit === options.discountUnit.PERCENT.value)
                        ? 100 : value.floatValue)
                  }
                  disabled={disabledInput["discountValue"]}
                  isRequire={true}
                  isError={errors["discountValue"] ? true : false}
                  errorMessage={
                    errors["discountValue"] ? errors["discountValue"].message : ""
                  }
                />
              </div>
            </div>
          </RowItem>
          {discountUnit === options.discountUnit.PERCENT.value &&
            <RowItem title="ส่วนลดสูงสุด" require={true}>
              {renderOption({
                key: "discountLimitOption",
                value: discountLimitOption,
                options: options.limit,
                disabled: disabledInput["discountLimitOption"],
                onInputValueChange: handleDataChange,
              })}
              {discountLimitOption === options.limit.LIMIT.value &&
                <div className="d-flex flex-wrap mt-2">
                  <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }}>
                    <FieldNumber
                      type={"CURRENCY"}
                      detail={""}
                      placeholder={"0"}
                      name={"discountLimit"}
                      value={discountLimit}
                      onChange={(value) =>
                        handleDataChange("discountLimit", value.floatValue)
                      }
                      disabled={disabledInput["discountLimit"]}
                      isRequire={false}
                      isError={errors["discountLimit"] ? true : false}
                      errorMessage={
                        errors["discountLimit"] ? errors["discountLimit"].message : ""
                      }
                    />
                  </div>
                  <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }}>
                  </div>
                </div>
              }
            </RowItem>
          }
        </ContainerSection>

        {isPlatform ?
          <ContainerSection
            title={"ร้านค้า และสินค้าที่เข้าร่วมส่วนลด"}
            tooltipId="tooltipProductJoin"
            tooltipTitle="ร้านค้า และสินค้าที่เข้าร่วมส่วนลด สามารถเลือกได้ 2 รูปแบบ"
            tooltipContent={`ร้านค้า และสินค้าทั้งหมด : ไม่จำเป็นต้องมีร้านค้า และสินค้าในตอนนี้ สามารถเพิ่มได้หลังจากสร้างคูปอง
              ร้านค้า และสินค้าบางรายการ : จำเป็นต้องมีร้านค้า และสินค้า เพื่อใช้งานคูปอง`}
            tooltipWidth="430px"
            id="shopJoin"
          >
            <RowItem title="ร้านค้า และสินค้าที่เข้าร่วม" require={true} width="9">
              <div>
                {renderOption({
                  key: "shopJointType",
                  value: shopJoin,
                  cols: [9, 9],
                  options: options.shopJointType,
                  onInputValueChange: (key, value) => handleSelectShopJoint(value),
                  isHasProductJoint: shopJoin === 'someShop',
                  error: errors?.shopJoin ? 'กรุณาเลือกร้านค้า และสินค้า' : '',
                  promoCode: joinSelectedShop,
                  handleOpenModalSelectedProducts: handleOpenModalSelectedShop,
                  isPlatform: isPlatform
                })}

              </div>
            </RowItem>
          </ContainerSection> :

          <ContainerSection title={"สินค้าที่เข้าร่วมส่วนลด"} tooltipId="tooltipProductJoin" tooltipTitle="สินค้าที่เข้าร่วมส่วนลด สามารถเลือกได้ 2 รูปแบบ"
            tooltipContent={`สินค้าทั้งหมด : ไม่จำเป็นต้องสร้างสินค้าตอนนี้ สามารถเพิ่มสินค้าหลังจากสร้างคูปอง <br/>
            สินค้าบางรายการ : จำเป็นต้องสร้างสินค้า เพื่อใช้งานคูปองกับสินค้าบางรายการ`}
            id="productJoin"
          >
            <RowItem title="สินค้าที่เข้าร่วม" require={true} width="9">
              <div>
                {renderOption({
                  key: "productJointType",
                  value: productJoin,
                  cols: [5, 7],
                  options: options.productJointType,
                  onInputValueChange: (key, value) => handleSelectProductJoint(value),
                  isHasProductJoint: productJoin === 'some',
                  error: errors?.productJoin ? 'กรุณาเลือกสินค้า' : '',
                  promoCode: promoCode,
                  handleOpenModalSelectedProducts: handleOpenModalSelectedProducts
                })}

              </div>
            </RowItem>
          </ContainerSection>
        }


        <ContainerSection title={"ระยะเวลาการเก็บคูปอง"}>
          <RowItem title="กำหนดวันที่">
            <DateTimePicker
              isModalDateOpen={modalDateOpen === "collectStartDate"}
              toggleModalDate={() => handleDataChange('modalDateOpen', modalDateOpen === "collectStartDate" ? "" : 'collectStartDate')}
              disabled={disabledInput["collectStartDate"]}
              allowpreviousDate={false}
              isDateError={errors["collectStartDate"] ? true : false}
              isTimeError={errors["collectStartDate"] ? true : false}
              date={collectStartDate}
              time={collectStartTime}
              labelDate={"วันที่เริ่มเก็บ"}
              labelTime={"เวลาที่เริ่มเก็บ"}
              onChange={handleCollectStartDateTimeChannge}
              dateErrorMessage={''}
              timeErrorMessage={''}
            />
            {errors["collectStartDate"] &&
              <div className="row mt-3 pl-4">
                <span style={{ color: "red" }}>
                  {errors["collectStartDate"].message}
                </span>
              </div>
            }
          </RowItem>
          <br />
          <RowItem title="">
            <DateTimePicker
              isModalDateOpen={modalDateOpen === "collectEndDate"}
              toggleModalDate={() => handleDataChange('modalDateOpen', modalDateOpen === "collectEndDate" ? "" : 'collectEndDate')}
              disabled={disabledInput["collectEndDate"]}
              dissableDatePast={isAddForm && !moment(data.collectEndDate).isSame(moment(data.collectStartDate), 'day') ? null : collectStartDate}
              allowpreviousDate={false}
              isDateError={errors["collectEndDate"] ? true : false}
              isTimeError={errors["collectEndDate"] ? true : false}
              date={collectEndDate}
              time={collectEndTime}
              disabledTime={isAddForm && !moment(data.collectEndDate).isSame(moment(data.collectStartDate), 'day') ? 
                moment('00:00:00', 'HH:mm:ss') : 
                moment(data.collectEndDate).isSame(moment(data.collectStartDate), 'day') ?
                moment(collectStartTime).add({ hours: 1 }) :
                moment('00:00:00', 'HH:mm:ss')}
              labelDate={"วันที่สิ้นสุด"}
              labelTime={"เวลาที่สิ้นสุด"}
              onChange={handleCollectEndDateTimeChannge}
              dateErrorMessage={''}
              timeErrorMessage={''}
            />

            {/* <div className="row mt-3 pl-4">
                  <span style={{ color: "red" }}>
                    * หมายเหตุ วันที่สิ้นสุดห้ามเกินวันหมดอายุ
                  </span>
                </div> */}
          </RowItem>
        </ContainerSection>
        <ContainerSection title={"ระยะเวลาการใช้งาน"}>
          <RowItem title="กำหนดวันที่">
            <div className="row">
              <div className="col-6">
                <DateTimePicker
                  isModalDateOpen={modalDateOpen === "usedStartDate"}
                  toggleModalDate={() => handleDataChange('modalDateOpen', modalDateOpen === "usedStartDate" ? "" : 'usedStartDate')}
                  disabled={disabledInput["usedStartDate"]}
                  allowpreviousDate={false}
                  isDateError={errors["usedStartDate"] ? true : false}
                  isTimeError={errors["usedStartDate"] ? true : false}
                  date={usedStartDate}
                  time={usedStartTime}
                  labelDate={"วันที่เริ่มใช้งาน"}
                  hideTime={true}
                  onChange={handleUsedStartDateTimeChannge}
                  dateErrorMessage={''}
                  timeErrorMessage={''}
                />
                {errors["usedStartDate"] &&
                  <div className="row mt-3 pl-4">
                    <span style={{ color: "red" }}>
                      {errors["usedStartDate"].message}
                    </span>
                  </div>
                }
              </div>
              <div className="col-6">
                <DateTimePicker
                  isModalDateOpen={modalDateOpen === "usedEndDate"}
                  toggleModalDate={() => handleDataChange('modalDateOpen', modalDateOpen === "usedEndDate" ? "" : 'usedEndDate')}
                  disabled={disabledInput["usedEndDate"]}
                  dissableDatePast={isAddForm ? null : usedStartDate}
                  allowpreviousDate={false}
                  isDateError={errors["usedEndDate"] ? true : false}
                  isTimeError={errors["usedEndDate"] ? true : false}
                  date={usedEndDate}
                  time={usedEndTime}
                  labelDate={"วันที่สิ้นสุด"}
                  hideTime={true}
                  onChange={handleUsedEndDateTimeChannge}
                  dateErrorMessage={''}
                  timeErrorMessage={''}
                />
                {errors["usedEndDate"] &&
                  <div className="row mt-3 pl-4">
                    <span style={{ color: "red" }}>
                      {errors["usedEndDate"].message}
                    </span>
                  </div>
                }
              </div>
            </div>
          </RowItem>
        </ContainerSection>
        <ContainerSection title={"การจำกัดจำนวน"}>
          <RowItem title="จำนวนคูปองส่วนลดทั้งหมด" require={false}>
            {renderOption({
              key: "stockMaxOption",
              value: stockMaxOption,
              options: options.limit,
              disabled: disabledInput["stockMaxOption"],
              onInputValueChange: handleDataChange,
            })}
            <br />
            {stockMaxOption === options.limit.LIMIT.value &&
              <div className="d-flex flex-wrap mt-2">
                <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }}>
                  <FieldNumber
                    type={"COMMON"}
                    title={"ระบุจำนวน"}
                    detail={""}
                    placeholder={"0"}
                    name={"stockMax"}
                    value={stockMax}
                    onChange={(value) =>
                      handleDataChange("stockMax", value.floatValue)
                    }
                    disabled={disabledInput["stockMax"]}
                    isRequire={true}
                    isError={errors["stockMax"] ? true : false}
                    errorMessage={
                      errors["stockMax"] ? errors["stockMax"].message : ""
                    }
                  />
                </div>
                <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }}>
                </div>
              </div>
            }

          </RowItem>
          <br />
          <RowItem title="รูปแบบจำนวนการแจก" require={false}>
            {renderOption({
              key: "generateLimitOption",
              value: generateLimitOption,
              options: options.generateLimit,
              disabled: disabledInput["generateLimitOption"],
              onInputValueChange: handleDataChange,
            })}
            <br />
            {generateLimitOption === options.generateLimit.LIMIT.value &&
              <div className="d-flex flex-wrap mt-2">
                <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }}>
                  <FieldNumber
                    type={"COMMON"}
                    title={"ระบุจำนวน"}
                    detail={""}
                    placeholder={"0"}
                    name={"generateLimit"}
                    value={generateLimit}
                    onChange={(value) =>
                      handleDataChange("generateLimit", value.floatValue)
                    }
                    disabled={disabledInput["generateLimit"]}
                    isRequire={true}
                    isError={errors["generateLimit"] ? true : false}
                    errorMessage={
                      errors["generateLimit"] ? errors["generateLimit"].message : ""
                    }
                  />
                </div>
                <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }}>
                </div>
              </div>
            }
          </RowItem>
          <br />
          <RowItem title="จำนวนการใช้คูปองส่วนลดทั้งหมด" require={false}>
            {renderOption({
              key: "limitGlobalOption",
              value: limitGlobalOption,
              col: 6,
              options: options.fequencyUnitOption,
              disabled: disabledInput["limitGlobalOption"],
              onInputValueChange: handleDataChange,
            })}
            <br />
            {limitGlobalOption !== options.fequencyUnitOption.UNLIMIT.value &&
              <div className="d-flex flex-wrap mt-2">
                <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }}>
                  <FieldNumber
                    type={"COMMON"}
                    title={"ระบุจำนวน"}
                    detail={""}
                    placeholder={"0"}
                    name={"limitGlobal"}
                    value={limitGlobal}
                    onChange={(value) => handleDataChange("limitGlobal", value.floatValue)}
                    disabled={disabledInput["limitGlobal"]}
                    isRequire={true}
                    isError={errors["limitGlobal"] ? true : false}
                    errorMessage={errors["limitGlobal"] ? errors["limitGlobal"].message : ""}
                  />
                </div>
                <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }}>
                </div>
              </div>
            }
          </RowItem>
          <br />
          <RowItem title="จำนวนการใช้คูปองส่วนลดของผู้ซื้อ" require={false}>
            {renderOption({
              key: "limitUserOption",
              value: limitUserOption,
              col: 6,
              options: options.limitUserUnitOption,
              disabled: disabledInput["limitUserOption"],
              onInputValueChange: handleDataChange,
            })}
            <br />
            {limitUserOption !== options.limitUserUnitOption.UNLIMIT.value &&
              <div className="d-flex flex-wrap mt-2">
                <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }}>
                  <FieldNumber
                    type={"COMMON"}
                    title={"ระบุจำนวน"}
                    detail={""}
                    placeholder={"0"}
                    name={"limitUser"}
                    value={limitUser}
                    onChange={(value) => handleDataChange("limitUser", value.floatValue)}
                    disabled={disabledInput["limitUser"]}
                    isRequire={true}
                    isError={errors["limitUser"] ? true : false}
                    errorMessage={errors["limitUser"] ? errors["limitUser"].message : ""}
                  />
                </div>
                <div style={{ flex: '50%', width: '100%', paddingRight: '15px' }}>
                </div>
              </div>
            }
          </RowItem>
        </ContainerSection>
        <ContainerSection>
          <RowItem title="ประเภทลูกค้าที่ใช้สิทธิ์ได้" require={false}>
            {clientLevelOption && Object.keys(clientLevelOption).length > 0 &&
              renderOption({
                key: "clientLevel",
                value: clientLevel,
                options: clientLevelOption,
                disabled: disabledInput["clientLevel"],
                onInputValueChange: handleDataChange,
              })}
            {errors["clientLevel"] &&
              <div className="row mt-3 pl-4">
                <span style={{ color: "red" }}>
                  {errors["clientLevel"].message}
                </span>
              </div>
            }
          </RowItem>
        </ContainerSection>

        {isPlatform &&
          <ContainerSection>
            <RowItem title="ผู้รับผิดชอบ" title2="ค่าใช้จ่ายในการลดราคา" width={9}>
              <div className="row col-12 d-flex align-items-center gap-1">
                <div className="d-flex flex-column" style={{ width: '38%' }}>
                  <FieldNumber
                    type={"PERCENT"}
                    title={"AIS"}
                    detail={""}
                    placeholder={"0"}
                    name={"costSharingPlatform"}
                    value={costSharingPlatform}
                    onFocus={elementName => setElementName(elementName)}
                    onChange={(value) =>
                      handleCostSharingChange("costSharingPlatform", value.floatValue)
                    }
                    disabled={disabledInput["costSharingPlatform"]}
                    isRequire={false}
                    isError={errors["costSharingPlatform"] ? true : false}
                    errorMessage={
                      errors["costSharingPlatform"] ? errors["costSharingPlatform"].message : ""
                    }
                  />
                </div>
                <span className="mt-4"> : </span>
                <div className="d-flex flex-column" style={{ width: '38%' }}>
                  <FieldNumber
                    type={"PERCENT"}
                    title={"ร้านค้า"}
                    detail={""}
                    placeholder={"0"}
                    name={"costSharingPartner"}
                    value={costSharingPartner}
                    onFocus={elementName => setElementName(elementName)}
                    onChange={(value) =>
                      handleCostSharingChange("costSharingPartner", value.floatValue)
                    }
                    disabled={disabledInput["costSharingPartner"]}
                    isRequire={false}
                    isError={errors["costSharingPartner"] ? true : false}
                    errorMessage={
                      errors["costSharingPartner"] ? errors["costSharingPartner"].message : ""
                    }
                  />
                </div>
              </div>
            </RowItem>
          </ContainerSection>
        }

        <ContainerSection>
          <RowItem title="สถานะการใช้งาน">
            <ToggleInput value={status !== 'hide'}
              onChange={() => handleDataChange("status", status === 'show' ? 'hide' : 'show')}
              name={"status"}
              labelOn={"เปิดการใช้งาน"}
              labelOff={"ปิดการใช้งาน"} />
          </RowItem>
        </ContainerSection>
        <ContainerSection>
          <div className="d-flex justify-content-center">
            <button className="btn-cancel mr-4 pt-3 pb-3" onClick={handleCancelSubmit}>
              ยกเลิก
            </button>
            <button className="btn-submit" onClick={handleManageCoupon}>
              บันทึก
            </button>
          </div>
          <br />
        </ContainerSection>

        <ModalConfirm
          isOpen={modalConfirm.isOpen}
          message={modalConfirm.message}
          confirm={modalConfirm.confirm}
          isClose={modalConfirm.isClose}
        />
        <ModalMessageCallback
          isOpen={promoCode.modalMessage.isOpen}
          message={promoCode.modalMessage.message}
          confirmCallback={() => handleSuccessComfirmModal(history)
          } // สร้าง coupon สำเร็จ
          title={promoCode.modalMessage.title}
        />

        <ModalMessage
          isShowModalMessage={modalMessage?.isShowModalMessage}
          title={modalMessage?.title}
          message={modalMessage?.message}
          btnLabel={modalMessage?.btnLabel}
          btnCancel={modalMessage?.btnCancel}
          isReverseBtn={true}
          confirmCallback={() => dispatch({ type: CLOSE_MODAL_MESSAGE })}
          cancelCallback={() => handleRedirectToProduct()}
        />

        <ModalAlert isOpen={modalAlert.isOpen} />
        <ModalCheckedProduct />


        <ModalSelectedProduct isOpen={promoCode?.isShowModalSelectedProduct} />
        <ModalListShopSelectedShop isOpen={promoCode?.isShowModalListShopSelectedShop} />
        <ToastNotification />
      </div>
    </>
  );
};

export default AddCoupon;

const useFormCoupon = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isPlatform = location.pathname.includes('platform');

  const modalAlert = useSelector((state) => state.modalAlert.modalAlert);
  const modalMessage = useSelector((state) => state.modalMessage);
  const userInfo = useSelector((state) => state.user.userInfo);
  const shopSelected = useSelector(state => state.shop.shopSelected);
  const promoCode = useSelector((state) => state.promocode);
  const selectedShop = useSelector((state) => state.selectedShop);
  const joinSelectedShop = useSelector((state) => state.joinSelectedShop);

  const { modalDetailPromocode,
    modalConfirm,
    managePromocode,
    verifyCode,
    clientLevel,
    couponDetail } = useSelector((state) => state.promocode);

  const initialData = {
    modalDateOpen: "",
    isValidCode: false,
    couponName: "",
    discountTypeOption: options.discountUnit.PERCENT.value,
    discountLimitOption: options.limit.UNLIMIT.value,
    limitUserOption: options.limitUserUnitOption.UNLIMIT.value,
    stockMaxOption: options.limit.UNLIMIT.value,
    generateLimitOption: options.limit.UNLIMIT.value,
    limitGlobalOption: options.limit.UNLIMIT.value,
    quantityMaxDiscount: "",
    stockMax: "",
    quantityDealing: "",
    limitGlobal: "",
    limitGlobalUnit: options.fequencyUnit[0].value,
    limitUser: "",
    usedCouponType: 0,
    limitUserType: 0,
    amoutReached: "",
    discount: "",
    collectStartDate: moment(),
    collectStartTime: moment('00:00', 'HH:mm'),
    collectEndDate: moment(),
    collectEndTime: moment('23:59', 'HH:mm'),
    usedStartDate: moment().set({ hours: 0, minutes: 0 }),
    usedStartTime: moment('00:00', 'HH:mm'),
    usedEndDate: moment().set({ hours: 23, minutes: 59 }),
    usedEndTime: moment('23:59', 'HH:mm'),
    productCode: "",
    costSharingPlatform: 100,
    costSharingPartner: 0,
    costSharingMall: "",
    productType: options.productType.ONLINE.value,
    productCategory: options.productCategory.COUPON.value,
    discountUnit: options.discountUnit.PERCENT.value,
    discountValue: "",
    purchaseMin: "",
    discountOption: options.discountOption.PRODUCT.value,
    clientLevel: 3,
    listShop: [],
    shopId: shopSelected?.id,
    status: "show",
    productChannel: options.productChannel.DEAL.value,
    price: "",
    priceBefore: "",
    description: "",
    discountLimit: "",
    generateLimit: "",
    validType: options.validType.PERIOD.value,
    validDay: "",
    codeFormatType: options.codeFormatType.BASIC.value,
    codeFormatOption: options.codeFormatOption.NUMBER.value,
    codeFormatLength: options.codeFormatLength[4].value,
    codeFormatValue: "",
    textBenefit: "",
    textTerms: "",
    textHowto: "",
    textRemark: "",
    mediaCover: { id: 247, path: "https://thumb.izcene.com/saranros/300x300/image/154e7c0f-1475-4e26-9992-7cbd8bbcf7b5.png" }
  };

  const [elementName, setElementName] = useState("");
  const [promoCodeDetail, setPromoCodeDetail] = useState(null);
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [productJoin, setProductJoin] = useState('all')
  const [shopJoin, setShopJoin] = useState('allShop')


  useEffect(() => {
    if (!_.isEmpty(promoCode?.itemJoin?.skus)) {
      setProductJoin('some')
    }
  }, [promoCode?.itemJoin])


  useEffect(() => {
    if (joinSelectedShop?.countAllSKU) {
      setShopJoin('someShop')
    } else {
      setShopJoin('allShop')
    }
  }, [joinSelectedShop?.countAllSKU])


  useEffect(() => {
    initialComponent();

    dispatch(
      {
        type: GET_SHOPS,
        payload: {
          limit: 10,
          page: 1,
          sortBy: 'name'
        },
      },
    )

    return () => unmountComponent()
  }, []);

  useEffect(() => {
    if (clientLevel && clientLevel.length > 0)
      provideClientLevel(clientLevel);
  }, [clientLevel])

  const scrollToPosition = async (id, number) => {
    const ele = await document?.getElementById(id);
    const blockStart = ele?.getBoundingClientRect().top + window.pageYOffset - number;
    window.scrollTo({ top: blockStart, behavior: 'smooth' });
  }

  useEffect(() => {
    if (couponDetail && window.location.pathname.includes('edit')) {
      const { productCategory,
        productCode,
        productType,
        stockMax,
        discountUnit,
        discountValue,
        logicData,
        limitGlobal,
        productChannel,
        description,
        validValue,
        textBenefit,
        textTerms,
        textHowto,
        textRemark,
        generateLimit,
        codeFormat,
        price,
        priceBefore,
      } = couponDetail || {};
      const { status,
        couponType,
        costSharingPlatform,
        costSharingPartner } = couponDetail?.coupon || {};
      const { name,
        limitUser,
        startDate,
        stopDate,
        levelIds,
        shopId,
        collectStartDate,
        collectStopDate } = couponDetail?.coupon?.promotion || {};

      let productCodeItem = productCode;
      if (window.location.pathname.includes('add')) {
        productCodeItem = `${shopSelected?.shopCode}-${productType === options.productType.ONLINE.value ?
          "1" : "2"}-${productCode}`
      }


      setData({
        ...data,
        isValidCode: true,
        shopId: shopId,
        productCategory: productCategory,
        couponName: name,
        couponType: couponType,
        productCode: productCodeItem,
        discountTypeOption: discountUnit === 'number' ? options.discountUnit.NUMBER : options.discountUnit.PERCENT.value,
        discountLimitOption: logicData?.discountLimit ? options.limit.LIMIT.value : options.limit.UNLIMIT.value,
        discountLimit: logicData?.discountLimit ? logicData?.discountLimit : '',
        stockMaxOption: stockMax === 999999 ? options.limit.UNLIMIT.value : options.limit.LIMIT.value,
        stockMax: stockMax === 999999 ? "" : stockMax,
        limitGlobalOption: limitGlobal?.value === stockMax && limitGlobal?.unit === options.fequencyUnitOption.MONTH.value ?
          options.fequencyUnitOption.UNLIMIT.value :
          options.fequencyUnitOption[limitGlobal?.unit.toUpperCase()]?.value,
        limitGlobalUnit: limitGlobal?.unit,
        limitGlobal: (limitGlobal?.value === stockMax && limitGlobal?.unit === options.fequencyUnitOption.MONTH.value) ?
          "" : limitGlobal?.value,
        price: price,
        priceBefore: priceBefore,
        costSharingPlatform,
        costSharingPartner,
        collectStartDate: moment(collectStartDate),
        collectEndDate: moment(collectStopDate),
        collectStartTime: moment(collectStartDate),
        collectEndTime: moment(collectStopDate),
        usedStartDate: moment(startDate),
        usedEndDate: moment(stopDate),
        // usedStartTime: moment(startDate),
        // usedEndTime: moment(stopDate),
        discountUnit: discountUnit,
        discountValue: discountValue,
        purchaseMin: logicData?.purchaseMin || 0,
        status: status,
        validType: validValue.day ? options.validType.DAY.value : options.validType.PERIOD.value,
        validDay: validValue.day ? validValue.day : "",
        limitUserType: limitUser ? provideLimitUserType(limitUser.unit) : 0,
        limitUser: limitUser ? limitUser.value : "",
        limitUserOption: limitUser ? limitUser?.unit : options.fequencyUnitOption.UNLIMIT.value,
        clientLevel: levelIds.length === 1 ? levelIds[0] : 3,
        productType: productType,
        productChannel: productChannel,
        description: description,
        codeFormatType: codeFormat?.name === options.codeFormatType.FIXED.value ? options.codeFormatType.FIXED.value : options.codeFormatType.BASIC.value,
        codeFormatValue: codeFormat?.name === options.codeFormatType.FIXED.value ? codeFormat.fixed : "",
        generateLimitOption: generateLimit === 999999 ? options.generateLimit.UNLIMIT.value : options.generateLimit.LIMIT.value,
        generateLimit: generateLimit === 999999 ? "" : generateLimit,
        textBenefit: textBenefit,
        textTerms: textTerms,
        textHowto: textHowto,
        textRemark: textRemark,
      })
    }
  }, [couponDetail]);

  useEffect(() => {
    setPromoCodeDetail(modalDetailPromocode.data);
  }, [modalDetailPromocode]);

  useEffect(() => {
    if (modalMessage) {
      if (modalMessage.code === 'PRODUCT_CODE_ALREADY_EXISTS') {
        setErrors({ ...errors, productCode: { message: modalMessage.message } })
      }
      if (modalMessage.code === 'PRODUCT_ALREADY_EXISTS') {
        setErrors({ ...errors, codeFormatValue: { message: modalMessage.message } })
      }
    }
  }, [modalMessage])
  
  useEffect(() => {
    const areHoursAndMinutesEqual = (
      moment(data.collectEndTime).hours() === moment(data.collectStartTime).hours() &&
      moment(data.collectEndTime).minutes() === moment(data.collectStartTime).minutes()
    );
    const areHoursAndMinutesBefore = (
      moment(data.collectEndTime).hours() <= moment(data.collectStartTime).hours() 
    );
    const collectStartTime = moment(data.collectStartTime);
    const collectEndTime = moment(data.collectEndTime);

   if(moment(data.collectEndDate).isSame(moment(data.collectStartDate), 'day') && areHoursAndMinutesEqual){
    setData({ ...data, collectEndDate: moment(data.collectStartDate).add({ day: 1 }) });
   }
   if(moment(data.collectEndDate).isSame(moment(data.collectStartDate), 'day') && areHoursAndMinutesBefore){
    setData({
      ...data,
      collectStartTime: collectStartTime.set({ hours: 0, minutes: 0 }),
      collectEndTime: collectEndTime.set({ hours: 23, minutes: 59 }),
    });
   }
   if (moment(data.collectEndDate) < moment(data.collectStartDate)){
    setData({ ...data, collectEndDate: moment(data.collectStartDate) });
  }
  }, [data.collectStartDate, data.collectStartTime, data.collectEndDate, data.collectEndTime])

  useEffect(() => {
    if (moment(data.usedEndDate).isSame(moment(data.usedStartDate), 'day')) {
      const updatedEndDate = moment(data.usedEndDate).set({ hours: 23, minutes: 59 });
      
      if (!moment(updatedEndDate).isSame(moment(data.usedEndDate))) {
        setData({ ...data, usedEndDate: updatedEndDate });
      }
    }
    if (moment(data.usedEndDate) < moment(data.usedStartDate)){
      setData({ ...data, usedEndDate: moment(data.usedStartDate).add({ hours: 23, minutes: 59 }) });
    }
  }, [data.usedStartDate, data.usedEndDate]);

  const initialComponent = () => {
    dispatch({ type: GET_CLIENT_LEVEL, payload: {} })
  }

  const unmountComponent = () => {
    setData(initialData);
    dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'couponDetail', value: null } });
    dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'itemJoin', value: {} } });
    dispatch({ type: RESET_PRODUCT_SELECTED_JOIN_SHOP });
    dispatch({ type: RESET_SELECTED_SHOP })

  }

  const provideLimitUserType = (unit) => {
    switch (unit) {
      case "day": return 0;
      case "week": return 1;
      case "month": return 2;
      default: return null
    }
  }

  const provideGlobalUnit = limitGlobal => {
    const value = limitGlobal.value;
    let unit = '';
    // eslint-disable-next-line default-case
    switch (limitGlobal.unit) {
      case 'day': unit = 'วัน';
        break;
      case 'week': unit = 'สัปดาห์';
        break;
      case 'month': unit = 'เดือน';
        break;
      case 'promotion': unit = 'โปรโมชั้น';
        break;
    }
    return `จำนวน ${numeral(value).format('0,0')} ต่อ ${unit}`
  }

  const provideClientLevel = clientLevels => {
    let optionsAPI = clientLevels.reduce((total, levelItem) => {
      let collection = total;
      collection[levelItem.name] = { ...levelItem, value: levelItem.id };
      let label = '';
      // eslint-disable-next-line default-case
      switch (levelItem.name) {
        case 'normal': label = 'เฉพาะลูกค้าที่ไม่ใช่ AIS'; break;
        case 'ais': label = 'เฉพาะลูกค้า AIS'; break;
      }
      collection[levelItem.name].label = label;
      return collection;
    }, {});

    let options = {
      all: {
        level: 3,
        name: "all",
        id: 3,
        value: 3,
        label: "ลูกค้าทั้งหมด",
      }
    }

    options = { ...options, ais: optionsAPI.ais, normal: optionsAPI.normal }

    setData({ ...data, clientLevelOption: options, });
  }

  const provideCouponParam = async () => {
    const {
      limitUserOption,
      stockMaxOption,
      couponName,
      productCode,
      purchaseMin,
      discountOption,
      costSharingPartner,
      costSharingPlatform,
      productCategory,
      collectStartDate,
      collectStartTime,
      collectEndDate,
      collectEndTime,
      usedStartDate,
      usedStartTime,
      usedEndDate,
      usedEndTime,
      limitUser,
      clientLevel,
      shopId,
      productType,
      description,
      productChannel,
      discountValue,
      discountUnit,
      stockMax,
      validType,
      validDay,
      generateLimit,
      limitGlobal,
      codeFormatType,
      codeFormatValue,
      limitGlobalOption,
      generateLimitOption,
      mediaCover,
      discountLimit,
      status } = data;

    const provideDataTime = (date, time) => new Date(`${date} ${time}`).toISOString();

    const startDateTimeCollect = provideDataTime(moment(collectStartDate).format('MM/DD/YYYY'),
      moment(collectStartTime).format('HH:mm'));
    const endDateTimeCollect = provideDataTime(moment(collectEndDate).format('MM/DD/YYYY'),
      moment(collectEndTime).format('HH:mm'));
    const startDateTimeUsed = provideDataTime(moment(usedStartDate).format('MM/DD/YYYY'),
      moment(usedStartTime).format('HH:mm'));
    const endDateTimeUsed = provideDataTime(moment(usedEndDate).format('MM/DD/YYYY'),
      moment(usedEndTime).format('HH:mm'));

    let clientLevels = [clientLevel]
    if (clientLevel === 3) clientLevels = [1, 2]

    let couponDataTemplate = {
      name: couponName,
      imageId: mediaCover?.id,
      description: description,
      startDate: startDateTimeUsed,
      stopDate: endDateTimeUsed,
      couponAction: discountOption,
      status: status,
      levelIds: clientLevels,
    }

    if (isPlatform) {
      couponDataTemplate.costSharingPartner = costSharingPartner;
      couponDataTemplate.costSharingPlatform = costSharingPlatform;
    } else {
      couponDataTemplate.costSharingPartner = 100;
      couponDataTemplate.costSharingPlatform = 0;
    }

    if (limitUserOption !== options.limitUserUnitOption.UNLIMIT.value)
      couponDataTemplate.limitUser = {
        value: limitUser,
        unit: limitUserOption,
      }

    if (window.location.pathname.includes('edit') &&
      limitUserOption === options.limitUserUnitOption.UNLIMIT.value) {
      couponDataTemplate.limitUser = null;
    }

    let paramUpdate = { couponData: { ...couponDataTemplate } }
    let paramCreate = {
      couponData: {
        ...couponDataTemplate,
        campaignId: 1,
        shopId: shopId
      }
    }

    const _stockMax = stockMaxOption === options.limit.LIMIT.value ? stockMax : 999999;

    let codeFormat = { name: options.codeFormatType.BASIC.value }
    if (productType === options.productType.ONLINE.value) {
      codeFormat.fixed = codeFormatValue;
      codeFormat.name = options.codeFormatType.FIXED.value;
    }

    let logicData = { purchaseMin: purchaseMin };
    if (data.discountUnit === options.discountUnit.PERCENT.value &&
      data.discountLimitOption === options.limit.LIMIT.value)
      logicData.discountLimit = discountLimit;

    let productCodeItem = productCode;
    if (window.location.pathname.includes('add')) {
      productCodeItem = `${shopSelected?.shopCode}-${productType === options.productType.ONLINE.value ?
        "1" : "2"}-${productCode}`
    }


    let productEvoucherDataTemplate = {
      productType: productType,
      productChannel: productChannel,
      productCode: productCodeItem,
      price: 1,
      priceBefore: 2,
      name: couponName,
      shopCode: shopSelected?.shopCode,
      description: description,
      productCategory: productCategory,
      discountValue: discountValue,
      discountUnit: discountUnit,
      sellingStartAt: startDateTimeCollect,
      sellingStopAt: endDateTimeCollect,
      stockMax: _stockMax,
      validType: validType,
      textBenefit: "-",
      textTerms: "-",
      textHowto: "-",
      textRemark: "-",
      mediaCover: { path: mediaCover?.path, id: mediaCover?.id },
      codeUnique: codeFormatType === options.codeFormatType.BASIC.value,
      validValue: validType === options.validType.DAY.value ?
        { day: validDay } :
        {
          start: startDateTimeUsed,
          end: endDateTimeUsed
        },
      logicData: logicData,
      codeFormat: codeFormat,
      status: status === 'show' ? 'active' : 'inactive',
      limitGlobal: {
        value: limitGlobalOption === options.fequencyUnitOption.UNLIMIT.value ?
          999999 : limitGlobal,
        unit: limitGlobalOption === options.fequencyUnitOption.UNLIMIT.value ?
          options.fequencyUnitOption.MONTH.value : limitGlobalOption
      },
      generateLimit: generateLimitOption === options.generateLimit.UNLIMIT.value ? _stockMax : generateLimit,
    }

    if (!isPlatform)
      productEvoucherDataTemplate.shopCode = shopSelected?.shopCode

    paramCreate.productEvoucherData = {
      ...productEvoucherDataTemplate,
      productCategory: options.productCategory.DISCOUNT.value
    }
    paramUpdate.productEvoucherData = { ...productEvoucherDataTemplate }

    // if (productJoin == 'some' && !_.isEmpty(promoCode?.itemJoin?.skus)) {
    //   paramCreate.itemJoin = promoCode?.itemJoin;
    //   paramUpdate.itemJoin = promoCode?.itemJoin;
    // }
    if(isPlatform) {
      if ((shopJoin == 'someShop') && (!_.isEmpty(promoCode?.itemJoin?.skus) || !_.isEmpty(promoCode?.itemJoin?.shops))) {
        paramCreate.itemJoin = promoCode?.itemJoin;
        paramUpdate.itemJoin = promoCode?.itemJoin;
      }
    } else {
      if ((productJoin == 'some') && !_.isEmpty(promoCode?.itemJoin?.skus)) {
        paramCreate.itemJoin = promoCode?.itemJoin;
        paramUpdate.itemJoin = promoCode?.itemJoin;
      }
    }

    return window.location.pathname.includes('add') ? paramCreate : paramUpdate;
  }

  const validateForm = () => {
    const requireMessage = "กรุณากรอกข้อมูล";

    let _errors = {};
    let requireFields = ["productCategory",
      "productType",
      "productChannel",
      "productCode",
      "couponName",
      "description",
      "mediaCover",
      "purchaseMin",
      "discountValue",
      "clientLevel",
      "validType",
    ];

    if (isPlatform) {
      requireFields = [...requireFields, "costSharingPlatform", "costSharingPartner"]
    }

    const convertDateTimeMoment = (_date, _time) => {
      const date = moment(_date).format('MM/DD/YYYY');
      const time = moment(_time).format('HH:mm');
      return moment(`${date} ${time}`);
    }
    const collectStart = convertDateTimeMoment(data.collectStartDate, data.collectStartTime);
    const collectEnd = convertDateTimeMoment(data.collectEndDate, data.collectEndTime);
    const usedStart = convertDateTimeMoment(data.usedStartDate, data.usedStartTime);
    const usedEnd = convertDateTimeMoment(data.usedEndDate, data.usedEndTime);


    if (_.isEmpty(promoCode?.itemJoin?.skus) && productJoin == 'some') {
      requireFields = [...requireFields, "productJoin"]
    }
    if (shopJoin == 'someShop') {
      console.log(promoCode?.itemJoin?.skus?.length, promoCode?.itemJoin?.shops?.length, !(promoCode?.itemJoin?.skus?.length + promoCode?.itemJoin?.shops?.length))
      if (!(promoCode?.itemJoin?.skus?.length + promoCode?.itemJoin?.shops?.length)) {
        requireFields = [...requireFields, "shopJoin"]
      }
    }



    if (collectStart > collectEnd) _errors['collectStartDate'] = { message: "วันที่เริ่มต้องน้อยกว่าวันที่สิ้นสุด" };
    if (usedStart > usedEnd) _errors['usedStartDate'] = { message: "วันที่เริ่มต้องน้อยกว่าวันที่สิ้นสุด" };

    if (data.discountUnit === options.discountUnit.PERCENT.value &&
      data.discountLimitOption === options.limit.LIMIT.value)
      requireFields = [...requireFields, "discountLimit"]

    if (data.stockMaxOption === options.limit.LIMIT.value)
      requireFields = [...requireFields, "stockMax"]

    if (data.generateLimitOption === options.generateLimit.LIMIT.value)
      requireFields = [...requireFields, "generateLimit"]

    if (data.limitGlobalOption === options.limit.LIMIT.value)
      requireFields = [...requireFields, "limitGlobal"]

    if (data.validType === options.validType.DAY.value)
      requireFields = [...requireFields, "validDay"]

    if (data.productType === options.productType.ONLINE.value)
      requireFields = [...requireFields, "codeFormatValue"]

    if (data.limitUserOption === options.limit.LIMIT.value)
      requireFields = [...requireFields, "limitUser"]

    requireFields.forEach(field => {
      if (isEmpty(data[field]))
        _errors[field] = { message: requireMessage };
    })

    setErrors(_errors);
    return _errors
  };

  const cancelSubmit = () => {
    setData({ ...initialData });
    closeConfirmModal();
    let redirectPath = '/manage/promocode/';

    if (!userInfo.shopId) {
      if (isPlatform) {
        redirectPath = `${redirectPath}platform/`;
      }
      else {
        redirectPath = `${redirectPath}mall/`;
      }
    }

    history.push({ pathname: redirectPath, });
  }

  const closeConfirmModal = () => {
    dispatch({
      type: OPEN_MODAL_CONFIRM_PROMOCODE,
      payload: { isOpen: false, message: '', confirm: () => { } }
    })
  }

  const handleCostSharingChange = (key, _value) => {
    let value = _value;
    if (_value > 100) value = 100;
    if (_value < 0) value = 0;
    if (key === elementName) {
      if (key === "costSharingPartner")
        setData({ ...data, [key]: value, costSharingPlatform: (100 - value) });
      if (key === "costSharingPlatform")
        setData({ ...data, [key]: value, costSharingPartner: (100 - value) });
    }
  }

  const handleDataChange = (key, value) => {
    if (key === 'productType' && value === options.productType.BATCH.value) {
      setData({ ...data, codeFormatType: options.codeFormatType.BASIC.value, [key]: value });
    } else {
      setData({ ...data, [key]: value });
    }
  }

  const handleDataLetterChange = (key, value) => {
    const regex = /^(?:[A-Za-z0-9]+|\d+)$/;
    if (regex.test(value) || value === "")
      setData({ ...data, [key]: value });

  }

  const handleCollectStartDateTimeChannge = (key, value) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case "date":
        setData({ ...data, collectStartDate: value, modalDateOpen: "" });
        break;
      case "time":
        setData({ ...data, collectStartTime: value });
        break;
    }
  }

  const handleCollectEndDateTimeChannge = (key, value) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case "date":
        setData({ ...data, collectEndDate: value, modalDateOpen: "" });
        break;
      case "time":
        setData({ ...data, collectEndTime: value });
        break;
    }
  }

  const handleUsedStartDateTimeChannge = (key, value) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case "date":
        setData({ ...data, usedStartDate: value, modalDateOpen: "" });
        break;
      case "time":
        setData({ ...data, usedStartTime: value });
        break;
    }
  }

  const handleUsedEndDateTimeChannge = (key, value) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case "date":
        setData({ ...data, usedEndDate: value, modalDateOpen: "" });
        break;
      case "time":
        setData({ ...data, usedEndTime: value });
        break;
    }
  }

  const handleCancelSubmit = () => {
    dispatch(
      {
        type: OPEN_MODAL_CONFIRM_PROMOCODE,
        payload: {
          isOpen: true, message: 'ยกเลิกการแก้ไขคูปอง',
          confirm: () => cancelSubmit(),
          isClose: () => closeConfirmModal()
        }
      })
    dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'managePromocode', value: 'create' } })
  }

  const handleSuccessComfirmModal = () => {

    let redirectPath = '/manage/promocode/';

    if (!userInfo.shopId) {
      if (isPlatform) {
        redirectPath = `${redirectPath}platform/`;
      }
      else {
        redirectPath = `${redirectPath}mall/`;
      }
    }

    // dispatch({
    //   type: OPEN_MODAL_MESSAGE_PROMOCODE,
    //   payload: { isOpen: false, message: '', title: 'iconSuccess' }
    // })

    history.push({
      pathname: redirectPath,
      state: { shopItemSelected: promoCode.shopSelected }
    })
  }

  const handleManageCoupon = async () => {
    let payload = await provideCouponParam();
    payload.handleSuccessComfirmModal = handleSuccessComfirmModal;
    const err = validateForm();

    if (Object.keys(err).length === 0) {
      if (window.location.pathname.includes('add')) {
        dispatch({ type: CREATE_COUPON, payload: payload })
      } else {
        dispatch({ type: UPDATE_COUPON, payload: { data: payload, id: couponDetail?.coupon?.id, handleSuccessComfirmModal } })
      }
    } else {
      const orderErrList = ['couponName', 'description', 'productCode', 'codeFormatValue', 'purchaseMin', 'discountValue', 'productJoin', 'shopJoin'];

      let errList = []
      orderErrList.map((item) => {
        const index = Object.keys(err)?.findIndex((err) => err === item)
        if (index > -1) {
          errList.push(item)
        }
      });

      if (errList?.length > 0) {
        scrollToPosition(`${errList[0]}`, 80)
        dispatch(
          dispatchModalAlert({
            type: OPEN_MODAL_ALERT,
            payload: { isOpen: true, message: "กรุณากรอกข้อมูลที่จำเป็นให้ครบ", type: 'alert' },
          })
        );
      }
    }
  };

  const handleSelectProductJoint = async (value) => {
    if (value == 'some') {

      let err = { ...errors }
      if (err?.productJoin) {
        delete err?.productJoin
      }
      setErrors(err)

      try {
        dispatch({ type: 'SET_LOADING', payload: { loading: true } })
        const _productProvider = new productProvider();
        const res = await _productProvider.getListAllProduct({ scope: 'count', shopId: shopSelected?.id })



        if (res?.count === 0) {
          dispatch(dispatchModalMessage({
            type: OPEN_MODAL_MESSAGE,
            payload: {
              message: 'คุณยังไม่มีสินค้า กดปุ่มสร้างสินค้าใหม่ เพื่อใช้งานกับสินค้าบางรายการ หรือกดปุ่มยืนยัน เพื่อให้ระบบเลือกสินค้าทั้งหมดเข้าร่วมโปรโมชั่น',
              title: "iconFail",
              btnLabel: 'ยืนยัน',
              btnCancel: 'สร้างสินค้าใหม่'
            },
          }));
        } else {
          setProductJoin(value)
        }

        dispatch({ type: 'SET_LOADING', payload: { loading: false } })

      } catch (err) {
        console.log(err);
        dispatch({ type: 'SET_LOADING', payload: { loading: false } })
      }
    } else {
      // all
      setProductJoin(value)
    }
  }

  const handleRedirectToProduct = () => {
    window.location.href = '/manage/product'
    localStorage.setItem("isEmptyProduct", true);
  }

  const handleOpenModalSelectedProducts = () => {
    dispatch({
      type: SET_KEY_VALUE_PROMOCODE,
      payload: {
        key: 'isShowModalSelectedProduct',
        value: true
      }
    })
  }
  useEffect(() => {
    if (window.location.pathname.includes('add') && isPlatform) {
      localStorage.setItem("itemjoin", JSON.stringify({ countAllShop: 0, countAllSKU: 0, countAllProduct: 0, shops: {} }));
      dispatch({ type: ADD_KEY_VALUE_SELECTEDED_JOIN_SHOP, payload: { key: null, value: { countAllShop: 0, countAllSKU: 0, countAllProduct: 0, shops: {} } } })
      if (joinSelectedShop?.countAllSKU) {
        setShopJoin('someShop')
      }
    }


    return (() => {
      // localStorage.setItem("itemjoin", JSON.stringify({ countAllShop: 0, countAllSKU: 0, countAllProduct: 0, shops: { } }));
    })
  }, [])
  const handleOpenModalSelectedShop = () => {
    dispatch({
      type: OPEN_MODAL_SELECTED_SHOP
    })
  }

  const handleSelectShopJoint = async (value) => {
    setShopJoin(value)
  }


  return {
    verifyCode,
    modalDetailPromocode,
    promoCode,
    joinSelectedShop,
    selectedShop,
    modalConfirm,
    promoCodeDetail,
    managePromocode,
    modalAlert,
    shopSelected,
    data,
    errors,
    productJoin,
    modalMessage,
    shopJoin,
    setElementName,
    provideGlobalUnit,
    handleCostSharingChange,
    handleCancelSubmit,
    handleManageCoupon,
    handleDataChange,
    handleDataLetterChange,
    handleCollectStartDateTimeChannge,
    handleCollectEndDateTimeChannge,
    handleUsedStartDateTimeChannge,
    handleUsedEndDateTimeChannge,
    handleSuccessComfirmModal,
    validateForm,
    handleSelectProductJoint,
    handleRedirectToProduct,
    handleOpenModalSelectedProducts,
    handleOpenModalSelectedShop,
    handleSelectShopJoint
  };
};

