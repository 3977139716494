import React, { Component } from 'react'
import './index.scss'
import iBanking from '../../images/icons/i-banking.svg'
import credit from '../../images/icons/credit.svg'
import cod from '../../images/icons/cod.svg'
import transfer from '../../images/icons/transfer.svg'
import True from '../../images/icons/true.svg'
import QRCode from '../../images/icons/qrcode.svg'
import IconPrint from '../../images/icons/BtnPrint.svg'
import IconDigitalProduct from '../../images/icons/digitalProductLogo.svg'
// import iconCopy from "../../images/icons/copy-2.svg";
import { UncontrolledTooltip } from 'reactstrap';
import numeral from 'numeral'
import { differenceDate, displayDateTh, displayDateThShortYear } from '../../helper/timeUtil'
import classnames from 'classnames'
import { connect } from 'react-redux'
import noItem from '../../images/icons/status-error.svg'
import host from '../../config/host'
// import productProvider from 'provider/productProvider'
import { dispatchProduct } from 'redux/actions/product'
import {
    dispatchOrder,
    OPEN_MODAL_INVOICE_DETAIL,
    SET_CLEARING_ORDER,
    ADD_CLEARING_ORDER,
    SELECT_ALL_CLEARING_ORDER,
    CLEAR_CLEARING_ORDER,
    // SET_SELECT_ALL_CLEARING,
    // OPEN_MODAL_CONFIRM_REFUND,
    // CLOSE_MODAL_CONFIRM_REFUND,
    CLOSE_MODAL_CONFIRM_CLEARING_ALL,
    OPEN_MODAL_CONFIRM_CLEARING_ALL,
    OPEN_MODAL_COVER_SHEET_PRINT,
    GET_ORDER_DELIVERIES
} from '../../redux/actions/order'
// import { Checkbox } from 'antd'
import { dispatchModalConfirm } from 'redux/actions/modalConfirm'
import ModalConfirm from 'components/modalConfirm/ModalConfirm'
// import modalConfirm from 'redux/reducers/modalConfirm'
import { GET_LIST_LOGISTICS_BY_SHOP } from 'redux/actions/product'
import TableHeader from 'components/TableHeader'

// const paymentStatus = ['wait', 'cod', 'transfer', 'paid', 'failed', 'approved']
// const shipmentStatus = ['wait', 'prepare', 'dispatch']
// const logisticStatus = ['wait', 'posting', 'intransit', 'redelivery', 'outdelivery', 'delivered', 'issues', 'return']

class ListOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // tooltipOpen: false
        }
    }

    checkTypePayment = (type) => {
        // if(type == "")
    }

    // toggle = () => {
    //     this.setState({ tooltipOpen: !this.state.tooltipOpen })
    // }

    checkDetailPayment = (type) => {
        if (type === "creditcard") { return "บัตรเครดิต" }
        else if (type === "ibanking") { return "อินเตอร์เน็ตแบงก์กิ้ง" }
        else if (type === "cod") { return "เก็บเงินปลายทาง" }
        else if (type === "transfer") { return "โอนผ่านธนาคาร" }
        else if (type === "qrcode") { return "QR Code" }
        else { return type }
    }

    checkpaymentStatus = (type, shipmentStatus) => {
        if (shipmentStatus === "waitconfirm") {
            return <label className="wait-payment">รอรับออเดอร์</label>
        }
        if (type === "wait") { return <label className="wait-payment">รอชำระเงิน</label> }
        else if (type === "transfer") { return <label className="wait-approve">รอตรวจสอบ</label> }
        else if (type === "approved" || type === "paid") { return <label className="success-order"> <img src={True} alt="check" className="check-success-pay pl-2" /> ชำระเงินแล้ว</label> }
        else if (type === "failed") { return <label className="cancel-order">ยกเลิก</label> }
        else { return type }
    }

    checkLogisticStatus = (type, shipmentStatus) => {
        if (shipmentStatus === "waitconfirm") {
            return <label className="wait-payment">รอรับออเดอร์</label>
        }
        if (type === "wait" || type === "redelivery") { return <label className="wait-approve">รอจัดส่ง</label> }
        else if (type === "posting" || type === "outdelivery") { return <label className="wait-approve">อยู่ระหว่างขนส่ง</label> }
        else if (type === "intransit") { return <label className="wait-approve"> อยู่ระหว่างขนส่ง</label> }
        else if (type === "delivered") { return <label className="success-order"><img src={True} alt="check" className="check-success-pay" /> สำเร็จ</label> }
        else if (type === "issues" || type === "return") { return "ยกเลิก" }
        ////refund
        else if (type === "request") { return <label className="wait-approve">รอการคืนเงิน</label> }
        else if (type === "refunded") { return <label className="success-order">คืนเงินแล้ว</label> }
        ////refund
        else { return type }
    }

    checkShipmentStatus = (type) => {
        switch (type) {
            case 'never':
                return "รอรับออเดอร์"
            default:
                return null
        }
    }

    setCurrentInvoice = invoice => {
        this.props.dispatch(dispatchOrder({ type: OPEN_MODAL_INVOICE_DETAIL, payload: { currentInvoice: invoice, isOpen: true } }));
        this.props.dispatch(dispatchProduct({ type: GET_LIST_LOGISTICS_BY_SHOP }))
        this.props.dispatch(dispatchOrder({ type: GET_ORDER_DELIVERIES, payload: { orderId: invoice.id } }))
    }

    onAddOrderClearing = (id) => {
        this.props.dispatch(dispatchOrder({ type: ADD_CLEARING_ORDER, payload: { id } }))

    }

    onConfirmClearing = () => {
        this.props.dispatch(dispatchOrder({ type: SET_CLEARING_ORDER, payload: { search: this.props.search, filter: this.props.filter } }))
    }

    handleConfirmClearing = () => {
        this.props.dispatch(dispatchModalConfirm({
            type: OPEN_MODAL_CONFIRM_CLEARING_ALL, payload: {
                isOpen: true,
                message: 'กรุณายืนยันว่าโอนเงินให้ร้านค้าเรียบร้อยแล้ว',
                btnConfirm: 'ยืนยัน',
                btnCancel: 'ยกเลิก'
            }
        }))
    }

    handleSelectAllClearingOrder = () => {
        const arr = []
        const oldOrder = this.props.clearingOrder.length

        this.props.order.listOrder.data.map(item => {
            if (!item.clearingAt) {
                arr.push(item.id)
            }
            return arr
        })
        if (arr.length === oldOrder) {
            //remove order
            this.props.dispatch(dispatchOrder({ type: CLEAR_CLEARING_ORDER }))
            // this.props.dispatch(dispatchOrder({ type: SET_SELECT_ALL_CLEARING, payload: !this.props.order.selectAllClearingOrder }))
        } else {
            this.props.dispatch(dispatchOrder({ type: SELECT_ALL_CLEARING_ORDER }))
        }
    }

    checkRefundStatus = (type, logistic) => {
        
        if (type === 'request' || type === "refunded" || type === "return" ||  type === "approved") {
            if (type === "request") return 'รออนุมัติคืนเงิน'
            else if (type === "approved") return 'กำลังดำเนินการคืนเงิน'
            else if (type === "return") return 'รอคืนสินค้า'
            else if (type === "refunded") return 'คืนเงินแล้ว'
        } else {
            if (logistic === "wait") { return "รอจัดส่ง" }
            else if (logistic === "posting" || logistic === "intransit" || logistic === "redelivery" || logistic === "outdelivery") { return "อยู่ระหว่างขนส่ง" }
            else if (logistic === "delivered") { return "สำเร็จ" }
            else if (logistic === "issues" || logistic === "return") { return "ยกเลิก" }
            else { return logistic }
        }
    }

    openModalCoverSheet = (event, currentInvoice) => {
        this.props.dispatch(dispatchOrder({ type: OPEN_MODAL_COVER_SHEET_PRINT, payload: { currentInvoice, isOpen: true } }));
        event.stopPropagation();
    }

    // checkOrderClearingAll = () => {
    //     const arr = []
    //     const oldOrder = this.props.clearingOrder.length
    //     this.props.order.listOrder.data.map(item => {
    //         if (!item.clearingAt) {
    //             arr.push(item.id)
    //         }
    //     })
    //     if (arr.length == oldOrder) {
    //         //remove order
    //     } else {
    //     }
    // }

    replaceSpaceWord = (text) => {
        var result = text.split(' ').join('')
        return result
    }


    showMethodPayment = (detail) => {
        switch (detail.paymentMethod) {
            case 'transfer':
                return <img src={transfer} alt="" id={'typePay-' + detail.id} />
            case 'ibanking':
                return <img src={iBanking} alt="" id={'typePay-' + detail.id} />
            case 'cod':
                return <img src={cod} alt="" id={'typePay-' + detail.id} />
            case 'creditcard':
                return <img src={credit} alt="" id={'typePay-' + detail.id} />
            case 'qrcode':
                return <img src={QRCode} alt="" id={'typePay-' + detail.id} />
            default:
                return null
        }
    }

    render() {
        // const { tooltipOpen } = this.state
        const { item, order, loading, status, modalConfirmClearingAll } = this.props

        return (
            <React.Fragment>
                {item && item.data?.length === 0 && !loading ? <div className="d-flex flex-column align-items-center justify-content-center mt-4 h-50vh">
                    <img src={noItem} alt="noItem" />
                    <span className="mt-2 font-secondary">ไม่มีรายการ</span>
                </div> :
                    <React.Fragment>

                        {/* {status == "success" ? <div className="select-all-clearing pr-3 d-flex flex-row align-items-center">
                            <div>
                                {order.listOrder?.data.filter(item => !item.clearingAt).length == 0 ? null :
                                    <React.Fragment><Checkbox onClick={() => this.handleSelectAllClearingOrder()} checked={order.listOrder?.data?.filter((item) => !item.clearingAt).length == clearingOrder.length} /> <span>เลือกทั้งหมด</span>
                                    </React.Fragment>
                                }
                                 <button 
                                 className={classnames('ml-3',{'btn-disable-clearing':order.listOrder?.data.filter((item) => !item.clearingAt).length == 0 || clearingOrder.length == 0,'btn-submit': clearingOrder.length > 0})} 
                                 onClick={() => this.handleConfirmClearing()} 
                                 disabled={clearingOrder.length == 0}>โอนเงินให้ร้านค้าแล้ว</button>
                            </div>
                        </div> : null} */}


                        <TableHeader
                            type={'header'}
                            dataHeader={[
                                'ลำดับ',
                                'หมายเลขออเดอร์',
                                'เลขอ้างอิง Transaction',
                                'ชื่อร้านค้า',
                                'ชื่อลูกค้า',
                                'ยอดเงิน',
                                <div> วันที่ชำระเงิน </div>,
                                status === 'refunded' ? <div>วันที่ขอคืนเงิน</div> : status === "waitRefund" || status === 'refundApproved' ? <div>วันที่จัดส่งสำเร็จ</div> : <div> สถานะการจัดส่ง </div>,
                                status === "refunded" ? <div>คืนเงินวันที่</div> : status === "waitRefund" ? <div>วันที่ขอคืนเงิน</div> : status === 'refundApproved' ? <div>วันที่อนุมัติคำขอ</div> : status === "success" ? <div> วันเวลาส่งสำเร็จ </div> : <div> ส่งภายในวันที่ </div>,
                                status === 'onTheWay' ? <div>วันที่จัดส่ง</div> : null,
                                status === "waitRefund" || status === "refunded" || status === 'refundApproved' ? <div>สถานะการคืนเงิน</div> : status === "success" ? null : <div style={{ width: '5em' }}> </div>,
                                // status === "waitRefund" || status === "refunded" ? null : <div>การชำระเงิน</div>,
                                // status === "waitRefund" || status === "refunded" ?
                                //     <div>สถานะการคืนเงิน</div> :
                                //     <div>สถานะการจัดส่ง</div>,
                                // status === "success" ?
                                //     <div>วันเวลาส่งสำเร็จ</div> :
                                //     <div>อัปเดตล่าสุด</div>
                            ]}
                            // colWidth={['4em', '17%', '12%', status === "waitRefund" || status === "refunded" ? '18%' : '15%', '10%', '8%', status === "waitRefund" || status === "refunded" ? '10%' : '8%', status === "waitRefund" || status === "refunded" ? '0' : '10%', status === "waitRefund" || status === "refunded" ? '13%' : '14%', '10%', '10%']}
                            colWidth={['2em',
                                '12%',
                                '13%',
                                status === "waitRefund" || status === "refunded" || status === 'refundApproved' ? '10%' : '15%',
                                '8%',
                                '9%',
                                status === "waitRefund" || status === "refunded" || status === 'refundApproved' ? '12%' : '14%',
                                status === "success" ? '10%' : status === "waitRefund" || status === "refunded" || status === 'refundApproved' ? '10%' : '15%',
                                status === "success" ? '15%' : status === "waitRefund" || status === "refunded" || status === 'refundApproved' ? '10%' : '10%',
                                status === "onTheWay" ? '10%' : '0',
                                status === "success" ? '0' : status === "waitRefund" ? '10%' : '11%']}
                            selectBox={false}
                            hover={false}
                            colSide={['center', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center']}
                        />
                        {item && item.data?.map((detail, idx) => (
                            <div className="list-order-content cursor-pointer" key={idx} onClick={() => this.setCurrentInvoice(detail)}>
                                <TableHeader
                                    type={'content'}
                                    dataHeader={[
                                        // 0
                                        <div>{(order.page - 1) * order.limit + idx + 1}</div>,
                                        // 1
                                        <div className="order-no-item d-flex flex-column">

                                            <label className="text-left pl-3"
                                                style={{ lineHeight: '10px' }}>
                                                {detail.orderNo}
                                            </label>

                                            {detail.delivery?.trackingNo ? <div className="d-flex flex-row justify-content-start align-items-center p-0">
                                                <img src={host.image + detail.delivery?.logistics?.logo} alt="icon" style={{ width: '50px' }} />
                                                <span className="msg-tracking-no ml-1">{detail.delivery?.trackingNo && this.replaceSpaceWord(detail.delivery?.trackingNo)}</span>
                                            </div> : null}

                                            {/* digital product */}
                                            {detail?.items &&
                                                detail?.items?.length > 0 &&
                                                detail?.items[0].product?.productType === "digital" &&
                                                <img src={IconDigitalProduct} />
                                            }
                                        </div>,
                                        // 2
                                        detail.payment?.transactionId,
                                        // 3
                                        detail?.shop?.name,
                                        // 4
                                        <div>{detail?.items[0].product?.productType === "digital" ? detail.customerInformation?.email : detail.customerInformation.name}</div>,
                                        // 5
                                        <div>฿ {numeral(detail.priceTotal).format('0,0.00')} </div>,
                                        // 6
                                        <div>
                                            <React.Fragment>{this.showMethodPayment(detail)} {displayDateThShortYear(detail.paymentedAt)} </React.Fragment>

                                            <UncontrolledTooltip placement="top" target={'typePay-' + detail.id}>{this.checkDetailPayment(detail.paymentMethod)}</UncontrolledTooltip>
                                        </div>,
                                        // 7
                                        status === "refunded" ? detail.issueAt && <div>{displayDateThShortYear(detail.issueAt)}</div> :
                                            status === "waitRefund" || status === 'refundApproved' ? detail.deliveredAt && <div>{displayDateThShortYear(detail.deliveredAt)}</div> :
                                                status === "waitConfirm" ? <div> {this.checkpaymentStatus(detail.paymentStatus, detail.shipmentStatus)} </div> : this.checkLogisticStatus(detail.logisticStatus, detail.shipmentStatus),
                                        // 8

                                        status === "waitRefund" ? detail?.issueAt && <div>{displayDateThShortYear(detail?.issueAt)}</div> :
                                            status === 'refundApproved' ? detail?.refundApprovedAt && <div>{displayDateThShortYear(detail?.refundApprovedAt)}</div> :
                                                status === "refunded" ? detail?.payment?.refundedAt && <div>{displayDateThShortYear(detail?.payment?.refundedAt)}</div> :
                                                    status === "success" ? <div>{detail.deliveredAt && (displayDateThShortYear(detail.deliveredAt, "DD MMM YY") || null)} {detail.deliveredAt && (displayDateTh(detail.deliveredAt, "HH:mm") || null)}</div> :
                                                        detail?.deliveryDate && <div> <span>{displayDateThShortYear(detail?.deliveryDate)}</span> <br />
                                                            {status === "deliveryDelayed" && <span style={{ color: '#D10808' }}>เกิน {differenceDate(new Date(), detail?.deliveryDate)} วัน</span>}
                                                        </div>,
                                        // 9
                                        status === "onTheWay" ? <div>{displayDateThShortYear(detail.dispatchAt || '')}</div> : status === "waitRefund" ? null : null,
                                        // 10
                                        status === "success" ?
                                            null :
                                            status === "waitRefund" || status === "refunded" || status === 'refundApproved'
                                                ?
                                                <div className='w-100'>
                                                    <label className={classnames({
                                                        'wait-approve': detail.logisticStatus === "intransit" || detail.refundStatus === "request" || detail.logisticStatus === "wait" || detail.logisticStatus === "posting" || detail.logisticStatus === "redelivery" || detail.logisticStatus === "outdelivery" || (status && status === 'refundApproved'),
                                                        'success-order': (detail.logisticStatus === "delivered" || detail.logisticStatus === "intransit!" || detail.refundStatus === "refunded") && (detail.refundStatus !== "request") && (status && status !== 'refundApproved'),
                                                        'wait-return': detail.logisticStatus === "delivered" && detail.refundStatus === "return",
                                                        // 'cancel-order': detail.logisticStatus == "outdelivery"
                                                    })}>
                                                        {(detail.logisticStatus === "delivered" || detail.logisticStatus === "intransit!" || detail.refundStatus === "refunded") && (detail.refundStatus !== "request") && (status && status !== 'refundApproved') ? 
                                                        <img src={True} alt="check" className="check-success-pay" /> : null}
                                                        {(status && status === "waitRefund") || (status && status === "refunded") || (status && status === 'refundApproved') ? 
                                                        this.checkRefundStatus(detail.refundStatus, detail.logisticStatus)
                                                        : 
                                                        this.checkLogisticStatus(detail.logisticStatus, detail.shipmentStatus)}
                                                    </label>
                                                </div> : <div>
                                                    {/* {displayDateThShortYear(detail.updatedAt, "DD MMM YY")} */}
                                                    <div className="print-cover-sheet">
                                                        <div className={'tooltip'} >
                                                            <img src={IconPrint} onClick={(event) => this.openModalCoverSheet(event, detail)} alt="print" />
                                                            <span className="tooltiptext">พิมพ์ใบปะหน้าพัสดุ</span>
                                                        </div>
                                                    </div>
                                                </div>
                                    ]}
                                    colWidth={['2em',
                                        '12%',
                                        '13%',
                                        status === "waitRefund" || status === "refunded" || status === 'refundApproved' ? '10%' : '15%',
                                        '8%',
                                        '9%',
                                        status === "waitRefund" || status === "refunded" || status === 'refundApproved' ? '12%' : '14%',
                                        status === "success" ? '10%' : status === "waitRefund" || status === "refunded" || status === 'refundApproved' ? '10%' : '15%',
                                        status === "success" ? '15%' : status === "waitRefund" || status === "refunded" || status === 'refundApproved' ? '10%' : '10%',
                                        status === "onTheWay" ? '10%' : '0',
                                        status === "success" ? '0' : status === "waitRefund" ? '10%' : '11%']}
                                    selectBox={false}
                                    hover={false}
                                    colSide={['center', 'left', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center']}
                                />

                            </div>

                        ))}

                        {/* <div className="list-order-header row">
                            <div className="col-1">ลำดับ</div>
                            <div className="col text-left">หมายเลขออเดอร์</div>
                            <div className="col">เลขอ้างอิง 2C2P</div>
                            <div className="col">ชื่อร้านค้า</div>
                            <div className="col-1">ชื่อลูกค้า</div>
                            <div className="col-1">ยอดเงิน</div>
                            <div className="col-1"></div>
                            {status === "waitRefund" || status === "refunded" ? null : <div className="col-1">การชำระเงิน</div>}
                            {status === "waitRefund" || status === "refunded" ?
                                <div className="col-1">สถานะการคืนเงิน</div> :
                                <div className="col-1">สถานะการจัดส่ง</div>
                            }
                            {status === "success" ?
                                <div className="col-2">วันเวลาส่งสำเร็จ</div> :
                                <div className="col-2">อัปเดตล่าสุด</div>
                            }
                        </div> */}
                        {/* {item && item.data.map((detail, idx) => (
                            <div className="list-order-content row cursor-pointer" style={{backgroundColor:'white'}} key={idx} onClick={() => this.setCurrentInvoice(detail)}>
                                <div className="col-1">{(order.page - 1) * order.limit + idx + 1}</div>
                                <div className="col order-no-item d-flex flex-column">
                                    <label className="text-left pl-3"> {detail.orderNo}</label>
                                    {detail.delivery?.trackingNo ? <div className="d-flex flex-row justify-content-start align-items-center p-0">
                                        <img src={host.image + detail.delivery?.logistics?.logo} alt="icon" style={{ width: '50px' }} />
                                        <span className="msg-tracking-no ml-1">{detail.delivery?.trackingNo && this.replaceSpaceWord(detail.delivery?.trackingNo)}</span>
                                    </div> : null}
                                </div>
                                <div className="col" style={{ wordBreak: 'break-word' }}>{detail.payment?.transactionId}</div>
                                <div className="col">{detail?.shop?.name}</div>
                                <div className="col-1">{detail.customerInformation.name}</div>
                                <div className="col-1">฿ {numeral(detail.priceTotal).format('0,0.00')}</div>
                                <div className="col-1">
                                    {detail.paymentMethod == "transfer" ? <img src={transfer} alt="" id={'typePay-' + detail.id} /> :
                                        detail.paymentMethod == "ibanking" ? <img src={iBanking} alt="" id={'typePay-' + detail.id} /> :
                                            detail.paymentMethod == "cod" ? <img src={cod} alt="" id={'typePay-' + detail.id} /> :
                                                detail.paymentMethod == "creditcard" ? <img src={credit} alt="" id={'typePay-' + detail.id} /> :
                                                    detail.paymentMethod == "qrcode" ? <img src={QRCode} alt="" id={'typePay-' + detail.id} /> : null}

                                    <UncontrolledTooltip placement="top" target={'typePay-' + detail.id}>{this.checkDetailPayment(detail.paymentMethod)}</UncontrolledTooltip>
                                </div>
                                {status == "waitRefund" || status == "refunded" ? null :
                                    <div className="col-1">
                                        <label className={classnames({ 'cancel-order': detail.paymentStatus == "failed", 'wait-payment': detail.paymentStatus == "wait", 'wait-approve': detail.paymentStatus == "transfer", 'success-order': detail.paymentStatus == "paid" || detail.paymentStatus == "approved" })}>
                                            {detail.paymentStatus == "paid" || detail.paymentStatus == "approved" ? <img src={True} alt="check" className="check-success-pay pl-2" /> : null}
                                            {this.checkpaymentStatus(detail.paymentStatus)}</label>
                                    </div>}
                                <div className="col">
                                    <label className={classnames({
                                        'wait-approve': detail.logisticStatus == "intransit" || detail.refundStatus == "request" || detail.logisticStatus == "wait" || detail.logisticStatus == "posting" || detail.logisticStatus == "redelivery" || detail.logisticStatus == "outdelivery",
                                        'success-order': (detail.logisticStatus == "delivered" || detail.logisticStatus == "intransit!" || detail.refundStatus == "refunded") && (detail.refundStatus !== "request"),
                                        // 'cancel-order': detail.logisticStatus == "outdelivery"
                                    })}>
                                        {(detail.logisticStatus == "delivered" || detail.logisticStatus == "intransit!" || detail.refundStatus == "refunded") && (detail.refundStatus !== "request") ? <img src={True} alt="check" className="check-success-pay" /> : null}
                                        {status && status == "waitRefund" || status && status == "refunded" ? this.checkRefundStatus(detail.refundStatus, detail.logisticStatus) : this.checkLogisticStatus(detail.logisticStatus)}
                                    </label>
                                </div>
                                {status === "success" ?
                                    <div className="col">
                                        {detail.deliveredAt && displayDateThShortYear(detail.deliveredAt, "DD MMM YY") || null} {detail.deliveredAt && displayDateTh(detail.deliveredAt, "HH:mm") || null}
                                    </div>
                                    : <div className="col">
                                        {displayDateThShortYear(detail.updatedAt, "DD MMM YY")}
                                        <div className="print-cover-sheet">
                                            <div className={'tooltip'} >
                                                <img src={IconPrint} onClick={(event) => this.openModalCoverSheet(event, detail)} />
                                                <span className="tooltiptext">พิมพ์ใบปะหน้าพัสดุ</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))} */}
                    </React.Fragment>
                }

                {modalConfirmClearingAll.isOpen ?
                    <ModalConfirm
                        isOpen={modalConfirmClearingAll.isOpen}
                        message={modalConfirmClearingAll.message}
                        btnConfirm={modalConfirmClearingAll.btnConfirm}
                        btnCancel={modalConfirmClearingAll.btnCancel}
                        confirm={() => this.onConfirmClearing()}
                        isClose={() => this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CONFIRM_CLEARING_ALL }))}
                    /> : null}




            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    order: state.order,
    loading: state.app.loading,
    modalConfirm: state.order.modalConfirm,
    clearingOrder: state.order.clearingOrder,
    openModalConfirm: state.modalConfirm,
    selectAllClearingOrder: state.order.selectAllClearingOrder,
    modalConfirmClearingAll: state.order.modalConfirmClearingAll,
    modalConfirmClearing: state.order.modalConfirmClearing
})

export default connect(mapStateToProps, null)(ListOrder)
