import axios from '../config/axios';
import host from '../config/host'

class globalProvider {

    getListSortType = async() => {
        try {
            const url = `${host.api}/globals/list-sort-type`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

}

export default new globalProvider()