import classNames from "classnames";
import CouponTag from "components/CouponTag";
import numeral from "numeral";
import React from "react";
import "./cardShop.scss";
import host from "config/host";
import ImgDefaultShop from 'images/default-shop.png';


const CardShop = (props) => {
  const {
    data = {},
    isActive = false,
    handleSelectShop = null,
    checkedShop = {},
    selectedShop = {},
  } = props;

  const id = ` ${data?.id}`
  const isAllProduct = (checkedShop?.shops[id]?.allProduct)
  const isAllProducrOld = (selectedShop?.shops[id]?.allProduct)

  const countChecked = ()=>{
    let count = 0

  if (isAllProducrOld != undefined && isAllProduct != undefined) {
    if (isAllProduct !== isAllProducrOld) {
      count += (checkedShop?.shops[id]?.count || 0)
      return count
    }else{
      if(isAllProduct==true) {
        count += (checkedShop?.shops[id]?.count || 0)
      if(isAllProducrOld==true){
        if(checkedShop?.shops[id]?.count ==0 && selectedShop?.shops[id]?.count != 0)
        count += (selectedShop?.shops[id]?.count || 0)
      }
        return count
      }
      count += (checkedShop?.shops[id]?.count || 0)
      count += (selectedShop?.shops[id]?.count || 0)
      return count
    }

  }else{
    if (isAllProduct != undefined) {
      count += (checkedShop?.shops[id]?.count || 0)
      return count
    }
    if (isAllProducrOld != undefined) {
      count += (selectedShop?.shops[id]?.count || 0)
      return count
    }
  }
  return count
  }

  const count = countChecked()

  const isShowTag = checkedShop?.shops[id] ? checkedShop?.shops[id]?.allProduct : selectedShop?.shops[id]?.allProduct
  // isAllProducrOld != undefined && console.log((checkedShop?.shops[id]?.count), (selectedShop?.shops[id]?.count), count)
  return (
    <div
      className={classNames("wrap-card-shop", isActive && "isActive")}
      onClick={() => handleSelectShop(data?.id)}
    >
      <div className="cover-shop-logo">
        {
          data?.logoPath?.path ? <img src={host?.img + data?.logoPath?.path} /> : <img src={ImgDefaultShop} />
        }

      </div>

      <div className="cover-shop-name">
        <span className="shop-name">{data?.name}</span>
        {count > 0 && (
          <CouponTag isAllShop={isShowTag} />
        )}
      </div>

      {count > 0 && (
        <div className="cover-badge">{numeral(count).format("0,0")}</div>
      )}
    </div>
  );
};

export default CardShop;
