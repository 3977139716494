import {
  ADD_KEY_VALUE_SELECTEDED_SHOP,
  ADD_PRODUCT_SELECTED_SHOP,
  REMOVE_SHOP_SELECTED_SHOP,
  UPDATE_COUNT_SELECTED_SHOP,
  RESET_SELECTED_SHOP
} from "redux/actions/selectedShop"

const initialState = {
  countAllShop: 0,
  countAllSKU: 0,
  countAllProduct: 0,
  shops: {
    //     " 12": {
    //       allProduct: false,
    //       count: 1,
    //       sku: 2,
    //       name:"",
    //       logoPath:{},
    //       product: {
    //         " 29": {
    //           " 45": true,
    //           " 67": true,
    //           " 68": false,
    //           " 69": true,
    //         },
    //       },
    //     },
  }
};

const addShopProducts = (shops, state) => {

  Object.keys(shops).forEach((shopId) => {
    const shop = shops[shopId];
    /* increase count produuct and count sku */
    let countProduct = Object.keys(shop.product).length;
    let countSKU = Object.values(shop.product).reduce(
      (SKUTotal, product) => {
        return SKUTotal + Object.keys(product).length;
      }, 0);
      
        if (state.shops[shopId]) {
        /* shop has already exist */
        state.shops[shopId].product = {
            ...shop.product,
            ...state.shops[shopId].product,
        };
        state.shops[shopId].allProduct = shop.allProduct

        } else {
          /* first time to add shop */
          state.countAllShop += 1;
          state.shops = { [shopId]: { ...shop }, ...state.shops };
          state.shops[shopId].count = 0;
          state.shops[shopId].sku = 0;
        }

    
    if(countProduct > 0) {

      if(countProduct){
        state.shops[shopId].count += countProduct;
        state.countAllProduct += countProduct;
      }

      if (countSKU) {
        state.shops[shopId].sku += countSKU;
        state.countAllSKU += countSKU;
      }

    } else {
      /* case: all product ไม่ต้องนับ sku กับ product ในร้าน */
      /* เอาจำนวน product and sku ของทั้งร้านรวมกับ countAll ได้เลย */
      state.shops[shopId].count = shop.count;
      state.shops[shopId].sku = shop.sku;
      state.countAllProduct += state.shops[shopId].count || 0;
      state.countAllSKU += state.shops[shopId].sku || 0;
    }

  });

  return state;
};

const removeShopSelectedShop = (payload, state) => {
  const tempState = {...state}
  const shopId = Object.keys(payload)[0] // payload -> {131 :null}

  // decrease countAll
  const { count, sku } = tempState.shops[shopId];
  tempState.countAllShop = tempState.countAllShop - 1;
  tempState.countAllProduct = tempState.countAllProduct - count;
  tempState.countAllSKU = tempState.countAllSKU - sku;
  delete tempState.shops[shopId]
  return  tempState
}

const updateCountSelectedShop = (state) => {

  state.countAllShop = Object.keys(state.shops).length;

  state.countAllProduct = Object.values(state.shops).reduce((acc, cur) => {
    return acc + (cur.count ? cur.count : 0)
  }, 0)

  state.countAllSKU = Object.values(state.shops).reduce((acc, cur) => {
    return acc + (cur.sku ? cur.sku : 0)
  }, 0)

  return state
}


export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_KEY_VALUE_SELECTEDED_SHOP:
      if (payload.key == null) {
        const { value } = payload
        const tmpValue = {...value}
        const tmpState = {...state }
        return { ...tmpState, ...tmpValue };
      } else {
        return { ...state, [payload.key]: payload.value }
      }

    case ADD_PRODUCT_SELECTED_SHOP:
      return { ...addShopProducts(payload, state) }
    case 'REMOVE_SHOP_SELECTED_SHOP':

      return { ...removeShopSelectedShop(payload, state) }
    // case REMOVE_SHOP_SELECTED_SHOP:
    //   return { ...removeShopSelectedShop(payload, state) }

    case UPDATE_COUNT_SELECTED_SHOP:
      return { ...updateCountSelectedShop(state) }

    case RESET_SELECTED_SHOP: {
      return {
        countAllShop: 0,
        countAllSKU: 0,
        countAllProduct: 0,
        shops: {}
      }
    }


    default: {
      return state
    }
  }
}