import { combineReducers } from 'redux';
import user from "./user"
import live from './live'
import modalAlert from './modalAlert'
import modalConfirm from './modalConfirm'
import app from './app'
import product from './product'
import modalUploadImage from './modalUploadImage'
import shop from './shop'
import post from './post'
import logistic from './logistic'
import notification from './notification'
import order from './order'
import socket from './socket'
import gallery from './gallery'
import modalMessage from './modalMessage'
import withdraw from './withdraw'
import playlists from './playlists'
import frontPage from './frontPage'
import promocode from './promocode'
import logisticPrice from './logisticPrice'
import ribbon from './ribbon'
import broadcast from './broadcast'
import dashboard from './dashboard'
import promotion from './promotion'
import announce from './announce'
import categories from './categories'
import checkedProduct from './checkedProduct'
import selectedProduct from './selectedProduct'
import joinSelectedShop from './joinSelectedShop'
import selectedShop from './selectedShop'
import checkedShop from './checkedShop'
// import { syncHistoryWithStore, routerReducer } from 'react-router-redux'

import listSelectedShop from './listSelectedShop';
import listCheckedShop from './listCheckedShop';

const rootReducer = combineReducers({
  user,
  live,
  modalAlert,
  modalConfirm,
  app,
  product,
  modalUploadImage,
  shop,
  post,
  logistic,
  notification,
  order,
  socket,
  gallery,
  modalMessage,
  withdraw,
  playlists,
  frontPage,
  promocode,
  logisticPrice,
  ribbon,
  broadcast,
  dashboard,
  promotion,
  announce,
  categories,
  selectedProduct,
  checkedProduct,
  listSelectedShop,
  listCheckedShop,
  joinSelectedShop:joinSelectedShop,
  selectedShop:selectedShop,
  checkedShop
  // routing: routerReducer
})

export default rootReducer;
