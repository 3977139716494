/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Checkbox from 'antd/lib/checkbox'
import IconClose from '../../images/icons/close.svg'
import './modalSelectList.scss'
import categoriesProvider from 'provider/categoriesProvider'
import { SubTitle2 } from 'components/FontStyle'
import classNames from 'classnames'
import { ReactComponent as IconArrowRight } from 'images/icons/arrow-right.svg'
import brandProvider from 'provider/brandProvider'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchNotification, OPEN_TOAST } from 'redux/actions/notification'
import { dispatchApp, SET_LOADING } from 'redux/actions/app'
import Loading from 'components/Loading'
import productProvider from 'provider/productProvider'
import playlistsProvider from 'provider/playlistsProvider'
// import { ReactComponent as IconSearch } from 'images/icons/search.svg'
import { InputSearch } from 'components/output'
import { dispatchPlaylists, GET_PLAYLIST_CATEGORY_MALL } from 'redux/actions/playlists'
import ModalAlert from 'components/modalAlert/ModalAlert'
import { OPEN_MODAL_ALERT } from 'redux/actions/modalAlert'
import { dispatchProduct, ADD_KEY_VALUE_PRODUCT } from 'redux/actions/product'
import { getUserRole } from 'helper/utils'
import { ADMIN, SUPER_ADMIN } from 'layout/Sidebar/Role'
import axiosNew from 'config/axios'
import shopProvider from 'provider/shopProvider'
import { dispatchShop, SET_SHOP_DETAIL } from 'redux/actions/shop'


const api_product = new productProvider()


const CheckboxList = ({
  defaultData,
  dataList,
  selectedList,
  onSelectItem,
  onPreview,
  previewList,
  listCat,
  level,
  type,
  onload
}) => {
  return (
    <div className='check-box-list'>
      <div className='item-list'>
        {/* <Checkbox
          className='wrap-check-box'
          checked={true}
          // onChange={onChange}
          // disabled={disabled}
          name='selectAll'
          value='selectAll'
        >
          เลือกทั้งหมด
        </Checkbox> */}
      </div>
      {defaultData.map((item, index) => {
        // const tmp = dataList[index] ? dataList[index] : item
        return (
          <div className='item-list' key={`data${index}`} onClick={() => onPreview(item)}>
            {((type === 'brand' && level !== 3) || (level === 1 && type === 'playlist') || type === 'product') &&
              <React.Fragment>
                <div className="d-flex flex-row">
                  <div onClick={() => { level !== 3 && onSelectItem(item, index) }}>
                    {(type === 'brand' || type === 'playlist') &&
                      <Checkbox
                        className={classNames('wrap-check-box', { 'wrap-check-box-disabled': level === 3 })}
                        // checked={selectedList.includes(item.id)}
                        checked={!!selectedList[item.id]}
                        name='selectAll'
                        value='selectAll'
                        disabled={level === 3}
                        style={{ pointerEvents: 'none' }}
                      >
                      </Checkbox>}
                  </div>
                  <div className={classNames('ml-3', { 'color-pink': previewList?.id === item.id })} style={{ cursor: 'default' }} >{item.name}</div>
                </div>
                <div className='icon-arrow'>
                  {(type === 'product' || (level === 1 && type === 'brand') && (type !== 'playlist')) && <IconArrowRight className={classNames('gray', { 'pink': previewList?.id === item.id })} width={8} />
                  }
                  {/* {
                    listCat.find((e) => e.parentId === item.id) &&
                    ((level === 1 && type === 'brand') && (type !== 'playlist'))
                    && <IconArrowRight className={classNames('gray', { 'pink': previewList?.id === item.id })} />
                    } */}
                </div>
              </React.Fragment>
            }
          </div>
        )
      })}
    </div >
  )
}



const ModalSelectList = (props) => {
  const { modal, type, clearData } = props
  const { data } = modal
  const dispatch = useDispatch()
  const { shopDetail: shopDetailInfo, currentShop = {} } = useSelector(state => state.shop)
  const { categoryType } = shopDetailInfo||{}
  // const { isOpen } = props.modal
  // const dataList = data1

  const [selectedList, setSelectedList] = useState({})
  const [cateSelected, setCateSelected] = useState([])
  const [previewList, setPreviewList] = useState({})
  const [listCat, setListCat] = useState(null)
  const [initLevel1, setInitLevel1] = useState([])
  const [initLevel2, setInitLevel2] = useState([])
  const [initLevel3, setInitLevel3] = useState([])
  const [childTree, setChildTree] = useState({})
  const [statusModal, setStatusModal] = useState(null)
  const [search, setSearch] = useState(null)
  const [onload, setOnload] = useState(false)
  let shopDetail = type == "product" ? shopDetailInfo : currentShop;
  shopDetail = shopDetail?.categoryType ? shopDetail : shopDetailInfo;

  const { categoryType: categoryTypeInfo = null } = async () => await getShopDetail() || {}

  const api_shop = new shopProvider()
  
  // useEffect(() => {
  //     getCategories()
  // }, [])

  // useEffect(() => {
  //   // if(!shopDetail){
  //   //   getShopDetail()
  //   // }
  //   if(type == 'product' && shopDetail) {
  //     getCategories()
  //   }
  // },[shopDetail])

  useEffect(() => {
    if (props.mode === 'create') {
      props.setCateSelected({ cateSelected, onCheckBeforeRemoveCate, onRemoveItem, childTree })
    }
    if(type === 'brand' && props.setCateSelected){
      props.setCateSelected({ cateSelected, onCheckBeforeRemoveCate, onRemoveItem, childTree })
    }
    return () => {
    }
  }, [cateSelected, childTree])

  useEffect(() => {
    if (onload) {
      dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
    } else {
      dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
    }
  }, [onload])

  useEffect(() => {
    if (!modal?.isOpen) {
      if (clearData === true) {
        cleanup()
      }
    } else {
      // getCategories()
      if (type === 'brand' && props?.mode !== 'update') {
        getCategories()
        if (modal?.data?.id) {
          getCateBrandfromBrandId({ brandId: modal.data.id })
          // getProductCategories(modal.data.id, modal.data.brandId)
        }
      }
      if (type === 'product') {
        const fetchShopDetail = async () => {
          // const data = await getShopDetail()
          // await getCategories()
          await getProductCategories(modal.data.id, modal.data.brandId)
          // await getCateBrandfromBrandId({brandId: modal.data.brandId})
        }
        fetchShopDetail()
      }
      if (type === 'playlist') {
        getPlaylistsCategories(modal.data.playlistsPlatform?.shopId, modal.data.playlistsId)
      }
    }
  }, [modal?.isOpen])

  // useEffect(() => {
  //   alert('type === brand && props.mode === create')
  //   let firstFetch = false

  //   if (!firstFetch) {
  //     firstFetch = true
  //     if (type === 'brand' && props.mode === 'create' && props?.isUpdateBrandId) {
  //       if (props?.isUpdateBrandId) {
  //         getCateBrandfromBrandId({ brandId: props.isUpdateBrandId })
  //       }
  //     }
  //   }

  //   return () => {

  //   }
  // }, [listCat])

    useEffect(() => {
      if(props.isUpdateBrandId){
        let firstFetch = false
    
        if (!firstFetch) {
          firstFetch = true
          if (type === 'brand' && props.mode === 'update' && props?.isUpdateBrandId) {
            if (props?.isUpdateBrandId) {
              getCategories()
              getCateBrandfromBrandId({ brandId: props.isUpdateBrandId })
            }
          }
        }
      }
    return () => {

    }
  }, [props.isUpdateBrandId])

  // useEffect(() => {
  //   if (modal?.data?.id) {
  //   getCateBrandfromBrandId({ brandId: modal.data.id })
  //   }
  //   return () => {
  //   }
  // }, [modal?.data])


  const cleanup = () => {
    if(type !== 'brand' && props.mode === 'update'){
      setSelectedList({})
      setPreviewList({})
      // setListCat([])
      setInitLevel1([])
      setInitLevel2([])
      setInitLevel3([])
      setChildTree({})
      setStatusModal(null)
    }
    if(type === 'brand' && props.mode !== 'update'){
      setCateSelected([])
      setSelectedList({})
    }
    if(type === 'product'){
      setSelectedList({})
      setPreviewList({})
      setInitLevel1([])
      setInitLevel2([])
      setInitLevel3([])
      setChildTree({})
      setStatusModal(null)
    }
  }

  const getPlaylistsCategories = async (shopId, playlistId) => {
    setOnload(true)
    try {
      const res = await playlistsProvider.getPlaylistCategoriesMall({ shopId })
      setInitLevel1(res.data)
      const defaultCat = await playlistsProvider.getPlaylistCategoriesByPlaylistId(playlistId)
      if (defaultCat.length > 0) {
        setStatusModal('update')
        await Promise.all(defaultCat.map(async (e) => {
          const findCate = res.data.some((list) => list.id === e.categories.id)
          if (findCate) {
            await onSelectItemLevel1(e.categories)
          }
        }))
      }
      setOnload(false)

      // if (res.length > 0) {
      //   const defaultCat = await playlistsProvider.getPlaylistCategoriesByPlaylistId(playlistId)
      //   if (defaultCat.categories.length > 0) {
      //     setStatusModal('update')
      //     defaultCat.categories.map((e) => {
      //       onSelectItemLevel1(e)
      //     })
      //   }
      // }
      // setOnload(false)


    } catch (error) {
      // dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
      console.log(`error`, error)
      throw error
    } finally {
      // setOnload(false)
    }
  }

  const getProductCategories = async (productId, brandId) => {
    try {
      setOnload(true)
      // const getListCat = await playlistsProvider.getPlaylistCategoriesMall(shopId)
      const resShopDetail = await getShopDetail()
      const getListCat = await brandProvider.getCateBrandfromBrandId({ brandId }, resShopDetail)
      setInitLevel1(getListCat.data)
      if (productId) {
        const res = await api_product.getProductCategoriesByProductId(productId)
        if (res.data.category.length > 0) {
          setStatusModal('update')
        }
        if (getListCat.data.find((e) => e.id === res?.data?.category[0]?.id)) {
          setProductCat(res.data,getListCat.data)
        }
      }
    } catch (error) {
      console.log(`error`, error)
    } finally {
      setOnload(false)
    }
  }

  const setProductCat = async (param,resListCat) => {
    setOnload(true)
    try {
      let data = {}
      if (param.category.length > 0) {
        const resShopDetail = await getShopDetail()
        // console.log('resShopDetailsetProductCat ', resShopDetail)
       

        const getCatList = await Promise.all(param.category.map(async (item, index) => {
          const filterListCat = await brandProvider.getCateBrandfromBrandId({ brandId: props.modal.data.brandId, parentId: item.id }, resShopDetail)
          return filterListCat.data
        }))
        const listAll = await Promise.all(getCatList)
        param.category.map(async (cat, catIndex) => {
          // if (catIndex === 0) {
          //   const findCat = resListCat.find((e) => e.id === cat.id)
          //   data[`lv${catIndex + 1}`] = findCat
          // }
          switch (catIndex) {
            case 0:
              const findCat = resListCat.find((e) => e.id === cat.id)
              data[`lv${catIndex + 1}`] = findCat
              break;
            case 1:
              const findCat2 = listAll[0].find((e) => e.id === cat.id)
              setInitLevel2(listAll[0])
              data['lv2'] = findCat2
              break;
            case 2:
              const findCat3 = listAll[1].find((e) => e.id === cat.id)
              setInitLevel3(listAll[1])
              data['lv3'] = findCat3
              break;
            default:
              return null
          }
        })
        setPreviewList(data)
     
      }
      return data
    } catch (error) {
      console.log(`error`, error)
    } finally {
      setOnload(false)
    }

  }

  const getCateBrandfromBrandId = async (param) => {
    if(type !== 'brand'){
      try {
        setOnload(true)
        const resShopDetail = await getShopDetail()
  
        const res = await brandProvider.getCateBrandfromBrandId({ brandId: param.brandId }, resShopDetail)
        if (res.data.length > 0) {
          await Promise.all(res.data.map(async (item) => {
            const getParentData = await brandProvider.getCateBrandfromBrandId({ brandId: param.brandId, parentId: item.id }, resShopDetail)
            await Promise.all(getParentData.data.map(async (lv2) => {
              await onSelectItemLevel2(lv2)
            }))
            setStatusModal('update')
          }))
        }
        setOnload(false)
      } catch (error) {
        throw error
      } finally {
        setOnload(false)
      }
  
    }
    if(type === 'brand'){
      try {
        setOnload(true)
        const resShopDetail = await getShopDetail()

        const res = await brandProvider.getCateBrandfromBrandId({ brandId: param.brandId }, resShopDetail)
        if (res.data.length > 0) {
          await Promise.all(res.data.map(async (item) => {
            const getParentData = await brandProvider.getCateBrandfromBrandId({ brandId: param.brandId, parentId: item.id }, resShopDetail)
            await Promise.all(getParentData.data.map(async (lv2) => {
              await onSelectItemLevel2(lv2)
            }))
            setStatusModal('update')
          }))
        }
      } catch (error) {
        throw error
      } finally {
        setOnload(false)
      }
    }
  }


  const getCategories = async () => {
    try {
      const resShopDetail = await getShopDetail()
      const res = await categoriesProvider.getCategories(null, resShopDetail, 1)
      setListCat(res.data)
      // if(resShopDetail?.categoryType === 'default'){
        const lv1 = await res.data.filter((item) => item.parentId === 0)
        setInitLevel1(lv1)
      // }
      return res
    } catch (error) {
      throw error
    }
  }

  const getShopDetail = async () => {
    const role = getUserRole()
    if(((role === ADMIN || SUPER_ADMIN) && modal.isOpen) || (type === 'brand')){
        const shop = await api_shop.getShopDetail({shopId: type === 'brand' || type === 'playlist' ? shopDetail?.id : props?.modal?.data?.shopId})
        // dispatch(dispatchShop({type: SET_SHOP_DETAIL, payload: { shopDetail: shop }}))
     return shop
    }  else {
      return null
    }
  }



  const onSelectItemLevel1 = async (data) => {
    dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
    if (selectedList[data.id]) {
      // setPreviewLv1({ lv1: null })
      onRemoveItem(data, 'level1')
    } else {
      // setPreviewLv1(data)
      setSelectedList((prevState) => ({
        ...prevState,
        [data.id]: data,
      }))
      // const isLastCat = listCat.find((e) => e.parentId === data.id)
      // if (!isLastCat) {
      //   setCateSelected((prevState) => ([
      //     ...prevState,
      //     {
      //       level1: data.id,
      //       title: `${ data.name }`
      //     }
      //   ]))
      setChildTree((prevState) => ({
        ...prevState,
        [data.id]: {}
      }))
      // }

      // const findLv2 = await categoriesProvider.getCategories({parentId: data.id},shopDetail,2)


      // const findLevel2 = listCat.filter((item) => item.parentId === data.id)
      const resShopDetail = await getShopDetail()
      const findLevel2 = await categoriesProvider.getCategories({ parentId: data.id },resShopDetail,2)
      const findLevel3 = []
      await Promise.all(findLevel2.data.map(async (item) => {
        setSelectedList((prevState) => ({
          ...prevState,
          [item.id]: item
        }))
        setCateSelected((prevState) => ([
          ...prevState,
          {
            level1: data.id,
            level2: item.id,
            title: `${data.name} > ${item.name}`
          }
        ]))
        setChildTree((prevState) => ({
          ...prevState,
          [data.id]: {
            ...prevState[data.id],
            [item.id]: []
          }
        }))

        // findLevel2.map(async (lv2) => {
        const findLv3 = await categoriesProvider.getCategories({ parentId: item.id },resShopDetail,3)

        // if (findLv3.data.length === 0) {
        //   setSelectedList((prevState) => ({
        //     ...prevState,
        //     [item.id]: item
        //   }))
        // }

        await Promise.all(findLv3.data.map(async (lv3) => {
          findLevel3.push(lv3)
          setSelectedList((prevState) => ({
            ...prevState,
            [lv3.id]: lv3
          }))
          setChildTree((prevState) =>
          ({
            ...prevState,
            [data.id]: {
              ...prevState[data.id],
              [item.id]: [...prevState[data?.id][item.id], lv3.id]
            }
          }))
        }))

        // })

        // const findL3 = listCat.filter((l3) => l3.parentId === item.id)
        // findL3.map((lv3) => {
        //   findLevel3.push(lv3)
        //   setSelectedList((prevState) => ({
        //     ...prevState,
        //     [lv3.id]: lv3
        //   }))
        //   setChildTree((prevState) =>
        //   ({
        //     ...prevState,
        //     [data.id]: {
        //       ...prevState[data.id],
        //       [item.id]: [...prevState[data.id][item.id], lv3.id]
        //     }
        //   }))



        // }
        // const level1 = data
        // const level2 = item
        // const level3 = lv3
        // setCateSelected((prevState) => ([
        //   ...prevState,
        //   {
        //     level1: level1.id,
        //     level2: level2.id,
        //     level3: level3.id,
        //     title: `${ level1.name } > ${ level2.name } > ${ level3.name }`
        //   }
        // ]))
        // })
      }))

      // if (data.id !== previewList['lv1']?.id) {
      setInitLevel2([...findLevel2.data])
      // setInitLevel3([])
      if (type !== 'playlist') {
        setPreviewList({ lv1: data })
      }
      // }
    }
    dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
  }

  const onSelectItemLevel2 = async (data) => {
   
    if(type !== 'brand'){
      if (selectedList[data.id]) {
        onRemoveItem(data, 'level2')
        // setPreviewList({ ...previewList, lv2: data })
        const findLv2 = listCat.filter((item) => item.parentId === data.id)
        findLv2.map((lv2) => {
          delete selectedList[lv2.id]
        })
        setInitLevel3(findLv2)
      } else {
        setSelectedList((prevState) => ({
          ...prevState,
          [data.id]: data,
        }))
        const dataLv1 = listCat.find((list) => data.parentId === list.id)
        setSelectedList((prevState) => ({
          ...prevState,
          [dataLv1?.id]: dataLv1,
        }))
        setCateSelected((prevState) => ([
          ...prevState,
          {
            level1: dataLv1?.id,
            level2: data?.id,
            title: `${dataLv1?.name} > ${data.name}`
          }
        ]))
        setChildTree((prevState) =>
        ({
          ...prevState,
          [dataLv1?.id]: {
            ...prevState[dataLv1?.id],
            [data.id]: []
          }
        }))
        const resShopDetail = await getShopDetail()
        const findList = await categoriesProvider.getCategories({ parentId: data.id },resShopDetail,3)
        await Promise.all(findList.data.map((lv3) => {
          setSelectedList((prevState) => ({
            ...prevState,
            [lv3.id]: lv3,
          }))
          setChildTree((prevState) =>
          ({
            ...prevState,
            [dataLv1?.id]: {
              ...prevState[dataLv1?.id],
              [data.id]: [...prevState[dataLv1?.id][data.id], lv3.id]
            }
          }))
        }))
        setInitLevel3(findList.data)
      }
    }

    if(type === 'brand'){
      if (selectedList[data.id]) {
        onRemoveItem(data, 'level2')
        // setPreviewList({ ...previewList, lv2: data })
        const findLv2 = listCat.filter((item) => item.parentId === data.id)
        findLv2.map((lv2) => {
          delete selectedList[lv2.id]
        })
        setInitLevel3(findLv2)
      } else {
        const {data: listCat} = await getCategories()
        setSelectedList((prevState) => ({
          ...prevState,
          [data.id]: data,
        }))
        const dataLv1 = listCat.find((list) => data.parentId === list.id)
        setSelectedList((prevState) => ({
          ...prevState,
          [dataLv1?.id]: dataLv1,
        }))
        setCateSelected((prevState) => ([
          ...prevState,
          {
            level1: dataLv1?.id,
            level2: data?.id,
            title: `${dataLv1?.name} > ${data.name}`
          }
        ]))
        setChildTree((prevState) =>
        ({
          ...prevState,
          [dataLv1?.id]: {
            ...prevState[dataLv1?.id],
            [data.id]: []
          }
        }))
        const resShopDetail = await getShopDetail()
        const findList = await categoriesProvider.getCategories({ parentId: data.id },resShopDetail,3)
        await Promise.all(findList.data.map((lv3) => {
          setSelectedList((prevState) => ({
            ...prevState,
            [lv3.id]: lv3,
          }))
          setChildTree((prevState) =>
          ({
            ...prevState,
            [dataLv1?.id]: {
              ...prevState[dataLv1?.id],
              [data.id]: [...prevState[dataLv1?.id][data.id], lv3.id]
            }
          }))
        }))
        setInitLevel3(findList.data)
      }
    }
  }

  const onSelectItemLevel3 = (data) => {
    if (selectedList[data.id]) {

      onRemoveItem(data, 'level3')

    } else {
      setSelectedList((prevState) => ({
        ...prevState,
        [data.id]: data,
      }))

      // if (!childTree[data.parentId]?.includes(data.id)) {
      //   setChildTree((prevState) => ({
      //     ...prevState,
      //     [data.parentId]: prevState[data.parentId] ? [...prevState[data.parentId], data.id] : [data.id]
      //   }))
      // }

      // const level3 = data
      // const level2 = selectedList[level3.parentId]
      // const level1 = selectedList[level2.parentId]

      // setCateSelected((prevState) => ([
      //   ...prevState,
      //   {
      //     level1: level1.id,
      //     level2: level2.id,
      //     level3: level3.id,
      //     title: `${ level1.name } > ${ level2.name } > ${ level3.name }`
      //   }
      // ]))
    }
  }



  const onRemoveItem = async (data, level) => {
    // clear selectedList
    // if (onload && props?.mode !== 'create') return
    setOnload(true)
    setSelectedList((prevState) => ({
      ...prevState,
      [data.id]: null,
    }))
    delete selectedList[data.id]
    if (level === 'level2') {
      const cateRemove = cateSelected.filter(cate => cate[level] === data.id)
      // const findCate = listCat.find((e) => e.id === data.id)
      const findLv1 = listCat.find((e) => e.id === data.parentId)
      // const findLv1 = await categoriesProvider.getCategories({ parentId: data.id },shopDetail)
      if(childTree[findLv1.id][data.id]){
        delete childTree[findLv1.id][data.id]
      }
      if (Object.keys(childTree[data.parentId]).length === 0) {
        delete childTree[data.parentId]
        delete selectedList[data.parentId]
      }
      cateRemove.map((cat) => {
        delete selectedList[cat['level3']]
      })
      const findLevel2 = initLevel2.filter((item) => item.parentId !== data.id)
      // setInitLevel2(findLevel2)
      const rmLevel2 = listCat.filter((item) => item.parentId === data.id)
      let lv3 = initLevel3
      rmLevel2.map((item) => {
        delete selectedList[item.id]
        const findLv3 = initLevel3.filter((lv3) => lv3.parentId === item.id)
        findLv3.map((rm) => {
          delete selectedList[rm.id]
          lv3 = lv3.filter((e) => e.id !== rm.id)
        })
      })
      // setInitLevel3(lv3)
      delete selectedList[data.id]
    }
    if (level === 'level1') {
      const cateRemove = cateSelected.filter(cate => cate[level] === data.id)
        const resShopDetail = await getShopDetail()
      cateRemove.map(async (cat) => {
        delete selectedList[cat.level2]
        // const findLv2 = listCat.filter((e) => e.parentId === cat.level2)
        if (cat.level2) {
          const findLv3 = await categoriesProvider.getCategories({ parentId: cat.level2 },resShopDetail,2)
          findLv3.data.map((e) => delete selectedList[e.id])
        }

        // delete selectedList[cat.level2]
        // delete selectedList[cat.level3]
      })
      delete childTree[data.id]
      setSelectedList(selectedList)
    }



    // clear CateSelected
    const cateRemain = cateSelected.filter(cate => cate[level] !== data.id)
    setCateSelected(cateRemain)
    if (props.mode === 'create') {
      props.setCateSelected({ cateSelected: cateRemain, onCheckBeforeRemoveCate, onRemoveItem, childTree })
    }

    // clear childTree
    // checkInvalidChildTree(data.id)

    /// clear Data
    // switch (level) {
    //   case 'level1':
    //     setInitLevel2([])
    //     setInitLevel3([])
    //     break;

    //   case 'level2':
    //     setInitLevel3([])
    //     break;

    //   default:
    //     break;
    // }
    setOnload(false)
  }

  const onCheckBeforeRemoveCate = (item) => {
    if (item['level3']) {
      return onRemoveItem({ id: item['level3'], parentId: item['level2'] }, 'level3')
    }
    if (item['level2']) {
      return onRemoveItem({ id: item['level2'], parentId: item['level1'] }, 'level2')
    }
    if (item['level1']) {
      return onRemoveItem({ id: item['level1'] }, 'level1')
    }
    // onRemoveItem({ id: item.id }, 'level3')
  }

  // const checkInvalidChildTree = (id) => {
  //   if (childTree[id]) {
  //     childTree[id].map(child => {
  //       checkInvalidChildTree(child)
  //       setSelectedList((prevState) => ({
  //         ...prevState,
  //         [child]: null,
  //       }))
  //     })
  //   }
  // }

  // const autoSelectItem = (parentData) => {
  //   //// loop for set chile level3 data to seletedList
  //   initLevel3.map(data => {
  //     setSelectedList((prevState) => ({
  //       ...prevState,
  //       [data.id]: data,
  //     }))
  //     if (!childTree[data.parentId]?.includes(data.id)) {
  //       setChildTree((prevState) => ({
  //         ...prevState,
  //         [data.parentId]: prevState[data.parentId] ? [...prevState[data.parentId], data.id] : [data.id]
  //       }))
  //     }

  //     const level3 = data
  //     const level2 = parentData
  //     const level1 = selectedList[level2.parentId]

  //     setCateSelected((prevState) => ([
  //       ...prevState,
  //       {
  //         level1: level1.id,
  //         level2: level2.id,
  //         level3: level3.id,
  //         title: `${ level1.name } > ${ level2.name } > ${ level3.name }`
  //       }
  //     ]))
  //   })
  // }


  const setPreviewLv1 = async (item) => {
    setInitLevel2([])
    let level2
    const resShopDetail = await getShopDetail()
    if (type !== 'product') {
      level2 = await categoriesProvider.getCategories({ parentId: item.id },resShopDetail,2)
    } else {
      level2 = await brandProvider.getCateBrandfromBrandId({ brandId: modal.data.brandId, parentId: item.id },resShopDetail)
    }

 
    if (type !== 'playlist') {
      setPreviewList({ lv1: item })
    }
    setInitLevel2([...level2.data])
    setInitLevel3([])
  }

  const onPreviewLevel1 = async (item) => {
  
    if (previewList['lv1']?.id === item.id) {
      // removePreviewLv1(item)
    } else if ((previewList['lv1'] && previewList['lv1']?.id) !== item.id) {
      const resShopDetail = await getShopDetail()
      const findLv2 = await categoriesProvider.getCategories({ parentId: item.id },resShopDetail,2)
      setInitLevel2(findLv2.data)
      setPreviewLv1(item)
    }
    else {
      setPreviewLv1(item)
    }
  }

  const onPreviewLevel2 = async (item) => {
    if (type === 'brand') return
    const resShopDetail = await getShopDetail()
    const level2 = await categoriesProvider.getCategories({ parentId: item.id },resShopDetail,3)
    setPreviewList({ ...previewList, lv2: item, lv3: null })
    setInitLevel3(level2.data)
  }

  const onPreviewLevel3 = (item) => {
    if (type === 'brand') return
    if (previewList['lv3']?.id) {
      // const level3 = listCat.filter((lv3) => lv3.parentId === item.id)
      setPreviewList({ ...previewList, lv3: item })
    } else {
      setPreviewList({ ...previewList, lv3: item })
    }
  }

  const onSubmit = async () => {
    const { type, onSubmitModal } = props;

    /* send data output */
    if (onSubmitModal) {
      if (previewList['lv1'] && previewList['lv2']) {
        const categoryParam = setParamProductCat();
        dispatch(dispatchApp({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'productCategory', value: categoryParam } }));
        onSubmitModal(categoryParam);
        return;
      } else {
        dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { type: 'danger', message: 'กรุณากรอกข้อมูลให้ครบ' } }));
        return;
      }
    }

    setParamProductCat()
    switch (type) {
      case 'brand':
        if (statusModal === 'update') {
          dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
          let defaultData = {}
          const resShopDetail = await getShopDetail()
          const defaultCat = await brandProvider.getCateBrandfromBrandId({ brandId: modal.data.id },resShopDetail)
          defaultCat.data.map(d => {
            // defaultData.[d.id] = d  // old code 
              defaultData[d.id] = d 
          })
          const data = childTree
          let categories = []
          let shopCategories = []
          for (const [keyLv1, lv1] of Object.entries(data)) {
            for (const [keyLv2, valueLv3] of Object.entries(lv1)) {
              if (valueLv3.length === 0) {
                categories.push(parseInt(keyLv2))
                 shopCategories.push(parseInt(keyLv2))
              }
              categories.push(...valueLv3)
              shopCategories.push(...valueLv3)
            }
          }

          if (props.mode === 'create') {
            props.handleGetCate({ categories })
            props.setCateSelected({ cateSelected, onCheckBeforeRemoveCate, onRemoveItem, childTree })
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
            props.isClose()
            return
          }


          // const param = {
          //   brandId: modal.data.id,
          //   categories
          // }
          const categoriesKey = resShopDetail.categoryType == 'custom' ? 'shopCategories' : 'categories'
          const categoriesRes = resShopDetail.categoryType == 'custom' ? shopCategories : categories
          const param = {
            brandId: modal.data.id,
            [categoriesKey]: categoriesRes,
          }
          try {
            const res = await brandProvider.updateBrandCategory(param,resShopDetail)
            if (res.status === 200) {
              props.isClose()
              cleanup();
              dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } }))
            }
          } catch (error) {
            // console.log(`error?.response`, error?.response)
            const errorStatus = error?.response?.status || error?.status 
            const errorMsg = error?.response?.data?.message || error?.data?.message 
            if (errorStatus === 400) {
              dispatch(dispatchNotification({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: errorMsg, type: 'alert' } }))
            } else {
              dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการผิดพลาด!`, type: 'danger' } }))
            }

          }
          dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        } else {
          dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
          const data = childTree
          let categories = []
          let shopCategories = []
          for (const [keyLv1, lv1] of Object.entries(data)) {
            let child = {}
            let shopChild = {}
            const subChild = []
            const shopSubChild = []
            child.categoryId = parseInt(keyLv1)
            shopChild.shopCategoryId = parseInt(keyLv1)
            for (const [keyLv2, valueLv3] of Object.entries(lv1)) {
              subChild.push({
                categoryId: parseInt(keyLv2),
                subChild: valueLv3
              })
              shopSubChild.push({
                shopCategoryId: parseInt(keyLv2),
                subChild: valueLv3
              })
            }
            categories.push({ ...child, child: subChild })
            shopCategories.push({ ...shopChild, child: shopSubChild })
          }
          if (props.mode === 'create') {
            props.handleGetCate({ categories })
            props.setCateSelected({ cateSelected, onCheckBeforeRemoveCate, onRemoveItem, childTree })
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
            props.isClose()
            setOnload(false)
            return
          } else {
            const categoriesKey = categoryType == 'custom' ? 'shopCategories' : 'categories'
            const categoriesRes = categoryType == 'custom' ? shopCategories : categories
            const param = {
              brandId: modal.data.id,
              [categoriesKey]:categoriesRes,
            }
            try {
              const res = await brandProvider.createBrandCategory(param,shopDetail)
              if (res) {
                props.isClose()
                dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } }))
                cleanup();
              }
            } catch (error) {
              dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
              dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการผิดพลาด!`, type: 'danger' } }))
            }
          }
          dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        }
        break;
      case 'product':
        if (statusModal === 'update') {
          setOnload(true)
          let cateProduct = setParamProductCat()
          const productId = cateProduct.productId
          delete cateProduct.productId
          const resShopDetail = await getShopDetail()

          try {
            const res = await api_product.updateCategoryProduct(cateProduct, productId,resShopDetail.categoryType)
            if (res.status === 200) {
              dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { type: 'success', message: 'ทำรายการสำเร็จ' } }))
              props.onSubmit()
              props.isClose()
            }
          } catch (error) {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
            dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { type: 'danger', message: 'ทำรายการผิดพลาด!' } }))
            throw error
          }
        } else {
          if (previewList['lv1'] && previewList['lv2']) {
            const cateProduct = setParamProductCat()
            try {
              const res = await api_product.createCategoryProduct(cateProduct,categoryTypeInfo)
              if (res.status === 200) {
                dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { type: 'success', message: 'ทำรายการสำเร็จ' } }))
                props.onSubmit()
                props.isClose()
              }
            } catch (error) {
              console.log(`error?.response`, error?.response)
              throw error
            }
          } else {
            dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { type: 'danger', message: 'กรุณากรอกข้อมูลให้ครบ' } }))
          }
        }
        setOnload(false)
        break;
      case 'playlist':
        if (statusModal === 'update') {
          setOnload(true)
          let defaultData = {}
          // const shopId = modal.data.playlistsPlatform?.shopId
          // const res = await playlistsProvider.getPlaylistCategoriesMall(shopId)
          const playlistId = modal.data.playlistsId
          const res = await playlistsProvider.getPlaylistCategoriesByPlaylistId(playlistId)
          res.map((e) => {
            defaultData = { ...defaultData, [e.categories.id]: e.categories }
            return defaultData
          })
          let paramPlaylist = {}
          const categoryIds = []
          const removeCategoryId = []
          await Promise.all(Object.keys(childTree).map((item, idx) => {
            if (!defaultData[item]) {
              categoryIds.push(item)
              // delete defaultData[item]
            } else {
              delete defaultData[item]
            }
          }))
          await Promise.all(Object.keys(defaultData).map((e) => {
            removeCategoryId.push(e)
          }))

          paramPlaylist = {
            playlistId: modal.data.playlistsId,
            addCategoryIds: categoryIds,
            removeCategoryId: removeCategoryId
          }
          if (paramPlaylist.addCategoryIds.length === 0 && paramPlaylist.removeCategoryId.length === 0) {
            props.isClose()
            // dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { type: 'danger', message: 'ทำรายการผิดพลาด' } }))
          } else {
            let statusUpdate = false
            if (paramPlaylist.addCategoryIds.length > 0) {
              const resCreate = await playlistsProvider.addPlaylistCategories({
                playlistId: modal.data.playlistsId,
                categoryIds: paramPlaylist.addCategoryIds,
              })
              if (resCreate.status === 201) {
                statusUpdate = true
              }
            }
            if (paramPlaylist.removeCategoryId.length > 0) {
              const resDelete = await playlistsProvider.deleteCategoriesPlaylist(paramPlaylist.playlistId, { categoryIds: removeCategoryId })
              if (resDelete.status === 200) {
                statusUpdate = true
              }
            }
            if (statusUpdate) {
              dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { type: 'success', message: 'ทำรายการสำเร็จ' } }))
              dispatch(dispatchPlaylists({ type: GET_PLAYLIST_CATEGORY_MALL, payload: { playlistId: modal.data.playlistsId } }))
              props.isClose()
            } else {
              props.isClose()
            }
          }
          setOnload(false)
        } else {
          setOnload(true)
          let paramPlaylist = {}
          const categoryIds = []
          Object.keys(childTree).map((item, idx) => {
            categoryIds.push(item)
          })
          paramPlaylist = {
            playlistId: modal.data.playlistsId,
            categoryIds: categoryIds
          }
          if (paramPlaylist.categoryIds.length > 0) {
            const res = await playlistsProvider.addPlaylistCategories(paramPlaylist)
            if (res.status === 201) {
              dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { type: 'success', message: 'ทำรายการสำเร็จ' } }))
              dispatch(dispatchPlaylists({ type: GET_PLAYLIST_CATEGORY_MALL, payload: { playlistId: modal.data.playlistsId } }))
              // { type: GET_PLAYLIST_CATEGORY_MALL, payload: { playlistId: playlistsId } }
              props.isClose()
            }
          } else {
            setOnload(false)
            return dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { type: 'danger', message: 'กรุณากรอกข้อมูล' } }))
          }
          setOnload(false)
        }
        break;
      default:
        break;
    }
  }

  const setParamProductCat = () => {
    let cateProduct = {}
    if (previewList['lv1'] && previewList['lv2']) {
      const productId = modal?.data?.id
      cateProduct.productId = productId
      cateProduct.categoryId = previewList['lv2'].id
      const categoryProduct = [{
        id: previewList['lv1'].id,
        value: previewList['lv1'].name
      }, {
        id: previewList['lv2'].id,
        value: previewList['lv2'].name
      }]

      if (previewList['lv3']) {
        cateProduct.categoryId = previewList['lv3'].id
        categoryProduct.push({
          id: previewList['lv3'].id,
          value: previewList['lv3'].name
        })
      }

      cateProduct.category = categoryProduct

      const catePath = cateProduct.category.map((e) => e.value)
      cateProduct.categoryPath = `/${catePath.join('/')}`
    }
    return cateProduct
  }

  const handleSearch = async (search) => {
    if (search?.trim().length > 0) {
      let resSearch = []
      const resShopDetail = await getShopDetail()
      if (type === 'brand') {
        const { data } = await categoriesProvider.getCategories({ search: search },resShopDetail)
        resSearch = data
      } else if (type === 'product') {
        const { data } = await brandProvider.getCateBrandfromBrandId({ brandId: modal.data.brandId, search: search },resShopDetail)
        resSearch = data
      } else if (type === 'playlist') {
        const shopId = modal.data.playlistsPlatform?.shopId
        const { data } = await playlistsProvider.getPlaylistCategoriesMall({ shopId, search })
        resSearch = data
      }
      setInitLevel1(resSearch)
      setInitLevel2([])
      setInitLevel3([])
      setPreviewList({})
    } else {
      // const { data } = await brandProvider.getCateBrandfromBrandId({ search: search },shopDetail)
      let resSearch = []
      const resShopDetail = await getShopDetail()
      if (type === 'brand') {
        const { data } = await categoriesProvider.getCategories(null,resShopDetail)
        resSearch = data
      } else if (type === 'product') {
        const { data } = await brandProvider.getCateBrandfromBrandId({ brandId: modal.data.brandId },resShopDetail)
        resSearch = data
      }
      else if (type === 'playlist') {
        const shopId = modal.data.playlistsPlatform?.shopId
        const { data } = await playlistsProvider.getPlaylistCategoriesMall({ shopId })
        resSearch = data
      }
      setInitLevel1(resSearch)
      setInitLevel2([])
      setInitLevel3([])
      setPreviewList({})
    }
  }

  const handleCloseModal = () => {
    if (props?.mode === 'create') {
      props.isClose()
    } else {
      props.isClose();
      cleanup();
    }
  }

  return (
    <Modal
      isOpen={modal?.isOpen}
      toggle={() => { handleCloseModal() }}
      backdrop='static'
      // style={{ width: '80%', maxWidth: '80%' }}
      className='modal-select-list'
      size="xl"
    >
      <ModalBody>
        <div className='bg-white header'>
          <span className='txt-topic'>
            {type === 'brand' && 'เลือกหมวดหมู่ที่เหมาะสมสำหรับแบรนด์สินค้า'}
            {type === 'product' && 'เลือกหมวดหมู่ที่เหมาะสมสำหรับสินค้า'}
            {type === 'playlist' && 'เพิ่มหมวดหมู่ลงเพลย์ลิสต์'}
          </span>
          <div>
            <img alt='' src={IconClose} onClick={() => { handleCloseModal() }} className='cursor-pointer' />
          </div>
        </div>
        <div className='content'>
          <div className="w-50">
            <InputSearch
              // defaultValue={}
              handleSearch={() => handleSearch(search)}
              placeholder='ค้นหา'
              onChange={(e) => setSearch(e.target.value)}
              onClearSearch={() => { setSearch(''); handleSearch('') }}
              statusSearch={search && search.trim().length > 0}
            />
          </div>
          {/* <form onSubmit={(e) => { e.preventDefault(); handleSearch() }} className="w-50 position-relative">
            <IconSearch className="cursor-pointer position-absolute" onClick={(e) => { e.preventDefault(); handleSearch() }} style={{ right: '10px', top: '20%' }} />
            <input
              type='text'
              className='form-control mb-3'
              name='name'
              autoComplete="off"
              // value={values.name}
              onChange={(e) => setSearch(e.target.value)}
              placeholder='ค้นหาหมวดหมู่'
            />
          </form> */}

          <div className='check-box-container'>
            <CheckboxList
              defaultData={initLevel1}
              selectedList={selectedList}
              onSelectItem={onSelectItemLevel1}
              onPreview={onPreviewLevel1}
              previewList={previewList['lv1']}
              listCat={listCat}
              level={1}
              type={type}
              onload={onload}
            />
            <CheckboxList
              defaultData={initLevel2}
              selectedList={selectedList}
              onSelectItem={onSelectItemLevel2}
              previewList={previewList['lv2']}
              onPreview={onPreviewLevel2}
              listCat={listCat}
              level={2}
              type={type}
              onload={onload}
            />
            <CheckboxList
              defaultData={initLevel3}
              selectedList={selectedList}
              onSelectItem={onSelectItemLevel3}
              listCat={listCat}
              level={3}
              type={type}
              onPreview={onPreviewLevel3}
              previewList={previewList['lv3']}
              onload={onload}
            />
          </div>
          <div className={classNames('mt-2 mb-2', { 'd-flex flex-row align-items-center': type === 'product' })}>หมวดหมู่สินค้าที่เลือก :
            {type === 'product' &&
              <div className='color-info-main ml-3'>
                {previewList['lv1'] && <SubTitle2>{previewList['lv1'].name}</SubTitle2>}
                {previewList['lv2'] && <SubTitle2> {'>'} {previewList['lv2'].name}</SubTitle2>}
                {previewList['lv3'] && <SubTitle2> {'>'} {previewList['lv3'].name}</SubTitle2>}
              </div>
            }
          </div>
          {type === 'brand' &&
            <div className='selected-list-cat'>
              {
                cateSelected.map((item, idx) =>
                  <div className='item-selected' key={`cate${idx}`}>
                    <span className='text-item-selected'>
                      {item.title}
                    </span>
                    <div>
                      <img alt='' className='ml-3' src={IconClose}
                        onClick={() => onCheckBeforeRemoveCate(item)}
                        style={{ width: '15px' }} />
                    </div>
                  </div>
                )
              }
            </div>
          }
          {type === 'playlist' &&
            <div className="d-flex flex-row flex-wrap" style={{ gap: '5px' }}>
              {Object.keys(childTree).map((e, idx) => (
                <div className='item-selected' key={`cate${idx}`}>
                  <span className='text-item-selected'>
                    {selectedList[e]?.name}
                  </span>
                  <div>
                    <img alt='' className='ml-3' src={IconClose}
                      onClick={() => {
                        onCheckBeforeRemoveCate({ level1: selectedList[e].id })
                      }}
                      style={{ width: '15px' }} />
                  </div>
                </div>
              ))}
            </div>

          }

        </div>

        <div className='bg-white footer'>
          <button className='btn-cancel mr-1' onClick={() => { handleCloseModal() }}>
            ยกเลิก
          </button>
          <button className='btn-submit ml-1' onClick={() => onSubmit()}>
            ยืนยัน
          </button>
        </div>
        <ModalAlert />
        <Loading />
      </ModalBody>
    </Modal >
  )
}

export default ModalSelectList
