import React, { useEffect, useState } from "react";
import "./style.scss";
import { Body1, Caption, H5, H6, SubTitle2, Tooltip } from "components/FontStyle";

import { ReactComponent as IconEdit } from 'images/icons/edit.svg'
import { ReactComponent as IconTrash } from 'images/icons/trash.svg';
import { ReactComponent as IconArrowRight } from 'images/icons/arrow-right.svg';
import Icon_Edit from 'images/icons/edit-no-padding.svg'
import Icon_Trash from 'images/icons/trash-no-padding.svg';

const CategoryItem = (props) => {
  const { name, onUpdate, onDelete, onSelectItem, isSelected } = props || {};
  const [isHover, setIsHover] = useState(false);
  const isActive = isHover || isSelected

  return (
    <div
      className={["category-item-container", isActive ? '' : 'display-hidden'].join(' ')}
      onMouseOut={() => setIsHover(false)}
      onMouseOver={() => setIsHover(true)}
      onClick={() => onSelectItem()}
    >

      <div className="category-name">
        <Caption style={{ color: isActive ? '#ED1072' : '#2A2C2D' }}>{name}</Caption>
      </div>

      <div className="handle-category">
        <img
          className={"icon-edit icon-manage cursor-pointer"}
          src={Icon_Edit}
          onClick={(e) => { onUpdate(); e.stopPropagation() }}
        />
        <img
          className={"icon-trash icon-manage cursor-pointer"}
          src={Icon_Trash}
          onClick={(e) => { onDelete(); e.stopPropagation() }}
        />
        {/* <IconEdit
          onClick={(e) => { onUpdate(); e.stopPropagation() }}
          className={"icon-edit icon-manage cursor-pointer"}
        />
        <IconTrash
          onClick={(e) => { onDelete(); e.stopPropagation() }}
          className={"icon-trash icon-manage cursor-pointer"}
        /> */}
        <IconArrowRight
          className={["icon-arrow-right icon-manage cursor-pointer", isActive ? 'isSelect' : ''].join(' ')}
        />
      </div >
    </div >
  );
}


export default CategoryItem;