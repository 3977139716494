import TableAnnounce from "components/TableAnnounce";
import React, { useEffect, useState } from "react";
import "../styles/_manageAnnounce.scss";
import { ReactComponent as IconNoPromocode } from "images/icons/no-item.svg";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchAnnounce,
  GET_ALL_ANNOUNCES,
  SET_PAGINATION,
  DELETE_ANNOUNCE,
} from "redux/actions/announce";
import PaginationComponent from "components/pagination";
import ModalConfirm from "components/modalConfirm/ModalConfirm";
import {
  dispatchModalConfirm,
  OPEN_MODAL_CONFIRM,
} from "redux/actions/modalConfirm";
import { ButtonAdd } from "components/output";

const Announce = (props) => {
  const dispatch = useDispatch();
  const { announceList, pagination } = useSelector((state) => state.announce);
  const { modalConfirm } = useSelector((state) => state.modalConfirm);

  const [announceId, setAnnounceId] = useState(null);

  useEffect(() => {
    dispatch(
      dispatchAnnounce({
        type: GET_ALL_ANNOUNCES,
        payload: {
          limit: 10,
          page: 1,
        },
      })
    );
  }, []);

  const handleChange = (page, limit) => {
    dispatch(
      dispatchAnnounce({
        type: GET_ALL_ANNOUNCES,
        payload: {
          limit: limit,
          page: page,
          currentPage: page
        },
      })
    );
  };

  const handleChangeSizePage = (page, limit) => {
    dispatch(
      dispatchAnnounce({
        type: SET_PAGINATION,
        payload: {
          limit: limit,
          currentPage: page,
        },
      })
    );
  };

  const Pagination = () => {
    return (
      <div className="cover-pagination-announce">
        <PaginationComponent
          total={announceList?.totalCount}
          onShowSizeChange={(page, limit) => handleChangeSizePage(page, limit)}
          onChange={(page, limit) => handleChange(page, limit)}
          pageSize={pagination?.limitPage}
          current={pagination?.currentPage}
        />
      </div>
    );
  };

  const handleDeleteAnnounce = () => {
    dispatch(
      dispatchAnnounce({
        type: DELETE_ANNOUNCE,
        payload: {
          id: announceId,
        },
      })
    );
  };

  const handleOpenModalConfirm = (id) => {
    setAnnounceId(id);
    dispatch(dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: true }));
  };

  const handleEditAnnounce = (announceId) => {
    props.history.push(`/manage/announce/edit-announce/${announceId}`);
  };

  const handleNavigateToAddAnnounce = () => {
    props.history.push("/manage/announce/add-announce");
  };

  return (
    <div className="wrap-manage-announce">
      <div className="cover-header-announce">
        <div>
          <span className="title-manage-announce">ประกาศ</span>
          <span className="subTitle-manage-announce">
            ทั้งหมด {announceList?.totalCount} รายการ
          </span>
        </div>

        <ButtonAdd
          label="สร้างประกาศ"
          handleAdd={handleNavigateToAddAnnounce}
        />
      </div>

      <div className="cover-body-announce">
        {announceList.data?.length == 0 ? (
          <div className="no-data-announce">
            <IconNoPromocode />
            <span className="text-no-data">ไม่มีรายการ</span>
          </div>
        ) : (
          <>
            <Pagination />
            <TableAnnounce
              data={announceList?.data}
              currentPage={pagination?.currentPage}
              limitPage={pagination?.limitPage}
              handleEditAnnounce={handleEditAnnounce}
              handleDeleteAnnounce={(id) => handleOpenModalConfirm(id)}
            />
            <Pagination />
          </>
        )}
      </div>

      <ModalConfirm
        isOpen={modalConfirm}
        message={"คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้"}
        btnConfirm={"ยืนยัน"}
        btnCancel={"ยกเลิก"}
        confirm={() => handleDeleteAnnounce()}
      />
    </div>
  );
};

export default Announce;
