import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";

import { SelectShop } from 'components/PlaylistItems/'
import Playlists from 'components/PlaylistItems/Playlists'
import {
  // dispatchShop,
  // ADD_KEY_VALUE_SHOP,
} from 'redux/actions/shop'

const PlaylistMall = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  // const userInfo = useSelector(state => state.user.userInfo)
  const shopSelected = useSelector(state => state.shop.shopSelected)

  useEffect(() => {
    // return () => unmountComponent()
  }, [])

  // const unmountComponent = () => {
  //   dispatch(
  //     dispatchShop({
  //       type: ADD_KEY_VALUE_SHOP,
  //       payload: { key: 'shopSelected', value: null }
  //     }));
  // }

  return (<>
    {/* {
      userInfo.shopId ?
        <Playlists data={{ dispatch, history }} />
        :
        <>
          {
            shopSelected ?
              <Playlists data={{ dispatch, history }} />
              :
              <SelectShop showPlayList={true}/>
          }
        </>
    } */}
    {
      shopSelected ?
        <Playlists data={{ dispatch, history }} />
        :
        <SelectShop showPlayList={true} />
    }
  </>)
}

export default PlaylistMall;