import React,{useEffect,useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import classNames from "classnames";
import host from 'config/host';
import IconEmptyProduct from 'images/icons/cover-product.svg'
import { Checkbox } from "components/input";
import { CHECK_PRODUCT_CHECKED_SHOP, COUNT_CHECKED_ALL_SHOP, UNCHECK_PRODUCT_CHECKED_SHOP } from "redux/actions/checkedShop";
import { ADD_CHECKED_PRODUCT_PROMOCODE, REMOVE_CHECKED_PRODUCT_PROMOCODE, UPDATE_COUNT_CHECKED_ALL_SHOP } from "redux/actions/promocode";
import { OPEN_TOAST } from 'redux/actions/notification'
import { ADD_LIST_CHECKED_SHOP, REMOVE_LIST_CHECKED_SHOP } from "redux/actions/listCheckedShop";
import numeral from "numeral";

const CardShopProduct = (props) => {

    const {shopId, product, objSku, isAllProduct:isAll} = props || {};
    const {coverPath, name, productSkus, productId} = product || {};

    const dispatch = useDispatch();
    const checkedShopState = useSelector(state => state.checkedShop);
    const seletedShopState = useSelector(state => state.selectedShop);
    const checkedProduct = checkedShopState?.shops[`${shopId}`];
    const selectedProduct = seletedShopState?.shops[`${shopId}`];
    const [isAllProduct, setIsAll] = useState(null)
    useEffect(() => {
      setIsAll(isAll)
      return () => {
      }
    }, [isAll])
    

    const promocodeState = useSelector(state => state.promocode)

    function getPriceNetScope(productSkus) {
        try {
            if(productSkus && typeof productSkus === "object") {
                if(Object.values(productSkus).length > 1) {
                    const prices = Object.values(productSkus).map(product => product.priceNet);
                    const maxPrice = Math.max(...prices);
                    const minPrice = Math.min(...prices);
                    if(maxPrice === minPrice) return  `฿ ${maxPrice}`
                    else return  `฿ ${numeral(minPrice).format('0,0')} - ${numeral(maxPrice).format('0,0')}`
                } else {
                    return `฿ ${numeral(Object.values(productSkus)[0]?.priceNet).format('0,0')}`;
                }
            }
            return "";
        } catch(error) {
            throw(error)
        }
    }
    
    const handleOnClickCheckbox = () => {
      const sku = objSku(productSkus)
      if (selectedProduct?.product[productId] !== undefined) return null;

      if (checkedProduct?.product[productId] !== undefined) {

        dispatch({
          type: UNCHECK_PRODUCT_CHECKED_SHOP,
          payload: { [shopId]: {[productId]: sku} },
        });
        dispatch({
            type: REMOVE_LIST_CHECKED_SHOP,
            payload: { shopId, productId },
          });
        dispatch({type: COUNT_CHECKED_ALL_SHOP,
          payload: { shopId: `${shopId}` }
        })
        dispatch({type: UPDATE_COUNT_CHECKED_ALL_SHOP,
          payload: { shopId: `${shopId}` }
        })

      } else {

        if(selectedProduct?.count + checkedProduct?.count >= 100 && !selectedProduct?.allProduct) {
          dispatch({
            type: OPEN_TOAST,
            payload: {
              type: 'danger',
              message: `คุณเลือกสินค้าเกินที่กำหนด <br/>
              (สินค้าบางรายการ สามารถเข้าโปรโมชั่น ได้สูงสุด 100 สินค้า)`
            }
          });

        } else {

          dispatch({
            type: CHECK_PRODUCT_CHECKED_SHOP,
            payload: { [shopId]: 
            {
            name: promocodeState?.shopSelectedDetail?.name,
            logoPath: promocodeState?.shopSelectedDetail?.logoPath,
            allProduct: false, ///////////////////////////////////////////////////////////////////
            product: {
                [productId]: sku
              }
            } 
          },
          });

          
  
          dispatch({
              type: ADD_LIST_CHECKED_SHOP,
              payload: { [shopId]: {[productId]: product} },
          });
          
          dispatch({type: COUNT_CHECKED_ALL_SHOP,
            payload: { shopId: `${shopId}` }
          })
          dispatch({type: UPDATE_COUNT_CHECKED_ALL_SHOP,
            payload: { shopId: `${shopId}` }
          })
        }

      }
    
    };

    


    return (
      <div className={classNames("card-product", { active: checkedProduct?.product[productId] !== undefined && !isAllProduct, })} >
        <div className="select-product">
          <Checkbox
            defaultChecked={productId}
            checking={isAllProduct ? true :
              selectedProduct?.product[productId] !== undefined
                ? true
                : checkedProduct?.product[productId] !== undefined
            }
            onSelectMedia={handleOnClickCheckbox}
            disabled={selectedProduct?.product[productId] !== undefined || isAllProduct === true}
          />
        </div>
        <div className="product-image">
          {coverPath?.path ? (
            <img src={`${host.image}${coverPath?.path}`} />
          ) : (
            <img src={IconEmptyProduct} />
          )}
        </div>
        <div className="product-detail">
          <div className="product-name"> {name}</div>
          <div className="product-price">
            <span>{getPriceNetScope(productSkus)}</span>
          </div>
        </div>
      </div>
    );
}

export default CardShopProduct;