/* eslint-disable array-callback-return */

/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import host from '../config/host'

import { productByShop } from 'components/ManageLiveProduct/mock'
import classnames from 'classnames'

import ManageLiveProduct from 'components/ManageLiveProduct';
import ModalCreateLive from 'components/live/ModalCreateLive';
import ModalAlert from 'components/modalAlert/ModalAlert';
import ModalConfirm from 'components/modalConfirm/ModalConfirm';
import DateTimePicker from 'components/DateTimePicker';
import DatePickerInput from 'components/DatePickerInput'
import ImageUpload from 'components/ImageUpload';
import VideoUpload from 'components/VideoUpload';
import RadioButtonGroup from 'components/RadioButtonGroup';
import VideoPlayer from 'components/VideoPlayer2'
import VideoPlayer3 from 'components/VideoPlayer3'
import { ButtonUpload, MediaCard } from 'components/output';

import IconIncrease from '../images/icons/increse.svg'
import IconClose from 'images/icons/close-image.svg'

import { dispatchLive, OPEN_MODAL_CREATE_LIVE } from 'redux/actions/live';
import { dispatchPost, CREATE_POST_VOD, GET_POST_VOD_BY_ID } from 'redux/actions/post';
import { dispatchProduct, GET_ALL_PRODUCTS_BY_SHOP, GET_INITIAL_EDIT_VOD } from 'redux/actions/product';
import { dispatchModalConfirm } from 'redux/actions/modalConfirm';
import { OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';
import { dispatchModalAlert, OPEN_MODAL_ALERT, } from 'redux/actions/modalAlert'
import { dispatchGallery, SET_MEDIA_UPLOAD_EVENT, SET_GALLERY_FIELD, } from 'redux/actions/gallery';
import { msgErrRequire } from 'helper/constant';

const _publishStatus = [{ label: 'ตั้งเวลา', value: 'SET_TIME' },
{ label: 'เผยแพร่ทันที', value: 'PUBLIC' }]
const initialState = {
    title: '',
    releaseDate: {
        date: moment(),
        time: null
    },
    products: [],
    publishStatus: _publishStatus[0].value,
    initStatus: false,
    errors: [],
    checkStock: false
}

class Upload extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    getInitialState = () => {
        let state = JSON.parse(JSON.stringify(initialState));
        var time = moment().utcOffset(0);
        time.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        state.releaseDate.date = moment();
        state.releaseDate.time = time;
        return state
    }

    componentDidMount = () => {


        const params = new URLSearchParams(window.location.search);
        const mode = params.get('mode');
        const postId = params.get('postId');
        this.setState({ mode, currentPostId: postId });

        this.props.dispatch(dispatchProduct({
            type: 'GET_INITIAL_EDIT_VOD',
            payload: { postId },
        }));
        // this.getPostVodById()
        // this.getProductByShop()


    }

    componentDidUpdate = prevProps => {

        if (this.state.checkStock === true) {

            if (this.props.productsByShop !== prevProps.productsByShop) {
                if (this.validateStock(this.props) === true) this.createVodPost(this.state.mode);
            }

        } else {

            if (this.state.mode === 'update') {

                // if (this.props.productsByShop && (this.props.productsByShop !== prevProps.productsByShop)) {
                //     const  {initStatus} = this.state;
                //     if (initStatus === false) {
                //         this.initialComponent(this.props.postVod);
                //         const products = this.filterProductSelected();
                //         this.setState({products,initStatus:true});
                //    }
                // }
                if (this.props.postVod !== prevProps.postVod) {
                    this.initialComponent(this.props.postVod);
                }



            } else {

                /* NOTE - init product with extra price */
                if (this.props.productsByShop !== prevProps.productsByShop) {
                    const products = this.initialProducts(this.props.productsByShop);
                    this.setState({ products });
                }
            }
        }

        if (prevProps.vodVideoUploadEvent !== this.props.vodVideoUploadEvent) {
            if (document.getElementsByClassName('video-js').length > 0) {
                document.getElementsByClassName('video-js')[0].classList.add('video-player')
                document.getElementsByClassName('video-js')[0].classList.remove('video-js')
                if (document.getElementsByClassName('video-js').length > 1) {
                    document.getElementsByClassName('video-js')[1].classList.add('video-player')
                    document.getElementsByClassName('video-js')[1].classList.remove('video-js')
                }
            }
        }
    }

    /* SECTION - common function */
    initialComponent = () => {
        let state = JSON.parse(JSON.stringify(this.state));
        let { title, releaseDate, } = state;
        const { postVod } = this.props;

        // NOTE - set date time release
        var time = moment(postVod.releaseDate);
        releaseDate.date = new Date(postVod.releaseDate);
        releaseDate.time = time;

        //   const dateString = moment(_releaseDate.date).format('MM/DD/YYYY')
        //   const timeString = moment(_releaseDate.time).format('HH:mm')
        //   const releaseDate = new Date(`${dateString} ${timeString}`).toISOString()

        title = postVod.title;
        this.props.dispatch(dispatchGallery({
            type: 'SET_GALLERY_FIELD',
            payload: {
                key: 'vodImageUploadEvent',
                value: [postVod.cover]
            },
        }));
        this.props.dispatch(dispatchGallery({
            type: 'SET_GALLERY_FIELD',
            payload: {
                key: 'vodVideoUploadEvent',
                value: [{ pathVod: postVod.publishUrl }]
            },
        }));
        this.setState({ title, releaseDate });

        const { initStatus } = this.state;
        if (initStatus === false) {
            const products = this.filterProductSelected();
            this.setState({ initStatus: true, products });
        }
    }

    initialProducts = (_products) => {
        let products = JSON.parse(JSON.stringify(_products));
        return products.map((product) => {
            product.productSkus = product.productSkus.map((productSku) => {
                // NOTE - insert special property
                //   productSku.select = false
                const extraPriceTemplate = {
                    discountPrice: null,
                    discountPercent: null,
                    buyLimit: null,
                    privileges: null,
                }
                productSku.extraPrice = extraPriceTemplate;
                productSku.errors = {};
                return productSku;
            })
            return product;
        });
        //   this.setState({products})
    }

    createVodPost = (mode) => {
        const { products, publishStatus, title, currentPostId } = this.state;
        const { currentShopId: shopId,
            vodImageUploadEvent: vodImageId,
            vodVideoUploadEvent: vodVideoId,
            postVod } = this.props;
        const { date, time } = this.state.releaseDate;

        const dateString = moment(date).format('MM/DD/YYYY');
        const timeString = moment(time).format('HH:mm');
        let releaseDate = publishStatus === 'SET_TIME' ? new Date(`${dateString} ${timeString}`).toISOString() : new Date().toISOString();
        let post = mode !== 'update' ?
            {
                coverPathId: vodImageId[0]?.id,
                videoPathId: vodVideoId[0]?.id,
                shopId,
                title,
                releaseDate
            }
            :
            {
                coverPathId: vodImageId[0]?.id,
                title,
                releaseDate
            }

        if (publishStatus === 'PUBLIC')
            post.status = 'public'


        const payload = {
            products,
            post,
            mode: mode ? 'update' : 'create',
            postID: postVod?.postId,
            mediaID: currentPostId ? Number(currentPostId) : ''
        }
        this.props.dispatch(dispatchGallery({ type: CREATE_POST_VOD, payload }));

    }

    getPostVodById = () => {
        const params = new URLSearchParams(window.location.search);
        const postId = params.get('postId');
        /* TODO - get post media by id */
        if (postId)
            this.props.dispatch(dispatchPost({ type: GET_POST_VOD_BY_ID, payload: { postId } }),)
    }

    getProductByShop = () =>
        this.props.dispatch(dispatchProduct({ type: GET_ALL_PRODUCTS_BY_SHOP, payload: {} }),)

    filterProductSelected = () => {
        try {
            const { postVod } = this.props;
            const products = postVod.items.reduce((total, item) => {

                const { limitPerUser, discountValue, priceDiscount, quotas, id, priceNet, skuId } = item;
                let productSku = {
                    ...item,
                    select: true,
                    itemId: id,
                    extraPrice: {
                        buyLimit: limitPerUser === 9999 ? '' : limitPerUser,
                        discountPercent: discountValue,
                        discountPrice: priceNet,
                        privileges: quotas
                    }
                }
                productSku.id = skuId;

                let productItem = {}
                const existItemIndex = total.findIndex(_item => _item.id === item.id)
                /* NOTE - same product insert new sku */
                if (existItemIndex > -1) {
                    total[existItemIndex].productSkus = [...productItem.productSkus, productSku]
                    return total
                } else { /* NOTE - insert new product insert new sku */
                    productItem = { ...item, name: item.productName, id: item.productId, productSkus: [productSku] }
                    return [...total, productItem];
                }

            }, [])
            return products;
        } catch (error) {
            return []
        }
    }

    validateStock = (nextProps) => {

        // NOTE - Validate stock remain
        let newProductList = [...nextProps.productsByShop].map((element) => Object.assign({}, element),)
        let stockList = {}

        newProductList = newProductList.map((product) => {
            product.productSkus.map((sku) => {
                stockList[sku.id] = {}
                stockList[sku.id].stockRemain = sku.stockRemain
            })
        })

        let validateStock = true
        let validateBuyLimit = true
        const _products = [...this.state.products].map((element) => Object.assign({}, element),)

        const checkStock = _products.map((product) => {
            product.productSkus = product.productSkus.map((sku) => {
                sku.error = {}
                if (sku.select) {
                    if (sku.extraPrice.privileges === '' ||
                        sku.extraPrice.privileges > stockList[sku.id].stockRemain ||
                        sku.extraPrice.privileges < 0
                    ) {

                        sku.error.stockOver = true;
                        validateStock = false;

                    }
                    // if (  sku.extraPrice.buyLimit === ''
                    if (sku.extraPrice.buyLimit > stockList[sku.id].stockRemain
                        || sku.extraPrice.buyLimit < 0) {


                        sku.error.buyLimit = true;
                        validateBuyLimit = false;
                    }
                }
                sku.stockRemain = stockList[sku.id].stockRemain
                return sku
            })
            return product
        })

        this.setState({
            checkStock: false,
            products: checkStock,
        })

        if (!validateStock) {
            this.props.dispatch(dispatchModalAlert({
                type: OPEN_MODAL_ALERT,
                payload: { isOpen: true, message: 'สิทธิ์พิเศษมีจำนวนเกินจากสต็อกสินค้า กรุณาตรวจสอบอีกครั้ง', type: 'alert', },
            }))
        }
        if (!validateBuyLimit) {
            this.props.dispatch(dispatchModalAlert({
                type: OPEN_MODAL_ALERT,
                payload: { isOpen: true, message: 'จำกัดจำนวนการซื้อเกินสต็อค', type: 'alert', },
            }))
        }

        return validateStock && validateBuyLimit
    }

    validateParam = () => {

        this.hasError = false;
        const { title, products, } = JSON.parse(JSON.stringify(this.state));
        const { vodImageUploadEvent, vodVideoUploadEvent } = this.props;


        // NOTE - check empty liveTitle liveCoverImageFile product
        this.checkEmptyPostDetail = () => {
            let errors = {}

            if (!(title.length > 0)) errors.title = true

            if (!vodImageUploadEvent)
                errors.vodCoverImageFile = true

            if (!vodVideoUploadEvent)
                errors.vodCVideoFile = true

            if (Object.keys(errors).length > 0) {
                this.openAlertModal(msgErrRequire)
                this.hasError = true;
            }
            this.setState({ errors })
        }

        // NOTE - check product selected
        this.checkProductSelected = () => {
            let countSelectProduct = 0;
            [...products].map((product) => {
                product.productSkus.map((sku) => {
                    if (sku.select) countSelectProduct++
                })
            })
            if (!(countSelectProduct > 0)) {
                this.openAlertModal('กรุณาเพิ่มสินค้า')
                this.hasError = true;
            }
        }

        // NOTE - check product empty field
        this.checkProductEmptyField = () => {
            let validateEmptyProducts = true
            let validateFormat = true
            const validateProducts = JSON.parse(JSON.stringify(products)).map((product) => {
                product.productSkus =
                    product.productSkus.map((sku) => {
                        let errors = {}

                        if (sku.select) {
                            const { buyLimit, discountPercent, discountPrice, privileges, } = sku.extraPrice

                            if (discountPercent === null && discountPrice === null) {
                                errors.discountPrice = true
                                errors.discountPercent = true
                            } else {

                                // NOTE - check price format
                                if (
                                    discountPrice < 0 ||
                                    discountPercent < 0 ||
                                    discountPercent > 100
                                ) {
                                    errors.discountPrice = true;
                                    errors.discountPercent = true;
                                    validateFormat = false;
                                }
                            }

                            if (privileges === null)
                                errors.privileges = true

                            if (Object.keys(errors).length > 0) {
                                this.hasError = true;
                                validateEmptyProducts = false
                            }
                        }
                        sku.errors = errors
                        return sku
                    })
                return product
            })

            if (!validateEmptyProducts) {
                this.openAlertModal('กรุณกรอกข้อมูลสินค้าให้ครบ')
                this.hasError = true;
            }
            if (!validateFormat) {
                if (!validateFormat) this.openAlertModal('กรุณาใส่ราคาให้ถูกต้อง')
            }

            this.setState({ products: validateProducts })
            if (!validateEmptyProducts) { this.hasError = true }
        }


        this.checkEmptyPostDetail();
        this.checkProductSelected();
        this.checkProductEmptyField();

        return !this.hasError

    }

    validateError = () => {
        /* NOTE - check stock */
        if (this.validateParam())
            this.setState({ checkStock: true }, () => this.getProductByShop())
    }

    reset = () => {
        localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: 3 }));
        window.location.replace('/manage/shop/shopdetail')
    }

    openAlertModal = (message) =>
        this.props.dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message, type: 'alert' }, }),)


    /* !SECTION - common function */


    /* SECTION - event function */
    onTitleChange = (e) => this.setState({ title: e.target.value });

    onDateTimePickerChange = (key, value) => { this.setState({ releaseDate: { ...this.state.releaseDate, [key]: value } }); }

    onSubmitProductSelected = (productSkuSelected) => {
        this.setState({ products: productSkuSelected })
    }

    onClickRemoveMedia = (index, collectionName) => {
        try {

            const tmp = [...this.props[collectionName]]
            if (tmp?.length > 0) {
                tmp.splice(index, 1);
                this.props.dispatch(dispatchGallery({ type: 'SET_GALLERY_FIELD', payload: { key: collectionName, value: tmp, }, }),)
            }
        } catch (error) {
            console.log(error);
        }
    }

    onProductsChange = products => this.setState({ products })

    onSubmitCreatePostVod = () => {
        this.validateError();
    }

    onSendingChange = sending => this.setState({sending})
    /* !SECTION - event function */

    render() {
        // const products = Array.from({ length: 5 });
        const { releaseDate, products, publishStatus, title, errors, mode } = this.state;
        const { date, time } = releaseDate;
        const { vodImageUploadEvent, currentShopId, vodVideoUploadEvent } = this.props;
        return (
            <React.Fragment>
                <div className="section-container">
                    {/* ANCHOR - title */}
                    <section className="section cover">
                        <h5>ชื่อ LIVE <span className="require"></span></h5>
                        <div className="title-detail">(จำกัด 200 ตัวอักษร)</div>
                        <input
                            type='text'
                            className={`form-control ${errors?.title ? 'error-field' : ''}`}
                            name='liveTitle'
                            value={title}
                            onChange={this.onTitleChange}
                        />
                        <span className='error-message'> {errors?.title ? '* กรุณากรอกชื่อ LIVE ' : ''} </span>
                    </section>
                    {/* ANCHOR - cover */}
                    <section className="section cover">
                        <h5>ภาพหน้าปก <span className="require"></span></h5>
                        <div className="title-detail">รองรับสัดส่วนภาพ 1:1 เท่านั้น (สี่เหลี่ยมจัตุรัส) ขนาดรูปแนะนำคือ 640 x 640 พิกเซล ขนาดไฟล์สูงสุด 10 MB</div>
                        <div className={classnames('media-container', { 'border-error': errors?.vodCoverImageFile })}>
                            <ImageUpload imageCollection={vodImageUploadEvent}
                                currentShopId={currentShopId}
                                collectionName={'vodImageUploadEvent'}
                                onClickRemoveMedia={this.onClickRemoveMedia}
                                limitImageUpload={1} />
                        </div>
                        <span className='error-message'> {errors?.vodCoverImageFile ? '* กรุณากรอกเพิ่มภาพปก ' : ''} </span>
                    </section>
                    {/* ANCHOR - upload-file */}
                    <section className="section upload-file">
                        <div className="row">
                            <div className="col">
                                <h5>อัพโหลดไฟล์ <span className="require"></span> </h5>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12 text-left">
                                <span className="title-detail">รองรับขนาดวิดีโอมาตราฐานที่แนะนำคือ 1280×720 และ 1920x1080 พิกเซล ขนาดไฟล์สูงสุด 2 GB </span>
                            </div>
                        </div>
                        <div className={classnames('', { 'border-error': errors?.vodCVideoFile })}>

                            {vodVideoUploadEvent?.length > 0 && vodVideoUploadEvent[0]?.pathVod ?
                                <React.Fragment>
                                    <VideoPlayer playsinline={true}
                                        width={'100%'}
                                        height={'auto'}
                                        muted={false}
                                        fullScreen={false}
                                        conrols={true}
                                        display={false}
                                        source={host.streamUrl + vodVideoUploadEvent[0]?.pathVod} />
                                    <VideoPlayer3 controls={true} src={host.streamUrl + vodVideoUploadEvent[0]?.pathVod} />
                                </React.Fragment>

                                :

                                <VideoUpload videoCollection={vodVideoUploadEvent}
                                    currentShopId={currentShopId}
                                    collectionName={'vodVideoUploadEvent'}
                                    onClickRemoveMedia={this.onClickRemoveMedia}
                                    limitVideoUpload={1} />
                            }
                        </div>
                        <span className='error-message'> {errors?.vodCVideoFile ? '* กรุณากรอกเพิ่ม video ' : ''} </span>

                    </section>
                    {/* ANCHOR - date time */}
                    <section className="section date-time">
                        <h5>วันที่ เวลาที่ แสดงโพสต์</h5>
                        <div className="row mt-4 mb-4">
                            <div className="col-4">
                                <RadioButtonGroup onChange={event => this.setState({ publishStatus: event.target.value })}
                                    value={publishStatus}
                                    disabled={false}
                                    name={"date-time"}
                                    plainOptions={_publishStatus} />
                            </div>
                        </div>
                        {publishStatus === 'SET_TIME' &&
                            <div className="row">
                                <div className="col">
                                    <DateTimePicker date={date}
                                        time={time}
                                        onChange={this.onDateTimePickerChange} />

                                </div>
                            </div>
                        }
                    </section>
                    {/* ANCHOR - products */}
                    <section className="section products">
                        <div className="row">
                            <div className="col">
                                <h5>สินค้าที่จะขายในวิดีโอ</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-left align-self-end">
                                <h5>ตั้งค่าสินค้า</h5>
                            </div>
                            <div className="col-6 text-right cursor-pointer" onClick={() => this.props.dispatch(dispatchLive({ type: OPEN_MODAL_CREATE_LIVE, payload: true, }),)}>
                                <img src={IconIncrease} alt='' />
                                <span className="hyper-link">เพิ่มสินค้า</span>
                            </div>
                        </div>

                        {/* ANCHOR - manage live product form */}
                        <div className="row">
                            <div className="col">
                                <p></p>
                                <ManageLiveProduct products={products}
                                    onProductsChange={this.onProductsChange} />
                            </div>
                        </div>
                    </section>
                    {/* ANCHOR - submit-form */}
                    <section className="section submit-form">
                        <div className="row">
                            <div className="col text-center">
                                <button className='btn-cancel mr-4' onClick={() => this.props.dispatch(dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: true, }),)}>ยกเลิก</button>
                                <button className='btn-submit mr-4' onClick={this.onSubmitCreatePostVod}>{mode === 'update' ? 'แก้ไข' : 'สร้าง'}</button>
                            </div>
                        </div>

                        <ModalConfirm
                            message={'ยกเลิกการสร้าง VOD'}
                            isOpen={this.props.modalConfirm}
                            confirm={this.reset}
                            isClose={() => this.props.dispatch(dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: false, }),)}
                        />

                        <ModalCreateLive
                            isOpen={this.props.modalCreateLive}
                            isClose={() => this.props.dispatch(dispatchLive({ type: OPEN_MODAL_CREATE_LIVE, payload: false, }),)}
                            onSubmitProductSelected={this.onSubmitProductSelected}
                            products={products}
                        />

                        <ModalAlert />

                    </section>
                </div>
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => ({
    postVod: state.post.postVod,
    modalCreateLive: state.live.modalCreateLive,
    modalConfirm: state.modalConfirm.modalConfirm,
    vodImageUploadEvent: state.gallery?.vodImageUploadEvent,
    vodVideoUploadEvent: state.gallery?.vodVideoUploadEvent,
    currentShopId: state.shop.currentShop?.id,
    productsByShop: state.product.productsByShop?.product
})

export default connect(mapStateToProps)(Upload)
