/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { CardDetail, SubTitle2 } from "components/FontStyle";
import {
  GET_SHOPS,
  SET_SHOPS,
  GET_SHOPS_SEARCH,
  ADD_KEY_VALUE_SHOP,
  GET_SHOPS_PROMOTION,
  GET_SHOPS_PROMOTION_SEARCH
} from 'redux/actions/shop';
// import './style.scss';

import { InputSearch } from "components/output";
import "styles/_PlaylistShop.scss";
import host from 'config/host';
import classNames from "classnames";
import IconShop  from 'images/icons/menu/menu-shop.svg';

/* -------------------------------------------------------------------------- */
/*                             conponents function                            */
/* -------------------------------------------------------------------------- */
const ListShop = (props) => {
  const { shopList, onClick, showPlayList } = props;
  return (
    <div id="ListShop">
      <div className="d-flex justify-content-start flex-row flex-wrap">
        {shopList.map((shop, index) => (
          <ShopItem shop={shop}
            key={`shop-${index}`}
            showPlayList={showPlayList}
            onClick={() => onClick(shop)} />
        ))}
      </div>
    </div>
  );
};

const ShopItem = (props) => {
  const { onClick, showPlayList } = props;
  const { logoPath, name, countPlaylist, promotionCount } = props.shop;
  return (
    <div className="card-shop position-relative" onClick={promotionCount ? null : onClick}>
      <div className={classNames({ 'shop-disabled cursor-default d-flex justify-content-center align-items-center': promotionCount })}>
        <SubTitle2 className='color-white'>ร้านค้านี้สร้างโปรโมชั่นค่าส่งแล้ว</SubTitle2>
      </div>
      <div className="d-flex justify-content-start flex-column">
        {
          logoPath?.path ?
          <img src={`${host.avatar}${logoPath?.path}`} />
          :
          <img src={`${IconShop}`} />
        }
        <CardDetail> <span className="text-overflow"> {name} </span></CardDetail>
        {showPlayList &&
          <div className="d-flex justify-content-between">
            <CardDetail>เพลย์ลิส</CardDetail>
            <CardDetail>
              {countPlaylist > 0 ? countPlaylist : "-"}
            </CardDetail>
          </div>
        }
      </div>
    </div>
  );
};

// const LoadingIcon = (props) => {
//   return (
//     <>
//       {props?.isLoading === true && (
//         <div class="d-flex justify-content-center mt-4 mb-4">
//           <div class="spinner-border" role="status"> </div>
//         </div>
//       )}
//     </>
//   );
// };

const InputSearchShop = (props) => {
  const {
    handleSearchKeyPress,
    handleSearchShop,
    handleClearSearch,
    statusSearch,
  } = useInputSearchShop(props);
  const {keyword,setKeyword} = props

  return <div className="row mb-4">
    <div className="col-5">
      <InputSearch
        placeholder={"ค้นหาร้านค้า"}
        onChange={(e) => setKeyword(e.target.value)}
        // onKeyPress={handleSearchKeyPress}
        handleSearch={handleSearchShop}
        onClearSearch={handleClearSearch}
        statusSearch={statusSearch}
        defaultValue={keyword}
      />
    </div>
  </div>
}

/* -------------------------------------------------------------------------- */
/*                              default component                             */
/* -------------------------------------------------------------------------- */
const SelectShop = (props) => {
  const [keyword, setKeyword] = useState('')
  const { shopList,
    hasMore,
    totoalShops,
    fetchMoreData,
    initialComponent,
    handleSetShopParam,
    } = useSelectShop({...props,keyword,setKeyword});
  const { showPlayList } = props;

  return (
    <div className="section-container">

      <section className="section-container">
        <section className="section header">
          <h3> ร้านค้า <CardDetail> {totoalShops} รายการ</CardDetail> </h3>
          <InputSearchShop initialComponent={initialComponent} shopPromotion={props.shopPromotion} keyword={keyword} setKeyword={setKeyword} />
        </section>
      </section>

      <InfiniteScroll
        dataLength={shopList.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4></h4>}
      >
        <ListShop shopList={shopList} onClick={handleSetShopParam} showPlayList={showPlayList} />
      </InfiniteScroll>

    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const useSelectShop = (props) => {
  const dispatch = useDispatch();

  const [shopList, setShopList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  // const [keyword, setKeyword] = useState('');
  const limitPerPage = 10; //load more 10
  const {keyword,setKeyword} = props

  useEffect(() => {
    initialComponent();
    return () => {
      unmountComponent();
    }
  }, []);

  const unmountComponent = () => {
    setShopList([]);
    dispatch({ type: SET_SHOPS, payload: { shops: [] } });
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shops', value: [] } });
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopsSearch', value: [] } });
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: 1 } });
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'limit', value: limitPerPage } });
  }

  const shops = useSelector((state) => state.shop.shops);
  const totoalShops = useSelector((state) => state.shop.total);
  const shopsSearch = useSelector((state) => state.shop.shopsSearch);


  useEffect(() => {
    if (!!shops) {
      if (!isSearch) {
        if (shops && shops.length > 0) {
          setShopList([...shopList, ...shops]);
        }
      } else {
        if (shops) {
          setShopList([...shops]);
          setIsSearch(false);
        }
      }

      if (shops && shops.length > 0) {
        const shopAmout = shops.length + (page * limitPerPage);
        const isFull = shopAmout === totoalShops;
        setHasMore(!isFull);
      }
    }

  }, [shops]);

  useEffect(() => {
    if (shopsSearch) {
      setIsSearch(true);
      dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shops', value: shopsSearch.shop } });
      dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'total', value: shopsSearch.count } });
    }
  }, [shopsSearch])

  const initialComponent = () => {
    setShopList([]);
    getShops(1);
  }

  const fetchMoreData = () => {
    getShops();
  }

  const getShops = (_page) => {
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: _page ? _page : page } });
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'limit', value: limitPerPage } });
    if (props.shopPromotion) {
      if (keyword.trim().length > 0) {
        // dispatch({ type: GET_SHOPS_PROMOTION_SEARCH, payload: { kw: keyword, page: _page ? _page : page, limit: limitPerPage } })
        dispatch({ type: GET_SHOPS_PROMOTION_SEARCH, payload: { kw: keyword } })
      } else {
        dispatch({ type: GET_SHOPS_PROMOTION, payload: { page: _page ? _page : page, limit: limitPerPage } })
      }
    } else {
      dispatch({ type: GET_SHOPS });
    }
    setPage((page + 1));
  }

  const handleSetShopParam = (shop) => {
    dispatch({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopSelected', value: shop } });
  };


  return {
    shopList,
    hasMore,
    totoalShops,
    fetchMoreData,
    initialComponent,
    handleSetShopParam,
    page,
    limitPerPage,
    setKeyword
  };
};

const useInputSearchShop = (props) => {
  const { initialComponent,keyword,setKeyword } = props;
  const dispatch = useDispatch();
  const [statusSearch, setStatusSearch] = useState(false);
  // const [page, setPage] = useState(1)
  const limitPerPage = 20
  const page = 1

  const handleSearchKeyPress = event => {
    if (event.key == "Enter")
      handleSearchShop();
  }

  const handleClearSearch = () => {
    setKeyword("");
    setStatusSearch(false)
    initialComponent();
  }

  const handleSearchShop = () => {
    if (keyword.trim().length == 0) {
      setStatusSearch(false);
      if (props.shopPromotion) {
        dispatch({ type: GET_SHOPS_PROMOTION_SEARCH, payload: { kw: keyword, page, limit: limitPerPage } })
      } else {
        dispatch({ type: GET_SHOPS_SEARCH, payload: { keyword, page, limit: limitPerPage } })
      }
    } else {
      setStatusSearch(true);

      if (props.shopPromotion) {
        dispatch({ type: GET_SHOPS_PROMOTION_SEARCH, payload: { kw: keyword, page, limit: limitPerPage } })
      } else {
        dispatch({ type: GET_SHOPS_SEARCH, payload: { keyword, page, limit: limitPerPage } })
      }
    }
  };

  return {
    handleSearchKeyPress,
    handleSearchShop,
    handleClearSearch,
    statusSearch,
  }
};


export default SelectShop;
