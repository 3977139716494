import React from "react";
import "./index.scss";
import { ReactComponent as IconShop } from "images/icons/Shop.svg";
import { ReactComponent as IconBag } from "images/icons/Bag.svg";
import classNames from "classnames";

const CouponTag = (props) => {
  const { isAllShop = false } = props;

  return (
    <div className={classNames("wrap-coupon-tag", isAllShop && "isAllShop")}>
      <div className="cover-icon-coupon-tag">
        {isAllShop ? <IconShop /> : <IconBag />}
      </div>

      <span className={classNames("text-tag-coupon", isAllShop && "isAllShop")}>
        {isAllShop ? "ลดทั้งร้านค้า" : "ลดบางรายการ"}
      </span>
    </div>
  );
};

export default CouponTag;
