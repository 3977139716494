import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SpreadSheet from 'components/SpreadSheet';
import SelectInput from 'components/SelectInput';
import fileProvider from 'provider/fileProvider';
import { useDispatch } from 'react-redux';
import { OPEN_MODAL_ALERT } from 'redux/actions/modalAlert';
import { OPEN_MODAL_CONFIRM, dispatchModalConfirm } from 'redux/actions/modalConfirm';



const ModalPreviewMassUpload = (props) => {
    const dispatch = useDispatch()
    const { data, toggle, shopList = [], fileXlsx = null, shopId, setShopId, setStatusUploaded,
        statusUploaded, clearFileExcel, getListDownload, setListDownload, errorUpload, setPage } = props
    const [shopIndex, setShopIndex] = useState(null)
    useEffect(() => {
        const findIndexShop = shopList.findIndex(shop => shop.value.id === shopId)
        if (findIndexShop <= -1) {
            setShopIndex(0)
            setShopId(shopList && shopList[0]?.value?.id)
        } else {
            setShopIndex(findIndexShop)
        }

        return () => {
        }
    }, [shopId, shopList, props.isOpen])


    // const progess = () => setProgressing(prevProgress => (prevProgress || 1) + 1)

    // useEffect(() => {

    //   return () => {
    //     setProgressing(null)
    //   }
    // }, [])

    // useEffect(() => {
    //     // setProgressing(null)
    //     clearInterval(progess)

    //   return () => {
    //   }
    // }, [progressing === 100])
    // let isFirstTimeUpload = true
    // console.log('isFirstTimeUpload', isFirstTimeUpload)
    // useEffect(() => {
    //     console.log('isFirstTimeUpload', isFirstTimeUpload)
    //     if (!isFirstTimeUpload) {
    //         isFirstTimeUpload = false
    //         console.log('ss')
    //         handleUploadExcel()
    //     }
    //     return () => {
    //     }
    // }, [statusUploaded === 'uploading'])
    const handleUploadExcel = async () => {

        

        // if (!fileXlsx || !shopId || statusUploaded === 'uploading') return
        if (props.feature === 'tracking') {
            const element = document.getElementById("myBar");
            let width = 0;
            const getStatusUpload = setInterval(uploadExcel, 1500)
            try {
                const resPercent = await new Promise(async (resolve, reject) => {
                    try {
                        let response = "";
                        if(props?.mode === "create") {
                            response = await fileProvider.uploadExcelTracking({ file: fileXlsx, shopId: shopId, })
                        }
                        if(props?.mode === "update") {
                            response = await fileProvider.uploadExcelEditProduct({ file: fileXlsx, shopId: shopId, })
                        }
                        resolve(response)
                    } catch (error) {
                        console.log(error)
                        let errorMessage = error?.response?.data?.message;
                        if(errorMessage === "This shop is uploading files. Can not upload") errorMessage = 'DOWNLOAD_IS_IN_PROGRESS';
                        clearInterval(getStatusUpload)
                        width = 0;
                        element.style.width = 0 + '%';
                        setStatusUploaded('uploaded')
                        dispatch({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: errorUpload[error?.response?.data?.message] || error?.response?.data?.message, type: 'alert' } })
                    }
                })
                if (resPercent) {
                    width = 100;
                    element.style.width = width + '%';
                    clearInterval(getStatusUpload)
                    setStatusUploaded('uploaded')
                    closeModal('uploaded')
                }

            } catch (error) {
                console.log(error)
                clearInterval(getStatusUpload)
                width = 0;
                element.style.width = 0 + '%';
                setStatusUploaded('uploaded')
                dispatch({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: errorUpload[error?.response?.data?.message] || error?.response?.data?.message, type: 'alert' } })
            }
            async function uploadExcel() {
                if (width === 70) {
                    clearInterval(getStatusUpload)
                }
                if (width <= 70) {
                    width++;
                    element.style.width = width + '%';
                }
            }

        } else {
            try {
                setStatusUploaded('uploading')
                let excelId
                const element = document.getElementById("myBar");
                let width = 0;
                const getUploadRes = setInterval(uploadExcel, 1500)

                async function uploadExcel() {
                    if (width === 70) {
                        clearInterval(getUploadRes)
                    } else {
                        width++;
                        element.style.width = width + '%';
                    }
                    try {
                        if (width === 1) {
                            let res = "";
                            if(props?.mode === "update") {
                                res = await fileProvider.uploadExcelEditProduct({ file: fileXlsx, shopId: shopId, });
                                width = 100;
                                element.style.width = width + '%';
                                setStatusUploaded('uploaded');
                                closeModal('uploaded');
                            }
                            if(props?.mode === "create") {
                                res = await fileProvider.uploadExcel({ file: fileXlsx, shopId: shopId })
                            
                                if (res) {
                                    excelId = res.id
                                    width = 70;
                                    element.style.width = 70 + '%';
                                    const getStatusUpload = setInterval(getResUpload, 1000)

                                    async function getResUpload() {
                                        if (width === 100) {
                                            clearInterval(getStatusUpload)
                                            setStatusUploaded('uploaded')
                                            closeModal('uploaded')
                                        } else {
                                            if (width >= 70) {
                                                const resPercent = await fileProvider.getStatusProductUploading(excelId)
                                                if (resPercent.totalItem === 0) {
                                                    width = 100
                                                    element.style.width = 100 + '%';
                                                    clearInterval(getStatusUpload)
                                                    setStatusUploaded('uploaded')
                                                    closeModal('uploaded')
                                                }
                                                if (((resPercent.successItem * 100) / resPercent.totalItem) >= 70) {
                                                    width = ((resPercent.successItem * 100) / resPercent.totalItem)
                                                    element.style.width = ((resPercent.successItem * 100) / resPercent.totalItem) + '%';
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } catch (error) {
                        console.log(error)
                        clearInterval(getUploadRes)
                        width = 0;
                        element.style.width = 0 + '%';
                        setStatusUploaded('uploaded')
                        dispatch({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: errorUpload[error?.response?.data?.message] || error?.response?.data?.message, type: 'alert' } })
                    }
                }
            } catch (error) {
                console.log(error)
                setStatusUploaded('uploaded')
                dispatch({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: errorUpload[error?.response?.data?.message] || error?.response?.data?.message, type: 'alert' } })
            }
        }
    }

    const handleConfirmEdit = async () => {
        dispatch(
            dispatchModalConfirm({
              type: OPEN_MODAL_CONFIRM,
              payload: {
                isOpen: true,
                message: `หากมีการแก้ไขข้อมูลในช่องที่ไม่อนุญาตให้แก้ไข 
                หรือกรอกข้อมูลไม่ตรงกับข้อมูลในระบบ (เช่น หมวดหมู่ และ แบรนด์)
                ระบบจะไม่อัปเดทสินค้านั้นให้`,
                btnConfirm: 'ยืนยันอัปโหลด',
                btnCancel: 'ยกเลิก',
                status: 'fail',
                confirm: () => {handleUploadExcel()},
                isClose: () => dispatch(dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: false, }),),
              },
            }),
        )
    }



    const closeModal = (status) => {
        toggle();
        setStatusUploaded(status);
        clearFileExcel()
        setShopId('');
        props.onClose();
    }

    return (
        <div>
            {/* <Button color="danger" onClick={() => {}}>{this.props.buttonLabel}</Button> */}
            <Modal isOpen={props.isOpen} toggle={() => { }} className={''} size='xl' style={{ maxWidth: '95%', width: '100%' }}>
                <ModalHeader toggle={() => { }}>ตรวจสอบไฟล์ (รองรับ 100 รายการต่อไฟล์ excel)</ModalHeader>
                <ModalBody>
                    <div className="product-table">
                        <SpreadSheet data={data} />
                    </div>

                    <div className="wrap-progress">
                        <div className="progress">
                            <div className="progress-bar" id="myBar"></div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex align-items-center" style={{ width: '50%' }}>
                            <label htmlFor="selectShop">เลือกร้านค้าที่ต้องการอัปโหลดสินค้า : </label>
                            <div style={{ width: '48%', marginLeft: '5px' }}>
                                <SelectInput
                                    placeholder={'เลือกร้านค้า'}
                                    iconClearSearch={false}
                                    options={shopList}
                                    onSelect={(shop) => { setShopId(shopList[shop]?.value?.id); setShopIndex(shop); setPage(1) }}
                                    value={shopIndex}
                                    disabled={props?.mode === "update"}
                                />
                            </div>
                        </div>
                        <div>
                            <Button color="btn btn-cancel" onClick={() => { closeModal('cancel') }} disabled={statusUploaded === 'uploading'}>ยกเลิก</Button>
                            <Button color="btn btn-confirm" onClick={() => handleConfirmEdit()} disabled={statusUploaded === 'uploading' || statusUploaded === 'validateFail'}>อัปโหลด</Button>

                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ModalPreviewMassUpload
