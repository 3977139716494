import React, { useEffect, useState } from "react";
import "./style.scss";
import { Tooltip } from "antd";
import { getTopViewColor } from "../../helper/utils";

const ProductItemTopViewer = ({ index, name, imagePath }) => {
  const dotStyle = {
    backgroundColor: getTopViewColor(index) || "#ff0000",
  };

  return (
    <div className="topview-group-container">
      <div className="topview-dots" style={dotStyle}></div>
        {imagePath && 
        <img
        src={imagePath[0]?.host + imagePath[0]?.path}
        alt={`ImageProduct`}
        className="topview-group-image"
      />
        }
      <span className="topview-group-text">{name?.length >= 13 ? name.slice(0, 13) + '...' : name}</span>
    </div>
  );
};

export default ProductItemTopViewer;
