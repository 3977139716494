export const GET_LIST_PROMOCODE = 'GET_LIST_PROMOCODE'
export const OPEN_MODAL_ADD_PROMOCODE = 'OPEN_MODAL_ADD_PROMOCODE'
export const OPEN_MODAL_LIST_SKU = 'OPEN_MODAL_LIST_SKU'
export const OPEN_MODAL_SELECTED_SHOP = 'OPEN_MODAL_SELECTED_SHOP'
export const CLOSE_MODAL_ADD_PROMOCODE = 'CLOSE_MODAL_ADD_PROMOCODE'
export const SET_TAB_PROMOCODE = 'SET_TAB_PROMOCODE'
export const SET_KEY_VALUE_PROMOCODE = 'SET_KEY_VALUE_PROMOCODE'
export const OPEN_MODAL_CONFIRM_PROMOCODE = "OPEN_MODAL_CONFIRM_PROMOCODE"
export const VERIFY_REF_CODE = "VERIFY_REF_CODE"
export const CREATE_PROMOCODE = "CREATE_PROMOCODE"
export const OPEN_MODAL_MESSAGE_PROMOCODE = "OPEN_MODAL_MESSAGE_PROMOCODE"
export const HANDLE_UPDATE_PROMOCODE = "HANDLE_UPDATE_PROMOCODE"
export const HANDLE_DELETE_PROMOCODE = "HANDLE_DELETE_PROMOCODE"
export const GET_DETAIL_UPDATE_PROMOCODE = "GET_DETAIL_UPDATE_PROMOCODE"
export const SET_DEFAULT_DATA_PROMOCODE = "SET_DEFAULT_DATA_PROMOCODE"
export const CREATE_COUPON = "CREATE_COUPON"
export const UPDATE_COUPON = "UPDATE_COUPON"
export const GET_COUPON_DETAIL = "GET_COUPON_DETAIL"
export const GET_COUPON_ALL_DETAIL = "GET_COUPON_ALL_DETAIL"
export const GET_CLIENT_LEVEL = "GET_CLIENT_LEVEL"
export const SET_SHOP_METAS = "SET_SHOP_METAS"
export const ADD_PRODUCT_PROMOCODE = "ADD_PRODUCT_PROMOCODE"
export const ADD_CHECKED_PRODUCT_PROMOCODE = "ADD_CHECKED_PRODUCT_PROMOCODE"
export const REMOVE_PRODUCT_PROMOCODE = "REMOVE_PRODUCT_PROMOCODE"
export const REMOVE_CHECKED_PRODUCT_PROMOCODE = "REMOVE_CHECKED_PRODUCT_PROMOCODE"
export const GET_PRODUCT_PROMOCODE = "GET_PRODUCT_PROMOCODE"
export const ADD_ITEMJOIN_PROMOCODE = "ADD_ITEMJOIN_PROMOCODE"
export const SELECTED_ITEMJOIN_PROMOCODECODE = "SELECTED_ITEMJOIN_PROMOCODECODE"
export const SELECTED_PRODUCT_PROMOCODE = "SELECTED_PRODUCT_PROMOCODE"
export const OPEN_MODAL_SELECTED_PRODUCT = "OPEN_MODAL_SELECTED_PRODUCT"
export const UPDATE_COUNT_CHECKED_ALL_SHOP = "UPDATE_COUNT_CHECKED_ALL_SHOP"
export const SELECTED_PRODUCT_IN_SHOP = "SELECTED_PRODUCT_IN_SHOP"

export const SELECT_SHOP_PRODUCT = "SELECT_SHOP_PRODUCT"
export const SUBMIT_PRODUCT_PROMOCODE = "SUBMIT_PRODUCT_PROMOCODE"

export const SELECTED_SHOPJOIN_PRODUCT = "SELECTED_SHOPJOIN_PRODUCT"
export const UPDATE_TYPE_PRODUCT_SELECTION_PROMOCODE = "UPDATE_TYPE_PRODUCT_SELECTION_PROMOCODE"



export function dispatchPromocode(param) {
  return {
    type: param.type,
    payload: param.payload,
  }
}
