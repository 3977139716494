/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import playlistsProvider from '../../provider/playlistsProvider'
import { takeLatest, call, put, select, 
  // take, 
  delay } from 'redux-saga/effects'

import {
  GET_PLAYLISTS,
  SET_PLAYLISTS,
  CREATE_PLAYLISTS,
  UPDATE_PLAYLISTS,
  DELETE_PLAYLISTS,
  GET_PLAYLISTS_ITEMS,
  SET_PLAYLISTS_ITEMS,
  DELETE_PLAYLISTS_ITEM,
  SHUFFLE_PLAYLISTS_ITEMS,
  CREATE_PLAYLISTS_ITEM,
  PIN_PLAYLISTS_ITEMS,
  UPDATE_ORDER_PLAYLISTS,
  UPDATE_ORDER_PLAYLISTS_ITEMS,
  ADD_SHOP_TO_PLAYLIST,
  UPDATE_PLAYLIST_SHOP_ORDER,
  GET_PLAYLIST_SHOP,
  ADD_KEY_VALUE_PLAYLISTS,
  DELETE_SHOP_FROM_PLAYLIST,
  SET_MODAL_ADD_PLAYLIST_MALL,
  GET_LISTKEY,
  GET_PLAYLIST_SHOP_COUPON,
  GET_PLAYLIST_CATEGORY,
  UPDATE_PLAYLIST_CATEGORY,
  ADD_PLAYLIST_CATEGORIES,
  DELETE_PLAYLIST_CATEGORY,
  GET_PLAYLIST_CATEGORY_MALL,
  DELETE_PLAYLIST_PLAYLIST_CATEGORY,
  GET_PLAYLIST_COUPON,
  CREATE_PLAYLIST_COUPON_ITEM,
  DELETE_COUPON_ITEM,
  UPDATE_PLAYLIST_COUPON
} from '../actions/playlists'
import { SET_LOADING } from '../actions/app'

import {
  OPEN_MODAL_ALERT,
} from 'redux/actions/modalAlert';
import { OPEN_TOAST } from 'redux/actions/notification'
import { getShop } from './selectors';
// import { ClosedCaptionButton } from 'video-react';


export function* onFetchPlaylists(action) {
  // const { payload } = action;
  const getShopState = yield select(getShop);
  const { shopSelected } = getShopState;
  try {
    let playlists = '';
    if (shopSelected && shopSelected.id)
      playlists = yield call(playlistsProvider.getPlaylists, { shopId: shopSelected.id })
    else
      playlists = yield call(playlistsProvider.getPlaylists, {})

    yield put({ type: SET_PLAYLISTS, payload: playlists })
  } catch (err) {
    throw err
  }
}

export function* onCreatePlaylists(action) {
  const { payload } = action
  const getShopState = yield select(getShop);
  const { shopSelected } = getShopState;

  try {
    yield call(playlistsProvider.createPlaylists, payload)
    const param = shopSelected ? { shopId: shopSelected.id } : {}
    yield put({ type: GET_PLAYLISTS, payload: param })
  } catch (err) {
    throw err
  }
}

export function* onUpdatePlaylists(action) {
  const { payload } = action
  const getShopState = yield select(getShop);
  const { shopSelected } = getShopState;

  try {
    const response = yield call(playlistsProvider.updatePlaylists, payload)
    // TODO GET_PLAYLISTS
    const param = shopSelected ? { shopId: shopSelected.id } : {}
    yield put({ type: GET_PLAYLISTS, payload: param })
  } catch (err) {
    throw err
  }
}

export function* onDeletePlaylists(action) {
  const { payload: playlistsId } = action
  const getShopState = yield select(getShop);
  const { shopSelected } = getShopState;

  try {
    yield put({type: SET_LOADING,payload:{loading: true}})
    yield call(playlistsProvider.deletePlaylists, playlistsId)

    // TODO GET_PLAYLISTS
    const param = shopSelected ? { shopId: shopSelected.id } : {}
    yield put({ type: GET_PLAYLISTS, payload: param })
    yield put({type: SET_LOADING,payload:{loading: false}})
  } catch (err) {
    yield put({type: SET_LOADING,payload:{loading: false}})
    throw err
  }
}

export function* onFetchPlaylistsItems(action) {
  const {
    payload: { playlistsId, limit, page },
  } = action
  try {
    const playlistItems = yield call(
      playlistsProvider.getPlaylistItemsByPlaylistId,
      { playlistsId, limit, page },
    )
    yield put({ type: SET_PLAYLISTS_ITEMS, payload: playlistItems })
  } catch (err) {
    throw err
  }
}

export function* onDeletePlaylistsItem(action) {
  const {
    payload: { playlistsId, playlistsItemId },
  } = action
  try {
    yield put({type: SET_LOADING,payload:{loading: true}})
    yield call(playlistsProvider.deletePlaylistsItem, playlistsItemId)
    yield put({
      type: GET_PLAYLISTS_ITEMS,
      payload: { playlistsId: playlistsId, limit: 1000, page: 1 },
    })
    yield put({type: SET_LOADING,payload:{loading: false}})
  } catch (err) {
    yield put({type: SET_LOADING,payload:{loading: false}})
    throw err
  }
}

export function* onCreatePlaylistItem(action) {
  const { payload: { productId, playlistId } } = action
  try {
    const data = yield call(playlistsProvider.createPlaylistItem, { productId, playlistId })
    yield put({ type: GET_PLAYLISTS_ITEMS, payload: { playlistsId: playlistId, limit: 1000, page: 1 } })
  } catch (err) {

    throw err
  }
}

export function* onShufflePlaylistItem(action) {
  const { payload: { playlistsId } } = action
  try {
    yield call(playlistsProvider.shufflePlaylistsItem, playlistsId)
    yield put({ type: GET_PLAYLISTS_ITEMS, payload: { playlistsId: playlistsId, limit: 100000000, page: 1 } })
  } catch (err) {
    throw err
  }
}

export function* onClickPin(action) {
  const { payload: { playlistsItemId, pinDate, playlistsId } } = action
  try {
    yield call(playlistsProvider.pinPlaylistsItem, { playlistsItemId, pinDate: pinDate })
    yield put({ type: GET_PLAYLISTS_ITEMS, payload: { playlistsId: playlistsId, limit: 100000000, page: 1 } })
  } catch (err) {
    throw err
  }
}

export function* onChangePositionOrder(action) {
  try {
    const { payload } = action
    const getShopState = yield select(getShop);
    const { shopSelected } = getShopState;

    const response = yield call(playlistsProvider.updateOrderPlaylists, payload.playlistsId, { order: payload.order })
    // TODO GET_PLAYLISTS
    const param = shopSelected ? { shopId: shopSelected.id } : {}
    yield put({ type: GET_PLAYLISTS, payload: param })
  } catch (error) {
    throw error
  }
}

export function* onChangePositionOrderPlaylistItems(action) {
  try {
    const { payload: { playlistItemId, order, playlistsId } } = action
    const response = yield call(playlistsProvider.updateOrderPlaylistsItems, playlistItemId, { order: order })
    yield put({ type: GET_PLAYLISTS_ITEMS, payload: { playlistsId: playlistsId, limit: 100000000, page: 1 } })
  } catch (error) {
    throw error
  }
}

export function* onAddShopToPlaylist(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { shopIds, playlistId, playlistsType } = action.payload
    const response = yield call(playlistsProvider.addShopToPlayList, { shopIds, playlistId })

    if (response.code === 422) {
      yield put({
        type: OPEN_MODAL_ALERT,
        payload: {
          isOpen: true,
          message: 'ร้านค้าที่เลือกมีอยู่ในเพลลิสต์แล้ว',
          type: 'alert'
        }
      })
    } else {

      yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ!`, type: 'success' } });
      yield delay(1000);
      yield put({ type: SET_MODAL_ADD_PLAYLIST_MALL, payload: { isOpen: false } })

      switch (playlistsType) {
        case 'shop':
          yield put({ type: GET_PLAYLIST_SHOP, payload: { playlistId } });
          break;
        case 'shop_coupon':
          yield put({ type: GET_PLAYLIST_SHOP_COUPON, payload: { playlistId } });
          break;
      }
    }
    yield put({ type: SET_LOADING, payload: { loading: false } })

  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onDeleteShopFromPlaylist(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })

    const { playlistShopId, playlistId, playlistsType } = action.payload
    yield call(playlistsProvider.deleteShopFromPlayList, playlistShopId)

    switch (playlistsType) {
      case 'shop':
        yield put({ type: GET_PLAYLIST_SHOP, payload: { playlistId } });
        break;
      case 'shop_coupon':
        yield put({ type: GET_PLAYLIST_SHOP_COUPON, payload: { playlistId } });
        break;
    }

    yield put({ type: SET_LOADING, payload: { loading: false } })

  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onUpdatePlaylistShopOrder(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistShopId, orderNumber, playlistId, playlistsType } = action.payload

    yield call(playlistsProvider.updatePlaylistShopOrder, playlistShopId, { order: orderNumber })

    switch (playlistsType) {
      case 'shop':
        yield put({ type: GET_PLAYLIST_SHOP, payload: { playlistId } });
        break;
      case 'shop_coupon':
        yield put({ type: GET_PLAYLIST_SHOP_COUPON, payload: { playlistId } });
        break;
    }

    yield put({ type: GET_PLAYLIST_SHOP, payload: { playlistId } })
    yield put({ type: SET_LOADING, payload: { loading: false } })

  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onGetPlaylistShop(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistId } = action.payload
    const playlistShops = yield call(playlistsProvider.getPlaylistShop, playlistId)
    yield put({ type: ADD_KEY_VALUE_PLAYLISTS, payload: { key: 'playlistShops', value: playlistShops } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onGetPlaylistShopCoupon(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistId } = action.payload
    const playlistShopCoupons = yield call(playlistsProvider.getPlaylistShopCoupon, playlistId)
    yield put({ type: ADD_KEY_VALUE_PLAYLISTS, payload: { key: 'playlistShopCoupons', value: playlistShopCoupons } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onGetPlaylistKey(action) {
  try {
    const { payload } = action
    const playlistType = yield call(playlistsProvider.getListKey,payload)
    yield put({ type: ADD_KEY_VALUE_PLAYLISTS, payload: { key: 'playlistType', value: playlistType } })
  } catch (error) {
    throw error
  }
}

export function* onGetPlaylistCategory(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistId } = action.payload
    const playlistCategory = yield call(playlistsProvider.getPlaylistCategories, playlistId)
    yield put({ type: ADD_KEY_VALUE_PLAYLISTS, payload: { key: 'playlistCategories', value: playlistCategory } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onUpdateCategoryPlaylist(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistId, orderNumber, playlistCategoriesId } = action.payload
    yield call(playlistsProvider.updateCategoryPlaylist, playlistCategoriesId, { order: orderNumber })
    yield put({ type: GET_PLAYLIST_CATEGORY_MALL, payload: { playlistId } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onAddPlaylistCategories(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistId, body } = action.payload
    yield call(playlistsProvider.addCategoriesToPlaylist, body)
    yield put({ type: GET_PLAYLIST_CATEGORY, payload: { playlistId } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onDeletePlaylistCategory(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistId, playlistCategoriesId } = action.payload
    yield call(playlistsProvider.deleteShopFromPlayList, playlistCategoriesId)
    yield put({ type: GET_PLAYLIST_CATEGORY, payload: { playlistId } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onDeletePlaylistOnPlaylistCategory(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistId, categories } = action.payload.category
    yield call(playlistsProvider.deleteCategoriesPlaylist, playlistId, { categoryIds: [categories?.id.toString()] })
    yield put({ type: GET_PLAYLIST_CATEGORY_MALL, payload: { playlistId } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onGetPlaylistCategoryMall(action) {
  try {
    const { playlistId } = action.payload
    const playlistCategory = yield call(playlistsProvider.getPlaylistCategoriesByPlaylistId, playlistId)
    const playlists = []
    for (let index = 0; index < playlistCategory.length; index++) {
      let data = {}
      const element = playlistCategory[index];
      data = { ...element }
      data.categories = { ...data, ...element.categories, imagePath: element.categories.imagePath?.path }
      playlists.push(data)
    }
    yield put({ type: ADD_KEY_VALUE_PLAYLISTS, payload: { key: 'playlistCategories', value: playlists } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onGetPlaylistCoupon(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistId } = action.payload
    const playlistCoupon = yield call(playlistsProvider.getListCouponPlaylist, playlistId)
    yield put({ type: ADD_KEY_VALUE_PLAYLISTS, payload: { key: 'playlistCoupon', value: playlistCoupon } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onCreatePlaylistCoupon(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { data } = action.payload
    const response = yield call(playlistsProvider.createCouponPlaylistItem, {data})

    if (response.code === 422) {
      yield put({
        type: OPEN_MODAL_ALERT,
        payload: {
          isOpen: true,
          message: 'คูปองที่เลือกมีอยู่ในเพลลิสต์แล้ว',
          type: 'alert'
        }
      })
    } else {

      yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ!`, type: 'success' } });
      yield delay(1000);
      yield put({ type: SET_MODAL_ADD_PLAYLIST_MALL, payload: { isOpen: false } })
  
      yield put({ type: GET_PLAYLIST_COUPON, payload: { playlistId: data.playlistId } })

    }

    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onDeleteCouponItem(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistId, playlistCouponId } = action.payload
    yield call(playlistsProvider.deleteCouponFromPlaylist, playlistCouponId)
    yield put({ type: GET_PLAYLIST_COUPON, payload: { playlistId } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export function* onUpdatePlaylistCoupon(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { playlistId, orderNumber, playlistCouponId } = action.payload
    yield call(playlistsProvider.updateOrderCouponPlaylist, playlistCouponId, { order: orderNumber })
    yield put({ type: GET_PLAYLIST_COUPON, payload: { playlistId } })
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw error
  }
}

export default function* useWatcher() {
  yield takeLatest(GET_PLAYLISTS, onFetchPlaylists)
  yield takeLatest(CREATE_PLAYLISTS, onCreatePlaylists)
  yield takeLatest(UPDATE_PLAYLISTS, onUpdatePlaylists)
  yield takeLatest(DELETE_PLAYLISTS, onDeletePlaylists)
  yield takeLatest(GET_PLAYLISTS_ITEMS, onFetchPlaylistsItems)
  yield takeLatest(CREATE_PLAYLISTS_ITEM, onCreatePlaylistItem)
  yield takeLatest(DELETE_PLAYLISTS_ITEM, onDeletePlaylistsItem)
  yield takeLatest(SHUFFLE_PLAYLISTS_ITEMS, onShufflePlaylistItem)
  yield takeLatest(PIN_PLAYLISTS_ITEMS, onClickPin)
  yield takeLatest(UPDATE_ORDER_PLAYLISTS, onChangePositionOrder)
  yield takeLatest(UPDATE_ORDER_PLAYLISTS_ITEMS, onChangePositionOrderPlaylistItems)
  yield takeLatest(GET_PLAYLIST_SHOP, onGetPlaylistShop)
  yield takeLatest(UPDATE_PLAYLIST_SHOP_ORDER, onUpdatePlaylistShopOrder)
  yield takeLatest(ADD_SHOP_TO_PLAYLIST, onAddShopToPlaylist)
  yield takeLatest(DELETE_SHOP_FROM_PLAYLIST, onDeleteShopFromPlaylist)
  yield takeLatest(GET_LISTKEY, onGetPlaylistKey)
  yield takeLatest(GET_PLAYLIST_SHOP_COUPON, onGetPlaylistShopCoupon)
  yield takeLatest(GET_PLAYLIST_CATEGORY, onGetPlaylistCategory)
  yield takeLatest(UPDATE_PLAYLIST_CATEGORY, onUpdateCategoryPlaylist)
  yield takeLatest(ADD_PLAYLIST_CATEGORIES, onAddPlaylistCategories)
  yield takeLatest(DELETE_PLAYLIST_CATEGORY, onDeletePlaylistCategory)
  yield takeLatest(GET_PLAYLIST_CATEGORY_MALL, onGetPlaylistCategoryMall)
  yield takeLatest(DELETE_PLAYLIST_PLAYLIST_CATEGORY, onDeletePlaylistOnPlaylistCategory)
  yield takeLatest(GET_PLAYLIST_COUPON, onGetPlaylistCoupon)
  yield takeLatest(CREATE_PLAYLIST_COUPON_ITEM, onCreatePlaylistCoupon)
  yield takeLatest(UPDATE_PLAYLIST_COUPON, onUpdatePlaylistCoupon)
  yield takeLatest(DELETE_COUPON_ITEM, onDeleteCouponItem)
}
