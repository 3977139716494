import React, { useState, useEffect } from 'react'
import { ReactComponent as IconSearch } from '../../../images/icons/search.svg'
import IconCloseCircle from 'images/icons/close-circle.svg'
import './styles.scss'

const InputSearch = (props) => {
  const { defaultValue, handleSearch, placeholder, onKeyPress, onChange, onClearSearch, statusSearch, values } = props
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (defaultValue) setValue(defaultValue)
  })

  useEffect(() => {
    setValue(values)
  }, [values])
  const [value, setValue] = useState(defaultValue)

  const onChangeSearch = (e) => {
    setValue(e.target.value)
    onChange(e)
  }

  const onSearch = (e) => {
    e.preventDefault()
    handleSearch(value, typeof value)

  }

  const clearSearch = () => {
    setValue("")
    onClearSearch()
  }

  return (
    <form className='search-box' onSubmit={onSearch}>
      <input
        type='text'
        className='input-search'
        placeholder={placeholder}
        onChange={onChangeSearch}
        value={value}
        onKeyPress={onKeyPress}
      />
      {statusSearch ? <img src={IconCloseCircle} alt="icon-close" onClick={clearSearch} className='icon-close' /> : null}
      <div className='icon-box' onClick={onSearch}>
        {/* <img src={IconSearch} alt='search' className='icon-search' /> */}
        <IconSearch className="icon-search" />
      </div>
    </form>
  )
}

InputSearch.defaultProps = {
  defaultValue: '',
  placeholder: 'ค้นหา',
  onKeyPress: () => { },
  onChange: () => { },
  onClearSearch: () => { },
  handleSearch: () => { },
  statusSearch: false
}

export default InputSearch
