import React, { useEffect } from "react";
import { useSelector, 
  // useDispatch 
} from "react-redux";

import { SelectShop } from "components/PlaylistItems/";
import PlaylistFrontPage from "../components/PlaylistFrontPage/PlaylistFrontPage";

// import { dispatchShop, ADD_KEY_VALUE_SHOP } from "redux/actions/shop";

const PlaylistFrontPagePlatform = (props) => {
  const { dispatch, history } = props;
  // const Dispatch = useDispatch();
  // const userInfo = useSelector(state => state.user.userInfo)
  const shopSelected = useSelector(state => state.shop.shopSelected)

  useEffect(() => {
    // return () => unmountComponent();
  }, []);

  // const unmountComponent = () => {
  //   Dispatch(
  //     dispatchShop({
  //       type: ADD_KEY_VALUE_SHOP,
  //       payload: { key: 'shopSelected', value: null }
  //     }));
  // }

  return (
    <>
      {/* {userInfo.shopId ? (
        <PlaylistFrontPage dispatch={dispatch} history={history} />
      ) : 
      (
      <>
        {shopSelected ? (
          <PlaylistFrontPage dispatch={dispatch} history={history} />
        ) : (
          <SelectShop showPlayList={false} />
        )}
      </>
      )} */}
      {/* {shopSelected ? (
        <PlaylistFrontPage dispatch={dispatch} history={history} />
      ) : (
        <>
          {shopSelected && shopSelected.id ? (
            <PlaylistFrontPage dispatch={dispatch} history={history} />
          ) : (
            <SelectShop showPlayList={false} />
          )}
        </>
      )} */}
      {shopSelected && shopSelected.id ? (
        <PlaylistFrontPage dispatch={dispatch} history={history} />
      ) : (
        <SelectShop showPlayList={false} />
      )}
    </>
  );
};

export default PlaylistFrontPagePlatform;

