import React from "react";
import DropDownPlaylist from 'components/DropdownPlaylist'
import "./index.scss";

/* -------------------------------------------------------------------------- */
/*                            Component: OrderIndex                           */
/* -------------------------------------------------------------------------- */
/*
<OrderIndex 
    options= {[{value: 1, name: 1},{value: 2, name: 2},
              {value: 3, name: 3},{value: 4, name: 4}]}
    indexSelected={3}
/>
*/
/* -------------------------------------------------------------------------- */

export default function OrderIndex(props) {
  
  const { indexSelected, options, handleSelected } = props;
  return (
    <div className="divContainer">
      <div className="item">
        <div className="itemHeader">ลำดับ</div>
        <div className="itemBody">
          {
            options && options.length > 0 &&
              <DropDownPlaylist
                options={options}
                defaultValue={indexSelected || ""}
                handleSelected={handleSelected}
              />
          }
        </div>
      </div>
    </div>
  );
}
