import { Body1, Body2, Tooltip } from 'components/FontStyle'
import ModalAlert from 'components/modalAlert/ModalAlert'
import MultipleSelect from 'components/MultipleSelectComponent'
import shopProvider from 'provider/shopProvider'
import userProvider from 'provider/userProvider'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { dispatchApp, SET_LOADING } from 'redux/actions/app'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert'
import { ReactComponent as IconEye } from 'images/icons/eye.svg'
import { ReactComponent as IconEyeOpen } from 'images/icons/eye-already.svg'
import 'styles/_changePassword.scss'

const CreateUserStaff = (props) => {
    const api_shop = new shopProvider()
    const [option, setOption] = useState([])
    const [valueShop, setValueShop] = useState([])
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [shopSelected, setShopSelected] = useState([])
    const [showPassword, setShowPassword] = useState('password')
    const [error, setError] = useState({})
    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const getShop = await api_shop.getAllListShops()
            const optionShop = []
            getShop.map(async (shop) => {
                await optionShop.push({ key: shop.name, value: shop })
            })
            setOption(optionShop)
            setIsLoading(false)
        })()
        return () => {

        }
    }, [])

    useEffect(() => {
        dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: isLoading } }))
        return () => {
        }
    }, [isLoading])



    const handleValueOption = (value) => {
        setShopSelected(value)
        const arrShopId = []
        value.map((shop) => {
            const findId = option.find((shopDefault) => shopDefault.value.name === shop)
            const { id } = findId.value
            arrShopId.push(id)
        })
        setValueShop(arrShopId)
    }

    const cleanup = () => {
        setEmail('')
        setPassword('')
        setValueShop('')
        setIsConfirm(false)
        setIsLoading(false)
        setShopSelected([])
    }

    const validatePassword = () => {

        const regPass = /^[^ก-ฮ]{6,16}$/


        let error = {} 


        if (!email) {
            error = ({ ...error, email: 'กรุณากรอกอีเมล' })
        } else {
            // delete error
        }
        if (!password) {
            error = ({ ...error, password: 'กรุณากรอกรหัสผ่าน' })
        } else {
            if (!regPass.test(password)) {
                // setError({...error,password:'กรุณากรอกตัวอักษรภาษาอังกฤษ A-Z,a-z,0-9 ความยาว 6-16 ตัวอักษร'})
                error = ({ ...error, password: 'กรุณากรอกตัวอักษรภาษาอังกฤษ A-Z,a-z,0-9 ความยาว 6-16 ตัวอักษร' })
            }
        }
        if (!shopSelected.length) {
            error = ({ ...error, shopSelected: 'กรุณาเลือกร้านค้า' })
        }


        setError(error)
        if (Object.keys(error).length) return false
        return true
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            //isConfirm
            if (isConfirm) return
            //validate input
            if (!(validatePassword())) return
            setIsConfirm(true)
            const params = {
                email: email?.toString().trim().toLowerCase(),
                password,
                shopId: valueShop
            }
            const res = await userProvider.createUserStaff(params)
            if (res.message === 'Successful!') {
                dispatch(dispatchModalAlert({
                    type: OPEN_MODAL_ALERT,
                    payload: {
                        isOpen: true, message: 'สร้างผู้ใช้สำเร็จ กรุณายืนยันอีเมล', type: 'success', confirm: () => {
                            dispatch(dispatchModalAlert({
                                type: OPEN_MODAL_ALERT,
                                payload: { isOpen: false, message: '', type: '', },
                            })); cleanup()
                        }
                    },
                }))
            }
            setIsConfirm(false)
        } catch (error) {
            const { data } = error

            switch (data?.message) {
                case "This email is already in use":
                    dispatch(dispatchModalAlert({
                        type: OPEN_MODAL_ALERT,
                        payload: {
                            isOpen: true,
                            message: "อีเมลนี้ถูกใช้งานแล้ว", type: 'alert', confirm: () => {
                                dispatch(dispatchModalAlert({
                                    type: OPEN_MODAL_ALERT,
                                    payload: { isOpen: false, message: '', type: '', },
                                }));
                            }
                        },
                    }))
                    break;
            
                default:
                    dispatch(dispatchModalAlert({
                        type: OPEN_MODAL_ALERT,
                        payload: {
                            isOpen: true, message: 'กรอกข้อมูลไม่ถูกต้อง กรุณาลองใหม่', type: 'alert', confirm: () => {
                                dispatch(dispatchModalAlert({
                                    type: OPEN_MODAL_ALERT,
                                    payload: { isOpen: false, message: '', type: '', },
                                }));
                            }
                        },
                    }))
                    break;
            }
            setIsConfirm(false)
        }
    }


    const onClickShowPassword = () => {
        var x = document.getElementById("myInput");
        if (x.type === "password") {
            x.type = "text";
            setShowPassword('text')
        } else {
            x.type = "password";
            setShowPassword('password')
        }
    }

    return (
        <form onSubmit={handleSubmit} className='d-flex justify-content-center'>
            <div className='d-flex flex-column justify-content-center align-items-center w-50'>
                <Body1 className='d-flex justify-content-center'>สร้างบัญชีผู้ใช้</Body1>
                <div className='d-flex justify-content-start flex-column w-100'>
                    <label className='mt-4'>
                        <Body2 className='require'>อีเมล</Body2>
                        <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} style={{backgroundColor: 'white'}}/>
                        <Tooltip className='text-danger'>{error?.email}</Tooltip>
                    </label>
                    {/* <label className='mt-4'>
                        <Body2>รหัสผ่าน</Body2>
                        <input type="password" className="form-control" id="myInput" autocomplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </label> */}
                    <div className='mt-4 position-relative'>
                        {showPassword === 'text' ?
                            <IconEyeOpen className='icon-eye-open' style={{ bottom: '-5%' }} onClick={() => onClickShowPassword()} /> :
                            <IconEye className='icon-eye' onClick={() => onClickShowPassword()} />}
                        <Body2 className='require'>รหัสผ่าน</Body2>
                        <input type="password" className="form-control"
                            placeholder='ตัวอักษรภาษาอังกฤษ A-Z,a-z,0-9 ความยาว 6-16 ตัวอักษร'
                            name="password" id="myInput" autocomplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} style={{backgroundColor: 'white'}}/>
                        {/* <InputText
                        label={<SubCaption>รหัสผ่าน</SubCaption>}
                        type={document.getElementById("myInput")}
                        name={'password'}
                        // isError={error}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder='ตัวอักษรภาษาอังกฤษ A-Z,a-z,0-9 ความยาว 6-16 ตัวอักษร'
                    /> */}
                    </div>
                    <Tooltip className='text-danger mt-2'>{error?.password}</Tooltip>



                    <label className='mt-4'>
                        <Body2 className='require'>ร้านค้า</Body2>
                        <MultipleSelect
                            option={option}
                            handleValueOption={handleValueOption}
                            value={shopSelected}
                            placeholder='กรุณาเลือกร้านค้า'
                        />
                        <Tooltip className='text-danger'>{error?.shopSelected}</Tooltip>
                    </label>
                </div>

                <button type="submit" className='btn btn-submit mt-4'><Body2>ยืนยันสร้างบัญชีผู้ใช้</Body2></button>
            </div>

            <ModalAlert />

        </form>
    )
}

export default CreateUserStaff