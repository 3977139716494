
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
// import IconSearch from '../images/icons/search.svg';
// import IconNoShop from '../images/icons/no-shop.svg';
// import IconLive from '../images/icons/live.svg';
import IconShopAvatar from '../images/icons/default-shop-logo.svg';
import { formatNumber, setLocalStorage } from '../helper/utils';
import host from '../config/host';
import noItem from '../images/icons/status-error.svg'


// import { SETTING_MENU_SIDEBAR, dispatchApp } from '../redux/actions/app';
import { GET_SHOPS, GET_SHOPS_SEARCH, SET_CURRENT_SHOP, dispatchShop, ADD_KEY_VALUE_SHOP, ON_SEARCH_SHOP, UPDATE_SHOP_DETAIL } from '../redux/actions/shop';

import '../styles/_mall.scss';
import PaginationComponent from '../components/pagination'
import { InputSearch } from 'components/output';
import ModalAddShop from 'components/ModalAddShop'
import ModalConfirmChildren from 'components/ModalConfirmChidrenHeader';
import CreateSEO from 'components/Form/CreateSEO';
import Loading from 'components/Loading';
import { Caption, H5 } from 'components/FontStyle';
// import {Pagination} from 'antd'


class Shop extends Component {

  constructor(props) {
    super(props)
    this.state = {
      shops: [],
      statusSearch: false,
      historySearch: '',
      search: "",
      modal: {
        isOpen: false,
        data: null
      },
      seo: null,
      errors: {}
    }
  }

  componentDidMount = () => {
    setLocalStorage({ key: 'shopDetailDefaultTab', value: { tab: 0 } })
    localStorage.setItem("createProductBy", JSON.stringify({ createAt: "product" }));
    this.getShopList();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.shops !== this.props.shops) {
      this.setState({ shops: nextProps.shops, modal: { isOpen: false, data: null } });
    }
    if (nextProps.gallery !== this.props.gallery) {
      // const { keyword, ...withoutKeyword } = this.state.seo
      this.setState({ seo: { ...this.state.seo, seoImageId: nextProps.gallery && nextProps.gallery[0]?.id, seoImage: nextProps.gallery && nextProps.gallery[0] } })
    }

    if ((this.props.galleryState?.albumDefault !== nextProps.galleryState?.albumDefault) && (nextProps.galleryState?.albumDefault === null)){
      this.setState({modal: {isOpen: false,data: null}})
    }

  }

  componentWillUnmount() {
    this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: 1 } }))
    this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'limit', value: 10 } }))
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.shopDetailError !== prevProps.shopDetailError) {
      switch (this.props.shopDetailError) {
        case 'THIS_PERMALINK_IS_EXIST.':
          this.setState({
            errors: {
              // ...this.state.errors,
              permalink: 'permalink นี้มีในระบบแล้ว'
            }
          })
          break;
        default:
          break;
      }

    }
    // console.log(`this.props`, this.props)
    // if (this.props.gallery !== ) {
    //   this.setState({ seo: { ...this.state.seo, seoImage: this.props.gallery[0], seoImageId: this.props.gallery && this.props.gallery[0]?.id } })
    // }
  }

  /* SECTION - Event */
  /* !SECTION */

  /* SECTION - Function */
  /* !SECTION */

  /* SECTION - Api */
  getShopList = () => {
    this.props.dispatch(dispatchShop({ type: GET_SHOPS,        payload: {
      limit: 100,
      page: 1,
      sortBy: 'name'
    } }))
  }
  setCurrentShop = (shop) => {
    localStorage.setItem("shop", JSON.stringify(shop));
    this.props.dispatch(dispatchShop({ type: SET_CURRENT_SHOP, payload: { shop } }));
  }
  searchShop = (keyword) => {
    this.props.dispatch(dispatchShop({ type: GET_SHOPS_SEARCH, payload: { keyword } }))
  }
  /* !SECTION */



  getBadeStatusClass = (type, data) => {
    const status = this.checkSEOLength(type, data?.length)
    switch (status) {
      case "good": return "badge badge-green";
      case "poor": return "badge badge-yellow";
      case "normal": return "badge badge-yellow";
      case "bad": return "badge badge-red";
      default: return null
    }
  }

  onChangePage = (page) => {
    if (this.state.statusSearch) {
      this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: page } }))
      this.props.dispatch(dispatchShop({ type: ON_SEARCH_SHOP, payload: { search: this.state.historySearch } }))
    } else {
      this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: page } }))
      this.props.dispatch(dispatchShop({ type: GET_SHOPS }))
    }

  }

  onShowSizeChange = (page, pageSize) => {
    this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: page } }))
    this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'limit', value: pageSize } }))
  }

  onChangeSearchShop = (e) => {
    this.setState({ search: e.target.value })
  }

  onKeyPressSearchShop = (e) => {
    if (this.state.search.trim().length === 0) {
      this.setState({ historySearch: '', statusSearch: false })
    }
    if (e.key === "Enter") {
      this.onClickSearchShop()
    }
  }

  onClickSearchShop = () => {
    if (this.state.search.trim().length === 0) {
      this.setState({ historySearch: '', statusSearch: false })
      this.props.dispatch(dispatchShop({ type: ON_SEARCH_SHOP, payload: { search: this.state.search } }))
    } else {
      this.setState({ historySearch: this.state.search, statusSearch: true })
      this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: 1 } }))
      this.props.dispatch(dispatchShop({ type: ON_SEARCH_SHOP, payload: { search: this.state.search } }))
    }
  }

  onClearSearch = () => {
    this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: 1 } }))
    this.setState({ search: '', statusSearch: false })
    this.getShopList()
  }

  handleChangeSEO = (text, key, type) => {
    const setVal = (key, value) => this.setState({ seo: { ...this.state.seo, [key]: value } })
    // if (type === 'permalink') {
    //   let permalink = text.target.value
    //   permalink.replace(/\s/g, '-')
    //   console.log(`permalink`, permalink)
    //   setVal(key, permalink)
    // }
    if (type === 'tag') {
      setVal(key, text)
    } else {
      const val = text.target.value
      setVal(key, val)
    }
  }

  setKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  submitModal = () => {
    const { modal, seo, statusSearch, historySearch } = this.state
    // const keyword = []
    // let errors = {}

    // if (!seo['title']) {
    //   errors.title = 'กรุณากรอก meta title'
    // } else {
    //   delete errors.title
    // }
    // if (!seo['description']) {
    //   errors.description = 'กรุณากรอก meta description'
    // } else {
    //   delete errors.description
    // }

    // if (!seo['permalink']) {
    //   errors.permalink = 'กรุณากรอก meta permalink '
    // } else {
    //   delete errors.permalink
    // }

    // if (Object.keys(errors).length > 0) {
    //   this.setState({ errors })
    //   return
    // }

    // if (seo.keyword !== undefined && seo.keyword?.length > 0) {
    //   for (let index = 0; index < seo.keyword.length; index++) {
    //     const element = seo.keyword[index];
    //     keyword.push(element.name)
    //   }
    // }
    let payload = {
      shopId: modal.data.id,
      body: {
        seo: {
          ...seo,
          // keyword: keyword
        }
      }
    }

    if(statusSearch){
      payload.search = historySearch
    }


    delete payload.body.seo.seoImage
    this.props.dispatch(dispatchShop({ type: UPDATE_SHOP_DETAIL, payload }))
  }

  checkSEOLength = (type, data = '') => {
    let level
    if (type === "title") {
      if (data <= 24) {
        level = 'bad'
      } else if (data <= 34) {
        level = 'poor'
      } else if (data <= 65) {
        level = 'good'
      } else if (data <= 75) {
        level = 'poor'
      } else if (data >= 76) {
        level = 'bad'
      }
    } else if (type === "description") {
      if (data <= 44) {
        level = 'bad'
      } else if (data <= 62) {
        level = 'poor'
      } else if (data <= 250) {
        level = 'good'
      } else if (data <= 320) {
        level = 'poor'
      } else if (data >= 321) {
        level = 'bad'
      }
    } else if (type === "keyword") {
      if (data === 0) {
        level = 'bad'
      } else {
        level = 'good'
      }
    }
    return level
  }



  render() {
    const { shops, modal, seo } = this.state;
    const { limit, page, total, pageSizeOptions, loading, 
      // gallery
     } = this.props

    return (
      <React.Fragment>
        <div className='shop'>
          <div className='shop-content'>
            <div className='search-shop'>
              <div className='d-flex justify-content-between mb-1'>
                <H5 style={{ fontWeight: 'normal' }}>
                  ร้านค้า{' '}
                  {/* <span className='total-shop'> {total} รายการ</span> */}
                </H5>

                <ModalAddShop />
              </div>
              <div style={{ width: '50%' }}>
                <InputSearch
                  placeholder={'ค้นหาร้านค้า'}
                  onChange={this.onChangeSearchShop}
                  onKeyPress={this.onKeyPressSearchShop}
                  handleSearch={this.onClickSearchShop}
                  onClearSearch={this.onClearSearch}
                  statusSearch={this.state.statusSearch}
                  // defaultValue={this.state.search}
                />
              </div>

              {/* <div className="input-text-field" style={{width:'50%'}} >
                            <input type="text" placeholder="ค้นหาร้านค้า" value={this.state.search} onChange={this.onChangeSearchShop} onKeyPress={this.onKeyPressSearchShop} />
                            <img src={IconSearch} alt="search" className="cursor-pointer" onClick={this.onClickSearchShop} />
                        </div> */}
            </div>
            {shops && shops.length === 0 ? null : (
              <div className='mt-2'>
                <PaginationComponent
                  defaultCurrent={1}
                  total={total}
                  onChange={(page) => this.onChangePage(page)}
                  pageSize={limit}
                  pageSizeOptions={pageSizeOptions}
                  onShowSizeChange={this.onShowSizeChange}
                  current={page}
                  locale={{ items_per_page: '/ หน้า' }}
                />
              </div>
            )}
            {/* <div className="no-shop">
                    <img src={IconNoShop}/>
                  </div> */}
            {shops && shops.length === 0 && !loading ? (
              <div className='d-flex flex-column align-items-center justify-content-center h-50vh'>
                <img src={noItem} alt='noItem' />
                <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
              </div>
            ) : (
              <div className='shop-list'>
                {/* <div className='table-header'> */}
                <div className='table-grid-header'>
                  <Caption>ลำดับ</Caption>
                  <Caption className="text-left">ชื่อร้านค้า</Caption>
                  <Caption>ชื่อบน URL</Caption>
                  {/* <Caption>กำลัง LIVE</Caption> */}
                  <Caption>รายการสินค้า</Caption>
                  <Caption>
                    สถานะร้านค้า
                  </Caption>
                  <Caption>สถานะ SEO</Caption>
                </div>
                {/* </div> */}

                {shops &&
                  shops.map((shop, index) => (
                    // <Link to={`/manage/shop/shopdetail`} key={'shop' + index}>
                    <div
                      className='position-relative'
                      onClick={() => { this.setCurrentShop(shop); }}
                      key={`listShop${index}`}
                    >
                      <div style={{ width: '88%', height: '100%', position: 'absolute', cursor: 'pointer' }}
                        onClick={() => this.props.history.push('/manage/shop/shopdetail')} />
                      <div className="table-grid-content" >
                        <div>{(page - 1) * limit + index + 1}</div>
                        <div className="text-left d-flex flex-row">{shop.logoPath ? (
                          <img
                            src={`${host.avatar}${shop.logoPath.path}`}
                            className='mr-1'
                          />
                        ) : (
                          <img
                            src={IconShopAvatar}
                            alt='IconShopAvatar'
                          />
                        )}
                          <div className='ml-2'>{shop.name}</div></div>
                        <div>{shop.seo?.permalink}</div>
                        {/* <div>
                          {shop.isLive === true ? (
                            <img src={IconLive} />
                          ) : (
                            '-'
                          )}</div> */}
                        <div>{formatNumber(shop.amount)}</div>
                        <div>{
                          ///status request mock open
                          shop.status === 'show' ||
                            shop.status === 'request' ? (
                            <span className='badge badge-green'>
                              เปิด
                            </span>
                          ) : (
                            <span className='badge badge-gray'>
                              ปิด
                            </span>
                          )
                        }</div>
                        <div className="cursor-pointer d-flex flex-row flex-wrap justify-content-center"
                          onClick={() => this.setState({ modal: { isOpen: true, data: shop } })} style={{ gap: '5px' }}>
                          <div className={this.getBadeStatusClass('title', shop.seo?.title)}>T</div>
                          <div className={this.getBadeStatusClass('description', shop.seo?.description)}>D</div>
                          <div className={this.getBadeStatusClass('keyword', shop.seo?.keyword)}>K</div>
                        </div>
                      </div>
                    </div>
                    // </Link>
                  )
                  )}


                {/* <div className='table-body'>
                  {shops &&
                    shops.map((shop, index) => (
                      <Link to={`/manage/shop/shopdetail`} key={index}>
                        <div
                          className='row'
                          onClick={() => this.setCurrentShop(shop)}
                        >
                          <div className='col-1 align-center'>
                            {(page - 1) * limit + index + 1}{' '}
                          </div>
                          <div className='col-3-atl align-left shop-logo d-flex align-items-center'>
                            {shop.logoPath ? (
                              <img
                                src={`${host.avatar}${shop.logoPath.path}`}
                                className='mr-1'
                              />
                            ) : (
                              <img
                                src={IconShopAvatar}
                                alt='IconShopAvatar'
                              />
                            )}
                            <span className='ml-4'>{shop.name}</span>
                          </div>
                          <div className='col-1-atl align-center'>
                            {' '}
                            {shop.isLive === true ? (
                              <img src={IconLive} />
                            ) : (
                              '-'
                            )}{' '}
                          </div>
                          <div className='col-2 align-center'>
                            {formatNumber(shop.amount)}
                          </div>
                          <div className='col-1-atl align-center'>
                            {
                              ///status request mock open
                              shop.status === 'show' ||
                                shop.status === 'request' ? (
                                <span className='badge badge-green'>
                                  {' '}
                                  เปิด{' '}
                                </span>
                              ) : (
                                <span className='badge badge-gray'>
                                  {' '}
                                  ปิด{' '}
                                </span>
                              )
                            }
                          </div>
                          <div className='col-3 align-center'>
                            TODO - wait for api
                            <span className={this.getBadeStatusClass(shop.seoStatus.title)}> Title </span>
                            <span className={this.getBadeStatusClass(shop.seoStatus.description)}> Description </span>
                            <span className={this.getBadeStatusClass(shop.seoStatus.keyword)}> Keyword </span>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div> */}



              </div>
            )}
          </div>

          {shops && shops.length === 0 ? null : (
            <div className='float-right position-relative'>
              <PaginationComponent
                defaultCurrent={1}
                total={total}
                onChange={(page) => this.onChangePage(page)}
                pageSize={limit}
                pageSizeOptions={pageSizeOptions}
                onShowSizeChange={this.onShowSizeChange}
                current={page}
                locale={{ items_per_page: '/ หน้า' }}
              />
            </div>
          )}
        </div>


        <ModalConfirmChildren
          isOpen={modal.isOpen}
          labelHeader={'ตั้งค่า SEO'}
          handleConfirm={() => this.submitModal()}
          handleClose={() => this.setState({ modal: { isOpen: false, data: null } })}
          btnCancel={'ยกเลิก'}
          btnConfirm={'บันทึก'}
        >
          <CreateSEO seo={seo} handleChangeSEO={this.handleChangeSEO} modal={modal} data={modal.data} setKeyValue={this.setKeyValue} errors={this.state.errors} />
          <Loading />
        </ModalConfirmChildren>

      </React.Fragment >
    )
  }
}

const mapStateToProps = state => ({
  shops: state.shop.shops,
  limit: state.shop.limit,
  page: state.shop.page,
  total: state.shop.total,
  pageSizeOptions: state.shop.pageSizeOptions,
  loading: state.app.loading,
  gallery: state.gallery?.seoUploadEvent,
  shopDetailError: state.shop.shopDetailError,
  galleryState: state.gallery
})

export default connect(mapStateToProps)(Shop)


