import React, { useState, useEffect } from 'react';
import RadioComponent from "components/RadioButton";

const EditProductRadio = (props) => {
    const {
        type, //'all'||'some'
        callbackData,
    } = props

    const [typeSelect, setTypeSelect] = useState(type);
    useEffect(() => {
        setTypeSelect(type)
    }, [type]);

    const handleSelectModeProduct = (e) => {
        const { value } = e.target
        setTypeSelect(value)
        callbackData(value)
    }

    return (
        <div className="d-flex flex-row gap-2">
            {
                [
                    { name: 'เลือกทุกรายการ', value: 'all' },
                    { name: 'เลือกบางรายการ', value: 'some' },
                ].map((item, index) => {
                    return (
                        <div className="d-flex flex-row align-items-center" key={`radio-${index}`}>
                            <RadioComponent
                                name={item.name}
                                value={item.value}
                                valueOption={typeSelect}
                                onChange={(e) => handleSelectModeProduct(e)}
                            />
                            <span>{item.name}</span>
                        </div>
                    )
                })}
        </div>
    );
};


export default EditProductRadio;