import { Loopback } from "../loopback-client";
import host from '../config/host';
import axiosUploadImage from "axios/index";
import { dataURLtoFile } from '../helper/utils';
import Axios from "axios/index";
import axiosNew from "config/axios";



class FileProvider extends Loopback {
    constructor() {
        super("FileProvider")
    }

    uploadImage = async fileImages => {

        let imagePaths = [];
        for (const element of fileImages) {

            const { file, fileName } = element.fileImage;
            const param = [{
                file: file,
                fileName: fileName,
                src: null,
                statusDelete: false,
                statusUpload: true,
                typeFile: "cover"
            }];



            const image = await this.uploadFileImage(param);
            imagePaths.push(`image/${image.cover.fileName}`);
            // 
        }

        return imagePaths
    }

    uploadFileImage = async (file) => {




        const { apiMedia, serviceKey, secretKey } = host;
        let cover = {}
        let media = []

        let key = {
            "serviceKey": serviceKey,
            "secretKey": secretKey
        }

        for (let index in file) {

            let data = file[index]

            if (data.statusUpload) {

                if (data.file) {

                    let fileObj = data.file


                    // เช็ค type image base 64 ถ้าไม่ใช่ แสดงว่าเป็น video
                    if (typeof fileObj === 'string') {
                        fileObj = await dataURLtoFile(fileObj, data.fileName)
                    }


                    let form = new FormData()
                    form.append('serviceKey', serviceKey)
                    form.append('secretKey', secretKey)
                    form.append('file', fileObj)

                    // delete image
                    if (data.fileOld) {
                        await axiosUploadImage.post(`${apiMedia}/deleteImage/${data.fileOld.fileName}`, JSON.stringify(key))
                            .then((response) => {
                            })
                            .catch((error) => {
                            });
                    }


                    /// upload image
                    await axiosUploadImage.post(`${apiMedia}/upload`, form)
                        // eslint-disable-next-line no-loop-func
                        .then((response) => {
                            if (data.typeFile === 'cover') {
                                cover = response.data
                            } else {
                                media = [
                                    ...media,
                                    response.data
                                ]
                            }


                            form.delete('serviceKey')
                            form.delete('secretKey')
                            form.delete('file')

                        })
                        .catch((error) => {
                            // dispatch(openNotification(`Error upload file${index} .`, `error`))
                        });


                } else {


                    if (data.statusDelete && data.fileOld) {
                        await axiosUploadImage.post(`${apiMedia}/deleteImage/${data.fileOld.fileName}`, JSON.stringify(key))
                            .then((response) => {
                            })
                            .catch((error) => {
                            });
                    }
                }

            } else {


                if (data.file) {
                    if (data.typeFile === 'cover') {
                        cover = data.file
                    } else {
                        media = [
                            ...media,
                            data.file
                        ]
                    }
                }


            }
        }

        return {
            media,
            cover
        }
    }

    uploadImageOnce = async (data) => {
        const path = `${host.apiMedia}/upload`

        const formData = new FormData()
        formData.append('file', data)
        formData.append('serviceKey', host.serviceKey)
        formData.append('secretKey', host.secretKey)

        const response = await Axios.post(path, formData)

        if (response.status === 200) {
            return response.data
        } else {
            // handle error

            return null
        }
    }

    uploadExcel = async (params) => {
        try {
            const path = `${host.api}/products/import/excel-product`

            const formData = new FormData()
            formData.append('file', params.file)
            formData.append('shopId', params.shopId)

            const res = await axiosNew.post(path, formData)
            return res.data
        } catch (error) {
            throw error
        }
    }

    getStatusProductUploading = async (id) => {
        try {
            const path = `${host.api}/product-attachments/${id}`
            const res = await axiosNew.get(path)
            return res.data
        } catch (error) {
            throw error
        }
    }

    uploadExcelTracking = async (params) => {
        try {
            const resData = await new Promise(async (resolve, reject) => {
                const path = `${host.api}/deliveries/import/tracking`
                const formData = new FormData()
                formData.append('file', params.file)
                formData.append('shopId', params.shopId)
                try {
                    const res = await axiosNew.patch(path, formData)
                    resolve(res.data)
                } catch (error) {
                    reject(error)
                }
            })
            return resData
        } catch (error) {
            throw error
        }
    }

    uploadExcelEditProduct = async (params) => {
        try {
            const resData = await new Promise(async (resolve, reject) => {
                const path = `${host.api}/products/import/excel-product`
                const formData = new FormData()
                formData.append('file', params.file)
                formData.append('shopId', params.shopId)
                try {
                    const res = await axiosNew.patch(path, formData)
                    resolve(res.data)
                } catch (error) {
                    reject(error)
                }
            })
            return resData
        } catch (error) {
            throw error
        }
    }

}


export default new FileProvider()