import React, { useEffect } from 'react'
import PlaylistFrontPage from "../components/PlaylistFrontPage/PlaylistFrontPage";
import { useSelector, useDispatch } from "react-redux";
import {
  dispatchShop,
  ADD_KEY_VALUE_SHOP,
} from 'redux/actions/shop'

const PlaylistFrontPagePlatForm = (props) => {
  const { dispatch, history } = props;
  const {shopSelected} = useSelector((state) => state.shop);

  const Dispatch = useDispatch();
  useEffect(() => {
    initialComponent();
    return () => {

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initialComponent = () => {
    Dispatch(
      dispatchShop({
        type: ADD_KEY_VALUE_SHOP,
        payload: { key: 'shopSelected', value: null }
      }));
  }
  return (
    <>
     { !shopSelected && 
      <PlaylistFrontPage dispatch={dispatch} history={history} />
     }
    </>
  );
};

export default PlaylistFrontPagePlatForm;