/* eslint-disable default-case */
import React, { useEffect } from 'react'
import { 
  // useDispatch, 
  useSelector } from "react-redux";
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom';
import {
  PlaylistShop,
  PlaylistShopCoupon,
  PlaylistCategories,
  PlaylistProducts,
  PlaylistCoupon
} from 'components/PlaylistItems';



/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */
const selectByPlaylistItemType = ({ playlistsId, playlistsName, playlistsType, playlistsPlatform, sortType, isPlatFormMode }) => {
  const playlist = { playlistsId, playlistsName, playlistsType, playlistsPlatform, sortType, isPlatFormMode };
  switch (playlistsType) {
    case "shop":
      return <PlaylistShop playlist={playlist} />
    case "shop_coupon":
      return <PlaylistShopCoupon playlist={playlist} />
    case "category":
      return <PlaylistCategories playlist={playlist} />
    case "product_general":
      return <PlaylistProducts playlist={playlist} />
    case "product_digital":
      return <PlaylistProducts playlist={playlist} />
    case "coupon":
      return <PlaylistCoupon playlist={playlist} />
  }
}

/* -------------------------------------------------------------------------- */
/*                             main component                                 */
/* -------------------------------------------------------------------------- */
const PlaylistItem = (props) => {
  const { playlistsId, playlistsName, playlistsType, playlistsPlatform, sortType, isPlatFormMode } = usePlaylistItem(props);
  return (
    <div className="container-playlist-item">
      {selectByPlaylistItemType({ playlistsId, playlistsName, playlistsType, playlistsPlatform, sortType, isPlatFormMode })}
    </div>
  );
};
export default PlaylistItem;
/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const usePlaylistItem = (props) => {
  const history = useHistory();
  const shopSelected = useSelector(state => state.shop.shopSelected)

  const { location: { state: { playlistsId, playlistsName, playlistsType, playlistsPlatform, sortType } } } = props;

  const Location = useLocation()
  const isPlatFormMode = Location.pathname.includes('platform');

  useEffect(() => {
    if(!shopSelected && !isPlatFormMode)
      history.push('/manage/playlists/mall')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { playlistsId, playlistsName, playlistsType, playlistsPlatform, sortType, isPlatFormMode }
};




