import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './styles.scss'
import { dispatchModalConfirm, 
    // OPEN_MODAL_CONFIRM 
} from '../../redux/actions/modalConfirm';
import classnames from 'classnames'
import closeIcon from '../../images/icons/close.svg'
import { CLOSE_MODAL_CONFIRM_LARGE } from 'redux/actions/order';


class ModalConfirmLarge extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false
        }
    }

static defaultProps = {
    isOpen: false,
    message: 'ยืนยันการเลือก',
    message2: '',
    btnConfirm: 'ใช่',
    btnCancel: 'ไม่ใช่'
}

    confirmModal = () => {
        this.props.confirm();
        // this.props.dispatch(dispatchModalConfirm({type:CLOSE_MODAL_CONFIRM_LARGE}))
    }

    cancelModal = () => {
        // if(this.props.isClose != undefined) return this.props.isClose()
        this.props.dispatch(dispatchModalConfirm({type:CLOSE_MODAL_CONFIRM_LARGE}))
    }


    render() {
        /// message show
        const {message, isOpen, isClose, btnConfirm, btnCancel, message2, type, headerMsg} = this.props
        return (
            <React.Fragment>
                {/* <Button color="danger" onClick={() => this.setState({ modal: true })}>{'modalconfirm'}</Button> */}
                <Modal isOpen={isOpen} toggle={() => this.setState({ modal: false })} modalClassName="modal-dialog-confirm-large">
                    <ModalHeader toggle={() => this.setState({ modal: true })}>
                        <div className="d-flex flex-row justify-content-between w-100">
                        <span> {headerMsg}  </span>
                        <img src={closeIcon} alt="" onClick={isClose} className="cursor-pointer" />
                        </div>

                    </ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-center align-items-center h-100 flex-column w-100 m-auto">
                        <h4 className="text-center">{message}</h4>
                            <h4 className={classnames({'text-success':type === "success"})}>{message2}</h4>
                            <div className="w-100 d-flex mt-4" style={{justifyContent:'center',gap: '20px'}}>
                            <span><button className="btn-cancel" onClick={this.cancelModal}>{btnCancel}</button></span>
                            <span><button className="btn-submit" onClick={this.confirmModal}>{btnConfirm}</button></span>
                        </div>
                            </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    // modalConfirm : state.modalConfirm.ModalConfirm
})

export default connect(mapStateToProps, null)(ModalConfirmLarge)