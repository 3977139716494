export const ADD_KEY_VALUE_SELECTEDED_JOIN_SHOP = "ADD_KEY_VALUE_SELECTEDED_JOIN_SHOP"
export const REMOVE_SHOP_SELECTED_JOIN_SHOP = 'REMOVE_SHOP_SELECTED_JOIN_SHOP'
export const UPDATE_COUNT_SELECTED_JOIN_SHOP = 'UPDATE_COUNT_SELECTED_JOIN_SHOP'
export const ADD_PRODUCT_SELECTED_JOIN_SHOP = "ADD_PRODUCT_SELECTED_JOIN_SHOP"
export const RESET_PRODUCT_SELECTED_JOIN_SHOP = "RESET_PRODUCT_SELECTED_JOIN_SHOP"

export function dispatchjoinSelectedShop(param){
    return {
      type: param.type,
      payload: param.payload,
    };
  }