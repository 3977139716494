import React, { useState } from 'react'
import { 
    // Button,
    Modal, ModalHeader, ModalBody, 
    // ModalFooter
 } from 'reactstrap';
import iconClose from 'images/icons/close.svg'
import './styles.scss'


const ModalConfirmChildren = (props) => {
    const { children, isOpen, btnConfirm, btnCancel, handleConfirm, handleClose, labelHeader } = props
    // eslint-disable-next-line no-unused-vars
    const [modal, setModal] = useState(false)

    const confirmModal = () => {
        handleConfirm()
    }

    const cancelModal = () => {
        handleClose()
    }
    return (
        <React.Fragment>
            {/* <Button color="danger" onClick={() => this.setState({ modal: true })}>{'modalconfirm'}</Button> */}
            <Modal isOpen={isOpen} toggle={() => setModal(false)} modalClassName="modal-confirm-children-header" size="xl">
                <ModalHeader toggle={() => { }}>
                    <div className="d-flex justify-content-between w-100">
                        <span>{labelHeader}</span>
                        <span>
                            <img src={iconClose} alt="" className="cursor-pointer" onClick={cancelModal} />
                        </span>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {children}
                    <div className="w-100 d-flex justify-content-center" style={{ gap: '30px', marginTop: '2em' }}>
                        <span><button className="btn-cancel" onClick={cancelModal}>{btnCancel}</button></span>
                        <span><button className="btn-submit" onClick={confirmModal}>{btnConfirm}</button></span>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

ModalConfirmChildren.defaultProps = {
    isOpen: false,
    btnCancel: 'cancel',
    btnConfirm: 'ok',
    handleClose: () => { },
    handleConfirm: () => { }
}

export default ModalConfirmChildren
