/* eslint-disable react-hooks/exhaustive-deps */
import { Body1 } from 'components/FontStyle'
import React, { useState, useEffect, useRef } from 'react'
import './styles.scss'
import { ReactComponent as IconArrowDown } from 'images/icons/ais-arrow-down.svg'
import { ReactComponent as IconArrowUp } from 'images/icons/ais-arrow-up.svg'

function useOutsideAlerter(ref, setIsOpen) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                // alert("You clicked outside of me!");
                setIsOpen(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function SelectInputAdd(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [text, setFilter] = useState('')
    const [optionsList, setOptions] = useState([])
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setIsOpen);

    useEffect(() => {
        let matchingStrings = [];
        if (props.options) {
            props.options && props.options.forEach((item) => {
                if (item?.label?.toLowerCase().substr(0, text?.length).indexOf(text.toLowerCase()) >= 0) {
                    matchingStrings.push(item)
                    // if (item.label.toLowerCase().search(searchString.toLowerCase()) > -1) {
                    //     matchingStrings.push(item)
                }
            })
        }
        setOptions(matchingStrings)
    }, [text])

    useEffect(() => {
        setOptions(props.options)
    }, [props.options])

    useEffect(() => {
        if (props.value === null) {
            setFilter('')
        } else {
            setFilter(props.value.label)
        }
    }, [props.value])

    const handleSelected = (item) => {
        props.handleSelected(item)
        setFilter(item.label)
        setIsOpen(false)
    }

    return <div ref={wrapperRef}>
        <div>
            <div class="container-select-input">
                {/* <div className="icon-input">
                    <input type="text" className="form-control w-100 select input-select" onChange={(e) => setFilter(e.target.value)} onClick={() => { props.handleGetOptions(); setIsOpen(true) }} value={text} />
                </div> */}
                <div class="inner-addon right-addon">
                    {isOpen ? <IconArrowUp className="glyphicon" /> : <IconArrowDown className="glyphicon" />}
                    <input type="text"
                        className="form-control w-100 select input-select"
                        onChange={(e) => setFilter(e.target.value)}
                        onClick={() => { props.handleGetOptions(); setIsOpen(true) }}
                        value={text}
                        disabled={props.disabled}
                        readOnly={props.disabled}
                    />
                </div>
                {/* <button className="select" name="select" value="options">options</button> */}
                {isOpen &&
                    <div className='position-relative'>
                        <div className="options">
                            <div className='list'>
                            {optionsList.map((item, idx) => (
                                <div onClick={() => { handleSelected(item) }} key={'list' + idx} className="item">
                                    <Body1> {item.label} </Body1>
                                </div>
                            ))}
                            </div>
                            <div className="item text-center" onClick={() => { props.onAddOptions(); setIsOpen(false) }} style={{ color: '#EC008C', borderTop: '1px solid #E1E1E1' }}>
                                <Body1> {props.labelAdd} </Body1>
                            </div>
                        </div>

                    </div>
                }

            </div>
        </div >
    </div>;
}
