export const ADD_LIST_CHECKED_SHOP = "ADD_LIST_CHECKED_SHOP";
export const REMOVE_LIST_CHECKED_SHOP = "REMOVE_LIST_CHECKED_SHOP";
export const SET_KEY_LIST_CHECKED_SHOP = "SET_KEY_LIST_CHECKED_SHOP";
export const CHECK_PRODUCT_CHECKED_SHOP = "CHECK_PRODUCT_CHECKED_SHOP"

export function dispatchListCheckedShop(param) {
  return {
    type: param.type,
    payload: param.payload,
  };
}
