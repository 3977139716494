import { Body1, Body2, H6, SubTitle2 } from 'components/FontStyle'
import DashBoardHeaderSelect from 'components/output/DashBoardHeaderSelect'
import PaginationComponent from 'components/pagination'
import moment from 'moment'
import dashboardProvider from 'provider/dashboardProvider'
import shopProvider from 'provider/shopProvider'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchApp, SET_LOADING } from 'redux/actions/app'
import { dispatchDashboard, SET_DEFAULT_DASHBOARD, SET_KEY_DASHBOARD } from 'redux/actions/dashboard'
import { ReactComponent as IconNoItem } from 'images/icons/no-item.svg';
import { generatePeriodDate, options } from 'components/PeriodTimeInput/dataUtil'
import 'styles/_summaryOrder.scss';
import { displayDateTh, displayDateThShortYear } from 'helper/timeUtil'
import numeral from 'numeral'


const SummaryOrder = () => {
    const dispatch = useDispatch()
    const dashboardState = useSelector(state => state.dashboard)
    const { shopSelected, fromDate, toDate, listShop, rangeDate } = dashboardState
    // const { state: { type } } = location
    const [listAllShop, setListAllShop] = useState([])
    const [listOrder, setListOrder] = useState(null)
    const [listOrderAll, setListOrderAll] = useState(null)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(null)
    const [headerExcel, setHeaderExcel] = useState(null)
    const [type, setType] = useState('order')
    const [isFirst, setIsFirst] = useState(true)
    const api_shops = new shopProvider()

    useEffect(() => {
        (async () => {
            if(!dashboardState.isFromDashboard){
                await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'shopSelected', value: 0 } }))
             }
 
             await getListShop()
             // await getListOrder()
             await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'summaryOrder', value: true } }))


            if (rangeDate) {
                const initDate = generatePeriodDate(rangeDate?.type, { startDate: rangeDate?.period?.from, endDate: rangeDate?.period?.to })
                if (initDate) {
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initDate.period.from).utc(0).format() } }))
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initDate.period.to).utc(0).format() } }))
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: rangeDate } }))
                }
            } else {
                const initialRealTime = generatePeriodDate(options.REAL_TIME, { startDate: moment(), endDate: moment() })
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: initialRealTime } }))
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initialRealTime.period.from).utc(0).format() } }))
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initialRealTime.period.to).utc(0).format() } }))
            }
        })()
        return () => {
            (async () => {
                new Promise(async (resolve,reject) => {
                    const initialRealTime = generatePeriodDate(options.REAL_TIME, { startDate: moment(), endDate: moment() })
                    if (initialRealTime) {
                        await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initialRealTime.period.from).utc(0).format() } }))
                        await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initialRealTime.period.to).utc(0).format() } }))
                        await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: initialRealTime } }))
                        await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'shopSelected', value: 0 } }))
                        await dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'summaryOrder', value: false } }))
                        // await dispatch(dispatchDashboard({ type: SET_DEFAULT_DASHBOARD }))  
                    }
                })
               
            })()
        }
    }, [])

    useEffect(() => {
        (async () => {
            // if(isFirst){
            //     setIsFirst(false)
            //     return
            // }
            dispatch(dispatchApp({type: SET_LOADING,payload:{loading: true}}))
            if(dashboardState.fromDate && dashboardState.toDate && page && limit){
                await getListOrder()
            }
            dispatch(dispatchApp({type: SET_LOADING,payload:{loading: false}}))
        })()

        return () => {
            // console.log('unmount')
            // dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'shopSelected', value: 0 } }))
        }
    }, [
        shopSelected,rangeDate,fromDate,toDate,page,limit
    ])



    const getListShop = async () => {
        // dispatch({ type: SET_LOADING, payload: { loading: true } })
        const res = await api_shops.getAllShops()
        if (res) {
            const listShop = res.shop.map((shop) => {
                return { label: shop.name, value: shop, img: shop.logoPath }
            })

            // for user which have one shop
            if (res?.shop?.length > 1) {
                // dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'shopSelected', value: 0 } }))
                listShop.unshift(
                    {
                        label: 'ร้านทั้งหมด',
                        value: '',
                        img: null
                    })
            }
            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'listShop', value: listShop } }))
            setListAllShop(listShop)
        }
        // dispatch({ type: SET_LOADING, payload: { loading: false } })
    }

    const checkOrderStatus = (type, logistic) => {
        if (type === 'request' || type === "refunded" || type === "return" ||  type === "approved") {
            if (type === "request") return 'รออนุมัติคืนเงิน'
            else if (type === "approved") return 'กำลังดำเนินการคืนเงิน'
            else if (type === "return") return 'รอคืนสินค้า'
            else if (type === "refunded") return 'คืนเงินแล้ว'
        } else {
            if (logistic === "wait") { return "รอจัดส่ง" }
            else if (logistic === "posting" || logistic === "intransit" || logistic === "redelivery" || logistic === "outdelivery") { return "อยู่ระหว่างขนส่ง" }
            else if (logistic === "delivered") { return "สำเร็จ" }
            else if (logistic === "issues" || logistic === "return") { return "ยกเลิก" }
            else { return logistic }
        }
    }

    const checkPaymentStatus = (type) => {
        if (type === "wait") { return 'รอชำระเงิน' }
        else if (type === "transfer") { return 'รอตรวจสอบ' }
        else if (type === "approved" || type === "paid") { return 'ชำระเงินแล้ว' }
        else if (type === "failed") { return 'ยกเลิก' }
        else { return type }
    }

    const checkPaymentMethod = (type) => {
        if(type === 'creditcard' || type ==='ibanking' || type ==='cod' || type ==='transfer' || type ==='QR_CODE'){
            if(type === 'creditcard') return 'บัตรเครดิต'
            if(type === 'ibanking') return 'อินเตอร์เน็ตแบงก์กิ้ง'
            if(type === 'cod') return 'เก็บเงินปลายทาง'
            if(type === 'transfer') return 'โอนผ่านธนาคาร'
            if(type === 'qrcode') return 'QR Code'
        } else { return type}
    }

    const fetchDataExcel = async () => {
        const excelArr = []
        // const params = { fromDate, toDate, limit: limit, page: page }
        const paramsListAll = { fromDate, toDate, page: 1 }
        if (shopSelected) {
            const shopId = listShop[shopSelected]?.value?.id
            if (shopId) {
                const shopId = listShop[shopSelected]?.value?.id
                // params.shopId = shopId
                paramsListAll.shopId = shopId
            }
        }
        if (listOrder) {
            paramsListAll.limit = listOrder?.count
            if (listOrder.count) {
                const listOrderAll = await dashboardProvider.getOrderDashboardReport(type, paramsListAll)
                setListOrderAll(listOrderAll.data.data)
                if (listOrderAll.data && (type === 'order')) {
                    if (listOrderAll.data?.data?.length) {
                        let couponPlatform = []
                        let couponShop = []
                        listOrderAll.data.data.map((data, index) => {
                            couponPlatform = data.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'platform')
                            couponShop = data.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'shop')
                            excelArr.push({
                                index: (index + 1).toLocaleString(),
                                orderNo: data.orderNo,
                                deliveryBy: data?.delivery?.name,
                                transactionId: data?.payment?.transactionId,
                                shopName: data?.shopName,
                                customerName: data?.delivery?.name === 'Digital' ? '-' : `${data?.customer?.name}\n${data?.customer?.mobile}\n${data?.customer?.address} ${data?.customer?.district}\n${data?.customer?.subdistrict} ${data?.customer?.province} ${data?.customer?.zipcode}`,
                                email: data?.customer?.email,
                                orderItem: `${data?.items?.map((product) => `${product.product?.name ? `${product.product?.name}\r\n` : ''}${product.productSku?.sku ? `${product.productSku?.sku}\r\n` : ''}${product?.productSku?.sellerSKU ? `เลขอ้างอิง SKU : ${product?.productSku?.sellerSKU ? `${product?.productSku?.sellerSKU}\r\n` : ''}` : ''}${product?.productSku?.barcode ? `บาร์โค้ด : ${product?.productSku?.barcode ? `${product?.productSku?.barcode}\r\n` : ''}` : ''}จำนวน : ${product?.quantity}`).join('\r\n')}`,
                                priceNet: data?.priceNet,
                                discountPlatformCode: couponPlatform.length ? `${couponPlatform[0]?.couponCode?.coupon?.promotion?.name}\n${couponPlatform[0]?.couponCode?.coupon?.refCode1}` : '',
                                priceDiscountPlatform: data?.discount?.priceItemDiscount || data?.discount?.priceShippingDiscount || data?.discount?.priceVoucherDiscount ? data?.discount?.priceItemDiscount + data?.discount?.priceShippingDiscount + data?.discount?.priceVoucherDiscount : '0',
                                discountShopCode: couponShop.length ? `${couponShop[0]?.couponCode?.coupon?.promotion?.name}\n${couponShop[0]?.couponCode?.coupon?.refCode1}` : '',
                                priceDiscountShop: data?.discount?.priceItemDiscountShop || data?.discount?.priceShippingDiscountShop || data?.discount?.priceVoucherDiscountShop ? data?.discount?.priceItemDiscountShop + data?.discount?.priceShippingDiscountShop + data?.discount?.priceVoucherDiscountShop : '0',
                                shippingPrice: data?.priceShippingNet ? data?.priceShippingNet : '0',
                                priceTotal: data.priceTotal.toLocaleString(),
                                paymentedAt: displayDateThShortYear(data?.paymentedAt),
                                ช่องทางการชำระเงิน: checkPaymentMethod(data?.paymentMethod),
                                สถานะการสั่งซื้อ:checkOrderStatus(data?.refundStatus,data?.logisticStatus) ,
                                สถานะการชำระเงิน:checkPaymentStatus(data?.paymentStatus),
                                shipmentStatus: checkLogisticStatusExcel(data?.logisticStatus,data?.shipmentStatus),
                                deliveryDate: displayDateThShortYear(data?.deliveryDate),
                                deliveredAt:displayDateThShortYear(data?.deliveredAt),
                                customerTax: data?.customerTax?.taxType ? `${data?.customerTax?.taxType === 'individual' ? `บุคคลธรรมดา\n${data?.customerTax?.name}\nหมายเลขบัตรประชาชน ${data?.customerTax?.taxNo}\nที่อยู่ใบกำกับภาษี\n${data?.customerTax?.address} ${data?.customerTax?.subdistrict} ${data?.customerTax?.district} ${data?.customerTax?.province} ${data?.customerTax?.zipcode}` : `นิติบุคคล\nที่อยู่ใบกำกับภาษี\n${data?.customerTax?.name}\n${data?.customerTax?.address}\n${data?.customerTax?.subdistrict} ${data?.customerTax?.district} ${data?.customerTax?.province} ${data?.customerTax?.zipcode}\nหมายเลขประจำตัวผู้เสียภาษี ${data?.customerTax?.taxNo}\n${data?.customerTax?.branch === '00000' ? 'สำนักงานใหญ่' : `สาขา ${data?.customerTax?.branch}`}`}` : '',
                                requestNo: data?.issues?.length ? data?.issues[data?.issues?.length - 1]?.issueNo : '-', 
                                refundType: data?.issues?.length ? data?.issues[data?.issues?.length - 1]?.refundType === 'refunded' ? 'Refund' : data?.issues[data?.issues?.length - 1].refundType === 'void' ? 'Void' : '-' : '-',
                                voidRefundDate: data?.issues?.length ? displayDateThShortYear(data?.issues[data?.issues?.length - 1].refundedAt,'DD MMM YYYY HH:mm') : '-'
                                // shopName: data.shopName,
                                // customerName: data?.customer?.name,
                                // priceTotal: data.priceTotal.toLocaleString(),
                                // paymentedAt: displayDateThShortYear(data?.paymentedAt),
                                // shipmentStatus: data?.shipmentStatus,
                                // deliveryDate: displayDateThShortYear(data?.deliveryDate)
                            })
                        })
                        excelArr.push({
                            index: '',
                            orderNo: '',
                            deliveryBy: '',
                            transactionId: '',
                            shopName:'',
                            customerName: 'ทั้งหมด',
                            email: '',
                            orderItem: '',
                            priceNet: listOrderAll?.data?.priceNet ? listOrderAll?.data?.priceNet : '0',
                            discountPlatformCode: '',
                            priceDiscountPlatform: listOrderAll?.data?.totalDiscountPlatform ? listOrderAll?.data?.totalDiscountPlatform : '0',
                            discountShopCode: '',
                            priceDiscountShop: listOrderAll?.data?.totalDiscountShop ? listOrderAll?.data?.totalDiscountShop : '0',
                            shippingPrice: listOrderAll?.data?.totalPriceShippingNet ? listOrderAll?.data?.totalPriceShippingNet : '0',
                            priceTotal: listOrderAll?.data?.priceTotal ? listOrderAll?.data?.priceTotal : '0',
                            paymentedAt: '',
                            ช่องทางการชำระเงิน: '',
                            shipmentStatus: '',
                            deliveryDate: '',
                            deliveredAt:'',
                            customerTax: '',
                            requestNo: '',
                            refundType: '',
                            voidRefundDate: ''
                        })
                        // setExcelData(excelArr)
                    } else {
                        // setExcelData([{ index: '', orderNo: '', shopName: '', customerName: '', quantity: '', priceTotal: '' }])
                    }
                    // ["ลำดับ", "หมายเลขออเดอร์","เลขอ้างอิง 2C2P", "ชื่อร้านค้า", "ชื่อลูกค้า","ยอดเงิน", "วันที่ชำระเงิน", "สถานะการจัดส่ง", "ส่งภายในวันที่"]

                }
                return {
                    header: [
                        "ลำดับ",
                        "หมายเลขออเดอร์",
                        "รูปแบบการจัดส่ง",
                        "เลขอ้างอิง Transaction",
                        "ชื่อร้านค้า",
                        "ข้อมูลการจัดส่ง",
                        "E-Mail",
                        "รายการสั่งซื้อ",
                        "ราคาสินค้า",
                        "เลขคูปองส่วนลด V-AVENUE",
                        "มูลค่าส่วนลด V-AVENUE",
                        "เลขคูปองส่วนลดร้านค้า",
                        "มูลค่าส่วนลดร้านค้า",
                        "ค่าจัดส่ง",
                        "ยอดที่ลูกค้าชำระ",
                        "วันที่ชำระเงิน",
                        "ช่องทางการชำระเงิน",
                        "สถานะการสั่งซื้อ",
                        "สถานะการชำระเงิน",
                        "สถานะการจัดส่ง",
                        "ส่งภายในวันที่",
                        "วันจัดส่งสำเร็จ",
                        "ข้อมูลใบกำกับภาษี",
                        'Request No.',
                        'Refund Type',
                        'Void/Refund Date'],
                    data: excelArr
                }
            } else {
                setListOrderAll({ data: [] })
                return {
                    header: [
                        "ลำดับ",
                        "หมายเลขออเดอร์",
                        "รูปแบบการจัดส่ง",
                        "เลขอ้างอิง Transaction",
                        "ชื่อร้านค้า",
                        "ข้อมูลการจัดส่ง",
                        "E-Mail",
                        "รายการสั่งซื้อ",
                        "ราคาสินค้า",
                        "เลขคูปองส่วนลด V-AVENUE",
                        "มูลค่าส่วนลด V-AVENUE",
                        "เลขคูปองส่วนลดร้านค้า",
                        "มูลค่าส่วนลดร้านค้า",
                        "ค่าจัดส่ง",
                        "ยอดที่ลูกค้าชำระ",
                        "วันที่ชำระเงิน",
                        "ช่องทางการชำระเงิน",
                        "สถานะการสั่งซื้อ",
                        "สถานะการชำระเงิน",
                        "สถานะการจัดส่ง",
                        "ส่งภายในวันที่",
                        "วันจัดส่งสำเร็จ",
                        "ข้อมูลใบกำกับภาษี"],
                    data: [
                    {
                    index: '',
                    orderNo: '',
                    deliveryBy: '',
                    transactionId: '',
                    shopName:'',
                    customerName: '',
                    email: '',
                    orderItem: '',
                    priceNet: '',
                    discountPlatformCode: '',
                    priceDiscountPlatform: '',
                    discountShopCode: '',
                    priceDiscountShop: '',
                    shippingPrice: '',
                    priceTotal: '',
                    paymentedAt: '',
                    ช่องทางการชำระเงิน: '',
                    "สถานะการสั่งซื้อ":'',
                    "สถานะการชำระเงิน":'',
                    shipmentStatus: '',
                    deliveryDate: '',
                    deliveredAt:'',
                    customerTax: ''
                }
            ]
        }
            }
        }
    }


    const getListOrder = async () => {
        dispatch(dispatchApp({type: SET_LOADING,payload:{loading: true}}))
        if (type) {
            const params = { fromDate, toDate, limit: limit, page: page }
            const paramsListAll = { fromDate, toDate, page: 1 }
            if (shopSelected) {
                const shopId = listShop[shopSelected]?.value?.id
                if (shopId) {
                    const shopId = listShop[shopSelected]?.value?.id
                    params.shopId = shopId
                    paramsListAll.shopId = shopId
                }
            }

            const listOrder = await dashboardProvider.getOrderDashboardReport(type, params)
            // if (listOrder) {
            //     paramsListAll.limit = listOrder.data?.count
            //     if (listOrder.data?.count) {
            //         const listOrderAll = await dashboardProvider.getOrderDashboardReport(type, paramsListAll)
            //         setListOrderAll(listOrderAll.data)
            //     } else {
            //         setListOrderAll({ data: [] })
            //     }
            // }
            setListOrder(listOrder?.data)
            setTotal(listOrder.data?.count)
            dispatch(dispatchApp({type: SET_LOADING,payload:{loading: false}}))

        }
    }

    const onChangePage = (page) => {
        setPage(page)
    }

    const onShowSizeChange = (page, limit) => {
        setPage(page)
        setLimit(limit)
    }

    const checkLogisticStatus = (type, shipmentStatus) => {
        if (shipmentStatus === "waitconfirm") {
            return <label className="wait-payment">รอรับออเดอร์</label>
        }
        if (type === "wait" || type === "redelivery") { return <label className="wait-approve">รอจัดส่ง</label> }
        else if (type === "posting" || type === "outdelivery") { return <label className="wait-approve">อยู่ระหว่างขนส่ง</label> }
        else if (type === "intransit") { return <label className="wait-approve"> อยู่ระหว่างขนส่ง</label> }
        else if (type === "delivered") { return <label className="success-order"> สำเร็จ </label> }
        else if (type === "issues" || type === "return") { return "ยกเลิก" }
        ////refund
        else if (type === "request") { return <label className="wait-approve">รอการคืนเงิน</label> }
        else if (type === "refunded") { return <label className="success-order">คืนเงินแล้ว</label> }
        ////refund
        else { return type }
    }

    const checkLogisticStatusExcel = (type, shipmentStatus) => {
        if (shipmentStatus === "waitconfirm") {
            return 'รอรับออเดอร์'
        }
        if (type === "wait" || type === "redelivery") { return 'รอจัดส่ง' }
        else if (type === "posting" || type === "outdelivery") { return 'อยู่ระหว่างขนส่ง' }
        else if (type === "intransit") { return 'อยู่ระหว่างขนส่ง' }
        else if (type === "delivered") { return 'สำเร็จ' }
        else if (type === "issues" || type === "return") { return "ยกเลิก" }
        ////refund
        else if (type === "request") { return 'รอการคืนเงิน' }
        else if (type === "refunded") { return 'คืนเงินแล้ว' }
        ////refund
        else { return type }
    }



    return (
        // <div></div>
        <div className='summary-order'>
            <DashBoardHeaderSelect
                // headerExcel={headerExcel}
                // dataReport={listOrderAll}
                type={type}
                setPage={setPage}
                fetchDataExcel={() => fetchDataExcel()} />

            <section className='d-flex flex-row bg-white' style={{ padding: '1em' }}>
                <div className='pl-0' style={{ padding: '0 2em' }}>
                    {type === 'order' && <React.Fragment><Body1>ยอดขายทั้งหมด</Body1>  <H6 className='font-weight-bold'>฿ {numeral(listOrder?.priceTotal).format("0,0.00")}</H6></React.Fragment>}
                </div>
                {type === 'order' && <div style={{ padding: '0 2em', borderLeft: '1px solid black' }}>
                    <Body1>จำนวนคำสั่งซื้อทั้งหมด</Body1>  <H6 className='font-weight-bold'>{listOrder?.count}</H6>
                </div>}
            </section>


            <section>
                <div className='table-order-report'>
                    <div>ลำดับ</div>
                    <div>หมายเลขออเดอร์</div>
                    <div>เลขอ้างอิง Transaction</div>
                    <div>ชื่อร้านค้า</div>
                    <div>ชื่อลูกค้า</div>
                    <div>ยอดเงิน</div>
                    <div>วันที่ชำระเงิน</div>
                    <div>สถานะการจัดส่ง</div>
                    <div>ส่งภายในวันที่</div>
                    <div>วันเวลาส่งสำเร็จ</div>
                </div>
                {
                    listOrder?.data?.length === 0 ?
                        <div className='d-flex justify-content-center align-items-center flex-column p-4 mt-4 mb-4'>
                            <IconNoItem />
                            <span>ไม่มีรายการ</span>
                        </div> :
                        listOrder?.data.map((order, index) => (
                            <div className='table-order-report-content' key={`${order?.orderNo + index}`}>
                                <div><Body2>{(page - 1) * limit + index + 1}</Body2></div>
                                <div><Body2>{order?.orderNo}</Body2></div>
                                <div><Body2>{order?.payment?.transactionId}</Body2></div>
                                <div><Body2>{order?.shopName}</Body2></div>
                                <div><Body2>{order?.customer?.name}</Body2></div>
                                <div><Body2>฿ {numeral(order?.priceTotal).format('0,0.00')}</Body2></div>
                                <div><Body2>{displayDateThShortYear(order?.paymentedAt)}</Body2></div>
                                <div><Body2>{checkLogisticStatus(order?.logisticStatus, order?.shipmentStatus)}</Body2></div>
                                <div><Body2>{displayDateThShortYear(order?.deliveryDate)}</Body2></div>
                                <div><Body2>{displayDateThShortYear(order?.deliveredAt)}</Body2></div>
                            </div>
                        ))
                }
            </section>



            {listOrder?.data?.length > 0 &&
                <PaginationComponent
                    defaultCurrent={1}
                    total={total}
                    onChange={(page) => onChangePage(page)}
                    pageSize={limit}
                    pageSizeOptions={[10, 20, 30]}
                    onShowSizeChange={onShowSizeChange}
                    current={page}
                    locale={{ items_per_page: '/ หน้า' }}
                />
            }

        </div>
    )
}

export default SummaryOrder