/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'config/axios'
import host from 'config/host'
import './styles.scss'

const DropdownWithAPI = (props) => {
  const {
    label,
    defaultValue,
    handleSelected,
    path,
    keyTitle,
    keyValue,
    require,
    error,
  } = props

 

  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState(null)

  const [valueDropdown, setValueDropdown] = useState({
    [keyValue]: '',
    [keyTitle]: '',
  })

  const ref = useRef(null)

  const handleClickOutside = (e) => {
    e.preventDefault()

    if (
      ref.current &&
      e.target.id !== `dropdown-box-${keyTitle}` &&
      e.target.id !== `icon-box-${keyTitle}` &&
      e.target.id !== `icon-angle-box-${keyTitle}` &&
      e.target.id !== `option-box-${keyTitle}` &&
      e.target.id !== `text-option-${keyTitle}`
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    if (path) {
      getOptions()
    }
  }, [path])

  useEffect(() => {
    if (options && !defaultValue[keyValue] && defaultValue[keyTitle]) {
      const tmp = options.find((item) => {
        return (
          `${defaultValue[keyTitle]}${defaultValue.zipcode || ''}` ===
          `${item[keyTitle]}${item.zipcode || ''}`
        )
      })

      setValueDropdown(tmp)
      handleSelected(tmp)
    }
  }, [options, defaultValue])

  useEffect(() => {
    if (defaultValue) {
      setValueDropdown(defaultValue)
    }
  }, [defaultValue])

  const getOptions = async () => {
    try {
      const url = `${host.api}/${path}`
      const res = await axios.get(url)

      setOptions(res.data)
      return res
    } catch (error) {
      throw error
    }
  }

  const toggleDropdown = (e) => {
    setIsOpen(!isOpen)
  }

  const onSelectDropdown = (e, data) => {
    e.stopPropagation()

    setValueDropdown(data)
    handleSelected(data)
    setIsOpen(false)
  }

  return (
    <div className='dropdown-with-api'>
      <label>{label}</label>
      {require && <span className='txt-red'>*</span>}
      <div className='container-dropdown-with-api'>
        <div
          id={`dropdown-box-${keyTitle}`}
          className={classnames('dropdown-with-api-box', { error: !!error })}
          onClick={toggleDropdown}
        >
          <span className='text-name'>{valueDropdown[keyTitle]}</span>
          <div
            id={`icon-box-${keyTitle}`}
            className='icon-box'
            onClick={toggleDropdown}
          >
            <FontAwesomeIcon
              id={`icon-angle-${keyTitle}`}
              icon={isOpen ? faAngleUp : faAngleDown}
              className='icon-angle'
              onClick={toggleDropdown}
            />
          </div>
        </div>
        {error && <div className='txt-red mt-1'>{error}</div>}
        <div ref={ref}>
          {isOpen ? (
            <div className='container-options'>
              {options ? (
                options.map((item,  index) => (
                  <div
                    id={`option-box-${index}-${keyTitle}`}
                    key={item[keyValue]}
                    className={classnames('option-box', {
                      active:
                        `${valueDropdown[keyTitle]}${
                          valueDropdown.zipcode || ''
                        }` === `${item[keyTitle]}${item.zipcode || ''}`,
                    })}
                    onClick={(e) => onSelectDropdown(e, item)}
                  >
                    <div id={`text-option-${keyTitle}`} className='text-option'>
                      {item[keyTitle]}
                      {item.zipcode && ` - ${item.zipcode}`}
                    </div>
                  </div>
                ))
              ) : (
                <div className='text-not-found'>ไม่มีข้อมูล</div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

DropdownWithAPI.defaultProps = {
  label: '',
  handleSelected: () => {},
  defaultValue: '',
  error: '',
  options: [
    {
      value: 'name',
      name: 'เรียงจากชื่อ',
    },
    {
      value: 'counter',
      name: 'ใช้งาน',
    },
    {
      value: 'lastused',
      name: 'อัปโหลดล่าสุด',
    },
  ],
}

export default DropdownWithAPI
