import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./editor.css";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { set } from "lodash";

const TextEditorWysiwyg = (props) => {
  const { value, placeholder="", maxLength=1000000 } = props || {};
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [textValue, setTextValue] = useState("")

  useEffect(() => {
    /* value = htmlm string */
    if (value) {
      if (typeof value === "string") {

        // const convertedDraf = value.replaceAll('pt;', 'px;');
        /* convert font size unit from px to vw */
        /* const convertedDraf = value; */

        const contentBlock = htmlToDraft(value);
        if (contentBlock) {
          /* convert html to draft */
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
      } else if (typeof value === "object") {

        setEditorState(value);
        // get plain text
        // console.log(value.getCurrentContent().getPlainText());
      }
    } else {
      EditorState.createEmpty();
    }
  }, [value]);

  const handleEditorStateChange = (editorStateValue) => {
    const blocks = convertToRaw(editorStateValue.getCurrentContent()).blocks;
    const text = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

    /* change font size unit from px t0 pt */
    const drafHtml = draftToHtml(convertToRaw(editorStateValue.getCurrentContent()));
    /* convert font size unit from px to vw */
    /* const convertedDraf = drafHtml; */

        props.onEditorChange({
          editorState: editorStateValue,
          htmlState: drafHtml,
          textState: text,
        });

        setEditorState(editorStateValue);


    };

    /* condition for check max length character */
    const handleBeforeInput = (input) => {
        if (maxLength) {

            const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
            const text = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

            if ( text && text.length >= maxLength ) {
                return "handled";
            }
        }
    };

  return (
    <div className="editor">
      <Editor
        editorState={editorState}
        handleBeforeInput={handleBeforeInput} // THIS IS THE IMPORTANT PART
        onEditorStateChange={handleEditorStateChange}
        placeholder={placeholder}
        stripPastedStyles={true}
        toolbar={{
          // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']
          options: [
            "inline",
            "fontSize",
            "textAlign",
            "history",
            "colorPicker",
            "link",
            "emoji",
          ],
          fontSize: {
            options: [10, 12, 14, 16, 18, 24, 32, 34, 48, 52, 64, 72, 96],
            dropdownClassName: undefined,
          },
          inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough'] },
        }}
      />
    </div>
  );
};

export default TextEditorWysiwyg;

TextEditorWysiwyg.propTypes = {
  value: PropTypes.string,
};

TextEditorWysiwyg.defaultProps = {
  value: "",
  handleSetState: '',
  handleSetText: () => { }
};
