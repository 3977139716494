
import { SET_LIST_SHOP_PROMOTION, 
         SET_KEY_PROMOTION,
         SET_DEFAULT_PROMOTION,
         OPEN_MODAL_MESSAGE_PROMOTION,
         OPEN_MODAL_CONFIRM_PROMOTION } from 'redux/actions/promotion'

const initialState = {
    listPromotion: [],
    listShop: [],
    page: 1,
    limit: 10,
    total: null,
    // shopSelectedIndex: 0
    promotionDetail: null,
    modalConfirm: {
        isOpen: false,
        message: '',
        confirm: null,
        isClose: null
    },
    modalMessage: {
        isOpen: false,
        message: '',
        btnLabel: 'ตกลง',
        title: ''
    },
    shopSelectedIndex: 0
}


export default function (state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case SET_LIST_SHOP_PROMOTION:
            // const { isOpen, message, type, btnLabel, confirm = null } = payload
            return {
                ...state,
                listShop: payload.listShop
            }

        case SET_KEY_PROMOTION:
            return {
                ...state,
                [payload.key]: payload.value
            }

        case SET_DEFAULT_PROMOTION:
            return {
              ...initialState
            }
        case OPEN_MODAL_MESSAGE_PROMOTION: {
            return {
              ...state,
              modalMessage: {
                isOpen: payload.isOpen,
                message: payload.message,
                title: payload.title
              }
            }
          }
        case OPEN_MODAL_CONFIRM_PROMOTION: {
            return {
              ...state,
              modalConfirm: {
                isOpen : payload.isOpen,
                message: payload.message,
                confirm : payload.confirm,
                isClose: payload.isClose
              }
            }
          }
        default:
            return state
    }
}