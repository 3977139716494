import React, { useEffect, useState } from "react";

const LegendViewer = ({  }) => {

  return (
    <div style={{ gap: '5px', display: 'flex', alignItems: 'center', justifyContent: "flex-end", paddingRight:'5%' }}>
      <span style={{ height: '10px', width: '10px', backgroundColor: "#B2D234", borderRadius: '50%', display: 'inline-block' }}></span>
      <span>ยอดขาย</span>
    </div>
  );
};

export default LegendViewer;
