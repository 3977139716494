import React, { Component } from "react";
import { connect } from "react-redux";
import './index.scss'
import host from 'config/host'

import { ButtonUploadVideo, 
  // MediaCard 
} from 'components/output';
import VideoPlayer from 'components/VideoPlayer2'
// import {
//     dispatchGallery,
//     SET_MEDIA_UPLOAD_EVENT,
//     SET_GALLERY_FIELD,
//   } from 'redux/actions/gallery';

// import IconClose from 'images/icons/close-image.svg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

const initialState = {};

class VideoUpload extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  render() {
    const {videoCollection,
           currentShopId, 
           collectionName,
           onClickRemoveMedia,
           limitVideoUpload} = this.props;
    return (
      <React.Fragment>
        <div className="video-container mt-4">
         { videoCollection?.length > 0 && (
           <React.Fragment>
                {videoCollection.map((video,index) => {
                return <div key={index} className='w-100'>
                  {/* <div className='video-item'> */}
                    { video?.pathVod ?
                      <VideoPlayer playsinline={true}
                                   width={'100%'}
                                   height={'60vh'}
                                   muted={false}
                                   fullScreen={false}
                                   source={ host.streamUrl + video?.pathVod} /> 
                      :
                      <video className='video' style={{ width: '100%' ,height: '60vh' }} controls>
                        <source src={host.video + video.path} type='video/mp4' />
                      </video>
                    }
                      {/* <FontAwesomeIcon
                        icon={faPlayCircle}
                        style={{ top: '-1rem', right: 0, left: 0, bottom: 0, margin: 'auto', position: 'absolute', fontSize: '2rem', color: '#e0e0e0', zIndex: 99, }}
                      /> */}
                </div>
            })} 
            </React.Fragment>
            )}

          <ButtonUploadVideo
              width='12em'
              height='12em'
              type={'video'}
              videoCollection={videoCollection}
              shopDetail={{ shopId: currentShopId }}
              disabled={!currentShopId}
              fieldUpload={collectionName}
              onClickRemoveMedia={() => onClickRemoveMedia(0,collectionName)}
              limitVideoUpload={limitVideoUpload}
          />

          { limitVideoUpload > 1 && videoCollection?.length > 0 &&
            <ButtonUploadVideo
                width='12em'
                height='12em'
                type={'video'}
                shopDetail={{ shopId: currentShopId }}
                disabled={!currentShopId}
                fieldUpload={collectionName}
                limitVideoUpload={limitVideoUpload}
            />
          }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(VideoUpload);
