/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './index.scss';

// import {
//     Nav,
//     NavbarBrand,
//     NavbarToggler,
//     NavItem,
//     NavLink,
//     Badge
// } from 'reactstrap';

import HeaderMenu from './HeaderMenu'

import IconHamburger from '../../images/icons/hamburger.svg';
// import LogoOutletCms from '../../images/icons/outlet-live-logo.svg';
// import IconUserProfile from '../../images/icons/user-profile.svg';
import Cookies from 'js-cookie'
// import { ReactComponent as Logo } from 'images/icons/logo-v-avenue.svg'
// import { Body2 } from 'components/FontStyle';
import LogoCMS from 'components/LogoCMS';
import { ReactComponent as IconUserProfile } from 'images/icons/user-profile.svg'
// import { ReactComponent as Logo } from 'images/icons/logo-v-avenue.svg'
// import { H4 } from 'components/FontStyle';


class Header extends Component {

    SidebarToggle = () => {
        document.getElementById('content').classList.toggle('hide-sidebar')
    }

    logout = () => {
        Cookies.remove('user')
        Cookies.remove('token')
        Cookies.remove('role')
        Cookies.remove('refreshToken')
        const keepLocalAnnounce = JSON.parse(localStorage.getItem('announce'))
        localStorage.clear()
        localStorage.setItem('announce', JSON.stringify(keepLocalAnnounce));
        window.location = '/login'
    }

    render() {
        return <section id="header">
            <ul className="wrap-menu">
                <li>
                    <Link to="/">
                        <div className="d-flex flex-row align-items-center">
                            {/* <Logo className="godang-logo-icon" />
                            <H4>CMS</H4> */}
                            <LogoCMS width='85px' />
                        </div>
                        {/* <img className="godang-logo-icon" src={LogoOutletCms} /> */}
                    </Link>
                </li>
                <li>
                    <button className="btn-hamburger" onClick={this.SidebarToggle}>
                        <img src={IconHamburger} />
                    </button>
                </li>
                <li>
                    <HeaderMenu />
                </li>
            </ul>

            <ul className="wrap-menu">
                <li className="icon-profile"><IconUserProfile /></li>
                <li><span className="link-menu cursor-pointer" onClick={this.logout}> ออกจากระบบ </span></li>
            </ul>
        </section>
    }
}

const mapStateToProps = state => ({
    user: state.user,
})

export default connect(mapStateToProps)(Header)

