import React from 'react'
import classNames from 'classnames'
import './style.scss'

const InputText = ({ label, isRequire, title, limit, value, placeholder, isError, errorMsg, onChange, disabled, name, className, classNameTitle, classNameSubtitle, height,type, onkeyDown }) => {

    return (
        <div className={className}>
            { label && 
                <span className={classNameTitle}> {label} <span className="require"></span></span>
            }
            { title && 
                <span className={classNameSubtitle}> {title} </span>
            }
            <input className={classNames('input-form mt-1 mb-1', { 'border-error': (errorMsg?.toString().trim().length > 0 || isError) }, { 'disabled': disabled })}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
                maxLength={limit}
                name={name}
                style={{ height: height }}
                onKeyDown={onkeyDown}
            />
            {errorMsg?.toString().trim().length > 0 && <span className="text-danger">{errorMsg}</span>}
        </div>
    )
}

export default InputText

InputText.defaultProps = {
    classNameTitle: 'caption',
    classNameSubtitle: `caption font-size-10px color-grey`,
    className: 'pt-3',
    label: null,
    isRequire: true,
    title: null,
    limit: 300,
    value: null,
    placeholder: null,
    isError: false,
    errorMsg: '',
    disabled: false,
    onChange: () => { },
    name: null,
    height: 'auto',
    type: 'text',
    onkeyDown: () => {}
}
