/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonUpload } from 'components/output'
import { dispatchGallery } from 'redux/actions/gallery'
import host from '../config/host'
import closeImage from 'images/icons/close-image.svg'
// import '../styles/_createlive.scss'
import brandProvider from 'provider/brandProvider'
import { dispatchNotification, OPEN_TOAST } from 'redux/actions/notification'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert'
import ModalAlert from 'components/modalAlert/ModalAlert'
import classNames from 'classnames'
import { Body1, Body2, Caption, H5, Tooltip } from 'components/FontStyle'
import ModalSelectList from 'components/ModalSelectList'
import { dispatchApp, SET_LOADING } from 'redux/actions/app'
import IconClose from 'images/icons/close.svg'
// import { TextInput } from 'components/input'
// import InputText from 'components/InputText'

import { msgErrRequire } from 'helper/constant'
import { dispatchShop } from 'redux/actions/shop'

const AddBrand = (props) => {
  const dispatch = useDispatch()
  const currentShopId = useSelector((state) => state.shop.currentShop?.id)
  const logoUploadEvent = useSelector((state) => state.gallery.logoUploadEvent)
  const coverUploadEvent = useSelector((state) => state.gallery.coverUploadEvent)
  const modeModalSelectList = "create";

  const { shopDetail = {} } = useSelector((state) => state.shop)
  const { categoryType = {} } = shopDetail ||  {}
  const { categories } = useSelector((state) => state.categories)


  const [errors, setErrors] = useState({})
  const [values, setValues] = useState({
    name: '',
    description: '',
    status: false, //'active' / 'inactive',
  })
  const [type, setType] = useState(null)
  const [modalCategories, setModalCategories] = useState({ isOpen: false, data: null })
  const [listCate, setListCate] = useState(null)
  const [cateSelected, setCateSelected] = useState(null)

  useEffect(() => {
    dispatch(dispatchShop({
      type: 'GET_CURRENT_SHOP_DETAIL'
    }))
  },[])

  useEffect(() => {

    return function cleanup() {
      dispatch(
        dispatchGallery({
          type: 'SET_GALLERY_FIELD',
          payload: {
            key: 'logoUploadEvent',
            value: undefined,
          },
        }),
      )
      dispatch(
        dispatchGallery({
          type: 'SET_GALLERY_FIELD',
          payload: {
            key: 'coverUploadEvent',
            value: undefined,
          },
        }),
      )
      setValues({

      })
    }
  }, [])

  useEffect(() => {
    if (props.location.state) {
      setType('update')
      getBrand(props.location.state.brand.id)
    } else {
      setType('create')
    }
  }, [props.location.state])

  const getBrand = async (brandId) => {
    const res = await brandProvider.getBrandFromBrandId(brandId)
    if (res.data && res.status === 201) {
      setBrand(res.data)
    }
  }

  const handleGetCategories = () => {
    setModalCategories({ isOpen: false, data: props.location.state.brand })
  }
  const setBrand = (data) => {
    setValues({
      name: data.name,
      description: data.description,
      status: data.status === 'show' ? true : false,
      logoPath: data.logoPath?.path,
      logoFile: data.logoPath,
      coverPath: data.coverPath?.path,
      coverFile: data.coverPath
    })
  }

  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      logoFile: logoUploadEvent && logoUploadEvent[0],
      logoPath: (logoUploadEvent && logoUploadEvent[0].path) || null,
    }))
  }, [logoUploadEvent])

  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      coverFile: coverUploadEvent && coverUploadEvent[0],
      coverPath: (coverUploadEvent && coverUploadEvent[0].path) || null,
    }))
  }, [coverUploadEvent])

  const onRemoveLogo = () => {
    dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: {
          key: 'logoUploadEvent',
          value: undefined,
        },
      }),
    )
    setValues({
      ...values,
      logoPath: null,
      logoFile: null
    })
  }

  const onRemoveCover = () => {
    dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: {
          key: 'coverUploadEvent',
          value: undefined,
        },
      }),
    )
    setValues({
      ...values,
      coverPath: null,
      coverFile: null
    })
  }

  const setValuesForm = (data, key) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: data,
    }))
  }

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    const sanitizedInput = inputText.replace(/[^a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g, '');
    setValuesForm(sanitizedInput, 'name')
  };

  const scrollToPosition = async (id, number) => {
    const ele = await document?.getElementById(id);
    const blockStart = ele?.getBoundingClientRect().top + window.pageYOffset - number;
    window.scrollTo({ top: blockStart, behavior: 'smooth' });
  }

  const validateData = () => {
    let validate = true
    let tmpError = {}
    const exp = /^[a-zA-Z0-9!@#\$%\^\&*\ )\(+=._-]{1,30}$/g
    const name = exp.test(values.name)
    // if (!name) {
    //   validate = false
    //   tmpError = {
    //     ...tmpError,
    //     name: 'กรุณากรอกชื่อแบรนด์เป็นภาษาอังกฤษ ตัวเลข ตัวอักษรพิเศษเท่านั้น'
    //   }
    // }

    let scrollId = ""

    if (!values.logoFile) {
      validate = false
      tmpError = {
        ...tmpError,
        logo: 'กรุณาใส่รูป logo'
      }

      scrollId = 'section-logo'
    }

    if (!values.name) {
      validate = false
      tmpError = {
        ...tmpError,
        name: 'กรุณากรอกชื่อแบรนด์'
      }

      if(scrollId == "") {
        scrollId = 'section-brand-name'
      }
    }

    if (!values.description || values.description.length > 5000) {
      validate = false
      tmpError = {
        ...tmpError,
        description: 'กรุณากรอกรายละเอียดไม่เกิน 5,000 ตัว'
      }

      if(scrollId == "") {
        scrollId = 'section-description'
      }
    }

    // if (!values.coverFile) {
    //   validate = false
    //   tmpError = {
    //     ...tmpError,
    //     cover: 'กรุณาใส่รูป cover'
    //   }
    // }
   

    if(cateSelected.cateSelected.length === 0){
      validate = false
      tmpError = {
        ...tmpError,
        categories: 'กรุณาเลือก category'
      }

      if(scrollId == "") {
        scrollId = 'section-category'
      }
    }

    
    if(scrollId) {
    scrollToPosition(scrollId, 50)
    dispatch(
      dispatchModalAlert({
        type: OPEN_MODAL_ALERT,
        payload: {
          isOpen: true,
          message: msgErrRequire,
          type: 'alert',
        },
      }),
    )
    }

   

    setErrors(tmpError)
    return validate
  }

  const onSubmit = async () => {
    dispatch(dispatchApp({type:SET_LOADING,payload:{loading: true}}))
    const { coverFile, coverPath, logoFile, logoPath, ...param } = values
    const validate = validateData()
    const data = {
      ...param,
      shopId: currentShopId,
      logoId: values.logoFile?.id,
      status: values.status ? 'show' : 'hide',
      coverId: coverFile ? values.coverFile?.id : 0
    }

    if (validate) {
      // call api create brand
      switch (type) {
        case 'update':
          try {
            const res = await brandProvider.updateBrand(props.location.state.brand.id, data)
            if (res.data.code === 422 && res.data.message === 'BRAND_ALREADY') {
              dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'มีชื่อนี้ในระบบแล้ว', type: 'alert' } }))
            }
            if (res.status === 200 && res.data) {

              let categories = []
              let shopCategories = []
              for (const [keyLv1, lv1] of Object.entries(cateSelected?.childTree)) {
                for (const [keyLv2, valueLv3] of Object.entries(lv1)) {
                  if (valueLv3.length === 0) {
                    categories.push(parseInt(keyLv2))
                    shopCategories.push(parseInt(keyLv2))
                  }
                  categories.push(...valueLv3)
                  shopCategories.push(...valueLv3)
                }
              }
              
              // const param = {
              //   brandId: res.data.id,
              //   categories
              // }
              const categoriesKey = categoryType == 'custom' ? 'shopCategories' : 'categories'
              const categoriesRes = categoryType == 'custom' ? shopCategories : categories
              const param = {
                brandId: res.data.id,
                [categoriesKey]:categoriesRes,
              }

              try {
                const res = await brandProvider.updateBrandCategory(param,shopDetail)
                if (res.status === 200) {
                  props.isClose();
                  dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } }))
                }
                dispatch(dispatchApp({type:SET_LOADING,payload:{loading: false}}))
              } catch (error) {
                // console.log(`error.response`, error.response)
                const errorStatus = error?.response?.status || error?.status 
                if (errorStatus === 400) {
                  dispatch(dispatchNotification({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: `${error?.response?.data.message}`, type: 'alert' } }))
                } else {
                  dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการผิดพลาด!`, type: 'danger' } }))
                }
                dispatch(dispatchApp({type:SET_LOADING,payload:{loading: false}}))
              }

              await dispatch(
                dispatchGallery({
                  type: 'SET_GALLERY_FIELD',
                  payload: {
                    key: 'logoUploadEvent',
                    value: null,
                  },
                }),
              )
              await dispatch(
                dispatchGallery({
                  type: 'SET_GALLERY_FIELD',
                  payload: {
                    key: 'coverUploadEvent',
                    value: null,
                  },
                }),
              )
              dispatch(dispatch({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ!`, type: 'success' } }))
              props.history.push('/manage/shop/shopdetail')
            }
          } catch (error) {
            if (error?.response?.data?.code === 400 && error?.response?.data?.message === 'VALIDATION_ERROR') {
              dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'กรุณากรอกข้อมูลให้ครบ', type: 'alert' } }))
              dispatch(dispatchApp({type:SET_LOADING,payload:{loading: false}}))
            }
            throw error
          }
          break;

        default:
          try {
            const res = await brandProvider.createBrand(data)
            if (res.data?.code === 422 && res?.data?.message === 'BRAND_ALREADY') {
              dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'มีชื่อนี้ในระบบแล้ว', type: 'alert' } }))
              dispatch(dispatchApp({type:SET_LOADING,payload:{loading: false}}))
            }
            if (res.status === 201 && res.data.id) {
              let categories = []
              let shopCategories = []
              for (const [keyLv1, lv1] of Object.entries(cateSelected?.childTree)) {
                let child = {}
                let shopChild = {}
                const subChild = []
                const shopSubChild = []
                child.categoryId = parseInt(keyLv1)
                shopChild.shopCategoryId = parseInt(keyLv1)
                for (const [keyLv2, valueLv3] of Object.entries(lv1)) {
                  subChild.push({
                    categoryId: parseInt(keyLv2),
                    subChild: valueLv3
                  })
                  shopSubChild.push({
                    shopCategoryId: parseInt(keyLv2),
                    subChild: valueLv3
                  })
                }
                categories.push({ ...child, child: subChild })
                shopCategories.push({ ...shopChild, child: shopSubChild })
              }
              // const param = {
              //   brandId: res.data.id,
              //   categories
              // }
              const categoriesKey = categoryType == 'custom' ? 'shopCategories' : 'categories'
              const categoriesRes = categoryType == 'custom' ? shopCategories : categories
              const param = {
                brandId: res.data.id,
                [categoriesKey]: categoriesRes,
              }
              try {
                const res = await brandProvider.createBrandCategory(param,shopDetail)
                if (res) {
                  props.isClose()
                  dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } }))
                  dispatch(dispatchApp({type:SET_LOADING,payload:{loading: false}}))
                }
              } catch (error) {
                dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการผิดพลาด!`, type: 'danger' } }))
                dispatch(dispatchApp({type:SET_LOADING,payload:{loading: false}}))
              }
              await dispatch(
                dispatchGallery({
                  type: 'SET_GALLERY_FIELD',
                  payload: {
                    key: 'logoUploadEvent',
                    value: null,
                  },
                }),
              )
              await dispatch(
                dispatchGallery({
                  type: 'SET_GALLERY_FIELD',
                  payload: {
                    key: 'coverUploadEvent',
                    value: null,
                  },
                }),
              )
              dispatch(dispatch({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ!`, type: 'success' } }))
              props.history.push('/manage/shop/shopdetail')
            }
          } catch (error) {
            if (error?.response?.data?.code === 400 && error.response.data.message === 'VALIDATION_ERROR') {
              dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'กรุณากรอกข้อมูลให้ครบ', type: 'alert' } }))
            }
            dispatch(dispatchApp({type:SET_LOADING,payload:{loading: false}}))
            throw error
          }
          break;
      }
    }
    dispatch(dispatchApp({type:SET_LOADING,payload:{loading: false}}))
  }

  const onCancel = () => {
    props.history.push('/manage/shop/shopdetail')
  }

  const handleGetCate = (cate) => {
    setListCate(cate)
  }
  return (
    <div className='col-12'>
      <div className='col-12'>
        <H5>เพิ่มแบรนด์สินค้า</H5>
      </div>
      <div className='image-live-container'>

        <div className='upload col-xl-10 m-auto p-3 pb-4'>
          <h3>
            ภาพหน้าปกแบรนด์สินค้า
            {/* <span style={{ color: 'red' }}>* </span> */}
          </h3>

          <Caption className='upload-detail'>
            ขนาดรูปแนะนำคือ 970x326 พิกเซล ขนาดไฟล์สูงสุดไม่เกิน 300 kb
          </Caption>

          {values.coverPath ? (
            <div className='d-flex'>
              <span className='item-product-container'>
                <img
                  src={host.image + values.coverPath}
                  alt=''
                  className='image cover'
                  style={{ width: '100%' }}
                />
                <div className='middle' onClick={onRemoveCover}>
                  <img
                    alt=''
                    src={closeImage}
                    className='position-absolute remove-image-product'
                  />
                </div>
              </span>
            </div>
          ) : (
            <ButtonUpload
              width='100%'
              height='300px'
              type='image'
              shopDetail={{ shopId: currentShopId }}
              disabled={!currentShopId}
              fieldUpload='coverUploadEvent'
              limitImageUpload={1}
            />
          )}
          <span className='error-message'>
            {errors?.cover ? `* ${errors.cover}` : ''}
          </span>
        </div>


        <div className='upload col-xl-10 m-auto p-3 pb-4' id="section-logo">
          <Body1>
            โลโก้ <span style={{ color: 'red' }}>* </span>
          </Body1>

          <div className='mt-2'>
            <Caption className='Captionload-detail'>
              รองรับสัดส่วนภาพ 1:1 เท่านั้น (สี่เหลี่ยมจัตุรัส) ขนาดรูปแนะนำคือ
              300x300 พิกเซล ขนาดไฟล์สูงสุด 10 MB
            </Caption>
          </div>

          {values.logoPath ? (
            <div className='d-flex'>
              <span className='item-product-container'>
                <img
                  src={host.image + values.logoPath}
                  alt=''
                  className='image'
                  style={{ width: '100%', objectFit: 'cover' }}
                />
                <div className='middle' onClick={onRemoveLogo}>
                  <img
                    alt=''
                    src={closeImage}
                    className='position-absolute remove-image-product'
                  />
                </div>
              </span>
            </div>
          ) : (
            <ButtonUpload
              width='12em'
              height='12em'
              type='image'
              shopDetail={{ shopId: currentShopId }}
              disabled={!currentShopId}
              fieldUpload='logoUploadEvent'
              limitImageUpload={1}
            />
          )}
          <span className='error-message'>
            {errors?.logo ? '* กรุณาใส่ภาพโลโก้' : ''}
          </span>
        </div>
        <div className='upload col-xl-10 m-auto p-3 pb-4' id="section-brand-name">
          <Body2 className="">
            ชื่อแบรนด์สินค้า ภาษาอังกฤษ
            <span style={{ color: 'red' }}>* </span>
            <Tooltip className='color-default-action-icon'> (จำกัด 30 ตัวอักษร)</Tooltip>
          </Body2>
          <br />
          <input
            type='text'
            className={`font-caption form-control ${errors?.name ? 'error-field' : ''}`}
            name='name'
            value={values.name}
            onChange={(e) => handleInputChange(e)}
            placeholder='กรอกชื่อแบรนด์สินค้า'
            style={{ fontSize: '20px' }}
          />
          <span className='error-message'>
            {errors?.name ? `* ${errors.name}` : ''}
          </span>
        </div>
        <div className='upload col-xl-10 m-auto p-3 pb-4' id="section-description">
          <Body2>
            รายละเอียด
            <span style={{ color: 'red' }}>* </span>
            <Tooltip className='color-default-action-icon'> (จำกัด 5,000 ตัวอักษร)</Tooltip>
          </Body2>
          <br />
          <textarea
            type='text'
            name='description'
            className={classNames('font-caption form-control', { 'error-field': errors.description })}
            value={values.description}
            style={{ height: '10em', resize: 'none' }}
            onChange={(e) => setValuesForm(e.target.value, 'description')}
          />
          <span className='error-message'>
            {errors?.description ? '* กรุณากรอกรายละเอียด' : ''}
          </span>
        </div>
      </div>

      <div className='image-live-container' id="section-category">
        <div className='col-xl-10 m-auto p-3 pb-4 row d-flex align-items-center'>
          <Body2 className='require'>หมวดหมู่สินค้า</Body2>
          <button className="btn btn-confirm ml-5" onClick={() => type == "update" ? setModalCategories({ isOpen: true, data: props.location.state.brand }) : setModalCategories({ isOpen: true, data: null })}>
            <Body2>เลือกหมวดหมู่</Body2>
          </button>
          <span className='error-message'>
            {errors?.categories ? '* กรุณาเลือกหมวดหมู่' : ''}
          </span>
        </div>
        {cateSelected?.cateSelected?.length > 0 &&
          <div className='col-xl-10 m-auto p-3 pb-4 selected-list-cat'>
            {
              cateSelected?.cateSelected?.map((item, idx) =>
                <div className='item-selected' key={`cate${idx}`}>
                  <span className='text-item-selected'>
                    {item.title}
                  </span>
                  <div>
                    <img alt='' className='ml-3 cursor-pointer' src={IconClose}
                      onClick={() => cateSelected?.onCheckBeforeRemoveCate(item)}
                      style={{ width: '15px' }} />
                  </div>
                </div>
              )
            }
          </div>
        }
        
       

      </div>


      <div className='image-live-container'>
        <div className='col-xl-10 m-auto p-3 pb-4 row'>
          <Body2>สถานะการใช้งาน</Body2>
          <div
            className='ml-5 toggle-green d-flex align-items-center gap-1'
          >
            <label className='switch'>
              <input
                type='checkbox'
                name='status'
                checked={values.status}
                defaultChecked={false}
                onChange={(e) => setValuesForm(e.target.checked, 'status')}
              />
              <span className='slider round'></span>
            </label>
            <span>{values.status ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</span>
          </div>
        </div>
      </div>
      <div className='image-live-container'>
        <div className='d-flex justify-content-center'>
          <span className='m-4'>
            <button className='btn-cancel' onClick={onCancel}>
              ยกเลิก
            </button>
          </span>
          <span className='m-4'>
            <button
              className='btn-submit'
              data-toggle='modal'
              data-target='#exampleModal'
              onClick={onSubmit}
            >
              บันทึก
            </button>
          </span>
        </div>
      </div>
      <ModalAlert />
      <ModalSelectList
        modal={modalCategories}
        isClose={() => setModalCategories({ isOpen: false, data: null })}
        type='brand'
        mode={type}
        handleGetCate={(cate) => handleGetCate(cate)}
        setCateSelected={(cate) => setCateSelected(cate)}
        isUpdateBrandId={type === 'update' ? props.location.state.brand.id : false}
      />
    </div>
  )
}

export default AddBrand
