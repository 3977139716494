import React, { useState, useEffect } from 'react'
import PeriodTimeInput from 'components/PeriodTimeInput'
import SelectInputWithImg from 'components/SelectInputWithImg'
import XlsxCreate from 'components/XlsxCreate'
import { ReactComponent as IconDownload } from 'images/icons/IconDownload.svg';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchDashboard, SET_KEY_DASHBOARD, SET_SHOP_DASHBOARD } from 'redux/actions/dashboard';
import moment from 'moment';
// import shopProvider from 'provider/shopProvider';
// import { SET_LOADING } from 'redux/actions/app';


const DashBoardHeaderSelect = (props) => {

    const dispatch = useDispatch()
    const dashboardState = useSelector(state => state.dashboard)
    const { listShop, rangeDate } = dashboardState
    // const api_shops = new shopProvider()
    const { dataReport, headerExcel, type } = props


    // const [listShop, setlistShop] = useState([])
    const [excelData, setExcelData] = useState(null)
    const [headerExcelData, setHeaderExcelData] = useState(null)
    // const [rangeDate, setRangeDate] = useState(optionsRangeDate.REAL_TIME);

    useEffect(() => {
        setHeaderExcelData(headerExcel)
    }, [headerExcel])




    // useEffect(() => {
        // const excelArr = []
        // if (dataReport && type === 'dashboard') {
        //     dataReport.revenueOfPeriod.map((data, index) => {
        //         excelArr.push({ time: moment(data.timestamp).format('DD-MM-YYYY HH:mm'), totalRevenue: data.totalRevenue.toLocaleString() })
        //     })
        //     excelArr.push({ time: 'ทั้งหมด', totalRevenue: dataReport.totalRevenue.toLocaleString() })
        //     setExcelData(excelArr)
        // }
        // if (dataReport && (type === 'sku-in-cart' || type === 'sku-wait-payment')) {
        //     if (dataReport.data.length) {
        //         dataReport.data.map((data, index) => {
        //             excelArr.push({ index: (index + 1).toLocaleString(), productName: data.name, sku: data.sku, quantity: data.quantity.toLocaleString() })
        //         })
        //         excelArr.push({ index: null, productName: null, sku: 'ทั้งหมด', quantity: dataReport.countQuantity.toLocaleString() })
        //         setExcelData(excelArr)
        //     } else {
        //         setExcelData([{ index: '', productName: '', sku: '', quantity: '' }])
        //     }

        // }
        // if (dataReport && (type === 'order')) {
        //     if (dataReport.data.length) {
        //         dataReport.data.map((data, index) => {
        //             excelArr.push({ index: (index + 1).toLocaleString(), orderNo: data.orderNo, shopName: data.shopName, customerName: data.customer.name, quantity: data.quantity.toLocaleString(), priceTotal: data.priceTotal.toLocaleString() })
        //         })
        //         excelArr.push({ index: null, orderNo: null, shopName: null, customerName: 'ทั้งหมด', quantity: dataReport.quantityTotal.toLocaleString(), priceTotal: dataReport.priceTotal.toLocaleString() })
        //         setExcelData(excelArr)
        //     } else {
        //         setExcelData([{ index: '', orderNo: '', shopName: '', customerName: '', quantity: '', priceTotal: '' }])
        //     }
        //     // ["ลำดับ", "หมายเลขออเดอร์", "ชื่อร้านค้า", "ชื่อลูกค้า", "จำนวนสินค้า", "ยอดเงิน"]

        // }
        // return () => {
        // }
    // }, [dataReport])



    const onChangeShopSelected = (value) => {
        dispatch(dispatchDashboard({ type: SET_SHOP_DASHBOARD, payload: { shopSelected: value } }))
    }

    const onChangeDate = (date) => {
        if(props?.setPage){
            props && props.setPage(1)
        }
        dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(date.period.from).utc(0).format() } }))
        dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(date.period.to).utc(0).format() } }))
        dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: date } }))
    }

    const showTypeFileGenerate = () => {
        switch (type) {
            case 'dashboard':
                return 'หน้ารวม'
            case 'order':
                return 'สรุปรายงานคำสั่งซื้อ'
            case 'sku-in-cart':
                return 'สินค้าในตะกร้า'
            case 'sku-wait-payment':
                return 'สินค้ารอชำระเงิน'
            case 'top-view':
                return 'สรุปรายงานยอดวิว'

            default:
                break;
        }
    }
    

    return (
        <section className="filter-box">
            <div className='filter-container' style={{ width: '84%' }}>
                <div className='d-flex flex-row align-items-center' style={{ width: '40%' }}>
                    <div style={{ width: '14%' }}>ร้านค้า : </div>
                    <SelectInputWithImg
                        options={listShop}
                        value={listShop.length > 0 && dashboardState?.shopSelected}
                        iconClearSearch={false}
                        onChange={(value) => onChangeShopSelected(value)}
                    />
                </div>
                <div className='d-flex flex-row align-items-center period-input'>
                    <div style={{ width: 'max-content' }}>ระยะเวลาของข้อมูล :</div>
                    {/* <SelectInput
                    options={[{ label: 'sda', value: 'asdasd' }, { label: 'sda', value: 'asdasd' }, { label: 'sda', value: 'asdasd' }]}
                /> */}
                    <div style={{ marginLeft: 5, width: 'max-content' }} >
                        <PeriodTimeInput
                            onPeriodDateSelected={(date) => onChangeDate(date)}
                            disabled={type === 'sku-in-cart' || type === 'sku-wait-payment'}
                            optionDate={rangeDate?.type}
                            allowTodayDate={false}
                            optionsRangeDate={(type === 'sku-in-cart' || type === 'sku-wait-payment') ? null : { startDate: rangeDate?.period?.from, endDate: rangeDate?.period?.to }}
                        />
                    </div>
                </div>
            </div>
            {type !== 'dashboard' && <XlsxCreate
                // data={excelData}
                // header={headerExcel}
                fileName={`${showTypeFileGenerate()}_${listShop[dashboardState?.shopSelected]?.value?.name ? listShop[dashboardState?.shopSelected]?.value?.name : 'ร้านทั้งหมด'}_${moment(dashboardState.fromDate).format('DDMMYYYY')}-${moment(dashboardState.toDate).format('DDMMYYYY')}`}
                handleFetchData={() => props.fetchDataExcel()}
                // fileName={`${detail?.listAllShop && detail?.listAllShop[detail?.shopSelectedIndex]?.label}-${detail?.status === 'settled' ? 'รอโอนเงิน' : detail?.status === 'transferred' ? 'โอนเงินให้ร้านค้าแล้ว' : 'รายการทั้งหมด'}-${displayDateThShortYear(new Date(), 'DD-MM-YY')}`}
                // fileName={'asd'}
                headerA1={type === 'top-view' ? 'สรุปรายงานยอดวิว' : type === 'order' ? 'สรุปรายงานการสั่งซื้อ' : type === 'sku-in-cart' ? 'จำนวนสินค้าในตะกร้า' : type === 'sku-wait-payment' ? 'สินค้าที่รอชำระเงิน' : ''}
                numberFormat={type === 'top-view' ? '#,#' : '0,0.00'}
            >
                <button className='btn-cancel' style={{ padding: '0.5rem 0.3rem' }}> <IconDownload className='icon-download' /> ดาวน์โหลดข้อมูล </button>
            </XlsxCreate> 
            }
            

            {/* <XlsxCreate
                data={new Array(1000).fill({ time: 10000, totalRevenue: 10000,asd: 'asldkasl;dkal;skd',asdasd:'asldasd;alkd',asd1: 'asldkasl;dkal;skd',as1dasd:'asldasd;alkd',as2d: 'asldkasl;dkal;skd',asd2asd:'asldasd;alkd',as3d: 'asldkasl;dkal;skd',asda3sd:'asldasd;alkd',a4sd: 'asldkasl;dkal;skd',as4dasd:'asldasd;alkd' })}
                header={['headerExcel','ทดสอบ','ทดสอบ','ทดสอบ','ทดสอบ','ทดสอบ','ทดสอบ','ทดสอบ','ทดสอบ','ทดสอบ','ทดสอบ']}
                fileName={`${showTypeFileGenerate()}_${listShop[dashboardState?.shopSelected]?.value?.name ? listShop[dashboardState?.shopSelected]?.value?.name : 'ร้านทั้งหมด'}_${moment(dashboardState.fromDate).format('DDMMYYYY')}-${moment(dashboardState.toDate).format('DDMMYYYY')}`}
            >
                <button className='btn-cancel' style={{padding: '0.5rem 0.3rem'}}> <IconDownload className='icon-download' /> ดาวน์โหลดข้อมูลเทส </button>
            </XlsxCreate> */}


        </section>
    )
}

export default DashBoardHeaderSelect
