import React, { useState, useEffect } from 'react';
// import { ReactComponent as IconDownload } from 'images/icons/IconDownload.svg';
// import SelectInput from 'components/SelectInput';
import 'styles/_orderReport.scss';
import { Body1, Caption, H6, SubTitle1, SubTitle2 } from 'components/FontStyle';
import PaginationComponent from 'components/pagination';
import { ReactComponent as IconNoItem } from 'images/icons/no-item.svg';
import { dispatchDashboard, SET_KEY_DASHBOARD, SET_SHOP_DASHBOARD } from 'redux/actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
// import SelectInputWithImg from 'components/SelectInputWithImg';
import dashboardProvider from 'provider/dashboardProvider';
import { SET_LOADING } from 'redux/actions/app';
// import host from 'config/host';
import DashBoardHeaderSelect from 'components/output/DashBoardHeaderSelect';
import moment from 'moment';
import { options, generatePeriodDate } from 'components/PeriodTimeInput/dataUtil';
import shopProvider from 'provider/shopProvider';

const OrderReport = ({ location }) => {
    const dispatch = useDispatch()
    const dashboardState = useSelector(state => state.dashboard)
    const { shopSelected, fromDate, toDate, listShop, rangeDate } = dashboardState
    const { state: { type } } = location
    // const [listAllShop, setListAllShop] = useState([])
    const [listOrder, setListOrder] = useState(null)
    const [listOrderAll, setListOrderAll] = useState(null)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(null)
    const [headerExcel, setHeaderExcel] = useState(null)
    const api_shops = new shopProvider()

    // useEffect(() => {
    //     dispatch({ type: SET_LOADING, payload: { loading: true } })
    //     getListShop()
    //     // return () => {
    //     //     // cleanup
    //     // }
    //     // }
    //     dispatch({ type: SET_LOADING, payload: { loading: false } })
    // }, [listShop])


    // useEffect(() => {
    //     const initialRealTime = generatePeriodDate(options.REAL_TIME, moment())
    //     dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: initialRealTime } }))
    //     dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initialRealTime.period.from).utc(0).format() }}))
    //     dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value:  moment(initialRealTime.period.to).utc(0).format() }}))
    //   return () => {
    //     dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: null } }))
    //     dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: null }}))
    //     dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value:  null }}))
    //   };
    // }, []);

    // useEffect(() => {
    //   console.log('rangeDate', rangeDate)

    //   return () => {
    //     // second
    //   }
    // }, [rangeDate])


    useEffect(() => {
        if (rangeDate) {
            const initDate = generatePeriodDate(rangeDate?.type, { startDate: rangeDate?.period?.from, endDate: rangeDate?.period?.to })
            if (initDate) {
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initDate.period.from).utc(0).format() } }))
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initDate.period.to).utc(0).format() } }))
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: rangeDate } }))
            }
        } else {
            const initialRealTime = generatePeriodDate(options.REAL_TIME, { startDate: moment(), endDate: moment() })
            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: initialRealTime } }))
            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initialRealTime.period.from).utc(0).format() } }))
            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initialRealTime.period.to).utc(0).format() } }))
        }
        return () => {
            if (rangeDate) {
                const initDate = generatePeriodDate(rangeDate?.type, { startDate: rangeDate?.period?.from, endDate: rangeDate?.period?.to })
                if (initDate) {
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initDate.period.from).utc(0).format() } }))
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initDate.period.to).utc(0).format() } }))
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: rangeDate } }))
                }
            }
        }
    }, [])




    useEffect(() => {
        switch (type) {
            case 'order':
                setHeaderExcel(["ลำดับ", "หมายเลขออเดอร์", "ชื่อร้านค้า", "ชื่อลูกค้า", "จำนวนสินค้า", "ยอดเงิน"])
                break;
            case 'sku-in-cart':
            case 'sku-wait-payment':
                setHeaderExcel(["ลำดับ", "ข้อมูลสินค้า", "ตัวเลือกสินค้า", "จำนวน", "ราคา"])
            default:
                break;
        }
        return () => {
        }
    }, [type])

    useEffect(() => {
        (async function () {
            dispatch({ type: SET_LOADING, payload: { loading: true } })
            if (type === 'sku-in-cart' || type === 'sku-wait-payment') {
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment().startOf('day').utc(0).format() } }))
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment().utc(0).format() } }))
                // await getListOrder()
            } else {
                // if (rangeDate?.period?.from) {
                //     dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(rangeDate.period.from).utc(0).format() } }))
                //     dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(rangeDate.period.to).utc(0).format() } }))
                // } else {
                //     dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment().startOf('day').utc(0).format() } }))
                //     dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment().utc(0).format() } }))
                // }
                // await getListOrder()
            }


            if (toDate || fromDate || rangeDate) {
                await getListOrder()
            }

            if (listShop.length !== 0) {
                dispatch({ type: SET_LOADING, payload: { loading: false } })
            }
        })()
        return () => {
        }
    }, [type, limit, page, shopSelected, rangeDate])

    useEffect(() => {
        (async function () {
            if (listShop.length === 0) {
                await getListShop()
            }
        })()
    }, [])


    // useEffect(() => {
    //     (async function () {
    //         if (toDate) {
    //             dispatch({ type: SET_LOADING, payload: { loading: true } })
    //             await getListOrder()
    //             dispatch({ type: SET_LOADING, payload: { loading: false } })
    //         }
    //     })()
    //     return () => {
    //     }
    // }, [rangeDate])

    useEffect(() => {
        (async function () {
            // dispatch({ type: SET_LOADING, payload: { loading: true } })
            if (toDate)
                setPage(1)
            // await getListOrder()
            // dispatch({ type: SET_LOADING, payload: { loading: false } })
        })()
        return () => {
        }
    }, [shopSelected])

    const getListShop = async () => {
        dispatch({ type: SET_LOADING, payload: { loading: true } })
        const res = await api_shops.getAllShops()
        if (res) {
            const arr = res.shop.map((shop) => {
                return { label: shop.name, value: shop, img: shop.logoPath }
            })

            arr.unshift(
                {
                    label: 'ร้านทั้งหมด',
                    value: '',
                    img: null
                })
            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'listShop', value: arr } }))
            // setlistShop(arr)
        }
        dispatch({ type: SET_LOADING, payload: { loading: false } })
    }


    const showOrder = (status, data) => {
        switch (status) {
            case 'order':
                return <section>
                    <div className='table-order-report'>
                        <div>ลำดับ</div>
                        <div>หมายเลขออเดอร์</div>
                        <div>ชื่อร้านค้า</div>
                        <div>ชื่อลูกค้า</div>
                        <div>จำนวนสินค้า</div>
                        <div>ยอดเงิน</div>
                    </div>
                    {
                        data?.data?.length === 0 ?
                            <div className='d-flex justify-content-center align-items-center flex-column p-4 mt-4 mb-4'>
                                <IconNoItem />
                                <span>ไม่มีรายการ</span>
                            </div> :
                            data?.data.map((order, index) => (
                                <div className='table-order-report-content' key={`${order?.orderNo + index}`}>
                                    <div><SubTitle2>{(page - 1) * limit + index + 1}</SubTitle2></div>
                                    <div><SubTitle2>{order?.orderNo}</SubTitle2></div>
                                    <div><SubTitle2>{order?.shopName}</SubTitle2></div>
                                    <div><SubTitle2>{order?.customer?.name}</SubTitle2></div>
                                    <div><SubTitle2>{order?.quantity}</SubTitle2></div>
                                    <div><SubTitle2>฿ {order?.priceTotal?.toLocaleString()}</SubTitle2></div>
                                </div>
                            ))
                    }
                </section>
                break;

            case 'sku-in-cart':
            case 'sku-wait-payment':
                return <section>
                    <div className='table-product-cart'>
                        <div>ลำดับ</div>
                        <div className='text-left'>ข้อมูลสินค้า</div>
                        <div>จำนวน</div>
                        <div>ราคา</div>
                    </div>


                    {data?.data?.length === 0 ?
                        <div className='d-flex justify-content-center align-items-center flex-column p-4 mt-4 mb-4'>
                            <IconNoItem />
                            <span>ไม่มีรายการ</span>
                        </div> :
                        data?.data.map((order, index) => (
                            <div className='table-product-cart-content' key={order?.order?.name + index}>
                                <div><SubTitle1>{(page - 1) * limit + index + 1}</SubTitle1></div>
                                <div className='justify-content-start'>
                                    <Caption className='bg-white'>
                                        <span className='d-flex flex-row align-items-center show-item'>
                                            <img src={order.imagePath?.host + order.imagePath?.path} width={'40px'} />
                                            <span className='d-flex flex-column align-items-start'>
                                                <Caption className='text-left'>{order?.name}</Caption>
                                                <span>{order?.sku}</span>
                                                <span>เลขอ้างอิง SKU: {order?.sellerSKU || '-'}</span>
                                            </span>

                                        </span>
                                    </Caption>
                                </div>
                                <div><SubTitle1 className='font-weight-bold'>{order?.quantity}</SubTitle1></div>
                                <div><SubTitle1 className='font-weight-bold'>{order?.priceTotal.toLocaleString()}</SubTitle1></div>
                            </div>
                        ))
                    }
                </section>

            default:
                return <div></div>
                break;
        }
    }

    // const getListShop = () => {
    //     if (listShop.length > 0) {
    //         const arr = listShop.map(shop => {
    //             return { label: shop.label, value: shop.value, img: shop.img }
    //         })
    //         // arr.unshift({label:'ร้านทั้งหมด',value: ''})
    //         setListAllShop(arr)
    //     }
    // }

    const getListOrder = async () => {
        if (type) {
            const params = { fromDate, toDate, limit: limit, page: page }
            const paramsListAll = { fromDate, toDate, page: 1 }
            if (type === 'sku-in-cart' || type === 'sku-wait-payment') {
                delete params.fromDate
                delete paramsListAll.fromDate
                params.toDate = moment().utc(0).format()
                paramsListAll.toDate = moment().utc(0).format()
            }
            if (shopSelected) {
                const shopId = listShop[shopSelected]?.value?.id
                if (shopId) {
                    const shopId = listShop[shopSelected]?.value?.id
                    params.shopId = shopId
                    paramsListAll.shopId = shopId
                }
            }

            const listOrder = await dashboardProvider.getOrderDashboardReport(type, params)
            // if (listOrder) {
            //     paramsListAll.limit = listOrder.data?.count
            //     if (listOrder.data?.count) {
            //         const listOrderAll = await dashboardProvider.getOrderDashboardReport(type, paramsListAll)
            //         setListOrderAll(listOrderAll.data)
            //     } else {
            //         setListOrderAll({ data: [] })
            //     }
            // }
            setListOrder(listOrder?.data)
            setTotal(listOrder.data?.count)
        }
    }

    const onChangeShopSelected = (value) => {
        dispatch(dispatchDashboard({ type: SET_SHOP_DASHBOARD, payload: { shopSelected: value } }))
    }

    const onShowSizeChange = (page, limit) => {
        setLimit(limit)
    }

    const onChangePage = (page) => {
        setPage(page)
    }

    const fetchDataExcel = async () => {
        const excelArr = []

        if (type) {
            // const params = { fromDate, toDate, limit: limit, page: page }
            const paramsListAll = { fromDate, toDate, page: 1 }
            if (type === 'sku-in-cart' || type === 'sku-wait-payment') {
                // delete params.fromDate
                delete paramsListAll.fromDate
                // params.toDate = moment().utc(0).format()
                paramsListAll.toDate = moment().utc(0).format()
            }
            if (shopSelected) {
                const shopId = listShop[shopSelected]?.value?.id
                if (shopId) {
                    const shopId = listShop[shopSelected]?.value?.id
                    // params.shopId = shopId
                    paramsListAll.shopId = shopId
                }
            }
            if (listOrder) {
                paramsListAll.limit = listOrder?.count
                if (listOrder.count) {
                    const listOrderAll = await dashboardProvider.getOrderDashboardReport(type, paramsListAll)
                    setListOrderAll(listOrderAll.data.data)
                    if (listOrderAll.data && type === 'dashboard') {
                        listOrderAll.data.revenueOfPeriod.map((data, index) => {
                            excelArr.push({ time: moment(data.timestamp).format('DD-MM-YYYY HH:mm'), totalRevenue: data.totalRevenue.toLocaleString() })
                        })
                        excelArr.push({ time: 'ทั้งหมด', totalRevenue: listOrderAll?.data?.totalRevenue.toLocaleString() })
                        // setExcelData(excelArr)
                    }
                    if (listOrderAll.data && (type === 'sku-in-cart' || type === 'sku-wait-payment')) {
                        if (listOrderAll?.data?.data?.length) {
                            listOrderAll.data.data.map((data, index) => {
                                excelArr.push({ index: (index + 1).toLocaleString(), productName: data.name, sku: data.sku, quantity: data.quantity.toLocaleString(), price: data.priceTotal })
                            })
                            excelArr.push({ index: null, productName: null, sku: 'ทั้งหมด', quantity: listOrderAll?.data?.countQuantity.toLocaleString(), price: listOrderAll.data.priceTotal})
                            // setExcelData(excelArr)
                        } else {
                            // setExcelData([{ index: '', productName: '', sku: '', quantity: '' }])
                        }

                    }
                    if (listOrderAll.data && (type === 'order')) {
                        if (listOrderAll.data?.data?.length) {
                            listOrderAll.data.data.map((data, index) => {
                                excelArr.push({ index: (index + 1).toLocaleString(), orderNo: data.orderNo, shopName: data.shopName, customerName: data.customer.name, quantity: data.quantity.toLocaleString(), priceTotal: data.priceTotal.toLocaleString() })
                            })
                            excelArr.push({ index: null, orderNo: null, shopName: null, customerName: 'ทั้งหมด', quantity: listOrderAll.data?.quantityTotal.toLocaleString(), priceTotal: listOrderAll.data?.priceTotal.toLocaleString() })
                            // setExcelData(excelArr)
                        } else {
                            // setExcelData([{ index: '', orderNo: '', shopName: '', customerName: '', quantity: '', priceTotal: '' }])
                        }
                        // ["ลำดับ", "หมายเลขออเดอร์", "ชื่อร้านค้า", "ชื่อลูกค้า", "จำนวนสินค้า", "ยอดเงิน"]

                    }
                    return {
                        header: headerExcel,
                        data: excelArr
                    }
                } else {
                    setListOrderAll({ data: [] })
                }
            }
        }

    } 


    return (
        <div className='order-report'>
            <DashBoardHeaderSelect
                headerExcel={headerExcel}
                dataReport={listOrderAll}
                type={type}
                fetchDataExcel={() => fetchDataExcel()} />

            <section className='d-flex flex-row bg-white' style={{ padding: '1em' }}>
                <div className='pl-0' style={{ padding: '0 2em' }}>
                    {type === 'order' && <React.Fragment><Body1>ยอดขายทั้งหมด</Body1>  <H6 className='font-weight-bold'>฿ {listOrder?.priceTotal?.toLocaleString()}</H6></React.Fragment>}
                    {type === 'sku-in-cart' && <React.Fragment><Body1>จำนวนสินค้าในตะกร้า ณ ปัจจุบัน </Body1><H6 className='ml-3 font-weight-bold'>{listOrder?.countQuantity}</H6> </React.Fragment>}
                    {type === 'sku-wait-payment' && <React.Fragment><Body1>จำนวนสินค้าที่รอชำระเงิน ณ ปัจจุบัน</Body1> <H6 className='ml-3 font-weight-bold'>{listOrder?.countQuantity}</H6></React.Fragment>}
                </div>
                {type === 'order' && <div style={{ padding: '0 2em', borderLeft: '1px solid black' }}>
                    <Body1>จำนวนคำสั่งซื้อทั้งหมด</Body1>  <H6 className='font-weight-bold'>{listOrder?.count}</H6>
                </div>}
            </section>

            {showOrder(type, listOrder)}
            {listOrder?.data?.length > 0 &&
                <PaginationComponent
                    defaultCurrent={1}
                    total={total}
                    onChange={(page) => onChangePage(page)}
                    pageSize={limit}
                    pageSizeOptions={[10, 20, 30]}
                    onShowSizeChange={onShowSizeChange}
                    current={page}
                    locale={{ items_per_page: '/ หน้า' }}
                />
            }

        </div>


    )
}

export default OrderReport
