import React from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import {ReactComponent as IconUpload} from 'images/icons/ic_upload.svg'
import './style.scss';

export default class UploadXlsx extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
      cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */
    };
    this.handleFile = this.handleFile.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }
  handleFile(file /*:File*/) {

    if(file.name.indexOf('.xlsx') < 0) {
      const data = {error: {name: 'InvalidFileType', message: 'ชนิดของไฟล์ไม่ถูกต้อง'}};
      this.props.onFileChange(file,data);
      return;
    } 

    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {

      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // console.log(rABS, wb);

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      /* Update state */
      this.setState({ data: data, cols: make_cols(ws["!ref"]) });

      /* return data */
      this.props.onFileChange(file,data);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }

  exportFile() {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(this.state.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx");
  }
  render() {
    return (
      <DragDropFile handleFile={this.handleFile}>
        <div className="row">
          <div className="col-12">
            <DataInput handleFile={this.handleFile} />
          </div>
        </div>

        {/* <div className="row">
          <div className="col-12">
            <button
              disabled={!this.state.data.length}
              className="btn btn-success"
              onClick={this.exportFile}
            >
              Export
            </button>
          </div>
        </div> */}

        {/* <div className="row">
          <div className="col-12">
            <OutTable data={this.state.data} cols={this.state.cols} />
          </div>
        </div> */}
      </DragDropFile>
    );
  }
}

class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <form className="form-inline">
        <div className="form-content">
          {/* <label htmlFor="file">Spreadsheet</label> */}
          <input
            type="file"
            className=""
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
            onClick={(event)=> { event.target.value = null }}
          />

          <div className="row">
          <div className="col-12 text-center">
            <IconUpload />
            <label className="color-ais-primary-main">เลือกไฟล์หรือวางไฟล์ excel ของคุณที่นี่</label>
            <label className="color-ais-text-input">ขนาดไฟล์ที่ใหญ่ที่สุด 10.0 MB และต้องเป็นไฟล์ xlxs</label>
            <button className='btn-submit mt-2'
                    onClick={() => {}}>
                      เลือกไฟล์
            </button>
          </div>
        </div>
        </div>
      </form>
    );
  }
}

class OutTable extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {this.props.cols.map(c => (
                <th key={c.key}>{c.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((r, i) => (
              <tr key={i}>
                {this.props.cols.map(c => (
                  <td key={c.key}>{r[c.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  // "xlsb",
  // "xlsm",
  // "xls",
  // "xml",
  // "csv",
  // "txt",
  // "ods",
  // "fods",
  // "uos",
  // "sylk",
  // "dif",
  // "dbf",
  // "prn",
  // "qpw",
  // "123",
  // "wb*",
  // "wq*",
  // "html",
  // "htm"
]
  .map(function(x) {
    return "." + x;
  })
  .join(",");

/* generate an array of column objects */
const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
