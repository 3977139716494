import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ButtonAdd, ButtonUpload, ModalLarge } from 'components/output'
import { TextInput, NumberInput, FloatInput, Textarea } from 'components/input'
import {
  dispatchUser,
  CREATE_USER_SHOP,
  SKIP_CREATE_USER,
  CREATE_USER_SHOP_FIELD,
} from 'redux/actions/user'
import { dispatchShop, CREATE_SHOP, UPLOAD_LOGO_SHOP, CREATE_SHOP_FIELD } from 'redux/actions/shop'
import SelectInput from 'components/SelectInput'
import shopProvider from 'provider/shopProvider'
import { isEmpty } from 'lodash'
import SwitchComponent from 'components/SwitchGreen'
import { Body1, Body2, Caption, H6, H5, Tooltip } from 'components/FontStyle'
import ModalConfirmChildren from 'components/ModalConfirmChildren'
import { dispatchGallery, SET_GALLERY_FIELD } from 'redux/actions/gallery'
// import { dispatchNotification, OPEN_TOAST } from 'redux/actions/notification'
// import { ReactComponent as IconShopAvatar } from 'images/icons/default-shop-logo.svg'
// import IconShopAvatar from 'images/icons/default-shop-logo.svg'
import { ReactComponent as IconClose } from 'images/icons/close-circle.svg'
import './styles.scss'
import Loading from 'components/Loading'
import { getUserRole } from 'helper/utils'
import { OWNER } from 'layout/Sidebar/Role'
import ShopThemeColor from 'components/Form/ShopThemeColor'

const shopProvider_api = new shopProvider()

const title = {
  createUserShop: 'สร้างผู้ใช้',
  createShop: 'สร้างร้านค้า',
  uploadLogo: 'อัพโหลดรูปภาพ'
}

const ModalAddShop = (props) => {
  const { userShop, dispatch, errUserShop, errorShop } = props

  const [statusModal, setStatusModal] = useState('none')
  const [data, setData] = useState({sharingShop: 100, sharingPlatform: 0})
  const [seo, setSeo] = useState({})
  const [error, setError] = useState(null)
  const [listProvince, setListProvince] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [modalConfirm, setModalConfirm] = useState(false)
  const [shopThemeColor, setShopThemeColor] = useState([
    {label:'สีหลัก',value:'#B2D234'},
    {label:'สีพื้นหลัง',value:'#F9FBEF'},
    {label:'สีรอง',value:'#252527'},
    {label:'สีตัวอักษรหลัก',value:'#2A2C2D'},
    {label:'สีตัวอักษรรอง',value:'#FFFFFF'}])
  // useEffect(() => {
  //   setData({ province: listProvince && listProvince[0]?.label })
  // }, [listProvince])

  // useEffect(() => {
  //   if (userShop) {
  //     getListProvince()
  //     setData({ province: listProvince && listProvince[0]?.label })
  //     toggleModal('createShop')
  //   }
  // }, [userShop])

  // useEffect(() => {
  //   if (error && Object.keys(error).length === 0) {
  //     submit()
  //   }
  // }, [error])

  useEffect(() => {
    if (error?.logo) {
      const { logo, ...withoutError } = error
      setError({ ...withoutError })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gallery])

  useEffect(() => {
    if (userShop) {
      createShop()
      // if (!errUserShop || !errorShop) {
      //   toggleModal('uploadLogo')
      // }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userShop])

  useEffect(() => {
    if (errorShop === 'uploadSuccess' && statusModal === 'uploadLogo') {
      onClose()
    }
    if (errorShop === 'success' && statusModal === 'createUserShop') {
      // onClose()
      toggleModal('uploadLogo')
    } else if (errorShop) {
      setError(errorShop)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorShop])

  useEffect(() => {
    if (errUserShop === 'success') {
      createShop()
    }
    if (errUserShop === 'validation') {
      validator()
    } else {
      setError(errUserShop)
    }
    if (!errUserShop) {
      setModalConfirm(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errUserShop])

  useEffect(() => {
    if(!data.sharingShop) setData({...data,sharingPlatform: 100})
    if(data.sharingShop){
      setData({...data,sharingPlatform: 100 - data.sharingShop}) 
    }
  }, [data?.sharingShop])

  useEffect(() => {
    if(data.sharingPlatform > 100) setData({...data,sharingPlatform: 100})
    if(!data.sharingPlatform) setData({...data,sharingShop: 100})
    if(data.sharingPlatform){
      setData({...data,sharingShop: 100 - data.sharingPlatform}) 
    }
  }, [data?.sharingPlatform])

  // useEffect(() => {
  //   toggleModal('none')
  // }, [props.listShop])

  const toggleModal = (modal) => {
    setStatusModal(modal)
    scrollToTop()
  }

  const handleChangeText = (text, key) => {
    if(key === 'sharingPlatform' && text > 100){
      return setData({...data,sharingPlatform: 100})
    }
    if(key === 'sharingShop' && text > 100){
      return setData({...data,sharingShop: 100})
    }
    if ((key === 'mobile' && text.length < 11) || key !== 'mobile') {
      setData({
        ...data,
        [key]: text,
      })
    }
  }

  // const handleChangeSeo = (text, key) => {
  //   setSeo({
  //     ...seo,
  //     [key]: text,
  //   })
  // }

  const onClickSkipCreateUser = () => {
    dispatch(
      dispatchUser({
        type: SKIP_CREATE_USER,
        payload: data,
      }),
    )
    // if (!errorShop) {
    //   toggleModal('uploadLogo')
    // }
  }



  const onChangeFloatInput = (value, key) => {
    setData({
      ...data,
      [key]: value,
    })
  }

  const getListProvince = async () => {
    if (listProvince.length === 0) {
      const listProvince = await shopProvider_api.getListProvince()
      const provinces = []
      // eslint-disable-next-line array-callback-return
      listProvince.data.map((province) => {
        provinces.push({ label: province.provinceName, value: province })
      })
      // if (data.province === undefined) {
      //   await setData({ ...data, province: provinces && provinces[0]?.value?.provinceName })
      // }
      await setListProvince(provinces)
    }
  }

  // const onChangeProvince = (value) => {
  //   setData({
  //     ...data,
  //     province: listProvince && listProvince[value].label
  //   })
  // }

  const createMall = async () => {
    const validate = await validator()
    if (validate) {
      //create user
      await submit()
      /// if (createUser){
      /// create Mall
    }
  }

  const onFetchProvince = async (value) => {
    if (value) {
      await getListProvince()
    }
  }

  const handleUploadLogo = () => {
    let error = {}
    if (!props.gallery) {
      error.logo = 'กรุณาใส่รูป'
    } else {
      delete error.logo
    }
    // if (!props.coverMall) {
    //   error.coverMall = 'กรุณาใส่รูป'
    // } else {
    //   delete error.coverMall
    // }
    setError(error)
    if (Object.keys(error).length === 0) {
      dispatch(
        dispatchShop({
          type: UPLOAD_LOGO_SHOP,
          payload: {
            shopId: props.gallery[props.gallery.length - 1].shopId,
            logo: props.gallery[props.gallery.length - 1],
            cover: props.coverMall && props.coverMall[props.coverMall.length - 1],
            shopThemeColor
          }
        })
      )
    }

  }

  const scrollToTop = () => {
    const ee = document.getElementsByClassName('modal-body')
    if(ee.length > 0){
      ee[0].scrollTop = 0
    }
  }


  const createShop = () => {
    const { email, mobile, password, permalink, ...dataWithoutProfile } = data
    dispatch(
      dispatchShop({
        type: CREATE_SHOP,
        payload: {
          token: userShop.token?.accessToken,
          body: {
            ...dataWithoutProfile,
            userId: userShop.user?.id,
            sharingShop: parseInt(data.sharingShop, 10) || 0,
            sharingPlatform: parseInt(data.sharingPlatform, 10) || 0,
            paymentFee: parseFloat(data.paymentFee),
            seo: {
              permalink: permalink
            },
            status: data.status ? 'show' : 'hide'
            // seo: {
            //   ...seo,
            //   seoLength: {
            //     title: 'bad',
            //     description: 'bad',
            //     keyword: 'bad',
            //   },
            // },
          },
        },
      }),
    )
  }

  const renderContent = () => {
    switch (statusModal) {
      case 'createUserShop':
        return (
          <div>

            <div className='bg-white p-5 mt-2 mb-2'>
              <H6 className='mb-3 font-weight-bold'>ข้อมูลผู้ใช้</H6>
              <div className='mb-5'>
                <TextInput
                  label='อีเมล'
                  handleChangeText={(text) => handleChangeText(text, 'email')}
                  error={error && error.email}
                  require
                />
                {/* {error?.skipBtn && (
                  <button
                    className='btn-box'
                    onClick={() => onClickSkipCreateUser(error.user)}
                  >
                    สร้างร้านโดยใช้ user นี้
                  </button>
                )} */}
              </div>
              <div className='mb-5'>
                <NumberInput
                  label='เบอร์โทรศัพท์'
                  defaultValue={data?.mobile}
                  handleChangeText={(text) => handleChangeText(text, 'mobile')}
                  error={error && error.mobile}
                  require
                />
              </div>
              <div className='mb-5'>
                <TextInput
                  label='รหัสผ่าน'
                  handleChangeText={(text) => handleChangeText(text, 'password')}
                  error={error && error?.password}
                  require
                  placeholder='ตัวอักษรภาษาอังกฤษ A-Z,a-z,0-9 รหัสผ่านต้องมีความยาว 6-16 ตัวอักษร'
                />
              </div>
            </div>


            <div className='bg-white p-5'>
              <div className='mb-5'>
                    <H6 className='mb-3 font-weight-bold'>ข้อมูลร้านค้า</H6>
                {/* <div className="d-flex flex-column mb-5">
                  <Body1 style={{ fontWeight: '100' }}>รองรับสัดส่วนภาพ 1:1 เท่านั้น (สี่เหลี่ยมจัตุรัส) ขนาดรูปแนะนำคือ 300 x 200 พิกเซล</Body1>
                  <ButtonUpload />
                </div> */}

                <TextInput
                  label='ชื่อที่ใช้แสดง'
                  title={<Tooltip>(จำกัด 30 ตัวอักษร)</Tooltip>}
                  handleChangeText={(text) => handleChangeText(text, 'name')}
                  error={error && error.name}
                  require
                  maxLength={30}
                  placeholder='กรอกชื่อที่ใช้แสดง'
                />
              </div>
              <div className='mb-5'>

                {/* <div className="d-flex flex-column mb-5">
                  <Body1 style={{ fontWeight: '100' }}>รองรับสัดส่วนภาพ 1:1 เท่านั้น (สี่เหลี่ยมจัตุรัส) ขนาดรูปแนะนำคือ 300 x 200 พิกเซล</Body1>
                  <ButtonUpload />
                </div> */}
                <Textarea
                  label='รายละเอียดร้านค้า'
                  title={<Tooltip>(จำกัด 5,000 ตัวอักษร)</Tooltip>}
                  defaultValue=''
                  placeholder='กรอกรายละเอียด'
                  handleChangeText={(text) => handleChangeText(text, 'description')}
                  error=''
                  require={false}
                />
              </div>
              <div className='mb-5'>
                <TextInput
                  label='ชื่อบน URL '
                  title='(จำกัด 100 ตัวอักษร)'
                  defaultValue={data?.permalink}
                  handleChangeText={(text) => handleChangeText(text, 'permalink')}
                  error={error && error.permalink}
                  maxLength={100}
                  placeholder='กรอกชื่อบน URL'
                  require
                />
              </div>
              <div className='mb-5'>
                <TextInput
                  label='รหัสร้านค้า'
                  title='(กรุณากรอก A-Z จำกัด 4 ตัวอักษร, ห้ามใส่อักขระพิเศษ)'
                  handleChangeText={(text) => handleChangeText(text.toUpperCase(), 'shopCode')}
                  error={error && error.shopCode}
                  require
                  maxLength={4}
                  style={{ textTransform: 'uppercase' }}
                  placeholder='กรอกรหัสร้านค้า'
                />
              </div>
              {/* <div className='mb-5'>
                <TextInput
                  label='อำเภอ/เขต'
                  handleChangeText={(text) => handleChangeText(text, 'zone')}
                  error={error && error.zone}
                  require
                />
              </div>
              <div className='mb-5'>
                <span className="require" style={{ fontWeight: 400 }}>จังหวัด</span>
                <SelectInput
                  placeholder={''}
                  options={listProvince}
                  value={listProvince.length !== 0 && !isEmpty(data.province) ? listProvince?.findIndex((province) => province.label === data.province) : null}
                  onChange={(value) => handleChangeText(listProvince[value].label, 'province')}
                  iconClearSearch={false}
                  error={error?.province}
                  className={error?.province && "ant-selector-select-error"}
                  onDropdownVisibleChange={(value) => onFetchProvince(value)}
                // onSelect={(value) => onChangeProvince(value)}
                />
              </div> */}
            </div>

            <div className='bg-white p-5 mt-2'>
              <H6 className='font-weight-bold'>ข้อมูลส่วนแบ่งรายได้</H6>
              <div className='mb-3'></div>
              <div className='mb-5'>
                <NumberInput
                  label='ส่วนแบ่งร้านค้า'
                  defaultValue={data?.sharingShop}
                  handleChangeText={(text) =>
                    handleChangeText(text, 'sharingShop')
                  }
                  error={error && error.sharingShop}
                  require
                />
              </div>
              <div className='mb-5'>
                <NumberInput
                  label='ส่วนแบ่งระบบ'
                  defaultValue={data?.sharingPlatform}
                  handleChangeText={(text) =>
                    handleChangeText(text, 'sharingPlatform')
                  }
                  error={error && error.sharingPlatform}
                  require
                />
              </div>
              <div className='mb-5'>
                <FloatInput
                  name='paymentFee'
                  label='ค่าธรรมเนียมการชำระเงิน'
                  type='CURRENCY'
                  placeholder='0.00'
                  value={data?.paymentFee}
                  onChangeFloatInput={(value) =>
                    onChangeFloatInput(value, 'paymentFee')
                  }
                  require
                  error={error?.paymentFee}
                  defaultValue={2}
                />
              </div>
            </div>

            <div className='bg-white p-5 mt-2 d-flex flex-row align-items-center'>
              <H5>สถานะการใช้งาน</H5>
              <div className="ml-5">
                <SwitchComponent checked={data.status} onChange={(text) => handleChangeText(text, 'status')} /> <span className="ml-2">{data?.status ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</span>
              </div>
            </div>
            <Loading />
          </div>

        )

      // case 'createShop':
      //   return (
      //     <div className='m-3'>
      //       <div className='bg-white p-5 mb-3'>
      //         <h5 className='mb-3'>ข้อมูลร้านค้า</h5>
      //         <div className='mb-3'>
      //           <TextInput
      //             label='ชื่อ'
      //             handleChangeText={(text) => handleChangeText(text, 'name')}
      //             error={error && error.name}
      //             require
      //           />
      //         </div>
      //         <div className='mb-3'>
      //           <Textarea
      //             label='รายละเอียด'
      //             handleChangeText={(text) =>
      //               handleChangeText(text, 'description')
      //             }
      //           />
      //         </div>
      //         <div className='mb-3'>
      //           <TextInput
      //             label='อำเภอ/เขต'
      //             handleChangeText={(text) => handleChangeText(text, 'zone')}
      //             error={error && error.zone}
      //             require
      //           />
      //         </div>

      //         <div className='mb-3'>
      //           <span className="require">จังหวัด</span>
      //           <SelectInput
      //             placeholder={''}
      //             options={listProvince}
      //             value={listProvince.length !== 0 && !isEmpty(data.province) ? listProvince?.findIndex((province) => province.label === data.province) : null}
      //             onChange={(value) => setData({ ...data, province: listProvince[value].label })}
      //             iconClearSearch={false}
      //           />
      //         </div>

      //         <div className='mb-3'>
      //           <NumberInput
      //             label='ส่วนแบ่งร้านค้า'
      //             defaultValue={data?.sharingShop}
      //             handleChangeText={(text) =>
      //               handleChangeText(text, 'sharingShop')
      //             }
      //             error={error && error.sharingShop}
      //             require
      //           />
      //         </div>
      //         <div className='mb-3'>
      //           <NumberInput
      //             label='ส่วนแบ่งระบบ'
      //             defaultValue={data?.sharingPlatform}
      //             handleChangeText={(text) =>
      //               handleChangeText(text, 'sharingPlatform')
      //             }
      //             error={error && error.sharingPlatform}
      //             require
      //           />
      //         </div>
      //         <div className=''>
      //           <FloatInput
      //             name='paymentFee'
      //             label='ค่าธรรมเนียมการชำระเงิน'
      //             type='CURRENCY'
      //             placeholder='0.00'
      //             value={data?.paymentFee}
      //             onChangeFloatInput={(value) =>
      //               onChangeFloatInput(value, 'paymentFee')
      //             }
      //             require
      //           />
      //         </div>
      //       </div>
      //       <div className='bg-white p-5'>
      //         <h5 className='mb-3'>จัดการ SEO</h5>

      //         <div className='mb-3'>
      //           <TextInput
      //             label='Title'
      //             handleChangeText={(text) => handleChangeSeo(text, 'title')}
      //             error={error && error.title}
      //             require
      //           />
      //         </div>
      //         <div className='mb-3'>
      //           <Textarea
      //             label='Description'
      //             handleChangeText={(text) =>
      //               handleChangeSeo(text, 'description')
      //             }
      //             error={error && error.description}
      //             require
      //           />
      //         </div>
      //         <div className='mb-3'>
      //           <TextInput
      //             label='Keyword'
      //             handleChangeText={(text) =>
      //               handleChangeSeo([text], 'keyword')
      //             }
      //             error={error && error.keyword}
      //             require
      //           />
      //         </div>
      //         <div className='mb-3'>
      //           <TextInput
      //             label='Permalink'
      //             handleChangeText={(text) =>
      //               handleChangeSeo(text, 'permalink')
      //             }
      //             error={error && error.permalink}
      //             require
      //           />
      //         </div>
      //       </div>
      //     </div>
      //   )

      case 'uploadLogo':
        return (
          <div className="bg-white p-3">
            <Body2 className='mb-3'>โลโก้ร้านค้า</Body2>
            <div className='mb-5'>
              <Caption style={{ fontWeight: '100' }}>ขนาดรูปแนะนำคือ 300 x 200 พิกเซล</Caption>
              <div className="mt-3"
              >
                {
                  props.gallery ?
                    <div id="logo-upload" >
                      <IconClose onClick={() => dispatch(dispatchGallery({ type: SET_GALLERY_FIELD, payload: { key: 'logoMall', value: null } }))} />
                      <img src={`${props.gallery[props.gallery.length - 1].host}${props.gallery[props.gallery.length - 1].path}`} alt=""
                        style={{ maxWidth: '300px', maxHeight: '200px' }}
                      />
                    </div> :
                    <ButtonUpload
                      // isAvatar
                      width='12em'
                      height='12em'
                      type={'image'}
                      shopDetail={{ shopId: props.shopTmp?.id }}
                      fieldUpload='logoMall'
                      limitImageUpload={1}
                    />
                }
              </div>
              {error?.logo && <span className="text-red">{error?.logo}</span>}
            </div>

            <Body2 className='mb-3'>ภาพหน้าปก</Body2>
            <div className='mb-5'>
              <Caption style={{ fontWeight: '100' }}> ขนาดรูปแนะนำคือ 970x326 พิกเซล ขนาดไฟล์สูงสุดไม่เกิน 300 kb</Caption>
              <div className="mt-3">
                {
                  props.coverMall ?
                    <div id="cover-upload" >
                      <IconClose onClick={() => dispatch(dispatchGallery({ type: SET_GALLERY_FIELD, payload: { key: 'coverMall', value: null } }))} />
                      <img src={`${props.coverMall[props.coverMall.length - 1].host}${props.coverMall[props.coverMall.length - 1].path}`} alt=""
                        style={{ width: '100%' }}
                      />
                    </div> :
                    <ButtonUpload
                      // isAvatar
                      width='100%'
                      height='300px'
                      type={'image'}
                      shopDetail={{ shopId: props.shopTmp?.id }}
                      fieldUpload='coverMall'
                      // disabled={props.gallery}
                      limitImageUpload={1}
                    />
                }

              </div>
              {error?.coverMall && <span className="text-red">{error?.coverMall}</span>}
            </div>

            <ShopThemeColor 
                theme={shopThemeColor}
                />
            <Loading />
          </div >
        )

      default:
        break
    }
  }

  const validator = () => {
    // let tmpError = error === null ? {} : { ...error }
    let tmpError = {}
    switch (statusModal) {
      case 'createUserShop':
        if (!data.email) {
          tmpError = {
            ...tmpError,
            email: 'กรุณากรอกอีเมล',
          }
        } else {
          const regEmail = new RegExp(
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          )

          if (!regEmail.test(data.email)) {
            tmpError = {
              ...tmpError,
              email: 'รูปแบบอีเมลไม่ถูกต้อง',
            }
          } else {
            delete tmpError.email
          }
        }

        if (!data.mobile) {
          tmpError = {
            ...tmpError,
            mobile: 'กรุณากรอกเบอร์โทรศัพท์',
          }
        } else if (
          data.mobile.toString().length !== 10 &&
          data.mobile.toString().length !== 9
        ) {
          tmpError = {
            ...tmpError,
            mobile: 'กรุณาใส่เบอร์โทรศัพท์ให้ถูกต้อง',
          }
        } else {
          delete tmpError.mobile
        }

        if (!data.password) {
          tmpError = {
            ...tmpError,
            password: 'กรุณากรอกรหัสผ่าน',
          }
        } else {
          const regPass = /^[a-zA-Z0-9]{6,16}$/

          if (!regPass.test(data.password)) {
            tmpError = {
              ...tmpError,
              password: 'รูปแบบรหัสผ่านไม่ถูกต้อง',
            }
          } else {
            delete tmpError.password
          }
        }
        if (!data.name) {
          tmpError = {
            ...tmpError,
            name: 'กรุณากรอกชื่อร้านค้า',
          }
        } else {
          delete tmpError.name
        }
        if (data.description?.length > 5000) {
          tmpError = {
            ...tmpError,
            description: 'กรุณากรอกรายละเอียดไม่เกิน 5,000 ตัวอักษร',
          }
        } else {
          delete tmpError.description
        }
        if (!data.permalink) {
          tmpError = {
            ...tmpError,
            permalink: 'กรุณากรอก permalink ร้านค้า',
          }
        } else {
          delete tmpError.permalink
        }
        if (!data.shopCode) {
          tmpError = {
            ...tmpError,
            shopCode: 'กรุณากรอกโค้ดร้านค้า',
          }
        } else {
          const regCodeMall = /^[A-Z]{4}$/
          if (!regCodeMall.test(data.shopCode)) {
            tmpError = {
              ...tmpError,
              shopCode: 'กรุณากรอก A-Z จำกัด 4 ตัวอักษร, ห้ามใส่อักขระพิเศษ',
            }
          } else {
            delete tmpError.shopCode
          }
        }

        if (data.sharingShop < 0) {
          tmpError = {
            ...tmpError,
            sharingShop: 'กรุณากรอกส่วนแบ่งร้านค้า',
          }
        } else {
          delete tmpError.sharingShop
        }

        if (data.sharingPlatform < 0) {
          tmpError = {
            ...tmpError,
            sharingPlatform: 'กรุณากรอกส่วนแบ่งระบบ',
          }
        } else {
          delete tmpError.sharingPlatform
        }

        if (!data.paymentFee) {
          tmpError = {
            ...tmpError,
            paymentFee: 'กรุณากรอกค่าธรรมเนียมการชำระเงิน',
          }
        } else {
          delete tmpError.paymentFee
        }
        // if (!data.province) {
        //   tmpError = {
        //     ...tmpError,
        //     province: 'กรุณากรอกจังหวัด',
        //   }
        // } else {
        //   delete tmpError.province
        // }
        // if (!data.zone) {
        //   tmpError = {
        //     ...tmpError,
        //     zone: 'กรุณากรอกเขต/อำเภอ',
        //   }
        // } else {
        //   delete tmpError.zone
        // }


        setError(tmpError)
        break


      case 'createShop':
        if (!data.name) {
          tmpError = {
            ...tmpError,
            name: 'กรุณากรอกชื่อร้านค้า',
          }
        } else {
          delete tmpError.name
        }

        if (!data.zone) {
          tmpError = {
            ...tmpError,
            zone: 'กรุณากรอกโซนร้านค้า',
          }
        } else {
          delete tmpError.zone
        }

        if (!data.sharingShop) {
          tmpError = {
            ...tmpError,
            sharingShop: 'กรุณากรอกส่วนแบ่งร้านค้า',
          }
        } else {
          delete tmpError.sharingShop
        }

        if (!data.sharingPlatform) {
          tmpError = {
            ...tmpError,
            sharingPlatform: 'กรุณากรอกส่วนแบ่งระบบ',
          }
        } else {
          delete tmpError.sharingPlatform
        }

        if (!data.paymentFee) {
          tmpError = {
            ...tmpError,
            paymentFee: 'กรุณากรอกค่าธรรมเนียมการชำระเงิน',
          }
        } else {
          delete tmpError.paymentFee
        }

        if (!seo.title) {
          tmpError = {
            ...tmpError,
            title: 'กรุณากรอก Title',
          }
        } else {
          delete tmpError.title
        }

        if (!seo.description) {
          tmpError = {
            ...tmpError,
            description: 'กรุณากรอกรายละเอียด',
          }
        } else {
          delete tmpError.description
        }

        if (!seo.keyword) {
          tmpError = {
            ...tmpError,
            keyword: 'กรุณากรอก Keyword',
          }
        } else {
          delete tmpError.keyword
        }

        if (!seo.permalink) {
          tmpError = {
            ...tmpError,
            permalink: 'กรุณากรอก Permalink',
          }
        } else {
          delete tmpError.permalink
        }

        setError(tmpError)
        break

      default:
        break
    }
    return Object.keys(tmpError).length === 0
  }

  const submit = () => {
    switch (statusModal) {
      case 'createUserShop':
        dispatch(
          dispatchUser({
            type: CREATE_USER_SHOP,
            payload: data,
          }),
        )

        break

      // case 'createShop':
      //   const {email, mobile, password, permalink, ...dataWithoutProfile } = data
      //   dispatch(
      //     dispatchShop({
      //       type: CREATE_SHOP,
      //       payload: {
      //         token: userShop.token.accessToken,
      //         body: {
      //           ...dataWithoutProfile,
      //           userId: userShop.user.id,
      //           sharingShop: parseInt(data.sharingShop, 10),
      //           sharingPlatform: parseInt(data.sharingPlatform, 10),
      //           paymentFee: parseFloat(data.paymentFee),
      //           status: data.status ? 'show' : 'hide',
      //           seo: {
      //             permalink: data.permalink
      //           }
      //           // seo: {
      //           //   ...seo,
      //           //   seoLength: {
      //           //     title: 'bad',
      //           //     description: 'bad',
      //           //     keyword: 'bad',
      //           //   },
      //           // },
      //         },
      //       },
      //     }),
      //   )

      //   break

      default:
        break
    }
  }

  const onClose = () => {
    toggleModal('none')
    setData({})
    setError(null)
    setSeo({})
    dispatch(
      dispatchGallery({
        type: SET_GALLERY_FIELD,
        payload: { key: 'logoMall', value: null },
      }),
    )
    dispatch(
      dispatchGallery({
        type: SET_GALLERY_FIELD,
        payload: { key: 'coverMall', value: null },
      }),
    )
    dispatch(
      dispatchShop({
        type: CREATE_SHOP_FIELD,
        payload: null
      })
    )
  }

  const handleCancelModalConfirm = () => {
    if(error.password){
      setError({...error,skipBtn: false})
      return
    }
    dispatch(dispatchUser({ type: CREATE_USER_SHOP_FIELD, payload: null }))
  }

  return (
    <>
      {getUserRole() !== OWNER &&
        <ButtonAdd
          label='เพิ่มร้านค้า'
          handleAdd={() => toggleModal('createUserShop')}
        />
      }
      <ModalLarge
        title={title[statusModal]}
        closeModal={onClose}
        clickCancel={onClose}
        clickSubmit={() => {
          if (statusModal === 'createUserShop') {
            createMall()
          } else if (statusModal === 'uploadLogo') {
            handleUploadLogo()
          }
          else {
            validator()
          }
        }}
        labelButton='สร้าง'
        labelCancel='ยกเลิก'
        isOpenModal={statusModal !== 'none'}
        isShowFooter
      >
        {renderContent()}
        <ModalConfirmChildren
          isOpen={error?.skipBtn}
          handleConfirm={() => { onClickSkipCreateUser(error?.user) }}
          handleCancel={() => handleCancelModalConfirm()}
          btnCancel='ยกเลิก'
          btnConfirm='ตกลง'
        >
          <Body1 className='text-center'>
            <H6>
              อีเมลนี้เคยใช้ในการสร้างร้านค้าแล้ว <br />
              ยืนยันการสร้างร้านค้าเพิ่มใช่หรือไม่</H6>
          </Body1>
          <Loading />
        </ModalConfirmChildren>
      </ModalLarge>
    </>
  )
}

const mapStateToProps = (state) => ({
  userShop: state.user.userShop,
  errUserShop: state.user.errUserShop,
  errorShop: state.shop.errorShop,
  listShop: state.shop.shops,
  shopTmp: state.shop.shopTmp,
  gallery: state.gallery.logoMall,
  coverMall: state.gallery.coverMall,
  currentShop: state.shop.currentShop
})

export default connect(mapStateToProps, null)(ModalAddShop)
