import React, { useState } from 'react';
import { H5, SubCaption, Tooltip } from 'components/FontStyle';
import InputText from 'components/InputText';
import 'styles/_changePassword.scss'
import { ReactComponent as IconHidePassword } from 'images/icons/Icon-hide-password.svg'
import ToastNotification from 'components/ToastNotification';
import { ReactComponent as IconEye } from 'images/icons/eye.svg'
import { ReactComponent as IconEyeOpen } from 'images/icons/eye-already.svg'
import userProvider from 'provider/userProvider';
import { useDispatch } from 'react-redux';
import { dispatchNotification, OPEN_TOAST } from 'redux/actions/notification';
import { Redirect } from 'react-router-dom';

const ChangePassword = (props) => {
    const dispatch = useDispatch()
    const [error, setError] = useState(null)
    const [value, setValue] = useState({ password: '', confirmpassword: '' });
    const [showPassword, setShowPassword] = useState('password')
    const [showConfirmPassword, setShowConfirmPassword] = useState('password');


    const onClickShowPassword = (name) => {
        const element = document.getElementsByName(name)[0].type
        if (element.length < 5) {
            // document.getElementsByName(name)[0].type = 'password'
            if (name === 'password') setShowPassword('password')
            if (name === 'confirmpassword') setShowConfirmPassword('password')
        } else {
            // document.getElementsByName(name)[0].type = 'text'
            if (name === 'password') setShowPassword('text')
            if (name === 'confirmpassword') setShowConfirmPassword('text')
        }
    }

    const onChangeText = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }

    const submit = async () => {
        const validate = validatePassword()
        if (validate) {
            /// api changepassword
            const params = {
                resetToken: props.match?.params?.id,
                email: props.match?.params?.email,
                password: value?.password
            }
            try {
                const res = await userProvider.resetPassword(params)
                if (res) {
                    dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } }))
                    props.history.push('/login')
                }
            } catch (error) {
                console.log(error);
                //    if(error.response.data.code === 401){
                dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง`, type: 'danger' } }))
                //    } 
            }
        }

    }

    const validatePassword = () => {
        if (value?.password !== value?.confirmpassword) {
            setError('* รหัสผ่านไม่ตรงกัน กรุณาลองใหม่')
            return false
        }

        const regPass = /^[a-zA-Z0-9]{6,16}$/

        if (!regPass.test(value?.password)) {
            setError('* รูปแบบรหัสผ่านไม่ถูกต้อง')
            return false
        } else {
            setError(null)
        }
        return true
    }

    return <div className='d-flex align-items-center justify-content-center change-password-page'>
        <div className='container-change-password'>
            <div className='content-change-password'>
                <div className='d-flex align-items-center justify-content-center flex-column'>
                    <span><IconHidePassword /></span>
                    <H5>ระบุรหัสผ่านใหม่</H5>
                </div>
                <div className='mt-4 position-relative'>
                    {showPassword === 'text' ?
                        <IconEyeOpen className='icon-eye-open' onClick={() => onClickShowPassword('password')} /> :
                        <IconEye className='icon-eye' onClick={() => onClickShowPassword('password')} />}
                    <InputText
                        label={<SubCaption>รหัสผ่านใหม่</SubCaption>}
                        type={showPassword}
                        name={'password'}
                        isError={error}
                        onChange={onChangeText}
                        value={value?.password}
                        placeholder='ตัวอักษรภาษาอังกฤษ A-Z,a-z,0-9 ความยาว 6-16 ตัวอักษร'
                    />
                </div>
                <div className='mt-4 position-relative'>
                    {showConfirmPassword === 'text' ?
                        <IconEyeOpen className='icon-eye-open' onClick={() => onClickShowPassword('confirmpassword')} /> :
                        <IconEye className='icon-eye' onClick={() => onClickShowPassword('confirmpassword')} />}
                    <InputText
                        label={<SubCaption>ยืนยันรหัสผ่านใหม่</SubCaption>}
                        type={showConfirmPassword}
                        name={'confirmpassword'}
                        onChange={onChangeText}
                        value={value?.confirmpassword}
                        isError={error}
                        placeholder='ตัวอักษรภาษาอังกฤษ A-Z,a-z,0-9 ความยาว 6-16 ตัวอักษร'
                    />
                </div>
                <div className='text-center'>
                    <Tooltip className='text-danger'>{error}</Tooltip>
                </div>

                <div className='d-flex align-items-center justify-content-between mt-4 container-button'>
                    <button className='btn-cancel' onClick={() => props.history.push('/login')}>ยกเลิก</button>
                    <button className='btn-submit' onClick={submit}>ยืนยัน</button>
                </div>
            </div>
        </div>

        <ToastNotification />

    </div>;
};

export default ChangePassword;
