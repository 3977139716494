import React from 'react'
import InputText from 'components/InputText'
import { ButtonUpload } from 'components/output'
import DateTimeSelection from 'components/DateTimeSelection'
import { Checkbox } from 'antd'
import { connect } from 'react-redux'
import ModalConfirm from 'components/modalConfirm/ModalConfirm'
import ModalAlert from 'components/modalAlert/ModalAlert'

const FormCreateBroadcastNoti = (props) => {
    const {
        notification, setNotification,
        desc, setDesc,
        url, setUrl,
        date,
        time,
        typeSend,
        setTypeSend,
        setDateTime,
        broadcastType,
        typeBroadcast,
        keyword,
        setKeyword,
        errors,
        setNotiAppWeb,
        setNotiEmail,
        notiEmail,
        createForm,
        notiAppWeb,
        cancelForm
    } = props

    return (
        <div className="form-create-broadcast">

            <div className="page-content">
                <h5> รูปภาพ </h5>
                <p className='upload-detail'>
                    รองรับสัดส่วนภาพ 1:1 เท่านั้น (สี่เหลี่ยมจัตุรัส)
                    ขนาดรูปแนะนำคือ 640 x 640 พิกเซล ขนาดไฟล์สูงสุด 10 MB
                </p>
                <React.Fragment>
                    <ButtonUpload
                        width='12em'
                        height='12em'
                        type='image'
                    //   shopDetail={{
                    //     shopId: this.state.shopSelected?.value?.id,
                    //   }}
                    //   disabled={!this.state.shopSelected?.value?.id}
                    //   fieldUpload='productUploadEvent'
                    //   limitImageUpload={10}
                    />
                </React.Fragment>
                <InputText
                    label={'หัวข้อการแจ้งเตือน'}
                    title={'(จำกัด 60 ตัวอักษร)'}
                    limit={60}
                    value={notification}
                    onChange={(e) => setNotification(e.target.value)}
                    isError={errors?.notificationContent}
                    errorMsg={errors?.notificationContent}
                    name='notification'
                />

                <InputText
                    label={'คำอธิบาย'}
                    title={'(จำกัด 60 ตัวอักษร)'}
                    limit={60}
                    value={desc}
                    onChange={(e) => { setDesc(e.target.value); }}
                    isError={errors?.description}
                    errorMsg={errors?.description}
                    name='desc'
                />
            </div>

            {typeBroadcast === broadcastType[0].value &&
                <div className="page-content">
                    <h5> ใส่ลิงค์ URL หน้าที่ต้องการโปรโมท </h5>
                    <InputText
                        label={'ลิงค์ URL'}
                        title={null}
                        isRequire={false}
                        placeholder={'https://'}
                        value={url}
                        onChange={(e) => { setUrl(e.target.value); }}
                        name='url'
                    />
                </div>
            }

            {typeBroadcast === 'content' &&
                <React.Fragment>
                    <div className="page-content">
                        <h5> คีย์เวิร์ด </h5>
                        <InputText
                            label={'ข้อความสั้นๆสำหรับแชร์'}
                            title={'(ห้ามใช้อักขระพิเศษอื่นๆ หากมีเว้นวรรคให้ใช้ - แทน)'}
                            isRequire={true}
                            placeholder={'ตัวอย่าง Promotion-55'}
                            value={keyword}
                            onChange={(e) => { setKeyword(e.target.value); }}
                            isError={errors?.keyword}
                            errorMsg={errors?.keyword}
                            name='keyword'
                        />
                    </div>

                    <div className="page-content">
                        <h5> แจ้งเตือนผ่านช่องทาง <span className="require"></span> </h5>

                        <div className="d-flex" style={{ gap: '40px' }}>
                            <label> <Checkbox
                                onChange={(e) => { setNotiAppWeb(e.target.checked); }}
                                checked={notiAppWeb} />
                                <span className="ml-3">แอพพลิเคชั่น / เว็บไซต์</span>
                            </label>
                            <label> <Checkbox
                                onChange={(e) => { setNotiEmail(e.target.checked); }}
                                checked={notiEmail} />
                                <span className="ml-3">อีเมล</span>
                            </label>
                        </div>

                        <div className="text-danger mt-1">{errors?.notiError}</div>
                    </div>
                </React.Fragment>
            }


            <div className="page-content">
                <h5>วันที่และเวลาที่ส่ง </h5>
                <DateTimeSelection
                    time={time}
                    date={date}
                    sending={typeSend}
                    onDateTimeChange={setDateTime}
                    onSendingChange={setTypeSend}
                />
            </div>

            <div className="d-flex justify-content-center align-items-center mt-4 gap-1">
                <button className="btn-cancel" onClick={() => cancelForm()}>ยกเลิกการสร้าง</button>
                <button type="submit" className="btn-submit" onClick={createForm} >บันทึก</button>
            </div>

            <ModalConfirm
                isOpen={props.modalConfirm.isOpen}
                message={props.modalConfirm.message}
                btnConfirm={props.modalConfirm.btnConfirm}
                btnCancel={props.modalConfirm.btnCancel}
            />
            <ModalAlert />
        </div>
    )
}

const mapStateToProps = (state) => ({
    modalConfirm: state.modalConfirm.modalConfirm,
    broadcast: state.broadcast.formData
})

export default connect(mapStateToProps)(FormCreateBroadcastNoti)