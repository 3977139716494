import React, { useEffect, useRef } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./index.scss";
import IconIncrease from "images/icons/increse.svg";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { ReactComponent as IconClose } from "images/icons/close.svg";
import {
  SET_KEY_VALUE_PROMOCODE,
  SELECTED_ITEMJOIN_PROMOCODECODE,
  OPEN_MODAL_SELECTED_PRODUCT,
  SELECTED_SHOPJOIN_PRODUCT,
  OPEN_MODAL_LIST_SKU,
  UPDATE_COUNT_CHECKED_ALL_SHOP
} from "../../redux/actions/promocode";
import classNames from "classnames";
import SelectedShop from "./shop";
import ModalListShopProductPromocode from 'components/ModalListShopProductPromocode';
import ModalListSkuSelectedShop from 'components/ModalListSkuSelectedShop'
import { OPEN_TOAST } from '../../redux/actions/notification'
import { REMOVE_SHOP_SELECTED_SHOP, removeShopSelectedShop } from '../../redux/actions/selectedShop'

const ModalListShopSelectedShop = (props) => {
  const { isOpen = false } = props;

  const {
    promocode,
    selectedShop,
    listSelectedShop,
    handleCloseModal = null,
    handleDeleteShop = null,
    handleEditShop = null,
    handleSubmit = null,
    handleOpenModalCheckedProduct = null,
  } = useModalListShopSelectedShop();

  // const { listProduct = {} } = promocode;
  // console.log("listProduct:>>",listProduct)
  // console.log("listSelectedShop:>>",listSelectedShop)
  // console.log("selectedShopKey:>>",Object.keys(selectedShop.shops))
  // console.log("selectedShop:>>",selectedShop)

  const HeaderTable = ["ร้านค้า", "รูปแบบการเข้าร่วม", "สินค้า", "SKU", "  "];
  const isDisableBtn = selectedShop?.countAllShop == 0;

  const BtnAdd = () => {
    return (
      <div
        className="wrap-btn-add-product"
        onClick={() => handleOpenModalCheckedProduct()}
      >
        <img src={IconIncrease} width={25} />
        <span>เลือกร้านค้า และสินค้า</span>
      </div>
    );
  };

  const EmptyProduct = () => {
    return (
      <div className="cover-empty-table-product-sku">
        <span
          className="text-empty"
          dangerouslySetInnerHTML={{
            __html: `ยังไม่มีรายการ <br/> กรุณากดปุ่มเลือกร้านค้า และสินค้า เพื่อทำรายการต่อ`,
          }}
        ></span>

        <BtnAdd />
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} className="modal-list-shop-selected-shop-product" centered={true}>
      <ModalHeader className="header-modal-selected-product">
        <div className="cover-header-modal-selected-product">
          <div>
            <span className="title-selected-product">
            ร้านค้า และสินค้าที่เข้าร่วมส่วนลด
            </span>
            <span className="subTitle-selected-product">
              (เลือกแล้ว{" "}
              <span className="text-number-highlight">
                {selectedShop?.countAllShop || 0}
              </span>{" "}
              ร้าน |{" "}
              <span className="text-number-highlight">
                {selectedShop?.countAllProduct || 0}
              </span>{" "}
              สินค้า |{" "}
              <span className="text-number-highlight">
                {selectedShop?.countAllSKU || 0}
              </span>{" "}
              SKU)
            </span>
          </div>
          <div>
            <IconClose
              className="icon-close-modal-selected-product"
              onClick={() => handleCloseModal()}
            />
          </div>
        </div>
      </ModalHeader>

      <ModalBody className="cover-body-modal-selected-product">
        <div className="d-flex justify-content-end">
          <BtnAdd />
        </div>

        <div className="cover-header-table-product-sku">
          {HeaderTable.map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </div>

        {/* ======== body table ======= */}
        {!isEmpty(selectedShop?.shops) && selectedShop?.countAllShop > 0 ? (
          <div className="cover-body-table-product-sku">
            {Object.keys(selectedShop.shops)?.map((e)=>{
              if(selectedShop?.shops[e] == undefined) {
                return null
              }

              return(
                <SelectedShop
                  key={parseInt(e)}
                  shop={selectedShop?.shops[e]}
                  shopId={e}
                  handleEditShop={(shopId) => handleEditShop(shopId)}
                  handleDeleteShop={(shopId)=> handleDeleteShop(shopId)}
                />
              )
            })}
          </div>
        ) : (
          <EmptyProduct />
        )}
        {/* <EmptyProduct /> */}
      </ModalBody>

      <ModalFooter className="cover-footer-modal-selected-product">
        <button
          className={classNames("btn-cancel", isDisableBtn && "disable-btn")}
          disabled={isDisableBtn}
          onClick={() => handleCloseModal()}
        >
          ยกเลิก
        </button>
        <button
          className={classNames("btn-submit", isDisableBtn && "disable-btn")}
          disabled={isDisableBtn}
          onClick={() => handleSubmit()}
        >
          ตกลง
        </button>
      </ModalFooter>
      <ModalListSkuSelectedShop isOpen={promocode?.isShowModalListSkuSelectedShop} />
      <ModalListShopProductPromocode />
    </Modal>
  );
};

const useModalListShopSelectedShop = () => {
    const promocode = useSelector((state) => state.promocode);
    const listSelectedShop = useSelector((state) => state.listSelectedShop);
    const selectedShop = useSelector((state) => state.selectedShop);

  const dispatch = useDispatch();
  //   useEffect(() => {
  //     if (promocode.isShowModalListShopSelectedShop) {
  //       dispatch({
  //         type: OPEN_MODAL_LIST_SKU
  //       });
  //     }
  //   }, [promocode.isShowModalListShopSelectedShop])

  const handleCloseModal = () => {
    dispatch({
      type: SET_KEY_VALUE_PROMOCODE,
      payload: {
        key: "isShowModalListShopSelectedShop",
        value: false,
      },
    });
  };

  const handleDeleteShop = (shopId = null) => {
    dispatch(removeShopSelectedShop({payload: {[shopId]: null}}));
  };

  const handleEditShop = (shopId = null) => {
    // console.log("handleEditShop:>>",shopId)
    dispatch({
      type: OPEN_MODAL_LIST_SKU,
      payload: shopId
    });
  };

  const handleSubmit = () => {
    dispatch({
      type: SELECTED_SHOPJOIN_PRODUCT,
    });

    // close modal selected
    handleCloseModal();

    dispatch({
      type: OPEN_TOAST,
      payload: {
        type: 'success',
        message: 'ทำรายการสำเร็จ'
      }
    })
  };

  const handleOpenModalCheckedProduct = () => {
    dispatch({
      type: SET_KEY_VALUE_PROMOCODE,
      payload: {
        key: 'isShowModalListShopProductPromocode',
        value: true
      }
    })
  };

  return {
    promocode,
    selectedShop,
    listSelectedShop,
    handleCloseModal,
    handleDeleteShop,
    handleEditShop,
    handleSubmit,
    handleOpenModalCheckedProduct,
  };
};

export default ModalListShopSelectedShop;
