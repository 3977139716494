import React, { useEffect, useState } from "react";
import { InputSearch } from "components/output";
import { useDispatch, useSelector } from "react-redux";
import CardShop from "./cardShop";
import "./listCardShop.scss";
import { SELECT_SHOP_PRODUCT } from "redux/actions/promocode";
import { GET_SHOPS_SEARCH } from "redux/actions/shop";
import { find, isEmpty } from "lodash";
import IconNoItem from "images/icons/no-item.svg";
import { GET_SHOPS } from "redux/actions/shop";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadMore from "components/LoadMore";
import shopProvider from "provider/shopProvider";
import { ADD_CHECKED_SHOP_SELECT } from "../../redux/actions/checkedShop";

const ListCardShop = () => {
  const dispatch = useDispatch();

  const [inputSearchShop, setInputSearchShop] = useState("");
  const [statusSearch, setStatusSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [listShops, setListShops] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const { shops, shopsSearch } = useSelector((state) => state.shop);
  const { shopSelected = null } = useSelector((state) => state.promocode);
  const checkedShop = useSelector((state) => state.checkedShop);
  const selectedShop = useSelector((state) => state.selectedShop);
  const { loading } = useSelector((state) => state.app);

  const listShop = statusSearch ? shopsSearch?.shop : listShops;

  useEffect(() => {
    setListShops(shops);
    setStatusSearch(false);
    setInputSearchShop("");
    setPage(1)
  }, []);

  const onScroll = async () => {
    setPage(page + 1);
    
    const api_shop = new shopProvider();
    const res = await api_shop.getShops({ limit: 10, page: page + 1, sortBy: 'name'});

    if (isEmpty(res?.shop) || statusSearch) {
      setHasMore(false);
    } else {
      setListShops([...listShops, ...res?.shop]);
    }
  };

  const onChangeSearch = (e) => {
    setInputSearchShop(e?.target?.value);
  };

  const handleSearch = () => {
    if (inputSearchShop?.trim().length > 0) {
    setStatusSearch(true);
      dispatch({
        type: GET_SHOPS_SEARCH,
        payload: {
          keyword: inputSearchShop.trim(),
          sortBy: "name"
        },
      });
    }
  };

  const onClearSearch = () => {
    setInputSearchShop("");
    setStatusSearch(false);
    dispatch({
      type: GET_SHOPS_SEARCH,
      payload: {
        sortBy: "name"
      },
    });
  };

  const handleSelectShop = (shopId, detail) => {
    dispatch({
      type: SELECT_SHOP_PRODUCT,
      payload: {
        shopId: shopId,
      },
    });

    dispatch({
      type: "SET_KEY_VALUE_PROMOCODE",
      payload: {
        key: "shopSelectedDetail",
        value: detail,
      },
    });
    
    /* SET default shop  state check shop */
    const findByShopID = find(listShop, ['id', shopId])
    const vName = findByShopID?.name || ''
    const vLogoPath = findByShopID?.logoPath || {}
    const selectedShopAllProduct = selectedShop?.shops[` ${findByShopID?.id}`]?.allProduct
    const checkedShopAllProduct = checkedShop?.shops[` ${findByShopID?.id}`]?.allProduct
    const vAllProduct = checkedShopAllProduct != undefined ? checkedShopAllProduct :
      selectedShopAllProduct != undefined ? selectedShopAllProduct : false
    dispatch({
      type: ADD_CHECKED_SHOP_SELECT,
      payload: {
        [findByShopID?.id]: {
          name: vName,
          logoPath: vLogoPath,
          allProduct: vAllProduct,
        }
      }
    })
    /* SET default shop  state check shop */
  };

  const onKeyPress = (e) => {
    if (e?.key == "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="wrap-list-card-shop">
      <div className="cover-search">
        <InputSearch
          placeholder={"ค้นหาจากชื่อร้าน"}
          onChange={onChangeSearch}
          onKeyPress={onKeyPress}
          handleSearch={handleSearch}
          onClearSearch={onClearSearch}
          statusSearch={statusSearch}
          defaultValue={inputSearchShop}
        />
      </div>
      <div
        id="scrollDiv"
        style={{
          height: "calc(90vh - 310px)",
          overflowY: "auto",
          paddingRight: "8px",
        }}
      >
        <InfiniteScroll
          className="cover-list-card-shop"
          dataLength={listShop?.length || 0}
          next={onScroll}
          hasMore={hasMore}
          loader={<LoadMore />}
          scrollableTarget="scrollDiv"
        >
          {statusSearch && isEmpty(shopsSearch?.shop) && !loading ? (
            <div className="cover-search-empty">
              <img src={IconNoItem} alt="noItem" />
              <span className="text-search-empty">ไม่มีรายการ</span>
            </div>
          ) : (
            !isEmpty(listShop) &&
            listShop.map((item, index) => {
              return (
                <CardShop
                  data={item}
                  key={"card-shop" + index}
                  handleSelectShop={(shopId) => handleSelectShop(shopId, item)}
                  isActive={item?.id == shopSelected}
                  checkedShop={checkedShop}
                  selectedShop={selectedShop}
                />
              );
            })
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ListCardShop;
