export const GET_LIST_ORDER = "GET_LIST_ORDER"
export const SET_ORDER_FIELD = "SET_ORDER_FIELD"
export const ON_SEARCH_ORDER = "ON_SEARCH_ORDER"
export const SET_ORDERLIST_IN_ORDER = "SET_ORDERLIST_IN_ORDER"
export const OPEN_MODAL_INVOICE_DETAIL = "OPEN_MODAL_INVOICE_DETAIL"
export const OPEN_MODAL_COVER_SHEET_PRINT = "OPEN_MODAL_COVER_SHEET_PRINT"
export const UPDATE_INVOICE_SHOP_NOTE = "UPDATE_INVOICE_SHOP_NOTE"
export const UPDATE_INVOICE_LOGISTIC = "UPDATE_INVOICE_LOGISTIC"
export const SET_UPDATE_SHOP_NOTE_RES = "SET_UPDATE_SHOP_NOTE_RES"
export const SET_UPDATE_LOGISTIC_RES = "SET_UPDATE_LOGISTIC_RES"
export const SET_MODAL_REFUND_REASON = "SET_MODAL_REFUND_REASON"
export const REQUEST_REFUND = "REQUEST_REFUND"
export const ON_CONFIRM_REFUND = "ON_CONFIRM_REFUND"
export const SET_CLEARING_ORDER = "SET_CLEARING_ORDER"
export const OPEN_MODAL_CONFIRM_REFUND = "OPEN_MODAL_CONFIRM_REFUND"
export const CLOSE_MODAL_CONFIRM_REFUND = "CLOSE_MODAL_CONFIRM_REFUND"
export const ADD_CLEARING_ORDER = "ADD_CLEARING_ORDER"
export const SET_DATA_CLEARING_ORDER = "SET_DATA_CLEARING_ORDER" 
export const SELECT_ALL_CLEARING_ORDER = "SELECT_ALL_CLEARING_ORDER"
export const CLEAR_CLEARING_ORDER = "CLEAR_CLEARING_ORDER"
export const SET_SELECT_ALL_CLEARING = "SET_SELECT_ALL_CLEARING"
export const OPEN_MODAL_CONFIRM_CLEARING = "OPEN_MODAL_CONFIRM_CLEARING"
export const CLOSE_MODAL_CONFIRM_CLEARING = "CLOSE_MODAL_CONFIRM_CLEARING"
export const OPEN_MODAL_CONFIRM_CLEARING_ALL = "OPEN_MODAL_CONFIRM_CLEARING_ALL"
export const CLOSE_MODAL_CONFIRM_CLEARING_ALL = "CLOSE_MODAL_CONFIRM_CLEARING_ALL"
export const REDIRECT_TAB = "REDIRECT_TAB"
export const SET_COMPLETE_LOGISTIC = "SET_COMPLETE_LOGISTIC"
export const SET_LOGISTICS_BY_SHOP = "SET_LOGISTICS_BY_SHOP"
export const GET_ORDER_DELIVERIES = "GET_ORDER_DELIVERIES"
export const SET_ORDER_DELIVERIES = "SET_ORDER_DELIVERIES"
export const OPEN_MODAL_CONFIRM_LARGE = "OPEN_MODAL_CONFIRM_LARGE"
export const CLOSE_MODAL_CONFIRM_LARGE = "CLOSE_MODAL_CONFIRM_LARGE"
export const HANDLE_SET_REDELIVERY = "HANDLE_SET_REDELIVERY"
export const OPEN_MODAL_CHILDREN = "OPEN_MODAL_CHILDREN"
export const CLOSE_MODAL_CHILDREN = "CLOSE_MODAL_CHILDREN"
export const CONFIRM_ORDER = "CONFIRM_ORDER"
export const SET_DEFAULT_ORDER = "SET_DEFAULT_ORDER"
export const SET_STATUS_VOID_REFUND = "SET_STATUS_VOID_REFUND"

export function dispatchOrder(params) {
    return {
        type: params.type,
        payload: params.payload
    }
}

