import { displayDateThShortYear } from 'helper/timeUtil'
import numeral from 'numeral'
import React from 'react'
import './styles.scss'
import { ReactComponent as IconNoItem } from 'images/icons/no-item.svg'
import { Link } from 'react-router-dom'
import XlsxCreate from 'components/XlsxCreate'
import { setLocalStorage } from 'helper/utils'


const AdminTransfer = (props) => {
    const { transferList, page, limit, showButtonTransferred, showStatusWithdraw, fetchDataExcel, listAllShop, status, shopSelectedIndex,
        modalDetail} = props

    return (
        <div className='overflow-auto'>
            <div className='table-admin-transfer'>
                <div>ลำดับ</div>
                <div>ยืนยันการโอนเงินให้ร้านค้า</div>
                <div>สถานะการโอนเงินให้ร้านค้า</div>
                <div>Transaction number</div>
                <div>Vendor Code</div>
                <div>จำนวนเงินที่โอน</div>
                <div>รอบสรุปข้อมูล</div>
                <div>กำหนดโอนเงินโดยประมาณไม่เกิน</div>
                <div>ร้านค้า</div>
                <div>ชื่อนิติบุคคล/ชื่อบุคคลธรรมดา</div>
                <div>จำนวนรายการ</div>
                <div>ดาวน์โหลด excel</div>
                <div></div>
            </div>

            {transferList?.data?.length === 0 &&
                <div className='d-flex flex-column justify-items-center align-items-center' style={{ marginTop: '10rem', marginBottom: '10rem' }}>
                    <IconNoItem />
                    ไม่มีข้อมูล
                </div>
            }

            {transferList?.data?.map((list, idx) => (
                <div className="table-admin-transfer-content table-withdraw-content-color">
                    <div>{(page - 1) * limit + idx + 1}</div>
                    <div>{showButtonTransferred(list)}</div>
                    <div>{showStatusWithdraw(list?.status, list?.transferredDate, list?.attachment)}</div>
                    <div>{list?.refNumber}</div>
                    <div>{list?.shop?.partner?.value}</div>
                    <div className='color-success'>{numeral(list?.totalRevenue).format('0,0.00')}</div>
                    <div>{displayDateThShortYear(list?.settledDate, 'DD MMM YY')}</div>
                    <div>{displayDateThShortYear(list?.settlementPeriod, 'DD MMM YY')}</div>
                    <div>{list?.shop?.name}</div>
                    <div>{list?.shop?.shopTax?.name}</div>
                    <div>{list?.orderWithdraw?.length}</div>
                    <div><span className='color-success text-underline cursor-pointer'>
                        <XlsxCreate
                            handleFetchData={() => fetchDataExcel(list)}
                            fileName={`${listAllShop && listAllShop[shopSelectedIndex]?.label}-${list?.status === 'settled' ? 'รอโอนเงิน' : list?.status === 'transferred' ? 'โอนเงินให้ร้านค้าแล้ว' : ''}-${displayDateThShortYear(new Date(), 'DD-MM-YY')}`}
                            headerA1={'รายการโอนเงิน'}
                            numberFormat={'#,0.00'}
                            textRight={['K','L','M','N','O','P','Q','R','S','T','W','X','Y','Z','AA','AB']}
                        >
                            Download.excel
                        </XlsxCreate>
                    </span></div>
                    <div><Link to={{
                        pathname: `/manage/revenue/transfer/${list?.refNumber}`,
                        state: {
                            ...list,
                            listAllShop,
                            // fetchDataExcel: () => fetchDataExcel(),
                            shopSelectedIndex,
                            modalDetail
                        },
                    }} target="_blank" onClick={() => setLocalStorage({ key: `${list?.refNumber}`, value: { ...list, listAllShop, shopSelectedIndex, transferList, modalDetail } })} ><span className='color-success cursor-pointer'>ดูรายละเอียด</span></Link>
                    </div>
                </div>
            ))
            }

            {/* {transferList?.data?.length > 0 &&
                <div className="table-last-content-withdraw-detail">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className='price'>{numeral(transferList?.totalItemPrice).format('0,0.00')}</div>
                    <div className='price'>{numeral(transferList?.totalShippingFee).format('0,0.00')}</div>
                    <div className='price'>{numeral(transferList?.totalDiscountShippingShop).format('0,0.00')}</div>
                    <div className='price'>{numeral(transferList?.totalShippingFee - transferList?.totalDiscountShippingShop).format('0,0.00')}</div>
                </div>
            } */}
        </div>
    )
}

export default AdminTransfer