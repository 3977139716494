/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { faPen, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox } from '../../input'
import ModalPreview from '../ModalPreview'
import ProfileStorage from '../ProfileStorage'
import logoimage from '../../../images/icons/logo-v-avenue-media.png'

import './styles.scss'
import host from '../../../config/host'

const MediaCard = (props) => {
  const {
    data,
    statusContent,
    statusAction,
    onClickIconEdit,
    isEditName,
    onClickMediaCard,
    cardType,
    mediaSelected,
    onSelectMedia,
    preview,
    onClickRemoveImage,
  } = props

  let action = null
  let cover = null
  let footer = null
  let contentPreview = null

  const [isOpenModal, setIsOpenModal] = useState(false)

  switch (statusAction) {
    case 'close':
      action = (
        <div className='close-box' onClick={onClickRemoveImage}>
          x
        </div>
      )
      break

    case 'select-image':
      action = (
        <div className='select-image-box'>
          <Checkbox
            checking={
              mediaSelected.findIndex(
                (mediaSelect) => mediaSelect.id == data.id,
              ) == -1
                ? false
                : true
            }
            onSelectMedia={onSelectMedia}
            media={data}
          />
        </div>
      )
      break

    default:
      break
  }

  switch (statusContent) {
    case 'profile-shop':
      footer = <ProfileStorage detail={data} />
      break

    case 'edit-album':
      footer = (
        <EditAlbum
          detail={data}
          onClickIconEdit={onClickIconEdit}
          isEditName={isEditName}
        />
      )
      break

    default:
      break
  }

  switch (cardType) {
    case 'image':
      cover = (
        <div
          className='img-media-card-box'
          onClick={() => setIsOpenModal(true)}
        >
          {preview ? (
            <img alt='' className='img-media-card' src={data.preview} />
          ) : (
            <img
              alt=''
              className='img-media-card'
              src={
                data.thumbnail
                  ? `${host.avatar}${data.thumbnail}`
                  : data.path
                    ? `${host.avatar}${data.path}`
                    : logoimage
              }
            />
          )}
        </div>
      )

      contentPreview = preview ? (
        <img alt='name' className='img-preview' src={data.preview} />
      ) : (
        <img
          alt='name'
          className='img-preview'
          src={
            data.thumbnail
              ? `${host.avatar}${data.thumbnail}`
              : `${host.image}${data.path}`
          }
        />
      )

      break

    case 'video':
      cover = (
        <div
          className='video-media-card-box'
          onClick={() => setIsOpenModal(true)}
        >
          <video className='video-media-card'>
            <source src={`${host.video}${data.path}`} type='video/mp4' />
          </video>

          <FontAwesomeIcon
            icon={faPlayCircle}
            className='icon-play'
          />
        </div>
      )

      contentPreview = (
        <div className='video-preview'>
          <video controls>
            <source src={`${host.video}${data.path}`} type='video/mp4' />
          </video>
        </div>
      )

      break

    default:
      break
  }

  const onClickMedia = () => {
    onClickMediaCard({ shopDetail: data })
  }

  return data ? (
    <>
      <div
        className='container-media-card'
        onClick={onClickMediaCard ? onClickMedia : null}
      >
        <div className='media-card-box'>
          {action}
          {cover}
          {footer}
        </div>
      </div>

      <ModalPreview
        closeModal={() => setIsOpenModal(false)}
        isOpenModal={isOpenModal}
      >
        {contentPreview}
      </ModalPreview>
    </>
  ) : null
}

const EditAlbum = (props) => {
  const { detail, onClickIconEdit, isEditName } = props

  return (
    <div className='detail-box'>
      <div className='album-name ellipsis'>{detail.name}</div>
      {isEditName && (
        <FontAwesomeIcon
          icon={faPen}
          className='icon-edit'
          onClick={onClickIconEdit}
        />
      )}
    </div>
  )
}

MediaCard.defaultProps = {
  cardType: 'image',
  imagePath: '',
  isEditName: false,
  preview: false,
}

export default MediaCard
