import host from 'config/host'
import axios from 'axios'
import Cookies from 'js-cookie'

const liveChatAPI = `${host.liveChat}`

export const getChatRooms = async ({ limit, shopId, skip }) => {
  const room = await axios({
    method: 'GET',
    url: `${liveChatAPI}/room`,
    params: { limit, shopId, skip },
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  })

  if (room.status === 200) {
    return room.data
  }
}

export const searchChatRoom = async (keyword, shopId) => {
  const room = await axios({
    method: 'POST',
    url: `${liveChatAPI}/room/search/${keyword}`,
    data: {
      shopId: shopId
    },
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  })
  if (room.status === 200) {
    return room.data
  }
}

export const searchChatRoomAdmin = async (keyword) => {
  const room = await axios({
    method: 'POST',
    url: `${liveChatAPI}/room/search/${keyword}`
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  })
  if (room.status === 200) {
    return room.data
  }
}

export const getMessage = async (params) => {
  // /message?roomId=602b8be3c5ea814514b00201&limit=5
  const room = await axios({
    method: 'GET',
    url: `${liveChatAPI}/message`,
    params,
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  })

  if (room.status === 200) {
    return room.data
  }
}

export const updateRoom = async (roomId, data) => {
     const room = await axios({
      method: 'PATCH',
      url: `${liveChatAPI}/room/${roomId}`,
      data: data,
    })

    if (room.status === 200) {
      return room.data
    }
}

export const sendMessage = async (data) => {
  const room = await axios({
   method: 'POST',
   url: `${liveChatAPI}/raw-message`,
   data: data,
 })

 if (room.status === 200) {
   return room.data
 }
}

export const uploadMedia = async (file) => {
  
  const formData = new FormData()
  formData.append('file', file);
  formData.append('serviceKey', host.serviceKey);
  formData.append('secretKey', host.secretKey);

  const media = await axios({
   method: 'POST',
   url: `${host.apiMedia}/upload`,
   data: formData,
 })

 if (media.status === 200) {
   return media.data
 }
}

export const getListShop = async () => {
  const listShop = await axios({
    method: "GET",
    url: `${host.api}/shop-user/list-shop`,
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })

  if (listShop.status === 200) {
    return listShop
  }
}
export const getresponseMessage = async (id)=>{
  const res = await axios({
    method: 'GET',
    url: `${liveChatAPI}/response-message/${id}`
  })
  if (res.status === 200) {
    return res.data
  }
}

export const setresponseMessage = async (data)=>{
  const res = await axios({
    method: 'POST',
    // url: `${liveChatAPI}/response-message/${id}`
     url: `${liveChatAPI}/response-message`,
    data:data
  })
  if (res.status === 200) {
    return res.data
  }
}

export const updateresponseMessage = async (id,data)=>{
  const res = await axios({
    method: 'PATCH',
    // url: `${liveChatAPI}/response-message/${id}`
    url: `${liveChatAPI}/response-message/${id}`,
    data:data
  })
  if (res.status === 200) {
    return res.data
  }
}

// const genCode = async (id) => {

//     const code = await axios({
//         method: 'GET',
//         url: `${api.liveChat}/genCodeById/${id}`,
//         headers: {
//             'Content-Type': 'application/json',
//         }
//     })

//     return code.data.code
// }

// const getUserFromCode = async (code) => {

//     const user = await axios({
//         method: 'GET',
//         url: `${api.liveChat}/getUserByCode/${code}`,
//         headers: {
//             'Content-Type': 'application/json',
//         }
//     })

//     return user.data

// }

// const sendDataToLine = async (data) => {

//     await axios({
//         method: 'POST',
//         url: `${api.CONNECTOR_SOCIAL_API}/pushMessageToLine`,
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         data: data
//     })
// }

// const sendDataToLine_Promise = async (params) => {

//     return new Promise(async (resolve, reject) => {
//         try {
//             const result = await axios({
//                 method: 'POST',
//                 url: `${api.CONNECTOR_SOCIAL_API}/pushMessageToLine`,
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 data: params
//             });
//             resolve(result);
//         } catch (error) {
//             reject(error);
//         }
//     })
// }

// const pushBtnPaymentToLine = async (params) => {

//     return new Promise(async (resolve, reject) => {
//         const { lineId } = params;
//         try {
//             const result = await axios({
//                 method: 'POST',
//                 url: `${api.CONNECTOR_SOCIAL_API}/pushBtnPaymentToLine`,
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 data: {
//                     lineId: lineId
//                 }
//             });
//             resolve(result);
//         } catch (error) {
//             reject(error);
//         }
//     })
// }

// const getTagColors = async () => {
//     const color = await axios({
//         method: 'GET',
//         url: `${api.SOCKET_API}/colorTags`,
//         headers: {
//             'Content-Type': 'application/json',
//         }
//     })

//     return color.data
// }

// const getTags = async () => {
//     const tags = await axios({
//         method: 'GET',
//         url: `${api.SOCKET_API}/Tags`,
//         headers: {
//             'Content-Type': 'application/json',
//         }
//     })

//     return tags.data
// }

// const getTagsById = async (id) => {
//     const tags = await axios({
//         method: 'GET',
//         url: `${api.SOCKET_API}/Tags/${id}`,
//         headers: {
//             'Content-Type': 'application/json',
//         }
//     })

//     return tags.data
// }

// const createTags = async (data) => {
//     await axios({
//         method: 'POST',
//         url: `${api.SOCKET_API}/createTag`,
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         data: data
//     })
// }

// const addTags = async (data) => {
//     await axios({
//         method: 'POST',
//         url: `${api.SOCKET_API}/addTag`,
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         data: data
//     })
// }

// const updateTimeUploadImage = async (data) => {
//     await axios({
//         method: 'PATCH',
//         url: `${api.SOCKET_API}/message`,
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         data: data
//     })
// }

// export genCode,
// getUserFromCode,
// sendDataToLine,
// sendDataToLine_Promise,
// getTagColors,
// getTags,
// getTagsById,
// createTags,
// addTags,
// pushBtnPaymentToLine,
// updateTimeUploadImage,
