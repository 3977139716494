import React, { useCallback, useEffect, useState } from 'react'
import {
    // Button,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from 'reactstrap';
import classnames from 'classnames'
import closeIcon from '../../images/icons/iconClose.svg'
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';

import Pagination from 'components/pagination'
import { ButtonAdd, InputSearch } from 'components/output';
import SelectInput from 'components/SelectInput';
import iconNoItem from 'images/icons/no-item.svg'
import IconCloseCircle from 'images/icons/close-circle.svg'

import {
    SELECTED_PRODUCT_PROMOCODE,
    SET_KEY_VALUE_PROMOCODE,
    OPEN_MODAL_CONFIRM_PROMOCODE,
    SUBMIT_PRODUCT_PROMOCODE,
    SELECT_SHOP_PRODUCT,
    UPDATE_TYPE_PRODUCT_SELECTION_PROMOCODE
} from 'redux/actions/promocode';
import {
    ADD_KEY_VALUE_PRODUCT,
    HANDLE_SEARCH_PRODUCT,
    GET_LIST_PRODUCT_PROMOCODE
} from 'redux/actions/product';
import {
    UNCHECK_ALL_PRODUCT_PROMOCODE,
} from 'redux/actions/checkedProduct'
import { SET_LOADING } from 'redux/actions/app';
import checkedShop from 'redux/reducers/checkedShop';
import EditProductRadio from './editProductRadio';
import { ADD_CHECKED_SHOP_SELECT, COUNT_CHECKED_ALL_SHOP, RESET_CHECKED_SHOP, UNCHECK_ALL_SHOP_PRODUCT_CHECKED_SHOP, dispatchCheckedShop } from 'redux/actions/checkedShop';
import CardShopProduct from './cardShopProduct';
import ListCardShop from './listCardShop';
import { SET_KEY_LIST_CHECKED_SHOP } from 'redux/actions/listCheckedShop';


const listSort = [
    { value: "name", label: "เรียงจากชื่อ" },
    { value: "createdAt", label: "อัปโหลดสินค้าล่าสุด" }
]

const ModalListShopProductPromocode = () => {
    const [search, setSearch] = useState('')
    const [statusSearch, setStatusSearch] = useState(false)
    const [dataTypeAllProduct, setDataTypeAllProduct] = useState('some')
    const [historySearch, setHistorySearch] = useState([])
    const [sortBy, setSortBy] = useState(0)
    const [isDisableBtn, setIsDisableBtn] = useState(true)
    const [countProduct, setCountProduct] = useState(0)
    const [countSku, setCountSku] = useState(0)
    const promocodeState = useSelector(state => state.promocode)
    const checkedShopState = useSelector(state => state.checkedShop)
    const selectedShopState = useSelector(state => state.selectedShop)
    const stateProduct = useSelector(state => state.product);
    const shopState = useSelector(state => state.shop)


    const id = ` ${promocodeState?.shopSelected}`
    const isAllProduct = (checkedShopState?.shops[id]?.allProduct)
    const isAllProducrOld = (selectedShopState?.shops[id]?.allProduct)
    const count = isAllProduct ? (selectedShopState?.shops[id]?.count) : isAllProducrOld != isAllProduct ? (checkedShopState?.shops[id]?.count) : (checkedShopState?.shops[id]?.count || 0) + (selectedShopState?.shops[id]?.count || 0)
    const sku = isAllProduct ? (selectedShopState?.shops[id]?.sku) : isAllProducrOld != isAllProduct ? (checkedShopState?.shops[id]?.sku) : (checkedShopState?.shops[id]?.sku || 0) + (selectedShopState?.shops[id]?.sku || 0)
    const { shopSelected } = promocodeState;



    const { product, limit, page, loading, total, pageSizeOptions, valueSearch, sort } = stateProduct;

    const dispatch = useDispatch()

    const checkedShopAllProduct = checkedShopState?.shops[` ${promocodeState?.shopSelected}`]?.allProduct
    const selectedShopAllProduct = selectedShopState?.shops[` ${promocodeState?.shopSelected}`]?.allProduct
    const typeAllProduct = checkedShopAllProduct != undefined ? checkedShopAllProduct ? 'all' : 'some' :
        selectedShopAllProduct != undefined ? selectedShopAllProduct ? 'all' : 'some' : 'some'

    useEffect(() => {
        setDataTypeAllProduct(typeAllProduct)
    }, [typeAllProduct]);
    const { isShowModalListShopProductPromocode } = promocodeState //modalstate

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: e.target.value } })
    }

    const onSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            if (search.trim().length === 0) {
                onClearSearch()
            }
        }
    }
    const onClearSearch = useCallback(() => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
        setSearch('')
        setStatusSearch(false)
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } })


        if(search.trim().length > 0) {
        dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { id: promocodeState.shopSelected } })
        }
    })

    const onClickSeachProduct = () => {
        if (search.trim().length === 0) {
            setStatusSearch(false)
            setHistorySearch(search)
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } })
            // dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { id: promocodeState.shopSelected } })

        } else {
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: true } })
            setStatusSearch(true)
            setHistorySearch(search)
            dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { id: promocodeState.shopSelected } })

        }
    }

    const clearModal = useCallback(() => {
        dispatch({ type: SET_LOADING, payload: { loading: false } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: 0 } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'product', value: {} } })
        dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'listCheckedProduct', value: {} } })
        dispatch({ type: UNCHECK_ALL_PRODUCT_PROMOCODE })
    })

    const onChangePage = (page) => {
        if (statusSearch) {
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: page } })
            dispatch({ type: HANDLE_SEARCH_PRODUCT, payload: { search: historySearch } })
        } else {
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: page } })
            dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { id: promocodeState.shopSelected } })
        }
    }
    const onShowSizeChange = (current, pageSize) => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'limit', value: pageSize } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: current } })
    }
    const handleSelectSort = (value) => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'sort', value: listSort[value].value } })
        setSortBy(value)
        dispatch({ type: GET_LIST_PRODUCT_PROMOCODE, payload: { id: promocodeState.shopSelected } })
    }

    const closeModal = () => {
        if (checkedShop.count == 0) {
            dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'isShowModalListShopProductPromocode', value: false } })
            clearModal()
        } else {
            dispatch(
                {
                    type: OPEN_MODAL_CONFIRM_PROMOCODE,
                    payload: {
                        isOpen: true, message: 'คุณแน่ใจที่จะยกเลิกการทำรายการใช่หรือไม่',
                        confirm: () => {
                            clearModal()
                            dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'isShowModalListShopProductPromocode', value: false } })
                            dispatch({ type: RESET_CHECKED_SHOP })
                            dispatch({ type: SET_KEY_LIST_CHECKED_SHOP, payload: { listCheckedShop: {} } })
                            dispatch({ type: OPEN_MODAL_CONFIRM_PROMOCODE, payload: { isOpen: false, message: '', } })
                        },
                        isClose: () => { dispatch({ type: OPEN_MODAL_CONFIRM_PROMOCODE, payload: { isOpen: false, message: '', } }) }
                    }
                })
        }

    }

    const confirmModal = () => {
        dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'isShowModalListShopProductPromocode', value: false } })
        dispatch({ type: SELECTED_PRODUCT_PROMOCODE })
    }

    const handleUnCheckAllProductCheckShop = (shopId) => {

        /* 
            UNCHECK_ALL_SHOP_PRODUCT_CHECKED_SHOP
            payload  : (Object) {['shopID']: null}
        */

        dispatch(
            dispatchCheckedShop(
                {
                    type: UNCHECK_ALL_SHOP_PRODUCT_CHECKED_SHOP,
                    payload: { [`${shopId}`]: null }
                }
            )
        )

        dispatch({
            type: COUNT_CHECKED_ALL_SHOP,
            payload: { shopId: `${shopId}` }
        })
    }

    const handleUpdateTypeProductSelectionPromocode = (type) => {
        /* 
            UPDATE_TYPE_PRODUCT_SELECTION_PROMOCODE
            payload  : {
                isAllProduct: Boolean,
                shopId: Number,
            }
        */
        dispatch({
            type: UPDATE_TYPE_PRODUCT_SELECTION_PROMOCODE,
            payload:
            {
                isAllProduct: type === 'all' ? true : false,
                shopId: `${promocodeState?.shopSelected}`,
            }
        })
    }

    const objSku = (productSkus) => {
        const sku = {}
        if (typeof productSkus === 'object') {
            for (const [key, value] of Object.entries(productSkus)) {
                Object.assign(sku, { [key]: true })
            }
            return sku
        } else {
            return {}
        }
    }

    useEffect(()=>{
        if(!isShowModalListShopProductPromocode){
            dispatch({
                type: SET_KEY_VALUE_PROMOCODE,
                payload: { key: 'shopSelected', value: null }
            })
        }
    },[dispatch, isShowModalListShopProductPromocode])

    useEffect(() => {
        let timenumber = 0;
        if (isShowModalListShopProductPromocode)
            if (!loading) {
                // set
                dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
                dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'limit', value: 30 } })
                dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'sort', value: listSort[0].value } })
                dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } })
                // timenumber = setTimeout(() => {
                //     dispatch({ type: SET_LOADING, payload: { loading: true } })
                // }, 1000);
                // dispatch({ type: GET_LIST_PRODUCT_PROMOCODE })
                dispatch({ type: SELECT_SHOP_PRODUCT, payload: { shopId: shopState?.shops[0]?.id } })

                /* SET default shop  state check shop */
                const vName = shopState.shops[0]?.name || ''
                const vLogoPath = shopState.shops[0]?.logoPath || {}
                const selectedShopAllProduct = selectedShopState?.shops[` ${shopState.shops[0].id}`]?.allProduct
                const checkedShopAllProduct = checkedShopState?.shops[` ${shopState.shops[0].id}`]?.allProduct
                const vAllProduct = checkedShopAllProduct != undefined ? checkedShopAllProduct :
                    selectedShopAllProduct != undefined ? selectedShopAllProduct : false
                dispatch({
                    type: ADD_CHECKED_SHOP_SELECT,
                    payload: {
                        [shopState?.shops[0].id]: {
                            name: vName,
                            logoPath: vLogoPath,
                            allProduct: vAllProduct,
                        }
                    }
                })
                /* SET default shop  state check shop */

            } else {
                // clearTimeout(timenumber)
                // dispatch({ type: SET_LOADING, payload: { loading: false } })
            }
        else {
            clearModal()
        }

    }, [checkedShopState.shops, clearModal, dispatch, isShowModalListShopProductPromocode, loading, selectedShopState.shops, shopState.shops])

    useEffect(() => {
        if(shopSelected){
            if( checkedShopState.countAllProduct > 0 && typeAllProduct === 'all' ){
                setIsDisableBtn(true)
            }

            if(Object.keys(checkedShopState.shops).length > 0 ) {
                if( checkedShopState.shops[` ${shopSelected}`].count > 0 && typeAllProduct === 'some' ) {
                    setIsDisableBtn(false)
                } else {
                    setIsDisableBtn(true)
                }
            }
        }
    }, [checkedShopState, shopSelected, typeAllProduct]);

    useEffect(() => {
        onClearSearch()
        const id = ` ${promocodeState.shopSelected}`

        return () => {
        }
    }, [onClearSearch, promocodeState.shopSelected])

    useEffect(() => {
        const callCount = countChecked()
        setCountProduct(callCount.count)
        setCountSku(callCount.sku)
    }, [checkedShopState, countChecked, selectedShopState])


    const countChecked = () => {
        let count = selectedShopState.countAllProduct
        let sku = selectedShopState.countAllSKU
        const id = ` ${promocodeState.shopSelected}`
        const checkedShop = checkedShopState
        const selectedShop = selectedShopState
        count -= (selectedShopState?.shops[id]?.count || 0)
        sku -= (selectedShopState?.shops[id]?.sku || 0)

        if (isAllProducrOld != undefined && isAllProduct != undefined) {
            if (isAllProduct !== isAllProducrOld) {
                count += (checkedShop?.shops[id]?.count || 0)
                sku += (checkedShop?.shops[id]?.sku || 0)

                return { count, sku }
            } else {
                if (isAllProduct == true) {
                    count += (checkedShop?.shops[id]?.count || 0)
                    sku += (checkedShop?.shops[id]?.sku || 0)
                    if (isAllProducrOld == true) {
                        if (checkedShop?.shops[id]?.count == 0 && selectedShop?.shops[id]?.count != 0) {
                            count += (selectedShop?.shops[id]?.count || 0)
                            sku += (selectedShop?.shops[id]?.sku || 0)
                        }

                    }
                    return { count, sku }
                }
                count += (checkedShop?.shops[id]?.count || 0)
                sku += (checkedShop?.shops[id]?.sku || 0)

                count += (selectedShop?.shops[id]?.count || 0)
                sku += (selectedShop?.shops[id]?.sku || 0)

                return { count, sku }
            }

        } else {
            if (isAllProduct != undefined) {
                count += (checkedShop?.countAllProduct || 0)
                sku += (checkedShop?.countAllSKU || 0)

                return { count, sku }
            }
            if (isAllProducrOld != undefined) {
                count += (selectedShop?.shops[id]?.count || 0)
                sku += (selectedShop?.shops[id]?.sku || 0)

                return { count, sku }
            }
        }
        return { count, sku }
    }



    return (
        <React.Fragment>
            <Modal
                isOpen={isShowModalListShopProductPromocode}
                // isOpen={true}
                toggle={closeModal}
                modalClassName="modal-list-shop-product-promocode"
                centered={true}
            >
                <ModalHeader style={{ padding: '0.5rem 1rem', border: 'none' }}>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <div>
                            <span className="title">{'เลือกร้านค้า และสินค้า'}</span>
                            <span className="sub">(เลือกแล้ว <span className="sub-txt">{checkedShopState.countAllShop + selectedShopState.countAllShop}</span> ร้านค้า <span className="sub-txt">{countProduct}</span> สินค้า <span className="sub-txt">{countSku}</span> SKU)</span>
                        </div>

                        {/* <img src={closeIcon} alt="" onClick={isClose} className="cursor-pointer" /> */}
                    </div>

                </ModalHeader>
                <ModalBody>
                    <div className="body-content m-auto">
                        <div className='cover-title-modal-select-product'>
                            <div className='container-left'>
                                <span className='text-title'>เลือกร้านค้า</span>
                            </div>
                            <div className='container-right'>
                                <span className='text-title'>เลือกสินค้าภายในร้าน</span>
                            </div>
                        </div>
                        <div className="container-shop-product">
                            <div className='list-shop-container'>
                                <ListCardShop />
                            </div>
                            <div className='list-product-container'>
                                {/* <div className="bg-white"> */}
                                {/* ///// */}
                                <EditProductRadio
                                    callbackData={(e) => handleUpdateTypeProductSelectionPromocode(e)}
                                    type={dataTypeAllProduct}
                                />
                                {/* //////// */}
                                <div className="d-flex justify-content-between align-items-center w-100" style={{ gap: '10px' }}>
                                    <InputSearch
                                        placeholder={"ค้นหาจากชื่อสินค้า, ประเภทสินค้า, แบรนด์, ตัวเลือกสินค้า, เลขอ้างอิง SKU"}
                                        onChange={onChangeSearch}
                                        onKeyPress={onSearchKeyPress}
                                        handleSearch={onClickSeachProduct}
                                        onClearSearch={onClearSearch}
                                        statusSearch={statusSearch}
                                        // defaultValue={valueSearch}
                                        defaultValue={valueSearch}
                                    />


                                    <div className='dropdown'>
                                        <SelectInput
                                            options={listSort}
                                            placeholder=''
                                            onChange={(value) => { handleSelectSort(value) }}
                                            value={sortBy}
                                            iconClearSearch={false}
                                            width={'12.5rem'}
                                        />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center w-100 py-2 mt-2">
                                    <span className="text-teriary">ทั้งหมด {total}  สินค้า</span>
                                    <button
                                        className={classnames("btn-submit", { "disable-btn": isDisableBtn })}
                                        disabled={isDisableBtn}
                                        style={{ padding: '0px', minWidth: '158px', borderRadius: '5px' }}
                                        onClick={() => handleUnCheckAllProductCheckShop(promocodeState.shopSelected)}>
                                        <img src={closeIcon} alt="icon-close" className='icon-close' style={{ paddingRight: '6px' }} />{'ยกเลิกที่เลือกทั้งหมด'}
                                    </button>
                                </div>


                                {(total === 0 || Object.values(product)?.length === 0) ?
                                    (
                                        <div className="no-list-card">
                                            <div><img src={iconNoItem} alt="noitem" /></div>
                                            <div className="font-weight-bold" >ไม่มีรายการ</div>
                                        </div>

                                    ) : (
                                        <div className="list-card">
                                            {
                                                Object.keys(product)?.map((key) => {
                                                    const item = product[key]
                                                    return <CardShopProduct
                                                        key={`card-product-${key}`}
                                                        objSku={objSku}
                                                        product={item}
                                                        shopId={` ${promocodeState.shopSelected}`}
                                                        isAllProduct={dataTypeAllProduct === 'all' ? true : false}
                                                    />
                                                })}
                                        </div>
                                    )}

                                <div className="cover-pagination-select-product">
                                    <Pagination
                                        total={total}
                                        defaultCurrent={1}
                                        onChange={onChangePage}
                                        pageSize={limit}
                                        pageSizeOptions={pageSizeOptions}
                                        onShowSizeChange={onShowSizeChange}
                                        current={page}
                                        showQuickJumper={true}
                                    />
                                </div>
                            </div>


                        </div>
                        {/* </div> */}
                        {/* <div className="bg-grey">
                            <div className="pagination d-flex justify-content-end align-items-center w-100">
                                <Pagination
                                    total={total}
                                    defaultCurrent={1}
                                    onChange={onChangePage}
                                    pageSize={limit}
                                    pageSizeOptions={pageSizeOptions}
                                    onShowSizeChange={onShowSizeChange}
                                    current={page}
                                    showQuickJumper={true}
                                />
                            </div>
                        </div> */}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="footer">
                        <span><button className="btn-cancel" onClick={closeModal}>{'ยกเลิก'}</button></span>
                        <span><button className="btn-submit" onClick={() => dispatch({ type: SUBMIT_PRODUCT_PROMOCODE })}>{'ตกลง'}</button></span>
                    </div>

                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default ModalListShopProductPromocode