export const SET_FORM_CREATE_BROADCAST = "SET_FORM_CREATE_BROADCAST"
export const CLEAR_FORM_DATA_BROADCAST = "CLEAR_FORM_DATA_BROADCAST"
export const OPEN_MODAL_PREVIEW_BROADCAST = "OPEN_MODAL_PREVIEW_BROADCAST"
export const CLOSE_MODAL_PREVIEW_BROADCAST = "CLOSE_MODAL_PREVIEW_BROADCAST"

export function dispatchBroadcast(param) {
  return {
    type: param.type,
    payload: param.payload,
  };
}