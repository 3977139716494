import { Body1, H5 } from 'components/FontStyle'
import InputText from 'components/InputText'
import { ButtonUpload } from 'components/output'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    
`

const ContentAdd = styled.form`

`

const AddMall = () => {
    return (
        <Container>
            <H5>เพิ่ม Mall</H5>

            <ContentAdd>
                <div className="d-flex flex-column">
                    <H5>โลโก้ Mall</H5>
                    <Body1>รองรับสัดส่วนภาพ 1:1 เท่านั้น (สี่เหลี่ยมจัตุรัส) ขนาดรูปแนะนำคือ 640 x 640 พิกเซล</Body1>
                    <ButtonUpload

                    />

                    <InputText
                        label={'ชื่อ Mall'}
                        title={'จำกัด 200 ตัวอักษร'}
                        placeholder={'กรอกชื่อ Mall'}
                    />
                </div>


            </ContentAdd>

        </Container>
    )
}

export default AddMall
