/* eslint-disable no-unused-vars */

import React, { Component } from 'react'
import videojs from 'video.js'
import '@videojs/http-streaming';
// import 'videojs-contrib-media-sources'
// import 'videojs-contrib-hls'
import { connect } from 'react-redux'
import './index.scss';


function openFullscreen(elem) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
}

function closeFullscreen(elem) {
  if (elem.exitFullscreen) {
    elem.exitFullscreen();
  } else if (elem.mozCancelFullScreen) {
    elem.mozCancelFullScreen();
  } else if (elem.webkitExitFullscreen) {
    elem.webkitExitFullscreen();
  } else if (elem.msExitFullscreen) {
    elem.msExitFullscreen();
  }
}

class VideoPlayer extends Component {


    state = {
    key: 0,
    duration: null,
    time: null,
    secondsElapsed: null,
    playing: false,
    fullScreen: this.props.fullScreen
  }

    static getDerivedStateFromProps(props, state) {
    state.fullScreen = props.fullScreen;
    return state
  }

  componentDidMount() {
    if (this.player) {
      const player = videojs(this.player,{
        html5: {
          vhs: {
            overrideNative: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        }
      });
      const { isMute } = this.props
      player.on('ready', ()=> {
        setTimeout(() => {
          player.autoplay('muted');
        }, 1000);

      });
      player.on('error',(err)=>{
        
        player.preload(true)
      })
    }
  }


    onError = (err, data) => {
    
    this.setState({ playing: false });
    // setTimeout(() => {
    //   ReactPlayer.canPlay(this.props.source)
    //   this.setState({ key: this.state.key + 1 });
    // }, 1500);
  }


  render() {
    const {display} = this.props;
    return (
      <React.Fragment>
        <video
          style={{display: `${display===true  ? 'block' : 'none'}`}}
          ref={(player) => { this.player = player }}
          width={this.state.fullScreen ? '100%' : this.props.width}
          height={this.state.fullScreen ? '100%' : this.props.height}
          className="video-js"
          controls={false}
          playsinline={true}
          // muted={false}
          onError={this.onError}
          data-setup='{}'
        >
          <source src={this.props.source} type="application/x-mpegURL" />
        </video>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isMute: state.live.isMute,
})

export default connect(mapStateToProps)(VideoPlayer)