export const ADD_KEY_VALUE_CATEGORIES = "ADD_KEY_VALUE_CATEGORIES"
export const GET_CATEGORIES = "GET_CATEGORIES"
export const SEARCH_CATEGORIES = "SEARCH_CATEGORIES"
export const SET_LEVEL_CATEGORIES = "SET_LEVEL_CATEGORIES"
export const SET_MODAL_CATEGORY = "SET_MODAL_CATEGORY"

export const GET_PARENT_CATEGORIES_ALL = "GET_PARENT_CATEGORIES_ALL"
export const GET_CHILD_CATEGORIES = "GET_CHILD_CATEGORIES"
export const CREATE_CATEGORIES = "CREATE_CATEGORIES"
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES"
export const DELETE_CATEGORIES = "DELETE_CATEGORIES"

export const FETCH_CATEGORIES = "FETCH_CATEGORIES"

export const FETCH_CATEGORY_ALL_LEVEL = "FETCH_CATEGORY_ALL_LEVEL"
export const GET_CATEGORY_ALL = "GET_CATEGORY_ALL"

export const SET_CATEGORY_SYSTEM = "SET_CATEGORY_SYSTEM"
export const SET_CATEGORY_SHOP = "SET_CATEGORY_SHOP"


export function dispatchCategories(param) {
  return {
    type: param.type,
    payload: param.payload,
  };
}