import React, { Component } from 'react'
import classnames from 'classnames'
import 'styles/_ribbon.scss'
import { ButtonAdd, 
    // ButtonUpload
 } from 'components/output';
import ModalConfirmChildrenHeader from 'components/ModalConfirmChidrenHeader';
import ModalAddRibbon from 'components/ModalAddRibbon';
import ModalSelectProduct from 'components/ModalSelectProduct';
import { connect } from 'react-redux'
import {
    ADD_PRODUCT_SELECT_RIBBON, dispatchRibbon, GET_DATA_ADD_PRODUCT, GET_LIST_DECORATE_GROUP, GET_LIST_RIBBON,
    HANDLE_CHANGE_PAGE_RIBBON, HANDLE_CONFIRM_ADD_PRODUCT, HANDLE_CREATE_DECORATE_GROUP, HANDLE_DELETE_DECORATE,
    HANDLE_DELETE_DECORATE_GROUP, HANDLE_SET_PERMISSION_DECORATE, HANDLE_UPDATE_STATUS_DECORATE_GROUP, SET_DATA_RIBBON_DEFAULT, SET_KEY_DATA_RIBBON,
    SET_KEY_RIBBON, SET_MODAL_ADD_PRODUCT_RIBBON, SET_MODAL_CONFIRM_RIBBON, SET_MODAL_CREATE_CATEGORIES, SET_MODAL_CREATE_RIBBON,
    SET_PAGINATION_PRODUCT, SET_PAGINATION_RIBBON
} from 'redux/actions/ribbon';
import { isEqual } from 'lodash';
import ToastNotification from 'components/ToastNotification';
import { ReactComponent as IconTrash } from 'images/icons/icon-pure-trash.svg'
import ModalConfirm from 'components/modalConfirm/ModalConfirm';
import { getUserRole } from 'helper/utils';
import { OWNER } from 'layout/Sidebar/Role';
import PaginationComponent from 'components/pagination';
import { dispatchGallery, SET_GALLERY_FIELD } from 'redux/actions/gallery';
import ListRibbonTable from 'components/ListRibbonTable';
import Loading from '../components/Loading'
import { H5, Tooltip } from 'components/FontStyle';


const ribbin = [{ label: 'ริบบิ้น', value: 'ribbon' }, { label: 'กรอบรูปสินค้า', value: 'frame' }]


class Ribbon extends Component {
    constructor(props) {
        super(props)
        this.state = {
            decorateGroupName: '',
            executePermission: false
        }
    }

    componentDidMount() {
        ////// get decorat list //////////
        this.props.dispatch(dispatchRibbon({ type: GET_LIST_DECORATE_GROUP }))
        this.props.dispatch(dispatchRibbon({ type: GET_DATA_ADD_PRODUCT }))
    }

    componentWillUnmount() {
        this.props.dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'activeTab', value: 'ribbon' } }))
    }

    componentDidUpdate(prevProps) {
        if (prevProps.gallery !== this.props.gallery) {
            this.setImageId(this.props.gallery)
        }
        if (prevProps.ribbon !== this.props.ribbon) {
            this.setState({ ...this.state })
        }

        if (!isEqual(prevProps.ribbon.dataRibbon, this.props.ribbon.dataRibbon) && this.props.ribbon.modalCreateRibbon.type === "edit") {
            this.setImageId(this.props.gallery?.length > 0 ? this.props.gallery[0] : this.props.ribbon.dataRibbon.imagePath)
        }

        if (prevProps.ribbon.modalAddProduct.isOpen && !this.props.ribbon.modalAddProduct?.isOpen) {
            this.props.dispatch({ type: SET_KEY_RIBBON, payload: { key: 'shopSelectFilter', value: {} } })
            this.props.dispatch({ type: SET_PAGINATION_PRODUCT, payload: { page: 1, limit: 10, total: null } })
            this.props.dispatch({ type: GET_DATA_ADD_PRODUCT })
        }

        if (prevProps.ribbon.modalCreateRibbon.isOpen && !this.props.ribbon.modalCreateRibbon.isOpen) {
            this.clearImagePreview()
            this.props.dispatch(dispatchRibbon({ type: SET_DATA_RIBBON_DEFAULT }))
        }

        if (prevProps.ribbon.modalCreateCategories.isOpen && !this.props.ribbon.modalCreateCategories.isOpen) {
            this.props.dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'errDecorateName', value: false } }))
        }
        //open
        if (!prevProps.ribbon.modalCreateCategories.isOpen && this.props.ribbon.modalCreateCategories.isOpen && this.props.ribbon.modalCreateCategories.type === "edit") {
            this.setState({ decorateGroupName: this.props.ribbon.decorateTab?.name })
        }
        //close
        if (prevProps.ribbon.modalCreateCategories.isOpen && !this.props.ribbon.modalCreateCategories.isOpen && prevProps.ribbon.modalCreateCategories.type === "create") {
            this.setState({ decorateGroupName: '', decorateName: '' })

        }
    }

    clearImagePreview = () => {
        this.setState({ imagePreview: null })
        this.props.dispatch(dispatchGallery({ type: SET_GALLERY_FIELD, payload: { key: 'ribbon', value: [] } }))
    }

    setImageId = (gallery) => {
        const { ribbon } = this.props
        const { modalCreateRibbon } = ribbon
        if (modalCreateRibbon.type === 'create') {
            this.setState({ imagePreview: gallery && gallery[0] })
            this.props.dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: 'imageId', value: gallery && gallery[0]?.id } }))
        } else if (modalCreateRibbon.type === "edit") {
            this.setState({ imagePreview: gallery && gallery })
            this.props.dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: 'imageId', value: gallery && gallery.id } }))
        }
    }

    handleSetActiveTab = (ribbon) => {
        const { dispatch } = this.props
        dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'activeTab', value: ribbon.value } }))
        this.props.dispatch(dispatchRibbon({ type: GET_LIST_DECORATE_GROUP }))
    }

    handleSetCategoriesTab = (decorate) => {
        const { dispatch } = this.props
        dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'decorateTab', value: decorate } }))
        dispatch(dispatchRibbon({ type: GET_LIST_RIBBON }))
    }

    addProduct = (e) => {
        e.stopPropagation()
    }

    handleSelectProduct = (item) => {
        const { dispatch } = this.props
        dispatch(dispatchRibbon({ type: ADD_PRODUCT_SELECT_RIBBON, payload: { product: item } }))
    }

    handleCheck = (product) => {
        const { listProductSelected } = this.props.ribbon
        const idSelected = [...listProductSelected.map((item) => item.id)]
        return idSelected.includes(product.id)
    }

    handleConfirmAddProduct = () => {
        const { dispatch } = this.props
        dispatch(dispatchRibbon({ type: HANDLE_CONFIRM_ADD_PRODUCT }))
    }

    handleSetPermissionDecorate = (id) => {
        const { dispatch } = this.props
        dispatch(dispatchRibbon({ type: HANDLE_SET_PERMISSION_DECORATE, payload: { id, priority: 1 } }))

    }

    handleClickBtnModalConfirm = async (type) => {
        const { dispatch, ribbon } = this.props
        switch (type) {
            case 'delete':
                return dispatch(dispatchRibbon({ type: HANDLE_DELETE_DECORATE, payload: { id: ribbon.modalConfirm.data.id } }))
            case 'applyall':
                return this.handleSetPermissionDecorate(ribbon.modalConfirm.data.id)
            case 'deletedecorategroup':
                return dispatch(dispatchRibbon({ type: HANDLE_DELETE_DECORATE_GROUP, payload: { id: ribbon.modalConfirm.data.id } }))
            default:
                break;
        }
    }

    executePriority = async (type) => {
        this.handleClickBtnModalConfirm(type)
    }



    validateCreateDecorateGroup = () => {
        const { dispatch, ribbon } = this.props
        let validate = false
        if (ribbon.decorateName == null || ribbon.decorateName.trim().length === 0) {
            dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'errDecorateName', value: { err: true, message: 'กรุณากรอกชื่อ' } } }))
        } else {
            validate = true
        }
        if (validate) {
            dispatch(dispatchRibbon({ type: HANDLE_CREATE_DECORATE_GROUP, payload: { name: ribbon.decorateTab.name } }))
        }
    }

    handleCheckTypeModalDecorateGroup = (type) => {
        const { dispatch } = this.props
        switch (type) {
            case 'create':
                return this.validateCreateDecorateGroup()
                // eslint-disable-next-line no-unreachable
                break;
            case 'edit':
                return dispatch(dispatchRibbon({ type: HANDLE_UPDATE_STATUS_DECORATE_GROUP, payload: { name: this.state.decorateGroupName } }))
            default:
                break;
        }
    }

    onChangePage = (page) => {
        const { dispatch } = this.props
        dispatch(dispatchRibbon({ type: HANDLE_CHANGE_PAGE_RIBBON, payload: { page } }))
    }

    onChangePageSize = (page, limit) => {
        const { dispatch } = this.props
        dispatch(dispatchRibbon({ type: SET_PAGINATION_RIBBON, payload: { page, limit } }))
        dispatch(dispatchRibbon({ type: HANDLE_CHANGE_PAGE_RIBBON, payload: { page } }))
    }

    onCloseModalAddRibbon = () => {
        const { dispatch } = this.props
        dispatch(dispatchRibbon({ type: SET_DATA_RIBBON_DEFAULT }))
        this.clearImagePreview()
        dispatch(dispatchRibbon({ type: SET_MODAL_CREATE_RIBBON, payload: { isOpen: false, type: null, data: null } }))
        this.setState({
            decorateName: ''
        })
    }

    handleChangeNameDecorateGroup = (e) => {
        if (e.target.value.trim().length > 20) return
        this.setState({ decorateGroupName: e.target.value })
    }

    handleChangeNameDecorateGroupCreate = (e) => {
        const value = e.target.value
        if (value.trim().length > 20) {
            return
        } else {
            this.setState({
                decorateName: e.target.value
            })
            this.props.dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'decorateName', value: e.target.value } }))
        }
    }

    render() {
        const { ribbon, dispatch, listDecorate, gallery, listAllProduct } = this.props
        const { activeTab, listShops } = ribbon
        const { imagePreview } = this.state
        return (
            <div id="ribbon">
                <H5>ตกแต่งรูปสินค้า</H5>
                <div className="content mt-2">
                    <div className="nav-ribbon">
                        <section className="d-flex flex-row border-white" style={{ color: '#646464' }}>
                            {ribbin.map((rib, idx) => (
                                <div className={classnames("nav-item", { 'active': activeTab === rib.value })}
                                    key={`tab-${idx}`}
                                    onClick={() => this.handleSetActiveTab(rib)}>
                                    <div className="position-relative p-3">
                                        {activeTab === rib.value && <span className="tab-active"></span>}
                                        {rib.label}
                                    </div>
                                </div>
                            ))}
                        </section>
                        <section className="p-3 d-flex flex-row justify-content-between align-items-center border-white mt-1 mb-1">
                            <span className="font-weight-bold"> {ribbon.activeTab === "ribbon" ? "หมวดหมู่ริบบิ้น" : ribbon.activeTab === "frame" ? "โฟลเดอร์" : null}  ({ribbon.listDecorate?.length})</span>
                            {getUserRole() !== OWNER &&
                                <span>
                                    <ButtonAdd
                                        label="เพิ่ม"
                                        handleAdd={() => dispatch(dispatchRibbon({ type: SET_MODAL_CREATE_CATEGORIES, payload: { isOpen: true, type: 'create' } }))}
                                    />
                                </span>
                            }
                        </section>
                        <section className="d-flex flex-column nav-categories border-white">
                            {listDecorate.map((decorate, decorateidx) => (
                                <React.Fragment key={`decorateidx-${decorateidx}`}>
                                    <div className={classnames('nav-cat-item', { 'active': isEqual(ribbon.decorateTab.name, decorate.name) })}
                                        onClick={() => this.handleSetCategoriesTab(decorate)} key={`decorateidx-${decorateidx}`}
                                    >
                                        {isEqual(ribbon.decorateTab.name, decorate.name) && <div className="tab-active-vertical"></div>}
                                        {decorate.name}  {decorate.default ? '(ค่าเริ่มต้น)' : null}  {decorate.status === "hide" ? <span className="text-danger float-right">(ปิดใช้งาน)</span> : null}
                                    </div>
                                </React.Fragment>
                            ))}
                        </section>
                    </div>

                    {ribbon.listRibbon?.length > 0 &&
                        <ListRibbonTable
                            ribbon={ribbon}
                            pagination={ribbon.pagination}
                            type={ribbon.activeTab}
                            dispatch={dispatch}
                        />
                    }

                </div>
                <div>
                    <PaginationComponent
                        total={ribbon.pagination.ribbon.total}
                        current={ribbon.pagination.ribbon.page}
                        pageSize={ribbon.pagination.ribbon.limit}
                        onChange={(page) => this.onChangePage(page)}
                        onShowSizeChange={(page, pageSize) => this.onChangePageSize(page, pageSize)}
                        pageSizeOptions={[10, 20, 50, 100]}
                    />
                </div>

                <ModalConfirmChildrenHeader
                    isOpen={ribbon.modalCreateCategories.isOpen}
                    handleConfirm={() => this.handleCheckTypeModalDecorateGroup(ribbon.modalCreateCategories.type)}
                    handleClose={() => dispatch(dispatchRibbon({ type: SET_MODAL_CREATE_CATEGORIES, payload: { isOpen: false, type: null } }),
                        dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'errDecorateName', value: false } })))
                    }
                    btnConfirm={'ตกลง'}
                    btnCancel={'ยกเลิก'}
                    labelHeader={ribbon.modalCreateCategories.type === "create" ? 'เพิ่มหมวดหมู่' : ribbon.modalCreateCategories.type === "edit" ? 'แก้ไข' : null}
                >
                    {
                        ribbon.modalCreateCategories.type === "create" ?
                            <section className="w-100">
                                <div className="bg-white" style={{ padding: '3em 4em', margin: '0 2em' }}>
                                    <span className="require">เพิ่มหมวดหมู่</span> <span className="text-limit">(จำกัด 20 ตัวอักษร)</span>
                                    <input type="text"
                                        className={classnames("form-control mt-1", { 'error-field': ribbon.errDecorateName })}
                                        // onChange={(e) => dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'decorateName', value: e.target.value } }))}
                                        onChange={(e) => this.handleChangeNameDecorateGroupCreate(e)}
                                        value={this.state.decorateName}
                                    />
                                    {ribbon.errDecorateName?.err && <span className="require text-danger">{ribbon.errDecorateName?.message}</span>}
                                </div>

                            </section> :
                            ribbon.modalCreateCategories.type === "edit" ?
                                <React.Fragment>

                                    {!ribbon.decorateTab.default &&
                                        <div>
                                            <section className="w-100">
                                                <div className="bg-white" style={{ padding: '3em 4em', margin: '0 2em' }}>
                                                    <span className="require">ชื่อหมวดหมู่</span> <span className="text-limit">(จำกัด 20 ตัวอักษร)</span>
                                                    <input type="text"
                                                        className={classnames("form-control mt-1", { 'error-field': ribbon.errDecorateName })}
                                                        onChange={(e) => this.handleChangeNameDecorateGroup(e)}
                                                        value={this.state.decorateGroupName}
                                                    />
                                                    {ribbon.errDecorateName?.message && <span className="require text-danger">{ribbon.errDecorateName?.message}</span>}
                                                </div>
                                            </section>
                                        </div>

                                    }
                                    <section className="d-flex justify-content-start mt-2" style={{ backgroundColor: 'white', padding: '3em 4em', margin: '0 2em' }}>
                                        <div className="d-flex align-items-center justify-content-between" style={{ width: '75%' }}>
                                            <h5 className="mt-1 mr-4">สถานะการใช้งาน</h5>
                                            <div
                                                className='toggle-green d-flex align-items-center gap-1'
                                            >
                                                <label className='switch'>
                                                    <input
                                                        type='checkbox'
                                                        checked={ribbon.decorateTab?.status === "show" ? true : false}
                                                        onChange={() =>
                                                            dispatch(dispatchRibbon({
                                                                type: SET_KEY_RIBBON,
                                                                payload: { key: 'decorateTab', value: { ...ribbon.decorateTab, status: ribbon.decorateTab.status === "show" ? 'hide' : 'show' } }
                                                            }))}
                                                    />
                                                    <span className='slider round'></span>
                                                </label>
                                                <span>
                                                    {ribbon.decorateTab?.status === "show" ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
                                                </span>
                                            </div>
                                        </div>
                                    </section>

                                    {
                                        ribbon.decorateTab?.default === 0 &&
                                        <span style={{ padding: '1em 4em', margin: '0 2em' }} className="color-primary d-flex justify-content-end align-items-center flex-row">
                                            <span className="cursor-pointer d-flex align-items-center gap-1">
                                                <div className='icon-manage-container'>
                                                    <IconTrash onClick={() => dispatch(dispatchRibbon({
                                                        type: SET_MODAL_CONFIRM_RIBBON, payload: {
                                                            isOpen: true,
                                                            data: ribbon.decorateTab,
                                                            type: 'deletedecorategroup',
                                                            message: <div className="text-center">ลบหมวดหมู่? <br /> {`ถ้ายืนยันลบ${ribbon.activeTab === "ribbon" ? 'ริบบิ้น' : 'กรอบรูป'}ทั้งหมดของหมวดหมู่นี้`} <br /> จะถูกลบออก</div>,
                                                            btnConfirm: 'ยืนยัน',
                                                            btnCancel: 'ยกเลิก'

                                                        }
                                                    }))} /></div> <Tooltip className="color-ais-primary-main">ลบหมวดหมู่นี้</Tooltip></span>
                                        </span>
                                    }
                                    <Loading />
                                </React.Fragment>
                                : null
                    }
                </ModalConfirmChildrenHeader>

                <ModalAddRibbon
                    isOpen={ribbon.modalCreateRibbon.isOpen}
                    handleClose={() => this.onCloseModalAddRibbon()}
                    dispatch={dispatch}
                    ribbon={ribbon}
                    gallery={gallery}
                    imagePreview={imagePreview}
                    type={ribbon.modalCreateRibbon?.type}
                />

                {this.props.show && <ToastNotification />}

                <ModalSelectProduct
                    isOpen={ribbon.modalAddProduct.isOpen}
                    isClose={() => dispatch(dispatchRibbon({ type: SET_MODAL_ADD_PRODUCT_RIBBON, payload: { isOpen: false } }))}
                    dispatch={dispatch}
                    listProduct={ribbon.listProduct}
                    listProductSelected={ribbon.listProductSelected}
                    onSelectProduct={(product) => this.handleSelectProduct(product)}
                    handleCheck={(product) => this.handleCheck(product)}
                    listShopsSelectInput={listShops}
                    handleConfirm={() => this.handleConfirmAddProduct()}
                    ribbon={ribbon}
                    listAllProduct={listAllProduct}
                />
                <ModalConfirm
                    isOpen={ribbon.modalConfirm.isOpen}
                    message={ribbon.modalConfirm.message}
                    btnConfirm={ribbon.modalConfirm.btnConfirm}
                    btnCancel={ribbon.modalConfirm.btnCancel}
                    confirm={() => this.handleClickBtnModalConfirm(ribbon.modalConfirm.type)}
                    isClose={() => dispatch(dispatchRibbon({ type: SET_MODAL_CONFIRM_RIBBON, payload: { isOpen: false, message: '', btnCancel: '', type: '' } }))}
                    disabled={this.props.executingPriority}
                />

            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    ribbon: state.ribbon,
    listDecorate: state.ribbon.listDecorate,
    gallery: state.gallery.ribbon,
    show: state.notification.toast.show,
    listAllProduct: state.ribbon.listAllProduct,
    executingPriority: state.ribbon.executingPriority
})


export default connect(mapStateToProps)(Ribbon)
