import React, { Component } from 'react';

class Alive extends Component {

    render() {

        const  timeSteamp = Math.floor(Date.now() / 1000)
        const res = {
            "code":200,
            "status":"ok",
            "requested_time": timeSteamp,
            "response_time": timeSteamp
        }

        return (
           
                <React.Fragment>
                   <pre>
                   {JSON.stringify(res)
                  }
                 </pre>
                </React.Fragment>
                 )
    }



} //  end class

export default Alive