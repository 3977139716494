import axios from "../config/axios";
import host from "../config/host";

class AnnounceProvider {
  getAllAnnounces = async (payload) => {
    try {
      const { limit, page } = payload;
      const params = new URLSearchParams({
        limit,
        page,
      });
      const url = `${host.api}/announces/all?${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getAnnounce = async ({announceId}) => {
    try {
        const url = `${host.api}/announces/${announceId}`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        throw error;
      }
  }
  getDisplayAnnounce = async ({announceIdList}) => {
    let queryParams = "";

    if(announceIdList && announceIdList.length > 0)
    queryParams = announceIdList.map(id => `exceptId=${id}`).join('&');

    if(queryParams) 
    queryParams = `?${queryParams}`;

    try {
        const url = `${host.api}/announces${queryParams}`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        throw error;
      }
  }

  createAnnounce = async (body) => {
    try {
      const url = `${host.api}/announces`;
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      throw error;
    }
  };
  updateAnnounce = async ({announceId, param}) => {
    try {
      const url = `${host.api}/announces/${announceId}`;
      const res = await axios.patch(url, param);
      return res.data;
    } catch (error) {
      throw error;
    }
  };
  deleteAnnounce = async ({announceId}) => {
    try {
      const url = `${host.api}/announces/${announceId}`;
      const res = await axios.delete(url);
      return res.data;
    } catch (error) {
      throw error;
    }
  };
}

export default AnnounceProvider;
