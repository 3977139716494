import axiosNew from 'axios'
import ModalAlert from 'components/modalAlert/ModalAlert'
import host from 'config/host'
import userProvider from 'provider/userProvider'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { dispatchApp, SET_LOADING } from 'redux/actions/app'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert'

const VerifyStaff = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    (async () => {
      try {
        dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
        const pathSplit = props.history.location.pathname.split('/')
        const key = pathSplit[2]
        const email = pathSplit[4]
        const res = await userProvider.verifyUserStaff(email, key)
        if (res.status === 200) {
          dispatch(dispatchModalAlert({
            type: OPEN_MODAL_ALERT,
            payload: { isOpen: true, message: 'ยืนยันบัญชีผู้ใช้งานสำเร็จ', type: 'success',confirm: () => {localStorage.clear(); props.history.push('/login')} },
          }))
        }

        dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
      } catch (error) {
        console.log(error)
        dispatch(dispatchModalAlert({
          type: OPEN_MODAL_ALERT,
          payload: { isOpen: true, message: 'คุณได้ดำเนินการยืนยันการลงทะเบียนไปแล้ว', type: 'alert',confirm: () => 
          (async () => {
            await localStorage.clear(); 
            await props.history.push('/login')
        })()
      },
        }))
      }

    })()

    return () => {

    }
  }, [])


  return (
    <div>
      ยืนยันบัญชี
      <ModalAlert />
    </div>

  )
}

export default VerifyStaff