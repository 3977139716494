import React, { Component } from "react";
import { connect } from "react-redux";

import "./index.scss";
// import iconCopy from "../../images/icons/copy-2.svg";
// import iconClose from "../../images/icons/close.svg";

import {
  dispatchPost,
  TOGGLE_OPEN_LIVE_SETTING_CONFIRM_MODAL,
} from "../../redux/actions/post";

class ModalLiveSettingConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  
  closeModal = () => {
    this.props.dispatch(
      dispatchPost({
        type: TOGGLE_OPEN_LIVE_SETTING_CONFIRM_MODAL,
        payload: { isOpen: false },
      })
    );
  };

  onSubmitForm = () =>  {
      this.props.onSubmitForm();
      this.closeModal();
}

  render() {
    const { isOpen, title, type, btnText} = this.props.modalLiveSettingConfirm;

    return (
      <React.Fragment>
        <div className={`modal-confirm ${!isOpen ? 'hide' : ''}`}>
          <div className="modal-confirm-container">
            <p>{title}</p>
            { type === "inform" && <button className="btn btn-submit mb-2" onClick={this.onSubmitForm}>{btnText}</button> }
            { type === "danger" && <button className="btn btn-danger mb-2" onClick={this.onSubmitForm}>{btnText}</button> }
            <button className="btn btn-cancel" onClick={this.closeModal}>ยกเลิก</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  modalLiveSettingConfirm: state.post.modalLiveSettingConfirm,
});

export default connect(mapStateToProps)(ModalLiveSettingConfirm);
