import React from "react";
import "./index.scss";

const LoadMore = () => {
  return (
    <div className="wrap-load-more">
      <div className="animate-loadmore">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default LoadMore;
