import React from "react";
import InputNumber from "components/InputNumber";
import { InputTitle, InputSubTitle, Require } from "components/FontStyle";

const FieldNumber = (props) => {
  const {
    type,
    title,
    detail,
    placeholder,
    name,
    value,
    onFocus,
    onChange,
    disabled,
    isRequire,
    isError,
    errorMessage,
  } = props;

  const inputType = type === "CURRENCY" ? "CURRENCY" : "DECIMAL";

  return (
    <div className="d-flex flex-column">
      <InputTitle>
        {title} {isRequire && <Require />}{" "}
      </InputTitle>
      <InputSubTitle>{detail}</InputSubTitle>
      <div className="input-group">
        {type === "CURRENCY" && (
          <div className="input-group-prepend">
            <span className="input-group-text">฿</span>
          </div>
        )}
        {type === "PERCENT" && (
          <div className="input-group-prepend">
            <span className="input-group-text">%</span>
          </div>
        )}
        <InputNumber
          value={value}
          name={name}
          className={`input-form text-center ${isError && "error-field"}`}
          type={inputType}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
        />
      </div>
      {isError && <span className="text-danger mt-1">{errorMessage}</span>}
    </div>
  );
};

export default FieldNumber;

FieldNumber.defaultProps = {
  title: "",
  detail: "",
  placeholder: "",
  name: "",
  value: "",
  isRequire: false,
  isError: false,
  disabled: false,
  onChange: () => { },
};
