import './styles.scss'

import React from 'react'
import { Body2, H5, SubTitle1 } from 'components/FontStyle'
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip'
import { Link } from 'react-router-dom'

const DashBoardLabel = (props) => {
    const { label, hint, value, width, tooltip, idx, buttonLabel, link, type, listShop } = props
    return (
        <div className="dashboard-label">

            <div className={`container w-${width}`}>
                <div className='d-flex align-items-center gap-1'>
                    <SubTitle1>{label}</SubTitle1> <span><React.Fragment>
                        <span id={`tooltip${idx}`}>{hint}</span>
                        <UncontrolledTooltip placement="top" target={`tooltip${idx}`}>
                            {tooltip}
                        </UncontrolledTooltip>
                    </React.Fragment></span>
                </div>

                <div className='d-flex justify-content-between align-items-baseline'>
                    <H5>{value}</H5> {link && <Link to={{
                        pathname: link,
                        state: {type,listShop}

                    }}><Body2 className='color-ais-primary-main cursor-pointer'>{buttonLabel}</Body2></Link>}
                </div>
            </div>


        </div>
    )
}

DashBoardLabel.defaultProps = {
    label: 'label',
    hint: 'hint',
    value: 'value',
    tooltip: '',
    idx: 0
    // width: '25'
}

export default DashBoardLabel
