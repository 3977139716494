/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import axios from 'axios'
import config from './config';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import jsCookie from 'js-cookie';

export class Loopback {
  constructor(name) {
    this.name = name;
  }
  getModel() {
    return this.name
  }
  /**
 * Get baseUrl from config and make sure there is a slash at the end.
 * @return {string} The API base URL
 */
  _getBaseUrl() {
    let baseUrl = config.get('baseUrl') || '';
    if (baseUrl.slice(-1) !== '/') {
      baseUrl += '/';
    }
    return baseUrl;
  }
  /**
 * Get accessToken from Cookie .
 * @return {string} The AccessToken
 */
  _getToken() {
    try {
        const userData = jsCookie.get('userInfo') ;
        let access_token = null;
        if (userData) {
          const user = JSON.parse(userData)
          access_token = user.token.id || null
        }
        return access_token;
    }catch(ex){
        
    }

  }
  /**
 * Given the endpoint and its filter, this will build the full
 * URL to query Loopback
 * @param  {object} filter   Filter object
 * @return {string}          Loopback URL
 */
  _buildUrl(filter, action, where) {
    const baseUrl = this._getBaseUrl();
    let load = '';
    if (!!action) {
      load = action
    }
    let url = baseUrl + this.name + load
    if (filter) {
      url += '?filter=' + encodeURIComponent(JSON.stringify(filter));
    }
    if (where) {
      url += '?where=' + encodeURIComponent(JSON.stringify(where));
    }
    const access_token = this._getToken()

    if (access_token) {
    //   url += filter || where
    //     ? '&'
    //     : '?';
       if(url.indexOf("?") != -1){
        url += '&'
       }else{
        url += '?'
       }
      url += 'access_token=' + access_token;
    }
    return url;
  }
  /**
  * query  api by axios.
  * @param  {object} options  option param for query api ,method ,url ,data
  * @return {Promise}  promise
  */
  _query = async (options = {
    method: "get",
    url: "",
    data: {},
    headers : {
        'Content-Type': 'application/json',
        'platform' : 'web'
    }
    
  }) => {
    let res = null;
    let errorMsg = null
    let returnData = {};
    try {
        options.headers = {
            'Content-Type': 'application/json',
            'platform' : 'web'
        }
      res = await axios(options)
    } catch (error) {
      errorMsg = error;
    }
    if (errorMsg == null) {
      returnData = {
        status: res.status,
        data: res.data
      }
    } else {
      returnData = {
        status: errorMsg.response.status,
        error: errorMsg
      }
      // return  error to catch
      throw returnData;
    }

    return returnData;
  }
  /**
  * login and get user data.
  * @param  {data}  param for login {"email": "u1@doe.com", "password": "opensesame"}
  * @return {Promise}  user data
  */
 

  /**
* Given the datafor tables will where and filter, this will build the full
* URL to query Loopback
* @param  {object} where object
* **@where property and value      {"username": "u"}
* **@order propertyName            "order":"username"
* **@include relatedModel          "include":"service"
* **@skip  pags skip               "skip": "0"
* **@limit number of records       "limit": "10"
* **@filter filter                 "status": "true"
* @return {array}  data for tables
*/
  datatable = async (where: {}, order: "", include: "", skip: "", limit: "", filter: {}) => {
    const key = Object.keys(where)
    where[key] = { "like": where[key], "options": "i" }
    where = Object.assign(where, filter)
    return this.find({
      where,
      "order": order,
      "include": include,
      "skip": skip,
      "limit": limit
    })
  }



  /// for Exec function
  datatableExec = async (where: {}, order: "", include: "", skip: "", limit: "", filter: {}) => {
    const key = Object.keys(where)
    where[key] = { "like": where[key], "options": "i" }
    filter = Object.assign(where, { "order": order }, { "include": include }, { "skip": skip }, { "limit": limit }, { "where" : {[key]: where[key], ...filter }})
    return await this.exec(
      "list",
      "GET",
      "",
      "",
      filter
    )
  }

  /**
* Given the count entries tables will where and filter, this will build the full
* URL to query Loopback
* @param  {object} where object
* **@where property and value      {"username": "u"}
* **@order propertyName            "order":"username"
* **@include relatedModel          "include":"service"
* **@skip  pags skip               "skip": "0"
* **@limit number of records       "limit": "10"
* **@filter filter                 "status": "true"
* @return {count}   count entries for tables
*/
  datatableCount = async (where: {}, order: "", include: "", skip: "", limit: "", filter: {}) => {
    const key = Object.keys(where)
    where[key] = { "like": where[key], "options": "i" }
    where = Object.assign(where, filter)
    return this.count(
      where
    )
  }

  datatableCountExec = async (where: {}, order: "", include: "", skip: "", limit: "", filter: {}) => {
    const key = Object.keys(where)
    where[key] = { "like": where[key], "options": "i" }
    where = Object.assign(where, filter)
    return await this.exec(
      "listCount",
      "GET",
      "",
      where,
      ""
    )
  }

  count = async (query) => {
    return this._query({
      method: 'get',
      url: this._buildUrl(null, `/count`,query),
    });
  }
  find(query) {
    return this._query({
      method: 'get',
      url: this._buildUrl(query),
    });
  }
  findOne = async (query) => {
    return this._query({
      method: 'get',
      url: this._buildUrl(query, `/findone`),
    });
  }
  findById = async (id, query) => {
    return this._query({
      method: 'get',
      url: this._buildUrl(query, `/${id}`),
    });
  }

  create = (data) => {
    return this._query({
      method: 'post',
      url: this._buildUrl(),
      data: data
    })
  }

  update = (data) => {
    return this._query({
      method: 'patch',
      url: this._buildUrl(),
      data: data
    })
  }

  updateById = (id, data, query) => {
    return this._query({
      method: 'patch',
      url: this._buildUrl(query, `/${id}`),
      data: data
    })
  }

  upsert = (where, data) => {
    return this._query({
      method: 'post',
      url: this._buildUrl("", `/upsertWithWhere`, where),
      data: data
    })
  }

  exec = (path, method, data, querywhere, queryfilter) => {
    const baseUrl = this._getBaseUrl() + this.name;
    return this._query({
      method: method,
      url: this._buildUrl(queryfilter, `/${path}`, querywhere),
      data: data
    })
  }

  destroyById = async (id, query) => {
    return this._query({
      method: 'delete',
      url: this._buildUrl(query, `/${id}`),
    });
  }
}


// export default new Loopback();
