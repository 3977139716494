import { generatePeriodDate, options } from 'components/PeriodTimeInput/dataUtil'
import moment from 'moment'
import { SET_SHOP_DASHBOARD, SET_KEY_DASHBOARD, SET_DEFAULT_DASHBOARD, GET_REPORT } from '../actions/dashboard'


const initialRealTime = generatePeriodDate(options.REAL_TIME, moment())
const initialState = {
    dashboardReport: {},
    listShop: [],
    shopSelected: 0,
    fromDate: moment(initialRealTime.period.from).utc(0).format(),
    toDate: moment(initialRealTime.period.to).utc(0).format(),
    rangeDate: initialRealTime
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {

        case SET_KEY_DASHBOARD: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }


        case SET_SHOP_DASHBOARD: {
            return {
                ...state,
                shopSelected: payload.shopSelected
            }
        }

        case SET_DEFAULT_DASHBOARD: {
            return {
                listShop: [],
                shopSelected: 0,
                fromDate: moment(initialRealTime.period.from).utc(0).format(),
                toDate: moment(initialRealTime.period.to).utc(0).format(),
                rangeDate: initialRealTime
            }
        }

        case GET_REPORT: {
            return {
                ...state,
                dashboardReport: payload.dataReport
            }
        }
        default: {
            return state
        }
    }
}