/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import CalendarInput from "components/PeriodTimeInput/CalendarInput";
import DateInput from 'components/PeriodTimeInput/DateInput';
import moment from "moment";
import "./index.scss";
import { displayDateTh } from 'helper/timeUtil';

/* -------------------------------------------------------------------------- */
/*                             function component                             */
/* -------------------------------------------------------------------------- */
const periodOptions = { START: {key: 'start', value: 'start'} ,
                        END: {key: 'end', value: 'end'}               
                      }

const PeriodDate = (props) => {
  const { data, 
          onPeriodDateClick, 
          onDateInputClick } = usePeriodDate(props);
  const { selectedDate,
          startDate,
          endDate, 
          periodOption } = data;
  
  return (
    <>
     <div className="d-flex flex-row mt-3 p-2">
         <div className="col-50">
             <DateInput  disabled={false}
                         value={startDate}
                         onClick={() => onDateInputClick(periodOptions.START.value)} 
                         placeholder="วันที่เริ่ม"/>
         </div>
         <div className="col-50">
             <DateInput  disabled={false}
                         value={endDate}
                         onClick={() => onDateInputClick(periodOptions.END.value)} 
                         placeholder="วันที่สิ้นสุด"/>
         </div>
     </div>
     <div className={`triangle-up ${periodOption === periodOptions.END.value ? 'move-right' : ''}`}></div>
      <CalendarInput
        selectedDate={startDate}
        periodDate={{startDate, endDate}}
        mode={"PERIOD"}
        periodOption={periodOption}
        onDateClick={(date) => onPeriodDateClick(date)}
        allowpreviousDate={true} 
        dissableDateFuture={moment()}
      />
    </>
  );
};

/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const usePeriodDate = (props) => {
  const {selectedDate} = props;
  const initialData = {
      startDate: moment(),
      endDate: moment(),
      selectedDate: moment(),
      periodOption: periodOptions.START.value
  }
  const [data, setData] = useState(initialData);

  useEffect(() => {

    if(selectedDate) {
        setData({...data, startDate: selectedDate?.startDate,
                          endDate: selectedDate?.endDate})
    }
  }, [selectedDate])
  

  const onPeriodDateClick = (date) => {

    const {periodOption, startDate, endDate} = data;
    if(periodOption === periodOptions.START.value) {
        setData({...data, startDate: date,
                          endDate:date,
                          periodOption: periodOptions.END.value})
        props.onDateClick({startDate: date, endDate: date}, false);

    } 
    if(periodOption === periodOptions.END.value) {
        setData({...data, endDate: date})
        props.onDateClick({startDate, endDate: date}, true);
        setData({...initialData, startDate: data.startDate, 
                                 endDate: data.endDate});
    }

  }

  const onDateInputClick = (option) => {
    if(option === periodOptions.START.value) 
        setData({...data, selectedDate: data.startDate,
                          periodOption: periodOptions.START.value});
    if(option === periodOptions.END.value)  
        setData({...data, selectedDate: data.endDate,
                          periodOption: periodOptions.END.value});
  }

  return { data,
           onDateInputClick,
           onPeriodDateClick };
};

/* -------------------------------------------------------------------------- */
/*                                   export                                   */
/* -------------------------------------------------------------------------- */
export default PeriodDate;
