import { getUserRole } from 'helper/utils';
import { isAuth } from 'index';
import { ADMIN, OWNER, SUPER_ADMIN } from 'layout/Sidebar/Role';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { isLogin } from '../utils';

const checkPermission = (permission) => {
    if(permission){
       return permission.includes(getUserRole())
    } 
    return false
}

const NotFound = () => <div>Permission Denied</div>

const PrivateRoute = ({ component: Component, permission, ...rest }) => {
    return (
        checkPermission(permission) ? <Route {...rest} render={props => (
            //check permission ? gopage : 404
            <Component {...props} /> 
        ) 
        }  /> : 
         <Route {...rest} render={props => (
            <NotFound />
        ) 
        }  /> 
    );
};

export default PrivateRoute;