import React, { useState, useEffect } from 'react'
import classNames from 'classnames';
import { H5, Caption, SubTitle2, Body1, H6, Tooltip, SubTitle1 } from 'components/FontStyle';
import InputText from 'components/InputText';
import SwitchComponent from 'components/SwitchGreen';
import { ReactComponent as IconHint } from 'images/icons/hint.svg'
import 'styles/_shopDetail.scss';
import IconMobile from 'images/icons/shop-member-preview.png'
import { ReactComponent as IconClose } from 'images/icons/close.svg'
import shopProvider from 'provider/shopProvider'
import { useSelector, useDispatch } from 'react-redux'
import { dispatchApp, SET_LOADING } from 'redux/actions/app';
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert';
import { dispatchNotification, OPEN_TOAST } from 'redux/actions/notification';
import { msgErrRequire } from 'helper/constant'

const api_shop = new shopProvider()

const ShopMemberInfo = () => {
    const dispatch = useDispatch()
    const shop = useSelector((state) => state.shop)
    const [isActive, setIsActive] = useState(false)
    const [name, setName] = useState(null)
    const [modalPreview, setModalPreview] = useState({ isOpen: false, data: null })
    const [error, setError] = useState(null)

    useEffect(() => {
        (async () => {
            fetchData()
        })()
        return () => {
            setName(''); setIsActive(false);
            setModalPreview({ isOpen: false, data: null });
            setError({});
        }
    }, [])
    useEffect(() => {
        if(!isActive){
        setName(null)
        }
    }, [isActive])


    const fetchData = async () => {
        try {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            const res = await api_shop.getShopMember(shop.currentShop.id)
            res.data.map((item, index) => {
                Object.keys(item).map((obj, objIndex) => {
                    if (obj === 'memberShopConfig') {
                        setName(item.memberShopConfig.label)
                        setIsActive(item.memberShopConfig.enable)
                    }
                    return {}
                })
                return {}
            })
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        } catch (error) {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
            dispatch(dispatchModalAlert({
                type: OPEN_MODAL_ALERT,
                payload: { isOpen: true, message: 'เกิดข้อผิดพลาด', type: 'alert', },
            }))
            throw error
        }
    }

    const onSubmit = async() => {
        let idDetectError = ''
        try {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            let isError = {}
            if (isActive) {
                if (!name || name.trim().length === 0) {
                    isError = { name: true }
                    idDetectError = 'detect-scroll-input-name'
                } else {
                    delete isError.name
                }
            }
            setError(isError)
            if (Object.keys(isError).length === 0) {
                const res = api_shop.setShopMember({ name, isActive, shopId: shop.currentShop.id })
                if (res) {
                    dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } }))
                }
            }else{
                dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: msgErrRequire, type: 'alert' }, }))
                await document.getElementById(idDetectError).innerHTML
                const scrollDiv = await document.getElementById(idDetectError).offsetTop;
                window.scrollTo({ top: scrollDiv - 62, behavior: 'smooth' });
            }
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        } catch (error) {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
            throw (error)
        }
    }
    return (
        <div className='shop-member-info'>
            <div className='shop-member-container'>
                <section className='bg-white d-flex flex-column shop-member-active'>
                    <H5>เลขสมาชิกสำหรับร้านค้า</H5>
                    <div className='d-flex flex-row align-items-center justify-content-between' style={{ gap: '40px' }}>
                        <Caption>ลูกค้าสามารถกรอกเลขสมาชิกสำหรับสะสมแต้มได้ (ร้านค้าต้องนำรหัสของลูกค้าไปดำเนินการต่อ)</Caption>
                        <div className='d-flex align-item-center'>
                            <SwitchComponent
                                checked={isActive}
                                onChange={() => setIsActive(!isActive)}
                            /> <span className={classNames('ml-2', {'color-ais-primary-main': isActive,'color-grey': !isActive})}>{isActive ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</span>
                        </div>
                    </div>
                </section>
                {isActive &&
                    <section className='bg-white'>
                        <Body1>หัวข้อที่ใช้แสดงหน้าบัตรเลขสมาชิก</Body1>
                        <div id='detect-scroll-input-name'>
                            <InputText
                                label="ชื่อ"
                                isRequire
                                title="(จำกัด 50 ตัวอักษร)"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                limit={50}
                                // isError={error?.name}
                                errorMsg={error?.name && 'กรุณากรอกชื่อ'}
                            />
                        </div>

                        <div className='mt-3'>
                            <SubTitle2 className='color-ais-primary-main shop-member-preview'>
                                ตัวอย่างการแสดงผล
                                <IconHint onClick={() => setModalPreview({ isOpen: true,data: name })} className='cursor-pointer ml-2' />
                            </SubTitle2>
                            {modalPreview.isOpen &&
                                <div className='modal-preview'>
                                    <H6 className='text-center font-weight-bold'>ตัวอย่างการแสดงผล</H6>
                                    {/* <IconMobile /> */}
                                    <IconClose className='position-absolute cursor-pointer'
                                        style={{ top: '20px', right: '20px' }}
                                        onClick={() => setModalPreview({ isOpen: false })}
                                    />
                                    <img src={IconMobile} alt="" className='w-100' />
                                    <div className='d-flex flex-column member-number-preview' style={{ fontSize: '2vw' }}>
                                        <span style={{ fontSize: '1.5vw' }}>หมายเลขสมาชิก {name} <span className="color-grey">(ถ้ามี)</span></span>
                                        <input type="text" className='input-preview' disabled />
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                }
                <section className='bg-white d-flex justify-content-center'>
                    <button className='btn btn-cancel'>ยกเลิก</button>
                    <button className='btn btn-confirm' onClick={() => onSubmit()}>บันทึก</button>
                </section>
            </div>
        </div>
    )
}

export default ShopMemberInfo
