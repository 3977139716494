import React from 'react'
import numeral from 'numeral'
import { useSelector} from 'react-redux'

import SkuItem from './skuItem';
import IconTrash from '../../images/icons/trash.svg'
import defaulIimage from '../../images/icons/default-product-image.svg'

import './productItem.scss'
import { getCheckedShop } from 'redux/sagas/selectors';

const ProductItem = (prop) => {
  const { productId = '', coverPath, name, productSkus, remains, status } = prop.product
  const {shopId} = prop
  const checkedShop = useSelector(getCheckedShop)

  const calculatePriceRange = (sku = []) => {
    const arr = [];
    Object.values(productSkus).map((e) => {
      if (!arr.includes(e?.priceNet)) arr.push(e?.priceNet)
    })
    return arr.sort((a, b) => a - b)
  }

  const priceRange = calculatePriceRange(productSkus)
  // if priceRange is range
  // const priceRange = [200,5000]

  const handleSkuCheckbox = (shopId, productId, skuId, value) => {
    prop.handleUpdateSKU({ shopId: shopId, productId: productId, skuID: skuId, checked: value })
  }

  const handleDeleteProduct = (shopId, productId) => {
    prop.handleDeleteProduct({shopId: shopId, productId: productId})
  }

  return (
    <div className='product-item'>
      <div className='product-item-container'>
        <div className='product-item-image'>
          <img src={ coverPath?.host !== undefined ? (coverPath?.host + coverPath?.path) : defaulIimage } alt={`product - ${name}`}/>
        </div>
        <div className='product-item-name'>
          {name}
        </div>
        <div className='product-item-price'>
          {priceRange?.length === 1 ? `฿ ${numeral(priceRange[0]).format('0,0.00')}` : `฿ ${numeral(priceRange[0]).format('0,0.00')} - ${numeral(priceRange[priceRange.length - 1]).format('0,0.00')}`}
        </div>
        <div className='product-item-remains'>
          {numeral(remains).format('0,0')}
        </div>
        <div className='product-item-status'>
          {status === 'show' ? <span className='show'>ขายอยู่</span> : <span className='not-show'>ไม่ขาย</span>}
        </div>

        {(checkedShop?.shops[shopId]?.allProduct===true || prop.count === 1 ) ? null :
        <div className='product-item-delete'>
          <img src={IconTrash} alt='Trash icon' onClick={() => handleDeleteProduct(shopId, productId)} />
        </div>
        }
      </div>

      <div className='text-sku'>SKU</div>

      <div className='sku-item-container'>
        {Object.keys(productSkus)?.map((sku) => {

          return (
            <SkuItem
              key = {`platform SKU - ${sku}`}
              imagePath = {(productSkus[sku]?.imagePath?.host !== undefined)? (productSkus[sku]?.imagePath?.host + productSkus[sku]?.imagePath?.path) : 
                            (coverPath?.host !== undefined)? (coverPath?.host + coverPath?.path) 
                            : defaulIimage }
              name = {productSkus[sku]?.sku}
              price = {productSkus[sku]?.priceNet}
              amount = {productSkus[sku]?.stockRemain}
              status = {productSkus[sku]?.status}
              handleSkuCheckbox={(value) => handleSkuCheckbox(shopId, productId, productSkus[sku].skuId, value)}
              value={ checkedShop?.shops[shopId]?.allProduct===true? true : checkedShop?.shops[shopId]?.product[productId][sku]}
              disabled={checkedShop?.shops[shopId]?.allProduct}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ProductItem