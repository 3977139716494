import axios from '../config/axios';
import host from '../config/host'
// import Cookies from 'js-cookie'

class shopProvider {
  getAllListShops = () => {
    return new Promise(async (resolve, reject) => {
      try {
        // const header = {
        //     headers : {
        //         Authorization : "Bearer " + JSON.parse(Cookies.get('token')).accessToken
        //     }
        // }
        const result = await axios.get(`${host.api}/shops/listShopName`)
        resolve(result.data)
      } catch (error) {
        throw error
      }
    })
  }

  getShops = async (params) => {
    try {
      let url = `${host.api}/shops?limit=${params.limit}&page=${params.page}`

      if(params?.sortBy) {
        url += `&sortBy=${params?.sortBy}`
      }
      const res = await axios.get(url)
      return res.data ? res.data : []
    } catch (error) {
      throw error
      // console.log(error)
    }
  }

  getShopsCoupon = async (params) => {
    try {
      const url = `${host.api}/shops/coupon?limit=${params.limit}&page=${params.page}`
      const res = await axios.get(url)
      return res.data ? res.data : []
    } catch (error) {

    }
  }

  getAllShops = async (params) => {
    try {
      const url = `${host.api}/shops`
      const res = await axios.get(url)
      return res.data ? res.data : []
    } catch (error) {
      // throw error
    }
  }

  getShopsSearch = async (payload) => {
    try {
      // const header = {
      //   headers: {
      //     Authorization:
      //       'Bearer ' + JSON.parse(sessionStorage.getItem('token')).accessToken,
      //   },
      // }
      const { keyword } = payload
      let url = `${host.api}/shops/search?kw=${keyword}`

      if(payload?.sortBy) {
       url += `&sortBy=${payload?.sortBy}`
      }

      const res = await axios.get(url)
      return res.data ? res.data : []
    } catch (error) {
      throw error
    }
  }

  getShopDetail = async (payload) => {
    try {
      const { shopId } = payload
      const url = `${host.api}/shops/${shopId}`
      const res = await axios.get(url)
      return res.data ? res.data : []
    } catch (error) {
      throw error
    }
  }

  getShopsPromotion = async (payload) => {
    try {
      const { kw, page, limit } = payload
      const url = `${host.api}/shops/promotion?benefitType=discount&benefit=shipping&page=${page}&limit=${limit}`
      const res = await axios.get(url)
      return res.data
    } catch (error) {
      throw error
    }
  }

  getShopsPromotionSearch = async (payload) => {
    try {
      const { kw, page, limit } = payload
      const url = `${host.api}/shops/promotion?benefitType=discount&benefit=shipping&kw=${kw}&page=${page}&limit=${limit}`
      const res = await axios.get(url)
      return res.data
    } catch (error) {
      throw error
    }
  }

  updateShopDetail = async (payload) => {
    try {
      const { body, shopId } = payload
      const url = `${host.api}/shops/${shopId}`
      const res = await axios.patch(url, body)
      return res.data ? res.data : null
    } catch (error) {
      throw error
    }
  }

  onSearchShop = async (params) => {
    try {
      const url = `${host.api}/shops/search?kw=${params.search}&limit=${params.limit}&page=${params.page}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  createShop = async (payload) => {
    const { body, token } = payload

    try {
      const url = `${host.api}/Shops`
      const res = await axios.post(url, body, {
        headers: { Authorization: 'Bearer ' + token },
      })
      return res.data
    } catch (error) {
      return error
    }
  }

  getShopsHubs = async (payload) => {
    try {
      const { shopId } = payload

      const url = `${host.api}/shop-hubs/shop/${shopId}`
      const res = await axios.get(url)
      return res.data ? res.data : []
    } catch (error) {
      throw error
    }
  }

  createShopsHubs = async (payload) => {
    try {
      const url = `${host.api}/shop-hubs`
      const res = await axios.post(url, payload)
      return res.data
    } catch (error) {
      return error
    }
  }

  updateShopHub = async (params) => {
    try {
      const url = `${host.api}/shop-hubs/${params.id}`
      const res = await axios.patch(url, params.data)
      return res
    } catch (error) {
      throw error
    }
  }

  getListProvince = async (params) => {
    try {
      const url = `${host.api}/provinces`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  getListDistrict = async (idProvince) => {
    try {
      const url = `${host.api}/districts/province/${idProvince}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  getListSubDistrict = async (idDistrict) => {
    try {
      const url = `${host.api}/sub-districts/district/${idDistrict}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  craeteShopTax = async (params) => {
    try {
      const url = `${host.api}/shop-tax`
      const res = await axios.post(url, params)
      return res
    } catch (error) {
      throw error
    }
  }

  getShopTax = async (id) => {
    try {
      const url = `${host.api}/shop-tax/shop/${id}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  editShopTax = async ({ id, data }) => {
    try {
      const url = `${host.api}/shop-tax/${id}`
      const res = await axios.patch(url, data)
      return res
    } catch (error) {
      throw error
    }
  }

  setShopMember = async (params) => {
    const body = {
      shopId: params.shopId,
      memberShopConfig: { orderKey: "refCode1", label: params.name, enable: params.isActive }
    }
    try {
      const url = `${host.api}/shop-metas`
      const res = await axios.post(url, body)
      return res
    } catch (error) {
      throw error
    }
  }

  getShopMember = async (shopId) => {
    try {
      const url = `${host.api}/shop-metas?shopId=${shopId}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  createShopTheme = async (params) => {
    try {
      const data = {
        shopId: params.shopId,
        theme: {
          primaryMain: params.primaryMain,
          // primaryDark: "#C9D44F",
          primaryBg: params.primaryBg,
          // primaryLight: "#dce04e",
          secondaryMain: params.secondaryMain,
          // secondaryLight: "#2E2E31",
          textPrimaryMain: params.textPrimaryMain,
          textSecondaryMain: params.textSecondaryMain
        }
      }
      const url = `${host.api}/shop-metas/theme`
      const res = await axios.post(url, data)
      return res
    } catch (error) {
      throw error
    }
  }


  getWebHookKey = async () => {
    try {
      const url = `${host.api}/shops/web-hook-key`
      const data = await axios.get(url)
      return data
      return
    } catch (error) {
      throw error
    }

  }
}

export default shopProvider