import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import noProduct from '../images/icons/no-product.svg'
// import stock from '../images/icons/stock.svg'
import { ReactComponent as IconEdit } from '../images/icons/edit.svg'
// import copy from '../images/icons/copy.svg'
// import IconProductEmpty from '../images/icons/product-empty.svg'
// import { Link } from 'react-router-dom'
import '../styles/_product.scss';
import { dispatchProduct, GET_LIST_PRODUCT, OPEN_MODAL_ADD_PRODUCT, ADD_KEY_VALUE_PRODUCT, HANDLE_SEARCH_PRODUCT, GET_DATA_PRODUCT_PAGE, HANDLE_DELETE_PRODUCT, OPEN_MODAL_LIMIT_PURCHASE, SET_KEY_PRODUCT } from '../redux/actions/product'
import Pagination from '../components/pagination'
import host from '../config/host'
// import Search from '../images/icons/search.svg'
import noItem from '../images/icons/status-error.svg'
import ModalAddProduct from '../components/modalAddProduct';
import { checkPermission, getUserRole, IsJsonString } from '../helper/utils'
import { ReactComponent as IconTrash } from '../images/icons/trash.svg';
// import { ReactComponent as IconView } from '../images/icons/view.svg';
import { ReactComponent as IconAlert } from '../images/icons/alert.svg';
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';
import Cookies from 'js-cookie';
import { ADMIN, OWNER, SUPER_ADMIN } from '../layout/Sidebar/Role';
import { ButtonAdd, InputSearch } from 'components/output';
import { Body1, Body2, Caption, H5, LinkLabel, Tooltip } from 'components/FontStyle/'
import numeral from 'numeral';
import classNames from 'classnames';
import { dispatchShop, SET_CURRENT_SHOP } from 'redux/actions/shop';
import ModalSelectList from 'components/ModalSelectList';
import ModalLimitPurchase from 'components/ModalLimitPurchase';
import { Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import XlsxCreate from 'components/XlsxCreate';
import { ReactComponent as IconDownload } from 'images/icons/IconDownload.svg';
import IconDefaultProduct from 'images/icons/icon-default-product.svg';
import productProvider from 'provider/productProvider';
import { displayDateThShortYear } from 'helper/timeUtil';
import classnames from 'classnames'
import DropdownText from 'components/DropdownText';



const api_product = new productProvider()

class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listAllProduct: [],
            statusSearch: false,
            historySearch: "",
            search: "",
            productMode: "ADD",
            productSelected: null,
            userRole: null,
            modalCategories: {
                isOpen: false,
                data: null
            },
        }
    }


    componentDidMount() {
        if (this.props.location?.state?.activeTab) {
            // this.setState({activeTab: this.props.location.state.activeTab })
            this.props.dispatch(dispatchProduct({ type: SET_KEY_PRODUCT, payload: { key: 'activeTab', value: this.props.location?.state?.activeTab } }))
        }
        this.props.dispatch(dispatchShop({ type: SET_CURRENT_SHOP, payload: { shop: null } }))
        localStorage.setItem("createProductBy", JSON.stringify({ createAt: "product" }));
        this.getProduct();

        const user = Cookies.get('user')
        const userInfo = IsJsonString(user) ? JSON.parse(user !== undefined ? user : null) : {};
        const userRole = userInfo.shopId ? OWNER : ADMIN;
        this.setState({ userRole });



        this.props.dispatch(dispatchShop({
            type: 'GET_CURRENT_SHOP_DETAIL'
        }))

    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevState.modalCategories.isOpen === true && !this.state.modalCategories.isOpen) {
        //     this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.state.search } }))
        // }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.product.listAllProduct !== state.listAllProduct) {
            return {
                listAllProduct: props.product.listAllProduct
            }
        }
        return state;
    }

    componentWillUnmount() {
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'limit', value: 10 } }))
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: null } }))
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'activeTab', value: '' } }))


    }

    getProduct = () => {
        // this.props.dispatch(dispatchProduct({
        //     type: GET_LIST_PRODUCT, payload: {
        //         limit: this.props.product.limit,
        //         page: this.props.product.page,
        //     }
        // }))
        this.props.dispatch(dispatchProduct({ type: GET_DATA_PRODUCT_PAGE }))
    }

    openModalAddProduct = (mode, productSelected = {}) => {
        this.setState({ productMode: mode, productSelected }, () =>
            this.props.dispatch(dispatchProduct({ type: OPEN_MODAL_ADD_PRODUCT, payload: { isOpen: true } }))
        );
    }

    onChangePage = (page) => {
        if (this.state.statusSearch) {
            this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: page } }))
            this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.state.historySearch } }))
        } else {
            this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: page } }))
            this.props.dispatch(dispatchProduct({ type: GET_LIST_PRODUCT }))
        }
    }

    onShowSizeChange = (current, pageSize) => {
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'limit', value: pageSize } }))
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: current } }))
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value })
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: e.target.value } }))
    }

    onSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            if (this.state.search.trim().length === 0) {
                this.onClearSearch()
            }
            // this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } }))
            // this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } }))
            // this.onClickSeachProduct()
        }

    }

    onClickSeachProduct = () => {
        if (this.state.search.trim().length === 0) {
            this.setState({ statusSearch: false, historySearch: this.state.search })
            this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } }))
            this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.state.search } }))
        } else {
            this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } }))
            this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: true } }))
            this.setState({ statusSearch: true, historySearch: this.state.search })
            this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.state.search } }))
        }
    }

    openModalDeleteProduct = (product) => {
        this.props.dispatch(dispatchModalConfirm({
            type: OPEN_MODAL_CONFIRM, payload: {
                isOpen: true,
                message: `คุณต้องการลบสินค้า ${product.name}`,
                btnConfirm: 'ใช่',
                btnCancel: 'ไม่ใช่',
                isClose: () => this.props.dispatch(dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: false })),
                confirm: () => this.handleDeleteProduct(product),
            }
        }))
    }

    handleDeleteProduct = (product) => {
        this.props.dispatch(dispatchProduct({ type: HANDLE_DELETE_PRODUCT, payload: { product, statusSearch: this.state.statusSearch, historySearch: this.state.historySearch } }))
    }

    onClearSearch = () => {
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } }))
        this.setState({ search: '', statusSearch: false })
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } }))
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } }))
        this.getProduct()
    }

    openModalLimitPurchase = (data, product) => {
        const { productSkus, ...productNosku } = product.product
        this.props.dispatch(
            dispatchProduct({
                type: OPEN_MODAL_LIMIT_PURCHASE,
                payload: { isOpen: true, data, product: productNosku },
            })
        );
    }

    fetchDataExcel = async () => {


        const setFormatData = async (data) => {
            const newData = await new Promise(async (resolve, reject) => {
                const header = ['ลำดับ', 'ชื่อสินค้า', 'หมวดหมู่', 'ชื่อร้านค้า', 'จำกัดการซื้อ', 'ตัวเลือก SKU', 'เลขอ้างอิง SKU', 'รหัสบาร์โค้ด', 'ราคาขาย', 'คลัง', 'สถานะ', 'จำกัดการซื้อ(สินค้าย่อย)']
                const dataProduct = await data.map((product, index) => {
                    return {
                        index: index + 1,
                        productName: product.name,
                        category: product.productCategories?.categories?.name,
                        shopName: product.shop.name,
                        limitPurchase: product.limit?.toLocaleString(),
                        // sku: product.productSkus.map((sku) => `${sku.id}\n`).join(''),
                        optionProduct: product.productSkus.map((sku) => `${sku.sku}`).join('\n'),
                        refSKU: product.productSkus.map((sku) => `${sku.sellerSKU ? sku.sellerSKU : ''}`).join('\n'),
                        barcodeSKU: product.productSkus.map((sku) => `${sku.barcode ? sku.barcode : ''}`).join('\n'),
                        price: product.productSkus.map((sku) => `${sku.priceNet.toLocaleString()}`).join('\n'),
                        stock: product.productSkus.map((sku) => `${sku.stockRemain.toLocaleString()}`).join('\n'),
                        status: product.productSkus.map((sku) => `${sku.status === 'show' ? 'ขายอยู่' : 'ไม่ขาย'}`).join('\n'),
                        limitPurchaseSKU: product.productSkus.map((sku) => `${sku.limit ? sku.limit.toLocaleString() : 'ไม่จำกัด'}`).join('\n'),
                    }
                })
                resolve({ header, dataProduct })
            })
            return newData
        }

        const data = await new Promise(async (resolve, reject) => {
            const { statusSearch, search } = this.state
            const { product } = this.props
            const { limit, page, activeTab } = product

            if (statusSearch) {
                const res = await api_product.handleSearchProduct({ search, limit: product?.total, page: 1, status: activeTab })
                const newData = await setFormatData(res.data.product)
                resolve(newData)
            } else {
                const res = await api_product.getListAllProduct({ page: 1, limit: product?.total })
                const newData = await setFormatData(res.product)
                resolve(newData)
            }
        })
        return { header: data.header, data: data.dataProduct }
    }

    toggle = (status) => {
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(dispatchProduct({ type: SET_KEY_PRODUCT, payload: { key: 'activeTab', value: status } }))
        this.onClickSeachProduct()
        
    }


    render() {
        const { product, limit, page, loading, total, shop, activeTab } = this.props;
        const { userRole } = this.state;
        return (
            <div className="shop">

                <div className="header-product d-flex flex-row justify-content-between align-items-center m-auto p-3 col-12">
                    <span className="d-flex flex-row align-items-baseline header-gap">  <H5>รายการสินค้า</H5> <h5>สินค้าทั้งหมด {total} รายการ</h5> </span>
                    <div style={{ color: "orange", gap: '2em' }} className="d-flex align-items-center add-product-btn"
                        // onClick={() => this.openModalAddProduct('ADD')}
                    >
                        {checkPermission('product', 'create', 'rw') ?
                            <React.Fragment>
                                {/* <FontAwesomeIcon icon={faPlusCircle} color="#ED1072" className="mr-1" />
                                <span className='color-primary'>สร้างรายการสินค้า</span> */}
                                <ButtonAdd
                                    label='สร้างรายการสินค้า'
                                    handleAdd={() => this.openModalAddProduct('ADD')}
                                />

                                {/* {
                                    getUserRole() === OWNER && <Body2 className='color-primary' onClick={() => this.props.history.push('/manage/product/mass-upload')}>เพิ่มสินค้าแบบชุด</Body2>
                                } */}
                                {getUserRole() === OWNER && 
                                 <DropdownText label="จัดการสินค้าแบบชุด" data={[{
                                    label: 'สร้างสินค้าแบบชุด',
                                    key: 'create',
                                },
                                {
                                    label: 'แก้ไขสินค้าแบบชุด',
                                    key: 'edit',
                                },]}
                                    handleClick={(key) =>key === 'create' ? this.props.history.push('/manage/product/mass-upload') : this.props.history.push('/manage/product/mass-upload-edit')}
                                    label="จัดการสินค้าแบบชุด"
                                />
                                }

                            </React.Fragment> : null}

                    </div>

                </div>

                <div className="search-product col-12 m-auto p-0">
                    <div className="input-group col-12 input-search p-0">

                        <div className='col-6'>
                            <Nav tabs>
                                {/* <NavItem onClick={() => this.toggle('waitShipping')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'waitShipping' })} >
                                         ออเดอร์ใหม่ ({listOrder?.waitShipping})
                                </NavLink>
                                </NavItem> */}
                                <NavItem onClick={() => this.toggle('')}>
                                    <NavLink className={classnames({ 'active': activeTab === '' })} >
                                        <Caption>ทั้งหมด</Caption>
                                    </NavLink>
                                </NavItem>
                                <NavItem onClick={() => this.toggle('show')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'show' })} >
                                        <Caption>ขายอยู่</Caption>
                                    </NavLink>
                                </NavItem>
                                <NavItem onClick={() => this.toggle('hide')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'hide' })} >
                                        <Caption>ไม่ขาย</Caption>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <div className="col-6 position-relative">
                            <InputSearch
                                placeholder={"ค้นหาจากชื่อสินค้า, ประเภทสินค้า, แบรนด์, ตัวเลือกสินค้า, เลขอ้างอิง SKU"}
                                onChange={this.onChangeSearch}
                                onKeyPress={this.onSearchKeyPress}
                                handleSearch={this.onClickSeachProduct}
                                onClearSearch={this.onClearSearch}
                                statusSearch={this.state.statusSearch}
                                defaultValue={product.valueSearch}
                            />
                            {/* <input type="text" className="form-control text-left position-relative w-100 search-product-input"
                                placeholder="ค้นหาจากชื่อสินค้า, ประเภทสินค้า, แบรนด์"
                                onChange={(e) => this.onChangeSearch(e)}
                                onKeyPress={this.onSearchKeyPress}
                            /> */}
                            {/* <img src={Search} alt="search" className="icon-search-product cursor-pointer" onClick={this.onClickSeachProduct} /> */}
                        </div>
                        <div className="input-group-append">
                            <span className="input-group-text"></span>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-between btn-download-container p-3 pagination-product-header'>


                    {this.state.listAllProduct.length === 0 ? null :
                        // <div className="pagination-top">
                        //     <div className="float-right position-relative">
                        <React.Fragment>
                            <XlsxCreate
                                handleFetchData={() => this.fetchDataExcel()}
                                fileName={`${shop.length <= 1 ? shop && shop[0]?.name : 'ร้านทั้งหมด'}-รายการสินค้า ${displayDateThShortYear(new Date(), "DD-MM-YY")}`}
                                headerA1={'รายการสินค้า'}
                            >
                                <button className='btn-cancel' style={{ padding: '0.5rem 0.3rem' }}> <IconDownload className='icon-download' /> ดาวน์โหลดข้อมูล </button>
                            </XlsxCreate>
                            <Pagination
                                total={product.total}
                                defaultCurrent={1}
                                onChange={this.onChangePage}
                                pageSize={this.props.limit}
                                pageSizeOptions={product.pageSizeOptions}
                                onShowSizeChange={this.onShowSizeChange}
                                current={product.page}
                                showQuickJumper={true}
                            />
                        </React.Fragment>

                        //     </div>
                        // </div>
                    }
                </div>

                {this.state.listAllProduct.length === 0 && !loading ? <div className="d-flex flex-column align-items-center justify-content-center mt-4 h-50vh">
                    <img src={noItem} alt="IconProductEmpty" />
                    <span className="mt-2 font-secondary">ยังไม่มีรายการสินค้า</span>
                </div> :
                    <div className="body-product col-12 m-auto list-product">
                        <div className="p-0 grid-head-col" style={{ fontSize: 'medium' }}>
                            <div className="">ลำดับ</div>
                            <div className="">ชื่อสินค้า</div>
                            <div className="">หมวดหมู่</div>
                            <div className="text-left">ชื่อร้านค้า</div>
                            <div>จำกัดการซื้อ</div>
                            <div className="">ตัวเลือกสินค้า </div>
                            <div className="text-left">เลขอ้างอิง SKU</div>
                            <div className="text-left">รหัสบาร์โค้ด</div>
                            <div className="text-right">ราคาขาย</div>
                            <div className="text-right">คลัง</div>
                            <div className="">สถานะ</div>
                            <div className="">
                                จำกัดการซื้อ <br />
                                <Tooltip>(สินค้าย่อย)</Tooltip>
                            </div>
                            <div></div>
                        </div>

                        {this.state.listAllProduct.map((item, idx) => (
                            <div className="grid-col" key={idx}>
                                <div className="text-center">
                                    <div>{(page - 1) * limit + idx + 1}</div>
                                </div>
                                <div className="d-flex flex-row product-name align-items-start">
                                    {item?.imagePath[0] && item?.imagePath[0].path ?
                                        <img src={host.avatar + item?.imagePath[0]?.path} alt="stock" style={{ width: '2.5em' }} />
                                        :
                                        <img src={IconDefaultProduct} alt="stock" style={{ width: '2.5em' }} />
                                    }
                                    <div className="d-flex flex-column justify-content-start align-items-start">
                                        <span className="font-weight-bold">{item.name}</span>
                                        {/* <span>ประเภท : ทั่วไป</span> */}
                                        {/* <span className="message-success">แสดงในหน้าเว็ป</span> */}
                                    </div>
                                </div>
                                <div className="text-center cursor-pointer color-info-main" onClick={() => this.setState({ modalCategories: { isOpen: true, data: { ...item, shopId: item.shop.id, categoryType: item.shop.categoryType } } })}>
                                    <div className='d-flex justify-content-center gap-1 product-category'>
                                        {(item?.countCategories === 0 || item?.countShopCategories === 0) &&
                                            <React.Fragment>
                                                <span><IconAlert id="test1" fill="red" className="icon-hint" /></span>
                                                <UncontrolledTooltip placement="top" target="test1">
                                                    {`ยังไม่ได้ตั้งค่า `}
                                                </UncontrolledTooltip>
                                            </React.Fragment>
                                        }
                                        <span>ตั้งค่า</span>
                                    </div>
                                </div>
                                <div className="text-left">{item?.shop?.name}</div>
                                <div className="text-center">
                                    <LinkLabel>
                                        <Caption onClick={() => this.openModalLimitPurchase(item.productSkus, { product: item })}>
                                            {item?.limit ? item.limit : 'ไม่จำกัด'}
                                        </Caption>
                                    </LinkLabel>
                                </div>

                                <div className="product-sku-list-table p-0">
                                    {item.productSkus.map((itemSku, idx) => (
                                        <React.Fragment key={`sku-${idx}`}>
                                            <div className='text-left'>{itemSku?.sku}</div>
                                            {itemSku?.sellerSKU ?
                                                <div className='text-left text-break'>{itemSku?.sellerSKU}</div>
                                                :
                                                <div className='text-center'>-</div>
                                            }
                                            {itemSku?.barcode ?
                                                <div className='text-left text-break'>{itemSku?.barcode}</div>
                                                :
                                                <div className='text-center'>-</div>
                                            }
                                            <div className='text-right'>฿ {numeral(itemSku.priceNet).format('0,0.00')}</div>
                                            <div className='text-right'>{numeral(itemSku.stockRemain).format('0,0')}</div>
                                            <div className='text-center'>{itemSku.status === "show" ?
                                                <span className="message-success">ขายอยู่</span> :
                                                <span className="message-grey">ไม่ขาย</span>}</div>
                                            <div className='text-center'><label key={'skulist' + idx}
                                                onClick={() => this.openModalLimitPurchase(item.productSkus, { product: item })}>
                                                <LinkLabel className={classNames({ 'color-info-main': itemSku.limit })}>
                                                    <Caption>
                                                        {itemSku.limit ? itemSku.limit : 'ไม่จำกัด'}
                                                    </Caption>
                                                </LinkLabel>
                                            </label></div>
                                        </React.Fragment>
                                    ))}
                                </div>

                                {/* <div className="text-center">
                                    {item.productSkus.map((item, idx) => (
                                        <span key={idx}>{item.id}</span>
                                    ))}

                                </div>
                                <div className="text-center">
                                    {item.productSkus.map((item, idx) => (
                                        <span key={idx}>{item.sku}</span>
                                    ))}
                                </div>
                                <div className="text-right">
                                    {item.productSkus.map((item, idx) => (
                                        <span key={idx}>฿ {numeral(item.priceNet).format('0,0.00')}</span>
                                    ))}
                                </div>
                                <div className="text-right">
                                    {item.productSkus.map((item, idx) => (
                                        <span key={idx}>{numeral(item.stockRemain).format('0,0')}</span>
                                    ))}
                                </div>
                                <div className="text-center">
                                    {item.productSkus.map((sku, idx) => (
                                        <span key={idx}>
                                            {sku.status === "show" ?
                                                <span className="message-success">ขายอยู่</span> :
                                                <span className="message-grey">ไม่ขาย</span>}</span>
                                    ))}
                                </div>

                                <div className="text-center">
                                    {item.productSkus.map((sku, idx) => (
                                        <label style={{ paddingTop: 5 }} key={'skulist' + idx}
                                            onClick={() => this.openModalLimitPurchase(item.productSkus, { product: item })}>

                                            <LinkLabel className={classNames({ 'success': sku.limit })}>
                                                <Caption>
                                                    {sku.limit ? sku.limit : 'ไม่จำกัด'}
                                                </Caption>

                                            </LinkLabel>
                                        </label>
                                    ))}

                                </div> */}

                                {userRole === OWNER ?
                                    <React.Fragment>
                                        <div className="text-center">
                                            <div className="d-flex">
                                                {/* <IconView onClick={() => this.openModalAddProduct('VIEW', item)} className="icon-manage cursor-pointer" /> */}
                                                <IconEdit onClick={() => this.openModalAddProduct('UPDATE', item)} className="icon-manage cursor-pointer" />
                                                <IconTrash onClick={() => this.openModalDeleteProduct(item)} className="icon-manage cursor-pointer" />
                                                {/* <span><img src={IconView} alt="view" onClick={() => this.openModalAddProduct('VIEW', item)} className='icon-link' /></span> */}
                                                {/* <span><img src={edit} alt="edit" onClick={() => this.openModalAddProduct('UPDATE', item)} className='icon-link' /></span> */}
                                                {/* <span> <img src={IconTrash} alt="iconTrash" className='icon-link' onClick={() => this.openModalDeleteProduct(item)} /> </span> */}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="text-center">
                                            <div>
                                                {/* <span><img src={IconView} alt="view" onClick={() => this.openModalAddProduct('VIEW', item)} className='icon-link' /></span> */}
                                                <IconEdit onClick={() => this.openModalAddProduct('UPDATE', item)} className="icon-manage cursor-pointer" />
                                                <IconTrash onClick={() => this.openModalDeleteProduct(item)} className="icon-manage cursor-pointer" />
                                                {/* <img src={edit} alt="edit" onClick={() => this.openModalAddProduct('UPDATE', item)} className='icon-link' /> */}
                                                {/* <img src={IconTrash} alt="iconTrash" className='icon-link' onClick={() => this.openModalDeleteProduct(item)} /> */}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        ))}

                        <div className="float-right position-relative mt-2">
                            <Pagination
                                total={product.total}
                                defaultCurrent={1}
                                onChange={this.onChangePage}
                                pageSize={this.props.limit}
                                pageSizeOptions={product.pageSizeOptions}
                                onShowSizeChange={this.onShowSizeChange}
                                current={product.page}
                                showQuickJumper={true}
                            />
                        </div>



                    </div>
                }

                <ModalAddProduct
                    mode={this.state.productMode}
                    productSelected={this.state.productSelected}
                    history={this.props.history} />
                <ModalLimitPurchase productSelected={this.state.productSelected}
                    getListProduct={() => this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.state.search } }))} />
                <ModalSelectList
                    modal={this.state.modalCategories}
                    type='product'
                    isClose={() => this.setState({ modalCategories: { isOpen: false, data: null } })}
                    onSubmit={() => { this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.state.search } })) }}
                />
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    product: state.product,
    limit: state.product.limit,
    page: state.product.page,
    loading: state.app.loading,
    total: state.product.total,
    user: state.user.userInfo,
    shop: state.shop.listShopName,
    activeTab: state.product.activeTab
})

export default connect(mapStateToProps, null)(Product)
