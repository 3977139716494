import React, { useState } from 'react'
import { 
    // Button,
     Modal,
    //  ModalHeader,
    //  ModalFooter,
     ModalBody,  } from 'reactstrap';


const ModalConfirmChildren = (props) => {
    const { children, isOpen, btnConfirm, btnCancel, handleConfirm, handleCancel } = props
    // eslint-disable-next-line no-unused-vars
    const [modal, setModal] = useState(false)

    const confirmModal = () => {
        handleConfirm()
    }

    const cancelModal = () => {
        handleCancel()
    }
    return (
        <React.Fragment>
            {/* <Button color="danger" onClick={() => this.setState({ modal: true })}>{'modalconfirm'}</Button> */}
            <Modal isOpen={isOpen} toggle={() => setModal(false)} modalClassName="modal-dialog-confirm">
                {/* <ModalHeader toggle={() => this.setState({ modal: true })}></ModalHeader> */}
                <ModalBody>
                    {children}
                    <div className="w-100 d-flex" style={{ justifyContent: 'space-evenly' }}>
                        <span><button className="btn-cancel" onClick={cancelModal}>{btnCancel}</button></span>
                        <span><button className="btn-submit" onClick={confirmModal}>{btnConfirm}</button></span>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

ModalConfirmChildren.defaultProps = {
    isOpen: false,
    btnCancel: 'cancel',
    btnConfirm: 'ok',
    handleCancel: () => { },
    handleConfirm: () => { }
}

export default ModalConfirmChildren
