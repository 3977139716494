import React, { Component } from "react";
import { connect } from "react-redux";
import moment from 'moment';

import "./index.scss";
import IconClock from "../../images/icons/white-clock.svg";

// import {
//   dispatchPost,
//   SET_MODAL_LIVE_FINISH_CONFIRM,
// } from "../../redux/actions/post";

class ModalLiveFinishConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  closeModal = () => {
    // this.props.dispatch(
    //   dispatchPost({
    //     type: SET_MODAL_LIVE_FINISH_CONFIRM,
    //     payload: { isOpen: false },
    //   })
    // );
    localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: 2 }));
    window.location.replace('/manage/shop/shopdetail');
  };

  getTimeDurations = () => {
    const durationTime = this.props.liveDuration;
    return moment.utc(durationTime * 1000).format('HH:mm:ss');
  }



  render() {
    const { isOpen } = this.props.modalLiveFinishConfirm;

    return (
      <React.Fragment>
        <div className={`modal-confirm ${!isOpen ? 'hide' : ''}`}>
          <div className="modal-confirm-container live-finish">
            <h4 className="mb-5">Ended</h4>
            <img src={IconClock} alt="" />
            <p className="mb-5 mt-3">
              <span className="title">Durations: </span>
              <span className="label">{this.getTimeDurations()} </span>
            </p>
            <button className="btn btn-submit" onClick={this.closeModal}>close</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  modalLiveFinishConfirm: state.post.modalLiveFinishConfirm,
  liveDuration: state.live.liveDuration
});

export default connect(mapStateToProps)(ModalLiveFinishConfirm);
