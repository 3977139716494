export const SET_LIST_SHOP_PROMOTION = "SET_LIST_SHOP_PROMOTION"
export const SET_KEY_PROMOTION = "SET_KEY_PROMOTION"
export const SET_DEFAULT_PROMOTION = "SET_DEFAULT_PROMOTION"
export const OPEN_MODAL_MESSAGE_PROMOTION = "OPEN_MODAL_MESSAGE_PROMOTION"
export const OPEN_MODAL_CONFIRM_PROMOTION = "OPEN_MODAL_CONFIRM_PROMOTION"
export const CREATE_PROMOTION = "CREATE_PROMOTION"
export const UPDATE_PROMOTION = "UPDATE_PROMOTION"
export const GET_PROMOTION_DETAIL = "GET_PROMOTION_DETAIL"

export function dispatchPromotion(params) {
    return {
        type: params.type,
        payload: params.payload
    }
}