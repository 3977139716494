/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import {
    // TabContent, TabPane, 
    Nav, NavItem, NavLink,
    // Card, Button, CardTitle, CardText, Row, Col
} from 'reactstrap';
import classnames from 'classnames';
import '../styles/_order.scss'
// import Search from '../images/icons/search.svg'
import ListOrder from '../components/ListOrder'
import { connect } from 'react-redux'
import {
    dispatchOrder, GET_LIST_ORDER, ON_SEARCH_ORDER, SET_DEFAULT_ORDER, SET_ORDER_FIELD,
    // SET_SELECT_ALL_CLEARING 
} from '../redux/actions/order';
import Pagination from '../components/pagination'
// import DropDown from '../components/DropDownList'

import ModalInvoiceDetail from '../components/modalInvoiceDetail';
import FilterSearch from 'components/FilterSearch';
import SelectInput from 'components/SelectInput';
// import { GET_LIST_SHOP } from 'redux/actions/shop';

import { getUserRole } from '../helper/utils';
import {
    ADMIN,
    // ADMIN, 
    OWNER,
    SUPER_ADMIN
} from '../layout/Sidebar/Role';
import ModalCoverSheetPrint from 'components/ModalCoverSheetPrint';
import ModalAlert from 'components/modalAlert/ModalAlert';
import { Caption, H5 } from 'components/FontStyle';
import XlsxCreate from 'components/XlsxCreate';
import { ReactComponent as IconDownload } from 'images/icons/IconDownload.svg';
import orderProvider from 'provider/orderProvider';
import { displayDateThShortYear } from 'helper/timeUtil';
import { Link } from 'react-router-dom';
// import order from 'redux/reducers/order';


// const Example = (props) => {
//     const [activeTab, setActiveTab] = useState('1'); 


//     const toggle = tab => {
//       if(activeTab !== tab) setActiveTab(tab);
//     }

// const typeFilterData = [{ label: 'ทั้งหมด', value: '' }, { label: 'โอนแล้ว', value: 'clr' }, { label: 'ยังไม่ได้โอน', value: 'nclr' }]
const filterSearch = [{ label: 'ชื่อลูกค้า', value: 'name' }, { label: 'หมายเลขออเดอร์', value: 'orderNo' }]
const initialState = {
    activeTab: 'waitShipping',
    limit: 10,
    pageSizeOptions: [10, 20, 40],
    current: 1,
    search: "",
    statusSearch: false,
    historySearch: '',
    filter: { label: 'ทั้งหมด', value: '' },
    listShop: [],
    searchShopSelectedIndex: 0,
    typeFilterSelectedIndex: 0,
    excelData: null,
    headerExcelData: null
}

const listNavTab = {
    waitShipping: 'รอจัดส่ง',
    onTheWay: 'อยู่ระหว่างขนส่ง',
    success: 'ลูกค้าได้รับแล้ว',
    waitRefund: 'รอคืนเงิน',
    refunded: 'คืนเงินแล้ว',
    refundApproved: 'กำลังดำเนินการคืนเงิน',
    deliveryDelayed: 'เกินเวลาจัดส่ง'
}

class Order extends Component {

    static getDerivedStateFromProps(props, state) {
        if (props.shop !== state.shop) {
            const listShop = [{ label: 'ร้านค้าทั้งหมด', value: '', }]
            // eslint-disable-next-line array-callback-return
            props.shop.map((item) => {
                listShop.push({ label: item.name, value: item })
            })
            return {
                listShop: listShop,
            };
        }
        return null;
    }

    constructor(props) {
        super(props)
        this.state = {
            ...initialState
        }
    }

    componentDidMount() {
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'status', value: this.props.match.params.slug || 'waitShipping' } }))
        this.setState({ activeTab: this.props.match.params.slug || 'waitShipping' })
        this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER }))
        window.history.pushState("", "", "/manage/order/all");
    }

    componentWillUnmount() {
        this.setState({ ...initialState })
        this.props.dispatch(dispatchOrder({ type: SET_DEFAULT_ORDER }))
    }

    componentDidUpdate(prevProps) {
        // if (this.props.tabSelected !== prevProps.tabSelected){
        //     this.toggle(this.props.tabSelected)
        // }
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.dispatch(dispatchOrder({ type: SET_DEFAULT_ORDER }))
        }
    }


    toggle = (tab) => {

        const { filterSearchSelect, filterSearchValue, searchShopSelect } = this.props.order
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'status', value: tab } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        this.setState({ activeTab: tab })
        if (this.state.statusSearch) {
            this.onSearchOrder(filterSearchSelect, filterSearchValue, searchShopSelect)
        } else {
            this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER, payload: { filter: this.state.filter.value } }))
        }

    }

    onChangePage = (page) => {
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: page } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'clearingOrder', value: [] } }))
        // this.props.dispatch(dispatchOrder({ type: SET_SELECT_ALL_CLEARING, payload: false }))
        if (!this.state.statusSearch) {
            this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER, payload: { filter: this.state.filter.value } }))
        }
        else {
            this.props.dispatch(dispatchOrder({ type: ON_SEARCH_ORDER, payload: { value: this.state.search, filter: this.state.filter.value } }))
        }
    }


    onShowSizeChange = (current, pageSize) => {
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'limit', value: pageSize } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: current } }))
        // this.props.dispatch(dispatchOrder({ type: ON_SEARCH_ORDER, payload: { search: this.state.search, filter: this.state.filter.value } }))
    }

    // onSearchOrder = (e) => {
    //     this.setState({ search: e.target.value })
    // }

    handleEnterSearchOrder = (e) => {
        if (e.key != 'Enter') { return }
        if (e.key == 'Enter') {
            if (this.state.search.trim().length == 0) {
                this.setState({
                    statusSearch: false,
                    historySearch: this.state.search
                })
                this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: false } }))
            } else {
                this.setState({
                    statusSearch: true,
                    historySearch: this.state.search
                })
                this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: true } }))
            }
            this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
            this.props.dispatch(dispatchOrder({ type: ON_SEARCH_ORDER, payload: { search: this.state.search, filter: this.state.filter.value } }))
        }
    }

    onClickSearchOrder = () => {
        this.setState({
            statusSearch: true,
            historySearch: this.state.search
        })
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: true } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(dispatchOrder({ type: ON_SEARCH_ORDER, payload: { search: this.state.search, filter: this.state.filter.value } }))
    }

    selectTypeFilter = (value) => {
        this.setState({
            filter: value
        }, () =>
            this.props.dispatch(dispatchOrder({ type: ON_SEARCH_ORDER, payload: { search: this.state.search, filter: this.state.filter.value } })),
            this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        )
    }

    onChangeInputSearch = (data) => {
        const { filterSearchSelect, filterSearchValue,
            // searchShopSelect 
        } = this.props
        this.setState({ searchShopSelectedIndex: data })
        const value = this.state.listShop[data]
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'searchShopSelect', value: value } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        // this.onSearchOrder(filterSearchSelect, filterSearchValue, searchShopSelect)
        if (this.state.statusSearch) {
            this.props.dispatch(dispatchOrder({
                type: ON_SEARCH_ORDER, payload: {
                    filter: filterSearchSelect,
                    value: filterSearchValue ? filterSearchValue : null,
                    shopId: value ? value.value.id : null
                }
            }))
        } else {
            this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER, payload: { shopId: value.value.id } }))
        }
    }

    onChangeFilter = (index) => {
        this.setState({ typeFilterSelectedIndex: index })
        const value = filterSearch[index].value
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchSelect', value: value } }))
    }

    onChangeFilterValue = (value) => {
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchValue', value: value } }))
    }

    onClearSearchShop = () => {
        this.setState({ searchShopSelectedIndex: 0 })
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'searchShopSelect', value: { label: 'ร้านทั้งหมด', value: '' } } }))
        this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER }))
    }

    onClearSearchFilterType = () => {
        this.setState({ typeFilterSelectedIndex: 0, statusSearch: false })
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: false } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchSelect', value: 'name' } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchValue', value: null } }))
        this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER }))
    }

    onSearchOrder = (filter, value, shopId) => {
        this.setState({ statusSearch: true })
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: true } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(dispatchOrder({
            type: ON_SEARCH_ORDER, payload: {
                filter: filter,
                value: value ? value : null,
                shopId: shopId ? shopId.value.id : null
            }
        }))
    }

    onEnterSearch = (e, filter, value, shopId) => {
        if (e.key == 'Enter') {
            this.onSearchOrder(filter, value, shopId)
        }
    }

    fetchDataExcel = async () => {

        const checkpaymentStatus = (type, shipmentStatus) => {
            if (shipmentStatus === "waitconfirm") {
                return 'รอรับออเดอร์'
            }
            if (type === "wait") { return 'รอชำระเงิน' }
            else if (type === "transfer") { return 'รอตรวจสอบ' }
            else if (type === "approved" || type === "paid") { return 'ชำระเงินแล้ว' }
            else if (type === "failed") { return 'ยกเลิก' }
            else { return type }
        }

        const checkLogisticStatus = (type, shipmentStatus) => {
            if (shipmentStatus === "waitconfirm") {
                return 'รอรับออเดอร์'
            }
            if (type === "wait" || type === "redelivery") { return 'รอจัดส่ง' }
            else if (type === "posting" || type === "outdelivery") { return 'อยู่ระหว่างขนส่ง' }
            else if (type === "intransit") { return 'อยู่ระหว่างขนส่ง' }
            else if (type === "delivered") { return 'สำเร็จ' }
            else if (type === "issues" || type === "return") { return "ยกเลิก" }
            ////refund
            else if (type === "request") { return 'รอการคืนเงิน' }
            else if (type === "refunded") { return 'คืนเงินแล้ว' }
            ////refund
            else { return type }
        }

        const checkDetailPayment = (type) => {
            if (type === "creditcard") { return "บัตรเครดิต" }
            else if (type === "ibanking") { return "อินเตอร์เน็ตแบงก์กิ้ง" }
            else if (type === "cod") { return "เก็บเงินปลายทาง" }
            else if (type === "transfer") { return "โอนผ่านธนาคาร" }
            else if (type === "qrcode") { return "QR Code" }
            else { return type }
        }

        const checkRefundStatus = (type, logistic) => {
        
            if (type === 'request' || type === "refunded" || type === "return" ||  type === "approved") {
                if (type === "request") return 'รออนุมัติคืนเงิน'
                else if (type === "approved") return 'กำลังดำเนินการคืนเงิน'
                else if (type === "return") return 'รอคืนสินค้า'
                else if (type === "refunded") return 'คืนเงินแล้ว'
            } else {
                if (logistic === "wait") { return "รอจัดส่ง" }
                else if (logistic === "posting" || logistic === "intransit" || logistic === "redelivery" || logistic === "outdelivery") { return "อยู่ระหว่างขนส่ง" }
                else if (logistic === "delivered") { return "สำเร็จ" }
                else if (logistic === "issues" || logistic === "return") { return "ยกเลิก" }
                else { return logistic }
            }
        }


        const separateType = (data) => {
            const { activeTab } = this.state
            let arr = []
            let couponPlatform = []
            let couponShop = []
            switch (activeTab) {
                case 'waitShipping':
                    data.map((item, index) => {
                        couponPlatform = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'platform')
                        couponShop = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'shop')
                        arr.push({
                            index: (index + 1),
                            orderNo: item.orderNo,
                            transaction: item.payment.transactionId,
                            shopName: item.shop.name,
                            customerName: item.delivery?.logistic === 'Digital' ? `${item.customerInformation.email}` : `${item.customerInformation.name}\r\n${item.customerInformation.mobile}\r\n${item.customerInformation.address} ${item.customerInformation.district}\r\n${item.customerInformation.subdistrict} ${item.customerInformation.province} ${item.customerInformation.zipcode}`,
                            email: `${item.customerInformation.email ? item.customerInformation.email : ''}`,
                            orderItem: `${item.items?.map((product) => `${product.product?.name}\r\n${product.productSKU?.sku}\r\n${product?.productSKU?.sellerSKU ? `เลขอ้างอิง SKU : ${product?.productSKU?.sellerSKU ? product?.productSKU?.sellerSKU : ''}\r\n` : ''}${product?.productSKU?.barcode ? `บาร์โค้ด : ${product?.productSKU?.barcode ? product?.productSKU?.barcode : ''}\r\n` : ''}จำนวน : ${product?.quantity}\r\n`).join('\r\n')}`,
                            // quantityProduct: item.items.length,
                            itemPrice: item.priceNet ? item.priceNet : '0',
                            discountPlatformCode: couponPlatform.length ? `${couponPlatform[0]?.couponCode?.coupon?.promotion?.name}\n${couponPlatform[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountPlatform: item.discount?.priceItemDiscount || item.discount?.priceShippingDiscount || item.discount?.priceVoucherDiscount ? item.discount?.priceItemDiscount + item.discount?.priceShippingDiscount + item.discount?.priceVoucherDiscount : '0',
                            discountShopCode: couponShop.length ? `${couponShop[0]?.couponCode?.coupon?.promotion?.name}\n${couponShop[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountShop: item.discount?.priceItemDiscountShop || item.discount?.priceShippingDiscountShop || item.discount?.priceVoucherDiscountShop ? item.discount?.priceItemDiscountShop + item.discount?.priceShippingDiscountShop + item.discount?.priceVoucherDiscountShop : '0',
                            shippingPrice: item.priceShippingNet ? item.priceShippingNet : '0',
                            total: item.priceTotal,
                            paymentDate: displayDateThShortYear(item.paymentedAt, 'DD MMM YY'),
                            paymentMethod: checkDetailPayment(item.paymentMethod),
                            statusDelivery: checkLogisticStatus(item.logisticStatus, item.shipmentStatus),
                            deliveryDate: displayDateThShortYear(item.deliveryDate, 'DD MMM YY'),
                            customerTax: item.customerTaxInformation?.taxType ? `${item.customerTaxInformation?.taxType === 'individual' ? `บุคคลธรรมดา\n${item.customerTaxInformation?.name}\nหมายเลขบัตรประชาชน ${item.customerTaxInformation?.taxNo}\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.address} ${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}` : `นิติบุคคล\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.name}\n${item.customerTaxInformation?.address}\n${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}\nหมายเลขประจำตัวผู้เสียภาษี ${item.customerTaxInformation?.taxNo}\n${item.customerTaxInformation?.branch === '00000' ? 'สำนักงานใหญ่' : `สาขา ${item.customerTaxInformation?.branch}`}`}` : ''
                        })
                    })
                    return {
                        header: ['ลำดับ', 'หมายเลขออเดอร์', 'เลขอ้างอิง Transaction', 'ชื่อร้านค้า', 'ข้อมูลการจัดส่ง', 'E-Mail', 'รายการสั่งซื้อ', 'ราคาสินค้า', 'เลขคูปองส่วนลด V-AVENUE', 'มูลค่าส่วน V-AVENUE', 'เลขคูปองส่วนลดร้านค้า', 'มูลค่าส่วนลดร้านค้า', 'ค่าจัดส่ง', 'ยอดที่ลูกค้าชำระ', 'วันที่ชำระเงิน', 'ช่องทางการชำระเงิน', 'สถานะการจัดส่ง', 'ส่งภายในวันที่', 'ข้อมูลใบกำกับภาษี'],
                        data: arr
                    }
                    break;
                case 'onTheWay':
                    data.map((item, index) => {
                        couponPlatform = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'platform')
                        couponShop = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'shop')
                        arr.push({
                            index: (index + 1).toString(),
                            orderNo: item.orderNo,
                            deliveryBy: item.delivery?.logistic,
                            trackingNo: item.delivery?.trackingNo,
                            transaction: item.payment.transactionId,
                            shopName: item.shop.name,
                            customerName: item.delivery?.logistic === 'Digital' ? `${item.customerInformation.email}` : `${item.customerInformation.name}\r\n${item.customerInformation.mobile}\r\n${item.customerInformation.address} ${item.customerInformation.district}\r\n${item.customerInformation.subdistrict} ${item.customerInformation.province} ${item.customerInformation.zipcode}`,
                            email: `${item.customerInformation.email ? item.customerInformation.email : ''}`,
                            orderItem: `${item.items?.map((product) => `${product.product?.name}\r\n${product.productSKU?.sku}\r\n${product?.productSKU?.sellerSKU ? `เลขอ้างอิง SKU : ${product?.productSKU?.sellerSKU ? product?.productSKU?.sellerSKU : ''}\r\n` : ''}${product?.productSKU?.barcode ? `บาร์โค้ด : ${product?.productSKU?.barcode ? product?.productSKU?.barcode : ''}\r\n` : ''}จำนวน : ${product?.quantity}\r\n`).join('\r\n')}`,
                            // quantityProduct: item.items.length,
                            itemPrice: item.priceNet ? item.priceNet : '0',
                            discountPlatformCode: couponPlatform.length ? `${couponPlatform[0]?.couponCode?.coupon?.promotion?.name}\n${couponPlatform[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountPlatform: item.discount?.priceItemDiscount || item.discount?.priceShippingDiscount || item.discount?.priceVoucherDiscount ? item.discount?.priceItemDiscount + item.discount?.priceShippingDiscount + item.discount?.priceVoucherDiscount : '0',
                            discountShopCode: couponShop.length ? `${couponShop[0]?.couponCode?.coupon?.promotion?.name}\n${couponShop[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountShop: item.discount?.priceItemDiscountShop || item.discount?.priceShippingDiscountShop || item.discount?.priceVoucherDiscountShop ? item.discount?.priceItemDiscountShop + item.discount?.priceShippingDiscountShop + item.discount?.priceVoucherDiscountShop : '0',
                            shippingPrice: item.priceShippingNet ? item.priceShippingNet : '0',
                            total: item.priceTotal ? item.priceTotal : '0',
                            paymentDate: displayDateThShortYear(item.paymentedAt, 'DD MMM YY'),
                            paymentMethod: checkDetailPayment(item.paymentMethod),
                            statusDelivery: checkLogisticStatus(item.logisticStatus, item.shipmentStatus),
                            deliveryIn: item?.deliveryDate ? displayDateThShortYear(item?.deliveryDate, 'DD MMM YY') : '',
                            deliveryDate: item.dispatchAt ? displayDateThShortYear(item.dispatchAt, 'DD MMM YY') : '',
                            customerTax: item.customerTaxInformation?.taxType ? `${item.customerTaxInformation?.taxType === 'individual' ? `บุคคลธรรมดา\n${item.customerTaxInformation?.name}\nหมายเลขบัตรประชาชน ${item.customerTaxInformation?.taxNo}\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.address} ${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}` : `นิติบุคคล\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.name}\n${item.customerTaxInformation?.address}\n${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}\nหมายเลขประจำตัวผู้เสียภาษี ${item.customerTaxInformation?.taxNo}\n${item.customerTaxInformation?.branch === '00000' ? 'สำนักงานใหญ่' : `สาขา ${item.customerTaxInformation?.branch}`}`}` : ''
                        })
                    })
                    return {
                        header: ['ลำดับ', 'หมายเลขออเดอร์', 'รูปแบบการจัดส่ง', 'หมายเลขพัสดุ', 'เลขอ้างอิง Transaction', 'ชื่อร้านค้า', 'ข้อมูลการจัดส่ง', 'E-Mail', 'รายการสั่งซื้อ', 'ราคาสินค้า', 'เลขคูปองส่วนลด V-AVENUE', 'มูลค่าส่วน V-AVENUE', 'เลขคูปองส่วนลดร้านค้า', 'มูลค่าส่วนลดร้านค้า', 'ค่าจัดส่ง', 'ยอดที่ลูกค้าชำระ', 'วันที่ชำระเงิน', 'ช่องทางการชำระเงิน', 'สถานะการจัดส่ง', 'ส่งภายในวันที่', 'วันที่จัดส่ง', 'ข้อมูลใบกำกับภาษี'],
                        data: arr
                    }
                    break;
                case 'success':
                    data.map((item, index) => {
                        couponPlatform = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'platform')
                        couponShop = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'shop')
                        arr.push({
                            index: (index + 1).toString(),
                            orderNo: item.orderNo,
                            deliveryBy: item.delivery?.logistic,
                            trackingNo: item.delivery?.trackingNo,
                            transaction: item.payment.transactionId,
                            shopName: item.shop.name,
                            customerName: item.delivery?.logistic === 'Digital' ? `${item.customerInformation.email}` : `${item.customerInformation.name}\r\n${item.customerInformation.mobile}\r\n${item.customerInformation.address} ${item.customerInformation.district}\r\n${item.customerInformation.subdistrict} ${item.customerInformation.province} ${item.customerInformation.zipcode}`,
                            email: `${item.customerInformation.email ? item.customerInformation.email : ''}`,
                            orderItem: `${item.items?.map((product) => `${product.product?.name}\r\n${product.productSKU?.sku}\r\n${product?.productSKU?.sellerSKU ? `เลขอ้างอิง SKU : ${product?.productSKU?.sellerSKU ? product?.productSKU?.sellerSKU : ''}\r\n` : ''}${product?.productSKU?.barcode ? `บาร์โค้ด : ${product?.productSKU?.barcode ? product?.productSKU?.barcode : ''}\r\n` : ''}จำนวน : ${product?.quantity}\r\n`).join('\r\n')}`,
                            // quantityProduct: item.items.reduce((a,{quantity}) => a + quantity,0),
                            itemPrice: item.priceNet ? item.priceNet : '0',
                            discountPlatformCode: couponPlatform.length ? `${couponPlatform[0]?.couponCode?.coupon?.promotion?.name}\n${couponPlatform[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountPlatform: item.discount?.priceItemDiscount || item.discount?.priceShippingDiscount || item.discount?.priceVoucherDiscount ? item.discount?.priceItemDiscount + item.discount?.priceShippingDiscount + item.discount?.priceVoucherDiscount : '0',
                            discountShopCode: couponShop.length ? `${couponShop[0]?.couponCode?.coupon?.promotion?.name}\n${couponShop[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountShop: item.discount?.priceItemDiscountShop || item.discount?.priceShippingDiscountShop || item.discount?.priceVoucherDiscountShop ? item.discount?.priceItemDiscountShop + item.discount?.priceShippingDiscountShop + item.discount?.priceVoucherDiscountShop : '0',
                            shippingPrice: item.priceShippingNet ? item.priceShippingNet : '0',
                            total: item.priceTotal,
                            paymentDate: displayDateThShortYear(item.paymentedAt, 'DD MMM YY'),
                            paymentMethod: checkDetailPayment(item.paymentMethod),
                            statusDelivery: checkLogisticStatus(item.logisticStatus, item.shipmentStatus),
                            deliveryIn: displayDateThShortYear(item.deliveredAt, 'DD MMM YY'),
                            customerTax: item.customerTaxInformation?.taxType ? `${item.customerTaxInformation?.taxType === 'individual' ? `บุคคลธรรมดา\n${item.customerTaxInformation?.name}\nหมายเลขบัตรประชาชน ${item.customerTaxInformation?.taxNo}\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.address} ${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}` : `นิติบุคคล\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.name}\n${item.customerTaxInformation?.address}\n${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}\nหมายเลขประจำตัวผู้เสียภาษี ${item.customerTaxInformation?.taxNo}\n${item.customerTaxInformation?.branch === '00000' ? 'สำนักงานใหญ่' : `สาขา ${item.customerTaxInformation?.branch}`}`}` : ''
                        })
                    })
                    return {
                        header: ['ลำดับ', 'หมายเลขออเดอร์', 'รูปแบบการจัดส่ง', 'หมายเลขพัสดุ', 'เลขอ้างอิง Transaction', 'ชื่อร้านค้า', 'ข้อมูลการจัดส่ง', 'E-Mail', 'รายการสั่งซื้อ', 'ราคาสินค้า', 'เลขคูปองส่วนลด V-AVENUE', 'มูลค่าส่วน V-AVENUE', 'เลขคูปองส่วนลดร้านค้า', 'มูลค่าส่วนลดร้านค้า', 'ค่าจัดส่ง', 'ยอดที่ลูกค้าชำระ', 'วันที่ชำระเงิน', 'ช่องทางการชำระเงิน', 'สถานะการจัดส่ง', 'วันเวลาที่ส่งสำเร็จ', 'ข้อมูลใบกำกับภาษี'],
                        data: arr
                    }
                case 'waitRefund':
                    data.map((item, index) => {
                        couponPlatform = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'platform')
                        couponShop = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'shop')
                        arr.push({
                            index: (index + 1).toString(),
                            orderNo: item.orderNo,
                            deliveryBy: item.delivery?.logistic,
                            trackingNo: item.delivery?.trackingNo,
                            transaction: item.payment.transactionId,
                            shopName: item.shop.name,
                            customerName: item.delivery?.logistic === 'Digital' ? `${item.customerInformation.email}` : `${item.customerInformation.name}\r\n${item.customerInformation.mobile}\r\n${item.customerInformation.address} ${item.customerInformation.district}\r\n${item.customerInformation.subdistrict} ${item.customerInformation.province} ${item.customerInformation.zipcode}`,
                            email: `${item.customerInformation.email ? item.customerInformation.email : ''}`,
                            orderItem: `${item.items?.map((product) => `${product.product?.name}\r\n${product.productSKU?.sku}\r\n${product?.productSKU?.sellerSKU ? `เลขอ้างอิง SKU : ${product?.productSKU?.sellerSKU ? product?.productSKU?.sellerSKU : ''}\r\n` : ''}${product?.productSKU?.barcode ? `บาร์โค้ด : ${product?.productSKU?.barcode ? product?.productSKU?.barcode : ''}\r\n` : ''}จำนวน : ${product?.quantity}`).join('\r\n')}`,
                            // quantityProduct: item.items.length,
                            itemPrice: item.priceNet ? item.priceNet : '0',
                            discountPlatformCode: couponPlatform.length ? `${couponPlatform[0]?.couponCode?.coupon?.promotion?.name}\n${couponPlatform[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountPlatform: item.discount?.priceItemDiscount || item.discount?.priceShippingDiscount || item.discount?.priceVoucherDiscount ? item.discount?.priceItemDiscount + item.discount?.priceShippingDiscount + item.discount?.priceVoucherDiscount : '0',
                            discountShopCode: couponShop.length ? `${couponShop[0]?.couponCode?.coupon?.promotion?.name}\n${couponShop[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountShop: item.discount?.priceItemDiscountShop || item.discount?.priceShippingDiscountShop || item.discount?.priceVoucherDiscountShop ? item.discount?.priceItemDiscountShop + item.discount?.priceShippingDiscountShop + item.discount?.priceVoucherDiscountShop : '0',
                            shippingPrice: item.priceShippingNet ? item.priceShippingNet : '0',
                            total: item.priceTotal,
                            paymentDate: displayDateThShortYear(item.paymentedAt, 'DD MMM YY'),
                            paymentMethod: checkDetailPayment(item.paymentMethod),
                            deliverySuccess: displayDateThShortYear(item.deliveredAt, 'DD MMM YY'),
                            refundDate: displayDateThShortYear(item.issueAt, 'DD MMM YY'),
                            refundStatus: checkRefundStatus(item.refundStatus, item.logisticStatus),
                            customerTax: item.customerTaxInformation?.taxType ? `${item.customerTaxInformation?.taxType === 'individual' ? `บุคคลธรรมดา\n${item.customerTaxInformation?.name}\nหมายเลขบัตรประชาชน ${item.customerTaxInformation?.taxNo}\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.address} ${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}` : `นิติบุคคล\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.name}\n${item.customerTaxInformation?.address}\n${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}\nหมายเลขประจำตัวผู้เสียภาษี ${item.customerTaxInformation?.taxNo}\n${item.customerTaxInformation?.branch === '00000' ? 'สำนักงานใหญ่' : `สาขา ${item.customerTaxInformation?.branch}`}`}` : ''
                        })
                    })
                    return {
                        header: ['ลำดับ', 'หมายเลขออเดอร์', 'รูปแบบการจัดส่ง', 'หมายเลขพัสดุ', 'เลขอ้างอิง Transaction', 'ชื่อร้านค้า', 'ข้อมูลการจัดส่ง', 'E-Mail', 'รายการสั่งซื้อ', 'ราคาสินค้า', 'เลขคูปองส่วนลด V-AVENUE', 'มูลค่าส่วน V-AVENUE', 'เลขคูปองส่วนลดร้านค้า', 'มูลค่าส่วนลดร้านค้า', 'ค่าจัดส่ง', 'ยอดที่ลูกค้าชำระ', 'วันที่ชำระเงิน', 'ช่องทางการชำระเงิน', 'วันที่จัดส่งสำเร็จ', 'วันที่ขอคืนเงิน', 'สถานะการคืนเงิน', 'ข้อมูลใบกำกับภาษี'],
                        data: arr
                    }
                    break;
                case 'refundApproved':
                    data.map((item, index) => {
                        couponPlatform = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'platform')
                        couponShop = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'shop')

                        arr.push({
                            index: (index + 1).toString(),
                            orderNo: item.orderNo,
                            deliveryBy: item.delivery?.logistic,
                            trackingNo: item.delivery?.trackingNo,
                            transaction: item.payment.transactionId,
                            shopName: item.shop.name,
                            customerName: item.delivery?.logistic === 'Digital' ? `${item.customerInformation.email}` : `${item.customerInformation.name}\r\n${item.customerInformation.mobile}\r\n${item.customerInformation.address} ${item.customerInformation.district}\r\n${item.customerInformation.subdistrict} ${item.customerInformation.province} ${item.customerInformation.zipcode}`,
                            email: `${item.customerInformation.email ? item.customerInformation.email : ''}`,
                            orderItem: `${item.items?.map((product) => `${product.product?.name}\r\n${product.productSKU?.sku}\r\n${product?.productSKU?.sellerSKU ? `เลขอ้างอิง SKU : ${product?.productSKU?.sellerSKU ? product?.productSKU?.sellerSKU : ''}\r\n` : ''}${product?.productSKU?.barcode ? `บาร์โค้ด : ${product?.productSKU?.barcode ? product?.productSKU?.barcode : ''}\r\n` : ''}จำนวน : ${product?.quantity}`).join('\r\n')}`,
                            // quantityProduct: item.items.length,
                            itemPrice: item.priceNet ? item.priceNet : '0',
                            discountPlatformCode: couponPlatform.length ? `${couponPlatform[0]?.couponCode?.coupon?.promotion?.name}\n${couponPlatform[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountPlatform: item.discount?.priceItemDiscount || item.discount?.priceShippingDiscount || item.discount?.priceVoucherDiscount ? item.discount?.priceItemDiscount + item.discount?.priceShippingDiscount + item.discount?.priceVoucherDiscount : '0',
                            discountShopCode: couponShop.length ? `${couponShop[0]?.couponCode?.coupon?.promotion?.name}\n${couponShop[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountShop: item.discount?.priceItemDiscountShop || item.discount?.priceShippingDiscountShop || item.discount?.priceVoucherDiscountShop ? item.discount?.priceItemDiscountShop + item.discount?.priceShippingDiscountShop + item.discount?.priceVoucherDiscountShop : '0',
                            shippingPrice: item.priceShippingNet ? item.priceShippingNet : '0',
                            total: item.priceTotal,
                            paymentDate: displayDateThShortYear(item.paymentedAt, 'DD MMM YY'),
                            paymentMethod: checkDetailPayment(item.paymentMethod),
                            deliverySuccess: displayDateThShortYear(item.deliveredAt, 'DD MMM YY'),
                            refundDate: displayDateThShortYear(item.issueAt, 'DD MMM YY'),
                            refundApproveDate: displayDateThShortYear(item.refundApprovedAt || null, 'DD MMM YY'),
                            refundStatus: checkRefundStatus(item.refundStatus, item.logisticStatus),
                            customerTax: item.customerTaxInformation?.taxType ? `${item.customerTaxInformation?.taxType === 'individual' ? `บุคคลธรรมดา\n${item.customerTaxInformation?.name}\nหมายเลขบัตรประชาชน ${item.customerTaxInformation?.taxNo}\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.address} ${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}` : `นิติบุคคล\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.name}\n${item.customerTaxInformation?.address}\n${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}\nหมายเลขประจำตัวผู้เสียภาษี ${item.customerTaxInformation?.taxNo}\n${item.customerTaxInformation?.branch === '00000' ? 'สำนักงานใหญ่' : `สาขา ${item.customerTaxInformation?.branch}`}`}` : ''
                        })
                    })
                    return {
                        header: ['ลำดับ', 'หมายเลขออเดอร์', 'รูปแบบการจัดส่ง', 'หมายเลขพัสดุ', 'เลขอ้างอิง Transaction', 'ชื่อร้านค้า', 'ข้อมูลการจัดส่ง', 'E-Mail', 'รายการสั่งซื้อ', 'ราคาสินค้า', 'เลขคูปองส่วนลด V-AVENUE', 'มูลค่าส่วน V-AVENUE', 'เลขคูปองส่วนลดร้านค้า', 'มูลค่าส่วนลดร้านค้า', 'ค่าจัดส่ง', 'ยอดที่ลูกค้าชำระ', 'วันที่ชำระเงิน', 'ช่องทางการชำระเงิน', 'วันที่จัดส่งสำเร็จ', 'วันที่ขอคืนเงิน', 'วันที่อนุมัติคำขอ', 'สถานะการคืนเงิน', 'ข้อมูลใบกำกับภาษี'],
                        data: arr
                    }
                case 'refunded':
                    data.map((item, index) => {
                        couponPlatform = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'platform')
                        couponShop = item.couponCodeOrder.filter((code) => code.couponCode?.coupon?.promotion?.owner === 'shop')
                        arr.push({
                            index: (index + 1).toString(),
                            orderNo: item.orderNo,
                            deliveryBy: item.delivery?.logistic,
                            trackingNo: item.delivery?.trackingNo,
                            transaction: item.payment.transactionId,
                            shopName: item.shop.name,
                            customerName: item.delivery?.logistic === 'Digital' ? `${item.customerInformation.email}` : `${item.customerInformation.name}\r\n${item.customerInformation.mobile}\r\n${item.customerInformation.address} ${item.customerInformation.district}\r\n${item.customerInformation.subdistrict} ${item.customerInformation.province} ${item.customerInformation.zipcode}`,
                            email: `${item.customerInformation.email ? item.customerInformation.email : ''}`,
                            orderItem: `${item.items?.map((product) => `${product.product?.name}\r\n${product.productSKU?.sku}\r\n${product?.productSKU?.sellerSKU ? `เลขอ้างอิง SKU : ${product?.productSKU?.sellerSKU ? product?.productSKU?.sellerSKU : ''}\r\n` : ''}${product?.productSKU?.barcode ? `บาร์โค้ด : ${product?.productSKU?.barcode ? product?.productSKU?.barcode : ''}\r\n` : ''}จำนวน : ${product?.quantity}\r\n`).join('\r\n')}`,
                            // quantityProduct: item.items.length,
                            itemPrice: item.priceNet ? item.priceNet : '0',
                            discountPlatformCode: couponPlatform.length ? `${couponPlatform[0]?.couponCode?.coupon?.promotion?.name}\n${couponPlatform[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountPlatform: item.discount?.priceItemDiscount || item.discount?.priceShippingDiscount || item.discount?.priceVoucherDiscount ? item.discount?.priceItemDiscount + item.discount?.priceShippingDiscount + item.discount?.priceVoucherDiscount : '0',
                            discountShopCode: couponShop.length ? `${couponShop[0]?.couponCode?.coupon?.promotion?.name}\n${couponShop[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountShop: item.discount?.priceItemDiscountShop || item.discount?.priceShippingDiscountShop || item.discount?.priceVoucherDiscountShop ? item.discount?.priceItemDiscountShop + item.discount?.priceShippingDiscountShop + item.discount?.priceVoucherDiscountShop : '0',
                            shippingPrice: item.priceShippingNet ? item.priceShippingNet : '0',
                            total: item.priceTotal,
                            paymentDate: displayDateThShortYear(item.paymentedAt, 'DD MMM YY'),
                            paymentMethod: checkDetailPayment(item.paymentMethod),
                            refundDate: displayDateThShortYear(item.issueAt, 'DD MMM YY'),
                            refundAt: displayDateThShortYear(item.updatedAt, 'DD MMM YY'),
                            refundStatus: checkRefundStatus(item.refundStatus, item.logisticStatus),
                            customerTax: item.customerTaxInformation?.taxType ? `${item.customerTaxInformation?.taxType === 'individual' ? `บุคคลธรรมดา\n${item.customerTaxInformation?.name}\nหมายเลขบัตรประชาชน ${item.customerTaxInformation?.taxNo}\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.address} ${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}` : `นิติบุคคล\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.name}\n${item.customerTaxInformation?.address}\n${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}\nหมายเลขประจำตัวผู้เสียภาษี ${item.customerTaxInformation?.taxNo}\n${item.customerTaxInformation?.branch === '00000' ? 'สำนักงานใหญ่' : `สาขา ${item.customerTaxInformation?.branch}`}`}` : '',
                            requestNo: item?.issue[item.issue?.length - 1]?.issueNo,
                            refundType:  item?.issue[item.issue?.length - 1]?.refundType === 'void' ? 'Void' : item?.issue[item.issue?.length - 1]?.refundType === 'refunded' ? 'Refund' : '',
                            voidRefundDate:  displayDateThShortYear(item?.issue[item.issue?.length - 1]?.refundedAt,'DD MMM YY HH:mm')
                        })
                    })
                    return {
                        header: ['ลำดับ', 'หมายเลขออเดอร์', 'รูปแบบการจัดส่ง', 'หมายเลขพัสดุ', 'เลขอ้างอิง Transaction', 'ชื่อร้านค้า', 'ข้อมูลการจัดส่ง', 'E-Mail', 'รายการสั่งซื้อ', 'ราคาสินค้า', 'เลขคูปองส่วนลด V-AVENUE', 'มูลค่าส่วน V-AVENUE', 'เลขคูปองส่วนลดร้านค้า', 'มูลค่าส่วนลดร้านค้า', 'ค่าจัดส่ง', 'ยอดที่ลูกค้าชำระ', 'วันที่ชำระเงิน', 'ช่องทางการชำระเงิน', 'วันที่ขอคืนเงิน', 'คืนเงินวันที่', 'สถานะการคืนเงิน', 'ข้อมูลใบกำกับภาษี','Request No.','Refund Type','Void/Refund Date'],
                        data: arr
                    }
                    break;
                case 'deliveryDelayed':
                    data.map((item, index) => {
                        arr.push({
                            index: (index + 1).toString(),
                            orderNo: item.orderNo,
                            transaction: item.payment.transactionId,
                            shopName: item.shop.name,
                            customerName: item.delivery?.logistic === 'Digital' ? `${item.customerInformation.email}` : `${item.customerInformation.name}\r\n${item.customerInformation.mobile}\r\n${item.customerInformation.address} ${item.customerInformation.district}\r\n${item.customerInformation.subdistrict} ${item.customerInformation.province} ${item.customerInformation.zipcode}`,
                            email: `${item.customerInformation.email ? item.customerInformation.email : ''}`,
                            orderItem: `${item.items?.map((product) => `${product.product?.name}\r\n${product.productSKU?.sku}\r\n${product?.productSKU?.sellerSKU ? `เลขอ้างอิง SKU : ${product?.productSKU?.sellerSKU ? product?.productSKU?.sellerSKU : ''}\r\n` : ''}${product?.productSKU?.barcode ? `บาร์โค้ด : ${product?.productSKU?.barcode ? product?.productSKU?.barcode : ''}\r\n` : ''}จำนวน : ${product?.quantity}\r\n`).join('\r\n')}`,
                            // quantityProduct: item.items.length,
                            itemPrice: item.priceNet ? item.priceNet : '0',
                            discountPlatformCode: couponPlatform.length ? `${couponPlatform[0]?.couponCode?.coupon?.promotion?.name}\n${couponPlatform[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountPlatform: item.discount?.priceItemDiscount || item.discount?.priceShippingDiscount || item.discount?.priceVoucherDiscount ? item.discount?.priceItemDiscount + item.discount?.priceShippingDiscount + item.discount?.priceVoucherDiscount : '0',
                            discountShopCode: couponShop.length ? `${couponShop[0]?.couponCode?.coupon?.promotion?.name}\n${couponShop[0]?.couponCode?.coupon?.refCode1}` : '',
                            priceDiscountShop: item.discount?.priceItemDiscountShop || item.discount?.priceShippingDiscountShop || item.discount?.priceVoucherDiscountShop ? item.discount?.priceItemDiscountShop + item.discount?.priceShippingDiscountShop + item.discount?.priceVoucherDiscountShop : '0',
                            shippingPrice: item.priceShippingNet ? item.priceShippingNet : '0',
                            total: item.priceTotal,
                            paymentDate: displayDateThShortYear(item.paymentedAt, 'DD MMM YY'),
                            paymentMethod: checkDetailPayment(item.paymentMethod),
                            deliveryStatus: checkLogisticStatus(item.logisticStatus, item.shipmentStatus),
                            deliveryAt: displayDateThShortYear(item.deliveryDate, 'DD MMM YY'),
                            customerTax: item.customerTaxInformation?.taxType ? `${item.customerTaxInformation?.taxType === 'individual' ? `บุคคลธรรมดา\n${item.customerTaxInformation?.name}\nหมายเลขบัตรประชาชน ${item.customerTaxInformation?.taxNo}\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.address} ${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}` : `นิติบุคคล\nที่อยู่ใบกำกับภาษี\n${item.customerTaxInformation?.name}\n${item.customerTaxInformation?.address}\n${item.customerTaxInformation?.subdistrict} ${item.customerTaxInformation?.district} ${item.customerTaxInformation?.province} ${item.customerTaxInformation?.zipcode}\nหมายเลขประจำตัวผู้เสียภาษี ${item.customerTaxInformation?.taxNo}\n${item.customerTaxInformation?.branch === '00000' ? 'สำนักงานใหญ่' : `สาขา ${item.customerTaxInformation?.branch}`}`}` : ''
                        })
                    })
                    return {
                        header: ['ลำดับ', 'หมายเลขออเดอร์', 'เลขอ้างอิง Transaction', 'ชื่อร้านค้า', 'ข้อมูลการจัดส่ง', 'E-Mail', 'รายการสั่งซื้อ', 'ราคาสินค้า', 'เลขคูปองส่วนลด V-AVENUE', 'มูลค่าส่วน V-AVENUE', 'เลขคูปองส่วนลดร้านค้า', 'มูลค่าส่วนลดร้านค้า', 'ค่าจัดส่ง', 'ยอดที่ลูกค้าชำระ', 'วันที่ชำระเงิน', 'ช่องทางการชำระเงิน', 'สถานะการจัดส่ง', 'ส่งภายในวันที่', 'ข้อมูลใบกำกับภาษี'],
                        data: arr
                    }
                    break;
                default:
                    break;
            }
        }


        const data = await new Promise(async (resolve, reject) => {
            try {
                const { order } = this.props
                const { statusSearch, listShop } = this.state
                if (!statusSearch) {
                    const res = await orderProvider.getListOrder({ status: order.status, shopId: listShop[this.state.searchShopSelectedIndex].value.id })
                    const fetchRes = await separateType(res.data)
                    resolve(fetchRes)
                } else {
                    const res = await orderProvider.onFilterSearchOrder({ kwby: order.filterSearchSelect, kw: order.filterSearchValue, shopId: listShop[this.state.searchShopSelectedIndex].value.id, status: order.status })
                    const fetchRes = await separateType(res.data.data)
                    resolve(fetchRes)
                }
            } catch (error) {
                reject(error)
                return
            }
        })
        return data
    }

    render() {
        const { activeTab, listShop } = this.state
        const { shop, order } = this.props
        const { listOrder,
            // loading, shop, order 
        } = this.props
        const { pageSizeOptions, limit, page, total,
            // status,
            filterSearchValue, filterSearchSelect, searchShopSelect } = this.props.order

        return (
            <div className="order-history">
                <div className="header-order">
                    <H5 >รายการสั่งซื้อ </H5>
                </div>

                <div className="search-container">
                    <div style={{ width: '100%', display: 'flex' }}>
                        {getUserRole() !== OWNER &&
                            <div className="w-50">
                                <SelectInput
                                    width={'98%'}
                                    options={listShop}
                                    onChange={(data) => this.onChangeInputSearch(data)}
                                    value={this.state.searchShopSelectedIndex}
                                    onClearSearchShop={() => this.onClearSearchShop()}
                                />
                                &nbsp; &nbsp;
                            </div>
                        }
                        <FilterSearch
                            width={'50%'}
                            onChange={(data) => this.onChangeFilter(data)}
                            options={filterSearch}
                            value={filterSearchValue}
                            onChangeValue={(e) => this.onChangeFilterValue(e)}
                            onClearSearchFilterType={this.onClearSearchFilterType}
                            valueTypeFilter={this.state.typeFilterSelectedIndex}
                            onSearchOrder={() => this.onSearchOrder(filterSearchSelect, filterSearchValue, searchShopSelect)}
                            onKeyPress={(e) => this.onEnterSearch(e, filterSearchSelect, filterSearchValue, searchShopSelect)}
                            statusSearch={this.state.statusSearch}
                        />
                    </div>
                    <div>
                        {/* <button className='btn btn-yellow' style={{width:'185px'}}> ดาวน์โหลดรายการจัดส่ง </button>  */}
                    </div>
                </div>

                <div className="body-order-content">
                    <div className="tab-order">
                        <div>
                            <Nav tabs>
                                {/* <NavItem onClick={() => this.toggle('waitShipping')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'waitShipping' })} >
                                         ออเดอร์ใหม่ ({listOrder?.waitShipping})
                                </NavLink>
                                </NavItem> */}
                                <NavItem onClick={() => this.toggle('waitShipping')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'waitShipping' })} >
                                        <Caption>รอจัดส่ง</Caption> ({listOrder?.waitShipping})
                                    </NavLink>
                                </NavItem>
                                <NavItem onClick={() => this.toggle('onTheWay')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'onTheWay' })} >
                                        <Caption>อยู่ระหว่างขนส่ง</Caption> ({listOrder?.onTheWay})
                                    </NavLink>
                                </NavItem>

                                <NavItem onClick={() => this.toggle('success')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'success' })} >
                                        <Caption>ลูกค้าได้รับแล้ว</Caption>
                                    </NavLink>
                                </NavItem>

                                <NavItem onClick={() => this.toggle('waitRefund')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'waitRefund' })} >
                                        <Caption>รออนุมัติขอคืนเงิน</Caption> ({listOrder?.waitRefund})
                                    </NavLink>
                                </NavItem>

                                <NavItem onClick={() => this.toggle('refundApproved')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'refundApproved' })} >
                                        <Caption>กำลังดำเนินการคืนเงิน</Caption> ({listOrder?.refundApproved})
                                    </NavLink>
                                </NavItem>

                                <NavItem onClick={() => this.toggle('refunded')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'refunded' })} >
                                        <Caption>คืนเงินแล้ว</Caption>
                                    </NavLink>
                                </NavItem>
                                <NavItem onClick={() => this.toggle('deliveryDelayed')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'deliveryDelayed' })} >
                                        <Caption>เกินเวลาจัดส่ง</Caption> ({listOrder?.deliveryDelayed})
                                    </NavLink>
                                </NavItem>

                            </Nav>
                        </div>
                    </div>

                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {/* <div className="input-text-field">
                            <input type="text" placeholder="ค้นหาออเดอร์" value={this.state.search} onChange={(e) => this.onSearchOrder(e)} onKeyPress={(e) => this.handleEnterSearchOrder(e)} />
                            <img src={Search} alt="Search" onClick={this.onClickSearchOrder} className="cursor-pointer" />
                        </div> */}

                        {/* {this.props.order.status == "success" ?
                            <div className="d-flex flex-row align-items-center justify-content-end">
                                <div className="mr-2">สถานะการโอนเงินให้ร้านค้า :
                                </div>
                                <DropDown
                                    onChange={this.selectTypeFilter}
                                    options={typeFilterData}
                                    // placeholder={'ทั้งหมด'}
                                    value={this.state.filter}
                                    className={''}
                                    style={{ width: '10em' }}
                                ></DropDown>
                            </div> : null} */}
                    </div>

                    <div className='d-flex align-items-center justify-content-between btn-download-container header-order-container'>
                        {
                            listOrder && listOrder.data?.length == 0 ? null :
                                <React.Fragment>
                                    <div className='d-flex gap-2'>

                                        <XlsxCreate
                                            handleFetchData={() => this.fetchDataExcel()}
                                            fileName={`${(order.searchShopSelect?.value ? order.searchShopSelect.value.name : shop.length <= 1 ? shop[0]?.name : 'ร้านค้าทั้งหมด')}-รายการสั่งซื้อ-สถานะ${listNavTab[activeTab]} ${displayDateThShortYear(new Date(), 'DD-MM-YY')}`}
                                            headerA1={`รายการสั่งซื้อ-สถานะ${listNavTab[activeTab]}`}
                                            numberFormat={'0,0.00'}
                                            nonNumberFormat={
                                                activeTab === 'waitShipping' ? [''] :
                                                    activeTab === 'onTheWay' ? [''] :
                                                        activeTab === 'success' ? [''] :
                                                            activeTab === 'waitRefund' ? [''] :
                                                                activeTab === 'refundApproved' ? [''] :
                                                                    activeTab === 'refunded' ? [''] :
                                                                        activeTab === 'deliveryDelayed' ? [''] : null
                                            }
                                        >
                                            <button className='btn-cancel' style={{ padding: '0.5rem 0.3rem' }}> <IconDownload className='icon-download' /> ดาวน์โหลดข้อมูล </button>
                                        </XlsxCreate>
                                        {(getUserRole() === OWNER && activeTab === 'waitShipping') &&
                                            <Link to='/manage/order/all/create/mass-upload-tracking'>
                                                <button className='btn-cancel' style={{ padding: '0.5rem 0.3rem' }}> <IconDownload className='icon-download' /> อัปโหลดหมายเลขพัสดุแบบชุด </button>
                                            </Link>
                                        }
                                    </div>
                                    <div className="pagination-top-order">
                                        <div className="float-right mt-3 position-relative">
                                            <Pagination
                                                defaultCurrent={1}
                                                total={total}
                                                onChange={(page) => this.onChangePage(page)}
                                                pageSize={limit}
                                                pageSizeOptions={pageSizeOptions}
                                                onShowSizeChange={this.onShowSizeChange}
                                                current={page}
                                            />
                                        </div>
                                    </div>

                                </React.Fragment>
                        }

                    </div>

                    <div className="list-order-item">
                        <ListOrder
                            item={listOrder}
                            status={this.state.activeTab}
                            search={this.state.search}
                            filter={this.state.filter.value}
                        />
                    </div>
                </div>
                {listOrder && listOrder.data?.length > 0 &&
                    <div className="float-right mt-4 position-relative">
                        <Pagination
                            defaultCurrent={1}
                            total={total}
                            onChange={(page) => this.onChangePage(page)}
                            pageSize={limit}
                            pageSizeOptions={pageSizeOptions}
                            onShowSizeChange={this.onShowSizeChange}
                            current={page}
                        />
                    </div>
                }

                <ModalInvoiceDetail
                    order={this.props.order}
                    search={this.state.search}
                    filter={this.state.filter.value}
                />

                <ModalCoverSheetPrint />

                <ModalAlert
                    onSubmit={() => this.toggle('deliveryDelayed')}
                />

            </div>


        )
    }
}


const mapStateToProps = (state) => ({
    listOrder: state.order.listOrder,
    order: state.order,
    tabSelected: state.order.status,
    shop: state.shop.listShopName
})

export default connect(mapStateToProps, null)(Order)
