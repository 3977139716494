export const SET_LIST_WITHDRAW = "SET_LIST_WITHDRAW"
export const SET_TAB_ACTIVE = "SET_TAB_ACTIVE"
export const GET_LIST_WITHDRAW = "GET_LIST_WITHDRAW"
export const SET_SELECT_WITHDRAW = "SET_SELECT_WITHDRAW"
export const ON_ADD_SELECT_WITHDRAW = "ON_ADD_SELECT_WITHDRAW"
export const ON_SELECT_ALL_WITHDRAW = "ON_SELECT_ALL_WITHDRAW"
export const ON_CONFITM_WITHDRAW = "ON_CONFITM_WITHDRAW"
export const HANDLE_SET_TAB_ACTIVE = "HANDLE_SET_TAB_ACTIVE"
export const SET_KEY_WITHDRAW = "SET_KEY_WITHDRAW"
export const ON_SEARCH_WITHDRAW = "ON_SEARCH_WITHDRAW"
export const SET_DEFAULT_WITHDRAW = "SET_DEFAULT_WITHDRAW"
export const OPEN_MODAL_WITHDRAW_PREVIEW = "OPEN_MODAL_WITHDRAW_PREVIEW"
export const CLOSE_MODAL_WITHDRAW_PREVIEW = "CLOSE_MODAL_WITHDRAW_PREVIEW"
export const FETCH_ORDER_SELLER_WITHDRAW = "FETCH_ORDER_SELLER_WITHDRAW"
export const SET_MODAL_SELLER_WITHDRAW = "SET_MODAL_SELLER_WITHDRAW"
export const GET_LIST_SHOP_WITHDRAW = "GET_LIST_SHOP_WITHDRAW"

export function disapatchWithdraw(params) {
  return {
    type: params.type,
    payload: params.payload,
  };
}