/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import host from 'config/host'
import { dispatchShop, UPDATE_SHOP_DETAIL } from 'redux/actions/shop'
import { dispatchGallery, SET_GALLERY_FIELD } from 'redux/actions/gallery'
import { TextInput, Textarea, FloatInput } from 'components/input'
import { ButtonUpload, ModalLoading } from 'components/output'
import IconShopAvatar from 'images/icons/default-shop-logo.svg'
import closeImage from 'images/icons/close-image.svg'
import { readCookie } from 'helper/cookieHelper'
import SwitchComponent from 'components/SwitchGreen'
import { Body1, Caption, H5 } from 'components/FontStyle'
import {
  // OWNER,
  SUPER_ADMIN
} from 'layout/Sidebar/Role'
import ShopThemeColor from './ShopThemeColor'
import shopProvider from 'provider/shopProvider'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert'
import { msgErrRequire } from 'helper/constant'

import WebHookComponent from './WebHook'
import { OPEN_TOAST, dispatchNotification } from 'redux/actions/notification'

const FormShopDetail = (props) => {
  const { shopDetail, media, dispatch, shopDetailError } = props
  const [data, setData] = useState(null)
  const [initalData, setInitalData] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [role, setRole] = useState('admin')
  const [shopThemeColor, setShopThemeColor] = useState([
    { label: 'สีหลัก', value: '#B2D234' },
    { label: 'สีพื้นหลัง', value: '#F9FBEF' },
    { label: 'สีรอง', value: '#252527' },
    { label: 'สีตัวอักษรหลัก', value: '#2A2C2D' },
    { label: 'สีตัวอักษรรอง', value: '#FFFFFF' }])
  const [themeInitial, setThemeInitial] = useState(null)
  // useEffect(() => {
  //   (async () => {
  //     await getTheme()
  //   })()
  // }, [])
  const shopApi = new shopProvider()

  useEffect(() => {
    switch (shopDetailError) {
      case 'THIS_PERMALINK_IS_EXIST.':
        setError({ ...error, permalink: 'permalink นี้มีในระบบแล้ว' })
        break;
      case 'NAME_IS_ALREADY_EXIST':
        setError({ ...error, name: 'ชื่อนี้มีในระบบแล้ว' })
        break;
      default:
        break;
    }
  }, [shopDetailError])

  useEffect(() => {
    (async () => {
      if (shopDetail?.id) {
        await getTheme(shopDetail?.id)
      }
    })()
    setIsLoading(false)
    const roleUser = readCookie('role')
    setRole(roleUser)
    const tmpInitalData = {
      logoPathId: shopDetail?.logoPath?.id || null,
      logoPath: shopDetail?.logoPath?.path || '',
      coverPathId: shopDetail?.coverPath?.id || null,
      coverPath: shopDetail?.coverPath?.path || '',
      name: shopDetail?.name || '',
      description: shopDetail?.description || '',
      sharingShop: shopDetail?.sharingShop || null,
      sharingPlatform: shopDetail?.sharingPlatform || null,
      paymentFee: `${shopDetail?.shopFixedRate?.fixedRate.toFixed(2)}` || null,
      permalink: shopDetail?.seo?.permalink || null,
      shopCode: shopDetail?.shopCode || null,
      status: shopDetail?.status === 'show' ? true : false,
      webHook: shopDetail?.webHook || {status: 'hide'},
      webHookAllow: shopDetail?.webHookAllow
    }

    setInitalData(tmpInitalData)
    setData(tmpInitalData)
  }, [shopDetail])

  useEffect(() => {
    if (media.avatar) {
      const tmp = data ? { ...data } : {}
      const listAvatar = media.avatar

      setData({
        ...tmp,
        logoPathId: listAvatar[0]?.id,
        logoPath: listAvatar[0]?.path,
      })
    }
  }, [media.avatar])

  useEffect(() => {
    if (media.coverShop) {
      const tmp = data ? { ...data } : {}

      setData({
        ...tmp,
        coverPathId: media?.coverShop[0]?.id,
        coverPath: media?.coverShop[0]?.path,
      })
    }
  }, [media.coverShop])


  const scrollToPosition = async (id, number) => {
    const ele = await document?.getElementById(id);
    const blockStart = ele?.getBoundingClientRect().top + window.pageYOffset - number;
    window.scrollTo({ top: blockStart, behavior: 'smooth' });
  }


  const validator = () => {
    let tmpError = error ? { ...error } : {}

    let idScroll = ''

    if (!data.logoPath) {
      tmpError = {
        ...tmpError,
        logo: 'กรุณาใส่โปรไฟล์ร้านค้า',
      }

      idScroll = 'section-logo'
    } else {
      delete tmpError.logo
    }

    // if (!data.coverPath) {
    //   tmpError = {
    //     ...tmpError,
    //     cover: 'กรุณาใส่ภาพหน้าปกร้านค้า',
    //   }
    // } else {
    //   delete tmpError.cover
    // }

    if (!data.name) {
      tmpError = {
        ...tmpError,
        name: 'กรุณาใส่ชื่อร้านค้า',
      }

      idScroll = 'section-name'
    } else {
      delete tmpError.name
    }

    if (data.description.length > 5000) {
      tmpError = {
        ...tmpError,
        description: 'กรุณากรอกรายละเอียดร้านค้าไม่เกิน 5,000 ตัวอักษร',
      }

      if(idScroll == "") {
        idScroll = 'section-description'
      }
    } else {
      delete tmpError.description
    }

    // if (!data.shopCode) {
    //   tmpError = {
    //     ...tmpError,
    //     shopCode: 'กรุณาใส่โค้ดร้านค้า'
    //   }
    // } else {
    //   const regexCode = /^[A-Z]{4}$/
    //   if (!regexCode.test(data.shopCode)) {
    //     tmpError = {
    //       ...tmpError,
    //       shopCode: 'กรุณากรอก A-Z จำกัด 4 ตัวอักษร, ห้ามใส่อักขระพิเศษ'
    //     }
    //   } else {
    //     delete tmpError.shopCode
    //   }
    // }

    // if (!data.description) {
    //   tmpError = {
    //     ...tmpError,
    //     description: 'กรุณาใส่รายละเอียดร้านค้า',
    //   }
    // } else {
    //   delete tmpError.description
    // }

    if (!data.permalink) {
      tmpError = {
        ...tmpError,
        permalink: 'กรุณาใส่ permalink',
      }

      if(idScroll == "") {
        idScroll = 'section-url'
      }
    } else {
      delete tmpError.permalink
    }

    if (data.sharingShop < 0) {
      tmpError = {
        ...tmpError,
        sharingShop: 'กรุณาใส่ส่วนแบ่งร้านค้า',
      }

      if(idScroll == "") {
        idScroll = 'section-sharing-shop'
      }
    } else {
      delete tmpError.sharingShop
    }

    if (data.sharingPlatform < 0) {
      tmpError = {
        ...tmpError,
        sharingPlatform: 'กรุณาใส่ส่วนแบ่งระบบ',
      }

      if(idScroll == "") {
        idScroll = 'section-sharing-platform'
      }
    } else {
      delete tmpError.sharingPlatform
    }

    if(data.paymentFee == "") {
      tmpError = {
        ...tmpError,
        paymentFee: 'กรุณาใส่ค่าธรรมเนียมการชำระเงิน',
      }

      if(idScroll == "") {
        idScroll = 'section-payment-fee'
      }
    } else {
      delete tmpError.paymentFee
    }


    if(idScroll) {
      scrollToPosition(idScroll, 50)
      dispatch(
        dispatchModalAlert({
          type: OPEN_MODAL_ALERT,
          payload: {
            isOpen: true,
            message: msgErrRequire,
            type: 'alert',
          },
        }),
      )
    }

    setError(tmpError)
  }

  useEffect(() => {
    if (error && Object.keys(error).length === 0) {
      updateShopDetail()
    }
  }, [error])

  const getTheme = async (shopId) => {
    let themeUpdate = [...shopThemeColor]
    const res = await new shopProvider().getShopMember(shopId)
    if (res.data?.length > 0) {
      res.data.map((item) => {
        if (item.shopId === shopId) {
          Object.keys(item).map((obj, index) => {
            if (obj === 'theme') {
              themeUpdate[0].value = item.theme.primaryMain
              themeUpdate[1].value = item.theme.primaryBg
              themeUpdate[2].value = item.theme.secondaryMain
              themeUpdate[3].value = item.theme.textPrimaryMain
              themeUpdate[4].value = item.theme.textSecondaryMain
            } else {
              return null
            }
          })
        }
      })
    }
    setThemeInitial([themeUpdate[0].value, themeUpdate[1].value, themeUpdate[2].value, themeUpdate[3].value, themeUpdate[4].value])
    setShopThemeColor(themeUpdate)
  }

  const updateShopDetail = () => {
    // setIsLoading(true)
    dispatch(
      dispatchShop({
        type: UPDATE_SHOP_DETAIL,
        payload: {
          shopId: shopDetail.id,
          body: {
            logoPathId: data.logoPathId || null,
            name: data.name,
            description: data.description,
            coverPathId: data.coverPathId || 0,
            sharingShop: parseFloat(data.sharingShop) || 0,
            sharingPlatform: parseFloat(data.sharingPlatform) || 0,
            paymentFee: parseFloat(data.paymentFee),
            seo: {
              permalink: data.permalink
            },
            status: data.status ? 'show' : 'hide',
            shopThemeColor,
            webHook: data.webHook,
            webHookAllow: data.webHookAllow
            // shopCode: data.shopCode
          },
        },
      }),
    )
    // setIsLoading(false)
  }


  const getWebHookKey = async () => {
    const res = await shopApi.getWebHookKey()
    setData({
      ...data,
      webHook: {
        ...data.webHook,
        shopKey: res.data.key,
        shopSecret: res.data.secret
      }
    })
  }


  const handleChangeText = (text, key) => {
    setData({
      ...data,
      [key]: text,
    })
  }

  const handleChangeField = (field, key, text) => {
    setData({
      ...data,
      [field]: {
        ...data[field],
        [key]: text
      }
    })
  }

  const onChangeFloatInput = (value, key) => {
    setData({
      ...data,
      [key]: value,
    })
  }

  const onChangeSharing = (_value, key) => {
    const value = parseFloat(_value) || 0;
    const sharingPlatform = key === 'sharingPlatform' ? value : 100 - value;
    const sharingShop = key === 'sharingShop' ? value : 100 - value;
    setData({
      ...data,
      sharingPlatform,
      sharingShop,
    })
  }

  const clearData = () => {
    setData(initalData)
    setShopThemeColor([
      { label: 'สีหลัก', value: themeInitial[0] },
      { label: 'สีพื้นหลัง', value: themeInitial[1] },
      { label: 'สีรอง', value: themeInitial[2] },
      { label: 'สีตัวอักษรหลัก', value: themeInitial[3] },
      { label: 'สีตัวอักษรรอง', value: themeInitial[4] }
    ])
    setError(null)
  }

  const isDirty = () => {
    const theme = []
    shopThemeColor.map((color) => theme.push(color.value))
    return (JSON.stringify(data) !== JSON.stringify(initalData)) || (JSON.stringify(theme) !== JSON.stringify(themeInitial))
  }

  const clearCover = () => {
    setData({ ...data, coverPath: null, coverPathId: null })
    dispatch(
      dispatchGallery({
        type: SET_GALLERY_FIELD,
        payload: { key: 'coverShop', value: null },
      }),
    )
  }

  const handleWebHookisAllow = () => {
    const newStatus = !data?.webHookAllow;
      setData({
        ...data,
        webHookAllow: newStatus,
        webHook: {
          ...data.webHook,
          status: newStatus ? 'show' : 'hide'
        }
      })
  }

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `คัดลอกสำเร็จ`, type: 'success' } }))
      })
      .catch((error) => {
        alert('Failed to copy:', error);
      });
  };

  return (
    <section className='detail-container mb-5'>
      <div className='detail-content' id='section-logo'>
        <div className='col-12 text-left align-center mt-3'>
          <H5>โลโก้ร้านค้า</H5>
        </div>
        <div className='col-12 text-left align-center mt-1'>
          <Caption>ขนาดรูปแนะนำคือ 300 x 200 พิกเซล</Caption>
        </div>
        <div className='col-12 text-left align-center mt-3 mb-3'>
          <ButtonUpload
            isAvatar
            shopDetail={{
              ...shopDetail,
              shopId: shopDetail?.id,
            }}
            fieldUpload='avatar'
            limitImageUpload={1}
            avatar={
              data?.logoPath ? (
                <img
                  alt=''
                  src={host.avatar + data?.logoPath}
                  className='shop-profile-image'
                />
              ) : (
                <img src={IconShopAvatar} alt='' />
              )
            }
          />
        </div>
        {error && error.logo ? (
          <div className='col-12 text-left'>
            <span style={{ color: 'red' }}>{error.logo}</span>
          </div>
        ) : null}



        <div className='col-12 text-left align-center mt-5'>
          <H5>ภาพหน้าปกร้านค้า</H5>
        </div>
        <div className='col-12 text-left align-center mt-1'>
          <Caption>
            ขนาดรูปแนะนำคือ 970 x 326 พิกเซล ขนาดไฟล์สูงสุดไม่เกิน 300 kb
          </Caption>
        </div>

        <div className='upload col-xl-10 p-3 pb-4'>
          {data?.coverPath ? (
            <div className='d-flex'>
              <span className='item-product-container'>
                <img
                  src={host.image + data.coverPath}
                  alt=''
                  className='image cover'
                  style={{ width: '100%' }}
                />
                <div className='middle' onClick={clearCover}>
                  <img
                    alt=''
                    src={closeImage}
                    className='position-absolute remove-image-product'
                  />
                </div>
              </span>
            </div>
          ) : (
            <ButtonUpload
              width='24em'
              height='12em'
              type='image'
              shopDetail={{
                ...shopDetail,
                shopId: shopDetail?.id,
              }}
              fieldUpload='coverShop'
              limitImageUpload={1}
            />
          )}
        </div>
        {error && error.cover ? (
          <div className='col-12 text-left'>
            <span style={{ color: 'red' }}>{error.cover}</span>
          </div>
        ) : null}

        <div className='col-12 mt-3' id="section-name">
          <TextInput
            label='ชื่อที่ใช้แสดง'
            defaultValue={data?.name}
            handleChangeText={(text) => handleChangeText(text, 'name')}
            error={error && error.name}
            require
            title='(จำกัด 30 ตัวอักษร)'
          />
        </div>
        <div className='col-12 mt-3' id="section-description">
          <Textarea
            label='รายละเอียดร้านค้า'
            defaultValue={data?.description}
            handleChangeText={(text) => handleChangeText(text, 'description')}
            error={error && error.description}
            // require
            title='(จำกัด 5,000 ตัวอักษร)'
          />
        </div>
        <div className='col-12 mt-3' id="section-url">
          <TextInput
            label='ชื่อบน URL'
            title='(จำกัด 100 ตัวอักษร / Optional)'
            defaultValue={data?.permalink}
            handleChangeText={(text) => handleChangeText(text, 'permalink')}
            error={error && error.permalink}
            require
          />
          {/* <Textarea
            label='ชื่อบน URL '
            defaultValue={data?.description}
            handleChangeText={(text) => handleChangeText(text, 'permalink')}
            error={error && error.description}
            require
          /> */}
        </div>
        <div className='col-12 mt-3'>
          <TextInput
            label='รหัสร้านค้า'
            title='(กรุณากรอก A-Z จำกัด 4 ตัวอักษร, ห้ามใส่อักขระพิเศษ)'
            defaultValue={data?.shopCode}
            handleChangeText={(text) => handleChangeText(text, 'shopCode')}
            error={error && error.shopCode}
            require
            disabled
          />
        </div>

        {role === SUPER_ADMIN
          // || role === OWNER 
          ? (
            <div>
              <div className='col-12 mt-3' id="section-sharing-shop">

                <FloatInput
                  name='sharingShop'
                  label='ส่วนแบ่งร้านค้า'
                  type='CURRENCY'
                  placeholder='0.00'
                  defaultValue={data?.sharingShop}
                  onChangeFloatInput={(value) =>
                    onChangeSharing(value, 'sharingShop')
                  }
                  error={error && error.sharingShop}
                  require
                />

              </div>

              <div className='col-12 mt-3' id="section-sharing-platform">
                <FloatInput
                  name='sharingPlatform'
                  label='ส่วนแบ่งระบบ'
                  type='CURRENCY'
                  placeholder='0.00'
                  defaultValue={data?.sharingPlatform ? data?.sharingPlatform : 0}
                  onChangeFloatInput={(value) =>
                    onChangeSharing(value, 'sharingPlatform')
                  }
                  error={error && error.sharingPlatform}
                  require
                />

              </div>
              <div className='col-12 mt-3' id='section-payment-fee'>
                <FloatInput
                  name='paymentFee'
                  label='ค่าธรรมเนียมการชำระเงิน (%)'
                  type='CURRENCY'
                  placeholder='0.00'
                  defaultValue={data?.paymentFee}
                  onChangeFloatInput={(value) =>
                    onChangeFloatInput(value, 'paymentFee')
                  }
                  require
                  error={error && error.paymentFee}
                />
              </div>
            </div>
          ) : null}

        <div className='bg-white p-3 mt-2 d-flex flex-row align-items-center'>
          <Body1>เปิดปิดร้านค้า</Body1>
          <div className="ml-5">
            <SwitchComponent checked={data?.status} onChange={(text) => handleChangeText(text, 'status')} /> <span className="ml-2">{data?.status ? 'เปิดอยู่' : 'ปิดอยู่'}</span>
          </div>
        </div>

        <div>
          <WebHookComponent 
          role={role}
           data={data?.webHook || {}} 
           webHookAllow={data?.webHookAllow}
           handleChangeField={handleChangeField} 
           handleChangeText={handleChangeText} 
           getWebHookKey={getWebHookKey} 
           handleWebHookisAllow={handleWebHookisAllow} 
           handleCopyToClipboard={handleCopyToClipboard}
           />
        </div>

        {/* <div className='row'>
          <div className='col-6 mt-3'>
            <TextInput
              label='TAG'
              handleChangeText={(text) => handleChangeText(text, 'name')}
              error={error && error.name}
              require
            />
          </div>
          <div className='col-6 mt-3'>
            <DropdownWithAPI
              label='Category'
              // handleChangeText={(text) => handleChangeText(text, 'name')}
              error={error && error.name}
              require
            />
          </div>
        </div> */}
      </div>

      <div style={{ paddingLeft: '5.5em', backgroundColor: 'white' }}>
        <ShopThemeColor
          theme={shopThemeColor}
          onChange={(color) => setShopThemeColor(color)}
        />
      </div>

      {isDirty() ? (
        <div className='detail-footer'>
          <button className='m-1 btn-cancel' onClick={clearData}>
            ยกเลิกแก้ไข
          </button>
          <button className='m-1 btn-submit' onClick={validator}>
            บันทึก
          </button>
        </div>
      ) : null}
      {isLoading && <ModalLoading isOpenModal={isLoading} />}
    </section>
  )
}

const mapStateToProps = (state) => ({
  shopDetail: state.shop.shopDetail,
  media: state.gallery,
  shopDetailError: state.shop.shopDetailError
})

export default connect(mapStateToProps)(FormShopDetail)
