import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";

import { makePdf } from '../../helper/pdfTool';
import { createCoverSheetPdfTemplate } from './generatePdfTemplate';

import { dispatchOrder, OPEN_MODAL_COVER_SHEET_PRINT,
  //  SET_ORDER_FIELD 
  } from 'redux/actions/order';

// import IconClose from "../../images/icons/Icon-Close-White.svg";
import {ReactComponent as IconClose} from "../../images/icons/Icon-Close-White.svg"
import { ReactComponent as IconDownload } from "../../images/icons/IconDownload.svg";
import { ReactComponent as IconPrint } from "../../images/icons/IconPrint.svg";
import { ReactComponent as Logo } from "../../images/icons/logo-v-avenue.svg";
import IconScissors from "../../images/icons/scissors.svg";

import './index.scss'

const initialState = {
  action: ''
};

class ModalCoverSheetPrint extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  toggleModal = () =>
    this.props.dispatch(dispatchOrder({
      type: OPEN_MODAL_COVER_SHEET_PRINT,
      payload: { currentInvoice: null, isOpen: false }
    }));


  printDiv = () => {
    let payload = createCoverSheetPdfTemplate(this.props.modalCoverSheetPrint.currentInvoice);
    payload.option = 'print'
    makePdf(payload);
  }

  generatePDF = () => {
    let payload = createCoverSheetPdfTemplate(this.props.modalCoverSheetPrint.currentInvoice);
    payload.option = 'download'
    makePdf(payload);
  }

  render() {

    const {
      isOpen,
      currentInvoice
    } = this.props.modalCoverSheetPrint || {};
    const {
      customerInformation,
      shopInformation,
      orderNo,
      items,
      countOrders,
      shop
    } = currentInvoice || {};
    const {
      mobile: customerMobile,
      name: customerName,
      address: customerAddress,
      province: customerProvince,
      subdistrict: customerSubdistrict,
      district: customerDisTrict,
      zipcode: customerZipcode
    } = customerInformation || {};
    const { district: shopDistrict,
      address: shopAddress,
      province: shopProvince,
      subdistrict: shopSubdistrict,
      zipcode: shopZipcode
    } = shopInformation || {}

    // const { action } = this.state;

    return <React.Fragment>

      <Modal
        isOpen={isOpen}
        backdrop='static'
        style={{ width: '80%', maxWidth: '80%' }}
        id="coverSheet"
      >
        <ModalBody>
        <div className='close-btn-wrap-top'>
          <IconClose onClick={this.toggleModal} className='cursor-pointer'/>
          {/* <img src={IconClose} onClick={this.toggleModal} alt='icon-close' /> */}
        </div>
          <div className="container" id="coverSheetInvoice">
            {/* ANCHOR - cover sheet */}
            <Logo width='105px' height='100%' className='mb-2' />
            <table className="table-content" id='coverSheetTable'>
              <tbody>
                <tr>
                  <td colspan="2" style={{ width: '100%' }}>
                    <h5> ผู้ส่ง {shop?.name}</h5>
                    <p>{shopAddress} {shopSubdistrict} {shopDistrict} {shopProvince} {shopZipcode}</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '60%' }}>
                    <h5> ผู้รับ {customerName}</h5>
                    <p>{customerAddress}  {customerSubdistrict} {customerDisTrict}  {customerProvince}</p>
                  </td>
                  <td className='text-center' style={{ width: '40%' }}>
                    <h5>เบอร์โทรศัพท์</h5>
                    <h3>{customerMobile}</h3>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '60%' }} className='text-center'>
                    <h5>{customerDisTrict}</h5>
                    <h1>{customerZipcode}</h1>
                  </td>
                  <td className='text-center' style={{ width: '40%' }}>
                    <h3>ไม่ต้องเก็บเงิน</h3>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <h4>หมายเลขออเดอร์ {orderNo} </h4>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row pr-3 pl-3 mt-4 mb-4">
              <div style={{ width: '3%', paddingTop: '5px' }}>
                <img src={IconScissors} alt="" />
              </div>
              <div style={{ width: '37%', padding: '2px 5px 0 5px' }}>
                <hr style={{ border: 'gray 1px dashed' }} />
              </div>
              <div className="pt-2 text-center" style={{ width: '20%' }}>
                <p>กรุณาพับหรือตัดส่วนนี้</p>
              </div>
              <div style={{ width: '40%', padding: '2px 5px 0 5px' }}>
                <hr style={{ border: 'gray 1px dashed' }} />
              </div>
            </div>

            {/* ANCHOR - invoice detail */}
            <table className="table-content" id="invoiceDetailTable">
              <thead>
                <tr>
                  <td colspan="3">
                    <div className="row pb-1">
                      <div className="col-1 text-center">
                        <span> #</span>
                      </div>
                      <div className="col-4 text-left">
                        <span>ชื่อสินค้า</span>
                      </div>
                      <div className="col-5 text-left">
                        <span>ตัวเลือกสินค้า</span>
                      </div>
                      <div className="col-2 text-center">
                        <span>จำนวน</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3">
                    {items && items?.length > 0 &&
                      items.map((item, itemIndex) =>
                        <React.Fragment >
                          <div className="row pb-1">
                            <div className="col-1 text-center">
                              <span> {(itemIndex + 1)}</span>
                            </div>
                            <div className="col-4 text-left">
                              <span>{item?.product.name}</span>
                            </div>
                            <div className="col-5 text-left">
                              <span>{item?.sku}</span>
                            </div>
                            <div className="col-2 text-center">
                              <span>x {item?.quantity}</span>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '60%', borderBottom: 'none', borderLeft: 'none' }}>
                  </td>
                  <td style={{ width: '13%' }} className='text-center pb-2'>
                    <span>จำนวน</span>
                  </td>
                  <td style={{ width: '13%' }} className='text-center pb-2'>
                    <span>{countOrders}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* ANCHOR - submit form */}
          <div id="submit-form">
            <button className='btn-cancel mr-2' onClick={() => this.generatePDF()} >
              <IconDownload className="mr-1 icon-download" />
              ดาวโหลด
            </button>
            <button className='btn-submit' onClick={() => this.printDiv()}>
              <IconPrint className="mr-1 icon-print" />
              พิมพ์เอกสาร
            </button>
          </div>

        </ModalBody>
      </Modal>
    </React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  modalCoverSheetPrint: state.order.modalCoverSheetPrint,
});

export default connect(mapStateToProps)(ModalCoverSheetPrint
);
