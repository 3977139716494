export const ADD_KEY_VALUE_SELECTEDED_SHOP = "ADD_KEY_VALUE_SELECTEDED_SHOP"
export const REMOVE_SHOP_SELECTED_SHOP = 'REMOVE_SHOP_SELECTED_SHOP'
export const UPDATE_COUNT_SELECTED_SHOP = 'UPDATE_COUNT_SELECTED_SHOP'
export const ADD_PRODUCT_SELECTED_SHOP = "ADD_PRODUCT_SELECTED_SHOP"
export const RESET_SELECTED_SHOP = "RESET_SELECTED_SHOP"

export function dispatchSelectedShop(param) {
  return {
    type: param.type,
    payload: param.payload,
  };
}

export const removeShopSelectedShop = (param) => {
  return {
    type: 'REMOVE_SHOP_SELECTED_SHOP',
    payload: param.payload,
  };
};