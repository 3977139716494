import React from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import './index.scss';
export default class Editor extends React.Component {
    constructor (props) {
      super(props)
      this.state = { editorHtml: '', theme: 'snow' }
      this.handleChange = this.handleChange.bind(this)
    }

    componentWillReceiveProps = nextProps => {
      
        if(nextProps.html && !Array.isArray(nextProps.html)) {
          this.setState({ editorHtml: nextProps.html });
        }
        
    }

   
    handleChange (html) {
        this.setState({ editorHtml: html });
        this.props.onChange(html);
    }
    
    render () {
      const {readOnly} = this.props;
      return (
        <div>
          <ReactQuill 
            theme={this.state.theme}
            onChange={this.handleChange}
            value={this.state.editorHtml}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={'.editor'}
            placeholder={this.props.placeholder}
            readOnly={readOnly}
           />
         </div>
       )
    }
  }
  
  /* 
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  Editor.modules = {
    toolbar: [
     
      ['bold', 'italic', 'underline'],
     
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  /* 
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  Editor.formats = [
    'bold', 'italic', 'underline'
  ]
  
  /* 
   * PropType validation
   */
  Editor.propTypes = {
    placeholder: PropTypes.string,
  }