/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { 
    // Button,
     Modal, ModalHeader, ModalBody, 
    //  ModalFooter
    } from 'reactstrap';
import './styles.scss'
import { 
    // AutoComplete, Input
    Radio } from 'antd';
import DateTimeLength from 'components/DateTimeLength';
import { ButtonUpload } from 'components/output';
import { ReactComponent as IconClose } from 'images/icons/close.svg'
import { ReactComponent as IconCloseCircle } from 'images/icons/close-circle.svg'
import { dispatchRibbon, HANDLE_CREATE_RIBBON, HANDLE_GET_DECORATE_BY_ID, HANDLE_UPDATE_DECORATE_BY_ID, SET_KEY_DATA_RIBBON, SET_KEY_RIBBON, 
    // SET_MODAL_CREATE_RIBBON 
} from 'redux/actions/ribbon';
// import ribbon from 'redux/reducers/ribbon';
// import { clamp } from 'lodash';
import host from 'config/host';
import classNames from 'classnames';
import ToastNotification from 'components/ToastNotification';
import { dispatchGallery, SET_GALLERY_FIELD } from 'redux/actions/gallery';
import AutoCompleteInput from 'components/AutoCompleteInput';
import moment from 'moment'

const ModalAddRibbon = (props) => {

    const {
        // buttonLabel,
        // className,
        isOpen,
        handleClose,
        ribbon,
        dispatch,
        // gallery,
        imagePreview,
        type
    } = props;

    const [imageShow, setImageShow] = useState(null)
    // const [modal, setModal] = useState(false);
    const [err, setErr] = useState({})

    useEffect(() => {
        if (props.imagePreview?.path && err.imageId) {
            delete err['imageId']
            setErr({ ...err })
        }
    })
    useEffect(() => {
        if (!props.ribbon.modalCreateRibbon.isOpen) {
            setErr({})
            props.dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'nameRibbonAlready', value: null } }))
            props.dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'keyRibbonAlready', value: null } }))
        }
    }, [ribbon.modalCreateRibbon])

    useEffect(() => {
        if (type === "edit") {
            dispatch(dispatchRibbon({ type: HANDLE_GET_DECORATE_BY_ID, payload: { id: ribbon.modalCreateRibbon?.data?.id } }))
        }
    }, [type])

    useEffect(() => {
        setImageShow(props.imagePreview)
    }, [imagePreview])

    // const toggle = () => setModal(!modal);

    const onChangeInput = (e) => {
        const value = e.target.value
        const name = e.target.name
        delete err[name]
        if (value.trim().length > 20) return
        props.dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: name, value } }))
    }

    // const onChangeTag = (e) => {
    //     if (e.trim().length > 20) return
    //     props.dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: 'key', value: e } }))
    // }

    const onChangeDateLimit = (e) => {
        const value = e.target.value
        const name = e.target.name
        props.dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: name, value } }))
    }

    const handleSubmit = () => {
        let err = {}
        let validated = false
        const { name, key, imageId, 
            // startDate, endDate, startTime, endTime, status
         } = ribbon.dataRibbon
        if (!name || name.trim().length === 0) {
            err.name = {
                status: true,
                message: "กรุณากรอกชื่อ"
            }
        }
        if (!key || key.trim().length === 0) err.key = {
            status: true,
            message: 'กรุณาใส่ Tag'
        }
        if (!imageId || imageId.toString().trim().length === 0) err.imageId = true
        if (Object.keys(err).length > 0) {
            validated = false
        } else {
            validated = true
        }
        setErr(err)
        if (validated) {
            if (type === "create") {
                dispatch(dispatchRibbon({ type: HANDLE_CREATE_RIBBON }))
            } else {
                dispatch(dispatchRibbon({ type: HANDLE_UPDATE_DECORATE_BY_ID }))
            }
        }
    }

    const editImage = () => {
        dispatch(dispatchGallery({ type: SET_GALLERY_FIELD, payload: { key: 'ribbon', value: [] } }))
        dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'dataRibbon', value: { ...props.ribbon.dataRibbon, imagePath: null } } }))
        setImageShow(null)
    }

    const onSelectTag = (item) => {
        props.dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: 'key', value: item } }))
    }

    const handleChangeDate = (type, date) => {
        let { startDate, endDate } = ribbon.dataRibbon
        startDate = moment(date, 'DD/MM/YYYY')
        endDate = moment(endDate, 'DD/MM/YYYY')
        if (type === 'startDate') {
            if (startDate.diff(endDate)) {
                dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: type, value: date } }))
                dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: 'endDate', value: date } }))
            }
        } else {
            dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: type, value: date } }))
        }
    }


    return (
        <div>
            <Modal isOpen={isOpen} toggle={handleClose} className='modal-add-ribbon'>
                <ModalHeader toggle={handleClose}>
                    <div className="d-flex flex-row justify-content-between">
                        {ribbon.activeTab === "ribbon" && type === "create" ? <span>เพิ่มริบบิ้น</span> : ribbon.activeTab === "ribbon" && type === "edit" ?
                            <span>แก้ไข <img src={`${host.image}${ribbon.modalCreateRibbon?.data?.imagePath?.path}`} alt="" className="ml-2" style={{ maxWidth: '6em' }} /> </span> : null}
                        {ribbon.activeTab === "frame" && type === "create" ? <span>เพิ่มกรอบรูป</span> : ribbon.activeTab === "frame" && type === "edit" ?
                            <span>แก้ไข <img src={`${host.image}${ribbon.modalCreateRibbon?.data?.imagePath?.path}`} alt="" className="ml-2" style={{ maxWidth: '3em' }} /> </span> : null}
                        <IconClose className="cursor-pointer"
                            onClick={handleClose} />
                    </div>
                </ModalHeader>
                <ModalBody>
                    {((ribbon.dataRibbon?.default == 0 && ribbon.modalCreateRibbon.type === "edit") || ribbon.modalCreateRibbon.type === "create") &&
                        <React.Fragment>
                            <section>
                                <h5>รายละเอียด</h5>
                                <div className="detail-ribbon">
                                    <div className="w-50 p-2" style={{ marginTop: '1px' }}>
                                        <span className="require"> ชื่อธีม </span> <span className="text-limit">(จำกัด 20 ตัวอักษร)</span>
                                        <input
                                            type="text"
                                            className={classNames('form-control', { 'error-field': err.name?.status || ribbon.nameRibbonAlready })}
                                            name="name"
                                            onChange={(e) => onChangeInput(e)}
                                            value={ribbon.dataRibbon.name}
                                            autoComplete={'off'}
                                        />
                                        {(err?.name?.status || ribbon.nameRibbonAlready) && <span className="text-danger">{err.name?.message || ribbon.nameRibbonAlready?.message}</span>}
                                    </div>

                                    <div className="w-50 p-2">
                                        <div className="w-100">
                                            <span className="require"> Tags </span> <span className="text-limit">(จำกัด 20 ตัวอักษร)</span>
                                            <AutoCompleteInput
                                                options={ribbon.listKey}
                                                onChange={(e) => onChangeInput(e)}
                                                name={'key'}
                                                value={ribbon.dataRibbon.key}
                                                onSelect={(item) => onSelectTag(item)}
                                                err={err?.key?.status || ribbon.keyRibbonAlready?.err}
                                            />
                                            {(err?.key?.status || ribbon.keyRibbonAlready?.err) && <span className="text-danger">{err?.key?.message || ribbon.keyRibbonAlready?.message}</span>}
                                        </div>


                                        {/* <div className="w-100">
                                            <span className="require"> Tag </span> <span className="text-limit">(จำกัด 20 ตัวอักษร)</span>
                                            <AutoCompleteComponent
                                                options={ribbon.listKey}
                                                value={ribbon.dataRibbon.key}
                                                onChange={(e) => onChangeTag(e)}
                                                onSelect={(e) => onChangeInput(e)}
                                            >
                                                <input type="text"
                                                    name="key"
                                                    // onChange={(e) => onChangeTag(e)}
                                                    // value={ribbon.dataRibbon.key}
                                                    autoComplete={'off'}
                                                    style={{border: err.key ? '1px solid red' : null }}
                                                />
                                                {err?.key && <span className="text-danger">กรุณาใส่ Tag</span>}
                                            </AutoCompleteComponent>
                                        </div> */}
                                    </div>
                                </div>



                            </section>
                        </React.Fragment>
                    }


                    <section className="d-flex flex-column">
                        <h5>รูปภาพ</h5>
                        {ribbon.activeTab === "ribbon" ? <span> รองรับสัดส่วนภาพขนาดรูปแนะนำคือ 360 x 60 พิกเซล </span> : <span> รองรับสัดส่วนภาพขนาดรูปแนะนำคือ 640 x 640 พิกเซล </span>}

                        {imageShow ?
                            <div style={{ width: '140px', height: '140px' }} className="image-container">
                                <img src={host.image + imageShow.path} alt="" className="cursor-pointer" />
                                <div className="remove-image" onClick={() => editImage()} >
                                    <IconCloseCircle />
                                </div>

                            </div> :
                            <div className="">
                                <ButtonUpload
                                    width='10em'
                                    height='10em'
                                    type='image'
                                    shopDetail={{
                                        shopId: 0,
                                    }}
                                    disabled={false}
                                    fieldUpload='ribbon'
                                    limitImageUpload={1}
                                    albumDetail={{ id: ribbon.activeTab === "ribbon" ? ribbon.albumRibbon?.ribbon.id : ribbon.activeTab === "frame" ? ribbon.albumRibbon?.frame.id : null, shopId: 0 }}
                                    validateWidthHeight={{
                                        width: ribbon.activeTab === "ribbon" ? 360 : ribbon.activeTab === "frame" ? 640 : null,
                                        height: ribbon.activeTab === "ribbon" ? 60 : ribbon.activeTab === "frame" ? 640 : null,
                                        type: ribbon.activeTab === "ribbon" ? 'lower' : ribbon.activeTab === "frame" ? 'equal' : null
                                    }}
                                />
                            </div>
                        }
                        {err?.imageId && <span className="text-danger">กรุณาใส่รูป</span>}



                    </section>

                    {((ribbon.dataRibbon?.default == 0 && ribbon.modalCreateRibbon.type === "edit") || ribbon.modalCreateRibbon.type === "create") &&
                        <React.Fragment>
                            <section className="date-time-ribbon">
                                <h5>วันที่ใช้งาน</h5>


                                <Radio.Group
                                    onChange={(e) => onChangeDateLimit(e)}
                                    value={ribbon.dataRibbon.dateLimit}
                                    className='font-primary d-flex justify-content-between mt-4'
                                    style={{ width: '30%' }}
                                    name={'dateLimit'}
                                >
                                    <Radio value={true}>
                                        <span>ตั้งเวลา</span>
                                    </Radio>
                                    <Radio value={false}>
                                        <span>ไม่จำกัด</span>
                                    </Radio>

                                </Radio.Group>

                                {ribbon.dataRibbon.dateLimit &&
                                    <div className="p-0">
                                        <DateTimeLength
                                            startDate={ribbon.dataRibbon.startDate}
                                            endDate={ribbon.dataRibbon.endDate}
                                            startTime={ribbon.dataRibbon.startTime}
                                            endTime={ribbon.dataRibbon.endTime}
                                            handleChangeDate={(type, date) => handleChangeDate(type, date)}
                                            handleChangeTime={(type, time) => dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: type, value: time } }))}
                                        />
                                    </div>
                                }


                            </section>

                            <section>
                                <div className="d-flex align-items-center justify-content-between" style={{ width: '30%' }}>
                                    <h5 className="mt-1">สถานะการใช้งาน</h5>
                                    <div
                                        className='toggle-green d-flex align-items-center gap-1'
                                    >
                                        <label className='switch'>
                                            <input
                                                type='checkbox'
                                                checked={ribbon.dataRibbon.status === "show" ? true : false}
                                                onChange={() => dispatch(dispatchRibbon({ type: SET_KEY_DATA_RIBBON, payload: { key: 'status', value: ribbon.dataRibbon.status === "show" ? "hide" : "show" } }))}
                                            />
                                            <span className='slider round'></span>
                                        </label>
                                        <span>
                                            {ribbon.dataRibbon.status === "show" ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
                                        </span>
                                    </div>
                                </div>
                            </section>
                        </React.Fragment>
                    }

                    <div className="mt-4">
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-cancel" onClick={handleClose}>ยกเลิก</button>
                            <button className="btn btn-submit" onClick={() => handleSubmit()}>ตกลง</button>
                        </div>
                    </div >


                </ModalBody>
                {/* <ModalFooter>
                    <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter> */}
                <ToastNotification />
            </Modal>

        </div >
    )
}

export default ModalAddRibbon
