import { Body1, Body2, H1, H5, H6 } from 'components/FontStyle'
import ModalPreviewMassUpload from 'components/ModalPreviewMassUpload';
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux";
import '../styles/_massUploadExcel.scss';
import TemplateImportTracking from 'file/template-import-tracking.xlsx'
import { ReactComponent as IconDownload } from 'images/icons/IconDownload.svg';

import UploadXlsx from 'components/UploadXlsx';
import ModalAlert from 'components/modalAlert/ModalAlert';
import {
    dispatchModalAlert,
    OPEN_MODAL_ALERT,
} from 'redux/actions/modalAlert';
import { dispatchApp, SET_LOADING } from 'redux/actions/app';
import useSelection from 'antd/lib/table/hooks/useSelection';
import massUploadProvider from 'provider/MassUploadProvider'
import {
    dispatchShop,
    GET_ALL_SHOPS,
} from 'redux/actions/shop';
import { displayDateThShortYear } from 'helper/timeUtil';
import { UncontrolledTooltip } from 'reactstrap';
import { ReactComponent as IconHint } from 'images/icons/hint.svg'
import host from 'config/host';
import axiosNew from 'config/axios';
import Cookies from 'js-cookie';
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const MassUploadTracking = (props) => {

    const { handlePreviewProductXlsx,
        isOpenModalPreviewMassUpload,
        setIsOpenModalPreviewMassUpload,
        productXlsx,
        downloadTemplateExcel,
        shopList,
        isLoading,
        page,
        skip,
        hasMore,
        listDownload,
        setPage,
        setSkip,
        fileXlsx,
        shopId,
        setShopId,
        setStatusUploaded,
        statusUploaded,
        clearFileExcel,
        getListDownload,
        lastDownloadElement,
        showErrorUpload,
        handleDownloadExcel,
        setListDownload,
        errorUpload,
        handleSubmitAlert,
        submitAction
    } = useMassExcelUpload(props);



    return (
        <div id='mass-upload-excel'>

            <H5>เพิ่มสินค้าแบบชุด</H5>
            <div className='d-flex flex-row justify-content-between align-items-center bg-white'>
                <label className='d-flex flex-column'>
                    <H6>ดาวน์โหลดแบบฟอร์ม</H6>
                    <Body1>กรุณาดาวน์โหลดแบบฟอร์ม เพื่อกรอกข้อมูลที่จำเป็นสำหรับอัปโหลดหมายเลขพัสดุของคุณ</Body1>
                </label>
                <a id="my_download" href={TemplateImportTracking} download="upload_tracking_template-v1" className='d-none' />
                <button className='btn-cancel' onClick={downloadTemplateExcel} style={{ height: '3em' }}> <IconDownload className='icon-download' /> ดาวน์โหลดแบบฟอร์ม</button>
                {/* <a href={resume} download="YourName resume.pdf"> Download CV </a> */}
            </div>

            <div className='bg-white d-flex flex-column'>
                <H6>อัปโหลด</H6>
                <Body1>อัปโหลดแบบฟอร์มที่แก้ไขเสร็จแล้ว เมื่ออัปโหลดแล้วรายการสั่งซื้อนั้นจะถูกย้ายไปยังสถานะ อยู่ระหว่างขนส่ง</Body1>
                <UploadXlsx onFileChange={(file, data) => handlePreviewProductXlsx(file, data)} />
                {/* /////////////////////////////////// component upload ////////////////////////////*/}
            </div>

            <div className='bg-white d-flex flex-column'>
                <H6>ประวัติการอัปโหลด</H6>
                <div className='d-flex flex-row justify-content-between'>
                    <Body1>แสดงรายการอัปโหลด 30 วันล่าสุด สูงสุดไม่เกิน 100 ครั้ง</Body1>
                    <Body2 className='color-primary cursor-pointer' onClick={() => props.history.push({
                        pathname: '/manage/order/all/onTheWay',
                        state: {
                            activeTab: 'hide'
                        }
                    })}>{`ตรวจสอบสถานะสินค้า >`}</Body2>
                </div>

                <div className='table-mass-upload-header width-table-mass-upload'>
                    <div>ลำดับ</div>
                    <div>วันที่อัปโหลด</div>
                    <div>ชื่อไฟล์</div>
                    <div>ชื่อร้านค้า</div>
                    <div>จำนวนคำสั่งซื้อ</div>
                    <div>สถานะ</div>
                    <div></div>
                </div>

                <div id='infinite-list'>
                    {listDownload.map((item, index) => {
                        return (
                            <div className="table-mass-upload-content width-table-mass-upload" key={index} ref={lastDownloadElement}>
                                <div>{index + 1}</div>
                                <div>{displayDateThShortYear(item?.createdAt)} {displayDateThShortYear(item?.createdAt, 'HH:mm')}</div>
                                <div>{item?.originalName}</div>
                                <div>{item?.shop?.name}</div>
                                <div>{item?.totalItem}</div>
                                <div className=''>{showErrorUpload(errorUpload[item?.issueNote?.message] || item?.issueNote?.message, item?.issueNote?.row, item.id)}</div>
                                <div>{item.status === 'success' && <span className='cursor-pointer color-upload-status-success' style={{ textDecoration: 'underline' }} onClick={() => { handleDownloadExcel(item.shop.id, item.fileName, item.originalName) }}>Download</span>}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {/* <H1>...isLoading</H1> */}
            {isLoading && <H1>...isLoading</H1>}


            <ModalPreviewMassUpload isOpen={isOpenModalPreviewMassUpload}
                data={productXlsx}
                shopList={shopList}
                toggle={() => setIsOpenModalPreviewMassUpload(!isOpenModalPreviewMassUpload)}
                fileXlsx={fileXlsx}
                shopId={shopId}
                setShopId={setShopId}
                setStatusUploaded={(status) => setStatusUploaded(status)}
                statusUploaded={statusUploaded}
                clearFileExcel={clearFileExcel}
                isFromShop={props?.location?.state?.isFromShop}
                getListDownload={getListDownload}
                setListDownload={setListDownload}
                errorUpload={errorUpload}
                setPage={setPage}
                feature={'tracking'}
            />
            <ModalAlert onSubmit={() => handleSubmitAlert(submitAction)} />
        </div>
    )
}

const useMassExcelUpload = props => {

    const errorUpload = {
        'ERROR E-TRACKING API': 'การอัพโหลดไฟล์ล้มเหลว',
        TRACKING_DOES_NOT_EXISTS: 'เลข tracking ไม่ถูกต้อง',
        FILE_NOT_FOUND: 'ไม่พบไฟล์',
        SHOP_UPLOADING_FILE: 'ร้านค้ากำลังอัพโหลดข้อมูลอยู่',
        DELIVERY_DOES_NOT_EXIST: 'พบปัญหาการจัดส่งของออเดอร์',
        DATA_NOT_FOUND: 'ไม่พบข้อมูล',
        REQUIRED_FIELD: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        ORDER_NO_ALREADY: 'พบออเดอร์ซ้ำกันในไฟล์อัพโหลด กรุณาตรวจสอบไฟล์อัพโหลด',
        ORDER_NOT_EXISTS: 'ไม่พบออเดอร์ในระบบ กรุณาตรวจสอบไฟล์อัพโหลด',
        ORDER_STATUS_NOT_MATCH: 'ไม่สามารถอัพโหลดไฟล์ได้ เนื่องจากมีหมายคำสั่งซื้อ/หมายเลขพัสดุ ที่ไม่สามารถใช้งานได้',
        FORMAT_TRACKING_NO_ERROR: 'หมายเลขพัสดุ ไม่สามารถใช้งานได้'
}


const currentShop = useSelector(state => state.shop.currentShop)

const [isOpenModalPreviewMassUpload, setIsOpenModalPreviewMassUpload] = useState(false);
const [productXlsx, setProductXlsx] = useState([]);
const [shopList, setShopList] = useState([]);
const [page, setPage] = useState(1)
const [skip, setSkip] = useState(10)
const [isLoading, setIsLoading] = useState(false)
const [hasMore, setHasMore] = useState(false)
const [shopId, setShopId] = useState(null)
const [fileXlsx, setFileXlsx] = useState(null)
const [statusUploaded, setStatusUploaded] = useState(null) /* cancel, fail, uploaded, validateFail */
const [listDownload, setListDownload] = useState([])
const [submitAction, setSubmitAction] = useState({ action: '', payload: {} }); /* 'Error': show modal xlsx with error */

const rowLimit = 100;
const dispatch = useDispatch();
const stateShop = useSelector(state => state.shop);
const { allShops } = stateShop;

const observer = useRef();
const lastDownloadElement = useCallback(node => {
    if (isLoading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting
            && hasMore
        ) {
            setPage(page => page + 1)
        }
    })
    if (node) observer.current.observe(node)
}, [isLoading, hasMore])

useEffect(() => {
    getAllShops();
    // getListDownload();
    return () => {
        // setHasMore(false)
    }
}, [])

useEffect(() => {

    if (allShops && Array.isArray(allShops)) {
        const _shopList = allShops.map(shop => ({ label: shop.name, value: shop }));
        setShopList(_shopList);
        if (props.location?.state?.isFromShop) return
        setShopId(_shopList[0]?.value?.id)
    }
}, [allShops]);

// useEffect(() => {
//     // setSkip(prevSkip => prevSkip + 10)
//     // setPage(prevPage => prevPage + 1)
//     setPage(page + 1)
//     console.log('page', page)
//     // getListDownload()
//     setHasMore(true)
// }, [listDownload])

useEffect(() => {
    getListDownload()
    return () => {
    }
}, [page])

useEffect(() => {
    (async () => {
        switch (statusUploaded) {
            case 'uploaded':
            case 'fail':
                await setPage(1)
                await setListDownload([])
                if (page === 1) {
                    await getListDownload()
                }
                break;
            case 'cancel':
                // await setPage(1)
                // await setListDownload([])
                // await getListDownload()
                break;
            default:
                break;
        }
    })()
    return () => {
    }
}, [statusUploaded])





const clearFileExcel = () => {
    document.getElementById('file').value = ''
}


/* Convert data to spread sheet format */
const handlePreviewProductXlsx = async (file, data) => {
    try {
        
        setLoading(true);
        setStatusUploaded(null);

        /* validate file type error */
        if (data.error) throw data.error;

        const dataXlxs = provideDataRow(data);

        /* validate file xlsx */
        const validateXlsxFile = new ValidateXlsxFile(dataXlxs);

        const validateAll = await validateXlsxFile.validateAll();
        setLoading(false);

        if (typeof validateAll === 'object')
            throw validateAll;

        openModalPreview(dataXlxs.data, file);
    } catch (error) {
        setStatusUploaded('validateFail');
        setLoading(false);
        if (error.name === errorStatusXlsx.InvalidNumberColumn.name) {
            openAlertModal(errorStatusXlsx.InvalidNumberColumn.message);
        }
        if (error.name === errorStatusXlsx.InvalidFileType.name) {
            openAlertModal(errorStatusXlsx.InvalidFileType.message);
        }
        if (error.name === errorStatusXlsx.EmptyData.name) {
            openAlertModal(errorStatusXlsx.EmptyData.message);
        }
        if (error.name === errorStatusXlsx.RowOverLimit.name) {
            openAlertModal(errorStatusXlsx.RowOverLimit.message);
        }
        if (error.name === errorStatusXlsx.RowError.name) {
            openAlertModal(errorStatusXlsx.RowError.message);
            setSubmitAction({ action: 'Error', payload: { rows: error.rows } })
        }

    }
}


/* handle action after submit button on alert modal */
const handleSubmitAlert = ({ action, payload }) => {
    if (action === 'Error') {
        openModalPreview(payload.rows);
        setSubmitAction({ action: '', payload: {} });
    }
}

const openModalPreview = (rows, file) => {
    setFileXlsx(file)
    setProductXlsx(rows);
    setIsOpenModalPreviewMassUpload(true);
}

/* Provide data form xlxs original file */
const provideDataRow = (data) => {

        /* validate header column */
        if(data[2].length !== 2)
            throw ( errorStatusXlsx.InvalidNumberColumn )

        /* remove empty rows and remove topic row */
        let removeEmptyRow = data.reduce((rows, row, index) => {
            if (row.length > 0 &&
                [0,1,3].indexOf(index) === -1) {

            if (row.length < 2) {
                for (let i = row.length - 1; i < 1; i++) {
                    row.push('');
                }
            }

            rows = [...rows, row];
        }

        return rows;
    }, []);

    /* validate row data is between 1 - 100 rows */
    if ((removeEmptyRow.length - 1) === 0) {
        throw ({
            name: errorStatusXlsx.EmptyData.name,
            rowCount: removeEmptyRow.length - 1
        })
    }
    /* validate row data is over 100 rows */
    if ((removeEmptyRow.length - 1) > rowLimit) {
        throw ({
            name: errorStatusXlsx.RowOverLimit.name,
            rowCount: removeEmptyRow.length - 1
        })
    }

    /* convert data to react spreadsheet format */
    const newDataFormated = removeEmptyRow.map((row, rowIndex) => {
        return convertRowToSpreadSheet(row, rowIndex);
    });

    return { data: newDataFormated, rowCount: removeEmptyRow.length - 1 };
}

/* Convert data to spread sheet format */
const convertRowToSpreadSheet = (row, rowIndex) => {
    let newRow = [];
    try {
        for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {

            let content = row[cellIndex];
            let classNames = '';

            if (rowIndex === 0) classNames = 'table-content text-center bg-header'; /* render header style */
            else classNames = 'table-content content text-center'; /* render body style */



            const columnImage = [8, 14, 15, 16, 17, 18, 19, 20, 21, 22]; /* image column */

            /* Insert picture if data is image url */
            if (typeof content === 'string' && rowIndex > 0) {
                if (columnImage.indexOf(cellIndex) > -1) {
                    content = <img src={content} />;
                }
            }
            if (content === '' || content === undefined) {
                /* empty element */
                newRow = [...newRow, { value: '', className: classNames }];
            } else {
                newRow = [...newRow, { value: content, className: classNames }];
            }
        }

        return newRow;
    } catch (error) {
        console.log(error)
    }
}

useEffect(() => {
    if (props.location?.state?.isFromShop) {
        // const findShop = shopList.find((shop) => shop.value.id === currentShop.id)
        setShopId(currentShop?.id)
    }
    return () => {
        // setShopId('')
    }
}, [props.location?.state?.isFromShop])


let isFirstTime = true
useEffect(() => {
    if (!isFirstTime) {
        isFirstTime = false
        setIsOpenModalPreviewMassUpload(false)
        setFileXlsx(null)
        setProductXlsx([])
        setStatusUploaded(null)
        clearFileExcel()
    }
    return () => {
        // setStatusUploaded(null)
    }
}, [statusUploaded === 'uploaded' || !isOpenModalPreviewMassUpload])



const openAlertModal = (message) => {
    dispatch(
        dispatchModalAlert({
            type: OPEN_MODAL_ALERT,
            payload: {
                isOpen: true,
                message: message,
                type: 'alert',
            },
        }),
    )
}

const setLoading = (status) => {
    dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: status } }))
}

const getAllShops = () => {
    dispatch(
        dispatchShop({
            type: GET_ALL_SHOPS,
            payload: {},
        }),
    )
}

const downloadTemplateExcel = () => {
    document.getElementById("my_download").click()
}

const getListDownload = async () => {
    const res = await massUploadProvider.getListUploadExcelTracking({ page, skip })
    if (res) {
        if (res.length === 0) {
            setHasMore(false)
        } else {
            setHasMore(true)
            setListDownload(prevDownload => [...prevDownload, ...res])
        }
    }
}

const showErrorUpload = (msg, row, id) => {

    if (msg || row) {
        return (
            // <div className='p-0 icon-hint'>
            //     <div className='p-0'>
            //         <p><span className='color-red icon-hint'>อัพโหลดไม่สำเร็จ</span> <span className='cursor-pointer' style={{ color: 'red' }} href="#" id={`showError${id}`}><IconHint /></span></p>
            //         <UncontrolledTooltip placement="top" target={`showError${id}`}>
            //             <span>อัพโหลดไม่สำเร็จเนื่องจาก</span> <br />
            //             {row && <span>สินค้าในแถว : {row}</span>}<br />
            //             <span>{msg}</span>
            //         </UncontrolledTooltip>
            //     </div>
            // </div>
            // <div className='tootip'>
            //     <OverlayTrigger placement="top" overlay={renderTooltip}>
            //         <span>sad</span>
            //     </OverlayTrigger>
            // </div>
            <div className='d-flex flex-row align-items-center justify-content-center p-0'>
                <span className='color-red icon-hint mr-1'>อัพโหลดไม่สำเร็จ</span>
                <div className='position-relative tooltip-mass-upload p-0'>
                    {/* <p><p className='color-red icon-hint'>อัพโหลดไม่สำเร็จ</p> <p className='cursor-pointer' style={{ color: 'red' }} href="#" id={`showError${id}`}><IconHint /></p></p> */}
                    <span class="tooltiptext">
                        <span>อัพโหลดไม่สำเร็จเนื่องจาก</span> <br />
                        {row && <span>สินค้าในแถว : {row}<br /></span>}
                        <span>{msg}</span>
                    </span>
                    <label htmlFor="" className='icon-hint'>
                        <div className='p-0'><IconHint className='cursor-pointer icon-hint' /></div>
                    </label>
                </div>
            </div>


        )
    } else {
        return (
            <div className='color-upload-status-success p-0'>อัพโหลดสำเร็จ</div>
        )
    }
}


const handleDownloadExcel = async (shopId, fileName, originalName) => {
    // await massUploadProvider.getDownloadExcel(shopId,fileName)

    axiosNew.get(`${host.api}/delivery-attachments/download?shopId=${shopId}&fileName=${fileName}`, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${Cookies.get('token')}`,

        },
        responseType: 'arraybuffer',
    }).then((response) => {
        const temp = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = temp;
        link.setAttribute('download', `${originalName}`); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}


return {
    handlePreviewProductXlsx,
    isOpenModalPreviewMassUpload,
    setIsOpenModalPreviewMassUpload,
    productXlsx,
    downloadTemplateExcel,
    shopList,
    page,
    skip,
    isLoading,
    hasMore,
    listDownload,
    setPage,
    setSkip,
    fileXlsx,
    shopId,
    setShopId,
    setStatusUploaded,
    statusUploaded,
    clearFileExcel,
    getListDownload,
    lastDownloadElement,
    showErrorUpload,
    handleDownloadExcel,
    errorUpload,
    handleSubmitAlert,
    submitAction
}
}

export default MassUploadTracking

const errorStatusXlsx =       { InvalidNumberColumn: {name:'InvalidNumberOfColumn',message:'จำนวนคอลลั่มไม่ถูกต้อง'},
                                EmptyData: {name:'EmptyData',message:'ไม่มีข้อมูล'},
                                InvalidNumber: {name:'InValidNumber',message:'รูปแบบตัวเลขไม่ถูกต้อง'},
                                InvalidNumberString: {name:'InvalidNumberString',message:'รูปแบบของหมายเลขคำสั่งซื้อไม่ถูกต้อง'},
                                InvalidNumberAndLetter: {name:'InvaLidNumberAndLetter',message:'รูปแบบของหมายเลขพัสดุไม่ถูกต้อง'},
                                RowError: {name:'RowError',message:'ข้อมูลไม่ถูกต้อง'},
                                InvalidFileType: {name: 'InvalidFileType', message: 'ชนิดของไฟล์ไม่ถูกต้อง'}, 
                                EmptyData: {name: 'EmptyData', message: 'ไม่มีข้อมูล'},
                                RowOverLimit: {name: 'RowOverLimit', message: 'ไม่สามารถอัปโหลดไฟล์ได้เนื่องจากไฟล์มีสินค้าเกิน 100 รายการ'},
                                LengthTextOverLimit: {name: 'LengthTextOverLimit', message: 'ความยาวตัวอักษรเกินกว่าที่กำหนด'},
                                DuplicateColumn1: {name: 'DuplicateColumn1', message: 'หมายเลขคำสั่งซื้อซ้ำกัน'},
                                DuplicateColumn2: {name: 'DuplicateColumn2', message: 'หมายเลขพัสดุซ้ำกัน'},
                            
                              }
class ValidateXlsxFile {

    constructor(fileXlsx) {
        this.fileXlsx = fileXlsx;
        this.columnName = ['หมายเลขคำสั่งซื้อ', 'หมายเลขพัสดุ']
    }

     /* get duplicate  */
     getDuplicateValue = (columnIndex) => {

        /* get duplicate value in this file
           slice(1) for skip header
         */
        const duplicateValue = this.fileXlsx?.data?.slice(1).reduce((total, row) => {

            const value = row[columnIndex].value;
            const blankSpaceRemove = String(value).replace(/\s/g, '');
            if(blankSpaceRemove !== '') {
                if(total[value]) 
                    total[value] = total[value]+1;
                else {
                    total[value] = 1;
                }
            }

            return total;

        }, {});

        return Object.keys(duplicateValue).filter(key => duplicateValue[key] > 1);
     }

    validateAll = async () => {
        let rowErrors = {};
        let rows = [];
        const duplicateColumn1 = this.getDuplicateValue(0);
        const duplicateColumn2 = this.getDuplicateValue(1);

        console.log('[duplicateColumn1]:',duplicateColumn1);
        console.log('[duplicateColumn2]:',duplicateColumn2);

        /* validate number of column */
        if (!this.validateNumberOfColumn()) {
            return errorStatusXlsx.InvalidNumberColumn;
        }

        /* validate data in each row. */
        for await (const [rowIndex, row] of this.fileXlsx.data.entries()) {

            let rowError = {};
            let newRow = [];

            for await (const [columnIndex, column] of row.entries()) {

                let newColumn = { ...column }

                /* 0:หมวดหมู่สินค้า, 
                   1:แบรนด์, 
                   2:ชื่อสินค้า, 
                   3:รายละเอียดสินค้า, 
                   4:เลขอ้างอิงตัวเลือกสินค้า (Seller SKU), 
                   6:ชื่อตัวเลือก 1, 
                   7:ตัวเลือก 1, 
                   11:ราคาปกติ, 
                   12:ราคาที่ประกาศขาย, 
                   13:สต็อก, 14:ภาพสินค้า, 
                   23:น้ำหนัก (กก), 
                   27:ระยะเวลาเตรียมการจัดส่ง (ชม.), 
                   28:Standard Delivery */
                const columnRequire = [0, 1];
                const columnStringNumber = [0] // รหัสบาร์โค๊ด
                const columnSpecialLetter = [1] // เลขอ้างอิงตัวเลือกสินค้า

                let value = null;
                /* assign value */
                if (column) {
                    if (typeof column.value !== 'object') {
                        value = column.value; /* column value */
                    } else {
                        if (column.value && column?.value?.props)
                            value = column.value?.props?.src; /* tag image */
                    }
                }

                if (rowIndex > 0) {

                    /* validate empty field */
                    if (columnRequire.indexOf(columnIndex) > -1) {
                        if (!value || (String(value).trim().length === 0)) {
                            rowError[`${columnIndex}`] = errorStatusXlsx.EmptyData;
                            newColumn.className = `${column.className} error-element`;
                            newColumn.value = <span className='error-element-message'>{'ข้อมูลจำเป็น'}</span>;
                        } else {
                            /* validate element is not number and string */
                            if (columnStringNumber.indexOf(columnIndex) > -1) {
                                if (value && !this.validateFieldIsNumberStr(String(value))) {
                                    rowError[`${columnIndex}`] = errorStatusXlsx.InvalidNumberString;
                                    newColumn.value = <div>
                                        <p>{column.value}</p>
                                        <span className='error-element-message'>
                                            {errorStatusXlsx.InvalidNumberString.message}
                                        </span>
                                    </div>;
                                    newColumn.className = `${column.className} error-element`;
                                }
                            }
        
                            /* validate element is not number and letter */
                            if (columnSpecialLetter.indexOf(columnIndex) > -1) {
                                if (value && !this.validateFieldIsNumberAndLetter(String(value))) {
                                    rowError[`${columnIndex}`] = errorStatusXlsx.InvalidNumberAndLetter;
                                    newColumn.value = <div>
                                        <p>{column.value}</p>
                                        <span className='error-element-message'>
                                            {errorStatusXlsx.InvalidNumberAndLetter.message}
                                        </span>
                                    </div>;
                                    newColumn.className = `${column.className} error-element`;
                                }
                            }
        
                            /* validate duplicate order number */
                            if(columnStringNumber.indexOf(columnIndex) > -1){
                                if(duplicateColumn1.indexOf(String(value)) > -1) {
                                          rowError[`${columnIndex}`] =  errorStatusXlsx.DuplicateColumn1;
                                          newColumn.value = <div>
                                                                  <p>{column.value}</p>
                                                                  <span className='error-element-message'>
                                                                      {errorStatusXlsx.DuplicateColumn1.message}
                                                                  </span>
                                                          </div>;
                                          newColumn.className = `${column.className} error-element`;
                                }
                            }
        
                            /* validate duplicate tracking number */
                            if(columnSpecialLetter.indexOf(columnIndex) > -1){
                                if(duplicateColumn2.indexOf(column.value) > -1) {
                                          rowError[`${columnIndex}`] =  errorStatusXlsx.DuplicateColumn2;
                                          newColumn.value = <div>
                                                                  <p>{column.value}</p>
                                                                  <span className='error-element-message'>
                                                                      {errorStatusXlsx.DuplicateColumn2.message}
                                                                  </span>
                                                          </div>;
                                          newColumn.className = `${column.className} error-element`;
                                }
                            }
                        }
                    }


                }

                newRow = [...newRow, newColumn];
            };

            if (Object.keys(rowError).length > 0)
                rowErrors[`${rowIndex}`] = rowError;

            rows = [...rows, newRow];

        };

        /* filter column index error that unique */
        const columnError = Object.keys(rowErrors).reduce((arr, row) => {

            Object.keys(rowErrors[row]).forEach(element => {
                arr[element] = true;
            });

            return arr;
        }, {});

        /* apply column error style */
        rows[0] = rows[0].map((col, columnIndex) => {
            if (Object.keys(columnError).indexOf(`${columnIndex}`) > -1) {
                col.className = col.className + ' error-column'
            }
            return col;
        })

        return Object.keys(rowErrors).length > 0 ?
            {
                name: errorStatusXlsx.RowError.name,
                message: errorStatusXlsx.RowError.value,
                rowErrors,
                rows
            } : true;
    }

    /* check filed empty require field */
    /* return index of empty in array */
    validateFieldEmpty = (row) => {
        try {
            let emptyElements = []
            for (let i = 0; i < row.length; i++) {
                if (row[i] === '' || row[i] === undefined) {
                    emptyElements = [...emptyElements, i]
                }
            }
            return emptyElements
        } catch (error) {
            console.log(error)
        }
    }

    /* check field is number */
    validateFieldIsNumber = (value) =>
        (typeof value === 'number' && value > -1)

    /* check field is number 0-9 string */
    validateFieldIsNumberStr = (value) =>
        (typeof value === 'string' && /^[0-9]+$/.test(value))

    /* check field is number and letter 0-9 and a-zA-z */
    validateFieldIsNumberAndLetter = (value) =>
        (typeof value === 'string' && /^[a-zA-Z0-9\s]+$/.test(value))

    /* check field is number and letter 0-9 and a-zA-z and special character*/
    validateFieldIsNumberAndLetterAndSpecial = (value) =>
        (typeof value === 'string' && /^[a-zA-Z0-9-_$&+,:;=?@#|'<>.^*()%!-]+$/.test(value))

    /* check number of column */
    validateNumberOfColumn = () => {
        // console.log('[this.fileXlsx.data]:', this.fileXlsx.data);
        if (Array.isArray(this.fileXlsx.data[0]) &&
            this.fileXlsx.data.length > 1) {
            return this.fileXlsx.data[0].length === this.columnName.length
        }
        return false;
    }




}
