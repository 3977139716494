/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */





import jsCookie from 'js-cookie';
import numeral from 'numeral';
import { SUPER_ADMIN, ADMIN, OWNER, ACCOUNTANT } from '../layout/Sidebar/Role';



const setUserFromLocal = (userData) => {
  try {
    window.localStorage.setItem('user', JSON.stringify(userData));
    //jsCookie.set('user', JSON.stringify(userData) ,{ expires: 7 })
  } catch (ex) {

  }
}

const delay = (delayInms) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}

const clearUserFromLocal = (isReload = true) => {
  try {
    //window.localStorage.clear()
    jsCookie.remove('userInfo');
    jsCookie.remove('socialProfile');
    // 

    setTimeout(() => {
      if (isReload) {
        window.location.reload()
      }
    }, 1000)

  } catch (ex) {

  }
  //window.location.href = "/login"
}

const clearSocialProfile = (isReload = true) => {
  try {

    jsCookie.remove('socialProfile');
    // 

    setTimeout(() => {
      if (isReload) {
        window.location.reload()
      }
    }, 1000)

  } catch (ex) {

  }
  //window.location.href = "/login"
}

const getUserFromLocal = () => {

  try {
    const userData = JSON.parse(jsCookie.get('userInfo')) || null;
    // const userData = JSON.parse(   window.localStorage.getItem('user')) || null;

    return userData;
  } catch (e) {
    return null;
  }
}

const isLogin = () => {
  let userData = getUserFromLocal()
  if (userData !== null) {
    return true;
  } else {
    return false;
  }
}

const getLocationString = (lat, lng) => {
  if (!lat || !lng)
    return ""
  else {
    return lat + "," + lng
  }
}



const readFile = (file) => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}



function isDataURL(s) {
  isDataURL.regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  return !!s.match(isDataURL.regex);
}

const isNumber = (input) => {
  const exp = /^-?\d+\.?\d*$/
  if (input) {
    const str = String(input)
    return str.match(exp) !== null
  } else {
    return false
  }
}

const isMobileOrTel = (input) => {
  const exp = /^([0-9]){9,10}$/
  // /^([0-9_-]){9,10}$/
  if (input) {
    const str = String(input)
    return str.match(exp) !== null
  } else {
    return false
  }
}

const isBase64 = (str) => {
  try {
    return btoa(atob(str)) == str
  } catch (err) {
    return false;
  }
}


const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

const isEmptyOrSpaces = (input) => {
  if (!input)
    return true

  return input === null || input.match(/^ *$/) !== null;
}


const login = (username, password) => {

  let role = ""
  let status = false
  let isAdmin = false
  if (isEmptyOrSpaces(username) || isEmptyOrSpaces(password)) {
    status = false
  }

  if (username == "admin" && password == "password") {
    status = true
    role = "admin"
    isAdmin = true
  }
  else if (username == "user" && password == "password") {
    status = true
    role = "user"
  } else {
    status = false
  }

  return {
    role,
    status,
    isAdmin
  }

}

const isAdmin = () => {
  let userData = getUserFromLocal()
  if (userData !== null) {
    return userData.isAdmin;
  } else {
    return false;
  }
}

const disableGoBack = () => {
  try {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
      window.history.pushState(null, null, document.URL);
    });
  } catch (ex) {

  }


}


const handleError = () => {


  if (process.env.NODE_ENV == "production") {
    window.location.href = "/errorNotFound"
  }

}

const setLocalStorage = (param = { key: "", value: "" }) => {

  return new Promise(function (resolve, reject) {
    try {
      const data = window.localStorage.setItem(param.key, JSON.stringify(param.value))
      resolve(data)
    } catch (ex) {
      reject(ex)
    }
  })
}

const getLocalStorage = (param = { key: "" }) => {
  return Promise.resolve().then(function () {
    const cart = window.localStorage.getItem(param.key)
    try {
      return JSON.parse(cart)
    } catch (ex) {
      return null
    }

  })

}

const detectInternalApp = () => {
  var UA = navigator.userAgent || navigator.vendor || window.opera;


  var isLINE = function (UA) {
    return (UA.indexOf("Line/") > -1);
  }

  // var isLINEBrowser = function (UA) {
  //     return (UA.indexOf("Line/") > -1 && UA.indexOf("/IAB") > -1);
  // }


  var isFacebook = function (UA) {
    return (UA.indexOf("FBAN") > -1) || (UA.indexOf("FBAV/") > -1);
  }



  if (isLINE(UA)) {
    return 'LINE';
  }
  if (isFacebook(UA)) {
    return 'Facebook'
  }

  return false;
}


function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const formatNumber = (num) => {
  return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
}

const decimal2point = (number) => {
  return Number.parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const decimalToInteger = (number) => {
  const numberStr = numeral(number).format('0');
  return Number(numberStr);
}


export function* cartesian(head, ...tail) {
  const remainder = tail.length > 0 ? cartesian(...tail) : [[]];
  for (let r of remainder) for (let h of head) yield [h, ...r];
}

const isServer = () => {
  if (typeof window != 'undefined') {
    return false
  } else {
    return true
  }
}


const checkPermission = (module, action, permission) => {
  const getUser = jsCookie.get('user')

  if (IsJsonString(getUser)) return JSON.parse(getUser).permission && JSON.parse(getUser).permission.some((e) => e.module == module && e.action == action && e.permission == permission)
}

// const checkDuplicateArray = (array,keyName) => {
//   return new Set(array.map(item => item[keyName])).size !== array.length
// }

const getUserRole = () => {
  try {
    const user = jsCookie.get('user')
    const userInfo = IsJsonString(user) ? JSON.parse(user != undefined ? user : null) : {};

    if (userInfo.shopId) return OWNER;
    if (userInfo.role) {
      const { name } = userInfo.role;
      if (name === "admin") return ADMIN;
      if (name === "superadmin") return SUPER_ADMIN;
      if (name === "accountant") return ACCOUNTANT;
    }

    return OWNER;

  } catch (error) {

  }
}

const checkDuplicateArr = (arr, value) => {

  return arr.some(item => item == value)

}

const isEmpty = (val) => {
  if (val === 0) return false;
  return (!val || val === undefined || val == null || val.length <= 0) ? true : false;
}

const textIsEmpty = (text) => {
  if (typeof text !== 'string') return
  if (text.trim().length === 0) return true
  return false
}

const objectIsEmpty = (obj) => {
  return !!obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

const checkZipCode = (input) => {
  const exp = /^([0-9]){5}$/
  // /^([0-9_-]){9,10}$/
  if (input) {
    const str = String(input)
    return str.match(exp) !== null
  } else {
    return false
  }
}

const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key]; var y = b[key];
    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  });
}

const isSpecialCharacters = (text) => {
  const exp = /^[^ !"`'#%&,:;<>=@{}~_\$\(\)\*\+\/\\\?\[\]\^\|]+$/
  return exp.test(text)
}

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

/* scrollToDiv('#question-header', 0); */
function scrollToDiv(selector, yOffset = 0) {
  const el = document.querySelector(selector);
  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: y, behavior: 'smooth' });
}

const convert2obj = (skuJoin) => {
  const data = skuJoin.reduce(function (r, a) {
    a.productId = (typeof a.productId == 'string') ? a.productId.replace(/\s/g, "") : a.productId // ลบช่องว่าง ของ id
    a.skuId = (typeof a.skuId == 'string') ? a.skuId.replace(/\s/g, "") : a.skuId // ลบช่องว่าง ของ sku
    if (r[` ${a.productId}`] == undefined) r[` ${a.productId}`] = ({ [` ${a.skuId}`]: true } || {}); // เช็ค obj ว่ามี id ของสินค้ายังถ้าไม่ ก็เพิ่มid obj นั้น **เพิ่มช่องว่าง id
    r[` ${a.productId}`] = Object.assign(r[` ${a.productId}`], { [` ${a.skuId}`]: true })// marge obj id ของสินค้า obj นั้น **เพิ่มช่องว่าง id
    return r;
  }, {});
  return data
}

// { productId: {skuId: {}, skuId: {} }}
const convert2array = (data) => {
  const skus = []
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const sku = data[key]
      const productId = parseInt(key) // เปลี่ยน type(string) to int
      for (const key in sku) {
        skus.push({ productId, skuId: parseInt(key) })
      }
    }
  }
  // { productId: ***, skuId: ***}
  return skus
}


const convert2objPromocode = (data) => {
  const { shops, count } = data
  const objShops = shops.reduce(function (c, s) {
    s.id = (typeof s.id == 'string') ? s.id.replace(/\s/g, "") : s.id // ลบช่องว่าง ของ id
    if (c[` ${s.id}`] == undefined) c[` ${s.id}`] = ({ name: s.name, logoPath: s.logoPath, allProduct: (s.skuJoin.length == 0 ? true : false), count: s.countProductJoin, sku: s.countSkuJoin } || {});
    const data = s.skuJoin.reduce(function (r, a) {
      a.productId = (typeof a.productId == 'string') ? a.productId.replace(/\s/g, "") : a.productId // ลบช่องว่าง ของ id
      a.skuId = (typeof a.skuId == 'string') ? a.skuId.replace(/\s/g, "") : a.skuId // ลบช่องว่าง ของ sku
      if (r[` ${a.productId}`] == undefined) r[` ${a.productId}`] = ({ [` ${a.skuId}`]: true } || {}); // เช็ค obj ว่ามี id ของสินค้ายังถ้าไม่ ก็เพิ่มid obj นั้น **เพิ่มช่องว่าง id
      r[` ${a.productId}`] = Object.assign(r[` ${a.productId}`], { [` ${a.skuId}`]: true })// marge obj id ของสินค้า obj นั้น **เพิ่มช่องว่าง id
      return r;
    }, {});
    c[` ${s.id}`].product = data
    return c
  }, {})

  return { shops: objShops, count: count }

}

function getTopViewColor(index) {
  const topViewColors = [
    "#B2D234", "#ED1072", "#6956E5", "#008F46", "#FB896B",
    "#6B6B6B", "#998349", "#FFB400", "#6E92B8", "#AB3E97"
  ];

  if (index >= 0 && index < topViewColors.length) {
    return topViewColors[index];
  } else {
    return "transparent";
  }
}

function deepCopyArray(arr) {
  const copy = [];

  arr.forEach((element) => {
    if (Array.isArray(element)) {
      copy.push(deepCopyArray(element));
    } else if (typeof element === 'object' && element !== null) {
      copy.push(deepCopyObject(element));
    } else {
      copy.push(element);
    }
  });

  return copy;
}

function deepCopyObject(obj) {
  const copy = {};

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      copy[key] = deepCopyArray(value);
    } else if (typeof value === 'object' && value !== null) {
      copy[key] = deepCopyObject(value);
    } else {
      copy[key] = value;
    }
  }

  return copy;
}

const diffObjectsInArray = (array1, array2) => {
  return array1?.filter(obj1 => {
    return !array2?.some(obj2 => {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    });
  });
};

function getDifferenceArray(arr1, arr2) {
  return arr1.filter(item => !arr2.includes(item));
}

function addArrayIfNotExists(mainArray, subArray) {
  const isExists = mainArray.some(existingArray => {
    return arraysAreEqual(existingArray, subArray);
  });

  if (!isExists) {
    mainArray.push(subArray);
  }
}

function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const frequencyCount = {};

  for (let item of arr1) {
    frequencyCount[item] = (frequencyCount[item] || 0) + 1;
  }

  for (let item of arr2) {
    if (!frequencyCount[item]) {
      return false;
    }
    frequencyCount[item]--;
  }

  return true;
}


function convert2arrPromocode(data = {}) {
  const skus = []
  const shops = []
  for (const [shopkey, shopValue] of Object.entries(data.shops)) {
    console.log(shopValue.allProduct,shopValue)
    if (shopValue?.allProduct == true) {
      shops.push({ shopId: parseInt(shopkey) })
    } else {
      for (const key in shopValue?.product) {
        if (shopValue?.product.hasOwnProperty(key)) {
          const sku = shopValue?.product[key]
          const productId = parseInt(key) // เปลี่ยน type(string) to int
          for (const key in sku) {
            skus.push({ shopId: parseInt(shopkey), productId, skuId: parseInt(key) })
          }
        }
      }
    }
  }

  return { shops: shops, skus: skus }
}

export {
  convert2obj,
  convert2array,
  convert2objPromocode,
  getLocationString,
  isDataURL,
  readFile,
  isBase64,
  dataURLtoFile,
  isEmptyOrSpaces,
  clearUserFromLocal,
  setUserFromLocal,
  getUserFromLocal,
  login,
  isLogin,
  isAdmin,
  isNumber,
  isMobileOrTel,
  disableGoBack,
  handleError,
  setLocalStorage,
  getLocalStorage,
  detectInternalApp,
  validateEmail,
  clearSocialProfile,
  formatNumber,
  decimal2point,
  delay,
  isServer,
  checkPermission,
  decimalToInteger,
  getUserRole,
  checkDuplicateArr,
  textIsEmpty,
  sortByKey,
  checkZipCode,
  objectIsEmpty,
  isSpecialCharacters,
  isEmpty,
  IsJsonString,
  hexToRgb,
  scrollToDiv,
  getTopViewColor,
  deepCopyArray,
  diffObjectsInArray,
  getDifferenceArray,
  addArrayIfNotExists,
  convert2arrPromocode
}
