import React from 'react'
import { Radio } from 'antd';
import { Nav, NavItem, NavLink } from 'reactstrap';
import 'styles/_createbroadcast.scss'
import classnames from 'classnames';
import FormCreateBroadcastNoti from './FormCreateBroadcastNoti';
import { Body1, H6, H5 } from 'components/FontStyle'
import FormContentBroadcast from './FormContentBroadcast';
import { connect } from 'react-redux'
import { useFormCreateBroadcastNoti } from 'hook/useFormCreateBroadcastNoti';
import ModalPreviewBroadCast from 'components/ModalPreviewBroadcast';

const CreateBroadCast = () => {
    const { notification, setNotification,
        desc, setDesc,
        url, setUrl,
        date, setDate,
        time, setTime,
        typeSend, setTypeSend, setDateTime,
        notiEmail, setNotiEmail,
        notiAppWeb, setNotiAppWeb,
        keyword, setKeyword,
        createForm,
        cleanup,
        errors,
        typeBroadcast, setTypeBroadcast,
        activeTab, setActiveTab,
        broadcastType,
        activeTabSelected,
        content, setContent, cancelForm,
        setHtmlFormat, closeModalPreview,
        createContentBroadcast
    } = useFormCreateBroadcastNoti()

    const onChangeType = (e) => {
        setTypeBroadcast(e.target.value)
        setActiveTab(activeTabSelected[0].value)
    }

    return (
        <div className="create-boardcast-container">
            <H5>สร้างบรอดแคสต์</H5>

            <div className="broadcast-content-box">
                <H6>รูปแบบบรอดแคสต์</H6>
                <Radio.Group
                    onChange={(e) => onChangeType(e)}
                    value={typeBroadcast}
                    className='font-primary d-flex justify-content-between mt-4'
                    style={{ width: '30%' }}
                    name={'typeSender'}
                >
                    <Radio value={broadcastType[0].value}>
                        <Body1>แจ้งเตือนแบบส่งลิงค์ URL</Body1>
                    </Radio>
                    <Radio value={broadcastType[1].value}>
                        <Body1>แจ้งเตือนแบบสร้างเนื้อหาบทความ</Body1>
                    </Radio>
                </Radio.Group>
            </div>

            <div className="mt-3">
                <Nav tabs>
                    {activeTabSelected.filter((item) =>
                        item.type.includes(typeBroadcast)).map((data, tabidx) => (
                            <NavItem onClick={() => setActiveTab(data.value)} key={tabidx}>
                                <NavLink className={classnames({ 'active': activeTab === data.value })} >
                                    {data.label}
                                </NavLink>
                            </NavItem>
                        ))}
                </Nav>

                <div>
                    {activeTabSelected[0].value === activeTab ?
                        <FormCreateBroadcastNoti
                            typeBroadcast={typeBroadcast}
                            activeTabSelected={activeTabSelected}
                            activeTab={activeTab}
                            broadcastType={broadcastType}
                            setActiveTab={setActiveTab}
                            useFormCreateBroadcastNoti={useFormCreateBroadcastNoti}
                            notification={notification}
                            setNotification={setNotification}
                            desc={desc}
                            setDesc={setDesc}
                            url={url}
                            setUrl={setUrl}
                            date={date}
                            setDate={setDate}
                            time={time}
                            setTime={setTime}
                            typeSend={typeSend}
                            setTypeSend={setTypeSend}
                            setDateTime={setDateTime}
                            keyword={keyword}
                            setKeyword={setKeyword}
                            errors={errors}
                            setNotiAppWeb={setNotiAppWeb}
                            setNotiEmail={setNotiEmail}
                            notiEmail={notiEmail}
                            createForm={createForm}
                            cleanup={cleanup}
                            notiAppWeb={notiAppWeb}
                            content={content}
                            cancelForm={cancelForm}
                        />
                        :
                        activeTabSelected[1].value === activeTab ?
                            <FormContentBroadcast
                                createForm={createForm}
                                activeTabSelected={activeTabSelected}
                                setActiveTab={setActiveTab}
                                errors={errors}
                                content={content}
                                setContent={setContent}
                                cancelForm={cancelForm}
                            // typeBroadcast={typeBroadcast} 
                            // activeTabSelected={activeTabSelected}
                            // activeTab={activeTab}
                            /> : null
                    }

                </div>
            </div>
            <ModalPreviewBroadCast
                content={content}
                setHtmlFormat={setHtmlFormat}
                closeModalPreview={closeModalPreview}
                createContentBroadcast={createContentBroadcast}
            />
        </div>
    )
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps)(CreateBroadCast)
