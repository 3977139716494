/* eslint-disable no-useless-constructor */
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux'
import moment from "moment";
// import '../styles/modal.scss'
// import '../styles/modalMessage.scss'
import './index.scss'
import Success from '../../images/icons/success.svg'
import Alert from '../../images/icons/alert.svg'
import iconClose from 'images/icons/close.svg'

import { Body1, Body2, Caption, H6, SubCaption, SubTitle2 } from "components/FontStyle";
import { TextInput, Textarea, FloatInput } from 'components/input'
import Radio from "components/RadioButton";
import { dispatchNotification, OPEN_TOAST } from 'redux/actions/notification';
import TimePicker from 'components/Timepicker';

const ModalChatMessage = (props) => {
  const { isOpen, data, submit, btnCancel } = props
  const dispatch = useDispatch();
  const [changeMessage, setChangeMessage] = useState("")
  const [type, setType] = useState('any')
  const [timeStart, setTimeStart] = useState(moment('00:00', 'HH:mm'))
  const [timeEnd, setTimeEnd] = useState(moment('23:59', 'HH:mm'))
  const [error, setError] = useState(null)


  useEffect(() => {
    setChangeMessage(data?.text || "")
    setType(data?.responseType||'any')

    if (data?.responseTime) {      

      setTimeStart(moment(data?.responseTime?.start, 'HH:mm'))
      setTimeEnd(moment(data?.responseTime?.end, 'HH:mm'))

    }



  }, [data, isOpen])

  const toggleModalMessage = () => {
    props.closeModalMessage()
  }

  const confirm = () => {
    try {
      props.confirmCallback()
    } catch (ex) {

    }
  }

  const fecthApi = () => {
    const data = {
      "text": changeMessage,
      "responseType": type,
      "responseTime": {
        "start": timeStart.format('HH:mm'),
        "end": timeEnd.format('HH:mm')
      }
    }
    try {
      submit(data)
      dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } }))
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = () => {
    if (changeMessage.length < 30) {
      setError('กรุณากรอกข้อความระหว่าง 30-300 ตัวอักษร')
      return;
    }
    fecthApi()
    cancelModal()
  }

  const cancelModal = () => {
    setError(null)
    setChangeMessage("")
    setTimeStart(moment('00:00', 'HH:mm'))
    setTimeEnd(moment('23:59', 'HH:mm'))
    btnCancel()
  }


  let typeStatus = {
    any: { value: "any", label: "ตลอดเวลา" },
    custom: { value: "custom", label: "กำหนดเวลาเอง" }
  }


  return (
    <Modal
      isOpen={isOpen}
      className="modal-chat-message">
      <ModalHeader toggle={() => { }}>
        <div className="d-flex justify-content-between w-100">
          <span>{'ข้อความตอบกลับอัตโนมัติ'}</span>
         <span>
            <img src={iconClose} alt="" className="cursor-pointer" onClick={cancelModal} />
          </span> 
        </div>
      </ModalHeader>

      <ModalBody>
        <label className="info-message">ข้อความอัตโนมัติจะแสดงให้ผู้ซื้อแต่ละคนเห็นเพียง 1 ครั้งใน 24 ชั่วโมงเท่านั้น</label>

        <div className="w-100">
          <div className='content-input d-flex flex-column bg-white'>
            <div className="d-flex flex-row">
            <SubTitle2>ตั้งค่าข้อความ</SubTitle2>
            </div>
            <div className="d-flex flex-column">

              <div>
                <Textarea
                  label='รายละเอียดข้อความ'
                  defaultValue={changeMessage}
                  handleChangeText={(text) => setChangeMessage(text)}
                  require={true}
                  error={error}
                  limit={300}
                  title='(30-300 ตัวอักษร)'
                  placeholder='กรอกรายละเอียด'
                />
              </div>
            </div>


            <div className="d-flex flex-column mt-3 pt-3">
              <Body2 className='pb-1'>
                กำหนดช่วงเวลาการตอบข้อความอัตโนมัติ
              </Body2>

              <div className="d-flex flex-row">
                {renderOption({
                  key: "",
                  value: type,
                  col: 6,
                  disabledItems: [],
                  options: typeStatus,
                  disabled: false,
                  fontSize: 20,
                  onInputValueChange: setType,
                })}
              </div>
            </div>
            {
              type == 'custom' ?
                <div className="d-flex flex-row">
                  <div className="d-flex flex-column mr-3 pr-3">
                    <Body2 className='pb-1'>
                      เวลาเริ่มต้น<span className="txt-red">*</span>
                    </Body2>

                    <div className="d-flex flex-row">
                      <TimePicker
                        onTimePickerChange={value => setTimeStart(value)}
                        value={timeStart}
                        disabled={false}
                        disabledTime={moment('00:00', 'HH:mm')}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column mr-3 pr-3">
                    <Body2 className='pb-1'>
                      เวลาสิ้นสุด<span className="txt-red">*</span>
                    </Body2>

                    <div className="d-flex flex-row">
                      <TimePicker
                        onTimePickerChange={value => setTimeEnd(value)}
                        value={timeEnd}
                        disabled={false}
                        disabledTime={moment('00:00', 'HH:mm')}
                      />
                    </div>
                  </div>

                </div>
                : null
            }




          </div>
        </div>


      </ModalBody>
      <ModalFooter>
        <div className="w-100 d-flex justify-content-center" style={{ gap: '30px', marginTop: '1em' }}>
          <span><button className="btn-cancel" onClick={cancelModal}>ยกเลิก</button></span>
          <span><button className="btn-submit" onClick={handleSubmit}>บันทึก</button></span>
        </div>
      </ModalFooter>
    </Modal>
  );

}

ModalChatMessage.defaultProps = {
  isOpen: false,
  title: "",
  message: "",
  tiem: "",
  footerMessage: "",
  btnLabel: "ตกลง",
  infoMsg: "",
  btnCancel: () => { }

}

export default ModalChatMessage


const renderOption = (params) => {
  const { key, value, col = 6, cols, options, disabled, fontSize = 14, disabledItems, onInputValueChange } = params;

  return (
    <div className="option-container">
      {Object.keys(options).map((keyItem, index) => {
        const isDisabled = disabledItems ? disabledItems.find(item => item === index) : false;
        const disabledItem = isDisabled != undefined ? true : disabled;
        let column = col;
        if (cols) column = cols[index];

        return <div key={keyItem} className={`option-item`}>
          <Radio
            value={options[keyItem]?.value}
            className="mr-4"
            valueOption={value}
            name={key}
            disabled={disabledItem}
            onChange={() =>
              onInputValueChange(options[keyItem].value)
            }
          />
          <label className="form-check-label mr-4" style={{ fontSize: fontSize }}>
            {options[keyItem]?.label}
          </label>
          <br />
          {options[keyItem]?.description &&
            <label className="label-description">
              {options[keyItem]?.description}
            </label>
          }

        </div>
      }
      )}
    </div>
  );
};