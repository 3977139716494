import {
    ADD_KEY_VALUE_CHECKED_PRODUCT,
    CHECK_PRODUCT_PROMOCODE,
    UNCHECK_PRODUCT_PROMOCODE,
    UNCHECK_ALL_PRODUCT_PROMOCODE
} from "redux/actions/checkedProduct"

const initialState = {
    count: 0,
    sku: 0,
    product: {},
};

/* function : check product by add product and make all sku with status true */
/* payload  : (Object) {'98': { '12': true, '14': true, '55': true }} */
/* return   : (Object) {sku, count, product} */
const checkProduct = (product, state) => {
    const productId = Object.keys(product)[0];
    /* count sku that value is true */
    function countTrueValues(innerObj) {
        return Object.values(innerObj).reduce((total, value) => {
            return total + (value === true ? 1 : 0);
        }, 0);
    }

    state.product = { ...state.product, ...product };
    state.count = Object.values(state.product).length;
    state.sku = state.sku + countTrueValues(product[`${productId}`]);
    return state;
}

/* function : uncheck product by delete product and reduct count */
/* payload  : (Object) {'98': false} */
/* return   : (Object) {sku, count, product} */
const unCheckProduct = (product, state) => {
    const productId = Object.keys(product)[0];
    state.sku = state.sku - Object.keys(state.product[`${productId}`]).length;
    state.count = state.count - Object.keys(product).length;
    delete state.product[`${productId}`];
    return state;
}

/* function : clear all product and count */
/* payload  : null */
/* return   : initial state */
const uncheckAllProduct = () => {
    const state = {
        count: 0,
        sku: 0,
        product: {},
    };
    return state;
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_KEY_VALUE_CHECKED_PRODUCT:
            return { ...state, [payload.key]: payload.value, }
        case CHECK_PRODUCT_PROMOCODE:
            return { ...checkProduct(payload, state) }
        case UNCHECK_PRODUCT_PROMOCODE:
            return { ...unCheckProduct(payload, state) }
        case UNCHECK_ALL_PRODUCT_PROMOCODE:
            return { ...uncheckAllProduct() }
        default: {
            return state
        }
    }
}