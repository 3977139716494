// import { initial } from "lodash";

import { OPEN_MODAL_CONFIRM } from '../actions/modalConfirm'


const initialState = {
    modalConfirm: false
}

export default function (state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case OPEN_MODAL_CONFIRM:
            return {
                ...state,
                modalConfirm: payload
            }
        default :
        return state
    }
}