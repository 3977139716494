export default {
  host: process.env.REACT_APP_API_HOST,
  api: process.env.REACT_APP_API_ENDPOINT,
  chat: process.env.REACT_APP_API_CHAT,
  // chat : 'http://127.0.0.1:9001/',
  img: 'https://thumb.izcene.com/saranros/800x600/',
  avatar: 'https://thumb.izcene.com/saranros/300x300/',
  cover: 'https://thumb.izcene.com/saranros/1024x576/',
  banner: 'https://thumb.izcene.com/saranros/0x0/',
  image: 'https://thumb.izcene.com/saranros/',
  video: 'https://secure-media.saranros.com/video/',
  secretKey: process.env.REACT_APP_SECRET_KEY,
  serviceKey: process.env.REACT_APP_SERVICE_KEY,
  apiMedia: process.env.REACT_APP_API_MEDIA,
  tokenSecretKey: process.env.REACT_APP_TOKEN_SECRET_KEY,
  streamUrl: process.env.REACT_APP_STREAM_URL,
  liveChat: process.env.REACT_APP_LIVECHAT_API,
  socket: process.env.REACT_APP_LIVECHAT_SOCKET,
  imgAnnounce: 'https://thumb.izcene.com/saranros/0x400/',
}

