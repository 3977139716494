import React from 'react';
import './modalAnnounce.scss'
import {
    // Button,
    Modal,
    //  ModalHeader,
    //  ModalFooter,
    ModalBody,
} from 'reactstrap';
import LogoCMS from 'components/LogoCMS';
import host from 'config/host';
import { displayDateTh } from 'helper/timeUtil';
import { ReactComponent as IconClose } from 'images/icons/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SET_KEY_ANNOUNCE, dispatchAnnounce } from 'redux/actions/announce';

const ModalAnnounce = (props) => {
    const {
        isShowModal = false,
        data = null,
    } = props
    const {
        image = "",
        title = "",
        announceBegin = "",
        announceEnd = "",
        description = "",
        id = "",
    } = Object(data)

    const dispatch = useDispatch()
    const userState = useSelector(state => state.user);
    const { id: userId } = userState?.userInfo || {};

    const imageCover = (!!image && !!image?.path) ? host?.image + image?.path : null
    const titleAnnounce = title
    const begin = displayDateTh(announceBegin, 'D MMM YYYY HH:mm',)
    const end = displayDateTh(announceEnd, 'D MMM YYYY HH:mm',)
    const dateTime = (!!announceBegin && !!announceEnd) ? begin + ' - ' + end : ''
    const contentHTML = description //|| "<style> @media (max-width:319px) { a > span[class^='font-size-']{ color: #1890ff; } p{ margin-bottom: 0em;} .font-size-12 { font-size: 12px !important; min-height: 12px !important; margin-bottom: 0em !important; display: inline-block; word-break: break-word !important; white-space: break-spaces;} }@media (min-width:320px) { a > span[class^='font-size-']{ color: #1890ff; } p{ margin-bottom: 0em;} .font-size-12 { font-size: 12px !important; min-height: 12px !important; margin-bottom: 0em !important; display: inline-block; word-break: break-word !important; white-space: break-spaces;} }@media (min-width:641px) { a > span[class^='font-size-']{ color: #1890ff; } p{ margin-bottom: 0em;} .font-size-12 { font-size: 12px !important; min-height: 12px !important; margin-bottom: 0em !important; display: inline-block; word-break: break-word !important; white-space: break-spaces;} }@media (min-width:1025px) { a > span[class^='font-size-']{ color: #1890ff; } p{ margin-bottom: 0em;} .font-size-12 { font-size: 12px !important; min-height: 12px !important; margin-bottom: 0em !important; display: inline-block; word-break: break-word !important; white-space: break-spaces;} } </style> <p><span class=\"font-size-12\" style=\"font-size: 12px;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></p>"
    // const contentHTML = "<style> @media (max-width:319px) { a > span[class^='font-size-']{ color: #1890ff; } p{ margin-bottom: 0em;} .font-size-12 { font-size: 12px !important; min-height: 12px !important; margin-bottom: 0em !important; display: inline-block; word-break: break-word !important; white-space: break-spaces;} }@media (min-width:320px) { a > span[class^='font-size-']{ color: #1890ff; } p{ margin-bottom: 0em;} .font-size-12 { font-size: 12px !important; min-height: 12px !important; margin-bottom: 0em !important; display: inline-block; word-break: break-word !important; white-space: break-spaces;} }@media (min-width:641px) { a > span[class^='font-size-']{ color: #1890ff; } p{ margin-bottom: 0em;} .font-size-12 { font-size: 12px !important; min-height: 12px !important; margin-bottom: 0em !important; display: inline-block; word-break: break-word !important; white-space: break-spaces;} }@media (min-width:1025px) { a > span[class^='font-size-']{ color: #1890ff; } p{ margin-bottom: 0em;} .font-size-12 { font-size: 12px !important; min-height: 12px !important; margin-bottom: 0em !important; display: inline-block; word-break: break-word !important; white-space: break-spaces;} } </style> <p><span class=\"font-size-12\" style=\"font-size: 12px;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia----- \n\n ---deserunt mollit anim id est laborum.</span></p>"


    const callbackCloseModalAnnounce = () => {
        try {
            /* close modal store announce id to cookie */
            if (isShowModal === true) {

                if (JSON.parse(localStorage.getItem("announce"))) {
                    let announces = JSON.parse(localStorage.getItem("announce"));
                    let announceByUser = announces?.find(announce => announce.userId === userId);

                    /* if user is already set */
                    if (announceByUser) {

                        let exceptedAnnounceId = announceByUser.exceptedAnnounceId;

                        /* if there are item more than 20 pop the first one out */
                        if (exceptedAnnounceId && exceptedAnnounceId.length > 20) {
                            exceptedAnnounceId = exceptedAnnounceId.shift();
                        }

                        /* set announce to this user */
                        if (data.id) {

                            const newAnnounce = announces.map(announceItem => {
                                if (announceItem.userId === userId) {
                                    announceItem.exceptedAnnounceId = [...announceItem.exceptedAnnounceId, data.id];
                                }
                                return announceItem;
                            });

                            localStorage.setItem("announce", JSON.stringify(newAnnounce));
                        }

                    } else {

                        /* add new user announce item */
                        if (data.id) {
                            const newItem = { userId: userId, exceptedAnnounceId: [data.id] };
                            localStorage.setItem("announce", JSON.stringify([...announces, newItem]));
                        }
                    }


                } else {

                    if (data.id)
                        localStorage.setItem(
                            "announce",
                            JSON.stringify([{ userId: userId, exceptedAnnounceId: [data.id] },])
                        );
                }
            }

            dispatch(
                dispatchAnnounce({
                    type: SET_KEY_ANNOUNCE,
                    payload: {
                        key: "modalDisplayAnnounce",
                        value: {
                            data: data,
                            isOpen: !isShowModal,
                        },
                    },
                })
            );
        } catch (error) {
            console.log(`error`, error);
        }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={isShowModal}
                // toggle={callbackCloseModalAnnounce}
                modalClassName='modal-wrap-announce'
            >
                <ModalBody className='modal-box-announce'>

                    {/* Header */}
                    <div className='modal-header-announce'>
                        {/* Header Fixed */}
                        <LogoCMS width='85px' />

                        <button
                            className="btn-icon-close"
                            onClick={callbackCloseModalAnnounce}
                        >
                            <IconClose className="icon-close" />
                        </button>
                    </div>
                    {/* Header */}


                    {/* Body */}
                    <div className='modal-body-announce'>
                        <div className='body-announce'>
                            {!!imageCover && (
                                <img
                                    src={imageCover}
                                />
                            )}
                            <div className='body-box-title'>
                                <h1>{titleAnnounce}</h1>
                                <h2>{dateTime}</h2>
                            </div>

                            <div
                                className='body-box-vysiwys'
                                dangerouslySetInnerHTML={{ __html: contentHTML }}
                            />
                        </div>
                    </div>
                    {/* Body */}


                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};


export default ModalAnnounce;