
import React from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';
import './index.scss';

const format = 'HH:mm';

const TimePickerComponent = (props) => {

    const {value, 
        // onTimePickerChange,
        disabledTime=moment('00:00:00', 'HH:mm:ss'),
        disabled} = props;

    const onChange = time => {
       props.onTimePickerChange(moment(time));
    };

    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
    }
      

    return (
        <TimePicker 
        bordered={true}
        defaultValue={moment('00:00', format)}
        format={format} 
        onChange={onChange}
        value={value}
        allowClear={false}
        disabled={disabled}
        disabledHours={() => range(0, disabledTime.hour())}
        // disabledMinutes={() => range(0, disabledTime.minute())}
        />
        
    )

};

export default TimePickerComponent;

