/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  DropdownSort,
  InputSearch,
  AlbumCard,
  ButtonAdd,
  ShopStorage,
  Modals,
  ModalLoading,
} from '../../output'
import { TextInput } from '../../input'
import { LayoutGallery, LayoutTab } from '../../layout'
import '../../../styles/gallery.scss'

import { connect } from 'react-redux'
import {
  dispatchGallery,
  GET_ALBUM_GALLERY,
  CREATE_ALBUM_GALLERY,
  UPDATE_ALBUM_GALLERY,
  SEARCH_ALBUM_GALLERY,
} from '../../../redux/actions/gallery'

const optionsDropdownSort = [
  {
    value: 'lastUpload',
    name: 'อัปโหลดล่าสุด',
  },
  {
    value: 'name',
    name: 'เรียงตามตัวอักษร',
  },
]

const Album = (props) => {
  const {
    isMainPage,
    isEditName,
    clickAlbum,
    shopDetail,
    albumData,
    dispatch,
  } = props

  const [activeModal, setActiveModal] = useState('none')
  const [nameInput, setNameInput] = useState('')
  const [albumId, setAlbumId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    dispatch(
      dispatchGallery({
        type: GET_ALBUM_GALLERY,
        payload: { shopId: shopDetail.shopId },
      }),
    )
  }, [])

  useEffect(() => {
    if (props.albumData) {
      setIsLoading(false)
    }
  }, [props.albumData])

  const toggleModal = (modal) => {
    setActiveModal(modal)
  }

  const handleChangeText = (text) => {
    setNameInput(text)
  }

  const clickButtonModals = () => {
    if (activeModal === 'createAlbum' && nameInput) {
      dispatch(
        dispatchGallery({
          type: CREATE_ALBUM_GALLERY,
          payload: { shopId: shopDetail.shopId, name: nameInput },
        }),
      )
    } else if (activeModal === 'editAlbum' && nameInput) {
      dispatch(
        dispatchGallery({
          type: UPDATE_ALBUM_GALLERY,
          payload: { name: nameInput },
          etc: { shopId: shopDetail.shopId, albumId: albumId },
        }),
      )
    }

    toggleModal('none')
  }

  return (
    <>
      <div className='container-select-image'>
        {isMainPage && (
          <ShopStorage
            detail={{
              id: shopDetail.shopId,
              name: shopDetail.shop.name,
              storage: {
                used: shopDetail.used,
                max: shopDetail.storageSizes,
              },
              count: 30,
              logoPath: shopDetail.shop.logoPath.path,
            }}
          />
        )}
        <LayoutTab
          valueActive='image'
          handleClickTab={(value) => console.log('')}
        >
          <div className='bg-white'>
            <div className='header'>
              <div className='content-header'>
                <h5>หน้าหลัก</h5>
                {isMainPage && (
                  <ButtonAdd
                    label='เพิ่มอัลบั้ม'
                    handleAdd={() => toggleModal('createAlbum')}
                  />
                )}
              </div>
              <div className='content-header'>
                <div style={{ width: '50%' }}>
                  <InputSearch
                    handleSearch={(value) =>
                      dispatch(
                        dispatchGallery({
                          type: SEARCH_ALBUM_GALLERY,
                          payload: { name: value, shopId: shopDetail.shopId },
                        }),
                      )
                    }
                    placeholder='ค้นหาจากชื่ออัลบั้ม'
                    // SEARCH_ALBUM_GALLERY
                  />
                </div>
                <div style={{ width: '35%' }}>
                  <DropdownSort
                    label='เรียงจาก'
                    defaultValue='lastUpload'
                    options={optionsDropdownSort}
                    handleSort={(value) =>
                      dispatch(
                        dispatchGallery({
                          type: GET_ALBUM_GALLERY,
                          payload: { shopId: shopDetail.shopId, sort: value },
                        }),
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <LayoutGallery>
              {albumData?.map((album, index) => (
                <AlbumCard
                  key={album.id}
                  data={album}
                  index={index}
                  onClickIconEdit={() => {
                    toggleModal('editAlbum')
                    setNameInput(album.name)
                    setAlbumId(album.id)
                  }}
                  isEditName={isEditName}
                  clickAlbum={() => {
                    clickAlbum({ albumDetail: album, keyPage: 'media' })
                  }}
                />
              ))}
            </LayoutGallery>
          </div>
        </LayoutTab>
      </div>
      {isMainPage && (
        <Modals
          title={`${
            activeModal === 'createAlbum' ? 'สร้าง' : 'แก้ไขชื่อ'
          }อัลบั้ม`}
          closeModal={() => toggleModal('none')}
          clickButton={clickButtonModals}
          labelButton='ตกลง'
          isOpenModal={
            activeModal === 'createAlbum' || activeModal === 'editAlbum'
          }
          shopId={shopDetail.shopId}
        >
          <TextInput
            label='ชื่ออัลบั้ม'
            handleChangeText={handleChangeText}
            placeholder={nameInput}
          />
        </Modals>
      )}
      {isLoading && <ModalLoading isOpenModal={isLoading} />}
    </>
  )
}

Album.defaultProps = {
  isMainPage: false,
  isEditName: false,
  clickAlbum: () => {},
}

const mapStateToProps = (state) => ({
  albumData: state.gallery.albumData,
})

export default connect(mapStateToProps, null)(Album)
