import React, { Component } from 'react'
// import navLogo from '../images/icons/outlet-live-logo.svg'
// import logoLogin from '../images/icons/logo-login.svg'
import { FETCH_USER_LOGIN, dispatchUser, SET_KEY_USER } from '../redux/actions/user'
import '../styles/_login.scss'
import { connect } from 'react-redux'
import { isAuth } from '../index'
import { Body1, SubCaption, H4 } from 'components/FontStyle'
import { ReactComponent as Logo } from 'images/icons/logo-v-avenue.svg'
import LogoCMS from 'components/LogoCMS'
import ToastNotification from 'components/ToastNotification'
import ModalConfirmEmail from 'components/ModalConfirmEmail'
import { OPEN_TOAST, dispatchNotification } from 'redux/actions/notification'
import { dispatchApp, SET_LOADING } from 'redux/actions/app'
import userProvider from 'provider/userProvider'
const api_user = userProvider

const labelsConfirmEmail = { CONFIRM_SUCCESSFUL: 'ระบบส่งลิงค์สำหรับเปลี่ยนรหัสผ่านไปที่อีเมลแล้วกรุณาตรวจสอบ',
                             CONFIRM_FAILED: 'ทำรายการไม่สำเร็จ กรุณาลองใหม่',
                             COMFIRM_NOT_EXIST: 'ไม่มีอีเมลนี้ในระบบ กรุณาลองใหม่'}
class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            isOpenModalConfirmEmail: false,
            comfirmErrors: {}
        }
    }

    componentDidMount() {
        if (isAuth) {
            window.location = "/manage/dashboard"
        }
    }

    onSetPassword = (e) => {
        this.props.dispatch(dispatchUser(({ type: SET_KEY_USER, payload: { key: 'errLogin', value: false } })))
        const x = document.getElementById('password')
        const icon = document.getElementById('eye')
        if (x.value.trim().length > 0) {
            if (x.type === "password") {
                icon.className = "show-password-ready"
            } else if (x.type === "text") {
                icon.className = "show-password-already"
            }

            // x.type = x.type
        }
        if (x.value.trim().length === 0) {
            icon.className = "icon-show-password"
            x.type = "password"
        }

        this.setState({
            password: e.target.value
        })
    }

    onShowPassword = () => {
        const x = document.getElementById("password");
        const icon = document.getElementById('eye')
        if (x.value.trim().length === 0) return
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (icon.className === "show-password-ready") {
            icon.className = "show-password-already"
        } else {
            icon.className = "show-password-ready"
        }
    }

    handleLogin = async () => {
        const { history } = this.props

        const email = document.getElementById('email');
        const password = document.getElementById('password')
        const data = {
            email: email.value.trim(),
            password: password.value
        }
        try {
            await this.props.dispatch(dispatchUser({ type: FETCH_USER_LOGIN, payload: { user: data, history } }))
            // history.push({ pathname: '/manage/addproduct', state: null })
        } catch (error) {

            throw error
        }
    }

    onSetEmail = (e) => {
        this.props.dispatch(dispatchUser(({ type: SET_KEY_USER, payload: { key: 'errLogin', value: false } })))
        this.setState({
            email: e.target.value
        })
    }

    onClickChangePassword = (event) => {
        event.preventDefault();
        this.setState({...this.state, isOpenModalConfirmEmail: true});
    }

    handleSubmitChangePassword = async (email) => {
        try {
            this.props.dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            await api_user.sendEmailPasswordReset({email: email.trim()});
            this.props.dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
            this.setState({...this.state, isOpenModalConfirmEmail: false});
            this.props.dispatch(dispatchNotification({type: OPEN_TOAST ,payload: { message: labelsConfirmEmail.CONFIRM_SUCCESSFUL , type: 'success' } }))
        } catch(error) {
            if (error.response) {
                if (error?.response?.data?.code === 401) {
                    this.setState({comfirmErrors: {email: labelsConfirmEmail.COMFIRM_NOT_EXIST}})
                } else {
                    this.props.dispatch(dispatchNotification({type: OPEN_TOAST ,
                                                              payload: { message: labelsConfirmEmail.CONFIRM_FAILED , 
                                                                         type: 'danger' } }))
                }
            }
            this.props.dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        }
    }

    handleCancelChangePassword = () => this.setState({...this.state, isOpenModalConfirmEmail: false});



    render() {
        return (
            <div className="overflow-page" id="login">
                {/* <div className="circle"></div> */}
                {/* <div className="circle-2"></div> */}
                <div className="nav-container position-relative">
                    <nav className="nav-login">
                        {/* <img src={navLogo} alt="logo" className="text-center" style={{ width: '100px', height: '45px' }} /> */}
                        <div className="container-logo" style={{ maxHeight: '30px' }}>
                            {/* <img src={logoLogin} alt="godang-cms" /> */}
                            <LogoCMS
                                width='85px'
                                height='100%'
                            />
                        </div>
                    </nav>
                </div>
                <div className="login-container">
                    <div className="form-login">
                        <form className="m-auto login-form position-relative" onSubmit={e => { e.preventDefault(); this.handleLogin() }}>
                            {/* <div className="border-bottom"></div> */}
                            <div className="container-logo">
                                {/* <img src={logoLogin} alt="godang-cms" /> */}
                                <Logo width="212px" height="104px" className='mr-3' />
                                <H4 className="border-logo">CMS</H4>
                                {/* <LogoCMS
                                    width='205px'
                                    height='100px'
                                /> */}
                            </div>
                            <div className="login-data">
                                <h2 className="font-weight-light">เข้าสู่ระบบ</h2>
                                <div className="form-group mt-4">
                                    <label htmlFor="email"><SubCaption>อีเมล</SubCaption> <SubCaption style={{ color: 'red' }}>*</SubCaption></label>
                                    <input type="text" className="form-control-1" id="email" aria-describedby="emailHelp" placeholder="กรอกอีเมล" onChange={(e) => this.onSetEmail(e)} />
                                </div>
                                <React.Fragment>
                                    <label htmlFor="password"><SubCaption>รหัสผ่าน</SubCaption> <SubCaption style={{ color: 'red' }}>*</SubCaption> </label>
                                    <div className="form-group position-relative">
                                        <input type="password" className="password-input" id="password" value={this.state.password} placeholder="กรอกรหัสผ่าน" onChange={(e) => this.onSetPassword(e)} />
                                        <div className='icon-show-password' id="eye" onClick={() => this.onShowPassword()}> </div>
                                    </div>
                                </React.Fragment>
                                <div style={{display: 'flex', justifyContent: 'end'}}>
                                    <label htmlFor="password" 
                                    className='cursor-pointer'
                                        style={{color: '#b2d234', fontSize: '1rem'}}
                                        onClick={this.onClickChangePassword}> 
                                        เปลี่ยนรหัสผ่าน </label>
                                </div>
                            </div>
                            {this.props.user.errLogin ? <span className="text-danger">{this.props.user?.errLogin === 'EMAIL_NOT_ACTIVATE' ? 'กรุณายืนยันอีเมล' : this.props.user?.errLogin === 'Incorrect password' ? 'ชื่อหรือรหัสผ่านผิดพลาด' : this.props.user?.errLogin}</span> : null}
                            <button className="btn-login" type="submit"><Body1>เข้าสู่ระบบ</Body1></button>
                        </form>
                    </div>
                </div>
                <ToastNotification />
                <ModalConfirmEmail isOpen={this.state.isOpenModalConfirmEmail} 
                                   handleConfirm={this.handleSubmitChangePassword} 
                                   handleCancel={this.handleCancelChangePassword}
                                   errors={this.state.comfirmErrors} />
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps, null)(Login)