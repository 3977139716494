/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { Body1, Body2, Caption, H5, Tooltip } from 'components/FontStyle'
import ModalTransferConfirm from 'components/ModalTransferConfirm'
import AccountantTransferDetail from 'components/output/Withdraw/AccountantTransferDetail'
import AdminTransferDetail from 'components/output/Withdraw/AdminTransferDetail'
import SelectInputWithImg from 'components/SelectInputWithImg'
import XlsxCreate from 'components/XlsxCreate'
import { displayDateThShortYear } from 'helper/timeUtil'
import { getLocalStorage, getUserRole, setLocalStorage } from 'helper/utils'
import { ACCOUNTANT, ADMIN, OWNER, SUPER_ADMIN } from 'layout/Sidebar/Role'
import numeral from 'numeral'
import withdrawProvider from 'provider/withdrawProvider'
import React, { useState, useEffect } from 'react'
import { getOrderSellerWithdraw } from 'redux/sagas/withdraw'

const TransferOrderDetail = () => {
    // const { status, transferredDate, refNumber, settledDate, settlementPeriod, orderWithdraw, totalRevenue, totalPaymentFee, listAllShop,
    //     // fetchDataExcel,
    //     shopSelectedIndex, id, shop, totalItemPrice, totalShippingFee, totalDiscountShippingShop, totalShippingDiscount, totalShippingDiscountShop,
    //     totalDiscountPlatform, totalDiscountShop, totalAmount, totalPaymentFeeVat, transferList,totalWithHoldingTax
    // } = location.state



    const { getListSellerWithdrawOrder,
        listWithdraw,
        checkPermTransfer, detail } = useHookTransferDetail()

    const statusTransfer = {
        settled: 'รอโอนเงิน',
        transferred: `โอนเงินให้ร้านค้าแล้ว (${displayDateThShortYear(detail?.transferredDate)})`
    }

    const [modalDetail, setModalDetail] = useState({ isOpen: false, data: null })

    const showStatusWithdraw = (status, date, withdrawTransaction) => {
        switch (status) {
            // case 'requested':
            // case 'settled':
            //     return <span>กำลังดำเนินการ</span>
            //     break;
            case 'transferred':
                return <span className='d-inline-block flex-column justify-content-center align-items-center'>
                    <span className='color-default-success'> โอนเงินให้ร้านค้าแล้ว</span>
                    <span className='color-grey d-flex'>{displayDateThShortYear(date,'DD MMM YY HH:mm')}</span>
                    <span className='color-grey d-flex'>{withdrawTransaction?.length > 0 && `฿ ${numeral(withdrawTransaction?.length > 0 && withdrawTransaction[withdrawTransaction?.length - 1]?.totalAmount).format('0,0.00')}` }</span>
                </span>

            default:
                return <span className=''>กำลังดำเนินการ</span>
                break;
        }
    }

    const showButtonTransferred = (list) => {
        switch (list?.status) {
            case 'settled':
                // if (checkPermTransfer()) {
                //     return <button className="btn-confirm-grid" style={{ minWidth: '10px', maxWidth: '6em' }} onClick={() => setModalDetail({ isOpen: true, data: list?.sellerWithdraw })}><Caption>ยืนยันโอนเงิน</Caption></button>
                // }
                return checkPermTransfer() ? 'รอโอนเงิน' : null
                break;
            case 'transferred':
                return <span className='text-underline cursor-pointer' onClick={() => setModalDetail({ isOpen: true, data: list?.sellerWithdraw })}>ดูหลักฐานการโอน</span>
            default:
                break;
        }
    }

    const fetchDataExcel = async () => {
        if (getUserRole() === ACCOUNTANT) {
            const header = ['ลำดับ', 'สถานะการโอนเงินให้ร้านค้า', 'Transaction number','เลขการโอนเงิน','Vendor Code', 'Customer Code', 'หมายเลขออเดอร์', 'รอบสรุปข้อมูล', 'กำหนดโอนเงินโดยประมาณไม่เกิน', 'วันที่ลูกค้าชำระเงิน', 'วันที่จัดส่งถึงลูกค้า', 'ร้านค้า', 'ชื่อนิติบุคคล/ชื่อบุคคลธรรมดา', 'จำนวนสินค้า', 'ยอดรวมค่าสินค้า', 'ค่าจัดส่ง', 'ส่วนลดค่าจัดส่ง', 'คงเหลือค่าจัดส่ง', 'ยอดรวมค่าสินค้าและค่าจัดส่ง', 'ส่วนลด V-avenue', 'ส่วนลดร้านค้า', 'ส่วนลดทั้งหมด', 'ยอดชำระทั้งสิ้น', 'ช่องทางการชำระเงิน', 'เลขอ้างอิง mPAY', 'ค่าบริการชำระเงินคิดที่อัตรา 2%', 'ภาษีค่าบริการชำระเงิน', 'ค่าบริการชำระเงิน (รวมภาษี)', 'ส่วนแบ่งรายได้ร้านค้า', 'ภาษีหัก ณ ที่จ่าย (W/T)', 'ส่วนแบ่งรายได้หลังหักภาษี ณ ที่จ่าย']
            const dataExcel = []
            await listWithdraw && listWithdraw.orderWithdraws.map((list, idx) => {
                dataExcel.push({
                    index: idx + 1,
                    // btnTransfer: list?.status,
                    statusTransfer: list?.status === 'transferred' ? `โอนเงินให้ร้านค้าแล้ว\n${displayDateThShortYear(list?.transferredDate,'DD MMM YY HH:mm')}\n฿ ${numeral(list?.sellerWithdraw?.attachment[list.sellerWithdraw?.attachment?.length - 1]?.totalAmount).format('0,0.00')}` : 'กำลังดำเนินการ',
                    transactionNumber: list?.sellerWithdraw?.refNumber,
                    transferRef: list?.sellerWithdraw?.transferredRef,
                    partner: detail?.shop?.partner?.value,
                    customerCode: detail?.transferList?.meta?.customerCode,
                    orderNo: list?.order?.orderNo,
                    settledDate: displayDateThShortYear(detail?.settledDate),
                    settlementPeriod: displayDateThShortYear(list?.sellerWithdraw?.settlementPeriod, 'DD MMM YY'),
                    paymentAt: displayDateThShortYear(list?.order?.paymentedAt, 'DD MMM YY HH:mm'),
                    deliveredAt: displayDateThShortYear(list?.order?.deliveredAt, 'DD MMM YY HH:mm'),
                    shopName: detail?.shop?.name,
                    shopTax: detail?.shop?.shopTax?.name,
                    countItem: numeral(list?.order?.items.reduce((a, { quantity }) => a + quantity, 0)).format('0,0'),
                    totalItemPrice: list?.totalItemPrice ? list?.totalItemPrice : '0',
                    totalShipping: numeral(list?.totalShippingFee).format('0,0.00') ? numeral(list?.totalShippingFee).format('0,0.00') : '0',
                    discount: (list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order.priceShippingDiscountShop : 0) ? (list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order.priceShippingDiscountShop : 0) : '0',
                    discountShipping: list?.totalShippingFee - ((list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order?.priceShippingDiscountShop : 0)) ? list?.totalShippingFee - ((list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order?.priceShippingDiscountShop : 0)) : '0',
                    amount: list?.totalItemPrice + list.order?.priceShippingNet ? list?.totalItemPrice + list.order?.priceShippingNet : '0',
                    discountPlatform: list?.totalDiscountPlatform ? list?.totalDiscountPlatform : '0',
                    discountShop: list?.totalDiscountShop ? list?.totalDiscountShop : '0',
                    discountAll: list?.totalDiscountPlatform + list?.totalDiscountShop ? list?.totalDiscountPlatform + list?.totalDiscountShop : '0',
                    totalAmount: list?.totalAmount ? list?.totalAmount : '0',
                    paymentMethod: list?.order?.paymentMethod,
                    transactionId: list?.order?.payment?.transactionId,
                    totalPaymentFee: list?.totalPaymentFee ? list?.totalPaymentFee : '0',
                    totalPaymentFeeVat: list?.totalPaymentFeeVat ? list?.totalPaymentFeeVat : '0',
                    totalPaymentFeeAndVat: list?.totalPaymentFee + list?.totalPaymentFeeVat ? list?.totalPaymentFee + list?.totalPaymentFeeVat : '0',
                    totalRevenue: (list?.totalAmount - (list?.totalPaymentFee + list?.totalPaymentFeeVat)) ? (list?.totalAmount - (list?.totalPaymentFee + list?.totalPaymentFeeVat)) : '0',
                    totalWithHoldingTax: list?.totalWithHoldingTax ? list?.totalWithHoldingTax : '0',
                    holdingTaxRevenue: list?.totalRevenue ? list?.totalRevenue : '0'
                })
            })
            dataExcel.push({
                index: '',
                // btnTransfer: '',
                statusTransfer: '',
                transactionNumber: '',
                transferRef: '',
                partner: '',
                customerCode: '',
                orderNo: '',
                settledDate: '',
                settlementPeriod: '',
                paymentAt: '',
                deliveredAt: '',
                shopName: '',
                shopTax: '',
                countItem: numeral(listWithdraw.orderWithdraws.map(element => element.order.items.map(item => item.quantity).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).format('0,0'),
                totalItemPrice: detail?.totalItemPrice ? detail?.totalItemPrice : '0',
                totalShipping: numeral(detail?.totalShippingFee).format('0,0.00') ? numeral(detail?.totalShippingFee).format('0,0.00') : '0',
                discount: (detail?.totalShippingDiscountShop + detail?.totalShippingDiscount) ? (detail?.totalShippingDiscountShop + detail?.totalShippingDiscount) : '0',
                discountShipping: detail?.totalShippingFee - (detail?.totalShippingDiscountShop + detail?.totalShippingDiscount) ? detail?.totalShippingFee - (detail?.totalShippingDiscountShop + detail?.totalShippingDiscount) : '0',
                amount: detail?.totalItemPrice + detail?.totalShippingFee,
                discountPlatform: detail?.totalDiscountPlatform ? detail?.totalDiscountPlatform : '0',
                discountShop: detail?.totalDiscountShop ? detail?.totalDiscountShop : '0',
                discountAll: detail?.totalDiscountPlatform + detail?.totalDiscountShop ? detail?.totalDiscountPlatform + detail?.totalDiscountShop : '0',
                totalAmount: detail?.totalAmount ? detail?.totalAmount : '0',
                paymentMethod: '',
                transactionId: '',
                totalPaymentFee: detail?.totalPaymentFee ? detail?.totalPaymentFee : '0',
                totalPaymentFeeVat: detail?.totalPaymentFeeVat ? detail?.totalPaymentFeeVat : '0',
                totalPaymentFeeAndVat: detail?.totalPaymentFee + detail?.totalPaymentFeeVat ? detail?.totalPaymentFee + detail?.totalPaymentFeeVat : '0',
                totalRevenue: detail?.totalAmount - (detail?.totalPaymentFee + detail?.totalPaymentFeeVat) ? detail?.totalAmount - (detail?.totalPaymentFee + detail?.totalPaymentFeeVat) : '0',
                totalWithHoldingTax: detail?.totalWithHoldingTax ? detail?.totalWithHoldingTax : '0',
                holdingTaxRevenue: detail?.totalRevenue ? detail?.totalRevenue : '0'
            })
            return {
                header,
                data: dataExcel
            }
        } else {
            const header = ['ลำดับ', 'สถานะการโอนเงินให้ร้านค้า', 'Transaction number', 'Vendor Code', 'หมายเลขออเดอร์', 'รอบสรุปข้อมูล', 'กำหนดโอนเงินโดยประมาณไม่เกิน', 'วันที่ลูกค้าชำระเงิน', 'วันที่จัดส่งถึงลูกค้า', 'ร้านค้า', 'ชื่อนิติบุคคล/ชื่อบุคคลธรรมดา', 'จำนวนสินค้า', 'ยอดรวมค่าสินค้า', 'ค่าจัดส่ง', 'ส่วนลดค่าจัดส่ง', 'คงเหลือค่าจัดส่ง', 'ยอดรวมค่าสินค้าและค่าจัดส่ง', 'ส่วนลด V-avenue', 'ส่วนลดร้านค้า', 'ส่วนลดทั้งหมด', 'ยอดชำระทั้งสิ้น', 'ช่องทางการชำระเงิน', 'เลขอ้างอิง mPAY', 'ค่าบริการชำระเงินคิดที่อัตรา 2%', 'ภาษีค่าบริการชำระเงิน', 'ค่าบริการชำระเงิน (รวมภาษี)', 'ส่วนแบ่งรายได้ร้านค้า', 'ภาษีหัก ณ ที่จ่าย (W/T)', 'ส่วนแบ่งรายได้หลังหักภาษี ณ ที่จ่าย']
            const dataExcel = []
            await listWithdraw && listWithdraw.orderWithdraws.map((list, idx) => {
                dataExcel.push({
                    index: idx + 1,
                    // btnTransfer: list?.status,
                    statusTransfer: list?.status === 'transferred' ? `โอนเงินให้ร้านค้าแล้ว\n${displayDateThShortYear(list?.transferredDate,'DD MMM YY HH:mm')}\n฿ ${numeral(list?.sellerWithdraw?.attachment[list.sellerWithdraw?.attachment?.length - 1]?.totalAmount).format('0,0.00')}` : 'กำลังดำเนินการ',
                    transactionNumber: list?.sellerWithdraw?.refNumber,
                    partner: detail?.shop?.partner?.value,
                    orderNo: list?.order?.orderNo,
                    settledDate: displayDateThShortYear(detail?.settledDate),
                    settlementPeriod: displayDateThShortYear(list?.sellerWithdraw?.settlementPeriod, 'DD MMM YY'),
                    paymentAt: displayDateThShortYear(list?.order?.paymentedAt, 'DD MMM YY HH:mm'),
                    deliveredAt: displayDateThShortYear(list?.order?.deliveredAt, 'DD MMM YY HH:mm'),
                    shopName: detail?.shop?.name,
                    shopTax: detail?.shop?.shopTax?.name,
                    countItem: numeral(list?.order?.items.reduce((a, { quantity }) => a + quantity, 0)).format('0,0'),
                    totalItemPrice: list?.totalItemPrice ? list?.totalItemPrice : '0',
                    totalShipping: numeral(list?.totalShippingFee).format('0,0.00') ? numeral(list?.totalShippingFee).format('0,0.00') : '0',
                    discount: list?.totalShippingDiscountShop + list?.totalShippingDiscount ? list?.totalShippingDiscountShop + list?.totalShippingDiscount : '0',
                    discountShipping: list?.totalShippingFee - (list?.totalShippingDiscountShop + list?.totalShippingDiscount) ? list?.totalShippingFee - (list?.totalShippingDiscountShop + list?.totalShippingDiscount) : '0',
                    amount: list?.totalItemPrice + list.order?.priceShippingNet ? list?.totalItemPrice + list.order?.priceShippingNet : '0',
                    discountPlatform: list?.totalDiscountPlatform ? list?.totalDiscountPlatform : '0',
                    discountShop: list?.totalDiscountShop ? list?.totalDiscountShop : '0',
                    discountAll: list?.totalDiscountPlatform + list?.totalDiscountShop ? list?.totalDiscountPlatform + list?.totalDiscountShop : '0',
                    totalAmount: list?.totalAmount ? list?.totalAmount : '0',
                    paymentMethod: list?.order?.paymentMethod,
                    transactionId: list?.order?.payment?.transactionId,
                    totalPaymentFee: list?.totalPaymentFee ? list?.totalPaymentFee : '0',
                    totalPaymentFeeVat: list?.totalPaymentFeeVat ? list?.totalPaymentFeeVat : '0',
                    totalPaymentFeeAndVat: list?.totalPaymentFee + list?.totalPaymentFeeVat ? list?.totalPaymentFee + list?.totalPaymentFeeVat : '0',
                    totalRevenue: (list?.totalAmount - (list?.totalPaymentFee + list?.totalPaymentFeeVat)) ? (list?.totalAmount - (list?.totalPaymentFee + list?.totalPaymentFeeVat)) : '0',
                    totalWithHoldingTax: list?.totalWithHoldingTax ? list?.totalWithHoldingTax : '0',
                    holdingTaxRevenue: list?.totalRevenue ? list?.totalRevenue : '0'
                })
            })
            dataExcel.push({
                index: '',
                // btnTransfer: '',
                statusTransfer: '',
                transactionNumber:'',
                partner: '',
                orderNo: '',
                settledDate: '',
                settlementPeriod: '',
                paymentAt: '',
                deliveredAt: '',
                shopName: '',
                shopTax: '',
                countItem: numeral(listWithdraw.orderWithdraws.map(element => element.order.items.map(item => item.quantity).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).format('0,0'),
                // withdrawList && withdrawList?.orderWithdraws?.map(element => element.order.items.map(item => item.quantity).reduce((a,b) => a+b,0)).reduce((a,b) => a+b,0)).format('0,0')
                totalItemPrice: detail?.totalItemPrice ? detail?.totalItemPrice : '0',
                totalShipping: numeral(detail?.totalShippingFee).format('0,0.00') ? numeral(detail?.totalShippingFee).format('0,0.00') : '0',
                discount: (detail?.totalShippingDiscountShop + detail?.totalShippingDiscount) ? (detail?.detail?.totalShippingDiscountShop + detail?.totalShippingDiscount) : '0',
                discountShipping: detail?.totalShippingFee - (detail?.totalShippingDiscountShop + detail?.totalShippingDiscount) ? detail?.totalShippingFee - (detail?.totalShippingDiscountShop + detail?.totalShippingDiscount) : '0',
                amount: detail?.totalItemPrice + detail?.totalShippingFee,
                discountPlatform: detail?.totalDiscountPlatform ? detail?.totalDiscountPlatform : '0',
                discountShop: detail?.totalDiscountShop ? detail?.totalDiscountShop : '0',
                discountAll: detail?.totalDiscountPlatform + detail?.totalDiscountShop ? detail?.totalDiscountPlatform + detail?.totalDiscountShop : '0',
                totalAmount: detail?.totalAmount ? detail?.totalAmount : '0',
                paymentMethod: '',
                transactionId: '',
                totalPaymentFee: detail?.totalPaymentFee ? detail?.totalPaymentFee : '0',
                totalPaymentFeeVat: detail?.totalPaymentFeeVat ? detail?.totalPaymentFeeVat : '0',
                totalPaymentFeeAndVat: detail?.totalPaymentFee + detail?.totalPaymentFeeVat ? detail?.totalPaymentFee + detail?.totalPaymentFeeVat : '0',
                totalRevenue: detail?.totalAmount - (detail?.totalPaymentFee + detail?.totalPaymentFeeVat) ? detail?.totalAmount - (detail?.totalPaymentFee + detail?.totalPaymentFeeVat) : '0',
                totalWithHoldingTax: detail?.totalWithHoldingTax ? detail?.totalWithHoldingTax : '0',
                holdingTaxRevenue: detail?.totalRevenue ? detail?.totalRevenue : '0'
            })
            return {
                header,
                data: dataExcel
            }
        }
    }

    return (
        <React.Fragment>
            <div>
            <H5>รายละเอียดการโอนเงิน</H5>
            <div className="bg-white p-4 d-flex flex-row">
                <div className='d-flex flex-column' style={{whiteSpace: 'pre'}}>
                    <Tooltip>สถานะการโอนเงิน</Tooltip>
                   <div className='d-flex flex-row align-items-center'>
                   <Body1>{detail?.status === 'transferred' ? `โอนเงินให้ร้านค้าแล้ว (${displayDateThShortYear(detail?.transferredDate)})`  : 'รอโอนเงิน'} </Body1>
                   {/* {console.log('detail?.orderWithdraw?.length', detail?.orderWithdraw?.length)}
                   {console.log('detail?.orderWithdraw[detail?.orderWithdraw?.length - 1]', detail?.orderWithdraw[detail?.orderWithdraw?.length - 1])} */}
                    <div className='ml-3'>{detail?.status === 'transferred' && showButtonTransferred(detail?.orderWithdraw[detail?.orderWithdraw?.length - 1])}</div>

                   </div>
                </div>
                <div className='d-flex flex-row justify-content-end align-items-between w-100 transfer-detail-header'>
                    {/* <div className='d-flex flex-column justify-content-start align-items-start justify-content-start align-items-start'>
                        <Tooltip>Transaction number</Tooltip>
                        <Body1 className='flex-start'>{detail?.refNumber}</Body1>
                    </div> */}
                    {/* <div className='d-flex flex-column justify-content-start align-items-start'>
                        <Tooltip>รอบสรุปข้อมูล</Tooltip>
                        <Body1>{displayDateThShortYear(detail?.settledDate)}</Body1>
                    </div> */}
                    {/* <div className='d-flex flex-column justify-content-start align-items-start'>
                        <Tooltip>กำหนดโอนเงินโดยประมาณไม่เกิน</Tooltip>
                        <Body1>{displayDateThShortYear(detail?.settlementPeriod)}</Body1>
                    </div> */}
                    {
                        detail?.status === 'transferred' &&  <div className='d-flex flex-column justify-content-start align-items-start'>
                        <Tooltip>เลขการโอนเงิน</Tooltip>
                        <Body1>{detail?.transferredRef}</Body1>
                    </div>
                    }
                    <div className='d-flex flex-column justify-content-start align-items-start'>
                        <Tooltip>จำนวนรายการ</Tooltip>
                        <Body1>{detail?.orderWithdraw?.length ? detail?.orderWithdraw?.length : 0}</Body1>
                    </div>
                    <div className='d-flex flex-column justify-content-start align-items-start'>
                        <Tooltip>จำนวนเงินที่โอน</Tooltip>
                        <Body1 className='color-success'>{numeral(detail?.totalRevenue ? detail?.totalRevenue : 0).format('0,0.00')}</Body1>
                    </div>
                    {/* <div className='d-flex flex-column justify-content-start align-items-start'>
                        <Tooltip>ค่าธรรมเนียมโอนเงิน</Tooltip>
                        <Body1>{numeral(detail?.totalPaymentFee).format('0,0.00')}</Body1>
                    </div> */}
                </div>
            </div>

            <div className='bg-white'>
                <div className="w-100 mb-4 d-flex flex-row bg-white p-4 justify-content-between mt-4" style={{ gap: '10%' }}>
                    {/* className,width,height,options,placeholder,onChange,onClearSearchShop,value,onBlur,iconClearSearch,name  */}
                    <div className='d-flex flex-row gap-1 align-items-center w-50'>
                        <Body2>ร้านค้า : </Body2>
                        <div className="w-50 cursor-none">
                            <SelectInputWithImg
                                options={detail?.listAllShop}
                                onChange={() => { }}
                                iconClearSearch={false}
                                value={detail?.listAllShop && detail?.listAllShop.findIndex((shop) => shop.label === detail?.shop?.name)}
                                disabled
                                className={'disabled'}
                            />
                        </div>
                        {checkPermTransfer() && detail?.status !== 'transferred' ?
                            <button className="btn-confirm-grid" style={{ minWidth: '10px', maxWidth: '6em' }} onClick={() => setModalDetail({ isOpen: true, data: detail })}><Caption>ยืนยันโอนเงิน</Caption></button> : null}
                    </div>

                    <div className="d-flex flex-row gap-1 align-items-center w-100 justify-content-end">
                        <XlsxCreate
                            handleFetchData={() => fetchDataExcel()}
                            fileName={`${detail?.listAllShop && detail.listAllShop[detail?.listAllShop && detail?.listAllShop.findIndex((shop) => shop.label === detail?.shop?.name)].label ? detail.listAllShop[detail?.listAllShop && detail?.listAllShop.findIndex((shop) => shop.label === detail?.shop?.name)].label : ''}-${detail?.status === 'settled' ? 'รอโอนเงิน' : detail?.status === 'transferred' ? 'โอนเงินให้ร้านค้าแล้ว' : 'รายการทั้งหมด'}-${displayDateThShortYear(new Date(), 'DD-MM-YY')}`}
                            // fileName={'asd'}
                            // headerA1={'รายการรายได้'}
                            numberFormat={'#,0.00'}
                            nonNumberFormat={getUserRole() === 'ACCOUNTANT' ? ['K'] : ['L']}
                            textRight={getUserRole() === 'ACCOUNTANT' ? ['N','O','P','Q','R','S','T','U','V','W','Z','AA','AB','AC','AD','AE'] : ['K','L','M','N','O','P','Q','R','S','T','W','X','Y','Z','AA','AB']}
                        >
                            <button className="btn btn-cancel"><Body1>ดาวน์โหลด Excel</Body1></button>
                        </XlsxCreate>
                    </div>
                </div>
            </div>
            </div>



                <div className='table-transfer-detail-scroll'>
                {getUserRole() === ACCOUNTANT &&
                    <AccountantTransferDetail
                        orderWithdraw={detail?.orderWithdraw}
                        showStatusWithdraw={showStatusWithdraw}
                        transactionData={detail}
                        withdrawList={listWithdraw}
                        showButtonTransferred={showButtonTransferred}
                        transferList={detail?.transferList}
                    />
                    // <div></div>
                }

                {(getUserRole() === SUPER_ADMIN || getUserRole === ADMIN || getUserRole() === OWNER) &&
                    <AdminTransferDetail
                        orderWithdraw={detail?.orderWithdraw}
                        showStatusWithdraw={showStatusWithdraw}
                        transactionData={detail}
                        withdrawList={listWithdraw}
                        showButtonTransferred={showButtonTransferred}
                        transferList={detail?.transferList}
                    />
                }
                </div>
            

            <ModalTransferConfirm
                modalDetail={modalDetail}
                setmodalDetail={setModalDetail}
                getListTransfer={() => getListSellerWithdrawOrder(detail?.id)}
            />


        </React.Fragment>
    )
}

const useHookTransferDetail = (props) => {
    // const { id } = props
    const [listWithdraw, setListWithdraw] = useState(null)
    const [detail, setDetail] = useState(null)
    // const { status, transferredDate, refNumber, settledDate, settlementPeriod, orderWithdraw, totalRevenue, totalPaymentFee, listAllShop,
    //     // fetchDataExcel,
    //     shopSelectedIndex, id, shop, totalItemPrice, totalShippingFee, totalDiscountShippingShop, totalShippingDiscount, totalShippingDiscountShop,
    //     totalDiscountPlatform, totalDiscountShop, totalAmount, totalPaymentFeeVat, transferList,totalWithHoldingTax
    // } = detail


    useEffect(() => {
        (async () => {
            const detailData = await getLocalStorage({ key: window.location.pathname.split("/").pop() })
            if (!detailData) window.location = '/manage/revenue/transfer'
            await getListSellerWithdrawOrder(detailData?.id)
            const { orderWithdraw,...order } = detailData
            // setDetail({...detail,...order})
        })()
    }, [])

    // useEffect(() => {
    //     (async () => {
    //         await getListSellerWithdrawOrder(detail?.id)
    //     })()

    //     return () => {
    //     }
    // }, [])


    const getListSellerWithdrawOrder = async (id) => {
        const res = await withdrawProvider.getOrderWithdrawBySellerWithdrawId({ id })
        setListWithdraw(res.data)
        // setDetail({...detail,status: res.data.orderWithdraws[0].status,transferredDate: res.data.orderWithdraws[0].transferredDate,orderWithdraw: res.data.orderWithdraws})
        const detailData = await getLocalStorage({ key: window.location.pathname.split("/").pop() })
        if(detailData){
            const { orderWithdraw,...order } = detailData
            setDetail({...order,status: res.data.orderWithdraws[0].status,transferredDate: res.data.orderWithdraws[0].transferredDate,orderWithdraw: res.data.orderWithdraws})
        } else {
            setDetail({...detail,status: res.data.orderWithdraws[0].status,transferredDate: res.data.orderWithdraws[0].transferredDate,orderWithdraw: res.data.orderWithdraws})
        }
        const getWithdrawLocal = await getLocalStorage({ key:res.data.orderWithdraws[0].sellerWithdraw.refNumber })
        setLocalStorage({ key: res.data.orderWithdraws[0].sellerWithdraw.refNumber, value: {...getWithdrawLocal,orderWithdraw: res.data.orderWithdraws,status: res.data.orderWithdraws[0].status,transferredDate: res.data.orderWithdraws[0].transferredDate } })
        // // setDetail(res.data.orderWithdraws[0])
        // res.data.count && res.data.orderWithdraws.map(async (item) => {
        //     const getWithdrawLocal = await getLocalStorage({ key: item.sellerWithdraw.refNumber })
        //     console.log('item', item)
        //     console.log('getWithdrawLocal', getWithdrawLocal)
        //     setLocalStorage({ key: item.sellerWithdraw.refNumber, value: {...getWithdrawLocal,orderWithdraw: res.data.orderWithdraws,status: item.status } })
        // })
        return res
    }

    const checkPermTransfer = () => {
        if (getUserRole() === ACCOUNTANT) return true
        return false
    }

    return {
        getListSellerWithdrawOrder,
        listWithdraw,
        checkPermTransfer,
        detail
    }

}

export default TransferOrderDetail