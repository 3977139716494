import promotionProvider from "provider/promotionProvider";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { SET_LOADING } from "redux/actions/app";
import { OPEN_MODAL_MESSAGE_ERROR, 
         OPEN_MODAL_MESSAGE_SUCCESS } from "redux/actions/modalMessage";
import { CREATE_PROMOTION, 
         UPDATE_PROMOTION, 
         SET_KEY_PROMOTION,
         OPEN_MODAL_MESSAGE_PROMOTION, 
         GET_PROMOTION_DETAIL} from "redux/actions/promotion";

export function* handleGetPromotionDetail(action) {
    const { payload } = action;
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } });
        const promotionDetail = yield call(promotionProvider.getPromotionDetail, payload);
        yield put({ type: SET_KEY_PROMOTION, payload: { key: 'promotionDetail', value: promotionDetail.data } })
        yield put({ type: SET_LOADING, payload: { loading: false } });

    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } });
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR });
    }
}

export function* handleCreatePromotion(action) {
    let { payload } = action;
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } });
        const res = yield call(promotionProvider.addPromotion, payload);

        if (res.status === 422 || res.status === 500) 
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'ไม่สามารถทำรายการได้', title: 'iconFail', status: res.status } })
        else 
            yield put({ type: OPEN_MODAL_MESSAGE_PROMOTION, payload: { isOpen: true, message: 'ทำรายการสำเร็จ', title: 'iconSuccess' } })
        
            yield put({ type: SET_LOADING, payload: { loading: false } });
    } catch (error) {

        yield put({ type: SET_LOADING, payload: { loading: false } })
        if (error.response) {
            const {message} = error.response.data;
            if ( message ==='PRODUCT_CODE_ALREADY_EXISTS') 
                 yield put({ type: OPEN_MODAL_MESSAGE_ERROR });
             else 
                yield put({ type: OPEN_MODAL_MESSAGE_ERROR });
                
        } else {
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR });
        }
    }
}

export function* handleUpdatePromotion(action) {
    const { payload } = action;
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } });
        const res = yield call(promotionProvider.updatePromotion, { body: payload.data, id: payload.id });
        if (res.status === 422 || res.status === 500) {
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'ตรวจสอบรหัสอ้างอิงเรียบร้อยแล้ว ไม่สามารถใช้งานรหัสอ้างอิงนี้ได้', title: 'iconFail', } })
        } else {
            yield put({ type: OPEN_MODAL_MESSAGE_PROMOTION, payload: { isOpen: true, message: 'ทำรายการสำเร็จ', title: 'iconSuccess' } })
        }
        yield put({ type: SET_LOADING, payload: { loading: false } });
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } });
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR });
    }

}

export default function* useWatcher() {
    yield takeLatest(CREATE_PROMOTION, handleCreatePromotion)
    yield takeLatest(UPDATE_PROMOTION, handleUpdatePromotion)
    yield takeLatest(GET_PROMOTION_DETAIL, handleGetPromotionDetail)
}