
import classNames from 'classnames'
import React from 'react'
import './styles.scss'

//select font 
// primary-font = baijamjulee
// secondary-font = kanit

export const H4 = (props) => {
    return <span className={classNames(`H4 ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}

export const H5 = (props) => {
    return <span className={classNames(`H5 ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}

export const H6 = (props) => {
    return <span className={classNames(`H6 ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}

export const SubTitle1 = (props) => {
    return <span className={classNames(`sub-title-1 ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}

export const SubTitle2 = (props) => {
    return <span className={classNames(`sub-title-2 ${props.className || ''} `)} style={props.style} onClick={props.onClick}> {props.children} </span>
}

export const Body2 = (props) => {
    return <span className={classNames(`body-2 ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}

export const Caption = (props) => {
    return <span className={classNames(`caption ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}

export const SubCaption = (props) => {
    return <span className={classNames(`sub-caption ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}

export const Tooltip = (props) => {
    return <span className={classNames(`font-tooltip ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}
export const SubTooltip = (props) => {
    return <span className={classNames(`font-sub-tooltip ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}
export const H1 = (props) => {
    return <span className={classNames(`H1 ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}
export const H2 = (props) => {
    return <span className={classNames(`H2 ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}
export const H3 = (props) => {
    return <span className={classNames(`H3 ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}
export const Body1 = (props) => {
    return <span className={classNames(`body-1 ${props.className || ''}`)} style={props.style} onClick={props.onClick}> {props.children} </span>
}

export const LinkLabel = (props) => {
    return <span className={classNames('link', props.className || '')} style={props.style} onClick={props.onClick}>{props.children}</span>;
};

export const CardTitle = (props) => {
    return <span className="card-title">{props.children}</span>
}

export const CardDetail = (props) => {
    return <span className="card-detail">{props.children}</span>
}

export const Require = (props) => {
    return <span className="input-require">*</span>
}

export const InputTitle = (props) => {
    return <span className="input-title"> {props.children} </span>
}

export const InputSubTitle = (props) => {
    return <span className="input-sub-title"> {props.children} </span>
}


