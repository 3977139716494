import React from 'react';

const ImageComponent = ({ src, style, className, onClick, alt }) => {
  return (
    <img
      src={src}
      style={style}
      className={className}
      onClick={onClick}
      alt={alt}
    />
  );
};

ImageComponent.defaultProps = {
    src:{},
    style:{},
    className:'',
    onClick: () => {},
    alt:''
}

export default ImageComponent;