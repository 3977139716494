export const GET_ALL_ANNOUNCES = "GET_ALL_ANNOUNCES";
export const GET_ANNOUNCE = "GET_ANNOUNCE";
export const GET_DISPLAY_ANNOUNCE = "GET_DISPLAY_ANNOUNCE";
export const OPEN_MODAL_DISPLAY_ANNOUNCE = "OPEN_MODAL_DISPLAY_ANNOUNCE";
export const CREATE_ANNOUNCE = "CREATE_ANNOUNCE";
export const EDIT_ANNOUNCE = "EDIT_ANNOUNCE";
export const DELETE_ANNOUNCE = "DELETE_ANNOUNCE";
export const SET_KEY_ANNOUNCE = "SET_KEY_ANNOUNCE";
export const SET_PAGINATION = "SET_PAGINATION";

export function dispatchAnnounce(param) {
  return {
    type: param.type,
    payload: param.payload,
  };
}
