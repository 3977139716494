import React, { Fragment } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import AddBrand from './pages/AddBrand'
import {
  ADMIN,
  // SUPER_ADMIN, ADMIN, 
  OWNER,
  SUPER_ADMIN,
  ACCOUNTANT } from './layout/Sidebar/Role';

import configureStore from './redux/store'
import Full from './layout/Full'
import Login from './pages/Login'
import CreateLive from './pages/CreateLive'
import ManageLive from './pages/ManageLive'
import ShopDetail from './pages/ShopDetail'
import AddShop from './pages/AddShop'
import Product from './pages/Product'
// import addProduct from './pages/addProduct'
import Order from './pages/Order'
import Dashboard from './pages/Dashboard'
import Withdraw from './pages/Withdraw'
import Upload from './pages/Upload'
import {
  Chat,
  ChatSummary,
  Gallery,
  Albums,
  Media,
  // Playlists,
  // PlaylistsItems,
  // PlaylistFrontPage,
  PlaylistFrontPageItems,
} from './pages'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import './styles/base.scss'
import 'antd/dist/antd.css'
import 'react-image-crop/lib/ReactCrop.scss'
import Cookies from 'js-cookie'
import { getUserRole } from 'helper/utils'
import permission from 'config/permission'
import Promocode from 'pages/Promocode';
// import AddPromocode from 'pages/AddPromocode';
import AddLogisticPrice from 'pages/AddLogisticPrice'
import Ribbon from 'pages/Ribbon'
import AddCoupon from 'pages/AddCoupon'
import Mall from 'pages/Mall'
import AddMall from 'pages/AddMall'
import PlaylistCategories from 'pages/PlaylistCategories'
import { createBrowserHistory } from "history";
import CreateBroadcast from 'pages/CreateBroadcast'
import Broadcast from 'pages/Broadcast'
import PlaylistItem from 'pages/PlaylistItem'
import PlaylistMall from './pages/PlaylistMall'
import PlaylistPlatform from './pages/PlaylistPlatform'
import PlaylistFrontPageMall from './pages/PlaylistFrontPageMall'
import PlaylistFrontPagePlatform from './pages/PlaylistFrontPagePlatform'
import OrderOntheway from 'pages/OrderOntheway'
import Theme from 'styles/Theme'
import OrderReport from 'pages/OrderReport'
import ChangePassword from 'pages/ChangePassword'
import PrivateRoute from './PrivateRoute'
import PromotionDelivery from 'pages/PromotionDelivery'
import AddPromotion from 'pages/AddPromotion'
import Promotion from 'pages/Promotion'
import TransferDetail from 'pages/TransferDetail'
import TransferOrderDetail from 'pages/TransferOrderDetail'
import SummaryOrder from 'pages/SummaryOrder'
import VerifyStaff from 'pages/VerifyStaff'
import CreateUserStaff from 'pages/CreateUserStaff'
import MassUploadExcel from 'pages/MassUploadExcel'
import MassUploadTracking from 'pages/MassUploadTracking'
import Alive from 'pages/Alive'
import Announce from 'pages/Announce'
import AddAnnounce from 'pages/AddAnnounce'
import TopViewer from './pages/TopViewer'
import MassUploadExcelEdit from 'pages/MassUploadExcelEdit'
import TableEditProductExcel from 'components/TableEditProductExcel'
import ModalEditProductExcel from 'components/ModalEditProductExcel'

const history = createBrowserHistory();
export let isAuth = false
export let RoleUser = null
export const getToken = Cookies.get('token')
//check token
if (getToken !== undefined) {
  isAuth = true
} else {
  isAuth = false
}
////check role
// const getUser = Cookies.get('user')
// if (getUser != undefined) {
//   const user = JSON.parse(getUser)
//   ////// cookies.user.shopId != null ? brand : superadmin
//   if (user['shopId']) {
//     RoleUser = 'brand'
//   } else {
//     RoleUser = 'superadmin'
//   }
//   Cookies.set('role', RoleUser)
// }

const Home = () => {
  return (
    <Fragment>
    <Switch>
      <Full>
      {/* <PrivateRoute
                exact
                path='/'
                component={() => window.location.replace('/manage/dashboard')}
                permission={[ADMIN,SUPER_ADMIN,OWNER]}
              /> */}
             <PrivateRoute
                exact
                path='/manage/shop/shopdetail/managelive'
                component={ManageLive}
                role={[ADMIN,SUPER_ADMIN,OWNER]}
                permission={[ADMIN,SUPER_ADMIN,OWNER]}
              />
              <PrivateRoute
                exact
                path='/manage/shop/shopdetail'
                component={ShopDetail}
                permission={[ADMIN,SUPER_ADMIN,OWNER]}
              />
              <PrivateRoute
                exact
                path='/manage/shop/shopdetail/createlive'
                component={CreateLive}
                permission={[ADMIN,SUPER_ADMIN,OWNER]}
              />
              <PrivateRoute
                exact
                path='/manage/shop/shopdetail/addlogisticprice'
                component={AddLogisticPrice}
                permission={[ADMIN,SUPER_ADMIN,OWNER]}
              />
              <PrivateRoute exact path='/manage/user/create-staff' component={CreateUserStaff} permission={[SUPER_ADMIN]} />
              <PrivateRoute exact path='/manage/shop/addshop' component={AddShop} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/shop/shopdetail/editlive' component={CreateLive} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/shop/shopdetail/editvod' component={Upload} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/shop/shopdetail/upload' component={Upload} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/shop/shopdetail/addbrand' component={AddBrand} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/product' component={Product} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/order/order-ontheway' component={OrderOntheway} permission={[ADMIN,SUPER_ADMIN,OWNER,ACCOUNTANT]} />
              <PrivateRoute exact path='/manage/order/all/create/mass-upload-tracking' component={MassUploadTracking} permission={[OWNER]} />
              <PrivateRoute exact path='/manage/order/all' component={Order} permission={[ADMIN,SUPER_ADMIN,OWNER,ACCOUNTANT]} />
              <PrivateRoute exact path='/manage/order/all/:slug' component={Order} permission={[ADMIN,SUPER_ADMIN,OWNER,ACCOUNTANT]} />
              <PrivateRoute exact path='/manage/order/summary-order' component={SummaryOrder} permission={[ADMIN,SUPER_ADMIN,OWNER,ACCOUNTANT]} />
              <PrivateRoute exact path='/manage/dashboard/orderReport' component={OrderReport} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/dashboard/top-viewer' component={TopViewer} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/dashboard' component={Dashboard} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/revenue/withdraw' component={Withdraw} permission={[ADMIN,SUPER_ADMIN,OWNER, ACCOUNTANT]} />
              <PrivateRoute exact path='/manage/revenue/transfer' component={TransferDetail} permission={[ADMIN,SUPER_ADMIN,OWNER, ACCOUNTANT]} />
              <PrivateRoute exact path='/manage/revenue/transfer/:refNumber' component={TransferOrderDetail} permission={[ADMIN,SUPER_ADMIN,OWNER, ACCOUNTANT]} />
              <PrivateRoute exact path='/manage/playlists' component={getUserRole() === OWNER ? PlaylistMall: PlaylistPlatform} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/platform' component={PlaylistPlatform} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/mall' component={PlaylistMall} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/platform/live-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/platform/next-live-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/platform/vod-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/platform/shop-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/platform/product-digital-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/platform/product-general-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/platform/category-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/platform/shop-coupon-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/platform/coupon-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/mall/live-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/mall/next-live-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/mall/vod-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/mall/shop-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/mall/product-digital-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/mall/product-general-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/mall/category-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/mall/shop-coupon-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/mall/coupon-items' component={PlaylistItem} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/ribbon' component={Ribbon} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/shop' component={Mall} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/shop/addmall' component={AddMall} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/playlists/categories' component={PlaylistCategories} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/broadcast/createbroadcast' component={CreateBroadcast} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/broadcast' component={Broadcast} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/manage/product/mass-upload' component={MassUploadExcel} permission={[OWNER]} />
              <PrivateRoute exact path='/manage/product/mass-upload-edit' component={MassUploadExcelEdit} permission={[OWNER]} />
              <Switch>
                <PrivateRoute exact path='/manage/promocode/mall/' component={Promocode} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
                <PrivateRoute exact path='/manage/promocode/platform/' component={Promocode} permission={[ADMIN,SUPER_ADMIN]} />
                <PrivateRoute exact path='/manage/promocode/' component={Promocode} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
                <PrivateRoute exact path='/manage/promocode/platform/addcoupon' component={AddCoupon} permission={[ADMIN,SUPER_ADMIN]} />
                <PrivateRoute exact path='/manage/promocode/platform/editcoupon' component={AddCoupon} permission={[ADMIN,SUPER_ADMIN]} />
                <PrivateRoute exact path='/manage/promocode/mall/addcoupon' component={AddCoupon} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
                <PrivateRoute exact path='/manage/promocode/mall/editcoupon' component={AddCoupon} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
                <PrivateRoute exact path='/manage/promocode/addcoupon' component={AddCoupon} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
                <PrivateRoute exact path='/manage/promocode/editcoupon' component={AddCoupon} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
                <PrivateRoute exact path='/manage/promocode/mall/:status' component={Promocode} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
                <PrivateRoute exact path='/manage/promocode/platform/:status' component={Promocode} permission={[ADMIN,SUPER_ADMIN]} />
                <PrivateRoute exact path='/manage/promocode/:status' component={Promocode} permission={[ADMIN,SUPER_ADMIN,OWNER]} />

                <PrivateRoute exact path='/manage/promotion' component={PromotionDelivery} permission={[ADMIN,SUPER_ADMIN,OWNER]} />



              </Switch>

              <Switch>
                <PrivateRoute exact path='/manage/announce' component={Announce} permission={[ADMIN, SUPER_ADMIN]} />
                <PrivateRoute exact path='/manage/announce/add-announce' component={AddAnnounce} permission={[ADMIN, SUPER_ADMIN]} />
                <PrivateRoute exact path='/manage/announce/edit-announce/:announceid' component={AddAnnounce} permission={[ADMIN, SUPER_ADMIN]} />
                <PrivateRoute exact path='/manage/announce/edit-announce' component={AddAnnounce} permission={[ADMIN, SUPER_ADMIN]} />
              </Switch>

              <Switch>
                <PrivateRoute exact path='/manage/promotion/' component={Promotion} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
                <PrivateRoute exact path='/manage/promotion/addpromotion' component={AddPromotion} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
                <PrivateRoute exact path='/manage/promotion/editpromotion' component={AddPromotion} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              </Switch>
              <PrivateRoute exact name='listFrontPage' path='/manage/playlist-front-page' component={PlaylistFrontPagePlatform} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact name='listFrontPage' path='/manage/playlist-front-page/platform' component={PlaylistFrontPagePlatform} permission={[ADMIN,SUPER_ADMIN,OWNER]} /> 
              <PrivateRoute exact name='listFrontPage' path='/manage/playlist-front-page/mall' component={PlaylistFrontPageMall} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact name='playlistSection' path='/manage/playlist-front-page/platform/page' component={PlaylistFrontPageItems} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact name='playlistSection' path='/manage/playlist-front-page/mall/page' component={PlaylistFrontPageItems} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute
                exact
                name='playlistSection'
                path='/manage/playlist-front-page/page'
                component={PlaylistFrontPageItems}
                permission={[ADMIN,SUPER_ADMIN,OWNER]}
              />

              <PrivateRoute exact path='/chat' component={Chat} permission={[ADMIN,SUPER_ADMIN,OWNER]}/>


              <PrivateRoute exact path='/chat-summary' component={ChatSummary} permission={[ADMIN,SUPER_ADMIN,OWNER]}/>


              <PrivateRoute exact path='/gallery' component={Gallery} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/gallery/albums' component={Albums} permission={[ADMIN,SUPER_ADMIN,OWNER]} />
              <PrivateRoute exact path='/gallery/albums/media' component={Media} permission={[ADMIN,SUPER_ADMIN,OWNER]} />



             

      </Full>
    </Switch>
    </Fragment>
  )
}



ReactDOM.render(
  <React.Fragment>
    <div className='root-container'>
    <Theme>
      <Provider store={configureStore}>
        <Router history={history}>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route path='/d66b6c3371873da6568f755d2824b20cc534751e0d107b09cd3dee8eb9008c6b/alive' component={Alive} />
            <Route path='/verify-staff/:key/email/:email' component={VerifyStaff} />
            <Route path='/changepassword/:id/email/:email' component={ChangePassword} />
            <Route exact path='/' component={() => window.location = '/manage/dashboard'} />
            <Route path='/manage' component={Home} />
            <Route path='/user' component={Home} />
            <Route path='/chat' component={Home} />
            <Route path='/gallery' component={Home} />
            <Route path='/chat-summary' component={Home} />
            <Route path='/test' component={ModalEditProductExcel} />
          <Route path='/test1' component={TableEditProductExcel} />
            <Route component={() => <div>Not Found Page</div>} />
          </Switch>
        </Router>
      </Provider>
      </Theme>
    </div>
  </React.Fragment>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
