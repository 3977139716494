import React, { Component } from "react";
import { connect } from "react-redux";
import './index.scss'
import host from 'config/host'

import { ButtonUpload, 
  // MediaCard
 } from 'components/output';
// import {
//     dispatchGallery,
//     SET_MEDIA_UPLOAD_EVENT,
//     SET_GALLERY_FIELD,
//   } from 'redux/actions/gallery';

import IconClose from 'images/icons/close-image.svg'

const initialState = {};

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  render() {
    const {imageCollection,
           currentShopId, 
           collectionName,
           limitImageUpload} = this.props;
    return (
      <React.Fragment>
        <div className="image-container">
         { imageCollection?.length > 0 ? (
           <React.Fragment>
                {imageCollection.map((image,index) => {
                return <React.Fragment>
                  <div className='image-item'>
                      <img className='image-preview' src={host.avatar + image.path} alt='img-upload'/>
                      <div className='middle' 
                          onClick={() => this.props.onClickRemoveMedia( index, collectionName ) } >
                          <img src={IconClose} className='float-icon' alt='icon-close'/>
                      </div>
                  </div>
                </React.Fragment>
            })} 
            </React.Fragment>
            ) : (
            <ButtonUpload
                width='12em'
                height='12em'
                type={'image'}
                shopDetail={{ shopId: currentShopId }}
                disabled={!currentShopId}
                fieldUpload={collectionName}
                limitImageUpload={limitImageUpload}
            />
        )}

          { limitImageUpload > 1 && imageCollection?.length > 0 &&
            <ButtonUpload
                width='12em'
                height='12em'
                type={'image'}
                shopDetail={{ shopId: currentShopId }}
                disabled={!currentShopId}
                fieldUpload={collectionName}
                limitImageUpload={limitImageUpload}
            />
          }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(ImageUpload);
