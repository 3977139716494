import { displayDateTh } from 'helper/timeUtil'
import moment from "moment";

export const options = {
    REAL_TIME: {key: 'REAL_TIME', label: 'Real-time'},
    YESTERDAY: {key: 'YESTERDAY', label: 'เมื่อวาน'},
    PAST_SEVEN: {key: 'PAST_SEVEN', label: 'ย้อนหลัง 7 วัน'},
    PAST_THIRTY: {key: 'PAST_THIRTY', label: 'ย้อนหลัง 30 วัน'},
    WITHIN_DAY: {key: 'WITHIN_DAY', label: 'ภายในวันที่'},
    WITHIN_WEEK: {key: 'WITHIN_WEEK', label: 'ภายในอาทิตย์'},
    WITHIN_MONTH: {key: 'WITHIN_MONTH', label: 'ภายในเดือน'},
    WITHIN_YEAR: {key: 'WITHIN_YEAR', label: 'ภายในปี'},
    MANUAL: {key: 'MANUAL', label: 'กำหนดเอง'},
}

export const generatePeriodDate = (periodType, periodDate, allowTodayDate=true) => {
    const MONTHS_SHORT = ["ม.ค", "ก.พ.", "มี.ค", "เม.ย.", "พ.ค", "มิ.ย.", "ก.ค", "ส.ค.", "ก.ย", "ต.ค.", "พ.ย", "ธ.ค."]
    let periodItem = {};
    let label = '';

    // Period part
    if(periodType?.key === options.REAL_TIME.key){
         periodItem = {from: moment().startOf('day'), 
                       to: moment()}
         label= `วันนี้จนถึง ${moment().format('HH:mm')}`
     }
     if(periodType?.key === options.YESTERDAY.key) {
         periodItem = {from: moment().subtract(1, "days").startOf('day'),
                       to: moment().subtract(1, "days").endOf('day')}
         label= displayDateTh(moment().subtract(1, "days"), 'D MMM YYYY')
     }
     if(periodType?.key === options.PAST_SEVEN.key) {
            periodItem = {from: moment().subtract(7, "days").startOf('day'), 
                        to: moment().subtract(1, "days").endOf('day')}
            label= `${displayDateTh(moment().subtract(7, "days"), 'D MMM YYYY')} - ${displayDateTh(moment().subtract(1, "days"), 'D MMM YYYY')}`
     } 
     if(periodType?.key === options.PAST_THIRTY.key) {
            periodItem = {from: moment().subtract(31, "days").startOf('day'), 
                          to: moment().subtract(1, "days").endOf('day')}
            label= `${displayDateTh(moment().subtract(31, "days"), 'D MMM YYYY')} - ${displayDateTh(moment().subtract(1, "days"), 'D MMM YYYY')}`
    } 
     // Calendar part
    if(periodType?.key === options.WITHIN_DAY.key) {
         label = `${displayDateTh(moment(periodDate?.startDate ), 'D MMM YYYY')}`;
         periodItem = {from: periodDate?.startDate?.clone().startOf('day'),
                         to: periodDate?.startDate?.clone().endOf('day')}
    }
    if(periodType?.key === options.WITHIN_WEEK.key) {
         const newPeriodDate = [periodDate?.startDate?.clone().startOf('week'), periodDate?.startDate?.clone().endOf('week')];
         label = `${displayDateTh(newPeriodDate[0], 'D MMM YYYY')} - ${displayDateTh(newPeriodDate[1], 'D MMM YYYY')}`
         periodItem = {from: newPeriodDate[0]?.clone().startOf('day'),
                         to: newPeriodDate[1]?.clone().endOf('day')}

         const isSameWeek = moment().startOf('week').isSame(periodDate?.startDate?.clone().startOf('week'), 'day');
         if(isSameWeek ===true) {
             if(allowTodayDate === false) 
                periodItem.to = moment().subtract(1, "days");
             else
                periodItem.to = moment().endOf('day');
         }
     }
    if(periodType?.key === options.WITHIN_YEAR.key) {
         const year = Number(moment(periodDate?.startDate).format('YYYY'));
         label = `${(year+543)}`
         periodItem = {from: periodDate?.startDate?.clone().startOf('year'),
                       to: periodDate?.startDate?.clone().endOf('year')}

         const isSameYear = moment().isSame(periodDate?.startDate, 'year'); 
         if(isSameYear === true) {
             if(allowTodayDate === false) 
                 periodItem.to = moment().subtract(1, "days").endOf('day');
            else
                periodItem.to = moment().endOf('day');
         }

    }
    if(periodType?.key === options.WITHIN_MONTH.key) {
         const month = Number(moment(periodDate?.startDate).format('MM'));
         const year = Number(moment(periodDate?.startDate).format('YYYY'));
         label = `${MONTHS_SHORT[month-1]} ${year+543}`;
         periodItem = {from: periodDate?.startDate?.clone().startOf('month'),
                       to: periodDate?.startDate?.clone().endOf('month')}
         
         const isSameMonth = moment().isSame(periodDate?.startDate, 'month');
         if(isSameMonth === true) {
             if(allowTodayDate === false) 
                 periodItem.to = moment().subtract(1, "days").endOf('day');
             else 
                 periodItem.to = moment().endOf('day');
         }
    }
    if(periodType?.key === options.MANUAL.key) {
            label = `${displayDateTh(moment(periodDate?.startDate), 'D MMM YYYY')} - ${displayDateTh(moment(periodDate?.endDate), 'D MMM YYYY')}`;
            periodItem = {from: periodDate?.startDate?.clone().startOf('day'),
                            to: periodDate?.endDate?.clone().endOf('day')}
    }

    return  { 
        type: periodType,
        period: periodItem,
        label: label
    };
}