import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.scss";
import { Body1, Caption, H5, H6, SubTitle2, Tooltip } from "components/FontStyle";
import ModalAlert from 'components/modalAlert/ModalAlert'
import ModalConfirm from 'components/modalConfirm/ModalConfirm'
import RadioComponent from "components/RadioButton";


import { dispatchCategories, ADD_KEY_VALUE_CATEGORIES, FETCH_CATEGORIES_BY_SYSTEM, FETCH_CATEGORIES_BY_MANUAL, CREATE_CATEGORIES, UPDATE_CATEGORIES, SET_LEVEL_CATEGORIES, SET_MODAL_CATEGORY, FETCH_CATEGORY_ALL_LEVEL, GET_CATEGORY_ALL, DELETE_CATEGORIES, SET_CATEGORY_SHOP } from "redux/actions/categories"
import ModalCategory from "components/ModalCategory";
import ToastNotification from "components/ToastNotification";
import CategoryWidget from "../CategoryWidget";


/* -------------------------------------------------------------------------- */
/*                               main component                               */
/* -------------------------------------------------------------------------- */


const ProductCategory = (props) => {
  const { value,currentTab } = props;
  const {
    categoryLevel1,
    categoryLevel2,
    categoryLevel3,
    selectCategoryLevel1,
    selectCategoryLevel2,
    selectCategoryLevel3,

    modalConfirmCategory,
    handleIsClose,
    handleSubmit,
    deleteCategoryItem,
    submitCategory,
    initialCategoryType,
    categoryType, /* "default", "custom" */
    isShowModalCategory,
    handleSelectCategoryType,
    handleSubmitCategory,
    handleClickCategoryItem,
    handleAddCategory,
    modalCategory,
  } = useProductCategory(props) || {};


  return (
    <div id="product-category">
      <div className="category-container">
        {/* type option */}
        <div className="section-container">
          <H6>การใช้งานหมวดหมู่</H6>

          <div className="cover-category-type">
            {initialCategoryType.map((item, index) => (
              <div key={index}>
                <RadioComponent
                  name={item?.name}
                  value={item?.name}
                  valueOption={categoryType}
                  onChange={(e) => handleSelectCategoryType(e)}
                />
                <span>{item?.label}</span>
              </div>
            ))}
          </div>
        </div>

        {/* product category */}
        <div className="section-container">
          <H6>{ categoryType === 'default' ? "หมวดหมู่จากระบบ" : "ตั้งค่าหมวดหมู่แบบกำหนดเอง"}</H6>
          <CategoryWidget
            categoryType={categoryType}
            listLevel1={categoryLevel1 || []}
            listLevel2={categoryLevel2 || []}
            listLevel3={categoryLevel3 || []}
            listSelectLevelById={
              [
                selectCategoryLevel1?.id,
                selectCategoryLevel2?.id,
                selectCategoryLevel3?.id
              ]
            }
            callbackClickCategoryItem={handleClickCategoryItem}
            callbackAddCategory={(level) => handleAddCategory(level)}
          />
        </div>

        {/* footer */}
        <div className="section-container footer">
          <Body1 className="color-info-main">เมื่อมีการเปลี่ยนแปลงการใช้งานหมวดหมู่ จากระบบเป็นกำหนดเอง ร้านค้าต้องอัปเดตหมวดหมู่ ในหน้าสินค้าอีกครั้ง</Body1>
          <button className="btn-update-categoryType" onClick={submitCategory}>
            ใช้การตั้งค่านี้
          </button>
        </div>
      </div>
      <ModalAlert />
      <ModalConfirm
        isOpen={modalConfirmCategory?.isOpen}
        message={modalConfirmCategory?.message}
        btnConfirm={"ยืนยัน"}
        btnCancel={"ยกเลิก"}
        confirm={handleSubmit}
        isClose={handleIsClose}
      />

      <ToastNotification />






      <ModalCategory
        isShowModal={isShowModalCategory}
        handleSubmitCategory={(data, actionType) => handleSubmitCategory(data, actionType)}
        actionType={modalCategory?.type != 'delete' ? modalCategory?.type : 'add'}
        title={modalCategory?.level == 1 ? 'เพิ่มหมวดหมู่' : 'เพิ่มหมวดหมู่ย่อย'}
      />
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const useProductCategory = (props) => {
  const { value, currentTab } = props;
  const dispatch = useDispatch();
  const categoryState = useSelector(state => state.categories);
  const { modalConfirmCategory, categoryType, isShowModalCategory, modalCategory,
    categoryLevel1,
    categoryLevel2,
    categoryLevel3,
    selectCategoryLevel1,
    selectCategoryLevel2,
    selectCategoryLevel3,
  } = categoryState || {}
  const { param } = modalConfirmCategory || {}

  const { shopDetail = null, currentShop = null } = useSelector(state => state.shop);

  useEffect(() => {
    dispatch(
      dispatchCategories({
        type: SET_LEVEL_CATEGORIES,
        payload: {
          categoryType: '',
          /* initialState */
          modalCategory: {
            type: 'add',
            level: 1,
            index: 0,
          },
          selectCategoryLevel1: null,
          selectCategoryLevel2: null,
          selectCategoryLevel3: null,
          /* initialState */
        },
      })
    );

    dispatch(dispatchCategories({
      type: FETCH_CATEGORY_ALL_LEVEL,
    }))

  }, [currentTab]);


  useEffect(() => {
    if(currentShop || shopDetail) {
    dispatch(dispatchCategories({
      type: FETCH_CATEGORY_ALL_LEVEL,
    }))
  }
  }, [categoryType, currentShop, shopDetail]);


  useEffect(() => {
    if (shopDetail) {
      dispatch(dispatchCategories({
        type: ADD_KEY_VALUE_CATEGORIES,
        payload: {
          key: 'categoryType',
          value: shopDetail?.categoryType ? shopDetail?.categoryType : 'default'
        }
      }))
    }

  }, [shopDetail])




  const initialCategoryType = [
    {
      name: "default",
      label: "หมวดหมู่จากระบบ",
    },
    {
      name: "custom",
      label: "กำหนดเอง",
    },
  ];

  const submitCategory = () => {
    dispatch(dispatchCategories({
      type: ADD_KEY_VALUE_CATEGORIES, payload: {
        key: "modalConfirmCategory",
        value: {
          isOpen: true,
          message: "คุณแน่ใจหรือไม่ที่จะใช้การตั้งค่านี้กับสินค้าในร้าน",
          action: "submit",
          param: { categoryId:0 }
        }
      }
    }))
  }

  const deleteCategoryItem = (categoryId) => {
    dispatch(dispatchCategories({
      type: ADD_KEY_VALUE_CATEGORIES, payload: {
        key: "modalConfirmCategory",
        value: {
          isOpen: true,
          message: "กรุณายืนยันการลบหมวดหมู่",
          action: "delete",
          param: { categoryId }
        }
      }
    }))
  }

  const handleSubmit = () => {
    switch (modalConfirmCategory?.action) {
      case "submit": return handleSubmitSetting();
      case "delete": return handleDeleteCategoryItem();
      default: return () => { }
    }
  }

  const handleSubmitSetting = () => {
    dispatch(
      dispatchCategories({
        type: SET_CATEGORY_SHOP,
        payload: {},
      })
    );
    handleIsClose();
  }

  const handleDeleteCategoryItem = () => {
    dispatch(
      dispatchCategories({
        type: DELETE_CATEGORIES,
        payload: { categoryId: modalConfirmCategory?.param?.categoryId },
      })
    );
    handleIsClose();
  }

  const handleIsClose = () => {
    dispatch(dispatchCategories({
      type: ADD_KEY_VALUE_CATEGORIES, payload: {
        key: "modalConfirmCategory",
        value: {
          ...modalConfirmCategory,
          isOpen: false
        }
      }
    }))
  }

  const handleSelectCategoryType = (e) => {
    const { name } = e.target
    dispatch(
      dispatchCategories({
        type: SET_LEVEL_CATEGORIES,
        payload: {
          categoryType: name,
          /* initialState */
          modalCategory: {
            type: 'add',
            level: 1,
            index: 0,
          },
          selectCategoryLevel1: null,
          selectCategoryLevel2: null,
          selectCategoryLevel3: null,
          /* initialState */
        },
      })
    );
  }

  const handleSubmitCategory = (value, actionType = "") => {
    if (actionType == "add") {
      dispatch(dispatchCategories({
        type: CREATE_CATEGORIES,
        payload: {
          data: value,
          categoryLevel: modalCategory.level
        }
      }))
    } else {
      dispatch(dispatchCategories({
        type: UPDATE_CATEGORIES,
        payload: {
          ...value
        }
      }))
    }
  }


  const onSetCategory = (level, index, type, isShowModal = false) => {
    const data = level == 1 ? categoryLevel1?.[index]
      : level == 2 ? categoryLevel2?.[index]
        : level == 3 ? categoryLevel3?.[index] : null

    dispatch(
      dispatchCategories({
        type: SET_MODAL_CATEGORY,
        payload: {
          isShowModalCategory: isShowModal,
          modalCategory: {
            type: type,
            level: level,
            index: index,
          },
          selectCategoryLevel: {
            key: `selectCategoryLevel${level}`,
            value: data,
          },
        },
      })
    );

    if(type != 'add') {
      dispatch(
        dispatchCategories({
          type: GET_CATEGORY_ALL,
          payload: {
            level: level,
          },
        })
      );
    }


  }

  
  const handleClickCategoryItem = (level, index, type) => {

    const getCategoryIdSelected = () => {
      switch (level) {
        case 1: return categoryLevel1[index].id;
        case 2: return categoryLevel2[index].id;
        case 3: return categoryLevel3[index].id;
      }
    }

    switch (type) {
      case 'edit':
        onSetCategory(level, index, type, true)
        break;
      case 'delete':
        onSetCategory(level, index, type)
        deleteCategoryItem(getCategoryIdSelected());
        break;
      default:
        onSetCategory(level, index, type)
        break;
    }
  }

  const handleAddCategory = (level) => {
    onSetCategory(level, null, 'add', true)
  }



  return {
    categoryLevel1,
    categoryLevel2,
    categoryLevel3,
    selectCategoryLevel1,
    selectCategoryLevel2,
    selectCategoryLevel3,

    modalConfirmCategory,
    handleIsClose,
    handleSubmit,
    deleteCategoryItem,
    submitCategory,
    initialCategoryType,
    categoryType,
    handleSelectCategoryType,
    isShowModalCategory,
    handleSubmitCategory,
    handleClickCategoryItem,
    handleAddCategory,
    modalCategory,
  };
};

export default ProductCategory;


