import React from 'react';
import './categoryWidget.scss'
import EmptyComponent from '../EmptyComponent';
import ButtonAdd from '../output/ButtonAdd/index.js';
import CategoryItem from '../ProductCategory/CategoryItem';

const CategoryWidget = (
    props = {}
) => {

    const {
        categoryType = 'default',
        listLevel1 = [...Array(0)],
        listLevel2 = [...Array(0)],
        listLevel3 = [...Array(0)],
        listSelectLevelById = [null, null, null],
        callbackClickCategoryItem = null,
        callbackAddCategory = null,
    } = props || {};

    /* Set Default */

    // categoryType = { 'default' }
    // listLevel1 = { [...Array(0)] }
    // listLevel2 = { [...Array(0)] }
    // listLevel3 = { [...Array(0)] }
    // listSelectLevelById = { [null, null, null]}
    // callbackClickCategoryItem = { null}
    // callbackAddCategory = { null}

    /* Set Default */


    const isTypeDefault = categoryType == 'default'

    const handleClickItem = (level, index, type) => {
        /* 
        level: 1 || 2 || 3 ,
        index: 0-n,
        type: 'select' || 'edit' || 'delete' 
        */
        callbackClickCategoryItem(level, index, type)
    }

    const addCategory = (level) => {
        /* level: 1 || 2 || 3 , */
        callbackAddCategory(level)
    }



    const ContentRender = () => {

        if (listLevel1?.length <= 0 && listLevel2?.length <= 0 && listLevel3?.length <= 0) {
            return (
                <div className='wrapper-inner-category-widget'>
                    <div
                        className={[
                            'content-category-widget content-empty-category-widget',
                            isTypeDefault ? 'isTypeDefault' : '',
                        ].join(' ')}
                    >
                        <EmptyComponent bgColor="transparent" />
                    </div>
                    {!isTypeDefault && (
                        <div className='footer-category-widget footer-empty-category-widget'>
                            <ButtonAdd
                                label={'เพิ่มหมวดหมู่'}
                                handleAdd={() => addCategory(1)}
                            />
                        </div>
                    )}
                </div>
            )
        } else {
            return (
                <div className='wrapper-inner-category-widget'>
                    <div
                        className={[
                            'content-category-widget',
                            isTypeDefault ? 'isTypeDefault' : '',
                        ].join(' ')}
                    >
                        {[
                            { list: [...listLevel1] },
                            { list: [...listLevel2] },
                            { list: [...listLevel3] },
                        ]?.map((itemList, indexList) => {
                            let level = indexList + 1
                            return (
                                <div
                                    key={`level-list-${indexList}`}
                                    className={[
                                        'content-level-category-widget',
                                        isTypeDefault ? 'isTypeDefault' : '',
                                        level==3 ? 'isHideIconArrow' : '',
                                    ].join(' ')}
                                >
                                    {[...itemList?.list]?.map((item, index) => {
                                        let nameCategory = item?.name || ''
                                        let isSelectCategory = listSelectLevelById[indexList] >= 0 ? item?.id == listSelectLevelById[indexList] : index == 0
                                        return (
                                            <div
                                                key={`level${level}-item-${index}`}
                                                role='button'
                                            >
                                                <CategoryItem
                                                    name={nameCategory}
                                                    isSelected={isSelectCategory}
                                                    onSelectItem={() => handleClickItem(level, index, 'select')}
                                                    onUpdate={() => handleClickItem(level, index, 'edit')}
                                                    onDelete={() => handleClickItem(level, index, 'delete')}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                    {!isTypeDefault && (
                        <div className='footer-category-widget'>
                            {[...Array(3)].map((item, index) => {
                                let disableButton = false
                                if (
                                    listLevel1?.length > 0
                                    && listLevel2?.length <= 0
                                    && index + 1 == 3
                                ) {
                                    disableButton = true
                                }

                                return (
                                    <div
                                        key={`btn-add-category-${index}`}
                                        className={[
                                            'footer-level-category-widget',
                                            disableButton ? 'buttonAddDisable' : '',
                                        ].join(' ')}
                                    >
                                        <ButtonAdd
                                            label={index == 0 ? 'เพิ่มหมวดหมู่' : 'เพิ่มหมวดหมู่ย่อย'}
                                            handleAdd={() => disableButton ? null : addCategory(index + 1)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            )
        }

    }


    return (
        <div className='wrapper-category-widget'>
            {ContentRender()}
        </div>
    );
};


export default CategoryWidget;