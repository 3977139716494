import React from "react";
import { useSelector } from "react-redux";
import { getSelectedProduct } from "redux/sagas/selectors";
import { Link } from "react-router-dom";
import classNames from "classnames";
// import ProductSkus from './sku';
// import IconTrash from '../../images/icons/trash.svg'
import { ReactComponent as IconEdit } from "images/icons/edit.svg";
import { ReactComponent as IconTrash } from "images/icons/trash.svg";
import defaulIimage from "../../images/icons/default-product-image.svg";
import CouponTag from "../CouponTag";

import "./shop.scss";

const SelectedShop = (prop) => {
  const { logoPath, name, allProduct, count, sku } = prop.shop;

  //   console.log("prop.shop:>>",prop.shop)

  const selectedShop = useSelector((state) => state.selectedShop);

  const handleDeleteShop = (e, shopId) => {
    e.stopPropagation();
    prop.handleDeleteShop(shopId);
  };

  const handleEditShop = (shopId) => {
    prop.handleEditShop(shopId);
  };

  return (
    <div className="selected-shop" onClick={() => handleEditShop(prop.shopId)}>
      <div className="product-container">
        <div className="product-image">
          <img
            src={
              logoPath?.host !== undefined
                ? logoPath?.host + logoPath?.path
                : defaulIimage
            }
            alt={`product - ${name}`}
          />
        </div>
        <div className="product-name">{name}</div>
        <div className="product-status-coupontag">
          <CouponTag isAllShop={allProduct} />
        </div>
        <div className="product-status-coupontag">{count || 0}</div>
        <div className="product-status-coupontag">{sku || 0}</div>

        <div>
          <IconEdit
            className={classNames("cursor-pointer")}
            onClick={() => handleEditShop(prop.shopId)}
          />
          {selectedShop.countAllShop === 1 ? null : (
            <div>
              <IconTrash
                className={classNames("cursor-pointer")}
                onClick={(e) => handleDeleteShop(e, prop.shopId)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectedShop;
