import React from 'react'
import numeral from 'numeral'
import { Checkbox } from "components/input";

const SkuItem = ({imagePath, name, price, amount, status, handleSkuCheckbox, value = false, disabled}) => {

  return (
  <div className='sku-item'>
    <div className='sku-item-checkbox'>
        <Checkbox
            checking={value}
            onSelectMedia={()=>handleSkuCheckbox(!value)}
            disabled={disabled}
          />
    </div>
    <div className='sku-item-detail'>
      <img src={imagePath} alt={`รูป-${name}`}/>
      <span>{name}</span>
    </div>
    <div className='sku-item-price'>
      {`฿ ${numeral(price).format('0,0.00')}`}
    </div>
    <div className='sku-item-amount'>
      {numeral(amount).format('0,0')}
    </div>
    <div className='sku-item-status'>
      {status === 'show' ? <span className='show'>ขายอยู่</span> : <span className='not-show'>ไม่ขาย</span>}
    </div>
  </div>
  )
}

export default SkuItem