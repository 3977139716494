import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'
import host from 'config/host'

const DropdownIcon = (props) => {
  const { label, options, defaultValue, handleSelected, 
    // icon 
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [valueDropdown, setValueDropdown] = useState({ value: '', name: '', icon:'' })

  useEffect(() => {
    if (defaultValue) {
      const tmpValue = options.find((option) => defaultValue === option.value)
      setValueDropdown(tmpValue)
    }
  }, [defaultValue, options])

  const toggleDropdown = (e) => {
    e.stopPropagation()
    setIsOpen((prevState) => {
      return !prevState
    })
  }

  const onSelectDropdown = (data) => {
    setValueDropdown(data)
    handleSelected(data.value)
    setIsOpen(false)
  }

  return (
    <div className='dropdown' id="dropdown-icon">
      <div className='label'>{label}</div>
      <div className='container-dropdown'>
        <div className='dropdown-box' onClick={toggleDropdown}>
          <span className='text-name'> {valueDropdown.icon && <img src={`${host.image}${valueDropdown.icon}` || ''} alt="" style={{width:'50px',marginRight:'1em'}}/>} {valueDropdown.name}</span>
          <div className='icon-box' onClick={toggleDropdown}>
            <FontAwesomeIcon
              icon={isOpen ? faAngleUp : faAngleDown}
              className='icon-angle'
            />
          </div>
        </div>
        {isOpen ? (
          <div className='container-options'>
            {options.map((item) => (
              <div
                key={item.value}
                className={classnames('option-box', {
                  active: valueDropdown.value === item.value,
                })}
                onClick={() => onSelectDropdown(item)}
              >
                <div className='text-option'>
                    <img src={`${host.image}${item.icon}`} alt="" style={{width:'40px',marginRight: '1em'}} />
                    {item.name}</div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

DropdownIcon.defaultProps = {
  label: '',
  handleSelected: () => {},
  defaultValue: '',
  options: [
    {
      value: 'name',
      name: 'เรียงจากชื่อ',
      icon: 'https://pbs.twimg.com/profile_images/1122723876130045953/J8dTVxJk_400x400.jpg'
    },
    {
      value: 'counter',
      name: 'ใช้งาน',
      icon: 'https://pbs.twimg.com/profile_images/1122723876130045953/J8dTVxJk_400x400.jpg'
    },
    {
      value: 'lastused',
      name: 'อัปโหลดล่าสุด',
      icon: 'https://pbs.twimg.com/profile_images/1122723876130045953/J8dTVxJk_400x400.jpg'
    },
  ],
}

export default DropdownIcon
