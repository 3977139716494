export const hasSubMenu = [
    'playlists',
    'playlist-front-page',
    'promocode',
    'revenue',
    'order'
];

export const hasQueryParam = [
    'promocode',
    'revenue',
];


export const menuItem = [
    { path: '/user', name: 'ผู้ใช้งาน' },
    { path: '/user/adduser', name: 'เพิ่มผู้ใช้งาน' },
    { path: '/manage/dashboard', name: 'สรุปรายงาน' },
    { path: '/manage/dashboard/orderReport', name: 'รายงานคำสั่งซื้อ' },
    { path: '/manage/dashboard/top-viewer', name: 'ยอดวิวทั้งหมด' },
    { path: '/manage/shop', name: 'ร้านค้า' },
    { path: '/manage/shop/shopdetail', name: 'รายละเอียดร้านค้า' },
    { path: '/manage/shop/shopdetail/createlive', name: 'สร้างไลฟ์' },
    { path: '/manage/shop/shopdetail/editlive', name: 'แก้ไขไลฟ์' },
    { path: '/manage/shop/shopdetail/editvod', name: 'แก้ไข VOD' },
    { path: '/manage/shop/shopdetail/upload', name: 'อัพโหลด' },
    { path: '/manage/shop/shopdetail/managelive', name: 'จัดการ LIVE' },
    { path: '/manage/shop/shopdetail/addlogisticprice', name: 'เพิ่มราคาค่าจัดส่ง' },
    { path: '/manage/shop/shopdetail/addbrand', name: 'เพิ่มแบรนด์สินค้า' },
    { path: '/manage/shop/addshop', name: 'สร้างร้านค้า' },
    { path: '/manage/product', name: 'สินค้า' },
    { path: '/manage/product/addproduct', name: 'เพิ่มสินค้า' },
    { path: '/manage/product/mass-upload', name: 'เพิ่มสินค้าแบบชุด' },
    { path: '/manage/order', name: 'รายการสั่งซื้อ' },
    { path: '/manage/order/all', name: 'รายการสั่งซื้อทั้งหมด' },
    { path: '/manage/order/order-ontheway', name: 'รายการจัดส่งที่ยังไม่ถึงผู้รับ' },
    { path: '/manage/order/summary-order', name: 'สรุปรายงานการสั่งซื้อ' },
    { path: '/manage/order/all/create', name: 'อัปโหลดหมายเลขพัสดุแบบชุด' },
    // { path: '/manage/order/all/create/mass-upload-tracking', name: 'อัปโหลดหมายเลขพัสดุแบบชุด' },
    { path: '/manage/revenue', name: 'รายได้ / การโอนเงิน' },
    { path: '/manage/revenue/withdraw', name: 'รายการรายได้' },
    { path: '/manage/revenue/transfer', name: 'การโอนเงิน' },
    { path: '/manage/playlists', name: 'เพลย์ลิสต์' },
    { path: '/manage/playlists/platform', name: 'เพลย์ลิสต์' },
    { path: '/manage/playlists/mall', name: 'เพลย์ลิสต์' },
    { path: '/manage/playlists/platform/live-items', name: 'ไลฟ์ในเพลย์ลิสต์' },
    { path: '/manage/playlists/platform/next-live-items', name: 'ไลฟ์ถัดไปในเพลย์ลิสต์' },
    { path: '/manage/playlists/platform/vod-items', name: 'วีโอดีในเพลย์ลิสต์' },
    { path: '/manage/playlists/platform/shop-items', name: 'ร้านค้าในเพลย์ลิสต์' },
    { path: '/manage/playlists/platform/product-digital-items', name: 'สินค้าในเพลย์ลิสต์' },
    { path: '/manage/playlists/platform/product-general-items', name: 'สินค้าในเพลย์ลิสต์' },
    { path: '/manage/playlists/platform/category-items', name: 'หมวดหมู่สินค้าในเพลย์ลิสต์' },
    { path: '/manage/playlists/platform/shop-coupon-items', name: 'คูปองในเพลย์ลิสต์' },
    { path: '/manage/playlists/platform/coupon-items', name: 'คูปองส่วนลด' },
    { path: '/manage/playlists/mall/live-items', name: 'ไลฟ์ในเพลย์ลิสต์' },
    { path: '/manage/playlists/mall/next-live-items', name: 'ไลฟ์ถัดไปในเพลย์ลิสต์' },
    { path: '/manage/playlists/mall/vod-items', name: 'วีโอดีในเพลย์ลิสต์' },
    { path: '/manage/playlists/mall/shop-items', name: 'ร้านค้าในเพลย์ลิสต์' },
    { path: '/manage/playlists/mall/product-digital-items', name: 'สินค้าในเพลย์ลิสต์' },
    { path: '/manage/playlists/mall/product-general-items', name: 'สินค้าในเพลย์ลิสต์' },
    { path: '/manage/playlists/mall/category-items', name: 'หมวดหมู่สินค้าในเพลย์ลิสต์' },
    { path: '/manage/playlists/mall/shop-coupon-items', name: 'คูปองในเพลย์ลิสต์' },
    { path: '/manage/playlists/mall/coupon-items', name: 'คูปองส่วนลด' },
    { path: '/manage/promocode/addpromocode', name: 'เพิ่มโปรโมชั่นโค้ด' },
    { path: '/manage/promocode', name: 'คูปองส่วนลด' },
    { path: '/manage/promocode/mall', name: 'คูปองส่วนลด' },
    { path: '/manage/promocode/platform', name: 'คูปองส่วนลด' },
    { path: '/manage/promocode/platform/addcoupon', name: 'เพิ่มคูปองส่วนลด' },
    { path: '/manage/promocode/platform/editcoupon', name: 'แก้ไขคูปองส่วนลด' },
    { path: '/manage/promocode/mall/addcoupon', name: 'เพิ่มคูปองส่วนลด' },
    { path: '/manage/promocode/mall/editcoupon', name: 'แก้ไขคูปองส่วนลด' },
    { path: '/manage/promocode/comingsoon', name: '' },
    { path: '/manage/promocode/processing', name: '' },
    { path: '/manage/promocode/expired', name: '' },

    { path: '/manage/promotion/mall', name: 'โปรโมชั่น' },
    { path: '/manage/promotion/platform', name: 'โปรโมชั่น' },
    { path: '/manage/promotion', name: 'โปรโมชั่นค่าจัดส่ง' },
    { path: '/manage/promotion/platform/addpromotion', name: 'เพิ่มโปรโมชั่น' },
    { path: '/manage/promotion/platform/editpromotion', name: 'แก้ไขโปรโมชั่น' },
    { path: '/manage/promotion/mall/addpromotion', name: 'เพิ่มโปรโมชั่น' },
    { path: '/manage/promotion/mall/editpromotion', name: 'แก้ไขโปรโมชั่น' },
    { path: '/manage/promotion/addpromotion', name: 'เพิ่มโปรโมชั่น' },
    { path: '/manage/promotion/editpromotion', name: 'แก้ไขโปรโมชั่น' },
    { path: '/manage/promotion/mall/:status', name: 'โปรโมชั่น' },
    { path: '/manage/promotion/platform/:status', name: 'โปรโมชั่น' },
    { path: '/manage/promotion/:status', name: 'โปรโมชั่น' },

    { path: '/manage/playlist-front-page', name: 'ตัวจัดการหน้าเว็บ' },
    { path: '/manage/playlist-front-page/mall', name: 'ตัวจัดการหน้าเว็บ' },
    { path: '/manage/playlist-front-page/platform', name: 'ตัวจัดการหน้าเว็บ' },
    { path: '/manage/playlist-front-page/mall/page', name: 'หน้าเว็บ' },
    { path: '/manage/playlist-front-page/platform/page', name: 'หน้าเว็บ' },
    { path: '/manage/ribbon', name: 'ตกแต่งรูปสินค้า' },
    { path: '/manage/broadcast', name: 'บรอดแคสต์' },
    { path: '/manage/broadcast/createbroadcast', name: 'สร้างบรอดแคสต์' },
    { path: '/manage/promotion', name: 'โปรโมชั่นค่าจัดส่ง' },

    { path: '/manage/announce', name: 'ประกาศ' },
    { path: '/manage/announce/add-announce', name: 'สร้างประกาศ' },
    { path: '/manage/announce/edit-announce', name: 'แก้ไขประกาศ' },

    
]
