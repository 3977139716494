
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import headerMenuList from './HeaderMenuList';

import { SETTING_MENU_SIDEBAR, 
  // SETTING_MENU_SIDEBAR_PERMISSION, 
  dispatchApp } from '../../redux/actions/app';

import './index.scss';

import Cookies from 'js-cookie';
import { ADMIN, OWNER, SUPER_ADMIN, ACCOUNTANT } from '../Sidebar/Role'
// import { IsJsonString } from 'helper/utils';

class HeaderMenu extends Component {

  // componentDidMount() {
  // this.props.dispatch(dispatchApp({ type: SETTING_MENU_SIDEBAR, payload: { group: headerMenuList.items[0] } }))
  // this.props.dispatch(dispatchApp({ type: SETTING_MENU_SIDEBAR_PERMISSION, payload: { group: headerMenuList.items[0] } }))
  constructor(props) {
    super(props)
    this.state = { userRole: null }
  }

  componentDidMount() {

    // const user = Cookies.get('user')
    // const userInfo = IsJsonString(user) ? JSON.parse(user != undefined ? user : null) : {};
    const role = Cookies.get('role');
    let userRole = OWNER;
      switch(role) {
        case 'ADMIN' : userRole = ADMIN; break;
        case 'SUPER_ADMIN' : userRole = SUPER_ADMIN; break;
        case 'OWNER' : userRole = OWNER; break;
        case 'ACCOUNTANT' : userRole = ACCOUNTANT; break;
      }
    this.setState({ userRole });

    this.props.dispatch(dispatchApp({ type: SETTING_MENU_SIDEBAR, payload: { group: headerMenuList.items[0].group } }))
  }

  setSideBarGroupMenu = (groupMenu) => {
    this.props.dispatch(dispatchApp({ type: SETTING_MENU_SIDEBAR, payload: { group: groupMenu.group } }))
  }

  checkDuplicateArray = (arr, value) => {
    // eslint-disable-next-line eqeqeq
    return arr.some(item => item == value)
  }


  render() {
    const { tabMenuHeader } = this.props
    const { userRole } = this.state

    return (
      <React.Fragment>
        <div className='menu-list'>
          <ul>
            {headerMenuList.items.map((menu, index) => {
              return (
                <React.Fragment key={`${'headerMenuList_' + index}`}>
                  {menu.role.includes(userRole) && (
                    <li
                      className={`${tabMenuHeader.group === menu.group ? 'active' : ''
                        }`}
                      onClick={() => this.setSideBarGroupMenu(menu)}
                    >
                      <Link to={menu.isIndex ? menu.linkUrl : menu.url} target={menu.url === '/chat' || menu.url === '/chat-summary' ? "_blank" : null}>
                        {menu.name}
                      </Link>

                    </li>
                  )}
                </React.Fragment>
              )
            })}
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  tabMenuHeader: state.app.tabMenuHeader,
  role: state.user.role
})

export default connect(mapStateToProps)(HeaderMenu)

