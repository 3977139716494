/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import classnames from 'classnames'
import { Body1, Body2, Caption, H5 } from 'components/FontStyle'
import ModalAlert from 'components/modalAlert/ModalAlert'
import ModalTransferConfirm from 'components/ModalTransferConfirm'
import AccountantTransfer from 'components/output/Withdraw/AccountantTransfer'
import AdminTransfer from 'components/output/Withdraw/AdminTransfer'
import AdminTransferDetail from 'components/output/Withdraw/AdminTransferDetail'
import AdminDetail from 'components/output/Withdraw/AdminTransferDetail'
import PaginationComponent from 'components/pagination'
import PeriodTimeInput from 'components/PeriodTimeInput'
import { options } from 'components/PeriodTimeInput/dataUtil'
import SelectInputWithImg from 'components/SelectInputWithImg'
import XlsxCreate from 'components/XlsxCreate'
import { displayDateThShortYear } from 'helper/timeUtil'
import { getUserRole } from 'helper/utils'
import { ACCOUNTANT, ADMIN, OWNER, SUPER_ADMIN } from 'layout/Sidebar/Role'
import moment from 'moment'
import numeral from 'numeral'
import withdrawProvider from 'provider/withdrawProvider'
import shopProvider from 'provider/shopProvider'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { dispatchApp, SET_LOADING } from 'redux/actions/app'
import { SET_DATA_CLEARING_ORDER } from 'redux/actions/order'
import { disapatchWithdraw, GET_LIST_SHOP_WITHDRAW, SET_KEY_WITHDRAW } from 'redux/actions/withdraw'
import { getUser } from 'redux/sagas/selectors'
import '../styles/_transferDetail.scss'
import classNames from 'classnames'

const TransferDetail = () => {

    const {
        onChangeDate,
        onChangeShop,
        listShop,
        shopSelectedIndex,
        listTransfer,
        page, setPage,
        limit, setLimit,
        status,
        onChangeStatus,
        dateSelected,
        dateOption,
        fromDate,
        toDate,
        showButtonTransferred,
        showStatusWithdraw,
        fetchDataExcel,
        listAllShop,
        onChangePage,
        modalDetail, setmodalDetail,
        getListTransfer,
        onShowSizeChange,
        withdrawSelect,
        setWithdrawSelect,
        handleCheckWithdrawSelect,
        handleSelectAllWithdraw,
        allWithdraw,
        sumWithdrawSelect,
        setSumWithdrawSelect,
        getAllWithdraw,
        countWithdrawReady,
    } = useHookTransferDetail()


    return (
        <div>

            <div>
                <H5>การโอนเงิน</H5> <Caption>(รายการแต่ละรายการจะแสดงหลังจากลูกค้าได้รับสินค้า 7 วัน)</Caption>
            </div>

            <div>
                <div className="w-100 mb-4 d-flex flex-row bg-white p-4" style={{ gap: '10%' }}>
                    {/* className,width,height,options,placeholder,onChange,onClearSearchShop,value,onBlur,iconClearSearch,name  */}
                    <div className='d-flex flex-row gap-1 align-items-center w-50'>
                        <Body2>ร้านค้า : </Body2>
                        <div className="w-75">
                            <SelectInputWithImg
                                options={listShop}
                                onChange={onChangeShop}
                                iconClearSearch={false}
                                value={listShop && shopSelectedIndex === 0 ? 0 : listShop && shopSelectedIndex}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-row gap-1 align-items-center w-100 ">
                        <Body2>รอบสรุปข้อมูล: </Body2>
                        <PeriodTimeInput
                            onPeriodDateSelected={(date) => onChangeDate(date)}
                            disabled={false}
                            optionDate={dateOption}
                            optionsRangeDate={dateSelected}
                            optionTypeDisabled={[options.REAL_TIME, options.YESTERDAY]}
                        />
                    </div>
                </div>

                <div className='color-red'><Caption>*กด Shift ค้างแล้วเลื่อนลูกกลิ้งเมาส์ (Scroll mouse) เพื่อเลื่อนดูข้อมูลตามแนวนอน</Caption> <br />
                    <Caption> ** กำหนดการโอนเงินโดยประมาณ ไม่รวมวันหยุดนักขัตฤกษ์</Caption></div>

                {/* <div className="body-order-content"> */}
                <div className="tab-order mt-4">
                    <div className=''>
                        <Nav tabs>
                            {/* <NavItem onClick={() => this.toggle('waitShipping')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'waitShipping' })} >
                                         ออเดอร์ใหม่ ({listOrder?.waitShipping})
                                </NavLink>
                                </NavItem> */}
                            <NavItem onClick={() => onChangeStatus('')}>
                                <NavLink className={classnames({ 'active': status === '' })} >
                                    <Caption>ทั้งหมด</Caption>
                                </NavLink>
                            </NavItem>
                            <NavItem onClick={() => onChangeStatus('settled')}>
                                <NavLink className={classnames({ 'active': status === 'settled' })} >
                                    <Caption>รอโอนเงิน</Caption>
                                </NavLink>
                            </NavItem>

                            <NavItem onClick={() => onChangeStatus('transferred')}>
                                <NavLink className={classnames({ 'active': status === 'transferred' })} >
                                    <Caption>โอนเงินให้ร้านค้าแล้ว</Caption>
                                </NavLink>
                            </NavItem>

                        </Nav>
                    </div>

                    <div>
                        {getUserRole() === ACCOUNTANT && 
                         <XlsxCreate
                         handleFetchData={() => fetchDataExcel(withdrawSelect,'group')}
                         fileName={`${listShop && listShop[shopSelectedIndex]?.label}-${status === '' ? 'ทั้งหมด' : status === 'settled' ? 'รอโอนเงิน' : status === 'transferred' ? 'โอนเงินให้ร้านค้าแล้ว' : ''}-${displayDateThShortYear(new Date(), 'DD-MM-YY')}`}
                         headerA1={'รายการรายได้'}
                         numberFormat={'0,0.00'}
                         // nonNumberFormat={perm === 'ACCOUNTANT' ? ['H'] : null}
                     >
                         <button className={classNames('btn btn-disabled-download-excel', { 'btn-cancel': withdrawSelect.length > 0, 'not-allowed': withdrawSelect.length === 0 })}
                             disabled={withdrawSelect.length === 0}>
                             <Body1>ดาวน์โหลด Excel</Body1>
                         </button>
                     </XlsxCreate>
                        }
                       
                        <button className={classNames('btn w-auto btn-disabled-grid', { 'btn-submit': withdrawSelect.length > 0, 'not-allowed': withdrawSelect.length === 0 })}
                            onClick={() => setmodalDetail({ isOpen: true, data: { id: withdrawSelect, totalRevenue: sumWithdrawSelect, attachment: [] } })}
                            disabled={withdrawSelect.length === 0}
                        ><Body1>ยืนยันการโอนเงินให้ร้านค้า ({withdrawSelect.length})</Body1></button>
                    </div>

                </div>

                <div>
                    {(getUserRole() === ADMIN || getUserRole() === SUPER_ADMIN || getUserRole() === OWNER) &&
                        <AdminTransfer
                            transferList={listTransfer}
                            showButtonTransferred={showButtonTransferred}
                            showStatusWithdraw={showStatusWithdraw}
                            fetchDataExcel={(id) => fetchDataExcel(id)}
                            listAllShop={listAllShop}
                            status={status}
                            shopSelectedIndex={shopSelectedIndex}
                            page={page}
                            limit={limit}
                            modalDetail={modalDetail}
                            setmodalDetail={setmodalDetail}
                            getListTransfer={getListTransfer}
                            setWithdrawSelect={setWithdrawSelect}
                            withdrawSelect={withdrawSelect}
                            handleCheckWithdrawSelect={handleCheckWithdrawSelect}
                            handleSelectAllWithdraw={handleSelectAllWithdraw}
                            allWithdraw={allWithdraw}
                            sumWithdrawSelect={sumWithdrawSelect}
                        />
                    }

                    {getUserRole() === ACCOUNTANT &&
                    <AccountantTransfer
                        transferList={listTransfer}
                        showButtonTransferred={showButtonTransferred}
                        showStatusWithdraw={showStatusWithdraw}
                        fetchDataExcel={(id) => fetchDataExcel(id)}
                        listAllShop={listAllShop}
                        status={status}
                        shopSelectedIndex={shopSelectedIndex}
                        page={page}
                        limit={limit}
                        modalDetail={modalDetail}
                        setmodalDetail={setmodalDetail}
                        getListTransfer={getListTransfer}
                        setWithdrawSelect={setWithdrawSelect}
                        withdrawSelect={withdrawSelect}
                        handleCheckWithdrawSelect={handleCheckWithdrawSelect}
                        handleSelectAllWithdraw={handleSelectAllWithdraw}
                        allWithdraw={allWithdraw}
                        countWithdrawReady={countWithdrawReady}
                    />
                    }


                </div>
            </div>

            <div>
                <PaginationComponent
                    total={listTransfer?.meta?.count}
                    pageSize={limit}
                    current={page}
                    pageSizeOptions={[50]}
                    onShowSizeChange={onShowSizeChange}
                    onChange={onChangePage}
                />
            </div>

            <ModalTransferConfirm
                modalDetail={modalDetail}
                setmodalDetail={setmodalDetail}
                getListTransfer={getListTransfer}
                setWithdrawSelect={setWithdrawSelect}
                setSumWithdrawSelect={setSumWithdrawSelect}
                getAllWithdraw={getAllWithdraw}
            />

            <ModalAlert />



        </div>
    )
}

const useHookTransferDetail = () => {
    const dispatch = useDispatch()
    const [listTransfer, setListTransfer] = useState(null)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [status, setStatus] = useState('')
    // const [first, setfirst] = useState(second)
    const [shopSelectedIndex, setShopSelectedIndex] = useState(0)
    const [dateSelected, setDateSelected] = useState({ startDate: moment(), endDate: moment() })
    const [dateOption, setDateOption] = useState(options.WITHIN_MONTH)
    // const [toDate, setToDate] = useState(moment().endOf('month'))
    // const [fromDate, setFromDate] = useState(moment().startOf('month'))
    const [period, setPeriod] = useState({ toDate: moment().endOf('day'), fromDate: moment().startOf('month') })
    const [loadingShop, setLoadingShop] = useState(false)
    const [loadingList, setLoadingList] = useState(false)
    const [listWithdrawByTransfer, setListWithdrawByTransfer] = useState(null)
    const [modalDetail, setmodalDetail] = useState({ isOpen: false, data: null })
    const withdraw = useSelector(state => state.withdraw)
    const { listAllShop } = withdraw
    const [withdrawSelect, setWithdrawSelect] = useState([])
    const [allWithdraw, setAllWithdraw] = useState([])
    const [sumWithdrawSelect, setSumWithdrawSelect] = useState(0)
    const [countWithdrawReady, setCountWithdrawReady] = useState(0)

    useEffect(() => {
        handleGetShopList()
        getAllWithdraw()
        return () => {
        }
    }, [])

    useEffect(() => {
        // setDefaultPage()
        getListTransfer()
        getAllWithdraw()
        setWithdrawSelect([])
        setSumWithdrawSelect(0)
    }, [status, shopSelectedIndex, period, page, limit])

    useEffect(() => {
        if (loadingShop || loadingList) {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
        } else {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        }
        return () => {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        }
    }, [loadingShop, loadingList])


    // const setDefaultPage = () => {
    //     setPage(1)
    //     setLimit(10)
    // }


    const onChangeStatus = (status) => {
        setPage(1)
        setStatus(status)
    }

    const onChangeShop = (index) => {
        setShopSelectedIndex(index)
    }

    const onChangeDate = (date) => {
        setDateOption(date.type)
        setDateSelected({ startDate: date?.period?.from, endDate: date?.period?.to })
        setPeriod({ toDate: date?.period?.to, fromDate: date?.period?.from })
        //setPageDefault()
        // getListTransfer()
    }

    const onChangePage = (page) => {
        setPage(page)
    }

    const showStatusWithdraw = (status, date, withdrawTransaction) => {
        switch (status) {
            // case 'requested':
            // case 'settled':
            //     return <span>กำลังดำเนินการ</span>
            //     break;
            case 'transferred':
                return <span className='d-inline-block flex-column justify-content-center align-items-center'>
                    <span className='color-default-success'> โอนเงินให้ร้านค้าแล้ว</span>
                    <span className='color-grey d-flex'>{displayDateThShortYear(date, 'DD MMM YY HH:mm')}</span>
                    <span className='color-grey d-flex'>{withdrawTransaction?.length > 0 && `฿ ${numeral(withdrawTransaction?.length > 0 && withdrawTransaction[withdrawTransaction?.length - 1]?.totalAmount).format('0,0.00')}`}</span>
                </span>

            default:
                return <span className=''>กำลังดำเนินการ</span>
                break;
        }
    }

    const getListSellerWithdrawOrder = async (id) => {
        const res = await withdrawProvider.getOrderWithdrawBySellerWithdrawId({ id })
        setListWithdrawByTransfer(res.data)
        return res.data
    }
    const getListSellerWithdrawOrderGroup = async (data) => {
        const res = await withdrawProvider.getOrderWithdrawBySellerWithdrawGroup(data)
        setListWithdrawByTransfer(res.data)
        return res.data
    }

    const fetchDataExcel = async (list,type) => {
        const { status, tranferredDate, refNumber, settledDate, settlementPeriod, orderWithdraw, totalRevenue, totalPaymentFee, listAllShop,
            // fetchDataExcel,
            shopSelectedIndex, id, shop, totalItemPrice, totalShippingFee, totalShippingDiscount, totalShippingDiscountShop,
            totalDiscountPlatform, totalDiscountShop, totalAmount, totalPaymentFeeVat, totalWithHoldingTax } = list || {}
                const res = type === 'group' ? await getListSellerWithdrawOrderGroup({ sellerWithdrawId: list }) : await getListSellerWithdrawOrder(list.id)
        // if (getUserRole() === ACCOUNTANT) {
        const header = ['ลำดับ', 'สถานะการโอนเงินให้ร้านค้า','Transaction number', 'Vendor Code', 'Customer Code', 'หมายเลขออเดอร์', 'รอบสรุปข้อมูล', 'กำหนดโอนเงินโดยประมาณไม่เกิน', 'วันที่ลูกค้าชำระเงิน', 'วันที่จัดส่งถึงลูกค้า', 'ร้านค้า', 'ชื่อนิติบุคคล/ชื่อบุคคลธรรมดา', 'จำนวนสินค้า', 'ยอดรวมค่าสินค้า', 'ค่าจัดส่ง', 'ส่วนลดค่าจัดส่ง', 'คงเหลือค่าจัดส่ง', 'ยอดรวมค่าสินค้าและค่าจัดส่ง', 'ส่วนลด V-avenue', 'ส่วนลดร้านค้า', 'ส่วนลดทั้งหมด', 'ยอดชำระทั้งสิ้น', 'ช่องทางการชำระเงิน', 'เลขอ้างอิง mPAY', 'ค่าบริการชำระเงินคิดที่อัตรา 2%', 'ภาษีค่าบริการชำระเงิน', 'ค่าบริการชำระเงิน (รวมภาษี)', 'ส่วนแบ่งรายได้ร้านค้า', 'ภาษีหัก ณ ที่จ่าย (W/T)', 'ส่วนแบ่งรายได้ร้านค้าหักส่วนลด+W/T']
        const dataExcel = []
        await res.orderWithdraws.map((list, idx) => {
            dataExcel.push({
                index: idx + 1,
                // btnTransfer: list?.status,
                statusTransfer: list?.status === 'transferred' ? `โอนเงินให้ร้านค้าแล้ว\n${displayDateThShortYear(list?.transferredDate, 'DD MMM YY HH:mm')}\n฿ ${numeral(list?.sellerWithdraw?.attachment[list.sellerWithdraw?.attachment?.length - 1]?.totalAmount).format('0,0.00')}` : 'กำลังดำเนินการ',
                transactionNumber: list?.sellerWithdraw?.refNumber,
                partner: shop?.partner?.value,
                customerCode: listTransfer?.meta?.customerCode,
                orderNo: list?.order?.orderNo,
                settledDate: displayDateThShortYear(list?.settledDate),
                settlementPeriod: displayDateThShortYear(list?.sellerWithdraw?.settlementPeriod),
                paymentAt: displayDateThShortYear(list?.order?.paymentedAt, 'DD MMM YY HH:mm'),
                deliveredAt: displayDateThShortYear(list?.order?.deliveredAt, 'DD MMM YY HH:mm'),
                shopName: shop?.name,
                shopTax: shop?.shopTax?.name,
                countItem: numeral(list?.order?.items.reduce((a, { quantity }) => a + quantity, 0)).format('0,0'),
                totalItemPrice: list?.totalItemPrice ? list?.totalItemPrice : '0',
                totalShipping: numeral(list?.totalShippingFee).format('0,0.00') ? numeral(list?.totalShippingFee).format('0,0.00') : '0',
                discount: (list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order.priceShippingDiscountShop : 0) ? (list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order.priceShippingDiscountShop : 0) : '0',
                discountShipping: list?.totalShippingFee - ((list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order?.priceShippingDiscountShop : 0)) ? list?.totalShippingFee - ((list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order?.priceShippingDiscountShop : 0)) : '0',
                amount: list?.totalItemPrice + list.order?.priceShippingNet ? list?.totalItemPrice + list.order?.priceShippingNet : '0',
                discountPlatform: list?.totalDiscountPlatform ? list?.totalDiscountPlatform : '0',
                discountShop: list?.totalDiscountShop ? list?.totalDiscountShop : '0',
                discountAll: list?.totalDiscountPlatform + list?.totalDiscountShop ? list?.totalDiscountPlatform + list?.totalDiscountShop : '0',
                totalAmount: list?.totalAmount ? list?.totalAmount : '0',
                paymentMethod: list?.order?.paymentMethod,
                transactionId: list?.order?.payment?.transactionId,
                totalPaymentFee: list?.totalPaymentFee ? list?.totalPaymentFee : '0',
                totalPaymentFeeVat: list?.totalPaymentFeeVat ? list?.totalPaymentFeeVat : '0',
                totalPaymentFeeAndVat: list?.totalPaymentFee + list?.totalPaymentFeeVat ? list?.totalPaymentFee + list?.totalPaymentFeeVat : '0',
                totalRevenue: (list?.totalAmount - (list?.totalPaymentFee + list?.totalPaymentFeeVat)) ? (list?.totalAmount - (list?.totalPaymentFee + list?.totalPaymentFeeVat)) : '0',
                totalWithHoldingTax: list?.totalWithHoldingTax ? list?.totalWithHoldingTax : '0', //
                holdingTaxRevenue: list?.totalRevenue ? list?.totalRevenue : '0'
            })
        })
        dataExcel.push({
            index: '',
            // btnTransfer: '',
            statusTransfer: '',
            transactionNumber: '',
            partner: '',
            customerCode: '',
            orderNo: '',
            settledDate: '',
            settlementPeriod: '',
            paymentAt: '',
            deliveredAt: '',
            shopName: '',
            shopTax: '',
            countItem: numeral(res.orderWithdraws.map(element => element.order.items.map(item => item.quantity).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).format('0,0'),
            totalItemPrice: totalItemPrice ? totalItemPrice : '0',
            totalShipping: numeral(totalShippingFee).format('0,0.00') ? numeral(totalShippingFee).format('0,0.00') : '0',
            discount: totalShippingDiscountShop + totalShippingDiscount ? totalShippingDiscountShop + totalShippingDiscount : '0',
            discountShipping: totalShippingFee - (totalShippingDiscountShop + totalShippingDiscount) ? totalShippingFee - (totalShippingDiscountShop + totalShippingDiscount) : '0',
            amount: totalItemPrice + totalShippingFee,
            discountPlatform: totalDiscountPlatform ? totalDiscountPlatform : '0',
            discountShop: totalDiscountShop ? totalDiscountShop : '0',
            discountAll: totalDiscountPlatform + totalDiscountShop ? totalDiscountPlatform + totalDiscountShop : '0',
            totalAmount: totalAmount ? totalAmount : '0',
            paymentMethod: '',
            transactionId: '',
            totalPaymentFee: totalPaymentFee ? totalPaymentFee : '0',
            totalPaymentFeeVat: totalPaymentFeeVat ? totalPaymentFeeVat : '0',
            totalPaymentFeeAndVat: totalPaymentFee + totalPaymentFeeVat ? totalPaymentFee + totalPaymentFeeVat : '0',
            totalRevenue: totalAmount - (totalPaymentFee + totalPaymentFeeVat) ? totalAmount - (totalPaymentFee + totalPaymentFeeVat) : '0',
            totalWithHoldingTax: totalWithHoldingTax ? totalWithHoldingTax : '0',
            holdingTaxRevenue: totalRevenue ? totalRevenue : '0'
        })
        return {
            header,
            data: dataExcel
        }
        // } else {
        //     const header = ['ลำดับ', 'สถานะการโอนเงินให้ร้านค้า', 'Vendor Code', 'หมายเลขออเดอร์', 'รอบสรุปข้อมูล', 'กำหนดโอนเงินโดยประมาณไม่เกิน', 'วันที่ลูกค้าชำระเงิน', 'วันที่จัดส่งถึงลูกค้า', 'ร้านค้า', 'ชื่อนิติบุคคล/ชื่อบุคคลธรรมดา', 'จำนวนสินค้า', 'ยอดรวมค่าสินค้า', 'ค่าจัดส่ง', 'ส่วนลดค่าจัดส่ง', 'คงเหลือค่าจัดส่ง', 'ยอดรวมค่าสินค้าและค่าจัดส่ง', 'ส่วนลด V-avenue', 'ส่วนลดร้านค้า', 'ส่วนลดทั้งหมด', 'ยอดชำระทั้งสิ้น', 'ช่องทางการชำระเงิน', 'เลขอ้างอิง mPAY', 'ค่าบริการชำระเงินคิดที่อัตรา 2%', 'ภาษีค่าบริการชำระเงิน', 'ค่าบริการชำระเงิน (รวมภาษี)', 'ส่วนแบ่งรายได้ร้านค้า', 'ภาษีหัก ณ ที่จ่าย (W/T)', 'ส่วนแบ่งรายได้ร้านค้าหักส่วนลด+W/T']
        //     const dataExcel = []
        //     await res.orderWithdraws.map((list, idx) => {
        //         dataExcel.push({
        //             index: idx + 1,
        //             // btnTransfer: list?.status,
        //             statusTransfer: list?.status === 'transferred' ? `โอนเงินให้ร้านค้าแล้ว\n${displayDateThShortYear(list?.transferredDate, 'DD MMM YY HH:mm')}\n฿ ${numeral(list?.sellerWithdraw?.attachment[list.sellerWithdraw?.attachment?.length - 1]?.totalAmount).format('0,0.00')}` : 'กำลังดำเนินการ',
        //             partner: shop?.partner?.value,
        //             orderNo: list?.order?.orderNo,
        //             settledDate: displayDateThShortYear(settledDate),
        //             settlementPeriod: displayDateThShortYear(list?.sellerWithdraw?.settlementPeriod),
        //             paymentAt: displayDateThShortYear(list?.order?.paymentedAt, 'DD MMM YY HH:mm'),
        //             deliveredAt: displayDateThShortYear(list?.order?.deliveredAt, 'DD MMM YY HH:mm'),
        //             shopName: shop?.name,
        //             shopTax: shop?.shopTax?.name,
        //             countItem: numeral(list?.order?.items.reduce((a, { quantity }) => a + quantity, 0)).format('0,0'),
        //             totalItemPrice: list?.totalItemPrice ? list?.totalItemPrice : '0',
        //             totalShipping: numeral(list?.totalShippingFee).format('0,0.00') ? numeral(list?.totalShippingFee).format('0,0.00') : '0',
        //             discount: (list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order?.priceShippingDiscountShop : 0) ? list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0 + list?.order?.priceShippingDiscountShop ? list?.order?.priceShippingDiscountShop : 0 : '0',
        //             discountShipping: list?.totalShippingFee - ((list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order?.priceShippingDiscountShop : 0)) ? list?.totalShippingFee - ((list?.order?.priceShippingDiscount ? list?.order?.priceShippingDiscount : 0) + (list?.order?.priceShippingDiscountShop ? list?.order?.priceShippingDiscountShop : 0)) : '0',
        //             amount: list?.totalItemPrice + list.order?.priceShippingNet ? list?.totalItemPrice + list.order?.priceShippingNet : '0',
        //             discountPlatform: list?.totalDiscountPlatform ? list?.totalDiscountPlatform : '0',
        //             discountShop: list?.totalDiscountShop ? list?.totalDiscountShop : '0',
        //             discountAll: list?.totalDiscountPlatform + list?.totalDiscountShop ? list?.totalDiscountPlatform + list?.totalDiscountShop : '0',
        //             totalAmount: list?.totalAmount ? list?.totalAmount : '0',
        //             paymentMethod: list?.order?.paymentMethod,
        //             transactionId: list?.order?.payment?.transactionId,
        //             totalPaymentFee: list?.totalPaymentFee ? list?.totalPaymentFee : '0',
        //             totalPaymentFeeVat: list?.totalPaymentFeeVat ? list?.totalPaymentFeeVat : '0',
        //             totalPaymentFeeAndVat: list?.totalPaymentFee + list?.totalPaymentFeeVat ? list?.totalPaymentFee + list?.totalPaymentFeeVat : '0',
        //             totalRevenue: (list?.totalAmount - (list?.totalPaymentFee + list?.totalPaymentFeeVat)) ? (list?.totalAmount - (list?.totalPaymentFee + list?.totalPaymentFeeVat)) : '0',
        //             totalWithHoldingTax: list?.totalWithHoldingTax ? list?.totalWithHoldingTax : '0', //
        //             holdingTaxRevenue: list?.totalRevenue ? list?.totalRevenue : '0'
        //         })
        //     })
        //     dataExcel.push({
        //         index: '',
        //         // btnTransfer: '',
        //         statusTransfer: '',
        //         partner: '',
        //         orderNo: '',
        //         settledDate: '',
        //         settlementPeriod: '',
        //         paymentAt: '',
        //         deliveredAt: '',
        //         shopName: '',
        //         shopTax: '',
        //         countItem: numeral(res.orderWithdraws.map(element => element.order.items.map(item => item.quantity).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).format('0,0'),
        //         totalItemPrice: totalItemPrice ? totalItemPrice : '0',
        //         totalShipping: numeral(totalShippingFee).format('0,0.00') ? numeral(totalShippingFee).format('0,0.00') : '0',
        //         discount: totalShippingDiscountShop + totalShippingDiscount ? totalShippingDiscountShop + totalShippingDiscount : '0',
        //         // discountShipping: totalShippingFee - totalDiscountShippingShop ? totalShippingFee - totalDiscountShippingShop : '0',
        //         discountShipping: totalShippingFee - (totalShippingDiscountShop + totalShippingDiscount) ? totalShippingFee - (totalShippingDiscountShop + totalShippingDiscount) : '0',
        //         amount: totalItemPrice + totalShippingFee,
        //         discountPlatform: totalDiscountPlatform ? totalDiscountPlatform : '0',
        //         discountShop: totalDiscountShop ? totalDiscountShop : '0',
        //         discountAll: totalDiscountPlatform + totalDiscountShop ? totalDiscountPlatform + totalDiscountShop : '0',
        //         totalAmount: totalAmount ? totalAmount : '0',
        //         paymentMethod: '',
        //         transactionId: '',
        //         totalPaymentFee: totalPaymentFee ? totalPaymentFee : '0',
        //         totalPaymentFeeVat: totalPaymentFeeVat ? totalPaymentFeeVat : '0',
        //         totalPaymentFeeAndVat: totalPaymentFee + totalPaymentFeeVat ? totalPaymentFee + totalPaymentFeeVat : '0',
        //         totalRevenue: totalAmount - (totalPaymentFee + totalPaymentFeeVat) ? totalAmount - (totalPaymentFee + totalPaymentFeeVat) : '0',
        //         totalWithHoldingTax: totalWithHoldingTax ? totalWithHoldingTax : '0',
        //         holdingTaxRevenue: totalRevenue ? totalRevenue : '0'
        //     })
        //     return {
        //         header,
        //         data: dataExcel
        //     }
        // }
    }

    const handleGetShopList = async () => {
        // dispatch({ type: GET_LIST_SHOP_WITHDRAW })
        try {
            setLoadingShop(true)
            const api_shop = new shopProvider()
            const resShop = await api_shop.getAllShops()
            const listShop = []
            // eslint-disable-next-line no-unused-vars
            for (const [index, e] of resShop?.shop.entries()) {
                listShop.push({ label: e.name, value: e, img: e.logoPath })
            }
            if (resShop.count > 1 || resShop.count === 0) {
                listShop.unshift({ label: `ร้านค้าทั้งหมด`, value: '' })
            }

            dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'listAllShop', value: listShop } }))
            if (loadingShop) return
            setLoadingShop(false)
        } catch (error) {
            console.log('error', error)
            setLoadingShop(false)
        }
    }

    const getListTransfer = (async (payload) => {
        const params = {
            status,
            limit,
            page,
            shopId: listAllShop && listAllShop[shopSelectedIndex]?.value?.id,
            toDate: moment(period?.toDate).utc(0).format(),
            fromDate: moment(period?.fromDate).utc(0).format(),
        }
        if (!params.status) delete params.status
        if (!params.shopId) delete params.shopId

        try {
            setLoadingList(true)
            const res = await withdrawProvider.getListTransfer(params)
            setListTransfer(res)
            setLoadingList(false)
            return res
        } catch (error) {
            // setLoading(false)
            console.log('error', error)
            setLoadingList(false)
        }
        // if (!listAllShop) return
        // setLoading(false)

    })

    const showButtonTransferred = (list) => {
        if (withdrawSelect.includes(list.id)) {
            return <button disabled className="btn-disabled-grid" style={{ minWidth: '10px', maxWidth: '6em' }} onClick={() => setmodalDetail({ isOpen: true, data: list })}><Caption>ยืนยันโอนเงิน</Caption></button>
        }
        switch (list?.status) {
            case 'settled':
                if (checkPermTransfer()) {
                    return <button className="btn-confirm-grid" style={{ minWidth: '10px', maxWidth: '6em' }} onClick={() => setmodalDetail({ isOpen: true, data: list })}><Caption>ยืนยันโอนเงิน</Caption></button>
                }

                break;
            case 'transferred':
                return <span className='text-underline cursor-pointer' onClick={() => setmodalDetail({ isOpen: true, data: list })}>ดูหลักฐานการโอน</span>
            default:
                break;
        }
    }

    const checkPermTransfer = () => {
        if (getUserRole() === ACCOUNTANT) return true
        return false
    }

    const onShowSizeChange = (page, limit) => {
        setLimit(limit)
    }

    const handleCheckWithdrawSelect = (id, amount) => {
        if (withdrawSelect.includes(id)) {

            const newArr = withdrawSelect.filter(item => item !== id);
            setWithdrawSelect(newArr)
            setSumWithdrawSelect(sumWithdrawSelect - amount || 0)
        } else {
            const findElement = allWithdraw.data.find((item) => item.id === id)
            if (findElement.status !== 'transferred') {
                setWithdrawSelect([...withdrawSelect, id])
                setSumWithdrawSelect(sumWithdrawSelect + amount)
            }

        }
    }

    const handleSelectAllWithdraw = async () => {
        try {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            const countWithdrawReady = allWithdraw?.data.filter((item) => item.status !== 'transferred')
            setCountWithdrawReady(countWithdrawReady.length)
            // const res = await getAllWithdraw()
            if (countWithdrawReady.length) {
                const allId = []
                const priceTotal = []
                for (let index = 0; index < countWithdrawReady.length; index++) {
                    const element = countWithdrawReady[index];
                    if (element.status !== "transferred") {
                        allId.push(element.id)
                        priceTotal.push(element.totalRevenue)
                    }
                }
                if (withdrawSelect.length === countWithdrawReady.length) {
                    setWithdrawSelect([])
                    setSumWithdrawSelect(0)
                } else {
                    setWithdrawSelect(allId)
                    handleCountWithdrawTotalSum(priceTotal)
                }
            }
        } catch (error) {
            console.log(error)
            alert('error')
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        } finally {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        }
    }


    const getAllWithdraw = (async () => {
        const params = {
            status,
            limit: 0,
            page: 1,
            shopId: listAllShop && listAllShop[shopSelectedIndex]?.value?.id,
            toDate: moment(period?.toDate).utc(0).format(),
            fromDate: moment(period?.fromDate).utc(0).format(),
        }
        if (!params.status) delete params.status
        if (!params.shopId) delete params.shopId
        const res = await withdrawProvider.getListTransfer(params)
        if (res?.data?.length) {
            const countWithdrawReady = res?.data?.filter((item) => item.status !== 'transferred')
            setCountWithdrawReady(countWithdrawReady.length)
            setAllWithdraw(res)
        }

        return res
    })
    const handleCountWithdrawTotalSum = (price) => {
        const sumWithInitial = price.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0,
        );
        setSumWithdrawSelect(sumWithInitial)
    }

    return {
        listShop: withdraw.listAllShop,
        listTransfer,
        status,
        shopSelectedIndex,
        onChangeStatus,
        onChangeDate,
        onChangeShop,
        dateSelected,
        dateOption,
        showButtonTransferred,
        showStatusWithdraw,
        fetchDataExcel,
        listAllShop,
        page, setPage,
        limit, setLimit,
        onChangePage,
        modalDetail, setmodalDetail,
        getListTransfer,
        onShowSizeChange,
        withdrawSelect,
        setWithdrawSelect,
        handleCheckWithdrawSelect,
        handleSelectAllWithdraw,
        allWithdraw,
        handleCountWithdrawTotalSum,
        sumWithdrawSelect,
        setSumWithdrawSelect,
        getAllWithdraw,
        countWithdrawReady,
        getListSellerWithdrawOrderGroup
    }

}

export default TransferDetail