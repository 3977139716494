
import axios from 'config/axios'
import host from 'config/host'

class withdrawProvider {

  onWithdrawById = async (params) => {
    try {
      const url = `${host.api}/seller-withdraw-orders/multi`
      const res = await axios.post(url, params)
      return res.data
    } catch (error) {
      throw error
    }
  }

  getOrderWithdrawBySellerWithdrawId = (params) => {
    try {
      const url = `${host.api}/seller-withdraw-orders/seller-withdraw/${params.id}`
      const res = axios.get(url)
      return res
    } catch (error) {
      console.log('error', error)
    }
  }

  getOrderWithdrawBySellerWithdrawGroup = (params) => {
    try {
      const url = `${host.api}/seller-withdraw-orders/seller-withdraw`
      const res = axios.post(url,params)
      return res
    } catch (error) {
      console.log('error', error)
    }
  }

  getListWithdraw = async (params) => {
    try {
      const url = `${host.api}/dashboards/withdraw`
      let param = new URL(url)
      if (params) {
        for (const [key, value] of Object.entries(params)) {
          param.searchParams.set(key, value);
        }
      }
      const res = await axios.get(url + param?.search)
      return res.data
    } catch (error) {
      throw error
    }
  }

  getListSellerWithdrawOrder = async (params) => {
    try {
      const url = `${host.api}/seller-withdraw-orders/list`
      let param = new URL(url)
      if (params) {
        for (const [key, value] of Object.entries(params)) {
          param.searchParams.set(key, value);
        }
      }
      const res = await axios.get(url + param?.search)
      return res.data
    } catch (error) {
      throw error
    }
  }

  getListTransfer = async (params) => {
    try {
      const url = `${host.api}/seller-withdraws`
      let param = new URL(url)
      if (params) {
        for (const [key, value] of Object.entries(params)) {
          param.searchParams.set(key, value);
        }
      }
      const res = await axios.get(url + param?.search)
      return res.data
    } catch (error) {
      throw error
    }
  }

  createTransferSellerById = async (params) => {
    try {
      const data = {
        attachmentFile: [
          params.attachmentFile
        ],
        totalAmount: parseFloat(params.totalAmount),
        transferredDate: params.transferredDate,
        remark: ""
      }
      // if(!params.attachmentFile) delete data.attachmentFile
      const url = `${host.api}/seller-withdraws/transfer/${params.id}`
      const res = await axios.patch(url, data)
      return res.data
    } catch (error) {
      // console.log('error', error)
      throw error
    }
  }

  createMultiTransferSellerById = async (params) => {
    try {
      const data = {
        sellerWithdrawId: params.id,
        attachmentFile: [
          params.attachmentFile
        ],
        totalAmount: parseFloat(params.totalAmount),
        transferredDate: params.transferredDate,
        remark: ""
      }
      // if(!params.attachmentFile) delete data.attachmentFile
      const url = `${host.api}/seller-withdraws/transfers`
      const res = await axios.patch(url, data)
      return res.data
    } catch (error) {
      // console.log('error', error)
      throw error
    }
  }



}

export default new withdrawProvider()