export const SET_LIST_SHOP_NAME = "SET_LIST_SHOP_NAME"
export const GET_LIST_SHOP = "GET_LIST_SHOP"
export const ADD_KEY_VALUE_SHOP = "ADD_KEY_VALUE_SHOP"
export const GET_SHOPS = "GET_SHOPS"
export const GET_USER_SHOPS = "GET_USER_SHOPS"
export const SET_SHOPS = "SET_SHOPS"
export const GET_SHOPS_SEARCH = "GET_SHOPS_SEARCH"
export const SET_SHOPS_SEARCH = "SET_SHOPS_SEARCH"
export const GET_SHOP_DETAIL = "GET_SHOP_DETAIL"
export const SET_SHOP_DETAIL = "SET_SHOP_DETAIL"
export const SET_CURRENT_SHOP = "SET_CURRENT_SHOP"
export const SET_CURRENT_POST = "SET_CURRENT_POST"
export const ON_SEARCH_SHOP = "ON_SEARCH_SHOP"
export const SET_PAGINATION_SHOP_DETAIL = "SET_PAGINATION_SHOP_DETAIL"
export const SET_PAGINATION_POSTS = "SET_PAGINATION_POSTS"
export const SET_DATA_SHOP_DETAIL = "SET_DATA_SHOP_DETAIL"
export const GET_ALL_SHOPS = "GET_ALL_SHOPS"
export const SET_ALL_SHOPS = "SET_ALL_SHOPS"
export const CREATE_SHOP = 'CREATE_SHOP'
export const CREATE_SHOP_FIELD = 'CREATE_SHOP_FIELD'
export const UPDATE_SHOP_DETAIL = 'UPDATE_SHOP_DETAIL'
export const GET_SHOPS_HUBS = 'GET_SHOPS_HUBS'
export const SET_SHOPS_HUBS = 'SET_SHOPS_HUBS'
export const CREATE_SHOPS_HUBS = 'CREATE_SHOPS_HUBS'
export const HANDLE_UPDATE_SHOP_TAX = "HANDLE_UPDATE_SHOP_TAX"
export const GET_SHOP_TAX = "GET_SHOP_TAX"
export const SET_SHOP_TAX = "SET_SHOP_TAX"
export const GET_CURRENT_SHOP_DETAIL = "GET_CURRENT_SHOP_DETAIL"
export const GET_SHOPS_COUPON = "GET_SHOPS_COUPON"
export const SET_CREATE_SHOP_TMP = "SET_CREATE_SHOP_TMP"
export const UPLOAD_LOGO_SHOP = "UPLOAD_LOGO_SHOP"
export const SET_KEY_SHOP_DETAIL = "SET_KEY_SHOP_DETAIL"
export const GET_SHOP_SELECTED = "GET_SHOP_SELECTED"
export const GET_SHOPS_PROMOTION = "GET_SHOPS_PROMOTION"
export const GET_SHOPS_PROMOTION_SEARCH = "GET_SHOPS_PROMOTION_SEARCH"


export function dispatchShop(params) {
    return {
        type: params.type,
        payload: params.payload
    }
}
