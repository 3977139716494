/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import FilterSearch from 'components/FilterSearch'
import { H5 } from 'components/FontStyle'
import SelectInput from 'components/SelectInput'
import { getUserRole } from 'helper/utils'
import { OWNER } from 'layout/Sidebar/Role'
import 'styles/_orderOntheway.scss';
import orderProvider from 'provider/orderProvider'
// import PaginationComponent from 'components/pagination'
import shopProvider from 'provider/shopProvider'
import { differenceDate, displayDateThShortYear } from 'helper/timeUtil'
import iBanking from 'images/icons/i-banking.svg'
import credit from 'images/icons/credit.svg'
import cod from 'images/icons/cod.svg'
import transfer from 'images/icons/transfer.svg'
import QRCode from 'images/icons/qrcode.svg'
import True from 'images/icons/true.svg'
import IconNoOrder from 'images/icons/no-item.svg'
import host from 'config/host'
import ModalLargeChildren from 'components/ModalLargeChildren'
import DatePickerInputComponent from 'components/DatePickerInput'
import TimePickerComponent from 'components/Timepicker'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { dispatchNotification, OPEN_TOAST } from 'redux/actions/notification'
import { dispatchApp, SET_LOADING } from 'redux/actions/app'
import Loading from 'components/Loading'
import ModalInvoiceDetail from 'components/modalInvoiceDetail'
import { dispatchOrder, GET_LIST_ORDER, GET_ORDER_DELIVERIES, OPEN_MODAL_INVOICE_DETAIL, SET_DEFAULT_ORDER, SET_ORDER_FIELD } from 'redux/actions/order'
import { dispatchProduct, GET_LIST_LOGISTICS_BY_SHOP } from 'redux/actions/product'
import { useSelector } from 'react-redux'
import ModalCoverSheetPrint from 'components/ModalCoverSheetPrint'

const api_shop = new shopProvider()

const OrderOntheway = () => {

    const { shop,
        // setShop,
        // search,
        // setSearch,
        searchFilter,
        // setSearchFilter,
        pagination,
        // setPagination,
        shopSelected,
        setShopSelected,
        fetchShop,
        fetchData,
        listOrder,
        // setListOrder,
        status,
        searchFilterSelected,
        setSearchFilterSelected,
        searchFilterValue,
        setSearchFilterValue,
        handleSearchFilter,
        dateTimeSelected,
        setDateTimeSelected,
        modalManualDerivery,
        setModalManualDerivery,
        onClickCompleteLogistic,
        setLogisticOrder,
        setCurrentInvoice,
        statusSearch,
        handleEnterSearch,
        getListPromocode } = useHookOrderDelay()
    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            await getListPromocode()
            await fetchShop()
        })()
        return () => {
            // if(!modalManualDerivery.isOpen){
            setLogisticOrder(null)
            cleanup()
        }
        // }
    }, [])

    useEffect(() => {
        (async () => {
            // dispatchOrder({ type: SET_DEFAULT_ORDER })
            if (shopSelected !== null) {
                if (statusSearch) {
                    await handleSearchFilter({ shopId: shopSelected && shop[shopSelected].value.id })
                } else {
                    await fetchData({ status, shopId: shopSelected && shop[shopSelected].value.id, page: pagination.page, limit: pagination.limit })
                }
            }
        })()
    }, [shopSelected])

    // useEffect(() => {
    //     if(searchFilterValue?.trim().length > 0){
    //         dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: true } }))
    //     }
    //     return () => {
    //         dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: false } }))
    //     }
    // }, [searchFilterValue])

    const cleanup = () => {
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'status', value: null } }))
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'listOrder', value: [] } }))
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'limit', value: 10 } }))
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: false } }))
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchValue', value: null } }))
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchSelect', value: 'name' } }))
        dispatch(dispatchOrder({ type: SET_DEFAULT_ORDER }))
    }




    const handleSearchShop = (data) => {
        setShopSelected(data)
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'searchShopSelect', value: shop[data] } }))
    }

    const onClearSearchFilterType = async () => {
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: false } }))
        setSearchFilterValue(null)
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchValue', value: null } }))
        dispatch(dispatchOrder({ type: GET_LIST_ORDER }))
        // await dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'searchFilterValue', value: null } }))
        // await fetchData()
    }

    return (
        <div className="order-history">
            <div className="header-order">
                <H5>รายการสั่งซื้อ </H5>
            </div>

            <div className="search-container">
                <div style={{ width: '100%', display: 'flex' }}>
                    {getUserRole() !== OWNER &&
                        <div className="w-50">
                            <SelectInput
                                width={'98%'}
                                options={shop}
                                onChange={(data) => handleSearchShop(data)}
                                value={shopSelected}
                                onClearSearchShop={() => setShopSelected(null)}
                            />
                        </div>
                    }
                    <FilterSearch
                        width={'50%'}
                        onChange={(data) => setSearchFilterSelected(data)}
                        options={searchFilter}
                        value={searchFilterValue}
                        onChangeValue={(e) => setSearchFilterValue(e)}
                        onClearSearchFilterType={() => onClearSearchFilterType()}
                        valueTypeFilter={searchFilterSelected}
                        onSearchOrder={() => handleSearchFilter({ shopId: shopSelected && shop[shopSelected].value?.id })}
                        onKeyPress={(e) => handleEnterSearch(e)}
                        statusSearch={statusSearch}
                    />
                </div>
                <div>
                    {/* <button className='btn btn-yellow' style={{width:'185px'}}> ดาวน์โหลดรายการจัดส่ง </button>  */}
                </div>
            </div>

            <div className="list-order-item">
                <TableOrder
                    listOrder={listOrder}
                    modalManualDerivery={modalManualDerivery}
                    setModalManualDerivery={setModalManualDerivery}
                    setCurrentInvoice={setCurrentInvoice}
                />
            </div>

            <ModalLargeChildren
                isOpen={modalManualDerivery.isOpen}
                headerMsg={modalManualDerivery.headerMsg}
                isClose={() => setModalManualDerivery({ isOpen: false })}
                confirm={() => onClickCompleteLogistic(modalManualDerivery.data)}
            >
                <div>
                    {
                        // getUserRole() === 'SUPER_ADMIN' && 
                        // deliveries && deliveries[deliveries?.length - 1]?.logistic !== 'EMS'
                        // && currentInvoice?.logisticStatus !== "delivered" && currentInvoice?.logisticStatus !== "wait"
                        // ? 
                        <div>
                            <h4 className="d-flex align-items-start" style={{ margin: '1rem' }}>ระบุวันที่ส่งสำเร็จไปแล้ว</h4>
                            <div className='d-flex align-items-start flex-column' style={{ margin: '1rem' }}>
                                <span className="require font-primary">วันที่</span>
                                <DatePickerInputComponent /// here
                                    onSubmitDateSelected={(date) => setDateTimeSelected({ ...dateTimeSelected, date })}
                                    allowpreviousDate={true} /// this allowpreviousDate control previousDate on/off
                                    date={new Date(dateTimeSelected.date)}
                                />
                            </div>

                            <div className='d-flex align-items-start flex-column' style={{ margin: '1rem' }}>
                                <span className="require font-primary">เวลา</span>
                                <TimePickerComponent
                                    onTimePickerChange={(time) => setDateTimeSelected({ ...dateTimeSelected, time })}
                                    value={dateTimeSelected.time}
                                />
                            </div>
                            <Loading />
                        </div>
                    }
                </div>
            </ModalLargeChildren>

            {/* <div>
                <PaginationComponent

                />
            </div> */}

            <ModalInvoiceDetail
                order={listOrder}
                search={shopSelected}
                filter={searchFilterValue}
            />

            <ModalCoverSheetPrint />

        </div>
    )
}


const TableOrder = (props) => {
    const { listOrder, modalManualDerivery, setModalManualDerivery, setCurrentInvoice } = props

    const showMethodPayment = (detail) => {
        switch (detail.paymentMethod) {
            case 'transfer':
                return <img src={transfer} alt="" id={'typePay-' + detail.id} />
            case 'ibanking':
                return <img src={iBanking} alt="" id={'typePay-' + detail.id} />
            case 'cod':
                return <img src={cod} alt="" id={'typePay-' + detail.id} />
            case 'creditcard':
                return <img src={credit} alt="" id={'typePay-' + detail.id} />
            case 'qrcode':
                return <img src={QRCode} alt="" id={'typePay-' + detail.id} />
            default:
                return null
        }
    }

    const checkLogisticStatus = (type, shipmentStatus) => {
        if (shipmentStatus === "waitconfirm") {
            return <label className="badge wait-payment">รอรับออเดอร์</label>
        }
        if (type === "wait" || type === "redelivery") { return <label className="badge wait-approve">รอจัดส่ง</label> }
        else if (type === "posting" || type === "outdelivery") { return <label className="badge wait-approve">อยู่ระหว่างขนส่ง</label> }
        else if (type === "intransit") { return <label className="badge wait-approve"> อยู่ระหว่างขนส่ง</label> }
        else if (type === "delivered") { return <label className="badge success-order"><img src={True} alt="check" className="check-success-pay" /> สำเร็จ</label> }
        else if (type === "issues" || type === "return") { return "ยกเลิก" }
        ////refund
        else if (type === "request") { return <label className="badge wait-approve">รอการคืนเงิน</label> }
        else if (type === "refunded") { return <label className="badge success-order">คืนเงินแล้ว</label> }
        ////refund
        else { return type }
    }


    return (
        <div id='order-list-delay'>
            <div className="table-header">
                <div>ลำดับ</div>
                <div>หมายเลขออเดอร์</div>
                <div>ร้านค้า</div>
                <div>ชื่อลูกค้า</div>
                <div>วันที่ชำระเงิน</div>
                <div>วันที่จัดส่ง</div>
                <div>ผู้ให้บริการขนส่ง</div>
                <div>หมายเลขพัสดุ</div>
                <div>จังหวัดที่ส่ง</div>
                <div>สถานะการจัดส่ง</div>
                <div>จัดส่งมาแล้ว</div>
                <div></div>
            </div>
            {listOrder?.data?.length > 0 ?
                listOrder?.data?.map((order, orderIndex) => (
                    <div className="table-content cursor-pointer" key={`order${orderIndex}`}>
                        <div onClick={() => setCurrentInvoice(order)}>{orderIndex + 1}</div>
                        <div className='color-info-main' onClick={() => setCurrentInvoice(order)}>{order?.orderNo}</div>
                        <div onClick={() => setCurrentInvoice(order)}>{order?.shop?.name}</div>
                        <div onClick={() => setCurrentInvoice(order)}>{order?.customerInformation?.name}</div>
                        <div onClick={() => setCurrentInvoice(order)}>
                            <span className="mr-2">{showMethodPayment(order)}</span>
                            {displayDateThShortYear(order.paymentedAt)}</div>
                        <div onClick={() => setCurrentInvoice(order)}>{displayDateThShortYear(order?.dispatchAt)}</div>
                        <a target="_blank" href={order.delivery.logistics.urlTracking}> <img src={host.image + order?.delivery?.logistics?.logo} alt="" style={{ maxWidth: '4em' }} /> </a>
                        <div onClick={() => setCurrentInvoice(order)}>{order?.delivery?.trackingNo}</div>
                        <div onClick={() => setCurrentInvoice(order)}>{order?.customerInformation?.province}</div>
                        <div onClick={() => setCurrentInvoice(order)}>{checkLogisticStatus(order?.logisticStatus, order?.shipmentStatus)}</div>
                        <div onClick={() => setCurrentInvoice(order)}>{differenceDate(new Date(), order?.dispatchAt)} วัน</div>
                        <div className='cursor-pointer color-info-main' onClick={() => setModalManualDerivery({ ...modalManualDerivery, isOpen: true, data: order })}>เปลี่ยนสถานะ</div>
                    </div>
                )) :
                <div className='icon-no-item'>
                    <img src={IconNoOrder} alt="" />
                    <span>ไม่มีรายการ</span>
                </div>
            }
        </div>
    )
}

const useHookOrderDelay = () => {
    const dispatch = useDispatch()
    const [listOrder, setListOrder] = useState([])
    const [shop, setShop] = useState([])
    const [search, setSearch] = useState(null)
    const [searchFilter, setSearchFilter] = useState([{ label: 'ชื่อลูกค้า', value: 'name' }, { label: 'หมายเลขออเดอร์', value: 'orderNo' }])
    const [searchFilterSelected, setSearchFilterSelected] = useState(0)
    const [searchFilterValue, setSearchFilterValue] = useState(null)
    const [pagination, setPagination] = useState({ page: 1, limit: 9999 })
    const [shopSelected, setShopSelected] = useState(null)
    const [status, setStatus] = useState('onTheWayDelay')
    const [dateTimeSelected, setDateTimeSelected] = useState({ date: moment().format('MM/DD/YYYY'), time: moment().utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) })
    const [modalManualDerivery, setModalManualDerivery] = useState({ isOpen: false, data: null, headerMsg: null })
    const [logisticOrder, setLogisticOrder] = useState(null)
    const listOrderRedux = useSelector(state => state.order.listOrder)
    const statusSearch = useSelector(state => state.order.statusSearch)

    useEffect(() => {
        setListOrder(listOrderRedux)
    }, [listOrderRedux])

    useEffect(() => {
        (async () => {
            if (modalManualDerivery.isOpen) {
                const res = await getDeliveryOrder(modalManualDerivery.data?.id)
                if (res.data) {
                    setLogisticOrder(res.data)
                }
            }
        })()

    }, [modalManualDerivery.isOpen])

    const fetchData = async (params) => {
        try {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            if (statusSearch) {
                handleSearchFilter()
            } else {
                const res = await orderProvider.getListOrder({ status: status, shopId: shopSelected && shop[shopSelected].value.id, limit: pagination.limit, page: pagination.page })
                if (res) {
                    setListOrder(res)
                    dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'status', value: 'onTheWayDelay' } }))
                    dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'listOrder', value: res } }))
                    dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
                    dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'limit', value: 9999 } }))
                }
            }
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        } catch (error) {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            throw error
        }
    }
    const fetchShop = async () => {
        try {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            const res = await api_shop.getAllListShops()
            const data = [{ label: 'ร้านทั้งหมด', value: '' }]
            Promise.all(res.map((shop, shopIndex) => {
                data.push({ label: shop.name, value: shop })
            }))
            setShop(data)
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
        } catch (error) {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
            throw error
        }
    }

    const getListPromocode = async () => {
        dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
        const res = await orderProvider.getListOrder({ status: status, shopId: shopSelected && shop[shopSelected].value.id, limit: pagination.limit, page: pagination.page })
        if (res) {
            setListOrder(res)
            dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'status', value: 'onTheWayDelay' } }))
            dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'listOrder', value: res } }))
            dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
            dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'limit', value: 9999 } }))
        }
        dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
    }

    const handleSearchFilter = async (params) => {
        dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: true } }))
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchSelect', value: searchFilter[searchFilterSelected].value } }))
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchValue', value: searchFilterValue } }))
        if (!searchFilterValue || searchFilterValue.trim().length === 0) {
            fetchData()
        }
        const res = await orderProvider.onFilterSearchOrder({
            status: status,
            shopId: shopSelected && shop[shopSelected].value.id,
            limit: pagination.limit,
            page: pagination.page,
            kwby: searchFilter[searchFilterSelected].value,
            kw: searchFilterValue
        })
        if (res) {
            setListOrder(res.data)
        }
        dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
    }

    const handleEnterSearch = async (e) => {
        dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: true } }))
        if (e.key === 'Enter') {
            if (searchFilterValue) {
                handleSearchFilter({ shopId: shopSelected && shop[shopSelected].value.id })
            } else {
                fetchData()
            }
        }
    }

    const onClickCompleteLogistic = async (order) => {
        try {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
            const dateString = moment(dateTimeSelected.date).format('YYYY-MM-DD')
            const timeString = moment(dateTimeSelected.time).format('HH:mm')
            const releaseDate = `${dateString}T${timeString}:00.000+07:00`
            const RES = await orderProvider.updateLogisticComplete({ detail: logisticOrder, dateString, timeString, releaseDate })
            // yield put({ type: SET_UPDATE_LOGISTIC_RES, payload: { RES } });

            if (RES) {
                fetchData()
                dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
                setModalManualDerivery({ isOpen: false })
                dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } }))
            }
        } catch (error) {
            dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
            dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `เกิดข้อผิดพลาด`, type: 'alert' } }))
        }
    }

    const getDeliveryOrder = async (orderId) => {
        const res = await orderProvider.getOrderDeliveriesByOrderId(orderId)
        return res
    }

    const setCurrentInvoice = invoice => {
        dispatch(dispatchOrder({ type: OPEN_MODAL_INVOICE_DETAIL, payload: { currentInvoice: invoice, isOpen: true } }));
        dispatch(dispatchProduct({ type: GET_LIST_LOGISTICS_BY_SHOP }))
        dispatch(dispatchOrder({ type: GET_ORDER_DELIVERIES, payload: { orderId: invoice.id } }))
    }

    return {
        shop,
        setShop,
        search,
        setSearch,
        searchFilter,
        setSearchFilter,
        searchFilterSelected,
        setSearchFilterSelected,
        pagination,
        setPagination,
        shopSelected,
        setShopSelected,
        fetchShop,
        fetchData,
        listOrder,
        setListOrder,
        status,
        searchFilterValue,
        setSearchFilterValue,
        handleSearchFilter,
        dateTimeSelected,
        setDateTimeSelected,
        modalManualDerivery,
        setModalManualDerivery,
        onClickCompleteLogistic,
        logisticOrder,
        setCurrentInvoice,
        setLogisticOrder,
        statusSearch,
        handleEnterSearch,
        getListPromocode
    }

}

export default OrderOntheway
