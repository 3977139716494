import React, { Component } from "react"
import { connect } from "react-redux"
import "styles/_broadcast.scss"

import { ButtonAdd, InputSearch } from "components/output"
import { Button, Card, Col, DatePicker, Popconfirm, Row, Select, Tabs, Tooltip } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import Pagination from "components/pagination"
// import noItem from "../images/icons/status-error.svg"
import { Link } from "react-router-dom"

const initialState = {}
const { Option } = Select
const { TabPane } = Tabs

const MONTHS = [
  { name: "มกราคม", value: 1 },
  { name: "กุมภาพันธ์", value: 2 },
  { name: "มีนาคม", value: 3 },
  { name: "เมษายน", value: 4 },
  { name: "พฤษภาคม", value: 5 },
  { name: "มิถุนายน", value: 6 },
  { name: "กรกฎาคม", value: 7 },
  { name: "สิงหาคม", value: 8 },
  { name: "กันยายน", value: 9 },
  { name: "ตุลาคม", value: 10 },
  { name: "พฤศจิกายน", value: 11 },
  { name: "ธันวาคม", value: 12 },
]

class Broadcast extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState))
    return state
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-container">
          <div className="row row-between">
            <h5>บรอดแคสต์</h5>
            <div className="item-wrap">
              <Link to="/manage/broadcast/createbroadcast">
                <ButtonAdd label="สร้างบรอดแคสต์" handleAdd={() => { }} />
              </Link>
            </div>
          </div>
          <div className="filter-wrapper">
            <div className="w-50">
              <InputSearch />
            </div>

            <div className="dropdown-wrap">
              ตัวกรอง
              <Select
                className="filter-dropdown"
                suffixIcon={<FontAwesomeIcon className="icon-angle" icon={faAngleDown} />}
                placeholder="เดือน"
              >
                <Option value="">ทั้งหมด</Option>
                {MONTHS.map((item) => (
                  <Option key={item?.value} value={item?.value}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
              <DatePicker
                placeholder="ปี"
                className="year-filter-picker"
                picker="year"
                suffixIcon={<FontAwesomeIcon className="icon-angle" icon={faAngleDown} />}
              />
            </div>
          </div>
          <div className="content-wrapper">
            <Tabs className="tabs-broadcast" type="card">
              <TabPane tab="รอส่ง" key="pending">
                <Row className="header-wrap">
                  <Col span={1}>ลำดับ</Col>
                  <Col span={1}></Col>
                  <Col span={15}>ข้อความ</Col>
                  <Col span={3}>วันที่ส่ง</Col>
                  <Col span={3}>แก้ไขล่าสุด</Col>
                </Row>
                <Card className="broadcast-card mt-10">
                  <Row align="middle">
                    <Col span={1}>1</Col>
                    <Col span={1}>
                      <img loading="lazy" src="" alt='img-content'/>
                    </Col>
                    <Col span={15}>
                      <div className="name">แบรนด์ดัง รับซัมเมอร์</div>
                      <div className="description">
                        Hana Pet ลดสูงสุด 30% แชมพุ ของเล่น Hana Pet ลดสูงสุด 30% แชมพุ ของเล่น
                      </div>
                    </Col>
                    <Col span={3}>1 ก.ย. 63 18:00</Col>
                    <Col span={3}>1 ก.ย. 63 18:00</Col>
                    <Col span={1}>
                      <Popconfirm title="คุณต้องการลบใช่ไหม?" okText="ใช่" cancelText="ยกเลิก">
                        <Button
                          className="icon-wrapper"
                          shape="circle"
                          type="text"
                          icon={<FontAwesomeIcon icon={faTrashAlt} />}
                        ></Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </Card>
                {/* <div className="not-found-item">
                  <img loading="lazy" src={noItem} />
                  <div className="text-not-found">ไม่มีรายการ</div>
                </div> */}
                <Pagination
                  total={10}
                  defaultCurrent={1}
                  // onChange={this.onChangePage}
                  pageSize={10}
                  // pageSizeOptions={product.pageSizeOptions}
                  // onShowSizeChange={this.onShowSizeChange}
                  current={1}
                  showQuickJumper={true}
                />
              </TabPane>
              <TabPane tab="ประวัติการส่ง" key="history">
                <Row className="header-wrap">
                  <Col span={1}>ลำดับ</Col>
                  <Col span={1}></Col>
                  <Col span={5}>ข้อความ</Col>
                  <Col className="text-center" span={5}>
                    อีเมล
                    <div className="email-wrapper">
                      <div>จำนวนส่ง</div>
                      <div>จำนวนที่คลิก</div>
                    </div>
                  </Col>
                  <Col className="text-center" span={5}>
                    แอพพลิเคชั่น / เว็บไซต์
                    <div className="email-wrapper">
                      <div>จำนวนส่ง</div>
                      <div>จำนวนที่คลิก</div>
                    </div>
                  </Col>
                  <Col span={3}>วันที่ส่ง</Col>
                  <Col span={3}>แก้ไขล่าสุด</Col>
                </Row>
                <Card className="broadcast-card mt-10">
                  <Row align="middle">
                    <Col span={1}>1</Col>
                    <Col span={1}>
                      <img loading="lazy" src="" alt='img-content'/>
                    </Col>
                    <Col span={5}>
                      <Tooltip title="แบรนด์ดัง รับซัมเมอร์แบรนด์ดัง รับซัมเมอร์">
                        <div className="name">แบรนด์ดัง รับซัมเมอร์แบรนด์ดัง รับซัมเมอร์</div>
                      </Tooltip>
                      <Tooltip title="Hana Pet ลดสูงสุด 30% แชมพุ ของเล่น Hana Pet ลดสูงสุด 30% แชมพุ ของเล่น">
                        <div className="description">
                          Hana Pet ลดสูงสุด 30% แชมพุ ของเล่น Hana Pet ลดสูงสุด 30% แชมพุ ของเล่น
                        </div>
                      </Tooltip>
                    </Col>
                    <Col offset={1} span={2}>
                      1200
                    </Col>
                    <Col span={2}>0</Col>
                    <Col offset={1} span={2}>
                      1200
                    </Col>
                    <Col span={2}>0</Col>
                    <Col span={3}>1 ก.ย. 63 18:00</Col>
                    <Col span={3}>1 ก.ย. 63 18:00</Col>
                    <Col span={1}>
                      <Popconfirm title="คุณต้องการลบใช่ไหม?" okText="ใช่" cancelText="ยกเลิก">
                        <Button
                          className="icon-wrapper"
                          shape="circle"
                          type="text"
                          icon={<FontAwesomeIcon icon={faTrashAlt} />}
                        ></Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </Card>
                {/* <div className="not-found-item">
                  <img loading="lazy" src={noItem} />
                  <div className="text-not-found">ไม่มีรายการ</div>
                </div> */}
                <Pagination
                  total={10}
                  defaultCurrent={1}
                  // onChange={this.onChangePage}
                  pageSize={10}
                  // pageSizeOptions={product.pageSizeOptions}
                  // onShowSizeChange={this.onShowSizeChange}
                  current={1}
                  showQuickJumper={true}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
})

export default connect(mapStateToProps)(Broadcast)
