export const ADD_KEY_VALUE_SELECTEDED_PRODUCT = "ADD_KEY_VALUE_SELECTEDED_PRODUCT"
export const ADD_SKU_PROMOCODE = "ADD_SKU_PROMOCODE"
export const UPDATE_SKU_PROMOCODE = "UPDATE_SKU_PROMOCODE"
export const REMOVE_SKU_PRODUCT_PROMOCODE = "REMOVE_SKU_PRODUCT_PROMOCODE"

export const RESET_PRODUCT_SELECTED = "RESET_PRODUCT_SELECTED"

export function dispatchSelectedProduct(param){
    return {
      type: param.type,
      payload: param.payload,
    };
  }