/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radio from '../components/RadioButton';
import DropDown from '../components/DropDownList';
import Editor from '../components/Editor';
import '../styles/_addShop.scss';
import { ReactComponent as IconCamera } from '../images/icons/camera.svg';
import IconShopProfile from '../images/icons/shop-profile-default.svg';
import IconIncrease from '../images/icons/increse.svg';
// import { convertToRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
// import question from '../images/icons/question.svg'

class AddShop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            company: {
                companyName: ["กรอกชื่อบริษัท", "Apple", "Sony", "Microsoft", "Samsung", "Disney"],
                SelectedCompany: { index: 0 },
                editorState: null,
                htmlRender: ""
            }
        }
    }

    onChange = (e) => {

    }

    onChangeRadio = (e) => {

    }

    onEditorChange = (htmlString) => {

    }

    render() {

        // const { editorState, htmlRender } = this.state

        return <React.Fragment>
            <section className="shop-addition">
                <section className="tax-information">
                    <h4>ข้อมูลทางภาษี</h4>
                    <p> <span className="title">ประเภทของร้านค้า</span> </p>
                    <div className="row">
                        <div className="col-3">
                            <Radio type="radio" value="individual" valueOption="primary" name="person" onChange={this.onChangeRadio} />
                            <label className="form-check-label">
                                นิติบุคคล
                            </label>
                        </div>
                        <div className="col-3">
                            <Radio type="radio" value="primary" valueOption="primary" name="person" onChange={this.onChangeRadio} />
                            <label className="form-check-label">
                                บุคคลธรรมดา
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> ชื่อบริษัท </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"กรอกชื่อบริษัท"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                        <div className="col-6">
                            <label className="form-label"> เลขทะเบียนนิติบุคคลหรือทะเบียนพาณิชย์ </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกเลขทะเบียนนิติบุคคลหรือทะเบียนพาณิชย์" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 col-center">
                            <Radio type="radio" value="individual" valueOption="primary" name="branch" onChange={this.onChangeRadio} />
                            <label className="form-check-label">
                                สำนักงานใหญ่
                            </label>
                        </div>
                        <div className="col-3 col-center">
                            <Radio type="radio" value="primary" valueOption="primary" name="branch" onChange={this.onChangeRadio} />
                            <label className="form-check-label">
                                สาขา
                            </label>
                        </div>

                        <div className="col-6">
                            <label className="form-label"> ระบุสาขา </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="ระบุสาขา" />
                        </div>
                    </div>

                    <p> <span className="title">ที่อยู่บริษัท</span> </p>
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label"> ถนน/บ้าน/บ้านเลขที่ </label>
                            <textarea placeholder="กรอกถนน/บ้าน/บ้านเลขที่" cols="30" rows="5" className="form-control text-field-shadow"></textarea>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> จังหวัด </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"เลือกจังหวัด"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                        <div className="col-6">
                            <label className="form-label"> เขต/อำเภอ </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"เลือกเขต/อำเภอ"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> แขวง/ตำบล </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"เลือกแขวง/ตำบล"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                        <div className="col-6">
                            <label className="form-label"> รหัสไปรษณีย์ </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกรหัสไปรษณีย์" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> เลขประจำตัวผู้เสียภาษี </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกเลขประจำตัวผู้เสียภาษี" />
                        </div>
                        <div className="col-6">
                            <label className="form-label"> เบอร์โทรบริษัท </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกเบอร์โทรบริษัท" />
                        </div>
                    </div>
                </section>
                <section className="contact-information">
                    <h4>ข้อมูลติดต่อ</h4>
                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> ชื่อนามสกุล </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกชื่อนามสกุล" />
                        </div>
                        <div className="col-6">
                            <label className="form-label"> เบอร์โทรผู้ติดต่อ </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกเบอร์โทรผู้ติดต่อ" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> ID LINE </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกID LINE" />
                        </div>
                        <div className="col-6">
                            <label className="form-label"> อีเมล </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกอีเมล" />
                        </div>
                    </div>

                    <p> <span className="title">ข้อมูลบัญชีธนาคารเพื่อนำส่งรายได้</span> </p>

                    <div className="row">
                        <div className="col-6">
                            <div className="select-bank">
                                <img src={IconIncrease} />
                                <span>กดเพื่อเลือกธนาคาร</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> เลขที่บัญชี </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"กรอกชื่อบริษัท"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                        <div className="col-6">
                            <label className="form-label"> สาขา </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"กรอกชื่อบริษัท"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> ชื่อบัญชี(ต้องตรงกับชื่อนิติบุคคลเท่านั้น) </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"กรอกชื่อบริษัท"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                    </div>
                </section>
                <section className="primary-information">
                    <h4>ข้อมูลทั่วไป</h4>
                    <div className="row">
                        <div className="col-12">
                            <span className="form-title"> รูปโปรไฟล์ร้านค้า </span>
                            <span className="form-detail">(ขนาดไฟล์: สูงสุด 1 MB ไฟล์ที่รองรับ: .JPEG, .PNG)</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="shop-profile">
                                <div className="shop-profile-image">
                                    <img src={IconShopProfile} />
                                    <a href="#">
                                        <IconCamera className="shop-profile-image-camera" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label"> ชื่อร้านค้า <span className="form-detail">(จำกัด 200 ตัวอักษร)</span> </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกชื่อร้านค้า" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <label className="form-label"> รายละเอียดร้านค้า </label>
                            <Editor onChange={this.onEditorChange} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            { }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> TAG </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกTAG" />
                        </div>
                        <div className="col-6">
                            <label className="form-label"> Category </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"เลือก Category"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                    </div>
                </section>
                <section className="address-information">
                    <h4>ที่อยู่ร้านค้า</h4>

                    <div className="row">
                        <div className="col-12">
                            <label className="form-label"> ถนน/บ้าน/บ้านเลขที่ </label>
                            <textarea placeholder="กรอกที่อยู่ร้านค้า" cols="30" rows="5" className="form-control text-field-shadow"></textarea>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> จังหวัด </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"เลือกจังหวัด"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                        <div className="col-6">
                            <label className="form-label"> เขต/อำเภอ </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"เลือกเขต/อำเภอ"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="form-label"> แขวง/ตำบล </label>
                            <DropDown onChange={this.onChange}
                                options={this.state.company.companyName}
                                placeholder={"เลือกแขวง/ตำบล"}
                                selectedIndex={this.state.company.SelectedCompany.index}>
                            </DropDown>
                        </div>
                        <div className="col-6">
                            <label className="form-label"> รหัสไปรษณีย์ </label>
                            <input type="text" className="form-control text-field-shadow" placeholder="กรอกรหัสไปรษณีย์" />
                        </div>
                    </div>
                </section>

                <section className="form-submit">
                    <div className="row">
                        <div className="col text-center">
                            <button className="btn btn-ignore">ยกเลิก</button>
                            <button className="btn btn-confirm">สร้าง</button>
                        </div>
                    </div>
                </section>
            </section>
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    user: state.user,
})

export default connect(mapStateToProps)(AddShop)

