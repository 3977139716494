import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Body2, H6 } from "components/FontStyle";
import BoxTitlePopUp from "components/BoxTitlePopUp";
import { Link } from 'react-router-dom';
import ProductItemTopViewer from "components/ProductItemTopViewer";
import ViewLineChart from "components/ViewLineChart";
import LineChartMulti from "components/LineChartMultiAxis";

const TopViewerWidget = (props) => {
  const { chartTopViewer:chartTopViewerProps, chartRange:chartRangeProps } = props
  const { dashboardReport } = useSelector((state) => state.dashboard);
  const dashboardReportList = dashboardReport?.productsView
  const [chartTopViewer, setChartTopViewer] = useState(null);
  const [chartRange, setChartRange] = useState(null);

  useEffect(() => {
    setChartTopViewer(chartTopViewerProps)
  }, [chartTopViewerProps]);
  useEffect(() => {
    setChartRange(chartRangeProps)
  }, [chartRangeProps]);

  return (
    <div className="graph-dashboard">
      <div className="d-flex flex-row justify-content-between">
        <BoxTitlePopUp textTimeRange={"ช่วงเวลาที่เกิดยอดวิว"} textTooltip={"ยอดวิวที่เกิดขึ้นในช่วงระยะเวลาที่เลือก"} />
        <Link
          to={{
            pathname: "/manage/dashboard/top-viewer",
            state: {
            },
          }}
        >
          <Body2 className="color-ais-primary-main cursor-pointer">
            {"ดูยอดวิวทั้งหมด"} {`>>`}
          </Body2>
        </Link>
      </div>
      <div
        className={["topview-groups-row"]}
        style={{
          '--maxWTopView': dashboardReportList?.length <= 2 ? '220px' :
            dashboardReportList?.length <= 4 ? '180px' :
              dashboardReportList?.length <= 6 ? '140px' :
                dashboardReportList?.length <= 8 ? '80px' :
                  dashboardReportList?.length <= 10 ? 'unset' : ''
        }}
      >

        {dashboardReport?.productsView?.length > 0 && dashboardReport?.productsView?.map((e, index) => (
          <ProductItemTopViewer
            key={index}
            index={index}
            name={e?.name}
            imagePath={e?.imagePath}
          />
        ))}
      </div>
      {/* <ViewLineChart showTextEvery={chartRange}/> */}
      <LineChartMulti
        data={chartTopViewer} showTextEvery={chartRange}
      />
    </div>
  );
};

export default TopViewerWidget;
