import axios from '../config/axios'
import host from '../config/host'
import Cookies from 'js-cookie'

import moc_joinProductShop from '../moc/join-promotion.json'
import moc_ProductShop_6 from '../moc/product?shopid=6.json'
const tokenSecretKey = {
    headers: {
        'secret-key': host.tokenSecretKey
    }
}


export const objProduct = (data) => {
    const { product = [], count, countSku } = data
    const array = product.map((item) => {
        const { id, coverPath, productSkus, name, status, unitType, remains, description,prepareDay } = item
        const skus = productSkus.map((skuitem) => {
            const { id, imagePath, priceNet, sku, stockRemain, status, size, sellerSKU, skuCode,barcode,priceList,weight } = skuitem
            return { skuId: ` ${id}`, imagePath, priceNet, sku, stockRemain, status, size, sellerSKU, skuCode,barcode,priceList,weight,description,prepareDay,productName: name }
        })
        return { coverPath, productId: ` ${id}`, productSkus: skus, name, status, unitType, remains,description,prepareDay }
    })
    const obj = product2obj(array)
    return { product: obj, count, countSku }
}

export const objProductShop = (data) => {
    const { shops = [], count } = data
    const array = shops.map((s) => {
        const { id, logoPath, skuJoin, name, countProductJoin, countSkuJoin, remains } = s
        return { id:` ${id}`, logoPath, skuJoin, name, countProductJoin:countProductJoin, countSkuJoin:countSkuJoin, remains }
    })
    return { shops: array, count }
}


const product2obj = (product) => {
    const data = product.reduce(function (r, a) {
        a.productSkus = sku2obj(a.productSkus)
        a.productId = (typeof a.productId == 'string') ? a.productId : ` ${a.productId}` // เปลี่ยน type(string) เพิ่มช่องว่างเพื่อกันให้ออโต้เรียงตาม id ที่เป็น number
        if (r[a.productId] == undefined) r[a.productId] = (a || {});
        return r;
    }, {});
    return data
}

const sku2obj = (productSkus) => {
    const data = productSkus.reduce(function (r, a) {
        a.skuId = (typeof a.skuId == 'string') ? a.skuId : ` ${a.skuId}` // เปลี่ยน type(string) เพิ่มช่องว่างเพื่อกันให้ออโต้เรียงตาม id ที่เป็น number
        if (r[a.skuId] == undefined) r[a.skuId] = (a || {});
        return r;
    }, {});
    return data
}

class productProvider {
    getListAllProduct = (params) => {
        const url = `${host.api}/products`
        let param = new URL(url)
        if (params) {
            for (const [key, value] of Object.entries(params)) {
                param.searchParams.set(key, value);
            }
        }

        const res = new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(url + param.search)
                resolve(res.data)
            } catch (error) {
                // reject(error)
            }
        })
        return res
    }
    onCreateProduct = async (params) => {
        const data = {
            createdBy: JSON.parse(Cookies.get('user')).id,
            shopId: params.shopId,
            // shopId: 4,
            // createdBy: 16,
            name: params.name,
            // nameEn: "Woman Bag",
            description: [{ contentType: "text", content: params.description }],
            coverPathId: params.coverPathId,
            imagePathIds: params.imagePathIds,
            videoPathIds: params.videoPathIds,
            saleType: params.saleType,
            unitType: "ชิ้น",
            categories: params.categories,
            seo: params.seo,
            status: params.status,
            brandId: params.brandId,
            prepareDay: params.prepareDay,
            digitalInfo: params.digitalInfo,
            productType: params.productType
        }
        // return new Promise(async (resolve, reject) => {
        try {
            localStorage.setItem('onCreateProduct', JSON.stringify(data))
            const result = await axios.post(host.api + "/products", data)
            return (result)
        } catch (error) {

            throw error
            // reject(error)
        }
        // })
    }
    getListCategories = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.get(host.api + "/playlists/cms/categories", tokenSecretKey)
                resolve(result.data)
            } catch (error) {
                throw error
            }
        })
    }
    getBrands = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.get(`${host.api}/brands`)
                resolve(result)
            } catch (error) {
                throw error
            }
        })
    }
    createProductSku = async (param) => {
        try {
            localStorage.setItem('createProductSku', JSON.stringify(param))
            const url = `${host.api}/product-sku`;
            const res = await axios.post(url, param)
            return res;

        } catch (error) {

            throw error
        }
    }
    getProductsByShop = async (param) => {
        try {
            const shopId = param.shopId;
            // const url = `${host.api}/products?shopId=${shopId}&limit=${param.limit}&page=${param.page}`;

            let url = '';
            /* NOTE see all product all shop if shopId = 14 */
            if (process.env.REACT_APP_BY_PASS_ALL_PRODUCT === 'ALL') {
                if (shopId !== 14)
                    url = `${host.api}/products?shopId=${shopId}&limit=${param.limit}&page=${param.page}`;
                else
                    url = `${host.api}/products?limit=${param.limit}&page=${param.page}`;
            } else {
                url = `${host.api}/products?shopId=${shopId}&limit=${param.limit}&page=${param.page}`;
            }

            const res = await axios.get(url)
            return res && res.data ? res.data : [];
        } catch (error) {

            throw error
        }
    }

    getAllProductByShop = async (params) => {
        const url = `${host.api}/products`
        let param = new URL(url)
        if (params) {
            for (const [key, value] of Object.entries(params)) {
                param.searchParams.set(key, value);
            }
        }
        try {
            // const url = `${host.api}/categories/all`
            const res = axios.get(url + param.search)
            return res
        } catch (e) {
            throw e
        }

    }

    getSearchProductsByShop = async (param) => {
        // try {
        // const header = {
        //     headers: {
        //         Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).accessToken
        //     }
        // }

        const { shopId, keyword, limit, page } = param;
        const params = {
            shopId,
            kw: keyword,
            limit,
            page
        }
        if (!keyword) { delete params.kw }
        const url = keyword ? `${host.api}/products/search-cms` : `${host.api}/products`;

        let newParam = new URL(url)
        if (params) {
            for (const [key, value] of Object.entries(params)) {
                newParam.searchParams.set(key, value);
            }
        }
        try {
            const res = await axios.get(url + newParam.search)
            return res && res.data ? res.data : [];
        } catch (e) {
            throw e
        }

        // const res = await axios.get(url)
        // } catch (error) {

        //     throw error
        // }
    }

    getSearchProducts = async (param) => {

        const { shopId, keyword, limit, page, scope, sortBy } = param;
        const params = {
            shopId,
            kw: keyword,
            limit,
            page,
            scope,
            sortBy
        }
        if (!keyword) delete params.kw
        if (!scope) delete params.scope
        if (!shopId) delete params.shopId
        if (!sortBy) delete params.sortBy

        if (!limit) delete params.limit
        if (!page) delete params.page


        const url = `${host.api}/products/search-cms`;
        let newParam = new URL(url)
        if (params) {
            for (const [key, value] of Object.entries(params)) {
                newParam.searchParams.set(key, value);
            }
        }
        try {
            const res = await axios.get(url + newParam.search)
            const data = objProduct(res.data)
            return data;
        } catch (e) {
            throw e
        }
    }

    getProductsPromocode = async (param) => {
        const { promotionId } = param;
        const url = `${host.api}/products/join-promotion/${promotionId}`;
        try {
            const res = await axios.get(url)
            const data = objProduct(res.data)
            return data;
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    getShopsProductsPromocode = async (param) => {
        const { promotionId } = param;
        const url = `${host.api}/shops/join-promotion/${promotionId}`;
        if (process.env.REACT_APP_MOC) {

            const data = objProductShop(moc_joinProductShop)
            return data;
        }
        try {
            const res = await axios.get(url)
            const data = objProductShop(res.data)
            return data
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    getProductsShopsPromocode = async (param) => {
        const { promotionId, shopId } = param;
        const url = `${host.api}/products/join-promotion/${promotionId}?shopId=${shopId}`;
        if (process.env.REACT_APP_MOC) {

            const data = objProductShop(moc_joinProductShop)
            return data;
        }
        try {
            const res = await axios.get(url)
            const data = objProduct(res.data)
            return data;
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    getProductsJoinPromocode = async (param) => {
        const { promotionId } = param;
        const url = `${host.api}/products/join-promotion/${promotionId}`;
        if (process.env.REACT_APP_MOC) {

            const data = objProduct(moc_ProductShop_6)
            return data;
        }
        try {
            const res = await axios.get(url)
            const data = objProduct(res.data)
            return data;
        } catch (e) {
            console.log(e)
            throw e
        }
    }


    createProductLogistics = async (payload) => {
        try {
            const url = `${host.api}/product-logistics`;
            localStorage.setItem('createProductLogistics', payload)
            const res = await axios.post(url, payload);
            return res
        } catch (error) {
            throw error
        }
    }

    handleSearchProduct = async (params) => {
        const newParams = {
            kw: params.search,
            limit: params.limit,
            page: params.page,
            status: params.status
        }
        if (newParams.status.trim().length === 0) {
            delete newParams.status
        }
        if (!params.limit) delete newParams.limit
        if (!params.page) delete newParams.page
        // const url = `${host.api}/products/search-cms`
        const url = params.search ? `${host.api}/products/search-cms` : `${host.api}/products`;
        let param = new URL(url)
        if (newParams) {
            for (const [key, value] of Object.entries(newParams)) {
                param.searchParams.set(key, value);
            }
        }
        try {
            // const url = `${host.api}/categories/all`
            const res = axios.get(url + param.search)
            return res
        } catch (e) {
            throw e
        }
        // try {
        //     const url = `${host.api}/products/search-cms?kw=${params.search}&limit=${params.limit}&page=${params.page}`
        //     const res = await axios.get(url)
        //     return res
        // } catch (error) {
        //     throw error
        // }
    }

    searchProductFilterPlaylist = async (params) => {

        const shopId = params.shopId !== '' ? `&shopId=${params.shopId}` : '';
        try {
            const url = `${host.api}/products/search-all/${params.playlistsId}?kw=${params.search}&limit=${params.limit}&page=${params.page}${shopId}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    getProductAttributes = async param => {
        try {
            const url = `${host.api}/product-attributes/product/${param.productId}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    getProductByProductId = async param => {
        try {
            const url = `${host.api}/products/${param.productId}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    updateProductById = async param => {
        try {
            const url = `${host.api}/products/${param.productId}`
            const res = await axios.patch(url, param.productDetail)
            return res
        } catch (error) {
            throw error
        }
    }

    updateProductSku = async param => {
        try {
            const url = `${host.api}/product-sku/${param.productId}`
            const res = await axios.patch(url, param.skus)
            return res
        } catch (error) {
            throw error
        }
    }

    deleteProductSku = async param => {
        try {
            const payload = { skuId: param.productSkus.skuId };
            const url = `${host.api}/product-sku/${param.productId}`;
            const res = await axios.delete(url, { data: payload });
            return res
        } catch (error) {
            throw error
        }
    }

    updateProductLogistics = async param => {
        try {
            const url = `${host.api}/product-logistics`
            const res = await axios.patch(url, param.productLogistic)
            return res
        } catch (error) {
            throw error
        }
    }

    getListProductLogisticsByProductId = async param => {
        try {
            const url = `${host.api}/product-logistics/product/${param.productId}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    deleteProduct = async (id) => {
        try {
            const url = `${host.api}/products/${id}`
            const res = await axios.delete(url)
            return res
        } catch (error) {
            throw error
        }
    }

    geListLogisticsNotPriceByShopId = async (params) => {
        try {
            const url = `${host.api}/product-logistics?weight=0.1&shopId=${params.shopId}` ///mock Weight
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    createLimitPurchase = async (params) => {
        try {
            const url = `${host.api}/product-metas/limit-product`
            const res = await axios.post(url, params)
            return res
        } catch (error) {
            throw error
        }
    }

    getLimitProduct = async (productId) => {
        try {
            const url = `${host.api}/product-metas/product/${productId}/limit-product`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            throw error
        }
    }

    createCategoryProduct = async (param, categoryType) => {
        let keyCateId = categoryType == 'custom' ? 'shopCategoryId' : 'categoryId'
        const paramRes = {
            category: param?.category,
            categoryPath: param?.categoryPath,
            productId: param?.productId,
            [keyCateId]: param?.categoryId
        }

        try {
            const url = `${host.api}/product-categories`
            const res = await axios.post(url, paramRes)
            return res
        } catch (error) {
            throw error
        }
    }

    getProductCategoriesByProductId = async (id) => {
        try {
            const url = `${host.api}/product-categories/product/${id}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    updateCategoryProduct = async (param, productId, categoryType) => {
        let keyCateId = categoryType == 'custom' ? 'shopCategoryId' : 'categoryId'
        const paramRes = {
            category: param?.category,
            categoryPath: param?.categoryPath,
            [keyCateId]: param?.categoryId
        }

        try {
            const url = `${host.api}/product-categories/product/${productId}`
            const res = await axios.patch(url, paramRes)
            return res
        } catch (error) {
            throw error
        }
    }


    validateSellerSKUIsExist = async (param) => {
        try {
            const url = `${host.api}/product-sku/check/seller-sku`
            const res = await axios.post(url, param)
            return res
        } catch (error) {
            throw error
        }
    }


}

export default productProvider
