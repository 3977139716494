import React, { useState } from 'react'
// import classnames from 'classnames'
import { detailButton } from 'components/Gallery/constant'
import { ModalUploadMedia } from 'components/Gallery'
import { tabsBrowse } from 'components/Gallery/constant'
import IconCamera from "images/icons/camera-orange.svg";
import './index.scss'

const ButtonUpload = (props) => {
  const {
    // width,
    // height,
    type,
    shopDetail,
    disabled,
    fieldUpload,
    limitImageUpload,
    onBrowse,
    // isAvatar,
    // avatar,
    targetAlbum, /// if u want to upload file to other album (not default album)
    albumDetail,
    validateWidthHeight,
    setCardIndex

  } = props

  const [isOpen, setIsOpen] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)


  const onClickBrowse = () => {
    if (!disabled) {
      onBrowse && onBrowse()
      setIsOpen(true)
      setCardIndex()
    }
  }

  return (
    <>
        <button
          className="btn-camera ml-4 d-flex justify-content-center align-items-center"
          onClick={onClickBrowse}
        >
          <img src={IconCamera} className="mr-2" alt='icon-camera'/>
          เพิ่มรูปภาพ
        </button> 

      <ModalUploadMedia
        title={detailButton[type].title}
        labelResetMedia={detailButton[type].reset}
        isOpenModal={isOpen}
        closeModal={() => setIsOpen(false)}
        dataTabs={tabsBrowse}
        isShowTabs
        cardType={type}
        shopDetail={shopDetail}
        fieldUpload={fieldUpload}
        limitImageUpload={limitImageUpload}
        setLoading={(data) => {
          setIsLoading(data)
        }}
        targetAlbum={targetAlbum}
        albumDetail={albumDetail}
        validateWidthHeight={validateWidthHeight}
      />
    </>
  )
}

ButtonUpload.defaultProps = {
  onClickButton: () => {},
  onBrowse: null,
  width: '8em',
  height: '8em',
  type: 'image',
  shopDetail: {},
  disabled: false,
  isShowButton: true,
  fieldUpload: null,
  limitImageUpload: 4,
  isAvatar: false,
  icon: '',
  avatar: '',
  validateWidthHeight:null /// {width: xx,height: xx,type: 'lower' || 'heigher' || 'equal'}
}

export default ButtonUpload
