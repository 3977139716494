/* eslint-disable no-useless-constructor */
import React from "react";
import { connect } from "react-redux";
import "./index.scss";
// import io from "socket.io-client";
// import $ from "jquery";
import emoSmile from "../../images/icons/emo-smile.svg";
import moment from 'moment';
import { sendMessage } from '../../redux/actions/socket'

// const socket = io("https://demo-godang-socket.saranros.com");

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textMsg: "",
      listMessage: [{ msg: "เข้าชมไลฟ์", username: "demo", join: true }],
    };
  }

  componentDidUpdate(nextProps) {
    if (nextProps?.listMessage !== this.props?.listMessage)
      this.gotoBottom('chat-panel');
  }

  onSocket = async () => {

  };

  sendMessage = async (e) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      if (this.state.textMsg) {
        const thisMessage = {
          content: this.state.textMsg,
          username: "เจ้าของร้าน",
          date: moment().valueOf(),
          image: "",
          picture: "",
          role: 'OWNER',
        }
        const payload = { roomId: this.props.roomId, message: thisMessage }
        this.props.dispatch(sendMessage(payload))
        this.setState({
          textMsg: ''
        })
      }
    }
  }

  gotoBottom = (id) => {
    var element = document.getElementById(id);
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  render() {
    // const { listMessage } = this.state;

    return (
      <React.Fragment>
        <div className="chat-container">
          <div className="chat-panel" id="chat-panel">
            <div className="message-list" id="message-list">
              {this.props.listMessage.map((message, idx) => {
                return (
                  <label
                    key={idx}
                    className={message.type === "join" ? "join" : "in-room"}
                  >
                    <span className="user">{message.username}</span> {" "}
                    <span className="join-message">{message.type === "join" ? " เข้าร่วมไลฟ์" : ":"}</span>
                    <span className={message.type === "join" ? "join" : ""}>
                      {" "}
                      {message.message}{" "}
                    </span>
                  </label>
                );
              })}
            </div>
          </div>

          {/* <div className="position-absolute input-chat-manage"> */}
          {/* ANCHOR - textbox message */}
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              style={{ height: "3em" }}
              // type="text"
              value={this.state.textMsg}
              placeholder="พิมพ์ข้อความของคุณ"
              onChange={(e) => this.setState({ textMsg: e.target.value })}
              onKeyPress={this.sendMessage}
            />
            <div className="input-group-append">
              <span className="input-group-text" style={{ marginTop: "5px" }}>
                {" "}
                <img src={emoSmile} alt="emoSmile" />{" "}
              </span>
            </div>
          </div>
          {/* </div> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  listMessage: state.socket.listMessage,
});

export default connect(mapStateToProps)(Chat);
