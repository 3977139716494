import axios from '../config/axios';
import host from '../config/host'
// import logisticMapping from 'config/logisticNameMapping'
import { readCookie } from 'helper/cookieHelper'

class orderProvider {

    getListOrder = async (params) => {
        // return new Promise(async (resolve, reject) => {
            const filter = {
                status: params.status ? `&status=${params.status}` : '',
                limit: params.limit ? `&limit=${params.limit}` : '',
                page: params.page ? `&page=${params.page}` : '',
                filter: params.filter ? `&filter=${params.filter}` : '',
                shopId: params.shopId ? `&shopId=${params.shopId}` : '',
                // type: params.type ? `&type=${params.type}` : '&type=general' 
            }
            try {
                const url = `${host.api}/orders/histories-all?${filter.status}${filter.limit}${filter.page}${filter.filter}${filter.shopId}`;
                const res = await axios.get(url);
                return res.data ? res.data : []
                // resolve(res.data ? res.data : []);
            } catch (error) {
                throw error
            }
        // })
    }


    onSearchOrder = async (params) => {
        const filter = {
            status : params.status ? `&status=${params.status}`  : '',
            kw: params.search ? `&kw=${params.search}` : '',
            limit: params.limit ? `&limit=${params.limit}` : '',
            page: params.page ? `&page=${params.page}` : '',
            filter: params.filter ? `&kwby=${params.filter}` : '',
            type: params.type ? `&type=${params.type}` : '&type=general',
            shopId: params.shopId ? `&shopId=${params.shopId}` : ''
        }
        try {
            const url = `${host.api}/orders/search?${filter.status}${filter.kw}${filter.limit}${filter.page}${filter.filter}${filter.shopId}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            console.log('error', error)
        }
    }

    updateInvoiceLogistic = async (payload) => {
        return new Promise(async (resolve, reject) => {
            const { deliveryId, trackingNo, logisticId, logistic } = payload
            try {
                const url = `${host.api}/deliveries/tracking/${deliveryId}`;
                const res = await axios.patch(url, { trackingNo: trackingNo, logisticId, logistic });
                resolve(res.data ? res.data : null);
            } catch (error) {
                reject(error)
                throw error
            }
        })
    }

    updateLogisticComplete = async (payload) => {
        return new Promise(async (resolve, reject) => {
            const { detail, dateString, timeString, releaseDate } = payload;
            const userData = JSON.parse(readCookie('user'))

            try {
                const url = `${host.api}/deliveries/track-hook/e-trackings`
                const data = {
                    // trackingNo: detail.delivery.trackingNo, ##old tracking
                    trackingNo: detail[detail.length - 1].trackingNo,
                    createdBy: userData.id,
                    timelines: [
                        {
                            date: dateString,
                            details: [
                                {
                                    dateTime: releaseDate,
                                    date: dateString,
                                    time: timeString,
                                    description: `วันที่ ${dateString} เวลา ${timeString} จัดส่งพัสดุของคุณเรียบร้อยแล้ว`,
                                    status: "on_delivered"
                                }
                            ]
                        }
                    ]
                }

                const res = await axios.post(url, data)
                resolve(res.data ? res.data : null);
            } catch (error) {

                throw error
            }
        })
    }

    updateInvoiceShopNote = async (payload) => {
        return new Promise(async (resolve, reject) => {
            const { invoiceId, shopNote } = payload
            try {
                const url = `${host.api}/orders/shop-note/${invoiceId}`;
                const res = await axios.patch(url, { shopNote });
                resolve(res.data ? res.data : []);
            } catch (error) {
                throw error
            }
        })
    }

    onRefundOrder = async (payload) => {
        try {
            const url = `${host.api}/orders/${payload.id}/refund`
            const data = {
                subject: "ยกเลิก / ขอคืนเงิน",
                issueNote: payload.message ? payload.message : 'อื่น ๆ'
            }
            const res = await axios.patch(url, data)
            return res
        } catch (error) {
            throw error
        }
    }

    onConfirmRefund = async (payload) => {
        try {
            const url = `${host.api}/orders/${payload.id}/refund-completed`
            const data = {
                issueNo: payload.issueNo,
            }
            const res = await axios.patch(url, data)
            return res
        } catch (error) {
            throw error
        }
    }

    onCancelRefund = async (payload) => {
        try {
            const url = `${host.api}/orders/${payload.id}/cancel-refund`
            const data = {
                note:payload.note?payload.note:' ',
                issueNo: payload.issueNo,
                solveType:payload.solveType
            }
            const res = await axios.patch(url, data)
            return res
        } catch (error) {
            throw error
        }
    }

    onClearingOrder = async (param) => {
        try {
            const url = `${host.api}/orders/clearing`
            const res = await axios.patch(url, param)
            return res
        } catch (error) {
            throw error
        }
    }

    onFilterSearchOrder = async (params) => {
        const filter = {
            kwby: params.kwby ? `&kwby=${params.kwby}` : '',
            kw: params.kw ? `&kw=${params.kw}` : '',
            shopId: params.shopId ? `&shopId=${params.shopId}` : '',
            page: params.page ? `&page=${params.page}` : '',
            limit: params.limit ? `&limit=${params.limit}` : '',
            status: params.status ? `&status=${params.status}` : '',
            type: '&type=general'
        }
        if(filter.kw.trim().length === 0) filter.kwby = ''
        // if(filter.kwby.trim().length == 0 && filter.kw.trim().length == 0 && filter.shopId.trim().length == 0) return false
        try {
            const url = `${host.api}/orders/search?${filter.kwby}${filter.kw}${filter.shopId}${filter.status}${filter.page}${filter.limit}`
            const res = await axios.get(url)
            return res ? res : {}
        } catch (error) {

        }
    }

    getListWithdraw = async (params) => {
        try {
            const url = `${host.api}/orders/withdraw?status=${params.status}${params.shopId ? `&shopId=${params.shopId}` : ''}&page=${params.page}&limit=${params.limit}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            throw error
        }
    }

    getListWithdrawAdmin = async (params) => {
        try {
            const url = `${host.api}/orders/withdraw?status=${params.status}&page=${params.page}&limit=${params.limit}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            throw error
        }
    }

    onSearchWithdraw = async (params) => {
        const filter = {
            kw: params.kw ? `&kw=${params.kw}` : '',
            shopId: params.shopId ? `&shopId=${params.shopId}` : '',
            status: params.status ? `&status=${params.status}` : '',
            page: `&page=${params.page}`,
            limit: `&limit=${params.limit}`
        }

        try {
            const url = `${host.api}/orders/search/withdraw?${filter.kw}${filter.shopId}${filter.status}${filter.page}${filter.limit}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            console.log('error', error)
            throw error
        }
    }

    getOrderDeliveriesByOrderId = async (orderId) => {
        try {
            const url = `${host.api}/deliveries/order/${orderId}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    reDelivery = async (params) => {
        try {
            const url = `${host.api}/deliveries/re-delivery`
            const res = await axios.post(url,params)
            return res
        } catch (error) {
            throw error
        }
    }

    confirmOrder = async ({orderId}) => {
        try {
            const url = `${host.api}/orders/${orderId}/confirm`
            const res = await axios.patch(url)
            return res
        } catch (error) {
            throw error
        }
    }

    approveRefund = async (payload) => {
        try {
            const url = `${host.api}/orders/${payload?.id}/approve-refund`
            const data = {
                priceRefund:payload.priceRefund,
                note:payload.note ? payload.note : 'อื่นๆ',
                issueNo: payload.issueNo,
                solveType:payload.solveType
            }
            const res = await axios.patch(url, data)
            return res
        } catch (error) {
            throw error
        }
    }

    setStatusVoidRefund = async ({orderId,issueNo,refundType}) => {
        try {
            const url = `${host.api}/orders/${orderId}/refund-type/${issueNo}`
            const res = await axios.patch(url,{refundType})
            return res?.data
        } catch (error) {
            throw error
        }
    }
}

export default new orderProvider()