import React, { useEffect, useState } from 'react'
import '../styles/_dashboard.scss'
import DashBoardLabel from 'components/DashboardLabel';
import LineChart from 'components/LineChart';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames'
import { Body1, Body2, Caption, H5, H6, SubTitle1, SubTitle2 } from 'components/FontStyle';
import { ReactComponent as IconHint } from 'images/icons/hint.svg'
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { ReactComponent as IconNoItem } from 'images/icons/no-item.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { dispatchDashboard, SET_KEY_DASHBOARD, SET_SHOP_DASHBOARD, GET_REPORT } from 'redux/actions/dashboard';
import { OWNER } from 'layout/Sidebar/Role';
import { addArrayIfNotExists, deepCopyArray, diffObjectsInArray, getDifferenceArray, getTopViewColor, getUserRole } from 'helper/utils';
import ReactDOMServer from 'react-dom/server'
import dashboardProvider from 'provider/dashboardProvider';
import { displayDateTh, displayDateThShortYear } from 'helper/timeUtil';
import moment from 'moment';
import DashBoardHeaderSelect from 'components/output/DashBoardHeaderSelect';
import { options, generatePeriodDate } from 'components/PeriodTimeInput/dataUtil';
import TopViewerWidget from "components/TopViewerWidget"

import { dispatchApp, SET_LOADING } from 'redux/actions/app';
import shopProvider from 'provider/shopProvider';
import dashboard from 'redux/reducers/dashboard';
import numeral from 'numeral';
import LineChartMultiAxis from 'components/LineChartMultiAxis';
import { uniqBy } from 'lodash';

const generateTooltipChart = (label, data, color) => {
    const htmlString = ReactDOMServer.renderToString(
        <div style={{ background: 'white', padding: '10px', display: 'flex', flexDirection: 'column', fontFamily: 'DBHeavent', fontSize: '20px', minWidth: '200px' }}>
            <span style={{ borderBottom: '1px solid #e9e9e9' }}> {label} </span>
            <div style={{ gap: '20px', display: 'flex', flexDirection: 'row' }}>
                <div style={{ gap: '5px', display: 'flex', alignItems: 'center' }}>
                    <span style={{ height: '10px', width: '10px', backgroundColor: color, borderRadius: '50%', display: 'inline-block' }}></span>
                    <span>ยอดขาย</span></div>
                <hr />
                <span>฿ {numeral(data).format('0,0.00')}</span>
            </div>
        </div>
    )
    return htmlString
}


const Dashboard = (props) => {
    const dispatch = useDispatch()
    const dashboardState = useSelector(state => state.dashboard)
    const {dashboardReport} = dashboardState
    const { listShop, rangeDate } = dashboardState
    // const [listShop, setlistShop] = useState([])
    const [activeTab, setActiveTab] = useState('topSold')
    const [dataReport, setDataReport] = useState(null)
    const [chartRange, setChartRange] = useState(null)
    const [chartView, setChartView] = useState(null)
    const [chartTopViewer, setChartTopViewer] = useState(null)
    const [isFirst, setIsFirst] = useState(true)
    const [rangeTopView, setRangeTopView] = useState('time')
    


    const api_shops = new shopProvider()
    let dashboardData = [
        { field: 'sales', label: 'ยอดขาย', value: `฿ ${dataReport?.totalRevenue !== undefined ? numeral(dataReport?.totalRevenue).format('0,0.00') : 0}`, tooltip: 'ยอดขายรวมจากคำสั่งซื้อที่ชำระเงินแล้ว ทั้งหมดในช่วงระยะเวลาที่เลือก' },
        { field: 'orderQuantity', label: 'จำนวนคำสั่งซื้อ', value: dataReport?.totalOrders !== undefined ? dataReport?.totalOrders.toLocaleString() : 0, tooltip: 'จำนวนคำสั่งซื้อที่ได้รับการชำระเงินแล้ว ทั้งหมดในช่วงระยะเวลาที่เลือก' },
        { field: 'productInCart', label: 'จำนวนสินค้าในตะกร้า ณ ปัจจุบัน', value: `${dataReport?.totalInCartItems !== undefined ? dataReport?.totalInCartItems.toLocaleString() : 0}`, tooltip: 'จำนวนสินค้าที่ลูกค้านำมาเก็บค้างไว้ในตะกร้า ทั้งหมด ณ ปัจจุบัน', link: '/manage/dashboard/orderReport', buttonLabel: 'ดูสินค้า >>', type: 'sku-in-cart' },
        { field: 'waitPayment', label: 'สินค้าที่รอชำระเงิน ณ ปัจจุบัน', value: `${dataReport?.totalUnpaidItems !== undefined ? dataReport?.totalUnpaidItems.toLocaleString() : 0}`, tooltip: 'จำนวนสินค้าที่ค้างไว้ในรายการรอชำระเงิน ของลูกค้าทั้งหมด ณ ปัจจุบัน', link: '/manage/dashboard/orderReport', buttonLabel: 'ดูสินค้า >>', type: 'sku-wait-payment' }
    ]

    const showDashboard = dashboardData.map((item, idx) => {
        return (
            <DashBoardLabel
                label={item.label}
                value={item.value}
                hint={<IconHint
                    width={30}
                    height={30} />}
                tooltip={item.tooltip}
                idx={idx}
                buttonLabel={item.buttonLabel}
                link={item.link}
                type={item.type}
                key={item.field}
            // listShop={listShop}
            // shopSelected={dashboardState?.shopSelected}
            // onChangeShopSelected={() => onChangeShopSelected()}
            />
        )
    })


    // useEffect(() => {
    //         (async () => {
    //             console.log('props', props)
    //             await getListShop()

    //             const initialRealTime = generatePeriodDate(options.REAL_TIME, moment())
    //             dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: initialRealTime } }))
    //             dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initialRealTime.period.from).utc(0).format() } }))
    //             dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initialRealTime.period.to).utc(0).format() } }))
    //             // await getDataReport(dashboardState.shopSelected)
    //         })()
    //     return () => {
    //         dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: null } }))
    //         dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: null } }))
    //         dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: null } }))
    //         dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
    //     };
    // }, []);


    useEffect(() => {
      (async () => {
        dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'isFromDashboard', value: true } }))
        await getListShop()
        if(isFirst && !dashboardState.summaryOrder){
            await getDataReport(dashboardState.shopSelected)
        }
        if (rangeDate && !dashboardState.summaryOrder) {
                const initDate = await generatePeriodDate(rangeDate?.type, {startDate:rangeDate?.period?.from,endDate: rangeDate?.period?.to})
            if (initDate) {
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initDate.period.from).utc(0).format() } }))
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initDate.period.to).utc(0).format() } }))
                dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: rangeDate } }))
            }
        } else {
            const initialRealTime = generatePeriodDate(options.REAL_TIME, moment())
            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: initialRealTime } }))
            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initialRealTime.period.from).utc(0).format() } }))
            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initialRealTime.period.to).utc(0).format() } }))
        }
      })()
        return () => {
            // second
            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'isFromDashboard', value: false } }))
        }
    }, [])


    useEffect(() => {
        (async function () {
            if (isFirst) {
                setIsFirst(false)
                return
            }
            if(dashboardState?.summaryOrder){
                return
            }
            if (dashboardState.toDate) {
                await getDataReport(dashboardState.shopSelected)
                // dispatch({ type: SET_LOADING, payload: { loading: false } })
            }
        })()
        return () => {
        }
    }, [(dashboardState.shopSelected)])


    useEffect(() => {
        (async function () {
            if (isFirst) {
                setIsFirst(false)
                return
            }
            if(dashboardState?.summaryOrder){
                return
            }

            // await getListShop()
            if (rangeDate && !dashboardState.summaryOrder) {
                const initDate = generatePeriodDate(rangeDate?.type, { startDate: rangeDate?.period?.from, endDate: rangeDate?.period?.to })
                if (initDate) {
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'fromDate', value: moment(initDate.period.from).utc(0).format() } }))
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'toDate', value: moment(initDate.period.to).utc(0).format() } }))
                    dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'rangeDate', value: rangeDate } }))
                }
            }

            if (dashboardState.toDate)
                await getDataReport(dashboardState.shopSelected)
        })()
        return () => {
        }
    }, [rangeDate])

    // useEffect(() => {
    //     if (listShop.length > 0) dispatch({ type: SET_LOADING, payload: { loading: false } })
    // }, [listShop])

    // useEffect(() => {

    //     // return () => {
    //     //     cleanup
    //     // }
    // }, [dashboardState.shopSelected || dashboardState.shopSelected === 0])

    // const getReport = async () => {
    //     const res = await dashboardProvider.getDashboardReport()
    //     return res
    // }

    // useEffect(() => {
    //     (async function () {
    //         // dispatch({ type: SET_LOADING, payload: { loading: true } })
    //         await getListShop()
    //         // await getDataReport()
    //         // dispatch({ type: SET_LOADING, payload: { loading: false } })
    //     })()
    //     return () => {
    //         // dispatch({ type: SET_LOADING, payload: { loading: false } })
    //     }
    // }, [listShop.length === 0])

    const getListShop = async () => {
        dispatch({ type: SET_LOADING, payload: { loading: true } })
        const res = await api_shops.getAllShops()
        if (res) {
            const arr = res.shop.map((shop) => {
                return { label: shop.name, value: shop, img: shop.logoPath }
            })

            // for user which have one shop
            if (res?.shop?.length > 1) {
                // dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'shopSelected', value: 0 } }))
                arr.unshift(
                    {
                        label: 'ร้านทั้งหมด',
                        value: '',
                        img: null
                    })
            }

            dispatch(dispatchDashboard({ type: SET_KEY_DASHBOARD, payload: { key: 'listShop', value: arr } }))
            // setlistShop(arr)

        }
        dispatch({ type: SET_LOADING, payload: { loading: false } })
    }


    const getDataReport = async (shopSelected) => {
        try {
            dispatch({ type: SET_LOADING, payload: { loading: true } })
            const shopId = listShop[shopSelected]?.value.id
            const params = { fromDate: dashboardState.fromDate, toDate: dashboardState?.toDate }
            if (shopId || shopId === 0) params.shopId = shopId
            const getReport = await dashboardProvider.getDashboardReport(params)
            if (getReport.data) {
                const { data } = getReport
                setDataReport(data)
                const { resRangeTopView: formatDate } = generateRangeChart(data)
                generateListChart(data)
                generateListChartTopViewer(data,formatDate)
                dispatch(dispatchDashboard({ type: GET_REPORT, payload: {dataReport: data} }))
            }
            // if (listShop.length !== 0) {
                dispatch({ type: SET_LOADING, payload: { loading: false } })
            // }
        } catch (error) {
            console.log(`error:`, error);
        }
    }

    const toggle = (status) => {
        setActiveTab(status)
    }

    // const onChangeShopSelected = (value) => {
    //     dispatch(dispatchDashboard({ type: SET_SHOP_DASHBOARD, payload: { shopSelected: value } }))
    // }

    const generateRangeChart = (data) => {
        switch (true) {
            case data.diffDays <= 0:
                setChartRange('week')
                break;
            case data.diffDays <= 8:
                setChartRange('week')
                break;
            case data.diffDays <= 31:
                setChartRange('month')
                break;
            case data.diffDays <= 366:
                setChartRange('year')
                break;
            default:
                // setChartRange('week')
                break;
        }

        let resRangeTopView = ''
        switch (true) {
            case data.diffDays <= 1:
                setRangeTopView('time')
                resRangeTopView = 'time'
                break;
            case data.diffDays <= 8:
                setRangeTopView('week')
                resRangeTopView = 'week'
                break;
            case data.diffDays <= 31:
                setRangeTopView('month')
                resRangeTopView = 'month'
                break;
            case data.diffDays <= 366:
                setRangeTopView('year')
                resRangeTopView = 'year'
                break;
            default:
                setRangeTopView('year')
                resRangeTopView = 'year'
                break;
        }
        return {resRangeTopView}
    }

    const generateListChart = (data) => {
        if (data.diffDays < 1) {
            const dayData = [['x', 'ยอดขาย', { role: "tooltip", type: "string", p: { html: true } }]]
            data.revenueOfPeriod.map((date) => dayData.push([moment(date.timestamp).format('HH'), date.totalRevenue, generateTooltipChart(`${moment(date.timestamp).format('HH')}.00 น.`, date.totalRevenue, '#b2d234')]))
            setChartView(dayData)
        }
        // else if(data.diffDays === 365 || data.diffDays === 364) {
        //     const yearData = [['x', 'ยอดขาย', { role: "tooltip", type: "string", p: { html: true } }]]
        //     console.log(`moment(data.timestamp)`, moment(data.timestamp))
        //     data.revenueOfPeriod.map((date) => yearData.push([moment(date.timestamp).format('MM'), date.totalRevenue, generateTooltipChart(moment(date.timestamp).format('HH'), date.totalRevenue, '#b2d234')]))
        // }
        else if ((data.diffDays >= 364 && data.diffDays <= 365) || (data.isStartOfYear && data.diffDays >= 30)) {
            const weekData = [['x', 'ยอดขาย', { role: "tooltip", type: "string", p: { html: true } }]]
            data.revenueOfPeriod.map((date) => weekData.push([moment(date.timestamp).format('MMMM'), date.totalRevenue, generateTooltipChart(moment(date.timestamp).format('MMMM'), date.totalRevenue, '#b2d234')]))
            setChartView(weekData)
        }
        else {
            const weekData = [['x', 'ยอดขาย', { role: "tooltip", type: "string", p: { html: true } }]]
            data.revenueOfPeriod.map((date) => weekData.push([moment(date.timestamp).format('DD'), date.totalRevenue, generateTooltipChart(displayDateThShortYear(date.timestamp), date.totalRevenue, '#b2d234')]))
            setChartView(weekData)
        }
    }

    const generateListChartTopViewer = (data,formatDate) => {       
        function mergeTimestampAndViewerData(productsView, format, dataArray) {
            const defaultProductsView = [['x','ไม่มีข้อมูล', {role: 'tooltip', type: 'string', p: {html: true}}], ...dataArray];
            // const defaultProductsView = [['x','ไม่มีข้อมูล'],['',0]]
            const mergedData = [];
            const listProduct = ['x'];
            const tooltip = { role: "tooltip", type: "string", p: { html: true } };
            const productsViewFilter = uniqBy(productsView,"product_id") || [];
            [...productsViewFilter].forEach((product) => {
              product.period.forEach((entry) => {
                const timestamp = moment(entry.timestamp).format(format);
                const totalViewer = entry.totalViewer;
                const existingEntry = mergedData.find((item) => item[0] === timestamp);
                if (existingEntry) {
                  existingEntry.push(totalViewer);
                } else {
                  mergedData.push([timestamp, totalViewer]);
                }
              });
              const name = product.name
              const existingProductEntry = listProduct.find((item) => item === name);
              if(!existingProductEntry){
                  listProduct.push(name)
              }
            });
            const newMergeData = []
            mergedData.map((item,index) => {
                const newItem = []
                item.map((val,valIndex) => {
                    if(typeof val === 'number'){
                        const sortData = sortDataTopview(listProduct, item, data)
                        const generate = generateTooltipTopView(sortData,formatDate,{period: productsView[0].period[index]})
                        newItem.push(val,generate)
                    } else {
                        newItem.push(val)
                    }
                    addArrayIfNotExists(newMergeData,newItem)
                })
            })

                for (let i = 2; i < listProduct.length * 2 - 1 && i <= listProduct.length; i += 2) {
                    listProduct.splice(i, 0, tooltip);
                  }
  
            if (productsView.length === 0 || listProduct.length <= 1) return defaultProductsView
            return [listProduct,...newMergeData];
          }
          
        if (data.diffDays < 1) {
            const dataArray = data.revenueOfPeriod.map((date) => {
                const formattedDate = moment(date.timestamp).format('HH')   
                const tooltip = generateTooltipNodata({ label: 'ไม่มีข้อมูล' });
                return [formattedDate, 0, tooltip];
           });
            const dayData = mergeTimestampAndViewerData(data.productsView,'HH',dataArray)
            setChartTopViewer(dayData)
        }
        else if ((data.diffDays >= 364 && data.diffDays <= 365) || (data.isStartOfYear && data.diffDays >= 30)) {
            const dataArray = data.revenueOfPeriod.map((date) => {
                const formattedDate = moment(date.timestamp).format('MMMM')   
                const tooltip = generateTooltipNodata({ label: 'ไม่มีข้อมูล' });
                return [formattedDate, 0, tooltip];
           });
            const weekData = mergeTimestampAndViewerData(data.productsView,'MMMM',dataArray)
            setChartTopViewer(weekData)
        }
        else {
            const dataArray = data.revenueOfPeriod.map((date) => {
                const formattedDate = moment(date.timestamp).format('DD')   
                const tooltip = generateTooltipNodata({ label: 'ไม่มีข้อมูล' });
                return [formattedDate, 0, tooltip];
           });
            const weekData = mergeTimestampAndViewerData(data.productsView,'DD',dataArray)
            setChartTopViewer(weekData)

        }
    }

        const sortDataTopview = (header,data,dataReport) => {
            const {productsView} = dataReport
            const response = [];
            const newData = deepCopyArray(data)
            for (let i = 1; i < header.length; i++) {
                const realIndex = productsView.findIndex(e => e.name === header[i])
                response.push({
                    label: header[i],
                    value: newData[i],
                    color: getTopViewColor(realIndex),
                    period: newData[0],
                });
            }
            response.sort((a, b) => b.value - a.value);
            response.splice(0, 0, {
                label: header[0],
                value: newData[0]
            });
            return response
        }



    const showTopTenProduct = (status, data) => {
        switch (status) {
            case 'topSold':
                return <section>
                    <div className='table-product'>
                        <div>ลำดับ</div>
                        <div className='text-left'>ข้อมูลสินค้า</div>
                        <div>ยอดที่ขายได้</div>
                    </div>
                    {data?.topSKUS?.revenueTotal?.length > 0 ?
                        data.topSKUS?.revenueTotal.map((item, skuidx) => (
                            <div className='table-product-content' key={item?.sku + skuidx}>
                                <div><SubTitle1>{skuidx + 1}</SubTitle1></div>
                                <div className='justify-content-start'>
                                    <Caption className='bg-white'>
                                        <span className='d-flex flex-row align-items-center show-item'>
                                            <img src={item?.productImage} alt={'Image' + skuidx} width={'30px'} />
                                            <span className='d-flex flex-column align-items-start'>
                                                <span className='text-left'>{item?.productName}</span>
                                                <span>{item?.sku}</span>
                                                <span>เลขอ้างอิง SKU: {item?.extraData?.sellerSKU || '-'}</span>
                                            </span>

                                        </span>
                                    </Caption>
                                </div>
                                <div><SubTitle1>฿ {numeral(item.totalRevenue).format('0,0.00')}</SubTitle1></div>
                            </div>
                        ))
                        : <div className='d-flex justify-content-center align-items-center flex-column p-4 mt-4 mb-4'>
                            <IconNoItem />
                            <span>ไม่มีรายการ</span>
                        </div>
                    }
                </section>
                break;
            case 'topQuantity':
                return <section>
                    <div className='table-product'>
                        <div>ลำดับ</div>
                        <div className='text-left'>ข้อมูลสินค้า</div>
                        <div>จำนวนที่ขายได้</div>
                    </div>
                    {data?.topSKUS?.quantityTotal?.length > 0 ?
                        data.topSKUS?.quantityTotal?.map((item, skuidx) => (
                            <div className='table-product-content' key={item.productName + skuidx}>
                                <div><SubTitle1>{skuidx + 1}</SubTitle1></div>
                                <div className='justify-content-start'>
                                    <Caption className='bg-white'>
                                        <span className='d-flex flex-row align-items-center show-item'>
                                            <img src={item?.productImage} alt={'Image' + skuidx} width={'30px'} />
                                            <span className='d-flex flex-column align-items-start'>
                                                <span className='text-left'>{item?.productName}</span>
                                                <span>{item?.sku}</span>
                                                <span>เลขอ้างอิง SKU: {item?.extraData?.sellerSKU || '-'}</span>
                                            </span>

                                        </span>
                                    </Caption>
                                </div>
                                <div><SubTitle1>{item?.totalQuantity?.toLocaleString()}</SubTitle1></div>
                            </div>
                        ))
                        : <div className='d-flex justify-content-center align-items-center flex-column p-4 mt-4 mb-4'>
                            <IconNoItem />
                            <span>ไม่มีรายการ</span>
                        </div>
                    }
                </section>

            default:
                break;
        }
    }

    const fetchDataExcel = async () => {
        const { shopSelected, fromDate, toDate, listShop, rangeDate } = dashboardState
        const paramsListAll = { fromDate, toDate, page: 1 }
        const excelArr = []
        if (shopSelected) {
            const shopId = listShop[shopSelected]?.value?.id
            if (shopId) {
                const shopId = listShop[shopSelected]?.value?.id
                // params.shopId = shopId
                paramsListAll.shopId = shopId
            }
        }
        if (dataReport) {
            dataReport.revenueOfPeriod.map((data, idx) => {
                excelArr.push({ time: moment(data.timestamp).format('DD-MM-YYYY HH:mm'), totalRevenue: numeral(data?.totalRevenue).format('0,0.00') })
            })
            excelArr.push({ time: 'ทั้งหมด', totalRevenue: numeral(dataReport?.totalRevenue).format('0,0.00') })
            return {
                header: ["วันที่", "ยอดขายทั้งหมด (THB)"],
                data: excelArr
            }
            // if (listOrderAll.data && type === 'dashboard') {
            //     listOrderAll.data.revenueOfPeriod.map((data, index) => {
            //         excelArr.push({ time: moment(data.timestamp).format('DD-MM-YYYY HH:mm'), totalRevenue: data.totalRevenue.toLocaleString() })
            //     })
            //     excelArr.push({ time: 'ทั้งหมด', totalRevenue: listOrderAll?.data?.totalRevenue.toLocaleString() })
            //     // setExcelData(excelArr)
            // }
        }
    }

    const dataTimeTopView = (data, format) => {
        if (format == "time") {
            return `${moment(data).format('HH')}.00 น.`
        }
        else if (format == "month" || format == "week") {
            return displayDateTh(data, "D MMM YYYY")
        }
        else {
            return moment(data).format('MMMM')
        }
    }

    const generateTooltipTopView = (product,format,{period}) => {
        const {timestamp} = period
            product.shift();          // ลบสมาชิกตำแหน่งแรก period
            // product.pop();            // ลบสมาชิกตำแหน่งสุดท้าย
        const htmlString = ReactDOMServer.renderToString(
          <div
            className="dashboard-top-view card-topview"
            style={{
              background: "white",
              padding: "10px 0",
              display: "flex",
              flexDirection: "column",
              fontFamily: "DBHeavent",
              fontSize: "20px",
              width: "350px",
              gap:"20px"
            }}
          >
            {/* <span style={{ borderBottom: "1px solid #e9e9e9", margin: "0 16px" }}> {product[0].period + `:-------${moment(product[0].timestamp,'mm/dd/yyyy')}`} </span> */}
            <span style={{ borderBottom: "1px solid #e9e9e9", margin: "0 16px" }}> {dataTimeTopView(timestamp,format)}</span>
            <div className="tooltip-dashboard-top-view-scroll" style={{ overflowY: "scroll", height: "180px"}}>
              {product.map((e,index) => (
                <div style={{ gap: "20px", display: "flex", flexDirection: "row",padding: "0 16px", justifyContent: "space-between", alignItems: "start" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "5px",
                      lineHeight: "22px"
                    }}
                  >
                    <div className="dots" style={{backgroundColor: e.color}}></div>
                    <span style={{wordBreak: "break-all"}}>
                      {typeof e.label === 'string' ? e.label : 'aa'}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexShrink: 0,
                    }}
                  >
                    <span style={{lineHeight:"22px"}}>{typeof e.value === 'number' ? e.value : 0} วิว</span>
                  </div>
                </div>
              ))}
            </div>
      </div>
    );
    return htmlString;
  };


    const generateTooltipNodata = (product) => {
    const htmlString = ReactDOMServer.renderToString(
        <div className="dashboard-top-view card-topview" style={{ background: 'white', padding: '10px', display: 'flex', flexDirection: 'column', fontFamily: 'DBHeavent', fontSize: '20px', minWidth: '200px' }}>
            <div style={{ gap: '20px', display: 'flex', flexDirection: 'row' }}>
                <div style={{ gap: '5px', display: 'flex', alignItems: 'center' }}>
                    <span style={{ height: '10px', width: '10px', backgroundColor: "#B2D234", borderRadius: '50%', display: 'inline-block' }}></span>
                    <span>{product.label} </span></div>
                <hr />
                <span>0 ยอดวิว</span>
            </div>
        </div>
    );
    return htmlString;
  };



    return (
        <div className="dashboard">

            <H5>สรุปรายงาน</H5>

            {/* <div style={{ backgroundColor: 'white', padding: '10px', display: 'flex', flexDirection: 'column', fontFamily: 'DBHeavent' }}>
                <span style={{ borderBottom: '1px solid black' }}> 2 เม.ย. 2564 15:00</span>
                <div style={{ gap: '20px', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ gap: '5px', display: 'flex', alignItems: 'center' }}>
                        <span style={{ height: '10px', width: '10px', backgroundColor: 'red', borderRadius: '50%', display: 'inline-block' }}></span>
                        <span>ยอดขาย</span></div>
                    <br />
                    <span>฿ 19,223</span>
                </div>
            </div> */}



            <DashBoardHeaderSelect
                // dataReport={dataReport} 
                // headerExcel={["วันที่", "ยอดขายทั้งหมด (THB)"]} 
                type={'dashboard'}
                rangeDate={dashboardState.rangeDate}
                fetchDataExcel={() => fetchDataExcel()}
            />

            <section className="box-2 mt-2">
                <div className='d-flex flex-row justify-content-between'>
                    <H6>ตัวชี้วัดหลัก</H6>
                    <Link to={{
                        // pathname: '/manage/dashboard/orderReport',
                        pathname: '/manage/order/summary-order',
                        state: {
                            // type: 'order',
                            // shopSelected: dashboardState?.shopSelected,
                            // listShop: listShop,
                        }
                    }}>
                        <Body2 className='color-ais-primary-main cursor-pointer'>ดูรายงานคำสั่งซื้อ {`>>`}</Body2>
                    </Link>
                </div>
                <div className="d-flex flex-row w-100 justify-content-between mt-3">
                    {showDashboard}
                </div>

                {/* <div>
                    <LineChart data={data} />
                </div> */}
            </section>

            <section>
                <div className='graph-dashboard'>
                    <div className='d-flex align-items-center gap-1'>
                        <H6>ช่วงเวลาที่เกิดยอดขาย</H6>
                        <React.Fragment>
                            <span id='soldTime'><IconHint className='icon-hint' width={30} height={30} /></span>
                            <UncontrolledTooltip placement="top" target="soldTime">
                                {'ยอดขายที่เกิดขึ้นในช่วงระยะเวลาที่เลือก'}
                            </UncontrolledTooltip>
                        </React.Fragment>
                    </div>
                    {chartView?.length > 1 && chartRange ?
                        <LineChart
                            data={chartView}
                            showTextEvery={chartRange}
                        /> :
                        <div className='d-flex justify-content-center align-items-center flex-column p-4 mt-4 mb-4'>
                            <IconNoItem />
                            <span>ไม่มีรายการ</span>
                        </div>
                    }
                </div>
            </section>

            <section>
                {chartTopViewer && 
                <TopViewerWidget 
                chartTopViewer={chartTopViewer} chartRange={chartRange}
                />
                }
            </section>


            <div className='bg-white'>
                <section>
                    <H6>การจัดอันดับสินค้า 10 อันดับแรก</H6>

                    <Nav tabs>
                        <NavItem onClick={() => toggle('topSold')}>
                            <NavLink className={classnames({ 'active': activeTab === 'topSold' })} >
                                <Body1>จากยอดขาย</Body1>
                                <React.Fragment>
                                    <span id='Sell'><IconHint className='icon-hint' /></span>
                                    <UncontrolledTooltip placement="top" target="Sell">
                                        {'ยอดขายรวมจากคำสั่งซื้อที่ชำระเงินแล้ว ทั้งหมดในช่วงระยะเวลาที่เลือก'}
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            </NavLink>
                        </NavItem>
                        <NavItem onClick={() => toggle('topQuantity')}>
                            <NavLink className={classnames({ 'active': activeTab === 'topQuantity' })} >
                                <Body1>จากจำนวนที่ขายได้</Body1>
                                <React.Fragment>
                                    <span id='top-10-product'><IconHint className='icon-hint' /></span>
                                    <UncontrolledTooltip placement="top" target="top-10-product">
                                        {'สินค้า 10 อันดับแรกที่มียอดขายสูงสุดจากคำสั่งซื้อที่ยืนยันแล้ว (นับจากจำนวนสินค้า) ทั้งหมดในช่วงระยะเวลาที่เลือก'}
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </section>
                {dataReport && showTopTenProduct(activeTab, dataReport)}
                {/* {showTopTenProduct(activeTab,dataReport)} */}
            </div>

            <section>
                <H6>การจัดอันดับหมวดหมู่สินค้ายอดนิยม 5 อันดับแรก</H6>
                {/* <div> */}

                <div className='table-hot-product'>
                    <div>ลำดับ</div>
                    <div className='text-left'>หมวดหมู่สินค้า</div>
                    <div>ยอดที่ขายได้</div>
                </div>


                {dataReport?.topCategories?.quantityTotal?.length === 0 ?
                    <div className='d-flex justify-content-center align-items-center flex-column p-4 mt-4 mb-4'>
                        <IconNoItem />
                        <span>ไม่มีรายการ</span>
                    </div> :
                    dataReport && dataReport.topCategories?.quantityTotal?.map((item, topCatidx) => (
                        <div className='table-hot-product-content'>
                            <div><SubTitle1>{topCatidx + 1}</SubTitle1></div>
                            <div className='text-left'><SubTitle1>{item?.category}</SubTitle1></div>
                            <div><SubTitle1>{item?.totalQuantity?.toLocaleString()}</SubTitle1></div>
                        </div>
                    ))
                }

            </section>

            {
                <section>
                    {getUserRole() === OWNER && <H6>โปรโมชันโค้ดยอดนิยม 5 อันดับแรก</H6>}
                    {getUserRole() !== OWNER && <H6>โปรโมชันโค้ดของ V-AVENUE ยอดนิยม 5 อันดับแรก</H6>}
                    <div className='table-promocode'>
                        <div>ลำดับ</div>
                        <div>ชื่อแคมเปญ</div>
                        <div>ระยะเวลาการใช้งาน</div>
                        <div>จำนวนการใช้งาน</div>
                        <div>ใช้งานโดยลูกค้าเก่า</div>
                        <div>ใช้งานโดยลูกค้าใหม่</div>
                    </div>

                    {dataReport?.topPromoUsed?.quantityTotal?.length === 0 ?
                        <div className='d-flex justify-content-center align-items-center flex-column p-4 mt-4 mb-4'>
                            <IconNoItem />
                            <span>ไม่มีรายการ</span>
                        </div> :
                        dataReport && dataReport.topPromoUsed?.quantityTotal?.map((item, promoidx) => (
                            <div className='table-promocode-content'>
                                <div><SubTitle1>{promoidx + 1}</SubTitle1></div>
                                <div><SubTitle1>{item.promotionData?.campaignName}</SubTitle1></div>
                                <div><SubTitle1>{`${displayDateThShortYear(item.promotionData?.fromDate)} - ${displayDateThShortYear(item.promotionData?.toDate)}`}</SubTitle1></div>
                                <div><SubTitle1>{item.totalUsed?.toLocaleString()}</SubTitle1></div>
                                <div><SubTitle1>{item.totalOldUserUsed?.toLocaleString()}</SubTitle1></div>
                                <div><SubTitle1>{item.totalNewUserUsed?.toLocaleString()}</SubTitle1></div>
                            </div>
                        ))
                    }
                </section>
            }
        </div>
    )
}

export default Dashboard
