import { takeLatest, put, call, select, delay } from "redux-saga/effects";
import categoriesProvider from 'provider/categoriesProvider';
import shopProvider from 'provider/shopProvider';
import { SET_LOADING } from 'redux/actions/app';
import {
  ADD_KEY_VALUE_CATEGORIES,
  GET_CATEGORIES,
  GET_PARENT_CATEGORIES_ALL,
  GET_CHILD_CATEGORIES,
  CREATE_CATEGORIES,
  UPDATE_CATEGORIES,
  DELETE_CATEGORIES,
  FETCH_CATEGORIES,
  FETCH_CATEGORY_ALL_LEVEL,
  GET_CATEGORY_ALL,
  SET_CATEGORY_SYSTEM,
  SET_CATEGORY_SHOP,
  SET_MODAL_CATEGORY
} from "redux/actions/categories";
import { getCategories, getShop } from "./selectors";
import { OPEN_TOAST } from 'redux/actions/notification'
import { isEmpty, set } from "lodash";
import { GET_SHOP_DETAIL, GET_CURRENT_SHOP_DETAIL } from 'redux/actions/shop'

export function* onGetCategories(action) {
  try {
    const { shopDetail = {} } = yield select(getShop)
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const categories = yield call(categoriesProvider.getCategories,null,shopDetail);
    yield put({ type: ADD_KEY_VALUE_CATEGORIES, payload: { key:'categories', value: categories} });
    yield put({ type: SET_LOADING, payload: { key:'categories', value: categories} });
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    throw new Error(error);
  }
}


export function* onGetParentCategoriesAll(action) {
  const { currentShop = {} } = yield select(getShop)
  const { modalCategory } = yield select(getCategories)
  const { index,type,level } = modalCategory
  const { payload = {} } = action

  try {
    const res = yield call(categoriesProvider.getParentCategoriesAll, { shopId: currentShop?.id });

    if(!isEmpty(res?.data)) {

      let itemSelected = {}
      if(index == 0 || payload?.actionFetch == 'delete') {
        itemSelected = res?.data[0] 
      } else if (payload?.actionFetch == 'create') {
        itemSelected = res?.data[res?.data?.length - 1]
      } else {
        itemSelected = res?.data[index]
      }

      yield put({ 
        type: SET_CATEGORY_SYSTEM,
        payload: {
          categoryLevel1: res?.data || [],
          selectCategoryLevel1: itemSelected
        }
      })
      
    }

    return res

  } catch (err) {
    console.log(err);
  }
}

export function* onGetChildCategories(action) {
  const { payload = {} } = action
  const { parentId = null, categoryLevel = '', level, actionFetch } = payload
  const { modalCategory, selectCategoryLevel2 } = yield select(getCategories)
  const { level: levelSelected, index, type } = modalCategory

  try {
    const child = yield call(categoriesProvider.getChildCategories, { parentId: parentId });

    if(child?.data?.length > 0) {

      // click select category
        if(actionFetch == 'select' && levelSelected !== level) {
          yield put({
            type: SET_CATEGORY_SYSTEM,
            payload: {
              [`selectCategoryLevel${level}`]: level == levelSelected ? null :  child?.data?.length == 0 ? {}  : child?.data[0],
              [`categoryLevel${level}`]: level == levelSelected ? null :  child?.data?.length == 0 ? [] : child?.data,
            }
          })
         } else {
            yield put({
              type: SET_CATEGORY_SYSTEM,
              payload: {
                [`${categoryLevel}`]: child?.data,
                [`selectCategoryLevel${level}`]: actionFetch == 'create' ? child?.data[child?.data?.length - 1] : (actionFetch == 'update') && actionFetch != 'delete' ? isEmpty(selectCategoryLevel2) ? child?.data[0] : child?.data[index] : child?.data[0] || null
              }
            })
         }
    } else {
      // clear level 3 if data is empty
      yield put({
        type: SET_CATEGORY_SYSTEM,
        payload: {
          [`selectCategoryLevel${level}`]: {},
          [`categoryLevel${level}`]: []
        }
      })
    }

    return child

  } catch (err) {
    console.log(err);
  }
}

export function* onCreateCategories(action) {
  const { payload } = action
  const { data = {}, categoryLevel = null } = payload
  const { currentShop = {} } = yield select(getShop)
  const { selectCategoryLevel1 = {}, selectCategoryLevel2 = {} } = yield select(getCategories)

  try {

    yield put({type: SET_LOADING, payload: { loading: true }})  

    let body = []
    let parentPath = {}

    if(categoryLevel == 3) {
      parentPath = selectCategoryLevel2
    } else if (categoryLevel == 2) {
      parentPath = selectCategoryLevel1
    } else if (categoryLevel == 1) {
      parentPath = { categoryPath: '/' }
    }

    for(const [index, item] of data.entries()) {
        body.push({
          name: item,
          nameEn: item,
          parentPath: parentPath?.categoryPath,
          categoryPath: categoryLevel == 1 ? `/${item}` : `${parentPath?.categoryPath}/${item}`,
          shopId: currentShop?.id || null
        })

        if(categoryLevel !== 1) {
          body[index] = { ...body[index], parentId: parentPath?.id }
        }
    }


    const res = yield call(categoriesProvider.createCategories, { body: body });

    yield put({
      type: ADD_KEY_VALUE_CATEGORIES,
      payload: {
        key: 'isShowModalCategory',
        value: false
      }
    })

    yield put({
      type: OPEN_TOAST,
      payload: {
        message: 'ทำรายการสำเร็จ',
        type: 'success',
      }
    })
 
    yield put({type: SET_LOADING, payload: { loading: false }})
 
    if(res?.data) {
      yield call(onFetchCategoryAllLevel, { payload: { actionFetch : 'create' }})
    }


  } catch (err) {
    console.log(err);
    yield put({type: SET_LOADING, payload: { loading: false }})
  }
}

export function* onUpdateCategories(action) {
  const { payload } = action
  const { id = "", name = "" } = payload

  try {
    yield call(categoriesProvider.updateCategories, { categoryId: id , body: {name} });
    yield put({ type: FETCH_CATEGORY_ALL_LEVEL, payload: { actionFetch: 'update'} });
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } });
    yield put({ type: ADD_KEY_VALUE_CATEGORIES, payload: {key:"isShowModalCategory", value: false} });
  } catch (err) {
    console.log(err);
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
  }
}

export function* onDeleteCategories(action) {
  const { payload } = action
  const { categoryId = {} } = payload

  try {
    yield call(categoriesProvider.deleteCategories, { categoryId: categoryId });
    yield put({ type: FETCH_CATEGORY_ALL_LEVEL, payload: { actionFetch: 'delete' } });
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } });
  } catch (err) {
    console.log(err);
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ`, type: 'danger' } });
  }
}

export function* onFetchCategories(action) {
  const { payload } = action;
  const { actionFetch = "" } = payload;
  const {
    modalCategory,
    selectCategoryLevel1,
    selectCategoryLevel2,
    selectCategoryLevel3,
    categoryType,
  } = yield select(getCategories);
  const { level, index, type } = modalCategory;

  try {
    let category_level1 = null;
    let category_level2 = null;
    
    if (actionFetch == "create") {
      if (level == 1) {
        const res = yield call(onGetParentCategoriesAll, { payload: { actionFetch: actionFetch } });
        yield put({
          type: SET_CATEGORY_SYSTEM,
          payload: {
            categoryLevel1: res?.data,
            selectCategoryLevel1: res?.data[res?.data?.length - 1],
            categoryLevel2: [],
            selectCategoryLevel2: {},
            categoryLevel3: [],
            selectCategoryLevel3: {},
          },
        });
        return true;
      } else if (level == 2) {
        const cate2 = yield call(onGetChildCategories, {
          payload: {
            parentId: selectCategoryLevel1?.id,
            categoryLevel: `categoryLevel2`,
            level: 2,
            actionFetch,
          },
        });

        if (!isEmpty(cate2?.data)) {
          yield call(onGetChildCategories, {
            payload: {
              parentId: cate2?.data[cate2?.data?.length - 1].id,
              categoryLevel: `categoryLevel3`,
              level: 3,
              actionFetch,
            },
          });
        }
        return true;
      } else {
        yield call(onGetChildCategories, {
          payload: {
            parentId: selectCategoryLevel2?.id,
            categoryLevel: `categoryLevel3`,
            level: 3,
            actionFetch,
          },
        });
        return true;
      }
    } else if (actionFetch == "update") {
      if (level == 1) {
        const res = yield call(onGetParentCategoriesAll, {
          payload: { actionFetch: actionFetch },
        });
        yield put({
          type: SET_CATEGORY_SYSTEM,
          payload: {
            categoryLevel1: res?.data,
            selectCategoryLevel1: res?.data[index],
            selectCategoryLevel2: selectCategoryLevel2,
            selectCategoryLevel3: selectCategoryLevel3,
          },
        });
        return true;
      } else if (level == 2) {
        yield call(onGetChildCategories, {
          payload: {
            parentId: selectCategoryLevel1?.id,
            categoryLevel: `categoryLevel2`,
            level: 2,
            actionFetch,
          },
        });
        return true;
      } else {
        yield call(onGetChildCategories, {
          payload: {
            parentId: selectCategoryLevel2?.id,
            categoryLevel: `categoryLevel3`,
            level: 3,
            actionFetch,
          },
        });
        return true;
      }
    } else if (actionFetch == "delete") {
      if (level == 1) {
        let cate1 = null;
        let cate2 = null;

        cate1 = yield call(onGetParentCategoriesAll, {
          payload: { actionFetch: actionFetch },
        });

        if (!isEmpty(cate1?.data)) {
          cate2 = yield call(onGetChildCategories, {
            payload: {
              parentId: cate1?.data[0].id,
              categoryLevel: `categoryLevel2`,
              level: 2,
              actionFetch,
            },
          });
        }

        if (!isEmpty(cate2?.data)) {
          yield call(onGetChildCategories, {
            payload: {
              parentId: cate2?.data[0]?.id,
              categoryLevel: `categoryLevel3`,
              level: 3,
              actionFetch,
            },
          });
        }

        return true;
      } else if (level == 2) {
        let cate2 = null;
        cate2 = yield call(onGetChildCategories, {
          payload: {
            parentId: selectCategoryLevel1?.id,
            categoryLevel: `categoryLevel2`,
            level: 2,
            actionFetch,
          },
        });

        if (!isEmpty(cate2?.data)) {
          yield call(onGetChildCategories, {
            payload: {
              parentId: cate2?.data[0].id,
              categoryLevel: `categoryLevel3`,
              level: 3,
              actionFetch,
            },
          });
        }

        return true;
      } else {
        yield call(onGetChildCategories, {
          payload: {
            parentId: selectCategoryLevel2?.id,
            categoryLevel: `categoryLevel3`,
            level: 3,
            actionFetch,
          },
        });
        return true;
      }
    }

    // find category lavel 1
    if (level == 1 && (actionFetch == "" || actionFetch == "select")) {
      if (categoryType == "custom") {
        category_level1 = yield call(onGetParentCategoriesAll, {
          payload: { actionFetch: actionFetch },
        });
      } else {
        category_level1 = yield call(getDefaultCategory, {
          payload: { level: 1 },
        });
      }
    }

    // find category level 2
    if (!isEmpty(category_level1?.data)) {
      let parentId = null;

      if (actionFetch == "delete" || index == 0) {
        parentId = category_level1?.data[0]?.id;
      } else {
        parentId = selectCategoryLevel1?.id;
      }

      if (categoryType == "custom") {
        category_level2 = yield call(onGetChildCategories, {
          payload: {
            parentId: parentId,
            categoryLevel: `categoryLevel2`,
            level: 2,
            actionFetch,
          },
        });
      } else {
        const parent =
          level == 2 ? selectCategoryLevel2?.id : category_level1?.data[index]?.id;
          category_level2 = yield call(getDefaultCategory, { payload: { parentId: parent, level: 2 }});
      }
    } else {
      if (actionFetch == "" || actionFetch == "select") {
        yield put({
          type: SET_CATEGORY_SYSTEM,
          payload: {
            selectCategoryLevel1: {},
            selectCategoryLevel2: {},
            selectCategoryLevel3: {},
            categoryLevel1: [],
            categoryLevel3: [],
            categoryLevel2: [],
          },
        });
      }
    }

    // find category level 3
    if (!isEmpty(category_level2?.data)) {
      const parentId = category_level2?.data[0]?.id;

      if (categoryType == "custom") {
        yield call(onGetChildCategories, {
          payload: {
            parentId: parentId,
            categoryLevel: `categoryLevel3`,
            level: 3,
            actionFetch,
          },
        });
      } else {
        const parent = category_level2?.data[0]?.id;
        yield call(getDefaultCategory, { payload: { parentId: parent, level: 3 }});
      }
    } else {
      if (actionFetch == "select" || actionFetch == "") {
        yield put({
          type: SET_CATEGORY_SYSTEM,
          payload: {
            selectCategoryLevel3: {},
            categoryLevel3: [],
          },
        });
      }
    }
  } catch (err) {
    console.log(err);
    yield put({ type: SET_LOADING, payload: { loading: false } });
  }
}



// ============ function resue ============= //
export function* onFetchCategoryAllLevel(action) {
  const { payload } = action

  try {
    yield put({type: SET_LOADING, payload: { loading: true }})
    yield call(onFetchCategories, { payload: { actionFetch : payload?.actionFetch }})
    yield put({type: SET_LOADING, payload: { loading: false }})

  } catch (err) {
    console.log(err);
    yield put({type: SET_LOADING, payload: { loading: false }})

  }
}


export function* onGetCategoryAll(action) {
  const { payload } = action
  const { level = 1, actionFetch = 'select' } = payload
  const { categoryLevel2, modalCategory, categoryType, selectCategoryLevel2, categoryLevel3 } = yield select(getCategories)
  const { index } = modalCategory
  const { shopDetail = {} } = yield select(getShop)

  try {
      if(level === 1) {
          yield call(onFetchCategoryAllLevel, { payload: { actionFetch: actionFetch }})
      } else if(level === 2) {
        if(categoryType == 'custom') {
          yield call(onGetChildCategories, { payload: { parentId: categoryLevel2[index]?.id, categoryLevel: `categoryLevel3`, level: 3, actionFetch: actionFetch }})
        } else {
          yield call(getDefaultCategory, { payload: { level: 3, parentId: selectCategoryLevel2?.id }})
        }
      } else if(level === 3) {
        yield put({
          type: ADD_KEY_VALUE_CATEGORIES,
          payload: {
            key: 'selectCategoryLevel3',
            value: categoryLevel3[index]
          }
        })
      }
  } catch (err) {
    console.log(err);
  }
}


export function* onSetCategoryShop(action) {
  try {
    const { currentShop = {} } = yield select(getShop);
    const { categoryType = {} } = yield select(getCategories);
    const shopApi = new shopProvider();
    yield call(shopApi.updateShopDetail, {body:{categoryType}, shopId: currentShop?.id});
    yield put({
      type: GET_SHOP_DETAIL,
      payload: {
        shopId: currentShop?.id
      }
    })

    yield put({
      type: GET_CURRENT_SHOP_DETAIL
    })
    yield put({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ`, type: 'success' } });
  } catch (error) {
  console.log(error);
  } 
}

function* getDefaultCategory(action) {
  const { payload } = action
  const { parentId = null, level } = payload
  const { modalCategory, categoryType = "" } = yield select(getCategories)
  const { index } = modalCategory
  const { shopDetail: shop = {} } = yield select(getShop);

  try {
    let res = null
    const shopDetail = { categoryType: categoryType, id: shop?.id }
    if(parentId) {
      res = yield call(categoriesProvider.getCategories, { parentId },shopDetail,level)
    } else {
      res = yield call(categoriesProvider.getCategories,null,shopDetail,level)
    }

    if(!isEmpty(res?.data)) {
      yield put({
        type: SET_CATEGORY_SYSTEM,
        payload: {
          [`categoryLevel${level}`]: res?.data || [],
          [`selectCategoryLevel${level}`]: level == 1 ? res?.data[index] : res?.data[0] || {}
        }
      })
    } else {
      yield put({
        type: SET_CATEGORY_SYSTEM,
        payload: {
          [`categoryLevel${level}`]: [],
          [`selectCategoryLevel${level}`]: {}
        }
      })
    }

    return res
  } catch (err) {
    console.log(err);
  }
}


export default function* useWatcher() {
  yield takeLatest(GET_CATEGORIES, onGetCategories);

  yield takeLatest(GET_PARENT_CATEGORIES_ALL, onGetParentCategoriesAll);
  yield takeLatest(GET_CHILD_CATEGORIES, onGetChildCategories)
  yield takeLatest(CREATE_CATEGORIES, onCreateCategories)
  yield takeLatest(UPDATE_CATEGORIES, onUpdateCategories)
  yield takeLatest(DELETE_CATEGORIES, onDeleteCategories)

  yield takeLatest(FETCH_CATEGORIES, onFetchCategories)

  yield takeLatest(FETCH_CATEGORY_ALL_LEVEL, onFetchCategoryAllLevel)
  yield takeLatest(GET_CATEGORY_ALL, onGetCategoryAll)
  yield takeLatest(SET_CATEGORY_SHOP, onSetCategoryShop)



}
