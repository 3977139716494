import React, { useState, useEffect } from "react";
import InputText from "components/InputText";
import ModalConfirmChildren  from "components/ModalConfirmChildren";
import './style.scss';

const labels = { SUCCESSFUL: 'ระบบส่งลิงค์สำหรับเปลี่ยนรหัสผ่านไปที่อีเมลแล้วกรุณาตรวจสอบ',
                 FAILED: 'ทำรายการไม่สำเร็จ กรุณาลองใหม่'}


const ModalConfirmEmail = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { isOpen, handleConfirm } = props;
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    return () => {
        clearData();
    };
  }, []);

  useEffect(() => {
    if(props.errors) 
        setErrors(props.errors);
  }, [props.errors])

  const clearData = () => {
      setErrors({});
      setEmail('');
  }
  

  const handleSubmit = () => {
    if (validateForm()) 
        handleConfirm(email);
  };

  const handleCancel = () => {
      props.handleCancel();
      clearData();
  }

  const validateForm = () => {
      let errorForm = {} 
      const checkIsEmpty = (text) => /^\s*$/.test(text)
      const checkIsValidEmailFormat = (email) => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
      
      if(checkIsEmpty(email)){
          errorForm.email = "อีเมลไม่ถูกต้อง กรุณาลองใหม่"
      }   
      else {
          if (!checkIsValidEmailFormat(email)) 
          errorForm.email = "รูปแบบอีเมลไม่ถูกต้อง กรุณาลองใหม่"
      }

      setErrors(errorForm);
      return Object.keys(errorForm).length === 0
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') 
        handleSubmit();
  }
  
  return (
    <React.Fragment>
      <ModalConfirmChildren isOpen={isOpen} 
                            btnConfirm={"ยืนยัน"} 
                            btnCancel={"ยกเลิก"} 
                            handleConfirm={handleSubmit} 
                            handleCancel={handleCancel} >
            <div className="modal-container">
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <h3>ระบุอีเมลที่ต้องการเปลี่ยนรหัสผ่าน</h3>
                </div>
                <div style={{width: '100%'}}>
                    <InputText
                        className={"input-text"}
                        onChange={(event) => setEmail(event.target.value) }
                        value={email}
                        label={"อีเมล"}
                        placeholder={"กรอกอีเมล"}
                        isRequire={true}
                        isError={!!errors?.email}
                        errorMsg={errors?.email}
                        classNameTitle={"input-title"}
                        classNameSubTitle={"input-subtitle"}
                        onkeyDown={handleKeyDown}
                    />
                </div>
            </div>
      </ModalConfirmChildren>
    </React.Fragment>
  );
};

ModalConfirmEmail.defaultProps = {};

export default ModalConfirmEmail;

ModalConfirmEmail.defaultProps = {
    isOpen:false,
    handleConfirm:() => {} ,
    handleCancel:() => {},
    errors:{} 
}
