
import React, { Component } from 'react';
import ModalCalendar from '../modalCalendar';
import calendar from '../../images/icons/calendar.svg'
import classNames from 'classnames';
import moment from 'moment';
import './index.scss';

class DatePickerInputComponent extends Component {

    static defaultProps = {
        checkEndDate: null
    }

    constructor(props) {
        super(props)
        this.state = {
            date: moment().format('DD/MM/YYYY'),
            isOpen: false
        }
    }

    componentDidMount() {
        this.setState({
            date: this.props.value && this.props.value
        })
    }

    toogleDisplay = () => {
        // if(!this.state.isOpen){  ///outside click to hide 
        //     document.addEventListener('click', this.handleOutsideClick, false)
        // } else {
        //     document.removeEventListener('click', this.handleOutsideClick, false)
        // }
        if (this.props.toggleModal) {
            this.props.toggleModal();
        } else {
            this.setState({ isOpen: !this.state.isOpen })
        }
        
    }

    onChange = date => {
        this.setState({ date: date });
    }

    onSubmitDateSelected = date => {
        this.props.onSubmitDateSelected(date);
    }

    // handleOutsideClick = (e) => {  ///outside click to hide 
    //    if(this.node.contains(e.target)){
    //        return
    //    }
    //    this.toogleDisplay()
    // }

    render() {

        let { date = moment().format("DD/MM/YYYY"),
            oneModalPerSceenMode, // allow to show only one modal per screen
            allowpreviousDate, // this allowpreviousDate control previousDate on/off
            dissableDateFuture, 
            dissableDatePast, 
            checkEndDate,
            checkStartDate,
            isModalOpen, 
            isError,
            disabled } = this.props; 
        let { isOpen } = this.state;

        return (<React.Fragment>
            <div className={classNames('date-picker-container', {'disabled': disabled},{'error-input': isError})}>
                <div className="date-picker-input">
                    <input
                        type="text" 
                        placeholder="เลือกวัน" 
                        size="10" 
                        autoComplete="off" 
                        value={date && moment(date, "DD/MM/YYYY").format("DD/MM") + moment(date, 'DD/MM/YYYY').add(543, 'y').format('/YYYY')} 
                        onClick={this.toogleDisplay}
                        disabled={disabled} />
                    <span className="date-picker-input-suffix" onClick={this.toogleDisplay}>
                        <img src={calendar} alt="" />
                    </span>
                </div>
            </div>
            <ModalCalendar isOpen={isModalOpen ? isModalOpen : isOpen}  // isModalOpen for manual show only on modal per screen
                toogleDisplay={this.toogleDisplay}
                onChange={this.onChange}
                value={date}
                oneModalPerSceenMode={oneModalPerSceenMode} // this allowpreviousDate control previousDate on/off
                onSubmitDateSelected={this.onSubmitDateSelected}
                allowpreviousDate={allowpreviousDate} /// this allowpreviousDate control dissableDateFuture on/off
                dissableDateFuture={dissableDateFuture}
                dissableDatePast={dissableDatePast}
                checkEndDate={checkEndDate}
                checkStartDate={checkStartDate}
            />
        </React.Fragment>)


        // let { onChange, value, allowpreviousDate } = this.props; /// this allowpreviousDate control previousDate on/off ///// example "23/02/2020 date"
        // let { isOpen, date } = this.state;

        // return (<React.Fragment>
        //     <div name="modal-calendar" ref={ref => this.node = ref}>
        //     <ModalCalendar isOpen={isOpen}  // here
        //         toogleDisplay={this.toogleDisplay}
        //         onChange={this.onChange}
        //         onSubmitDateSelected={this.onSubmitDateSelected}
        //         allowpreviousDate={allowpreviousDate} /// this allowpreviousDate control previousDate on/off
        //         date={this.state.date}
        //     />
        //     </div>

        //     <div className="date-picker">
        //         <div className="date-picker-input" onClick={this.toogleDisplay} >
        //             <input placeholder="เลือกวัน" size="10" autoComplete="off"
        //                 value={date && moment(date, "DD/MM/YYYY").format("DD/MM") + moment(date, 'DD/MM/YYYY').add(543, 'y').format('/YYYY')}
        //                 onClick={this.toogleDisplay} />
        //             <span className="date-picker-input-suffix">
        //                 <img src={calendar} alt="" />
        //             </span>
        //         </div>
        //     </div>

        // </React.Fragment>)


    }


}

DatePickerInputComponent.defaultProps = {
    checkEndDate: null,
    checkStartDate: null
}

export default DatePickerInputComponent;

