import { call, put, select, takeLatest } from "redux-saga/effects";
import logisticProvider from 'provider/ligisticProvider'
import {
    CREATE_LOGISTIC_PRICE, GET_LIST_ADD_LOGISTIC, GET_LIST_SHOP_LOGISTIC, SET_KEY_ADD_LOGISTIC_PRICE, SET_MODAL_CONFIRM,
    UPDATE_LOGISTIC_PRICE, GET_SHOP_RATE_LOGISTIC_BY_ID, SET_EDIT_SHOP_LOGISTIC, HANDLE_APPLY_ALL_SHOP_LOGISTIC,
    HANDLE_DELETE_SHOP_LOGISTIC, GET_LIST_BY_LOGISTIC_ID, HANDLE_CHANGE_STATUS_LOGISTIC, 
    // GET_LIST_ALL_SHOP_LOGISTIC_BY_SHOP

} from 'redux/actions/logisticPrice'
import { getLogisticPrice, getShop } from "./selectors";
// import { OPEN_MODAL_CONFIRM } from "redux/actions/modalConfirm";
import { setLocalStorage } from "helper/utils";
import { OPEN_MODAL_MESSAGE_ERROR } from "redux/actions/modalMessage";
// import { OPEN_MODAL_ALERT } from "redux/actions/modalAlert";
import { SET_LOADING } from "redux/actions/app";
// import { Select } from "antd";


export function* getListLogistics(action) {
    // const { payload } = action
    try {
        const listLogistics = yield call(logisticProvider.getLogisticList)
        yield put({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'listLogistics', value: listLogistics } })
        // yield put({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'logisticSelected', value: listLogistics[0] } })
    } catch (error) {
        console.log('error', error)
    }
}

export function* getListShopLogisticByLogisticId(action) {
    const { payload } = action
    const shopState = yield select(getShop)
    const logisticPriceState = yield select(getLogisticPrice)
    const params = {
        shopId: shopState.currentShop.id,
        logisticId: payload.logisticId,
        page: logisticPriceState.page,
        limit: logisticPriceState.limit
    }
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        if (payload.logisticId !== undefined) {
            const listLogistic = yield call(logisticProvider.getProviderShopLogisticByShopId, params)
            yield put({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'shopLogistic', value: listLogistic } })
            yield put({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'total', value: listLogistic.count } })
        } else {
            yield getListShopLogistic()
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        console.log('error', error)
    }
}
export function* handleCreateLogisticPrice(action) {
    try {
        const logisticPriceState = yield select(getLogisticPrice)
        const shopState = yield select(getShop)
        const rates = []
        let previouseStartValue = 0;
        // eslint-disable-next-line array-callback-return
        logisticPriceState.price.map((item, idx) => {
            
            if (item.status === "active") {

                if(previouseStartValue < item.start && item?.status !== "inactive" )
                rates.push({
                    price: item.price,
                    start: item.start,
                    end: (logisticPriceState?.calculateBy === "weight" && !item.end) ? 10000 : item.end,
                    rateType: logisticPriceState?.calculateBy === "quantity" ? item.rateType : "flat"
                })

                previouseStartValue = item.start;
            }
        })
        const data = {
            shopId: shopState.currentShop.id,
            name: logisticPriceState?.name,
            type: "ladder",
            logisticId: logisticPriceState.logisticSelected.id,
            logisticCode: logisticPriceState.logisticSelected.code,
            status: "show",
            rates: rates,
            calculateBy: logisticPriceState?.calculateBy
        }
        const resCreate = yield call(logisticProvider.createShopLogistic, data)
        if (resCreate.data.message === "NAME_ALREADY") {
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'ชื่อถูกใช้แล้ว' } })
        } else if (resCreate.data.code === 422) {
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'รายการผิดพลาด' } })
        } else {
            yield put({ type: SET_MODAL_CONFIRM, payload: { isOpen: true } })
        }

        setLocalStorage({key: "trigger", value: "LOGISTIC_SHOP_PROVIDER_LIST"})

    } catch (error) {
        console.log('error', error)
    }
}

export function* getListShopLogistic(action) {
    try {
        const shopState = yield select(getShop)
        const logisticPriceState = yield select(getLogisticPrice)
        const listShopLogistic = yield call(logisticProvider.getListShopLogistic, { id: shopState.currentShop.id, page: logisticPriceState.page, limit: logisticPriceState.limit })
        yield put({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'shopLogistic', value: listShopLogistic } })
        yield put({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'total', value: listShopLogistic.count } })
    } catch (error) {
        console.log('error', error)
    }

}

export function* handleUpdateLogisticPrice(action) {
    const { payload } = action
    const logisticPriceState = yield select(getLogisticPrice)
    // const shopState = yield select(getShop)
    let rates = []
    // eslint-disable-next-line array-callback-return
    logisticPriceState.price.map((item, idx) => {

        rates.push({
            price: item.price,
            start: item.start,
            end: (logisticPriceState?.calculateBy === "weight" && !item.end) ? 10000 : item.end,
            rateType: logisticPriceState?.calculateBy === "quantity" ? item.rateType : "flat",
            shopLogisticRateId: item.id,
            deleted: item?.status === "inactive" ? true : false
        })

    })


    ///create new price length
    // eslint-disable-next-line array-callback-return
    rates.map((e, index) => {
        if (e.shopLogisticRateId === undefined) {
            delete e.shopLogisticRateId
            delete e.deleted
        }
    })

    let removeRateLogistic = logisticPriceState.removeRateLogisiticPrice
    // eslint-disable-next-line array-callback-return
    removeRateLogistic.map((e, index) => {
        removeRateLogistic[index].deleted = true
        removeRateLogistic[index].price = 0
        e.shopLogisticRateId = e.id
        delete removeRateLogistic[index].id
        delete removeRateLogistic[index].shopId
        delete removeRateLogistic[index].shopLogisticId
        delete removeRateLogistic[index].status
    });

    /* กรณีที่เปลี่ยน calculateBy ให้ลบข้อมูลเก่าออกด้วย */
    const {snapData, calculateBy} = logisticPriceState || {};

    if(snapData && snapData?.calculateBy !== calculateBy) {
        const deletedData = [...snapData?.price].map(item => {
            item.deleted = true;
            item.shopLogisticRateId = item.id;

            if(item.shopId) delete item.shopId;
            if(item.id) delete item.id;
            if(item.shopLogisticId) delete item.shopLogisticId;

            return item;
        })
        removeRateLogistic = [...removeRateLogistic, ...deletedData]
    }

    let data = {
        name: logisticPriceState.name,
        calculateBy: calculateBy,
        type: "ladder",
        rates: [...rates, ...removeRateLogistic]
    }

    //// check name update
    if (data.name === payload.name) {
        delete data.name
    }

    if(snapData && snapData?.calculateBy === calculateBy) {
        delete data.calculateBy
    }
    

    const resCreate = yield call(logisticProvider.updateShopLogistic, { data, id: payload.id })
    if (resCreate) {
        yield setLocalStorage({ key: 'shopDetailDefaultTab', value: { tab: 5 } })
        window.location.replace('/manage/shop/shopdetail')
    }
}

export function* getShopRateLogistic(action) {
    const { payload } = action
    const detailData = yield call(logisticProvider.getShopRateLogistic, { id: payload.id })
    yield put({ type: SET_EDIT_SHOP_LOGISTIC, payload: detailData.data })
}

export function* handleApplyAllShopLogistic(action) {
    const { payload } = action
    yield call(logisticProvider.applyAllShopLogisticId, payload.id)
    // if(res.data){
    //     yield put({type: OPEN_MODAL_CONFIRM,payload:{
    //         message: 'คุณแน่ใจหรือไม่ที่จะใช้การจัดส่งนี้กับสินค้าทั้งร้าน',
    //         btnCancel: 'ยกเลิก',
    //         btnConfirm: 'ยืนยัน'
    //     }})
    // }
}

export function* handleDeleteShopLogistic(action) {
    const { payload } = action
    try {
        const res = yield call(logisticProvider.deleteShopLogisticByLogisticId, payload.id)
        if (res) {
            yield getListShopLogistic()
        }
    } catch (error) {

    }
}

export function* handleChangeStatusLogistic(action) {
    const { payload } = action
    const data = {
        status: payload.status
    }
    const resUpdate = yield call(logisticProvider.updateShopLogistic, { data, id: payload.logisticId })
    if (resUpdate) {
        yield getListShopLogisticByLogisticId({ payload: { logisticId: payload.id } })
    }
}

// export function* getListAllShopLogisticByShopId(action) {
//     const ShopState = yield select(getShop)
//     const res = yield call(logisticProvider.getListShopLogisticAllByShopId, { id: ShopState.currentShop.id })
// }

export default function* useWatcher() {
    yield takeLatest(GET_LIST_ADD_LOGISTIC, getListLogistics)
    yield takeLatest(CREATE_LOGISTIC_PRICE, handleCreateLogisticPrice)
    yield takeLatest(GET_LIST_SHOP_LOGISTIC, getListShopLogistic)
    yield takeLatest(UPDATE_LOGISTIC_PRICE, handleUpdateLogisticPrice)
    yield takeLatest(GET_SHOP_RATE_LOGISTIC_BY_ID, getShopRateLogistic)
    yield takeLatest(HANDLE_APPLY_ALL_SHOP_LOGISTIC, handleApplyAllShopLogistic)
    yield takeLatest(HANDLE_DELETE_SHOP_LOGISTIC, handleDeleteShopLogistic)
    yield takeLatest(GET_LIST_BY_LOGISTIC_ID, getListShopLogisticByLogisticId)
    yield takeLatest(HANDLE_CHANGE_STATUS_LOGISTIC, handleChangeStatusLogistic)
    // yield takeLatest(GET_LIST_ALL_SHOP_LOGISTIC_BY_SHOP, getListAllShopLogisticByShopId)
}