/* eslint-disable default-case */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { connect } from "react-redux";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import "./index.scss";
import iconCopy from "../../images/icons/copy-2.svg";
import iconClose from "../../images/icons/close.svg";

import {
  dispatchPost,
  TOGGLE_OPEN_LIVE_SETTING_MODAL,
  TOGGLE_OPEN_LIVE_SETTING_CONFIRM_MODAL
} from "../../redux/actions/post";

import { dispatchLive,
  // GET_LIVE_STATUS, 
  PROVIDE_STREAM } from '../../redux/actions/live';

class ModalLiveSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      reconnectState:false
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.modalLiveSetting !== this.props.modalLiveSetting) {
      this.setState({ isOpen: nextProps.modalLiveSetting.isOpen });
    }

    if (nextProps.liveStatus !== this.props.liveStatus) {
      if(nextProps.liveStatus === 'publish' || nextProps.liveStatus === 'ready') this.setState({reconnectState:false});
    }
  };

  closeModal = () => {
    this.props.dispatch(
      dispatchPost({
        type: TOGGLE_OPEN_LIVE_SETTING_MODAL,
        payload: { isOpen: false },
      })
    );
  };

  openPublishLiveModal = () => {
    const title = "ต้องการเริ่มการไลฟ์?";
    const type = "inform";
    const btnText = "เริ่มการไลฟ์";
    this.props.dispatch(
      dispatchPost({
        type: TOGGLE_OPEN_LIVE_SETTING_CONFIRM_MODAL,
        payload: { isOpen: true, title, type, btnText },
      })
    );
  };

  openStopLiveModal = () => {
    const title = "ต้องการจบการไลฟ์??";
    const type = "danger";
    const btnText = "จบการไลฟ์";
    this.props.dispatch(
      dispatchPost({
        type: TOGGLE_OPEN_LIVE_SETTING_CONFIRM_MODAL,
        payload: { isOpen: true, title, type, btnText },
      })
    );
  };

  getButtonByLiveStatus = () => {
    const {upstreamStatus} = this.props.liveStatus || {};
    const {prepareLive} = this.props;
    const {reconnectState} = this.state;

    
    switch (upstreamStatus) {
      case "ready":
        return prepareLive ? <button className="btn btn-secondary"> {reconnectState ? 'เชื่อมต่ออีกครั้ง':'กำลังเตรียมไลฟ์'} </button>
        :<button className="btn btn-submit" onClick={this.openPublishLiveModal} > เริ่มการไลฟ์ </button>
      case "wait":
        return <button className="btn btn-secondary"> เริ่มการไลฟ์ </button>;
      case "closed":
        return <button className="btn btn-secondary"> เริ่มการไลฟ์ </button>;
      case "publish":
        return <button className="btn btn-danger" onClick={this.openStopLiveModal} > จบการไลฟ์ </button>;
      case "disconnect":
        return reconnectState ? <button className="btn btn-secondary"> เชื่อมต่ออีกครั้ง </button>  :
                                <button className="btn btn-danger" onClick={this.openStopLiveModal} > จบการไลฟ์ </button>
    }
  }

  reconnectLive = () => {
    this.setState({reconnectState: true});
    this.props.dispatch(dispatchLive({ type: PROVIDE_STREAM, payload: {}, }) );
    this.props.prepareLiveState();
  }

  exportConfigFile = () => {
    const basicFile = `[General]
Name=outlet live

[Video]
BaseCX=1080
BaseCY=1920
OutputCX=720
OutputCY=1280

[Panels]
CookieId=D5EC141AC3E9A8D5

[Output]
Mode=Advanced

[AdvOut]
RescaleRes=1920x1080
TrackIndex=1
RecType=FFmpeg
RecRescaleRes=1920x1080
RecTracks=1
FLVTrack=1
FFOutputToFile=false
FFFormat=
FFFormatMimeType=
FFRescaleRes=1920x1080
FFVEncoderId=0
FFVEncoder=
FFAEncoderId=0
FFAEncoder=
FFAudioMixes=1
FFURL=rtmp://a.rtmp.youtube.com/live2/tuk6-q8sw-ruba-cfyz-4tm9
FFVBitrate=8000
FFVGOPSize=2
`;
    const service = `{
"settings": {
    "bwtest": false,
    "key": "${this.props.post?.streamKey}",
    "server": "${this.props.post?.streamUrl}",
    "use_auth": false
},
"type": "rtmp_custom"
}`;
    const streamEncoder = `{
"bitrate": 3000,
"keyint_sec": 2,
"preset": "fast",
"profile": "main",
"tune": "zerolatency",
"x264opts": "level=4.1"
}`

    let zip = new JSZip();
    zip.file("basic.ini", basicFile);
    zip.file("service.json", service);
    zip.file("streamEncoder.json", streamEncoder);
    zip.generateAsync({type: "blob"}).then(function(content) {
    FileSaver.saveAs(content, "profile.zip");
    });
}

  render() {
    const { isOpen, reconnectState=false } = this.state;
    const { 
      // generateKey, post, 
      liveStatus: _liveDetail } = this.props;
    const { upstreamKey='', 
            upstreamUrl='', 
            upstreamStatus:liveStatus } = _liveDetail || {};
    // const streamKey = post?.streamKey;
    // const streamUrl = post?.streamUrl;

    return (
      <React.Fragment>
        <div className={`modal-setting ${!isOpen ? "hide" : ""}`}>
          <div className="modal-setting-container">
            <div className="close-btn" onClick={this.closeModal}>
              <img src={iconClose} alt='icon-close'/>
            </div>
            <div className="row justify-content-center mb-2">ตั้งค่าไลฟ์</div>
            { (liveStatus !== 'disconnect') || (liveStatus === 'disconnect' &&  reconnectState) ?
              <React.Fragment>
                <div className="row">
                  <div className="col-10">
                    <label>Stream key (คัดลอกไปวางยัง encoder)</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paymentTime"
                      value={upstreamKey}
                    />
                  </div>
                  <div className="col-2">
                    <CopyToClipboard text={upstreamKey} onCopy={() => this.setState({copied: true})}>
                          <div className={'tooltip'}  
                              onMouseLeave={ event => event.target.parentElement.classList.remove('tooltiptext-show') } 
                              onClick={ event => event.target.parentElement.classList.add('tooltiptext-show') }>
                                <img src={iconCopy} className="copy-btn"/>
                                <span className="tooltiptext">คัดลอก</span>
                          </div>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10">
                    <label>Stream URL</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paymentTime"
                      value={upstreamUrl}
                    />
                  </div>
                  <div className="col-2">
                  <CopyToClipboard text={upstreamUrl} onCopy={() => this.setState({copied: true})}>
                          <div className={'tooltip'}  
                              onMouseLeave={ event => event.target.parentElement.classList.remove('tooltiptext-show') } 
                              onClick={ event => event.target.parentElement.classList.add('tooltiptext-show') }>
                                <img src={iconCopy} className="copy-btn"/>
                                <span className="tooltiptext">คัดลอก</span>
                          </div>
                  </CopyToClipboard>
                  </div>
                </div>
              </React.Fragment>       
              : !reconnectState &&
                <React.Fragment>      
                <div className="row">
                  <div className="canvas-bg-gray">
                    <p><span> ไลฟ์ขาดการเชื่อมต่อ กรุณาเชื่อมต่ออีกครั้งหรือเลือกจบไลฟ์</span></p>
                    <button className="btn btn-submit" onClick={this.reconnectLive}> เชื่อมต่ออีกครั้ง </button>
                  </div>
                </div>
                </React.Fragment>      
            }

            <div className="row mt-3">
              <div className="col-6"> 
              <button className="btn btn-download" onClick={this.exportConfigFile}> ดาวน์โหลด Profile <div className="detail font-size-12px">( สำหรับ OBS )</div> </button>
              </div>
              <div className="col-6 text-right">
                {this.getButtonByLiveStatus()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  modalLiveSetting: state.post.modalLiveSetting,
  generateKey     : state.post.generateKey,
  post            : state.post.post,
  liveStatus      : state.live?.liveStatus
});

export default connect(mapStateToProps)(ModalLiveSetting);
