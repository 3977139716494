import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import host from "../config/host";
import "styles/_addAnnounce.scss";
import moment from "moment/moment";
import { Caption, H5, H6, SubTitle2, Tooltip } from "components/FontStyle";

import { dispatchModalMessage, OPEN_MODAL_MESSAGE } from 'redux/actions/modalMessage';
import { dispatchAnnounce, CREATE_ANNOUNCE, EDIT_ANNOUNCE, GET_ANNOUNCE } from 'redux/actions/announce';
import { dispatchApp, SET_LOADING } from "redux/actions/app";

import ButtonUploadWithoutAlbum from "components/output/ButtonUploadWithoutAlbum";
import ToastNotification from "components/ToastNotification";
import { TextInput } from "components/input";
import TextEditorWysiwyg from "components/TextEditorWysiwyg";
import DateTimePicker from "components/DateTimePicker";
import { isEmpty } from "lodash";

/* -------------------------------------------------------------------------- */
/*                               main component                               */
/* -------------------------------------------------------------------------- */

const AddAnnounce = (props) => {

  const {
    formData,
    mode,
    handleSubmit,
    handleCancel,
    handleFormDataChange,
    handleDateTimeChannge,
    errors,
  } = useAddAnnounce(props);

  const { image, title, description, duration, modalDateOpen } = formData;
  const { startDate, startTime, endDate, endTime } = duration;
  const { editorState } = description || {};

  const rowAnnounceImage = () => {
    return (
      <div className="row-container row-image">
        <div className="item-container">
          <Caption><b>รูปภาพประกาศ</b></Caption>
          <Caption>
            อัปโหลดได้ 1 รูป รองรับเฉพาะ png, jpg, gif, webp เท่านั้น
          </Caption>
          <div className="mt-3">
            <ButtonUploadWithoutAlbum
              width={"12em"}
              height={"12em"}
              handleChangeImage={(img) => handleFormDataChange("image", img)}
              exImage={image}
              addTypeImage={['image/webp']}
            />
          </div>
        </div>
      </div>
    );
  };

  const rowAnnounceTitle = () => {
    const inputTitle = (
        <TextInput
          label="หัวข้อประกาศ"
          require={true}
          defaultValue={title}
          title={"จำกัด 80 ตัวอักษร"}
          handleChangeText={(value) => handleFormDataChange("title", value)}
          placeholder={"กรอกหัวข้อ"}
          error={errors["title"]}
          maxLength={80}
        />
      );
    return (
      <div className="row-container row-title">
        <div className="item-container">{inputTitle}</div>
      </div>
    );
  }

  const rowAnnounceDescription = () => {
    return (
      <div className="row-container row-description">
        <div className="item-container">
         <div className="d-flex flex-row gap-1">
             <SubTitle2 /* className="require" */>รายละเอียดเนื้อหา</SubTitle2>
             <Tooltip className="d-flex flex-row align-items-center color-grey">(จำกัด 10,000 ตัวอักษร)</Tooltip>
         </div>
         <TextEditorWysiwyg
           value={editorState}
           maxLength={10000}
           onEditorChange={(editorData) => handleFormDataChange("description", editorData)}
         />
         {errors?.description && ( <span className="text-danger mt-1">{errors?.description}</span> )}
       </div>
      </div>
    );
  }

  const rowAnnounceDuration = () => {
    return (
      <div className="row-container row-duration">
        <div className="item-container">
          <DateTimePicker
            isModalDateOpen={modalDateOpen === "startDate"}
            toggleModalDate={() => handleFormDataChange( "modalDateOpen", modalDateOpen === "startDate" ? "" : "startDate" ) }
            disabled={false}
            allowpreviousDate={false}
            isDateError={errors["startDate"] ? true : false}
            isTimeError={errors["startTime"] ? true : false}
            date={startDate}
            time={startTime}
            labelDate={"วันที่เริ่มต้น"}
            labelTime={"เวลาเริ่มต้น"}
            onChange={(key,value) => handleDateTimeChannge('start',key,value)}
            dateErrorMessage={""}
            timeErrorMessage={""}
          />
           <DateTimePicker
            isModalDateOpen={modalDateOpen === "endDate"}
            toggleModalDate={() => handleFormDataChange( "modalDateOpen", modalDateOpen === "endDate" ? "" : "endDate" ) }
            disabled={false}
            allowpreviousDate={false}
            dissableDatePast={startDate}
            isDateError={errors["endDate"] ? true : false}
            isTimeError={errors["endTime"] ? true : false}
            date={endDate}
            time={endTime}
            // disabledTime={startTime}
            labelDate={"วันที่สิ้นสุด"}
            labelTime={"เวลาสิ้นสุด"}
            onChange={(key,value) => handleDateTimeChannge('end',key,value)}
            dateErrorMessage={""}
            timeErrorMessage={""}
          />
        </div>
      </div>
    );
  }

  const rowFooter = () => {
    return <div className="row-container row-footer">
        <div className="item-container">
           <div className="button-wrap">
             <button className="btn btn-cancel" onClick={handleCancel}>ยกเลิก</button>
             <button className="btn btn-confirm" onClick={handleSubmit}>บันทึก</button>
           </div>
        </div>
    </div>
  }

  return (
    <div id="add-announce">
      <div className="add-announce-container">
        <H5>{mode === 'ADD' ? 'สร้างประกาศ' : 'แก้ไขประกาศ'}</H5>
        <div className="add-announce-detail">
            <H6>ข้อมูลประกาศ</H6>
            {rowAnnounceImage()}
            {rowAnnounceTitle()}
            {rowAnnounceDescription()} 
        </div>
        <div className="add-announce-detail">
            <H6>ระยะเวลาการประกาศ</H6>
            {rowAnnounceDuration()}
        </div>
        <div className="add-announce-detail">
            {rowFooter()}
        </div>
            <ToastNotification />
      </div>
    </div>
  );
};


/* -------------------------------------------------------------------------- */
/*                                    hook                                    */
/* -------------------------------------------------------------------------- */
const useAddAnnounce = (props) => {

    const dispatch            = useDispatch();
    const history             = useHistory();

    const location            = useLocation();
    const {pathname}          = location;

    const announceState       = useSelector(state => state.announce);
    const { currentAnnounce } = announceState || {};

    const appState            = useSelector(state => state.app);
    const { loading }         = appState || {};

    const defaultFormData = {
        image   : {},
        title   : "",
        description  : { editorState: "", htmlState: "" },
        modalDateOpen: "",
        duration: {
            startDate: moment(),
            startTime: moment('00:00','HH:mm'),
            endDate  : moment(),
            endTime  : moment('23:59','HH:mm'),
        }
    };
    const [formData, setFormData] = useState(defaultFormData);
    const [errors, setErrors] = useState({});
    const [mode, setMode] = useState('ADD'); // 'ADD', 'EDIT'

    /* ------------------------------ initial page ------------------------------ */
    useEffect(() => {
        initialComponent();
        return () => clearFormData();
    }, []);

    useEffect(() => {
        if(location) {
            const currentMode = pathname.includes('add') ? "ADD" : "EDIT";
            if(currentMode ===  "EDIT") {
                const {announceid} = props.match.params || {}
                if (!announceid) history.push("/manage/announce");
            }
        }
    }, [location])

    useEffect(() => {

        if (currentAnnounce && mode === 'EDIT') {
          /* ------------------------- initial data for update ------------------------ */
          let { image, title, announceEnd, announceBegin, description: description } =
            currentAnnounce || {};
          
          description = {
            editorState: (description),
            htmlState: (description),
          }; 

          const duration = {
            startDate: moment(announceBegin),
            startTime: moment(announceBegin),
            endDate  : moment(announceEnd),
            endTime  : moment(announceEnd),
          }

          if(image) {
              image.preview = `${host.image}${image?.path}`;
          } else {
              image = {};
          }
          
          setFormData((prev) => {
            return { ...prev, image, title, description, duration};
          });
        }
    },[currentAnnounce]);

    const initialComponent = () => {

        /* ---------------------------- set current mode ---------------------------- */
        const currentMode = pathname.includes('add') ? "ADD" : "EDIT";
        setMode(currentMode);


        if(currentMode === "EDIT") {
            const {announceid} = props.match.params || {}
            getCurrentAnnounce(announceid);
        }

    }

    const getCurrentAnnounce = (announceId) => {
        dispatch(dispatchAnnounce({type: GET_ANNOUNCE, payload: {announceId}}))
    }

    const clearFormData = () => {
        setFormData(defaultFormData);
    }

    const handleFormDataChange = (key,value) => {

        setFormData(prev => {
            return {...prev, [key]: value}
        })
    }

    const handleDateTimeChannge = (optionKey, key, value) => {

      const {startDate, endDate} = formData?.duration || {};
        
      const dateKey = optionKey === "start" ? "startDate" : "endDate";
      const timeKey = optionKey === "start" ? "startTime" : "endTime";
      switch (key) {
        case "date":
          if(optionKey==="start" && value > endDate) {
            setFormData( prev => ({ ...prev, modalDateOpen: "", duration: { ...prev.duration, [dateKey]: value, endDate: value}}));
          } else {
            setFormData( prev => ({ ...prev, modalDateOpen: "", duration: { ...prev.duration, [dateKey]: value }}));
          }
          break;
        case "time":
        setFormData( prev => ({ ...prev, modalDateOpen: "", duration: { ...prev.duration, [timeKey]: value }}));
        break;
      }
    };

    const uploadImage = async () => {
        const path = `${host.apiMedia}/upload`;
    
        const formDataImage = new FormData();
        formDataImage.append("file", formData.image?.file);
        formDataImage.append("serviceKey", host.serviceKey);
        formDataImage.append("secretKey", host.secretKey);
    
        const response = await axios.post(path, formDataImage);
    
        if (response.status === 200) {
          return response.data;
        } else {
          // handle error
          return null;
        }
    };

    const combindDataTime = (date, time) => new Date(`${date} ${time}`).toISOString();

    const validateForm = () => {
        const { title, description } = formData || {};
        let Errors = {};
        let messageError = "";

        if(isEmpty(title)) {
            Errors = {...Errors, title: "กรุณากรอกข้อมูลหัวข้อประกาศ"};
            messageError = "กรุณากรอกข้อมูลหัวข้อประกาศ";
        }
        
        // if(isEmpty(description?.htmlState)) {
        //     Errors = {...Errors, description: "กรุณากรอกรายละเอียดเนื้อหา"};
        //     messageError = "กรุณากรอกรายละเอียดเนื้อหา";
        // }
        
        setErrors({...Errors});

        if(!isEmpty(messageError)) {
            dispatch( dispatchModalMessage({ type: OPEN_MODAL_MESSAGE, payload: { message: messageError, title: "iconFail" }, }) );
        }

        /* ------------- no errors return true have errors return false ------------- */
        return (Object.keys(Errors).length === 0) 
    }

    const handleSubmit = async () => {
 
        if(validateForm() && loading === false) {
            try {

              dispatch(dispatchApp({type: SET_LOADING, payload:  { loading: true }}));

                const {duration, title, image, description} = formData;
                const {startDate, startTime, endDate, endTime} = duration;
    
                /* -------------------------- provide duration time ------------------------- */
                const startDateTime = combindDataTime( moment(startDate).format("MM/DD/YYYY"), moment(startTime).format("HH:mm") );
                const endDateTime = combindDataTime( moment(endDate).format("MM/DD/YYYY"), moment(endTime).format("HH:mm") );
    
                
                /* -------------------------- provide default param ------------------------- */
                const param = {
                    title: title,
                    description: (description?.htmlState),
                    announceBegin: startDateTime,
                    announceEnd: endDateTime
                }
                
                /* ------------ provide announce image by upload to default album ----------- */
                let uploadData = image;
                if(image) {

                    /* have image upload */
                    if (image?.file) {
                      uploadData = await uploadImage();
                      uploadData.path = `image/${uploadData?.fileName}`
                      param.image = uploadData;
                    } else {

                      /* case remove image */
                      if(JSON.stringify(image) === '{"file":null,"preview":null,"type":"delete"}') {
                        param.image = null;
                      } else {

                        /* have image default from API */
                        param.image = image;
                      }

                    }
                }
    
    
                if(mode === "ADD")
                dispatch( { type: CREATE_ANNOUNCE, payload: param, history: history, } );
    
                if(mode === "EDIT") {

                    /* if remove image */
                    if(!image) param.image = null;

                    const {announceid: announceId} = props.match.params || {}
                    dispatch(dispatchAnnounce({type: EDIT_ANNOUNCE, payload: {param, announceId}}));
                }
           
            } catch (error) {
                dispatch(dispatchApp({type: SET_LOADING, payload:  { loading: false }}));
                console.log(error);
            } finally {

            }
        }
        
    }

    const handleCancel = () => {
      history.push('/manage/announce')
    }


    return {
      formData,
      mode,
      handleFormDataChange,
      handleDateTimeChannge,
      handleSubmit,
      handleCancel,
      errors,
    };
};

export default AddAnnounce;
