import { isEmpty } from "lodash";
import {
  ADD_LIST_CHECKED_SHOP,
  REMOVE_LIST_CHECKED_SHOP,
  SET_KEY_LIST_CHECKED_SHOP,
} from "../actions/listCheckedShop";

const initialState = {
  listCheckedShop: {
    shops: {},
  },
  listCheckedShopOld: {
    shops: {},
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_KEY_LIST_CHECKED_SHOP: {
      return {
        ...state,
        [payload.key]: payload.value,
      };
    }

    case ADD_LIST_CHECKED_SHOP: {

      let stateShop = JSON.parse(JSON.stringify(state.listCheckedShop.shops || {}));
      const shopId = Object.keys(payload)[0];
      if(stateShop[shopId]){
        stateShop[shopId] = {...payload[shopId], ...stateShop[shopId]}
      } else {
        stateShop[shopId] = {...payload[shopId]}
      }
      return {
        ...state,
        listCheckedShop: {shops: {...stateShop}},
      };

    }

    case REMOVE_LIST_CHECKED_SHOP: {
      const { shopId = null, productId = null } = payload;

      const temp = { ...state.listCheckedShop.shops };
      for (const key in temp) {
        delete temp[key][productId];
      }

      // check shop has product
      const isShopEmpty = Object.keys(temp[shopId]).length === 0;
      if (isShopEmpty) {
        delete temp[shopId];
      }

      return {
        ...state,
        listCheckedShop: { shops: temp },
      };
    }

    default:
      return state;
  }
}
