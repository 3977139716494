/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { Input, Radio } from 'antd'
import LogoSelect from 'components/LogoSelect'
// import { divide, isEmpty } from 'lodash'
import React, { Component } from 'react'
import 'styles/_addlogisticprice.scss'
import { ReactComponent as IconTrash } from 'images/icons/trash.svg'
import ModalConfirm from 'components/modalConfirm/ModalConfirm'
import {
    CREATE_LOGISTIC_PRICE, dispatchLogisticPrice, GET_LIST_ADD_LOGISTIC, SET_DEFAULT_LOGISTIC_PRICE,
    //  SET_EDIT_SHOP_LOGISTIC, 
     SET_KEY_ADD_LOGISTIC_PRICE,
    SET_PRICE_LOGISTIC_PRICE, UPDATE_LOGISTIC_PRICE, HANDLE_ADD_LOGISTIC_DELETED, GET_SHOP_RATE_LOGISTIC_BY_ID,
    //  SET_MODAL_CONFIRM
} from 'redux/actions/logisticPrice'
import { connect } from 'react-redux';
import classnames from 'classnames'
import { 
    // isEmptyOrSpaces, 
    setLocalStorage, 
    // textIsEmpty 
} from 'helper/utils'
// import logistic from 'redux/reducers/logistic'
import ModalConfirmChildren from 'components/ModalConfirmChildren'
import { ReactComponent as IconSuccess } from 'images/icons/success.svg'
import InputNumberComponent from 'components/InputNumber'
import numeral from 'numeral'
import { OPEN_MODAL_ALERT, dispatchModalAlert } from 'redux/actions/modalAlert'
import { msgErrRequire } from 'helper/constant'
import ModalAlert from 'components/modalAlert/ModalAlert'
// import { dispatchLogistic } from 'redux/actions/logistic'
// import logisticPrice from 'redux/reducers/logisticPrice'


const calculateByOptions = [
  { value: "quantity", label: "กำหนดราคาค่าจัดส่งตามจำนวนชิ้น" },
  { value: "weight", label: "กำหนดราคาจัดส่งตามน้ำหนัก" },
];

class AddLogisticPrice extends Component {

    constructor(props) {
        super(props)
        this.state = {
          err: {
            start: [],
            price: [],
            end: [],
          },
          priceState: {
            piece: [{ start: 1, end: "", price: "", rateType: "flat", status: "active" }],
            weight: [{ start: 0.01, end: "", price: "", rateType: "", status: "active" }],
          },
        };
    }

    componentDidMount() {

      this.props.dispatch(dispatchLogisticPrice({
        type: SET_KEY_ADD_LOGISTIC_PRICE, payload: {
            key: "calculateBy",
            value: "quantity"
        }
      }))

        this.props.dispatch(dispatchLogisticPrice({ type: GET_LIST_ADD_LOGISTIC }))
        if (this.props.location.state != undefined) {

          this.props.dispatch(dispatchLogisticPrice({
            type: SET_KEY_ADD_LOGISTIC_PRICE, payload: {
                key: "logisticSelected",
                value: this.props.location.state
            }
          }))

          this.props.dispatch(dispatchLogisticPrice({ type: GET_SHOP_RATE_LOGISTIC_BY_ID, payload: { id: this.props.location.state.id } }))
          // this.props.dispatch(dispatchLogisticPrice({ type: SET_EDIT_SHOP_LOGISTIC, payload: this.props.location.state }))
        }
    }

    componentWillUnmount() {
        this.props.dispatch(dispatchLogisticPrice({ type: SET_DEFAULT_LOGISTIC_PRICE }))
        
        this.props.dispatch(dispatchLogisticPrice({
          type: SET_KEY_ADD_LOGISTIC_PRICE, payload: {
              key: "calculateBy",
              value: "quantity"
          }
        }))

        // this.props.dispatch(dispatchLogisticPrice({ type: SET_MODAL_CONFIRM, payload: { isOpen: false } }))
        // this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'name', value: '' } }))
        // this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { 
        //     key: 'logisticSelected', 
        //     value: this.props.logisticPrice.listLogistics.find(item => item.code === 'DHL') 
        // } }))
        // this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'price', value: [ {
        //     start: 1,
        //     end: null,
        //     price: null,
        //     rateType: 'flat',
        //     status: 'active',
        // }] } }))
    }

    componentDidUpdate(prevProps) {
        if (this.props.logisticPrice.listLogistics !== prevProps.logisticPrice.listLogistics) {

          if(this.props.location.state != undefined) {
            this.props.dispatch(dispatchLogisticPrice({
              type: SET_KEY_ADD_LOGISTIC_PRICE, payload: {
                  key: "logisticSelected",
                  value: this.props.location.state
              }
            }));
          } else {
            if(this.props.logisticPrice.listLogistics && this.props.logisticPrice.listLogistics.length > 0)
            this.props.dispatch(dispatchLogisticPrice({
              type: SET_KEY_ADD_LOGISTIC_PRICE, payload: {
                  key: "logisticSelected",
                  value: this.props.logisticPrice.listLogistics[0]
              }
            }));
          }

            // return {
            //     logisticPrice: prevProps.logisticPrice
            // }
        }
    }

    handleDeliveryTypeChange = (calculateByValue) => {

        const {priceState} = this.state || {};
        const { piece, weight } = priceState || {};

        const setPriceDataToReducerState = (value) => {
            this.props.dispatch(dispatchLogisticPrice({
                type: SET_KEY_ADD_LOGISTIC_PRICE, payload: {
                    key: "price",
                    value: value
                }
            }))
        }

        const setCalculateByToReducerState = (calculateBy) => {
          this.props.dispatch(dispatchLogisticPrice({
              type: SET_KEY_ADD_LOGISTIC_PRICE, payload: {
                  key: "calculateBy",
                  value: calculateBy
              }
          }))
      }

        switch (calculateByValue) {
          case "weight":
            setPriceDataToReducerState(weight);
            setCalculateByToReducerState("weight");
            this.setState(prev => ({...prev, priceState: {...priceState, piece: this.props.logisticPrice?.price}}));
            break;
          case "quantity": 
            setPriceDataToReducerState(piece);
            setCalculateByToReducerState("quantity")
            this.setState(prev => ({...prev, priceState: {...priceState, weight: this.props.logisticPrice?.price}}));
            break;
        }

    }


    onChangeLogisticSelect = (logistic) => {
        //undefined === 'create'  ||  !undefined === "edit"
        if (this.props.location.state == undefined) {
            this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'logisticSelected', value: logistic } }))
        }
    }

    onChangeName = (name) => {
        if (name.trim().length === 60) return
        this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'name', value: name } }))
        this.setState({
            err: {
                ...this.state.err,
                name: false
            }
        })
    }

    getStartCalculateByValue = () => {
      try {
        let price = this.props.logisticPrice.price;

        const nextLevelIncrease = this.props.logisticPrice?.calculateBy === "weight" ? 0.01 : 1;
        const quantityStart = price[price.length - 1].end + nextLevelIncrease;
        const weightStart = (price[price.length - 1].end + nextLevelIncrease);

        return this.props.logisticPrice?.calculateBy === "weight" ? Number(weightStart) : quantityStart;

      } catch(error) {
        console.error(error)
      }

    }

    handleAddPriceLogistic = () => {

        let price = this.props.logisticPrice.price;

        const data = {
            start: this.getStartCalculateByValue(),
            end: null,
            price: null,
            status: 'active',
            rateType: 'flat'
        }
        price.push(data)
        this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'price', value: price } }))
    }

    handleDeletePrice = (idx) => {
        let price = this.props.logisticPrice.price
        const priceRemove = this.props.logisticPrice.price[idx]
        this.props.dispatch(dispatchLogisticPrice({ type: HANDLE_ADD_LOGISTIC_DELETED, payload: priceRemove }))
        price.splice(idx, 1)
        // price[idx].start = priceRemove.start
        if (price[idx]?.start) {
            price[idx].start = price[idx - 1].end + 1
        }
        this.props.dispatch(dispatchLogisticPrice({
            type: SET_KEY_ADD_LOGISTIC_PRICE, payload: {
                key: 'price',
                value: price
            }
        }))
    }

    validateNumber = (evt) => {
      var charCode = (evt.which) ? evt.which : evt.keyCode
      return !(charCode > 31 && (charCode < 48 || charCode > 57));
  };

    onChangePrice = (name, value, idx) => {

       if (this.props.logisticPrice?.calculateBy === "quantity") {
         if (value) {
           if (name === "end") {
             /* value must be Integer */
             if (`${value}`.includes(".")) {
               value = parseFloat(`${value}`.split(".")[0]);
             }
           }

           if (Number(value) > 99999999999) value = 99999999999;
           if (Number(value) === 0) value = 1;
         }
       }

        if ( this.props.logisticPrice?.calculateBy === "weight" &&
          name === "end" ) {
            if(value > 10000 ) value = 10000;
            if(value === 0 ) value = 0.01;
            if(!value) value = "";
        }

        if(value && value < 0) value = value * -1;

        if(value >= 0) {
          this.props.dispatch(dispatchLogisticPrice({
              type: SET_PRICE_LOGISTIC_PRICE, payload: {
                  name: name,
                  value: value,
                  idx: idx
              }
          }))
        }
    }

    onChangeLogisticRateType = (e, idx) => {
        this.props.dispatch(dispatchLogisticPrice({
            type: SET_PRICE_LOGISTIC_PRICE, payload: {
                name: e.target.name,
                value: e.target.value,
                idx: idx
            }
        }))
    }

    onBlurChangePrice = (name, value, idx) => {
        let logisticPrice = this.props.logisticPrice.price
        for (let index = 0; index < logisticPrice.length; index++) {
            const element = logisticPrice[index];
            for (let indx = 0; indx < index; indx++) {
                const elem = logisticPrice[indx];

                const nextLevelIncrease = this.props.logisticPrice?.calculateBy === "weight" ? 0.01 : 1;
                const quantityStart     = logisticPrice[index - 1].end +  nextLevelIncrease;
                const weightStart       = (logisticPrice[index - 1].end + nextLevelIncrease);
                let   startValue        = this.props.logisticPrice?.calculateBy === "weight" ? weightStart : quantityStart;

                if (!isNaN(logisticPrice[index - 1].end)) {

                    this.props.dispatch(dispatchLogisticPrice({
                        type: SET_PRICE_LOGISTIC_PRICE, payload: {
                            name: 'start',
                            value: Number(startValue),
                            idx: index
                        }
                    }))

                    if (logisticPrice[index - 1].end + nextLevelIncrease > element.end) {
                        this.props.dispatch(dispatchLogisticPrice({
                            type: SET_PRICE_LOGISTIC_PRICE, payload: {
                                name: 'end',
                                value: null,
                                idx: index
                            }
                        }))
                    }
                }

                if (isNaN(elem.end)) {
                    this.props.dispatch(dispatchLogisticPrice({
                        type: SET_PRICE_LOGISTIC_PRICE, payload: {
                            name: 'status',
                            value: 'inactive',
                            idx: index
                        }
                    }))
                }

            }
        }


        const setInactive = logisticPrice.findIndex((item, index) => isNaN(item.end))

        logisticPrice.map((e, index) => {
            if (index > setInactive && setInactive !== -1) {
                this.props.dispatch(dispatchLogisticPrice({
                    type: SET_PRICE_LOGISTIC_PRICE, payload: {
                        name: 'status',
                        value: 'inactive',
                        idx: index
                    }
                }))
            } else {

                if(this.props.logisticPrice?.calculateBy === "weight") {

                   if(logisticPrice[index].start > 10000) {
                    this.props.dispatch(dispatchLogisticPrice({
                      type: SET_PRICE_LOGISTIC_PRICE, payload: {
                          name: 'status',
                          value: 'inactive',
                          idx: index
                      }
                    }))
                  } else {
                    this.props.dispatch(dispatchLogisticPrice({
                      type: SET_PRICE_LOGISTIC_PRICE, payload: {
                          name: 'status',
                          value: 'active',
                          idx: index
                      }
                  }))
                  }

                }

                if(this.props.logisticPrice?.calculateBy === "quantity") {
                  this.props.dispatch(dispatchLogisticPrice({
                      type: SET_PRICE_LOGISTIC_PRICE, payload: {
                          name: 'status',
                          value: 'active',
                          idx: index
                      }
                  }))
                }
            }
        })

        if (
          !isNaN(logisticPrice[logisticPrice.length - 1].end) &&
          logisticPrice[logisticPrice.length - 1].end != null &&
          logisticPrice[logisticPrice.length - 1].status != "inactive"
        ) {
          let isAllowToAdd = true;

          const endValue = logisticPrice[logisticPrice.length - 1].end;

          /* if weight >= 10000 do not add price input on next line */
          if(endValue && endValue >= 10000) isAllowToAdd = false;

          if(endValue === "") isAllowToAdd = false;

            if (isAllowToAdd === true) {
              this.handleAddPriceLogistic();
            }
        }
    }

    handleSubmit = async () => {

      try {
        const { logisticPrice } = this.props
        if (
          !isNaN(logisticPrice.price[logisticPrice.price.length - 1].end) &&
          logisticPrice.price[logisticPrice.price.length - 1].end != null &&
          logisticPrice.price[logisticPrice.price.length - 1].status !=
            "inactive"
        ) {
          this.handleAddPriceLogistic();
          return;
        }

        const verify = await this.verify(logisticPrice)
        if (verify) {
            if (this.props.location.state == undefined) {
                this.props.dispatch(dispatchLogisticPrice({ type: CREATE_LOGISTIC_PRICE }))
            } else {
                this.props.dispatch(dispatchLogisticPrice({ type: UPDATE_LOGISTIC_PRICE, payload: this.props.location.state }))
            }
        }
      } catch(error) {
      }

    }

  verify = async(data) => {
    let err = {
      name: false,
      start: [],
      end: [],
      price: []
    }
    let verify = true
    if (data.name?.trim().length === 0) err.name = true
    const lastIndexStatusActive = data.price?.filter((item) => item.status == 'active').length - 1

    for (let index = 0; index < data.price.length; index++) {
      const element = data.price[index];
      if (element.status == 'active') {

        if (isNaN(element.start) || element.start == null) err.start = [...err.start, index]

        if (index != data.price.length - 1) {
          if (lastIndexStatusActive != index) {
            if (isNaN(element.end) || element.end == null) {
              err.end = [...err.end, index]
            }
          }
        }

        if (
          element.price == undefined ||
          isNaN(element.price) ||
          !element.price
        )
          err.price = [...err.price, index];
      }

    }

    this.setState({ err })
    let idDetectError = ''

    if (err.name) {
      if (!idDetectError) {
        idDetectError = 'detect-scroll-input-name-logistic'
      }
      verify = false
    }
    if (err.end.length > 0) {
      if (!idDetectError) {
        idDetectError = `detect-scroll-input-end-logistic-${err.end[0]}`
      }
      verify = false
    }
    if (err.price.length > 0) {
      if (!idDetectError) {
        idDetectError = `detect-scroll-input-price-logistic-${err.price[0]}`
      }
      verify = false
    }

    if (!verify) {
      this.props.dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT,payload: { isOpen: true, message: msgErrRequire, type: 'alert' } }))
      await document.getElementById(idDetectError).innerHTML
      const scrollDiv = await document.getElementById(idDetectError).offsetTop;
      window.scrollTo({ top: scrollDiv - 70 , behavior: 'smooth' });
    }

    return verify
  }

    handleCreateSuccess = async () => {
        setLocalStorage({ key: 'shopDetailDefaultTab', value: { tab: 5 } })
        await this.props.history.push({ pathname: "/manage/shop/shopdetail" })
    }

    addMoreShopLogistic = () => {
        window.location.replace('/manage/shop/shopdetail/addlogisticprice')
    }

    render() {
        const { logisticPrice, modalConfirm } = this.props
        const { listLogistics, price, logisticSelected, name, calculateBy } = logisticPrice
        const { err } = this.state
        return (
          <div className="add-logistic-price">
            <h5>เพิ่มราคาค่าจัดส่ง</h5>
            <div className="container-box">
              <h4>เลือกผู้ให้บริการขนส่ง</h4>
              <div
                className="d-flex flex-row flex-wrap mt-3"
                style={{ gap: "30px" }}
              >
                {listLogistics &&
                  listLogistics.map((item) => (
                    <LogoSelect
                      value={item.logo}
                      className={
                        item.code === logisticSelected?.code ||
                        item.code === logisticSelected?.logisticCode
                          ? "active"
                          : ""
                      }
                      onClick={() => this.onChangeLogisticSelect(item)}
                    />
                  ))}
              </div>
            </div>

            <div className="container-box">
              <h5>ตั้งชื่อการจัดส่ง</h5>

              <div className="d-flex flex-column mt-4" id="detect-scroll-input-name-logistic">
                <div>
                  <span className="require">ชื่อการจัดส่ง</span>{" "}
                  <span>(จำกัด 60 ตัวอักษร)</span>
                </div>
                <Input
                  type="text"
                  className="input-form w-50 caption"
                  placeholder="ตั้งชื่อการจัดส่งของคุณ"
                  onChange={(e) => this.onChangeName(e.target.value)}
                  value={name}
                />
                {err?.name && (
                  <span className="require text-danger">
                    {" "}
                    กรุณากรอกชื่อการจัดส่ง{" "}
                  </span>
                )}
              </div>
            </div>

            <div className="container-box">
              <h5>รูปแบบการจัดส่ง</h5>

              {/* ANCHOR: delivery type option */}
              <div className="d-flex flex-row gap-4">
                {calculateByOptions &&
                  calculateByOptions.map((priceItem, priceItemIndex) => (
                    <div key={`deliverly-type-option-${priceItemIndex}`}>
                      <Radio
                        type="radio"
                        value={priceItem?.value}
                        className="mr-2"
                        valueOption={calculateBy}
                        name="calculateBy"
                        onChange={(e) =>
                          this.handleDeliveryTypeChange(e?.target?.value)
                        }
                        checked={calculateBy === priceItem.value}
                      />
                      <label className="form-check-label">
                        {priceItem?.label}
                      </label>
                    </div>
                  ))}
              </div>

              {/* ANCHOR: delivery price list by piece */}
              {calculateBy === "quantity" &&
                price.map((item, idx) => (
                  <>
                    <div
                      className={classnames(
                        "d-flex flex-row justify-content-between align-items-start flex-wrap set-price-value",
                        { "opacity-disable-price": item.status === "inactive" }
                      )}
                    >
                      <span>สินค้าชิ้นที่</span>
                      <span>{numeral(item?.start).format("0,0")}</span>
                      <span>-</span>
                      <div className="d-flex flex-column" id={`detect-scroll-input-end-logistic-${idx}`}>
                        <Input
                          type="number"
                          className="input-form caption set-price"
                          placeholder="ไม่จำกัด"
                          onKeyPress={this.validateNumber}
                          name="end"
                          onChange={(e) =>
                            this.onChangePrice(
                              e.target.name,
                              e.target.value,
                              idx
                            )
                          }
                          value={item.end}
                          onBlur={(e) =>
                            this.onBlurChangePrice(
                              e.target.name,
                              e.target.value,
                              idx
                            )
                          }
                        />
                        {err?.end.includes(idx) && (
                          <span className="text-danger require">
                            กรุณากรอกจำนวนสินค้า
                          </span>
                        )}
                      </div>

                      <div>
                        <Radio
                          type="radio"
                          value="flat"
                          className="mr-2"
                          valueOption={"rateType"}
                          name="rateType"
                          onChange={(e) =>
                            this.onChangeLogisticRateType(e, idx)
                          }
                          checked={item.rateType == "flat"}
                        />
                        <label className="form-check-label">ราคาเหมาจ่าย</label>
                      </div>
                      <div>
                        <Radio
                          type="radio"
                          value="item"
                          valueOption={"rateType"}
                          name="rateType"
                          onChange={(e) =>
                            this.onChangeLogisticRateType(e, idx)
                          }
                          checked={item.rateType == "item"}
                        />
                        <label className="form-check-label">
                          ราคาต่อชิ้น (ชิ้นละ)
                        </label>
                      </div>

                      <div className="d-flex flex-column" id={`detect-scroll-input-price-logistic-${idx}`}>
                        <InputNumberComponent
                          value={item.price}
                          name={"price"}
                          className={`form-control input-form caption set-price`}
                          type={"CURRENCY"}
                          placeholder={"ระบุจำนวนเงิน"}
                          onChange={(value) =>
                            this.onChangePrice("price", value?.floatValue, idx)
                          }
                          onBlur={(e) =>
                            this.onBlurChangePrice("price", item.price, idx)
                          }
                        />
                        {err?.price.includes(idx) && (
                          <span className="text-danger require">
                            กรุณากรอกราคาสินค้า
                          </span>
                        )}
                      </div>

                      <div style={{ width: "4.5%" }}>
                        {idx !== 0 ? (
                          <IconTrash
                            className="cursor-pointer"
                            onClick={() => this.handleDeletePrice(idx)}
                          />
                        ) : null}
                      </div>
                    </div>
                  </>
                ))}

              {/* ANCHOR: delivery price list by weight */}
              {calculateBy === "weight" &&
                price.map((item, idx) => (
                  <div
                    className={classnames(
                      "d-flex flex-row justify-content-between align-items-start flex-wrap set-price-value",
                      { "opacity-disable-price": item?.status === "inactive" }
                    )}
                  >
                    <span>น้ำหนักพัสดุเกิน</span>
                    <span>{numeral(item?.start).format("0,0.00")} กก.</span>
                    <span>-</span>
                    <div className="d-flex flex-row gap-2 align-items-start">
                      <span>ไม่เกิน</span>
                      <div>
                        <InputNumberComponent
                          value={item.end}
                          name={"end"}
                          className={`form-control input-form caption set-price`}
                          type={"CURRENCY"}
                          placeholder={"สูงสุด 10,000.00 กก."}
                          onChange={(value) =>
                            this.onChangePrice("end", value?.floatValue, idx)
                          }
                          onBlur={(e) =>
                            this.onBlurChangePrice("end", item.end, idx)
                          }
                        />
                      </div>
                      <span> กก. </span>
                    </div>
                    <div className="d-flex flex-row gap-2 align-items-start" id={`detect-scroll-input-price-logistic-${idx}`}>
                      <span>เรทราคา</span>
                      <div>
                        <InputNumberComponent
                          value={item.price}
                          name={"price"}
                          className={`form-control input-form caption set-price`}
                          type={"CURRENCY"}
                          placeholder={"ระบุจำนวนเงิน"}
                          onChange={(value) =>
                            this.onChangePrice("price", value?.floatValue, idx)
                          }
                          onBlur={(e) =>
                            this.onBlurChangePrice("price", item.end, idx)
                          }
                        />
                        {err?.price.includes(idx) && (
                          <span className="text-danger require">
                            {" "}
                            กรุณากรอกเรทราคา{" "}
                          </span>
                        )}
                      </div>
                      <span>บาท</span>
                    </div>
                    <div style={{ width: "5%" }}>
                      {idx !== 0 ? (
                        <IconTrash
                          className="cursor-pointer"
                          onClick={() => this.handleDeletePrice(idx)}
                        />
                      ) : null}
                    </div>
                  </div>
                ))}

              {/* <div className="d-flex justify-content-end mt-2">
                        <button className={classnames({
                            'btn btn-disabled': textIsEmpty(logisticPrice.price[logisticPrice.price.length - 1]?.end?.toString()) || isNaN(logisticPrice.price[logisticPrice.price.length - 1]?.end),
                            'btn-cancel btn': !textIsEmpty(logisticPrice.price[logisticPrice.price.length - 1]?.end?.toString()) && !isNaN(logisticPrice.price[logisticPrice.price.length - 1]?.end)
                        }
                        )} onClick={() => this.handleAddPriceLogistic()}
                            disabled={textIsEmpty(logisticPrice.price[logisticPrice.price.length - 1]?.end?.toString()) || isNaN(logisticPrice.price[logisticPrice.price.length - 1]?.end)}
                        >
                            เพิ่มอัตราค่าจัดส่ง
                        </button>
                    </div> */}
            </div>

            <div
              className="d-flex flex-row justify-content-center"
              style={{ gap: "30px" }}
            >
              <button
                className="btn-cancel"
                onClick={() =>
                  this.props.history.push("/manage/shop/shopdetail")
                }
              >
                ยกเลิก
              </button>
              <button
                className="btn-submit"
                onClick={() => this.handleSubmit()}
              >
                บันทึก
              </button>
            </div>

            <ModalConfirm
              isOpen={false}
              message={"ทำรายการสำเร็จ"}
              type={"success"}
              btnConfirm={"เสร็จสิ้น"}
              btnCancel={"สร้างรายการใหม่เพิ่ม"}
              confirm={() => {}}
              isClose={() => {}}
            />

            <ModalConfirmChildren
              isOpen={modalConfirm.isOpen}
              btnCancel={"สร้างรายการใหม่เพิ่ม"}
              btnConfirm={"เสร็จสิ้น"}
              handleConfirm={() => this.handleCreateSuccess()}
              handleCancel={() => this.addMoreShopLogistic()}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <IconSuccess />
                <h4 className="mt-3">ทำรายการสำเร็จ</h4>
              </div>
            </ModalConfirmChildren>
            <ModalAlert/>
          </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    logisticPrice: state.logisticPrice,
    modalConfirm: state.logisticPrice.modalConfirm
})


export default connect(mapStateToProps, null)(AddLogisticPrice)