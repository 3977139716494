import React from 'react'
import { ReactComponent as Logo } from 'images/icons/logo-v-avenue.svg'
import { Body2 } from 'components/FontStyle'
import './styles.scss'

const LogoCMS = (props) => {
    const { width, height } = props
    return (
        <div className="d-flex flex-row align-items-center" id="logo-cms">
            <Logo style={{ width, height }} className='mr-2' />
            <Body2 className="text-logo">CMS</Body2>
        </div>
    )
}

export default LogoCMS

LogoCMS.defaultProps = {
    width: '114px',
    height: '100%'
}
