import { Body1 } from 'components/FontStyle'
// import { ButtonIcon } from 'components/output'
import React from 'react'
// import { ReactComponent as IconTextbox } from 'images/icons/ic_textbox.svg'
// import { ReactComponent as IconPhoto } from 'images/icons/ic_photo.svg'
import ModalAlert from 'components/modalAlert/ModalAlert'
import ModalConfirm from 'components/modalConfirm/ModalConfirm'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import ContentList from 'components/Broadcast/ContentList'


const FormContentBroadcast = (props) => {
    const history = useHistory()
    const { createForm,
        setActiveTab, activeTabSelected, errors, 
        // setContent, 
        cancelForm } = props
    return (
        <React.Fragment>
            <div className="broadcast-content-box">
                <Body1>สร้างเนื้อหารูปภาพและข้อความ</Body1>

                {/* content image and content text render */}
                <ContentList
                    contentList={[]}
                    onContentListChange={(contents) => console.log('[contents]: ', contents)}
                />

                <div className="d-flex justify-content-center align-items-center mt-4 gap-1">
                    <button className="btn-cancel" onClick={cancelForm}>ยกเลิกการสร้าง</button>
                    <button className="btn-submit" style={{ width: '12em' }} onClick={createForm}>แสดงตัวอย่างก่อนบันทึก</button>
                </div>
            </div>

            <ModalAlert
                onSubmit={() => Object.keys(errors).length > 0 ? setActiveTab(activeTabSelected[0].value) : null}
            />

            <ModalConfirm
                isOpen={props.modalConfirm.isOpen}
                message={props.modalConfirm.message}
                btnConfirm={props.modalConfirm.btnConfirm}
                btnCancel={props.modalConfirm.btnCancel}
                confirm={() => history.push('/manage/broadcast')}
            />

        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    modalConfirm: state.modalConfirm.modalConfirm
})
export default connect(mapStateToProps)(FormContentBroadcast)
