import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import './styles.scss'

const NumberInput = (props) => {
  const {
    label,
    defaultValue,
    handleChangeText,
    placeholder,
    error,
    require,
    disabled,
  } = props

  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const onChangeText = (e) => {
    const tmp = e.target.value
    const reg = new RegExp(/^[0-9 ]+$/)

    if (tmp === '' || reg.test(tmp)) {
      // setValue(tmp.trim())
      handleChangeText(tmp.trim())
    }
  }

  return (
    <div className='text-input-field'>
      <label>{label}</label>
      {require && <span className='txt-red'>*</span>}
      <div className={classnames('container-input', { error: !!error })}>
        <input
          type='text'
          className='input-box'
          placeholder={placeholder}
          onChange={onChangeText}
          value={defaultValue}
          disabled={disabled}
        />
      </div>
      {error && <div className='txt-red mt-1'>{error}</div>}
    </div>
  )
}

NumberInput.defaultProps = {
  label: 'ชื่ออัลบั้ม',
  defaultValue: '',
  placeholder: '',
  error: '',
  require: false,
}

export default NumberInput
