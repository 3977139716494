import React from 'react'
import numeral from 'numeral'
import { useSelector} from 'react-redux'
import { getSelectedProduct } from 'redux/sagas/selectors';

import ProductSkus from './sku';
import IconTrash from 'images/icons/trash.svg'
import defaulIimage from 'images/icons/cover-product.svg'

import './product.scss'

const SelectedProduct = (prop) => {
  const { productId = '', coverPath, name, productSkus, remains, status } = prop.product

  const {product, count} = useSelector(getSelectedProduct) // SKU

  const calculatePriceRange = (sku = []) => {
    const arr = [];
    Object.values(productSkus).map((e) => {
      if (!arr.includes(e?.priceNet)) arr.push(e?.priceNet)
    })
    return arr.sort((a, b) => a - b)
  }

  const priceRange = calculatePriceRange(productSkus)

  // if priceRange is range not a single number
  // const priceRange = [200,5000]

  const handleSkuCheckbox = (productId, skuId, value) => {
    prop.handleUpdateSKU({ productID: productId, skuID: skuId, checked: value })
  }

  const handleDeleteProduct = (productId) => {
    prop.handleDeleteProduct(productId)
  }


  return (
    <div className='selected-product'>
      <div className='product-container'>
        <div className='product-image'>
          <img src={ coverPath?.host !== undefined ? (coverPath?.host + coverPath?.path) : defaulIimage } alt={`product - ${name}`}/>
        </div>
        <div className='product-name'>
          {name}
        </div>
        <div className='product-price'>
          {priceRange?.length === 1 ? `฿ ${numeral(priceRange[0]).format('0,0.00')}` : `฿ ${numeral(priceRange[0]).format('0,0.00')} - ${numeral(priceRange[priceRange.length - 1]).format('0,0.00')}`}
        </div>
        <div className='product-remains'>
          {numeral(remains).format('0,0')}
        </div>
        <div className='product-status'>
          {status === 'show' ? <span className='show'>ขายอยู่</span> : <span className='not-show'>ไม่ขาย</span>}
        </div>

        {count == 1  ? null :
        <div className='product-delete'>
          <img src={IconTrash} alt='Trash icon' onClick={() => handleDeleteProduct(productId)} />
        </div>
        }
      </div>

      <div className='text-sku'>SKU</div>

      <div className='productSkus-container'>
        {Object.values(productSkus)?.map((sku) => {
          // if(product[productId][sku?.skuId]==undefined) return null;
          return (
            <ProductSkus
              key = {sku?.skuId}
              imagePath = {(sku?.imagePath?.host !== undefined)? (sku?.imagePath?.host + sku?.imagePath?.path) : 
                            (coverPath?.host !== undefined)? (coverPath?.host + coverPath?.path) 
                            : defaulIimage }
              name = {sku?.sku}
              price = {sku?.priceNet}
              amount = {sku?.stockRemain}
              status = {sku?.status}
              handleSkuCheckbox={(value) => handleSkuCheckbox(productId, sku.skuId, value)}
              value={product[productId][sku?.skuId]}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SelectedProduct