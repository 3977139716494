/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { ListUser, ListChat, SideBarDetail } from 'components/outputChat'

import 'styles/chat/chatRoom.scss'
import {
  ADMIN,
  // SUPER_ADMIN, ADMIN, 
  OWNER,
  SUPER_ADMIN,
  ACCOUNTANT
} from '../layout/Sidebar/Role';

import Cookies from 'js-cookie'
import { Caption } from 'components/FontStyle'
import ToggleInput from 'components/ToggleInput'
import classNames from 'classnames'
import ModalChatMessage from 'components/modalChatMessage'
import { getListShop, getresponseMessage, setresponseMessage, updateresponseMessage } from 'provider/liveChatProvider'

const Chat = (props) => {
  const [dataUser, setDataUser] = useState({})
  const [idSelected, setIdSelected] = useState('')
  const [isShowChat, setIsShowChat] = useState(false)
  const [pin, setPin] = useState(false)
  const [announce, setAnnounce] = useState(false)
  const [shopId, setShopId] = useState(null)
  const [message, setMessage] = useState(undefined)
  const [statsuMessage, setStatsuMessage] = useState(false)
  const [type, setType] = useState(undefined)
  const [modalMessage, setModalMessage] = useState(false)

  const RoleUser = Cookies.get('role')

  useEffect(() => {
    document.getElementById('content').classList.toggle('hide-sidebar')
    if (window.name) {
      setIdChat(window.name)
    }
    fetchIDShop();
    // setMessage(`สวัสดีค่ะขอบคุณสำหรับการติดต่อ มีคำถามหรือข้อสงสัยสามารถทิ้งข้อความไว้ได้เลยค่ะ แอดมินจะรีบตอบกลับทันที  แอดมินจะรีบตอบกลับทันทีแอดมินจะรีบตอบกลับทันที  แอดมินจะรีบตอบกลับทันที`)
  }, [])

  useEffect(() => {
    if (shopId)
      fetchMessage();
  }, [shopId])

  const setUser = async (data) => {
    let userArrange = {}
    await Promise.all(
      await data?.map((user, index) => {
        userArrange[user._id] = user
      }),
    )

    setDataUser(userArrange)
  }

  const setIdChat = (id) => {
    setIdSelected(id)
    setIsShowChat(true)
  }

  const fetchIDShop = async () => {
    const { data } = await getListShop()
    if (data[0]) {
      setShopId(data[0]?.id)
    }
  }

  const fetchMessage = async () => {
    const res = await getresponseMessage(shopId);
    setMessage(res)
    setStatsuMessage(res?.status == 'active' ? true : false)
    setType(res?.responseType)
  }

  const fetchUpdateMessage = async (id,data) => {
    const res = await updateresponseMessage(id,data);
    setStatsuMessage(res?.status == 'active' ? true : false)
  }


  const fetchSetMessage = async (data) => {
    data.shopId = shopId ;
    const res = await setresponseMessage(data);
    setMessage(res)
    setStatsuMessage(res?.status == 'active' ? true : false)
    setType(res?.responseType)
  }

  const handleChangeStatus = () => {
    fetchUpdateMessage(shopId,{status:statsuMessage?'inactive':'active'})
    // setTimeout(() => {
    //   setStatsuMessage(!statsuMessage)
    // }, 3000);
  }

  console.log(message,type)
  return (
    <div className="chatRoom">

      <div className='d-flex flex-column'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>ข้อความ</div>
          {RoleUser == OWNER ? <button className='btn btn-modal' onClick={() => setModalMessage(true)}>ตั้งค่าข้อความอัตโนมัติ</button>: null}
        </div>

        {message?.status?
          <div className={classNames('box-message', { 'active': statsuMessage })}>
            <div className='time-message'>ข้อความอัตโนมัติ {type == 'any' ? '(ตลอดเวลา)' : `(${message?.responseTime?.start}-${message?.responseTime?.end})`}:</div>
            <div className='text-message'>{message?.text}</div>
            <div className='status-message'>
              <ToggleInput value={statsuMessage}
                onChange={() => handleChangeStatus()}
                name={"status"}
                labelOn={"เปิดการใช้งาน"}
                labelOff={"ปิดการใช้งาน"} />
            </div>
          </div>
          : null
        }

      </div>

      <div className='div-all'>
        <div className='div-list-user'>
          <ListUser
            setDataUser={setUser}
            setIdChat={setIdChat}
            idSelected={idSelected}
          />
          {/* <ListUser
            setIdChat={this.setIdChat}
            setDataUser={this.setDataUser}
            setReadChat={this.setReadChat}
            idSelected={this.state.id}
            disableChat={this.disableChat}
            setPin={this.setPin}
            setAnnounce={this.setAnnounce}
            setAnnounceMsg={this.setAnnounceMsg}
            setPlatform={this.setPlatform}
          /> */}
        </div>

        <div className='div-list-chat'>
          <ListChat
            isShowChatWithoutUser={!!window.name}
            dataUser={dataUser}
            idSelected={idSelected}
            isShowChat={isShowChat}
          />
          {/* <ListChat
            isShowChat={this.state.isShowChat}
            id={this.state.id}
            dataUser={this.state.dataUser}
            announce={this.state.announce}
            announceMsg={this.state.announceMsg}
            platform={this.state.platform}
          /> */}
        </div>
        {/* <SideBarDetail
          dataUser={dataUser}
          idSelected={idSelected}
          isShowChat={isShowChat}
          pin={pin}
          setPin={(value) => setPin(value)}
          announce={announce}
          setAnnounce={(value) => setAnnounce(value)}
        /> */}

        {/* <SideBarDetail
          isShowChat={this.state.isShowChat}
          dataUser={this.state.dataUser}
          id={this.state.id}
          pin={this.state.pin}
          setPin={this.setPin}
          announce={this.state.announce}
          setAnnounce={this.setAnnounce}
          announceMsg={this.state.announceMsg}
          setAnnounceMsg={this.setAnnounceMsg}
          platform={this.state.platform}
          updateDataUser={this.updateDataUser}
        /> */}
      </div>

      <ModalChatMessage
        isOpen={modalMessage}
        data={message}
        submit={fetchSetMessage}
        btnCancel={() => setModalMessage(false)}
      />

    </div>
  )
}

export default Chat
