import React, { Component } from 'react'
// import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames'
// import Cookies from 'js-cookie';
import { ButtonAdd, InputSearch } from 'components/output';
import '../styles/_promocode.scss'
// import TableHeader from 'components/TableHeader';
import { Link, withRouter } from 'react-router-dom';
import {
    dispatchPromocode, OPEN_MODAL_CONFIRM_PROMOCODE, SET_KEY_VALUE_PROMOCODE, SET_TAB_PROMOCODE, GET_LIST_PROMOCODE,
    // HANDLE_UPDATE_PROMOCODE, SET_SHOP_METAS,
    HANDLE_DELETE_PROMOCODE, GET_DETAIL_UPDATE_PROMOCODE
} from 'redux/actions/promocode';
import { connect } from 'react-redux'
import { ReactComponent as IconNoPromocode } from 'images/icons/nopromocode.svg'
import { ReactComponent as IconEdit } from 'images/icons/edit.svg';
import { ReactComponent as IconTrash } from 'images/icons/trash.svg';
import { ReactComponent as IconPercent } from 'images/icons/logo-percent.svg';
import { ReactComponent as IconBath } from 'images/icons/logo-bath.svg';
import ModalConfirm from 'components/modalConfirm/ModalConfirm';
// import promocode from 'redux/reducers/promocode';
import equal from 'fast-deep-equal'
import PaginationComponent from 'components/pagination';
import { displayDateThShortYear } from 'helper/timeUtil';
import { H5, Body1, Caption } from 'components/FontStyle';
import classNames from 'classnames';
import SelectInput from 'components/SelectInput';
import shopProvider from 'provider/shopProvider';
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert';
import ModalAlert from 'components/modalAlert/ModalAlert';
import { dispatchApp, SET_LOADING } from 'redux/actions/app';
import { dispatchNotification, OPEN_TOAST } from 'redux/actions/notification'
import promocodeProvider from 'provider/promocodeProvider';
import globalProvider from 'provider/globalProvider';
import {
    GET_SHOP_SELECTED,
    dispatchShop
} from 'redux/actions/shop';
import XlsxCoupon from 'components/XlsxCoupon';
import numeral from 'numeral';
import { CHECK_PRODUCT_CHECKED_SHOP, dispatchListCheckedShop } from 'redux/actions/listCheckedShop';
import ModalListShopProductPromocode from 'components/ModalListShopProductPromocode';



const statusTab = {
    ALL: { label: '' },
    COMINGSOON: { label: 'comingsoon' },
    PROCESSING: { label: 'processing' },
    EXPIRED: { label: 'expired' }
}

// const sortDisplayPromocode = [
//     { label: 'ตามเพลย์ลิสต์', value: 'playlist' },
//     { label: 'สุ่มคูปอง', value: 'random' },
//     { label: 'วันที่สร้าง', value: 'createdAt' }
// ]

let sortDisplayPromocode

class Promocode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            promocode: null,
        }
        this.getListPromocode = this.getListPromocode.bind(this)
        this.showStatusCustomCoupon = this.showStatusCustomCoupon.bind(this)
        this.isPlatform = this.props.location.pathname.includes('platform');
    }




    initialComponent = async () => {
        // this.props.dispatch(
        //     dispatchPromocode({
        //         type: SET_SHOP_METAS,
        //         payload: {
        //             shopId: 256,
        //             couponSortType: "createdAt"
        //         } 
        //     }))
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'kw', value: '' } }))
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'shopSelected', value: null } }))
        const getListSortType = await globalProvider.getListSortType()
        const listSortType = getListSortType.data.map((list, listIndex) => (
            {
                label: list.nameTh,
                value: list
            }
        ))
        sortDisplayPromocode = listSortType
        this.props.dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: true } }))
        this.props.dispatch(
            dispatchPromocode(
                {
                    type: SET_KEY_VALUE_PROMOCODE,
                    payload: {
                        key: 'activeTab',
                        value: this.props.match.params.status || ''
                    }
                }))
        // const platform = window.location.pathname.includes('platform') ? 'platform' : 'mall';
        // const redirectUrl = this.props.userInfo?.shopId ?
        //     '/manage/promocode' : `/manage/promocode/${platform}/`
        // window.history.pushState("", "", redirectUrl);
        await this.getListShop()
        await this.setSortTypePromocode()
        await this.getListPromocode()
        await this.props.dispatch(dispatchApp({ type: SET_LOADING, payload: { loading: false } }))
    }

    async componentDidMount() {

        // this.props.history.listen(async (location, action) => {
        //     if ( location.pathname === "/manage/promocode/mall/" ||
        //          location.pathname === "/manage/promocode/platform/")
        //         this.initialComponent();
        // });
        this.initialComponent();
    }

    //    static getDerivedStateFromProps(nextProps){
    //        console.log(`nextProps`, nextProps)
    //    }


    componentWillUnmount() {
        const path = this.props.history.location.pathname.split('/')
        if (path[2] !== 'promocode') {
            this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'shopSelected', value: undefined } }))
        }
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: "kw", value: '' } }))
    }

    // shouldComponentUpdate(props) {
    //     // if (this.props.promocode !== props.promocode) {
    //         return this.props.promocode !== props.promocode
    //     // }
    // }

    // componentWillReceiveProps(nextProps){
    //     if (nextProps.promocode !== this.state.promocode) {
    //         this.getListPromocode()
    //     }
    // }

    static async getDerivedStateFromProps(props, state) {
        if (!equal(props.promocode, state.promocode)) {
            // window.scrollTo({ top: 0, behavior: 'smooth' })
            return { promocode: props.promocode }
        }
        if (state.listShop && props.promocode.shopSelected) {
            const findShop = state.listShop?.findIndex((shop) => shop.label === props.promocode.shopSelected.label)
            return { shopSelected: findShop }
        }
        return null
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.shopSelected !== this.state.shopSelected) {
            this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'page', value: 1 } }))
            await this.setSortTypePromocode()
            await this.getListPromocode()
        }

        if (prevState.sortDisplay !== this.state.sortDisplay && this.state.shopSelected !== 0) {
            // this.props.dispatch(
            //     dispatchPromocode({
            //         type: SET_SHOP_METAS,
            //         payload: {
            //             shopId: this.state.listShop[this.state.shopSelected].value.id,
            //             couponSortType: this.state.sortDisplay
            //         }
            //     }))
            // this.getListPromocode()
        }
        if (prevState.listShop !== this.state.listShop) {
            let findShop = 0;
            if (this.state.listShop &&
                this.props.promocode.shopSelected) {
                findShop = this.state.listShop?.findIndex((shop) =>
                    shop.label === this.props.promocode.shopSelected.label)
            }
            let shopSelectedIndex = this.state.listShop.length === 2 &&
                !window.location.pathname.includes('platform') ?
                1 : findShop;
            this.setState({ shopSelected: shopSelectedIndex })
        }
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ sortDisplay: null, shopSelected: 0 })
            this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'kw', value: '' } }))
            this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'statusSearch', value: '' } }))

            this.initialComponent()
        }
    }

    setSortTypePromocode = async () => {
        if (this.state.shopSelected &&
            this.state.shopSelected !== 0 &&
            !this.props.location.pathname.includes('platform')) {
            const getShopMetas = await promocodeProvider.getShopMetas(this.state.listShop[this.state?.shopSelected].value?.id)
            //// set display from shop
            if (getShopMetas.data.length === 0) {
                await this.handleSelectSortDisplay(sortDisplayPromocode[1].value.name)
                return
                // this.setState({
                //     sortDisplay: sortDisplayPromocode[0].value
                // })
            } else {
                let isSetup = false
                getShopMetas.data.map(async (meta) => {
                    const data = Object.keys(meta).filter((ob) => ob === 'couponSortType')
                    if (data.includes('couponSortType')) {
                        this.setState({
                            sortDisplay: meta.couponSortType
                        })
                        isSetup = true
                    }
                    // else {
                    //     await this.handleSelectSortDisplay(sortDisplayPromocode[0].value.name)
                    // }
                })
                if (!isSetup) await this.handleSelectSortDisplay(sortDisplayPromocode[1].value.name)
            }
        } else {
            this.setState({
                sortDisplay: null
            })
        }
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'setSortListPromocode', value: null } }))
    }

    getListPromocode = () => {
        const { listShop, shopSelected } = this.state
        let payload = { shopId: null }
        if (!this.props.userInfo?.shopId) {
            if (this.props.location.pathname.includes('platform')) {
                payload.type = "platform"
            } else {
                payload.type = "shop"
            }
        }

        if (listShop && shopSelected)
            payload.shopId = listShop[shopSelected]?.value?.id?.toString()

        this.props.dispatch(dispatchPromocode({ type: GET_LIST_PROMOCODE, payload }))
    }

    getListShop = async () => {
        const res = await new shopProvider().getAllListShops()
        const shopList = [{ label: 'ร้านค้าทั้งหมด', value: '' }]
        // eslint-disable-next-line array-callback-return
        Promise.all(res.map(element => {
            shopList.push({ label: element.name, value: element })
        }))
        this.setState({
            listShop: shopList,
            shopSelected: 0
        })
    }


    setTabStatus = (tab) => {
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(dispatchPromocode({ type: SET_TAB_PROMOCODE, payload: tab }))
        this.getListPromocode()
    }

    onSearchPromocode = (e) => {
        if (e.key !== "Enter") return
        if (e.key === "Enter") {
            if (this.props.promocode.kw?.trim().length === 0) {
                this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'statusSearch', value: false } }))
            } else {
                this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'statusSearch', value: true } }))
            }
            this.getListPromocode()
        }
    }

    onClearSearch = () => {
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'statusSearch', value: false } }))
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'kw', value: '' } }))
        this.getListPromocode()
    }

    onChangePage = (page) => {
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'page', value: page } }))
        this.getListPromocode()
    }

    onShowSizeChange = (page, limit) => {
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'limit', value: limit } }))
    }

    showCouponType = (type) => {
        switch (type) {
            case 'discount':
                return 'ลดราคา'
            case 'voucher':
                return 'คูปองแทนเงินสด'
            default: return ''
        }
    }

    showCouponAction = (action) => {
        switch (action) {
            case 'product':
                return 'ลดราคาสินค้า'
            case 'shipping':
                return 'ลดค่าจัดส่ง'
            default:
                break;
        }
    }

    showCouponRole = (role) => {
        switch (role) {
            case 'public':
                return 'สาธารณะ'
            case 'private':
                return 'ส่วนตัว'
            default:
                break;
        }
    }

    showStatusCustomCoupon = (status) => {
        switch (status) {
            case 'comingsoon':
                return <div className="badge badge-orange p-0">เร็วๆ นี้</div>
            case 'processing':
                return <div className="badge badge-green p-0">กำลังดำเนินการ</div>
            case 'expired':
                return <div className="badge badge-red p-0">หมดอายุ</div>
            default:
                return <div className="badge badge-gray p-0">ยังไม่เปิดใช้งาน</div>
        }
    }

    showStatusCoupon = (status, statusCustom) => {
        switch (status) {
            case 'show':
                return this.showStatusCustomCoupon(statusCustom)
            case 'hide':
                return <div className="badge badge-gray">ยังไม่เปิดใช้งาน</div>
            default:
                break;
        }
    }

    handleClickAddCoupon = () => {

        // set shopSelected
        if (!this.props.location.pathname.includes('platform') &&
            this.props.promocode.shopSelected &&
            this.props.promocode.shopSelected.value) {
            this.props.dispatch(dispatchApp({
                type: GET_SHOP_SELECTED,
                payload: { shopId: this.props.promocode.shopSelected.value.id }
            }))
        }


        if (this.props.userInfo?.shopId) {
            this.props.dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'managePromocode', value: 'create' } })
            this.props.history.push("/manage/promocode/addcoupon", {
                listShop: this.state.listShop,
                shopSelected: this.state.shopSelected,
                shopId: this.props.userInfo?.shopId,
            })
        }
        else {
            if (window.location.pathname.includes('platform')) {
                this.props.history.push("/manage/promocode/platform/addcoupon", {
                    listShop: this.state.listShop,
                    shopSelected: this.state.shopSelected,
                    shopId: undefined,
                })
            } else {
                if (this.state.shopSelected === undefined || this.state.shopSelected?.toString().trim().length === 0) {
                    this.props.dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'managePromocode', value: 'create' } })
                    this.props.dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, type: 'alert', message: 'กรุณาเลือกร้านค้า' } }))
                } else {
                    this.props.dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'managePromocode', value: 'create' } })
                    this.props.history.push("/manage/promocode/mall/addcoupon", {
                        listShop: this.state.listShop,
                        shopSelected: this.state.shopSelected,
                        shopId: this.state.listShop[this.state.shopSelected]?.value?.id,
                    })
                }
            }
        }
    }

    handleSelectShop = (value) => {
        this.setState({
            shopSelected: value
        })
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'shopSelected', value: this.state.listShop[value] } }))
    }

    handleSelectSortDisplay = async (value) => {
        try {
            // this.props.dispatch(
            //     dispatchPromocode({
            //         type: SET_SHOP_METAS,
            //         payload: {
            //             shopId: this.state.listShop[this.state.shopSelected].value.id,
            //             couponSortType: value
            //         }
            //     }))

            const data = await promocodeProvider.setShopMetas({
                shopId: this.state.listShop[this.state.shopSelected].value.id,
                couponSortType: value
            })
            if (data.status === 201) {
                this.setState({
                    sortDisplay: value
                })
            }
            if (data.status === 422) {
                this.props.dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการผิดพลาด!`, type: 'danger' } }))
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    clearSearch = () => {
        this.props.dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'kw', value: '' } })
    }

    fetchDataExcel = async () => {

        const { listShop, shopSelected } = this.state
        let payload = { shopId: null }
        if (!this.props.userInfo?.shopId) {
            if (this.props.location.pathname.includes('platform')) {
                payload.type = "platform"
            } else {
                payload.type = "shop"
            }
        }

        if (listShop && shopSelected)
            payload.shopId = listShop[shopSelected]?.value?.id?.toString()

        // this.props.dispatch(dispatchPromocode({ type: GET_LIST_PROMOCODE, payload }))


        const data = await new Promise(async (resolve, reject) => {
            const { promocode } = this.props
            try {
                // const shopId = JSON.parse(Cookies.get('user')).shopId;
                const getPromocodeState = promocode
                let param = {
                    filter: { kw: getPromocodeState.kw },
                    status: getPromocodeState.activeTab,
                    // page: getPromocodeState.page,
                    // limit: getPromocodeState.limit,
                }
                if (payload?.shopId !== undefined && payload.shopId?.toString().trim().length !== 0)
                    param.shopId = payload.shopId

                if (payload?.type)
                    param.type = payload.type

                const res = await promocodeProvider.getListPromocode(param)
                if (res) {
                // yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'promocode', value: res.data } })
                // yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'total', value: res.data.count } })
                resolve(res.data)
                }
            } catch (error) {
                reject(error)
                // yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
            }
        })
        return data
    }

    render() {
        const { setTabStatus, onSearchPromocode } = this
        const { promocode, location } = this.props
        const { shopSelected, sortDisplay } = this.state
        return (
            <div id="promocode">
                {/* <H4>โปรโมชั่นโค้ด</H4> */}

                <div className="search-promotion-box bg-white">
                    <div>
                        <div className='d-flex flex-row justify-content-between mb-2'>
                            <div>
                                <H5>รายการคูปองส่วนลด</H5>
                                <Body1>{promocode.promocode?.count} รายการ</Body1>
                            </div>
                            <ButtonAdd
                                label='เพิ่มคูปอง'
                                handleAdd={this.handleClickAddCoupon}
                            />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">

                            {/* <div className='d-flex flex-row'> */}
                            <div className='w-100 d-flex flex-row' style={{ gap: '1em' }}>
                                {!location.pathname.includes('platform') &&
                                    <div className='w-50'>
                                        <SelectInput
                                            options={this.state.listShop}
                                            placeholder='กรุณาเลือกร้านค้า'
                                            onChange={(value) => { this.handleSelectShop(value) }}
                                            onClearSearchShop={() => this.setState({ shopSelected: undefined })}
                                            value={this.state.shopSelected}
                                            iconClearSearch={this.state.shopSelected?.toString().trim().length > 0 ? true : false}
                                        />
                                    </div>
                                }
                                <InputSearch
                                    placeholder={'ค้นหาชื่อคูปองส่วนลด'}
                                    onKeyPress={(e) => onSearchPromocode(e)}
                                    onChange={(e) => this.props.dispatch(
                                        dispatchPromocode({
                                            type: SET_KEY_VALUE_PROMOCODE,
                                            payload: { key: 'kw', value: e.target.value }
                                        })
                                    )}
                                    onClearSearch={this.onClearSearch}
                                    statusSearch={promocode.statusSearch}
                                    handleSearch={this.getListPromocode}
                                    defaultValue={promocode.kw}
                                    values={promocode.kw}
                                />
                            </div>

                            {/* TODO - handle manage item for user owner and admin */}




                        </div>
                    </div>

                    <div className='d-flex flex-row flex-wrap justify-content-between align-items-center'>
                        <ul className="d-flex flex-row tab-select-status-promotion">
                            <li
                                className={classnames({ 'active': promocode.activeTab === statusTab.ALL.label })}
                                onClick={() => setTabStatus('')}>
                                <Caption>คูปองส่วนลดทั้งหมด</Caption>
                                {promocode.activeTab === statusTab.ALL.label && <div className="tab-status-active" />}
                            </li>
                            <li
                                className={classnames({ 'active': promocode.activeTab === statusTab.COMINGSOON.label })}
                                onClick={() => setTabStatus('comingsoon')}>
                                <Caption>เร็วๆนี้</Caption>
                                {promocode.activeTab === 'comingsoon' && <div className="tab-status-active" />}
                            </li>
                            <li
                                className={classnames({ 'active': promocode.activeTab === statusTab.PROCESSING.label })}
                                onClick={() => setTabStatus('processing')}>
                                <Caption>ใช้งานอยู่</Caption>
                                {promocode.activeTab === 'processing' && <div className="tab-status-active" />}
                            </li>
                            <li
                                className={classnames({ 'active': promocode.activeTab === statusTab.EXPIRED.label })}
                                onClick={() => setTabStatus('expired')}>
                                <Caption>หมดอายุแล้ว</Caption>
                                {promocode.activeTab === 'expired' && <div className="tab-status-active" />}
                            </li>
                        </ul>
                        <span> <XlsxCoupon
                            handleFetchData={this.fetchDataExcel}
                            fileName={`รายการคูปองส่วนลด-${this.props.location.pathname.includes('platform') ? 'แพลตฟอร์ม' : this.state.shopSelected ? this.state.listShop[this.state.shopSelected]?.label : 'ร้านค้า'}-${displayDateThShortYear(new Date(),'DD-MM-YY')}`}
                            isPlatform={this.props.location.pathname.includes('platform')}
                        /> </span>
                    </div>
                </div>

                {!this.props.location.pathname.includes('platform') &&
                    <div className="web-format-filter">
                        <span>รูปแบบการแสดงผลในหน้าเว็บ</span>
                        {sortDisplayPromocode && sortDisplayPromocode.map((list, listIndex) => (
                            <React.Fragment key={`sortFilter${listIndex}`}>
                                <li
                                    className={classnames({ 'active': sortDisplay === list.value.name && shopSelected !== 0, 'pe-none in-active': shopSelected === 0 || this.props.location.pathname.includes('platform') })}
                                    onClick={() => this.handleSelectSortDisplay(list.value.name)}>
                                    {list.label}
                                </li>
                            </React.Fragment>
                        ))}

                    </div>
                }

                <TablePromocode
                    data={promocode}
                    dispatch={this.props.dispatch}
                    isPlatform={location.pathname.includes('platform')}
                    userInfo={this.props.userInfo}
                    listShop={this.state.listShop}
                    shopSelected={this.state.shopSelected}
                />

                {/* <div className="table-promocode mt-3">
                    <TableHeader
                        type={'header'}
                        dataHeader={['ลำดับ', 'รหัสอ้างอิง', 'ชื่อแคมเปญ', 'รูปแบบ', 'ประเภท', 'สิทธิ์การใช้งาน', 'สถานะการใช้งาน', '', '']}
                        colWidth={['10em', '20%', '35%', '25%', '20%', '25%', '25%', '7%', '7%']}
                        colSide={['center', 'left', 'left', 'left', 'left', 'center', 'center', 'center', 'center', 'center']}
                    />


                    {promocode.promocode?.data?.map((item, idx) => {
                        let redirectPath = '/manage/promocode/';
                        if (this.props.userInfo?.shopId) {
                            redirectPath += 'editcoupon'
                        } else {
                            if (this.isPlatform)
                                redirectPath += 'platform/editcoupon'
                            else
                                redirectPath += 'mall/editcoupon'
                        }

                        return <React.Fragment>
                            <TableHeader
                                key={`promocode${idx}`}
                                type={'content'}
                                // dataHeader={['1', '123123', 'คูปองแทนเงินสด 200 บาท', 'คูปองแทนเงินสด', 'ลดราสินค้า', 'สาธารณะ', 'กำลังดำเนินการ', <img src={IconEdit} /> , <img src={IconTrash} />]}
                                dataHeader={[(promocode.page - 1) * promocode.limit + 1 + idx,
                                item.coupon?.refCode1,
                                <div style={{ paddingRight: '2em' }}>{item.name}</div>,
                                this.showCouponType(item.coupon?.couponType),
                                this.showCouponAction(item.coupon?.couponAction),
                                this.showCouponRole(item.coupon?.couponRole),
                                this.showStatusCoupon(item.coupon?.status, item.statusCustom),
                                <Link to={redirectPath}>
                                    <img src={IconEdit}
                                        className="cursor-pointer"
                                        onClick={() => this.props.dispatch(dispatchPromocode(
                                            {
                                                type: GET_DETAIL_UPDATE_PROMOCODE,
                                                payload: { id: item.coupon?.id, shopId: item.shopId }
                                            }))} />
                                </Link>,
                                <img src={IconTrash} className="cursor-pointer" onClick={() =>
                                    this.props.dispatch(dispatchPromocode({
                                        type: OPEN_MODAL_CONFIRM_PROMOCODE,
                                        payload: {
                                            isOpen: true,
                                            message: 'คุณแน่ใจหรือไม่ที่จะลบโปรโมชั่นโค้ดนี้',
                                            confirm: () => this.props.dispatch(dispatchPromocode({ type: HANDLE_DELETE_PROMOCODE, payload: { id: item.coupon?.id } })),
                                            isClose: () => this.props.dispatch(dispatchPromocode({ type: OPEN_MODAL_CONFIRM_PROMOCODE, payload: { isOpen: false } }))
                                        }
                                    }))} />
                                ]}
                                colWidth={['10em', '20%', '35%', '25%', '20%', '25%', '25%', '7%', '7%']}
                                colSide={['center', 'left', 'left', 'left', 'left', 'center', 'center', 'center', 'center', 'center']}
                            />
                        </React.Fragment>
                    }
                    )}

                    {promocode.promocode?.data?.length === 0 &&
                        <div className="no-item-promocode">
                            <img src={IconNoPromocode} />
                            <span className="title">ยังไม่มีโปรโมชั่นโค้ด</span>
                        </div>
                    }
                </div> */}

                {promocode.promocode?.data?.length !== 0 &&
                    <PaginationComponent
                        pageSize={promocode.limit}
                        current={promocode.page}
                        total={promocode.total}
                        defaultCurrent={1}
                        onChange={this.onChangePage}
                        pageSizeOptions={promocode.pageSizeOptions}
                        onShowSizeChange={this.onShowSizeChange}
                        showQuickJumper={true}
                    />
                }


                <ModalConfirm
                    isOpen={promocode.modalConfirm.isOpen}
                    message={promocode.modalConfirm.message}
                    confirm={promocode.modalConfirm.confirm}
                    isClose={promocode.modalConfirm.isClose}
                />

                <ModalAlert />

            </div>
        )
    }
}

const TablePromocode = (props) => {
    const showStatusCoupon = (status, statusCustom) => {
        switch (status) {
            case 'show':
                return showStatusCustomCoupon(statusCustom)
            case 'hide':
                return <div className="color-default-action-main">ยังไม่เปิดใช้งาน</div>
            default:
                break;
        }
    }


    const showStatusCustomCoupon = (status) => {
        switch (status) {
            case 'comingsoon':
                return <div className="color-default-warning-main">เร็วๆ นี้</div>
            case 'processing':
                return <div className="color-ais-primary-main">ใช้งานอยู่</div>
            case 'expired':
                return <div className="color-default-action-main">หมดอายุ</div>
            default:
                return <div className="color-default-action-main">ยังไม่เปิดใช้งาน</div>
        }
    }

    let redirectPath = '/manage/promocode/';
    if (props.userInfo?.shopId) {
        redirectPath += 'editcoupon'
    } else {
        if (props.isPlatform)
            redirectPath += 'platform/editcoupon'
        else
            redirectPath += 'mall/editcoupon'
    }
    return (
        <React.Fragment>
            <div className={`table-promocode-header ${props.isPlatform ? 'platform' : ''}`}>
                {!props.isPlatform && <div className='text-left'>ร้านค้า</div>}
                <div className='text-left'>ชื่อคูปองส่วนลด</div>
                <div className='text-left'>มูลค่าส่วนลด</div>
                <div className='text-right'>คูปองทั้งหมด</div>
                <div className='text-right'>คูปองที่ถูกเก็บแล้ว</div>
                <div className='text-right'>คูปองที่ใช้แล้ว</div>
                {props.isPlatform && (<div className='text-right'>ร้านค้าที่เข้าร่วม</div>)}
                <div className='text-right'>SKU</div>
                <div className='text-left'>ระยะเวลาใช้</div>
                <div>สถานะ</div>
                <div></div>
            </div>
            {props.data?.promocode?.data?.map((code, codeIndex) => (
                <div className={`table-promocode-content ${props.isPlatform ? 'platform' : ''}`} key={`codeIndex${codeIndex}`}>
                    {!props.isPlatform && <div className="text-left">{code.shop?.name}</div>}
                    {/* <div className='text-left d-flex flex-row align-items-start ml-1'> */}
                    <div className='d-flex flex-row'>
                        <div className='p-0'>
                            {code?.detail?.discountUnit === 'number' && <IconBath className='mr-2' />}
                            {code?.detail?.discountUnit === 'percent' && <IconPercent className='mr-2' />}
                        </div>
                        <div className='d-flex flex-column text-left p-0'>
                            <span>{code?.name}</span>
                            <span>{code?.detail?.productCode}</span>
                        </div>
                    </div>
                    <div className='text-left'> {code?.detail?.discountValue} {code?.detail?.discountUnit === 'number' ? 'บาท' : '%'}</div>
                    <div className='text-right'>{code.detail?.stockMax === 999999 ? 'ไม่จำกัด' : code.detail?.stockMax}</div>
                    <div className='text-right'>{code.coupon?.countCollect}</div>
                    <div className='text-right'>{code.coupon?.countUsed}</div>
                    {/* Wait API Change field name */}
                    {props.isPlatform && (<div className='text-right'> { numeral(code?.countShopJoin || 0).format('0,0')}</div>)}
                    <div className='text-right'>{code?.countSkuJoin > 0 ? numeral(code?.countSkuJoin || 0).format('0,0') : numeral(code?.shop?.countProductSku || 0).format('0,0')}</div>
                    <div className='text-left'>
                        {displayDateThShortYear(code.startDate, 'DD/MM/YYYY HH:mm')}<br />
                        {displayDateThShortYear(code.stopDate, 'DD/MM/YYYY HH:mm')}
                    </div>
                    {showStatusCoupon(code.coupon?.status, code.statusCustom)}
                    <div className='d-flex flex-row flex-wrap'>
                        <Link to={{ pathname: redirectPath, state: props.userInfo?.shopId ? props.userInfo?.shopId : props.listShop && props.listShop[props.shopSelected]?.value?.id }}>
                            <IconEdit
                                className={classNames('cursor-pointer')}
                                onClick={() => props.dispatch(dispatchPromocode(
                                    {
                                        type: GET_DETAIL_UPDATE_PROMOCODE,
                                        payload: { id: code.coupon?.id, shopId: code.shopId }
                                    }))} />
                        </Link>
                        <IconTrash
                            className={classNames('cursor-pointer', { 'svg-disabled': code.statusCustom === 'processing' })}
                            onClick={() =>
                                code.statusCustom !== 'processing' && props.dispatch(dispatchPromocode({
                                    type: OPEN_MODAL_CONFIRM_PROMOCODE,
                                    payload: {
                                        isOpen: true,
                                        message: 'คุณแน่ใจหรือไม่ที่จะลบโปรโมชั่นโค้ดนี้',
                                        confirm: () => props.dispatch(dispatchPromocode({ type: HANDLE_DELETE_PROMOCODE, payload: { id: code.coupon?.id } })),
                                        isClose: () => props.dispatch(dispatchPromocode({ type: OPEN_MODAL_CONFIRM_PROMOCODE, payload: { isOpen: false } }))
                                    }
                                }))} />
                    </div>
                </div>
            ))}


            {props.data.promocode?.data?.length === 0 &&
                <div className="no-item-promocode">
                    <IconNoPromocode />
                    <span className="title">ยังไม่มีโปรโมชั่นโค้ด</span>
                </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    promocode: state.promocode,
    userInfo: state.user.userInfo,
    modalAlert: state.modalAlert.modalAlert
})


export default connect(mapStateToProps)(withRouter(Promocode))