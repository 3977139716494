/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { 
    // Button, 
    Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './styles.scss'
import iconClose from '../../images/icons/close.svg'
// import InputSelect from 'components/InputSelect';
import CardItemProductModal from 'components/CardItemProductModal';
import PaginationComponent from 'components/pagination';
import SelectInput from 'components/SelectInput';
import { dispatchRibbon, 
    // GET_DATA_ADD_PRODUCT,
     HANDLE_CHANGE_PAGE_PRODUCT, HANDLE_CHANGE_SHOP_SELECT_FILTER, HANDLE_GET_LIST_PRODUCT_DECORATE, HANDLE_RANDOM_PRODUCT_DECORATE, HANDLE_SELECT_ALL_PRODUCT_DECORATE, SEARCH_PRODUCT_ADD_RIBBON, SET_KEY_RIBBON, SET_PAGINATION_PRODUCT } from 'redux/actions/ribbon';
import { isEqual } from 'lodash';
import host from 'config/host';
import Loading from 'components/Loading';
import classNames from 'classnames';

const ModalSelectProduct = (props) => {
    const {
        // buttonLabel,
        // className,
        isOpen,
        isClose,
        dispatch,
        listProduct,
        onSelectProduct,
        handleCheck,
        listProductSelected,
        handleConfirm,
        listShopsSelectInput,
        ribbon,
        listAllProduct
    } = props;

    const [listShop, setListShop] = useState([])
    const [type, setType] = useState('create')
    const [ShopSelectInput, setListShopSelectInput] = useState([{ label: 'ร้านทั้งหมด', value: {} }])

    useEffect(() => {
        const listShop = filterShop()
        setListShop(listShop)
    }, [ribbon])

    useEffect(() => {
        let listShopSelect = [{ label: 'ร้านทั้งหมด', value: {} }]
        const data = listShopsSelectInput?.map((shop) => {
            listShopSelect.push({ label: shop.name, value: shop })
        })
        setListShopSelectInput(listShopSelect)
    }, [listShopsSelectInput])

    useEffect(() => {
        if (ribbon.modalAddProduct.data != null) {
            dispatch(dispatchRibbon({ type: HANDLE_GET_LIST_PRODUCT_DECORATE }))
        }
    }, [ribbon.modalAddProduct])

    useEffect(() => {
        if (ribbon.listProductSelected.length === 0) {
            setType('create')
        } else {
            setType('edit')
        }
    }, [ribbon.listProductSelected])


    const filterShop = () => {
        const listShopId = []
        const listShopDetail = []
        // const listProductSelectedId = [...listProductSelected.map((product) => product.shopId)]

        let shopList = listProductSelected?.map((product) => {
            if (!listShopId.includes(product.shopId)) {
                listShopId.push(product.shopId)
                listShopDetail.push(product.shop)
            }
        })
        return listShopDetail
    }

    const handleChangeShopSelect = (value) => {
        dispatch(dispatchRibbon({ type: SET_PAGINATION_PRODUCT, payload: { page: 1 } }))
        dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'shopSelectFilter', value: ShopSelectInput[value].value } }))
        dispatch(dispatchRibbon({ type: HANDLE_CHANGE_SHOP_SELECT_FILTER }))
    }

    const handleSearchProduct = (e) => {
        if (e.key === "Enter") {
            dispatch(dispatchRibbon({ type: SEARCH_PRODUCT_ADD_RIBBON, payload: e.key }))
        }
    }

    const handleSelectAllProduct = () => {
        dispatch(dispatchRibbon({ type: HANDLE_SELECT_ALL_PRODUCT_DECORATE }))
    }

    const handleRandomProductDecorate = () => {
        dispatch(dispatchRibbon({ type: HANDLE_RANDOM_PRODUCT_DECORATE }))
    }

    // const checkProductSelect = () => {
    //     const shopFilter = props.ribbon.shopSelectFilter?.id
    //     const listProduct = props.listProduct.filter((item) => item.shopId === shopFilter).length
    //     const listProductSelectedId = props.listProductSelected.filter((item) => item.shopId === shopFilter).length

    //     if (shopFilter === undefined) {
    //         return props.listProductSelected.length === props.listProduct.length
    //     } else {
    //         return listProductSelectedId === listProduct
    //     }
    // }

    const onChangePage = (page) => {
        dispatch(dispatchRibbon({ type: HANDLE_CHANGE_PAGE_PRODUCT, payload: { page } }))
    }
    const onChangeSizePage = (current, pageSize) => {
        dispatch(dispatchRibbon({ type: SET_PAGINATION_PRODUCT, payload: { limit: pageSize, page: 1 } }))
        dispatch(dispatchRibbon({ type: HANDLE_CHANGE_PAGE_PRODUCT, payload: { page: 1 } }))
    }




    return (
        <div>
            {/* <Button color="danger" onClick={isClose}>{buttonLabel}</Button> */}
            <Modal isOpen={isOpen} toggle={isClose} modalClassName='modal-add-product-ribbon'>
                <ModalHeader toggle={isClose}>
                    <div className="d-flex justify-content-between w-100">
                        <span>สินค้าที่ใช้กับโปรโมชั่นนี้
                            <img src={`${host.image}${ribbon.modalAddProduct?.data?.imagePath?.path}`} alt=""
                                style={{ maxWidth: ribbon.activeTab === "ribbon" ? '6em' : '3em' }} className="ml-4" /> </span>
                        <img src={iconClose} alt="close" onClick={isClose} className="cursor-pointer" />
                    </div> </ModalHeader>
                <ModalBody>
                    <div className="flex-row d-flex p-1 h-100 ">
                        <div className="w-50 h-100 first-product">
                            <div className="search-modal-promotion-box">
                                <h5>เลือกสินค้า</h5>
                                <div className="w-100 d-flex flex-row align-items-center justify-content-between gap-1">

                                    <div className="d-flex flex-row w-50 align-items-center">
                                        <span className="w-25"> ร้านค้า : </span>
                                        <div className="ml-2 w-100">
                                            <SelectInput
                                                iconClearSearch={false}
                                                options={ShopSelectInput}
                                                onChange={handleChangeShopSelect}
                                                value={ShopSelectInput?.findIndex((shop) => isEqual(shop.value, ribbon?.shopSelectFilter)) === -1 ?
                                                    null : ShopSelectInput?.findIndex((shop) => isEqual(shop.value, ribbon?.shopSelectFilter))}
                                            />
                                        </div>
                                    </div>
                                    <span>สินค้าทั้งหมด {ribbon.listProductCount} รายการ</span>
                                </div>
                                <div className="d-flex flex-row align-items-center mt-3">
                                    <span style={{ width: '13%' }}>สินค้า : </span>
                                    <input className="form-control"
                                        onChange={(e) => dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'searchProduct', value: e.target.value } }))}
                                        onKeyDown={(e) => handleSearchProduct(e)}
                                        value={ribbon.searchProduct}
                                    /></div>
                            </div>

                            <div className="show-product-first-tab">
                                {listProduct?.map((item, productidx) => (
                                    <CardItemProductModal
                                        product={item}
                                        onSelectProduct={(product) => onSelectProduct(product)}
                                        handleCheck={(product) => handleCheck(product)}
                                        ribbon={ribbon}
                                        key={`productidx-${productidx}`}
                                    />
                                ))}
                            </div>
                            <div>
                                {/* total, pageSize, current, pageSizeOptions, showQuickJumper, onShowSizeChange, onChange */}
                                <PaginationComponent
                                    total={ribbon.pagination.product.total}
                                    pageSize={ribbon.pagination.product.limit}
                                    current={ribbon.pagination.product.page}
                                    pageSizeOptions={[10, 20, 50, 100]}
                                    onShowSizeChange={(current, pageSize) => onChangeSizePage(current, pageSize)}
                                    onChange={(page) => onChangePage(page)}
                                    size={'small'}
                                />
                            </div>
                        </div>

                        <div className="w-50 h-100 second-product">
                            <div className="header-second-product">
                                <div className="d-flex justify-content-between">
                                    <h5>สินค้าที่เลือก</h5>
                                    <span>{listProductSelected?.length} รายการ</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        {/* <button className={classNames('btn', { 'btn-cancel': checkProductSelect() })} onClick={() => handleSelectAllProduct()}>ใช้กับสินค้าทั้งหมด</button> */}
                                        <button className={classNames('btn')} onClick={() => handleSelectAllProduct()}>ใช้กับสินค้าทั้งหมด</button>
                                        <button className="btn" onClick={() => handleRandomProductDecorate()}>สุ่มสินค้า</button>
                                    </div>
                                    <span className="color-primary cursor-pointer" onClick={() => dispatch(dispatchRibbon({ type: SET_KEY_RIBBON, payload: { key: 'listProductSelected', value: [] } }))}>ล้างข้อมูล</span>
                                </div>
                            </div>

                            {/* <div className="content-second-product"> */}

                            <div className="show-product-second-tab">
                                {/* ribbon.listShops -> list By shop index*/}
                                {listShop?.map((shop, shopidx) => (
                                    listProductSelected?.filter((product) => product?.shopId === shop?.id).length != 0 &&
                                    <div className="list-shop-container" key={`shopidx-${shopidx}`}>
                                        <div className="w-100 d-flex justify-content-between">
                                            <span> <img src={host.image + shop?.logoPath?.path} alt="" className="image-shop" /> {shop?.name}</span>
                                            <span>
                                                {listProductSelected.filter((product) => product?.shopId === shop?.id).length}/
                                                {listAllProduct?.filter((product) => product?.shopId === shop?.id).length} สินค้าที่เลือก
                                            </span>
                                        </div>
                                        {listProductSelected.map((product) => (
                                            product.name &&
                                            isEqual(product.shopId, shop.id) &&
                                            <CardItemProductModal
                                                product={product}
                                                handleCheck={() => { }}
                                                onSelectProduct={(product) => onSelectProduct(product)}
                                                type={'select'}
                                                listProduct={listProductSelected}
                                                ribbon={ribbon}
                                            />
                                        ))}
                                    </div>
                                ))}

                                {/* {[...new Array(12)].map((item) => (
                                            <CardItemProductModal />
                                        ))} */}
                            </div>

                            {/* </div> */}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-cancel" onClick={isClose}>ยกเลิก</button>
                    <button className="btn btn-confirm" onClick={() => handleConfirm()}>ตกลง</button>
                </ModalFooter>
                <Loading />
            </Modal>
        </div>
    );
}

export default ModalSelectProduct;
