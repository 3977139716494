import React, { useEffect, useState } from "react";
import { Body2, H6 } from "components/FontStyle";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import { ReactComponent as IconHint } from "images/icons/hint.svg";
import { Link } from "react-router-dom";

const BoxTitlePopUp = ({ textTimeRange, textTooltip }) => {
  return (
    <div className="d-flex align-items-center gap-1">
      <H6>{textTimeRange}</H6>
      <React.Fragment>
        <span id="soldTime">
          <IconHint className="icon-hint" width={30} height={30} />
        </span>
        <UncontrolledTooltip placement="top" target="soldTime">
          {textTooltip}
        </UncontrolledTooltip>
      </React.Fragment>
    </div>
  );
};

export default BoxTitlePopUp;
