import React, { useEffect, useState } from "react";

import {
    // Button,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from 'reactstrap';

import Pagination from 'components/pagination'
import { ButtonAdd, InputSearch } from 'components/output';
import SelectInput from 'components/SelectInput';
import iconNoItem from 'images/icons/no-item.svg'

import classnames from 'classnames'
import closeIcon from '../../images/icons/iconClose.svg'
import IconCloseCircle from 'images/icons/close-circle.svg'

import { useDispatch, useSelector } from 'react-redux';
import {
    SELECTED_PRODUCT_PROMOCODE,
    SET_KEY_VALUE_PROMOCODE,
    ADD_CHECKED_PRODUCT_PROMOCODE,
    REMOVE_CHECKED_PRODUCT_PROMOCODE,
    OPEN_MODAL_CONFIRM_PROMOCODE
} from 'redux/actions/promocode';
import {
    ADD_KEY_VALUE_PRODUCT,
    HANDLE_SEARCH_PRODUCT,
    GET_LIST_PRODUCT_PROMOCODE
} from 'redux/actions/product';
import {
    ADD_KEY_VALUE_CHECKED_PRODUCT,
    CHECK_PRODUCT_PROMOCODE,
    UNCHECK_ALL_PRODUCT_PROMOCODE,
    UNCHECK_PRODUCT_PROMOCODE
} from 'redux/actions/checkedProduct'
import { SET_LOADING } from 'redux/actions/app';
import './index.scss'
import productProviders, { objProduct } from "provider/productProvider";
import { forIn } from "lodash";
import CardProduct from "./cardProduct";
const productProvider = new productProviders();
// const listSort ={
//     createdAt: { value: "createdAt", label: "อัปโหลดสินค้าล่าสุด" },
//     name: { value: "name", label: "เรียงจากชื่อ" },
//   }


const listSort = [
    { value: "name", label: "เรียงจากชื่อ" },
    { value: "createdAt", label: "อัปโหลดสินค้าล่าสุด" }
]




const ModalCheckedProduct = (props) => {
    const dispatch = useDispatch()
    const seletedProduct = useSelector(state => state.selectedProduct);
    const checkedProduct = useSelector(state => state.checkedProduct);
    const promocode = useSelector(state => state.promocode);
    const stateProduct = useSelector(state => state.product);

    const { product, limit, page, loading, total, pageSizeOptions, valueSearch, sort } = stateProduct;



    const isOpen = promocode.isShowModalCheckedProduct

    const [products, setProduct] = useState([])
    const [search, setSearch] = useState('')
    const [statusSearch, setStatusSearch] = useState(false)
    const [historySearch, setHistorySearch] = useState([])
    const [sortBy, setSortBy] = useState(0)
    const [isDisableBtn, setIsDisableBtn] = useState(true)


    // moc data from json
    useEffect(() => {
        // const val = objProduct(jsonData);
        // dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'product', value: val.product } })
        // dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: val.count } })

    }, [])


    // get data Api
    useEffect(() => {
        let timenumber = 0;
        if (isOpen)
            if (!loading) {
                // set
                dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
                dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'limit', value: 30 } })
                dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'sort', value: listSort[0].value } })
                dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } })
                // timenumber = setTimeout(() => {
                //     dispatch({ type: SET_LOADING, payload: { loading: true } })
                // }, 1000);
                dispatch({ type: GET_LIST_PRODUCT_PROMOCODE })
            } else {
                // clearTimeout(timenumber)
                // dispatch({ type: SET_LOADING, payload: { loading: false } })
            }
        else {
            claerData()
        }

    }, [isOpen, loading])



    useEffect(() => {
        if (checkedProduct.count == 0) {
            setIsDisableBtn(true)
        } else {
            setIsDisableBtn(false)
        }
    }, [checkedProduct.count])

    const claerData = () => {
        dispatch({ type: SET_LOADING, payload: { loading: false } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'total', value: 0 } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'product', value: {} } })
        dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'listCheckedProduct', value: {} } })
        dispatch({ type: UNCHECK_ALL_PRODUCT_PROMOCODE })


    }

    const cancleModal = () => {
        if (checkedProduct.count == 0) {
            dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'isShowModalCheckedProduct', value: false } })
            claerData()
        } else {
            dispatch(
                {
                    type: OPEN_MODAL_CONFIRM_PROMOCODE,
                    payload: {
                        isOpen: true, message: 'คุณแน่ใจที่จะยกเลิกการทำรายการใช่หรือไม่',
                        confirm: () => {
                            claerData()
                            dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'isShowModalCheckedProduct', value: false } })
                            dispatch({ type: OPEN_MODAL_CONFIRM_PROMOCODE, payload: { isOpen: false, message: '', } })
                        },
                        isClose: () => { dispatch({ type: OPEN_MODAL_CONFIRM_PROMOCODE, payload: { isOpen: false, message: '', } }) }
                    }
                })
        }

    }


    const confirmModal = () => {
        dispatch({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'isShowModalCheckedProduct', value: false } })
        dispatch({ type: SELECTED_PRODUCT_PROMOCODE })
    }

    const handleUncheckAll = () => {
        dispatch({ type: UNCHECK_ALL_PRODUCT_PROMOCODE })
    }


    const onChangeSearch = (e) => {
        setSearch(e.target.value)
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: e.target.value } })
    }
    const onSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            if (search.trim().length === 0) {
                onClearSearch()
            }
        }

    }

    const onClickSeachProduct = () => {

        if (search.trim().length === 0) {
            setStatusSearch(false)
            setHistorySearch(search)
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } })
            // dispatch({ type: GET_LIST_PRODUCT_PROMOCODE })

        } else {
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: true } })
            setStatusSearch(true)
            setHistorySearch(search)
            dispatch({ type: GET_LIST_PRODUCT_PROMOCODE })

        }
    }

    const onClearSearch = () => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } })
        setSearch('')
        setStatusSearch(false)
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } })

        if(search.trim().length > 0) {
        dispatch({ type: GET_LIST_PRODUCT_PROMOCODE })
        }
    }

    const onChangePage = (page) => {
        if (statusSearch) {
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: page } })
            dispatch({ type: HANDLE_SEARCH_PRODUCT, payload: { search: historySearch } })
        } else {
            dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: page } })
            dispatch({ type: GET_LIST_PRODUCT_PROMOCODE })
        }
    }
    const onShowSizeChange = (current, pageSize) => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'limit', value: pageSize } })
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: current } })
    }
    const handleSelectSort = (value) => {
        dispatch({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'sort', value: listSort[value].value } })
        setSortBy(value)
        dispatch({ type: GET_LIST_PRODUCT_PROMOCODE })
    }

    const objSku = (productSkus) => {
        const sku = {}
        if (typeof productSkus === 'object') {
            for (const [key, value] of Object.entries(productSkus)) {
                Object.assign(sku, { [key]: true })
            }
            return sku
        } else {
            return {}
        }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                toggle={() => { }}
                modalClassName="modal-dialog-checked-product"
                centered={true}
            >
                <ModalHeader style={{ padding: '0.5rem 1rem', border: 'none' }}>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <div>
                            <span className="title">{'เลือกสินค้า'}</span>
                            <span className="sub">(เลือกแล้ว <span className="sub-txt">{checkedProduct.count + seletedProduct.count}</span> สินค้า <span className="sub-txt">{checkedProduct.sku + seletedProduct.sku}</span> SKU)</span>
                        </div>

                        {/* <img src={closeIcon} alt="" onClick={isClose} className="cursor-pointer" /> */}
                    </div>

                </ModalHeader>
                <ModalBody>
                    <div className="body-content m-auto">
                        <div className="bg-white">
                            <div className="d-flex justify-content-between align-items-center w-100" style={{ gap: '10px' }}>
                                <InputSearch
                                    placeholder={"ค้นหาจากชื่อสินค้า, ประเภทสินค้า, แบรนด์, ตัวเลือกสินค้า, เลขอ้างอิง SKU"}
                                    onChange={onChangeSearch}
                                    onKeyPress={onSearchKeyPress}
                                    handleSearch={onClickSeachProduct}
                                    onClearSearch={onClearSearch}
                                    statusSearch={statusSearch}
                                    defaultValue={valueSearch}
                                />

                                <div className='dropdown'>
                                    <SelectInput
                                        options={listSort}
                                        placeholder=''
                                        onChange={(value) => { handleSelectSort(value) }}
                                        value={sortBy}
                                        iconClearSearch={false}
                                        width={'12.5rem'}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 py-2">
                                <span className="text-teriary">ทั้งหมด {total}  สินค้า</span>
                                <button
                                    className={classnames("btn-submit", { "disable-btn": isDisableBtn })}
                                    disabled={isDisableBtn}
                                    style={{ padding: '0px', minWidth: '158px', borderRadius: '5px' }}
                                    onClick={handleUncheckAll}>
                                    <img src={closeIcon} alt="icon-close" className='icon-close' style={{ paddingRight: '6px'}} />{'ยกเลิกที่เลือกทั้งหมด'}
                                </button>
                            </div>

                            {(total === 0 || Object.values(product)?.length === 0) ?
                                (
                                    <div className="no-list-card">
                                        <div><img src={iconNoItem} /></div>
                                        <div className="font-weight-bold" >ไม่มีรายการ</div>
                                    </div>

                                ) : (
                                    <div className="list-card">
                                        {
                                            Object.keys(product)?.map((key) => {
                                                const item = product[key]
                                                return <CardProduct key={`card-product-${key}`} objSku={objSku} product={item} />
                                            })}
                                    </div>
                                )}
                        </div>


                        <div className="bg-grey">
                            <div className="pagination d-flex justify-content-end align-items-center w-100">
                                <Pagination
                                    total={total}
                                    defaultCurrent={1}
                                    onChange={onChangePage}
                                    pageSize={limit}
                                    pageSizeOptions={pageSizeOptions}
                                    onShowSizeChange={onShowSizeChange}
                                    current={page}
                                    showQuickJumper={true}
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="footer">
                        <span><button className="btn-cancel" onClick={cancleModal}>{'ยกเลิก'}</button></span>
                        <span><button className="btn-submit" onClick={confirmModal}>{'ตกลง'}</button></span>
                    </div>

                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default ModalCheckedProduct;
