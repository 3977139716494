/* eslint-disable no-unused-vars */
import postProvider from '../../provider/postProvider';
import FileProvider from '../../provider/fileProvider';
import { takeLatest, call, put, select, delay, take, takeEvery, all } from 'redux-saga/effects';
import {
    GET_POST_BY_SHOP_ID,
    SET_POST_BY_SHOP_ID,
    CREATE_POST,
    CREATE_POST_ITEM,
    GET_POST,
    SET_POST,
    GET_GENERATE_KEY,
    SET_GENERATE_KEY,
    PUBLISH_POST,
    STOP_POST,
    TOGGLE_SHOW_ON_ITEM,
    CREATE_RERUN_LIVE,
    GET_POST_BY_ID,
    HANDLE_DELETE_POST_LIVE,
    SET_MODAL_LIVE_FINISH_CONFIRM,
    UPDATE_POST,
    UPDATE_POST_ITEM,
    GET_POST_MEDIA_GROUP,
    SET_POST_MEDIAS,
    CREATE_POST_RERUN,
    CREATE_POST_VOD,
    GET_POST_VOD_BY_ID,
    SET_POST_VOD_BY_ID,
    DELETE_POST_VOD,
    UPDATE_POST_VOD_STATUS,
    GET_POST_MEDIA, SET_POST_MEDIA,
    GET_POST_VOD_BY_STATUS,
    UPDATE_POST_RERUN,
    SET_MODAL_RERUN_LIVE,
    UPDATE_PRODUCT_UPDATE,
    GET_POST_MEDIA_LIVE,
    GET_POST_MEDIA_BY_ID
} from '../actions/post';
import { SET_PAGINATION_POSTS } from '../actions/shop'
import { GET_LIVE_STATUS } from '../actions/live'
import { SET_LOADING } from '../actions/app';
import { OPEN_MODAL_ALERT } from '../actions/modalAlert';
import { getShop, getPost } from './selectors';
import Cookies from 'js-cookie';
import { loggers } from 'redux-act';
import { putWait, withCallback } from 'redux-saga-callback';
import { forEach } from 'jszip';

export function* onGetPostByShopID(action) {
    // yield put({ type: SET_LOADING, payload: { loading: true } })
    try {
        const { payload } = action;
        const getShopState = yield select(getShop);
        const { currentShop, paginationPost } = getShopState;
        const param = {
            shopId: currentShop.id,
            limit: paginationPost.limit,
            page: paginationPost.page
        }
        // const param = { shopId:payload.shopId, 
        //                   limit:100, 
        //                   page:1 }
        const postsByShopId = yield call(postProvider.getPostByShopId, param)
        yield put({ type: SET_POST_BY_SHOP_ID, payload: { postsByShopId: postsByShopId.posts } })
        yield put({ type: SET_PAGINATION_POSTS, payload: { key: 'total', value: postsByShopId.count } })
        // yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (ex) {
        yield put({ type: SET_LOADING, payload: { loading: false } })

    }
}
export function* onCreatePostVod(action) {
    yield put({ type: SET_LOADING, payload: { loading: true } });
    const { payload } = action;
    const { products, post, mode, postID } = payload;
    const getShopState = yield select(getShop);
    const { currentShop } = getShopState;

    try {

        let postVodCreate;
        const shopId = currentShop.id;

        /* NOTE - create post */
        if (mode === 'create') {
            post.shopId = shopId;
            const res = yield call(postProvider.createPostVod, post);
            if (!(res.status >= 400)) postVodCreate = res.data;
            // else throw res;
        }


        /* TODO - update post */
        if (mode === 'update') {
            yield call(postProvider.updatePostVod, payload);
            // if(res.status >= 400) throw res
        }


        const postId = mode === 'create' ? postVodCreate.id : postID;

        /* NOTE - create post items */
        post.shopId = shopId;
        const postItemsParam = createPostItemParam(products, postId, post);

        const postItemCreateRes = mode === 'create' ? yield call(postProvider.createPostItem, postItemsParam) :
            yield call(postProvider.upadatePostItem, postItemsParam);


        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: mode === 'create' ? 'สร้าง VOD สำเร็จ' : 'แก้ไข VOD สำเร็จ', type: 'success' } });
        yield delay(1000);
        localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: 3 }));
        window.location.replace("/manage/shop/shopdetail");

        yield put({ type: SET_LOADING, payload: { loading: false } })

    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
        console.error("[onCreatePost]", error);
        if (error.message.indexOf('E11000') > -1)
            yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'สร้างไลฟ์ไม่สำเร็จ \n ชื่อ Live ซ้ำ', type: 'alert' } })
        else
            yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'สร้างไลฟ์ไม่สำเร็จ', type: 'alert' } })

    }

}

export function* onGetPostVod(action) {
    try {

        const getShopState = yield select(getShop);
        const { currentPost } = getShopState;

        const param = { postId: currentPost.postId };
        const post = yield call(postProvider.getPost, param);

        yield put({ type: SET_POST, payload: { post } });


    } catch (error) {

    }

}

export function* onCreatePost(action) {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const { payload } = action;
    const { products, post, mode, postID, mediaId } = payload;

    const getShopState = yield select(getShop);
    const { currentShop } = getShopState;

    try {

        const shopId = currentShop.id;
        post.shopId = shopId;
        let postCreate;

        /* NOTE - create post */
        if (mode === 'create')
            postCreate = yield call(postProvider.createPost, post);

        /* NOTE - update post */
        if (mode === 'update') {
            const { coverPathId, shopId, title, releaseDate } = post;
            const _post = { coverPathId, title, releaseDate }
            yield call(postProvider.updatePost, { post: _post, mediaId });
        }

        const postId = mode === 'create' ? postCreate.id : postID;

        /* NOTE - create post items */
        const postItemsParam = createPostItemParam(products, postId, post);

        const postItemCreateRes = mode === 'create' ? yield call(postProvider.createPostItem, postItemsParam) :
            yield call(postProvider.upadatePostItem, postItemsParam);

        if (postItemCreateRes.status >= 400) throw postItemCreateRes;
        else {

            yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'สร้างไลฟ์สำเร็จ', type: 'success' } });
            yield delay(1000);
            localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: 2 }));
            window.location.replace("/manage/shop/shopdetail");
        }

        yield put({ type: SET_LOADING, payload: { loading: false } })

    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
        console.error("[onCreatePost]", error);
        if (error.message.indexOf('E11000') > -1)
            yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'สร้างไลฟ์ไม่สำเร็จ \n ชื่อ Live ซ้ำ', type: 'alert' } })
        else
            yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'สร้างไลฟ์ไม่สำเร็จ', type: 'alert' } })

    }
}

export function createPostItemParam(products, postId, post) {
    return products.reduce((productList, product) => {
        // eslint-disable-next-line array-callback-return
        product.productSkus.map(sku => {
            const item = {
                shopId: post.shopId,
                postId: Number(postId),
                productId: sku.productId,
                skuId: sku.id,
                id: sku.itemId,
                priceNet: sku.priceNet,
                priceDiscount: (sku.priceNet - sku.extraPrice.discountPrice),
                discountValue: sku.extraPrice.discountPercent,
                quotas: sku.extraPrice.privileges,
                releaseDate: post.releaseDate,
                discountValueUnit: "percent",
                costSharingPlatform: 0,
                costSharingPartner: sku.extraPrice.discountPrice
            }

            // NOTE - unlimit limitPerUser condition
            if (sku.extraPrice.buyLimit) item.limitPerUser = sku.extraPrice.buyLimit;
            if (sku.select === true) productList.push(item)
        })
        return productList;
    }, []);
}

export function* onCreateRerunLive(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const { payload } = action;
        yield call(postProvider.createLiveRerun, payload);
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'สร้างรีรันสำเร็จ', type: 'success' } });
        yield delay(1000);
        yield put({ type: SET_LOADING, payload: { loading: false } });
        localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: 2 }));
        window.location.replace("/manage/shop/shopdetail");

    } catch (error) {
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'สร้างรีรันสำเร็จไม่สำเร็จ', type: 'alert' } })
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }

}

export function* onGetPost(action) {
    try {

        const getShopState = yield select(getShop);
        const { currentPost } = getShopState;

        const param = { postId: currentPost.postId };
        const post = yield call(postProvider.getPost, param);

        yield put({ type: SET_POST, payload: { post } });


    } catch (error) {

    }

}

export function* onGetPostById(action) {

    try {
        const { payload } = action;
        const param = { postId: payload.postId };
        const post = yield call(postProvider.getPost, param);
        yield put({ type: SET_POST, payload: { post } });
    } catch (error) {

    }

}

export function* onGetPostMedia(action) {
    try {

        const getShopState = yield select(getShop);
        const { currentPost } = getShopState;

        const param = { postId: currentPost.mediaId };
        const res = yield call(postProvider.getPostVodById, param);
        yield put({ type: SET_POST_MEDIA, payload: { postMediae: res.data } });

    } catch (error) {

    }

}

export function* onGetPostMediaById(action) {
    try {
        const { payload } = action;
        const param = { mediaId: payload.mediaId };
        const res = yield call(postProvider.getPostMediaById, param);
        yield put({ type: SET_POST_MEDIA, payload: { postMediae: res.data } });

    } catch (error) {

    }

}

export function* onGetPostVodById(action) {

    try {
        const { payload } = action;
        const param = { postId: payload.postId };
        const postVod = yield call(postProvider.getPostVodById, param);
        yield put({ type: SET_POST_VOD_BY_ID, payload: { postVod: postVod.data } });
    } catch (error) {

    }

}

export function* onGenerateKey(action) {

    try {
        const getShopState = yield select(getShop);
        const { currentPost } = getShopState;

        const param = { postId: currentPost.postId };
        const generateKey = yield call(postProvider.getGenerateKey, param);
        yield put({ type: SET_GENERATE_KEY, payload: { generateKey } });
        yield put({ type: GET_POST, payload: {} });

    } catch (error) {

    }
}

export function* onPublishPost(action) {


    try {
        const getShopState = yield select(getShop);
        const { currentPost } = getShopState;

        const { payload } = action;
        const { upstreamKey } = payload;
        const postId = currentPost.postId;
        const param = { postId, upstreamKey };

        yield call(postProvider.publishPost, param);
        yield delay(5000)
        yield put({ type: GET_LIVE_STATUS, payload: {} });
        yield put({ type: GET_POST, payload: {} });


    } catch (error) {

    }
}

export function* onStopPost(action) {

    try {

        const getShopState = yield select(getShop);
        const { currentPost } = getShopState;

        const { payload } = action;
        const { upstreamKey } = payload;
        const postId = currentPost.postId;
        const param = { postId, upstreamKey };

        yield call(postProvider.stopPost, param);
        yield put({ type: GET_LIVE_STATUS, payload: {} });
        yield put({ type: GET_POST, payload: {} });
        // yield put({ type: SET_MODAL_LIVE_FINISH_CONFIRM, payload: {isOpen: true} });


    } catch (error) {

    }

}

export function* onToggleShowOnItem(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const { payload } = action;
        const res = yield call(postProvider.toggleShowProductItem, payload);
        if (res.status >= 400) yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'แก้ไขการแสดงสินค้าไม่สำเร็จ', type: 'alert' } })
        yield put({ type: GET_POST, payload: {} });
        yield put({ type: GET_POST_MEDIA, payload: {} });
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'แก้ไขการแสดงสินค้าไม่สำเร็จ', type: 'alert' } })
    }
}

export function* onProductOrderUpdate(action) {

    try {

        yield put({ type: SET_LOADING, payload: { loading: true } })
        const { payload } = action;
        const { items } = payload;

        yield all(items.forEach((item, index) => {
            const payload = {
                item: {
                    index: (index + 1),
                    skuId: item.skuId,
                    order: item.order,
                    showOn: item.showOn
                },
                itemId: item.id
            };
            putWait({ type: TOGGLE_SHOW_ON_ITEM, payload });
        }))

        yield put({ type: GET_POST_MEDIA, payload: {} })
        yield put({ type: GET_POST, payload: {} })
        yield put({ type: SET_LOADING, payload: { loading: false } })

    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }

}

// export function* onProvideStream(action) {
//     try {

//         const getShopState = yield select(getShop);
//         const { currentPost } = getShopState;

//         let param = { postId: currentPost. postId };
//         const post = yield call(postProvider.getPost, param);

//         if(!post?.streamKey || post?.streamUrl) 
//             yield put({ type: GET_GENERATE_KEY, payload: {} });

//     } catch (error) {

//     }
// }

export function* onDeletePostLive(action) {
    const { payload } = action;
    const { groupName, post } = payload;
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const res = yield call(postProvider.deletePostVodById, { postID: post.mediaId })
        if (res.status >= 400) throw res;

        const ShopState = yield select(getShop);
        const PostState = yield select(getPost);

        if (true) {
            if (groupName === 'nowLiveList')
                yield put({ type: GET_POST_MEDIA_GROUP, payload: getUpdatePostMediaParam(groupName, ShopState, PostState) })
            if (groupName === 'nextLiveList')
                yield put({ type: GET_POST_MEDIA_GROUP, payload: getUpdatePostMediaParam(groupName, ShopState, PostState) })
            if (groupName === 'finishLiveList')
                yield put({ type: GET_POST_MEDIA_GROUP, payload: getUpdatePostMediaParam(groupName, ShopState, PostState) })
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'ลบไม่สำเร็จ', type: 'alert' } })
        yield put({ type: SET_LOADING, payload: { loading: false } })

    }
}

export function* onUpdatePostVodStatus(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const res = yield call(postProvider.updatePostVodStatus, action.payload)

        const ShopState = yield select(getShop);
        const PostState = yield select(getPost);

        if (res.status >= 400)
            throw res;

        else {
            yield put({ type: GET_POST_MEDIA_GROUP, payload: getUpdatePostMediaParam('vodList', ShopState, PostState) })
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })

    } catch (error) {
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'แก้ไขสถานะ VOD ไม่สำเร็จ', type: 'alert' } })
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}

export function* onUpdatePostRerun(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const res = yield call(postProvider.updateRerun, action.payload)

        const ShopState = yield select(getShop);
        const PostState = yield select(getPost);

        if (res.status >= 400)
            throw res;

        else {
            yield put({ type: GET_POST_MEDIA_GROUP, payload: getUpdatePostMediaParam("nextLiveList", ShopState, PostState) })
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
        yield put({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'isOpen', value: false } })


    } catch (error) {
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'แก้ไขสถานะ VOD ไม่สำเร็จ', type: 'alert' } })
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}

export function* onGetPostVodStatus(action) {
    try {
        const { payload } = action;
        const { status } = payload;
        const ShopState = yield select(getShop);
        const PostState = yield select(getPost);
        yield put({ type: SET_LOADING, payload: { loading: true } })
        yield put({ type: GET_POST_MEDIA_GROUP, payload: getUpdatePostMediaParam('vodList', ShopState, PostState, status) })

    } catch (error) {
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'แก้ไขสถานะ VOD ไม่สำเร็จ', type: 'alert' } })
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}

export function* onDeletePostVod(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const res = yield call(postProvider.deletePostVodById, action.payload)

        const ShopState = yield select(getShop);
        const PostState = yield select(getPost);

        if (res.status >= 400)
            throw res;
        else
            yield put({ type: GET_POST_MEDIA_GROUP, payload: getUpdatePostMediaParam('vodList', ShopState, PostState) })

        yield put({ type: SET_LOADING, payload: { loading: false } })


    } catch (error) {
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'ลบ VOD ไม่สำเร็จ', type: 'alert' } })
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}

export function getUpdatePostMediaParam(groupName, ShopState, PostState, status = '') {

    const { currentShop } = ShopState;
    const { page, limit } = PostState.postMediaes[groupName];
    if (groupName === 'nowLiveList') {
        return {
            shopId: currentShop.id,
            type: ['live'],
            upstreamStatus: ['ready', 'publish', 'disconnect'],
            groupName
        }
    }
    if (groupName === 'nextLiveList') {
        return {
            shopId: currentShop.id,
            type: ['live', 'rerun'],
            upstreamStatus: ['wait'],
            groupName, page, limit
        }

    }
    if (groupName === 'finishLiveList') {
        return {
            shopId: currentShop.id,
            type: ['live'],
            upstreamStatus: ['finish'],
            groupName, page, limit
        }
    }
    if (groupName === 'vodList') {
        return {
            shopId: currentShop.id,
            type: ['vod'],
            //   upstreamStatus: ['ready','processing'],
            groupName, page, limit
        }
    }
    if (groupName === 'vodListByStatus') {
        return {
            shopId: currentShop.id,
            type: ['vod'],
            groupName, page, limit, status
        }
    }

}

export function* onGetPostMediaLive(action) {
    try {
        const { payload } = action // payload : { type, status }
        const { groupName, limit, page } = payload;
        yield put({ type: SET_LOADING, payload: { loading: true } });
        const res = yield call(postProvider.getPostMediaLive, payload);
        let postMediaes = res.data;
        if (postMediaes)
            yield put({ type: SET_POST_MEDIAS, payload: { postMediaes, groupName } })

        yield put({ type: SET_LOADING, payload: { loading: false } });
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } });
    }
}

export function* onGetPostMediaGroup(action) {
    const { payload } = action // payload : { type, status }
    const { groupName, limit, page } = payload;
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const res = yield call(postProvider.getPostMedia, payload);

        /* NOTE set limit and page of each live list */
        let postMediaes = res.data;
        if (postMediaes) {
            if (limit && page) {
                postMediaes.page = page;
                postMediaes.limit = limit;
            }
            yield put({ type: SET_POST_MEDIAS, payload: { postMediaes, groupName } })
        }

        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}

export function* onCreatePostRerun(action) {
    try {

        yield put({ type: SET_LOADING, payload: { loading: true } })
        const { payload } = action;
        const res = yield call(postProvider.createPostRerun, payload);
        if (res.status >= 200 && res.status < 300) {
            yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'สร้างรีรันสำเร็จ', type: 'success' } });
            yield delay(1000);
            yield put({ type: SET_LOADING, payload: { loading: false } });
            localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: 3 }));
            window.location.replace("/manage/shop/shopdetail");
        } else throw res.data;

    } catch (error) {
        console.log('[error]:', error);
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'สร้างรีรันสำเร็จไม่สำเร็จ', type: 'alert' } })
        yield put({ type: SET_LOADING, payload: { loading: false } })
    }
}




export default function* useWatcher() {
    yield takeLatest(GET_POST_BY_SHOP_ID, onGetPostByShopID)
    yield takeLatest(CREATE_POST, onCreatePost)
    yield takeLatest(UPDATE_POST, onCreatePost)
    yield takeLatest(GET_POST, onGetPost)
    yield takeLatest(GET_POST_BY_ID, onGetPostById)
    yield takeLatest(GET_POST_VOD_BY_ID, withCallback(onGetPostVodById))
    yield takeLatest(GET_GENERATE_KEY, withCallback(onGenerateKey))
    yield takeLatest(PUBLISH_POST, onPublishPost)
    yield takeLatest(STOP_POST, onStopPost)
    // yield takeLatest(TOGGLE_SHOW_ON_ITEM, onToggleShowOnItem)
    yield takeLatest(TOGGLE_SHOW_ON_ITEM, withCallback(onToggleShowOnItem))
    yield takeLatest(UPDATE_PRODUCT_UPDATE, onProductOrderUpdate)
    // yield takeLatest(PROVIDE_STREAM, onProvideStream)
    yield takeLatest(HANDLE_DELETE_POST_LIVE, onDeletePostLive)
    yield takeLatest(CREATE_RERUN_LIVE, onCreateRerunLive)
    yield takeEvery(GET_POST_MEDIA_GROUP, onGetPostMediaGroup)
    yield takeEvery(GET_POST_MEDIA_LIVE, onGetPostMediaLive)
    yield takeEvery(GET_POST_MEDIA, onGetPostMedia)
    yield takeEvery(GET_POST_MEDIA_BY_ID, onGetPostMediaById)
    yield takeLatest(CREATE_POST_VOD, onCreatePostVod)
    yield takeLatest(DELETE_POST_VOD, onDeletePostVod)
    yield takeLatest(UPDATE_POST_VOD_STATUS, onUpdatePostVodStatus)
    yield takeLatest(UPDATE_POST_RERUN, onUpdatePostRerun)
    yield takeLatest(CREATE_POST_RERUN, onCreatePostRerun)

}