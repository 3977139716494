/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import './styles.scss';
import ModalEditProductExcel from 'components/ModalEditProductExcel';
import MassUploadProvider from 'provider/MassUploadProvider';
import moment from 'moment';
import { displayDateThShortYear } from 'helper/timeUtil';
import { ReactComponent as IconHint } from 'images/icons/hint.svg'
import axiosNew from 'config/axios';
import host from 'config/host';
import Cookies from 'js-cookie';


const TableEditProductExcel = ({isOpen = false, 
                                toggle = () => {}, setIsOpen = () => {},
                                histories = []}) => {


  const columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'id',
      width: '10%',
      render: (text, record, index) => <span>{index + 1}</span>,
      align: 'center'
    },
    {
      title: 'วันที่อัปโหลด',
      dataIndex: 'createdAt',
      width: '15%',
      render: (text) => (
        <span>{displayDateThShortYear(text, 'DD MMM YY HH:mm')}</span>
      )
    },
    {
      title: 'ชื่อไฟล์',
      dataIndex: 'originalName',
      width: '30%'
    },
    {
      title: 'ชื่อร้านค้า',
      dataIndex: 'shop.name',
      width: '15%',
      render: ((text, record) => <span>{record.shop.name}</span>)
    },
    {
      title: 'จำนวนสินค้า',
      dataIndex: 'totalItem',
      width: '10%',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '12%',
      render: ((text, record) => <span>{text === 'success' ? <span className="text-success">อัปโหลดสำเร็จ</span> : <span className='text-danger cursor-pointer'>
        <Tooltip placement="top" title={'อัปโหลดไม่สำเร็จ เนื่องจากกรอกข้อมูลไม่ถูกต้อง'}>อัปโหลดไม่สำเร็จ <IconHint/>
      </Tooltip></span>}</span>)
    },
    {
      title: '',
      dataIndex: '',
      align: 'center',
      width: '10%',
      render: (text,record) => record.status === 'success' && 
      <div className='cursor-pointer text-download text-success' 
      onClick={() => handleDownload({shopId: record.shopId,fileName: record.fileName, originalName: record.originalName})}>Download</div>
    },
  ];



  const handleDownload = async (params) => {
    const {shopId,fileName,originalName} = params

    axiosNew.get(`${host.api}/product-attachments/download?shopId=${shopId}&fileName=${fileName}`, {
      headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${Cookies.get('token')}`,

      },
      responseType: 'arraybuffer',
  }).then((response) => {
      const temp = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = temp;
      link.setAttribute('download', `${originalName}`); //or any other extension
      document.body.appendChild(link);
      link.click();
  });
  }



  return (
    <div className='table-edit-product-excel'>
      <Table columns={columns} dataSource={histories} size="middle" pagination={false} />
      <ModalEditProductExcel isOpen={isOpen} toggle={toggle} />
    </div>
  )


}
export default TableEditProductExcel;
